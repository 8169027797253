<div class="modal-header">
  <h2 class="modal-title">{{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.TITLE' | translate}}</h2>
  <sis-modal-close-button/>
</div>
<form (ngSubmit)="onSubmit()">
  <div class="modal-body" *ngIf="values?.enrolmentRight && form">
    <div class="form-group">
      <span>
        <transloco [content]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.EDIT_DESCRIPTION' | translate:{
              studentName: values.enrolmentRight.personId | personInfo | async | personFullName,
              courseUnitName: values.enrolmentRight.courseUnitId | courseUnit | async | localizeProperty:'name',
              productName: productId ?
                (productId | openUniversityProduct | async | localizeProperty:'name') : '-'
            }" />
      </span>
    </div>
    <div class="form-group">
      <label [class]="getLabelState(form.controls.validityPeriod)">
        {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT.VALIDITY_PERIOD' | translate}}
      </label>
      <sis-contextual-notification variant="warning" class="d-block mt-0 mb-4">
        {{'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.VALIDITY_EDIT_NOTIFICATION' | translate}}
      </sis-contextual-notification>
      <sis-local-date-range-editor [control]="form.controls.validityPeriod" [hideLabels]="true"/>
    </div>
    <div class="form-group">
      <sis-basic-grouping-panel heading="OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.ENROLMENT_RIGHT_COURSE_UNIT_REALISATIONS"
                                guidance="OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.ENROLMENT_RIGHT_COURSE_UNIT_REALISATIONS_GUIDANCE">
        <div *ngIf="{ assessmentItems: assessmentItems$ | async, productCurs: productAllowedCURIdsByAssessmentItemId$ | async } as data"
             class="mb-5">
          <sis-assessment-item-cur-selection
            *ngFor="let assessmentItem of data.assessmentItems; trackBy: entityId"
            [form]="getEnrolmentConstraintControls(assessmentItem.id)"
            [assessmentItem]="assessmentItem"
            [activityPeriodFilter]="editedValidityPeriod"
            [productAllowedCURIds]="data.productCurs?.[assessmentItem.id]"
            [usedEnrolments]="usedEnrolmentsByAiId[assessmentItem.id]"
          />
        </div>
      </sis-basic-grouping-panel>
    </div>
    <div class="form-group">
      <sis-text-field [label]="'OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.CHANGE_MESSAGE_LABEL' | translate"
                      [control]="form.controls.changeMessage"
                      [textarea]="true">
      </sis-text-field>
    </div>
  </div>
  <div class="modal-footer">
    <sis-button [hollow]="true" (clicked)="onCancel()">{{'SIS_COMPONENTS.BUTTON.CANCEL' | translate}}</sis-button>
    <sis-button type="submit">{{'SIS_COMPONENTS.BUTTON.SAVE' | translate}}</sis-button>
  </div>
</form>
