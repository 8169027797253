import { Pipe, PipeTransform } from '@angular/core';
import { Event } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';

@Pipe({ name: 'eventTimeRange' })
export class EventTimeRangePipe implements PipeTransform {

    transform(event: Event): string {
        if (!event) {
            return '';
        }
        const startMoment = moment(event.start);
        const endMoment = moment(event.start).add(moment.duration(event.duration));
        return _.join([startMoment.format('H.mm'), endMoment.format('H.mm')], '–');
    }
}
