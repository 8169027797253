import angular from 'angular';
import * as _ from 'lodash-es';
import sisuRegularExpandableTpl from './sisuRegularExpandable.tpl.html';
export const sisuRegularExpandableModule = 'sis-components.expandable.sisuRegularExpandable';
(function () {
  sisuRegularExpandableController.$inject = ["$log"];
  angular.module(sisuRegularExpandableModule, []).component('sisuRegularExpandable', {
    template: sisuRegularExpandableTpl,
    controller: sisuRegularExpandableController,
    transclude: true,
    bindings: {
      headingId: '<',
      expandableTitle: '<',
      initiallyCollapsed: '<',
      toggleContentCallback: '&?',
      showContentCallback: '&?',
      specialExpandableClass: '<'
    }
  });

  /**
   * toggleContentCallback is optional function that can be used to lazy load content data and the load may be async
   * showContentCallback is optional function that can be used to hide content data during loading for example
   */
  function sisuRegularExpandableController($log) {
    var ctrl = this;
    var toggleButtonEnabled = true;
    var openedOnce = false;
    ctrl.$onInit = function () {
      ctrl.collapsed = _.isBoolean(ctrl.initiallyCollapsed) ? ctrl.initiallyCollapsed : ctrl.initiallyCollapsed === 'true';
      openedOnce = !ctrl.collapsed;
      ctrl.toggleContent = _.isFunction(ctrl.toggleContentCallback) ? toggleContentCallbackDefined : toggleContentCallbackNotDefined;
      if (isDefinedButNotFunction(ctrl.toggleContentCallback)) {
        $log.warn("toggleContentCallback is defined but not a function");
      }
      ctrl.showContent = _.isFunction(ctrl.showContentCallback) ? showContentCallbackDefined : showContentCallbackNotDefined;
      if (isDefinedButNotFunction(ctrl.showContentCallback)) {
        $log.warn("showContentCallback is defined but not a function");
      }
    };
    function isDefinedButNotFunction(callBackFunction) {
      return callBackFunction && !_.isFunction(callBackFunction);
    }
    function toggleContentCallbackDefined() {
      toggleContentCallbackNotDefined();
      var toggleContentCallbackResponse = ctrl.toggleContentCallback();
      if (_.get(toggleContentCallbackResponse, 'then')) {
        //Hide toggle button during async call
        toggleButtonEnabled = false;
        return toggleContentCallbackResponse.then(function () {
          toggleButtonEnabled = true;
        });
      }
    }
    function toggleContentCallbackNotDefined() {
      ctrl.collapsed = !ctrl.collapsed;
      openedOnce = true;
    }
    function showContentCallbackDefined() {
      return ctrl.showContentCallback && ctrl.showContentCallback() && !ctrl.collapsed;
    }
    function showContentCallbackNotDefined() {
      return !ctrl.collapsed;
    }
    ctrl.toggleButtonEnabled = function () {
      return toggleButtonEnabled;
    };
    ctrl.openedOnce = function () {
      return openedOnce;
    };
  }
})();