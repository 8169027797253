import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    DegreeProgramme,
    DegreeProgrammeAttainment,
    DegreeProgrammeAttainmentWorkflow,
    DPSMBase,
    Module,
    ModuleAttainment,
    ModuleAttainmentWorkflow,
    StudyModule,
    StudyRight,
    UniversitySettings,
    Workflow,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, combineLatestWith, Observable, of, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ModalService } from 'sis-common/modal/modal.service';
import { isDegreeProgrammeAttainment, isModuleAttainment } from 'sis-components/attainment/AttainmentUtil';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_WORKFLOW_STATE } from 'sis-components/model/student-workflow-constants';
import { UniversityService } from 'sis-components/service/university.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

import { AttainmentStudentService } from '../../../service/attainment-student.service';

export interface StudentModuleInfoModalValues {
    module: DPSMBase;
    validatablePlan: ValidatablePlan;
    studyRight: StudyRight;
    planValidationResult: PlanValidationResult;
}

interface StudentModuleInfoModalData {
    showModuleAttainmentApplicationBlock: boolean;
    showGraduationSection: boolean;
    selectedModule: DPSMBase;
    selectedModuleAttainment: ModuleAttainment | DegreeProgrammeAttainment;
    degreeProgrammeAttainmentWorkflow: DegreeProgrammeAttainmentWorkflow;
    activeModuleAttainmentWorkflow: ModuleAttainmentWorkflow;
    showCreateModuleAttainmentApplicationBlock: boolean;
}
@Component({
    selector: 'app-student-module-info-modal',
    templateUrl: './student-module-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentModuleInfoModalComponent implements OnInit {

    module: DPSMBase;
    validatablePlan: ValidatablePlan;
    studyRight: StudyRight;
    planValidationResult: PlanValidationResult;
    data$: Observable<Partial<StudentModuleInfoModalData>>;

    constructor(
        @Inject(ModalService.injectionToken) public values: StudentModuleInfoModalValues,
        public activeModal: NgbActiveModal,
        private attainmentStudentService: AttainmentStudentService,
        private workflowEntityService: WorkflowEntityService,
        private universityService: UniversityService,
        private authService: AuthService,
        private appErrorHandler: AppErrorHandler,
    ) {
        this.module = values.module;
        this.validatablePlan = values.validatablePlan;
        this.studyRight = values.studyRight;
        this.planValidationResult = values.planValidationResult;
    }

    ngOnInit() {
        const selectedModule$ = of(this.module);
        const moduleAttainments$ = this.createModuleAttainmentsObservable();
        const selectedModuleAttainment$ = this.createModuleAttainmentObservable(moduleAttainments$, selectedModule$);
        const universitySettings$ = this.createUniversitySettingsObservable();
        const showGraduationSection$ = this.createShowGraduationSectionObservable(universitySettings$, selectedModule$);
        const showCreateModuleAttainmentApplicationBlock$ = this.createShowCreateModuleAttainmentApplicationBlockObservable(universitySettings$, selectedModule$);
        const workflows$ = this.createStudentWorkflowsObservable();
        const activeModuleAttainmentWorkflow$ = this.createActiveModuleAttainmentWorkflowObservable(workflows$, selectedModule$);
        const degreeProgrammeAttainmentWorkflow$ = this.createDegreeProgrammeAttainmentWorkflowObservable(workflows$, selectedModule$);

        this.data$ = combineLatest({
            degreeProgrammeAttainmentWorkflow: degreeProgrammeAttainmentWorkflow$,
            showGraduationSection: showGraduationSection$,
            selectedModule: selectedModule$,
            selectedModuleAttainment: selectedModuleAttainment$,
            activeModuleAttainmentWorkflow: activeModuleAttainmentWorkflow$,
            showCreateModuleAttainmentApplicationBlock: showCreateModuleAttainmentApplicationBlock$,
        });
    }

    close() {
        this.activeModal.close();
    }

    createUniversitySettingsObservable(): Observable<UniversitySettings> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    canApplyForModuleAttainment(universitySettings: UniversitySettings, selectedModule: DPSMBase) {
        if (!this.validatablePlan) {
            return false;
        }
        if (!this.isStudyModule(selectedModule) || !selectedModule.graded) {
            return false;
        }
        if (!(universitySettings?.frontendFeatureToggles?.moduleAttainmentApplicationEnabled ?? true)) {
            return false;
        }
        if (!this.validatablePlan.isModuleInPlan(selectedModule.id) || this.validatablePlan.isModuleAttained(selectedModule.id)) {
            return false;
        }
        if (this.module.moduleContentApprovalRequired) {
            const moduleContentWorkflow = this.validatablePlan.getEffectiveModuleContentApproval(selectedModule.id);
            if (!moduleContentWorkflow || !_.includes(['ACCEPTED', 'CONDITIONAL'], moduleContentWorkflow.state)) {
                return false;
            }
        }
        const moduleValidationResult = _.get(this.planValidationResult, ['moduleValidationResults', selectedModule.id]);
        if (!moduleValidationResult || moduleValidationResult.state !== 'PARTS_ATTAINED') {
            return false;
        }
        return true;
    }

    createShowCreateModuleAttainmentApplicationBlockObservable(universitySettings$: Observable<UniversitySettings>, selectedModule$: Observable<DPSMBase>): Observable<boolean> {
        return universitySettings$
            .pipe(
                combineLatestWith(selectedModule$),
                map(([universitySettings, selectedModule]) => this.canApplyForModuleAttainment(universitySettings, selectedModule)),
            );
    }

    createActiveModuleAttainmentWorkflowObservable(workflows$: Observable<Workflow[]>, selectedModule$: Observable<Module>): Observable<ModuleAttainmentWorkflow> {
        return workflows$
            .pipe(
                map((workflows) => workflows.filter(workflow => workflow.type === 'ModuleAttainmentWorkflow')),
                combineLatestWith(selectedModule$),
                map(([workflows, selectedModule]) => (workflows as ModuleAttainmentWorkflow[]).find(
                    workflow => workflow.moduleId === selectedModule.id
                        && [STUDENT_WORKFLOW_STATE.REQUESTED, STUDENT_WORKFLOW_STATE.IN_HANDLING]
                            .includes(workflow.state as STUDENT_WORKFLOW_STATE),
                )),
            );
    }

    createShowGraduationSectionObservable(universitySettings$: Observable<UniversitySettings>, selectedModule$: Observable<Module>): Observable<boolean> {
        return combineLatest([universitySettings$, selectedModule$])
            .pipe(
                map(([universitySettings, module]) => this.showGraduationSection(universitySettings, module)),
            );
    }

    showGraduationSection(universitySettings: UniversitySettings, module: Module): boolean {
        return this.isDegreeProgramme(module) && this.degreeProgrammeAttainmentApplicationEnabled(universitySettings);
    }

    isDegreeProgramme(module: Module): module is DegreeProgramme {
        return module.type === 'DegreeProgramme';
    }

    degreeProgrammeAttainmentApplicationEnabled(universitySettings: UniversitySettings): boolean {
        return universitySettings?.frontendFeatureToggles?.degreeProgrammeAttainmentApplicationEnabled ?? true;
    }

    createModuleAttainmentsObservable(): Observable<(ModuleAttainment | DegreeProgrammeAttainment)[]> {
        const validStudentAttainments$ = this.attainmentStudentService.getMyValidAttainments()
            .pipe(this.appErrorHandler.defaultErrorHandler());
        return validStudentAttainments$
            .pipe(
                shareReplay(1),
                map(attainments => attainments.filter(
                    attainment => isModuleAttainment(attainment) || isDegreeProgrammeAttainment(attainment),
                ) as (ModuleAttainment | DegreeProgrammeAttainment)[]));
    }

    createModuleAttainmentObservable(moduleAttainments$: Observable<(ModuleAttainment | DegreeProgrammeAttainment)[]>,
                                     selectedModule$: Observable<Module>): Observable<ModuleAttainment | DegreeProgrammeAttainment> {
        return moduleAttainments$
            .pipe(
                combineLatestWith(selectedModule$),
                map(([attainments, selectedModule]) =>
                    attainments.find(attainment => attainment.moduleId === selectedModule.id)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    /**
     * Fetches all workflows for the current user.
     * Returns an empty array if preview mode is enabled.
     */
    createStudentWorkflowsObservable(): Observable<Workflow[]> {
        const workflowObservable$ = this.workflowEntityService.getWorkflowsByStudentId(this.authService.personId());
        return workflowObservable$
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                shareReplay(1),
            );
    }

    /**
     * Searches for DegreeProgrammeAttainmentWorkflow related to the currently selected module.
     *
     * @param workflows$ Array of workflows to search.
     * @param selectedModule$ The module that the search is performed for.
     */
    createDegreeProgrammeAttainmentWorkflowObservable(workflows$: Observable<Workflow[]>, selectedModule$: Observable<Module>): Observable<DegreeProgrammeAttainmentWorkflow> {
        return workflows$
            .pipe(
                map((workflows) => workflows.filter(workflow => workflow.type === 'DegreeProgrammeAttainmentWorkflow')),
                combineLatestWith(selectedModule$),
                map(([workflows, selectedModule]) => (workflows as DegreeProgrammeAttainmentWorkflow[]).find(
                    workflow => workflow.moduleId === selectedModule.id
                        && [STUDENT_WORKFLOW_STATE.REQUESTED, STUDENT_WORKFLOW_STATE.IN_HANDLING]
                            .includes(workflow.state as STUDENT_WORKFLOW_STATE),
                )),
            );
    }

    isStudyModule(module: DPSMBase): module is StudyModule {
        return module.type === 'StudyModule';
    }

    refreshData() {
        this.ngOnInit();
    }

}
