<div [hidden]="!pageTitle" [class.no-crumbs]="!showBreadcrumbs" class="page-title-container">
  <h1 id="skip-link-target" tabindex="-1" [attr.data-cy]="dataCy" class="page-title">
    {{pageTitle}}
    <span class="badge-container">
      <ng-container *ngFor="let badge of badges">
        <sis-tiny-badge data-cy="page-title-badge" [variant]="badge.variant" [customClasses]="badge.customClasses">{{badge.content}}</sis-tiny-badge>
      </ng-container>
      <ng-content select="[page-title=badges]"></ng-content>
    </span>
  </h1>
  <p *ngIf="pageSubTitle" class="page-sub-title">{{ pageSubTitle }}</p>
</div>
