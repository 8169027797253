import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Attainment } from 'common-typescript/types';
import { take } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { AttainmentEntityService } from '../../service/attainment-entity.service';

interface AttainmentMarkAsSecondaryModalValues {
    attainment: Attainment
}

@Component({
    selector: 'sis-attainment-mark-as-secondary-modal',
    templateUrl: './attainment-mark-as-secondary-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentMarkAsSecondaryModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: AttainmentMarkAsSecondaryModalValues,
                public activeModal: NgbActiveModal,
                private attainmentEntityService: AttainmentEntityService,
                private appErrorHandler: AppErrorHandler) {
    }

    requestInProgress = false;

    markAttainmentAsSecondary() {
        if (!this.requestInProgress) {
            this.requestInProgress = true;
            this.attainmentEntityService.markAsSecondaryAttainment(this.values.attainment.id)
                .pipe(take(1),
                      this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    next: () => this.activeModal.close(),
                    complete: () => { this.requestInProgress = false; },
                });
        }
    }
}
