import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/constants';
import {
    BatchOperationRequestWithEndTimes as BatchEndTimes,
    BatchOperationRequestWithEndTimesAndLocations as BatchEndTimesAndLocations,
    BatchOperationRequestWithLocations as BatchLocations,
    BatchOperationResult,
    CooperationNetworkShare, CourseUnitEnrolmentRight,
    CourseUnitRealisation,
    CourseUnitRealisationCourseUnitCodes,
    CourseUnitRealisationResponsibilityInfoType,
    CourseUnitRealisationsToCooperationNetworkRequest,
    DocumentState,
    EnrolmentCalculationState,
    EnrolmentConstraint,
    EnrolmentTimesAndStudyGroupsUpdateRequest,
    FlowState,
    LocalDateRange,
    OpenUniversityProductTeaching,
    OtmId,
    PersonWithCourseUnitRealisationResponsibilityInfoType,
    ResolvedFlowState,
    ResponsibilityInfo,
    ResponsiblePersonDeleteRequest,
    ResponsiblePersonsAddRequest,
    ResponsiblePersonValidityPeriodEndDates,
    ResponsiblePersonValidityPeriodsEndRequest,
    SearchValidity,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { simpleObjectToQueryParams } from '../search-ng/search-utils';
import { SisuDataLoader } from '../service/SisuDataLoader';
import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';

export interface AssessmentItemSearchParams {
    activityPeriods?: LocalDateRange[];
    activityState?: SearchValidity;
    documentStates?: DocumentState[];
    flowState?: FlowState[];
}

export interface OpenUniversityProductSearchParams {
    activityStatus?: SearchValidity;
    documentStates?: DocumentState | DocumentState[];
    flowStates?: FlowState | FlowState[];
    excludeDelayedPublish?: boolean;
    onlyForSalesPeriod?: boolean;
}

export type EnrolmentPeriodValidity = 'PAST' | 'ONGOING' | 'FUTURE' | 'MISSING';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        addToCooperationNetworkBatch() {
            return `${this.backend}/course-unit-realisations/add-to-cooperation-network/batch`;
        },
        addResponsiblePersons() {
            return `${this.backend}/course-unit-realisations/add-responsible-persons/batch`;
        },
        endResponsiblePersonValidityPeriod() {
            return `${this.backend}/course-unit-realisations/end-responsible-person-validity-period/batch`;
        },
        deleteResponsiblePerson() {
            return `${this.backend}/course-unit-realisations/delete-responsible-person/batch`;
        },
        getByCourseUnitIds() {
            return `${this.backend}/authenticated/course-unit-realisations-by-course-unit-id`;
        },
        getCourseUnitRealisationsCourseUnitCodes() {
            return `${this.backend}/authenticated/course-unit-realisations-course-unit-codes`;
        },
        getByAssessmentItemIds() {
            return `${this.backend}/course-unit-realisations-by-assessment-item-id`;
        },
        getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds() {
            return `${this.backend}/course-unit-realisations/published`;
        },
        updateEndTimesAndLocationsBatch() {
            return `${this.backend}/course-unit-realisations/update-endtimes-and-locations/batch`;
        },
        updateEndTimesBatch() {
            return `${this.backend}/course-unit-realisations/update-endtimes/batch`;
        },
        updateLocationsBatch() {
            return `${this.backend}/course-unit-realisations/update-locations/batch`;
        },
        getByOpenUniversityProductIds() {
            return `${this.backend}/course-unit-realisations/by-open-university-product-id`;
        },
        updateEnrolmentTimesAndStudyGroups(courseUnitRealisationId: OtmId) {
            return `${this.backend}/course-unit-realisations/enrolment-times-and-study-groups/${courseUnitRealisationId}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'course-unit-realisations',
})
export class CourseUnitRealisationEntityService extends EntityService<CourseUnitRealisationState> {

    public readonly courseUnitRealisationCourseUnitCodesStore: CourseUnitRealisationCourseUnitCodesStore;
    public readonly courseUnitRealisationCourseUnitCodesQuery: CourseUnitRealisationCourseUnitCodesQuery;
    public readonly courseUnitRealisationCourseUnitCodesDataLoader: SisuDataLoader<OtmId, CourseUnitRealisationCourseUnitCodes, CourseUnitRealisationCourseUnitCodes>;

    constructor(
        private localeService: LocaleService,
        private universityService: UniversityService,
    ) {
        super(CourseUnitRealisationStore, CourseUnitRealisationQuery);
        this.courseUnitRealisationCourseUnitCodesStore = new CourseUnitRealisationCourseUnitCodesStore();
        this.courseUnitRealisationCourseUnitCodesQuery = new CourseUnitRealisationCourseUnitCodesQuery(this.courseUnitRealisationCourseUnitCodesStore);
        this.courseUnitRealisationCourseUnitCodesDataLoader = new SisuDataLoader<OtmId, CourseUnitRealisationCourseUnitCodes, CourseUnitRealisationCourseUnitCodes>(
            {
                getByIdsCall: courseUnitRealisationIds => this.createGetCurCourseUnitCodesByCurIdsCall(courseUnitRealisationIds),
                successEntitiesCallback: entities => this.courseUnitRealisationCourseUnitCodesStore.upsertMany(entities),
                resultExtractor: (courseUnitRealisationId, entities) => entities.find(entity => entity.courseUnitRealisationId === courseUnitRealisationId),
                bufferSize: 50,
                bufferTime: 20,
            });
    }

    private createGetCurCourseUnitCodesByCurIdsCall(courseUnitRealisationIds: OtmId[]): Observable<CourseUnitRealisationCourseUnitCodes[]> {
        return this.getHttp().get<CourseUnitRealisationCourseUnitCodes[]>(CONFIG.ENDPOINTS.getCourseUnitRealisationsCourseUnitCodes(), { params: { courseUnitRealisationIds } });
    }

    getCourseUnitRealisationCourseUnitCodes(courseUnitRealisationId: OtmId, bypassStore: boolean = false): Observable<CourseUnitRealisationCourseUnitCodes> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('The courseUnitRealisationId was missing!'));
        }
        if (!bypassStore && this.courseUnitRealisationCourseUnitCodesQuery.hasEntity(courseUnitRealisationId)) {
            return this.courseUnitRealisationCourseUnitCodesQuery.selectEntity(courseUnitRealisationId);
        }
        return this.courseUnitRealisationCourseUnitCodesDataLoader.load(courseUnitRealisationId)
            .pipe(switchMap(() => this.courseUnitRealisationCourseUnitCodesQuery.selectEntity(courseUnitRealisationId)));
    }

    getByCourseUnitIds(courseUnitIds: OtmId[]) {
        return this.getHttp().get<CourseUnitRealisation[]>(CONFIG.ENDPOINTS.getByCourseUnitIds(), { params: { courseUnitId: courseUnitIds } });
    }

    getByAssessmentItemId(assessmentItemId: OtmId, filters?: AssessmentItemSearchParams) {
        const params = simpleObjectToQueryParams({
            ...(filters ?? {}),
            assessmentItemId,
            activityPeriods: filters?.activityPeriods?.map(period => `${period.startDate},${period.endDate ?? ''}`),
        });
        return this.getHttp().get<CourseUnitRealisation[]>(CONFIG.ENDPOINTS.getByAssessmentItemIds(), { params });
    }

    getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(assessmentItemIds: OtmId[]) {
        const params = {
            assessmentItemId: assessmentItemIds,
        };
        return this.getHttp().get<CourseUnitRealisation[]>(CONFIG.ENDPOINTS.getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(), { params });
    }

    updateEndTimesAndLocationsBatch(batchOperation: BatchEndTimesAndLocations): Observable<BatchOperationResult> {
        return this.getHttp().post<BatchOperationResult>(CONFIG.ENDPOINTS.updateEndTimesAndLocationsBatch(), batchOperation);
    }

    updateEndTimesBatch(batchOperation: BatchEndTimes): Observable<BatchOperationResult> {
        return this.getHttp().post<BatchOperationResult>(CONFIG.ENDPOINTS.updateEndTimesBatch(), batchOperation);
    }

    updateLocationsBatch(batchOperation: BatchLocations): Observable<BatchOperationResult> {
        return this.getHttp().post<BatchOperationResult>(CONFIG.ENDPOINTS.updateLocationsBatch(), batchOperation);
    }

    updateEnrolmentTimesAndStudyGroups(enrolmentTimesAndStudyGroupsUpdateRequest: EnrolmentTimesAndStudyGroupsUpdateRequest): Observable<CourseUnitRealisation> {
        return this.getHttp().put<CourseUnitRealisation>(CONFIG.ENDPOINTS.updateEnrolmentTimesAndStudyGroups(enrolmentTimesAndStudyGroupsUpdateRequest.courseUnitRealisationId), enrolmentTimesAndStudyGroupsUpdateRequest);
    }

    findByOpenUniversityProductIds(
        openUniversityProductIds: OtmId | OtmId[],
        filters: OpenUniversityProductSearchParams = {},
    ): Observable<OpenUniversityProductTeaching[]> {
        if (!openUniversityProductIds || openUniversityProductIds.length === 0) {
            return of([]);
        }
        const params = simpleObjectToQueryParams({
            ...(filters ?? {}),
            openUniversityProductId: openUniversityProductIds,
        });

        return this.getHttp().get<OpenUniversityProductTeaching[]>(CONFIG.ENDPOINTS.getByOpenUniversityProductIds(), { params })
            .pipe(
                map(results => results.map(result => ({
                    ...result,
                    courseUnitRealisations: this.sortByActivityPeriodAndName(result.courseUnitRealisations),
                }))),
            );
    }

    /**
     * Fetches all course unit realisations related to the given enrolment right, with some CURs related to the associated open
     * university product added if necessary.
     *
     * - Fetches all CURs related to the associated product, filtered by the given `documentStates` and `flowStates` parameters
     * - Fetches all CURs based on the enrolment constraints, filtered by the given `documentStates` and `flowStates` parameters
     * - Merges the results from the above two fetches, with the data from the enrolment constraint query taking precedence (i.e.
     * if both data sets have CURs for the same assessment item, the CURs from the product data are omitted)
     * - Filters all CURs by the enrolment right validity period, so that CURs with a non-overlapping activity period are omitted
     */
    findForEnrolmentRight(
        enrolmentRight: CourseUnitEnrolmentRight,
        documentStates: DocumentState[] = ['ACTIVE', 'DRAFT'],
        flowStates?: FlowState[],
    ): Observable<{ [assessmentItemId: string]: CourseUnitRealisation[] }> {
        const { openUniversityProductId, enrolmentConstraints } = (enrolmentRight ?? {});
        console.log('DATE UTILS', dateUtils);

        return combineLatest([
            this.findByOpenUniversityProductIds(openUniversityProductId, { documentStates, flowStates })
                .pipe(
                    take(1),
                    map(results => results.reduce((acc, item) => ({ ...acc, [item.assessmentItemId]: item.courseUnitRealisations }), {})),
                ),
            this.findByEnrolmentConstraints(enrolmentConstraints, documentStates, flowStates).pipe(take(1)),
        ])
            .pipe(
                map(([productCURsByAIId, enrolmentRightCURsByAIId]) => ({ ...productCURsByAIId, ...enrolmentRightCURsByAIId })),
                map((cursByAssessmentItemId: { [assessmentItemId: string]: CourseUnitRealisation[] }) => _.mapValues(
                    cursByAssessmentItemId,
                    curs => curs?.filter(cur => dateUtils.dateRangesOverlap(
                        cur?.activityPeriod?.startDate,
                        cur?.activityPeriod?.endDate,
                        enrolmentRight.validityPeriod?.startDate,
                        enrolmentRight.validityPeriod?.endDate,
                    )) ?? [],
                )),
            );
    }

    addToCooperationNetworkBatch(courseUnitRealisationIds: OtmId[], cooperationNetworkShare: CooperationNetworkShare, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addToCooperationNetworkBatch(),
            { courseUnitRealisationIds, cooperationNetworkId: cooperationNetworkShare.cooperationNetworkId, validityPeriod: cooperationNetworkShare.validityPeriod } as CourseUnitRealisationsToCooperationNetworkRequest,
            { params: { dryRun } },
        );
    }

    addResponsiblePersonsBatch(courseUnitRealisationIds: OtmId[], responsibilityInfos: ResponsibilityInfo[], dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addResponsiblePersons(),
            { ids: courseUnitRealisationIds, responsibilityInfos } as ResponsiblePersonsAddRequest<PersonWithCourseUnitRealisationResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    endResponsiblePersonValidityPeriodBatch(courseUnitRealisationIds: OtmId[], responsiblePersonId: OtmId, endDates: ResponsiblePersonValidityPeriodEndDates, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const roleValidityPeriodEndDates = [
            { roleUrn: 'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher', endDate: endDates.responsibleTeacherRoleEndDate },
            { roleUrn: 'urn:code:course-unit-realisation-responsibility-info-type:teacher', endDate: endDates.teacherRoleEndDate },
            { roleUrn: 'urn:code:course-unit-realisation-responsibility-info-type:administrative-person', endDate: endDates.adminRoleEndDate },
            { roleUrn: 'urn:code:course-unit-realisation-responsibility-info-type:contact-info', endDate: endDates.contactInfoRoleEndDate },
        ];
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.endResponsiblePersonValidityPeriod(),
            { ids: courseUnitRealisationIds, responsiblePersonId, roleValidityPeriodEndDates } as ResponsiblePersonValidityPeriodsEndRequest<CourseUnitRealisationResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    deleteResponsiblePersonBatch(courseUnitRealisationIds: OtmId[], responsiblePersonId: OtmId, rolesToDelete: Set<CourseUnitRealisationResponsibilityInfoType>, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.deleteResponsiblePerson(),
            { ids: courseUnitRealisationIds, responsiblePersonId, rolesToDelete: [...rolesToDelete] } as ResponsiblePersonDeleteRequest<CourseUnitRealisationResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    isActive(courseUnitRealisation: CourseUnitRealisation): boolean {
        if (!courseUnitRealisation?.activityPeriod) {
            return false;
        }
        return dateUtils.rangeContains(moment(), courseUnitRealisation.activityPeriod);
    }

    getResolvedFlowState(courseUnitRealisation: CourseUnitRealisation): ResolvedFlowState {
        const isActive = this.isActive(courseUnitRealisation);

        if (courseUnitRealisation.documentState === 'DELETED') {
            return 'DELETED';
        }
        if (courseUnitRealisation.flowState === 'ARCHIVED') {
            return 'ARCHIVED';
        }
        if (courseUnitRealisation.flowState === 'NOT_READY') {
            return 'NOT_READY';
        }
        if (courseUnitRealisation.flowState === 'PUBLISHED') {
            if (courseUnitRealisation.publishDate && moment(courseUnitRealisation.publishDate).isAfter(moment(), 'day')) {
                return 'PUBLISHED_FUTURE';
            }
            if (isActive) {
                return 'PUBLISHED_ACTIVE';
            }
            return 'PUBLISHED_EXPIRED';
        }
        if (courseUnitRealisation.flowState === 'CANCELLED') {
            if (isActive) {
                return 'CANCELLED_ACTIVE';
            }
            return 'CANCELLED_EXPIRED';
        }
        return null;
    }

    getEnrolmentPeriodValidity(
        courseUnitRealisation: CourseUnitRealisation,
        enrolmentCalculationState: EnrolmentCalculationState,
    ): EnrolmentPeriodValidity {
        const { enrolmentPeriod, lateEnrolmentEnd } = courseUnitRealisation ?? {};
        if (!enrolmentPeriod?.startDateTime || !enrolmentPeriod?.endDateTime) {
            return 'MISSING';
        }

        const now = moment();
        if (now.isBefore(enrolmentPeriod.startDateTime)) {
            return 'FUTURE';
        }
        if (now.isBetween(enrolmentPeriod.startDateTime, enrolmentPeriod.endDateTime)) {
            return 'ONGOING';
        }

        // Actual enrolment period has ended...
        if (lateEnrolmentEnd) {
            // ...and there is a late enrolment period
            // cross study enrolments are not allowed during late enrolment period
            const isCrossStudyEnrolment = !courseUnitRealisation?.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
            if (now.isAfter(lateEnrolmentEnd) || isCrossStudyEnrolment) {
                return 'PAST';
            }
            if (enrolmentCalculationState && enrolmentCalculationState !== 'CONFIRMED') {
                return 'FUTURE';
            }
            return 'ONGOING';
        }

        // ...and there is no late enrolment period
        return 'PAST';
    }

    sortByActivityPeriodAndName<T extends Partial<CourseUnitRealisation>>(curs: T[]): T[] {
        return _.sortBy(curs, [
            'activityPeriod.startDate',
            cur => this.localeService.localize(cur?.name),
        ]);
    }

    /**
     * Fetches course unit realisations based on the given enrolment constraints, and returns an object with one entry for each
     * constraint, where the key is the assessment item id defined in the constraint, and the value is an array of course unit
     * realisations related to the constraint. The course unit realisations to return for a constraint are resolved as follows:
     *
     * - If the `allowedCourseUnitRealisationIds` array of a constraint is not empty, exactly those CURs will be fetched
     * - If the `allowedCourseUnitRealisationIds` array of a constraint is `null`, all CURs of the assessment item will be fetched
     * - If the `allowedCourseUnitRealisationIds` array of a constraint is empty, no CURs will be fetched
     * - All CURs will be filtered by the given `documentStates` and `flowStates` parameters
     */
    private findByEnrolmentConstraints(
        constraints: EnrolmentConstraint[],
        documentStates: DocumentState[] = ['ACTIVE', 'DRAFT'],
        flowStates?: FlowState[],
    ): Observable<{ [assessmentItemId: string]: CourseUnitRealisation[] }> {
        if (!constraints) {
            return of({});
        }
        const allowedCURIdsByAIId: { [assessmentItemId: string]: OtmId[] | null } = constraints?.filter(Boolean).reduce(
            (result, constraint) => ({ ...result, [constraint.assessmentItemId]: constraint.allowedCourseUnitRealisationIds }),
            {},
        ) ?? {};

        if (Object.keys(allowedCURIdsByAIId).length === 0) {
            return of({});
        }

        const allExplicitlyAllowedCURIds = Object.values(allowedCURIdsByAIId).flat().filter(Boolean);
        const assessmentItemIdsWithoutCURConstraints = Object.keys(allowedCURIdsByAIId).filter(aiId => !allowedCURIdsByAIId[aiId]);

        return combineLatest([
            this.getByIds(allExplicitlyAllowedCURIds)
                .pipe(
                    map(curs => documentStates?.length > 0 ? curs.filter(cur => documentStates.includes(cur.documentState)) : curs),
                    map(curs => flowStates?.length > 0 ? curs.filter(cur => flowStates.includes(cur.flowState)) : curs),
                ),
            ...assessmentItemIdsWithoutCURConstraints
                .map(aiId => this.getByAssessmentItemId(aiId, { documentStates, flowState: flowStates })),
        ])
            .pipe(
                map(curArrays => _.uniqBy(curArrays.flat(), 'id')),
                map(curs => _.mapValues(
                    allowedCURIdsByAIId,
                    (curIds, aiId) => curs.filter(cur => !curIds ? cur.assessmentItemIds?.includes(aiId) : curIds.includes(cur.id)),
                )),
                map(cursByAiId => _.mapValues(cursByAiId, curs => this.sortByActivityPeriodAndName(curs))),
            );
    }

}

type CourseUnitRealisationState = EntityState<CourseUnitRealisation, OtmId>;

class CourseUnitRealisationQuery extends QueryEntity<CourseUnitRealisationState> {
    constructor(protected store: CourseUnitRealisationStore) {
        super(store);
    }
}

@StoreConfig({ name: 'course-unit-realisations' })
class CourseUnitRealisationStore extends EntityStore<CourseUnitRealisationState> {}

type CourseUnitRealisationCourseUnitCodesState = EntityState<CourseUnitRealisationCourseUnitCodes, OtmId>;

class CourseUnitRealisationCourseUnitCodesQuery extends QueryEntity<CourseUnitRealisationCourseUnitCodesState> {
    constructor(protected store: CourseUnitRealisationCourseUnitCodesStore) {
        super(store);
    }
}

@StoreConfig({ name: 'course-unit-realisation-course-unit-codes', idKey: 'courseUnitRealisationId' })
class CourseUnitRealisationCourseUnitCodesStore extends EntityStore<CourseUnitRealisationCourseUnitCodesState> {}
