import angular from 'angular';
import * as _ from 'lodash-es';
import { createRootRule } from 'common-typescript';
import { StudySelectorService } from 'sis-components/service/study-selector.service.ts';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planSelectionService.$inject = ["ruleService", "$q", "commonCourseUnitService", "commonModuleService", "studySelectorService", "commonPlanService", "planStudyRightService", "previewMode", "validAttainmentFilterService"];
  angular.module('student.common.service.planSelectionService', ['sis-components.service.ruleService', 'sis-components.service.courseUnitService', 'sis-components.service.moduleService', StudySelectorService.downgrade.moduleName, commonPlanServiceModule, 'student.common.utils.previewMode', 'sis-components.service.planStudyRightService', 'sis-components.service.validAttainmentFilterService']).factory('planSelectionService', planSelectionService);
  const resolveRule = (module, plan, studyRight) => module.id === plan.rootId ? createRootRule(module, studyRight) : module.rule;

  /**
   * @ngInject
   */
  function planSelectionService(
  //NOSONAR
  ruleService, $q, commonCourseUnitService, commonModuleService, studySelectorService, commonPlanService, planStudyRightService, previewMode, validAttainmentFilterService) {
    function doesParentModuleAcceptSelections(parentModuleId, validatablePlan) {
      return validatablePlan.isModuleInPlan(parentModuleId) && !validatablePlan.isModuleAttained(parentModuleId);
    }
    function isAttainmentAttached(attainment, validatablePlan) {
      var allAttainments = _.values(validatablePlan.attainmentsById);
      return validAttainmentFilterService.isAttached(attainment, allAttainments);
    }
    function findMatchingModuleRule(ruleOfParentModule, moduleGroupId) {
      return _.find(ruleService.allRules(ruleOfParentModule), {
        moduleGroupId: moduleGroupId
      });
    }
    function findMatchingCourseUnitRule(ruleOfParentModule, courseUnitGroupId) {
      return _.find(ruleService.allRules(ruleOfParentModule), {
        courseUnitGroupId: courseUnitGroupId
      });
    }
    function addModuleSelection(moduleId, parentModuleId, rawPlan) {
      if (!_.has(rawPlan, 'moduleSelections')) {
        rawPlan.moduleSelections = [];
      }
      rawPlan.moduleSelections.push({
        parentModuleId: parentModuleId,
        moduleId: moduleId
      });
    }
    function addCourseUnitSelection(courseUnitId, parentModuleId, rawPlan) {
      if (!_.has(rawPlan, 'courseUnitSelections')) {
        rawPlan.courseUnitSelections = [];
      }
      rawPlan.courseUnitSelections.push({
        parentModuleId: parentModuleId,
        courseUnitId: courseUnitId
      });
    }
    function addAssessmentItemSelection(assessmentItemId, courseUnitId, rawPlan) {
      if (!_.has(rawPlan, 'assessmentItemSelections')) {
        rawPlan.assessmentItemSelections = [];
      }
      rawPlan.assessmentItemSelections.push({
        assessmentItemId: assessmentItemId,
        courseUnitId: courseUnitId
      });
    }
    function removeAssessmentItemSelection(assessmentItemId, rawPlan) {
      _.remove(rawPlan.assessmentItemSelections, {
        assessmentItemId: assessmentItemId
      });
    }
    function recursivelyGetGroupIdsToBeSelected(rule, courseUnitGroupIds, moduleGroupIds) {
      if (rule.type === 'CourseUnitRule') {
        courseUnitGroupIds.push(rule.courseUnitGroupId);
      } else if (rule.type === 'ModuleRule') {
        moduleGroupIds.push(rule.moduleGroupId);
      } else if (rule.type === 'CompositeRule' && ruleService.isSelectAllRule(rule)) {
        _.forEach(rule.rules, function (subRule) {
          recursivelyGetGroupIdsToBeSelected(subRule, courseUnitGroupIds, moduleGroupIds);
        });
      } else {
        var childRule = _.get(rule, 'rule');
        if (childRule && childRule.type === 'CompositeRule') {
          recursivelyGetGroupIdsToBeSelected(childRule, courseUnitGroupIds, moduleGroupIds);
        }
      }
    }
    function getDeferredArray(size) {
      var deferredArray = [];
      for (var i = 0; i < size; i++) {
        deferredArray.push($q.defer());
      }
      return deferredArray;
    }
    var api = {
      getRulesToBeActivated: function (rule, parentModule, validatablePlan, studyRight) {
        if (ruleService.isSelectAllRule(rule)) {
          return rule.rules;
        }
        if (_.get(rule, 'require.min') === 0) {
          return [];
        }
        if (!_.isNil(_.get(studyRight, 'personalizedSelectionPath'))) {
          const personalizedPhase1ModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase1.moduleGroupId');
          const personalizedPhase1ChildModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase1.childModuleGroupId');
          const personalizedPhase1ModuleId = _.get(validatablePlan.getModuleInPlanByGroupId(personalizedPhase1ModuleGroupId), 'id');
          if (personalizedPhase1ModuleId && validatablePlan.isModuleInPlanUnderModule(parentModule.id, personalizedPhase1ModuleId)) {
            return _.filter(rule.rules, subRule => subRule.type === 'ModuleRule' && subRule.moduleGroupId === personalizedPhase1ChildModuleGroupId);
          }
          const personalizedPhase2ModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase2.moduleGroupId');
          const personalizedPhase2ChildModuleGroupId = _.get(studyRight, 'personalizedSelectionPath.phase2.childModuleGroupId');
          const personalizedPhase2ModuleId = _.get(validatablePlan.getModuleInPlanByGroupId(personalizedPhase2ModuleGroupId), 'id');
          if (personalizedPhase2ModuleId && validatablePlan.isModuleInPlanUnderModule(parentModule.id, personalizedPhase2ModuleId)) {
            return _.filter(rule.rules, subRule => subRule.type === 'ModuleRule' && subRule.moduleGroupId === personalizedPhase2ChildModuleGroupId);
          }
        }
        var rulesToBeSelected = [];
        if (validatablePlan.rootModule.type === 'Education') {
          var educationOptions = planStudyRightService.getEducationOptions(validatablePlan, validatablePlan.rootModule);
          if (educationOptions) {
            rulesToBeSelected = _.filter(rule.rules, function (subRule) {
              if (subRule.type !== 'ModuleRule') {
                return true;
              }
              var educationOption = planStudyRightService.getPotentialEducationOption(subRule.moduleGroupId, parentModule.groupId, educationOptions, validatablePlan);
              if (!educationOption) {
                return true;
              }
              return planStudyRightService.isEducationOptionSelectableBasedOnLearningOpportunity(educationOption, validatablePlan.rootModule, validatablePlan.plan.learningOpportunityId);
            });
          }
          if (studyRight && studyRight.learningOpportunityId === validatablePlan.plan.learningOpportunityId) {
            rulesToBeSelected = _.filter(rulesToBeSelected, function (subRule) {
              var educationOption = planStudyRightService.getPotentialEducationOption(subRule.moduleGroupId, parentModule.groupId, educationOptions, validatablePlan);
              if (!educationOption) {
                return true;
              }
              return planStudyRightService.isEducationOptionIncludedInStudyRightAcceptedSelectionPath(educationOption, studyRight);
            });
          }
          if (rulesToBeSelected.length === 1) {
            return rulesToBeSelected;
          }
        }
        return [];
      },
      // isImplicitCourseUnitObligatory() is intended for determining whether implicitly selected courseUnit is
      // considered obligatory, which means that moving the courseUnit explicitly to parentModule is allowed

      isImplicitCourseUnitObligatory: (courseUnit, parentModule) => {
        const rootRule = _.get(parentModule, 'rule');
        const allRules = ruleService.allRules(rootRule);
        const courseUnitRule = _.find(allRules, {
          type: 'CourseUnitRule',
          courseUnitGroupId: courseUnit.groupId
        });
        if (!courseUnitRule) {
          return false;
        }
        return ruleService.isImplicitCourseUnitRuleObligatory(rootRule, courseUnitRule);
      },
      canCourseUnitBeReplaced: function (courseUnit, parentModule, validatablePlan) {
        if (parentModule.type === 'CustomModuleAttainment' || validatablePlan.isModuleAttained(parentModule)) {
          return false;
        }
        var matchingCourseUnitRule = findMatchingCourseUnitRule(parentModule.rule, courseUnit.groupId);
        if (!matchingCourseUnitRule) {
          return true;
        }
        var rulePath = ruleService.getRulePathFromRootToSubRule(parentModule.rule, matchingCourseUnitRule);
        var nearestCompositeRule = _.last(_.filter(rulePath, {
          type: 'CompositeRule'
        }));
        if (!nearestCompositeRule) {
          return true;
        }
        return nearestCompositeRule.allMandatory;
      },
      canModuleBeReplaced: function (module, parentModule, validatablePlan) {
        if (parentModule.type === 'CustomModuleAttainment' || validatablePlan.isModuleAttained(parentModule)) {
          return false;
        }
        var matchingModuleRule = findMatchingModuleRule(parentModule.rule, module.groupId);
        if (!matchingModuleRule) {
          return true;
        }
        var rulePath = ruleService.getRulePathFromRootToSubRule(parentModule.rule, matchingModuleRule);
        var nearestCompositeRule = _.last(_.filter(rulePath, {
          type: 'CompositeRule'
        }));
        if (!nearestCompositeRule) {
          return true;
        }
        return nearestCompositeRule.allMandatory;
      },
      deActivateRule: function (parentModule, rule, ruleToBeDeactivated, plan, validatablePlan) {
        if (!_.has(ruleToBeDeactivated, 'type')) {
          return;
        }
        if (ruleToBeDeactivated.type === 'CourseUnitRule') {
          var plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(ruleToBeDeactivated.courseUnitGroupId);
          if (plannedCourseUnit) {
            api.removeCourseUnit(plannedCourseUnit, parentModule, plan, validatablePlan);
          }
        } else if (ruleToBeDeactivated.type === 'ModuleRule') {
          var plannedModule = validatablePlan.getModuleInPlanByGroupId(ruleToBeDeactivated.moduleGroupId);
          if (plannedModule) {
            api.removeModuleRecursively(plannedModule, parentModule, plan, validatablePlan);
          }
        } else if (ruleToBeDeactivated.type === 'CompositeRule') {
          api.deActivateCompositeRule(parentModule, rule, ruleToBeDeactivated, plan, validatablePlan);
        } else {
          if (_.has(ruleToBeDeactivated, 'rule')) {
            api.deActivateRule(parentModule, rule, _.get(ruleToBeDeactivated, 'rule'), plan, validatablePlan);
          }
        }
      },
      deActivateCompositeRule: function (rootModule, rule, ruleToBeDeactivated, plan, validatablePlan) {
        var matchingStudiesByRule = ruleService.getMatchingStudiesByRule(rule, rootModule, validatablePlan);
        var allStudies = _.get(matchingStudiesByRule, rule.localId);
        var studiesMatchingTargetRule = _.get(matchingStudiesByRule, ruleToBeDeactivated.localId);
        if (!allStudies || !studiesMatchingTargetRule) {
          return;
        }
        _.forEach(allStudies.modules, function (childModule) {
          if (_.includes(_.map(studiesMatchingTargetRule.modules, 'id'), childModule.id)) {
            api.removeModuleRecursively(childModule, rootModule, plan, validatablePlan);
          }
        });
        _.forEach(allStudies.courseUnits, function (courseUnit) {
          if (_.includes(_.map(studiesMatchingTargetRule.courseUnits, 'id'), courseUnit.id)) {
            api.removeCourseUnit(courseUnit, rootModule, plan, validatablePlan);
          }
        });
        _.forEach(allStudies.customModuleAttainments, function (customModuleAttainment) {
          if (_.includes(_.map(studiesMatchingTargetRule.customModuleAttainments, 'id'), customModuleAttainment.id)) {
            _.remove(plan.customModuleAttainmentSelections, {
              customModuleAttainmentId: customModuleAttainment.id,
              parentModuleId: rootModule.id
            });
          }
        });
        _.forEach(allStudies.customCourseUnitAttainments, function (customCourseUnitAttainment) {
          if (_.includes(_.map(studiesMatchingTargetRule.customCourseUnitAttainments, 'id'), customCourseUnitAttainment.id)) {
            _.remove(plan.customCourseUnitAttainmentSelections, {
              customCourseUnitAttainmentId: customCourseUnitAttainment.id,
              parentModuleId: rootModule.id
            });
          }
        });
      },
      activateRule: function (rule, parentModule, rawPlan, validatablePlan) {
        var groupIdsToBeSelected = api.getGroupIdsToBeSelected(rule);
        return api.selectStudiesByGroupId(groupIdsToBeSelected.courseUnitGroupIds, groupIdsToBeSelected.moduleGroupIds, parentModule, rawPlan, validatablePlan);
      },
      selectStudiesByGroupId: function (courseUnitGroupIds, moduleGroupIds, parentModule, rawPlan, validatablePlan) {
        var courseUnitGroupIdsNotInPlan = _.filter(_.uniq(courseUnitGroupIds), function (courseUnitGroupId) {
          var plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitGroupId);
          if (plannedCourseUnit && (validatablePlan.isCourseUnitInPlan(plannedCourseUnit) || validatablePlan.isCourseUnitInPlanAsSubstitute(plannedCourseUnit))) {
            return false;
          }
          return true;
        });
        var moduleGroupIdsNotInPlan = _.filter(_.uniq(moduleGroupIds), function (moduleGroupId) {
          var plannedModule = validatablePlan.getModuleInPlanByGroupId(moduleGroupId);
          if (plannedModule && validatablePlan.isModuleInPlan(plannedModule.id)) {
            return false;
          }
          return true;
        });
        var promises = [];
        var selectedCourseUnits = [];
        var selectedModules = [];
        var parentCurriculumPeriodIds = api.getParentCurriculumPeriodIdsRecursively(parentModule, rawPlan, validatablePlan);
        _.forEach(courseUnitGroupIdsNotInPlan, function (courseUnitGroupId) {
          var promise = api.addCourseUnitToPlanByGroupId(courseUnitGroupId, parentModule, rawPlan, validatablePlan, parentCurriculumPeriodIds).then(function (courseUnit) {
            if (courseUnit) {
              selectedCourseUnits.push(courseUnit);
            }
          });
          promises.push(promise);
        });
        _.forEach(moduleGroupIdsNotInPlan, function (moduleGroupId) {
          var promise = api.addModuleToPlanByGroupId(moduleGroupId, parentModule, rawPlan, validatablePlan, parentCurriculumPeriodIds).then(function (module) {
            if (module) {
              selectedModules.push(module);
            }
          });
          promises.push(promise);
        });
        return $q.all(promises).then(function () {
          return {
            courseUnits: selectedCourseUnits,
            modules: selectedModules
          };
        });
      },
      getParentCurriculumPeriodIdsRecursively: function (parentModule, rawPlan, validatablePlan) {
        var curriculumPeriodIds = _.get(parentModule, 'curriculumPeriodIds', []);
        if (curriculumPeriodIds.length > 0) {
          return curriculumPeriodIds;
        } else {
          var selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(parentModule);
          if (selectedParent && selectedParent.type !== 'CustomModuleAttainment') {
            return api.getParentCurriculumPeriodIdsRecursively(selectedParent, rawPlan, validatablePlan);
          } else {
            return [rawPlan.curriculumPeriodId];
          }
        }
      },
      getModuleOfGroup: function (groupId, parentCurriculumPeriodIds, fallBackCurriculumPeriodId) {
        if (previewMode.isPreviewMode()) {
          return commonModuleService.findAllVersions(groupId).then(function (modules) {
            return studySelectorService.selectStudyForPlan(modules, parentCurriculumPeriodIds, fallBackCurriculumPeriodId);
          });
        }
        return commonModuleService.findAllVersionsForStudent(groupId).then(function (modules) {
          return studySelectorService.selectStudyForPlan(modules, parentCurriculumPeriodIds, fallBackCurriculumPeriodId);
        });
      },
      getCourseUnitOfGroup: function (groupId, parentCurriculumPeriodIds, fallBackCurriculumPeriodId) {
        if (previewMode.isPreviewMode()) {
          return commonCourseUnitService.findAllVersionsForStaff(groupId).then(function (courseUnits) {
            return studySelectorService.selectStudyForPlan(courseUnits, parentCurriculumPeriodIds, fallBackCurriculumPeriodId);
          });
        }
        return commonCourseUnitService.findAllVersionsForStudent(groupId).then(function (courseUnits) {
          return studySelectorService.selectStudyForPlan(courseUnits, parentCurriculumPeriodIds, fallBackCurriculumPeriodId);
        });
      },
      getModuleById: function (moduleId, validatablePlan) {
        return _.get(validatablePlan.modulesById, moduleId);
      },
      getCourseUnitById: function (courseUnitId, validatablePlan) {
        return _.get(validatablePlan.courseUnitsById, courseUnitId);
      },
      getGroupIdsToBeSelected: function (rule) {
        var courseUnitGroupIds = [];
        var moduleGroupIds = [];
        recursivelyGetGroupIdsToBeSelected(rule, courseUnitGroupIds, moduleGroupIds);
        return {
          courseUnitGroupIds: _.uniq(courseUnitGroupIds),
          moduleGroupIds: _.uniq(moduleGroupIds)
        };
      },
      addModuleSelection: function (moduleId, parentModuleId, rawPlan) {
        if (!_.has(rawPlan, 'moduleSelections')) {
          rawPlan.moduleSelections = [];
        }
        rawPlan.moduleSelections.push({
          parentModuleId: parentModuleId,
          moduleId: moduleId
        });
      },
      addCourseUnitSelection: function (courseUnitId, parentModuleId, rawPlan) {
        if (!_.has(rawPlan, 'courseUnitSelections')) {
          rawPlan.courseUnitSelections = [];
        }
        rawPlan.courseUnitSelections.push({
          parentModuleId: parentModuleId,
          courseUnitId: courseUnitId
        });
      },
      // TODO: handling of substitutes when removing courseUnit

      removeCourseUnit: function (courseUnit, parentModule, rawPlan, validatablePlan) {
        /*
         * This function is meant to be used in the process of deactivating a rule. Therefore
         * the parentModule argument is necessary because the courseUnit can be selected elsewhere in plan
         * and implicitly selected according to the rules of the given parentModule. In this case
         * the courseUnit should not be removed from plan when deactivating the rule. Also assessmentItemSelections
         * should be left as they are.
         */

        if (validatablePlan.isSelectedParentOfCourseUnit(parentModule, courseUnit)) {
          _.remove(rawPlan.assessmentItemSelections, {
            courseUnitId: courseUnit.id
          });
          _.remove(rawPlan.courseUnitSelections, {
            courseUnitId: courseUnit.id
          });
        }
      },
      removeModuleRecursively: function (module, parentModule, rawPlan, validatablePlan) {
        /*
         * This function is meant to be used in the process of deactivating a rule. Therefore
         * the parentModule is necessary here because the module can be selected elsewhere in plan
         * and implicitly selected according to the rules of the given parentModule. in this case
         * the courseUnit should not be removed from plan when deactivating the rule. Also all selections
         * made under the module should be left as they are.
         */

        if (!validatablePlan.isSelectedParentOfModule(parentModule, module)) {
          return;
        }
        var childModuleSelections = _.filter(rawPlan.moduleSelections, {
          parentModuleId: module.id
        });
        _.forEach(childModuleSelections, function (moduleSelection) {
          var childModule = api.getModuleById(moduleSelection.moduleId, validatablePlan);
          if (childModule) {
            api.removeModuleRecursively(childModule, module, rawPlan, validatablePlan);
          }
        });
        var childCourseUnitSelections = _.filter(rawPlan.courseUnitSelections, {
          parentModuleId: module.id
        });
        _.forEach(childCourseUnitSelections, function (courseUnitSelection) {
          var childCourseUnit = api.getCourseUnitById(courseUnitSelection.courseUnitId, validatablePlan);
          if (childCourseUnit) {
            api.removeCourseUnit(childCourseUnit, module, rawPlan, validatablePlan);
          }
        });
        var childCustomModuleAttainmentIds = _.map(_.filter(rawPlan.customModuleAttainmentSelections, {
          parentModuleId: module.id
        }), 'customModuleAttainmentId');
        _.forEach(childCustomModuleAttainmentIds, function (childCustomModuleAttainmentId) {
          _.remove(rawPlan.customModuleAttainmentSelections, {
            customModuleAttainmentId: childCustomModuleAttainmentId,
            parentModuleId: module.id
          });
        });
        var childCustomCourseUnitAttainmentIds = _.map(_.filter(rawPlan.customCourseUnitAttainmentSelections, {
          parentModuleId: module.id
        }), 'customCourseUnitAttainmentId');
        _.forEach(childCustomCourseUnitAttainmentIds, function (childCustomCourseUnitAttainmentId) {
          _.remove(rawPlan.customCourseUnitAttainmentSelections, {
            customCourseUnitAttainmentId: childCustomCourseUnitAttainmentId,
            parentModuleId: module.id
          });
        });
        _.remove(rawPlan.moduleSelections, {
          moduleId: module.id,
          parentModuleId: parentModule.id
        });
      },
      addCourseUnitToPlanByGroupId: function (courseUnitGroupId, parentModule, rawPlan, validatablePlan, parentCurriculumPeriodIds) {
        var courseUnitAttainment = validatablePlan.getCourseUnitAttainmentByGroupId(courseUnitGroupId);
        if (courseUnitAttainment) {
          // if an attained version of the courseUnit exists select that version to the plan
          addCourseUnitSelection(courseUnitAttainment.courseUnitId, parentModule.id, rawPlan);
          // returning null means that the selected courseUnit does not require any further automatic selections
          return $q.when(null);
        }
        return api.getCourseUnitOfGroup(courseUnitGroupId, parentCurriculumPeriodIds, rawPlan.curriculumPeriodId).then(courseUnit => {
          if (courseUnit) {
            addCourseUnitSelection(courseUnit.id, parentModule.id, rawPlan);
            return courseUnit;
          }
          return null;
        });
      },
      addModuleToPlanByGroupId: function (moduleGroupId, parentModule, rawPlan, validatablePlan, parentCurriculumPeriodIds) {
        var moduleAttainment = validatablePlan.getModuleAttainmentByGroupId(moduleGroupId);
        // if an attained version of the module exists select that version to the plan
        if (moduleAttainment) {
          addModuleSelection(moduleAttainment.moduleId, parentModule.id, rawPlan);
          // returning null means that the selected module does not require any further automatic selections
          return $q.when(null);
        }
        return api.getModuleOfGroup(moduleGroupId, parentCurriculumPeriodIds, rawPlan.curriculumPeriodId).then(module => {
          if (module) {
            addModuleSelection(module.id, parentModule.id, rawPlan);
            return module;
          }
          return null;
        });
      },
      selectModule: function (module, parentModule, rawPlan, validatablePlan, studyRight) {
        if (!doesParentModuleAcceptSelections(parentModule.id, validatablePlan)) {
          return $q.when(null);
        }
        var plannedModule = validatablePlan.getModuleInPlanByGroupId(module.groupId);
        if (plannedModule) {
          var parentModuleOfPlannedModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(plannedModule);
          if (parentModuleOfPlannedModule && validatablePlan.isModuleInPlanUnderModule(parentModule, plannedModule)) {
            return $q.when(null);
          }
          if (parentModuleOfPlannedModule && api.canModuleBeReplaced(plannedModule, parentModuleOfPlannedModule, validatablePlan)) {
            api.removeModuleRecursively(plannedModule, parentModuleOfPlannedModule, rawPlan, validatablePlan);
          } else {
            return $q.when(null);
          }
        }
        addModuleSelection(module.id, parentModule.id, rawPlan);
        var courseUnitSelectionsRequiringAutomaticSelections = [];
        var moduleSelectionsRequiringAutomaticSelections = [];
        if (!validatablePlan.isModuleAttained(module.id)) {
          moduleSelectionsRequiringAutomaticSelections.push(module);
        }
        const parentRule = resolveRule(parentModule, rawPlan, studyRight);
        var activatedRule = findMatchingModuleRule(parentRule, module.groupId);
        if (activatedRule) {
          var rulePath = ruleService.getRulePathFromRootToSubRule(parentRule, activatedRule);
          _.forEach(rulePath, function (rule) {
            if (rule.type === 'CompositeRule' && ruleService.isSelectOneRule(rule)) {
              var rulesToBeDeactivated = _.filter(rule.rules, function (childRule) {
                return !_.includes(rulePath, childRule);
              });
              _.forEach(rulesToBeDeactivated, function (ruleToBeDeactivated) {
                api.deActivateRule(parentModule, parentRule, ruleToBeDeactivated, rawPlan, validatablePlan);
              });
            }
          });
        }
        return commonPlanService.getValidatablePlan(rawPlan, previewMode.isPreviewMode(), true).then(function (updatedValidatablePlan) {
          validatablePlan = updatedValidatablePlan;
          var courseUnitGroupIdsToBeSelected = [];
          var moduleGroupIdsToBeSelected = [];
          _.forEach(rulePath, function (rule) {
            if (rule.type === 'CompositeRule' && ruleService.isSelectAllRule(rule)) {
              _.forEach(rule.rules, function (subRule) {
                if (!_.includes(rulePath, subRule)) {
                  var studiesToBeSelected = api.getGroupIdsToBeSelected(subRule);
                  courseUnitGroupIdsToBeSelected = _.concat(courseUnitGroupIdsToBeSelected, studiesToBeSelected.courseUnitGroupIds);
                  moduleGroupIdsToBeSelected = _.concat(moduleGroupIdsToBeSelected, studiesToBeSelected.moduleGroupIds);
                }
              });
            }
          });
          return api.selectStudiesByGroupId(courseUnitGroupIdsToBeSelected, moduleGroupIdsToBeSelected, parentModule, rawPlan, validatablePlan).then(function (studiesRequiringAutomaticSelections) {
            moduleSelectionsRequiringAutomaticSelections = _.concat(moduleSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.modules);
            courseUnitSelectionsRequiringAutomaticSelections = _.concat(courseUnitSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.courseUnits);
            return api.makeAutomaticSelectionsForModules(rawPlan, validatablePlan, moduleSelectionsRequiringAutomaticSelections, courseUnitSelectionsRequiringAutomaticSelections, studyRight).then(function () {
              api.makeAutomaticSelectionsForCourseUnits(rawPlan, courseUnitSelectionsRequiringAutomaticSelections);
              return rawPlan;
            });
          });
        });
      },
      unselectModule: function (module, rawPlan, validatablePlan) {
        var parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        if (!parentModule || validatablePlan.isModuleAttained(parentModule.id) || parentModule.type === 'CustomModuleAttainment') {
          return null;
        }
        var deActivatedRule = findMatchingModuleRule(parentModule.rule, module.groupId);
        if (deActivatedRule) {
          if (ruleService.isRuleObligatory(parentModule.rule, deActivatedRule)) {
            return null;
          }
          var parentRule = ruleService.getParentRule(parentModule.rule, deActivatedRule);
          if (parentRule && ruleService.isSelectAllRule(parentRule)) {
            api.deActivateRule(parentModule, parentModule.rule, parentRule, rawPlan, validatablePlan);
          } else {
            api.deActivateRule(parentModule, parentModule.rule, deActivatedRule, rawPlan, validatablePlan);
          }
        } else {
          api.removeModuleRecursively(module, parentModule, rawPlan, validatablePlan);
        }
        return rawPlan;
      },
      forceRemoveModule: function (module, parentModule, rawPlan, validatablePlan) {
        api.removeModuleRecursively(module, parentModule, rawPlan, validatablePlan);
        return rawPlan;
      },
      selectCourseUnit: function (courseUnit, parentModule, rawPlan, validatablePlan) {
        if (!doesParentModuleAcceptSelections(parentModule.id, validatablePlan)) {
          return $q.when(null);
        }
        var plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnit.groupId);
        if (plannedCourseUnit) {
          if (validatablePlan.isCourseUnitInPlanAsSubstitute(plannedCourseUnit)) {
            return $q.when(null);
          }
          var parentModuleOfPlannedCourseUnit = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(plannedCourseUnit);
          if (parentModuleOfPlannedCourseUnit && api.canCourseUnitBeReplaced(plannedCourseUnit, parentModuleOfPlannedCourseUnit, validatablePlan)) {
            api.removeCourseUnit(plannedCourseUnit, parentModuleOfPlannedCourseUnit, rawPlan, validatablePlan);
          } else {
            return $q.when(null);
          }
        }
        addCourseUnitSelection(courseUnit.id, parentModule.id, rawPlan);
        var courseUnitSelectionsRequiringAutomaticSelections = [];
        var moduleSelectionsRequiringAutomaticSelections = [];
        if (!validatablePlan.isCourseUnitAttained(courseUnit.id)) {
          courseUnitSelectionsRequiringAutomaticSelections.push(courseUnit);
        }
        var activatedRule = findMatchingCourseUnitRule(parentModule.rule, courseUnit.groupId);
        if (activatedRule) {
          var rulePath = ruleService.getRulePathFromRootToSubRule(parentModule.rule, activatedRule);
          _.forEach(rulePath, function (rule) {
            if (rule.type === 'CompositeRule' && ruleService.isSelectOneRule(rule)) {
              var rulesToBeDeactivated = _.filter(rule.rules, function (childRule) {
                return !_.includes(rulePath, childRule);
              });
              _.forEach(rulesToBeDeactivated, function (ruleToBeDeactivated) {
                api.deActivateRule(parentModule, parentModule.rule, ruleToBeDeactivated, rawPlan, validatablePlan);
              });
            }
          });
        }
        return commonPlanService.getValidatablePlan(rawPlan, previewMode.isPreviewMode(), true).then(function (updatedValidatablePlan) {
          validatablePlan = updatedValidatablePlan;
          var courseUnitGroupIdsToBeSelected = [];
          var moduleGroupIdsToBeSelected = [];
          _.forEach(rulePath, function (rule) {
            if (rule.type === 'CompositeRule' && ruleService.isSelectAllRule(rule)) {
              _.forEach(rule.rules, function (subRule) {
                if (!_.includes(rulePath, subRule)) {
                  var studiesToBeSelected = api.getGroupIdsToBeSelected(subRule);
                  courseUnitGroupIdsToBeSelected = _.concat(courseUnitGroupIdsToBeSelected, studiesToBeSelected.courseUnitGroupIds);
                  moduleGroupIdsToBeSelected = _.concat(moduleGroupIdsToBeSelected, studiesToBeSelected.moduleGroupIds);
                }
              });
            }
          });
          return api.selectStudiesByGroupId(courseUnitGroupIdsToBeSelected, moduleGroupIdsToBeSelected, parentModule, rawPlan, validatablePlan).then(function (studiesRequiringAutomaticSelections) {
            moduleSelectionsRequiringAutomaticSelections = _.concat(moduleSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.modules);
            courseUnitSelectionsRequiringAutomaticSelections = _.concat(courseUnitSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.courseUnits);
            return api.makeAutomaticSelectionsForModules(rawPlan, validatablePlan, moduleSelectionsRequiringAutomaticSelections, courseUnitSelectionsRequiringAutomaticSelections).then(function () {
              api.makeAutomaticSelectionsForCourseUnits(rawPlan, courseUnitSelectionsRequiringAutomaticSelections);
              return rawPlan;
            });
          });
        });
      },
      selectCourseUnitByGroupId: function (courseUnitGroupId, parentModule, rawPlan, validatablePlan) {
        if (!doesParentModuleAcceptSelections(_.get(parentModule, 'id'), validatablePlan)) {
          return $q.when(null);
        }
        var courseUnitInPlan = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitGroupId);
        if (courseUnitInPlan) {
          return $q.when(null);
        }
        var courseUnitAttainment = validatablePlan.getCourseUnitAttainmentByGroupId(courseUnitGroupId);
        if (courseUnitAttainment) {
          if (!isAttainmentAttached(courseUnitAttainment, validatablePlan)) {
            addCourseUnitSelection(courseUnitAttainment.courseUnitId, parentModule.id, rawPlan);
            return $q.when(rawPlan);
          } else {
            return $q.when(null);
          }
        }
        var parentCurriculumPeriodIds = api.getParentCurriculumPeriodIdsRecursively(parentModule, rawPlan, validatablePlan);
        return api.getCourseUnitOfGroup(courseUnitGroupId, parentCurriculumPeriodIds, rawPlan.curriculumPeriodId).then(versionToBeSelected => {
          if (versionToBeSelected) {
            addCourseUnitSelection(versionToBeSelected.id, parentModule.id, rawPlan);
            api.makeAutomaticSelectionsForCourseUnits(rawPlan, [versionToBeSelected]);
            return rawPlan;
          }
          return null;
        });
      },
      removeGradeRaiseAttempt(courseUnitId, rawPlan) {
        const courseUnitSelection = _.find(rawPlan.courseUnitSelections, {
          courseUnitId
        });
        if (courseUnitSelection === null) return null;
        courseUnitSelection.completionMethodId = null;
        courseUnitSelection.gradeRaiseAttempt = null;
        _.remove(rawPlan.assessmentItemSelections, {
          courseUnitId
        });
        return rawPlan;
      },
      selectCompletionMethodWithAssessmentItems(courseUnitId, completionMethod, assessmentItemIds, rawPlan, gradeRaiseAttempt) {
        const courseUnitSelection = _.find(rawPlan.courseUnitSelections, {
          courseUnitId
        });
        if (!courseUnitSelection) return null;
        if (gradeRaiseAttempt !== null) courseUnitSelection.gradeRaiseAttempt = gradeRaiseAttempt;
        courseUnitSelection.completionMethodId = completionMethod.localId;
        _.remove(rawPlan.assessmentItemSelections, {
          courseUnitId
        });
        if (completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
          if (!rawPlan.assessmentItemSelections) {
            rawPlan.assessmentItemSelections = [];
          }
          _.forEach(completionMethod.assessmentItemIds, assessmentItemId => {
            addAssessmentItemSelection(assessmentItemId, courseUnitId, rawPlan);
          });
        } else if (assessmentItemIds) {
          if (!rawPlan.assessmentItemSelections) {
            rawPlan.assessmentItemSelections = [];
          }
          _.forEach(assessmentItemIds, assessmentItemId => {
            addAssessmentItemSelection(assessmentItemId, courseUnitId, rawPlan);
          });
        }
        return rawPlan;
      },
      selectModuleByGroupId: function (moduleGroupId, parentModule, rawPlan, validatablePlan, studyRight) {
        if (!doesParentModuleAcceptSelections(_.get(parentModule, 'id'), validatablePlan)) {
          return $q.when(null);
        }
        var moduleInPlan = validatablePlan.getModuleInPlanByGroupId(moduleGroupId);
        if (moduleInPlan) {
          return $q.when(null);
        }
        var moduleAttainment = validatablePlan.getModuleAttainmentByGroupId(moduleGroupId);
        if (moduleAttainment) {
          if (!isAttainmentAttached(moduleAttainment, validatablePlan)) {
            addModuleSelection(moduleAttainment.moduleId, parentModule.id, rawPlan);
            return $q.when(rawPlan);
          } else {
            return $q.when(null);
          }
        }
        var parentCurriculumPeriodIds = api.getParentCurriculumPeriodIdsRecursively(parentModule, rawPlan, validatablePlan);
        return api.getModuleOfGroup(moduleGroupId, parentCurriculumPeriodIds, rawPlan.curriculumPeriodId).then(versionToBeSelected => {
          if (versionToBeSelected) {
            addModuleSelection(versionToBeSelected.id, parentModule.id, rawPlan);
            var modulesRequiringAutomaticSelections = [versionToBeSelected];
            var courseUnitsRequiringAutomaticSelections = [];
            return api.makeAutomaticSelectionsForModules(rawPlan, validatablePlan, modulesRequiringAutomaticSelections, courseUnitsRequiringAutomaticSelections, studyRight).then(function () {
              api.makeAutomaticSelectionsForCourseUnits(rawPlan, courseUnitsRequiringAutomaticSelections);
              return rawPlan;
            });
          }
          return null;
        });
      },
      // moveCourseUnit() function is intended for moving a courseUnit that is implicitly
      // selected in targetModule there explicitly. in this case timing and completionMethod
      // selections are preserved as they were. this function does not check whether the operation
      // is allowed, this has to be checked separately calling

      moveCourseUnit: (courseUnit, targetModule, validatablePlan) => {
        const rawPlan = _.get(validatablePlan, 'plan');
        const courseUnitSelection = _.find(rawPlan.courseUnitSelections, {
          courseUnitId: courseUnit.id
        });
        if (!courseUnitSelection) {
          return null;
        }
        const newParentModuleId = _.get(targetModule, 'id');
        if (!newParentModuleId) {
          return null;
        }
        courseUnitSelection.parentModuleId = newParentModuleId;
        return rawPlan;
      },
      unselectCourseUnit: function (courseUnit, parentModule, rawPlan, validatablePlan) {
        var parentModuleInPlan = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        if (!parentModuleInPlan || parentModule.id !== parentModuleInPlan.id || validatablePlan.isModuleAttained(parentModuleInPlan) || parentModuleInPlan.type === 'CustomModuleAttainment') {
          return null;
        }
        var deActivatedRule = findMatchingCourseUnitRule(parentModule.rule, courseUnit.groupId);
        if (deActivatedRule) {
          if (ruleService.isRuleObligatory(parentModule.rule, deActivatedRule)) {
            return null;
          }
          var parentRule = ruleService.getParentRule(parentModule.rule, deActivatedRule);
          if (parentRule && ruleService.isSelectAllRule(parentRule)) {
            api.deActivateRule(parentModule, parentModule.rule, parentRule, rawPlan, validatablePlan);
          } else {
            api.deActivateRule(parentModule, parentModule.rule, deActivatedRule, rawPlan, validatablePlan);
          }
        } else {
          api.removeCourseUnit(courseUnit, parentModule, rawPlan, validatablePlan);
        }
        return rawPlan;
      },
      makeAutomaticSelectionsForModules: function (rawPlan, validatablePlan, modulesRequiringAutomaticSelections, courseUnitsRequiringAutomaticSelections, studyRight) {
        var unhandledModules = _.cloneDeep(modulesRequiringAutomaticSelections);
        var currentValidatablePlan = validatablePlan;
        if (unhandledModules.length === 0) {
          return $q.when(validatablePlan);
        }
        var modulesWaiting = [];
        var deferredArray = getDeferredArray(unhandledModules.length);
        _.forEach(unhandledModules, function (parentModule, index) {
          var parentCurriculumPeriodIds = api.getParentCurriculumPeriodIdsRecursively(parentModule, rawPlan, currentValidatablePlan);
          if (index === 0) {
            const rule = resolveRule(parentModule, rawPlan, studyRight);
            api.makeAutomaticSelectionsForRule(parentModule, rule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(function (newValidatablePlan) {
              currentValidatablePlan = newValidatablePlan;
              deferredArray[index].resolve();
            });
          } else {
            deferredArray[index - 1].promise.then(function () {
              api.makeAutomaticSelectionsForRule(parentModule, parentModule.rule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(function (newValidatablePlan) {
                currentValidatablePlan = newValidatablePlan;
                deferredArray[index].resolve();
              });
            });
          }
        });
        return $q.all(_.map(deferredArray, 'promise')).then(function () {
          return api.makeAutomaticSelectionsForModules(rawPlan, currentValidatablePlan, modulesWaiting, courseUnitsRequiringAutomaticSelections, studyRight).then(function (newValidatablePlan) {
            return newValidatablePlan;
          });
        });
      },
      makeAutomaticSelectionsForRule: function (parentModule, rule, rawPlan, validatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight) {
        var currentValidatablePlan = validatablePlan;
        if (rule.type === 'CourseUnitRule' && !validatablePlan.getCourseUnitInPlanByGroupId(rule.courseUnitGroupId)) {
          return api.addCourseUnitToPlanByGroupId(rule.courseUnitGroupId, parentModule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds).then(function (courseUnit) {
            if (courseUnit) {
              courseUnitsRequiringAutomaticSelections.push(courseUnit);
              return commonPlanService.getValidatablePlan(rawPlan, previewMode.isPreviewMode(), true).then(function (updatedValidatablePlan) {
                return updatedValidatablePlan;
              });
            }
            return $q.when(currentValidatablePlan);
          });
        }
        if (rule.type === 'ModuleRule' && !validatablePlan.getModuleInPlanByGroupId(rule.moduleGroupId)) {
          return api.addModuleToPlanByGroupId(rule.moduleGroupId, parentModule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds).then(function (module) {
            if (module) {
              modulesWaiting.push(module);
              return commonPlanService.getValidatablePlan(rawPlan, previewMode.isPreviewMode(), true).then(function (updatedValidatablePlan) {
                return updatedValidatablePlan;
              });
            }
            return $q.when(currentValidatablePlan);
          });
        }
        if (rule.type === 'CompositeRule') {
          var rulesToBeSelected = api.getRulesToBeActivated(rule, parentModule, currentValidatablePlan, studyRight);
          if (rulesToBeSelected.length === 0) {
            return $q.when(currentValidatablePlan);
          }
          var deferredArray = getDeferredArray(rulesToBeSelected.length);
          _.forEach(rulesToBeSelected, function (subRule, index) {
            if (index === 0) {
              api.makeAutomaticSelectionsForRule(parentModule, subRule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(function (updatedValidatablePlan) {
                currentValidatablePlan = updatedValidatablePlan;
                deferredArray[index].resolve();
              });
            } else {
              deferredArray[index - 1].promise.then(function () {
                api.makeAutomaticSelectionsForRule(parentModule, subRule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(function (updatedValidatablePlan) {
                  currentValidatablePlan = updatedValidatablePlan;
                  deferredArray[index].resolve();
                });
              });
            }
          });
          return $q.all(_.map(deferredArray, 'promise')).then(function () {
            return currentValidatablePlan;
          });
        }
        if (rule.type === 'CreditsRule') {
          const childRule = _.get(rule, 'rule');
          if (_.get(rule, 'credits.min') > 0 && _.get(childRule, 'type') === 'CompositeRule' && childRule.rules.length === 1) {
            return api.makeAutomaticSelectionsForRule(parentModule, childRule.rules[0], rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(updatedValidatablePlan => {
              return updatedValidatablePlan;
            });
          }
          return api.makeAutomaticSelectionsForRule(parentModule, childRule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(updatedValidatablePlan => {
            return updatedValidatablePlan;
          });
        }
        if (_.has(rule, 'rule')) {
          return api.makeAutomaticSelectionsForRule(parentModule, rule.rule, rawPlan, currentValidatablePlan, parentCurriculumPeriodIds, courseUnitsRequiringAutomaticSelections, modulesWaiting, studyRight).then(updatedValidatablePlan => {
            return updatedValidatablePlan;
          });
        }
        return $q.when(currentValidatablePlan);
      },
      // TODO: handle duplicate assessmentItemIds

      selectCompletionMethod: (courseUnit, completionMethod, rawPlan) => {
        const courseUnitSelection = _.find(rawPlan.courseUnitSelections, {
          courseUnitId: courseUnit.id
        });
        if (courseUnitSelection) {
          courseUnitSelection.completionMethodId = completionMethod.localId;
          if (completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
            if (_.has(rawPlan, 'assessmentItemSelections')) {
              _.remove(rawPlan.assessmentItemSelections, {
                courseUnitId: courseUnit.id
              });
            }
            _.forEach(completionMethod.assessmentItemIds, assessmentItemId => {
              removeAssessmentItemSelection(assessmentItemId, rawPlan);
              addAssessmentItemSelection(assessmentItemId, courseUnit.id, rawPlan);
            });
          }
        }
      },
      makeAutomaticSelectionsForCourseUnits: (rawPlan, courseUnits) => {
        _.forEach(courseUnits, courseUnit => {
          const completionMethods = _.get(courseUnit, 'completionMethods') || [];
          const degreeStudyCompletionMethods = _.filter(completionMethods, cm => cm.studyType === 'DEGREE_STUDIES');
          const selectableCompletionMethod = _.size(degreeStudyCompletionMethods) === 1 ? _.first(degreeStudyCompletionMethods) : null;
          if (selectableCompletionMethod && selectableCompletionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
            api.selectCompletionMethod(courseUnit, selectableCompletionMethod, rawPlan);
          }
        });
      },
      makeAutomaticSelectionsForNewPlan: function (rawPlan, isPreviewMode, studyRight) {
        rawPlan.moduleSelections = [{
          moduleId: rawPlan.rootId
        }];
        return commonPlanService.getValidatablePlan(rawPlan, isPreviewMode, true).then(function (validatablePlan) {
          var modulesRequiringAutomaticSelections = [validatablePlan.rootModule];
          var courseUnitsRequiringAutomaticSelections = [];
          return api.makeAutomaticSelectionsForModules(rawPlan, validatablePlan, modulesRequiringAutomaticSelections, courseUnitsRequiringAutomaticSelections, studyRight).then(function () {
            api.makeAutomaticSelectionsForCourseUnits(rawPlan, courseUnitsRequiringAutomaticSelections);
            return rawPlan;
          });
        });
      }
    };
    return api;
  }
})();