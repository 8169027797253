<ng-container *transloco="let t">
  <div class="container">
    <div class="row title-container sis-pb-xs sis-pt-md">
      <div class="col-12">
        <h1 sisAutofocus id="skip-link-target" tabindex="-1" class="fake-crumbs">
          <ng-container *ngIf="courseUnitRealisation || application || plan || courseUnit">
            <ng-container *ngIf="application && firstMessage.conversationId === application.id">
              <span>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.CONVERSATION_TITLE_PREFIX_APPLICATION') }}</span>:
              {{ application.code }}
            </ng-container>
            <ng-container *ngIf="plan && firstMessage.conversationId === plan.id">
              <span>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.CONVERSATION_TITLE_PREFIX_PLAN') }}</span>:
              {{ plan.name }}
            </ng-container>
            <ng-container
              *ngIf="courseUnitRealisation && firstMessage.conversationId === courseUnitRealisation.id">
              <span>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.CONVERSATION_TITLE_PREFIX_COURSE_UNIT_REALISATION') }}</span>:
              {{ courseUnitRealisation.nameSpecifier | localizedString }},
              {{ courseUnitRealisation.name | localizedString}}
              {{ courseUnitRealisation.activityPeriod | localDateRange }}
            </ng-container>
            <ng-container *ngIf="courseUnit && firstMessage.conversationId === courseUnit.id">
              <span>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.CONVERSATION_TITLE_PREFIX_COURSE_UNIT') }}</span>:
              {{ courseUnit.code }}
              {{ courseUnit.name | localizedString }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!courseUnitRealisation && !application && !plan">
            <span
              *ngIf="allMessages && allMessages.length > 1">{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.TITLE') }}</span>
            <span *ngIf="allMessages && allMessages.length === 1 && firstMessage.categoryType === 'MESSAGE'">
              {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.TITLE_ONE_MESSAGE') }}
            </span>
            <span
              *ngIf="allMessages && allMessages.length === 1 && firstMessage.categoryType === 'NOTIFICATION'">
              {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.TITLE_ONE_NOTIFICATION') }}
            </span>
          </ng-container>
        </h1>
      </div>
    </div>

    <div *ngIf="allMessages">
      <div class="row g-0 sis-pb-sm conversation-details-information">
        <div class="col-md-8 d-flex justify-content-start">
          <span *ngIf="messageCount === 1" class="guidance">
                {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.NUMBER_OF_MESSAGES_TOTAL_ONE')}} &nbsp;
          </span>
          <span *ngIf="messageCount > 1" class="guidance">
                {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.NUMBER_OF_MESSAGES_TOTAL', {messageCount: messageCount}) }} &nbsp;
          </span>
          <span *ngIf="unreadMessageCount === 1" class="guidance">
                {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.NUMBER_OF_MESSAGES_UNREAD_TOTAL_ONE') }}
          </span>
          <span *ngIf="unreadMessageCount > 1" class="guidance">
                {{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.NUMBER_OF_MESSAGES_UNREAD_TOTAL', {unreadMessageCount: unreadMessageCount}) }}
          </span>
        </div>
        <div class="col-md-4 d-flex justify-content-lg-end">
          <sis-button size="sm" [hollow]="true" (clicked)="goBack()">
            <sis-icon icon="back"></sis-icon>
            <span>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.BACK_BUTTON_TITLE') }}</span>
          </sis-button>
          <sis-menu-button color="primary" [options]="menuOptions" [placeLeft]="true"></sis-menu-button>
        </div>
      </div>
    </div>
    <ul class="message-list">
      <li *ngFor="let message of firstThreeMessages; trackBy:entityId">
        <div class="message-top-border">
            <sis-message
              [message]="message"
              [isExpanded]="isExpanded(message.id)"
              (expandEvent)="onExpandEvent($event)"
              (transitionEvent)="onTransition($event)">
            </sis-message>
        </div>
      </li>
      <li *ngFor="let message of restOfMessages; trackBy:entityId">
        <div class="message-top-border">
            <sis-message
              [message]="message"
              [isExpanded]="isExpanded(message.id)"
              (expandEvent)="onExpandEvent($event)"
              (transitionEvent)="onTransition($event)">
            </sis-message>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
