dropdownSelectController.$inject = ["$scope"];
import angular from 'angular';
import * as _ from 'lodash-es';
import dropdownSelectTpl from './dropdownSelect.tpl.html';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component.ts';
export const dropdownSelectModule = 'sis-components.select.dropdownSelect';

/**
 * This component is meant to provide backward compatibility for legacy code by working as an adapter that
 * maps the inputs/outputs of the old dropdownSelect component with the new Angular DropdownSelectComponent.
 * It is designed to work as a drop-in replacement for the old component, requiring no changes in existing
 * codebase.
 *
 * This component should NOT be used when writing new code; the downgraded version of DropdownSelectComponent
 * is the preferred choice. The only purpose of this component was to allow transitioning from UI Bootstrap
 * dropdowns to NG Bootstrap dropdowns without changing existing codebase.
 */
angular.module(dropdownSelectModule, [DropdownSelectComponent.downgrade.moduleName]).component('dropdownSelect', {
  bindings: {
    id: '<',
    disabled: '<',
    required: '<',
    compact: '<',
    alignRight: '<',
    ariaLabelledBy: '<',
    selection: '<',
    options: '<',
    container: '<',
    onChange: '&',
    trackByIndex: '<'
  },
  controller: dropdownSelectController,
  template: dropdownSelectTpl
});

/**
 * Component for drop down where available selections is a set of objects.
 *
 * options is array of objects :
 * { value : 'the value',
 * label : 'label to be displayed'
 * info : 'additional info to be displayed after label'
 * guidance : 'another type of additional info to be displayed under label', DO NOT USE! Use 'info' instead
 * disabled: 'true/false if the option is disabled'
 * header: 'true/false if the option is a header in the list of options }
 *
 * selection - one-way bound value/result of selection
 *
 * selectionChange - will not work without it, you should set new value (selection) to the binded variable in your
 * controller.
 *
 * selected is computed object in options array based on initial selection value
 */
function dropdownSelectController($scope) {
  const ctrl = this;
  ctrl.$scope = $scope;
  ctrl.$onInit = updateSelected;
  ctrl.$onChanges = updateSelected;
  function updateSelected() {
    ctrl.selected = _.find(ctrl.options, ['value', ctrl.selection]);
  }
  ctrl.setValue = function (selection) {
    ctrl.selection = selection;
    updateSelected();
    if (_.get(ctrl, 'onChange')) {
      ctrl.onChange({
        selection
      });
    }
  };
}