import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Attainment, CourseUnit, CreditRange, CustomModuleAttainment, LocalizedString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { UiStateObject } from '../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-custom-module-attainment',
    templateUrl: './plan-custom-module-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanCustomModuleAttainmentComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planCustomModuleAttainment',
        directiveName: 'sisPlanCustomModuleAttainment',
    };

    customModuleAttainmentStateObject: any;
    courseUnitsById: { [id: string]: CourseUnit };
    attainment: Attainment;
    visibleCredits: CreditRange;
    gradeAbbreviation: LocalizedString;
    isOpen: boolean;
    classes: string;

    @Input() customModuleAttainment: CustomModuleAttainment;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;

    ngOnChanges() {
        if (this.customModuleAttainment) {
            this.customModuleAttainmentStateObject = _.get(this.planStateObject.customModuleAttainments, this.customModuleAttainment.id);
        }
        this.classes = this.getClasses();
    }

    getClasses(): string {
        const classes = [
            `tree-list-item-level-${this.level}`,
        ];
        if (this.level > 1) {
            classes.push('tree-list-item', 'clearfix');
        }
        return classes.join(' ');
    }
}
