import { inject, Injectable } from '@angular/core';
import { CourseUnitRealisation, Enrolment, EnrolmentState } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { EnrolmentPeriodDateService } from 'sis-components/service/enrolment-period-date.service';

export type FilterKey = undefined | EnrolmentState | 'ACTIVITY_ENDED' | 'ABORTED' | 'NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED' | 'ENROLMENT_NOT_STARTED' | 'PROCESSING';

export interface CalendarFilterStates {
    ENROLMENT_NOT_STARTED: boolean;
    NOT_ENROLLED: boolean;
    PROCESSING: boolean;
    ENROLLED: boolean;
    REJECTED: boolean;
}

/**
 * This service was split from the old student AngularJS `enrolmentService`. Methods and unit tests were directly copied
 * from the old service and typed.
 */
@Injectable({
    providedIn: 'root',
})
export class CalendarEnrolmentService {
    enrolmentPeriodDateService = inject(EnrolmentPeriodDateService);

    /**
     * Calculates that which filter this enrolment and cur applies to.
     * undefined: enrolment not in calendar
     * ACTIVITY_ENDED: activity period has ended for cur
     * ABORTED: enrolment state is ABORTED_BY_*
     * NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED: enrolment state is NOT_ENROLLED and enrolment period is defined and passed
     * ENROLMENT_NOT_STARTED: enrolment state is NOT_ENROLLED and enrolment period not defined or not started
     * NOT_ENROLLED: enrolment state is NOT_ENROLLED and enrolment period is ongoing
     * PROCESSING: enrolment state is PROCESSING
     * ENROLLED: enrolment state is ENROLLED
     * REJECTED: enrolment state is REJECTED
     */
    getFilterKeyForEnrolment(enrolment: Enrolment, cur: CourseUnitRealisation): FilterKey {
        if (!enrolment || !cur || !enrolment.isInCalendar) {
            return undefined;
        }
        if (this.enrolmentPeriodDateService.activityPeriodEnded(cur)) {
            return 'ACTIVITY_ENDED';
        }
        const { state } = enrolment;
        if (_.includes(['ABORTED_BY_STUDENT', 'ABORTED_BY_TEACHER'], state)) {
            return 'ABORTED';
        }
        if (state === 'NOT_ENROLLED') {
            if (this.enrolmentPeriodDateService.allEnrolmentPeriodsHaveEnded(cur)) {
                return 'NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED';
            }
            return this.enrolmentPeriodDateService.enrolmentPeriodStarted(cur) ?
                'NOT_ENROLLED' : 'ENROLMENT_NOT_STARTED';
        }
        if (state === 'INVALID') {
            return 'PROCESSING';
        }
        return state;
    }

    getDefaultCalendarFilterStates(): CalendarFilterStates {
        return {
            ENROLMENT_NOT_STARTED: true,
            NOT_ENROLLED: true,
            PROCESSING: true,
            ENROLLED: true,
            REJECTED: true,
        };
    }
}
