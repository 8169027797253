<section #alertWrapper class="alerts" [class.alerts--sticky]="!topNavigationVisible">
<div *ngFor="let alert of alerts"
  class="single-alert"
  [ngClass]="['alert-id-' + alert.identifier, getAlertStateClassName(alert)]">
    <ngb-alert [type]="alert.type" (closed)="dismissAlert(alert.identifier)" [dismissible]="!alert.hideDismissibleButton">
      <p class="alert-text"
            *ngIf="!alert.onClickCallback && !alert.scrollToElement"
            data-cy="alert-text"
            [attr.id]="alert.identifier"
            [attr.role]="alert.role ?? 'alert'"
      >{{alert.message}}</p>
      <button type="button"
              *ngIf="alert.onClickCallback || alert.scrollToElement"
              class="alert-button"
              (keydown)="handleKeyDown($event)"
              data-cy="alert-button"
              [attr.id]="alert.identifier"
              (keydown.enter)="onClickAlert(alert)"
              (keydown.space)="onClickAlert(alert)"
              (click)="onClickAlert(alert)"
              [attr.tabindex] = "[alert.onClickCallback || alert.scrollToElement ? '0': null]">
        <transloco [content]="alert.message" />
      </button>
    </ngb-alert>
</div>
</section>
