import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { ValidatablePlan } from 'common-typescript';
import {
    CourseUnit,
    Education, Enrolment,
    OtmId,
    Plan,
    StudyPeriod,
    StudyPeriodLocator,
    StudyYear,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatestWith, from, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { COLOR_SERVICE, COMMON_PLAN_SERVICE, COMMON_STUDY_PERIOD_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyPeriodLocatorService } from 'sis-components/service/study-period-locator.service';
import { StudyYearsEntityService } from 'sis-components/service/study-years-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL, PLAN_SEEKER } from '../../../ajs-upgraded-modules';
import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';
import { ChoosePlanComponent } from '../choose-plan/choose-plan.component';

interface CourseUnitsNotInCalendarData {
    allStudentPlans: Plan[];
    selectedValidatablePlan: ValidatablePlan;
    planEducations: Education[];
    notScheduledCourseUnitsByPeriod: Record<StudyPeriodLocator, CourseUnit[]>;
    courseUnitColorCategoriesById: Record<OtmId, string>;
    studyPeriodsByLocator: Record<StudyPeriodLocator, StudyPeriod>;
    studyYearsByStartYear: Record<number, StudyYear>;
}
@Component({
    selector: 'app-course-units-not-in-calendar',
    templateUrl: './course-units-not-in-calendar.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        NgxFudisModule,
        SisComponentsModule,
        SisCommonModule,
        ChoosePlanComponent,
        UIRouterModule,
    ],
})
export class CourseUnitsNotInCalendarComponent implements OnInit {

    planEntityService = inject(PlanEntityService);
    enrolmentStudentService = inject(EnrolmentStudentService);
    educationEntityService = inject(EducationEntityService);
    appErrorHandler = inject(AppErrorHandler);
    commonPlanService = inject(COMMON_PLAN_SERVICE);
    colorService = inject(COLOR_SERVICE);
    stateService = inject(StateService);
    planSeekerService = inject(PLAN_SEEKER);
    commonStudyPeriodService = inject(COMMON_STUDY_PERIOD_SERVICE);
    studyPeriodLocatorService = inject(StudyPeriodLocatorService);
    studyYearsEntityService = inject(StudyYearsEntityService);
    universityService = inject(UniversityService);
    courseUnitInfoModalService = inject(COURSE_UNIT_INFO_MODAL);

    // TODO: replace this
    @Input() getValidatablePlan: () => PromiseLike<ValidatablePlan>;

    data$: Observable<CourseUnitsNotInCalendarData>;

    ngOnInit() {
        this.data$ = this.createDataObservable();
    }

    createDataObservable(): Observable<CourseUnitsNotInCalendarData> {
        const validatablePlan$ =
            this.createValidatablePlanObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const allStudentPlans$ =
            this.createAllStudentPlansObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const planEducations$ =
            this.createPlanEducationsObservable(allStudentPlans$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const allStudentEnrolments$ =
            this.createAllStudentEnrolmentsObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const enrolmentsInCalendar$ =
            this.createEnrolmentsInCalendarObservable(allStudentEnrolments$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const notScheduledCourseUnits$: Observable<CourseUnit[]> =
            this.createNotScheduledCourseUnitsObservable(enrolmentsInCalendar$, validatablePlan$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const courseUnitColorCategoriesById$ =
            this.createCourseUnitColorCategoriesByIdObservable(notScheduledCourseUnits$, validatablePlan$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const notScheduledCourseUnitsByPeriod$ =
            this.createNotScheduledCourseUnitsByPeriodObservable(notScheduledCourseUnits$, validatablePlan$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const studyPeriodsByLocator$: Observable<Record<StudyPeriodLocator, StudyPeriod>> =
            this.createStudyPeriodsByLocatorObservable(notScheduledCourseUnitsByPeriod$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        const studyYearsByStartYear$ =
            this.createStudyYearsByStartYearObservable(studyPeriodsByLocator$).pipe(shareReplay({ bufferSize: 1, refCount: true }));

        return allStudentPlans$.pipe(
            combineLatestWith(
                validatablePlan$,
                planEducations$,
                notScheduledCourseUnitsByPeriod$,
                courseUnitColorCategoriesById$,
                studyPeriodsByLocator$,
                studyYearsByStartYear$,
            ),
            this.appErrorHandler.defaultErrorHandler(),
            map(([
                allStudentPlans,
                selectedValidatablePlan,
                planEducations,
                notScheduledCourseUnitsByPeriod,
                courseUnitColorCategoriesById,
                studyPeriodsByLocator,
                studyYearsByStartYear,
            ]) => ({
                allStudentPlans,
                selectedValidatablePlan,
                planEducations,
                notScheduledCourseUnitsByPeriod,
                courseUnitColorCategoriesById,
                studyPeriodsByLocator,
                studyYearsByStartYear,
            })));
    }

    createValidatablePlanObservable(): Observable<ValidatablePlan> {
        return from(convertAJSPromiseToNative(this.getValidatablePlan()));
    }

    createAllStudentPlansObservable(): Observable<Plan[]> {
        return this.planEntityService.getMyPlans();
    }

    createPlanEducationsObservable(allStudentPlans$: Observable<Plan[]>): Observable<Education[]> {
        return allStudentPlans$.pipe(switchMap((plans) => {
            const educationIds = _.compact(_.uniq(plans.map((plan) => plan.rootId)));
            if (educationIds.length > 0) {
                return this.educationEntityService.getByIds(educationIds);
            }
            return of([]);
        }));
    }

    createAllStudentEnrolmentsObservable(): Observable<Enrolment[]> {
        return this.enrolmentStudentService.getAllEnrolments();
    }

    createEnrolmentsInCalendarObservable(allStudentEnrolments$: Observable<Enrolment[]>): Observable<Enrolment[]> {
        return allStudentEnrolments$.pipe(map((enrolments) => enrolments.filter((enrolment) => enrolment.isInCalendar)));
    }

    createNotScheduledCourseUnitsObservable(enrolmentsInCalendar$: Observable<Enrolment[]>, validatablePlan$: Observable<ValidatablePlan>): Observable<CourseUnit[]> {
        return enrolmentsInCalendar$.pipe(
            combineLatestWith(validatablePlan$),
            switchMap(([enrolmentsInCalendar, validatablePlan]) => {
                if (validatablePlan) {
                    // TODO: This returns course units that are enriched with '$hasTeaching' property. This
                    //  is still used in the template, make sure to handle it when this method is angularized
                    return from(convertAJSPromiseToNative(this.commonPlanService.findNotScheduledCourseUnits(validatablePlan, enrolmentsInCalendar))) as Observable<CourseUnit[]>;
                }
                return of([]);
            }),
        );
    }

    createCourseUnitColorCategoriesByIdObservable(notScheduledCourseUnits$: Observable<CourseUnit[]>, validatablePlan$: Observable<ValidatablePlan>): Observable<Record<OtmId, string>> {
        return notScheduledCourseUnits$.pipe(
            combineLatestWith(validatablePlan$),
            map(([notScheduledCourseUnits, validatablePlan]) => {
                const colorCategoriesById: Record<OtmId, string> = {};
                if (validatablePlan) {
                    notScheduledCourseUnits.forEach((courseUnit) => {
                        colorCategoriesById[courseUnit.id] = this.colorService.getCourseUnitColorCategory(validatablePlan, courseUnit);
                    });
                }
                return colorCategoriesById;
            }),
        );
    }

    createNotScheduledCourseUnitsByPeriodObservable(notScheduledCourseUnits$: Observable<CourseUnit[]>, validatablePlan$: Observable<ValidatablePlan>): Observable<Record<StudyPeriodLocator, CourseUnit[]>> {
        return notScheduledCourseUnits$.pipe(
            combineLatestWith(validatablePlan$),
            map(([notScheduledCourseUnits, validatablePlan]) =>
                this.getNotScheduledCourseUnitsByPeriod(notScheduledCourseUnits, validatablePlan)),
        );
    }

    createStudyPeriodsByLocatorObservable(notScheduledCourseUnitsByPeriod$: Observable<Record<StudyPeriodLocator, CourseUnit[]>>): Observable<Record<StudyPeriodLocator, StudyPeriod>> {
        return notScheduledCourseUnitsByPeriod$.pipe(
            switchMap((notScheduledCourseUnitsByPeriod) => {
                const studyPeriodIds = _.uniq(_.compact(_.keys(notScheduledCourseUnitsByPeriod))).filter((locator) => locator !== 'undefined');
                if (studyPeriodIds.length > 0) {
                    return from(Promise.all(studyPeriodIds.map((studyPeriodId) =>
                        this.commonStudyPeriodService.getPeriodsFor(studyPeriodId))))
                        .pipe(
                            map((studyPeriods) => _.keyBy(studyPeriods, 'locator') as Record<StudyPeriodLocator, StudyPeriod>),
                        );
                }
                return of({});
            }),
        );
    }

    createStudyYearsByStartYearObservable(studyPeriodsByLocator$: Observable<Record<StudyPeriodLocator, StudyPeriod>>): Observable<Record<number, StudyYear>> {
        return studyPeriodsByLocator$.pipe(
            switchMap((studyPeriodsByLocator) => {
                const parsedLocators = _.chain(_.keys(studyPeriodsByLocator))
                    .uniq()
                    .map((locator) => this.parseStudyPeriodLocator(locator))
                    .value();

                if (!_.isEmpty(parsedLocators)) {
                    const organisationId = this.universityService.getCurrentUniversityOrgId();
                    const years = _.map(parsedLocators, 'year');
                    const firstYear = _.min(years);
                    const numOfYears = _.max(years) - firstYear + 1;
                    return this.studyYearsEntityService.getStudyYears(organisationId, firstYear, numOfYears).pipe(
                        map((studyYears) => _.keyBy(studyYears, 'startYear')),
                    );
                }
                return of({});
            }),
        );
    }

    getNotScheduledCourseUnitsByPeriod(notScheduledCourseUnits: CourseUnit[], validatablePlan: ValidatablePlan): Record<OtmId, CourseUnit[]> {
        const courseUnitsByPeriods = _.groupBy(notScheduledCourseUnits, (courseUnit) => {
            if (validatablePlan) {
                return _.head(validatablePlan.getPlannedPeriods(courseUnit));
            }
            return undefined;
        });
        const notScheduledCourseUnitsByPeriod: Record<OtmId, CourseUnit[]> = {};
        _.chain(courseUnitsByPeriods)
            .keys()
            .sortBy()
            .forEach((key) => {
                notScheduledCourseUnitsByPeriod[key] = courseUnitsByPeriods[key];
            })
            .value();
        return notScheduledCourseUnitsByPeriod;
    }

    parseStudyPeriodLocator(locator: StudyPeriodLocator) {
        return this.studyPeriodLocatorService.parseStudyPeriodLocator(locator);
    }

    onPlanChange(planId: OtmId) {
        this.planSeekerService.storeSelectedPlanId(planId);
        this.stateService.reload();
    }

    onOpenCourseUnitModal(courseUnit: CourseUnit, validatablePlan: ValidatablePlan) {
        const colorCategory = this.colorService.getCourseUnitColorCategory(validatablePlan, courseUnit);
        this.courseUnitInfoModalService.showCompletionMethodsForCourseUnit(courseUnit.id, validatablePlan, `cu-color-${colorCategory}`);
    }
}
