import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { dateUtils } from 'common-typescript/constants';
import { LocalDateTimeString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { LocaleService } from 'sis-common/l10n/locale.service';

/**
 * Will format the given date range as long date range including the weekday name it starts and ends.
 * Weekday is formatted by locale, with the first letter capitalized
 * Date in D.M.YYYY
 * Time in HH:mm
 * Time prefix (e.g. kl, klo) is translated to current locale (does not exist in english).
 *
 * Examples:
 * same day date range: Maanantai 11.12.2023, klo 10.20-11.20
 * spanning several days: Onsdag 13.12.2023, kl. 10.31 - Torsdag 14.12.2023, kl. 11.31
 * no 'klo' in english: Monday 23.12.2019, 8.00 – Tuesday 7.1.2020, 16.00
 */
@Pipe({
    name: 'weekdayAndDateTimeRange',
})
export class WeekdayAndDateTimeRangePipe implements PipeTransform {

    constructor(
        private localeService: LocaleService,
        private translocoService: TranslocoService,
    ) {}

    transform(start: LocalDateTimeString | Date | moment.Moment,
              end: LocalDateTimeString | Date | moment.Moment): string {
        const mStart = dateUtils.createMoment(start);
        const mEnd = dateUtils.createMoment(end);

        if (!mStart || !mEnd) {
            return '';
        }
        const locale = this.localeService.getCurrentLanguage();

        const startTime = this.getTime(mStart);
        const endTime = this.getTime(mEnd);

        const startDayAndDate = this.getDayAndDate(mStart, locale);

        const startTimeWithPrefix = this.translocoService.translate('TIME.WITH_PREFIX', { time: startTime });

        if (this.datesEqual(mStart, mEnd)) {
            return `${startDayAndDate}, ${startTimeWithPrefix} - ${endTime}`;
        }
        const endDayAndDate = this.getDayAndDate(mEnd, locale);
        const endTimeWithPrefix = this.translocoService.translate('TIME.WITH_PREFIX', { time: endTime });

        return `${startDayAndDate}, ${startTimeWithPrefix} - ${endDayAndDate}, ${endTimeWithPrefix}`;
    }

    datesEqual(m1: moment.Moment, m2: moment.Moment) {
        return m1.isSame(m2, 'day');
    }

    getTime(m: moment.Moment) {
        return dateUtils.formatDateIfValid(m, 'HH.mm');
    }

    getDayAndDate(m: moment.Moment, locale: string) {
        const dayAndDate = m.locale(locale).format('dddd D.M.YYYY');
        return _.upperFirst(dayAndDate);
    }
}
