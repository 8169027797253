import { Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit } from 'common-typescript/types';
import { from, Observable } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { OutdatedCourseUnitsModalComponent } from './outdated-course-units-modal.component';

export interface OutdatedCourseUnitsModalValues {
    courseUnits: [CourseUnit, CourseUnit][],
    validatablePlan: ValidatablePlan,
}
@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class OutdatedCourseUnitsModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.plan.outdatedCourseUnitsModalService',
        serviceName: 'outdatedCourseUnitsModalServiceNg',
    };

    constructor(private modalService: ModalService) { }

    open(outdatedCourseUnitsModalValues: OutdatedCourseUnitsModalValues): Observable<void> {
        return from(this.modalService.open(
            OutdatedCourseUnitsModalComponent,
            outdatedCourseUnitsModalValues,
            {
                closeWithOutsideClick: true,
                size: 'xl',
            }).result);
    }
}
