import { Pipe, PipeTransform } from '@angular/core';
import { CreditRange, Module } from 'common-typescript/types';

/**
 * Get module target credits or undefined.
 * Module-pipe returns Module objects, but they can be subtypes of Module with targetCredits property (e.g. DPSMBase).
 * This pipe returns the targetCredits property of module if it exists.
 */
@Pipe({ name: 'moduleTargetCredits' })
export class ModuleTargetCreditsPipe implements PipeTransform {

    transform(module: Module): CreditRange | undefined {
        return (module as ModuleWithTargetCredits)?.targetCredits;
    }
}

interface ModuleWithTargetCredits extends Module {
    targetCredits?: CreditRange;
}
