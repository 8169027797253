import angular from 'angular';
import moment from 'moment';
import * as _ from 'lodash-es';
export const commonEnrolmentPeriodDateServiceModule = 'sis-components.service.enrolmentPeriodDateService';
(function () {
  angular.module(commonEnrolmentPeriodDateServiceModule, []).service('enrolmentPeriodDateService', enrolmentPeriodDateService);
  function enrolmentPeriodDateService() {
    const getStartDateTime = cur => _.get(cur, 'enrolmentPeriod.startDateTime');
    const getEndDateTime = cur => _.get(cur, 'enrolmentPeriod.endDateTime');
    const getLateEnrolmentEndDateTime = cur => _.get(cur, 'lateEnrolmentEnd');
    const hasEnrolmentStartAndEnd = cur => getStartDateTime(cur) && getEndDateTime(cur);

    /**
     * Period has not started if it is not yet defined.
     * Period has not ended if it is not yet defined.
     * Period is not ongoing if it is not yet defined.
     *
     */
    const api = {
      enrolmentPeriodOngoing(cur) {
        if (!hasEnrolmentStartAndEnd(cur)) {
          return false;
        }
        const now = moment();
        return now.isAfter(getStartDateTime(cur)) && now.isBefore(getEndDateTime(cur));
      },
      lateEnrolmentPeriodOngoing(cur) {
        if (!hasEnrolmentStartAndEnd(cur)) {
          return false;
        }
        const lateEnrolmentEndDateTime = getLateEnrolmentEndDateTime(cur);
        if (!lateEnrolmentEndDateTime) {
          return false;
        }
        const now = moment();
        return now.isAfter(getEndDateTime(cur)) && now.isBefore(lateEnrolmentEndDateTime);
      },
      cancellationPossible(cur) {
        if (!hasEnrolmentStartAndEnd(cur)) {
          return false;
        }
        const now = moment();
        const dates = api.getEnrolmentDates(cur);
        return now.isAfter(dates.startDateTime) && now.isBefore(dates.cancellationEndDateTime);
      },
      activityPeriodOnGoing(cur) {
        const startDate = _.get(cur, 'activityPeriod.startDate');
        const endDate = _.get(cur, 'activityPeriod.endDate');
        return startDate && endDate && moment().isBetween(startDate, endDate, 'day', '[)');
      },
      activityPeriodEnded(cur) {
        const endDate = _.get(cur, 'activityPeriod.endDate');
        return endDate && moment().isAfter(endDate);
      },
      enrolmentPeriodStarted(cur) {
        if (!hasEnrolmentStartAndEnd(cur)) {
          return false;
        }
        return moment().isAfter(getStartDateTime(cur));
      },
      allEnrolmentPeriodsHaveEnded(cur) {
        if (!hasEnrolmentStartAndEnd(cur)) {
          return false;
        }
        return api.enrolmentPeriodStarted(cur) && !api.enrolmentPeriodOngoing(cur) && !api.lateEnrolmentPeriodOngoing(cur);
      },
      getEnrolmentDates(cur) {
        const cancellationEndDateTime = _.get(cur, 'enrolmentAdditionalCancellationEnd');
        return {
          startDateTime: getStartDateTime(cur),
          endDateTime: getEndDateTime(cur),
          lateEnrolmentEnd: getLateEnrolmentEndDateTime(cur),
          cancellationEndDateTime: !_.isNil(cancellationEndDateTime) ? cancellationEndDateTime : getEndDateTime(cur)
        };
      }
    };
    return api;
  }
})();