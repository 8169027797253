import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import angular from 'angular';
import { CooperationNetwork, CooperationNetworkShare, Organisation } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { CooperationNetworkEntityService } from '../../service/cooperation-network-entity.service';
import { OrganisationEntityService } from '../../service/organisation-entity.service';
import { UniversityService } from '../../service/university.service';

export interface CooperationNetworkShareListItem {
    cooperationNetworkShare: CooperationNetworkShare;
    formId: number;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-cooperation-network-details-editor',
    templateUrl: './cooperation-network-details-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CooperationNetworkDetailsEditorComponent implements OnInit, AfterViewChecked, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.cooperation-network-details-editor.downgraded',
        directiveName: 'sisCooperationNetworkDetailsEditor',
    };

    @Input() shares: CooperationNetworkShare[];
    @Input() scope: angular.IScope;
    @Output() editShares = new EventEmitter<{ editedShares: CooperationNetworkShare[] }>();
    private destroyed$ = new Subject<void>();
    organisation$: Observable<Organisation>;
    cooperationNetworks$: Observable<CooperationNetwork[]>;
    universityOrgId: string;
    form: FormGroup;
    shareListItems: CooperationNetworkShareListItem[];
    formIndex = 0;

    constructor(
        private cooperationNetworkEntityService: CooperationNetworkEntityService,
        private universityService: UniversityService,
        private organisationEntityService: OrganisationEntityService,
        private fb: SisFormBuilder,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.initShareListItems();
        this.universityOrgId = this.universityService.getCurrentUniversityOrgId();
        this.organisation$ = this.organisationEntityService.findUniversityRootOrganisation(this.universityOrgId);
        this.cooperationNetworks$ = this.cooperationNetworkEntityService.getByUniversityOrgId(this.universityOrgId);
        this.form = this.fb.group({});
        this.setupValueChangeListeners();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    private setupValueChangeListeners() {
        this.form.valueChanges
            .pipe(takeUntil(this.destroyed$),
                  distinctUntilChanged(_.isEqual)).subscribe(x => {
                this.editShares.emit({ editedShares: this.shareListItems
                    .filter(listItem => listItem.cooperationNetworkShare.cooperationNetworkId != null)
                    .map(listItem => listItem.cooperationNetworkShare) });
            });
    }

    private initShareListItems(): void {
        this.shareListItems = [];
        if (!this.shares) {
            this.shares = [];
        }
        for (const share of this.shares) {
            this.shareListItems.push({ cooperationNetworkShare: share, formId: this.getIndexForNewForm() });
        }
    }

    addShare() {
        if (this.allowAddingShare()) {
            this.shareListItems = _.concat(this.shareListItems, { cooperationNetworkShare: { cooperationNetworkId: null, validityPeriod: null }, formId: this.getIndexForNewForm() });
        }
    }

    getIndexForNewForm() {
        const index = this.formIndex;
        this.formIndex += 1;
        return index;
    }

    allowAddingShare() {
        return this.shareListItems.length < 1 || !this.form.invalid;
    }

    onRemoveShare(eventData: number) {
        this.removeShare(eventData);
        this.editShares.emit({ editedShares: this.shareListItems.map(listItem => listItem.cooperationNetworkShare) });
    }

    removeShare(removeId: number) {
        const updatedShares = [];
        for (const shareListItem of this.shareListItems) {
            if (shareListItem.formId !== removeId) {
                updatedShares.push(shareListItem);
            }
        }
        this.shareListItems = updatedShares;
    }
}
