import { Component, Inject, Input, OnChanges, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { Education } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-education',
    templateUrl: './plan-education.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanEducationComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planEducation',
        directiveName: 'sisPlanEducation',
    };

    @Input() education: Education;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;

    isOpen: boolean;
    educationValidationResult: any;
    classes: string;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnInit() {
        this.isOpen = true;
    }

    ngOnChanges() {
        this.educationValidationResult = _.get(this.planValidationResult.moduleValidationResults, this.education.id);
        this.classes = this.getClasses();
    }

    getClasses(): string {
        const classes = [
            `tree-list-item-level-${this.level}`,
        ];
        if (this.level > 1) {
            classes.push('tree-list-item', 'clearfix');
        }
        return classes.join(' ');
    }

    handleHeaderClick() {
        this.planActionsService?.openModule?.(this.education);
    }

}
