import { ChangeDetectionStrategy, Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AssessmentItemAttainment,
    Attainment, CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    ModuleAttainment,
} from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

export type AcceptedAttainmentTypes =
    DegreeProgrammeAttainment
    | ModuleAttainment
    | CustomModuleAttainment
    | AssessmentItemAttainment
    | CustomCourseUnitAttainment
    | CourseUnitAttainment;
export interface AttainmentDetailsModalValues {
    attainment: AcceptedAttainmentTypes;
    secondaryAttainments: Attainment[];
    hasValidParentAttainment: boolean;
    isAboutToExpire: boolean;
    externallyImportedDPAttainment: boolean;
}

export function attainmentDetailsModalOpener(): (attainment: AcceptedAttainmentTypes, secondaryAttainments: Attainment[], hasValidParentAttainment: boolean, isAboutToExpire: boolean, externallyImportedDPAttainment: boolean) => NgbModalRef {
    const modalService = inject(ModalService);
    return (
        attainment: AcceptedAttainmentTypes,
        secondaryAttainments: Attainment[],
        hasValidParentAttainment: boolean,
        isAboutToExpire: boolean,
        externallyImportedDPAttainment: boolean,
    ) => modalService.open(AttainmentDetailsModalComponent, {
        attainment,
        secondaryAttainments,
        hasValidParentAttainment,
        isAboutToExpire,
        externallyImportedDPAttainment,
    }, { size: 'md' });
}
@Component({
    selector: 'sis-attainment-details-modal',
    templateUrl: './attainment-details-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentDetailsModalComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.attainment.attainmentDetails.attainmentDetailsModal.downgraded',
        directiveName: 'sisAttainmentDetailsModal',
    };

    attainment: AcceptedAttainmentTypes;
    secondaryAttainments: Attainment[];
    expirationImminent = false;
    externallyImportedDPAttainment = false;

    constructor(@Inject(ModalService.injectionToken) private values: AttainmentDetailsModalValues,
                public modal: NgbActiveModal) {
        this.attainment = values.attainment;
        this.secondaryAttainments = values.secondaryAttainments;
        this.expirationImminent = values.isAboutToExpire && !values.hasValidParentAttainment;
        this.externallyImportedDPAttainment = values.externallyImportedDPAttainment;
    }

}
