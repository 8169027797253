<div *transloco="let t" class="search-page container sis-pt-md">
  <div class="search-title row">
    <div class="col-12">
      <h2 data-cy="page-secondary-title" translate>SEARCH_PAGE_OPEN_UNIVERSITY_TITLE</h2>
    </div>
  </div>
  <p class="guidance" translate>SEARCH_PAGE_SECONDARY_TITLE</p>

  <div class="search-and-results scroll-target">
    <sis-search [guidance]="'COURSE_UNIT_SEARCH_PLACEHOLDER'" (searchTrigger)="onSearchParameterChange($event)">
      <ng-template sisSearchFilter>
        <sis-search-filter-organisation-id/>
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-curriculum-periods
          [key]="'curriculumPeriodId'"
          [label]="t('SEARCH.FILTER_TAGS.CURRICULUMPERIODS')"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-code-book
          [key]="'studyLevel'"
          [label]="t('SEARCH.FILTER_TAGS.STUDYLEVELURNS')"
          [codeBookUrn]="'urn:code:study-level'"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-code-book
          [key]="'attainmentLanguage'"
          [label]="t('SEARCH.FILTER_TAGS.ATTAINMENT_LANGUAGE')"
          [codeBookUrn]="'urn:code:language'"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <app-search-filter-assessment-item-type/>
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-code-book
          [key]="'teachingLanguage'"
          [label]="t('SEARCH.FILTER_TAGS.TEACHINGLANGUAGE')"
          [codeBookUrn]="'urn:code:language'"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-study-periods
          [key]="'activityPeriod'"
          [label]="t('SEARCH.FILTER_TAGS.ACTIVITYPERIODS')"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-combobox
          [key]="'salesPeriodValidity'"
          [label]="t('SEARCH.FILTER_TAGS.SALESPERIOD')"
          [options]="salesPeriodOptions"
        />
      </ng-template>
    </sis-search>

    <!-- this visually hidden div block is only to provide search result information for screen reader -->
    <!-- It has to be outside of <sis-search-results/>, as it should never be removed from the DOM, and
         <sis-search-results/> only projects the given content if the search parameters are valid. -->
    <div class="visually-hidden" aria-atomic="true" aria-live="polite">
      @if (searchResult$ | async; as searchResult) {
        @if (!searching) {
          @if (searchResult.total === 0) {
            <p>{{ t('SEARCH_NO_RESULTS') }}</p>
          }
          @if (searchResult.truncated) {
            <p>{{ t('SEARCH_LARGE_RESULT') }}</p>
          }
          <p *ngIf="!searchResult.truncated && searchResult.total > 0">
            {{searchResult.total}}
            <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
            <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
          </p>
        }
      }
    </div>

    <sis-search-results>
      @if (searchResult$ | async; as searchResult) {
        <div class="below-searchbar row">
          <div class="query-notifications col-12">
            @if (searchResult.total === 0) {
              <p class="results-no-show guidance">{{ t('SEARCH_NO_RESULTS') }}</p>
            }
            @if (searchResult.truncated) {
              <p class="results-no-show guidance">{{ t('SEARCH_LARGE_RESULT') }}</p>
            }
          </div>
          <div *ngIf="!searchResult.truncated && searchResult?.total > 0" class="results-info col-12">
            <div class="row">
              <h2 class="col-12 col-sm-6" id="results-show-guide" tabindex="-1"
                  data-cy="courseunit-search-resultrow">
                {{searchResult.total}}
                <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
                <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
              </h2>
              <div class="col-12 col-sm-6">
                <div class="float-start float-sm-end">
                  <label id="sort-method-dropdown-label" class="choose-sort-type" translate>SEARCH.SORT_SELECTOR.CHOOSE_SORT_METHOD</label>
                  <sis-dropdown-select [ariaLabelledBy]="'sort-method-dropdown-label'"
                                       [options]="searchSortOptions"
                                       [selection]="searchService.sort$ | async"
                                       (selectionChange)="sort($event)"
                                       [alignRight]="true"
                                       data-cy="sort-search-results">
                  </sis-dropdown-select>
                </div>
              </div>
            </div>
          </div>
          <div class="results col-12">
            @if (curriculumPeriods$ | async; as curriculumPeriods) {
              <div *ngFor="let result of searchResult?.searchResults" data-cy="student-courseunit-search-resultrow">
                <app-search-result-row
                  [studyPeriods]="studyPeriodInfos$ | async"
                  [curriculumPeriods]="curriculumPeriods"
                  [result]="result"
                  [term]="searchService.fullTextQuery$ | async"
                  [searchFilterCurriculumPeriodIds]="curriculumPeriodIds$ | async"
                  [courseCartCourseUnitIds]="courseCartCourseUnitIds"
                  [loggedIn]="isLoggedIn()"
                  [hasOpenUniversityLink]="true"
                  (addCourseUnitToCourseCart)="addCourseUnitToCourseCart($event)"
                  (removeCourseUnitFromCourseCart)="removeCourseUnitFromCourseCart($event)"
                ></app-search-result-row>
              </div>
            }
          </div>

          <div class="search-result-pagination row"
               *ngIf="!searchResult.truncated && searchResult?.total > resultsPerPage">
            <div class="col-12"
                 data-cy="pagination-controls">
              <sis-pagination
                sisScrollIntoViewOnPageChange="div.scroll-target"
                [totalItems]="searchResult.total"
                [maxSize]="5"
                [itemsPerPage]="resultsPerPage"
                [currentPage]="currentPage"
                (currentPageChange)="onPaginationChange($event)">
              </sis-pagination>
            </div>
          </div>
        </div>
      }
    </sis-search-results>
  </div>
</div>

