import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DegreeProgrammeAttainment } from 'common-typescript/types';

@Component({
    selector: 'sis-degree-programme-attainment-details',
    templateUrl: './degree-programme-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DegreeProgrammeAttainmentDetailsComponent {
    @Input() attainment: DegreeProgrammeAttainment;
    @Input() expirationImminent: boolean;
}

