<ng-container *transloco="let t">
  <div class="container">
    @if (otherPlansWithMessages.length > 0) {
      <a href="#other-plans" class="to-other-plans" (click)="scroll()" >
        {{ t('SIS_COMPONENTS.MESSENGER.LINK_MESSAGES_RELATED_TO_OTHER_PLANS',
              {educationName: (plan.rootId | education | async | entityName | localizedString)}) }}
      </a>
    }

    <ul class="messenger">
      <li class="messenger-new" [ngClass]="{'messenger-right':!isStudent, 'messenger-left':isStudent}">
        <div class="messenger-inner">
          @if (form) {
            <fudis-form
              [level]="2"
              [title]="t('SIS_COMPONENTS.MESSENGER.NEW_MESSAGE')"
              [errorSummaryTitle]="t('ERROR-SUMMARY.HELP_TEXT')"
              data-cy="plan-messages-form">
              <fudis-form-content>
                <fudis-grid [columns]="1" [width]="'sm'">
                  <fudis-grid-item>
                    <fudis-select
                      [size]="'lg'"
                      [label]="t('SIS_COMPONENTS.MESSENGER.NEW.TYPE')"
                      [control]="form.controls.messageType"
                      [initialFocus]="false"
                      [selectionClearButton]="false"
                      [variant]="'dropdown'"
                      (selectionUpdate)="setMessageType($event)"
                      data-cy="message-type-dropdown-select"
                    >
                      <ng-template fudisSelectOptions>
                        @for (option of messageTypeOptions; track option.value) {
                          <fudis-select-option [data]="option"></fudis-select-option>
                        }
                      </ng-template>
                    </fudis-select>
                  </fudis-grid-item>
                  @if (isStudyBoxMessage(newMessage)) {
                    <fudis-dl>
                      <fudis-dl-item>
                        <fudis-dt [contentText]="t('SIS_COMPONENTS.MESSENGER.HEADER_PLACE_AND_STUDY')"></fudis-dt>
                        <fudis-dd class="dd-delete-button">
                          {{ newMessage.parentModuleId | module | async | entityName | localizedString }} >

                          @if (isPlanCourseUnitComment(newMessage) && newMessage.courseUnitId | courseUnit | async; as courseUnit) {
                            {{ courseUnit | entityName | localizedString }}
                          }
                          @if (isPlanCustomStudyDraftComment(newMessage) && newMessage.customStudyDraft) {
                            {{ t('SIS_COMPONENTS.MESSENGER.CUSTOM_STUDY_DRAFT') }} :
                            {{ newMessage.customStudyDraft.name }}
                          }
                          @if (isPlanModuleComment(newMessage) && newMessage.moduleId | module | async; as messageModule) {
                            {{ messageModule.name | localizedString }}
                          }
                          <fudis-button
                            [variant]="'tertiary'"
                            [icon]="'delete'"
                            [size]="'small'"
                            [label]="t('SIS_COMPONENTS.MESSENGER.MESSAGE.RESET_MESSAGE_TYPE')"
                            (handleClick)="resetMessageType()"
                          ></fudis-button>
                        </fudis-dd>
                      </fudis-dl-item>
                    </fudis-dl>
                  }
                  <fudis-grid-item>
                    <fudis-text-area
                      [label]="t('SIS_COMPONENTS.MESSENGER.HEADER_MESSAGE')"
                      [size]="'lg'"
                      [control]="form.controls.comment"
                      [initialFocus]="false"
                      data-cy="plan-messages-textarea"
                    ></fudis-text-area>
                  </fudis-grid-item>
                  <fudis-grid-item>
                    <fudis-checkbox-group [formGroup]="form.controls.agreement"
                                          [label]="">
                      <fudis-checkbox [label]="t('SIS_COMPONENTS.MESSENGER.NEW.AGREEMENT')"
                                      [controlName]="'checkbox'"/>
                    </fudis-checkbox-group>
                  </fudis-grid-item>
                  <fudis-grid-item>
                    <fudis-button [variant]="'primary'"
                                  [size]="'medium'"
                                  [label]="t('SIS_COMPONENTS.MESSENGER.NEW.SAVE')"
                                  (handleClick)="saveNewMessage()"
                    ></fudis-button>
                  </fudis-grid-item>
                </fudis-grid>
              </fudis-form-content>
            </fudis-form>
          }
        </div>
      </li>

      @for (message of messages; track message.id) {
        <li class="messenger-message"
            [ngClass]="{'messenger-note':message.type === 'PlanGeneric',
                        'messenger-study':message.type !== 'PlanGeneric',
                        'messenger-right':isTeacherMessage(message),
                        'messenger-left':!isTeacherMessage(message),
                        'deleted':message.documentState === 'DELETED'}">
          @if (message.type !== 'CustomModuleContentApplicationMessage' && message.type !== 'RequiredModuleContentApplicationMessage') {
            <sis-plan-message
              [message]="message"
              [validatablePlan]="validatablePlan"></sis-plan-message>
          } @else {
            <sis-module-content-application-message
              [message]="message"></sis-module-content-application-message>
          }
        </li>
      }

      <li class="messenger-history-begin">
        {{ t('SIS_COMPONENTS.MESSENGER.PLAN.CREATED', {created: (plan.metadata.createdOn | localDateTimeFormat)}) }}
      </li>
    </ul>

    @if (otherPlansWithMessages.length > 0) {
      <div id="other-plans"></div>
      <div class="other-plans-divider">
        <div class="divider"></div>
        <p class="guidance">
          {{ t('SIS_COMPONENTS.MESSENGER.OTHER_PLANS_TITLE',
                {educationName: (plan.rootId | education | async | entityName | localizedString)}) }}
        </p>
        <div class="divider"></div>
      </div>
    }

    @for (plan of otherPlansWithMessages; track plan.id) {
      <div class="plan-container">
        <div class="accordion-panel accordion-panel-noblock">
          <div class="header has-header-items">
            <button type="button" class="collapse non-button"
                    [ngClass]="{'collapsed': !expandedPlans[plan.id]}"
                    [attr.aria-expanded]="expandedPlans[plan.id]"
                    [attr.aria-label]="plan.name"
                    (click)="togglePlanExpandable(plan.id)">
              <sis-icon [icon]="'chevron-ring-fill'" [iconClass]="!expandedPlans[plan.id] ? null : 'cw-90'"></sis-icon>
            </button>
            <div class="title">
              <h2 class="plan-name">
                {{plan.name}}
                @if (plan.primary) {
                  <fudis-badge [variant]="'success'" [content]="t('SIS_COMPONENTS.PLAN.PRIMARY_STUDY_PLAN')"></fudis-badge>
                }
                @if (plan.documentState === 'DELETED') {
                  <fudis-badge [variant]="'danger'" [content]="t('VISIBLE_DOCUMENT_STATE.DELETED')"></fudis-badge>
                }
              </h2>
              <div class="plan-description">
                {{ t('SIS_COMPONENTS.MESSENGER.PLAN_DETAILS',
                  {
                    learningOpportunity: (plan.learningOpportunityId
                                          | learningOpportunityName: (plan.rootId | education | async)
                                          | localizedString),
                    education: (plan.rootId | education | async | entityName | localizedString),
                    curriculumPeriod: (plan.curriculumPeriodId | curriculumPeriodName | async),
                    created: (plan.metadata.createdOn | localDateFormat),
                    modified: (plan.metadata.lastModifiedOn | localDateTimeFormat)
                  })
                }}
              </div>
            </div>
          </div>
          @if (expandedPlans[plan.id]) {
            <div class="content"
                 [ngClass]="{'collapsed': !expandedPlans[plan.id]}">
              <div class="content-row">
                <div class="content-text">
                  <ul class="messenger">

                    @for (message of messagesByPlanId[plan.id]; track message.id) {
                      <li class="messenger-message"
                          [ngClass]="{'messenger-note':message.type === 'PlanGeneric',
                            'messenger-study':message.type !== 'PlanGeneric',
                            'messenger-right':isTeacherMessage(message),
                            'messenger-left':!isTeacherMessage(message),
                            'deleted':message.documentState === 'DELETED'}">
                        @if (message.type !== 'CustomModuleContentApplicationMessage' && message.type !== 'RequiredModuleContentApplicationMessage') {
                          <sis-plan-message
                            [message]="message"
                            [validatablePlan]="validatablePlan"></sis-plan-message>
                        } @else {
                          <sis-module-content-application-message
                            [message]="message"></sis-module-content-application-message>
                        }
                      </li>
                    }

                    <li class="messenger-history-begin">
                      {{ t('SIS_COMPONENTS.MESSENGER.PLAN.CREATED', {created: (plan.metadata.createdOn | localDateTimeFormat)}) }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
        @if (!$last && expandedPlans[plan.id]) {
          <hr>
        }
      </div>
    }
    @if (otherPlansWithoutMessages.length > 0) {
      <div class="other-plans-divider">
        <div class="divider"></div>
        <p class="guidance">
          {{ t('SIS_COMPONENTS.MESSENGER.PLANS_WITH_NO_MESSAGES_TITLE',
                {educationName: (plan.rootId | education | async | entityName | localizedString)}) }}
        </p>
        <div class="divider"></div>
      </div>
    }
    @for (plan of otherPlansWithoutMessages; track plan.id) {
      <div class="plan-container">
        <h2 class="plan-name">
          {{plan.name}}
          @if (plan.primary) {
            <fudis-badge [variant]="'success'" [content]="t('SIS_COMPONENTS.PLAN.PRIMARY_STUDY_PLAN')"></fudis-badge>
          }
          @if (plan.documentState === 'DELETED') {
            <fudis-badge [variant]="'danger'" [content]="t('VISIBLE_DOCUMENT_STATE.DELETED')"></fudis-badge>
          }
        </h2>
        <div class="plan-description">
          {{ t('SIS_COMPONENTS.MESSENGER.PLAN_DETAILS', {
          learningOpportunity: (plan.learningOpportunityId
            | learningOpportunityName: (plan.rootId | education | async)
            | localizedString),
          education: (plan.rootId | education | async | entityName | localizedString),
          curriculumPeriod: (plan.curriculumPeriodId | curriculumPeriodName | async),
          created: (plan.metadata.createdOn | localDateFormat),
          modified: (plan.metadata.lastModifiedOn | localDateTimeFormat)})
          }}
        </div>
      </div>
    }
  </div>
</ng-container>
