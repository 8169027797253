import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FrontpageComponentName, UserSettings } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, of, switchMap, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { WidgetOption } from './select-widgets-dropdown.component';

interface SettingsForm {
    components: FormArray<FormControl<boolean>>;
}

@Component({
    selector: 'app-select-widgets',
    templateUrl: './select-widgets.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWidgetsComponent implements OnInit {

    @Input() widgetOptions: WidgetOption[] = [];

    selectedOptions: WidgetOption[] = [];
    form: FormGroup<SettingsForm>;

    private userSettingsEntityService = inject(UserSettingsEntityService);
    private errorHandler = inject(AppErrorHandler);

    ngOnInit() {
        this.userSettingsEntityService.getOwnSettings()
            .pipe(
                take(1),
                switchMap((userSettings: UserSettings) => {
                    this.populateSelectedOptions(userSettings);
                    return this.firstTimeOpenSave(userSettings);
                }),
                this.errorHandler.defaultErrorHandler(),
            ).subscribe();
    }

    private populateSelectedOptions(userSettings: UserSettings) {
        const selected: WidgetOption[] = [];
        this.widgetOptions.forEach(option => {
            if (this.isVisible(option.value, userSettings)) {
                selected.push(option);
            }
        });
        this.selectedOptions = [...this.selectedOptions, ...selected];
    }

    private isVisible(componentName: FrontpageComponentName, userSettings: UserSettings): boolean {
        if (!userSettings) return true;
        if (!userSettings.componentSettings) return true;
        if (!userSettings.componentSettings[componentName]) return true;
        return !userSettings.componentSettings[componentName].isHidden;
    }

    widgetOptionChange(selectedOptions: WidgetOption[]) {
        this.userSettingsEntityService.getOwnSettings()
            .pipe(
                take(1),
                switchMap(settings => this.saveUserSettings(settings, selectedOptions)),
                this.errorHandler.defaultErrorHandler(),
            ).subscribe();
    }

    private saveUserSettings(settings: UserSettings, selectedOptions: WidgetOption[]) {
        const settingsCopy = _.cloneDeep(settings);
        if (!settingsCopy.componentSettings) settingsCopy.componentSettings = {};

        this.widgetOptions.forEach(widgetOption => {
            this.updateComponentSetting(widgetOption, selectedOptions, settingsCopy);
        });
        return this.userSettingsEntityService.saveOwnSettings(settingsCopy).pipe(take(1));
    }

    private updateComponentSetting(option: WidgetOption, selectedOptions: WidgetOption[], settings: UserSettings) {
        const componentName = option.value;
        const isHidden = !selectedOptions.find(o => o.value === option.value);

        settings.componentSettings[componentName] = _.merge({}, settings.componentSettings[componentName], {
            componentName, isHidden,
        });
    }

    // Save default selections when user navigates to frontpage first time ever
    private firstTimeOpenSave(userSettings: UserSettings): Observable<UserSettings> {
        const componentSettings = userSettings?.componentSettings;
        if (!componentSettings || Object.keys(componentSettings).length === 0) {
            return this.saveUserSettings(userSettings, this.selectedOptions);
        }
        return of(userSettings);
    }
}
