import { Pipe, PipeTransform } from '@angular/core';
import { Code, Urn } from 'common-typescript/types';
import { sortBy } from 'lodash-es';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { CommonCodeService } from '../service/common-code.service';
import { convertAJSPromiseToNative } from '../util/utils';

@Pipe({
    name: 'codesSortedByName',
})
export class CodesSortedByNamePipe implements PipeTransform {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private commonCodeService: CommonCodeService,
        private localeService: LocaleService,
    ) {}

    transform(urns: Urn[]): Observable<Code[]> {
        if (!urns || urns.length === 0) {
            return of([]);
        }
        return from(convertAJSPromiseToNative(this.commonCodeService.getCodesByUrns(urns)))
            .pipe(
                map(codes => sortBy(codes, (code) => this.localeService.localize(code?.name))),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
