import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItemAttainment, Attainment } from 'common-typescript/types';

@Component({
    selector: 'sis-assessment-item-attainment-details',
    templateUrl: './assessment-item-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class AssessmentItemAttainmentDetailsComponent {
    @Input() attainment: AssessmentItemAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() expirationImminent: boolean;
}
