import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CourseUnit, CurriculumPeriod, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Option } from '../../select/dropdown-select/dropdown-select.component';

export interface CourseUnitVersion {
    courseUnit: CourseUnit;
    curriculumPeriods: CurriculumPeriod[];
    usesCourseUnitRealisation?: boolean;
}

export interface NamesById {
    [id: string]: string;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-version-selector',
    templateUrl: './course-unit-version-selector.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitVersionSelectorComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitInfoModal.courseUnitVersionSelector.downgraded',
        directiveName: 'sisCourseUnitVersionSelector',
    };

    @Output() change = new EventEmitter<CourseUnit>();
    @Input() courseUnitVersions: CourseUnitVersion[];
    @Input() currentCourseUnit: CourseUnit;
    @Input() displayNamesByCourseUnitId: NamesById;

    options: Option[] = [];

    constructor(private translateService: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.courseUnitVersions || changes.displayNamesByCourseUnitId) {
            const versions = (changes.courseUnitVersions?.currentValue as CourseUnitVersion[]) ?? this.courseUnitVersions;
            const displayNames = (changes.displayNamesByCourseUnitId?.currentValue as NamesById) ?? this.displayNamesByCourseUnitId;
            this.options = _.chain(versions)
                .sortBy('curriculumPeriods[0].activePeriod.startDate')
                .filter('courseUnit')
                .map(({ courseUnit, usesCourseUnitRealisation }) => ({
                    value: courseUnit.id,
                    label: _.get(displayNames, courseUnit.id),
                    info: usesCourseUnitRealisation ? this.translateService.instant('STUDIES.COURSE_UNIT_INFO_MODAL.USES_COURSE_UNIT_REALISATION') : undefined,
                }))
                .value();
        }
    }

    selectVersion(courseUnitId: OtmId): void {
        const version = _.chain(this.courseUnitVersions).map('courseUnit')
            .find({ id: courseUnitId })
            .value();
        this.change.emit(version);
    }
}
