import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';

import { MessageEntityService } from '../../service/message-entity.service';
import { StudyBoxType } from '../../study-boxes/study-box/study-box.component';

import {
    InputPlanMessage,
    isPlanCourseUnitComment,
    isPlanCustomStudyDraftComment,
    isPlanGenericComment,
    isPlanModuleComment,
    isStudyBoxMessage,
    StudyBoxMessage,
} from './plan-message-utils';

@Component({
    selector: 'sis-plan-message',
    templateUrl: './sis-plan-message.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SisPlanMessageComponent {

    private readonly messageEntityService = inject(MessageEntityService);

    protected readonly StudyBoxType = StudyBoxType;

    @Input({ required: true }) set message(message: InputPlanMessage) {
        this.planMessage = message;
        this.messageTypeTranslationKey = this.messageEntityService.getMessageTypeTranslationKey(message);
        this.senderFullName = `${message.senderFirstNames} ${message.senderLastName}`;
    }

    @Input({ required: true }) validatablePlan: ValidatablePlan;

    planMessage: InputPlanMessage;
    messageTypeTranslationKey: string;
    senderFullName: string;

    readonly hasStudyBox = isStudyBoxMessage;
    readonly isPlanGenericComment = isPlanGenericComment;
    readonly isPlanCourseUnitComment = isPlanCourseUnitComment;
    readonly isPlanCustomStudyDraftComment = isPlanCustomStudyDraftComment;
    readonly isPlanModuleComment = isPlanModuleComment;

    isSuggestionForAddition(message: StudyBoxMessage): boolean {
        return message.operationType === 'SUGGEST_ADD';
    }
}
