import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { CourseUnitRealisationNameService, PartialCUR } from 'sis-common/name/course-unit-realisation-name.service';

@Pipe({
    name: 'courseUnitRealisationFullName',
})
export class CourseUnitRealisationFullNamePipe implements PipeTransform {

    constructor(private courseUnitRealisationNameService: CourseUnitRealisationNameService) {
    }

    transform(value: PartialCUR): LocalizedString {
        if (!value) {
            return null;
        }
        return this.courseUnitRealisationNameService.generateFullName(value);
    }
}
