import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OtmId, StudyEvent } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { StudyEvent as GraphQLStudyEvent } from 'sis-common/generated/graphql';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-study-event-info-list',
    templateUrl: './study-event-info-list.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudyEventInfoListComponent {

    @Input() teacherIds?: OtmId[];

    @Input() set studyEvents(studyEvents: (StudyEvent | GraphQLStudyEvent)[]) {
        this.sortedEvents = _.sortBy(studyEvents?.filter(Boolean), 'startTime');
    }

    sortedEvents: (StudyEvent | GraphQLStudyEvent)[];

    readonly entityId = trackByEntityId;
}
