import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { LocalizedString, Message, Module, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { MessageTypes } from '../constant/messageTypes';
import { MessageConversationService } from '../service/message-conversation.service';
import { MessageEntityService, MessageRelation, MessageWithResolvedData } from '../service/message-entity.service';

export interface MessageTransition {
    target: 'Application' | 'MessageInPlan' | 'Attainment' | 'Calendar' | 'CourseUnitEvaluation' | 'Enrolment';
    message: Message;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-message',
    templateUrl: './sis-message.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SisMessageComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-common.messages.sisMessage',
        directiveName: 'sisMessage',
    };

    @Input() message: Message;

    @Input() isExpanded = false;

    @Output() expandEvent = new EventEmitter<Message>();

    @Output() transitionEvent = new EventEmitter<MessageTransition>();

    messageTypes = MessageTypes;
    suggestionForAddition: boolean;
    suggestedParentModule: Module;
    hideSenderStudentNumber: boolean;
    messageTitle: string;
    messageLocalizedTitle: LocalizedString;
    messageSenderName: LocalizedString;
    messageComment: string;
    messageLocalizedComment: LocalizedString;
    messageParentModule: Module;
    messageOperationType: string;
    messageTargetName: string;
    messageModuleId: OtmId;
    messageCourseUnitId: OtmId;
    messageTypeTranslationKey: string;
    senderFullName: string;
    isApplicationMsg: boolean;
    showPlanMessageBtn: boolean;

    constructor(private messageConversationService: MessageConversationService,
                private stateService: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private messageEntityService: MessageEntityService,
    ) {
    }

    ngOnInit() {
        this.messageTypeTranslationKey = this.getMessageTypeTranslationKey(this.message);
        this.senderFullName = this.getSenderFullName(this.message);
        this.isApplicationMsg = this.isApplicationMessage();
        this.showPlanMessageBtn = this.showPlanMessageButton();

        this.messageEntityService.loadRelations(this.message, [MessageRelation.COURSEUNIT, MessageRelation.PARENTMODULE, MessageRelation.MODULE])
            .subscribe((data: MessageWithResolvedData) => {
                const { parentModule, message } = data;
                this.messageTargetName = this.messageEntityService.getMessageTargetNameFromResolvedData(data);
                this.messageTitle = _.get(message, 'title');
                this.messageLocalizedTitle = _.get(message, 'localizedTitle');
                this.messageSenderName = _.get(message, 'senderName');
                this.messageComment = _.get(message, 'comment');
                this.messageLocalizedComment = _.get(message, 'localizedComment');
                this.messageParentModule = parentModule;
                this.messageOperationType = _.get(message, 'operationType');
                this.hideSenderStudentNumber = _.get(this.uiRouterGlobals.current, 'data.hideSenderStudentNumber') || false;
                this.suggestionForAddition = this.isSuggestionForAddition(message);
                this.messageModuleId = _.get(message, 'moduleId');
                this.messageCourseUnitId = _.get(message, 'courseUnitId');
                if (this.suggestionForAddition && this.isExpanded) {
                    this.getSuggestedParentModule();
                }
            });
    }

    isSuggestionForAddition(message: Message) {
        return _.isObject(message) && _.get(message, 'operationType') === 'SUGGEST_ADD' &&
            (_.get(message, 'courseUnitId') || _.get(message, 'moduleId'));
    }

    getSuggestedParentModule() {
        if (this.messageParentModule) {
            this.suggestedParentModule = this.messageParentModule;
        }
    }

    toggleExpanded(isCollapsed: boolean): void {
        if (isCollapsed === this.isExpanded) {
            if (!isCollapsed && this.suggestionForAddition && !this.suggestedParentModule) {
                this.getSuggestedParentModule();
            }
            this.expandEvent.emit(this.message);
        }
    }

    private getMessageTypeTranslationKey(message: Message): string {
        return this.messageEntityService.getMessageTypeTranslationKey(message);
    }

    private getSenderFullName(message: Message): string {
        return _.join(_.compact([message.senderFirstNames, message.senderLastName]), ' ');
    }

    goToApplication() {
        this.transitionEvent.emit({
            target: 'Application',
            message: this.message,
        });
    }

    openMessageInPlan() {
        this.transitionEvent.emit({
            target: 'MessageInPlan',
            message: this.message,
        });
    }

    openAttainment() {
        this.transitionEvent.emit({
            target: 'Attainment',
            message: this.message,
        });
    }

    goToCalendar() {
        this.transitionEvent.emit({
            target: 'Calendar',
            message: this.message,
        });
    }

    goToEnrolment() {
        this.transitionEvent.emit({
            target: 'Enrolment',
            message: this.message,
        });
    }

    goToCourseUnitEvaluation() {
        this.transitionEvent.emit({
            target: 'CourseUnitEvaluation',
            message: this.message,
        });
    }

    private showPlanMessageButton(): boolean {
        return !this.isApplicationMsg && !_.includes(
            [
                MessageTypes.AUTOMATIC_COURSE_UNIT_REALISATION_MESSAGE,
                MessageTypes.MANUAL_COURSE_UNIT_REALISATION_MESSAGE,
                MessageTypes.COURSE_UNIT_MANUAL_EVALUATION_REQUIRED_MESSAGE,
                MessageTypes.ENROLMENT_REMINDER_MESSAGE,
            ],
            this.message.type,
        );
    }

    private isApplicationMessage(): boolean {
        return this.messageConversationService.isApplicationMessage(this.message);
    }
}
