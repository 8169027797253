<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>
    <ng-template #curriculumPeriods>
      <div sis-curriculum-periods-details class="form-group-mimic" role="listitem" [attainment]="attainment"></div>
    </ng-template>

    <ng-template #verifierAndRegistrationDate>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.VERIFIER_AND_REGISTRATION_DATE') }}</dt>
        <dd>{{attainment.verifierPersonId ? (attainment.verifierPersonId | publicPerson | async | personFullNameReversed) : '-'}}, {{attainment.registrationDate | localDateFormat}}</dd>
      </div>
    </ng-template>

    <ng-template #administrativeNote>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ADMINISTRATIVE_NOTE') }}</dt>
        <dd>{{ attainment.administrativeNote || '-' }}</dd>
      </div>
    </ng-template>
  </sis-common-attainment-details>
</ng-container>
