import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CourseUnit, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { CourseUnitInfoVersion } from 'sis-components/courseUnitInfo/course-unit-info.service';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';

@Component({
    selector: 'app-course-unit-info-modal-header-version-selector',
    templateUrl: './course-unit-info-modal-header-version-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalHeaderVersionSelectorComponent {

    @Input() courseUnit: CourseUnit;
    @Input() options: SelectOption[];
    @Input() courseUnitVersions: CourseUnitInfoVersion[];
    @Output() setModalCourseUnitVersion = new EventEmitter<CourseUnit>();

    onCourseUnitVersionChange(courseUnitId: OtmId): void {
        const courseUnit = _.chain(this.courseUnitVersions).map('courseUnit')
            .find({ id: courseUnitId }).value();
        this.setModalCourseUnitVersion.emit(courseUnit);
    }
}
