"use strict";

import { StudyModuleEntityService } from 'sis-components/service/study-module-entity.service';
(function () {
  studyModuleModel.$inject = ["DS", "$q", "$log", "$rootScope", "jsDataRelationHelperService", "$injector"];
  angular.module('sis-components.model.studyModule', ['js-data', 'sis-components.model.gradeScale', 'sis-components.model.studyLevel', 'sis-components.model.curriculumPeriod', 'sis-components.model.officialLanguage', 'sis-components.model.studyField', 'sis-components.service.jsDataRelationHelper', StudyModuleEntityService.downgrade.moduleName]).factory('studyModuleJSDataModel', studyModuleModel).run(["studyModuleJSDataModel", function (studyModuleJSDataModel) {}]);

  /**
   * @ngInject
   */
  function studyModuleModel(DS, $q, $log, $rootScope, jsDataRelationHelperService, $injector) {
    function loadStudyModuleUniversityOrgsPersonAndOrganisationRelations(studyModule) {
      var relationLoads = [jsDataRelationHelperService.loadOrganisationRelations(studyModule.organisations), jsDataRelationHelperService.loadModuleResponsibilityInfoRelations(studyModule.responsibilityInfos), jsDataRelationHelperService.loadUniversityOrgs([studyModule])];
      return $q.all(relationLoads).then(function () {
        return studyModule;
      }, function (error) {
        $log.warn("Failed to load studyModule organisations and person relations", studyModule, error);
      });
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'studyModule',
      endpoint: '/kori/api/study-modules',
      relations: {
        hasOne: {
          gradeScale: {
            localField: 'gradeScale',
            localKey: 'gradeScaleId'
          },
          studyLevel: {
            localField: 'studyLevelLocal',
            localKey: 'studyLevel'
          }
        },
        hasMany: {
          organisation: {
            localField: 'universityOrgs',
            localKeys: 'universityOrgIds'
          },
          curriculumPeriod: {
            localField: 'curriculumPeriods',
            localKeys: 'curriculumPeriodIds'
          },
          officialLanguage: {
            localField: 'attainmentLanguages',
            localKeys: 'possibleAttainmentLanguages'
          },
          studyField: {
            localField: 'studyFieldsLocal',
            localKeys: 'studyFields'
          }
        }
      },
      methods: {
        loadRelations: function () {
          return loadStudyModuleUniversityOrgsPersonAndOrganisationRelations(this);
        }
      },
      beforeUpdate: function (resource, studyModule, cb) {
        if (studyModule.contentDescription) {
          studyModule.contentDescription = _.chain(studyModule.contentDescription).omitBy(_.isUndefined).omitBy(_.isNull).value();
        }
        if (studyModule.validityPeriod && !studyModule.validityPeriod.startDate && !studyModule.validityPeriod.endDate) {
          studyModule.validityPeriod = undefined;
        }
        cb(null, studyModule);
      },
      afterUpdate: function (resource, studyModule, cb) {
        const studyModuleEntityService = $injector.get('studyModuleEntityService');
        studyModuleEntityService.storeUpsert(_.cloneDeep(studyModule));
        $rootScope.$broadcast('StudyModuleChange', studyModule);
        return loadStudyModuleUniversityOrgsPersonAndOrganisationRelations(studyModule).then(function () {
          cb(null, studyModule);
        });
      },
      afterCreate: (resource, studyModule, cb) => {
        const studyModuleEntityService = $injector.get('studyModuleEntityService');
        studyModuleEntityService.storeUpsert(_.cloneDeep(studyModule));
        $rootScope.$broadcast('StudyModuleChange', studyModule);
        cb(null, studyModule);
      }
    });
  }
})();