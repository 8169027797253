import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnDestroy,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FudisSelectOption } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    AnyCourseUnitRule,
    CourseUnitResultItem,
    EntityWithRule,
    OtmId,
} from 'common-typescript/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { CreditRangePipe } from '../../../number/credit-range.pipe';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../../plan/plan-actions-service/plan-actions.service';
import { ReadMoreModalService } from '../../../read-more-ng/read-more-modal.service';
import { ReadMoreValues } from '../../../read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { CourseUnitEntityService, CourseUnitSearchQueryParams } from '../../../service/course-unit-entity.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

interface CourseUnitSearchOptionType {
    value: CourseUnitResultItem,
    label: string
    subLabel?: string
}

@Component({
    selector: 'sis-plan-structure-any-course-unit-rule',
    templateUrl: './plan-structure-any-course-unit-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAnyCourseUnitRuleComponent implements OnDestroy {
    @Input({ required: true }) rule: AnyCourseUnitRule;
    @Input({ required: true }) parentModule: EntityWithRule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';
    @Input({ required: true }) currentUniversityOrgId: OtmId;
    @Input({ required: true }) crossStudyFeaturesEnabled: boolean;
    @Input({ required: true }) cooperationNetworkIds: OtmId[];
    @Input({ required: true }) curriculumPeriodIds: OtmId[];

    courseUnitSelectControl = new FormControl<null | FudisSelectOption<CourseUnitResultItem>>(null);
    ripaStudiesIncluded = false;

    private destroy$ = new Subject<void>();
    protected searchTextUpdateSubject = new Subject<string | null>();
    protected searchResults = new BehaviorSubject<FudisSelectOption<CourseUnitSearchOptionType>[]>([]);

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private creditRangePipe: CreditRangePipe,
        private translocoService: TranslocoService,
        private readMoreModalService: ReadMoreModalService,
        @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
        this.searchTextUpdateSubject.pipe(
            tap(() => this.searchResults?.next([])),
            filter(textQuery => textQuery?.trim() !== ''),
            switchMap(textQuery => this.searchCourseUnits(textQuery)),
            takeUntil(this.destroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openAddStudyDraftModal() {
        this.planActionsService.openCustomStudyDraftCreationModal(this.parentModule);
    }

    openReadMoreAboutStudyDraftModal() {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: null,
                contentTranslateKey: 'PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_READ_MORE_DESCRIPTION',
                contentTranslateParameters: null,
                title: this.translocoService.translate('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_READ_MORE_TITLE'),
            },
        };
    }

    searchCourseUnits(textQuery: string): Observable<CourseUnitSearchOptionType[]> {
        const searchParameters = {
            fullTextQuery: textQuery,
            orgRootId: this.currentUniversityOrgId,
            curriculumPeriodId: this.curriculumPeriodIds,
            validity: 'ONGOING_AND_FUTURE',
            limit: 100,
            start: 0,
        };

        const cooperationNetworkParameters = this.ripaStudiesIncluded ? {
            cooperationNetworkId: this.cooperationNetworkIds,
            cooperationNetworkSearchType: 'COURSE_UNITS_COMBINED_WITH_COOPERATION_NETWORKS',
            validityInCooperationNetwork: 'ONGOING',
        } : {};

        const queryParams = Object.assign(searchParameters, cooperationNetworkParameters) as CourseUnitSearchQueryParams;

        return this.courseUnitEntityService.searchActive(queryParams)
            .pipe(
                map(courseUnits => courseUnits.searchResults.map(courseUnit => ({
                    value: courseUnit,
                    label: `${courseUnit.name} (${this.creditRangePipe.transform(courseUnit.credits)})`,
                    subLabel: `${courseUnit.code} | ${this.translocoService.translate('SIS_COMPONENTS.STUDY.COURSE_UNIT')}`,
                }))),
                tap(opts => {
                    this.searchResults?.next(opts);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    toggleRipaStudiesForSearch = (ripaStudiesIncluded: boolean) => {
        this.ripaStudiesIncluded = ripaStudiesIncluded;
    };

    updateSelected(option: FudisSelectOption<CourseUnitSearchOptionType>): void {
        // TODO: add the selected option to the course units shown underneath the search
    }
}
