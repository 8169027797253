<div class="clear">
  <ul class="tree-list tree-list-study-plan clearfix">
    <li class="{{classes}}">
      <div class="module-border" [class.collapsed]="!isOpen" [class.module-border-selected]="uiStateObject.selectedModule?.id === education.id">
        <div class="tree-list-heading tree-list-heading-has-children clearfix no-padding" ng-class="$ctrl.moduleTargetStates()">
          <div class="accordion-panel accordion-panel-long-title clickable"
               [class.accordion-panel-primary]="uiStateObject.selectedModule?.id === education.id"
               [class.accordion-panel-noblock]="uiStateObject.selectedModule?.id !== education.id">
            <div class="header has-header-items">
              <button type="button"
                      class="collapse non-button"
                      (click)="isOpen = !isOpen"
                      [class.collapsed]="isOpen"
                      [attr.aria-expanded]="isOpen"
                      [attr.aria-label]="(education.name | localizedString)">
                <sis-icon icon="chevron-ring-fill" [iconClass]="isOpen ? 'cw-90' : null"></sis-icon>
              </button>
              <div class="title" (click)="handleHeaderClick()">
                <span class="plan-module-name">{{ education.name | localizedString }}</span>
                <span class="additional">
                  <span *ngIf="education.documentState !== 'ACTIVE'"
                        class="tiny-badge"
                        [class.accent]="education.documentState === 'DRAFT'"
                        [class.danger]="education.documentState === 'DELETED'"
                        [translate]="('VISIBLE_DOCUMENT_STATE.' + education.documentState)">
                  </span>
                  {{ education.code }}
                </span>
              </div>
              <ng-container *ngIf="educationValidationResult.state!=='ATTAINED'">
                <div class="cell status">
                  <div *ngIf="educationValidationResult.state === 'INVALID'"
                       [class.state-gray]="uiStateObject.selectedModule?.id === education.id"
                       [class.state-invalid]="uiStateObject.selectedModule?.id !== education.id">
                    <sis-icon icon="alert-fill"></sis-icon>
                    <span translate="SIS_COMPONENTS.PLAN_STATE.INVALID"></span>
                  </div>
                  <div *ngIf="educationValidationResult.state !== 'INVALID'" class="state">
                    {{'SIS_COMPONENTS.PLAN_STATE.' + educationValidationResult.state | translate}}
                  </div>
                </div>
                <div class="cell module-credits"></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="isOpen" class="tree-list-body" [class.collapsed]="!isOpen">
          <div class="row">
            <ng-container *ngFor="let courseUnitId of planStateObject.education.selectedCourseUnitIds">
              <sis-plan-course-unit *ngIf="!planStateObject.courseUnits[courseUnitId].isSubstituted"
                                    [courseUnit]="planData.courseUnitsById[courseUnitId]"
                                    [parentModule]="education"
                                    [planValidationResult]="planValidationResult"
                                    [planStateObject]="planStateObject"
                                    [planData]="planData">
              </sis-plan-course-unit>
              <div *ngIf="planStateObject.courseUnits[courseUnitId].isSubstituted"
                   [class.courseunit-substitution-group]="planStateObject.courseUnits[courseUnitId].substitutedBy.length > 1">
                <sis-plan-course-unit *ngFor="let substituteCourseUnitId of planStateObject.courseUnits[courseUnitId].substitutedBy"
                                      [courseUnit]="planData.courseUnitsById[substituteCourseUnitId]"
                                      [planValidationResult]="planValidationResult"
                                      [planStateObject]="planStateObject"
                                      [planData]="planData"
                                      [substitutedCourseUnitId]="courseUnitId"></sis-plan-course-unit>
              </div>
            </ng-container>
            <sis-plan-custom-study-draft *ngFor="let customStudyDraftId of planStateObject.education.selectedCustomStudyDraftIds"
                                         [customStudyDraft]="planData.customStudyDraftsById[customStudyDraftId]"
                                         [planStateObject]="planStateObject"></sis-plan-custom-study-draft>
            <sis-plan-custom-course-unit-attainment *ngFor="let customCourseUnitAttainmentId of planStateObject.education.selectedCustomCourseUnitAttainmentIds"
                                                    [customCourseUnitAttainment]="$any(planData.attainmentsById[customCourseUnitAttainmentId])"
                                                    [planStateObject]="planStateObject"></sis-plan-custom-course-unit-attainment>
          </div>
        </div>
        <div *ngIf="isOpen">
          <sis-plan-module *ngFor="let moduleId of planStateObject.education.selectedModuleIds"
                           [module]="planData.modulesById[moduleId]"
                           [planValidationResult]="planValidationResult"
                           [planStateObject]="planStateObject"
                           [planData]="planData"
                           [uiStateObject]="uiStateObject"
                           [isImplicit]="false"
                           [level]="level+1"></sis-plan-module>
          <sis-plan-custom-module-attainment *ngFor="let customModuleAttainmentId of planStateObject.education.selectedCustomModuleAttainmentIds"
                                             [customModuleAttainment]="$any(planData.attainmentsById[customModuleAttainmentId])"
                                             [planStateObject]="planStateObject"
                                             [planData]="planData"
                                             [uiStateObject]="uiStateObject"
                                             [level]="level+1"></sis-plan-custom-module-attainment>
        </div>
      </div>
    </li>
  </ul>
</div>


