<ng-container *transloco="let t">
  @if (ruleModule()) {
    @if (ruleModule().type === 'StudyModule') {
      <sis-plan-structure-study-module-box [module]="$any(ruleModule())"
                                           [planStateObject]="planStateObject()"
                                           [planData]="planData()"
                                           [selected]="ruleValidationResult()?.active"
                                           [action]="action()"
                                           (actionChange)="handleToggle()"
                                           [headingLevel]="headingLevel()"
                                           [actionInputId]="rule().localId"
                                           [actionInputName]="actionInputName()"
                                           [inputType]="actionInputType()">
        @if (showMandatorySelection()) {
          <sis-study-notification-box>
            {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
          </sis-study-notification-box>
        }
      </sis-plan-structure-study-module-box>
    } @else if (ruleModule().type === 'DegreeProgramme') {
      <sis-plan-structure-degree-programme-box [module]="$any(ruleModule())"
                                               [planStateObject]="planStateObject()"
                                               [planData]="planData()"
                                               [selected]="ruleValidationResult()?.active"
                                               [action]="action()"
                                               (actionChange)="handleToggle()"
                                               [headingLevel]="headingLevel()"
                                               [actionInputId]="rule().localId"
                                               [actionInputName]="actionInputName()"
                                               [inputType]="actionInputType()">
        @if (showMandatorySelection()) {
          <sis-study-notification-box>
            {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
          </sis-study-notification-box>
        }
      </sis-plan-structure-degree-programme-box>
    } @else if (ruleModule().type === 'GroupingModule') {
      <p>PLACEHOLDER: GroupingModule</p>
    }
  }
</ng-container>
