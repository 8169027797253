import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService, FudisValidators } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { dateUtils, ISO_LOCAL_DATE_FORMAT, MaxLength } from 'common-typescript/constants';
import {
    OtmId,
    Passport,
} from 'common-typescript/types';
import moment from 'moment/moment';
import { forkJoin, take } from 'rxjs';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { PassportEntityService } from '../../service/passport-entity.service';

export interface EditPassportDialogData {
    studentId: OtmId,
    passports: Passport[],
}

interface PassportFormControls {
    id: FormControl<OtmId>,
    passportNumber: FormControl<string>,
    issuer: FormControl<string>,
    validityPeriodStartDate: FormControl<Date | null>,
    validityPeriodEndDate: FormControl<Date | null>;
}

interface PassportFormObject {
    id: OtmId,
    passportNumber: string,
    issuer: string,
    validityPeriodStartDate: Date | null,
    validityPeriodEndDate: Date | null;
}

interface PassportEditForm {
    passports: FormArray<FormGroup<PassportFormControls>>
}

export function createPassportEditModalOpener(): (studentId: OtmId, passports: Passport[]) => MatDialogRef<EditPassportModalComponent, { data: object }> {
    const fudisDialogService = inject(FudisDialogService);
    return (studentId: OtmId, passports: Passport[]) =>
        fudisDialogService.open(EditPassportModalComponent, { data: { studentId, passports } });
}

@Component({
    selector: 'sis-edit-passport-modal',
    templateUrl: './edit-passport-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPassportModalComponent implements OnInit {

    private fudisDialogService = inject(FudisDialogService);
    private fb = inject(SisFormBuilder);
    private translocoService = inject(TranslocoService);
    private passportEntityService = inject(PassportEntityService);
    private appErrorHandler = inject(AppErrorHandler);

    form: FormGroup<PassportEditForm>;
    values: EditPassportDialogData = inject(MAT_DIALOG_DATA);
    deletedPassportIds: OtmId[] = [];

    ngOnInit(): void {
        this.buildForm();
    }

    private buildForm(): void {
        this.form = this.fb.group({
            passports: this.fb.array<FormGroup<PassportFormControls>>([]),
        });
        if (this.values.passports?.length) {
            this.values.passports.forEach((passport) => this.passports.push(this.addPassport(passport)));
        }
    }

    addPassport(passport: Passport): FormGroup {
        const existingValidityPeriodStart = passport?.validityPeriod?.startDate;
        const existingValidityPeriodEnd = passport?.validityPeriod?.endDate;

        const existingStartDate = existingValidityPeriodStart ? new Date(moment(existingValidityPeriodStart).format(ISO_LOCAL_DATE_FORMAT)) : null;
        const existingEndDate = existingValidityPeriodEnd ? new Date(moment(existingValidityPeriodEnd).subtract(1, 'days').format(ISO_LOCAL_DATE_FORMAT)) : null;

        return this.fb.group<PassportFormControls>({
            id: this.fb.control<OtmId>(passport?.id),
            passportNumber: this.fb.control(passport?.passportNumber, ([
                FudisValidators.maxLength(
                    MaxLength.MAX_SHORT_STRING_LENGTH,
                    this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: MaxLength.MAX_SHORT_STRING_LENGTH },
                    ),
                ),
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
            issuer: this.fb.control(passport?.issuer, ([
                FudisValidators.minLength(3, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN_LENGTH', { minLength: 3 })),
                FudisValidators.maxLength(3, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: 3 })),
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
            validityPeriodStartDate: this.fb.control<Date>(
                existingStartDate ?? null,
                [FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'))],
            ),
            validityPeriodEndDate: this.fb.control<Date>(
                existingEndDate ?? null,
                [FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'))],
            ),
        });
    }

    get passports() {
        return this.form.controls.passports;
    }

    save(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
        } else if (this.form.pristine) {
            this.fudisDialogService.close();
        } else {
            this.sendRequests();
            this.fudisDialogService.close();
        }
    }

    sendRequests(): void {
        const currentPassports = this.passportFormObjectsToPassports();

        const newPassports = currentPassports
            .filter((passport: Passport) => !passport.id)
            .map((passport: Partial<Passport>) => ({
                ...passport,
                personId: this.values.studentId }));
        const existingPassports = currentPassports.filter((passport: Passport) => passport.id);
        const modifiedPassports = existingPassports.filter((passport: Passport) =>
            this.extractModifiedPassportIds().includes(passport.id),
        );

        const observables: any = [];
        newPassports.forEach((passport: Partial<Passport>) => observables.push(
            this.passportEntityService.createPassport(passport)));
        modifiedPassports.forEach((passport: Partial<Passport>) => observables.push(
            this.passportEntityService.updatePassport(passport.id, passport)));
        this.deletedPassportIds.forEach((id: OtmId) => observables.push(
            this.passportEntityService.deletePassport(id)));

        forkJoin(observables)
            .pipe(take(1),
                  this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => {
            });
    }

    add(): void {
        this.passports.push(this.addPassport(null));
    }

    delete(index: number): void {
        const passport = this.passports.at(index).value;
        if (passport.id) {
            this.deletedPassportIds.push(passport.id);
        }
        this.passports.removeAt(index);
        this.form.markAsDirty();
    }

    private extractModifiedPassportIds(): OtmId[] {
        const modified: OtmId[] = [];
        this.passports.controls.forEach((group, index) => {
            if (group.dirty) {
                modified.push(this.passports.at(index).get('id').value);
            }
        });
        return modified;
    }

    private passportFormObjectsToPassports(): Partial<Passport>[] {
        return this.passports.value.map(
            (passport: PassportFormObject) => {
                const startDate = passport.validityPeriodStartDate;
                const formattedStartDate = dateUtils.convertDateToIsoLocalDate(startDate);

                const endDate = moment(passport.validityPeriodEndDate).add(1, 'days').toDate();
                const formattedEndDate = dateUtils.convertDateToIsoLocalDate(endDate);

                const validityPeriod = { startDate: formattedStartDate, endDate: formattedEndDate };
                return {
                    id: passport.id,
                    passportNumber: passport.passportNumber,
                    issuer: passport.issuer,
                    validityPeriod,
                };
            },
        );
    }
}
