import angular from 'angular';
import * as _ from 'lodash-es';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
import { commonEnrolmentStateServiceModule } from 'sis-components/service/enrolmentState.service';
import { enrolmentModelModule } from 'sis-components/model/enrolment.model';
import { enrolmentValidationServiceModule } from './enrolmentValidation.service';
export const commonEnrolmentServiceModule = 'sis-components.service.enrolmentService';
(function () {
  commonEnrolmentService.$inject = ["$q", "$log", "$http", "$rootScope", "ENROLMENT_URL", "enrolmentJSDataModel", "jsDataRelationHelperService", "assessmentItemJSDataModel", "courseUnitRealisationJSDataModel", "courseUnitJSDataModel", "AuthService", "uuidService", "ENROLMENT_COLORS", "enrolmentServiceEventType", "enrolmentValidationService"];
  angular.module(commonEnrolmentServiceModule, ['sis-common.auth', UuidService.downgrade.moduleName, enrolmentModelModule, 'sis-components.model.courseUnitRealisation', 'sis-components.model.courseUnit', 'sis-components.model.assessmentItem', 'sis-components.service.jsDataRelationHelper', enrolmentValidationServiceModule, commonEnrolmentStateServiceModule]).constant('enrolmentServiceEventType', {
    updated: 'enrolmentService.enrolmentsUpdated'
  }).factory('commonEnrolmentService', commonEnrolmentService);

  /**
   * @ngInject
   */
  function commonEnrolmentService($q, $log, $http, $rootScope, ENROLMENT_URL, enrolmentJSDataModel, jsDataRelationHelperService, assessmentItemJSDataModel, courseUnitRealisationJSDataModel, courseUnitJSDataModel, AuthService, uuidService, ENROLMENT_COLORS, enrolmentServiceEventType, enrolmentValidationService) {
    function getLeastUsedEnrolmentColorIndex() {
      let usedColorIndexes = [];
      for (let i = 0; i < ENROLMENT_COLORS.length; i++) {
        usedColorIndexes.push({
          index: i,
          count: 0
        });
      }
      const usedColorIndexesMap = _.keyBy(usedColorIndexes, 'index');
      _.forEach(enrolmentJSDataModel.getAll(), enrolment => {
        if (_.has(usedColorIndexesMap, enrolment.colorIndex)) {
          usedColorIndexesMap[enrolment.colorIndex].count += 1;
        }
      });
      usedColorIndexes = _.orderBy(usedColorIndexes, usedColorIndex => usedColorIndex.count);
      return _.first(usedColorIndexes).index;
    }
    const api = {
      deleteEnrolment(enrolment) {
        return enrolmentJSDataModel.destroy(enrolment.id).then(response => {
          $rootScope.$broadcast(enrolmentServiceEventType.updated);
          return response;
        });
      },
      refreshEnrolmentIfChanged(enrolmentId, revision) {
        const current = enrolmentJSDataModel.get(enrolmentId);
        if (!current || current.metadata.revision >= revision) {
          return $q.reject();
        }
        return enrolmentJSDataModel.find(enrolmentId, {
          bypassCache: true,
          cacheResponse: true
        }).then(response => {
          $rootScope.$broadcast(enrolmentServiceEventType.updated);
          return response;
        });
      },
      updateEnrolment(enrolment) {
        return enrolmentJSDataModel.save(enrolment).then(response => {
          $rootScope.$broadcast(enrolmentServiceEventType.updated);
          return response;
        });
      },
      createOwnEnrolment(courseUnitRealisation, assessmentItem, courseUnit) {
        // Allow ILMO backend to allocate enrolment id
        const enrolment = {
          personId: AuthService.personId(),
          courseUnitRealisationId: _.get(courseUnitRealisation, 'id'),
          assessmentItemId: _.get(assessmentItem, 'id'),
          courseUnitId: _.get(courseUnit, 'id'),
          state: 'NOT_ENROLLED',
          processingState: 'NOT_PROCESSED',
          isInCalendar: true
        };
        if (courseUnitRealisation) {
          enrolment.studySubGroups = enrolmentValidationService.createStudySubGroupsForEnrolment(courseUnitRealisation);
        }
        enrolment.colorIndex = getLeastUsedEnrolmentColorIndex();
        return api.createEnrolment(enrolment);
      },
      createEnrolment(enrolment) {
        return enrolmentJSDataModel.create(enrolment).then(response => {
          $rootScope.$broadcast(enrolmentServiceEventType.updated);
          return response;
        });
      },
      findMyEnrolments(bypassCache, loadRelations) {
        const params = {};
        const options = {
          bypassCache: !!bypassCache,
          endpoint: ENROLMENT_URL.MY_ENROLMENTS
        };
        return api.findEnrolments(params, options, loadRelations);
      },
      getStudentsEnrolmentsForCourseUnitRealisations(courseUnitRealisationIds, bypassCache, loadRelations) {
        if (_.isEmpty(courseUnitRealisationIds)) {
          return $q.when([]);
        }
        const params = {
          courseUnitRealisationId: courseUnitRealisationIds
        };
        const options = {
          bypassCache: !!bypassCache,
          endpoint: ENROLMENT_URL.MY_ENROLMENTS
        };
        return api.findEnrolments(params, options, !!loadRelations);
      },
      findByPersonId(personId, bypassCache, loadRelations) {
        const params = {
          personId
        };
        const options = {
          bypassCache: !!bypassCache
        };
        return api.findEnrolments(params, options, loadRelations);
      },
      findEnrolments(params, options, loadRelations) {
        return enrolmentJSDataModel.findAll(params, options).then(enrolments => {
          if (loadRelations) {
            const bypassCacheWithRelations = false;
            return api.loadEnrolmentRelations(enrolments, bypassCacheWithRelations);
          }
          return enrolments;
        });
      },
      /**
       * Loads assessment items, course units and course unit realisations.
       */
      loadEnrolmentRelations(enrolments, bypassCache) {
        if (_.isEmpty(enrolments)) {
          return $q.when([]);
        }
        const relations = [courseUnitJSDataModel, courseUnitRealisationJSDataModel, assessmentItemJSDataModel];
        function loadRelations(jsDataModel) {
          return jsDataRelationHelperService.loadRelationForArray(jsDataModel, enrolmentJSDataModel, enrolments, !!bypassCache);
        }
        const loads = _.map(relations, loadRelations);
        return $q.all(loads).then(() => enrolments);
      },
      enrol(enrolment, answers) {
        const payload = {
          enrolmentId: enrolment.id,
          studySubGroups: enrolment.studySubGroups,
          studyRightId: enrolment.studyRightId,
          answers
        };
        return $http.post(enrolUrl(enrolment), payload).then(injectResponseToJsDataModel);
      },
      cancelEnrolment(enrolment) {
        return $http.post(cancelEnrolmentUrl(enrolment), {}).then(injectResponseToJsDataModel);
      },
      abortOwnEnrolment(enrolment) {
        return $http.post(abortOwnEnrolmentUrl(enrolment), {}).then(injectResponseToJsDataModel);
      },
      revert(enrolment) {
        enrolmentJSDataModel.revert(enrolment.id);
      },
      injectToJsData(enrolment) {
        enrolmentJSDataModel.inject(enrolment);
      }
    };
    function injectResponseToJsDataModel(response) {
      return enrolmentJSDataModel.inject(response.data);
    }
    function enrolUrl(enrolment) {
      return `/ilmo/api/enrolments/${enrolment.id}/enrol`;
    }
    function cancelEnrolmentUrl(enrolment) {
      return `/ilmo/api/enrolments/${enrolment.id}/cancel-enrolment`;
    }
    function abortOwnEnrolmentUrl(enrolment) {
      return `/ilmo/api/enrolments/${enrolment.id}/abort-own-enrolment`;
    }
    return api;
  }
})();