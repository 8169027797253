<ng-container *transloco="let t">
  <label [for]="id" class="simple-radio-button">
    <input type="radio"
           class="simple-radio-button__input"
           [name]="name"
           [attr.name]="name"
           [value]="selected"
           [attr.id]="id"
           [attr.aria-labelledby]="id"
           (change)="selectedChange.emit($event)"
    />
    <div class="simple-radio-button__content">
      <div class="simple-radio-button__content-wrapper">
        <div class="simple-radio-button__content__value">
          <span
            *ngIf="selected"
            class="simple-radio-button__content__value__indicator">
          </span>
        </div>
      </div>
      <span *ngIf="label || visuallyHiddenInfo" [attr.id]="id" class="simple-radio-button__label">
        {{ t(label) }}
        <span *ngIf="visuallyHiddenInfo" class="visually-hidden">{{visuallyHiddenInfo | translate}}</span>
      </span>
    </div>
  </label>
</ng-container>
