import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, signal } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/angular';
import { UniversitySettings } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'sis-common/config/config.service';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { SisFocusModeParams, SisStateObject } from '../router/types';

import { UniversityService } from './university.service';

const locations = {
    STUDENT: '/student',
    TEACHER: '/teacher',
    // Navigating in staff context from teacher to staff role doesn't work unless full path given
    STAFF: '/staff/studies/staff/frontpage',
    ADMIN: '/admin',
};

export const navUrls = {
    STUDENT_URL: locations.STUDENT,
    STUDENT_PLAN_URL: `${locations.STUDENT}/plan`,
    TEACHER_URL: locations.TEACHER,
    ADMIN_URL: locations.ADMIN,
    STAFF_URL: locations.STAFF,
};

export interface FocusMode {
    exitState?: string;
    exitStateParams?: any;
    exitUrl?: string;
    titleKey: string;
}

/**
 * Traverses the router state hierarchy from the given state up towards the root state, and returns the first
 * encountered focus mode params.
 */
export function findClosestFocusModeParams(state: SisStateObject): SisFocusModeParams | null {
    return state?.focusMode ?? (state?.parent ? findClosestFocusModeParams(state.parent) : null);
}

@Injectable({
    providedIn: 'root',
})
export class NavUtilsService {

    readonly focusMode = signal<FocusMode>(null);

    private location: Location;

    constructor(private configService: ConfigService,
                private universityService: UniversityService,
                private localeService: LocaleService,
                private transitions: TransitionService,
                @Inject(DOCUMENT) protected document: Document) {
        this.location = document.defaultView.location;
    }

    init(): void {
        this.transitions.onSuccess({}, transition => this.updateFocusMode(transition));
    }

    getMessagesHref(): string {
        const messageUrl = '/message-conversations/all';
        const url = this.getRouteRoleContextPath();

        switch (url) {
            case '/teacher/role/teacher':
            case '/teacher/role/staff':
            case '/tutor/role/teacher':
            case '/tutor/role/staff':
            case '/staff/studies/staff':
            case '/staff/studies/teacher':
                return `${url}${messageUrl}`;
            case '/student/plan/preview':
                return `/staff/studies/staff${messageUrl}`;
            default:
                return `${_.startsWith(url, '/student') ? '/student' : `${this.getContextPath()}`}${messageUrl}`;
        }
    }

    getHelpUrl(): Observable<string> {
        return this.universityService.getCurrentUniversitySettings().pipe(
            map((settings: UniversitySettings) => {
                const currentApp = _.split(this.getSelectedContextPath().substring(1), '/');
                const baseUrl = _.get(settings.helpUrls, currentApp) || _.get(settings.helpUrls, 'default');
                const lang = this.localeService.getCurrentLanguage();
                const view = _.split(this.location.pathname.substring(1), '/')[0];
                return `${baseUrl}?role=${currentApp}&view=${view}&lang=${lang}`;
            }));
    }

    getContextPath(): string {
        return this.location.pathname.substring(0, this.location.pathname.indexOf('/', 2));
    }

    getRouteRoleContextPath(): string {
        return this.location.pathname.split('/').slice(0, 4).join('/');
    }

    /**
     * When we're under an explicitly known teacher/staff path, returns {@link locations.TEACHER} or
     * {@link locations.STAFF}. Otherwise, returns the root path (presumably one of {@link locations} constants).
     */
    getSelectedContextPath(): string {
        const url = this.getRouteRoleContextPath();
        if (url === '/teacher/role/teacher' || url === '/staff/studies/teacher' || url === '/tutor/role/teacher') {
            return locations.TEACHER;
        }
        if (url === '/teacher/role/staff' || url === '/staff/studies/staff' || url === '/tutor/role/staff') {
            return locations.STAFF;
        }
        if (url === '/student/plan/preview') {
            return locations.STAFF;
        }

        return this.getContextPath();
    }

    getHomeUrl(): string {
        let homeUrl = this.getSelectedContextPath();

        if (_.startsWith(homeUrl, locations.STUDENT)) {
            const studentFrontpageRedirectUrl = _.get(this.configService.get(), 'studentFrontpageRedirectUrl');
            if (studentFrontpageRedirectUrl) {
                homeUrl = studentFrontpageRedirectUrl;
            }
        }

        return homeUrl;
    }

    private updateFocusMode(transition: Transition): void {
        const focusMode = findClosestFocusModeParams(transition.$to());
        if (focusMode) {
            if (this.focusMode()) {
                // Transitioning from one focus mode state to another (e.g. wizard with multiple steps,
                // each having an own router state) -> only update the title. The exit state should remain
                // as the state from which the user originally entered the focus mode.
                this.focusMode.set({ ...this.focusMode(), titleKey: focusMode.titleKey });
            } else {
                // Entering a new focus mode
                const exitUrl = focusMode.exitUrlResolver?.(transition);
                if (exitUrl) {
                    this.focusMode.set({ exitUrl, titleKey: focusMode.titleKey });
                } else {
                    this.focusMode.set({
                        exitState: transition.from().name?.length ? transition.from().name : focusMode.defaultExitState,
                        exitStateParams: { ...transition.params('from') },
                        titleKey: focusMode.titleKey,
                    });
                }
            }
        } else {
            this.focusMode.set(null);
        }
    }
}
