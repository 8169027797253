import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import {
    DegreeProgramme,
    GroupingModule,
    ModuleContentApplication,
    PlanValidationState,
    StudyModule,
    StudyRight,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { take } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

import { UI_CONTEXT } from '../../../../ajs-upgraded-modules';
import {
    moduleContentWorkflowApplicationModalOpener,
} from '../../../../plan/module-content-workflow-modal/module-content-workflow-modal.component';
import {
    ShowViewModuleContentWorkflowLinkService,
} from '../show-view-module-content-workflow-link.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-apply-for-module-content-workflow',
    templateUrl: './apply-for-module-content-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyForModuleContentWorkflowComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.showViewModuleContentApplicationLink.applyForModuleContentWorkflow',
        directiveName: 'appApplyForModuleContentWorkflow',
    };

    @Input() module: StudyModule | DegreeProgramme | GroupingModule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() isFreeEdit: boolean;
    @Input() matchingStudyRight: StudyRight;

    moduleContentApplication: ModuleContentApplication;
    studentViewableModuleContentApplication: ModuleContentApplication;
    statusCode: string;
    moduleContentApplicationRequired: boolean;

    private readonly moduleContentWorkflowApplicationModalOpener = moduleContentWorkflowApplicationModalOpener();

    constructor(
        private errorHandler: AppErrorHandler,
        private showViewModuleContentWorkflowLinkService: ShowViewModuleContentWorkflowLinkService,
        @Inject(UI_CONTEXT) private uiContext: any,
        private translocoService: TranslocoService,
    ) {
    }

    ngOnChanges(): void {
        this.moduleContentApplication = null;
        if (this.validatablePlan && this.module) {
            this.moduleContentApplication = this.validatablePlan.getEffectiveModuleContentApproval(this.module.id);
        }
        this.studentViewableModuleContentApplication = this.showViewModuleContentWorkflowLinkService.getStudentViewableModuleContentWorkflow(this.validatablePlan, this.module);
        this.statusCode = this.getStatusCode();
        this.moduleContentApplicationRequired = this.isModuleContentApplicationRequired();
    }

    translationExists(key: string) {
        return this.translocoService.translate(key) !== key;
    }

    hasStudentViewableModuleContentApplication() {
        return !!this.studentViewableModuleContentApplication;
    }

    canApplyForModuleContentApproval() {
        if (!this.moduleContentApplicationRequired) {
            const contextualState = this.getModuleValidationResult()?.contextualState;
            if (contextualState && !this.isModuleContextualStateApplicable(contextualState)) {
                return false;
            }
        }
        if (!this.isEmptyOrRejectedStudentViewableModuleContentApplication()) {
            return false;
        }

        const isInvalidContent = (this.statusCode === 'FREE_EDIT.APPROVAL_REQUIRED.INVALID_CONTENTS');

        const isApprovalNotRequired = (this.statusCode === 'FREE_EDIT.APPROVAL_NOT_REQUIRED.INVALID_CONTENTS');

        const isRequiredAndNotFreeEdit = (!this.isFreeEdit && this.moduleContentApplicationRequired);

        const isRequiredAndFreeEditWithInvalidContent = (this.isFreeEdit && this.moduleContentApplicationRequired && isInvalidContent);

        return (isRequiredAndNotFreeEdit || isRequiredAndFreeEditWithInvalidContent || isApprovalNotRequired);
    }

    isEmptyOrRejectedStudentViewableModuleContentApplication(): boolean {
        if (!this.hasStudentViewableModuleContentApplication()) {
            return true;
        }
        return this.studentViewableModuleContentApplication.state === 'REJECTED';
    }

    makeCustomModuleContentApplicationRequest() {
        this.moduleContentWorkflowApplicationModalOpener('CUSTOM', this.module, this.validatablePlan, this.matchingStudyRight)
            .closed
            .pipe(take(1), this.errorHandler.defaultErrorHandler())
            .subscribe(() => {
                this.updatePlan();
                this.uiContext.closeFreeEdit();
            });
    }

    makeRequiredModuleContentApplicationRequest() {
        this.moduleContentWorkflowApplicationModalOpener('REQUIRED', this.module, this.validatablePlan, this.matchingStudyRight)
            .closed
            .pipe(take(1), this.errorHandler.defaultErrorHandler())
            .subscribe(() => {
                this.updatePlan();
                this.uiContext.closeFreeEdit();
            });
    }

    isButtonHidden() {
        switch (this.statusCode) {
            case 'SELECTION_ASSISTANT.APPROVAL_REQUIRED.VALID_CONTENTS':
            case 'FREE_EDIT.APPROVAL_NOT_REQUIRED.INVALID_CONTENTS':
            case 'FREE_EDIT.APPROVAL_REQUIRED.VALID_CONTENTS':
            case 'FREE_EDIT.APPROVAL_REQUIRED.INVALID_CONTENTS':
                return false;
            default:
                return true;
        }
    }

    updatePlan() {
        return this.uiContext.planContext.buildAndUpdate(this.validatablePlan.plan, true);
    }

    getModuleValidationResult() {
        const planValidationResult = _.get(this.uiContext, 'planContext.planValidationResult');
        const moduleId = _.get(this.module, 'id');
        if (!moduleId || !planValidationResult) {
            return null;
        }
        const moduleValidationResults = _.get(planValidationResult, 'moduleValidationResults');
        return _.get(moduleValidationResults, moduleId);
    }

    isModuleContextualStateApplicable(contextualState: string): boolean {
        return contextualState === PlanValidationState.INVALID || contextualState === PlanValidationState.INCOMPLETE;
    }

    private getStatusCode() {
        let code = '';
        if (this.module) {
            code = this.showViewModuleContentWorkflowLinkService.getStatusCode(this.validatablePlan, this.module, this.getModuleValidationResult(), this.isFreeEdit);
        }
        return code;
    }

    private isModuleContentApplicationRequired() {
        if (this.module) {
            return this.showViewModuleContentWorkflowLinkService.isModuleContentApplicationRequired(this.module);
        }
        return false;
    }
}
