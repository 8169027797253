import angular from 'angular';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import invalidSelectionsTpl from './invalidSelections.tpl.html';
(function () {
  angular.module('student.plan.invalidSelections', ['student.common.components.courseUnitBox', 'student.common.components.moduleBox', 'student.common.context.uiContext', 'student.common.service.rawPlanEdit', 'sis-common.errorhandler.errorService', planValidationResultHelperModule, IconComponent.downgrade.moduleName]).component('invalidSelections', {
    template: invalidSelectionsTpl,
    bindings: {
      module: '<',
      validatablePlan: '<'
    },
    controller: ["uiContext", "rawPlanEditService", "defaultPromiseHandler", "planValidationResultHelper", function (uiContext, rawPlanEditService, defaultPromiseHandler, planValidationResultHelper) {
      const $ctrl = this;
      $ctrl.$onChanges = () => {
        $ctrl.planValidationResult = $ctrl.getPlanValidationResult();
        updateInvalidStudies();
      };
      function updateInvalidStudies() {
        if (!$ctrl.validatablePlan) {
          return;
        }
        $ctrl.invalidCourseUnitSelections = $ctrl.getInvalidCourseUnitSelections();
        $ctrl.invalidModuleSelections = $ctrl.getInvalidModuleSelections();
        $ctrl.invalidCustomCourseUnitAttainmentSelections = $ctrl.getInvalidCustomCourseUnitAttainmentSelections();
        $ctrl.invalidCustomModuleAttainmentSelections = $ctrl.getInvalidCustomModuleAttainmentSelections();
        $ctrl.customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference = $ctrl.getCustomCourseUnitAttainmentSelectionsWithInvalidAttainmentReference();
        $ctrl.customModuleAttainmentSelectionsWithInvalidAttainmentReference = $ctrl.getCustomModuleAttainmentSelectionsWithInvalidAttainmentReference();
      }
      $ctrl.getPlanValidationResult = () => _.get(uiContext, 'planContext.planValidationResult');
      $ctrl.getInvalidCourseUnitSelections = () => _.filter($ctrl.validatablePlan.getSelectedCourseUnitsUnderModule($ctrl.module), cu => _.get(planValidationResultHelper.getCourseUnitValidationResult(cu.id, $ctrl.planValidationResult), 'invalidSelection') === true);
      $ctrl.getInvalidModuleSelections = () => _.filter($ctrl.validatablePlan.getSelectedModulesUnderModule($ctrl.module), module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.planValidationResult), 'invalidSelection') === true);
      $ctrl.getInvalidCustomCourseUnitAttainmentSelections = () => {
        const selectedCustomCourseUnitAttainments = $ctrl.validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule($ctrl.module);
        return _.filter(selectedCustomCourseUnitAttainments, ccua => _.get(planValidationResultHelper.getCustomCourseUnitAttainmentValidationResult(ccua.id, $ctrl.planValidationResult), 'invalidSelection') === true);
      };
      $ctrl.getInvalidCustomModuleAttainmentSelections = () => {
        const selectedCustomModuleAttainments = $ctrl.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule($ctrl.module);
        return _.filter(selectedCustomModuleAttainments, cma => _.get(planValidationResultHelper.getCustomModuleAttainmentValidationResult(cma.id, $ctrl.planValidationResult), 'invalidSelection') === true);
      };
      $ctrl.getCustomCourseUnitAttainmentSelectionsWithInvalidAttainmentReference = () => {
        const invalidIds = _.chain(_.get($ctrl.validatablePlan, 'plan.customCourseUnitAttainmentSelections')).filter(selection => selection.parentModuleId === _.get($ctrl.module, 'id')).filter(selection => $ctrl.validatablePlan.getCustomCourseUnitAttainment(selection.customCourseUnitAttainmentId) === null).map('customCourseUnitAttainmentId').value();
        return invalidIds;
      };
      $ctrl.getCustomModuleAttainmentSelectionsWithInvalidAttainmentReference = () => {
        const invalidIds = _.chain(_.get($ctrl.validatablePlan, 'plan.customModuleAttainmentSelections')).filter(selection => selection.parentModuleId === _.get($ctrl.module, 'id')).filter(selection => $ctrl.validatablePlan.getCustomModuleAttainment(selection.customModuleAttainmentId) === null).map('customModuleAttainmentId').value();
        return invalidIds;
      };
      $ctrl.hasInvalidSelections = function () {
        const selectionsValidByType = [_.isEmpty($ctrl.invalidCourseUnitSelections), _.isEmpty($ctrl.invalidModuleSelections), _.isEmpty($ctrl.invalidCustomCourseUnitAttainmentSelections), _.isEmpty($ctrl.invalidCustomModuleAttainmentSelections), _.isEmpty($ctrl.customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference), _.isEmpty($ctrl.customModuleAttainmentSelectionsWithInvalidAttainmentReference)];
        return _.some(selectionsValidByType, selectionsAreValid => !selectionsAreValid);
      };
      $ctrl.removeSelectedCourseUnit = courseUnit => uiContext.planContext.toPlan().then(rawPlan => {
        rawPlanEditService.removeCourseUnit(courseUnit.id, rawPlan);
        return uiContext.planContext.buildAndUpdate(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.removeSelectedModule = module => uiContext.planContext.toPlan().then(rawPlan => {
        rawPlanEditService.removeModule(module.id, rawPlan);
        return uiContext.planContext.buildAndUpdate(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.removeCustomCourseUnitAttainment = customCourseUnitAttainmentId => uiContext.planContext.toPlan().then(rawPlan => {
        rawPlanEditService.removeCustomCourseUnitAttainment(customCourseUnitAttainmentId, rawPlan);
        return uiContext.planContext.buildAndUpdate(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.removeCustomModuleAttainment = customModuleAttainmentId => uiContext.planContext.toPlan().then(rawPlan => {
        rawPlanEditService.removeCustomModuleAttainment(customModuleAttainmentId, rawPlan);
        return uiContext.planContext.buildAndUpdate(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $ctrl.isInvalidSelection = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.planValidationResult), 'invalidSelection') === true;
      $ctrl.isInvalidAccordingToModuleContentApproval = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.planValidationResult), 'invalidAccordingToModuleContentApproval') === true;
      $ctrl.hasModuleContentApproval = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.planValidationResult), 'invalidAccordingToModuleContentApproval') !== undefined;
    }]
  });
})();