<ng-container *transloco="let t">
  <ng-container *ngIf="{
      isMobileView: isMobileView$ | async,
      parameters: searchService.searchParameters$ | async,
      searchFilterCount: searchFilterCount$ | async
    } as context">
    <div class="row align-items-baseline">
      <div *ngIf="context.parameters?.filters?.universityOrgId | organisation | async as university" class="col-lg-2">
        <label id="university-select-editor-label">{{ t('SEARCH.UNIVERSITY_LABEL') }}</label>
        <sis-university-select-editor [ariaLabelledBy]="'university-select-editor-label'"
                                      [placeOnLeft]="true"
                                      [onlySisuOrganisations]="true"
                                      [university]="university"
                                      (universityChange)="selectUniversity($event?.id)">
        </sis-university-select-editor>
      </div>
      @if (isTextSearchEnabled) {
        <div [ngClass]="{
        'col-lg-6': context.parameters?.filters?.universityOrgId,
        'col-lg-8': !context.parameters?.filters?.universityOrgId
      }">
          <label for="full-text-query-input">{{ t('SEARCH.INPUT_LABEL') }}</label>
          <div class="search-group">
            <input #fullTextQueryInput
                   id="full-text-query-input"
                   data-cy="full-text-query-input"
                   class="form-control"
                   [value]="context.parameters?.filters?.fullTextQuery || ''"
                   (keyup.enter)="searchButtonTriggered()">
            <sis-button class="search-trigger-button"
                        [dataCy]="'search-trigger-button'"
                        [ariaLabel]="t(ariaLabel)"
                        (clicked)="searchButtonTriggered()"
                        [ngClass]="'btn-search'">
              <sis-icon color="white" icon="search" aria-hidden="true"/>
              {{ t('SEARCH.PLACEHOLDER_SEARCH') }}
            </sis-button>
          </div>
          <p class="sis-guidance sis-guidance__primary">{{ t(guidance) }}</p>
        </div>
      }
    </div>
    <sis-expandable *ngIf="searchFilterTemplates?.length > 0"
                    [variant]="'link'"
                    [title]="searchFiltersTitle(context.searchFilterCount)"
                    [closed]="context.isMobileView">
      <ng-template sisExpandableContent>
        <div class="row">
          <div *ngFor="let template of searchFilterTemplates" class="col-lg-3">
            <ng-container [ngTemplateOutlet]="template?.templateRef"></ng-container>
          </div>
          <div *ngIf="context.searchFilterCount > 0" class="col-lg-3 clear-search-filters-button text-center">
            <sis-button (clicked)="clearSearchFilters(context?.parameters)" [naked]="true" data-cy="clear-search-filters-button">
              <sis-icon icon="close"></sis-icon>
              {{ t('SEARCH.CLEAR_SEARCH_FILTERS') }}
            </sis-button>
          </div>
        </div>
      </ng-template>
    </sis-expandable>
  </ng-container>
</ng-container>
