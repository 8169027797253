import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../common/common.module';
import { EnrolmentModule } from '../common/components/enrolment/enrolment.module';

import { FrontpageComponent } from './frontpage-component/frontpage.component';
import { SelectWidgetsDropdownComponent } from './frontpage-component/select-widgets/select-widgets-dropdown.component';
import { SelectWidgetsComponent } from './frontpage-component/select-widgets/select-widgets.component';
import { frontpageRoutes } from './frontpage.route';
import { StudentTermRegistrationPeriodInfoComponent } from './student-term-registration-period-info/student-term-registration-period-info.component';
import { StudyRightExpirationInfoComponent } from './study-right-expiration-info/study-right-expiration-info.component';
import { ActiveStudiesComponent } from './widgets/active-studies/active-studies.component';
import { CollapsibleListContentDirective } from './widgets/collapsible-list/collapsible-list-content.directive';
import { CollapsibleListComponent } from './widgets/collapsible-list/collapsible-list.component';
import { GradeAverageComponent } from './widgets/grade-average/grade-average.component';
import { AttainmentInfoComponent } from './widgets/recent-attainments/attainment-info/attainment-info.component';
import { RecentAttainmentsComponent } from './widgets/recent-attainments/recent-attainments.component';
import { SelectPlanDropdownComponent } from './widgets/select-plan-dropdown/select-plan-dropdown.component';
import { StudyProgressGraphComponent } from './widgets/study-progress-graph/study-progress-graph.component';
import { UpcomingStudiesComponent } from './widgets/upcoming-studies/upcoming-studies.component';
import { WidgetContainerComponent } from './widgets/widget-container/widget-container.component';

@NgModule({
    declarations: [
        CollapsibleListComponent,
        CollapsibleListContentDirective,
        FrontpageComponent,
        StudentTermRegistrationPeriodInfoComponent,
        RecentAttainmentsComponent,
        AttainmentInfoComponent,
        WidgetContainerComponent,
        SelectWidgetsComponent,
        SelectWidgetsDropdownComponent,
        ActiveStudiesComponent,
        StudyProgressGraphComponent,
        StudyRightExpirationInfoComponent,
        UpcomingStudiesComponent,
        SelectPlanDropdownComponent,
        GradeAverageComponent,
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
        UIRouterModule.forChild({
            states: frontpageRoutes,
        }),
        ReactiveFormsModule,
        EnrolmentModule,
        NgxFudisModule,
    ],
})
export class FrontpageModule {}
