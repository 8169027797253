import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { CustomStudyDraft } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-custom-study-draft',
    templateUrl: './plan-custom-study-draft.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanCustomStudyDraftComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planCustomStudyDraft',
        directiveName: 'sisPlanCustomStudyDraft',
    };

    customStudyDraftStateObject: any;
    canOpenCustomStudyDraftInfoModal: boolean;

    @Input() customStudyDraft: CustomStudyDraft;
    @Input() planStateObject: PlanStateObject;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnInit() {
        this.customStudyDraftStateObject = _.get(this.planStateObject?.customStudyDrafts, this.customStudyDraft.id);
        this.canOpenCustomStudyDraftInfoModal = !!this.planActionsService?.openCustomStudyDraftInfoModal;
    }

    openCustomStudyDraftInfoModal(customStudyDraft: CustomStudyDraft) {
        this.planActionsService?.openCustomStudyDraftInfoModal?.(customStudyDraft);
    }
}
