<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment">
      </sis-attainment-expiration-notification>
    </ng-template>
    <ng-template #curriculumPeriods>
      <div sis-curriculum-periods-details class="form-group-mimic" role="listitem" [attainment]="attainment"></div>
    </ng-template>
    <ng-template #verifierAndRegistrationDate>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.VERIFIER_AND_REGISTRATION_DATE') }}</dt>
        <dd>{{
          (attainment.verifierPersonId ? (attainment.verifierPersonId | publicPerson | async | personFullNameReversed) : '-')
          + ', '
          + (attainment.registrationDate | localDateFormat)
          }}</dd>
      </div>
    </ng-template>
    <ng-template *ngIf="(attainment?.courseUnitId | courseUnit) | async as courseUnit" #rdiCredits>
      <ng-container *ngIf="courseUnit.rdiCreditsEnabled as rdiCreditsEnabled">
        <div *ngIf="rdiCreditsEnabled === 'ENABLED' && attainment.rdiCredits" class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
          <dd>
            {{ attainment.rdiCredits }}
          </dd>
        </div>
        <div *ngIf="rdiCreditsEnabled === 'ENABLED' && !attainment.rdiCredits" class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
          <dd> -</dd>
        </div>
        <div *ngIf="rdiCreditsEnabled === 'DISABLED' && attainment.rdiCredits" class="form-group-mimic" role="listitem">
          <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
          <dd>
            {{ attainment.rdiCredits }}
          </dd>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #administrativeNote>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ADMINISTRATIVE_NOTE') }}</dt>
        <dd>{{ attainment.administrativeNote || '-' }}</dd>
      </div>
    </ng-template>
    <ng-template #thesisInformation>
      <sis-thesis-details [attainment]="attainment">
      </sis-thesis-details>
    </ng-template>
    <ng-template *ngIf="attainment?.creditTransferInfo" #creditTransferInformation>
      <sis-attainment-credit-transfer-info-details [creditTransferInfo]="attainment.creditTransferInfo"
                                                   data-cy="attainment-credit-transfer-info">
      </sis-attainment-credit-transfer-info-details>
    </ng-template>
    <ng-template *ngIf="attainment?.cooperationNetworkStatus" #cooperationNetworkStatus>
      <sis-attainment-cooperation-network-status-details
        [cooperationNetworkStatus]="attainment.cooperationNetworkStatus"
        data-cy="attainment-ripa">
      </sis-attainment-cooperation-network-status-details>
    </ng-template>
  </sis-common-attainment-details>
</ng-container>
