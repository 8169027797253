import angular from 'angular';
import * as _ from 'lodash-es';
import { isAttached, toChildAttainmentIds } from '../attainment/AttainmentUtil.ts';
export const commonAttainmentServiceModule = 'sis-components.service.attainmentService';
(function () {
  commonAttainmentService.$inject = ["$q", "attainmentJSDataModel", "jsDataCacheHelper", "$http", "attainmentEndpoint"];
  angular.module(commonAttainmentServiceModule, ['sis-components.constant.attainmentTypes', 'sis-components.model.attainment', 'sis-components.service.jsDataCacheHelper']).factory('commonAttainmentService', commonAttainmentService);

  /**
   * @ngInject
   */
  function commonAttainmentService($q, attainmentJSDataModel, jsDataCacheHelper, $http, attainmentEndpoint) {
    const api = {
      findModuleContentApplicationsCustomAttainments: function (applicationId) {
        return $http.get(_.join([attainmentEndpoint.FOR_MODULE_CONTENT_APPLICATION, applicationId], '/')).then(result => result.data);
      },
      findAttainmentsForStudentApplication: function (applicationId, onlyActive) {
        return $http.get(_.join([attainmentEndpoint.FOR_STUDENT_APPLICATION, applicationId], '/')).then(result => result.data).then(attainments => {
          if (onlyActive) {
            return _.filter(attainments, {
              documentState: 'ACTIVE'
            });
          }
          return attainments;
        });
      },
      search: function (searchParams) {
        const searchConfig = searchParams.build();
        if (searchConfig.params.fullTextQuery) {
          searchConfig.params.searchString = searchConfig.params.fullTextQuery;
        }
        return $http.get(attainmentEndpoint.SEARCH, searchConfig).then(result => result.data);
      },
      findByIds(ids) {
        return jsDataCacheHelper.findByIds(attainmentJSDataModel, ids);
      },
      /**
       * Search attainments for person by search criterias.
       * @param personId
       * @param params
       * @returns {*}
       */
      searchForPerson: function (personId, params) {
        return $http.get(attainmentEndpoint.functional.SEARCH_FOR_PERSON(personId), {
          params
        }).then(result => result.data);
      },
      create: function (attainment) {
        return attainmentJSDataModel.create(attainment);
      },
      markAsSecondary(id) {
        return $http.post(attainmentEndpoint.functional.MARK_AS_SECONDARY(id)).then(result => result.data);
      },
      isAttached(attainment, attainments) {
        return isAttached(attainment, attainments);
      },
      toChildAttainmentIds(attainment) {
        return toChildAttainmentIds(attainment);
      },
      getChildStudiesFromAttainmentNodes: (attainmentNodes, validatablePlan) => {
        const courseUnits = [];
        const modulesAndGroupNodes = [];
        const customCourseUnitAttainmentIds = [];
        const customModuleAttainmentIds = [];
        _.forEach(attainmentNodes, node => {
          if (node.type === 'AttainmentReferenceNode' && node.attainmentId) {
            const attainment = _.get(validatablePlan.attainmentsById, node.attainmentId);
            const attainmentType = _.get(attainment, 'type');
            if (attainmentType === 'CourseUnitAttainment') {
              const courseUnit = validatablePlan.getCourseUnit(attainment.courseUnitId);
              if (courseUnit) {
                courseUnits.push(courseUnit);
              }
            }
            if (attainmentType === 'ModuleAttainment') {
              const module = validatablePlan.getModule(attainment.moduleId);
              if (module) {
                modulesAndGroupNodes.push(module);
              }
            }
            if (attainmentType === 'CustomCourseUnitAttainment') {
              customCourseUnitAttainmentIds.push(attainment.id);
            }
            if (attainmentType === 'CustomModuleAttainment') {
              customModuleAttainmentIds.push(attainment.id);
            }
          } else if (node.type === 'AttainmentGroupNode') {
            modulesAndGroupNodes.push(node);
          }
        });
        return {
          courseUnits,
          customCourseUnitAttainmentIds,
          customModuleAttainmentIds,
          modulesAndGroupNodes
        };
      },
      injectAttainment(attainment) {
        return attainmentJSDataModel.inject(attainment, {
          onConflict: 'replace'
        });
      }
    };
    return api;
  }
})();