import { Inject, Injectable } from '@angular/core';
import { EventSourceFuncArg } from '@fullcalendar/core';
import {
    CourseUnit, CourseUnitRealisation,
    Enrolment, Event, FlowState,
    FullCalendarStudyEvent, LocalizedString,
    StudyEvent, StudyGroupSet, StudySubGroup,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { combineLatestWith, forkJoin, Observable, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { COURSE_UNIT_REALISATION_NAME_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';
import { StudyEventEntityService } from '../../common/service/study-event-entity.service';

import { CalendarViewDateRange, FullCalendarEventService } from './full-calendar-event.service';
import { FullCalendarWebStorageService } from './full-calendar-web-storage.service';
import { HiddenEventPreference } from './full-calendar.types';

@Injectable({
    providedIn: 'root',
})
export class FullCalendarStudyEventService {

    constructor(
        private fullCalendarEventService: FullCalendarEventService,
        private studyEventEntityService: StudyEventEntityService,
        private enrolmentStudentService: EnrolmentStudentService,
        private courseUnitEntityService: CourseUnitEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private fullCalendarWebStorageService: FullCalendarWebStorageService,
        private appErrorHandler: AppErrorHandler,
        @Inject(COURSE_UNIT_REALISATION_NAME_SERVICE) private courseUnitRealisationNameService: any,
    ) { }

    getEvents(fetchInfo: EventSourceFuncArg): Observable<FullCalendarStudyEvent[]> {
        const calendarViewDateRange: CalendarViewDateRange = { start: fetchInfo.start, end: fetchInfo.end };

        return this.studyEventEntityService.getMyStudyEvents(fetchInfo.start, fetchInfo.end).pipe(
            combineLatestWith(this.enrolmentStudentService.getAllEnrolments().pipe(
                take(1),
                map((enrolments) => enrolments.filter(enrolment => enrolment.isInCalendar)),
            )),
            switchMap(([studyEvents, enrolmentsInCalendar]) => {
                const courseUnitRealisationIds = _.uniq(_.flatMap(enrolmentsInCalendar, enrolment => enrolment.courseUnitRealisationId));
                const courseUnitIds = _.uniq(_.flatMap(enrolmentsInCalendar, enrolment => enrolment.courseUnitId));
                const cusById$ = this.courseUnitEntityService.getByIds(courseUnitIds).pipe(take(1), map((curs) => _.keyBy(curs, 'id')));
                const cursById$ = this.courseUnitRealisationEntityService.getByIds(courseUnitRealisationIds).pipe(take(1), map((curs) => _.keyBy(curs, 'id')));
                return forkJoin({
                    studyEvents: of(studyEvents),
                    enrolmentsInCalendar: of(enrolmentsInCalendar),
                    cusById: cusById$,
                    cursById: cursById$,
                });
            }),
            this.appErrorHandler.defaultErrorHandler(),
            map(({ studyEvents, enrolmentsInCalendar, cusById, cursById }) => this.studyEventsToFullCalendarEvents(studyEvents, calendarViewDateRange, enrolmentsInCalendar, cusById, cursById)),
        );
    }

    studyEventsToFullCalendarEvents(studyEvents: StudyEvent[],
                                    calendarViewDateRange: CalendarViewDateRange,
                                    enrolments: Enrolment[],
                                    cusById: { [index: string]: CourseUnit },
                                    cursById: { [index: string]: CourseUnitRealisation }): FullCalendarStudyEvent[] {
        const hiddenEventPreference = this.fullCalendarWebStorageService.getHiddenEventPreference();
        return studyEvents
            .map((studyEvent: StudyEvent) => this.studyEventToFullCalendarEvents(studyEvent, calendarViewDateRange, enrolments, hiddenEventPreference, cusById, cursById))
            .flat();
    }

    studyEventToFullCalendarEvents(studyEvent: StudyEvent,
                                   calendarViewDateRange:
                                   CalendarViewDateRange,
                                   enrolments: Enrolment[],
                                   hiddenEventPreference: HiddenEventPreference,
                                   cusById: { [index: string]: CourseUnit },
                                   cursById: { [index: string]: CourseUnitRealisation }): FullCalendarStudyEvent[] {
        const { enrolment, studyGroupSet, studySubGroup } = this.findEnrolmentAndGroupInfoForStudyEvent(studyEvent, enrolments, cursById) || {};

        return studyEvent.events
            .filter((event: Event) => !event.excluded)
            .filter((event: Event) => this.fullCalendarEventService.isEventInstanceBetweenDateRange({ start: event.start, end: event.end }, calendarViewDateRange))
            .map((event: Event): FullCalendarStudyEvent => this.buildFullCalendarStudyEvent(studyEvent, event, enrolment, studySubGroup, studyGroupSet, cusById, cursById))
            .filter((event) => this.filterVisibleEvents(event, hiddenEventPreference));
    }

    buildFullCalendarStudyEvent(studyEvent: StudyEvent,
                                event: Event,
                                enrolment: Enrolment,
                                studySubGroup: StudySubGroup,
                                studyGroupSet: StudyGroupSet,
                                cusById: { [index: string]: CourseUnit },
                                cursById: { [index: string]: CourseUnitRealisation }): FullCalendarStudyEvent {
        const courseUnit: CourseUnit = _.get(cusById, enrolment.courseUnitId);
        const courseUnitRealisation: CourseUnitRealisation = _.get(cursById, enrolment.courseUnitRealisationId);
        const flowState: FlowState = courseUnitRealisation.flowState;
        let eventName: LocalizedString = !courseUnitRealisation ? undefined :
            this.courseUnitRealisationNameService.generateFullNameFromCourseUnit(courseUnitRealisation, courseUnit);
        if (courseUnit) {
            eventName = _.mapValues(eventName, value => `${courseUnit.code} ${value}`);
        }
        const cancelled = !!(flowState === 'CANCELLED' || studySubGroup.cancelled || event.cancelled);
        const notEnrolled = enrolment.state === 'NOT_ENROLLED';
        const stateBasedClassName = this.getStateBasedClassName(cancelled, notEnrolled);
        return {
            start: moment(event.start).toDate(),
            end: moment(event.end).toDate(),
            className: `${stateBasedClassName} study-event`,
            extendedProps: {
                eventTitle: this.getFullCalendarTitle(studyGroupSet),
                teacherIds: _.defaultTo(event.irregularTeacherIds, _.get(studySubGroup, 'teacherIds')),
                locationIds: _.defaultTo(event.irregularLocationIds, studyEvent.locationIds),
                locationsRemoved: _.isArray(event.irregularLocationIds) && _.isEmpty(event.irregularLocationIds),
                notice: event.notice,
                name: studyEvent.name,
                cancelled,
                enrolment,
                courseUnitRealisation,
                flowState,
                eventName,
                studyGroupSetName: _.get(studyGroupSet, 'name'),
                studySubGroupName: _.get(studySubGroup, 'name'),
                type: 'CALENDAR_EVENT',
            },
        };
    }

    getStateBasedClassName(cancelled: boolean, notEnrolled: boolean): 'cancelled' | 'not-enrolled' | 'scheduled' {
        if (cancelled) {
            return 'cancelled';
        }
        if (notEnrolled) {
            return 'not-enrolled';
        }
        return 'scheduled';
    }

    getFullCalendarTitle(studyGroupSet: StudyGroupSet): { name: LocalizedString } {
        if (!studyGroupSet) {
            return null;
        }
        return {
            name: studyGroupSet.name,
        };
    }

    findEnrolmentAndGroupInfoForStudyEvent(studyEvent: StudyEvent, enrolments: Enrolment[], cursById: { [index: string]: CourseUnitRealisation }): { enrolment: Enrolment, studyGroupSet: StudyGroupSet, studySubGroup: StudySubGroup } {
        for (const enrolment of enrolments) {
            const courseUnitRealisation = _.get(cursById, enrolment.courseUnitRealisationId);
            for (const studyGroupSet of _.get(courseUnitRealisation, 'studyGroupSets', [])) {
                const studySubGroup = _.get(studyGroupSet, 'studySubGroups', [])
                    .find((ssg: StudySubGroup) => _.includes(ssg.studyEventIds, studyEvent.id));
                if (studySubGroup) {
                    return { enrolment, studyGroupSet, studySubGroup };
                }
            }
        }
        return null;
    }

    filterVisibleEvents(event: FullCalendarStudyEvent, hiddenEventPreference: HiddenEventPreference) {
        if (hiddenEventPreference.includes('cancelled')) {
            return !event.extendedProps.cancelled;
        }
        return true;
    }
}
