<div *transloco="let t" class="search-page container sis-pt-md">
  <div class="search-title row">
    <div class="col-12">
      <h2 data-cy="page-secondary-title">{{'SEARCH_PAGE_CROSS_STUDY_TITLE' | translate}}</h2>
    </div>
  </div>
  <p class="guidance">{{'SEARCH_CROSS_STUDY_GUIDANCE' | translate:{name: currentUniversity$ | async | localizeProperty: 'name'} }}</p>

  <div class="search-and-results scroll-target">
    <sis-search [guidance]="'COURSE_UNIT_SEARCH_PLACEHOLDER'" (searchTrigger)="onSearchParameterChange($event)">
      <ng-template sisSearchFilter>
        <sis-search-filter-cooperation-networks
          [key]="'cooperationNetworkId'"
          [label]="t('SEARCH.FILTER_TAGS.COOPERATION_NETWORK')"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-universities
          [key]="'targetUniversityId'"
          [label]="t('SEARCH.FILTER_TAGS.COOPERATION_NETWORK_UNIVERSITY')"
          [excludedUniversityIds]="currentUniversityOrgId"
        />
      </ng-template>
      <ng-template sisSearchFilter>
        <sis-search-filter-code-book
          [key]="'curCodeUrn'"
          [label]="t('SEARCH.FILTER_TAGS.TEACHINGLANGUAGE')"
          [codeBookUrn]="'urn:code:language'"
        />
      </ng-template>
    </sis-search>

    <!-- this visually hidden div block is only to provide search result information for screen reader -->
    <!-- It has to be outside of <sis-search-results/>, as it should never be removed from the DOM, and
         <sis-search-results/> only projects the given content if the search parameters are valid. -->
    <div class="visually-hidden" aria-atomic="true" aria-live="polite">
      @if (searchResult$ | async; as searchResult) {
        @if (!searching) {
          @if (searchResult.total === 0) {
            <p>{{ t('SEARCH_NO_RESULTS') }}</p>
          }
          @if (searchResult.truncated) {
            <p>{{ t('SEARCH_LARGE_RESULT') }}</p>
          }
          <p *ngIf="!searchResult.truncated && searchResult.total > 0">
            {{searchResult.total}}
            <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
            <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
          </p>
        }
      }
    </div>

    <sis-search-results>
      @if (searchResult$ | async; as searchResult) {
        <div class="below-searchbar row">
          <div class="query-notifications col-12">
            @if (searchResult.total === 0) {
              <p class="results-no-show guidance">{{ t('SEARCH_NO_RESULTS') }}</p>
            }
            @if (searchResult.truncated) {
              <p class="results-no-show guidance">{{ t('SEARCH_LARGE_RESULT') }}</p>
            }
          </div>
          <div *ngIf="!searchResult.truncated && searchResult?.total > 0" class="results-info col-12">
            <div class="row">
              <h2 class="col-12 col-sm-6" id="results-show-guide" tabindex="-1"
                  data-cy="courseunit-search-resultrow">
                {{searchResult.total}}
                <span *ngIf="searchResult.total === 1" translate>SEARCH_RESULTS_TITLE_ONE</span>
                <span *ngIf="searchResult.total > 1" translate>SEARCH_RESULTS_TITLE_MANY</span>
              </h2>
              <div class="col-12 col-sm-6">
                <div class="float-start float-sm-end">
                  <label id="sort-method-dropdown-label" class="choose-sort-type" translate>SEARCH.SORT_SELECTOR.CHOOSE_SORT_METHOD</label>
                  <sis-dropdown-select [ariaLabelledBy]="'sort-method-dropdown-label'"
                                       [options]="searchSortOptions"
                                       [selection]="searchService.sort$ | async"
                                       (selectionChange)="sort($event)"
                                       [alignRight]="true"
                                       data-cy="sort-search-results">
                  </sis-dropdown-select>
                </div>
              </div>
            </div>
          </div>
          <div class="results col-12">
            <div *ngFor="let result of searchResult?.searchResults" data-cy="student-courseunit-search-resultrow">
              <app-search-cooperation-network-result-row
                [result]="result"
                [term]="searchService.fullTextQuery$ | async"
                [courseCartCourseIds]="courseCartCourseUnitIds"
                [loggedIn]="isLoggedIn()"
                (addCourseUnitToCourseCart)="addCourseUnitToCourseCart($event)"
                (removeCourseUnitFromCourseCart)="removeCourseUnitFromCourseCart($event)">
              </app-search-cooperation-network-result-row>
            </div>
          </div>
        </div>
        <div class="search-result-pagination row"
             *ngIf="!searchResult.truncated && searchResult?.total > this.resultsPerPage">
          <div class="col-12"
               data-cy="pagination-controls">
            <sis-pagination
              sisScrollIntoViewOnPageChange="div.scroll-target"
              [totalItems]="searchResult.total"
              [maxSize]="5"
              [itemsPerPage]="resultsPerPage"
              [currentPage]="currentPage"
              (currentPageChange)="onPaginationChange($event)">
            </sis-pagination>
          </div>
        </div>
      }
    </sis-search-results>
  </div>
</div>
