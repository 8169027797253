<div class="courseunit cu-color-study-draft">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <span class="credit-amount">{{ customStudyDraft.credits | decimalNumber }}</span>
            <span class="credit-abbreviation" translate="SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR"></span>
        </div>
        <div class="title-box">
            <span class="courseunit-code">
                <sis-button [link]="true"
                            *ngIf="canOpenCustomStudyDraftInfoModal"
                            (clicked)="openCustomStudyDraftInfoModal(this.customStudyDraft)"
                            [ariaLabel]="('ARIA_LABEL.CUSTOM_STUDY_DRAFT' | translate) + ' ' + (customStudyDraft.name) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
                    <sis-icon icon="brochure"></sis-icon>
                    <span translate="CUSTOM_STUDY_DRAFT.OPEN_INFO_MODAL_ANCHOR_TEXT"></span>
                </sis-button>
                <span *ngIf="!canOpenCustomStudyDraftInfoModal">
                    <sis-icon icon="brochure"></sis-icon>
                    <span translate="CUSTOM_STUDY_DRAFT.OPEN_INFO_MODAL_ANCHOR_TEXT"></span>
                </span>
            </span>
            <span class="courseunit-title">
              <sis-tooltip [tooltipText]="customStudyDraft.name" [appendToBody]="true">
                <span class="sis-tooltip-trigger-content">{{ customStudyDraft.name }}</span>
              </sis-tooltip>
            </span>
        </div>
        <ng-content select="[custom-study-draft-action]"></ng-content>
    </div>
</div>
