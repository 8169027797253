<ng-container *transloco="let t">
  <fudis-grid [rowGap]="'xs'" [classes]="'fudis-mt-xs'">
    @if (selectionUIState === 'ACTIVE') {
      <div role="heading" class="fudis-heading__xs" [attr.aria-level]="headingLevel">
        {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_MODULE_RULE.TITLE') }}
      </div>
      <fudis-select
              [size]="'lg'"
              [placeholder]="t('PLAN_EDIT.SELECTION_MODAL.SEARCH_PLACEHOLDER')"
              [label]="t('PLAN_EDIT.SELECTION_MODAL.ANY_MODULE_RULE.SEARCH_MODULES_LABEL')"
              [control]="moduleSelectControl"
              [variant]="'autocompleteType'"
              [helpText]="t('PLAN_EDIT.SELECTION_MODAL.SEARCH_HELP_TEXT')"
              [selectionClearButton]="false"
              (selectionUpdate)="updateSelected($event)"
              (filterTextUpdate)="searchTextUpdateSubject.next($event)"
      >
        <ng-template fudisSelectOptions>
          @if (searchResults | async; as options) {
            <fudis-select-option
                    *ngFor="let option of options"
                    [data]="option"/>
          }
        </ng-template>
      </fudis-select>
    } @else {
      <fudis-body-text>
        {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_MODULE_OR_COURSE_UNIT_TITLE') }}
      </fudis-body-text>
    }
  </fudis-grid>
</ng-container>
