import { Component, Inject, Input, OnChanges, Optional, ViewEncapsulation } from '@angular/core';
import { CreditRange, CustomCourseUnitAttainment } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-custom-course-unit-attainment',
    templateUrl: './plan-custom-course-unit-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanCustomCourseUnitAttainmentComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planCustomCourseUnitAttainment',
        directiveName: 'sisPlanCustomCourseUnitAttainment',
    };

    ccuaStateObject: any;
    visibleCredits: CreditRange;

    @Input() customCourseUnitAttainment: CustomCourseUnitAttainment;
    @Input() planStateObject: PlanStateObject;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnChanges() {
        if (this.customCourseUnitAttainment) {
            this.ccuaStateObject = _.get(this.planStateObject.customCourseUnitAttainments, this.customCourseUnitAttainment.id);
            this.visibleCredits = { min: this.customCourseUnitAttainment.credits, max: this.customCourseUnitAttainment.credits } as CreditRange;
        }

    }

    openCustomCourseUnitAttainmentInfoModal() {
        this.planActionsService?.openCustomCourseUnitAttainmentInfoModal?.(this.customCourseUnitAttainment);
    }

}
