import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CreditRange, LocalizedString, OtmId } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { Action, InputType } from '../study-action-box/study-action-box.component';

export enum StudyBoxType {
    courseUnit = 'COURSE_UNIT',
    studyModule = 'STUDY_MODULE',
    degreeProgramme = 'DEGREE_PROGRAMME',
    studyDraft = 'STUDY_DRAFT',
    customAttainment = 'CUSTOM_ATTAINMENT',
    includedAttainment = 'INCLUDED_ATTAINMENT',
}

@Component({
    selector: 'sis-study-box',
    templateUrl: './study-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyBoxComponent {

    @Input() id: OtmId;
    @Input() type?: StudyBoxType = StudyBoxType.courseUnit;
    @Input() code?: string;
    @Input() credits: CreditRange;
    @Input() plannedCredits?: CreditRange;
    @Input() attainedCredits?: number;
    @Input() gradeId?: number;
    @Input() gradeScaleId?: OtmId;
    @Input() studyState?: 'DRAFT' | 'ACTIVE' | 'DELETED';
    @Input() isPartiallyAttained?: boolean;
    @Input() isSubstituted?: boolean;
    @Input() isCrossStudyCourseUnit?: boolean;
    @Input() action?: Action;
    @Input() selected?: boolean;
    @Input() dottedBorder?: boolean = false;
    @Input() inputType?: InputType;
    @Input() actionInputId: string;
    @Input() actionInputName: string;
    /**
     * Determines header's semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level? = 4;
    @Input() set name(value: LocalizedString | string) {
        if (typeof value === 'string') {
            this._name = value;
        } else {
            this._name = this.localeService.localize(value);
        }
    }

    @Output() actionChange = new EventEmitter<OtmId>();
    // Name will be not clickable when not observing this output.
    @Output() nameClickAction = new EventEmitter<OtmId>();

    private readonly localeService = inject(LocaleService);

    _name: string;

}
