import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { dateUtils } from 'common-typescript/constants';
import {
    CourseUnit, CourseUnitEnrolmentRight,
    EnrolmentRight,
    OpenUniversityProduct,
    OpenUniversityProductSeats,
    OpenUniversityProductTeaching,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { EnrolmentCalculationConfigEntityService } from 'sis-components/service/enrolment-calculation-config-entity.service';
import { EnrolmentRightEntityService } from 'sis-components/service/enrolment-right-entity.service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { OpenUniversityProductEntityService } from 'sis-components/service/open-university-product-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import {
    AssessmentItemTeachingInitialFocusData,
    AssessmentItemTeachingInitialFocusService,
} from '../assessment-item-teaching/assessment-item-teaching-initial-focus.service';
import { AggregateProductInfo, AggregateProductInfoType, ProductTeaching } from '../types';

enum TAB { CURRENT_PRODUCTS, FUTURE_PRODUCTS, PURCHASED_PRODUCTS }

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-open-university-offering',
    templateUrl: './open-university-offering.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [AssessmentItemTeachingInitialFocusService],
})
export class OpenUniversityOfferingComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.courseUnitInfoModal.openUniversityOffering.downgraded',
        directiveName: 'appOpenUniversityOffering',
    };

    @Input() set courseUnit(courseUnit: CourseUnit) {
        this._courseUnit = courseUnit;
        this.hasOpenUniversityOffering = courseUnit?.completionMethods?.some(cm => cm?.studyType === 'OPEN_UNIVERSITY_STUDIES');
        this.currentProducts = [];
        this.futureProducts = [];
        this.selectedProduct = null;
        if (this.hasOpenUniversityOffering) {
            this.fetchProductsAndCurs(courseUnit.id);
        }
    }

    get courseUnit() {
        return this._courseUnit;
    }

    /**
     * Id of the product which should be automatically selected in the carousel when the view is initialized.
     * This is an alternative to {@link preselectedPurchase}, so don't provide both.
     */
    @Input() productId?: OtmId;

    /**
     * If provided, the component will start a state transition to the given state name when a product is selected,
     * with the id of the selected product as the `productId` parameter.
     */
    @Input() productSelectionTargetState?: string;

    /**
     * If provided, this component will initially select the purchases tab and the defined open university product,
     * and focus the defined assessment item and course unit realisation.
     * This is an alternative to {@link productId}, so don't provide both.
     */
    @Input() preselectedPurchase?: OpenUniversityOfferingPreselection;

    _selectedProduct: AggregateProductInfo;

    set selectedProduct(selected: AggregateProductInfo) {
        this._selectedProduct = selected;
        this.ref.detectChanges();
    }

    get selectedProduct(): AggregateProductInfo {
        return this._selectedProduct;
    }

    readonly TAB = TAB;

    currentProducts: AggregateProductInfo[] = [];
    futureProducts: AggregateProductInfo[] = [];
    purchasedProducts: AggregateProductInfo[] = [];
    differentVersionEnrolmentRights: EnrolmentRight[] = [];
    hasOpenUniversityOffering: boolean;
    currentTabIndex = TAB.CURRENT_PRODUCTS;
    isMobileView: boolean;
    isCurrentOrgIdDifferentThanAllProductOrgIds: boolean;
    currentOrgId: string;

    private _courseUnit: CourseUnit;

    private readonly destroyed$ = new Subject<void>();
    private readonly _userLoggedIn$: Observable<boolean>;

    constructor(
        private breakpointService: BreakpointService,
        private cartService: OpenUniversityCartCustomerService,
        private courseUnitRealisationService: CourseUnitRealisationEntityService,
        private enrolmentRightService: EnrolmentRightEntityService,
        private enrolmentCalculationConfigService: EnrolmentCalculationConfigEntityService,
        private localeService: LocaleService,
        private openUniversityProductService: OpenUniversityProductEntityService,
        private ref: ChangeDetectorRef,
        private state: StateService,
        private appErrorHandler: AppErrorHandler,
        private universityService: UniversityService,
        private authService: AuthService,
        private assessmentItemTeachingInitialFocusService: AssessmentItemTeachingInitialFocusService,
    ) {
        // It's technically possible, that this won't emit a value right away,
        // so it can momentarily seem as if nobody's logged in,
        // but this should be a rare corner case.
        this._userLoggedIn$ = authService.userLoggedIn$.pipe(
            appErrorHandler.defaultErrorHandler(),
        );
    }

    ngOnInit(): void {
        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(breakpoint => this.isMobileView = (breakpoint < Breakpoint.SM));
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    onTabChange(tabIndex: TAB): void {
        if (tabIndex !== this.currentTabIndex) {
            this.currentTabIndex = tabIndex;
            this.onProductSelect(null);
            this.ref.detectChanges();
        }
    }

    onProductSelect(product: AggregateProductInfo): void {
        if (product !== this.selectedProduct) {
            this.selectedProduct = product;

            if (this.productSelectionTargetState) {
                // Update the router state, which also updates the current URL. This causes the current product selection to
                // survive page refreshes, and makes it possible for a user to share a URL to a specific product.
                this.state.go(this.productSelectionTargetState, { productId: product?.id ?? null });
            }
        }
    }

    get userLoggedIn$(): Observable<boolean> {
        return this._userLoggedIn$;
    }

    private fetchProductsAndCurs(courseUnitId: OtmId): void {
        this.currentOrgId = this.universityService.getCurrentUniversityOrgId();
        this.openUniversityProductService.getByCourseUnitId(courseUnitId, 'ACTIVE', 'PUBLISHED', this.authService.loggedIn() ? 'ALL' : 'ONGOING_AND_FUTURE')
            .pipe(
                map(products => this.groupAndSortProducts(products)),
                switchMap((groupedProducts: GroupedProducts) => combineLatest([
                    of(groupedProducts),
                    this.fetchCourseUnitRealisations(groupedProducts),
                    this.cartService.getProductsInCurrentCart(),
                    this.enrolmentCalculationConfigService.getOpenUniversityProductSeats(groupedProducts.current.map(({ id }) => id)),
                    this.fetchCourseUnitGroupEnrolmentRights(this.courseUnit?.groupId),
                ])),
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (
                    [
                        groupedProducts,
                        allTeaching,
                        productsInCart,
                        currentProductSeats,
                        enrolmentRights,
                    ]: [
                        GroupedProducts,
                        readonly OpenUniversityProductTeaching[],
                        readonly OpenUniversityProduct[],
                        readonly OpenUniversityProductSeats[],
                        readonly EnrolmentRight[],
                    ]) => {
                    // let's not tamper possibly cached EnrolmentRightEntityService data
                    const sortedEnrolmentRights = enrolmentRights.slice()
                        .filter(right => right.documentState === 'ACTIVE' && right.type === 'CUR_ENROLMENT')
                        .map(right => right as CourseUnitEnrolmentRight)
                        .sort(this.compareEnrolmentRightsLatestFirst);
                    this.currentProducts = groupedProducts.current.map(product => <AggregateProductInfo>{
                        ...product,
                        type: AggregateProductInfoType.Current,
                        isInCart: productsInCart.some(({ id }) => id === product.id),
                        isFull: (currentProductSeats?.find(({ productId }) => productId === product.id)?.availableSeats === 0) ?? false,
                        isCurrentOrgIdDifferentThanProductOrgId: this.isCurrentOrganisationDifferentThanProductOrganisation(product),
                        currentOrgId: this.currentOrgId,
                        teachingOnSale: this.getGroupedTeachingForProduct(product.id, allTeaching),
                        curAvailableSeats: (currentProductSeats?.find(({ productId }) => productId === product.id)?.curSeats),
                        isPurchased: !!sortedEnrolmentRights.find(({ openUniversityProductId }) => openUniversityProductId === product.id),
                    });
                    this.futureProducts = groupedProducts.future.map(product => <AggregateProductInfo>{
                        ...product,
                        type: AggregateProductInfoType.Future,
                        isInCart: false,
                        isFull: false,
                        isCurrentOrgIdDifferentThanProductOrgId: this.isCurrentOrganisationDifferentThanProductOrganisation(product),
                        currentOrgId: this.currentOrgId,
                        teachingOnSale: this.getGroupedTeachingForProduct(product.id, allTeaching),
                        curAvailableSeats: [],
                        isPurchased: !!sortedEnrolmentRights.find(({ openUniversityProductId }) => openUniversityProductId === product.id),
                    });
                    this.purchasedProducts = groupedProducts.all
                        .map(product => ({
                            product,
                            enrolmentRight: sortedEnrolmentRights.find(({ openUniversityProductId }) => openUniversityProductId === product.id),
                        }))
                        .filter(productAndEnrolmentRight => !!productAndEnrolmentRight.enrolmentRight)
                        .map(productAndEnrolmentRight => {
                            const product: OpenUniversityProduct = productAndEnrolmentRight.product;
                            const enrolmentRight: EnrolmentRight = productAndEnrolmentRight.enrolmentRight;
                            return <AggregateProductInfo>{
                                ...product,
                                type: AggregateProductInfoType.Purchased,
                                isInCart: productsInCart.some(({ id }) => id === product.id),
                                isFull: (currentProductSeats?.find(({ productId }) => productId === product.id)?.availableSeats === 0) ?? false,
                                isCurrentOrgIdDifferentThanProductOrgId: this.isCurrentOrganisationDifferentThanProductOrganisation(product),
                                currentOrgId: this.currentOrgId,
                                teachingOnSale: this.getGroupedTeachingForProduct(product.id, allTeaching),
                                curAvailableSeats: (currentProductSeats?.find(({ productId }) => productId === product.id)?.curSeats),
                                enrolmentRight,
                                isPurchased: true,
                            };
                        });

                    this.differentVersionEnrolmentRights = sortedEnrolmentRights?.filter(er => er.courseUnitId !== this.courseUnit.id && er.courseUnitGroupId === this.courseUnit.groupId);

                    if (this.preselectedPurchase) {
                        this.currentTabIndex = TAB.PURCHASED_PRODUCTS;
                        this.selectedProduct = this.purchasedProducts
                            .find(productInfo =>
                                productInfo.id === this.preselectedPurchase.selectedOpenUniversityProductId);

                        // If the preselected purchased product was found
                        // and the focused assessment item has been defined (can be at least theoretically null),
                        // provide the initial focus data to AssessmentItemTeachingComponent.
                        if (!!this.selectedProduct && !!this.preselectedPurchase.focusedAssessmentItemId) {
                            this.assessmentItemTeachingInitialFocusService
                                .initialFocusData = <AssessmentItemTeachingInitialFocusData>{
                                    focusedAssessmentItemId: this.preselectedPurchase.focusedAssessmentItemId,
                                    focusedCourseUnitRealisationId: this.preselectedPurchase
                                        .focusedCourseUnitRealisationId,
                                };
                        }

                        delete this.preselectedPurchase; // preselection is done only once
                        delete this.productId; // just in case
                    } else if (this.productId) {
                        this.handleInitialProductSelection(this.productId);
                        // The initial product selection should only be performed once
                        delete this.productId;
                    } else if (this.selectedProduct) {
                        // Make sure the status flags in the selected product are up-to-date
                        this.selectedProduct = ([...this.currentProducts, ...this.futureProducts]
                            .find(({ id }) => id === this.selectedProduct.id));
                    }
                    this.isCurrentOrgIdDifferentThanAllProductOrgIds = this.checkDiffBetweenCurrentOrgAndProductsOrgs();
                    this.ref.detectChanges();
                },
            });
    }

    private fetchCourseUnitRealisations(
        groupedProducts: GroupedProducts,
    ): Observable<OpenUniversityProductTeaching[]> {
        return this.courseUnitRealisationService.findByOpenUniversityProductIds(
            Object.values(groupedProducts.all).map(product => product.id),
            {
                documentStates: 'ACTIVE',
                flowStates: 'PUBLISHED',
                activityStatus: this.authService.loggedIn() ? 'ALL' : 'ONGOING_AND_FUTURE',
                excludeDelayedPublish: true,
                onlyForSalesPeriod: true,
            },
        );
    }

    private fetchCourseUnitGroupEnrolmentRights(groupId: OtmId): Observable<readonly EnrolmentRight[]> {
        if (!groupId || !this.authService.loggedIn()) {
            return of([]);
        }
        return this.enrolmentRightService.getByCourseUnitGroupIds([this.courseUnit.groupId]);
    }

    private groupAndSortProducts(products: OpenUniversityProduct[]): GroupedProducts {
        return {
            current: _.chain(products)
                .filter(product => dateUtils.rangeContains(moment(), product.salesPeriod))
                .sortBy([product => product.salesPeriod?.endDateTime, product => this.localeService.localize(product.name)])
                .value(),
            future: _.chain(products)
                .filter(product => dateUtils.isRangeAfter(moment(), product.salesPeriod))
                .sortBy([product => product.salesPeriod?.startDateTime, product => this.localeService.localize(product.name)])
                .value(),
            all: _.chain(products)
                .sortBy([product => product.salesPeriod?.startDateTime, product => this.localeService.localize(product.name)])
                .value(),
        };
    }

    private handleInitialProductSelection(initialProductId: OtmId): void {
        // search from purchasedProducts first
        const initialProduct = ([...this.purchasedProducts, ...this.currentProducts, ...this.futureProducts].find(({ id }) => id === initialProductId));
        if (initialProduct) {
            // it is important to change tab first and then set selectedProduct
            // A different order caused an update error described in OTM-32408 (child component emitted outdated product values)
            this.currentTabIndex = [this.currentProducts, this.futureProducts, this.purchasedProducts]
                .findIndex(productCategory => productCategory.some(p => p === initialProduct));
            this.selectedProduct = initialProduct;
        }
    }

    private getGroupedTeachingForProduct(productId: OtmId, teachingForAllProducts: readonly OpenUniversityProductTeaching[]): ProductTeaching {
        return teachingForAllProducts.filter(item => item.productId === productId)
            .reduce((result, item) => ({ ...result, [item.assessmentItemId]: item.courseUnitRealisations }), {});
    }

    private isCurrentOrganisationDifferentThanProductOrganisation(product: OpenUniversityProduct): boolean {
        return !product?.universityOrgIds.some(prodOrg => prodOrg === this.currentOrgId);
    }

    private checkDiffBetweenCurrentOrgAndProductsOrgs(): boolean {
        const currentAndFutureProducts = [...this.currentProducts, ...this.futureProducts];

        if (currentAndFutureProducts.length === 0) {
            return false;
        }
        return !currentAndFutureProducts.some(product => product.universityOrgIds.find(prodOrg => prodOrg === this.currentOrgId));
    }

    private compareEnrolmentRightsLatestFirst(a: EnrolmentRight, b: EnrolmentRight): number {
        return (b.validityPeriod?.endDate ?? '').localeCompare(a.validityPeriod?.endDate ?? '') || b.metadata?.createdOn.localeCompare(a.metadata?.createdOn);
    }
}

/**
 * Holds the IDs to identify the initially selected {@link OpenUniversityProduct}
 * and the initially focused {@link CourseUnitRealisation} under an {@link AssessmentItem}.
 */
export interface OpenUniversityOfferingPreselection {
    readonly selectedOpenUniversityProductId: OtmId;
    readonly focusedAssessmentItemId: OtmId | null;
    readonly focusedCourseUnitRealisationId: OtmId;
}

interface GroupedProducts {
    readonly all: readonly OpenUniversityProduct[];
    readonly current: readonly OpenUniversityProduct[];
    readonly future: readonly OpenUniversityProduct[]
}

