import { Component, Inject, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService, FudisValidators } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { MaxLength } from 'common-typescript/constants';
import { DeepPartial, FinnishAddress, GenericAddress, PrivatePerson, Urn } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { PrivatePersonEntityService } from '../../service/private-person-entity.service';
import { setEventHandlersForEditMode } from '../../util/edit-mode/utils';
import { COUNTRY_URN_FI, FINNISH_CITIZENSHIP_URNS } from '../utils/basic-info-utils';

export interface ContactInfoEditDialogData {
    studentProfile: PrivatePerson
}

export function editContactInformationDialogOpener(): (data: ContactInfoEditDialogData) => MatDialogRef<EditContactInformationDialogComponent, ContactInfoEditDialogData> {
    const dialogService = inject(FudisDialogService);
    return data => dialogService.open(EditContactInformationDialogComponent, { data });
}

interface FinnishAddressForm {
    city: FormControl<string>,
    postalCode: FormControl<string>,
    streetAddress: FormControl<string>,
    countryUrn: FormControl<Urn>,
    type: FormControl<'FinnishAddress'>
}

interface GenericAddressForm {
    address: FormControl<string>,
    countryUrn: FormControl<Urn>,
    type: FormControl<'GenericAddress'>
}

interface ContactInfoEditForm {
    phoneNumber: FormControl<string>,
    secondaryEmail: FormControl<string>,
    secondaryAddressType: FormControl<'FinnishAddress' | 'GenericAddress'>,
    primaryAddress: FormGroup<FinnishAddressForm>,
    finnishSecondaryAddress: FormGroup<FinnishAddressForm>,
    genericSecondaryAddress: FormGroup<GenericAddressForm>
}

@Component({
    selector: 'sis-edit-contact-information-dialog',
    templateUrl: './edit-contact-information-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EditContactInformationDialogComponent implements OnInit, OnDestroy {

    countryCodebookUrns = 'urn:code:country';
    form: FormGroup<ContactInfoEditForm>;
    isPrimaryAddressEditable: boolean;
    copyStudentProfile: PrivatePerson;
    errorSummaryVisible = false;

    readonly finnishCountryCodes = FINNISH_CITIZENSHIP_URNS;

    /** Used to unsubscribe from the status and value change observables hooked up to the form */
    destroyed$ = new Subject<void>();
    constructor(private fb: SisFormBuilder,
                private appErrorHandler: AppErrorHandler,
                private translocoService: TranslocoService,
                private privatePersonEntityService: PrivatePersonEntityService,
                private fudisDialogService: FudisDialogService,
                @Inject(MAT_DIALOG_DATA) public data: ContactInfoEditDialogData,
    ) {
        setEventHandlersForEditMode();
    }

    ngOnInit(): void {
        this.copyStudentProfile = { ...this.data.studentProfile };
        this.isPrimaryAddressEditable = _.get(this.copyStudentProfile, 'primaryAddress.isUserEditable') ?? true;

        this.form = this.buildForm(this.copyStudentProfile);
        this.setupValueChangeSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    buildForm(person: DeepPartial<PrivatePerson> = {}): FormGroup {
        const primaryAddress: Partial<FinnishAddress> = person.primaryAddress || {};
        const finnishSecondaryAddress: Partial<FinnishAddress> = person.secondaryAddress?.type === 'FinnishAddress' ?
            person.secondaryAddress as Partial<FinnishAddress> : null;
        const genericSecondaryAddress: Partial<GenericAddress> = person.secondaryAddress?.type === 'GenericAddress' ?
            person.secondaryAddress as Partial<GenericAddress> : null;
        return this.fb.group<ContactInfoEditForm>({
            phoneNumber: this.fb.control(
                person.phoneNumber,
                FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
            ),
            secondaryEmail: this.fb.control(
                person.secondaryEmail,
                ([
                    FudisValidators.email(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.EMAIL')),
                    FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                ]),
            ),
            secondaryAddressType: this.fb.control(person?.secondaryAddress?.type),
            primaryAddress: this.fb.group<FinnishAddressForm>({
                streetAddress: this.fb.control(
                    primaryAddress.streetAddress,
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                    ]),
                ),
                postalCode: this.fb.control(
                    primaryAddress.postalCode,
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                    ]),
                ),
                city: this.fb.control(
                    primaryAddress.city,
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                    ]),
                ),
                countryUrn: this.fb.control(COUNTRY_URN_FI),
                type: this.fb.control('FinnishAddress'),
            },
            ),
            finnishSecondaryAddress: this.fb.group<FinnishAddressForm>({
                streetAddress: this.fb.control(
                    { value: finnishSecondaryAddress?.streetAddress, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                postalCode: this.fb.control(
                    { value: finnishSecondaryAddress?.postalCode, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                city: this.fb.control(
                    { value: finnishSecondaryAddress?.city, disabled: !finnishSecondaryAddress },
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_SHORT_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                countryUrn: this.fb.control(COUNTRY_URN_FI),
                type: this.fb.control('FinnishAddress'),
            },
            ),
            genericSecondaryAddress: this.fb.group<GenericAddressForm>({
                countryUrn: this.fb.control(
                    { value: genericSecondaryAddress?.countryUrn, disabled: !genericSecondaryAddress },
                    FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                ),
                address: this.fb.control(
                    { value: genericSecondaryAddress?.address, disabled: !genericSecondaryAddress },
                    ([
                        FudisValidators.maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH, this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH')),
                        FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
                    ]),
                ),
                type: this.fb.control('GenericAddress'),
            },
            ),
        });
    }

    private setupValueChangeSubscriptions(): void {
        this.secondaryAddressType.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (result) => {
                    if (result === 'FinnishAddress') {
                        this.finnishSecondaryAddress.enable();
                        this.finnishSecondaryAddress.updateValueAndValidity();
                        this.genericSecondaryAddress.disable();
                    } else if (result === 'GenericAddress') {
                        this.genericSecondaryAddress.enable();
                        this.genericSecondaryAddress.updateValueAndValidity();
                        this.finnishSecondaryAddress.disable();
                    } else {
                        this.finnishSecondaryAddress.disable();
                        this.genericSecondaryAddress.disable();
                    }
                },
            });
    }

    isSecondaryAddressInFinland(): boolean {
        return this.secondaryAddressType.value === 'FinnishAddress';
    }

    addSecondaryAddress(): void {
        this.copyStudentProfile.secondaryAddress = {} as any;
        this.secondaryAddressType.setValue('FinnishAddress');
    }

    removeSecondaryAddress(): void {
        this.copyStudentProfile.secondaryAddress = null;
        this.secondaryAddressType.setValue(null);
        this.clearSecondaryAddressInputFields();

    }

    clearSecondaryAddressInputFields() {
        this.finnishSecondaryAddressStreetAddress.reset();
        this.finnishSecondaryAddressCity.reset();
        this.finnishSecondaryAddressPostalCode.reset();

        this.genericSecondaryAddressAddress.reset();
        this.genericSecondaryAddressCountryUrn.reset();
    }

    onSelectCodeUrn(control: AbstractControl, urn: Urn): void {
        if (urn !== control.value) {
            control.setValue(urn);
            control.markAsTouched();
            control.markAsDirty();
        }
    }

    saveContactInformationForm(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
        } else if (this.form.pristine) {
            this.fudisDialogService.close();
        } else {
            const {
                primaryAddress: primaryAddressFields,
                secondaryAddressType,
                finnishSecondaryAddress: finnishSecondaryAddressFields,
                genericSecondaryAddress: genericSecondaryAddressFields,
                ...otherFields
            } = this.form.value;

            let secondaryAddress = null;
            if (this.secondaryAddressType.value !== null) {
                secondaryAddress = secondaryAddressType === 'FinnishAddress' ? { ...finnishSecondaryAddressFields, address: null } :
                    { ...genericSecondaryAddressFields, streetAddress: null, city: null, postalCode: null };
            }

            const updatedStudentProfile: PrivatePerson = _.merge({}, this.copyStudentProfile, {
                ...otherFields,
                primaryAddress: primaryAddressFields,
                secondaryAddress,
            });
            this.privatePersonEntityService.saveUserDetails(updatedStudentProfile)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler())
                .subscribe();
            this.fudisDialogService.close({ studentProfile: updatedStudentProfile });
        }
    }

    get phoneNumber() {
        return this.form.controls.phoneNumber;
    }

    get secondaryEmail() {
        return this.form.controls.secondaryEmail;
    }

    get primaryAddressStreetAddress() {
        return this.form.controls.primaryAddress.controls.streetAddress;
    }

    get primaryAddressPostalCode() {
        return this.form.controls.primaryAddress.controls.postalCode;
    }

    get primaryAddressCity() {
        return this.form.controls.primaryAddress.controls.city;
    }

    get finnishSecondaryAddress() {
        return this.form.controls.finnishSecondaryAddress;
    }

    get finnishSecondaryAddressStreetAddress() {
        return this.form.controls.finnishSecondaryAddress.controls.streetAddress;
    }

    get finnishSecondaryAddressPostalCode() {
        return this.form.controls.finnishSecondaryAddress.controls.postalCode;
    }

    get finnishSecondaryAddressCity() {
        return this.form.controls.finnishSecondaryAddress.controls.city;
    }

    get secondaryAddressType() {
        return this.form.controls.secondaryAddressType;
    }

    get genericSecondaryAddress() {
        return this.form.controls.genericSecondaryAddress;
    }

    get genericSecondaryAddressAddress() {
        return this.form.controls.genericSecondaryAddress.controls.address;
    }

    get genericSecondaryAddressCountryUrn() {
        return this.form.controls.genericSecondaryAddress.controls.countryUrn;
    }
}
