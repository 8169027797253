import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { PlanStructureModule } from 'sis-components/plan-structure/plan-structure.module';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN } from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { PlanManager } from 'sis-components/plan/plan-manager/plan-manager.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentPlanActionsService } from '../common/service/student-plan-actions.service';

import { CopyPlanModalComponent } from './copy-plan-modal/copy-plan-modal.component';
import { CreateFirstPlanComponent } from './create-first-plan/create-first-plan.component';
import { CreatePlanModalComponent } from './create-plan-modal/create-plan-modal.component';
import { EditPlanNgNameModalComponent } from './edit-plan-ng-name-modal/edit-plan-ng-name-modal.component';
import { PlanHeaderInfoListComponent } from './plan-header/plan-header-info-list/plan-header-info-list.component';
import { PlanHeaderComponent } from './plan-header/plan-header.component';
import { PlanNgComponent } from './plan-ng.component';
import { planStates } from './plan-ng.route';
import { PlanNotificationComponent } from './plan-notification/plan-notification.component';
import {
    PlanStructureEditModalComponent,
} from './plan-structure/plan-structure-edit-modal/plan-structure-edit-modal.component';
import { FreeEditCourseUnitExpandableComponent } from './plan-structure/plan-structure-edit-modal/plan-structure-free-edit/free-edit-course-unit-expandable/free-edit-course-unit-expandable.component';
import { FreeEditCustomStudyDraftExpandableComponent } from './plan-structure/plan-structure-edit-modal/plan-structure-free-edit/free-edit-custom-study-draft-expandable/free-edit-custom-study-draft-expandable.component';
import { FreeEditModuleExpandableComponent } from './plan-structure/plan-structure-edit-modal/plan-structure-free-edit/free-edit-module-expandable/free-edit-module-expandable.component';
import { PlanStructureFreeEditComponent } from './plan-structure/plan-structure-edit-modal/plan-structure-free-edit/plan-structure-free-edit.component';
import { PlanStructureComponent } from './plan-structure/plan-structure.component';

@NgModule({
    declarations: [
        PlanNgComponent,
        PlanHeaderComponent,
        PlanStructureComponent,
        PlanHeaderInfoListComponent,
        PlanStructureEditModalComponent,
        EditPlanNgNameModalComponent,
        CopyPlanModalComponent,
        CreatePlanModalComponent,
        CreateFirstPlanComponent,
        PlanStructureFreeEditComponent,
        FreeEditCourseUnitExpandableComponent,
        FreeEditCustomStudyDraftExpandableComponent,
        FreeEditModuleExpandableComponent,
        PlanNotificationComponent,
    ],
    imports: [
        PlanStructureModule,
        UIRouterModule.forChild({ states: planStates }),
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxFudisModule,
    ],
    providers: [
        PlanManager,
        {
            provide: PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, useClass: StudentPlanActionsService,
        },
    ],
})
export class PlanNgModule { }
