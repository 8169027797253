import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CourseUnit,
    CourseUnitResultItem,
    OtmId,
    SearchResult,
    UniversityOrganisation,
    YearRange,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { SEARCH_PARAMETERS } from '../../ajs-upgraded-modules';
import { filterInput } from '../../typeahead/typeahead.component';
import { convertAJSPromiseToNative } from '../../util/utils';

interface CourseUnitResultItemEnriched extends CourseUnitResultItem {
    disabled?: boolean;
    yearRange?: YearRange;
}

interface SearchService {
    search: (searchParameters: any) => Promise<SearchResult<CourseUnitResultItem>>;
}

interface Selection {
    name: string;
    id: OtmId;
}

/**
 * @deprecated This component uses deprecated SEARCH_PARAMETERS API and is incompatible
 * with CourseUnitEntityService which should be used instead of the deprecated COURSE_UNIT_SERVICE.
 * No direct replacement available at the time of writing.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-search',
    templateUrl: './course-unit-search.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitSearchComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.courseUnit.courseUnitSearch',
        directiveName: 'sisCourseUnitSearch',
    };

    @Input() isResultDisabled?: (courseUnit: CourseUnitResultItem) => boolean;

    /** This component will clear model if university is changed so that 'model' (module) hopefully always belongs to
     'university'. However it is possible to have module from 'wrong university' by updating 'model' binding. */
    @Input() model?: CourseUnitResultItem | CourseUnit;
    @Output() modelChange = new EventEmitter<CourseUnitResultItem>();

    @Input() required = false;

    /** Clears selection if university changes (needed).
     Clears initial course unit on initialisation, fix this if needed. */
    @Input() university: UniversityOrganisation;

    /** Object with a method named 'search' that takes searchParameters as input and returns searchResults as promise. */
    @Input() searchService: SearchService;

    selection?: Selection;
    truncated = false;

    constructor(private translateService: TranslateService,
                private localeService: LocaleService,
                @Inject(SEARCH_PARAMETERS) private SearchParameters: any) {
        this.doSearch = this.doSearch.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (_.get(changes, 'university.previousValue.id') !== _.get(changes, 'university.currentValue.id')) {
            this.model = undefined;
            this.updateSelection();
        }
        if (_.has(changes, 'model')) {
            this.updateSelection();
        }
    }

    onTextClick(event: MouseEvent): void {
        const target = event.target as HTMLInputElement;
        if (target.select) {
            target.select();
        }
    }

    removeSelection(): void {
        this.updateModel(undefined);
    }

    isDisabled(courseUnit: CourseUnitResultItemEnriched): boolean {
        return !!this.isResultDisabled && this.isResultDisabled(courseUnit);
    }

    onSelect(selectedCourseUnit?: CourseUnitResultItem): void {
        this.updateModel(selectedCourseUnit);
    }

    doSearch(searchString$: Observable<string>): Observable<CourseUnitResultItemEnriched[]> {
        return searchString$.pipe(
            filterInput(),
            switchMap((searchString) => {
                const params = new this.SearchParameters({ documentState: ['ACTIVE', 'DRAFT'] });
                params.universityOrgId.toggleValue(this.university);
                params.searchString.toggleValue(searchString);
                return from(convertAJSPromiseToNative(this.searchService.search(params)));
            }),
            map((result: SearchResult<CourseUnitResultItem>) => {
                _.forEach(result.searchResults, (courseUnit) => {
                    (courseUnit as CourseUnitResultItemEnriched).disabled = this.isDisabled(courseUnit);
                });
                this.truncated = result.truncated;
                if (result.total > result.searchResults.length) {
                    result.searchResults = this.appendTooManyResultsNotifierObject(result.searchResults);
                }
                return result.searchResults;
            }),
        );
    }

    renderCourseUnit(module: CourseUnitResultItemEnriched): string {
        return module.name;
    }

    private updateSelection(): void {
        if (this.model) {
            this.selection = {
                name: typeof this.model.name === 'string' ? this.model.name : this.localeService.localize(this.model.name),
                id: this.model.id,
            };
        } else {
            this.selection = undefined;
        }
    }

    private updateModel(courseUnit?: CourseUnitResultItem): void {
        this.model = courseUnit;
        this.updateSelection();
        this.modelChange.emit(courseUnit);
    }

    private appendTooManyResultsNotifierObject(results: CourseUnitResultItemEnriched[]): CourseUnitResultItemEnriched[] {
        const notifier = {
            name: this.translateService.instant('SIS_COMPONENTS.COURSE_UNIT.COURSE_UNIT_SEARCH.TOO_MANY_RESULTS'),
            disabled: true,
        } as Partial<CourseUnitResultItem>;
        return results.concat(notifier as CourseUnitResultItem);
    }
}
