import angular from 'angular';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service.ts';
import { customStudyDraftModule } from 'sis-components/customStudyDraft/customStudyDraft.component';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { StudentCustomStudyDraftModalComponent } from '../custom-study-draft-modal/custom-study-draft-modal.component.ts';
import { ApplyForModuleContentWorkflowComponent } from '../../common/components/show-view-module-content-application-link/apply-for-module-content-workflow/apply-for-module-content-workflow.component.ts';
import freeEditTpl from './freeEdit.tpl.html';
import { CreateCustomStudyDraftModalWrapperComponent } from './create-custom-study-draft-modal-wrapper/create-custom-study-draft-modal-wrapper.component.ts';
(function () {
  angular.module('student.plan.freeEdit', ['student.common.components.courseUnitBox', 'student.common.components.moduleBox', 'student.common.components.inlineSearch', 'student.plan.ruleSearchParametersService', 'student.common.service.rawPlanEdit', 'sis-components.attainment.customModuleAttainmentBox', 'sis-common.errorhandler.errorService', 'sis-components.service.commonMessageService', 'sis-components.service.colorService', 'student.common.service.planSelectionService', customStudyDraftModule, planValidationResultHelperModule, ConfirmDialogService.downgrade.moduleName, IconComponent.downgrade.moduleName, ApplyForModuleContentWorkflowComponent.downgrade.moduleName, CreateCustomStudyDraftModalWrapperComponent.downgrade.moduleName]).component('freeEdit', {
    template: freeEditTpl,
    bindings: {
      module: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      matchingStudyRight: '<',
      applicationsEnabled: '<',
      canApplyForCustomAttainments: '<'
    },
    controller: ["$scope", "$q", "$log", "$state", "uiContext", "defaultPromiseHandler", "ruleSearchParametersService", "rawPlanEditService", "planContextEventType", "colorService", "confirmDialogService", "planValidationResultHelper", "modalService", "planSelectionService", function ($scope, $q, $log, $state, uiContext, defaultPromiseHandler, ruleSearchParametersService, rawPlanEditService, planContextEventType, colorService, confirmDialogService, planValidationResultHelper, modalService, planSelectionService) {
      const $ctrl = this;
      let gradeScales;
      let unattachedAttainments;
      $ctrl.$onInit = function () {
        $ctrl.courseUnitAttainmentsExpanded = false;
        $ctrl.moduleAttainmentsExpanded = false;
        return uiContext.planContext.toPlan().then(plan => {
          $ctrl.rawPlan = plan;
          getSelectedItems();
          ruleSearchParametersService.getCurriculumPeriodsForSearch($ctrl.rawPlan.curriculumPeriodId).then(curriculumPeriods => {
            $ctrl.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
          return rawPlanEditService.getAllUnattachedAttainments().then(result => {
            unattachedAttainments = result;
            getSelectableItems();
            return rawPlanEditService.loadGradeScales(getGradeScaleIds()).then(result => {
              gradeScales = result;
            }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        });
      };
      $ctrl.getPlanValidationResult = () => _.get(uiContext, 'planContext.planValidationResult');
      $ctrl.getGrade = function (gradeId, gradeScaleId) {
        if (gradeScales) {
          let grade = {
            abbreviation: null
          };
          const gradeScale = _.get(gradeScales, gradeScaleId);
          if (gradeScale) {
            grade = _.find(gradeScale.grades, {
              localId: gradeId
            });
          }
          return grade;
        }
        return null;
      };
      $ctrl.isCourseUnitSelectionDisabled = function (courseUnit) {
        return rawPlanEditService.isCourseUnitInPlan(courseUnit.groupId, $ctrl.validatablePlan);
      };
      $ctrl.isModuleSelectionDisabled = function (module) {
        return rawPlanEditService.isModuleInPlan(module.groupId, $ctrl.validatablePlan);
      };
      $ctrl.selectCourseUnit = function (courseUnit) {
        if (rawPlanEditService.selectCourseUnit(courseUnit.id, $ctrl.module.id, $ctrl.rawPlan)) {
          planSelectionService.makeAutomaticSelectionsForCourseUnits($ctrl.rawPlan, [courseUnit]);
          updatePlan();
        }
      };
      $ctrl.selectCourseUnitResult = function (courseUnit) {
        const attainedVersionId = rawPlanEditService.findAttainedVersionIdForCourseUnit(courseUnit, unattachedAttainments);
        const versionIdToBeSelected = attainedVersionId || courseUnit.id;
        if (rawPlanEditService.selectCourseUnit(versionIdToBeSelected, $ctrl.module.id, $ctrl.rawPlan)) {
          if (!attainedVersionId) {
            planSelectionService.makeAutomaticSelectionsForCourseUnits($ctrl.rawPlan, [courseUnit]);
          }
          updatePlan();
        }
      };
      $ctrl.selectCustomCourseUnitAttainment = function (customCourseUnitAttainment) {
        if (rawPlanEditService.selectCustomCourseUnitAttainment(customCourseUnitAttainment.id, $ctrl.module.id, $ctrl.rawPlan)) {
          updatePlan();
        }
      };
      $ctrl.unSelectCourseUnit = function (courseUnit) {
        rawPlanEditService.removeCourseUnit(courseUnit.id, $ctrl.rawPlan);
        // the following delete line should be removed, when validationResults have been taken out of courseUnit object
        delete courseUnit.validationResults;
        updatePlan();
      };
      $ctrl.unSelectCustomCourseUnitAttainment = function (customCourseUnitAttainment) {
        rawPlanEditService.removeCustomCourseUnitAttainment(customCourseUnitAttainment.id, $ctrl.rawPlan);
        updatePlan();
      };
      $ctrl.selectModule = function (module) {
        if (rawPlanEditService.selectModule(module.id, $ctrl.module.id, $ctrl.rawPlan)) {
          updatePlan();
        }
      };
      $ctrl.selectModuleResult = function (module) {
        const attainedVersionId = rawPlanEditService.findAttainedVersionIdForModule(module, unattachedAttainments);
        const versionIdToBeSelected = attainedVersionId || module.id;
        if (rawPlanEditService.selectModule(versionIdToBeSelected, $ctrl.module.id, $ctrl.rawPlan)) {
          updatePlan();
        }
      };
      $ctrl.selectCustomModuleAttainment = function (customModuleAttainment) {
        if (rawPlanEditService.selectCustomModuleAttainment(customModuleAttainment.id, $ctrl.module.id, $ctrl.rawPlan)) {
          updatePlan();
        }
      };
      $ctrl.createNewCustomStudyDraft = result => {
        if (_.get(result, 'operation') === 'ADD' && _.get(result, 'customStudyDraft')) {
          const rawPlan = $ctrl.validatablePlan.plan;
          rawPlanEditService.addCustomStudyDraft(result.customStudyDraft, rawPlan);
          updatePlan(rawPlan);
        }
      };
      $ctrl.openCustomStudyDraftInfo = customStudyDraft => {
        const options = {
          customAttainmentApplicationsDisabled: !$ctrl.canApplyForCustomAttainments,
          priorLearningInclusionApplicationHidden: !uiContext.priorLearningInclusionApplicationAllowed,
          customAttainmentApplicationHidden: !uiContext.customAttainmentApplicationAllowed
        };
        const studentId = _.get($ctrl.validatablePlan, 'plan.userId');
        modalService.open(StudentCustomStudyDraftModalComponent, {
          studentId,
          customStudyDraft,
          options
        }, {
          size: 'sm'
        }).result.then(result => {
          const operation = _.get(result, 'operation');
          const customStudyDraftId = customStudyDraft.id;
          const planId = _.get($ctrl.validatablePlan, 'plan.id');
          const stateParams = {
            customStudyDraftId,
            planId
          };
          if (operation === 'INCLUSION_APPLICATION') {
            $state.go('student.logged-in.profile.applications.create-prior-learning-inclusion-application', stateParams);
          }
          if (operation === 'CUSTOM_ATTAINMENT_APPLICATION') {
            $state.go('student.logged-in.profile.applications.create-custom-attainment-application', stateParams);
          }
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      };
      $ctrl.removeCustomStudyDraft = customStudyDraftId => {
        const rawPlan = $ctrl.validatablePlan.plan;
        const customStudyDraft = _.find(rawPlan.customStudyDrafts, {
          id: customStudyDraftId
        });
        if (!customStudyDraft) {
          $log.warn('Could not find customStudyDraft by id: ', customStudyDraftId);
          return;
        }
        const confirmTexts = {
          title: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.TITLE',
          description: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION',
          name: customStudyDraft.name
        };
        confirmDialogService.confirm(confirmTexts).then(() => {
          rawPlanEditService.removeCustomStudyDraft(customStudyDraftId, rawPlan);
          updatePlan(rawPlan);
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      };
      $ctrl.unSelectModule = function (module) {
        rawPlanEditService.removeModule(module.id, $ctrl.rawPlan);
        updatePlan();
      };
      $ctrl.unSelectCustomModuleAttainment = function (customModuleAttainment) {
        rawPlanEditService.removeCustomModuleAttainment(customModuleAttainment.id, $ctrl.rawPlan);
        updatePlan();
      };
      $ctrl.isInvalidSelection = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.getPlanValidationResult()), 'invalidSelection') === true;
      $ctrl.isInvalidAccordingToModuleContentApproval = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.getPlanValidationResult()), 'invalidAccordingToModuleContentApproval') === true;
      $ctrl.hasModuleContentApproval = module => _.get(planValidationResultHelper.getModuleValidationResult(module.id, $ctrl.getPlanValidationResult()), 'invalidAccordingToModuleContentApproval') !== undefined;
      $ctrl.getCustomCourseUnitAttainmentColorCategory = function (attainment) {
        try {
          const colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, attainment.id);
          if (colorCategory) {
            return `cu-color-${colorCategory}`;
          }
        } catch (e) {
          $log.error('Failed to getCourseUnitColorCategory', e);
        }
      };
      function updatePlan() {
        return uiContext.planContext.buildAndUpdate($ctrl.rawPlan);
      }
      function getSelectedItems() {
        $ctrl.selectedCourseUnits = $ctrl.validatablePlan.getSelectedCourseUnitsById($ctrl.module);
        $ctrl.selectedModules = $ctrl.validatablePlan.getSelectedModulesById($ctrl.module);
        $ctrl.selectedCustomCourseUnitAttainments = $ctrl.validatablePlan.getSelectedCustomCourseUnitAttainmentsById($ctrl.module, $ctrl.rawPlan);
        $ctrl.selectedCustomModuleAttainments = $ctrl.validatablePlan.getSelectedCustomModuleAttainmentsById($ctrl.module, $ctrl.rawPlan);
        $ctrl.customStudyDrafts = $ctrl.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(_.get($ctrl.module, 'id'));
      }
      function getSelectableItems() {
        $ctrl.selectableAttainedCourseUnits = rawPlanEditService.getSelectableAttainedCourseUnits(unattachedAttainments, $ctrl.validatablePlan);
        $ctrl.selectableCustomCourseUnitAttainments = rawPlanEditService.getSelectableCustomCourseUnitAttainments(unattachedAttainments, $ctrl.validatablePlan, $ctrl.matchingStudyRight);
        $ctrl.selectableAttainedModules = rawPlanEditService.getSelectableAttainedModules(unattachedAttainments, $ctrl.validatablePlan);
        $ctrl.selectableCustomModuleAttainments = rawPlanEditService.getSelectableCustomModuleAttainments(unattachedAttainments, $ctrl.validatablePlan, $ctrl.matchingStudyRight);
      }
      function getGradeScaleIds() {
        return _.uniq(_.map(_.flatMap(_.union([$ctrl.selectableCustomCourseUnitAttainments, $ctrl.selectableCustomModuleAttainments, $ctrl.selectedCustomCourseUnitAttainments, $ctrl.selectedCustomModuleAttainments])), 'gradeScaleId'));
      }
      $scope.$on(planContextEventType.planBuilt, refresh);
      function refresh() {
        return uiContext.planContext.toPlan().then(plan => {
          const promises = [];
          $ctrl.rawPlan = plan;
          getSelectedItems();
          promises.push(ruleSearchParametersService.getCurriculumPeriodsForSearch($ctrl.rawPlan.curriculumPeriodId).then(curriculumPeriods => {
            $ctrl.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
          }));
          getSelectableItems();
          promises.push(rawPlanEditService.loadGradeScales(getGradeScaleIds()).then(result => {
            gradeScales = result;
          }));
          return $q.all(promises).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        });
      }
    }]
  });
})();