<div *ngIf="courseUnit"
     class="courseunit"
     [ngClass]="[categoryCssClass ? categoryCssClass : '']">
    <div class="courseunit-top-row">
        <div class="credits-box">
            <sis-tooltip
                    *ngIf="attainment"
                    [tooltipText]="({min: attainment.credits, max: attainment.credits} | creditRangeLong: 'ATTAINED')"
                    [appendToBody]="true">
                <span class="credit-amount sis-tooltip-trigger-content">{{{min: attainment.credits, max: attainment.credits} | creditRange: 'SYMBOLS':true}}</span>
            </sis-tooltip>
            <sis-tooltip
                    *ngIf="!attainment && courseUnit"
                    [tooltipText]="(courseUnit.credits | creditRangeLong: 'NORMAL')"
                    [appendToBody]="true">
                <span class="credit-amount sis-tooltip-trigger-content">{{courseUnit.credits | creditRange: 'SYMBOLS':true}}</span>
            </sis-tooltip>
            <span class="credit-abbreviation" translate>SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR</span>
        </div>
        <div class="grade-box" *ngIf="attainment">
            <sis-icon icon="achievement"></sis-icon>
            <span class="grade-text">{{attainment.gradeId | grade: attainment.gradeScaleId | async }}</span>
        </div>
        <div class="title-box">
            <ng-content select="[course-unit-box=left-of-name]">
            </ng-content>
            <span class="courseunit-code">
                <sis-button [link]="true"
                            *ngIf="codeClickAction.observers.length > 0"
                            (clicked)="codeClickAction.emit(courseUnit.id)"
                            [ariaLabel]="courseUnit.code + ' ' + (courseUnit.name | localizedString) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
                  <sis-icon icon="brochure"></sis-icon>{{courseUnit.code}}
                </sis-button>
                <span *ngIf="codeClickAction.observers.length === 0">
                  <sis-icon icon="brochure"></sis-icon>{{courseUnit.code}}
                </span>
                <sis-non-active-document-state-badge [documentState]="courseUnit.documentState"></sis-non-active-document-state-badge>
            </span>
            <span class="courseunit-title">
                <sis-tooltip [tooltipText]="(courseUnit.name | localizedString)" [appendToBody]="true">
                    <span class="sis-tooltip-trigger-content">{{courseUnit.name | localizedString}}</span>
                </sis-tooltip>
            </span>
        </div>
        <ng-content select="[course-unit-box=right-of-title]">
        </ng-content>
    </div>
    <div class="cu-notification-bar">
        <ng-content select="[course-unit-box=notifications]">
        </ng-content>
    </div>
</div>
