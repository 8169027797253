import * as _ from 'lodash-es';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchInOrderService } from 'sis-components/service/search-in-order.service.ts';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import { silentErrorHandler } from 'sis-components/error-handler/silent-error-handler.ts';
import { InlineSearchAdditionalDataRowComponent } from 'sis-components/plan/inline-search/inline-search-additional-data-row/inline-search-additional-data-row.component.ts';
import { MarkStringComponent } from 'sis-components/search/mark-string/mark-string.component.ts';
import inlineSearchTpl from './inlineSearch.tpl.html';
'use strict';
/**
 * Inline search controller and directive for course units and modules.
 */
(function () {
  inlineSearch.$inject = ["$animate", "$timeout"];
  InlineSearchCtrl.$inject = ["$log", "$scope", "universityService", "courseUnitService", "moduleService", "$translate", "StudentSearchParameters"];
  angular.module('student.common.components.inlineSearch', ['sis-common.university', 'student.common.service.courseUnitService', 'student.common.service.moduleService', 'ui.select', 'ngSanitize', 'sis-components.rangeFilter', 'pascalprecht.translate', SearchInOrderService.downgrade.moduleName, InlineSearchAdditionalDataRowComponent.downgrade.moduleName, MarkStringComponent.downgrade.moduleName]).controller('InlineSearchCtrl', InlineSearchCtrl).directive('inlineSearch', inlineSearch);

  /**
   * @ngInject
   */
  function inlineSearch($animate, $timeout) {
    return {
      restrict: 'E',
      scope: {
        callback: '=',
        searchValue: '@searchValueKey',
        searchModules: '=',
        placeholder: '@placeholderKey',
        disabledIds: '=',
        isSelectionDisabled: '=',
        getStatusIcon: '=',
        curriculumPeriodIds: '=',
        studyRightSelectionTypes: '<',
        cooperationNetworkIds: '<'
      },
      controller: 'InlineSearchCtrl',
      controllerAs: 'ctrl',
      bindToController: true,
      template: inlineSearchTpl,
      link: function (scope, element) {
        // ui-select hax0r "moved" here, disables animation to avoid the animate 'enter' event problem
        // ui-select v0.17.1
        $timeout(() => {
          const container = element.querySelectorAll('.ui-select-choices-content');
          $animate.enabled(container[0], false);
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function InlineSearchCtrl($log, $scope, universityService, courseUnitService, moduleService, $translate, StudentSearchParameters) {
    const ctrl = this;
    ctrl.queryMinLength = 3;
    ctrl.queryMaxLength = 255;
    ctrl.destroyed$ = new Subject();
    ctrl.runCuSearch$ = new BehaviorSubject(null);
    ctrl.runModuleSearch$ = new BehaviorSubject(null);
    ctrl.searchCourseUnits = query => {
      const searchParameters = new StudentSearchParameters();
      searchParameters.fullTextQuery = query;
      searchParameters.curriculumPeriodIds = ctrl.curriculumPeriodIds;
      searchParameters.organisationRootIds = ctrl.orgRootIds;
      searchParameters.validity = 'ONGOING_AND_FUTURE';
      if (ctrl.cooperationNetworkIds) {
        searchParameters.cooperationNetworkIds = ctrl.cooperationNetworkIds;
        searchParameters.cooperationNetworkSearchType = 'COURSE_UNITS_COMBINED_WITH_COOPERATION_NETWORKS';
        searchParameters.validityInCooperationNetwork = 'ONGOING';
      }
      ctrl.runCuSearch$.next(searchParameters);
    };
    ctrl.searchModulesData = query => {
      ctrl.runModuleSearch$.next(query);
    };
    function capResults(results) {
      ctrl.results = results.searchResults;
      const more = results.total - results.start - results.searchResults.length;
      if (results.total > 0 && results.searchResults.length === 0) {
        ctrl.results.push({
          name: $translate.instant('INLINE_SEARCH_LARGE_RESULT'),
          noClick: true
        });
      } else if (more > 0) {
        ctrl.results.splice(0, 0, {
          name: $translate.instant('INLINE_SEARCH_MORE_ITEMS', {
            count: more
          }),
          noClick: true
        });
      }
    }
    function clearResultsOnError(err) {
      $log.warn('Executing inline search Failed.', err);
      ctrl.results = [];
    }
    function matchInOtherLang(valueInUILang, matchedValue) {
      if (valueInUILang !== matchedValue) {
        return matchedValue;
      }
      return undefined;
    }
    ctrl.$onInit = function () {
      ctrl.results = [];
      ctrl.currentUniversityOrgId = universityService.getCurrentUniversityOrgId();
      ctrl.orgRootIds = [ctrl.currentUniversityOrgId];
      ctrl.queryTooShortBy = 0;
      ctrl.queryTooLong = false;
      ctrl.runCuSearch$.pipe(takeUntil(ctrl.destroyed$), singleConcurrentSearchWithThrottle(params => courseUnitRequest(params))).subscribe(results => {
        handleResults(results);
      });
      ctrl.runModuleSearch$.pipe(takeUntil(ctrl.destroyed$), singleConcurrentSearchWithThrottle(params => moduleRequest(params))).subscribe(results => {
        handleResults(results);
      });
    };
    function handleResults(results) {
      if (results) {
        capResults(results);
        $scope.$applyAsync();
      } else {
        clearResultsOnError();
      }
    }
    function courseUnitRequest(searchParams) {
      return from(courseUnitService.search(searchParams)).pipe(silentErrorHandler);
    }
    function moduleRequest(queryString) {
      return from(moduleService.search(queryString, {
        validity: 'ONGOING_AND_FUTURE',
        curriculumPeriodIds: ctrl.curriculumPeriodIds,
        organisationRootIds: ctrl.orgRootIds,
        codeUrn: ctrl.studyRightSelectionTypes
      })).pipe(silentErrorHandler);
    }
    ctrl.$onDestroy = () => {
      ctrl.destroyed$.next();
    };
    ctrl.doSearch = function (query) {
      ctrl.searchValue = query;
      ctrl.queryTooShortBy = query ? ctrl.queryMinLength - query.length : 0;
      ctrl.queryTooLong = query.length > ctrl.queryMaxLength;
      if (!query || ctrl.queryTooShortBy > 0 || ctrl.queryTooLong) {
        ctrl.results = [];
        return;
      }
      if (ctrl.searchModules) {
        ctrl.searchModulesData(query);
        return;
      }
      ctrl.searchCourseUnits(query);
    };
    ctrl.selectItem = function (resultItem) {
      ctrl.selectedItem = resultItem?.name;
      if (_.isNil(_.get(resultItem, 'id'))) {
        $log.error('Called selectItem callback with undefined resultItem or resultItem without an id.');
        return;
      }
      const service = ctrl.searchModules ? moduleService : courseUnitService;
      service.get(resultItem.id).then(ctrl.callback, err => {
        $log.error('Failed to call selectItem callback function.', err);
      });
    };
    ctrl.someOtherFieldThanNameInUILangMatched = function (resultItem) {
      return matchInOtherLang(resultItem.name, resultItem.nameMatch) || matchInOtherLang(resultItem.name, resultItem.searchTagsMatch);
    };
    ctrl.getPlaceholder = function () {
      // eslint-disable-next-line no-nested-ternary
      return ctrl.searchValue ? ctrl.searchValue : ctrl.placeholder ? $translate.instant(ctrl.placeholder) : '';
    };
    ctrl.isHomeUniversityStudy = resultItem => _.includes(resultItem.universityOrgIds, ctrl.currentUniversityOrgId);
  }
})();