import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter, inject,
    Input, OnChanges,
    Output, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { OtmId, Plan } from 'common-typescript/types';

@Component({
    selector: 'app-plan-header',
    templateUrl: './plan-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanHeaderComponent implements OnChanges {

    @Input() allStudentPlans: Plan[];
    @Input() currentPlan: Plan;

    @Output() selectPlan = new EventEmitter<OtmId>();

    private translocoService = inject(TranslocoService);

    mappedPlanStructure: { [key: string]: Plan[] } = {};
    selectPlanControl: FormControl<{ value: string; label: string }> = new FormControl();

    protected readonly Object = Object;

    ngOnChanges(changes: SimpleChanges): void {
        this.updateSelect();
    }

    private updateSelect(): void {
        this.selectPlanControl?.setValue({
            value: this.currentPlan?.id,
            label: this.currentPlan.name + (this.currentPlan.primary ? ` (${this.translocoService.translate('PRIMARY_STUDY_PLAN')})` : ''),
        });

        this.mappedPlanStructure = {};
        this.allStudentPlans?.forEach((plan: Plan) => {
            if (!this.mappedPlanStructure[plan.rootId]) {
                this.mappedPlanStructure[plan.rootId] = [];
            }
            this.mappedPlanStructure[plan.rootId].push(plan);
        });
    }
}
