import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { LocalizedString, OtmId, Plan } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { PLAN_SEEKER } from '../../../ajs-upgraded-modules';
import { PlanOption, PlanSelectService } from '../../service/plan-select.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-plan-select',
    templateUrl: './plan-select.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PlanSelectComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.planSelect.downgraded',
        directiveName: 'appPlanSelect',
    };

    @Input() plans: Plan[];

    selectedPlan?: Plan;
    selectedLearningOpportunityName?: LocalizedString;
    options: PlanOption[];

    constructor(private state: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private planSelectService: PlanSelectService,
                @Inject(PLAN_SEEKER) private planSeeker: any) { }

    ngOnInit() {
        this.selectedPlan = (this.plans || [])
            .find(plan => plan.id === this.uiRouterGlobals.params.planId);
        this.selectedLearningOpportunityName =
            this.selectedPlan ? this.planSelectService.getSelectedLearningOpportunityName(this.selectedPlan) : undefined;
        this.options = this.planSelectService.createSelectOptions(this.plans);
    }

    get selectedPlanName(): string | undefined {
        return this.selectedPlan ? this.selectedPlan.name : undefined;
    }

    get selectedPlanEducationName(): LocalizedString | undefined {
        return _.get(this.selectedPlan, 'education.name') || undefined;
    }

    get selectedPlanCurriculumPeriodName(): LocalizedString | undefined {
        return _.get(this.selectedPlan, 'curriculumPeriod.name') || undefined;
    }

    onSelect(planId: OtmId): void {
        this.planSeeker.storeSelectedPlanId(planId);
        this.state.go(this.uiRouterGlobals.current, { planId, selectedModuleId: null });
    }
}
