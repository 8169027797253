<div class="help-and-feedback-menu" [class.help-and-feedback-menu--mobile]="mobileMode">
<ng-container *ngIf="helpUrl$ | async as helpUrl"
              [ngTemplateOutlet]="externalLink"
              [ngTemplateOutletContext]="{url:helpUrl, name:'ARIA_LABEL.HELP' | transloco, iconLink:!navCollapsed}"/>
<ng-container *ngIf="universityLinks$ | async as universityLinks">
  <ng-container *ngIf="universityLinks?.length > 0 && authenticated()">
    <ng-container *ngFor="let link of universityLinks"
                  [ngTemplateOutlet]="externalLink"
                  [ngTemplateOutletContext]="{url:link.url, name:link.name | localizedString:currentLanguage}"/>
  </ng-container>
</ng-container>
</div>
<ng-template #externalLink let-url="url" let-name="name" let-iconLink="iconLink">
  <a ngbDropdownItem
     fudisLink
     role="menuitem"
     [href]="url"
     [title]="name"
     [external]="true"
     target="_blank"
     class="help-and-feedback-menu__item d-block"
     [class.d-none]="iconLink"
     (blur)="blurMobileHelpAndFeedbackMenu.emit($event)"
     (keydown.escape)="blurMobileHelpAndFeedbackMenu.emit($event)"
     (keydown)="keydownPressed.emit($event)"
     rel="noopener noreferrer">
  </a>
</ng-template>
