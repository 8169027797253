import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, CurriculumPeriod } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import {
    CourseUnitDisplayNamesById,
    CourseUnitInfoService,
    CourseUnitInfoVersion,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { CourseUnitVersionService } from 'sis-components/service/course-unit-version.service';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';
import { PlanCourseUnitService } from 'sis-components/service/plan-course-unit.service';

import {
    ModalTab,
} from '../../course-unit-info-modal-header/course-unit-info-modal-header-tabs/course-unit-info-modal-header-tabs.component';

@Component({
    selector: 'app-student-course-unit-info-modal-header',
    templateUrl: './student-course-unit-info-modal-header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentCourseUnitInfoModalHeaderComponent implements OnChanges, OnInit {

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;

    @Output() closeModal = new EventEmitter<any>();
    @Output() setModalCourseUnitVersion = new EventEmitter<CourseUnit>();
    @Output() saveCourseUnitVersionChange = new EventEmitter<void>();

    tabs: ModalTab[];
    options: SelectOption[];
    courseUnitVersions: CourseUnitInfoVersion[];
    hasNewerVersion: boolean;
    selectableVersionsAndNames$: Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]>;
    validatablePlanSubject: ReplaySubject<ValidatablePlan> = new ReplaySubject<ValidatablePlan>(1);
    currentCurriculumPeriod: CurriculumPeriod;
    versionChanged: boolean;
    versionSwitchAllowed: boolean;
    courseUnitInPlan: CourseUnit;
    originalCourseUnitHasSubstitutions: boolean;
    showVersionSwitchNotification: boolean;

    constructor(
        private courseUnitInfoService: CourseUnitInfoService,
        private curriculumPeriodEntityService: CurriculumPeriodEntityService,
        private planCourseUnitService: PlanCourseUnitService,
        private appErrorHandler: AppErrorHandler,
        private changeDetectorRef: ChangeDetectorRef,
        private courseUnitVersionService: CourseUnitVersionService,
    ) {}

    ngOnInit() {
        this.courseUnitInPlan = _.cloneDeep(this.courseUnit);
        this.tabs = [
            {
                id: 'course-unit-info-modal-brochure-tab',
                title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO',
                name: 'BASIC',
            },
        ];
        this.initCourseUnitInfoModalHeaderVersionSettings();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseUnit || changes.courseUnitInPlan || changes.validatablePlan) {
            this.validatablePlanSubject.next(this.validatablePlan);

            this.showVersionSwitchNotification = this.courseUnitVersionService.showVersionSwitchNotification(this.courseUnitInPlan, this.validatablePlan);

            this.planCourseUnitService.isVersionChangeAllowed(this.courseUnit, this.courseUnitInPlan, this.validatablePlan).then((result) => {
                this.versionSwitchAllowed = result;
            });

            if (this.courseUnitInPlan) {
                this.versionChanged = this.courseUnitInPlan?.id !== this.courseUnit.id;
            }
        }
    }

    selectTab() {

    }

    initCourseUnitInfoModalHeaderVersionSettings(): void {
        this.curriculumPeriodEntityService.findCurrentCurriculumPeriod()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                switchMap((curriculumPeriod: CurriculumPeriod) => {
                    this.selectableVersionsAndNames$ = this.validatablePlanSubject.pipe(
                        switchMap((validatablePlan: ValidatablePlan) =>
                            this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.courseUnit).pipe(
                                tap(([courseUnitVersions, displayNamesByCourseUnitId]) => {
                                    // set course unit version options and settings
                                    this.currentCurriculumPeriod = curriculumPeriod;

                                    this.originalCourseUnitHasSubstitutions = validatablePlan.isSubstituted(this.courseUnitInPlan);
                                    this.courseUnitVersions = courseUnitVersions;

                                    this.hasNewerVersion = this.courseUnitVersionService.showVersionChangeSuggestionNotification(
                                        this.courseUnit,
                                        courseUnitVersions,
                                        validatablePlan,
                                        curriculumPeriod,
                                    );

                                    this.options = this.courseUnitInfoService.createSelectOptionsForModal(
                                        courseUnitVersions,
                                        displayNamesByCourseUnitId,
                                        validatablePlan,
                                    );
                                }),
                            ),
                        ),
                    );
                    return this.selectableVersionsAndNames$;
                }),
            )
            .subscribe(() => {
                this.changeDetectorRef.markForCheck();
            });
    }

    saveVersionChange() {
        this.courseUnitInPlan = this.courseUnit;
        this.saveCourseUnitVersionChange.emit();
        const headerContainer = document.getElementById('student-course-unit-modal-heading');
        headerContainer.focus();
    }

}
