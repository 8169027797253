import { Inject, Injectable } from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import { DegreeProgrammeAttainmentApplication, ModuleAttainmentApplication, Plan } from 'common-typescript/types';
import * as _ from 'lodash-es';

import { PLAN_LOADER } from '../../ajs-upgraded-modules';

@Injectable()
export class AttainmentApplicationPlanValidationService {

    constructor(@Inject(PLAN_LOADER) private PlanLoader: any) { }

    /**
     * Validates plan snapshot data in application. Returns object containing:
     * -planSnapshot: plan snapshot in the application
     * -comparablePlan: the current plan
     * -planValidationResult: validation result of planSnapshot
     * -validatablePlan: validatable plan of planSnapshot
     */
    planValidationForSnapshotPlan(application: ModuleAttainmentApplication | DegreeProgrammeAttainmentApplication): Promise<PlanValidationForSnapshotPlan> {
        return this.PlanLoader.createValidatablePlan(application.planId, true)
            .then((validatablePlan: ValidatablePlan) => {
                const comparablePlan: Plan = _.get(validatablePlan, 'plan');
                return {
                    comparablePlan,
                    planSnapshot: _.assign(_.cloneDeep(comparablePlan), _.cloneDeep(application.planContent)),
                };
            })
            .then((plansObject: PlansObject) => this.PlanLoader.validatePlanWithThisPlan(plansObject.planSnapshot)
                .then((planValidationResult: PlanValidationResult) => ({
                    planValidationResult,
                    validatablePlan: planValidationResult.validatablePlan,
                    comparablePlan: plansObject.comparablePlan,
                    planSnapshot: plansObject.planSnapshot,
                })));
    }
}

interface PlansObject {
    comparablePlan: Plan;
    planSnapshot: Plan;
}

export interface PlanValidationForSnapshotPlan {
    comparablePlan: Plan;
    planSnapshot: Plan;
    planValidationResult: PlanValidationResult;
    validatablePlan: ValidatablePlan;
}
