import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Attainment } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    AttainmentMisregistrationModalComponent,
} from '../../attainment/attainment-misregistration-modal/attainment-misregistration-modal.component';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { AttainmentEntityService } from '../../service/attainment-entity.service';
import {
    AcceptedAttainmentTypes,
    attainmentDetailsModalOpener,

} from '../attainment-details/attainment-details-modal/attainment-details-modal.component';
import {
    AttainmentMarkAsSecondaryModalComponent,
} from '../attainment-mark-as-secondary-modal/attainment-mark-as-secondary-modal.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class AttainmentEditModalService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.attainment.attainmentEditModalService',
        serviceName: 'attainmentEditModalService',
    };

    private readonly attainmentDetailsModalOpener = attainmentDetailsModalOpener();

    constructor(private modalService: ModalService,
                private appErrorHandler: AppErrorHandler,
                private attainmentEntityService: AttainmentEntityService) { }

    openMisregistrationModal(attainment: Attainment): Observable<NgbModalRef> {
        return this.modalService.open(AttainmentMisregistrationModalComponent, { attainment }, { size: 'lg' }).closed;
    }

    openMarkAsSecondaryModal(attainment: Attainment): Observable<NgbModalRef> {
        return this.modalService.open(AttainmentMarkAsSecondaryModalComponent, { attainment }).closed;
    }

    openAttainmentDetailsModal(attainment: Attainment): Observable<NgbModalRef> {
        const attainments = this.attainmentEntityService.getByPersonId(attainment.personId);

        return attainments
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                switchMap((selectedAttainments) => {
                    const allMatchingSecondaryAttainments = this.attainmentEntityService.getSecondaryAttainments(attainment, selectedAttainments);
                    const secondaryAttainments = _.filter(allMatchingSecondaryAttainments, { misregistration: false });
                    const hasValidParentAttainment = this.attainmentEntityService.isAttached(attainment, selectedAttainments);
                    const isAboutToExpire = this.attainmentEntityService.isAboutToExpire(attainment);
                    return this.attainmentDetailsModalOpener(attainment as AcceptedAttainmentTypes, secondaryAttainments, hasValidParentAttainment, isAboutToExpire, false).closed;
                }));
    }
}
