import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, CurriculumPeriod, Plan } from 'common-typescript/types';
import { Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import {
    CourseUnitDisplayNamesById, CourseUnitInfoService,
    CourseUnitInfoVersion,
    PreviewModeConfig,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/menuButton/menu-button.component';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { CourseUnitVersionService } from 'sis-components/service/course-unit-version.service';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';

import { ModalTab } from './course-unit-info-modal-header-tabs/course-unit-info-modal-header-tabs.component';

@Component({
    selector: 'app-course-unit-info-modal-header',
    templateUrl: './course-unit-info-modal-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalHeaderComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitModalInfoHeader.sisCourseUnitInfoModalHeader',
        directiveName: 'appCourseUnitInfoModalHeader',
    };

    @Input() courseUnit: CourseUnit;
    @Input() originalCourseUnit: CourseUnit;
    @Input() openTab: 'BASIC' | 'COMPLETION_METHODS' | 'SUBSTITUTIONS' | 'OPEN_UNIVERSITY_OFFERING';
    @Input() tabs: ModalTab[];
    @Input() previewModeConfig: PreviewModeConfig;
    @Input() menuOptions: Option[];
    @Input() plan: Plan;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input() versionSwitchAllowed: boolean;
    @Input() showVersionSwitchNotification: boolean;

    @Output() closeModal = new EventEmitter<any>();
    @Output() setModalCourseUnitVersion = new EventEmitter<CourseUnit>();
    @Output() saveCourseUnitVersionChange = new EventEmitter<void>();
    @Output() selectTab = new EventEmitter<string>();

    versionChanged = false;
    hasNewerVersion = false;
    currentCurriculumPeriod: CurriculumPeriod;
    isLoggedIn = false;
    options: SelectOption[];
    courseUnitVersions: CourseUnitInfoVersion[];
    validatablePlanSubject: ReplaySubject<ValidatablePlan> = new ReplaySubject<ValidatablePlan>(1);
    selectableVersionsAndNames$: Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]>;
    originalCourseUnitHasSubstitutions = false;

    constructor(private appErrorHandler: AppErrorHandler,
                private authService: AuthService,
                private courseUnitInfoService: CourseUnitInfoService,
                private changeDetectorRef: ChangeDetectorRef,
                private curriculumPeriodService: CurriculumPeriodEntityService,
                private courseUnitVersionService: CourseUnitVersionService,
    ) {}

    ngOnInit() {
        this.isLoggedIn = this.authService.loggedIn();
        this.originalCourseUnitHasSubstitutions = this.validatablePlan.isSubstituted(this.originalCourseUnit);
        this.initCurrentCurriculumPeriod();
    }

    initCurrentCurriculumPeriod(): void {
        this.curriculumPeriodService.findCurrentCurriculumPeriod()
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((curriculumPeriod: CurriculumPeriod) => {
                this.currentCurriculumPeriod = curriculumPeriod;
                this.initSelectableVersionsAndNamesObservables();
                this.changeDetectorRef.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseUnit || changes.originalCourseUnit) {
            this.versionChanged = this.originalCourseUnit?.id !== this.courseUnit.id;
        }
        if (changes.validatablePlan) {
            this.validatablePlanSubject.next(this.validatablePlan);
        }
    }

    initSelectableVersionsAndNamesObservables() {
        // get new versions and names on init (plan or no plan) AND whenever the validatable plan changes
        this.selectableVersionsAndNames$ = this.validatablePlanSubject.pipe(
            switchMap((validatablePlan: ValidatablePlan) => this.getSelectableVersionsAndNames(validatablePlan)),
        );
    }

    getSelectableVersionsAndNames(validatablePlan: ValidatablePlan): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.courseUnit, this.previewModeConfig).pipe(
            take(1),
            tap(([courseUnitVersions, displayNamesByCourseUnitId]: [CourseUnitInfoVersion[], CourseUnitDisplayNamesById]) => {
                this.courseUnitVersions = courseUnitVersions;

                this.hasNewerVersion = this.courseUnitVersionService.showVersionChangeSuggestionNotification(
                    this.courseUnit,
                    courseUnitVersions,
                    validatablePlan,
                    this.currentCurriculumPeriod);
                this.options = this.courseUnitInfoService.createSelectOptionsForModal(courseUnitVersions, displayNamesByCourseUnitId, validatablePlan);
            }),
        );
    }

    saveVersionChange() {
        this.saveCourseUnitVersionChange.emit();
        const headerContainer = document.getElementById('student-course-unit-modal-heading');
        headerContainer.focus();
    }
}
