import { Component, Inject, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import {
    CourseUnit,
    CourseUnitManualEvaluationRequiredMessage,
    CourseUnitRealisation,
    Message,
    OtmId,
    Plan,
    ReceivedMessageTypeFilter,
    StudentApplication,
    StudentApplicationMessage,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import {
    map,
    take,
    tap,
} from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    COMMON_MESSAGE_SERVICE,
    COMMON_STUDENT_APPLICATION_SERVICE,
    COURSE_UNIT_REALISATION_SERVICE,
} from '../ajs-upgraded-modules';
import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { MessageTypes } from '../constant/messageTypes';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { Option } from '../menuButton/menu-button.component';
import { CourseUnitEntityService } from '../service/course-unit-entity.service';
import { MessageConversationService } from '../service/message-conversation.service';
import { PlanEntityService } from '../service/plan-entity.service';
import { trackByEntityId } from '../util/utils';

import { MessageTransition } from './sis-message.component';

export interface MessageReferences {
    courseUnitRealisationId: OtmId | null;
    applicationId: OtmId | null;
    planId: OtmId | null;
    courseUnitId: OtmId | null;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-message-conversation',
    templateUrl: './sis-message-conversation.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class SisMessageConversationComponent implements OnChanges, OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-commponents.messages.sisMessageConversation',
        directiveName: 'sisMessageConversation',
    };

    @Input() recipientId: string;

    @Input() messageConversationId: string;

    @Input() filterType: ReceivedMessageTypeFilter = 'DEFAULT_RESTRICTED';

    @Input() returnRoute: string;

    @Input() returnParams: any;

    @Input() userType: 'STAFF' | 'STUDENT' = 'STUDENT';

    allMessages: Message[];
    restOfMessages: Message[];
    firstMessage: Message;
    firstThreeMessages: Message[];
    messageCount: number;
    unreadMessageCount: number;
    messageReferences: MessageReferences;
    courseUnitRealisation: CourseUnitRealisation | null;
    application: StudentApplication | null;
    plan: Plan | null;
    courseUnit: CourseUnit | null;
    data: any;
    applicationUrlsByMessageType: { [type: string]: string };
    expandedMessageIds: string[];
    initialState = true;
    menuOptions: Option[];

    readonly entityId = trackByEntityId;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private messageConversationService: MessageConversationService,
        private translateService: TranslateService,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private planEntityService: PlanEntityService,
        private courseUnitEntityService: CourseUnitEntityService,
        private logger: NGXLogger,
        private alertsService: AlertsService,
        private translocoService: TranslocoService,
        @Inject(COURSE_UNIT_REALISATION_SERVICE) private commonCourseUnitRealisationService: any,
        @Inject(COMMON_MESSAGE_SERVICE) private commonMessageService: any,
        @Inject(COMMON_STUDENT_APPLICATION_SERVICE) private commonStudentApplicationService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnInit() {
        this.data = this.uiRouterGlobals.current.data;
        this.applicationUrlsByMessageType = this.getApplicationUrlsByMessageType();
    }

    ngOnChanges() {
        this.messageConversationService.getReceivedMessagesForConversation(
            this.recipientId,
            this.messageConversationId,
            this.filterType,
            this.userType,
        ).pipe(
            map((messages) => {
                this.allMessages = messages;
                this.messageCount = _.size(messages);
                this.unreadMessageCount = _.size(_.filter(messages, msg => !msg.isRead));
                this.firstMessage = _.first(messages);
                if (this.messageCount >= 3) {
                    this.firstThreeMessages = _.take(messages, 3);
                    this.restOfMessages = _.drop(messages, 3);
                } else {
                    this.firstThreeMessages = _.take(messages, this.messageCount);
                    this.restOfMessages = [];
                }
                if (this.initialState) {
                    this.expandedMessageIds = _.map(this.firstThreeMessages, 'id');
                    _.forEach(this.firstThreeMessages, (message) => {
                        if (!message.isRead) {
                            this.markMessageAsRead(message.id);
                        }
                    });
                }
                this.initialState = false;
                this.menuOptions = this.getDropdownMenuOptions();

                if (!this.messageReferences) {
                    this.messageReferences = this.commonMessageService.getMessageReferences(this.firstMessage);
                    this.getReferredObject();
                }

                return messages;
            }),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }

    markMessageAsRead(messageId: string) {
        this.messageConversationService.markMessageAsRead(messageId).pipe(
            take(1),
            tap(() => {
                this.ngOnChanges();
            }),
        ).subscribe({
            error: this.defaultPromiseHandler.loggingRejectedPromiseHandler,
        });
    }

    markMessageConversationAsRead(): void {
        this.messageConversationService.markMessageConversationsAsRead([this.messageConversationId])
            .pipe(
                take(1),
                tap(() => {
                    this.showSuccessAlert('SIS_COMPONENTS.MESSENGER.ALERTS.MARK_READ_SUCCESS_SINGULAR');
                    this.ngOnChanges();
                }),
            ).subscribe();
    }

    markMessageConversationAsUnread(): void {
        this.messageConversationService.markMessageConversationsAsUnread([this.messageConversationId])
            .pipe(
                take(1),
                tap(() => {
                    this.showSuccessAlert('SIS_COMPONENTS.MESSENGER.ALERTS.MARK_UNREAD_SUCCESS_SINGULAR');
                    this.ngOnChanges();
                }),
            ).subscribe();
    }

    markMessageConversationAsArchived(): void {
        this.messageConversationService.markMessageConversationsAsArchived([this.messageConversationId])
            .pipe(
                take(1),
                tap(() => {
                    this.showSuccessAlert('SIS_COMPONENTS.MESSENGER.ALERTS.ARCHIVE_SUCCESS_SINGULAR');
                    this.ngOnChanges();
                }),
            ).subscribe();
    }

    markMessageConversationAsNotArchived(): void {
        this.messageConversationService.markMessageConversationsAsNotArchived([this.messageConversationId])
            .pipe(
                take(1),
                tap(() => {
                    this.showSuccessAlert('SIS_COMPONENTS.MESSENGER.ALERTS.UNARCHIVE_SUCCESS_SINGULAR');
                    this.ngOnChanges();
                }),
            ).subscribe();
    }

    isExpanded(messageId: string): boolean {
        return _.includes(this.expandedMessageIds, messageId);
    }

    onExpandEvent(message: Message): void {
        if (this.isExpanded(message.id)) {
            _.pull(this.expandedMessageIds, message.id);
            return;
        }
        this.expandedMessageIds.push(message.id);
        if (!message.isRead) {
            this.markMessageAsRead(message.id);
        }

    }

    getApplicationUrlsByMessageType() {
        return {
            [MessageTypes.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE]:
                _.get(this.data, 'openPriorLearningApplicationHref'),
            [MessageTypes.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE]:
                _.get(this.data, 'openPriorLearningApplicationHref'),
            [MessageTypes.MODULE_ATTAINMENT_APPLICATION_MESSAGE]:
                _.get(this.data, 'openModuleAttainmentMessageHref'),
            [MessageTypes.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE]:
                _.get(this.data, 'openDegreeProgrammeAttainmentMessageHref'),
            [MessageTypes.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE]:
                _.get(this.data, 'openCustomAttainmentMessageHref'),
            [MessageTypes.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE]:
                _.get(this.data, 'openStudyRightExtensionApplicationHref'),
            [MessageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE]:
                _.get(this.data, 'openModuleContentApplicationHref'),
            [MessageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE]:
                _.get(this.data, 'openModuleContentApplicationHref'),
        };
    }

    goBack(): void {
        this.stateService.go(this.returnRoute, { returnStateParams: this.returnParams });
    }

    onTransition(event: MessageTransition) {
        if (event.target === 'MessageInPlan') {
            this.openMessageInPlan(event.message);
        }
        if (event.target === 'Application') {
            this.goToApplication(event.message);
        }
        if (event.target === 'Calendar') {
            this.goToCalendar(_.get(event.message, 'courseUnitRealisationId'));
        }
        if (event.target === 'Enrolment') {
            this.goToEnrolment();
        }
        if (event.target === 'CourseUnitEvaluation') {
            this.goToCourseUnitEvaluation(event.message);
        }
        if (event.target === 'Attainment') {
            this.goToAttainment();
        }
    }

    openMessageInPlan(message: Message) {
        if (this.data.openInPlanStructureHref &&
            (message.type === MessageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === MessageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE)
        ) {
            window.location = this.data.openInPlanStructureHref
                .replace(':studentId', _.get(message, 'studentId'))
                .replace(':planId', _.get(message, 'planId'))
                .replace(':moduleContentApprovalId', `moduleContentApprovalId=${_.get(message, 'applicationId')}`)
                .replace('openUnplanned', '');

        } else if (this.data.openInPlanStructureHref && this.messageConversationService.isApplicationMessage(message)) {
            window.location = this.data.openInPlanStructureHref
                .replace(':studentId', _.get(message, 'studentId'))
                .replace(':planId', _.get(message, 'planId'));

        } else if (this.data.openInPlanTutoringHref &&
            (message.type === MessageTypes.PLAN_MODULE ||
                message.type === MessageTypes.PLAN_COURSE_UNIT ||
                message.type === MessageTypes.PLAN_GENERIC ||
                message.type === MessageTypes.PLAN_CUSTOM_STUDY_DRAFT
            )) {
            window.location = this.data.openInPlanTutoringHref
                .replace(':studentId', _.get(message, 'studentId'))
                .replace(':planId', _.get(message, 'planId'));

        } else if (this.data.openInPlanStructureRoute && (message.type === MessageTypes.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE || message.type === MessageTypes.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE)) {
            this.stateService.go(
                this.data.openInPlanStructureRoute,
                {
                    planId: _.get(message, 'planId'),
                    personId: _.get(message, 'studentId'),
                    moduleContentApprovalId: _.get(message, 'applicationId'),
                },
            );

        } else if (this.data.openInPlanTutoringRoute &&
            (message.type === MessageTypes.PLAN_MODULE ||
                message.type === MessageTypes.PLAN_COURSE_UNIT ||
                message.type === MessageTypes.PLAN_GENERIC ||
                message.type === MessageTypes.PLAN_CUSTOM_STUDY_DRAFT
            )) {
            this.stateService.go(this.data.openInPlanTutoringRoute, {
                planId: _.get(message, 'planId'),
                personId: _.get(message, 'studentId'),
            });

        } else {
            this.logger.error('Could not determine correct plan view.');
        }
    }

    goToApplication(message: Message) {
        if (!this.messageConversationService.isApplicationMessage(message)) {
            return;
        }
        const applicationMessage = message as StudentApplicationMessage;
        if (this.isInternalState('staff.logged-in.transactions.studentApplications.studentApplication')) {
            this.stateService.go('staff.logged-in.transactions.studentApplications.studentApplication', {
                studentApplicationId: applicationMessage.applicationId,
            });
        } else if (this.applicationUrlsByMessageType[message.type]) {
            window.location.href = this.applicationUrlsByMessageType[message.type]
                .replace(':applicationId', applicationMessage.applicationId);
        }
    }

    goToCalendar(courseUnitRealisationId?: string) {
        if (courseUnitRealisationId) {
            this.stateService.go('student.logged-in.calendar.enrolments', { courseUnitRealisationId }, { reload: true });
        } else {
            this.stateService.go('student.logged-in.calendar');
        }
    }

    goToEnrolment() {
        this.stateService.go('student.logged-in.enrolments');
    }

    goToCourseUnitEvaluation(message: Message) {
        if (message.type !== MessageTypes.COURSE_UNIT_MANUAL_EVALUATION_REQUIRED_MESSAGE) {
            return;
        }
        const manualEvaluationRequiredMessage = message as CourseUnitManualEvaluationRequiredMessage;
        this.stateService.go(
            'staff.logged-in.studies.courseunit.view.evaluation',
            { courseUnitId: manualEvaluationRequiredMessage.courseUnitId },
        );
    }

    goToAttainment() {
        this.stateService.go('student.logged-in.profile.attainments');
    }

    isInternalState(routeName: string) {
        return !!this.stateService.get(routeName);
    }

    getReferredObject(): void {
        if (this.messageReferences.applicationId) {
            this.commonStudentApplicationService.findById(this.messageReferences.applicationId)
                .then((result: StudentApplication) => {
                    this.application = result;
                })
                .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        } else if (this.messageReferences.planId) {
            this.planEntityService
                .getById(this.messageReferences.planId)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((plan: Plan) => {
                    this.plan = plan;
                });
        } else if (this.messageReferences.courseUnitRealisationId) {
            this.commonCourseUnitRealisationService.findById(this.messageReferences.courseUnitRealisationId)
                .then((result: CourseUnitRealisation) => {
                    this.courseUnitRealisation = result;
                })
                .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        } else if (this.messageReferences.courseUnitId) {
            this.courseUnitEntityService
                .getById(this.messageReferences.courseUnitId)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((courseUnit: CourseUnit) => {
                    this.courseUnit = courseUnit;
                });
        }
    }

    getDropdownMenuOptions(): Option[] {
        return [
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_READ'),
                action: () => {
                    this.expandedMessageIds = [..._.map(this.firstThreeMessages, 'id'), ..._.map(this.restOfMessages, 'id')];
                    this.markMessageConversationAsRead();
                },
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_UNREAD'),
                action: () => {
                    this.expandedMessageIds = [];
                    this.markMessageConversationAsUnread();
                },
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_ARCHIVED'),
                action: () => this.markMessageConversationAsArchived(),
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_NOT_ARCHIVED'),
                action: () => this.markMessageConversationAsNotArchived(),
            },
        ];
    }

    showSuccessAlert(messageKey: string): void {
        const alertMessage = this.translocoService.translate(messageKey);
        this.alertsService.addAlert({
            message: alertMessage,
            type: AlertType.SUCCESS,
        });
    }

}
