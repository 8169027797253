<ng-container *transloco="let t">
  <fudis-dl [columns]="{ sm: 1, md: 2 }">
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.FIRST_NAMES')"/>
      <fudis-dd [contentText]="person.firstNames"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.LAST_NAME')"/>
      <fudis-dd [contentText]="person.lastName"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('FIELD_NAMES.personalIdentityCode')"/>
      <fudis-dd
        [contentText]="isPersonalIdentityCodeVisible ? person.personalIdentityCode : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
        [ariaLabel]="isPersonalIdentityCodeVisible ? t('ARIA_LABEL.HIDE_PERSONAL_ID_NUMBER') : t('ARIA_LABEL.SHOW_PERSONAL_ID_NUMBER')"
      >
        <fudis-button
          [variant]="'tertiary'"
          [size]="'small'"
          [icon]="isPersonalIdentityCodeVisible ? 'eye-blind' : 'eye'"
          [label]="isPersonalIdentityCodeVisible ? t('HIDE') : t('VIEW')"
          (handleClick)="isPersonalIdentityCodeVisible = !isPersonalIdentityCodeVisible"
        ></fudis-button>
      </fudis-dd>
    </fudis-dl-item>
  </fudis-dl>
</ng-container>
