import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FrontpageComponentName, UserSettings } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { ActiveStudiesComponent } from '../widgets/active-studies/active-studies.component';
import { GradeAverageComponent } from '../widgets/grade-average/grade-average.component';
import { RecentAttainmentsComponent } from '../widgets/recent-attainments/recent-attainments.component';
import { StudyProgressGraphComponent } from '../widgets/study-progress-graph/study-progress-graph.component';
import { UpcomingStudiesComponent } from '../widgets/upcoming-studies/upcoming-studies.component';

import { WidgetOption } from './select-widgets/select-widgets-dropdown.component';

@Component({
    selector: 'app-frontpage',
    templateUrl: './frontpage.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontpageComponent implements OnInit {

    userSettings$: Observable<UserSettings>;

    /**
     * These are widgets displayed on the frontpage.
     */
    widgetOptions: WidgetOption[] = [
        { component: UpcomingStudiesComponent, value: 'app-upcoming-studies', labelKey: 'FRONTPAGE.UPCOMING_STUDIES.TITLE' },
        { component: ActiveStudiesComponent, value: 'app-active-studies', labelKey: 'FRONTPAGE.ACTIVE_STUDIES.TITLE' },
        { component: RecentAttainmentsComponent, value: 'app-recent-attainments', labelKey: 'FRONTPAGE.RECENT_ATTAINMENTS.TITLE' },
        { component: StudyProgressGraphComponent, value: 'app-study-progress-graph', labelKey: 'FRONTPAGE.STUDY_PROGRESS.TITLE' },
        { component: GradeAverageComponent, value: 'app-grade-average', labelKey: 'FRONTPAGE.GRADE_AVERAGE.TITLE' },
    ];

    constructor(
        private userSettingsEntityService: UserSettingsEntityService,
        private errorHandler: AppErrorHandler,
    ) {}

    ngOnInit() {
        this.userSettings$ = this.userSettingsEntityService.getOwnSettings()
            .pipe(
                this.errorHandler.defaultErrorHandler(),
            );
    }

    allComponentsHidden(userSettings: UserSettings) {
        if (!userSettings?.componentSettings || Object.values(userSettings.componentSettings).length === 0) return false;

        return Object.values(userSettings?.componentSettings).every(setting => setting.isHidden);
    }

    isVisible(userSettings: UserSettings, componentName: FrontpageComponentName) {
        return !userSettings?.componentSettings?.[componentName]?.isHidden;
    }
}
