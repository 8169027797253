import { Component, Inject, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Attainment, LocalizedString, PriorLearningWorkflow } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { EMPTY, Observable, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { HeaderService } from 'sis-components/header/header.service';
import {
    STUDENT_WORKFLOW_TYPE,
} from 'sis-components/model/student-workflow-constants';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { DegreeProgrammeEntityService } from 'sis-components/service/degree-programme-entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';

import { COURSE_UNIT_INFO_MODAL } from '../../../ajs-upgraded-modules';
import {
    workflowCancellationDialogOpener,
} from '../cancel-workflow-dialog/cancel-workflow-dialog.component';

@Component({
    selector: 'app-prior-learning-workflow',
    templateUrl: './prior-learning-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PriorLearningWorkflowComponent implements OnInit {

    @Input() studentWorkflow: PriorLearningWorkflow;

    headerService = inject(HeaderService);
    stateService = inject(StateService);
    activeAttainment: Attainment;
    supplementRationale$: Observable<string>;
    inclusionApplicationInstruction$: Observable<LocalizedString>;

    private readonly openCancelWorkflowDialog = workflowCancellationDialogOpener();

    constructor(
        @Inject(COURSE_UNIT_INFO_MODAL) private courseUnitInfoModal: any,
        private attainmentEntityService: AttainmentEntityService,
        private workflowDataChangeService: WorkflowDataChangeService,
        private degreeProgrammeEntityService: DegreeProgrammeEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {
    }

    ngOnInit(): void {
        this.headerService.setApplicationStateBadge(this.studentWorkflow);
        this.getActiveAttainment().subscribe();

        if (this.isSupplementRequested()) {
            this.supplementRationale$ = this.getSupplementRequestDescription();

        }
        if (this.isInclusionWorkflow()) {
            this.inclusionApplicationInstruction$ = this.getInclusionApplicationInstruction();
        }
    }

    getActiveAttainment(): Observable<Attainment> {
        return this.attainmentEntityService.getByWorkflowId(this.studentWorkflow?.id)
            .pipe(
                take(1),
                map(attainments => attainments.find(this.isAttainmentActive)),
                tap((attainment) => this.activeAttainment = attainment),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    isAttainmentActive(attainment: Attainment) {
        return !attainment.misregistration && !!attainment.primary;
    }

    openCancelDialog() {
        this.openCancelWorkflowDialog({
            workflowType: this.studentWorkflow?.type,
            workflowId: this.studentWorkflow?.id,
            successCallback: () => this.stateService.reload(),
        }).afterClosed().subscribe();
    }

    openCourseUnitInfoModal() {
        const courseUnitId = _.get(this.studentWorkflow, 'courseUnitId');
        if (courseUnitId) this.courseUnitInfoModal.showCourseUnit(courseUnitId);
    }

    goToSupplementWizard() {
        if (this.isSubstitutionWorkflow()) {
            this.stateService.go(
                'student.logged-in.profile.applications.prior-learning-application.supplement-prior-learning-substitution-workflow',
                { applicationId: this.studentWorkflow?.id });
        } else {
            this.stateService.go(
                'student.logged-in.profile.applications.prior-learning-application.supplement-prior-learning-inclusion-workflow',
                { applicationId: this.studentWorkflow?.id });
        }
    }

    getSupplementRequestDescription() {
        return this.workflowDataChangeService.getActiveSupplementRequestDescription(this.studentWorkflow?.id)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    getInclusionApplicationInstruction() {
        const degreeProgrammeId = _.get(this.studentWorkflow, 'degreeProgrammeId');
        if (degreeProgrammeId) {
            return this.degreeProgrammeEntityService.getById(degreeProgrammeId)
                .pipe(
                    take(1),
                    map((dp) => dp.inclusionApplicationInstruction),
                    this.appErrorHandler.defaultErrorHandler(),
                );
        }
        return EMPTY;
    }

    isWorkflowRequested() {
        return this.studentWorkflow?.state === 'REQUESTED';
    }

    isWorkflowInHandling() {
        return this.studentWorkflow?.state === 'IN_HANDLING';
    }

    isWorkflowCancelled() {
        return this.studentWorkflow?.state === 'CANCELLED';
    }

    isSupplementRequested() {
        return this.studentWorkflow?.state === 'SUPPLEMENT_REQUESTED';
    }

    isInitiatedByStaff() {
        return this.studentWorkflow?.initiatorType === 'STAFF';
    }

    isSubstitutionWorkflow() {
        return this.studentWorkflow?.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW;
    }

    isInclusionWorkflow() {
        return this.studentWorkflow?.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW;
    }
}
