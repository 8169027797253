import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CourseUnitRealisation,
    EnrolmentCalculationConfig,
    Urn,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { EnrolmentCalculationConfigEntityService } from '../../service/enrolment-calculation-config-entity.service';
import { UniversityService } from '../../service/university.service';
import { Tab } from '../../tabs/tab-content-switch/tab-content-switch.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-info-modal-course-unit-realisation',
    templateUrl: './course-unit-info-modal-course-unit-realisation.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitInfoModalCourseUnitRealisationComponent implements OnChanges, OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitInfoModal.courseUnitRealisation',
        directiveName: 'sisCourseUnitInfoModalCourseUnitRealisation',
    };

    openTabIndex = 0;
    currentUniversityCustomCodeUrns: Urn[] = [];
    hasCoordinatingOrganisations: boolean;
    enrolmentCalculationConfig$: Observable<EnrolmentCalculationConfig>;
    tabs: Tab[];

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private universityService: UniversityService,
        private enrolmentCalculationConfigEntityService: EnrolmentCalculationConfigEntityService) { }

    ngOnInit() {
        this.enrolmentCalculationConfig$ = this.enrolmentCalculationConfigEntityService.getEnrolmentCalculationConfigOrNull(this.courseUnitRealisation.id).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        );
        this.tabs = [
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.BASIC_INFO',
                id: `course-unit-info-modal-cur-basic-info-tab-${this.courseUnitRealisation.id}`,

            },
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.ENROLMENT_INFO',
                id: `course-unit-info-modal-cur-enrolment-info-tab-${this.courseUnitRealisation.id}`,

            },
            {
                title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.STUDY_GROUPS',
                id: `course-unit-info-modal-cur-study-groups-tab-${this.courseUnitRealisation.id}`,

            },
        ];

    }

    ngOnChanges() {
        this.currentUniversityCustomCodeUrns = _.get(this.courseUnitRealisation.customCodeUrns, this.universityService.getCurrentUniversityOrgId()) || [];
        this.hasCoordinatingOrganisations = _.some(
            this.courseUnitRealisation.organisations,
            { roleUrn: 'urn:code:organisation-role:coordinating-organisation' },
        );
    }

    onTabChange(tabIndex: number) {
        this.openTabIndex = tabIndex;
    }

    hasRequirementPersonRules(enrolmentCalculationConfig: EnrolmentCalculationConfig) {
        return enrolmentCalculationConfig.requirementPersonRules !== null && enrolmentCalculationConfig.requirementPersonRules.length > 0;
    }

    hasOrderingPersonRules(enrolmentCalculationConfig: EnrolmentCalculationConfig) {
        return enrolmentCalculationConfig.orderingPersonRules !== null && enrolmentCalculationConfig.orderingPersonRules.length > 0;
    }

}
