import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CourseUnitEnrolmentRight, OtmId, UsedEnrolments } from 'common-typescript/types';
import { orderBy } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { LocalDateRangePipe } from '../../date/pipes/local-date-range/local-date-range.pipe';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { getLabelState } from '../../form/formUtils';
import { Option } from '../../select/dropdown-select/dropdown-select.component';
import { CourseUnitEntityService } from '../../service/course-unit-entity.service';
import { EnrolmentRightEntityService } from '../../service/enrolment-right-entity.service';

/**
 * Allows choosing an enrolment right that can be used to enrol to a specific course unit realisation
 * in the context of a specific study right, course unit and assessment item. If only one eligible
 * enrolment right exists, it will be shown as read-only. Otherwise, a dropdown will be rendered.
 *
 * This component does not check whether an enrolment right should be selected during enrolment.
 */
@Component({
    selector: 'sis-select-enrolment-right-for-enrolment',
    templateUrl: './select-enrolment-right-for-enrolment.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SelectEnrolmentRightForEnrolmentComponent implements OnChanges {

    @Input() id?: string = 'enrolmentRightId';
    @Input() control: FormControl<OtmId>;
    @Input() studyRightId: OtmId;
    @Input() courseUnitId: OtmId;
    @Input() assessmentItemId: OtmId;
    @Input() courseUnitRealisationId: OtmId;
    /** The id of the student whose enrolment rights to fetch, defaults to the authenticated user */
    @Input() personId?: OtmId;
    /** If true, each option label will be prefixed with the name of the course unit. */
    @Input() showCourseUnitName = false;

    options: Option[];

    readonly getLabelState = getLabelState;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitService: CourseUnitEntityService,
        private enrolmentRightService: EnrolmentRightEntityService,
        private localeService: LocaleService,
        private translate: TranslateService,
        private localDateRangePipe: LocalDateRangePipe,
        private ref: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studyRightId || changes.courseUnitId || changes.assessmentItemId || changes.courseUnitRealisationId) {
            this.updateEnrolmentRightOptions();
        }
    }

    private updateEnrolmentRightOptions(): void {
        if (!this.studyRightId || !this.courseUnitId || !this.assessmentItemId || !this.courseUnitRealisationId) {
            this.options = [];
            return;
        }

        this.enrolmentRightService
            .findEligibleEnrolmentRights(this.studyRightId, this.courseUnitId, this.assessmentItemId, this.courseUnitRealisationId, this.personId)
            .pipe(
                map(enrolmentRights => orderBy(
                    enrolmentRights,
                    ['validityPeriod.startDate', 'validityPeriod.endDate'],
                    ['desc', 'desc'],
                )),
                switchMap(enrolmentRights => combineLatest([
                    of(enrolmentRights),
                    this.enrolmentRightService.countUsedEnrolments(enrolmentRights.map(({ id }) => id)),
                    this.resolveCourseUnitName(),
                ])),
                map(([enrolmentRights, enrolmentCounts, courseUnitName]) =>
                    enrolmentRights.map(enrolmentRight => this.enrolmentRightToOption(enrolmentRight, enrolmentCounts, courseUnitName))),
                this.appErrorHandler.defaultErrorHandler([]),
                take(1),
                tap(options => this.updateControlValue(options)),
            )
            .subscribe((options) => {
                this.options = options;
                this.ref.markForCheck();
            });
    }

    private updateControlValue(options: Option[]): void {
        if (options?.length === 1 && !options[0].disabled) {
            this.control?.setValue(options[0].value);
        } else if (this.control?.value && !options?.some(option => option.value === this.control.value)) {
            this.control.setValue(null);
        }
    }

    private resolveCourseUnitName(): Observable<string | null> {
        return (this.showCourseUnitName ? this.courseUnitService.getById(this.courseUnitId) : of(null))
            .pipe(
                map(courseUnit => this.localeService.localize(courseUnit?.name) ?? null),
                catchError(() => of(null)),
            );
    }

    private enrolmentRightToOption(enrolmentRight: CourseUnitEnrolmentRight, usedEnrolments: UsedEnrolments[], courseUnitName?: string): Option {
        const maxNumberOfEnrolments = enrolmentRight.enrolmentConstraints
            ?.find(constraint => constraint.assessmentItemId === this.assessmentItemId)
            ?.maxNumberOfEnrolments ?? null;
        const usedNumberOfEnrolments = usedEnrolments
            ?.find(entry => entry.enrolmentRightId === enrolmentRight.id && entry.assessmentItemId === this.assessmentItemId)
            ?.enrolmentCount ?? 0;
        const hasEnrolmentsRemaining = maxNumberOfEnrolments === null || usedNumberOfEnrolments < maxNumberOfEnrolments;

        const validityPeriodLabel: string = this.translate.instant(
            'ENROLMENT.OPEN_UNIVERSITY_ENROLMENT.VALID_DURING',
            { dateRange: this.localDateRangePipe.transform(enrolmentRight.validityPeriod) },
        );
        const label = courseUnitName ? `${courseUnitName} (${validityPeriodLabel})` : validityPeriodLabel;

        let info: string;
        if (Number.isInteger(maxNumberOfEnrolments) && maxNumberOfEnrolments > 0) {
            if (hasEnrolmentsRemaining) {
                info = `${maxNumberOfEnrolments - usedNumberOfEnrolments}/${maxNumberOfEnrolments} \
${this.translate.instant('ENROLMENT.OPEN_UNIVERSITY_ENROLMENT.ENROLMENTS_REMAINING')}`;
            } else {
                info = this.translate.instant('ENROLMENT.OPEN_UNIVERSITY_ENROLMENT.NO_ENROLMENTS_REMAINING');
            }
        }

        return {
            label,
            info,
            value: enrolmentRight.id,
            disabled: !hasEnrolmentsRemaining,
        };
    }
}
