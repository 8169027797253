import angular from 'angular';
import * as _ from 'lodash-es';
import { authServiceModule } from 'sis-common/auth/auth.service';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import { enrolmentServiceModule } from '../common/service/enrolment.service';
(function () {
  angular.module('student.calendar.route', ['student.calendar', 'student.common.components.planSeeker', 'sis-components.model.enrolment', 'sis-components.plan.planLoader', commonPlanServiceModule, 'sis-components.service.courseUnitService', enrolmentServiceModule, authServiceModule]).config(["$stateProvider", $stateProvider => {
    $stateProvider.state('student.logged-in.calendar', {
      url: '/calendar',
      component: 'calendar',
      resolve: {
        getValidatablePlan: ["$q", "planSeeker", "PlanLoader", function ($q, planSeeker, PlanLoader) {
          return _.once(() => planSeeker.find().then(planId => planId ? PlanLoader.createValidatablePlan(planId) : $q.resolve(null)));
        }]
      },
      data: {
        hideFooter: true,
        hideLastNode: false,
        browserTabTitle: 'APPLICATION_SCHEDULE'
      },
      headerParams: {
        displayNameKey: 'APPLICATION_SCHEDULE',
        skipBreadcrumb: false
      },
      redirectTo: 'student.logged-in.calendar.enrolments'
    })
    // DEPRECATED! These states are here for backwards compatibility. The study calendar URL changed in v2.13,
    // and these routes allow any old bookmarks, shared links etc. to work. Can be removed in some later release.
    .state('student.logged-in.plan.calendar', {
      url: '/calendar',
      redirectTo: 'student.logged-in.calendar'
    }).state('student.logged-in.plan.calendar.children', {
      url: '/*ignore',
      redirectTo: 'student.logged-in.calendar'
    });
  }]);
})();