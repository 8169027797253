import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength } from 'common-typescript/constants';
import * as _ from 'lodash-es';
import { maxLength, required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

@Component({
    selector: 'app-edit-contact-info-for-graduation',
    templateUrl: './edit-contact-info-for-graduation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EditContactInfoForGraduationComponent implements OnInit {
    form: FormGroup;
    codebookUrns = { country: 'urn:code:country' };
    countryUrnFI = 'urn:code:country:246';
    countryUrnAX = 'urn:code:country:248';
    isSecondaryAddress: boolean;
    getLabelState = getLabelState;

    @Input() person: any;
    @Output() saveEdit = new EventEmitter<any>();
    @Output() cancelEdit = new EventEmitter<void>();

    constructor(private fb: SisFormBuilder) {}

    ngOnInit(): void {
        this.isSecondaryAddress = !!this.person.secondaryAddress;

        this.form = this.buildForm();

        if (this.isSecondaryAddress) {
            this.resolveSecondaryAddressValidators();
            this.secondaryAddressCountryUrn.setValue(this.isSecondaryAddressInFinland.value ? null : this.person.secondaryAddress.countryUrn);
            this.secondaryAddressStreetAddressFinland.setValue(this.isSecondaryAddressInFinland.value
                ? this.person.secondaryAddress.streetAddress : null);
            this.secondaryAddressStreetAddressAbroad.setValue(!this.isSecondaryAddressInFinland.value
                ? this.person.secondaryAddress.address : null);
            this.secondaryAddressPostalCode.setValue(this.isSecondaryAddressInFinland.value ? this.person.secondaryAddress.postalCode : null);
            this.secondaryAddressCity.setValue(this.isSecondaryAddressInFinland.value ? this.person.secondaryAddress.city : null);
        }
    }

    buildForm(): FormGroup {
        return this.fb.group({
            phoneNumber: this.fb.control(this.person.phoneNumber ? this.person.phoneNumber : null,
                                         maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)),
            secondaryEmail: this.fb.control(this.person.secondaryEmail ? this.person.secondaryEmail : null,
                                            maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)),
            isSecondaryAddressInFinland: this.fb.control(
                this.isSecondaryAddress ? _.get(this.person, 'secondaryAddress.type') === 'FinnishAddress' : null),
            secondaryAddressStreetAddressFinland: this.fb.control(null),
            secondaryAddressStreetAddressAbroad: this.fb.control(null),
            secondaryAddressPostalCode: this.fb.control(null),
            secondaryAddressCity: this.fb.control(null),
            secondaryAddressCountryUrn: this.fb.control(null),
        });
    }

    resolveSecondaryAddressValidators() {
        this.secondaryAddressStreetAddressAbroad.clearValidators();
        this.secondaryAddressCountryUrn.clearValidators();
        this.secondaryAddressStreetAddressFinland.clearValidators();
        this.secondaryAddressPostalCode.clearValidators();
        this.secondaryAddressCity.clearValidators();

        if (this.isSecondaryAddress && this.isSecondaryAddressInFinland.value) {
            this.secondaryAddressStreetAddressFinland.setValidators([maxLength(MaxLength.MAX_SHORT_STRING_LENGTH), required()]);
            this.secondaryAddressPostalCode.setValidators([maxLength(MaxLength.MAX_SHORT_STRING_LENGTH), required()]);
            this.secondaryAddressCity.setValidators([maxLength(MaxLength.MAX_SHORT_STRING_LENGTH), required()]);
        } else if (this.isSecondaryAddress && !this.isSecondaryAddressInFinland.value) {
            this.secondaryAddressStreetAddressAbroad.setValidators([maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH), required()]);
            this.secondaryAddressCountryUrn.setValidators([required()]);
        }

        this.secondaryAddressCountryUrn.updateValueAndValidity();
        this.secondaryAddressStreetAddressAbroad.updateValueAndValidity();
        this.secondaryAddressStreetAddressFinland.updateValueAndValidity();
        this.secondaryAddressPostalCode.updateValueAndValidity();
        this.secondaryAddressCity.updateValueAndValidity();
    }

    get phoneNumber(): FormControl {
        return this.form.get('phoneNumber') as FormControl;
    }

    get secondaryEmail(): FormControl {
        return this.form.get('secondaryEmail') as FormControl;
    }

    get isSecondaryAddressInFinland(): FormControl {
        return this.form.get('isSecondaryAddressInFinland') as FormControl;
    }

    get secondaryAddressStreetAddressFinland(): FormControl {
        return this.form.get('secondaryAddressStreetAddressFinland') as FormControl;
    }

    get secondaryAddressStreetAddressAbroad(): FormControl {
        return this.form.get('secondaryAddressStreetAddressAbroad') as FormControl;
    }

    get secondaryAddressPostalCode(): FormControl {
        return this.form.get('secondaryAddressPostalCode') as FormControl;
    }

    get secondaryAddressCity(): FormControl {
        return this.form.get('secondaryAddressCity') as FormControl;
    }

    get secondaryAddressCountryUrn(): FormControl {
        return this.form.get('secondaryAddressCountryUrn') as FormControl;
    }

    save() {
        this.person.phoneNumber = this.phoneNumber.value ? this.phoneNumber.value : undefined;
        this.person.secondaryEmail = this.secondaryEmail.value ? this.secondaryEmail.value : undefined;

        if (this.isSecondaryAddress) {
            _.set(this.person, 'secondaryAddress', {});

            if (this.isSecondaryAddressInFinland.value) {
                _.set(this.person, 'secondaryAddress.streetAddress', this.secondaryAddressStreetAddressFinland.value);
                _.set(this.person, 'secondaryAddress.type', 'FinnishAddress');
                _.set(this.person, 'secondaryAddress.city', this.secondaryAddressCity.value);
                _.set(this.person, 'secondaryAddress.postalCode', this.secondaryAddressPostalCode.value);
                _.set(this.person, 'secondaryAddress.countryUrn', this.countryUrnFI);
            } else {
                _.set(this.person, 'secondaryAddress.countryUrn', this.secondaryAddressCountryUrn.value);
                _.set(this.person, 'secondaryAddress.address', this.secondaryAddressStreetAddressAbroad.value);
                _.set(this.person, 'secondaryAddress.type', 'GenericAddress');
            }
        } else {
            delete this.person.secondaryAddress;
        }

        this.saveEdit.emit({ profile: this.person });
    }

    cancel() {
        this.cancelEdit.emit();
    }

    addSecondaryAddress() {
        this.isSecondaryAddress = true;
        this.secondaryAddressInFinlandUpdated(true);
    }

    removeSecondaryAddress() {
        this.isSecondaryAddress = false;

        this.isSecondaryAddressInFinland.setValue(null);
        this.secondaryAddressStreetAddressFinland.reset();
        this.secondaryAddressPostalCode.reset();
        this.secondaryAddressCity.reset();
        this.secondaryAddressCountryUrn.reset();
        this.secondaryAddressStreetAddressAbroad.reset();

        this.resolveSecondaryAddressValidators();
    }

    secondaryAddressInFinlandUpdated(isSecondaryAddressInFinland: boolean) {
        this.isSecondaryAddressInFinland.setValue(isSecondaryAddressInFinland);
        this.resolveSecondaryAddressValidators();
    }

    setSecondaryAddressCountryUrn(selectedUrn?: any) {
        this.secondaryAddressCountryUrn.setValue(selectedUrn);
    }
}
