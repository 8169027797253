import { MaxLength } from 'common-typescript/constants';
import {
    CreditRange,
    LocalDateRange,
    LocalDateTimeRange,
    PartialBy,
    SearchParameters,
    SearchRequest,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { isNil, negate } from 'lodash-es';

function isNotNil(value: unknown): boolean {
    return value !== null && value !== undefined;
}

type Primitive = string | boolean | number;

function toQueryParam(value: Primitive | Primitive[]): string | string[] {
    if (Array.isArray(value)) {
        const stringArray = value.filter(isNotNil).map(val => val.toString().trim()).filter(val => val.length > 0);
        return stringArray.length > 0 ? stringArray : null;
    }

    const asString = value?.toString().trim();
    return asString?.length > 0 ? asString : null;
}

/**
 * Counts selected search filter values omitting fullTextQuery and universityOrgId.
 *
 * @param searchParameters
 */
export function countSelectedFilters(searchParameters: SearchParameters): number {
    const { fullTextQuery, universityOrgId, ...filtersToCount } = searchParameters?.filters || {};
    return Object.values(filtersToCount)
        .filter(negate(isNil))
        .flatMap(value => Array.isArray(value) ? value : [value])
        .filter(negate(isNil))
        .length;
}

export function isSearchValid(searchParameters: SearchParameters): boolean {
    const fullTextQueryLength = (searchParameters?.filters?.fullTextQuery || '').length;
    if (fullTextQueryLength >= 3 && fullTextQueryLength <= MaxLength.MAX_TERSE_STRING_LENGTH) {
        return true;
    }
    return countSelectedFilters(searchParameters) > 0 && fullTextQueryLength === 0;
}

export function creditRangeToQueryParam(range: Partial<CreditRange>) {
    if (isNil(range?.min) && isNil(range?.max)) {
        return null;
    }
    return `${range.min ?? ''},${range.max ?? ''}`;
}

/**
 * This method return null if startDate is not set.
 */
export function dateRangeToQueryParam(dateRange: PartialBy<LocalDateRange, 'endDate'>): string | null {
    if (!dateRange || !dateRange.startDate) {
        return null;
    }

    return `${dateRange.startDate}${dateRange.endDate ? `,${dateRange.endDate}` : ''}`;
}

/**
 * This method returns dateRange string if startDate or endDate is not set.
 */
export function dateRangeAllowBlankDateToQueryParam(dateRange: Partial<LocalDateRange>): string | null {
    if (!dateRange || (!dateRange.startDate && !dateRange.endDate)) {
        return null;
    }

    const startDate = dateRange.startDate ? `${dateRange.startDate}` : '';
    const endDate = dateRange.endDate ? `,${dateRange.endDate}` : '';

    return `${startDate}${endDate}`;
}

export function dateTimeRangeToQueryParam(dateTimeRange: Partial<LocalDateTimeRange>): string | null {
    if (!dateTimeRange || (!dateTimeRange.startDateTime && !dateTimeRange.endDateTime)) {
        return null;
    }

    const startTime = dateTimeRange.startDateTime ? `${dateTimeRange.startDateTime}` : '';
    const endTime = dateTimeRange.endDateTime ? `,${dateTimeRange.endDateTime}` : '';

    return `${startTime}${endTime}`;
}

/**
 * Converts a simple, string-keyed object that only contains primitive values (or arrays of primitive values) into
 * an object that can be used as HTTP request query parameters (using e.g. the Angular HttpClient). All values in
 * the object are converted to strings or string arrays (depending on whether the original value is a primitive or
 * an array, respectively), and all strings are trimmed. All keys whose value would be converted to an empty string
 * or an empty array will be omitted from the result. If the resulting object would be empty, returns `null` instead.
 */
export function simpleObjectToQueryParams(object: { [key: string]: Primitive | Primitive[] }): { [key: string]: string | string[] } {
    if (typeof object !== 'object' || Array.isArray(object) || !object) {
        return null;
    }

    const parameters = Object.entries(object)
        .map(([key, value]) => ({ key, value: toQueryParam(value) }))
        .filter(({ value }) => isNotNil(value) && value.length > 0)
        .reduce((params, { key, value }) => ({ ...params, [key]: value }), {});

    return Object.keys(parameters).length > 0 ? parameters : null;
}

/**
 * Converts a `SearchRequest` to an object accepted by `HttpClient` as the query parameters of an HTTP request.
 * Properties with empty values will be omitted.
 */
export function searchRequestToQueryParams(searchRequest: Partial<SearchRequest<any>>): { [key: string]: string | string[] } {
    if (_.isEmpty(searchRequest)) {
        return {};
    }

    return _.omitBy(
        {
            fullTextQuery: searchRequest.fullTextQuery,
            documentState: searchRequest.documentStates,
            start: _.isInteger(searchRequest.start) ? searchRequest.start.toString() : null,
            limit: _.isInteger(searchRequest.limit) ? searchRequest.limit.toString() : null,
            sort: _.isArray(searchRequest.sorts) ? searchRequest.sorts.filter(Boolean).join(',') : null,
            uiLang: searchRequest.uiLang,
        },
        _.isEmpty,
    );
}
