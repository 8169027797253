import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Input, Output,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, CourseUnitSelection, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

import {
    CompletionMethodSelections,
    SelectCompletionMethodComponent, SelectCompletionMethodDialogValues,
} from '../../../plan/select-completion-method/select-completion-method.component';

@Component({
    selector: 'app-course-unit-selection-info',
    templateUrl: './course-unit-selection-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitSelectionInfoComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.courseUnitSelectionInfo',
        directiveName: 'appCourseUnitSelectionInfo',
    };

    constructor(
        private modalService: ModalService,
    ) {
    }

    @Input({ required: true }) courseUnitSelection: CourseUnitSelection;
    @Input({ required: true }) courseUnit: CourseUnit;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input() currentAttainmentId: OtmId;
    @Input() isAttained: boolean = false;
    @Output() selectCompletionMethod = new EventEmitter<CompletionMethodSelections>();

    isCompletionMethodSelectionMissing() {
        return !this.courseUnitSelection?.completionMethodId;
    }

    getCompletionMethodIndex(): number {
        const index = this.courseUnit?.completionMethods.map(completionMethod =>
            completionMethod.localId).indexOf(this.courseUnitSelection?.completionMethodId);
        return index >= 0 ? index + 1 : undefined;
    }

    hasCompletionMethods(): boolean {
        return this.courseUnit?.completionMethods?.length > 0;
    }

    getOpenDialogTranslationKeyWhenCompletionMethodSelected(): string {
        return this.courseUnit?.completionMethods?.filter(method => method.studyType === 'DEGREE_STUDIES').length === 1 ? 'PLAN.VIEW_COMPLETION_METHOD' : 'PLAN.CHANGE_COMPLETION_METHOD';
    }

    openDialog() {
        this.modalService.open(SelectCompletionMethodComponent, <SelectCompletionMethodDialogValues>{
            assessmentItemSelections: _.map(this.validatablePlan.getSelectedAssessmentItems(this.courseUnit), (ai) => ai.id),
            validatablePlan: this.validatablePlan,
            currentAttainmentId: this.currentAttainmentId,
            courseUnit: this.courseUnit,
            isGradeRaiseAttempt: false,
            completionMethodId: this.courseUnitSelection.completionMethodId,
        }, { size: 'sm' }).closed
            .subscribe((completionMethodSelections: CompletionMethodSelections) => {
                this.selectCompletionMethod.emit(completionMethodSelections);
            });
    }
}
