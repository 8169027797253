<ng-container *transloco="let t">
  <sis-expandable [title]="customModuleAttainment | attainmentName | async | localizedString"
                  [variant]="'naked-dotted'"
                  [contentPadding]="'none'"
                  [closed]="false"
                  [level]="headingLevel">
    <ng-template sisExpandableCustomTitle>
      <sis-plan-structure-module-state-badges [stateObject]="customModuleAttainmentStateObject">
      </sis-plan-structure-module-state-badges>
      <div class="expandable__title__sub-title expandable__title__sub-title__size__md">
        <div class="d-flex flex-column flex-sm-row align-items-center">
          <div class="me-auto me-sm-0">
            <ng-container *ngIf="customModuleAttainment?.code">
             {{ customModuleAttainment.code}}
            </ng-container>
          </div>
          <div class="me-auto me-sm-0 ms-sm-auto">
            <sis-plan-structure-module-selection-state
              [stateObject]="customModuleAttainmentStateObject">
            </sis-plan-structure-module-selection-state>
          </div>
        </div>
        <hr class="sis-hr sis-mb-zero sis-mt-xxs"/>
      </div>
    </ng-template>
    <ng-template *ngIf="customModuleAttainment" sisExpandableContent>
      <div class="d-flex flex-lg-row flex-column study-credit-info">
        <sis-description-list-compact [fillByColumn]="false">
          <dl>
            <ng-template #compactListItem>
              <dt class="label colon">{{t('SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS')}}</dt>
              <dd class="value">{{((customModuleAttainment?.credits | decimalNumber) + ' ' + t('CREDITS')) ?? '-'}}</dd>
            </ng-template>
            <ng-template #compactListItem>
              <dt class="label colon">{{t('SIS_COMPONENTS.PLAN.LABEL.ATTAINED')}}</dt>
              <dd class="value" *ngIf="customModuleAttainment?.credits as attainedCredits; else noValue">
                {{ (attainedCredits | decimalNumber) + ' ' + t('CREDITS')}}
              </dd>
              <ng-template #noValue>
                <dd class="value">{{'-'}}</dd>
              </ng-template>
            </ng-template>
          </dl>
        </sis-description-list-compact>
      </div>
      <sis-plan-structure-attainment-node-structure
        *ngIf="customModuleAttainment"
        [attainmentNodes]="customModuleAttainment.nodes"
        [planData]="planData"
        [planStateObject]="planStateObject"
        [headingLevel]="headingLevel + 1">
      </sis-plan-structure-attainment-node-structure>
    </ng-template>
  </sis-expandable>
</ng-container>
