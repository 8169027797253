import { Component, DestroyRef, inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AssessmentItem,
    CompletionMethod,
    CourseUnit, CourseUnitEnrolmentRight, CourseUnitRealisation,
    EnrolmentConstraint,
    OtmId,
} from 'common-typescript/types';
import { Observable, of, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

import { AggregateProductInfo, AggregateProductInfoType } from '../types';

/**
 * Expects a provision of {@link AssessmentItemTeachingInitializationStateService} from a DOM ancestor.
 */
@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProductDetailsComponent implements OnChanges {

    @Input({ required: true }) courseUnit: CourseUnit;
    @Input({ required: true }) product: AggregateProductInfo;
    @Input({ required: true }) mobileView: boolean;

    completionMethod: CompletionMethod;
    assessmentItems$: Observable<AssessmentItem[]>;
    cursForPurchasedProductByAssItemId: Map<OtmId, CourseUnitRealisation[]> = new Map();

    readonly entityId = trackByEntityId;

    constructor(
        private assessmentItemService: AssessmentItemEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private destroyedRef: DestroyRef,
    ) {}

    private readonly appErrorHandler = inject(AppErrorHandler);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.courseUnit?.currentValue?.id !== changes.courseUnit?.previousValue?.id ||
            changes.product?.currentValue?.id !== changes.product?.previousValue?.id) {

            let assessmentItemIds: OtmId[];
            if (this.isPurchased) {
                const enrolmentRight: CourseUnitEnrolmentRight = this.product?.enrolmentRight;
                if (!enrolmentRight) {
                    throw new Error('Product is purchased, but enrolmentRight is missing.');
                }
                this.completionMethod = this.courseUnit?.completionMethods?.find(cm => cm.localId === enrolmentRight?.completionMethodId);
                assessmentItemIds = enrolmentRight?.enrolmentConstraints?.map(constraint => constraint.assessmentItemId);
                this.getCursForPurchasedProductByAssItemIds(enrolmentRight.enrolmentConstraints);
            } else {
                this.completionMethod = this.courseUnit?.completionMethods?.find(cm => cm.localId === this.product?.completionMethodId);
                assessmentItemIds = Object.keys(this.product?.teachingOnSale ?? {});
            }

            if (assessmentItemIds?.length > 0) {
                this.assessmentItems$ = this.assessmentItemService.getByIdsSorted(assessmentItemIds, this.courseUnit?.id);
            } else {
                delete this.assessmentItems$;
            }
        }
    }

    private getCursForPurchasedProductByAssItemIds(enrolmentConstraints: EnrolmentConstraint[]) {
        if (!enrolmentConstraints) {
            return;
        }

        for (const constraint of enrolmentConstraints) {
            this.getCursForPurchasedProduct(constraint)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                    takeUntilDestroyed(this.destroyedRef),
                )
                .subscribe(curs => this.cursForPurchasedProductByAssItemId.set(constraint.assessmentItemId, curs));
        }
    }

    private getCursForPurchasedProduct(enrolmentConstraint: EnrolmentConstraint): Observable<CourseUnitRealisation[]> {
        if (!enrolmentConstraint?.allowedCourseUnitRealisationIds) {
            return this.courseUnitRealisationEntityService.getByAssessmentItemId(enrolmentConstraint.assessmentItemId);
        }
        if (enrolmentConstraint.allowedCourseUnitRealisationIds.length === 0) {
            return of([]);
        }
        if (enrolmentConstraint.allowedCourseUnitRealisationIds.length > 0) {
            return this.courseUnitRealisationEntityService.getByIds(enrolmentConstraint.allowedCourseUnitRealisationIds);
        }
    }

    get isOnSale(): boolean {
        return this.product?.type === AggregateProductInfoType.Current;
    }

    get isFutureProduct(): boolean {
        return this.product?.type === AggregateProductInfoType.Future;
    }

    get isPurchased(): boolean {
        return this.product?.type === AggregateProductInfoType.Purchased;
    }
}
