import { InjectionToken, Provider } from '@angular/core';
import * as angular from 'angular';
import {
    Attainment,
    Code,
    CodeBook,
    CourseUnit,
    CourseUnitRealisation,
    DegreeProgramme,
    EnrolmentCalculationConfig,
    Module,
    Organisation,
    PrivatePerson,
    PrivatePersonBasicInfo,
    StudyModule,
    Urn,
} from 'common-typescript/types';
import { DS, DSResourceDefinition } from 'js-data';

// Some of the following imports prevents "is missing from the TypeScript compilation" error if the file is only imported in JavaScript files
import './attainment/AttainmentUtil'; // same as above
import { EditModeService } from './edit/editMode.service';
import './model/competencyConstants';
import './model/qualificationConstants';

/* **************************************************************************************
 * NOTE: Don't add any new upgrade configs here without a very good reason. If you need *
 * functionality from a legacy AngularJS service, your first choice should always be to *
 * move/reimplement that functionality to a new/existing Angular service.               *
 ************************************************************************************** */

const angularJSInjectorName = '$injector';

/**
 * @deprecated
 */
export const COURSE_UNIT_INFO_MODAL = new InjectionToken<any>('courseUnitInfoModal');
export const courseUnitInfoModalFactory = (injector: angular.auto.IInjectorService) => injector.get('courseUnitInfoModal');
export const courseUnitInfoModalProvider: Provider = {
    provide: COURSE_UNIT_INFO_MODAL,
    useFactory: courseUnitInfoModalFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `AlertsService` instead
 */
export const ALERTS_SERVICE = new InjectionToken<any>('alertsService');
export const alertsServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('alertsService');
export const alertsServiceProvider: Provider = {
    provide: ALERTS_SERVICE,
    useFactory: alertsServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `AssessmentItemEntityService` instead
 */
export const ASSESSMENT_ITEM_SERVICE = new InjectionToken<any>('commonAssessmentItemService');
export const assessmentItemServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonAssessmentItemService');
export const assessmentItemServiceProvider: Provider = {
    provide: ASSESSMENT_ITEM_SERVICE,
    useFactory: assessmentItemServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CommonCodeService` instead
 */
export const CODEBOOK_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<CodeBook>>('codebookJSDataModel');
export const codebookJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('codebookJSDataModel');
export const codebookJSDataModelProvider: Provider = {
    provide: CODEBOOK_JS_DATA_MODEL,
    useFactory: codebookJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CourseUnitEntityService` instead
 */
export const COURSE_UNIT_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<CourseUnit>>('courseUnitJSDataModel');
export const courseUnitJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('courseUnitJSDataModel');
export const courseUnitJSDataModelProvider: Provider = {
    provide: COURSE_UNIT_JS_DATA_MODEL,
    useFactory: courseUnitJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const LANGUAGE_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<any>>('languageJSDataModel');
export const languageJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('languageJSDataModel');
export const languageJSDataModelProvider: Provider = {
    provide: LANGUAGE_JS_DATA_MODEL,
    useFactory: languageJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COLOR_SERVICE = new InjectionToken<any>('colorService');
export const colorServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('colorService');
export const colorServiceProvider: Provider = {
    provide: COLOR_SERVICE,
    useFactory: colorServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `ApplicationDecisionEntityService` instead
 */
export const COMMON_APPLICATION_DECISION_SERVICE = new InjectionToken<any>('commonApplicationDecisionService');
export const commonApplicationDecisionServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonApplicationDecisionService');
export const commonApplicationDecisionServiceProvider: Provider = {
    provide: COMMON_APPLICATION_DECISION_SERVICE,
    useFactory: commonApplicationDecisionServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `AttainmentEntityService` instead
 */
export const COMMON_ATTAINMENT_SERVICE = new InjectionToken<any>('commonAttainmentService');
export const commonAttainmentServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonAttainmentService');
export const commonAttainmentServiceProvider: Provider = {
    provide: COMMON_ATTAINMENT_SERVICE,
    useFactory: commonAttainmentServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EducationEntityService` instead
 */
export const COMMON_EDUCATION_SERVICE = new InjectionToken<any>('commonEducationService');
export const commonEducationServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonEducationService');
export const commonEducationServiceProvider: Provider = {
    provide: COMMON_EDUCATION_SERVICE,
    useFactory: commonEducationServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_EMPLOYEE_SERVICE = new InjectionToken<any>('commonEmployeeService');
export const commonEmployeeServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonEmployeeService');
export const commonEmployeeServiceProvider: Provider = {
    provide: COMMON_EMPLOYEE_SERVICE,
    useFactory: commonEmployeeServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE = new InjectionToken<any>('customStudyDraftInfoModalService');
export const customStudyDraftInfoModalServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('customStudyDraftInfoModalService');
export const customStudyDraftInfoModalServiceProvider: Provider = {
    provide: CUSTOM_STUDY_DRAFT_INFO_MODAL_SERVICE,
    useFactory: customStudyDraftInfoModalServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentCalculationConfigEntityService` instead
 */
export const ENROLMENT_CALCULATION_CONFIG_SERVICE = new InjectionToken<any>('enrolmentCalculationService');
export const enrolmentCalculationConfigServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('enrolmentCalculationService');
export const enrolmentCalculationConfigServiceProvider: Provider = {
    provide: ENROLMENT_CALCULATION_CONFIG_SERVICE,
    useFactory: enrolmentCalculationConfigServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentQuestionnaireEntityService` instead
 */
export const COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE = new InjectionToken<any>('commonEnrolmentQuestionnaireService');
export const commonEnrolmentQuestionnaireServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonEnrolmentQuestionnaireService');
export const commonEnrolmentQuestionnaireServiceProvider: Provider = {
    provide: COMMON_ENROLMENT_QUESTIONNAIRE_SERVICE,
    useFactory: commonEnrolmentQuestionnaireServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentEntityService` instead
 */
export const COMMON_ENROLMENT_SERVICE = new InjectionToken<any>('commonEnrolmentService');
export const commonEnrolmentServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonEnrolmentService');
export const commonEnrolmentServiceProvider: Provider = {
    provide: COMMON_ENROLMENT_SERVICE,
    useFactory: commonEnrolmentServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `GradeScaleEntityService` instead
 */
export const COMMON_GRADE_SCALE_SERVICE = new InjectionToken<any>('commonGradeScaleService');
export const commonGradeScaleServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonGradeScaleService');
export const commonGradeScaleServiceProvider: Provider = {
    provide: COMMON_GRADE_SCALE_SERVICE,
    useFactory: commonGradeScaleServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `MessageEntityService` instead
 */
export const COMMON_MESSAGE_SERVICE = new InjectionToken<any>('commonMessageService');
export const commonMessageServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonMessageService');
export const commonMessageServiceProvider: Provider = {
    provide: COMMON_MESSAGE_SERVICE,
    useFactory: commonMessageServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `ModuleEntityService` instead
 */
export const COMMON_MODULE_SERVICE = new InjectionToken<any>('commonModuleService');
export const commonModuleServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonModuleService');
export const commonModuleServiceProvider: Provider = {
    provide: COMMON_MODULE_SERVICE,
    useFactory: commonModuleServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PlanEntityService` instead
 */
export const COMMON_PLAN_SERVICE = new InjectionToken<any>('commonPlanService');
export const commonPlanServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonPlanService');
export const commonPlanServiceProvider: Provider = {
    provide: COMMON_PLAN_SERVICE,
    useFactory: commonPlanServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_STUDENT_SERVICE = new InjectionToken<any>('commonStudentService');
export const commonStudentServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudentService');
export const commonStudentServiceProvider: Provider = {
    provide: COMMON_STUDENT_SERVICE,
    useFactory: commonStudentServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_STUDENT_APPLICATION_SERVICE = new InjectionToken<any>('commonStudentApplicationService');
export const commonStudentApplicationServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudentApplicationService');
export const commonStudentApplicationServiceProvider: Provider = {
    provide: COMMON_STUDENT_APPLICATION_SERVICE,
    useFactory: commonStudentApplicationServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `StudyRightEntityService` instead
 */
export const COMMON_STUDY_RIGHT_SERVICE = new InjectionToken<any>('commonStudyRightService');
export const commonStudyRightServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudyRightService');
export const commonStudyRightServiceProvider: Provider = {
    provide: COMMON_STUDY_RIGHT_SERVICE,
    useFactory: commonStudyRightServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDY_FIELD_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Urn>>('studyFieldJSDataModel');
export const studyFieldJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('studyFieldJSDataModel');
export const studyFieldJsDataModelProvider: Provider = {
    provide: STUDY_FIELD_JS_DATA_MODEL,
    useFactory: studyFieldJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_STUDENT_ATTAINMENT_SERVICE = new InjectionToken<any>('commonStudentAttainmentService');
export const commonStudentAttainmentServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudentAttainmentService');
export const commonStudentAttainmentServiceProvider: Provider = {
    provide: COMMON_STUDENT_ATTAINMENT_SERVICE,
    useFactory: commonStudentAttainmentServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_STUDY_PERIOD_SERVICE = new InjectionToken<any>('commonStudyPeriodService');
export const commonStudyPeriodServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudyPeriodService');
export const commonStudyPeriodServiceProvider: Provider = {
    provide: COMMON_STUDY_PERIOD_SERVICE,
    useFactory: commonStudyPeriodServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_TERM_REGISTRATION_PERIOD_SERVICE = new InjectionToken<any>('commonTermRegistrationPeriodService');
export const commonTermRegistrationPeriodServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonTermRegistrationPeriodService');
export const commonTermRegistrationPeriodServiceProvider: Provider = {
    provide: COMMON_TERM_REGISTRATION_PERIOD_SERVICE,
    useFactory: commonTermRegistrationPeriodServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMPETENCY_SERVICE = new InjectionToken<any>('competencyService');
export const competencyServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('competencyService');
export const competencyServiceProvider: Provider = {
    provide: COMPETENCY_SERVICE,
    useFactory: competencyServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentValidationService` instead
 */
export const ENROLMENT_VALIDATION_SERVICE = new InjectionToken<any>('enrolmentValidationService');
export const enrolmentValidationServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('enrolmentValidationService');
export const enrolmentValidationServiceProvider: Provider = {
    provide: ENROLMENT_VALIDATION_SERVICE,
    useFactory: enrolmentValidationServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PublicPersonEntityService` instead
 */
export const PUBLIC_PERSON_SERVICE = new InjectionToken<any>('publicPersonService');
export const publicPersonServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('publicPersonService');
export const publicPersonServiceProvider: Provider = {
    provide: PUBLIC_PERSON_SERVICE,
    useFactory: publicPersonServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PlanLoaderService` instead
 */
export const PLAN_LOADER = new InjectionToken<any>('PlanLoader');
export const planLoaderFactory = (injector: angular.auto.IInjectorService) => injector.get('PlanLoader');
export const planLoaderProvider: Provider = {
    provide: PLAN_LOADER,
    useFactory: planLoaderFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const PLAN_STUDY_RIGHT_SERVICE = new InjectionToken<any>('planStudyRightService');
export const planStudyRightServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('planStudyRightService');
export const planStudyRightServiceProvider: Provider = {
    provide: PLAN_STUDY_RIGHT_SERVICE,
    useFactory: planStudyRightServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CurriculumPeriodEntityService` instead
 */
export const CURRICULUM_PERIOD_SERVICE = new InjectionToken<any>('commonCurriculumPeriodService');
export const curriculumPeriodServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonCurriculumPeriodService');
export const curriculumPeriodServiceProvider: Provider = {
    provide: CURRICULUM_PERIOD_SERVICE,
    useFactory: curriculumPeriodServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CourseUnitEntityService` instead
 */
export const COURSE_UNIT_SERVICE = new InjectionToken<any>('commonCourseUnitService');
export const courseUnitServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonCourseUnitService');
export const courseUnitServiceProvider: Provider = {
    provide: COURSE_UNIT_SERVICE,
    useFactory: courseUnitServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CourseUnitRealisationEntityService` instead
 */
export const COURSE_UNIT_REALISATION_SERVICE = new InjectionToken<any>('commonCourseUnitRealisationService');
export const courseUnitRealisationServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonCourseUnitRealisationService');
export const courseUnitRealisationServiceProvider: Provider = {
    provide: COURSE_UNIT_REALISATION_SERVICE,
    useFactory: courseUnitRealisationServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `StudyEventEntityService` instead
 */
export const STUDY_EVENT_SERVICE = new InjectionToken<any>('commonStudyEventService');
export const studyEventServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonStudyEventService');
export const studyEventServiceProvider: Provider = {
    provide: STUDY_EVENT_SERVICE,
    useFactory: studyEventServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDY_EVENT_SUMMARY_SERVICE = new InjectionToken<any>('studyEventSummaryService');
export const studyEventSummaryServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('studyEventSummaryService');
export const studyEventSummaryServiceProvider: Provider = {
    provide: STUDY_EVENT_SUMMARY_SERVICE,
    useFactory: studyEventSummaryServiceFactory,
    deps: [angularJSInjectorName],
};

export const editModeServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('editModeService');
export const editModeServiceProvider: Provider = {
    provide: EditModeService,
    useFactory: editModeServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const JS_DATA_STORE = new InjectionToken<DS>('DS');
export const jsDataStoreFactory = (injector: angular.auto.IInjectorService) => injector.get('DS');
export const jsDataStoreProvider: Provider = {
    provide: JS_DATA_STORE,
    useFactory: jsDataStoreFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const JS_DATA_RELATION_HELPER_SERVICE = new InjectionToken<any>('jsDataRelationHelperService');
export const jsDataRelationHelperServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('jsDataRelationHelperService');
export const jsDataRelationHelperServiceProvider: Provider = {
    provide: JS_DATA_RELATION_HELPER_SERVICE,
    useFactory: jsDataRelationHelperServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `AttainmentEntityService` instead
 */
export const ATTAINMENT_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Attainment>>('attainmentJSDataModel');
export const attainmentJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('attainmentJSDataModel');
export const attainmentJSDataModelProvider: Provider = {
    provide: ATTAINMENT_JS_DATA_MODEL,
    useFactory: attainmentJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `CourseUnitRealisationEntityService` instead
 */
export const COURSE_UNIT_REALISATION_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<CourseUnitRealisation>>('courseUnitRealisationJSDataModel');
export const courseUnitRealisationJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('courseUnitRealisationJSDataModel');
export const courseUnitRealisationJSDataModelProvider: Provider = {
    provide: COURSE_UNIT_REALISATION_JS_DATA_MODEL,
    useFactory: courseUnitRealisationJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `OrganisationEntityService` instead
 */
export const ORGANISATION_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Organisation>>('organisationJSDataModel');
export const organisationJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('organisationJSDataModel');
export const organisationJSDataModelProvider: Provider = {
    provide: ORGANISATION_JS_DATA_MODEL,
    useFactory: organisationJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const ORGANISATION_ROLE_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Code>>('organisationRoleModel');
export const organisationRoleJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('organisationRoleModel');
export const organisationRoleJSDataModelProvider: Provider = {
    provide: ORGANISATION_ROLE_JS_DATA_MODEL,
    useFactory: organisationRoleJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PrivatePersonEntityService` instead
 */
export const PRIVATE_PERSON_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<PrivatePerson>>('privatePersonJSDataModel');
export const privatePersonJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('privatePersonJSDataModel');
export const privatePersonJSDataModelProvider: Provider = {
    provide: PRIVATE_PERSON_JS_DATA_MODEL,
    useFactory: privatePersonJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PersonInfoEntityService` instead
 */
export const PRIVATE_PERSON_INFO_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<PrivatePersonBasicInfo>>('privatePersonInfoJSDataModel');
export const privatePersonInfoJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('privatePersonInfoJSDataModel');
export const privatePersonInfoJSDataModelProvider: Provider = {
    provide: PRIVATE_PERSON_INFO_JS_DATA_MODEL,
    useFactory: privatePersonInfoJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `EnrolmentCalculationConfigEntityService` instead
 */
export const ENROLMENT_CALCULATION_CONFIG_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<EnrolmentCalculationConfig>>('enrolmentCalculationConfigJSDataModel');
export const enrolmentCalculationConfigJsDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('enrolmentCalculationConfigJSDataModel');
export const enrolmentCalculationConfigJSDataModelProvider: Provider = {
    provide: ENROLMENT_CALCULATION_CONFIG_JS_DATA_MODEL,
    useFactory: enrolmentCalculationConfigJsDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use the `SearchFilterType` enum from baseTypes.ts instead
 */
export const SEARCH_FILTER_TYPES = new InjectionToken<any>('SearchFilterTypes');
export const searchFilterTypesFactory = (injector: angular.auto.IInjectorService) => injector.get('SearchFilterTypes');
export const searchFilterTypesProvider: Provider = {
    provide: SEARCH_FILTER_TYPES,
    useFactory: searchFilterTypesFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const SEARCH_PARAMETERS = new InjectionToken<any>('SearchParameters');
export const searchParametersFactory = (injector: angular.auto.IInjectorService) => injector.get('SearchParameters');
export const searchParametersProvider: Provider = {
    provide: SEARCH_PARAMETERS,
    useFactory: searchParametersFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const SEARCH_PARAMETER_STORAGE_SERVICE = new InjectionToken<any>('searchParameterStorageService');
export const searchParameterStorageServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('searchParameterStorageService');
export const searchParameterStorageServiceProvider: Provider = {
    provide: SEARCH_PARAMETER_STORAGE_SERVICE,
    useFactory: searchParameterStorageServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const SEARCH_COMPONENT_UTILS_SERVICE = new InjectionToken<any>('searchComponentUtilsService');
export const searchComponentUtilsServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('searchComponentUtilsService');
export const searchComponentUtilsServiceProvider: Provider = {
    provide: SEARCH_COMPONENT_UTILS_SERVICE,
    useFactory: searchComponentUtilsServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PrivatePersonEntityService` instead
 */
export const COMMON_PRIVATE_PERSON_SERVICE = new InjectionToken<any>('commonPrivatePersonService');
export const commonPrivatePersonServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonPrivatePersonService');
export const commonPrivatePersonServiceProvider: Provider = {
    provide: COMMON_PRIVATE_PERSON_SERVICE,
    useFactory: commonPrivatePersonServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `PersonInfoEntityService` instead
 */
export const COMMON_PRIVATE_PERSON_INFO_SERVICE = new InjectionToken<any>('commonPrivatePersonInfoService');
export const commonPrivatePersonInfoServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonPrivatePersonInfoService');
export const commonPrivatePersonInfoServiceProvider: Provider = {
    provide: COMMON_PRIVATE_PERSON_INFO_SERVICE,
    useFactory: commonPrivatePersonInfoServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const JS_DATA_CACHE_HELPER_SERVICE = new InjectionToken<Function>('jsDataCacheHelper');
export const jsDataCacheHelperServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('jsDataCacheHelper');
export const jsDataCacheHelperServiceProvider: Provider = {
    provide: JS_DATA_CACHE_HELPER_SERVICE,
    useFactory: jsDataCacheHelperServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_PERSON_GROUP_SERVICE = new InjectionToken<any>('commonPersonGroupService');
export const commonPersonGroupServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonPersonGroupService');
export const commonPersonGroupServiceProvider: Provider = {
    provide: COMMON_PERSON_GROUP_SERVICE,
    useFactory: commonPersonGroupServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const VALID_ATTAINMENT_FILTER_SERVICE = new InjectionToken<any>('validAttainmentFilterService');
export const validAttainmentFilterServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('validAttainmentFilterService');
export const validAttainmentFilterServiceProvider: Provider = {
    provide: VALID_ATTAINMENT_FILTER_SERVICE,
    useFactory: validAttainmentFilterServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const PLAN_VALIDATION_RESULT_HELPER = new InjectionToken<any>('planValidationResultHelper');
export const planValidationResultHelperFactory = (injector: angular.auto.IInjectorService) => injector.get('planValidationResultHelper');
export const planValidationResultHelperProvider: Provider = {
    provide: PLAN_VALIDATION_RESULT_HELPER,
    useFactory: planValidationResultHelperFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const BULK_EDIT_SERVICE = new InjectionToken<any>('bulkEditService');
export const bulkEditServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('bulkEditService');
export const bulkEditServiceProvider: Provider = {
    provide: BULK_EDIT_SERVICE,
    useFactory: bulkEditServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COMMON_PLAN_SELECTION_SERVICE = new InjectionToken<any>('commonPlanSelectionService');
export const commonPlanSelectionServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('commonPlanSelectionService');
export const commonPlanSelectionServiceProvider: Provider = {
    provide: COMMON_PLAN_SELECTION_SERVICE,
    useFactory: commonPlanSelectionServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const RULE_SERVICE = new InjectionToken<any>('ruleService');
export const ruleServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('ruleService');
export const ruleServiceProvider: Provider = {
    provide: RULE_SERVICE,
    useFactory: ruleServiceFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const STUDY_MODULE_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<StudyModule>>('studyModuleJSDataModel');
export const studyModuleJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('studyModuleJSDataModel');
export const studyModuleJSDataModelProvider: Provider = {
    provide: STUDY_MODULE_JS_DATA_MODEL,
    useFactory: studyModuleJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const MODULE_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<Module>>('planModuleJSDataModel');
export const moduleJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('planModuleJSDataModel');
export const moduleJSDataModelProvider: Provider = {
    provide: MODULE_JS_DATA_MODEL,
    useFactory: moduleJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated Use `DegreeProgrammeEntityService` instead
 */
export const DEGREE_PROGRAMME_JS_DATA_MODEL = new InjectionToken<DSResourceDefinition<DegreeProgramme>>('degreeProgrammeJSDataModel');
export const degreeProgrammeJSDataModelFactory = (injector: angular.auto.IInjectorService) => injector.get('degreeProgrammeJSDataModel');
export const degreeProgrammeJSDataModelProvider: Provider = {
    provide: DEGREE_PROGRAMME_JS_DATA_MODEL,
    useFactory: degreeProgrammeJSDataModelFactory,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const CALENDAR_EVENT_SERVICE = new InjectionToken<any>('calendarEventService');
export const getCalendarEventService = (injector: angular.auto.IInjectorService) => injector.get('calendarEventService');
export const calendarEventServiceProvider = {
    provide: CALENDAR_EVENT_SERVICE,
    useFactory: getCalendarEventService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const COURSE_UNIT_REALISATION_NAME_SERVICE = new InjectionToken<any>('courseUnitRealisationNameService');
export const getCourseUnitRealisationNameService = (injector: angular.auto.IInjectorService) => injector.get('courseUnitRealisationNameService');
export const courseUnitRealisationNameServiceProvider = {
    provide: COURSE_UNIT_REALISATION_NAME_SERVICE,
    useFactory: getCourseUnitRealisationNameService,
    deps: [angularJSInjectorName],
};

/**
 * @deprecated
 */
export const LEGACY_TRANSLATE_SERVICE = new InjectionToken<any>('$translate');
export const legacyTranslateServiceFactory = (injector: angular.auto.IInjectorService) => injector.get('$translate');
export const legacyTranslateServiceProvider: Provider = {
    provide: LEGACY_TRANSLATE_SERVICE,
    useFactory: legacyTranslateServiceFactory,
    deps: ['$injector'],
};
