import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PersonGroupDetailsForOwnGroup } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PersonGroupEntityService } from 'sis-components/service/person-group-entity.service';

@Component({
    selector: 'app-student-person-groups',
    templateUrl: './student-person-groups.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentPersonGroupsComponent implements OnInit {

    personGroupDetails$: Observable<PersonGroupDetailsForOwnGroup[]>;

    RESPONSIBILITY_INFO_LIMIT = 6;

    customLimitsByGroupId: { [id: string]: number } = {};

    constructor(private personGroupEntityService: PersonGroupEntityService,
                private localeService: LocaleService,
                private appErrorHandler: AppErrorHandler) {
    }

    ngOnInit(): void {
        this.personGroupDetails$ = this.personGroupEntityService.getOwnPersonGroupDetails()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    setCustomLimit(group: PersonGroupDetailsForOwnGroup) {
        _.set(this.customLimitsByGroupId, group.id, _.size(group.responsibilityInfos));
    }

    resetCustomLimit(group: PersonGroupDetailsForOwnGroup) {
        _.unset(this.customLimitsByGroupId, group.id);
    }

}
