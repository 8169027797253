import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { PlanValidationTs, ValidatablePlan } from 'common-typescript';
import { DegreeProgrammeAttainmentApplication, DegreeProgrammeAttainmentApplicationQuestion, DegreeProgrammeAttainmentApplicationQuestionnaire, Plan, UniversitySettings } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import {
    COMMON_EDUCATION_SERVICE,
    COMMON_STUDENT_APPLICATION_SERVICE,
    COMMON_STUDY_RIGHT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { HeaderService } from 'sis-components/header/header.service';
import { STUDENT_APPLICATION_STATE } from 'sis-components/model/student-application-constants';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { DegreeProgrammeAttainmentApplicationQuestionnaireService } from 'sis-components/service/degree-programme-application-questionnaire.service';
import { UniversityService } from 'sis-components/service/university.service';

import {
    DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_SERVICE,
    STUDENT_PROFILE_SERVICE,
} from '../../../../ajs-upgraded-modules';
import {
    GraduationSurveyModalComponent,
} from '../../../../common/components/graduation/graduation-survey-modal/graduation-survey-modal.component';
import {
    isAMKDegree,
    isBachelorsDegree,
    resolveGraduationModalValues,
} from '../../../../common/components/graduation/graduation-utils';

@Component({
    selector: 'app-create-degree-programme-attainment-application',
    templateUrl: './create-degree-programme-attainment-application.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreateDegreeProgrammeAttainmentApplicationComponent implements OnInit {
    form: FormGroup;

    constraints: any;
    attainmentExists: boolean;
    existingApplicationId: any;
    studyRightExists: any;
    studyRight: any;
    planState: any;
    showAlumniAssociationInfo: boolean;
    alumniAssociationDescription: any;
    alumniAssociationUrl: any;
    alumniAssociationInfoText: any;
    planStateValidForSending: boolean;
    primaryPlan: any;
    universitySettings: UniversitySettings;
    digitalDegreeCertificatePreferred: boolean;

    ready = false;
    editMode = false;
    applicationExists = false;

    questions: DegreeProgrammeAttainmentApplicationQuestion[] = [];
    application: Partial<DegreeProgrammeAttainmentApplication> = {
        joinsAlumniAssociation: null,
        degreeDeliveryMethod: 'PICK_UP',
        additionalInfo: null,
        deliveryAddress: null,
        questionnaireAnswers: [],
    };

    @Input() profile: any;
    @Input() plan: Plan;
    @Input() planValidationResult: any;
    @Input() validatablePlan: ValidatablePlan;
    @Input() degreeProgramme: any;

    sendDisabled = false;

    constructor(
        private fb: SisFormBuilder,
        private headerService: HeaderService,
        private modalService: ModalService,
        private dialogService: FudisDialogService,
        private localeService: LocaleService,
        private universityService: UniversityService,
        private confirmDialogService: ConfirmDialogService,
        private uiRouterService: UiRouterService,
        private stateService: StateService,
        private translateService: TranslateService,
        private degreeProgrammeAttainmentApplicationQuestionnaireService: DegreeProgrammeAttainmentApplicationQuestionnaireService,
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private authService: AuthService,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
        @Inject(STUDENT_PROFILE_SERVICE) private studentProfileService: any,
        @Inject(COMMON_EDUCATION_SERVICE) private commonEducationService: any,
        @Inject(COMMON_STUDENT_APPLICATION_SERVICE) private commonStudentApplicationService: any,
        @Inject(COMMON_STUDY_RIGHT_SERVICE) private commonStudyRightService: any,
        @Inject(DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_SERVICE) private degreeProgrammeAttainmentApplicationService: any,
    ) {
    }

    ngOnInit(): void {
        this.form = this.buildForm();

        this.resolveExistingAttainment();
        this.resolvePlanStateValidForSending();
        this.resolvePrimaryPlan();

        Promise.all([
            this.resolveDegreeProgrammeApplicationQuestionnaire(this.degreeProgramme.id),
            this.resolveExistingApplications(),
            this.resolveStudyRight(),
            this.resolveCurrentUniversitySettings(),
        ]).then(() => {
            this.resolveAlumniAssociationInfo();
            this.buildQuestionOnForm();
            this.setDeliveryMethodPreference();
            this.ready = true;
        });
    }

    buildForm(): FormGroup {
        return this.fb.group({
            degreeDeliveryMethod: this.fb.control(this.application.degreeDeliveryMethod, required()),
            additionalInfo: this.fb.control(this.application.additionalInfo),
            deliveryAddress: this.fb.control(this.application.deliveryAddress),
        });
    }

    buildQuestionOnForm() {
        if (this.questions.length > 0) {
            this.questions.forEach((question: DegreeProgrammeAttainmentApplicationQuestion) => {
                this.form.addControl(question.localId, this.fb.control(null, {
                    validators: question.required ? [required(), maxLength(1024)] : [maxLength(1024)],
                }));
            });
        }
    }

    get degreeDeliveryMethod(): FormControl {
        return this.form.get('degreeDeliveryMethod') as FormControl;
    }

    get additionalInfo(): FormControl {
        return this.form.get('additionalInfo') as FormControl;
    }

    get deliveryAddress(): FormControl {
        return this.form.get('deliveryAddress') as FormControl;
    }

    getQuestionControl(key: string): FormControl {
        return this.form.get(key) as FormControl;
    }

    toggleJoinsAlumniAssociation() {
        this.application.joinsAlumniAssociation = !this.application.joinsAlumniAssociation;
    }

    resolveExistingAttainment() {
        this.attainmentExists = _.has(this.validatablePlan, 'isModuleAttained')
            ? this.validatablePlan.isModuleAttained(this.degreeProgramme.id) : false;
    }

    resolvePlanStateValidForSending() {
        this.planState = PlanValidationTs.getPlanStateForModule(
            _.get(this.validatablePlan.modulesById, this.degreeProgramme.id),
            this.validatablePlan,
            this.planValidationResult);

        this.planStateValidForSending = this.degreeProgrammeAttainmentApplicationService
            .isPlanStateValidForApplicationSending(this.planState);
    }

    resolvePrimaryPlan() {
        this.primaryPlan = _.get(this.validatablePlan, 'plan.primary');
    }

    resolveExistingApplications() {
        return this.degreeProgrammeAttainmentApplicationService.findByModuleAndStates(
            _.get(this.degreeProgramme, 'id'), [STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING],
        ).then((applications: any) => {
            if (!_.isEmpty(applications)) {
                this.applicationExists = true;
                this.existingApplicationId = applications[0].id;
            }
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    resolveDegreeProgrammeApplicationQuestionnaire(degreeProgrammeId: any) {
        return this.degreeProgrammeAttainmentApplicationQuestionnaireService.getByDegreeProgrammeId(degreeProgrammeId)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((degreeProgrammeAttainmentApplicationQuestionnaire: DegreeProgrammeAttainmentApplicationQuestionnaire) => {
                this.questions = degreeProgrammeAttainmentApplicationQuestionnaire.questions.filter(question => question.state === 'ACTIVE');
            });
    }

    resolveStudyRight() {
        return this.commonStudyRightService.findByPersonId(this.authService.personId(), true, true)
            .then((studyRights: any) => {
                this.studyRight = _.chain(studyRights)
                    .filter(studyRight => _.includes(['ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE'], _.get(studyRight, 'state')))
                    .find({
                        documentState: 'ACTIVE',
                        educationId: _.get(this.validatablePlan.plan, 'rootId'),
                        learningOpportunityId: _.get(this.validatablePlan.plan, 'learningOpportunityId'),
                    }).value();
                this.studyRightExists = !_.isNil(this.studyRight);
            }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    resolveAlumniAssociationInfo() {
        this.commonEducationService.findById(_.get(this.validatablePlan.plan, 'rootId')).then((education: any) => {
            if (this.shouldShowAlumniAssociationInfo(education)) {
                this.showAlumniAssociationInfo = true;
                this.application.joinsAlumniAssociation = false;
                this.alumniAssociationDescription = _.get(this.universitySettings, 'alumniAssociationDescription');
                this.alumniAssociationUrl = _.get(this.universitySettings, 'alumniAssociationUrl');
                this.alumniAssociationInfoText = _.get(this.universitySettings, 'alumniAssociationInfoText');
            }
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    setDeliveryMethodPreference() {
        this.digitalDegreeCertificatePreferred = _.get(this.universitySettings, ['frontendFeatureToggles', 'digitalDegreeCertificatePreferred']);
    }

    resolveCurrentUniversitySettings() {
        return firstValueFrom(this.universityService.getCurrentUniversitySettings())
            .then((universitySettings) => {
                this.universitySettings = universitySettings;
            });
    }

    shouldShowAlumniAssociationInfo(education: any) {
        const personalizedPhase2ModuleGroupId = _.get(this.studyRight, 'personalizedSelectionPath.phase2.moduleGroupId');
        if (!_.isNil(personalizedPhase2ModuleGroupId) && personalizedPhase2ModuleGroupId === this.degreeProgramme.groupId) {
            return true;
        }
        const phase2 = _.get(education, 'structure.phase2');
        if (_.isNil(phase2)) {
            return true;
        }
        return _.includes(_.map(phase2.options, 'moduleGroupId'), this.degreeProgramme.groupId);
    }

    navigateToExistingApplication() {
        if (this.existingApplicationId) {
            this.stateService.go('student.logged-in.profile.applications.degree-programme-attainment-workflow', { applicationId: this.existingApplicationId });
        }
    }

    exitApplication(): void {
        this.uiRouterService.goToPreviousState();
    }

    send() {
        if (!this.sendDisabled && this.form.valid) {
            this.application.degreeDeliveryMethod = this.digitalDegreeCertificatePreferred ? 'DIGITAL_CERTIFICATE' : this.degreeDeliveryMethod.value;
            this.application.additionalInfo = this.additionalInfo.value;
            this.getQuestionnaireAnswers();
            this.getAdditionalInfo();
            this.getDelivery();

            this.confirmDialogService.confirm({
                title: 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.VERIFICATION.TITLE',
                descriptions: [
                    'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.VERIFICATION.TEXT',
                    'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.VERIFICATION.VERIFICATION_TEXT',
                ],
                cancelText: 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CANCEL',
                confirmText: 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.VERIFICATION.OK',
            }).then(() => {
                this.sendDisabled = true;

                const applicationPromise = this.degreeProgrammeAttainmentApplicationService.create(
                    this.application, this.degreeProgramme, this.studyRight, this.validatablePlan, this.planValidationResult);

                const bachelorsSurveyPromise = applicationPromise.then(() => {
                    if (!isBachelorsDegree(this.degreeProgramme) && !isAMKDegree(this.degreeProgramme)) {
                        return Promise.resolve();
                    }

                    // @ts-ignore
                    return firstValueFrom(this.universityService.getCurrentUniversitySettings()).then((universitySettings) => {
                        const graduationSurveyWithConfirmationEnabled = _.get(universitySettings, 'frontendFeatureToggles.mandatoryGraduationSurveyEnabled', false);
                        const surveyUrl = isBachelorsDegree(this.degreeProgramme) ? _.get(universitySettings, 'bachelorsGraduateSurveyUrl') : _.get(universitySettings, 'amkGraduateSurveyUrl');
                        if (graduationSurveyWithConfirmationEnabled || _.isNil(surveyUrl) || _.isEmpty(surveyUrl)) {
                            return Promise.resolve();
                        }
                        const graduateType = isBachelorsDegree(this.degreeProgramme) ? 'BACHELORS' : 'AMK';
                        const modalContents = resolveGraduationModalValues(graduateType, surveyUrl);
                        return this.dialogService.open(GraduationSurveyModalComponent, { data: modalContents });
                    });
                });

                Promise.all([applicationPromise, bachelorsSurveyPromise])
                    .then((results: any) => {
                        const application = _.find(results[0], { moduleId: this.degreeProgramme.id });

                        // the purpose of the following call is to update the js-data cache to include the newly created application
                        this.commonStudentApplicationService.findAll({ studentId: this.authService.personId() }, { bypassCache: true });
                        this.alertsService.addAlert({
                            type: AlertType.SUCCESS,
                            message: this.translateService.instant('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CREATED'),
                        });
                        this.stateService.go(
                            'student.logged-in.profile.applications.degree-programme-attainment-workflow',
                            { applicationId: _.get(application, 'id') },
                        );
                    })
                    .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler)
                    .finally(() => this.sendDisabled = false);
            }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    setEditMode(editMode: boolean) {
        this.editMode = editMode;
    }

    edit() {
        this.setEditMode(true);
    }

    degreeDeliveryMethodChanged(delivery: string) {
        if (delivery === 'PICK_UP') {
            this.deliveryAddress.clearValidators();
            this.deliveryAddress.updateValueAndValidity();
        } else if (delivery === 'MAIL' && this.profile.primaryAddress) {
            this.deliveryAddress.setValidators([required()]);
            this.deliveryAddress.setValue(this.profile.primaryAddress);
            this.deliveryAddress.updateValueAndValidity();
        }
    }

    cancelEdit() {
        this.setEditMode(false);
    }

    getQuestionnaireAnswers() {
        if (this.questions.length > 0) {
            this.application.questionnaireAnswers = [];
            this.questions.forEach((question: DegreeProgrammeAttainmentApplicationQuestion) => {
                this.application.questionnaireAnswers.push({
                    answer: this.form.get(question.localId).value,
                    guidance: question.guidance,
                    question: question.question,
                    questionId: question.localId,
                    required: question.required,
                });
            });
        } else {
            delete this.application.questionnaireAnswers;
        }
    }

    getAdditionalInfo() {
        if (this.additionalInfo.value) {
            this.application.additionalInfo = this.additionalInfo.value;
        } else {
            delete this.application.additionalInfo;
        }
    }

    getDelivery() {
        if (this.application.degreeDeliveryMethod === 'MAIL') {
            this.application.deliveryAddress = this.deliveryAddress.value;
        } else {
            delete this.application.deliveryAddress;
        }
    }

    saveEdit(profile: any) {
        this.studentProfileService.saveLimitedStudentBasicInfo(profile)
            .then(() => {
                this.profile = profile;
                this.degreeDeliveryMethod.setValue('PICK_UP');
                this.setEditMode(false);
            })
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    isSecondaryAddressInFinland() {
        return _.get(this.profile, 'secondaryAddress.type') === 'FinnishAddress';
    }

    getAlumniAssociationUrl() {
        return this.localeService.localize(this.alumniAssociationUrl);
    }

    getAlumniAssociationDescription() {
        return this.localeService.localize(this.alumniAssociationDescription);
    }

    getAlumniAssociationInfoText() {
        return this.localeService.localize(this.alumniAssociationInfoText);
    }
}
