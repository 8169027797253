import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    inject, Input,
    OnInit, signal,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from '@uirouter/core';
import { ValidatablePlan } from 'common-typescript';
import {
    AssessmentItem,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    EnrolmentCalculationConfigForPerson,
    EnrolmentDataChange, EnrolmentQuestionnaire, EnrolmentQuestionnaireAnswers,
    Organisation,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    bufferTime, catchError,
    combineLatestWith, concatMap,
    debounceTime,
    map, mergeWith,
    Observable,
    of, scan,
    shareReplay,
    startWith, Subject,
    switchMap,
    tap, throwError,
} from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import {
    EnrolmentQuestionnaireAnswersEntityService,
} from 'sis-components/service/enrolment-questionnaire-answers-entity.service';
import { EnrolmentQuestionnaireEntityService } from 'sis-components/service/enrolment-questionnaire-entity.service';
import { NotificationsService } from 'sis-components/service/notifications/notifications.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';
import { convertAJSPromiseToNative } from 'sis-components/util/utils';

import { COURSE_UNIT_INFO_MODAL, ENROLMENT_MODAL_SERVICE, UI_STATE_STORE } from '../../../ajs-upgraded-modules';
import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';
import { EnrolmentWizardComponent } from '../../../enrolments/enrolment-wizard/enrolment-wizard.component';
import { EnrolmentsModule } from '../../../enrolments/enrolments.module';
import { SelectEducationModalComponent } from '../../../plan/selectEducation/selectEducationModal.component';
import { FullCalendarMessageService } from '../../full-calendar/full-calendar-message.service';

import {
    CalendarCourseUnitRealisationComponent,
} from './calendar-course-unit-realisation/calendar-course-unit-realisation.component';
import { CalendarEnrolmentService, CalendarFilterStates, FilterKey } from './calendar-enrolment.service';

interface CalendarEnrolmentsData {
    enrolments: Enrolment[];
    enrolmentCourseUnitsById: { [index: string]: CourseUnit };
    enrolmentCourseUnitRealisationsById: { [index: string]: CourseUnitRealisation };
    enrolmentAssessmentItemsById: { [index: string]: AssessmentItem };
    enrolmentCalculationConfigsByCourseUnitRealisationId: { [index: string]: EnrolmentCalculationConfigForPerson };
    universities: Organisation[];
}

@Component({
    selector: 'app-calendar-enrolments',
    templateUrl: './calendar-enrolments.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        NgxFudisModule,
        SisComponentsModule,
        SisCommonModule,
        EnrolmentsModule,
        CalendarCourseUnitRealisationComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarEnrolmentsComponent implements OnInit {

    // TODO: replace this
    @Input() getValidatablePlan: () => PromiseLike<ValidatablePlan>;

    _currentView = signal('NOT_STARTED_AND_ACTIVE');
    /**
     * Current view of the calendar enrolments.
     *  - 'NOT_STARTED_AND_ACTIVE' - Filters enrolments using active filters and displays
     *      checkbox controls for filters at the top of the view.
     *  - 'FINISHED_AND_ABORTED' - Filters enrolments using finished and aborted filters. Hides checkbox controls.
     */
    @Input() set currentView(value: 'NOT_STARTED_AND_ACTIVE' | 'FINISHED_AND_ABORTED') {
        this._currentView.set(value);
    }

    enrolmentStudentService = inject(EnrolmentStudentService);
    courseUnitEntityService = inject(CourseUnitEntityService);
    courseUnitRealisationEntityService = inject(CourseUnitRealisationEntityService);
    assessmentItemEntityService = inject(AssessmentItemEntityService);
    organisationEntityService = inject(OrganisationEntityService);
    localeService = inject(LocaleService);
    translocoService = inject(TranslocoService);
    calendarEnrolmentService = inject(CalendarEnrolmentService);
    fb = inject(SisFormBuilder);
    appErrorHandler = inject(AppErrorHandler);
    fullCalendarMessageService = inject(FullCalendarMessageService);
    alertsService = inject(AlertsService);
    enrolmentModalService = inject(ENROLMENT_MODAL_SERVICE);
    enrolmentQuestionnaireEntityService = inject(EnrolmentQuestionnaireEntityService);
    enrolmentQuestionnaireAnswersEntityService = inject(EnrolmentQuestionnaireAnswersEntityService);
    modalService = inject(ModalService);
    notificationService = inject(NotificationsService);
    courseUnitInfoModalService = inject(COURSE_UNIT_INFO_MODAL);
    studyRightEntityService = inject(StudyRightEntityService);
    planEntityService = inject(PlanEntityService);
    commonPlanService = inject(COMMON_PLAN_SERVICE);
    stateService = inject(StateService);
    destroyRef = inject(DestroyRef);
    uiStateStore = inject(UI_STATE_STORE);

    activeFilters = ['NOT_ENROLLED', 'ENROLMENT_NOT_STARTED', 'PROCESSING', 'ENROLLED', 'REJECTED'] as const;
    finishedAndAbortedFilters = ['ACTIVITY_ENDED', 'ABORTED', 'NOT_ENROLLED_AND_ENROLMENT_PERIODS_PASSED'] as const;
    stateOrder = [...this.activeFilters, ...this.finishedAndAbortedFilters, undefined];

    initialFormFilters: CalendarFilterStates = this.uiStateStore.readField('student.calendar.calendarEnrolments.', 'filters', this.calendarEnrolmentService.getDefaultCalendarFilterStates());

    formFilters = this.fb.group({
        notEnrolled: this.fb.control(this.initialFormFilters.NOT_ENROLLED),
        enrolmentNotStarted: this.fb.control(this.initialFormFilters.ENROLMENT_NOT_STARTED),
        processing: this.fb.control(this.initialFormFilters.PROCESSING),
        enrolled: this.fb.control(this.initialFormFilters.ENROLLED),
        rejected: this.fb.control(this.initialFormFilters.REJECTED),
    });

    initialExpandedCurIds = new Set<string>(this.uiStateStore.readField('otm.student.calendar.courseUnitRealisationItem.', 'expanded'));

    allStudentEnrolmentsInCalendar$: Observable<Enrolment[]> = this.enrolmentStudentService.getAllEnrolments().pipe(
        this.appErrorHandler.defaultErrorHandler(),
        map((enrolments) => enrolments.filter(enrolment => enrolment.isInCalendar)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    studentEnrolmentCourseUnits$: Observable<{ [index: string]: CourseUnit }> = this.allStudentEnrolmentsInCalendar$.pipe(
        switchMap(enrolments => this.courseUnitEntityService.getByIds(
            [...new Set(enrolments.map(enrolment => enrolment.courseUnitId))],
        )
            .pipe(this.appErrorHandler.defaultErrorHandler())),
        map((courseUnits) => _.keyBy(courseUnits, 'id')),
    );

    studentEnrolmentCourseUnitRealisations$: Observable<{ [index: string]: CourseUnitRealisation }> = this.allStudentEnrolmentsInCalendar$.pipe(
        switchMap(enrolments => this.courseUnitRealisationEntityService.getByIds(
            [...new Set(enrolments.map(enrolment => enrolment.courseUnitRealisationId))],
        )
            .pipe(this.appErrorHandler.defaultErrorHandler()),
        ),
        map((courseUnitRealisations) => _.keyBy(courseUnitRealisations, 'id')),
    );

    studentEnrolmentAssessmentItems$: Observable<{ [index: string]: AssessmentItem }> = this.allStudentEnrolmentsInCalendar$.pipe(
        switchMap(enrolments => this.assessmentItemEntityService.getByIds(
            [...new Set(enrolments.map(enrolment => enrolment.assessmentItemId))],
        )
            .pipe(this.appErrorHandler.defaultErrorHandler()),
        ),
        map((assessmentItems) => _.keyBy(assessmentItems, 'id')),
    );

    refreshCalculationConfigsSubject$ = new Subject<Enrolment[]>();

    enrolmentCalculationConfigsByCourseUnitRealisationId$: Observable<{ [index: string]: EnrolmentCalculationConfigForPerson }> =
        this.allStudentEnrolmentsInCalendar$.pipe(
            take(1),
            mergeWith(this.refreshCalculationConfigsSubject$),
            switchMap(enrolments => this.enrolmentStudentService.getCalculationConfigs(
                [...new Set(enrolments.map(enrolment => enrolment.courseUnitRealisationId))],
            )
                .pipe(this.appErrorHandler.defaultErrorHandler()),
            ),
            map((configs) => _.keyBy(configs, 'courseUnitRealisationId')),
            scan((acc, newConfigs) => ({ ...acc, ...newConfigs }), {}),
        );

    universities$: Observable<Organisation[]> = this.organisationEntityService.getRootOrganisations().pipe(
        this.appErrorHandler.defaultErrorHandler(),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    data$: Observable<CalendarEnrolmentsData> = this.allStudentEnrolmentsInCalendar$.pipe(
        combineLatestWith(
            this.studentEnrolmentCourseUnits$,
            this.studentEnrolmentCourseUnitRealisations$,
            this.studentEnrolmentAssessmentItems$,
            this.enrolmentCalculationConfigsByCourseUnitRealisationId$,
            this.universities$,
        ),
        map(([enrolments,
            enrolmentCourseUnitsById,
            enrolmentCourseUnitRealisationsById,
            enrolmentAssessmentItemsById,
            enrolmentCalculationConfigsByCourseUnitRealisationId,
            universities,
        ]) => ({
            enrolments,
            enrolmentCourseUnitsById,
            enrolmentCourseUnitRealisationsById,
            enrolmentAssessmentItemsById,
            enrolmentCalculationConfigsByCourseUnitRealisationId,
            universities,
        })),
    );

    // If the enrolment tries to "jump" between filters, we need to keep it visible until filters are changed
    extraVisibleEnrolments = signal(new Set<OtmId>());

    dataSignal = toSignal(this.data$);

    formFilterValues = toSignal(this.formFilters.valueChanges.pipe(startWith(this.formFilters.value)));
    currentFilters = computed<FilterKey[]>(() => {
        const filterFormValues = this.formFilterValues();
        const currentView = this._currentView();
        const currentFilters = [] as FilterKey[];
        if (currentView === 'FINISHED_AND_ABORTED') {
            return [...this.finishedAndAbortedFilters];
        } if (currentView === 'NOT_STARTED_AND_ACTIVE') {
            if (filterFormValues.notEnrolled) {
                currentFilters.push('NOT_ENROLLED');
            }
            if (filterFormValues.enrolmentNotStarted) {
                currentFilters.push('ENROLMENT_NOT_STARTED');
            }
            if (filterFormValues.processing) {
                currentFilters.push('PROCESSING');
            }
            if (filterFormValues.enrolled) {
                currentFilters.push('ENROLLED');
            }
            if (filterFormValues.rejected) {
                currentFilters.push('REJECTED');
            }
        }
        return currentFilters;
    });

    enrolmentsByFilter = computed<Map<FilterKey, Enrolment[]>>(() => {
        const enrolmentsByFilter = new Map();
        const { enrolments, enrolmentCourseUnitRealisationsById } = this.dataSignal();
        for (const enrolment of enrolments) {
            const courseUnitRealisationForEnrolment = enrolmentCourseUnitRealisationsById[enrolment.courseUnitRealisationId];
            const filterKey = this.calendarEnrolmentService.getFilterKeyForEnrolment(enrolment, courseUnitRealisationForEnrolment);
            if (!enrolmentsByFilter.has(filterKey)) {
                enrolmentsByFilter.set(filterKey, []);
            }
            enrolmentsByFilter.get(filterKey).push(enrolment);
        }
        return enrolmentsByFilter;
    });

    enrolmentCountByFilter = computed<Map<FilterKey, number>>(() => {
        const enrolmentsByFilter = this.enrolmentsByFilter();
        const enrolmentCountByFilter = new Map();
        for (const [filterKey, enrolments] of enrolmentsByFilter) {
            enrolmentCountByFilter.set(filterKey, enrolments.length);
        }
        return enrolmentCountByFilter;
    });

    visibleEnrolmentsSortedByCourseUnitName = computed<Enrolment[]>(() => {
        const { enrolmentCourseUnitsById, enrolments } = this.dataSignal();
        const extraVisibleEnrolments = this.extraVisibleEnrolments();
        const currentFilters = this.currentFilters();

        const visibleEnrolments: Enrolment[] = [];
        for (const currentFilter of currentFilters) {
            if (this.enrolmentsByFilter().has(currentFilter)) {
                visibleEnrolments.push(...this.enrolmentsByFilter().get(currentFilter));
            }
        }
        for (const extraEnrolmentId of extraVisibleEnrolments) {
            if (!visibleEnrolments.some(enrolment => enrolment.id === extraEnrolmentId)) {
                const extraEnrolment = enrolments.find(enrolment => enrolment.id === extraEnrolmentId);
                if (extraEnrolment) {
                    visibleEnrolments.push(extraEnrolment);
                }
            }
        }
        return _.orderBy(visibleEnrolments, [enr => this.localeService.localize(enrolmentCourseUnitsById[enr.courseUnitId].name)]);
    });

    sortedVisibleEnrolmentsByCourseUnitId = computed(() => {
        const visibleEnrolments = this.visibleEnrolmentsSortedByCourseUnitName();
        const { enrolmentCourseUnitRealisationsById } = this.dataSignal();
        const visibleEnrolmentsByCourseUnitId = _.groupBy(visibleEnrolments, 'courseUnitId');
        for (const [key, value] of Object.entries(visibleEnrolmentsByCourseUnitId)) {
            visibleEnrolmentsByCourseUnitId[key] = _.orderBy(value, [
                enr => _.indexOf(this.stateOrder, this.calendarEnrolmentService.getFilterKeyForEnrolment(
                    enr, enrolmentCourseUnitRealisationsById[enr.courseUnitRealisationId])),
                enr => this.localeService.localize(enrolmentCourseUnitRealisationsById[enr.courseUnitRealisationId].name),
            ]);
        }
        return Object.entries(visibleEnrolmentsByCourseUnitId);
    });

    formFilterOptionsWithCount = computed(() => {
        const enrolmentCountByFilter = this.enrolmentCountByFilter();
        return [
            {
                controlName: 'enrolmentNotStarted',
                label: this.translocoService.translate('CALENDAR_FILTERS.ENROLMENT_NOT_STARTED',
                                                       { count: enrolmentCountByFilter.get('ENROLMENT_NOT_STARTED') ?? 0 }),
            },
            {
                controlName: 'notEnrolled',
                label: this.translocoService.translate('CALENDAR_FILTERS.NOT_ENROLLED',
                                                       { count: enrolmentCountByFilter.get('NOT_ENROLLED') ?? 0 }),
            },
            {
                controlName: 'processing',
                label: this.translocoService.translate('CALENDAR_FILTERS.PROCESSING',
                                                       { count: enrolmentCountByFilter.get('PROCESSING') ?? 0 }),
            },
            {
                controlName: 'enrolled',
                label: this.translocoService.translate('CALENDAR_FILTERS.ENROLLED',
                                                       { count: enrolmentCountByFilter.get('ENROLLED') ?? 0 }),
            },
            {
                controlName: 'rejected',
                label: this.translocoService.translate('CALENDAR_FILTERS.REJECTED',
                                                       { count: enrolmentCountByFilter.get('REJECTED') ?? 0 }),
            },
        ];
    });

    ngOnInit() {
        const enrolmentChangeNotifications$ = this.notificationService.getEventsObservable('enrolmentChange');
        enrolmentChangeNotifications$
            .pipe(takeUntilDestroyed(this.destroyRef),
                  // If there are multiple emissions in short time handle them in a batch
                  bufferTime(500),
                  filter((dataChanges: EnrolmentDataChange[]) => dataChanges.length > 0),
                  concatMap((dataChanges: EnrolmentDataChange[]) => this.handleEnrolmentUpdates(dataChanges)),
                  this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();

        this.allStudentEnrolmentsInCalendar$.pipe(
            takeUntilDestroyed(this.destroyRef),
            debounceTime(250),
            tap(() => this.fullCalendarMessageService.reFetchEventsSubject.next()),
        ).subscribe();

        this.formFilters.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() => {
                const mappedFilterValues = {
                    NOT_ENROLLED: this.formFilters.value.notEnrolled,
                    ENROLMENT_NOT_STARTED: this.formFilters.value.enrolmentNotStarted,
                    PROCESSING: this.formFilters.value.processing,
                    ENROLLED: this.formFilters.value.enrolled,
                    REJECTED: this.formFilters.value.rejected,
                };
                this.uiStateStore.storeField('student.calendar.calendarEnrolments.', 'filters', mappedFilterValues);
                this.extraVisibleEnrolments.set(new Set());
            }),
        ).subscribe();
    }

    handleEnrolmentUpdates(events: EnrolmentDataChange[]) {
        events.forEach(event => {
            this.addEnrolmentDataChangeAlerts(event);
        });

        const dataChangesByEnrolmentId = _.groupBy(events, 'enrolmentId');
        Object.entries(dataChangesByEnrolmentId).forEach(([enrolmentId, dataChanges]) => {
            dataChangesByEnrolmentId[enrolmentId] = _.orderBy(dataChanges, 'enrolmentRevision', 'desc');
        });

        const enrolmentIdsToCheck = Object.keys(dataChangesByEnrolmentId);
        const enrolmentsToUpdate: OtmId[] = [];
        enrolmentIdsToCheck.forEach(enrolmentId => {
            const currentEnrolment = this.enrolmentStudentService.query.hasEntity(enrolmentId)
                ? this.enrolmentStudentService.query.getEntity(enrolmentId)
                : null;
            if (currentEnrolment && currentEnrolment.metadata?.revision < dataChangesByEnrolmentId[enrolmentId][0].enrolmentRevision) {
                enrolmentsToUpdate.push(enrolmentId);
            }
        });

        if (enrolmentsToUpdate.length > 0) {
            return this.enrolmentStudentService.getByIds(enrolmentsToUpdate, true).pipe(
                take(1),
                tap((enrolments: Enrolment[]) => { this.refreshCalculationConfigsSubject$.next(enrolments); }),
                this.appErrorHandler.defaultErrorHandler());
        }
        // eslint-disable-next-line no-void
        return of(void 0);
    }

    addEnrolmentDataChangeAlerts(event: EnrolmentDataChange): void {
        if (event.newState !== event.previousState) {
            switch (event.newState) {
                case 'REJECTED':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.WARNING,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.REJECTED'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                case 'ENROLLED':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.SUCCESS,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.ENROLLED'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                case 'INVALID':
                    this.alertsService.addTemporaryAlert({ type: AlertType.WARNING,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.INVALID'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                default:
                // Do nothing
            }
        }
        if (event.newState === 'PROCESSING' &&
            (event.newProcessingState !== event.previousProcessingState || event.newState !== event.previousState)) {

            switch (event.newProcessingState) {
                case 'CURRENTLY_SELECTED':
                case 'SELECTED':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.INFO,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.CURRENTLY_SELECTED'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                case 'CURRENTLY_NOT_SELECTED':
                case 'NOT_SELECTED':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.INFO,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.CURRENTLY_NOT_SELECTED'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                case 'REQ_NOT_FULFILLED':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.WARNING,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.REQ_NOT_FULFILLED'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                case 'PENDING':
                    this.alertsService.addTemporaryAlert({
                        type: AlertType.INFO,
                        message: this.translocoService.translate('ENROLMENT.NOTIFICATION.PENDING'),
                        identifier: `enrolment-in-calendar-alert-${event.courseUnitRealisationId}`,
                        dismiss: 10000,
                    });
                    break;
                default:
                // Do nothing
            }
        }
    }

    refreshEnrolment(id: OtmId): void {
        this.enrolmentStudentService.get(id).pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
            tap((enrolment: Enrolment) => { this.refreshCalculationConfigsSubject$.next([enrolment]); }),
        ).subscribe();
    }

    onViewInCalendar(date: string) {
        this.fullCalendarMessageService.goToDateSubject.next(date);
    }

    onToggleStudySubGroupInCalendar(enrolment: Enrolment) {
        this.enrolmentStudentService.update(enrolment.id, enrolment)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    onRemoveFromCalendar(enrolment: Enrolment) {
        this.alertsService.dismissAlertIfExists(`enrolment-updated-alert-${enrolment.courseUnitRealisationId}`);
        this.alertsService.dismissAlertIfExists(`enrolment-succeeded-alert-${enrolment.courseUnitRealisationId}`);
        this.alertsService.dismissAlertIfExists(`enrolment-in-calendar-alert-${enrolment.courseUnitRealisationId}`);

        let operation: Observable<Enrolment>;
        if (enrolment.state === 'NOT_ENROLLED') {
            operation = this.enrolmentStudentService.delete(enrolment.id);
        } else {
            operation = this.enrolmentStudentService.update(enrolment.id, { ...enrolment, isInCalendar: false });
        }
        return operation.pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }

    isThereStudyRightMatchingPlan(enrolment: Enrolment,
                                  allEnrolments: Enrolment[],
                                  courseUnit: CourseUnit,
                                  courseUnitRealisation: CourseUnitRealisation): void {
        this.studyRightEntityService.getStudyRightsForCurrentUser()
            .pipe(
                take(1),
                combineLatestWith(convertAJSPromiseToNative(this.getValidatablePlan())),
                switchMap(([studyRights, currentValidatablePlan]) => {
                    const myStudyRight = studyRights.find((studyRight) => studyRight.id === enrolment.studyRightId);
                    if (_.get(currentValidatablePlan, 'plan.rootId') !== _.get(myStudyRight, 'educationId')) {
                        return this.planEntityService.getMyPlans()
                            .pipe(
                                take(1),
                                switchMap((myPlans) => {
                                    const myMatchingPlan = _.find(myPlans, { rootId: _.get(myStudyRight, 'educationId'), primary: true });
                                    return convertAJSPromiseToNative(this.commonPlanService.getValidatablePlan(_.cloneDeep(myMatchingPlan), false, true))
                                        .then((validatablePlan: ValidatablePlan) => {
                                            this.showGuidanceAlertsForStudent(validatablePlan, courseUnit, courseUnitRealisation, enrolment, allEnrolments);
                                        });
                                }),
                            );
                    }
                    this.showGuidanceAlertsForStudent(currentValidatablePlan, courseUnit, courseUnitRealisation, enrolment, allEnrolments);
                    return of(null);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            ).subscribe();
    }

    showGuidanceAlertsForStudent(validatablePlan: ValidatablePlan,
                                 courseUnit: CourseUnit,
                                 courseUnitRealisation: CourseUnitRealisation,
                                 enrolment: Enrolment,
                                 allEnrolments: Enrolment[]): void {
        if (!validatablePlan) {
            // there is no primary plan for current enrolment with this study right.
            // Lets guide student to create one and add study module there.
            this.alertsService.addAlert({
                type: AlertType.INFO,
                message: this.translocoService.translate(
                    'ENROLMENT.NO_MATCHING_PLAN',
                    {
                        cur: this.localeService.localize(courseUnit.name),
                    },
                ),
                identifier: `guidance-alert-${courseUnitRealisation.id}`,
                onClickCallback: () => this.openCreatePlanModal(enrolment, courseUnitRealisation, courseUnit),
            });
        }

        if (validatablePlan) {
            const isSelected = _.filter(
                validatablePlan.plan.courseUnitSelections,
                courseUnitSelections => courseUnitSelections.courseUnitId === courseUnit.id,
            );
            const isCourseUnitUnscheduled = this.commonPlanService.isCourseUnitUnscheduled(enrolment.courseUnitId, validatablePlan, allEnrolments);
            if (!(isSelected.length > 0)) {
                this.alertsService.addAlert({
                    type: AlertType.INFO,
                    message: this.translocoService.translate('ENROLMENT.CUR_IS_NOT_IN_PLAN',
                                                             {
                                                                 cur: this.localeService.localize(courseUnit.name),
                                                             }),
                    identifier: `guidance-alert-${courseUnitRealisation.id}`,
                    onClickCallback: () => this.goStructure(validatablePlan, courseUnitRealisation, courseUnit),
                });
            } else if (!isSelected[0].completionMethodId) {
                this.alertsService.addAlert({
                    type: AlertType.INFO,
                    message: this.translocoService.translate(
                        'ENROLMENT.MISSING_COMPLETION_METHOD_SELECTION',
                        {
                            cur: this.localeService.localize(courseUnit.name),
                        },
                    ),
                    identifier: `guidance-alert-${courseUnitRealisation.id}`,
                    onClickCallback: () => this.openCourseUnitModal(validatablePlan, courseUnitRealisation, courseUnit),
                });
            } else if (isCourseUnitUnscheduled) {
                this.alertsService.addAlert(
                    {
                        type: AlertType.INFO,
                        message: this.translocoService.translate(
                            'ENROLMENT.MISSING_PART_OF_COURSE_UNIT',
                            {
                                cur: this.localeService.localize(courseUnit.name),
                            },
                        ),
                        identifier: `guidance-alert-${courseUnitRealisation.id}`,
                        onClickCallback: () => this.openCourseUnitModal(validatablePlan, courseUnitRealisation, courseUnit),
                    },
                );
            }
        }
    }

    openCreatePlanModal(enrolment: Enrolment,
                        courseUnitRealisation: CourseUnitRealisation,
                        courseUnit: CourseUnit) {
        this.alertsService.dismissAlertIfExists(`guidance-alert-${courseUnitRealisation.id}`);
        this.modalService.open(SelectEducationModalComponent, { studyRightId: enrolment.studyRightId }, { keyboard: false, size: 'sm' })
            .result
            .then((result) => {
                this.stateService.go('student.logged-in.structure', {
                    planId: result.planId, openUnplanned: true, unplannedCourseUnitId: courseUnit.id,
                });
            }).catch(() => { /* Do nothing */ });
    }

    goStructure(validatablePlan: ValidatablePlan, courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit) {
        this.alertsService.dismissAlertIfExists(`guidance-alert-${courseUnitRealisation.id}`);
        this.stateService.go('student.logged-in.structure', {
            planId: validatablePlan.plan.id, openUnplanned: true, unplannedCourseUnitId: courseUnit.id,
        });
    }

    openCourseUnitModal(validatablePlan: ValidatablePlan, courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit) {
        this.alertsService.dismissAlertIfExists(`guidance-alert-${courseUnitRealisation.id}`);
        this.courseUnitInfoModalService.showCompletionMethodsForCourseUnit(courseUnit.id, validatablePlan);
    }

    onEnrol(data: {
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }) {
        const { enrolment, courseUnitRealisation, courseUnit } = data;
        this.modalService.open(
            EnrolmentWizardComponent,
            { enrolment: _.cloneDeep(enrolment), isUpdate: false, isConfirmedSsgEdit: false },
            { size: 'lg' },
        ).result.then((updatedEnrolment: Enrolment) => {
            this.addExtraVisibleEnrolment(enrolment.id);
            this.isThereStudyRightMatchingPlan(updatedEnrolment, this.dataSignal().enrolments, courseUnit, courseUnitRealisation);
            this.refreshCalculationConfigsSubject$.next([updatedEnrolment]);
        })
            .catch(() => { /* Do nothing */
            });
    }

    onUpdate(data: {
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }) {
        const { enrolment, courseUnitRealisation, courseUnit } = data;
        this.modalService.open(
            EnrolmentWizardComponent,
            { enrolment: _.cloneDeep(enrolment), isUpdate: true, isConfirmedSsgEdit: false },
            { size: 'lg' },
        ).result.then((updatedEnrolment: Enrolment) => {
            this.addExtraVisibleEnrolment(enrolment.id);
            this.isThereStudyRightMatchingPlan(updatedEnrolment, this.dataSignal().enrolments, courseUnit, courseUnitRealisation);
            this.refreshCalculationConfigsSubject$.next([updatedEnrolment]);
        })
            .catch(() => { /* Do nothing */
            });
    }

    onModifyGroups(enrolment: Enrolment) {
        this.modalService.open(
            EnrolmentWizardComponent,
            { enrolment: _.cloneDeep(enrolment), isUpdate: true, isConfirmedSsgEdit: true },
            { size: 'lg' },
        ).result.then(() => {
            this.addExtraVisibleEnrolment(enrolment.id);
            this.refreshCalculationConfigsSubject$.next([enrolment]);
        })
            .catch(() => { /* Do nothing */
            });
    }

    onCancelEnrolment(data: {
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }) {
        const { enrolment, courseUnitRealisation, courseUnit } = data;
        this.enrolmentModalService.openCancelEnrolmentModal(enrolment, courseUnitRealisation, courseUnit)
            .then((modalResponse: Partial<{ enrolment: Enrolment }>) => {
                this.alertsService.dismissAlertIfExists(`guidance-alert-${courseUnitRealisation.id}`);
                this.refreshEnrolment(modalResponse.enrolment.id);
                this.addExtraVisibleEnrolment(modalResponse.enrolment.id);
            }).catch(() => { /* Do nothing */
            });
    }

    onAbort(data: {
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }) {
        this.enrolmentModalService.openAbortEnrolmentModal(data.enrolment, data.courseUnitRealisation, data.courseUnit)
            .then((modalResponse: Partial<{ enrolment: Enrolment }>) => {
                const updatedEnrolment = _.cloneDeep(modalResponse.enrolment);
                updatedEnrolment.studySubGroups.forEach((studySubGroup) => {
                    studySubGroup.isInCalendar = false;
                });
                this.enrolmentStudentService.update(updatedEnrolment.id, updatedEnrolment)
                    .pipe(
                        take(1),
                        this.appErrorHandler.defaultErrorHandler(),
                    )
                    .subscribe(() => {
                        this.refreshCalculationConfigsSubject$.next([updatedEnrolment]);
                        this.removeExtraVisibleEnrolment(updatedEnrolment.id);
                    });
            })
            .catch(() => { /* Do nothing */ });
    }

    onOpenQuestionnaire(data: { enrolment: Enrolment; courseUnitRealisation: CourseUnitRealisation; courseUnit: CourseUnit }) {
        const { enrolment, courseUnitRealisation, courseUnit } = data;
        this.enrolmentQuestionnaireEntityService.getByCourseUnitRealisationId(courseUnitRealisation.id)
            .pipe(
                catchError((err) => {
                    if (err.status === 404) {
                        return of({
                            courseUnitRealisationId: courseUnitRealisation.id,
                            enrolmentQuestions: [],
                        });
                    }
                    throwError(() => err);

                }),
                take(1),
                switchMap((questionnaire) => {
                    if (questionnaire?.enrolmentQuestions?.length > 0) {
                        return this.enrolmentQuestionnaireAnswersEntityService.getByEnrolmentId(enrolment.id)
                            .pipe(
                                catchError((err) => {
                                    if (err.status === 404) {
                                        return of(undefined);
                                    }
                                    throwError(() => err);
                                }),
                                take(1),
                                switchMap((questionnaireAnswers) =>
                                    of({ questionnaire, answers: this.populateQuestionnaireAnswers(enrolment, courseUnitRealisation, questionnaireAnswers,
                                                                                                   questionnaire as EnrolmentQuestionnaire) })),
                            );
                    }
                    return of({ questionnaire, answers: undefined });
                }),
                this.appErrorHandler.defaultErrorHandler(),
            ).subscribe(({ questionnaire, answers }) => {
                this.enrolmentModalService.openEnrolmentQuestionnaireModal(questionnaire, answers, courseUnitRealisation, courseUnit)
                    .catch(() => { /* Do nothing */ });
            });
    }

    populateQuestionnaireAnswers(enrolment: Enrolment,
                                 courseUnitRealisation: CourseUnitRealisation,
                                 questionnaireAnswers: EnrolmentQuestionnaireAnswers,
                                 questionnaire: EnrolmentQuestionnaire): EnrolmentQuestionnaireAnswers {
        let enrolmentQuestionnaireAnswers: Partial<EnrolmentQuestionnaireAnswers> = questionnaireAnswers ? { ...questionnaireAnswers } : undefined;
        if (!enrolmentQuestionnaireAnswers) {
            enrolmentQuestionnaireAnswers = {
                enrolmentId: enrolment.id,
                courseUnitRealisationId: courseUnitRealisation.id,
                studentId: enrolment.personId,
            };
        }
        let answers = enrolmentQuestionnaireAnswers.answers;
        if (!answers) {
            answers = [];
        }

        enrolmentQuestionnaireAnswers.answers = questionnaire.enrolmentQuestions
            .map((question) => {
                const answer = _.find(answers, { questionId: question.localId });
                if (answer) {
                    return answer;
                }
                return {
                    questionId: question.localId,
                    selections: [],
                    answerText: '',
                };
            });
        return enrolmentQuestionnaireAnswers as EnrolmentQuestionnaireAnswers;
    }

    onOpenCourseUnitInfo(data: { courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation }) {
        const { courseUnit, courseUnitRealisation } = data;
        if (!_.isFunction(this.getValidatablePlan)) {
            this.courseUnitInfoModalService.showCourseUnitRealisationForCourseUnit(courseUnit.id, courseUnitRealisation, undefined);
        } else {
            this.getValidatablePlan().then((validatablePlan) => {
                this.courseUnitInfoModalService.showCourseUnitRealisationForCourseUnit(courseUnit.id, courseUnitRealisation, validatablePlan);
            });
        }
    }

    onExpandedChange(data: { courseUnitRealisationId: OtmId, expanded: boolean }) {
        if (data.expanded) {
            this.initialExpandedCurIds.add(data.courseUnitRealisationId);
        } else {
            this.initialExpandedCurIds.delete(data.courseUnitRealisationId);
        }
        this.uiStateStore.storeField('otm.student.calendar.courseUnitRealisationItem.', 'expanded', Array.from(this.initialExpandedCurIds));
    }

    addExtraVisibleEnrolment(enrolmentId: OtmId) {
        const current = this.extraVisibleEnrolments();
        const newVisible = new Set(current);
        newVisible.add(enrolmentId);
        this.extraVisibleEnrolments.set(newVisible);
    }

    removeExtraVisibleEnrolment(enrolmentId: OtmId) {
        const current = this.extraVisibleEnrolments();
        const newVisible = new Set(current);
        newVisible.delete(enrolmentId);
        this.extraVisibleEnrolments.set(newVisible);
    }
}
