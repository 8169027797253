import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { AssessmentItem, CourseUnit, CourseUnitRealisation, Enrolment, EnrolmentCalculationConfigForPerson, EnrolmentStudySubGroup, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { Observable, tap } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';
import { StudentCourseUnitInfoModalService } from '../../common/service/student-course-unit-info-modal.service';
import { enrolmentDialogOpener } from '../enrolment-dialog/enrolment-dialog.component';

@Component({
    selector: 'app-enrolment-box',
    templateUrl: './enrolment-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentBoxComponent implements OnInit {
    @Input({ required: true }) enrolment: Enrolment;
    @Output() enrolmentClickAction = new EventEmitter<any>();
    @Output() enrolmentCancelAction = new EventEmitter<any>();
    @Output() enrolmentRemoveAction = new EventEmitter<any>();

    constructor(
        private studentCourseUnitInfoModalService: StudentCourseUnitInfoModalService,
        private courseUnitEntityService: CourseUnitEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private assessmentEntityService: AssessmentItemEntityService,
        private appErrorHandler: AppErrorHandler,
        private enrolmentStudentService: EnrolmentStudentService,
        private universityService: UniversityService,
    ) {}

    isLateEnrolment = false;
    courseUnit$: Observable<CourseUnit>;
    courseUnitRealisation$: Observable<CourseUnitRealisation>;
    assessmentItem$: Observable<AssessmentItem>;
    courseUnit: CourseUnit;
    courseUnitRealisation: CourseUnitRealisation;
    enrolmentCalculationConfig$: Observable<EnrolmentCalculationConfigForPerson>;
    enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson;
    currentUniversityId: OtmId;

    sortedGroupLists: EnrolmentStudySubGroup[][];

    private _openEnrolmentDialog = enrolmentDialogOpener();

    ngOnInit(): void {
        this.courseUnit$ = this.courseUnitEntityService.getById(this.enrolment.courseUnitId)
            .pipe(
                tap((courseUnit) => this.courseUnit = courseUnit),
                this.appErrorHandler.defaultErrorHandler(),
            );

        this.courseUnitRealisation$ = this.courseUnitRealisationEntityService.getById(this.enrolment.courseUnitRealisationId, true)
            .pipe(
                tap((courseUnitRealisation) => {
                    this.courseUnitRealisation = courseUnitRealisation;
                    this.isLateEnrolment = !!courseUnitRealisation?.enrolmentPeriod?.endDateTime &&
                        !!courseUnitRealisation?.lateEnrolmentEnd && moment().isAfter(courseUnitRealisation.enrolmentPeriod.endDateTime);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );

        this.assessmentItem$ = this.assessmentEntityService.getById(this.enrolment.assessmentItemId)
            .pipe(this.appErrorHandler.defaultErrorHandler());

        this.enrolmentCalculationConfig$ = this.enrolmentStudentService.getCalculationConfig(this.enrolment.courseUnitRealisationId)
            .pipe(
                tap(enrolmentCalculationConfig => {
                    this.enrolmentCalculationConfig = enrolmentCalculationConfig;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );

        this.currentUniversityId = this.universityService.getCurrentUniversityOrgId();
    }

    isCurCancelled() {
        return ['CANCELLED_ACTIVE', 'CANCELLED_EXPIRED', 'CANCELLED'].includes(this.courseUnitRealisation?.flowState);
    }

    getAction(enrolment: Enrolment) {
        if (_.isEqual('NOT_ENROLLED', enrolment.state)) {
            return 'remove-selection';
        }
        return null;
    }

    getEnrolmentPeriodDateState() {
        return this.courseUnitRealisationEntityService.getEnrolmentPeriodValidity(
            this.courseUnitRealisation,
            this.enrolmentCalculationConfig?.enrolmentCalculationState,
        );
    }

    usesExternalEnrolment() {
        return this.courseUnitRealisation?.usesExternalEnrolment;
    }

    hasExternalEnrolmentLink() {
        return this.courseUnitRealisation?.externalEnrolmentLink;
    }

    hasExternalEnrolmentLinkLabel() {
        return this.courseUnitRealisation?.externalEnrolmentLink?.label;
    }

    isCrossStudy() {
        return !this.courseUnitRealisation.universityOrgIds?.includes(this.currentUniversityId);
    }

    invalidCrossStudyNetwork() {
        return !this.courseUnitRealisation.cooperationNetworkDetails;
    }

    crossStudyInvalidState() {
        return this.enrolment.processingState === 'NOT_VALID' || this.enrolment.state === 'INVALID';
    }

    getTimingInfoToShow() {
        if (this.isCrossStudy() && this.invalidCrossStudyNetwork()) return null;
        if (['NOT_ENROLLED', 'PROCESSING'].includes(this.enrolment.state) || this.getPrimaryButtonTextAndAction()?.action === 'enroll') return 'enroll';
        if (this.getSecondaryButtonTextAndAction()?.action === 'cancel') return 'cancel';
        if (this.getSecondaryButtonTextAndAction()?.action === 'abort') return 'abort';
        if (this.getPrimaryButtonTextAndAction()?.action === 'updateGroups') return 'update';
        return null;
    }

    getUpdateTiming() {
        return this.resolveConfirmedStudySubGroupModificationEnd();
    }

    getAbortTiming() {
        return this.courseUnitRealisation.activityPeriod.endDate;
    }

    getCancelTiming() {
        if (this.courseUnitRealisation.enrolmentAdditionalCancellationEnd) {
            return this.courseUnitRealisation.enrolmentAdditionalCancellationEnd;
        }
        if (this.courseUnitRealisation.continuousEnrolment) {
            return this.courseUnitRealisation.activityPeriod?.startDate;
        }
        if (this.getEnrolmentPeriodDateState() === 'ONGOING') {
            return this.courseUnitRealisation.enrolmentPeriod.endDateTime;
        }
    }

    resolveConfirmedStudySubGroupModificationEnd() {
        const { activityPeriod, confirmedStudySubGroupModificationEnd } = this.courseUnitRealisation;
        return dateUtils.minDateTime(
            activityPeriod?.endDate ? moment(activityPeriod.endDate).toString() : null,
            confirmedStudySubGroupModificationEnd,
        );
    }

    getPrimaryButtonTextAndAction() {
        if (!this.courseUnitRealisation) return null;
        const {
            usesExternalEnrolment,
            continuousEnrolment,
            confirmedStudySubGroupModificationAllowed,
        } = this.courseUnitRealisation;

        const now = moment();

        if (usesExternalEnrolment) {
            return null;
        }
        if (this.isCrossStudy() && this.invalidCrossStudyNetwork()) {
            return null;
        }

        if (this.enrolment.state === 'ENROLLED'
            && confirmedStudySubGroupModificationAllowed
            && now.isBefore(this.resolveConfirmedStudySubGroupModificationEnd())) {
            return { text: 'ENROLMENT.MODIFY_GROUPS', action: 'updateGroups' };
        }

        if (this.getEnrolmentPeriodDateState() !== 'ONGOING') return null;

        switch (this.enrolment.state) {
            case 'NOT_ENROLLED':
                return { text: 'ENROLMENT.ENROL', action: 'enroll' };
            case 'PROCESSING':
                if (!continuousEnrolment && !this.isLateEnrolment) {
                    return { text: 'EDIT', action: 'edit' };
                }
                break;
            case 'REJECTED':
            case 'INVALID':
                return { text: 'ENROLMENT.RE_ENROL', action: 'enroll' };
            default:
                break;
        }

        return null;
    }

    emitPrimaryClickAction() {
        const action = this.getPrimaryButtonTextAndAction()?.action;
        this.enrolmentClickAction.emit({ action });
    }

    emitSecondaryClickAction() {
        const action = this.getSecondaryButtonTextAndAction()?.action;
        this.enrolmentCancelAction.emit({ action });
    }

    getSecondaryButtonTextAndAction() {
        if (!this.courseUnitRealisation) return null;
        const now = moment();
        const { state } = this.enrolment;
        const { continuousEnrolment, activityPeriod, enrolmentPeriod, enrolmentAdditionalCancellationEnd, usesExternalEnrolment } = this.courseUnitRealisation;
        const calculationState = this.enrolmentCalculationConfig?.enrolmentCalculationState;

        const cancelVisible = () => {
            if (!['PROCESSING', 'INVALID', 'ENROLLED'].includes(state)) {
                return false;
            }
            if (continuousEnrolment && now.isSameOrAfter(enrolmentPeriod?.startDateTime) && now.isBefore(activityPeriod?.startDate)) {
                return true;
            }
            if (this.getEnrolmentPeriodDateState() === 'ONGOING' && !this.isLateEnrolment && !continuousEnrolment) {
                return true;
            }
            if (enrolmentAdditionalCancellationEnd
                && now.isBefore(enrolmentAdditionalCancellationEnd)
                && calculationState === 'CONFIRMED'
            ) {
                return true;
            }
            return false;
        };

        const abortVisible = () => {
            if (state === 'ENROLLED') {
                if (!this.courseUnitRealisationEntityService.isActive(this.courseUnitRealisation)) return false;
                if (calculationState === 'CONFIRMED' || continuousEnrolment) return true;
            }
            return false;
        };
        if (usesExternalEnrolment) {
            return null;
        }
        if (cancelVisible()) {
            return { text: 'ENROLMENT.CANCEL', action: 'cancel' };
        }
        if (abortVisible()) {
            return { text: 'ENROLMENT.ABORT_ENROLMENT', action: 'abort' };
        }
        return null;
    }

    getStudyGroupInfoTitle(index: number) {
        if (this.enrolment.state === 'ENROLLED') {
            return index === 0 ? 'ENROLMENTS.GROUP_INFO.CONFIRMED' : 'ENROLMENTS.GROUP_INFO.REJECTED';
        }
        if (this.enrolment.state === 'PROCESSING') {
            if (['NOT_PROCESSED', 'PENDING'].includes(this.enrolment.processingState)) return null;
            if (['CURRENTLY_NOT_SELECTED', 'NOT_SELECTED', 'REQ_NOT_FULFILLED'].includes(this.enrolment.processingState)) return 'ENROLMENTS.GROUP_INFO.CURRENTLY_REJECTED';
            return index === 0 ? 'ENROLMENTS.GROUP_INFO.CURRENTLY_CONFIRMED' : 'ENROLMENTS.GROUP_INFO.CURRENTLY_REJECTED';
        }
        return null;
    }

    openCourseUnitInfoModal(
        courseUnit: CourseUnit,
        courseUnitRealisation: CourseUnitRealisation,
    ): void {
        this.studentCourseUnitInfoModalService.openCourseUnitModalForEnrolment(
            courseUnit.id,
            courseUnitRealisation,
            this.enrolment,
        );
    }

    handleStudyGroupData() {
        if (this.sortedGroupLists) return;
        const enrolledGroups = this.enrolment.studySubGroups?.filter(group => ['PRIMARY', 'SUITABLE'].includes(group.enrolmentStudySubGroupPriority));

        const placedGroupIds = [...this.enrolment.confirmedStudySubGroupIds || [], ...this.enrolment.tentativeStudySubGroupIds || []]
            .filter(id => !_.flatMap(enrolledGroups, 'studySubGroupId').includes(id));
        enrolledGroups.push(...this.getPlacedGroups(placedGroupIds));

        if (this.enrolment.state === 'PROCESSING') {
            if (['CURRENTLY_SELECTED', 'SELECTED'].includes(this.enrolment.processingState)) {
                const tentativeGroups = enrolledGroups.filter(group => this.enrolment.tentativeStudySubGroupIds?.includes(group.studySubGroupId) ||
                    this.enrolment.confirmedStudySubGroupIds?.includes(group.studySubGroupId));
                const rejectedGroups = enrolledGroups.filter(group => !this.enrolment.tentativeStudySubGroupIds?.includes(group.studySubGroupId) &&
                !this.enrolment.confirmedStudySubGroupIds?.includes(group.studySubGroupId));
                this.sortedGroupLists = [tentativeGroups, rejectedGroups];
            } else {
                this.sortedGroupLists = [enrolledGroups];
            }
        } else if (this.enrolment.state === 'ENROLLED') {
            const confirmedGroups = enrolledGroups.filter(group => this.enrolment.confirmedStudySubGroupIds?.includes(group.studySubGroupId));

            if (!confirmedGroups.length) {
                this.sortedGroupLists = [];
            } else {
                const rejectedGroups = enrolledGroups.filter(group => !this.enrolment.confirmedStudySubGroupIds?.includes(group.studySubGroupId));
                this.sortedGroupLists = [confirmedGroups, rejectedGroups];
            }
        } else {
            this.sortedGroupLists = [enrolledGroups];
        }
    }

    getPlacedGroups(placedGroupIds: OtmId[]): EnrolmentStudySubGroup[] {
        const placedGroups: EnrolmentStudySubGroup[] = [];
        placedGroupIds.forEach((groupId) => {
            placedGroups.push({
                enrolmentStudySubGroupPriority: 'PRIMARY',
                isInCalendar: false,
                studySubGroupId: groupId,
            });
        });
        return placedGroups;
    }

    showRequirementsInfo() {
        if (!this.enrolmentCalculationConfig?.requirementRules?.length) return false;
        if (this.enrolment.state === 'PROCESSING') {
            return ['CURRENTLY_NOT_SELECTED', 'REQ_NOT_FULFILLED'].includes(this.enrolment.processingState);
        }
        return this.enrolment.state === 'REJECTED';
    }

    openEnrolmentDialog() {
        this._openEnrolmentDialog({ enrolmentId: this.enrolment?.id });
    }
}

