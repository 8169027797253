import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    Attainment, AttainmentMisregistrationRequest,
    AttainmentSearchRequest,
    AttainmentState,
    AttainmentType,
    AttainmentUpdateRequest,
    CourseUnitAttainment,
    CourseUnitSubstitutionRegistrationRequest,
    DegreeModificationRequest,
    DegreeModificationResponse,
    DocumentState, EvaluationAttainmentWrapper,
    LocalizedString,
    OtmId,
    SearchResult,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { orderBy } from 'lodash-es';
import { catchError, combineLatest, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    isAboutToExpire,
    isAssessmentItemAttainment,
    isAttached,
    isCourseUnitAttainment,
    isCustomCourseUnitAttainment,
    isCustomModuleAttainment,
    isDegreeProgrammeAttainment,
    isModuleAttainment,
    toChildAttainmentIds,
} from '../attainment/AttainmentUtil';
import { searchRequestToQueryParams } from '../search-ng/search-utils';

import { AssessmentItemEntityService } from './assessment-item-entity.service';
import { CourseUnitEntityService } from './course-unit-entity.service';
import { EntityService } from './entity.service';
import { GraduationEntityService } from './graduation-entity.service';
import { ModuleEntityService } from './module-entity.service';

export interface FindForPersonParams {
    assessmentItemId?: OtmId | OtmId[];
    attached?: boolean;
    attainmentState?: AttainmentState | AttainmentState[];
    attainmentType?: AttainmentType | AttainmentType[];
    courseUnitId?: OtmId;
    courseUnitRealisationId?: OtmId;
    documentState?: DocumentState | DocumentState[];
    misregistration?: boolean;
    moduleId?: OtmId;
    primary?: boolean;
    searchString?: string;
    sort?: string;
}

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        forStudentApplication(applicationId: OtmId) {
            return `${this.backend}/attainments/student-applications/${applicationId}`;
        },
        byWorkflowId(workflowId: OtmId) {
            return `${this.backend}/attainments/workflows/${workflowId}`;
        },
        modifyDegree(id: OtmId) {
            return `/ori/api/attainments/${id}/modify-degree`;
        },
        updateAttainment(id: OtmId) {
            return `/ori/api/attainments/${id}/update`;
        },
        updatePermissionCheck(id: OtmId) {
            return `${this.backend}/attainments/${id}/update-permission-check`;
        },
        getModuleContentApplicationsCustomAttainments(applicationId: OtmId) {
            return `${this.backend}/attainments/module-content-application-attainments/${applicationId}`;
        },
        search() {
            return `${this.backend}/attainments/search`;
        },
        markAsSecondaryAttainment(id: OtmId) {
            return `${this.backend}/attainments/${id}/mark-as-secondary`;
        },
        registerCourseUnitSubstitution(): string {
            return `${this.backend}/attainments/register-course-unit-substitution`;
        },
        misregister(id: OtmId): string {
            return `${this.backend}/attainments/${id}/misregister`;
        },
        createAttainments(): string {
            return `${this.backend}/attainments/mass`;
        },
    },
};
@StaticMembers<DowngradedService>()
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'attainments',
})
@Injectable({
    providedIn: 'root',
})
export class AttainmentEntityService extends EntityService<AttainmentEntityState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.attainmentEntityService',
        serviceName: 'attainmentEntityService',
    };

    constructor(
        private moduleEntityService: ModuleEntityService,
        private courseUnitEntityService: CourseUnitEntityService,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private graduationEntityService: GraduationEntityService,
        private localeService: LocaleService,
    ) {
        super(AttainmentStore, AttainmentQuery);
    }

    getByStudentApplicationId(applicationId: OtmId): Observable<Attainment[]> {
        return this.getHttp().get<Attainment[]>(CONFIG.ENDPOINTS.forStudentApplication(applicationId))
            .pipe(
                tap(attainments => this.store.upsertMany(attainments)),
                switchMap(() => this.selectAll({ filterBy: att => att.studentApplicationId === applicationId })),
            );
    }

    getByWorkflowId(workflowId: OtmId): Observable<Attainment[]> {
        return this.getHttp().get<Attainment[]>(CONFIG.ENDPOINTS.byWorkflowId(workflowId))
            .pipe(
                tap(attainments => this.store.upsertMany(attainments)),
                switchMap(() => this.selectAll({ filterBy: att => att.workflowId === workflowId })),
            );
    }

    getModuleContentApplicationsCustomAttainments(applicationId: OtmId): Observable<Attainment[]> {
        return this.getHttp().get<Attainment[]>(CONFIG.ENDPOINTS.getModuleContentApplicationsCustomAttainments(applicationId))
            .pipe(
                tap(attainments => this.store.upsertMany(attainments)),
                switchMap((attainments) => this.query.selectMany(attainments.map(att => att.id))),
            );
    }

    getByPersonId(personId: OtmId): Observable<Attainment[]> {
        return this.getHttp().get<Attainment[]>(this.api, { params: { personId } })
            .pipe(
                tap(entities => this.store.upsertMany(entities)),
                switchMap(() => this.selectAll({ filterBy: entity => entity.personId === personId })),
            );
    }

    findForPerson(personId: OtmId, params?: FindForPersonParams): Observable<Attainment[]> {
        return this.getHttp().get<Attainment[]>(`${this.api}/${personId}/search`, { params: { ...params } });
    }

    getAttainmentName(attainment: Attainment): Observable<LocalizedString> {
        if (isAssessmentItemAttainment(attainment)) {
            return combineLatest(
                this.courseUnitEntityService.getById(attainment.courseUnitId),
                this.assessmentItemEntityService.getSnapshotAtDateTime(attainment.assessmentItemId, attainment.attainmentDate),
                (courseUnit, assessmentItem) => ({ courseUnit, assessmentItem }),
            )
                .pipe(map(({ courseUnit, assessmentItem }) =>
                    this.localeService.merge([courseUnit.name, assessmentItem.name], ', ', true)));
        }
        if (isCourseUnitAttainment(attainment)) {
            return this.courseUnitEntityService.getById(attainment.courseUnitId)
                .pipe(map(courseUnit => courseUnit.name));
        }
        if (isModuleAttainment(attainment) || isDegreeProgrammeAttainment(attainment)) {
            return this.moduleEntityService.getById(attainment.moduleId)
                .pipe(map(module => module.name));
        }
        if (isCustomCourseUnitAttainment(attainment) || isCustomModuleAttainment(attainment)) {
            return of(attainment.name);
        }
        return of(null);
    }

    getAttainmentCode(attainment: Attainment): Observable<string> {
        if (isCourseUnitAttainment(attainment) || isAssessmentItemAttainment(attainment)) {
            return this.courseUnitEntityService.getById(attainment.courseUnitId)
                .pipe(map(courseUnit => courseUnit.code));
        }
        if (isModuleAttainment(attainment) || isDegreeProgrammeAttainment(attainment)) {
            return this.moduleEntityService.getById(attainment.moduleId)
                .pipe(map(module => module.code));
        }
        if (isCustomCourseUnitAttainment(attainment) || isCustomModuleAttainment(attainment)) {
            return of(attainment.code);
        }
        return of(null);
    }

    updatePermissionCheck(id: OtmId): Observable<any> {
        return this.getHttp().get(CONFIG.ENDPOINTS.updatePermissionCheck(id));
    }

    updateAttainment(id: OtmId, request: AttainmentUpdateRequest): Observable<Attainment> {
        return this.getHttp().post<Attainment>(CONFIG.ENDPOINTS.updateAttainment(id), request)
            .pipe(
                tap(attainment => this.store.upsert(attainment.id, attainment)),
                switchMap(attainment => this.query.selectEntity(attainment.id)),
            );
    }

    markAsSecondaryAttainment(id: OtmId): Observable<Attainment> {
        return this.getHttp().post<Attainment>(CONFIG.ENDPOINTS.markAsSecondaryAttainment(id), {})
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    modifyDegree(attainmentId: OtmId, request: DegreeModificationRequest): Observable<DegreeModificationResponse> {
        return this.getHttp().post<DegreeModificationResponse>(CONFIG.ENDPOINTS.modifyDegree(attainmentId), request)
            .pipe(
                tap(response => this.store.upsert(response.attainment.id, response.attainment)),
                switchMap(response => this.query.selectEntity(response.attainment.id)
                    .pipe(
                        map(attainment => (<DegreeModificationResponse>{
                            attainment,
                            graduation: response.graduation,
                        })),
                    ),
                ),
            );
    }

    search(searchParams: Partial<AttainmentSearchRequest>): Observable<SearchResult<Attainment>> {
        return this.getHttp().get<SearchResult<Attainment>>(CONFIG.ENDPOINTS.search(), { params: this.toQueryParams(searchParams) });
    }

    isAttached(attainment: Attainment, attainments: Attainment[]): boolean {
        return isAttached(attainment, attainments);
    }

    toChildAttainmentIds(attainment: Attainment): OtmId[] {
        return toChildAttainmentIds(attainment);
    }

    isAboutToExpire(attainment: Attainment): boolean {
        return isAboutToExpire(attainment);
    }

    getSecondaryAttainments(attainment: Attainment, allAttainments: Attainment[]): Attainment[] {
        let filter;

        if (isAssessmentItemAttainment(attainment)) {
            filter = {
                primary: false,
                type: attainment.type,
                assessmentItemId: attainment.assessmentItemId,
            };
        }

        if (isCourseUnitAttainment(attainment)) {
            filter = {
                primary: false,
                type: attainment.type,
                courseUnitId: attainment.courseUnitId,
            };
        }

        if (isModuleAttainment(attainment)) {
            filter = {
                primary: false,
                type: attainment.type,
                moduleId: attainment.moduleId,
            };
        }

        if (isDegreeProgrammeAttainment(attainment)) {
            filter = {
                primary: false,
                type: attainment.type,
                moduleId: attainment.moduleId,
            };
        }

        if (filter) {
            return _.filter(allAttainments, filter) as Attainment[];
        }
        return [];
    }

    isExternallyImportedDPAttainment(attainment: Attainment): Observable<boolean> {
        if (attainment.type === 'DegreeProgrammeAttainment') {
            return this.graduationEntityService.getByAttainmentId(attainment.id)
                .pipe(
                    take(1),
                    map(() => false),
                    catchError((error) => {
                        if (error.status === 404) {
                            return of(true);
                        }
                        return throwError(() => error);
                    }),
                );
        }
        return of(false);
    }

    /**
     * Creates a substituted {@link CourseUnitAttainment}.
     *
     * If the study right is based on enrolment rights, the attainment will be linked with an existing {@link EnrolmentRight} of {@link CourseUnitSubstitutionRegistrationRequest.studyRightId} and {@link CourseUnitSubstitutionRegistrationRequest.courseUnitId},
     * that is valid on the provided {@link CreditTransferInfo.creditTransferDate},
     * or with a new {@link EnrolmentRight} of type "SUBSTITUTION".
     */
    registerCourseUnitSubstitution(request: CourseUnitSubstitutionRegistrationRequest): Observable<void> {
        return this
            .getHttp()
            .post<CourseUnitAttainment>(CONFIG.ENDPOINTS.registerCourseUnitSubstitution(), request)
            .pipe(
                map(attainment => this.store.upsert(attainment.id, attainment)),
            );
    }

    misregister(id: OtmId, request: AttainmentMisregistrationRequest): Observable<Attainment> {
        return this.getHttp().post<Attainment>(CONFIG.ENDPOINTS.misregister(id), request)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    createAttainments(attainments: Attainment[], allowReEvaluation?: boolean): Observable<EvaluationAttainmentWrapper> {
        return this.getHttp().post<EvaluationAttainmentWrapper>(CONFIG.ENDPOINTS.createAttainments(), attainments, { params: { allowReEvaluation } });
    }

    private toQueryParams(searchRequest: Partial<AttainmentSearchRequest>): { [key: string]: string | string[] | boolean } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                searchString: searchRequest.fullTextQuery,
                assessmentItemId: searchRequest.assessmentItemIds,
                attached: searchRequest.attached,
                attainmentState: searchRequest.attainmentStates,
                attainmentType: searchRequest.attainmentTypes,
                courseUnitId: searchRequest.courseUnitId,
                courseUnitRealisationId: searchRequest.courseUnitRealisationId,
                misregistration: searchRequest.misregistration,
                moduleId: searchRequest.moduleId,
                parentCourseUnitId: searchRequest.parentCourseUnitId,
                personId: searchRequest.personId,
                primary: searchRequest.primary,
            },
            _.isNil,
        );

    }
}
/**
 * Get latest attainment by ordering
 * 1. Primarily on registration date (desc)
 * 2. Secondarily on lastModifiedOn (desc)
 *
 * @param attainments
 */
export function latestAttainment(attainments: Attainment[]): Attainment {
    return orderBy(attainments, [
        attainment => attainment.registrationDate,
        attainment => attainment.metadata?.lastModifiedOn,
    ], ['desc', 'desc'])[0];
}

export function filterLatestAttainment(): OperatorFunction<Attainment[], Attainment> {
    return map(attainments => latestAttainment(attainments));
}

type AttainmentEntityState = EntityState<Attainment, OtmId>;

@StoreConfig({ name: 'attainment' })
class AttainmentStore extends EntityStore<AttainmentEntityState> {}

class AttainmentQuery extends QueryEntity<AttainmentEntityState> {
    constructor(protected store: AttainmentStore) {
        super(store);
    }
}
