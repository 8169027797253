import { Injectable } from '@angular/core';
import {
    AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormControlOptions, FormControlState, FormGroup, FormRecord, ValidatorFn } from '@angular/forms';
import { ISO_LOCAL_TIME_FORMAT, MaxLength, MOMENT_DATE_DISPLAY_FORMAT, MOMENT_TIME_DISPLAY_FORMAT } from 'common-typescript/constants';
import {
    CodeBook,
    LocalDateRange,
    LocalDateString,
    LocalDateTimeRange,
    LocalDateTimeString,
    LocalizedString,
    LocalTimeRange,
    LocalTimeString,
    NumberRange,
    OrganisationRoleShareBase,
    OtmId,
    SisValidatorFn,
    Urn,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    isNumber,
    localDate,
    localTime,
    max,
    maxDate,
    maxDuration,
    maxLength,
    maxLocalTime,
    min,
    minDate,
    minExclusive,
    minLength,
    minLessOrEqualToMax,
    minLocalTime,
    oneChildValueRequired,
    pattern,
    required,
    requiredIf,
    startDateBeforeEndDate,
    startDateOrEndDateRequired,
    startDateTimeBeforeEndDateTime,
    startTimeBeforeEndTime,
} from './form-validators';
import { subtractDayFromEndDate } from './formUtils';
import { SisFormControl } from './sis-form-control';

/**
 * Defines parameters for building a 'FormControl` instance for a local date editor.
 */
export interface LocalDateEditorOptions {
    /** If true, the date input will be marked as required. */
    required?: boolean;
    /** The minimum allowed date value (inclusive). */
    minDate?: moment.MomentInput;
    /**
     * A custom validation error message translation key to use if the control value is before the given minDate.
     * The translation key can use the `minDate` translation parameter to show the defined minDate as part of
     * the validation error message.
     */
    minDateErrorKey?: string;
    /** The maximum allowed date value (exclusive). */
    maxDate?: moment.MomentInput;
    /**
     * A custom validation error message translation key to use if the control value is same or after the given maxDate.
     * The translation key can use the `maxDate` translation parameter to show the defined maxDate as part of
     * the validation error message.
     */
    maxDateErrorKey?: string;
    /** Additional validators to apply to the control (in addition to the ones applied based on the other options) */
    validators?: SisValidatorFn[];
    /** Makes the component uneditable through the ui */
    disabled?: boolean;
}

/**
 * Defines parameters for building a 'FormGroup` instance for a local date time editor.
 */
export interface LocalDateTimeEditorOptions {
    /** If true, the date and time inputs will be marked as required. */
    required?: boolean;
    /** The minimum allowed date time value (inclusive). */
    minDate?: moment.MomentInput;
    /** The maximum allowed date time value (exclusive). */
    maxDate?: moment.MomentInput;
    /** Makes the component uneditable through the ui */
    disabled?: boolean;
    /** Additional validators to apply to the date range control (in addition to the ones applied based on the other options) */
    validators?: SisValidatorFn[];
}

/**
 * Defines parameters for building a 'FormGroup` instance for a local date range editor.
 */
export interface LocalDateRangeEditorOptions {
    /** If true, both start date and end date will be marked as required. Overrides startRequired and endRequired. */
    required?: boolean;
    /** If true, the start date will be marked as required. */
    startRequired?: boolean;
    /** If true, the end date will be marked as required. */
    endRequired?: boolean;
    /** If true, the start date or end date is required. */
    startOrEndRequired?: boolean;
    /** The minimum allowed start date value (inclusive). */
    minStartDate?: moment.MomentInput;
    /** See `LocalDateEditorOptions.minDateErrorKey` */
    minStartDateErrorKey?: string;
    /** The maximum allowed start date value (exclusive). */
    maxStartDate?: moment.MomentInput;
    /** See `LocalDateEditorOptions.maxDateErrorKey` */
    maxStartDateErrorKey?: string;
    /** The minimum allowed end date value (inclusive). */
    minEndDate?: moment.MomentInput;
    /** See `LocalDateEditorOptions.minDateErrorKey` */
    minEndDateErrorKey?: string;
    /** The maximum allowed end date value (exclusive). */
    maxEndDate?: moment.MomentInput;
    /** See `LocalDateEditorOptions.maxDateErrorKey` */
    maxEndDateErrorKey?: string;
    /** Additional validators to apply to the date range control (in addition to the ones applied based on the other options) */
    validators?: SisValidatorFn[];
}

/**
 * Defines parameters for building a 'FormGroup` instance for a local date time range editor.
 */
export interface LocalDateTimeRangeEditorOptions {
    /** If true, both start date time and end date time will be marked as required. Overrides startRequired and endRequired. */
    required?: boolean;
    /** If true, the start date time will be marked as required. */
    startRequired?: boolean;
    /** If true, the end date time will be marked as required. */
    endRequired?: boolean;
    /** The minimum allowed start date time value (inclusive). */
    minStartDate?: moment.MomentInput;
    /** The maximum allowed end date time value (exclusive). */
    maxEndDate?: moment.MomentInput;
    /** Sets the start date as uneditable through the ui. */
    startEditDisabled?: boolean;
    /** Sets the end date as uneditable through the ui. */
    endEditDisabled?: boolean;
    /** Additional validators to apply to the date range control (in addition to the ones applied based on the other options) */
    validators?: SisValidatorFn[];
}

/**
 * Defines parameters for building a `FormGroup` instance for a localized string editor.
 */
export interface LocalizedStringEditorOptions {
    /** If true, a non-empty value has to be given for at least one locale. */
    oneRequired?: boolean;
    /** If true, a non-empty value has to be given for all locales. Overrides `oneRequired.` */
    allRequired?: boolean;
    /** Defines the minimum length for all non-empty values. A non-null `minLength` does NOT make the field required. */
    minLength?: number;
    /** Defines the maximum length for all non-empty values. */
    maxLength?: MaxLength | number;
    /** The locales available in the editor. */
    locales?: string[];
    /** Property for overriding the default 'blur' updateOn setting */
    updateOn?: 'change' | 'blur' | 'submit';
}

/**
 * Defines parameters for building a `FormControl` instance for a local time editor
 */
export interface LocalTimeEditorOptions {
    /** If true, input will be marked as required */
    required?: boolean;
    /** If true, input will be disabled */
    disabled?: boolean;
    /** The minimum allowed start time value (exclusive). */
    minTime?: MinTime;
    /** The maximum allowed end time value (inclusive). */
    maxTime?: MaxTime;
}

/**
 * Defines parameters for building a `FormGroup` instance for a time range editor
 */
export interface LocalTimeRangeEditorOptions {
    /** If true, startTime will be marked as required */
    startRequired?: boolean;
    /** If true, startTime will be disabled */
    startDisabled?: boolean;
    /** If true, endTime will be marked as required */
    endRequired?: boolean;
    /** If true, endTime will be disabled */
    endDisabled?: boolean;
    /** Defines the maximum duration between startTime and endTime */
    maxDuration?: MaxDuration;
}

/** Defines parameters for building a `FormGroup` instance for editing number ranges. */
export interface NumberRangeEditorOptions {
    minRequired?: boolean;
    maxRequired?: boolean;
    minValue?: number;
    maxValue?: number;
}

/**
 * Interface for maxDuration
 */
export interface MaxDuration {
    duration: moment.Duration;
    errorKey?: string;
}

/**
 * Interface for minTime
 */
export interface MinTime {
    time: moment.MomentInput;
    errorKey?: string;
}

/**
 * Interface for maxTime
 */
export interface MaxTime {
    time: moment.MomentInput;
    errorKey?: string;
}

/**
 * FormGroup interface for LocalDateTime
 */
export interface LocalDateTimeForm {
    date: FormControl<LocalDateString>;
    time: FormControl<LocalTimeString>;
}

/**
 * FormGroup interface for LocalDateRange
 */
export interface LocalDateRangeForm {
    startDate: FormControl<LocalDateString>;
    endDate: FormControl<LocalDateString>;
}

/**
 * FormGroup interface for LocalDateTimeRange
 */
export interface LocalDateTimeRangeForm {
    startDateTime: FormGroup<LocalDateTimeForm>;
    endDateTime: FormGroup<LocalDateTimeForm>;
}

/**
 * FormGroup interface for LocalTimeRange
 */
export interface LocalTimeRangeForm {
    startTime: FormControl<LocalTimeString>;
    endTime: FormControl<LocalTimeString>;
}

export interface NumberRangeControls {
    min: FormControl<number | string | null>;
    max: FormControl<number | string | null>;
}

export interface CustomCodeSelectionControls {
    codeBookUrn: FormControl<Urn | null>;
    codes: FormArray<FormControl<Urn | null>>;
}

export type CustomCodeSelectionControlsArray = FormArray<FormGroup<CustomCodeSelectionControls>>;

export interface OrganisationRoleShareBaseControls {
    organisationId: FormControl<OtmId | null>;
    educationalInstitutionUrn: FormControl<Urn | null>;
    share: FormControl<number | null>;
    roleUrn: FormControl<Urn | null>;
}

/**
 * Extends Angular's built-in `FormBuilder` service with SISU-specific functionality.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class SisFormBuilder extends FormBuilder {

    constructor(private localeService: LocaleService) {
        super();
        this.DEFAULT_LOCALIZED_STRING_OPTIONS = { oneRequired: false, allRequired: false, locales: localeService.getOfficialLanguages() };
    }

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.form.formBuilder',
        serviceName: 'sisFormBuilder',
    };

    private readonly DEFAULT_LOCALIZED_STRING_OPTIONS: LocalizedStringEditorOptions;

    /**
     * Builds a new `FormControl` that accepts monetary values using the Finnish number format as input. More specifically:
     * - The decimal separator (if any) has to be a comma
     * - The input can either be an integer, or a decimal number with one or two decimal places
     * - The integer part can have arbitrary whitespace, but the decimal part can have no whitespace
     *
     * @param initialValue Initial value of the control. If the value is a string, it must use a non-localized format
     * (i.e. no grouping separators, no whitespace, and dot as the decimal separator).
     * @param isRequired If true, the `required` validator will be added to the control
     * @param minValue If a valid primitive number, the `min` validator will be added to the control
     * @param maxValue If a valid primitive number, the `max` validator will be added to the control
     */
    currency(initialValue?: number | string, isRequired = false, minValue?: number, maxValue?: number) {
        let parsedValue: number = null;
        if (_.isString(initialValue) && _.isFinite(_.toNumber(initialValue))) {
            parsedValue = _.toFinite(initialValue);
        } else if (_.isFinite(initialValue)) {
            parsedValue = initialValue as number;
        }
        let formState: string = null;
        if (!_.isNull(parsedValue)) {
            const formatter = new Intl.NumberFormat('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            formState = formatter.format(parsedValue)
                // NumberFormat uses a minus sign which is unrecognized by the Angular validators. Replace it with a "regular" dash.
                .replace('−', '-')
                // Replace non-breaking spaces (i.e. grouping separators) with regular ones
                .replace(/\s/g, ' ')
                // Remove unnecessary decimals if the value is effectively an integer
                .replace(/,00$/, '');
        }
        return this.control(formState, {
            validators: [
                pattern(/^-?[\d\s]*\d+[\d\s]*(,\d{1,2})?$/, 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.CURRENCY'),
                isRequired ? required() : null,
                _.isFinite(minValue) ? min(minValue) : null,
                _.isFinite(maxValue) ? max(maxValue) : null,
            ].filter(Boolean),
            updateOn: 'blur',
        });
    }

    /**
     * Builds a fully configured `FormControl` instance for editing a `LocalDateString`. Accepts a `LocalDateTimeString` as the
     * initialValue argument, but the time part will be stripped from the input.
     *
     * Note that the returned `FormControl` will contain the date value using the Finnish format (instead of ISO-8601).
     */
    localDate(initialValue?: LocalDateString | LocalDateTimeString | moment.Moment, options: LocalDateEditorOptions = {}): FormControl<LocalDateString> {
        const formattedDate = this.formatLocalDate(initialValue);
        const validators = [
            localDate(),
            options.required ? required() : null,
            options.minDate ? minDate(options.minDate, options.minDateErrorKey) : null,
            options.maxDate ? maxDate(options.maxDate, options.maxDateErrorKey) : null,
            ...(options.validators || []),
        ].filter(Boolean);

        const control = this.control(formattedDate, { validators, updateOn: 'blur' });
        if (options.disabled) {
            control.disable();
        }
        return control;
    }

    formatLocalDate(date: string | moment.Moment): string {
        const parsedDate = moment(date, [moment.ISO_8601, MOMENT_DATE_DISPLAY_FORMAT], true);
        return parsedDate.isValid() ? parsedDate.format(MOMENT_DATE_DISPLAY_FORMAT) : null;
    }

    /**
     * Builds a fully configured `FormControl` instance for editing a `LocalTimeString`. Accepts a `LocalDateTimeString` as the
     * initialValue argument, but the date part will be stripped from the input.
     *
     * Note that the returned `FormControl` will contain the time value using the Finnish format (instead of ISO-8601).
     */
    localTime(initialValue?: LocalTimeString | LocalDateTimeString, options: LocalTimeEditorOptions = {}): FormControl<LocalTimeString> {
        const parsedTime = moment(initialValue, [moment.ISO_8601, MOMENT_TIME_DISPLAY_FORMAT, ISO_LOCAL_TIME_FORMAT], true);
        const formattedTime = parsedTime.isValid() ? parsedTime.format(MOMENT_TIME_DISPLAY_FORMAT) : null;
        const validators = [
            localTime(),
            options.required ? required() : null,
            options.minTime ? minLocalTime(options.minTime.time, options.minTime.errorKey) : null,
            options.maxTime ? maxLocalTime(options.maxTime.time, options.maxTime.errorKey) : null,
        ].filter(Boolean);

        const control: FormControl = this.control(formattedTime, { validators, updateOn: 'blur' });
        if (options.disabled) {
            control.disable();
        }
        return control;
    }

    /**
     * Builds a fully configured `FormGroup` instance for editing a `LocalDateTimeString`.
     *
     * Note that the returned `FormControl` will contain the date and time values using the Finnish format (instead of ISO-8601).
     */
    localDateTime(initialValue?: LocalDateTimeString, options: LocalDateTimeEditorOptions = {}): FormGroup<LocalDateTimeForm> {
        const validators = [
            options.required ? required() : null,
            options.minDate ? minDate(options.minDate) : null,
            options.maxDate ? maxDate(options.maxDate) : null,
        ].filter(Boolean);

        return this.group(
            {
                date: this.localDate(initialValue, { required: options.required, disabled: options.disabled }),
                time: this.localTime(initialValue, { required: options.required, disabled: options.disabled }),
            },
            { validators: [...validators, ...(options.validators || [])] },
        );
    }

    /**
     * Builds a fully configured `FormGroup` instance for editing a `LocalDateRange`.
     *
     * Note that the returned `FormControl` will contain the date values using the Finnish format (instead of ISO-8601).
     */
    localDateRange(initialValue?: Partial<LocalDateRange>, options: LocalDateRangeEditorOptions = {}): FormGroup<LocalDateRangeForm> {
        const startDateOptions: LocalDateEditorOptions = {
            required: options.required || options.startRequired,
            minDate: options.minStartDate,
            minDateErrorKey: options.minStartDateErrorKey,
            maxDate: options.maxStartDate,
            maxDateErrorKey: options.maxStartDateErrorKey,
        };
        const endDateOptions: LocalDateEditorOptions = {
            required: options.required || options.endRequired,
            minDate: options.minEndDate,
            minDateErrorKey: options.minEndDateErrorKey,
            maxDate: options.maxEndDate,
            maxDateErrorKey: options.maxEndDateErrorKey,
        };
        return this.group(
            {
                startDate: this.localDate(initialValue?.startDate, startDateOptions),
                endDate: this.localDate(subtractDayFromEndDate(initialValue?.endDate), endDateOptions),
            },
            { validators: [
                startDateBeforeEndDate(),
                options.startOrEndRequired ? startDateOrEndDateRequired() : null,
                ...(options.validators || []),
            ].filter(Boolean) },
        );
    }

    /**
     * Builds a fully configured `FormGroup` instance for editing a `LocalDateTimeRange`.
     *
     * Note that the returned `FormControl` will contain the date and time values using the Finnish format (instead of ISO-8601).
     */
    localDateTimeRange(initialValue?: Partial<LocalDateTimeRange>, options: LocalDateTimeRangeEditorOptions = {}): FormGroup<LocalDateTimeRangeForm> {
        const startDateOptions: LocalDateTimeEditorOptions = {
            required: options.required || options.startRequired,
            minDate: options.minStartDate,
            disabled: options.startEditDisabled,
        };
        const endDateOptions: LocalDateTimeEditorOptions = {
            required: options.required || options.endRequired,
            maxDate: options.maxEndDate,
            disabled: options.endEditDisabled,
        };

        return this.group(
            {
                startDateTime: this.localDateTime(initialValue?.startDateTime, startDateOptions),
                endDateTime: this.localDateTime(initialValue?.endDateTime, endDateOptions),
            },
            { validators: [startDateTimeBeforeEndDateTime(), ...(options.validators || [])] },
        );
    }

    localTimeRange(initialValue?: Partial<LocalTimeRange>, options: LocalTimeRangeEditorOptions = {}): FormGroup<LocalTimeRangeForm> {
        const validators = [
            startTimeBeforeEndTime(),
            options.maxDuration ? maxDuration(options.maxDuration.duration, options.maxDuration.errorKey) : null,
        ].filter(Boolean);
        return this.group(
            {
                startTime: this.localTime(initialValue?.startTime, {
                    required: options.startRequired,
                    disabled: options.startDisabled,
                }),
                endTime: this.localTime(initialValue?.endTime, {
                    required: options.endRequired,
                    disabled: options.endDisabled,
                }),
            },
            { validators },
        );
    }

    /**
     * Builds a fully configured `FormGroup` instance for editing a `LocalizedString`.
     */
    localizedString(initialValue?: LocalizedString,
                    options?: LocalizedStringEditorOptions): FormRecord<FormControl<string>> {
        const opts: LocalizedStringEditorOptions = Object.assign({}, this.DEFAULT_LOCALIZED_STRING_OPTIONS, options);

        const validators: SisValidatorFn[] = [
            opts.minLength ? minLength(opts.minLength, null) : null,
            opts.maxLength ? maxLength(opts.maxLength, null) : null,
            opts.allRequired ? required() : null,
        ].filter(validator => !_.isNil(validator));

        const formGroup: FormRecord<FormControl<string>> = this.group({});
        if (opts.oneRequired && !opts.allRequired) {
            formGroup.setValidators(oneChildValueRequired('SIS_COMPONENTS.STRING.LOCALIZED_STRING_EDITOR.REQUIRED'));
        }
        const validatorOrOps = {
            validators,
            updateOn: opts.updateOn || 'blur',
        };
        opts.locales.forEach(locale =>
            formGroup.addControl(
                locale,
                this.control(
                    _.get(initialValue, locale),
                    validatorOrOps,
                )));

        return formGroup;
    }

    /**
     * Builds a `FormGroup` instance for editing number ranges.
     */
    numberRange<T extends NumberRange<number>>(initialValue?: T, options?: NumberRangeEditorOptions): FormGroup<NumberRangeControls> {
        return this.group(
            {
                min: this.control<number | string | null>(
                    initialValue?.min,
                    [
                        isNumber(),
                        requiredIf(() => options?.minRequired),
                        min(options?.minValue),
                        max(options?.maxValue),
                    ],
                ),
                max: this.control<number | string | null>(
                    initialValue?.max,
                    [
                        isNumber(),
                        requiredIf(() => options?.maxRequired),
                        min(options?.minValue),
                        max(options?.maxValue),
                    ],
                ),
            },
            { validators: minLessOrEqualToMax() },
        );
    }

    buildCustomCodebookValueSelectionForm(codebooks: CodeBook[], initialValues?: Urn[]): CustomCodeSelectionControlsArray {
        return this.array(codebooks.map(book => this.group<CustomCodeSelectionControls>({
            codeBookUrn: this.control<Urn>(book.urn),
            codes: this.array(initialValues?.filter(value => book.codes?.map(union => union.urn).includes(value)).map(value => this.control(value)) ?? []),
        })));
    }

    /**
     * Note that there is no backend validation for distinct organisationIds or educationalInstitutionUrns.
     *
     * @param initialValues An optional array of values to initialize the FormArray with
     * @param requireResponsibleOrganisation Whether to add a validator to the FormArray that requires it to contain
     * at least one responsible organisation
     */
    organisationRoleShareBaseArray(
        initialValues?: OrganisationRoleShareBase[],
        requireResponsibleOrganisation = true,
    ): FormArray<FormGroup<OrganisationRoleShareBaseControls>> {
        const groupArray = (initialValues || []).map(initialValue => this.organisationRoleShareBase(initialValue));
        const shareSum = (formArray: FormArray) => {
            const rolesByRoleUrn = _.groupBy(formArray.controls, group => group.get('roleUrn').value);
            const invalidInvalidKeys = Object.keys(rolesByRoleUrn)
                .filter((key) => {
                    const sum = rolesByRoleUrn[key].map((group: FormGroup) => group.get('share').value).reduce((x, y) => x + y, 0);
                    return Math.abs(sum - 1) >= 0.0000001;
                })
                .map(roleUrn => `shareSum_${roleUrn}`);
            if (invalidInvalidKeys.length === 0) {
                return null;
            }
            const errors: any = {};
            invalidInvalidKeys.forEach((invalidKey) => {
                errors[invalidKey] = {
                    translationKey: 'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.SUM_OF_SHARES_MUST_EQUAL_HUNDRED',
                };
            });
            return errors;
        };
        const shouldHaveAtLeastOneResponsibleOrganisation = (formArray: FormArray) => {
            if (formArray.controls.find(group => group.get('roleUrn').value === 'urn:code:organisation-role:responsible-organisation')) {
                return null;
            }
            return {
                shouldHaveAtLeastOneResponsibleOrganisation: {
                    translationKey: 'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ORGANISATION_MISSING',
                },
            };
        };
        return this.array(
            groupArray,
            {
                validators: [
                    shareSum,
                    requireResponsibleOrganisation ? shouldHaveAtLeastOneResponsibleOrganisation : null,
                ].filter(Boolean),
            },
        );
    }

    organisationRoleShareBase(initialValue?: OrganisationRoleShareBase): FormGroup<OrganisationRoleShareBaseControls> {
        const share = this.control(
            initialValue?.share,
            [
                isNumber(),
                required(),
                minExclusive(
                    0,
                    'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.SHARE_MUST_BE_OVER_ZERO',
                ),
                max(1),
            ],
        );
        const initial = {
            share,
            educationalInstitutionUrn: this.control(initialValue?.educationalInstitutionUrn),
            organisationId: this.control(initialValue?.organisationId),
            roleUrn: this.control(initialValue?.roleUrn),
        };
        const missingOrganisationIdOrEducationalInstitutionUrn = (group: FormGroup) => {
            if (group.get('roleUrn').value !== 'urn:code:organisation-role:coordinating-organisation') {
                return null;
            }
            if (group.get('organisationId').value || group.get('educationalInstitutionUrn').value) {
                return null;
            }
            return {
                missingOrganisationIdOrEducationalInstitutionUrn: {
                    translationKey: 'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ORGANISATION_AND_EDUCATIONAL_INSTITUTION_MISSING',
                },
            };
        };
        const onlyOrganisationIdOrEducationalInstitutionUrnAllowed = (group: FormGroup) => {
            if (group.get('roleUrn').value !== 'urn:code:organisation-role:coordinating-organisation') {
                return null;
            }
            if (!group.get('organisationId').value || !group.get('educationalInstitutionUrn').value) {
                return null;
            }
            return {
                onlyOrganisationIdOrEducationalInstitutionUrnAllowed: {
                    translationKey: 'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ONLY_ORGANISATION_OR_EDUCATIONAL_INSTITUTION',
                },
            };
        };
        const organisationIdRequiredForResponsibleOrganisationRole = (group: FormGroup) => {
            if (group.get('roleUrn').value !== 'urn:code:organisation-role:responsible-organisation') {
                return null;
            }
            if (group.get('organisationId').value) {
                return null;
            }

            return {
                organisationIdRequiredForResponsibleOrganisationRole: {
                    translationKey: 'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ORGANISATION_MISSING',
                },
            };
        };
        return this.group(
            initial,
            {
                validators: [
                    missingOrganisationIdOrEducationalInstitutionUrn,
                    onlyOrganisationIdOrEducationalInstitutionUrnAllowed,
                    organisationIdRequiredForResponsibleOrganisationRole,
                ],
            },
        );
    }

    sisFormControl<T>(value: FormControlState<T> | T, validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null): SisFormControl<T> {
        return new SisFormControl(value, validatorOrOpts, asyncValidator);
    }
}
