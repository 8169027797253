import { dateUtils } from 'common-typescript/constants';
'use strict';
angular.module('student.timing.studyRightIndicator.studyRightIndicatorBuilder', ['student.timing.constant', 'sis-components.service.studyRightService']).factory('studyRightIndicatorBuilder', ["timelineConfig", "studyRightState", function (timelineConfig, studyRightState) {
  return function (periods, studyRights) {
    var hasBeenActive = false;
    var isFirstActive = true;
    var isFirstOver = true;
    var isFirstRequest = true;
    _.each(periods, function (period) {
      var isActive = false;
      period.studyRightIndicator = {
        change: false
      };
      _.each(studyRights, function (studyRight) {
        if (_.has(studyRight, 'valid') && isInRange(period.valid, studyRight.valid) && (studyRight.state === studyRightState.ACTIVE || studyRight.state === studyRightState.PASSIVE)) {
          isActive = true;
          hasBeenActive = true;
        }
      });
      if (isActive) {
        period.studyRightIndicator.status = timelineConfig.studyRight.status.ACTIVE;
      } else if (hasBeenActive) {
        period.studyRightIndicator.status = timelineConfig.studyRight.status.REQUEST_MORE;
      }
      if (isActive && isFirstActive) {
        period.studyRightIndicator.change = true;
        isFirstActive = false;
      }
      if (period.studyRightIndicator.status === timelineConfig.studyRight.status.ACTIVE && isFirstRequest) {
        period.studyRightIndicator.change = true;
        isFirstRequest = false;
      }
      if (period.studyRightIndicator.status === timelineConfig.studyRight.status.REQUEST_MORE && isFirstOver) {
        period.studyRightIndicator.change = true;
        isFirstOver = false;
      }
    });
  };
  function isInRange(periodRange, studyRightRange) {
    return dateUtils.dateTimeRangesOverlapNullsAsInfinity(periodRange.startDate, periodRange.endDate, studyRightRange.startDate, studyRightRange.endDate, 'day');
  }
}]);