import angular from 'angular';
import moment from 'moment';
import * as _ from 'lodash-es';
import angularTranslate from 'angular-translate';
import { localeServiceModule } from 'sis-common/l10n/localeService';
export const studyEventSummaryServiceModule = 'sis-components.service.studyEventSummaryService';
angular.module('sis-components.service.studyEventSummaryService', [angularTranslate, localeServiceModule]).service('studyEventSummaryService', ["$translate", "localeService", function ($translate, localeService) {
  const api = {
    getWeekDayOrInterval: studyEvent => {
      if (_.includes([null, 'NEVER', 'WEEKLY'], studyEvent.recursEvery)) {
        return api.getWeekDay(studyEvent.startTime);
      }
      if (studyEvent.recursEvery === 'DAILY') {
        return $translate.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.DAILY');
      }
      if (studyEvent.recursEvery === 'EVERY_SECOND_WEEK') {
        return _.join([$translate.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.EVERY_SECOND_WEEK'), api.getWeekDay(studyEvent.startTime)], ' ');
      }
      if (studyEvent.recursEvery === 'MONTHLY') {
        return $translate.instant('SIS_COMPONENTS.STUDY_EVENT_RECURSION.MONTHLY');
      }
      return '';
    },
    getWeekDay: dateTime => moment(dateTime).locale(localeService.getCurrentLocale()).format('ddd'),
    getTimeRange: studyEvent => {
      const startMoment = moment(studyEvent.startTime);
      const endMoment = moment(studyEvent.startTime).add(moment.duration(studyEvent.duration));
      return _.join([startMoment.format('HH.mm'), endMoment.format('HH.mm')], '–');
    },
    // Currently using the Finnish locale for displaying dates and times. Asked POs on Skype on 28.4.2016 whether this is desirable.
    getDateRange: studyEvent => {
      if (_.isEmpty(studyEvent.events)) {
        return '';
      }
      const start = moment(_.first(studyEvent.events).start);
      const end = moment(_.last(studyEvent.events).start);
      if (start === null && end === null) {
        return '';
      }
      if (start.isSame(end)) {
        // Using format 'l' would give localized equivalent of this.
        // The reason I did not do this outright is the fact that Moment does not support
        // localized format equivalent of D.M. (see 5 lines below), so some coding would be needed in order to get around that.
        return end.format('DD.MM.YYYY');
      }
      if (start.year() === end.year()) {
        return _.join([start.format('DD.MM.'), end.format('DD.MM.YYYY')], '–');
      }
      return _.join([start.format('DD.MM.YYYY'), end.format('DD.MM.YYYY')], '–');
    }
  };
  return api;
}]);