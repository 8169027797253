import * as _ from 'lodash-es';

import {
    PlanValidationState,
} from '../../../types/baseTypes.js';
import { AssessmentItem, CompletionMethod, CourseUnit } from '../../../types/generated/common-backend.js';
import { Range } from '../../model/range.js';
import { PlanValidationStateService } from '../../service/planValidationState.service.js';

import { AttainmentValidation } from './attainmentValidation.js';
import { RuleContext } from './context/ruleContext.js';
import { PlanValidationResult } from './planValidationResult.js';
import { RangeValidation } from './rangeValidation.js';
import { ValidatablePlan } from './validatablePlan.js';

export class CourseUnitValidation {

    static readonly unlimitedCredits = 1000000;

    static validateCourseUnit(
        courseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        const attainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (attainment) {
            AttainmentValidation.validateCourseUnitAttainment(courseUnit, validatablePlan, ruleContext);
        } else if (validatablePlan.isSubstituted(courseUnit)) {
            CourseUnitValidation.validateSubstitutes(courseUnit, validatablePlan, ruleContext, planValidationResult);
        } else if (validatablePlan.getSelectedCompletionMethod(courseUnit)) {
            CourseUnitValidation.validateCompletionMethod(courseUnit, validatablePlan, ruleContext);
        } else {
            ruleContext.addPlannedCredits(new Range(courseUnit.credits));
        }
        if (validatablePlan.isSubstitute(courseUnit)) {
            CourseUnitValidation.validateSubstitutedCourseUnit(courseUnit, validatablePlan, ruleContext);
        }
        ruleContext.addCourseUnit(courseUnit);
        planValidationResult.courseUnitValidationResults[courseUnit.id] = ruleContext.getResults();
        return ruleContext;
    }

    private static subtract(creditsAvailable: number, credits: number): number {
        return creditsAvailable - credits;
    }

    private static validateSubstitutedCourseUnit(
        substitutedCourseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        if (validatablePlan.isCourseUnitInPlan(substitutedCourseUnit)) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
        if (validatablePlan.isSubstituted(substitutedCourseUnit)) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        } else {
            const maxCredits = ruleContext.getActualCredits().max || this.unlimitedCredits;
            const usedCredits = _.map(validatablePlan.getSubstituteForCredits(substitutedCourseUnit), (credits) => credits || maxCredits);
            const creditsAvailable = _.reduce(usedCredits, CourseUnitValidation.subtract, maxCredits);
            if (creditsAvailable < 0) {
                ruleContext.mergeState(PlanValidationState.INVALID);
            }
        }
    }

    private static validateCompletionMethod(
        courseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const subContext = CourseUnitValidation.validateCompletionMethodSelections(
            validatablePlan.getSelectedCompletionMethod(courseUnit),
            validatablePlan.getSelectedAssessmentItems(courseUnit),
            validatablePlan);

        const result = RangeValidation.validateRange(new Range(courseUnit.credits), subContext.getActualCredits());
        subContext.mergeState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));

        if (subContext.state === PlanValidationState.ATTAINED) {
            ruleContext.mergeState(PlanValidationState.PARTS_ATTAINED);
        } else {
            ruleContext.mergeState(PlanValidationState.PLANNED);
        }
        ruleContext.mergeSubContext(subContext);
    }

    private static validateSubstitutes(
        courseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
        planValidationResult: PlanValidationResult,
    ): void {

        if (validatablePlan.isSubstitute(courseUnit)) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        } else {
            const substitutedByCourseUnits = validatablePlan.getSelectedSubstitution(courseUnit);
            if (!_.isEmpty(substitutedByCourseUnits)) {
                _.forEach(substitutedByCourseUnits, (substitutionWithCourseUnit) => {
                    ruleContext.mergePartial(CourseUnitValidation.validateCourseUnit(substitutionWithCourseUnit.courseUnit, validatablePlan, planValidationResult),
                                             substitutionWithCourseUnit.credits);
                });
            } else {
                ruleContext.mergeState(PlanValidationState.INVALID);
            }
        }
    }

    private static validateCompletionMethodSelections(
        completionMethod: CompletionMethod | null,
        selectedAssessmentItems: AssessmentItem[],
        validatablePlan: ValidatablePlan,
    ): RuleContext {
        const ruleContext = new RuleContext();
        let count = 0;
        _.forEach(selectedAssessmentItems, (assessmentItem) => {
            ruleContext.mergeContext(CourseUnitValidation.validateAssessmentItem(assessmentItem, validatablePlan));

            if (_.includes(_.get(completionMethod, 'assessmentItemIds'), assessmentItem.id)) {
                count += 1;
            } else {
                ruleContext.mergeState(PlanValidationState.INVALID);
            }
        });
        let result;
        if (completionMethod && completionMethod.typeOfRequire === 'ALL_SELECTED_REQUIRED') {
            result = RangeValidation.validateRange(new Range(_.size(completionMethod.assessmentItemIds)), new Range(count));
            ruleContext.mergeState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        } else if (completionMethod && completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE') {
            result = RangeValidation.validateRange(new Range(completionMethod.require), new Range(count));
            ruleContext.mergeState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        } else {
            // OPTIONAL_WITH_DESCRIPTION: We don't know whether all the requirements have been met or not, so
            // the only option to use is INCOMPLETE
            ruleContext.mergeState(PlanValidationState.INCOMPLETE);
        }
        return ruleContext;
    }

    private static validateAssessmentItem(assessmentItem: AssessmentItem, validatablePlan: ValidatablePlan): RuleContext {
        const attainment = validatablePlan.getAssessmentItemAttainment(assessmentItem.id);
        if (attainment) {
            return AttainmentValidation.validateAssessmentItemAttainment(assessmentItem, validatablePlan);
        }
        const ruleContext = new RuleContext();
        ruleContext.addPlannedCredits(new Range(assessmentItem.credits));
        ruleContext.addAssessmentItem(assessmentItem);
        return ruleContext;
    }

}
