import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { CooperationNetwork } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { Option } from '../../../select/combobox/combobox.component';
import { CooperationNetworkEntityService } from '../../../service/cooperation-network-entity.service';
import { SearchService } from '../../search.service';

@Component({
    selector: 'sis-search-filter-cooperation-networks',
    templateUrl: './search-filter-cooperation-networks.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterCooperationNetworksComponent {

    @Input({ required: true }) key: string;

    @Input({ required: true }) label: string;

    private readonly cooperationNetworkService = inject(CooperationNetworkEntityService);
    private readonly localeService = inject(LocaleService);
    private readonly searchService = inject(SearchService);

    options$: Observable<Option[]> = this.searchService.universityOrgId$
        .pipe(
            switchMap(universityOrgId => this.cooperationNetworkService.getByUniversityOrgId(universityOrgId)
                .pipe(
                    catchError(e => {
                        console.error(`Error fetching cooperation networks for university ${universityOrgId}`, e);
                        return of(<CooperationNetwork[]>[]);
                    }),
                )),
            map(networks => networks
                .filter(network => this.cooperationNetworkService.getCooperationNetworkValidity(network) === 'ONGOING')),
            map(networks => networks.map(network => ({
                label: this.localeService.localize(network.name),
                value: network.id,
            }))),
            map(options => options.sort((a, b) => a.label.localeCompare(b.label))),
        );
}
