import { Component, Inject, Input, OnChanges, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { EntityWithRule, ModuleContentApplication, StudyRight } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { from } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { CreateModuleContentWorkflowModalComponent } from '../create-module-content-workflow-modal/create-module-content-workflow-modal.component';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@Component({
    selector: 'sis-create-custom-module-content-workflow',
    templateUrl: './create-custom-module-content-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CreateCustomModuleContentWorkflowComponent implements OnChanges {
    @Input() parentModule: EntityWithRule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() matchingStudyRight: StudyRight;

    isCreatingWorkflowPossible = false;

    effectiveModuleContentApplicationStates = [
        'REQUESTED',
        'IN_HANDLING',
        'ACCEPTED',
        'CONDITIONAL',
    ];

    blockingPlanStates = [
        'PLANNED',
        'PARTS_ATTAINED',
        'IMPLICIT',
        'EMPTY',
    ];

    constructor(
        private modalService: ModalService,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnChanges() {
        this.isCreatingWorkflowPossible = this.isCreatingWorkflowAllowed();
    }

    isCreatingWorkflowAllowed(): boolean {
        if (!this.matchingStudyRight || !this.validatablePlan || !this.parentModule || !this.planValidationResult) {
            return false;
        }
        if (!!this.getEffectiveModuleContentApplication()) {
            return false;
        }
        return !this.isPlanStateBlockingNewWorkflow();
    }

    getEffectiveModuleContentApplication(): ModuleContentApplication {
        const allModuleContentApplications = this.validatablePlan.moduleContentApplications;
        const effectiveApplicationsForCurrentModule = _.filter(allModuleContentApplications, (application) =>
            application.educationId === this.validatablePlan.rootModule.id &&
            application.approvedModuleId === this.parentModule.id &&
            application.parentModuleId === _.get(this.validatablePlan.moduleIdSelectionMap, [this.parentModule.id, 'parentModuleId']) &&
            _.includes(this.effectiveModuleContentApplicationStates, application.state));
        return _.first(effectiveApplicationsForCurrentModule);

    }

    isPlanStateBlockingNewWorkflow() {
        const moduleValidationResult = _.get(this.planValidationResult, ['moduleValidationResults', this.parentModule.id]);
        return _.includes(this.blockingPlanStates, moduleValidationResult?.state);
    }

    openModal() {
        from(
            this.modalService.open(
                CreateModuleContentWorkflowModalComponent,
                {
                    module: this.parentModule,
                    validatablePlan: this.validatablePlan,
                    workflowType: 'CustomModuleContentWorkflow',
                    studyRightId: _.get(this.matchingStudyRight, 'id'),
                },
                {
                    size: 'lg',
                },
            ).result,
        )
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => this.planActionsService?.closeAndSave?.());
    }
}
