import {
    Component,
    Input,
    OnChanges,
    ViewEncapsulation,
} from '@angular/core';
import { CreditsRule, DecimalNumberRange, EntityWithRule, RangeValidationResultState } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-credit-phrase',
    templateUrl: './credit-phrase.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CreditPhraseComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.header.creditPhrase',
        directiveName: 'sisCreditPhrase',
    };

    isOk: boolean;
    implicitCount: number;
    directCount: number;
    selected: number;
    tooManyCreditsSelected: boolean;
    notEnoughCreditsSelected: boolean;
    addRange: DecimalNumberRange;
    removeRange: DecimalNumberRange;
    require: DecimalNumberRange;
    targetCredits: DecimalNumberRange;
    plannedCredits: DecimalNumberRange;

    @Input() parentModule: EntityWithRule;
    @Input() ruleValidationResult: any;
    @Input() creditsRule: CreditsRule;
    @Input() level: number;

    ngOnChanges() {
        this.update();
    }

    update(): void {
        if (this.level === 1 && _.get(this.parentModule, 'targetCredits')) {
            this.targetCredits = {
                min: _.get(this.parentModule, 'targetCredits.min', null),
                max: _.get(this.parentModule, 'targetCredits.max', null),
            };
        } else {
            this.targetCredits = {
                min: this.creditsRule.credits.min,
                max: this.creditsRule.credits.max || null,
            };
        }
        this.plannedCredits = this.ruleValidationResult.plannedCredits;
        this.isOk = this.ruleValidationResult.result === RangeValidationResultState.OK;
        this.notEnoughCreditsSelected = _.isNumber(this.plannedCredits.max) && this.plannedCredits.max < this.targetCredits.min;
        this.tooManyCreditsSelected = _.isNumber(this.targetCredits.max) && this.plannedCredits.min > this.targetCredits.max;
        this.addRange = this.getAddRange();
        this.removeRange = this.getRemoveRange();
    }

    getRuleValidationResult(planValidationResult: any, module: EntityWithRule): any {
        return _.get(planValidationResult.ruleValidationResults, [module.id, this.creditsRule.localId]);
    }

    getAddRange(): DecimalNumberRange {
        const minAddition = _.isNumber(this.plannedCredits.max) ? this.targetCredits.min - this.plannedCredits.max : null;
        const maxAddition = _.isNumber(this.targetCredits.max) ? this.targetCredits.max - this.plannedCredits.min : null;
        return {
            min: minAddition,
            max: maxAddition,
        };
    }

    getRemoveRange(): DecimalNumberRange {
        const minRemoval = _.isNumber(this.targetCredits.max) ? this.plannedCredits.min - this.targetCredits.max : null;
        const maxRemoval = _.isNumber(this.plannedCredits.max) ? this.plannedCredits.max - this.targetCredits.min : null;
        return {
            min: minRemoval,
            max: maxRemoval,
        };
    }

}
