import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { TranslocoService } from '@ngneat/transloco';
import { dateUtils } from 'common-typescript/constants';
import { OtmId, StudyPeriod, StudyTerm, StudyYear } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { groupBy } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { EntityService } from './entity.service';

export interface StudyPeriodWithUniqueName extends StudyPeriod {
    uniqueName: string;
}

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getStudyYears() {
            return `${this.backend}/study-years`;
        },
    },
};

@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'study-years',
})
export class StudyYearsEntityService extends EntityService<StudyYearsState> {

    constructor(
        private localeService: LocaleService,
        private transloco: TranslocoService,
    ) {
        super(StudyYearsStore, StudyYearsQuery);
    }

    /**
     * Returns study years corresponding to the given study year start years. Fetches the study years with a single API request
     * that includes all study years between the first and the last of the given years; i.e. a continuous range is always returned.
     */
    getStudyYears(organisationId: OtmId, studyYearStartYears: number[]): Observable<StudyYear[]>;

    /**
     * Returns the requested amount of study years starting from the given study year.
     */
    getStudyYears(organisationId: OtmId, firstYear: number, numberOfYears: number): Observable<StudyYear[]>;

    getStudyYears(organisationId: OtmId, firstYearOrYearRange: number | number[], numberOfYears?: number): Observable<StudyYear[]> {
        const params: { [key: string]: string | number } = { organisationId };
        if (Array.isArray(firstYearOrYearRange)) {
            const firstYear = Math.min(...firstYearOrYearRange);
            const lastYear = Math.max(...firstYearOrYearRange);
            params.firstYear = firstYear;
            params.numberOfYears = lastYear - firstYear + 1;
        } else {
            params.firstYear = firstYearOrYearRange;
            params.numberOfYears = numberOfYears;
        }
        return this.getHttp().get<StudyYear[]>(CONFIG.ENDPOINTS.getStudyYears(), { params });
    }

    /**
     * Emits the current study year,
     * or produces an error if not found.
     */
    getCurrentStudyYear(organisationId: OtmId): Observable<StudyYear> {
        if (!organisationId) {
            throw Error('No organisationId given');
        }
        const firstYear = moment().year() - 1;
        return this.getStudyYears(organisationId, firstYear, 2).pipe(
            map((studyYears: StudyYear[]) => {
                const today = moment();
                const currentStudyYear: StudyYear = _.find(studyYears, studyYear => dateUtils.rangeContains(today, studyYear.valid));
                if (currentStudyYear) {
                    return currentStudyYear;
                }
                throw Error('Current study year template missing');
            }),
        );
    }

    /**
     * Returns the current study year and a requested amount of upcoming study years.
     *
     * @param organisationId The id of the university whose study years to return
     * @param upcomingStudyYearCount The amount of upcoming study years to return _in addition to_ the current one
     */
    getCurrentAndUpcomingStudyYears(organisationId: OtmId, upcomingStudyYearCount = 1): Observable<StudyYear[]> {
        return this.getStudyYears(organisationId, moment().year() - 1, upcomingStudyYearCount + 2)
            .pipe(
                map(studyYears => studyYears.filter(studyYear => !dateUtils.isRangeBefore(moment(), studyYear.valid))),
                map(studyYears => {
                    const current = studyYears.find(({ valid }) => dateUtils.rangeContains(moment(), valid));
                    if (!current) {
                        throw Error('Current study year template missing');
                    }
                    return [
                        current,
                        ...studyYears
                            .filter(({ startYear }) => startYear > current.startYear &&
                                startYear - current.startYear <= upcomingStudyYearCount),
                    ];
                }),
            );
    }

    getCurrentStudyPeriod(organisationId: OtmId): Observable<StudyPeriod> {
        return this.getCurrentStudyYear(organisationId)
            .pipe(
                map(studyYear => studyYear.studyTerms
                    .flatMap(studyTerm => studyTerm.studyPeriods)
                    .find(studyPeriod => dateUtils.rangeContains(moment(), studyPeriod.valid))),
            );
    }

    getCurrentStudyTermOfStudyYear(studyYear: StudyYear): StudyTerm {
        const currentMoment = moment();
        return _.find(_.get(studyYear, 'studyTerms', []), term => dateUtils.rangeContains(currentMoment, term.valid));
    }

    getCurrentUniversityStudyTermIndex(currentStudyYear: StudyYear): number {
        const firstStudyTerm = _.first(currentStudyYear.studyTerms);
        const currentTermIsFirstTerm = moment().isBetween(firstStudyTerm.valid.startDate, firstStudyTerm.valid.endDate, null, '[)');
        return currentTermIsFirstTerm ? 0 : 1;
    }

    /**
     * Checks if the given study year has several study periods with the same name **in the current UI language**.
     * If found, adds specifiers to their names, so that all periods have a unique name (given that there's no
     * more than two duplicates, which shouldn't happen).
     */
    addSpecifiersToDuplicatePeriodNames(studyYear: StudyYear): StudyPeriodWithUniqueName[] {
        const periods: StudyPeriodWithUniqueName[] = studyYear.studyTerms
            .flatMap(term => term.studyPeriods)
            .map(period => ({ ...period, uniqueName: this.localeService.localize(period.name) }));

        const periodsByName = groupBy(periods, period => period.uniqueName);
        Object.values(periodsByName)
            .filter(period => period.length > 1)
            .forEach(periodsWithDuplicateNames => {
                const first = periodsWithDuplicateNames[0];
                const last = periodsWithDuplicateNames[periodsWithDuplicateNames.length - 1];
                first.uniqueName = `${first.uniqueName} ${this.transloco.translate('STUDY_PERIODS.NAME_SPECIFIER_FIRST')}`;
                last.uniqueName = `${last.uniqueName} ${this.transloco.translate('STUDY_PERIODS.NAME_SPECIFIER_LAST')}`;
            });

        return periods;
    }
}

type StudyYearsState = EntityState<StudyYear>;

@StoreConfig({ name: 'study-years' })
class StudyYearsStore extends EntityStore<StudyYearsState> {}

class StudyYearsQuery extends QueryEntity<StudyYearsState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: StudyYearsStore) {
        super(store);
    }
}
