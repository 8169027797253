import angular from 'angular';
import * as _ from 'lodash-es';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { commonCourseUnitServiceModule } from '../service/courseUnit.service';
import { commonMessageServiceModule } from '../service/message.service';
import { commonModuleServiceModule } from '../service/module.service';
import { ModuleBoxComponent } from '../module/module-box/module-box.component.ts';
import { CourseUnitBoxComponent } from '../courseUnit/course-unit-box/course-unit-box.component.ts';
import { localizedMarkupStringFilterModule } from '../string/localizedMarkupString.filter';
import messageTpl from './message.tpl.html';
import '../courseUnit/courseUnit.component';
import '../model/message.model';
(function () {
  messageController.$inject = ["$state", "$log", "$window", "$q", "commonMessageService", "errorService", "localeService", "commonModuleService", "commonCourseUnitService", "defaultPromiseHandler", "MessageType", "messageJSDataModel"];
  angular.module('sis-components.messenger.message', [commonMessageServiceModule, 'sis-components.constant.messageTypes', errorServiceModule, localeServiceModule, 'sis-components.model.message', commonModuleServiceModule, 'sis-components.courseUnit', commonCourseUnitServiceModule, 'sis-components.model.message', ModuleBoxComponent.downgrade.moduleName, CourseUnitBoxComponent.downgrade.moduleName, localizedMarkupStringFilterModule]).component('message', {
    template: messageTpl,
    bindings: {
      messageId: '<',
      returnRoute: '<',
      removeSenderStudentNumber: '<'
    },
    controller: messageController
  });
  function messageController($state, $log, $window, $q, commonMessageService,
  // NOSONAR
  errorService, localeService, commonModuleService, commonCourseUnitService, defaultPromiseHandler, MessageType, messageJSDataModel) {
    const $ctrl = this;
    $ctrl.openInPlanStructureRoute = _.get($state, 'current.data.openInPlanStructureRoute');
    $ctrl.openInPlanStructureHref = _.get($state, 'current.data.openInPlanStructureHref');
    $ctrl.openInPlanTutoringRoute = _.get($state, 'current.data.openInPlanTutoringRoute');
    $ctrl.openInPlanTutoringHref = _.get($state, 'current.data.openInPlanTutoringHref');
    const applicationUrlsByMessageType = {
      [MessageType.PRIOR_LEARNING_INCLUSION_APPLICATION_MESSAGE]: _.get($state, 'current.data.openPriorLearningApplicationHref'),
      [MessageType.PRIOR_LEARNING_SUBSTITUTION_APPLICATION_MESSAGE]: _.get($state, 'current.data.openPriorLearningApplicationHref'),
      [MessageType.MODULE_ATTAINMENT_APPLICATION_MESSAGE]: _.get($state, 'current.data.openModuleAttainmentMessageHref'),
      [MessageType.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION_MESSAGE]: _.get($state, 'current.data.openDegreeProgrammeAttainmentMessageHref'),
      [MessageType.CUSTOM_ATTAINMENT_APPLICATION_MESSAGE]: _.get($state, 'current.data.openCustomAttainmentMessageHref'),
      [MessageType.STUDY_RIGHT_EXTENSION_APPLICATION_MESSAGE]: _.get($state, 'current.data.openStudyRightExtensionApplicationHref'),
      [MessageType.CUSTOM_MODULE_CONTENT_APPLICATION_MESSAGE]: _.get($state, 'current.data.openModuleContentApplicationHref'),
      [MessageType.REQUIRED_MODULE_CONTENT_APPLICATION_MESSAGE]: _.get($state, 'current.data.openModuleContentApplicationHref')
    };
    $ctrl.$onInit = function () {
      return $ctrl.loadMessage($ctrl.messageId, $ctrl.removeSenderStudentNumber);
    };
    $ctrl.loadMessage = function (messageId, removeSenderStudentNumber = false) {
      return commonMessageService.findMessage(messageId).then(message => {
        $ctrl.message = message;
        if (removeSenderStudentNumber) {
          message.senderStudentNumber = null;
        }
        if (!message.isRead) {
          markAsReadByMe(message);
        }
        if ($ctrl.isSuggestionForAddition(message)) {
          commonModuleService.findById(message.parentModuleId).then(module => {
            $ctrl.suggestionForAdditionParentModule = module;
          });
        }
        if (message.type === MessageType.MANUAL_COURSE_UNIT_REALISATION_MESSAGE || message.type === MessageType.AUTOMATIC_COURSE_UNIT_REALISATION_MESSAGE) {
          messageJSDataModel.loadRelations(message, ['courseUnitRealisation']);
        }
        return $ctrl.getMessageTargetName($ctrl.message).then(targetName => {
          $ctrl.messageTargetName = targetName;
        });
      }).catch(err => {
        $log.error(err);
        $state.go('^.list', {}, {
          reload: false
        });
        errorService.showSystemError(err);
      });
    };
    $ctrl.getMessageTypeTranslationKey = function (message) {
      return commonMessageService.getMessageTypeTranslationKey(message);
    };
    $ctrl.getMessageTargetName = message => {
      if (message.moduleId) {
        return commonModuleService.findById(message.moduleId).then(module => localeService.getLocalizedValue(module.name));
      }
      if (message.courseUnitId) {
        return commonCourseUnitService.findById(message.courseUnitId).then(courseUnit => localeService.getLocalizedValue(courseUnit.name));
      }
      if (message.customStudyDraft) {
        return $q.when(message.customStudyDraft.name);
      }
      return $q.when(null);
    };
    function markAsReadByMe(message) {
      commonMessageService.markAsRead(message.id).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    $ctrl.isSuggestionForAddition = function (message) {
      return _.isObject(message) && message.operationType === 'SUGGEST_ADD' && (!_.isEmpty(message.courseUnitId) || !_.isEmpty(message.moduleId));
    };
    $ctrl.openMessageInPlan = function (message) {
      if ($ctrl.openInPlanStructureHref && message.isModuleContentApplicationMessage) {
        $window.location = $ctrl.openInPlanStructureHref.replace(':studentId', _.get(message, 'studentId')).replace(':planId', _.get(message, 'planId')).replace(':moduleContentApprovalId', `moduleContentApprovalId=${_.get(message, 'applicationId')}`).replace('openUnplanned', '');
      } else if ($ctrl.openInPlanStructureHref && $ctrl.isApplicationMessage(message)) {
        $window.location = $ctrl.openInPlanStructureHref.replace(':studentId', _.get(message, 'studentId')).replace(':planId', _.get(message, 'planId'));
      } else if ($ctrl.openInPlanTutoringHref && (message.isPlanModuleComment || message.isPlanCourseUnitComment || message.isPlanGenericComment || message.isPlanCustomStudyDraftComment)) {
        $window.location = $ctrl.openInPlanTutoringHref.replace(':studentId', _.get(message, 'studentId')).replace(':planId', _.get(message, 'planId'));
      } else if ($ctrl.openInPlanStructureRoute && message.isModuleContentApplicationMessage) {
        $state.go($ctrl.openInPlanStructureRoute, {
          planId: _.get(message, 'planId'),
          personId: _.get(message, 'studentId'),
          moduleContentApprovalId: _.get(message, 'applicationId')
        });
      } else if ($ctrl.openInPlanTutoringRoute && (message.isPlanModuleComment || message.isPlanCourseUnitComment || message.isPlanGenericComment || message.isPlanCustomStudyDraftComment)) {
        $state.go($ctrl.openInPlanTutoringRoute, {
          planId: _.get(message, 'planId'),
          personId: _.get(message, 'studentId')
        });
      } else {
        $log.error('Could not determine correct destination plan view');
      }
    };
    $ctrl.goToApplication = message => {
      if ($ctrl.isStateInternal('staff.logged-in.transactions.studentApplications.studentApplication')) {
        $state.go('staff.logged-in.transactions.studentApplications.studentApplication', {
          studentApplicationId: message.applicationId
        });
      } else if (applicationUrlsByMessageType[message.type]) {
        $window.location = applicationUrlsByMessageType[message.type].replace(':applicationId', message.applicationId);
      }
    };
    $ctrl.goToCalendar = function () {
      $state.go('student.logged-in.calendar');
    };
    $ctrl.goToCourseUnitEvaluation = function () {
      $state.go('staff.logged-in.studies.courseunit.view.evaluation', {
        courseUnitId: $ctrl.message.courseUnitId
      });
    };
    $ctrl.openAttainment = function () {
      $state.go('student.logged-in.profile.attainments');
    };
    $ctrl.isStateInternal = function (routeName) {
      return !!$state.get(routeName);
    };
    $ctrl.isPriorLearningApplicationMessage = function (message) {
      return message && (message.isPriorLearningInclusionApplicationMessage || message.isPriorLearningSubstitutionApplicationMessage);
    };
    $ctrl.isModuleAttainmentApplicationMessage = function (message) {
      return message && message.isModuleAttainmentApplicationMessage;
    };
    $ctrl.isDegreeProgrammeAttainmentApplicationMessage = function (message) {
      return message && message.isDegreeProgrammeAttainmentApplicationMessage;
    };
    $ctrl.isCustomAttainmentApplicationMessage = function (message) {
      return message && message.isCustomAttainmentApplicationMessage;
    };
    $ctrl.isModuleContentApplicationMessage = function (message) {
      return message && message.isModuleContentApplicationMessage;
    };
    $ctrl.isStudyRightExtensionApplicationMessage = function (message) {
      return message && message.isStudyRightExtensionApplicationMessage;
    };
    $ctrl.isApplicationMessage = function (message) {
      return message && Object.keys(applicationUrlsByMessageType).includes(message.type);
    };
    $ctrl.isManualCourseUnitRealisationMessage = function (message) {
      return message && message.isManualCourseUnitRealisationMessage;
    };
    $ctrl.isAutomaticCourseUnitRealisationMessage = function (message) {
      return message && message.isAutomaticCourseUnitRealisationMessage;
    };
    $ctrl.isCourseUnitManualEvaluationRequiredMessage = function (message) {
      return message && message.isCourseUnitManualEvaluationRequiredMessage;
    };
    $ctrl.isCourseUnitRealisationMessage = function (message) {
      return message && ($ctrl.isManualCourseUnitRealisationMessage(message) || $ctrl.isAutomaticCourseUnitRealisationMessage(message));
    };
    $ctrl.closeMessage = function () {
      if ($ctrl.returnRoute) {
        $state.go($ctrl.returnRoute);
      } else {
        $state.go('^.list', {}, {
          notify: true,
          reload: false
        });
      }
    };
    $ctrl.showSenderFullName = () => !$ctrl.isAutomaticCourseUnitRealisationMessage($ctrl.message) && !$ctrl.isCourseUnitManualEvaluationRequiredMessage($ctrl.message);
    $ctrl.showPlanMessageButton = () => !$ctrl.isApplicationMessage($ctrl.message) && !$ctrl.isCourseUnitRealisationMessage($ctrl.message) && !$ctrl.isCourseUnitManualEvaluationRequiredMessage($ctrl.message);
  }
})();