import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { StudyYear } from 'common-typescript/types';
import moment from 'moment';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Option } from '../../../select/combobox/combobox.component';
import { StudyYearsEntityService } from '../../../service/study-years-entity.service';
import { SearchService } from '../../search.service';

/**
 * A search filter component which allows selecting study periods from the current and upcoming study year.
 * Past periods are not included. If {@link SearchService} has been configured to allow university selection,
 * the selectable periods will change when the selected university changes.
 *
 * The search filter values emitted by this component are the validity periods of the selected study periods.
 */
@Component({
    selector: 'sis-search-filter-study-periods',
    templateUrl: './search-filter-study-periods.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterStudyPeriodsComponent {

    @Input({ required: true }) key: string;

    @Input({ required: true }) label: string;

    private readonly searchService = inject(SearchService);
    private readonly studyYearsService = inject(StudyYearsEntityService);

    options$: Observable<Option[]> = this.searchService.universityOrgId$
        .pipe(
            switchMap(universityOrgId => this.studyYearsService.getCurrentAndUpcomingStudyYears(universityOrgId)
                .pipe(
                    catchError(e => {
                        console.error(`Error fetching study years for university ${universityOrgId}`, e);
                        return of([]);
                    }),
                )),
            map(studyYears => studyYears.flatMap(studyYear => this.toOptions(studyYear))),
        );

    private toOptions(studyYear: StudyYear): Option[] {
        return this.studyYearsService.addSpecifiersToDuplicatePeriodNames(studyYear)
            .filter(period => !dateUtils.isRangeBefore(moment(), period.valid))
            .map(period => ({
                value: period.valid,
                label: `${studyYear.name}: ${period.uniqueName}`,
            }));
    }
}
