import angular from 'angular';
import * as _ from 'lodash-es';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { searchAndResultsModule } from './searchAndResults.dir';
import { searchParameterServiceModule } from './searchParameters';
import searchResultInfoTpl from './searchResultInfo.tpl.html';
export const searchResultInfoModule = 'sis-components.search.searchResultInfo';
(function () {
  searchResultInfoCtrl.$inject = ["$log", "searchDefaults", "$scope"];
  angular.module(searchResultInfoModule, [searchAndResultsModule, searchParameterServiceModule, errorServiceModule]).component('searchResultInfo', {
    bindings: {
      searchResult: '<',
      searchParams: '<',
      toggleShowMaxResults: '&'
    },
    template: searchResultInfoTpl,
    controller: searchResultInfoCtrl
  });

  /**
   * @ngInject
   */
  function searchResultInfoCtrl($log, searchDefaults, $scope) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.pageSize = searchDefaults.limit;
      $ctrl.showMaxResults = false;
    };
    $ctrl.$onChanges = () => {
      $scope.$evalAsync(() => {
        $ctrl.totalCount = _.get($ctrl.searchResult, 'total');
        $ctrl.count = _.size(_.get($ctrl.searchResult, 'searchResults'));
        $ctrl.truncated = _.get($ctrl.searchResult, 'truncated');
        if (_.has($ctrl.searchParams, 'showMaxResults.displayValue')) {
          $ctrl.showMaxResults = $ctrl.searchParams.showMaxResults.displayValue();
        }
      });
    };
  }
})();