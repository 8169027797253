import angular from 'angular';
import * as _ from 'lodash-es';
import { enrolmentQuestionnaireQuestionComponentModule } from './enrolmentQuestionnaireQuestion.component';
import { commonEnrolmentQuestionnaireServiceModule } from '../service/enrolmentQuestionnaire.service';
import enrolmentQuestionnaireModalTpl from './enrolmentQuestionnaireModal.tpl.html';
export const enrolmentQuestionnaireModalModule = 'sis-components.enrolmentQuestionnaireModal';
(function () {
  enrolmentQuestionnaireModalController.$inject = ["commonEnrolmentQuestionnaireService"];
  angular.module(enrolmentQuestionnaireModalModule, [enrolmentQuestionnaireQuestionComponentModule, commonEnrolmentQuestionnaireServiceModule]).component('enrolmentQuestionnaireModal', {
    bindings: {
      resolve: '<',
      close: '&'
    },
    template: enrolmentQuestionnaireModalTpl,
    controller: enrolmentQuestionnaireModalController
  });
  function enrolmentQuestionnaireModalController(commonEnrolmentQuestionnaireService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.enrolmentQuestionnaire = $ctrl.resolve.enrolmentQuestionnaire;
      $ctrl.enrolmentQuestionnaireAnswers = $ctrl.resolve.enrolmentQuestionnaireAnswers;
      $ctrl.courseUnitRealisation = $ctrl.resolve.courseUnitRealisation;
      $ctrl.hasQuestions = _.get($ctrl, 'enrolmentQuestionnaire.enrolmentQuestions', []).length > 0;
      const languageQuestion = _.find(_.get($ctrl, 'enrolmentQuestionnaire.enrolmentQuestions', []), {
        questionType: 'LANGUAGE_SELECT'
      });
      $ctrl.languageQuestion = commonEnrolmentQuestionnaireService.constructLanguageSelectQuestionForStudent(languageQuestion, $ctrl.resolve.courseUnit);
      $ctrl.otherQuestionIds = _.map(_.filter(_.get($ctrl, 'enrolmentQuestionnaire.enrolmentQuestions', []), question => question.questionType !== 'LANGUAGE_SELECT'), 'localId');
    };
    $ctrl.getAnswer = function (questionId) {
      return _.find(_.get($ctrl.enrolmentQuestionnaireAnswers, 'answers', []), answer => answer.questionId === questionId);
    };
    $ctrl.cancel = function () {
      $ctrl.close();
    };
    $ctrl.getQuestion = function (questionId) {
      return _.find(_.get($ctrl.enrolmentQuestionnaire, 'enrolmentQuestions', []), question => questionId === question.localId);
    };
    $ctrl.getQuestionIndex = function (questionId) {
      if (!_.includes($ctrl.otherQuestionIds, questionId)) {
        return undefined;
      }
      return $ctrl.otherQuestionIds.indexOf(questionId) + 1;
    };
  }
})();