<label class="control control-checkbox sis-checkbox" (click)="$event.stopPropagation();">
    <input type="checkbox"
           [attr.aria-posinset]="ariaPosinset"
           [attr.aria-setsize]="ariaSetsize"
           [attr.aria-checked]="checked"
           [attr.role]="ariaRole"
           [attr.aria-labelledby]="ariaLabelledBy"
           [attr.aria-describedby]="ariaDescribedBy"
           [attr.name]="name"
           [name]="name"
           [ngModel]="checked"
           (ngModelChange)="checkedChange.emit($event)"
           [required]="required"
           [disabled]="disabled"
           [attr.aria-disabled]="ariaDisabled"
           [attr.id]="id"
           (click)="preventSelection($event)">
    <span class="control-indicator" [class.invalid]="invalid" [attr.aria-hidden]="true"></span>
    <span *ngIf="label || visuallyHiddenInfo">
      {{label | translate}} <span *ngIf="visuallyHiddenInfo" class="visually-hidden">{{visuallyHiddenInfo | translate}}</span>
      <span *ngIf="secondaryLabel" class="secondary-label">
        {{secondaryLabel | translate}}
      </span>
      <ng-content select="sis-tiny-badge"></ng-content>
    </span>
</label>
