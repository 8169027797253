import { inject, Injectable } from '@angular/core';
import { PlanValidationResult, PlanValidationTs, ValidatablePlan } from 'common-typescript';
import {
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    DegreeProgramme,
    EntityWithRule,
    GradeAverageCalculationMethod,
    GradeAverageCalculationResult,
    GradeScale,
    Module,
    OtmId,
    PlanValidationState,
    StudyModule,
    StudyYear,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { firstValueFrom, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { CreditRangePipe } from '../number/credit-range.pipe';
import { CommonGradeAverageService } from '../service/common-grade-average.service';
import { GradeScaleEntityService } from '../service/grade-scale-entity.service';
import { StudyYearsEntityService } from '../service/study-years-entity.service';
import { UniversityService } from '../service/university.service';

@Injectable({
    providedIn: 'root',
})
export class StudyProgressGraphResultService {

    private studyProgressGraphResults: StudyProgressGraphResults = {};
    private ATTAINMENT_YEARLY_TARGET_VALUE = 60;

    private readonly gradeScaleEntityService = inject(GradeScaleEntityService);
    private readonly commonGradeAverageService = inject(CommonGradeAverageService);
    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly studyYearsEntityService = inject(StudyYearsEntityService);
    private readonly universityService = inject(UniversityService);
    private readonly creditRangePipe = inject(CreditRangePipe);

    async createResults(validatablePlan: ValidatablePlan, module: Module, skipAverageAndGoalCalc?: boolean): Promise<StudyProgressGraphResults> {
        if (!validatablePlan || !module) {
            return null;
        }
        const plannedModule = this.getPlannedModule(validatablePlan.modulesById, module.groupId);
        const relevantAttainments = validatablePlan.getAllCourseUnitAndCustomCourseUnitAttainmentsUnderModule(plannedModule?.id);
        const planValidationResult: PlanValidationResult = PlanValidationTs.validatePlan(validatablePlan);
        const moduleValidationResult = this.getModuleValidationResult(plannedModule?.id, planValidationResult);

        if (relevantAttainments?.length === 0 && !moduleValidationResult) {
            return null;
        }

        if (!skipAverageAndGoalCalc) {
            const [gradeAvg, studyYearAttGoal] = await Promise.all([
                firstValueFrom(this.calculateGradeAverageForModule(validatablePlan, module)),
                firstValueFrom(this.getStudyYearAttainmentGoal(relevantAttainments)),
            ]);
            this.studyProgressGraphResults.average = gradeAvg;
            this.studyProgressGraphResults.yearAttainmentGoal = {
                from: studyYearAttGoal,
                to: this.ATTAINMENT_YEARLY_TARGET_VALUE,
            };
        }
        this.createPlannedAndAttained(plannedModule, moduleValidationResult);

        this.studyProgressGraphResults.planValidationResult = planValidationResult;
        this.studyProgressGraphResults.moduleValidationResult = moduleValidationResult;
        return this.studyProgressGraphResults;
    }

    createPlannedAndAttained(plannedModule: (DegreeProgramme | StudyModule),
                             moduleValidationResult: { [id: string]: any }) {
        this.studyProgressGraphResults = {
            ...this.studyProgressGraphResults,
            planned: {
                from: this.getPlannedCredits(moduleValidationResult),
                to: plannedModule.targetCredits.min,
                percentage: this.percentage(moduleValidationResult.plannedCredits.min,
                                            plannedModule.targetCredits.min),
            },
            attained: {
                from: moduleValidationResult.attainedCredits,
                to: plannedModule.targetCredits.min,
                percentage: this.percentage(moduleValidationResult.attainedCredits,
                                            plannedModule.targetCredits.min),
            },
        };
    }

    getStudyYearAttainmentGoal(relevantAttainments: (CourseUnitAttainment | CustomCourseUnitAttainment)[]): Observable<number> {
        return this.studyYearsEntityService.getCurrentStudyYear(this.universityService.getCurrentUniversityOrgId())
            .pipe(
                map((studyYear: StudyYear) => {
                    const thisYearsAttainments = relevantAttainments.filter(att => moment(att.attainmentDate)
                        .isBetween(studyYear.valid.startDate, studyYear.valid.endDate, 'days', '[)'));
                    return _.sumBy(thisYearsAttainments, 'credits');
                }),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    calculateGradeAverageForModule(validatablePlan: ValidatablePlan, module: Module): Observable<number> {
        return this.gradeScaleEntityService.getById('sis-0-5')
            .pipe(
                map(gradeScale => this.getGradeAverageResult(validatablePlan, module, gradeScale)),
                map((gradeAverageCalculationResult: GradeAverageCalculationResult) => {
                    const average = gradeAverageCalculationResult?.gradeAverage;
                    if (_.isNumber(average)) {
                        return _.round(average, 2);
                    }
                }),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    private getPlannedModule(modulesById: { [id: string]: EntityWithRule }, moduleGroupId: OtmId): (DegreeProgramme | StudyModule) {
        for (const value of Object.values(modulesById)) {
            if (value?.groupId === moduleGroupId) {
                return value as (DegreeProgramme | StudyModule);
            }
        }
    }

    private getGradeAverageResult(validatablePlan: ValidatablePlan, module: Module, gradeScale: GradeScale): GradeAverageCalculationResult {
        const attainmentIds: string[] = this.commonGradeAverageService.getAttainmentIdsForModule(module, validatablePlan).sort();
        const allAttainments = _.orderBy(_.values(validatablePlan.attainmentsById), 'id');
        const method: GradeAverageCalculationMethod = 'COURSE_UNIT_ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS';
        return this.commonGradeAverageService.calculateGradeAverage(attainmentIds, allAttainments, gradeScale, method);
    }

    private getModuleValidationResult(moduleId: OtmId, planValidationResult: PlanValidationResult) {
        if (!moduleId || !planValidationResult) {
            return null;
        }
        const moduleValidationResults = _.get(planValidationResult, 'moduleValidationResults');
        return _.get(moduleValidationResults, moduleId);
    }

    private percentage(n: number, m: number): number {
        if (_.isNil(n) || n === 0 || (n === 0 && m === 0)) {
            return 0;
        }
        if (_.isNil(m) || m === 0) {
            return 100;
        }
        return Math.floor(n / m * 100);
    }

    private getPlannedCredits(moduleValidationResult: { [id: string]: any }): string {
        return this.creditRangePipe.transform(moduleValidationResult.plannedCredits, 'SYMBOLS', true);
    }
}

/**
 * Contains study progress data for one of the root modules of a study plan (i.e. study module or degree programme
 * that is located directly under the education node in the plan structure). For educations with only one phase,
 * the result contains the study progress data for the whole plan (as there is only one root module).
 */
export interface StudyProgressGraphResults {
    /** Grade average */
    average?: number;
    /** Amount of credits that should be attained yearly */
    yearAttainmentGoal?: Range;
    /** Amount of planned credits */
    planned?: PlannedOrAttained;
    /** Amount of attained credits */
    attained?: PlannedOrAttained;
    /** Validation results for the plan in question */
    planValidationResult?: PlanValidationResult;
    /** Validation results for the study module / degree programme in question */
    moduleValidationResult?: { state: PlanValidationState };
}

interface PlannedOrAttained extends Range {
    percentage: number;
}

interface Range {
    from: number | string;
    to: number;
}
