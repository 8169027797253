<ng-container *transloco="let t">
  <nav role="navigation" class="top-navigation-icons" [attr.aria-label]="t('ARIA_LABEL.SECONDARY_MENU')">
    <ul class="top-navigation-icons__list" [class.top-navigation-icons__list__side]="navCollapsed">
      <ng-container *ngIf="authenticated() && frontendName !== 'ADMIN'"
                    [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'messages', mobileMode }"/>
      <ng-container *ngIf="authenticated() && frontendName === 'STUDENT'"
                    [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'notes', mobileMode }"/>
      <ng-container [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{ variant:'help', mobileMode }"/>
    </ul>
  </nav>
</ng-container>

<ng-template #listItem let-variant="variant" let-mobileMode="mobileMode">
  <li *ngIf="!(variant === 'help' && mobileMode)" class="top-navigation-icons__list__item">
    <sis-top-navigation-icon [variant]="variant"
                             [mobileMode]="mobileMode"
                             [initialFocus]="initialFocus && variant === 'open-university-cart'"
                             (keydownPressed)="keydownPressed.emit($event)"
                             (blurMobileMenuIcon)="blurMobileMenuIcons.emit($event)"/>
  </li>
</ng-template>
