import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
    Attainment,
    DegreeProgrammeAttainment,
    Education,
    LearningOpportunitySelectionPath,
    Module,
    OtmId,
    StudyModule,
    StudyRight,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { isDegreeProgrammeAttainment } from '../../attainment/AttainmentUtil';
import { ConnectionModel, ConnectionModelService } from '../../service/connection-model.service';
import { LearningOpportunityAllowedPathService } from '../../service/learning-opportunity-allowed-path.service';
import { PreviewModel, PreviewModelObject, PreviewModelService } from '../../service/preview-model.service';
import { PhaseId, StudyRightEntityService } from '../../service/study-right-entity.service';
import { StudyRightSelectionPathService } from '../../service/study-right-selection-path.service';

interface EducationOptionWrapper {
    option: PreviewModelObject;
    isActivePhase: boolean;
    isInLearningOpportunityAllowedPaths: boolean;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-study-right-education-structure-view',
    templateUrl: './study-right-education-structure-view.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudyRightEducationStructureViewComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.sis-study-right-education-structure-view',
        directiveName: 'sisStudyRightEducationStructureView',
    };

    constructor(
        private readonly _previewModelService: PreviewModelService,
        private readonly _connectionModelService: ConnectionModelService,
        private readonly _studyRightSelectionPathService: StudyRightSelectionPathService,
        private readonly _learningOpportunityAllowedPathService: LearningOpportunityAllowedPathService,
        private readonly _studyRightEntityService: StudyRightEntityService,
    ) { }

    /**
     * Determines expandable heading's semantic aria-level for screen readers, default is equivalent for h6
     */
    @Input() level?: number = 6;
    @Input() set attainments(attainments: Attainment[]) {
        this.dpAttainmentsByGroupId = {};
        attainments?.filter(att => isDegreeProgrammeAttainment(att))
            .forEach((att: DegreeProgrammeAttainment) => this.dpAttainmentsByGroupId[att.moduleGroupId] = att);
    }

    @Input() studyRight: StudyRight;
    @Input() education: Education;
    dpAttainmentsByGroupId: { [index: string]: Attainment } = {};
    previewModel: PreviewModel;
    connectionModel: ConnectionModel;
    leftOptions: EducationOptionWrapper[];
    rightOptions: EducationOptionWrapper[];

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.studyRight || changes.education) && this.studyRight && this.education) {
            this.previewModel = this._previewModelService.getPreviewModelForLearningOpportunity(this.education, this.studyRight);
            this.connectionModel = this._connectionModelService.getConnectionModel(this.previewModel, this.education, this.studyRight);
            const leftOptions = _.map(_.values(this.previewModel.left), option => this.getEducationOptionWrapper(option, 'phase1'));
            this.leftOptions = _.sortBy(leftOptions, 'option.index');
            const rightOptions = _.map(_.values(this.previewModel.right), option => this.getEducationOptionWrapper(option, 'phase2'));
            this.rightOptions = _.sortBy(rightOptions, 'option.index');
        }
    }

    toStudyModule(module: Module): StudyModule {
        return module as StudyModule;
    }

    private isActivePhase(phaseOption: PreviewModelObject, phaseId: PhaseId): boolean {
        return this._studyRightEntityService.isActivePhase(this.studyRight, phaseOption, phaseId);
    }

    private isModuleInLearningOpportunityAllowedPaths(moduleGroupId: OtmId, educationPathKey: keyof LearningOpportunitySelectionPath) {
        return this._learningOpportunityAllowedPathService.isModuleInLearningOpportunityAllowedPaths(
            this._studyRightSelectionPathService.getStudyRightSelectionPaths(this.education, this.studyRight),
            moduleGroupId,
            educationPathKey,
        );
    }

    private getEducationOptionWrapper(phaseOption: PreviewModelObject, phaseId: PhaseId): EducationOptionWrapper {
        return {
            option: phaseOption,
            isActivePhase: this.isActivePhase(phaseOption, phaseId),
            isInLearningOpportunityAllowedPaths: this.isModuleInLearningOpportunityAllowedPaths(phaseOption.moduleGroupId, phaseOption.educationPathKey),
        };
    }
}
