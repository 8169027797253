import {
    CourseUnitEnrolmentRight,
    CourseUnitRealisation,
    CourseUnitRealisationSeats,
    OpenUniversityProduct,
    OtmId,
} from 'common-typescript/types';

export interface ProductTeaching {
    [assessmentItemId: OtmId]: CourseUnitRealisation[];
}

export enum AggregateProductInfoType {
    /**
     * Information about an open university product that is currently on sale.
     * It is possible, that the product has already been purchased,
     * but this type of information is used in the context of "current products".
     */
    Current,
    /**
     * Information about an open university product that will be sold in the future.
     */
    Future,
    /**
     * Information about an open university product that has been purchased.
     * It is possible, that the product is also on sale,
     * but this type of information is used in the context of "purchased products".
     */
    Purchased,
}

export interface AggregateProductInfo extends OpenUniversityProduct {
    /**
     * Type of this information object, not the product itself.
     * The same product can be represented by several (different type of) info objects.
     */
    readonly type: AggregateProductInfoType;
    /** Is the product in the current users cart */
    readonly isInCart: boolean;
    /** Does the product have available seats remaining for open university students */
    readonly isFull: boolean;
    /** Is current organisation different than products organisation */
    readonly isCurrentOrgIdDifferentThanProductOrgId?: boolean;
    /** Current application organisation. Don't mix this with product.organisation */
    readonly currentOrgId?: string;
    /** Course unit realisations currently on sale for the product, grouped by assessment item */
    readonly teachingOnSale: ProductTeaching;
    /** Available seat counts for each course unit realisation */
    readonly curAvailableSeats: CourseUnitRealisationSeats[];
    /**
     * Latest valid enrolment right, when this info is in the context of "purchased products",
     * otherwise undefined.
     */
    readonly enrolmentRight?: CourseUnitEnrolmentRight;
    /**
     * Tells if the product was purchased earlier.
     * This is true for all "purchased products" (see {@link enrolmentRight}),
     * but can also be true for products that are currently on sale or in the future.
     */
    readonly isPurchased: boolean;
}
