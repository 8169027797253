import * as _ from 'lodash-es';
import { CommonGradeAverageService } from '../service/common-grade-average.service.ts';
import { IconComponent } from '../icon/icon.component.ts';
import printPlanTpl from './printPlan.tpl.html';
'use strict';
(function () {
  PrintPlanCtrl.$inject = ["$q", "$timeout", "$window", "printService", "commonStudentService", "commonGradeScaleService", "commonGradeAverageService"];
  angular.module('sis-components.printPlan', ['sis-components.printPlan.printService', 'sis-components.studyPeriod', 'sis-components.rangeFilter', 'sis-components.planRange', 'sis-components.service.studentService', 'sis-components.service.gradeScale', IconComponent.downgrade.moduleName, CommonGradeAverageService.downgrade.moduleName]).component('printPlan', {
    template: printPlanTpl,
    controller: PrintPlanCtrl,
    bindings: {
      validatablePlan: '<',
      planValidationResult: '<',
      stateNameToGoBack: '<?',
      stateParamsToGoBack: '<?'
    }
  });
  function PrintPlanCtrl($q, $timeout, $window, printService, commonStudentService, commonGradeScaleService, commonGradeAverageService) {
    const $ctrl = this;
    $ctrl.periodMap = {};
    $ctrl.rows = [];
    $ctrl.abbreviations = {};
    $ctrl.$onInit = function () {
      commonStudentService.findStudent($ctrl.validatablePlan.plan.userId).then(student => {
        $ctrl.userName = student.fullName;
        $ctrl.studentNumber = student.studentNumber;
        $timeout($ctrl.print);
      });
      $ctrl.stateNameToGoBack = $ctrl.stateNameToGoBack || '^';
      $ctrl.stateParamsToGoBack = $ctrl.stateParamsToGoBack || {};
    };
    $ctrl.$onChanges = function (changesObject) {
      if (changesObject.planValidationResult) {
        $ctrl.planValidationResult = changesObject.planValidationResult.currentValue;
      }
      if (changesObject.validatablePlan && changesObject.validatablePlan.currentValue) {
        $ctrl.allAttainments = _.values(changesObject.validatablePlan.currentValue.attainmentsById);
        $ctrl.name = _.get(changesObject.validatablePlan.currentValue, 'plan.name');
        printService.printablePlanStructure(changesObject.validatablePlan.currentValue, $ctrl.planValidationResult).then(rows => {
          $ctrl.rows = rows;
          getGradeScales(rows).then(() => {
            _.forEach($ctrl.rows, $ctrl.calculateGrades);
          });
        });
      }
    };
    $ctrl.calculateGrades = function (row) {
      const attainmentScale = _.get($ctrl.gradeScales, _.get(row, 'attainment.gradeScaleId'));
      if (attainmentScale) {
        const grade = _.get(attainmentScale.grades, row.attainment.gradeId);
        if (grade) {
          row.grade = _.get(grade, 'abbreviation');
        }
      }
      const method = 'COURSE_UNIT_AND_EMPTY_MODULE_ARITHMETIC_MEAN_WEIGHTED_BY_CREDITS';
      const gradeScale = $ctrl.gradeScales['sis-0-5'];
      let gradeAverageResult;
      if (_.includes(['ModuleAttainment', 'CustomModuleAttainment', 'DegreeProgrammeAttainment'], _.get(row, 'attainment.type'))) {
        gradeAverageResult = commonGradeAverageService.calculateGradeAverage([row.attainment.id], $ctrl.allAttainments, gradeScale, method);
      } else if (!_.get(row, 'attainment') && _.get($ctrl.validatablePlan.modulesById, row.id)) {
        // Do not try to calculate grade averages for other attainment types.
        // Should calculate only for non attained modules and degree programmes
        // Now we are still in 'hähmäinen' zone. row.id can be anything from moduleId, courseUnitId to attainmentId
        // But we assume that if some module has same id then row can not be else than a non attained module row.
        const module = _.get($ctrl.validatablePlan.modulesById, row.id);
        const attainmentIds = commonGradeAverageService.getAttainmentIdsForModule(module, $ctrl.validatablePlan);
        gradeAverageResult = commonGradeAverageService.calculateGradeAverage(attainmentIds, $ctrl.allAttainments, gradeScale, method);
      } else {
        return;
      }
      const gradeAverage = _.get(gradeAverageResult, 'gradeAverage');
      row.gradeAverage = _.isNumber(gradeAverage) ? +gradeAverage.toFixed(2) : undefined;
    };
    $ctrl.print = function () {
      $window.print();
    };
    $ctrl.indent = function (indent) {
      return `indent-${indent}`;
    };
    $ctrl.hasTiming = function (item) {
      return !!(item && Array.isArray(item) && item.length > 0);
    };
    function getGradeScales(rows) {
      const attainments = _.compact(_.map(rows, 'attainment'));
      return $q.all([commonGradeScaleService.getGradeScales(attainments),
      // Ensure existence of sis-0-5
      commonGradeScaleService.getGradeScale('sis-0-5')]).then(result => {
        // First promise resolves into OtmId - GradeScale map and second into GradeScale
        const gradeScales = result[0];
        gradeScales['sis-0-5'] = result[1];
        $ctrl.gradeScales = gradeScales;
      });
    }
  }
})();