import {
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { CommonSearchFilters, OtmId, SearchParameters } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoint, BreakpointService } from '../service/breakpoint.service';

import { SearchFilterDirective } from './search-filters/search-filter.directive';
import { countSelectedFilters, isSearchValid } from './search-utils';
import { SearchService } from './search.service';

@Component({
    selector: 'sis-search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {

    /**
     * Wrap each desired search filter component with `<ng-template sisSearchFilter>...</ng-template>`, e.g.
     * ```
     * <sis-search>
     *   <ng-template sisSearchFilter>
     *     <sis-search-filter-x/>
     *   </ng-template>
     *   <ng-template sisSearchFilter>
     *     <sis-search-filter-y/>
     *   </ng-template>
     *   ...
     * </sis-search>
     * ```
     */
    @ContentChildren(SearchFilterDirective) searchFilterTemplates: SearchFilterDirective[];

    /**
     * Shows or hides the search text input field. Defaults to true.
     */
    @Input() isTextSearchEnabled = true;

    /**
     * Optional custom translation key or text to be used as the aria-label of the search button
     */
    @Input() ariaLabel? = 'ARIA_LABEL.SEARCH';

    /**
     * Optional custom translation key or text to be used as the guidance of the text search input
     */
    @Input() guidance? = 'SEARCH.INPUT_GUIDANCE';

    /**
     * Emits stored parameters on subscription and on every change. Skips emitting when invalid search parameter
     * selections.
     */
    @Output() searchTrigger = new EventEmitter<SearchParameters>();

    @ViewChild('fullTextQueryInput') set fullTextQueryElement(element: ElementRef) {
        if (element) {
            this._fullTextQueryElement = element;
        }
    }

    private _fullTextQueryElement: ElementRef;
    private breakpointService = inject(BreakpointService);
    private destroyRef = inject(DestroyRef);
    /**
     * A view specific instance of {@link SearchService}.
     */
    protected readonly searchService: SearchService<CommonSearchFilters> = inject(SearchService);
    private translocoService = inject(TranslocoService);

    isMobileView$: Observable<boolean>;
    searchFilterCount$: Observable<number>;

    ngOnInit() {
        this.isMobileView$ = this.breakpointService.breakpoint$
            .pipe(map(breakpoint => breakpoint < Breakpoint.MD));

        this.searchFilterCount$ = this.searchService.searchParameters$
            .pipe(map(searchParameters => countSelectedFilters(searchParameters)));

        this.searchService.searchParameters$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(parameters => this.handleSearch(parameters));
    }

    private handleSearch(searchParameters: SearchParameters) {
        if (isSearchValid(searchParameters)) {
            this.searchTrigger.emit(searchParameters);
        }
    }

    searchButtonTriggered() {
        this.searchService.patchFilters({
            fullTextQuery: this.fullTextQueryValue(),
        });
    }

    clearSearchFilters(parameters: SearchParameters) {
        this.searchService.updateFilters({
            fullTextQuery: parameters?.filters?.fullTextQuery,
            universityOrgId: parameters?.filters?.universityOrgId,
        });
    }

    private fullTextQueryValue() {
        return this._fullTextQueryElement?.nativeElement?.value || undefined;
    }

    searchFiltersTitle(count: number): string {
        const titleText = this.translocoService.translate('SEARCH.SEARCH_FILTERS');
        const countText = this.translocoService.translate('SEARCH.SEARCH_FILTER_COUNT', { count });
        if (count > 0) {
            return `${titleText} ${countText}`;
        }
        return titleText;
    }

    selectUniversity(universityOrgId: OtmId) {
        // many filters depend on selected university, so clear selections
        this.searchService.updateFilters({
            fullTextQuery: this.fullTextQueryValue(),
            universityOrgId,
        });
    }
}
