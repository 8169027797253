import angular from 'angular';
import angularTranslate from 'angular-translate';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { environmentNgjsServiceModule } from 'sis-common/environmentService/environmentNgjs.service';
import { take } from 'rxjs/operators';
import { CustomAttainmentCreditInfoModalComponent } from '../custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component.ts';
import { AttainmentEditModalService } from '../list/attainment-edit-modal.service.ts';
import customCourseUnitAttainmentBoxTpl from './customCourseUnitAttainmentBox.tpl.html';
(function () {
  CustomCourseUnitBoxController.$inject = ["$log", "$translate", "modalService", "attainmentEditModalService", "localeService", "colorService", "environmentNgjsService"];
  angular.module('sis-components.attainment.customCourseUnitAttainmentBox', ['sis-components.service.colorService', 'sis-components.date.localDateFormatFilter', environmentNgjsServiceModule, IconComponent.downgrade.moduleName, angularTranslate, localeServiceModule, TooltipComponent.downgrade.moduleName, ModalService.downgrade.moduleName, AttainmentEditModalService.downgrade.moduleName]).component('customCourseUnitAttainmentBox', {
    template: customCourseUnitAttainmentBoxTpl,
    transclude: true,
    bindings: {
      attainment: '<',
      planValidationResult: '<?',
      grade: '<',
      expiryDate: '<?',
      dimmed: '<?',
      selected: '<?',
      validatablePlan: '<?',
      showCourseUnitAttainmentNotification: '<?'
    },
    controller: CustomCourseUnitBoxController
  });
  function CustomCourseUnitBoxController($log, $translate, modalService, attainmentEditModalService, localeService, colorService, environmentNgjsService) {
    const $ctrl = this;
    $ctrl.openInfoModal = function () {
      return modalService.open(CustomAttainmentCreditInfoModalComponent, $ctrl.attainment, {
        closeWithOutsideClick: true,
        size: 'lg'
      });
    };
    $ctrl.openAttainmentDetailsModal = function () {
      return attainmentEditModalService.openAttainmentDetailsModal($ctrl.attainment).pipe(take(1)).subscribe();
    };
    $ctrl.$onChanges = () => {
      const customCourseUnitAttainmentId = _.get($ctrl.attainment, 'id');
      const customCourseUnitAttainmentValidationResults = _.get($ctrl.planValidationResult, 'customCourseUnitAttainmentValidationResults');
      $ctrl.customCourseUnitAttainmentValidationResult = _.get(customCourseUnitAttainmentValidationResults, customCourseUnitAttainmentId);
      setTitleAriaLabel();
    };
    $ctrl.isInvalidSelection = () => _.get($ctrl.customCourseUnitAttainmentValidationResult, 'invalidSelection') === true;
    $ctrl.isInvalidAccordingToModuleContentApproval = () => _.get($ctrl.customCourseUnitAttainmentValidationResult, 'invalidAccordingToModuleContentApproval') === true;
    $ctrl.hasModuleContentApproval = () => _.get($ctrl.customCourseUnitAttainmentValidationResult, 'invalidAccordingToModuleContentApproval') !== undefined;
    $ctrl.getColorCategory = () => {
      try {
        const colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, $ctrl.attainment.id);
        return colorCategory ? `cu-color-${colorCategory}` : 'cu-color-0';
      } catch (e) {
        $log.error(`Failed to getCourseUnitColorCategory: ${e}`);
        return undefined;
      }
    };
    $ctrl.isStudentPage = () => environmentNgjsService.getFrontendName() === 'STUDENT';
    function setTitleAriaLabel() {
      // The attainment is not available if it has expired; we don't need the aria-label in that case
      if (!$ctrl.attainment) {
        return;
      }
      const attainmentName = localeService.getLocalizedValue($ctrl.attainment.name);
      const attainmentType = $translate.instant('SIS_COMPONENTS.PLAN.STUDY_TOOLTIP.CUSTOM_ATTAINMENT');
      $ctrl.titleAriaLabel = `${attainmentName}, ${attainmentType}`;
    }
  }
})();