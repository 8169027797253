import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FrontpageComponentName } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { UuidService } from 'sis-common/uuid/uuid.service';

export interface WidgetOption {
    /** Component itself */
    component: any,
    /** Use component selector name */
    value: FrontpageComponentName,
    /** The label key that will be shown for this option in the UI */
    labelKey: string,
}

@Component({
    selector: 'app-select-widgets-dropdown',
    templateUrl: './select-widgets-dropdown.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SelectWidgetsDropdownComponent implements OnInit, OnChanges {
    @Input() id: string;
    @Input() options: WidgetOption[] = [];
    @Input() selected: WidgetOption[] = [];
    @Output() optionChange = new EventEmitter<WidgetOption[]>();

    filteredItems: any[] = [];
    showDropdown = false;
    onFocus = false;

    @ViewChild('buttonElement', { read: ElementRef }) private buttonElement: ElementRef;
    @ViewChild('optionMenuElement') private optionMenuElement: ElementRef;

    constructor(private changeDetectorRef: ChangeDetectorRef, private uuidService: UuidService) {
    }

    ngOnInit() {
        this.id = this.id ? this.id : this.uuidService.randomUUID();
        this.filteredItems = _.cloneDeep(this.options);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.selected && changes.selected.previousValue) && !_.isEqual(changes.selected.previousValue, changes.selected.currentValue)) {
            this.selected = _.cloneDeep(changes.selected.currentValue);
        }
    }

    removeOption(option: WidgetOption): void {
        this.selected = this.selected.filter(item => option.value !== item.value);
    }

    select(option: WidgetOption): void {
        if (this.isChecked(option)) {
            this.removeOption(option);
        } else {
            this.selected.push(option);
        }
        this.optionChange.emit(this.selected);
    }

    isChecked(option: WidgetOption): boolean {
        return this.selected.some(e => e.value === option.value);
    }

    toggleMenu(isOpen?: boolean): void {
        if (this.showDropdown === isOpen) {
            return;
        }
        const oldState = this.showDropdown;
        this.showDropdown = isOpen !== undefined ? isOpen : !this.showDropdown;
        if (!oldState && this.showDropdown) {
            // when the menu is opened, move the focus to the first element
            setTimeout(() => {
                const input = this.optionMenuElement?.nativeElement?.firstElementChild;
                input?.querySelector('input')?.focus();
            });
        }
        this.changeDetectorRef.detectChanges();
    }

    /* Component focus handling */
    @HostListener('document:keyup', ['$event'])
    @HostListener('document:click', ['$event'])
    focusHandler(event: any) {
        if (this.isFocused(event)) {
            this.toggleFocus(true);
        } else {
            this.toggleFocus(false);
            this.toggleMenu(false);
        }
    }

    toggleFocus(onFocus?: boolean): void {
        this.onFocus = onFocus !== undefined ? onFocus : !this.onFocus;
    }

    isFocused(event: any): boolean {
        const element = document.getElementById(this.id);
        return element ? element.contains(event.target) : false;
    }

    /* Component keydown handling */
    @HostListener('keydown', ['$event'])
    private keydownHandler(event: any): void {
        if (event.key === 'Escape') this.toggleMenu(false);
        if (event.key !== 'Tab' || event.key !== 'Shift') {
            if (this.optionMenuElement?.nativeElement?.contains(event.target)) {
                this.menuKeypressHandler(event);
            }
            if ((event.key === 'ArrowDown') && this.buttonElement.nativeElement.contains(event.target)) {
                if (!this.showDropdown) {
                    event.preventDefault();
                    this.toggleMenu();
                }
            }
        }
    }

    private menuKeypressHandler(event: any): void {
        const parent = event.target.closest('li');
        switch (event.key) {
            case 'ArrowDown':
                if (event.target.id !== `option-menu-${this.id}`
                && parent.nextElementSibling) {
                    event.preventDefault();
                    parent.nextElementSibling.querySelector('input')?.focus();
                }
                break;
            case 'ArrowUp':
                if (event.target.id !== `option-menu-${this.id}`
                && parent.previousElementSibling) {
                    event.preventDefault();
                    parent.previousElementSibling.querySelector('input')?.focus();
                }
                break;
            case ' ':
                event.target.focus();
                break;
            case 'Escape':
                this.toggleMenu(false);
        }
    }
}
