<ng-container *transloco="let t">
  <ng-container *ngIf="attainment | attainmentName | async | localizedString as attainmentName">
    <div class="modal-header">
      <h2 id="attainment-details-heading">{{attainmentName}}, {{ attainment | attainmentCode | async }}</h2>
      <button type="button"
              class="modal-close"
              (click)="modal.close()"
              [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
        <sis-icon icon="ring-close"></sis-icon>
      </button>
    </div>
    <div class="modal-body">
      <app-course-unit-attainment-details *ngIf="attainment.type === 'CourseUnitAttainment'"
                                          [attainment]="$any(attainment)"
                                          [secondaryAttainments]="secondaryAttainments"
                                          [expirationImminent]="expirationImminent"
                                          [gradeRaiseEnabled]="gradeRaiseEnabled"
                                          [gradeRaiseInfoEnabled]="gradeRaiseInfoEnabled"
                                          [gradeRaiseAttempt]="gradeRaiseAttempt"
                                          [isAttachedToPrimaryAttainment]="isAttachedToPrimaryAttainment"
                                          [modalRef]="this.modal">
      </app-course-unit-attainment-details>
      <app-degree-programme-attainment-details *ngIf="attainment.type === 'DegreeProgrammeAttainment'"
                                               [attainment]="$any(attainment)"
                                               [secondaryAttainments]="secondaryAttainments"
                                               [expirationImminent]="expirationImminent">
      </app-degree-programme-attainment-details>
      <app-module-attainment-details *ngIf="attainment.type === 'ModuleAttainment'"
                                     [attainment]="$any(attainment)"
                                     [secondaryAttainments]="secondaryAttainments"
                                     [expirationImminent]="expirationImminent">
      </app-module-attainment-details>
      <app-custom-module-attainment-details *ngIf="attainment.type === 'CustomModuleAttainment'"
                                            [attainment]="$any(attainment)"
                                            [secondaryAttainments]="secondaryAttainments"
                                            [expirationImminent]="expirationImminent">
      </app-custom-module-attainment-details>
      <app-assessment-item-attainment-details *ngIf="attainment.type === 'AssessmentItemAttainment'"
                                      [attainment]="$any(attainment)"
                                      [secondaryAttainments]="secondaryAttainments"
                                      [expirationImminent]="expirationImminent">
      </app-assessment-item-attainment-details>
      <app-custom-course-unit-attainment-details *ngIf="attainment.type === 'CustomCourseUnitAttainment'"
                                                 [attainment]="$any(attainment)"
                                                 [secondaryAttainments]="secondaryAttainments"
                                                 [expirationImminent]="expirationImminent">
      </app-custom-course-unit-attainment-details>
    </div>
    <div class="modal-footer">
      <sis-button (clicked)="modal.dismiss()">{{ t('COMMON.CLOSE') }}</sis-button>
    </div>
  </ng-container>
</ng-container>
