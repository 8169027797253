<div #dropdownSelect class="dropdown">
  <div #dropdownSelectInput
       data-cy="dropdown"
       class="dropdown dropdown-toggle"
       role="combobox"
       [attr.id]="id"
       [tabindex]="!disabled ? 0 : -1"
       [attr.disabled]="disabled || control?.disabled || null"
       [attr.aria-disabled]="disabled || control?.disabled || null"
       [attr.aria-haspopup]="'listbox'"
       [attr.aria-controls]="'sis-listbox-element'"
       [attr.aria-labelledby]="ariaLabelledBy"
       [attr.aria-describedby]="id + '_errors ' + id + '_help-block'"
       [attr.aria-expanded]="isDropDownOpen"
       [ngClass]="class"
       [class.dropdown-toggle__disabled]="disabled || control?.disabled || null"
       [class.dropdown-toggle__light]="onDarkBackground"
       [class.form-control]="!compact"
       [class.non-button]="compact"
       [class.ng-touched]="control?.touched"
       [class.ng-dirty]="skipDirtyCheck ? false : control?.dirty"
       [class.ng-invalid]="control?.invalid"
       [class.item-has-additional-info]="selected && selected.info"
       [class.invalid]="control?.invalid && (control?.touched || (skipDirtyCheck ? false : control?.dirty))"
       (click)="toggleDropDown()"
       (keydown)="handleKeyDown($event)">
    <span *ngIf="!selected" id="placeholder" class="sis-form-placeholder" [class.form-placeholder__light]="onDarkBackground">
      {{(placeholder || 'SIS_COMPONENTS.SELECT.CHOOSE') | translate}}
    </span>
    <ng-container *ngIf="selected">
      <span>{{selected.label}}
        <span *ngIf="compact && selected.info" class="compact-additional-info">{{selected.info}}</span>
      </span>
      <span *ngIf="!compact && selected.info" class="additional-info">
        <span class="additional-info-text">{{selected.info}}</span>
      </span>
    </ng-container>
    <sis-icon *ngIf="!(selected && clearable)" icon="chevron" iconClass="cw-90" />
    <sis-button *ngIf="selected && clearable"
        class="clear-value"
        [link]="true"
        [ariaLabel]="'SIS_COMPONENTS.SELECT.CLEAR_FIELD' | translate"
        (clicked)="clearSelection()"
        (keydown.enter)="clearSelection()"
        (keydown.space)="clearSelection()">
      <sis-icon icon="close" />
    </sis-button>
  </div>
  <div class="dropdown-menu"
       role="listbox"
       [attr.id]="'sis-listbox-element'"
       [attr.aria-labelledby]="ariaLabelledBy"
       [class.show]="isDropDownOpen"
       [class.dropdown-menu-right]="alignRight">
    <ng-container *ngFor="let option of _refactoredOptions.nonGroupedOptions, trackBy:trackerFunction">
      <div data-cy="dropdown-select-option"
           class="dropdown-item option"
           role="option"
           [attr.id]="'sis-dropdown-select-' + id + '-item-' + option.indexId"
           [tabindex]="-1"
           [class.active]="option.value === value"
           [class.disabled]="option.disabled"
           [attr.disabled]="option.disabled || null"
           [attr.aria-disabled]="option.disabled || false"
           [attr.aria-selected]="option.value === value"
           (click)="!option.disabled && selectValue(option.value)"
           (keydown)="handleKeyDown($event, option.indexId)">
        {{ option.label }}
        <span class="additional-info" [class.compact]="compact" *ngIf="option.info || option.disabled">
        {{option?.info}}
          <span *ngIf="option.disabled" class="d-block">{{'SIS_COMPONENTS.SELECT.OPTION_DISABLED' | translate}}</span>
      </span>
      </div>
    </ng-container>
    <ng-container *ngFor="let header of _headersToLoop">
      <ng-container *ngIf="_refactoredOptions.groupedOptions[header]">
        <div [attr.aria-labelledby]="'sis-dropdown-select-' + id + '-header-' + _refactoredOptions.groupedOptions[header].headerLabel"
             class="dropdown-header dropdown-group"
             role="group">
          <div [attr.aria-hidden]="true"
               [attr.id]="'sis-dropdown-select-' + id + '-header-' + _refactoredOptions.groupedOptions[header].headerLabel">
            {{ _refactoredOptions.groupedOptions[header].headerLabel }}
          </div>
          <ng-container *ngFor="let option of _refactoredOptions.groupedOptions[header].options">
            <div data-cy="dropdown-select-option"
                 class="dropdown-item option dropdown-group__option"
                 role="option"
                 [attr.id]="'sis-dropdown-select-' + id + '-item-' + option.indexId"
                 [tabindex]="-1"
                 [class.active]="option.value === value"
                 [class.disabled]="option.disabled"
                 [attr.disabled]="option.disabled || null"
                 [attr.aria-disabled]="option.disabled || false"
                 [attr.aria-selected]="option.value === value"
                 (click)="!option.disabled && selectValue(option.value)"
                 (keydown)="handleKeyDown($event, option.indexId)">
              {{ option.label }}
              <span class="additional-info" [class.compact]="compact" *ngIf="option.info || option.disabled">
              {{option?.info}}
                <span *ngIf="option.disabled" class="d-block">{{'SIS_COMPONENTS.SELECT.OPTION_DISABLED' | translate}}</span>
            </span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!options || options.length === 0">
      <div class="dropdown-item option disabled"
           role="option"
           [attr.id]="'sis-dropdown-select-' + id + '-item-0'"
           [attr.aria-disabled]="disabled"
           [tabindex]="-1"
           (keydown)="noItemsKeyDown($event)">
        {{ 'SIS_COMPONENTS.OBJECT.REFERRED_OBJECT_SELECT_EDITOR.NOT_FOUND_TEXT' | translate }}
      </div>
    </ng-container>
  </div>
</div>

<sis-validation-errors *ngIf="control?.invalid && (control?.touched || (skipDirtyCheck ? false : control?.dirty))"
                       [control]="control"
                       [formFieldId]="id"
                       [ariaLive]="false" />
<p *ngIf="helpBlock" [id]="id + '_help-block'" class="help-block">{{helpBlock | translate: helpBlockParams}}</p>
