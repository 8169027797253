<ng-container *transloco="let t">
  <div class="row">
    <div class="col-12">
      @if (canShowCalendarButton()) {
        <button type="button"
                class="link-button event-date"
                (click)="viewInCalendar()"
                [attr.aria-label]="(studyEvent().startTime | localDateFormat) + ' '
                + ((studyEvent().recursUntil | localDateFormat) ?? '') + ' '
                + (t('ARIA_LABEL.SHOW_IN_CALENDAR'))">
          {{ studyEvent().startTime | localDateFormat }}
          @if (studyEvent().recursUntil !== null) {
            <span>-{{ studyEvent().recursUntil | localDateFormat }}</span>
          }
        </button>
      } @else {
        {{ studyEvent().startTime | localDateFormat }}
        @if (studyEvent().recursUntil !== null) {
          <span>–{{ studyEvent().recursUntil | localDateFormat }}</span>
        }
      }
    </div>
  </div>
  <div class="row event-detail-row">
    <div class="col-2 col-md-3 col-lg-2">
      {{ weekDayOrInterval() }}
    </div>
    <div class="col-3">
      {{ timeRange() }}
    </div>
    <div class="col-7 col-md-6 col-lg-7">
      @if (allEventsExcludedOrCancelled()) {
        <div>
          <fudis-badge [variant]="'danger'" [content]="t('STUDY_EVENT_SUMMARY.CANCELLED')">
          </fudis-badge>
        </div>
      }
      @if (!allEventsExcludedOrCancelled()) {
        <div>
          @for (locationId of studyEvent().locationIds; track locationId) {
            <div>
              <sis-icon icon="pin-small"></sis-icon>
              @if (locationId | location | async; as location) {
                <span class="location">
              {{ location.name | localizedString }}
                  – {{ location.building.name | localizedString }}
              </span>
              }
            </div>
          } @empty {
            <div>
              <sis-icon icon="pin-small"></sis-icon>
              <span class="no-location">{{ t('STUDY_EVENT_SUMMARY.NO_LOCATION') }}</span>
            </div>
          }
          @if (relevantOverrides()?.length > 0) {
            <div class="irregular-locations">
              (<span>{{ t('STUDY_EVENT_SUMMARY.IRREGULAR_LOCATIONS') }}</span>)
            </div>
          }
        </div>
      }
    </div>
  </div>
</ng-container>
