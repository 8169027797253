import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CooperationNetworkStatus } from 'common-typescript/types';

@Component({
    selector: 'sis-attainment-cooperation-network-status-details',
    templateUrl: './attainment-cooperation-network-status-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttainmentCooperationNetworkStatusDetailsComponent implements OnInit {
    @Input() cooperationNetworkStatus: CooperationNetworkStatus;

    titleTranslationKey: string;
    outboundStatusTranslationKey: string;

    ngOnInit(): void {
        this.titleTranslationKey = this.resolveTitleTranslationKey(this.cooperationNetworkStatus);
        this.outboundStatusTranslationKey = this.resolveOutboundStatusTranslationKey(this.cooperationNetworkStatus);
    }

    resolveTitleTranslationKey(cooperationNetworkStatus: CooperationNetworkStatus): string {
        return cooperationNetworkStatus?.direction === 'INBOUND'
            ? 'ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.INBOUND_TITLE'
            : 'ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_TITLE';
    }

    resolveOutboundStatusTranslationKey(cooperationNetworkStatus: CooperationNetworkStatus): string {
        const status = cooperationNetworkStatus?.outboundStatus;
        if (status) {
            return `ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_STATUS.${status}`;
        }
        return 'ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_STATUS_UNDEFINED';
    }
}
