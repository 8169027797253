import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    BehaviorSubject, defaultIfEmpty, EMPTY,
    finalize,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    throwError,
} from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';

import { ConfigService } from '../config/config.service';
import { EnvironmentService } from '../environmentService/environment.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

import { AuthenticatedUser, ParsedToken, parseToken } from './auth-utils';
import { TokenStorageService } from './token-storage.service';

class AuthToken {
    constructor(public authToken: string,
                public expirationTime: number) {
    }
}
class AuthResponse {
    constructor(public authToken: string,
                public expiresIn: number,
                public sessionTimeoutAt: number) {
    }
}
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class AuthService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'common.ngAuthService',
        serviceName: 'ngAuthService',
    };

    private preAuthCall: Observable<string>;
    private user: Partial<AuthenticatedUser>;
    private anonymousIncludedUrls: string[] = [];
    private anonymousExcludedUrls: string[] = [];
    private _initialized$ = new BehaviorSubject(false);
    private readonly _userLoggedInSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    // create a permanent field to avoid change detection loops, in case an HTML template calls the getter directly
    private readonly _userLoggedIn$: Observable<boolean> = this._userLoggedInSubject$.pipe(distinctUntilChanged());
    private loginTargetKey = 'loginTarget';

    constructor(private tokenStorage: TokenStorageService,
                private configService: ConfigService,
                private uiRouterGlobals: UIRouterGlobals,
                private http: HttpClient,
                private environmentService: EnvironmentService,
                private sessionStorageService: SessionStorageService,
                @Inject(DOCUMENT) private document: Document) {}

    private storeAuthToken(response: AuthResponse): void {
        const currentToken = this.tokenStorage.token;

        this.tokenStorage.store(response.authToken, response.expiresIn, response.sessionTimeoutAt);

        if (!currentToken) {
            this._userLoggedInSubject$.next(true);
        }
    }

    private retrieveAuthToken(): AuthToken | null {
        return this.tokenStorage.retrieve();
    }

    private preAuth(): Observable<AuthResponse> {
        return (this.http.get('/ori/preauth', { observe: 'response' }) as Observable<HttpResponse<AuthResponse>>)
            .pipe(mergeMap((response) => {
                if (response.status === 200 && response.body.authToken) {
                    return of(response.body);
                }
                if (response.status === 204) {
                    this.logout();
                    return EMPTY;
                }
                return throwError(() => 'PreAuth call failed');
            }));
    }

    username(): OtmId {
        return this.parseUser()?.username;
    }

    personId(): OtmId {
        return this.parseUser()?.id;
    }

    roles(): string {
        return this.parseUser()?.roles;
    }

    scope(): string {
        return this.parseUser()?.scope;
    }

    displayname(): string {
        return this.parseUser()?.displayname;
    }

    firstNameInitialLetter(): string {
        return this.parseUser()?.firstNameInitialLetter;
    }

    lastNameInitialLetter(): string {
        return this.parseUser()?.lastNameInitialLetter;
    }

    loggedIn(): boolean {
        return !!this.parseUser();
    }

    hasScope(scope: string | string[]): boolean {
        if (this.parseUser()) {
            if (!Array.isArray(scope)) {
                return this.user.anyScopes.includes(scope);
            }
            return scope.some(s => this.user.anyScopes.includes(s));
        }
        return false;
    }

    isAnonymousUrl(url: string) {
        const matchesUrlPrefix = function (urlPrefix: string) {
            return url.startsWith(urlPrefix);
        };
        return this.anonymousIncludedUrls.find(matchesUrlPrefix) && !this.anonymousExcludedUrls.find(matchesUrlPrefix);
    }

    setAnonymousUrls(includedUrls: string[], excludedUrls: string[]) {
        this.anonymousIncludedUrls = includedUrls;
        this.anonymousExcludedUrls = excludedUrls;
    }

    isPreAuth(url: string): boolean {
        return url === '/ori/preauth';
    }

    refreshAuthToken(forceRefresh: boolean = false): Observable<string> {
        const authResponse = this.retrieveAuthToken();
        if (!authResponse && !forceRefresh) {
            return of(null);
        }
        if (!forceRefresh && authResponse && authResponse.expirationTime && new Date().getTime() < authResponse.expirationTime) {
            return of(authResponse.authToken);
        }
        if (!this.preAuthCall) {
            this.preAuthCall = this.preAuth().pipe(
                mergeMap(response => {
                    this.storeAuthToken(response);
                    this.preAuthCall = null;
                    return of(response.authToken);
                }),
                shareReplay(1));
        }
        return this.preAuthCall;
    }

    initializeAppAuth(): Observable<boolean> {
        if (this._initialized$.value) {
            return of(this.loggedIn());
        }
        if (this.loggedIn()) { // user is accessing fresh app with an existing token
            this._initialized$.next(true);
            this._userLoggedInSubject$.next(true);
            return of(true);
        }
        return this.refreshAuthToken(true).pipe( // user is accessing a fresh app, no token
            catchError(() => of(false)),
            defaultIfEmpty(of(false)),
            map(() => this.loggedIn()),
            tap(loggedIn => this._userLoggedInSubject$.next(loggedIn)),
            finalize(() => {
                this._initialized$.next(true);
                this.clearUser();
            }));
    }

    // clear session storage and user state and emit a userLoggedIn$(false) event.
    // keep loginTarget after session storage clear
    logout() {
        const token = this.tokenStorage.token;
        const loginTarget = this.sessionStorageService.getItem(this.loginTargetKey);

        this.clear();

        if (loginTarget) {
            this.sessionStorageService.setItem(this.loginTargetKey, loginTarget);
        }

        if (token) {
            this._userLoggedInSubject$.next(false);
        }
    }

    // clear session storage and user state, no event emission
    clear() {
        this.clearUser();
        this.tokenStorage.clear();
    }

    goToLoginPage(reason?: string) {
        const universityConfig = this.configService.get();
        const logoutUrl = universityConfig?.logoutUrl;

        if (!logoutUrl) {
            return;
        }

        if (this.uiRouterGlobals.params.loggedOut) {
            return;
        }

        const logoutUrlPrefix = logoutUrl.startsWith('http') ? '' : this.document.location.origin;
        const contextPath = this.environmentService.frontendName.toLowerCase();
        const logoutReason = reason ? `&reason=${reason}` : '';

        const targetUrl = `${this.document.location.origin}/${contextPath}/login?loggedOut=true${logoutReason}`;

        this.document.location.href = `${logoutUrlPrefix}${logoutUrl}${encodeURIComponent(targetUrl)}`;
    }

    parseUser() {
        const response = this.tokenStorage.retrieve();
        const token = response ? response.authToken : null;
        if (token && !this.user) {
            const parsed = parseToken(token);
            // non-global scope format is 'resource-type:resource-id,resource-id,..:permission,permission..'
            const anyScopes = _.uniq(
                _.flatMap(_.split(parsed.scope, ' '), scopeString =>
                    _.split(_.last(scopeString.split(':')), ',')),
            );
            this.user = {
                username: parsed.sub,
                anyScopes,
                scope: parsed.scope,
                expires: parsed.exp,
                roles: parsed.roles,
                id: parsed.personid,
            };
            this.setNameProperties(parsed, this.user);
        }
        return this.user;
    }

    setNameProperties(parsed: ParsedToken, user: Partial<AuthenticatedUser>) {
        if (!parsed.userinfo) {
            return;
        }
        const callName = parsed.userinfo.callname;
        const firstNames = parsed.userinfo.firstnames;
        const lastName = parsed.userinfo.lastname;
        const fullName = _.compact([(callName || firstNames), lastName]).join(' ');
        const firstNameInitialLetter = _.head(_.first(_.compact([callName, firstNames])));
        const lastNameInitialLetter = _.head(_.first(_.compact([lastName])));
        user.displayname = fullName;
        user.firstNameInitialLetter = firstNameInitialLetter;
        user.lastNameInitialLetter = lastNameInitialLetter;
    }

    clearUser() {
        this.user = undefined;
    }

    getAuthUrl(shibbolethPath: string, loginTargetUrl: string) {
        if (shibbolethPath.startsWith('http')) {
            return shibbolethPath + loginTargetUrl;
        }
        return this.document.location.origin + shibbolethPath + loginTargetUrl;

    }

    moveToAuthUrl(shibbolethPath: string, loginTargetUrl: string) {
        this.sessionStorageService.removeItem(this.loginTargetKey);
        if (shibbolethPath.startsWith('http')) {
            this.document.location.href = shibbolethPath + loginTargetUrl;
        } else {
            this.document.location.href = this.document.location.origin + shibbolethPath + loginTargetUrl;
        }
    }

    /**
     * Compares required scope(s) with user's auth token scopes.
     * If the user lacks any of the required scopes, the method returns true.
     * requiresScope is defined in router state declarations.
     */
    insufficientScope(requiredScope: string | string[] | boolean) {
        if (typeof requiredScope === 'string' || Array.isArray(requiredScope)) {
            return !this.hasScope(requiredScope);
        }
        return requiredScope;
    }

    get initialized$() {
        return this._initialized$.asObservable();
    }

    /**
     * stores the latest value and emits a new one when it changes.
     * true if there is a valid token stored for the user, which in this context means that the user is logged in.
     * false when the user's existing token was cleared, i.e. user was logged out.
     * does not emit anything until authentication status is known (before any successful preAuth-call has completed)
     *
     * To use it in a component template it is best to wrap the observable in an object. This renders content even if the observable value evaluates to false:
     *
     *     <ng-container *ngIf="{loggedIn: this.authService.userLoggedIn$ | async} as state">
     *         <p *ngIf="state.loggedIn === false">
     *           You are logged out!
     *         </p>
     *         <p *ngIf="state.loggedIn === true">
     *           Welcome!
     *         </p>
     *     </ng-container>
     *
     */
    get userLoggedIn$(): Observable<boolean> {
        return this._userLoggedIn$;
    }
}
