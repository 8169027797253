import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { isSafeInteger } from 'lodash-es';

@Component({
    selector: 'app-cart-container',
    templateUrl: './cart-container.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartContainerComponent {

    @Input() pageTitle: string;
    @Input() wizardStepKeys?: string[];

    @Input() set currentStep(currentStep: number) {
        this._currentStep = currentStep;
    }

    get currentStep(): number {
        if (!this.wizardStepKeys || !isSafeInteger(this._currentStep) ||
            this._currentStep < 0 || this._currentStep >= this.wizardStepKeys.length) {
            return 0;
        }

        return this._currentStep;
    }

    private _currentStep: number;
}
