import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import {
    CurriculumPeriod,
    DegreeProgramme,
    PersonWithModuleResponsibilityInfoType,
    StudyModule,
} from 'common-typescript/types';
import moment from 'moment';
import { map, Observable, OperatorFunction, ReplaySubject, Subject, switchMap } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';

@Component({
    selector: 'app-module-info-basic-info',
    templateUrl: './module-info-basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoBasicInfoComponent implements OnInit {
    @Input() set module(module: StudyModule | DegreeProgramme) {
        this.moduleChangeSubject$.next(module);
        this._module = module;
    }

    _module: StudyModule | DegreeProgramme;
    moduleChangeSubject$: Subject<StudyModule | DegreeProgramme> = new ReplaySubject<StudyModule | DegreeProgramme>(1);

    sortedCurriculumPeriods$: Observable<CurriculumPeriod[]>;
    validResponsibilityInfos$: Observable<PersonWithModuleResponsibilityInfoType[]>;

    constructor(private curriculumPeriodEntityService: CurriculumPeriodEntityService,
                private appErrorHandler: AppErrorHandler) {}

    ngOnInit(): void {
        this.sortedCurriculumPeriods$ = this.moduleChangeSubject$.pipe(this.getSortedCurriculumPeriodsOperator());
        this.validResponsibilityInfos$ = this.moduleChangeSubject$.pipe(this.getValidResponsibilityInfosOperator());
    }

    getSortedCurriculumPeriodsOperator(): OperatorFunction<StudyModule | DegreeProgramme, CurriculumPeriod[]> {
        return switchMap((module) => this.curriculumPeriodEntityService.getByIds(module.curriculumPeriodIds)
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                map(curriculumPeriods => [...curriculumPeriods].sort(this.compareCurriculumPeriodStartDates))));
    }

    getValidResponsibilityInfosOperator(): OperatorFunction<StudyModule | DegreeProgramme, PersonWithModuleResponsibilityInfoType[]> {
        return map((module) => module.responsibilityInfos.filter(this.isValidResponsibilityInfo));
    }

    /**
     * ResponsibilityInfo is valid if it doesn't have a validity period
     * or current date is contained in the validity period.
     *
     * @param responsibilityInfo
     */
    isValidResponsibilityInfo(responsibilityInfo: PersonWithModuleResponsibilityInfoType): boolean {
        return !responsibilityInfo.validityPeriod
            || dateUtils.dateRangeContains(moment(), responsibilityInfo.validityPeriod.startDate, responsibilityInfo.validityPeriod.endDate);
    }

    private compareCurriculumPeriodStartDates(a: CurriculumPeriod, b: CurriculumPeriod): number {
        return (new Date(a.activePeriod.startDate) > new Date(b.activePeriod.startDate) ? 1 : -1);
    }
}
