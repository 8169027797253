<ng-container *transloco="let t">
  <fudis-section
          class="fudis-mb-lg"
          [title]="t('PROFILE.CONTACT_INFO')"
          [titleVariant]="'sm'"
          [level]="3"
  >
    <fudis-section-actions>
      @if (isEditEnabledByUniversity) {
        <fudis-button [variant]="'primary'" [label]="t('BUTTON.EDIT')" (handleClick)="openEditDialog()"/>
      }
    </fudis-section-actions>

    <fudis-section-content>
      <fudis-dl class="fudis-mb-lg" [rowGap]="'xl'">
        <fudis-dl-item [id]="'phone-number-dl-item'">
          <fudis-dt [contentText]="t('FIELD_NAMES.phoneNumber')"/>
          @if (isClassified('PhoneNumber')) {
            <fudis-dd
                    [ariaLabel]="isClassifiedInfoVisible('PhoneNumber') ? null : t('ARIA_LABEL.HIDDEN_PHONE_NUMBER')"
                    [contentText]="isClassifiedInfoVisible('PhoneNumber') ? studentProfile.phoneNumber : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
            >
              <fudis-button
                      [id]="'phone-number-visibility-button'"
                      [variant]="'tertiary'"
                      [size]="'small'"
                      [icon]="isClassifiedInfoVisible('PhoneNumber') ? 'eye-blind' : 'eye'"
                      [label]="isClassifiedInfoVisible('PhoneNumber') ? t('HIDE') : t('VIEW')"
                      (handleClick)="toggleFieldVisibility('PhoneNumber')"
              />
            </fudis-dd>
          } @else {
            <fudis-dd [contentText]="studentProfile.phoneNumber"/>
          }
        </fudis-dl-item>
        <fudis-dl-item>
          <fudis-dt [contentText]="t('FIELD_NAMES.primaryEmail')"/>
          <fudis-dd [contentText]="studentProfile.primaryEmail"/>
        </fudis-dl-item>
        <fudis-dl-item [id]="'secondary-email-dl-item'">
          <fudis-dt [contentText]="t('FIELD_NAMES.secondaryEmail')"/>
          @if (isClassified('SecondaryEmail')) {
            <fudis-dd
                    [ariaLabel]="isClassifiedInfoVisible('SecondaryEmail') ? null : t('ARIA_LABEL.HIDDEN_SECONDARY_EMAIL')"
                    [contentText]="isClassifiedInfoVisible('SecondaryEmail') ? studentProfile.secondaryEmail : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
            >
              <fudis-button
                      [variant]="'tertiary'"
                      [size]="'small'"
                      [icon]="isClassifiedInfoVisible('SecondaryEmail') ? 'eye-blind' : 'eye'"
                      [label]="isClassifiedInfoVisible('SecondaryEmail') ? t('HIDE') : t('VIEW')"
                      (handleClick)="toggleFieldVisibility('SecondaryEmail')"
              />
            </fudis-dd>
          } @else {
            <fudis-dd [contentText]="studentProfile.secondaryEmail"/>
          }
        </fudis-dl-item>
        <fudis-dl-item [id]="'primary-address-dl-item'">
          <fudis-dt [contentText]="t('FIELD_NAMES.primaryAddress')"/>
          @if (studentProfile.primaryAddress) {
            <fudis-dd
                    [ariaLabel]="!isClassified('PrimaryAddress') || isClassifiedInfoVisible('PrimaryAddress')
                ? null : t('ARIA_LABEL.HIDDEN_PRIMARY_ADDRESS')"
                    [contentText]="!isClassified('PrimaryAddress') || isClassifiedInfoVisible('PrimaryAddress')
                ? null : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
            >
              @if (!isClassified('PrimaryAddress') || isClassifiedInfoVisible('PrimaryAddress')) {
                <fudis-dl [variant]="'compact'">
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('FIELD_NAMES.streetAddress')"/>
                    <fudis-dd [contentText]=" studentProfile.primaryAddress.streetAddress"/>
                  </fudis-dl-item>
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('FIELD_NAMES.postalCode')"/>
                    <fudis-dd [contentText]=" studentProfile.primaryAddress.postalCode"/>
                  </fudis-dl-item>
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('FIELD_NAMES.city')"/>
                    <fudis-dd [contentText]=" studentProfile.primaryAddress.city"/>
                  </fudis-dl-item>
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('FIELD_NAMES.country')"/>
                    <fudis-dd [contentText]=" studentProfile.primaryAddress.countryUrn | codeLocalizedName | async"/>
                  </fudis-dl-item>
                </fudis-dl>
              }
              @if (isClassified('PrimaryAddress')) {
                <fudis-button
                        [variant]="'tertiary'"
                        [size]="'small'"
                        [icon]="isClassifiedInfoVisible('PrimaryAddress') ? 'eye-blind' : 'eye'"
                        [label]="isClassifiedInfoVisible('PrimaryAddress') ? t('HIDE') : t('VIEW')"
                        (handleClick)="toggleFieldVisibility('PrimaryAddress')"
                />
              }
            </fudis-dd>
          }
        </fudis-dl-item>
        <fudis-dl-item [id]="'secondary-address-dl-item'">
          <fudis-dt [contentText]="t('FIELD_NAMES.secondaryAddress')"/>
          @if (studentProfile.secondaryAddress) {
            <fudis-dd
                    [ariaLabel]="!isClassified('SecondaryAddress') || isClassifiedInfoVisible('SecondaryAddress')
                ? null :t('ARIA_LABEL.HIDDEN_SECONDARY_ADDRESS')"
                    [contentText]="!isClassified('SecondaryAddress') || isClassifiedInfoVisible('SecondaryAddress')
                ? null : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
            >
              @if (!isClassified('SecondaryAddress') || isClassifiedInfoVisible('SecondaryAddress')) {
                <fudis-dl [variant]="'compact'">
                  @if (isSecondaryAddressInFinland()) {
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('FIELD_NAMES.streetAddress')"/>
                      <fudis-dd [contentText]=" studentProfile.secondaryAddress.streetAddress"/>
                    </fudis-dl-item>
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('FIELD_NAMES.postalCode')"/>
                      <fudis-dd [contentText]=" studentProfile.secondaryAddress.postalCode"/>
                    </fudis-dl-item>
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('FIELD_NAMES.city')"/>
                      <fudis-dd [contentText]=" studentProfile.secondaryAddress.city"/>
                    </fudis-dl-item>
                  } @else {
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('FIELD_NAMES.address')"/>
                      <fudis-dd [contentText]=" studentProfile.secondaryAddress.address"/>
                    </fudis-dl-item>
                  }
                  <fudis-dl-item>
                    <fudis-dt [contentText]="t('FIELD_NAMES.country')"/>
                    <fudis-dd [contentText]="studentProfile.secondaryAddress.countryUrn | codeLocalizedName | async"/>
                  </fudis-dl-item>
                </fudis-dl>
              }
              @if (isClassified('SecondaryAddress')) {
                <fudis-button
                        [variant]="'tertiary'"
                        [size]="'small'"
                        [icon]="isClassifiedInfoVisible('SecondaryAddress') ? 'eye-blind' : 'eye'"
                        [label]="isClassifiedInfoVisible('SecondaryAddress') ? t('HIDE') : t('VIEW')"
                        (handleClick)="toggleFieldVisibility('SecondaryAddress')"
                />
              }
            </fudis-dd>
          }
        </fudis-dl-item>
      </fudis-dl>
      <fudis-button
              [label]="t('PROFILE.CLASSIFIED.TITLE')"
              [variant]="'tertiary'"
              [icon]="'lock'"
              (handleClick)="openClassifiedInfoDialog()"
      />
    </fudis-section-content>
  </fudis-section>
</ng-container>
