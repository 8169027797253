import {
    PlanCourseUnitMessage,
    PlanCustomStudyDraftMessage,
    PlanGenericMessage,
    PlanMessage,
    PlanModuleMessage,
} from 'common-typescript/types';

export type InputPlanMessage = PlanMessage | PlanGenericMessage | PlanModuleMessage | PlanCourseUnitMessage | PlanCustomStudyDraftMessage;
export type StudyBoxMessage = PlanModuleMessage | PlanCourseUnitMessage | PlanCustomStudyDraftMessage;

export function isStudyBoxMessage(message: Partial<InputPlanMessage>): message is StudyBoxMessage {
    const boxTypes = ['PlanModuleSelection', 'PlanCourseUnitSelection', 'PlanCustomStudyDraft'];
    return boxTypes.includes(message?.type);
}

export function isPlanGenericComment(message: InputPlanMessage): message is PlanGenericMessage {
    return message?.type === 'PlanGeneric';
}

export function isPlanCourseUnitComment(message: InputPlanMessage): message is PlanCourseUnitMessage {
    return message?.type === 'PlanCourseUnitSelection';
}

export function isPlanCustomStudyDraftComment(message: InputPlanMessage): message is PlanCustomStudyDraftMessage {
    return message?.type === 'PlanCustomStudyDraft';
}

export function isPlanModuleComment(message: InputPlanMessage): message is PlanModuleMessage {
    return message?.type === 'PlanModuleSelection';
}
