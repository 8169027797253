import * as _ from 'lodash-es';
export function BaseCourseUnitRuleController(uiContext, PlanState, courseUnitInfoModal) {
  const ctrl = this;
  ctrl.toggleSelection = toggleSelection;
  ctrl.isSelected = isSelected;
  ctrl.isSelectedCustomCourseUnitAttainment = isSelectedCustomCourseUnitAttainment;
  ctrl.isImplicitlySelected = isImplicitlySelected;
  ctrl.isDimmed = isDimmed;
  ctrl.isCourseUnitRemoveAllowed = isCourseUnitRemoveAllowed;
  ctrl.isUnplanned = isUnplannedCourseUnit;
  ctrl.isUnplannedCustomCourseUnitAttainment = isUnplannedCustomCourseUnitAttainment;
  ctrl.isImplicit = isImplicit;
  ctrl.getRuleValidationResult = () => uiContext.planContext.getRuleValidationResult(ctrl.module.id, ctrl.rule.localId);
  ctrl.getPlanValidationResult = () => _.get(uiContext, 'planContext.planValidationResult');
  ctrl.handleAdditionalBoxClick = function (module, $event) {
    if ($event.target.localName === 'div') {
      toggleSelection(module);
    }
  };
  ctrl.handleCheckboxClick = function (module, $event) {
    if ($event.target.localName === 'input') {
      toggleSelection(module);
    }
  };
  ctrl.handleButtonClick = function (module, $event) {
    if ($event.target.localName === 'button') {
      toggleSelection(module);
    }
  };
  ctrl.handleSpanClick = function (module, $event) {
    if ($event.target.localName === 'span') {
      toggleSelection(module);
    }
  };
  ctrl.isTogglingEnabled = courseUnit => {
    if (isImplicit(courseUnit)) {
      return uiContext.planContext.canImplicitlySelectedCourseUnitBeMovedToTargetModule(courseUnit, ctrl.module);
    }
    return !uiContext.planContext.isCourseUnitSelectionDisabled(courseUnit, ctrl.module);
  };
  function toggleSelection(courseUnit, module) {
    if (isImplicit(courseUnit) && uiContext.planContext.canImplicitlySelectedCourseUnitBeMovedToTargetModule(courseUnit, ctrl.module)) {
      return uiContext.planContext.moveCourseUnit(courseUnit, ctrl.module);
    }
    if (!canBeAdded(courseUnit) && !isCourseUnitRemoveAllowed(courseUnit)) {
      return undefined;
    }
    if (isSelected(courseUnit) && !isUnplannedCourseUnit(courseUnit)) {
      return unSelectCourseUnit(courseUnit);
    }

    // open modal only if the user is placing an unplanned course unit realisation with module
    if (uiContext.unplannedCourseUnitRealisation !== undefined && _.findIndex(uiContext.unplannedCourseUnits, {
      id: courseUnit.id
    }) > -1 && module) {
      // need to show info modal and highlight completion methods
      return selectCourseUnit(courseUnit).then(() => {
        courseUnitInfoModal.showCompletionMethodsForUnplannedCourseUnit(courseUnit.id, _.get(uiContext, 'planContext.validatablePlan'), courseUnit, module, uiContext.unplannedCourseUnitRealisation).then(() => {
          forcePlanReloadUpdate().finally(() => {
            if (uiContext.planContext) {
              const courseUnitFromPlanContext = uiContext.planContext.getCourseUnit(courseUnit.id);
              if (!getSelectedCompletionMethodId(courseUnitFromPlanContext)) {
                unSelectCourseUnit(courseUnitFromPlanContext);
              }
            }
            uiContext.cancelPlanningOfCourseUnit();
          });
        });
      });
    }
    uiContext.cancelPlanningOfCourseUnit();
    return selectCourseUnit(courseUnit);
  }
  function forcePlanReloadUpdate() {
    return uiContext.planContext.forcePlanReloadUpdate();
  }
  function selectCourseUnit(courseUnit) {
    return uiContext.planContext.selectCourseUnit(ctrl.module, courseUnit);
  }
  function unSelectCourseUnit(courseUnit) {
    return uiContext.planContext.unSelectCourseUnit(ctrl.module, courseUnit);
  }
  function getSelectedCompletionMethodId(courseUnit) {
    const validatablePlan = _.get(uiContext, 'planContext.validatablePlan');
    if (!validatablePlan) {
      return null;
    }
    return validatablePlan.getSelectedCompletionMethod(courseUnit);
  }
  function isSelected(courseUnit) {
    if (!courseUnit) {
      return false;
    }
    return uiContext.planContext.isSelectedCourseUnit(ctrl.module, courseUnit);
  }
  function isSelectedCustomCourseUnitAttainment(customCourseUnitAttainment) {
    if (!customCourseUnitAttainment) {
      return false;
    }
    return uiContext.planContext.isSelectedCustomCourseUnitAttainment(ctrl.module, customCourseUnitAttainment);
  }
  function isCourseUnitRemoveAllowed(courseUnit) {
    return uiContext.planContext.isCourseUnitRemoveAllowed(courseUnit, ctrl.module);
  }
  function canBeAdded(courseUnit) {
    return !uiContext.planContext.isCourseUnitSelectionDisabled(courseUnit, ctrl.module);
  }
  function isImplicit(courseUnit) {
    const validatablePlan = _.get(uiContext, 'planContext.validatablePlan');
    if (courseUnit && validatablePlan) {
      const ruleValidationResult = ctrl.getRuleValidationResult();
      return validatablePlan.isCourseUnitInPlan(courseUnit) && _.get(ruleValidationResult, 'state') === PlanState.IMPLICIT;
    }
    return false;
  }
  function isImplicitlySelected(courseUnit) {
    const selectedCourseUnitsById = _.get(ctrl.getRuleValidationResult(), 'selectedCourseUnitsById');
    return isImplicit(courseUnit) && !!_.get(selectedCourseUnitsById, courseUnit.id);
  }
  function isDimmed(courseUnit) {
    if (!courseUnit) {
      return false;
    }
    if (ctrl.isTogglingEnabled(courseUnit) || isCourseUnitRemoveAllowed(courseUnit)) {
      return false;
    }
    return true;
  }
  function isUnplannedCourseUnit(courseUnit) {
    return !!_.find(uiContext.unplannedCourseUnits, {
      id: courseUnit.id
    });
  }
  function isUnplannedCustomCourseUnitAttainment(customCourseUnitAttainment) {
    return !!_.find(uiContext.unplannedCustomCourseUnitAttainments, {
      id: customCourseUnitAttainment.id
    });
  }
}