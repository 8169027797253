<ng-container *transloco="let t">
  <fudis-section
          class="fudis-mt-sm"
          [title]="t('PROFILE.PASSPORT_INFO.TITLE')"
          [titleVariant]="'sm'"
          [level]="3"
  >
    @if (isEditingEnabled && passports$ | async; as passports) {
      <fudis-section-actions>
        <fudis-button [variant]="'primary'" [label]="t('BUTTON.EDIT')" (handleClick)="openEditModal(passports)"/>
      </fudis-section-actions>
    }
    <fudis-section-content>
      @if (showAccessForbiddenNotification) {
        <fudis-notification [variant]="'warning'">
          <fudis-body-text>{{ t('PROFILE.PASSPORT_INFO.ACCESS_FORBIDDEN') }}</fudis-body-text>
        </fudis-notification>
      } @else if (showGenericErrorNotification) {
        <fudis-notification [variant]="'danger'">
          <fudis-body-text>{{ t('PROFILE.PASSPORT_INFO.FETCHING_PASSPORTS_FAILED') }}</fudis-body-text>
        </fudis-notification>
      }
      @if (passports$ | async; as passports) {
        @if (!passports || (passports && passports.length === 0)) {
          <fudis-body-text [variant]="'md-light'">
            {{ t('PROFILE.PASSPORT_INFO.NO_PASSPORTS_ADDED') }}
          </fudis-body-text>
        } @else {
          <fudis-grid [columns]="2">
            @for (passport of passports; track passport.id; let index = $index) {
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('PROFILE.PASSPORT_INFO.PASSPORT') + ' ' + (index +1)"/>
                  <fudis-dd>
                    <fudis-dl [variant]="'compact'">
                      <fudis-dl-item>
                        <fudis-dt [contentText]="t('PROFILE.PASSPORT_INFO.ISSUER')"/>
                        <fudis-dd [id]="'passport-issuer-content-' + index"
                                  [contentText]="isPassportVisible(passport.id) ? passport.issuer : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
                                  [ariaLabel]="isPassportVisible(passport.id) ? null : t('ARIA_LABEL.PASSPORT.HIDDEN_PASSPORT_ISSUER')"/>
                      </fudis-dl-item>
                      <fudis-dl-item>
                        <fudis-dt [contentText]="t('PROFILE.PASSPORT_INFO.PASSPORT_NUMBER')"/>
                        <fudis-dd [id]="'passport-number-content-' + index"
                                  [contentText]="isPassportVisible(passport.id) ? passport.passportNumber : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
                                  [ariaLabel]="isPassportVisible(passport.id) ? null : t('ARIA_LABEL.PASSPORT.HIDDEN_PASSPORT_NUMBER')"/>
                      </fudis-dl-item>
                      <fudis-dl-item>
                        <fudis-dt [contentText]="t('PROFILE.PASSPORT_INFO.VALIDITY_PERIOD')"/>
                        <fudis-dd [id]="'passport-validity-period-content-'+ index"
                                  [contentText]="isPassportVisible(passport.id) ? (passport.validityPeriod | localDateRange) : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
                                  [ariaLabel]="isPassportVisible(passport.id) ? null : t('ARIA_LABEL.PASSPORT.HIDDEN_PASSPORT_VALIDITY_PERIOD')"/>
                      </fudis-dl-item>
                    </fudis-dl>
                  </fudis-dd>
                  <fudis-button
                          [id]="'passport-visibility-button-' + index"
                          [icon]="isPassportVisible(passport.id) ? 'eye-blind' : 'eye'"
                          [label]="isPassportVisible(passport.id) ? t('PROFILE.PASSPORT_INFO.HIDE_INFO') : t('PROFILE.PASSPORT_INFO.SHOW_INFO')"
                          (handleClick)="togglePassportVisibility(passport.id)"
                          variant="tertiary"
                  />
                </fudis-dl-item>
              </fudis-dl>
            }
          </fudis-grid>
        }
      }
    </fudis-section-content>
  </fudis-section>
</ng-container>
