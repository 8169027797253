import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ContentChildren,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output, TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { DegreeProgrammeAttainment, ModuleAttainment, OtmId, StudyModule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { CurriculumPeriodNameService } from 'sis-components/service/curriculum-period-name.service';

@Component({
    selector: 'app-module-info-header',
    templateUrl: './module-info-header.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoHeaderComponent implements OnInit, OnDestroy {

    constructor(private ref: ChangeDetectorRef,
                private curriculumPeriodNameService: CurriculumPeriodNameService,
                private appErrorHandler: AppErrorHandler) {}

    @Input() attainment: ModuleAttainment | DegreeProgrammeAttainment;
    @Input() module: StudyModule;
    @Input() versions: StudyModule[];
    @Input() language: string;

    @ContentChildren('headerNotification', { read: TemplateRef, descendants: true }) headerNotifications: TemplateRef<any>[];

    @Output() setModuleInfoVersionChange = new EventEmitter<StudyModule>();
    @Output() closeModal = new EventEmitter<void>();

    options: SelectOption[] = [];

    versionSelectOptions$: Subscription = null;

    moduleLocalizationKey: string;
    popoverLabelLocalizationKey: string;
    popoverInfoLocalizationKey: string;

    ngOnInit() {
        switch ((this.module.type as 'DegreeProgramme' | 'StudyModule')) {
            case 'DegreeProgramme': {
                this.moduleLocalizationKey = 'ENTITY_NAMES.DegreeProgramme';
                this.popoverLabelLocalizationKey = 'MODULE_INFO.DEGREE_PROGRAMME_VERSION';
                this.popoverInfoLocalizationKey = 'MODULE_INFO.DEGREE_PROGRAMME_VERSION_POPOVER_INFO';
                break;
            }
            case 'StudyModule': {
                this.moduleLocalizationKey = 'ENTITY_NAMES.StudyModule';
                this.popoverLabelLocalizationKey = 'MODULE_INFO.STUDY_MODULE_VERSION';
                this.popoverInfoLocalizationKey = 'MODULE_INFO.STUDY_MODULE_VERSION_POPOVER_INFO';
                break;
            }
            default: {
                break;
            }
        }
        this.versionSelectOptions$ = this.curriculumPeriodNameService.getVersionSelectOptions(this.versions)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((result) => {
                this.options = result;
                this.ref.markForCheck();
            });
    }

    ngOnDestroy() {
        this.versionSelectOptions$.unsubscribe();
    }

    changeVersion(versionId: OtmId): void {
        const selectedVersion = _.find(this.versions, ver => ver.id === versionId);
        this.setModuleInfoVersionChange.emit(selectedVersion);
    }

}
