import angular from 'angular';
import moment from 'moment';
import * as _ from 'lodash-es';
export const calendarEventServiceModule = 'sis-components.calendar.service.calendarEvent';
(function () {
  calendarEventService.$inject = ["$http", "$log", "dateFormat", "calendarEventUri", "MAX_EVENTS", "OWN_CALENDAR_EVENT_TEXT_COLOR", "OWN_CALENDAR_EVENT_BACKGROUND_COLOR"];
  angular.module(calendarEventServiceModule, []).factory('calendarEventService', calendarEventService).constant('MAX_EVENTS', 365).constant('OWN_CALENDAR_EVENT_BACKGROUND_COLOR', 'middle-gray').constant('OWN_CALENDAR_EVENT_TEXT_COLOR', 'dark-gray').constant('calendarEventUri', '/ilmo/api/calendar-events').constant('dateFormat', {
    LOCAL_DATE: 'YYYY-MM-DD',
    LOCAL_DATETIME: 'YYYY-MM-DDTHH:mm',
    TIME: 'HH:mm'
  });

  /**
   * @ngInject
   */
  function calendarEventService($http, $log, dateFormat, calendarEventUri, MAX_EVENTS, OWN_CALENDAR_EVENT_TEXT_COLOR, OWN_CALENDAR_EVENT_BACKGROUND_COLOR) {
    function datesEqual(date1, date2) {
      const event1Date = moment(date1);
      const event2Date = moment(date2);
      return event1Date.isSame(event2Date, 'day');
    }
    function containsDate(dateArray, date) {
      return !!_.find(dateArray, arrayDate => datesEqual(arrayDate, date));
    }
    function isExcluded(studyEvent, date) {
      return containsDate(studyEvent.exceptions, date);
    }
    function nextEventStartDate(type, date) {
      const nextStartDate = date.clone();
      if (type === 'DAILY') {
        nextStartDate.add(1, 'days');
      } else if (type === 'WEEKLY') {
        nextStartDate.add(7, 'days');
      } else if (type === 'EVERY_SECOND_WEEK') {
        nextStartDate.add(14, 'days');
      } else if (type === 'MONTHLY') {
        nextStartDate.add(1, 'months');
      }
      return nextStartDate;
    }
    function isEventInstanceBetweenDateRange(eventInstance, startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const eventInstanceStart = moment(eventInstance.start);
      const eventInstanceEnd = moment(eventInstance.end);
      return eventInstanceStart.isBetween(start, end, 'day', '[]') || eventInstanceEnd.isBetween(start, end, 'day', '[]') || eventInstanceStart.isBefore(start) && eventInstanceEnd.isAfter(end);
    }
    const api = {
      save: function (calendarEvent) {
        return $http.post(calendarEventUri, calendarEvent).then(result => result.data);
      },
      update: function (calendarEvent) {
        return $http.put(`${calendarEventUri}/${calendarEvent.id}`, calendarEvent).then(result => result.data);
      },
      delete: function (calendarEvent) {
        return $http.delete(`${calendarEventUri}/${calendarEvent.id}`);
      },
      /**
       * Returns all calendar events if both arguments are omitted.
       * @param startDate, optional
       * @param endDate, optional
       * @returns array of calendar events
       */
      findEvents: function (startDate, endDate) {
        const formatDate = date => date ? moment(date).format(dateFormat.LOCAL_DATE) : undefined;
        const params = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate)
        };
        return $http.get(calendarEventUri, {
          params
        }).then(result => result.data);
      },
      /**
       * Creates a token for retrieving an ICS calendar.
       * The token is used for identifying the rights for getting an ICS calendar and for identifying what kind
       * of calendar to get, a student or a teacher calendar.
       * @param currentLocale The locale code of the language to use for localizable content.
       * @param forTeacher A boolean value where 'true' indicates the calendar should be for a teacher.
       * @returns An alphanumeric token to use for loading the calendar for a certain person and role.
       */
      getCalendarShareToken: function (currentLocale, forTeacher) {
        var params = `language=${currentLocale}`;
        if (forTeacher) {
          params = `${params}&role=TEACHER`;
        } else {
          params = `${params}&role=STUDENT`;
        }
        return $http.post(`/ilmo/api/calendar-share-tokens?${params}`).then(result => result.data);
      },
      toggleExceptionDate: function (calendarEvent, event) {
        const toggledDate = moment(event.start).format(dateFormat.LOCAL_DATE);
        if (!_.isArray(calendarEvent.exceptions)) {
          calendarEvent.exceptions = [toggledDate];
        } else {
          const exception = _.find(calendarEvent.exceptions, exceptionDate => datesEqual(exceptionDate, toggledDate));
          if (exception) {
            _.remove(calendarEvent.exceptions, exceptionDate => datesEqual(exceptionDate, toggledDate));
          } else {
            calendarEvent.exceptions.push(toggledDate);
            calendarEvent.exceptions.sort();
          }
        }
      },
      getCalendarEventEndTime: function (calendarEvent, startTime) {
        const start = startTime || moment(calendarEvent.startTime);
        return start.clone().add(moment.duration(calendarEvent.duration).asMinutes(), 'minutes');
      },
      getFullCalendarEvents: function (calendarEvents, startDate, endDate) {
        const fullCalendarEvents = [];
        _.forEach(calendarEvents, calendarEvent => {
          const eventInstances = api.createEventInstances(calendarEvent);
          _.forEach(eventInstances, eventInstance => {
            if (!eventInstance.excluded && isEventInstanceBetweenDateRange(eventInstance, startDate, endDate)) {
              fullCalendarEvents.push({
                title: calendarEvent.name,
                start: moment(eventInstance.start),
                end: moment(eventInstance.end),
                color: OWN_CALENDAR_EVENT_BACKGROUND_COLOR,
                borderColor: 'white',
                textColor: OWN_CALENDAR_EVENT_TEXT_COLOR,
                type: 'OWN_CALENDAR_EVENT',
                eventObject: calendarEvent,
                className: 'own-calendar-event'
              });
            }
          });
        });
        return fullCalendarEvents;
      },
      nextEventStartDate,
      createEventInstances: function (calendarEvent) {
        if (!calendarEvent) {
          return [];
        }
        const events = [];
        const startDateTime = moment(calendarEvent.startTime);
        if (calendarEvent.recursEvery === 'NEVER') {
          events.push(createEvent(startDateTime.clone(), calendarEvent));
        } else {
          const recursUntil = moment(calendarEvent.recursUntil);
          let start = startDateTime.clone();
          for (let index = 0; index < MAX_EVENTS + 1 && start.isSameOrBefore(recursUntil, 'day'); index++) {
            events.push(createEvent(start, calendarEvent));
            start = nextEventStartDate(calendarEvent.recursEvery, start);
          }
        }
        const eventStartDates = _.map(events, 'start');
        calendarEvent.exceptions = _.filter(calendarEvent.exceptions, exceptionDate => containsDate(eventStartDates, exceptionDate));
        return events;
      }
    };
    function createEvent(start, calendarEvent) {
      const end = api.getCalendarEventEndTime(calendarEvent, start);
      return {
        start: start.clone().toDate(),
        end: end.toDate(),
        excluded: isExcluded(calendarEvent, start)
      };
    }
    return api;
  }
})();