import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { StateService } from '@uirouter/angular';
import {
    DegreeProgramme,
    DegreeProgrammeAttainment,
    DegreeProgrammeAttainmentWorkflow, LocalizedUrl,
    Module,
    ModuleAttainment,
    OtmId, Plan, UniversitySettings,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { UniversityService } from 'sis-components/service/university.service';

import { isAMKDegree, isBachelorsDegree, resolveGraduationModalValues } from '../../../graduation/graduation-utils';
import {
    MandatoryGraduationSurveyModalComponent,
    MandatoryGraduationSurveyValues,
} from '../../../graduation/mandatory-graduation-survey-modal/mandatory-graduation-survey-modal.component';

@Component({
    selector: 'app-student-module-info-modal-graduation-info',
    templateUrl: './student-module-info-modal-graduation-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentModuleInfoModalGraduationInfoComponent implements OnInit {

    @Input() module: Module;
    @Input() plan: Plan;
    @Input() organisationId: OtmId;
    @Input() selectedModuleAttainment: ModuleAttainment | DegreeProgrammeAttainment;
    @Input() degreeProgrammeAttainmentWorkflow: DegreeProgrammeAttainmentWorkflow;
    @Output() closeModal = new EventEmitter<void>();

    mandatoryGraduationModalValues$: Observable<MandatoryGraduationSurveyValues>;

    constructor(private stateService: StateService,
                private universityService: UniversityService,
                private fudisDialogService: FudisDialogService,
                private errorHandler: AppErrorHandler) {}

    ngOnInit() {
        this.mandatoryGraduationModalValues$ = this.universityService.getCurrentUniversitySettings()
            .pipe(this.errorHandler.defaultErrorHandler(),
                  map(universitySettings => this.getMandatoryGraduationModalValues(universitySettings)));
    }

    navigateToDegreeProgrammeAttainmentWorkflowCreation(): void {
        this.stateService.go(
            'student.logged-in.profile.applications.create-degree-programme-attainment-application',
            {
                planId: this.plan.id,
                degreeProgrammeId: this.module.id,
            });
        this.closeModal.emit();
    }

    openMandatoryGraduationSurveyModal(mandatoryGraduationModalValues: MandatoryGraduationSurveyValues) {
        this.closeModal.emit();
        this.fudisDialogService.open(MandatoryGraduationSurveyModalComponent, { data: mandatoryGraduationModalValues });
    }

    getMandatoryGraduationModalValues = (universitySettings: UniversitySettings): MandatoryGraduationSurveyValues => {
        const graduationSurveyWithConfirmationEnabled =
            _.get(universitySettings, 'frontendFeatureToggles.mandatoryGraduationSurveyEnabled', false);
        if (!graduationSurveyWithConfirmationEnabled) return null;

        let surveyUrl: LocalizedUrl;
        let graduateType: string;

        if (isBachelorsDegree(this.module as DegreeProgramme)) {
            surveyUrl = _.get(universitySettings, 'bachelorsGraduateSurveyUrl');
            graduateType = 'BACHELORS';
        }
        if (isAMKDegree(this.module as DegreeProgramme)) {
            surveyUrl = _.get(universitySettings, 'amkGraduateSurveyUrl');
            graduateType = 'AMK';
        }
        if (!surveyUrl) return null;

        return {
            degreeProgrammeId: this.module.id,
            organisationId: this.organisationId,
            planId: this.plan.id,
            ...resolveGraduationModalValues(graduateType, surveyUrl),
        };
    };

}
