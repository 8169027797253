import angular from 'angular';
import * as _ from 'lodash-es';
import { LanguagePillsComponent } from '../language-pills/language-pills.component.ts';
import localizedStringTpl from './localizedString.tpl.html';
export const localizedStringModule = 'sis-components.string.localizedString';
(function () {
  localizedStringController.$inject = ["$scope", "$locale", "localeService", "$timeout"];
  angular.module(localizedStringModule, ['pascalprecht.translate', 'sis-common.l10n.localeService', 'sis-common.l10n.localizedStringFilter', LanguagePillsComponent.downgrade.moduleName]).directive('localizedString', localizedStringDirective).controller('localizedStringController', localizedStringController);

  /**
   * @ngInject
   */
  function localizedStringDirective() {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        selectedLocale: '=?',
        preventValidation: '<'
      },
      transclude: true,
      template: localizedStringTpl,
      controller: 'localizedStringController as ctrl',
      bindToController: true
    };
  }

  /**
   * @ngInject
   */
  function localizedStringController($scope, $locale, localeService, $timeout) {
    const ctrl = this;
    ctrl.scope = $scope;
    ctrl.locale = $locale;
    ctrl.supportedLocales = localeService.supportedLocales;
    if (!ctrl.selectedLocale) {
      ctrl.selectedLocale = localeService.getCurrentLocale() || ctrl.supportedLocales[0];
    }
    ctrl.$onInit = function () {
      // setTimeout is used to prevent "$digest loop" errors
      setTimeout(() => {
        ctrl.invalidLangFields = ctrl.getInvalidLangFields();
      });
    };
    ctrl.getInvalidLangFields = function () {
      return _.filter(ctrl.supportedLocales, locale => ctrl.noContent(locale) && !ctrl.preventValidation);
    };
    ctrl.noContent = function (lang) {
      if (!angular.isDefined(ctrl.data) || ctrl.data === null) {
        return true;
      }
      return !angular.isDefined(ctrl.data[lang]) || ctrl.data[lang] === '';
    };

    // This fixes problem when changes not rendering when using this in Angular template.
    ctrl.$onChanges = changes => {
      if (changes.data && !_.isEqual(changes.data.previousValue, changes.data.currentValue)) {
        $timeout(() => {
          $scope.$apply();
        });
      }
    };
  }
})();