educationSearchController.$inject = ["$filter", "$timeout", "educationService", "localeService"];
import angular from 'angular';
import * as _ from 'lodash-es';
import { map } from 'rxjs/operators';
import { filterInput } from 'sis-components/typeahead/typeahead.component.ts';
import educationSearchTpl from './educationSearch.tpl.html';
angular.module('student.plan').component('educationSearch', {
  template: educationSearchTpl,
  controller: educationSearchController,
  bindings: {
    education: '=',
    onEducationSelected: '&'
  }
});
function educationSearchController($filter, $timeout, educationService, localeService) {
  const $ctrl = this;
  let allEducations = [];
  $ctrl.$onInit = () => {
    educationService.getAllEducations().then(educations => {
      allEducations = educations;
    });
  };
  $ctrl.getDisplayName = education => {
    if (education === null || _.isUndefined(education)) {
      return '';
    }
    // If groupId is not unique, add a validity period to the name
    const isGroupIdUnique = _.isNil(education.groupId) || _.filter(allEducations, {
      groupId: education.groupId
    }).length <= 1;
    const validityPeriod = !isGroupIdUnique ? ` (${$filter('localDateFormat')(education.validityPeriod.startDate)} - ${$filter('localDateFormat')(education.validityPeriod.endDate)})` : '';
    return `${education.code} ${localeService.getLocalizedValue(education.name)}${validityPeriod}`;
  };
  $ctrl.clearEducation = () => {
    $timeout(() => {
      $ctrl.education = undefined;
    });
  };
  $ctrl.educationChanged = education => {
    if (angular.isDefined($ctrl.onEducationSelected)) {
      $ctrl.onEducationSelected({
        education
      });
    }
  };
  $ctrl.getEducations = queryText$ => queryText$.pipe(filterInput(), map(queryText => _.filter(allEducations, education => !_.isUndefined(education.name) && _.includes(_.toLower(localeService.getLocalizedValue(education.name)), _.toLower(queryText)))));
}