import {
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnChanges,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ResultTemplateContext } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead-window';
import { SisValidationErrors } from 'common-typescript/types';
import { omit } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { PlainTextPipe } from '../string/plain-text.pipe';

export const TYPEAHEAD_MAX_LENGTH = 255;

/**
 * A typeahead component based on NG Bootstrap's <a href="https://ng-bootstrap.github.io/#/components/typeahead/examples">Typeahead</a>.
 *
 * Renders a text `input` element that gives search suggestions using the `ngbTypeahead` directive. Please use the related operator
 * {@link filterInput} in all typeahead components for consistency.
 *
 * NOTE: with our current way of using typeahead components, the `model` property can have values of type variable `Model` or `ResultItem`:
 * <ol>
 *     <li>when an item is selected from the typeahead suggestions list, the model gets assigned with the selected `ResultItem` value</li>
 *     <li>often, after the selection, we fetch the actual `Model` value and assign it to the model</li>
 * </ol>
 * This is documented in the types of the inputs and outputs of this class, using the `Model` and `ResultItem` type variables.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-typeahead',
    templateUrl: './typeahead.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TypeaheadComponent<Model, ResultItem> implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.typeahead.typeahead',
        directiveName: 'sisTypeahead',
    };

    /**
     * Additional class attribute values to add to the input element. `form-control` is always added.
     */
    @Input() class?: string;

    /**
     * Emits an event when the input element is clicked.
     */
    @Output() click = new EventEmitter<MouseEvent>();

    /**
     * Whether the input element should be disabled.
     */
    @Input() disabled = false;

    /**
     * If `true`, other values than the ones suggested by typeahead are also allowed.
     */
    @Input() editable = false;

    /**
     * If `true` shows a clear-button in the input-field that will clear the current value.
     */
    @Input() clearable = false;

    /**
     * If `true`, use Typeahead's internal <a href="https://ng-bootstrap.github.io/#/components/typeahead/api#NgbHighlight">NgbHighlight</a>
     * for highlighting search terms in the search results. Set to `false` by default because most of our search APIs return already
     * highlighted results.
     */
    @Input() highlightSearchTerm = false;

    /**
     * An id attribute value to add to the input element.
     */
    @Input() id?: string;

    /**
     * The function that converts an item from the result list to a string to display in the input field.
     *
     * It is called when the user selects something in the popup or the model value changes, so the input needs to be updated.
     *
     * NOTE: The item is always rendered as plain text. Any HTML tags are removed using {@link PlainTextPipe}.
     */
    @Input() inputFormatter?: (item: Model | ResultItem) => string;

    /**
     * Model value passed as `ngModel` to the input element, updated whenever the input model updates.
     */
    @Input() model?: Model | ResultItem;

    /**
     * Emits an event whenever the model value changes. Emits `undefined` if there is no selected value.
     *
     * Note that if the `editable` property is `true`, this can emit the current string value in the input element
     * while the user types, but if `editable` is `false` (default), `undefined` (or nothing) is emitted instead.
     * Setting `editable` to true should only be done if the `ResultItem` type is string.
     */
    @Output() modelChange = new EventEmitter<ResultItem | undefined>();

    /**
     * A name attribute value to set on the input element.
     */
    @Input() name?: string;

    /**
     * A placeholder text to show in the input element.
     */
    @Input() placeholder?: string;

    /**
     * Whether empty values are disallowed in the input field.
     */
    @Input() required = false;

    /**
     * The function that converts an item from the result list to a string to display in the popup.
     *
     * Must be provided if the `typeahead` function returns something other than `Observable<string[]>`.
     *
     * Alternatively for more complex markup in the popup you should use the `resultTemplate` property.
     */
    @Input() resultFormatter?: (item: ResultItem) => string;

    /**
     * The template to override the way resulting items are displayed in the popup. The default template renders the items as HTML.
     */
    @Input() resultTemplate?: TemplateRef<ResultTemplateContext>;

    /**
     * If this component is used an element in a form, this should be set to FormControl to it, and NgModel should be left undefined.
     */
    @Input() control?: FormControl;

    /**
     * An event emitted right before an item is selected from the result list. Note that this event is not emitted
     * if the user clears the selection; for that, use {@link modelChange}. This event is mostly useful if you need
     * to prevent items from being selected; you can do so with {@link NgbTypeaheadSelectItemEvent#preventDefault}.
     *
     * The event payload is of type {@link NgbTypeaheadSelectItemEvent}.
     */
    @Output() selectItem = new EventEmitter<NgbTypeaheadSelectItemEvent>();

    /**
     * The function that converts a stream of text values from the input element to the stream of the array of
     * items to display in the typeahead popup.
     *
     * If the resulting observable emits a non-empty array, the popup will be shown. If it emits an empty array,
     * the popup will be closed.
     *
     * Note that the `this` argument is `undefined` so you need to explicitly bind it to a desired "this" target.
     */
    @Input() typeahead: (text$: Observable<string>) => Observable<ResultItem[]>;

    /**
     * Emits the trigger when the focus is removed from the model -input (not control).
     */
    @Output() blurEvent = new EventEmitter<any>();

    validationErrors: SisValidationErrors;
    /**
     * Tracks the selected model value even if a model is not given as an input
     */
    modelValueSelected: boolean;
    @ViewChild('inputElement') inputElement: ElementRef;

    /**
     * @param changeDetectorRef handle to trigger change detection manually: keyboard navigation in typeahead results list works very slowly due to change detection
     *                          slowness by default, and forcing change detection on keydown events seems to solve it
     * @param logger an {@link NGXLogger} instance
     * @param plainTextPipe pipe used for removing HTML tags
     */
    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private logger: NGXLogger,
        private plainTextPipe: PlainTextPipe,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        const modelValue = changes?.model;
        // Initial model might be given with delay, and it won't trigger a model change event
        if (modelValue?.currentValue) {
            this.modelValueSelected = true;
        }
    }

    // This has to be an arrow function since ng-bootstrap 10.0.0 (or after 9.1.0) for change detection to work
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/4055
    debouncedTypeahead: OperatorFunction<string, readonly ResultItem[]> = (text$: Observable<string>): Observable<ResultItem[]> => {
        if (!this.typeahead) {
            this.logger.warn('No typeahead function set');
            return EMPTY;
        }
        return this.typeahead(text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
        ));
    };

    formatInput(item: Model | ResultItem): string {
        return this.plainTextPipe.transform(this.inputFormatter ? this.inputFormatter(item) : item);
    }

    checkSearchLength($event: KeyboardEvent) {
        const target = $event.target as HTMLInputElement;

        if (target.value.length > TYPEAHEAD_MAX_LENGTH) {
            this.validationErrors = {
                maxLength: {
                    translationKey: 'INPUT_ERRORS.SEARCH_QUERY_TEXT_TOO_LONG',
                },
            };
        } else {
            this.validationErrors = omit(this.validationErrors, ['maxLength']);
        }
    }

    onModelChange($event: ResultItem) {
        this.modelValueSelected = !!$event;
        this.modelChange.emit($event);
    }

    /**
     * Sets any value currently in control or model to undefined. Also clears the value in
     * the native input element in case the typeahead is used without a model or a form control.
     * Moves keyboard focus to the input.
     */
    clear(): void {
        // Clear and emit updated value for control or model
        if (this.control) {
            this.control.patchValue(undefined);
        }
        if (this.model) {
            this.model = undefined;
        }
        // There are some usages that rely only on modelChange-events without
        // passing a model or a form control.
        this.inputElement.nativeElement.value = '';
        this.onModelChange(undefined);
        // Move focus back to input as the clear-button will disappear
        this.inputElement.nativeElement.focus();
    }
}

/**
 * A commonly usable operator for the typeahead text input stream: filters the input stream, requiring a minimum number of characters (3 by default).
 *
 * Usage example:
 * <pre>
 * search(text$: Observable<string>) {
 *     return text$.pipe(
 *         filterInput(),
 *         // Do something ...
 *     );
 * }
 * </pre>
 *
 * @param textMinLength the minimum number of input characters to require (default 3)
 * @param textMaxLength the maximum number of input characters allowed (default 255)
 */
export function filterInput(textMinLength = 3, textMaxLength = TYPEAHEAD_MAX_LENGTH) {
    return (observable: Observable<string>) =>
        observable.pipe(
            filter(text => text.length >= textMinLength),
            filter(text => text.length <= textMaxLength),
        );
}
