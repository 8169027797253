import { inject } from '@angular/core';
import {
    CommonSearchFilters,
    DeepPartial,
    OtmId,
    SearchOptions,
    SearchParameters,
    SortOrder,
} from 'common-typescript/types';
import { isEmpty, isEqual, isNil, isObject, isString, omitBy } from 'lodash-es';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { SessionStorageService } from 'sis-common/storage/session-storage.service';

import { UniversityService } from '../service/university.service';

import { DEFAULT_SEARCH_LIMIT } from './search-constants';

/**
 * Interface for providing configuration to SearchService instance.
 */
export interface SearchServiceConfig<T> {
    /**
     * Mandatory key to be used for view specific session storage.
     *
     * NOTE! When needed, this should be refactored to optional parameter to support SearchService usage without
     * storing values.
     */
    storageKey: string
    /**
     * If se to true, enables `universityOrgId` -parameter. Disabled by default. If enabled, current university is used
     * by default.
     */
    universityOrgIdEnabled?: boolean
    /**
     * Initial filters to be used in case no stored values are found.
     */
    defaultFilters?: T
    /**
     * Initial options to be used in case no stored values are found.
     */
    defaultOptions?: Partial<SearchOptions>
}

/**
 * Service for storing (session storage) and updating search parameters. Also provides an observable for listening
 * search parameter changes.
 *
 * NOTE! Create only view specific instances of this service. This can be done using component specific dependency
 * provider.
 */
export class SearchService<T extends CommonSearchFilters> {

    private readonly localeService = inject(LocaleService);
    private readonly storageService = inject(SessionStorageService);
    private readonly universityService = inject(UniversityService);

    private readonly searchParametersSubject: BehaviorSubject<SearchParameters<T>>;

    /** Emits the search parameters whenever they change */
    readonly searchParameters$: Observable<SearchParameters<T>>;

    /** A convenience observable which emits the `fullTextQuery` filter value whenever it changes */
    readonly fullTextQuery$: Observable<string | null>;

    /** A convenience observable which emits the current sort order whenever it changes */
    readonly sort$: Observable<string | null>;

    /** Same as `sort$`, but emits the sort order in a structured format */
    readonly sortOrder$: Observable<SortOrder | null>;

    /**
     * A convenience observable which emits the university id. If university selection has been enabled
     * for the current search functionality ({@link SearchServiceConfig#universityOrgIdEnabled}), emits the
     * `universityOrgId` filter value whenever it changes. Otherwise, emits the current university id.
     */
    readonly universityOrgId$: Observable<OtmId | null>;

    constructor(private readonly config: Readonly<SearchServiceConfig<T>>) {
        if (this.isStoreEmpty()) {
            this.storeInitialParameters();
        } else {
            this.storeUniversityOrgId();
        }
        this.searchParametersSubject = new BehaviorSubject(this.getStoredParameters());

        this.searchParameters$ = this.searchParametersSubject.asObservable();
        this.fullTextQuery$ = this.searchParameters$
            .pipe(
                map(params => params?.filters?.fullTextQuery ?? null),
                distinctUntilChanged(),
            );
        this.sort$ = this.searchParameters$
            .pipe(
                map(params => params?.options?.sort?.[0] ?? null),
                distinctUntilChanged(),
            );
        this.sortOrder$ = this.sort$
            .pipe(
                map(key => !key ? null : ({
                    orderKey: key.replace(/^-/, ''),
                    reverse: key.startsWith('-'),
                })),
            );
        this.universityOrgId$ = iif(
            () => config.universityOrgIdEnabled,
            this.searchParameters$
                .pipe(
                    map(params => params?.filters?.universityOrgId ?? null),
                    distinctUntilChanged(),
                ),
            of(this.universityService.getCurrentUniversityOrgId()),
        );
    }

    /**
     * Partially updates filters of stored parameters. Resets pagination (start=0).
     *
     * @param patchedFilters
     */
    patchFilters(patchedFilters: Partial<T>) {
        const oldParameters = this.searchParametersSubject.value;
        const newParameters = this.patchParameters(oldParameters, { filters: patchedFilters });

        if (!this.isEqualOmitNilAndEmpty(oldParameters, newParameters)) {
            newParameters.options.start = 0;
            this.setStoredParameters(newParameters);
            this.searchParametersSubject.next(newParameters);
        }
    }

    /**
     * Overwrites filters of stored parameters. Resets pagination (start=0).
     *
     * @param newFilters
     */
    updateFilters(newFilters: T) {
        const oldParameters = this.searchParametersSubject.value;

        if (!this.isEqualOmitNilAndEmpty({ filters: oldParameters?.filters }, { filters: newFilters })) {
            const newParameters = {
                options: {
                    ...oldParameters?.options,
                    start: 0,
                },
                filters: newFilters,
            };
            this.setStoredParameters(newParameters);
            this.searchParametersSubject.next(newParameters);
        }
    }

    /**
     * Partially updates options of stored parameters.
     *
     * @param patchedOptions
     */
    patchOptions(patchedOptions: Partial<SearchOptions>) {
        const oldParameters = this.searchParametersSubject.value;
        const newParameters = this.patchParameters(oldParameters, { options: patchedOptions });

        if (!this.isEqualOmitNilAndEmpty(oldParameters, newParameters)) {
            this.setStoredParameters(newParameters);
            this.searchParametersSubject.next(newParameters);
        }
    }

    /**
     * Sorts search with the given key. Reverse sorts if already sorted with the key. Supports currently sorting only
     * with one key.
     *
     * @param orderKey
     */
    sort(orderKey: string) {
        this.patchOptions({
            sort: this.searchParametersSubject.value?.options?.sort?.[0] === orderKey ? [`-${orderKey}`] : [orderKey],
        });
    }

    private patchParameters(current: SearchParameters<T>, patch: DeepPartial<SearchParameters<T>>): SearchParameters<T> {
        return {
            options: {
                ...current?.options,
                ...patch?.options,
            },
            filters: {
                ...current?.filters,
                ...patch?.filters,
            },
        };
    }

    private isEqualOmitNilAndEmpty(a: DeepPartial<SearchParameters<T>>, b: DeepPartial<SearchParameters<T>>): boolean {
        const isNilOrEmpty = (value: unknown) => isNil(value) || ((isObject(value) || isString(value)) && isEmpty(value));
        return isEqual(
            { options: omitBy(a.options, isNilOrEmpty), filters: omitBy(a.filters, isNilOrEmpty) },
            { options: omitBy(b.options, isNilOrEmpty), filters: omitBy(b.filters, isNilOrEmpty) },
        );
    }

    private storeInitialParameters() {
        this.setStoredParameters({
            filters: { ...this.defaultFilters(), ...this.config.defaultFilters },
            options: { ...this.defaultOptions(), ...this.config.defaultOptions },
        });
    }

    /**
     * Keeps `universityOrgId` up-to-date in store. Otherwise, things might break if new version enables/disables
     * `config.universityOrgIdEnabled` and some user has old conflicting value in store.
     *
     * @private
     */
    private storeUniversityOrgId() {
        const parameters = this.getStoredParameters();
        if (this.config.universityOrgIdEnabled) {
            parameters.filters.universityOrgId = parameters?.filters?.universityOrgId || this.universityService.getCurrentUniversityOrgId();
        } else {
            parameters.filters.universityOrgId = undefined;
        }
        this.setStoredParameters(parameters);
    }

    private defaultFilters(): T {
        return {
            universityOrgId: this.config.universityOrgIdEnabled
                ? this.universityService.getCurrentUniversityOrgId() : undefined,
        } as T;
    }

    private defaultOptions(): SearchOptions {
        return {
            limit: DEFAULT_SEARCH_LIMIT,
            start: 0,
            uiLang: this.localeService.getCurrentLanguage(),
        };
    }

    private isStoreEmpty(): boolean {
        return !this.storageService.getItem(this.config.storageKey);
    }

    private getStoredParameters(): SearchParameters<T> {
        return this.storageService.getValueOrDefault(this.config.storageKey, {
            options: this.defaultOptions(),
            filters: this.defaultFilters(),
        });
    }

    private setStoredParameters(parameters: SearchParameters<T>): void {
        this.storageService.setValue(this.config.storageKey, parameters);
    }
}
