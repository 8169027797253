import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AttainmentNode,
    AttainmentReferenceNode,
    CourseUnitAttainment,
    ModuleAttainment,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { isCustomCourseUnitAttainment, isCustomModuleAttainment } from '../../attainment/AttainmentUtil';
import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { UiStateObject } from '../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-node-structure',
    templateUrl: './attainment-node-structure.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class AttainmentNodeStructureComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.attainmentNodeStructure',
        directiveName: 'sisAttainmentNodeStructure',
    };

    nodes: any[];

    @Input() attainmentNodes: AttainmentNode[];
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;

    readonly isCustomCourseUnitAttainment = isCustomCourseUnitAttainment;
    readonly isCustomModuleAttainment = isCustomModuleAttainment;

    ngOnInit() {
        this.nodes = [];
        _.forEach(this.attainmentNodes, (node) => {
            if (node.type === 'AttainmentGroupNode') {
                this.nodes.push(node);
            }
            if (node.type === 'AttainmentReferenceNode') {
                const attainment = this.planData.attainmentsById[(node as AttainmentReferenceNode).attainmentId];

                if (attainment.type === 'CourseUnitAttainment') {
                    this.nodes.push({ type: 'CourseUnit', courseUnitId: (attainment as CourseUnitAttainment).courseUnitId });
                }
                if (attainment.type === 'ModuleAttainment') {
                    this.nodes.push({ type: 'Module', moduleId: (attainment as ModuleAttainment).moduleId });
                }
                if (attainment.type === 'CustomCourseUnitAttainment') {
                    this.nodes.push({ type: 'CustomCourseUnitAttainment', customCourseUnitAttainmentId: attainment.id });
                }
                if (attainment.type === 'CustomModuleAttainment') {
                    this.nodes.push({ type: 'CustomModuleAttainment', customModuleAttainmentId: attainment.id });
                }
            }
        });
    }
}
