import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Attainment,
    CourseUnit,
    CourseUnitAttainment,
    GradeRaiseAttempt,
    RdiCreditsEnabled,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, of, switchMap, take } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { PrimaryPlanService } from 'sis-components/service/primary-plan.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

@Component({
    selector: 'app-course-unit-attainment-details',
    templateUrl: './course-unit-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitAttainmentDetailsComponent implements OnInit {
    rdiCreditsEnabled: RdiCreditsEnabled;
    completionMethodsAvailable: boolean = false;
    gradeRaiseCourseUnitVersion$: Observable<CourseUnit>;
    gradeRaiseAttemptEnabled: boolean = false;
    isCrossStudyCourseUnit: boolean = false;
    planHref = '/student/plan';

    @Input() attainment: CourseUnitAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() modalRef: NgbActiveModal;
    @Input() expirationImminent: boolean;
    @Input() gradeRaiseEnabled: boolean;
    @Input() gradeRaiseInfoEnabled: boolean;
    @Input() gradeRaiseAttempt: GradeRaiseAttempt;
    @Input() isAttachedToPrimaryAttainment: boolean = false;

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private universityService: UniversityService,
        private appErrorHandler: AppErrorHandler,
        private studyRightEntityService: StudyRightEntityService,
        private primaryPlanService: PrimaryPlanService,
    ) { }

    ngOnInit() {
        this.getGradeRaiseAttemptEnabledToggle();
        this.setPlanHref();
        this.courseUnitEntityService.getById(this.attainment.courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((courseUnit: CourseUnit) => {
                this.isCrossStudyCourseUnit = !courseUnit?.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
                this.rdiCreditsEnabled = courseUnit.rdiCreditsEnabled;
                this.completionMethodsAvailable = courseUnit.completionMethods.length > 0;
                if (this.gradeRaiseAttempt && this.gradeRaiseAttempt.courseUnitId !== courseUnit.id) {
                    this.gradeRaiseCourseUnitVersion$ = this.courseUnitEntityService.getById(this.gradeRaiseAttempt.courseUnitId)
                        .pipe(
                            take(1),
                            this.appErrorHandler.defaultErrorHandler());
                } else {
                    this.gradeRaiseCourseUnitVersion$ = of(courseUnit);
                }
            });
    }

    gradeRaiseButtonTranslationKey(): string {
        return this.gradeRaiseAttempt ? 'ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_CHANGE_COMPLETION_METHOD'
            : 'ATTAINMENTS.ATTAINMENT_DETAILS.GRADE_RAISE_ATTEMPT_BUTTON';
    }

    getCompletionMethodIndexForGradeRaise(courseUnit: CourseUnit): number {
        const index = courseUnit.completionMethods.map(completionMethod =>
            completionMethod.localId).indexOf(this.gradeRaiseAttempt.completionMethodId);
        return index >= 0 ? index + 1 : undefined;
    }

    private getGradeRaiseAttemptEnabledToggle() {
        this.universityService.getCurrentUniversitySettings()
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            ).subscribe((universitySettings) => {
                this.gradeRaiseAttemptEnabled = _.get(
                    universitySettings,
                    'frontendFeatureToggles.gradeRaiseAttemptEnabled',
                    false,
                );
            });
    }

    private setPlanHref() {
        if (this.gradeRaiseInfoEnabled && this.attainment.studyRightId) {
            this.studyRightEntityService.getById(this.attainment.studyRightId)
                .pipe(
                    take(1),
                    switchMap((studyRight) => this.primaryPlanService.getPrimaryPlanForStudyRight(studyRight)),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((plan) => {
                    if (plan) {
                        this.planHref = `/student/structure/${plan.id}`;
                    }
                });
        }
    }
}
