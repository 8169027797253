import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { CustomStudyDraft, EntityWithRule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { PlanManager, PlanOperationType } from 'sis-components/plan/plan-manager/plan-manager.service';
import { ReadMoreModalService } from 'sis-components/read-more-ng/read-more-modal.service';
import { ReadMoreValues } from 'sis-components/read-more-ng/read-more-ng-modal/read-more-ng-modal.component';
import { StudyBoxType } from 'sis-components/study-boxes/study-box/study-box.component';

import {
    CreateCustomStudyDraftModalComponent,
} from '../../../../../plan/create-custom-study-draft-modal/create-custom-study-draft-modal.component';

@Component({
    selector: 'app-free-edit-custom-study-draft-expandable',
    templateUrl: './free-edit-custom-study-draft-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FreeEditCustomStudyDraftExpandableComponent implements OnInit {
    @Input() module: EntityWithRule;
    @Input() validatablePlan: ValidatablePlan;

    private dialogService = inject(FudisDialogService);

    protected readonly StudyBoxType = StudyBoxType;
    checkedCustomStudyDrafts: CustomStudyDraft[] = [];
    allCustomStudyDrafts: CustomStudyDraft[] = [];

    constructor(
        private readMoreModalService: ReadMoreModalService,
        private planManager: PlanManager,
        private translocoService: TranslocoService) {}

    ngOnInit(): void {
        this.checkedCustomStudyDrafts = this.getCustomStudyDrafts(this.validatablePlan);
        this.allCustomStudyDrafts = this.getCustomStudyDrafts(this.validatablePlan);
    }

    openReadMoreAboutStudyDraftModal() {
        this.readMoreModalService.open(this.getReadMoreOptions());
    }

    getReadMoreOptions(): ReadMoreValues {
        return {
            options: {
                contentHtml: null,
                contentTranslateKey: 'PLAN.READ_MORE',
                contentTranslateParameters: null,
                title: this.translocoService.translate('PLAN.CUSTOM_STUDY_DRAFTS_TITLE'),
            },
        };
    }

    openAddStudyDraftModal() {
        this.dialogService.open(CreateCustomStudyDraftModalComponent, { data: { selectedModuleId: this.module.id } })
            .afterClosed()
            .subscribe((result) => {
                if (_.get(result, 'operation') === 'ADD' && _.get(result, 'customStudyDraft')) {
                    this.planManager.processPlanOperation(
                        {
                            parentModule: this.module,
                            planOperationType: PlanOperationType.ADD_CUSTOM_STUDY_DRAFT,
                            target: result.customStudyDraft,
                            parentCourseUnit: null,
                        },
                    ).then(() => {
                        this.allCustomStudyDrafts.push(result.customStudyDraft);
                        this.checkedCustomStudyDrafts.push(result.customStudyDraft);
                    });
                }
            });
    }

    customStudyDraftIsChecked(customStudyDraft: CustomStudyDraft): boolean {
        return _.some(this.checkedCustomStudyDrafts, { id: customStudyDraft.id });
    }

    handleToggle(customStudyDraft: CustomStudyDraft): void {
        if (this.customStudyDraftIsChecked(customStudyDraft)) {
            this.planManager.processPlanOperation(
                {
                    parentModule: this.module,
                    planOperationType: PlanOperationType.UNSELECT_CUSTOM_STUDY_DRAFT_BY_ID,
                    target: customStudyDraft.id,
                    parentCourseUnit: null,
                },
            ).then(() => _.remove(this.checkedCustomStudyDrafts, (csd) => csd.id === customStudyDraft.id));
        } else {
            this.planManager.processPlanOperation(
                {
                    parentModule: this.module,
                    planOperationType: PlanOperationType.ADD_CUSTOM_STUDY_DRAFT,
                    target: customStudyDraft,
                    parentCourseUnit: null,
                },
            ).then(() => this.checkedCustomStudyDrafts.push(customStudyDraft));
        }
    }

    getCustomStudyDrafts(validatablePlan: ValidatablePlan): CustomStudyDraft[] {
        return validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.module.id);
    }

}
