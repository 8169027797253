import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnit, PersonWithModuleResponsibilityInfoType } from 'common-typescript/types';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-course-unit-info-brochure-container',
    templateUrl: './course-unit-info-brochure-container.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoBrochureContainerComponent {
    @Input() language: string;

    @Input() set courseUnit(courseUnit: CourseUnit) {
        this._courseUnit = courseUnit;
        this.sortedByNameResponsibilityInfos = this.sortResponsibilityInfos();
    }

    get courseUnit() {
        return this._courseUnit;
    }

    sortedByNameResponsibilityInfos: PersonWithModuleResponsibilityInfoType[];

    private _courseUnit: CourseUnit;

    // sort responsibility infos by responsible-teacher > contact-info-role first and then within each role responsibility infos with personId reference first
    sortResponsibilityInfos() {
        return _.chain(this._courseUnit.responsibilityInfos)
            .filter(info => _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person')
            .orderBy(
                [
                    info => info.roleUrn === 'urn:code:module-responsibility-info-type:contact-info',
                    info => !info.personId,
                    info => _.upperCase(_.get(info, 'person.fullName')),
                ],
            )
            .value();
    }
}
