import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
/**
 * This is simple version of radio button component that looks like fudis radio-button.
 * Reason for this components existence is that it does not require usage of controls.
 */
@Component({
    selector: 'sis-simple-radio-button',
    templateUrl: './simple-radio-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SimpleRadioButtonComponent {

    @Input() label: string;
    @Input() name: string;
    @Input() selected: boolean;
    @Input() id: string;
    @Output() selectedChange = new EventEmitter<any>();

    /**
     * Extra info after label only accessible to screen reader users.
     */
    @Input() visuallyHiddenInfo?: string;
}
