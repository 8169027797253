import angular from 'angular';
import * as _ from 'lodash-es';
export const rawPlanEditServiceModule = 'student.common.service.rawPlanEdit';
(function () {
  rawPlanEditService.$inject = ["$q", "attainmentService", "validAttainmentFilterService", "gradeScaleJSDataModel"];
  angular.module('student.common.service.rawPlanEdit', ['student.common.service.attainmentService', 'sis-components.service.validAttainmentFilterService', 'sis-components.model.gradeScale']).factory('rawPlanEditService', rawPlanEditService);
  function rawPlanEditService($q, attainmentService, validAttainmentFilterService, gradeScaleJSDataModel) {
    var api = {
      selectCourseUnit: function (courseUnitId, parentModuleId, rawPlan) {
        if (!_.get(rawPlan, 'courseUnitSelections')) {
          rawPlan.courseUnitSelections = [];
        }
        const existingSelection = _.find(rawPlan.courseUnitSelections, {
          courseUnitId
        });
        if (existingSelection) {
          return false;
        }
        rawPlan.courseUnitSelections.push({
          courseUnitId: courseUnitId,
          parentModuleId: parentModuleId
        });
        return true;
      },
      removeCourseUnit: function (courseUnitId, rawPlan) {
        _.remove(rawPlan.courseUnitSelections, {
          courseUnitId: courseUnitId
        });
        var assessmentItemSelections = _.get(rawPlan, 'assessmentItemSelections');
        if (assessmentItemSelections) {
          _.remove(assessmentItemSelections, {
            courseUnitId: courseUnitId
          });
        }
      },
      selectCustomCourseUnitAttainment: function (id, parentModuleId, rawPlan) {
        if (!_.get(rawPlan, 'customCourseUnitAttainmentSelections')) {
          rawPlan.customCourseUnitAttainmentSelections = [];
        }
        const existingSelection = _.find(rawPlan.customCourseUnitAttainmentSelections, {
          customCourseUnitAttainmentId: id
        });
        if (existingSelection) {
          return false;
        }
        rawPlan.customCourseUnitAttainmentSelections.push({
          customCourseUnitAttainmentId: id,
          parentModuleId: parentModuleId
        });
        return true;
      },
      removeCustomCourseUnitAttainment: function (customCourseUnitAttainmentId, rawPlan) {
        _.remove(rawPlan.customCourseUnitAttainmentSelections, {
          customCourseUnitAttainmentId: customCourseUnitAttainmentId
        });
      },
      selectModule: function (moduleId, parentModuleId, rawPlan) {
        if (!_.get(rawPlan, 'moduleSelections')) {
          rawPlan.moduleSelections = [];
        }
        const existingSelection = _.find(rawPlan.moduleSelections, {
          moduleId
        });
        if (existingSelection) {
          return false;
        }
        rawPlan.moduleSelections.push({
          moduleId: moduleId,
          parentModuleId: parentModuleId
        });
        return true;
      },
      removeModule: function (moduleId, rawPlan) {
        removeModuleRecursively(moduleId, rawPlan);
      },
      selectCustomModuleAttainment: function (customModuleAttainmentId, parentModuleId, rawPlan) {
        if (!_.get(rawPlan, 'customModuleAttainmentSelections')) {
          rawPlan.customModuleAttainmentSelections = [];
        }
        const existingSelection = _.find(rawPlan.customModuleAttainmentSelections, {
          customModuleAttainmentId
        });
        if (existingSelection) {
          return false;
        }
        rawPlan.customModuleAttainmentSelections.push({
          customModuleAttainmentId: customModuleAttainmentId,
          parentModuleId: parentModuleId
        });
        return true;
      },
      removeCustomModuleAttainment: function (customModuleAttainmentId, rawPlan) {
        _.remove(rawPlan.customModuleAttainmentSelections, {
          customModuleAttainmentId: customModuleAttainmentId
        });
      },
      getSelectableAttainedCourseUnits: function (allUnattachedAttainments, validatablePlan) {
        var selectableAttainments = _.filter(allUnattachedAttainments, function (attainment) {
          return attainment.type === 'CourseUnitAttainment' && !api.isCourseUnitInPlan(attainment.courseUnitGroupId, validatablePlan);
        });
        return _.map(selectableAttainments, function (courseUnitAttainment) {
          return _.get(validatablePlan.courseUnitsById, courseUnitAttainment.courseUnitId);
        });
      },
      getSelectableCustomCourseUnitAttainments: function (allUnattachedAttainments, validatablePlan, matchingStudyRight) {
        var studyRightId = _.get(matchingStudyRight, 'id');
        return _.filter(allUnattachedAttainments, function (attainment) {
          return attainment.type === 'CustomCourseUnitAttainment' && !isCustomCourseUnitAttainmentInPlan(attainment.id, validatablePlan) && (
          // Consider attainments without studyRightId as not bound to any study right and include them
          !attainment.studyRightId || attainment.studyRightId === studyRightId);
        });
      },
      getSelectableAttainedModules: function (allUnattachedAttainments, validatablePlan) {
        var selectableAttainments = _.filter(allUnattachedAttainments, function (attainment) {
          return _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type')) && !api.isModuleInPlan(attainment.moduleGroupId, validatablePlan);
        });
        return _.map(selectableAttainments, function (moduleAttainment) {
          return _.get(validatablePlan.modulesById, moduleAttainment.moduleId);
        });
      },
      getSelectableCustomModuleAttainments: function (allUnattachedAttainments, validatablePlan, matchingStudyRight) {
        var studyRightId = _.get(matchingStudyRight, 'id');
        var selectableAttainments = _.filter(allUnattachedAttainments, function (attainment) {
          return attainment.type === 'CustomModuleAttainment' && !isCustomModuleAttainmentInPlan(attainment.id, validatablePlan) && (!attainment.studyRightId || attainment.studyRightId === studyRightId);
        });
        return selectableAttainments;
      },
      getAllUnattachedAttainments: function () {
        return attainmentService.getMyValidAttainments().then(function (allAttainments) {
          return _.filter(allAttainments, function (attainment) {
            return !validAttainmentFilterService.isAttached(attainment, allAttainments);
          });
        });
      },
      loadGradeScales: function (gradeScaleIds) {
        var gradeScalesById = {};
        var promises = [];
        _.forEach(gradeScaleIds, function (gradeScaleId) {
          var promise = getGradeScale(gradeScaleId).then(function (gradeScale) {
            gradeScalesById[gradeScale.id] = gradeScale;
          });
          promises.push(promise);
        });
        return $q.all(promises).then(function () {
          return gradeScalesById;
        });
      },
      getSelectedCustomCourseUnitAttainmentsByParentModuleId: function (parentModuleId, rawPlan) {
        var customCourseUnitAttainmentSelections = _.filter(rawPlan.customCourseUnitAttainmentSelections, {
          parentModuleId: parentModuleId
        });
        return _.map(customCourseUnitAttainmentSelections, 'customCourseUnitAttainmentId');
      },
      getSelectedCustomModuleAttainmentsByParentModuleId: function (parentModuleId, rawPlan) {
        var customModuleAttainmentSelections = _.filter(rawPlan.customModuleAttainmentSelections, {
          parentModuleId: parentModuleId
        });
        return _.map(customModuleAttainmentSelections, 'customModuleAttainmentId');
      },
      isCourseUnitInPlan: function (groupId, validatablePlan) {
        var courseUnitsInPlanMap = _.filter(_.values(validatablePlan.courseUnitsById), {
          groupId: groupId
        });
        if (_.isEmpty(courseUnitsInPlanMap)) {
          return false;
        }
        var courseUnitInPlan = _.find(courseUnitsInPlanMap, function (courseUnit) {
          return validatablePlan.isCourseUnitInPlan(courseUnit) || validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit);
        });
        return !!courseUnitInPlan;
      },
      isModuleInPlan: function (groupId, validatablePlan) {
        var modulesInPlanMap = _.filter(_.values(validatablePlan.modulesById), {
          groupId: groupId
        });
        if (_.isEmpty(modulesInPlanMap)) {
          return false;
        }
        var moduleInPlan = _.find(modulesInPlanMap, function (module) {
          return validatablePlan.isModuleInPlan(module.id);
        });
        return !!moduleInPlan;
      },
      findAttainedVersionIdForCourseUnit: function (courseUnit, allAttainments) {
        var attainment = _.find(allAttainments, function (attainment) {
          return attainment.type === 'CourseUnitAttainment' && _.get(attainment, 'courseUnitGroupId') === courseUnit.groupId;
        });
        return !!attainment ? attainment.courseUnitId : undefined;
      },
      findAttainedVersionIdForModule: function (module, allAttainments) {
        var attainment = _.find(allAttainments, function (attainment) {
          return _.includes(['ModuleAttainment', 'DegreeProgrammeAttainment'], _.get(attainment, 'type')) && _.get(attainment, 'moduleGroupId') === module.groupId;
        });
        return !!attainment ? attainment.moduleId : undefined;
      },
      addCustomStudyDraft: (studyDraft, rawPlan) => {
        rawPlan.customStudyDrafts.push(studyDraft);
        return rawPlan;
      },
      removeCustomStudyDraft: (studyDraftId, rawPlan) => {
        _.remove(rawPlan.customStudyDrafts, {
          id: studyDraftId
        });
        return rawPlan;
      }
    };
    return api;
    function getCourseUnitIdsByParentModuleId(parentModuleId, rawPlan) {
      return _.map(_.filter(_.get(rawPlan, 'courseUnitSelections', []), {
        parentModuleId: parentModuleId
      }), 'courseUnitId');
    }
    function getModuleIdsByParentModuleId(parentModuleId, rawPlan) {
      return _.map(_.filter(_.get(rawPlan, 'moduleSelections', []), {
        parentModuleId: parentModuleId
      }), 'moduleId');
    }
    function getCustomCourseUnitAttainmentIdsByParentModuleId(parentModuleId, rawPlan) {
      return _.map(_.filter(_.get(rawPlan, 'customCourseUnitAttainmentSelections', []), {
        parentModuleId: parentModuleId
      }), 'customCourseUnitAttainmentId');
    }
    function getCustomModuleAttainmentIdsByParentModuleId(parentModuleId, rawPlan) {
      return _.map(_.filter(_.get(rawPlan, 'customModuleAttainmentSelections', []), {
        parentModuleId: parentModuleId
      }), 'customModuleAttainmentId');
    }
    function removeModuleRecursively(moduleId, rawPlan) {
      var childModuleIds = getModuleIdsByParentModuleId(moduleId, rawPlan);
      _.forEach(childModuleIds, function (moduleId) {
        removeModuleRecursively(moduleId, rawPlan);
      });
      var childCourseUnitIds = getCourseUnitIdsByParentModuleId(moduleId, rawPlan);
      _.forEach(childCourseUnitIds, function (courseUnitId) {
        api.removeCourseUnit(courseUnitId, rawPlan);
      });
      var childCustomModuleAttainmentIds = getCustomModuleAttainmentIdsByParentModuleId(moduleId, rawPlan);
      _.forEach(childCustomModuleAttainmentIds, function (childCustomModuleAttainmentId) {
        api.removeCustomModuleAttainment(childCustomModuleAttainmentId, rawPlan);
      });
      var childCustomCourseUnitAttainmentIds = getCustomCourseUnitAttainmentIdsByParentModuleId(moduleId, rawPlan);
      _.forEach(childCustomCourseUnitAttainmentIds, function (childCustomCourseUnitAttainmentId) {
        api.removeCustomCourseUnitAttainment(childCustomCourseUnitAttainmentId, rawPlan);
      });
      _.remove(rawPlan.moduleSelections, {
        moduleId: moduleId
      });
    }
    function isCustomCourseUnitAttainmentInPlan(id, validatablePlan) {
      var customCourseUnitAttainment = _.get(validatablePlan.customCourseUnitAttainmentsById, id);
      if (customCourseUnitAttainment) {
        return validatablePlan.isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment);
      }
      return false;
    }
    function isCustomModuleAttainmentInPlan(id, validatablePlan) {
      return validatablePlan.isModuleAttainmentInPlan(id);
    }
    function getGradeScale(gradeScaleId) {
      return gradeScaleJSDataModel.find(gradeScaleId);
    }
  }
})();