import angular from 'angular';
import * as _ from 'lodash-es';
import { commonUniversityServiceModule } from 'sis-common/university/university.service';
import { searchEventConstantModule } from './searchEvent.constant';
import { searchParameterServiceModule } from './searchParameters';
export const searchComponentUtilsServiceModule = 'sis-component.search.searchComponentUtilsService';
(function () {
  searchComponentUtilsService.$inject = ["universityService", "SearchParameters", "SEARCH_EVENT", "SearchParameter", "OrParameter", "$translate"];
  angular.module(searchComponentUtilsServiceModule, [commonUniversityServiceModule, searchParameterServiceModule, searchEventConstantModule]).factory('searchComponentUtilsService', searchComponentUtilsService);
  function searchComponentUtilsService(universityService, SearchParameters, SEARCH_EVENT, SearchParameter, OrParameter, $translate) {
    return {
      setUpCtrlForUniversitySelectEditor(ctrl, scope, searchService, localSearchServiceName) {
        ctrl.searchParams = new SearchParameters();
        ctrl.university = universityService.getCurrentUniversity();
        ctrl.searchParams.universityOrgId.toggleValue(ctrl.university);
        ctrl.setUniversity = university => {
          const oldUniId = _.get(ctrl.university, 'id');
          ctrl.university = university;
          ctrl.searchParams.universityOrgId.removeAllValues();
          ctrl.searchParams.universityOrgId.toggleValue(university);
          // Clear organisations and search again only if university changed
          if (oldUniId !== _.get(university, 'id')) {
            ctrl.searchParams.organisations.removeAllValues();
            ctrl.searchParams.organisationRoots.removeAllValues();
            scope.$broadcast(SEARCH_EVENT.SEARCH_AGAIN, 'University has changed');
          }
        };
        ctrl[localSearchServiceName] = {
          search: searchParams => {
            // University that is saved to session storage might be different on first load.
            const university = _.head(searchParams.universityOrgId.getValues());
            if (_.get(university, 'id') !== _.get(ctrl.university, 'id')) {
              ctrl.university = university || ctrl.university;
              ctrl.searchParams.universityOrgId.removeAllValues();
              ctrl.searchParams.universityOrgId.toggleValue(ctrl.university);
            }
            return searchService.search(searchParams);
          }
        };
      },
      getDisplayValueString(parameter) {
        if (!(parameter instanceof SearchParameter)) {
          return undefined;
        }
        const displayValue = parameter.displayValue();
        const separatorKey = parameter instanceof OrParameter ? 'OR_SEPARATOR' : 'AND_SEPARATOR';
        const separator = $translate.instant(`SEARCH.FILTER_TAGS.${separatorKey}`);
        let displayArray;
        let charLength = 0;
        let wordCount = 0;
        const maximumAllowedDisplayLength = 30;
        if (!(displayValue instanceof Array)) {
          // There might be string and object values (e.g. CreditRange) so we need to wrap those
          displayArray = [displayValue];
        } else {
          displayArray = displayValue;
        }
        _.forEach(displayArray, value => {
          charLength += value.length;
          if (charLength < maximumAllowedDisplayLength) {
            wordCount += 1;
          }
        });
        const count = displayArray.length - 1;
        const truncatedHelpTextKey = count === 1 ? 'SEPARATOR_ONE_OTHER' : 'SEPARATOR_OTHERS';
        const truncatedHelpText = $translate.instant(`SEARCH.FILTER_TAGS.${truncatedHelpTextKey}`);
        if (wordCount === 0 && displayArray.length === 1) {
          return _.truncate(displayArray[0], {
            length: maximumAllowedDisplayLength,
            omission: '…',
            separator
          });
        }
        if (wordCount === 0 && displayArray.length > 1) {
          const truncated = _.truncate(displayArray[0], {
            length: maximumAllowedDisplayLength - 12,
            omission: '…',
            separator
          });
          return `${truncated + separator + count} ${truncatedHelpText}`;
        }
        if (wordCount < displayArray.length) {
          return `${displayArray[0] + separator + count} ${truncatedHelpText}`;
        }
        return _.join(displayArray, separator);
      }
    };
  }
})();