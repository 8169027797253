import {
    AutomaticCourseUnitRealisationMessage,
    CourseUnitManualEvaluationRequiredMessage,
    CourseUnitRealisationMessage,
    EnrolmentReminderMessage,
    Message,
    MultiStudentMessage,
    StudentMessage,
} from 'common-typescript/types';

export function isMessage(message: any): message is Message {
    return !!message && 'type' in message && 'categoryType' in message && 'conversationId' in message;
}

export function isAutomaticCourseUnitRealisationMessage(message: any): message is AutomaticCourseUnitRealisationMessage {
    return isMessage(message) && message.type === 'AutomaticCourseUnitRealisationMessage';
}

export function isEnrolmentReminderMessage(message: any): message is EnrolmentReminderMessage {
    return isMessage(message) && message.type === 'EnrolmentReminderMessage';
}

export function hasComment(message: Message): message is StudentMessage {
    return isMessage(message) && 'comment' in message;
}

export function hasLocalizedComment(message: Message): message is CourseUnitRealisationMessage | CourseUnitManualEvaluationRequiredMessage {
    return isMessage(message) && 'localizedComment' in message;
}

export function hasLocalizedTitle(message: Message): message is CourseUnitRealisationMessage {
    return isMessage(message) && 'localizedTitle' in message;
}

export function hasSenderName(message: Message): message is MultiStudentMessage | CourseUnitManualEvaluationRequiredMessage {
    return isMessage(message) && 'senderName' in message;
}

export function hasTitle(message: Message): message is StudentMessage {
    return isMessage(message) && 'title' in message;
}
