import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/core';
import { ValidatablePlan } from 'common-typescript';
import { Attainment, CompletionMethod, CourseUnit, OtmId } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { CourseUnitCrossStudyComponent } from 'sis-components/courseUnit/course-unit-cross-study/course-unit-cross-study.component';
import { PriorLearningSubstitutionsContainerComponent } from 'sis-components/courseUnit/prior-learning-substitutions/prior-learning-substitutions-container/prior-learning-substitutions-container.component';
import { getInjectables } from 'sis-components/router/router-utils';
import { SisStateDeclaration } from 'sis-components/router/types';

import {
    redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt,
} from '../../../open-university/open-university.route-ng';
import {
    OpenUniversityOfferingComponent,
} from '../../../open-university/study-offering/open-university-offering/open-university-offering.component';
import { CompletionMethodsListComponent } from '../course-unit-info/completion-methods-list/completion-methods-list.component';
import { CompletionMethodsNgComponent } from '../course-unit-info/completion-methods-ng/completion-methods-ng.component';
import {
    CourseUnitInfoBrochureContainerComponent,
} from '../course-unit-info/course-unit-info-brochure/course-unit-info-brochure-container/course-unit-info-brochure-container.component';

import {
    attainmentsResolver,
    completionMethodsResolver,
    courseUnitInfoResolver,
    courseUnitRealisationResolver,
    courseUnitResolver,
    forceEnableCalendarAddResolver,
    languageResolver,
    previousStateResolver, productIdResolver,
    resolveRealisationTabId,
    tabIdResolver,
    validatablePlanResolver,
} from './course-unit-info-as-page.resolvers';
import { CourseUnitInfoTabIds } from './course-unit-info-navigation/course-unit-info-navigation.component';
import { CourseUnitInfoComponent } from './course-unit-info/course-unit-info.component';

export interface CourseUnitInfo {
    courseUnit: CourseUnit;
    completionMethods: CompletionMethod[];
    productId: OtmId;
    forceEnableCalendarAdd: boolean;
    validatablePlan: Partial<ValidatablePlan>;
    attainments: Attainment[];
}

export const courseUnitInfoAsPageStates: SisStateDeclaration[] = [
    {
        name: 'student.course-unit-index',
        url: '/courseunit',
        redirectTo: 'student.search',
    },
    {
        name: 'student.course-unit',
        url: '/courseunit/:courseUnitId',
        data: {
            showHeader: false,
            showHome: true,
            hideLastNode: false,
        },
        resolve: {
            courseUnitInfo: courseUnitInfoResolver,
            language: languageResolver,
            courseUnit: courseUnitResolver,
            tabId: tabIdResolver,
            productId: productIdResolver,
            previousState: previousStateResolver,
        },
        views: {
            '': { component: CourseUnitInfoComponent },
        },
        headerParams: {
            skipBreadcrumb: false,
            displayNameFunction: (transition) => {
                const [localeService, translate, courseUnit] = getInjectables(transition, LocaleService, TranslateService, 'courseUnit');
                return `${localeService.localize(courseUnit?.name)} (${courseUnit.credits.min === courseUnit.credits.max ? courseUnit.credits.min : `${courseUnit.credits.min}-${courseUnit.credits.max} ${translate.instant('CREDITS')}`})`;
            },
        },
        redirectTo: 'student.course-unit.brochure',
    },
    {
        name: 'student.course-unit.brochure',
        //  DON'T CHANGE THIS URL! /brochure route is referenced in open university emails, check OpenUniversityEmailContentBuilder.COURSE_UNIT_INFO_LINK_TEMPLATE
        url: '/brochure',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO',
        },
        views: {
            'course-unit-info-tab-content': { component: CourseUnitInfoBrochureContainerComponent },
        },
        redirectTo: redirectWhenMissingCourseUnit,
        params: {
            tabId: CourseUnitInfoTabIds.BROCHURE,
        },
    },
    {
        name: 'student.course-unit.completion-methods',
        url: '/completion-methods?courseUnitRealisation&tabId',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.COMPLETION_METHODS',
        },
        views: {
            'course-unit-info-tab-content': { component: CompletionMethodsNgComponent },
        },
        params: {
            tabId: CourseUnitInfoTabIds.COMPLETION_METHODS,
        },
        resolve: {
            attainments: attainmentsResolver,
            completionMethods: completionMethodsResolver,
            forceEnableCalendarAdd: forceEnableCalendarAddResolver,
            courseUnitRealisation: courseUnitRealisationResolver,
            realisationTabId: resolveRealisationTabId,
        },
        redirectTo: redirectWhenMissingCourseUnit,
    },
    {
        name: 'student.course-unit.completion-methods-list',
        url: '/completion-methods-list',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.COMPLETION_METHODS_2',
        },
        views: {
            'course-unit-info-tab-content': { component: CompletionMethodsListComponent },
        },
        params: {
            tabId: CourseUnitInfoTabIds.COMPLETION_METHODS_2,
        },
        resolve: {
            courseUnit: courseUnitResolver,
        },
        redirectTo: redirectWhenMissingCourseUnit,
    },
    {
        name: 'student.course-unit.substitutions',
        url: '/substitutions',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.SUBSTITUTIONS',
        },
        params: {
            tabId: CourseUnitInfoTabIds.SUBSTITUTIONS,
        },
        resolve: {
            validatablePlan: validatablePlanResolver,
        },
        views: {
            'course-unit-info-tab-content': { component: PriorLearningSubstitutionsContainerComponent },
        },
        redirectTo: redirectWhenMissingCourseUnit,
    },
    {
        name: 'student.course-unit.open-university',
        url: '/open-university',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.OPEN_UNIVERSITY',
        },
        params: {
            tabId: CourseUnitInfoTabIds.OPEN_UNIVERSITY_OFFERING,
        },
        resolve: {
            productSelectionTargetState: () => 'student.course-unit.open-university.product',
        },
        views: {
            'course-unit-info-tab-content': { component: OpenUniversityOfferingComponent },
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.course-unit.brochure', redirectWhenMissingCourseUnit),
    },
    // This route opens the same view as the one above, but allows pre-selecting a product by id.
    // This is defined as a separate route to allow opening the view without a product id as well
    // (UI-Router does not support optional path variables in a route configuration).
    //  DON'T CHANGE THIS URL! /:productId route is referenced in open university emails, check OpenUniversityEmailContentBuilder.PRODUCT_INFO_LINK_TEMPLATE
    {
        name: 'student.course-unit.open-university.product',
        url: '/:productId',
        headerParams: {
            skipBreadcrumb: true,
        },
        params: {
            tabId: CourseUnitInfoTabIds.OPEN_UNIVERSITY_OFFERING,
        },
        views: {
            'course-unit-info-tab-content': { component: OpenUniversityOfferingComponent },
        },
        redirectTo: redirectToStateIfOpenUniversityFeaturesAreNotEnabledForStudentOrAlt('student.course-unit.brochure', redirectWhenMissingCourseUnit),
    },
    {
        name: 'student.course-unit.cross-study',
        url: '/cross-study',
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'STUDIES.CROSS_STUDY.TITLE',
        },
        params: {
            tabId: CourseUnitInfoTabIds.CROSS_STUDY,
        },
        views: {
            'course-unit-info-tab-content': { component: CourseUnitCrossStudyComponent },
        },
        redirectTo: redirectWhenMissingCourseUnit,
    },
];

function redirectWhenMissingCourseUnit(transition: Transition) {
    const errorService = transition.injector().get('errorService');
    const $filter = transition.injector().get('$filter');
    return transition.injector().getAsync('courseUnitInfo')
        .then((data) => {
            if (data) {
                // All is good, do not redirect
                return undefined;
            }
            errorService.showTranslatedError({
                titleKey: 'ERROR.COURSE_UNIT_WITH_GIVEN_ID_NOT_FOUND',
            });
            return { state: 'student.search' };
        });
}
