import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { LocalizedString, OtmId } from 'common-typescript/types';

export interface CheckboxTreeOption {
    label: LocalizedString;
    secondaryLabel?: LocalizedString;
    value: OtmId;
    parentId: OtmId;
    children: CheckboxTreeOption[];
    code?: string;
}

interface ExpansionState {
    id: String;
    expanded: boolean;
}

/**
 * Checkbox tree was developed for the redesigned organisation selection dialog. It has been
 * developed with only the organisation selector's needs in mind. However, should you need it,
 * it should be pretty easy to modify this to be properly reusable.
 */
@Component({
    selector: 'sis-checkbox-tree',
    templateUrl: './checkbox-tree.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxTreeComponent implements OnInit {
    @Input() option: CheckboxTreeOption;
    /**
     * List of currently selected options.
     */
    @Input() selected: CheckboxTreeOption[] = [];
    /**
     * Optional list of values of Options to expand by default.
     */
    @Input() defaultExpanded: string[] = [];
    /**
     * Optional list of values of Options to apply top-level styles to children.
     */
    @Input() topLevelStyleTargets: string[] = [];

    @Input() hideRootCheckbox: boolean;

    @Output() checkedChange = new EventEmitter<CheckboxTreeOption>();

    expansionState: ExpansionState[];

    ngOnInit() {
        this.topLevelStyleTargets = this.hideRootCheckbox ? this.option.children.map(opt => opt.value) : [];
        this.expansionState = this.createInitialExpansionState();
    }

    get allChildren() {
        if (!this.option) {
            return [];
        }

        return this.flattenChildren([{
            label: this.option.label,
            value: this.option.value,
            children: this.option.children,
            parentId: null,
            code: this.option.code,
        }]);
    }

    toggleExpanded(id: string) {
        const node = this.findExpansionStateNode(id);
        node.expanded = !node.expanded;
    }

    isExpanded(id: string) {
        return this.findExpansionStateNode(id).expanded || false;
    }

    shouldHideCheckbox(option: CheckboxTreeOption) {
        return this.hideRootCheckbox && !option.parentId;
    }

    toggleChecked(id: string) {
        this.checkedChange.emit(this.getOption(id));
    }

    isChecked(id: string): boolean {
        return !!this.selected.find(({ value }) => value === id);
    }

    isIndeterminate(id: string): boolean {
        return this.anyChildIsSelected(this.getOption(id));
    }

    /**
     * Checkbox is disabled if it has an ancestor that is selected.
     */
    isDisabled(id: string): boolean {
        const allSelectedChildren = this.flattenChildren(this.selected);

        for (const child of allSelectedChildren) {
            if (child.children.some(c => c.value === id)) {
                return true;
            }
        }
        return false;
    }

    makeCheckboxLabelStyles(id: string): string[] {
        if (this.option.value === id || this.topLevelStyleTargets.includes(id)) {
            return ['checkbox-tree-contents', 'checkbox-tree-top-level'];
        }
        return ['checkbox-tree-contents'];
    }

    private getOption(id: string) {
        return this.allChildren.find(opt => opt.value === id);
    }

    private flattenChildren(nodes: CheckboxTreeOption[]): CheckboxTreeOption[] {
        let children = [] as any;
        return nodes.map((node: any) => {
            if (node.children && node.children.length) {
                children = [...children, ...node.children];
            }
            return node;
        }).concat(children.length ? this.flattenChildren(children) : children);
    }

    private createInitialExpansionState(): ExpansionState[] {
        return this.allChildren.map(child => ({
            id: child.value,
            expanded: this.anyChildIsSelected(child) || this.defaultExpanded.includes(child.value),
        }));
    }

    private findExpansionStateNode(id: string) {
        return this.expansionState.find(n => n.id === id);
    }

    private anyChildIsSelected(option: CheckboxTreeOption): boolean {
        return option.children.some(({ value }) => this.isChecked(value));
    }
}
