<article class="study-wrapper" [class.selected]="selected" [class.dotted]="dottedBorder" data-cy="study-box">
  <div class="study-header" [class.attained]="attainedCredits" role="heading" [attr.aria-level]="level">
    <ng-container *ngTemplateOutlet="nameClickAction.observed ? linkTitle : title"></ng-container>
  </div>
  <div class="study-content">
    <span class="study-content-header">
      <span>
        <span *ngIf="type !== 'STUDY_DRAFT'">{{code}} | </span>{{'SIS_COMPONENTS.STUDY.' + type | translate}}
      </span>
      <div class="study-content-badges">
        <sis-document-state-badge *ngIf="studyState" [state]="studyState"></sis-document-state-badge>
        <sis-tiny-badge [variant]="'primary'" *ngIf="isCrossStudyCourseUnit" [content]="('SIS_COMPONENTS.STUDY.CROSS_STUDY' | translate)"></sis-tiny-badge>
      </div>
    </span>
    <div *ngIf="attainedCredits" class="row">
      <ng-container *ngIf="!isPartiallyAttained">
        <div class="col-2 col-sm-1"><sis-icon icon="achievement" color="success"></sis-icon></div>
        <div class="col-10 col-sm-11 attainment-info">
          <div>{{'SIS_COMPONENTS.STUDY.' + (isSubstituted ? 'SUBSTITUTED' : 'ATTAINED') | translate}} ({{attainedCredits}} {{"CREDITS" | translate}})</div>
          <div>{{'SIS_COMPONENTS.STUDY.ATTAINMENT_GRADE' | translate}}: {{gradeId | grade: gradeScaleId | async }}</div>
        </div>
      </ng-container>
      <div *ngIf="isPartiallyAttained">
        <sis-icon icon=info></sis-icon>
        {{'SIS_COMPONENTS.STUDY.PARTIALLY_ATTAINED' | translate}}
        ({{attainedCredits}} / {{plannedCredits | creditRange}})
      </div>
    </div>
  </div>
  <ng-content select="[study-box=notifications]"></ng-content>
  <sis-study-action-box *ngIf="action"
                        [action]="action"
                        [actionInputId]="actionInputId"
                        [actionInputName]="actionInputName"
                        (actionChange)="actionChange.emit(id)"
                        [selected]="selected"
                        [inputType]="inputType"
                        [studyInfo]="(_name || '') + ' ' + (credits | creditRange)">
  </sis-study-action-box>
</article>

<ng-template #linkTitle>
  <sis-button [link]="true" (clicked)="nameClickAction.emit(id)">
    <ng-container *ngTemplateOutlet="title"></ng-container>
  </sis-button>
</ng-template>

<ng-template #title>
  {{ _name }} ({{credits | creditRange}})
</ng-template>
