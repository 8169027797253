import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { MaxLength } from 'common-typescript/constants';

import { isSearchValid } from '../search-utils';
import { SearchService } from '../search.service';

/**
 * Component for wrapping search result content. If search is valid, renders all given content. If search is
 * invalid, renders some simple guidance texts.
 */
@Component({
    selector: 'sis-search-results',
    templateUrl: './search-results.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent {

    /**
     * A view specific instance of {@link SearchService}.
     */
    protected readonly searchService = inject(SearchService);

    protected readonly isSearchValid = isSearchValid;
    protected readonly maxQueryLength = MaxLength.MAX_TERSE_STRING_LENGTH;
}
