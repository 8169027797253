import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { CurriculumPeriod } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { Option } from '../../../select/combobox/combobox.component';
import { CurriculumPeriodEntityService } from '../../../service/curriculum-period-entity.service';
import { SearchService } from '../../search.service';

function descendingChronologicalOrder(a: CurriculumPeriod, b: CurriculumPeriod) {
    return (b?.activePeriod?.startDate ?? '').localeCompare(a?.activePeriod?.startDate ?? '');
}

@Component({
    selector: 'sis-search-filter-curriculum-periods',
    templateUrl: './search-filter-curriculum-periods.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterCurriculumPeriodsComponent {

    @Input({ required: true }) key: string;

    @Input({ required: true }) label: string;

    private readonly curriculumPeriodService = inject(CurriculumPeriodEntityService);
    private readonly localeService = inject(LocaleService);
    private readonly searchService = inject(SearchService);

    options$: Observable<Option[]> = this.searchService.universityOrgId$
        .pipe(
            switchMap(universityOrgId => this.curriculumPeriodService.getByUniversityOrgId(universityOrgId)
                .pipe(catchError(e => {
                    console.error(`Error fetching curriculum periods with universityOrgId ${universityOrgId}`, e);
                    return of([]);
                }))),
            map(curriculumPeriods => curriculumPeriods.sort(descendingChronologicalOrder)),
            map(curriculumPeriods => curriculumPeriods.map(curriculumPeriod => ({
                label: this.localeService.localize(curriculumPeriod.name),
                value: curriculumPeriod.id,
            }))),
        );
}
