import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { SisComponentsModule } from '../../sis-components.module';

import { SearchFilterCodeBookComponent } from './search-filters/search-filter-code-book/search-filter-code-book.component';
import { SearchFilterComboboxComponent } from './search-filters/search-filter-combobox/search-filter-combobox.component';
import { SearchFilterCooperationNetworksComponent } from './search-filters/search-filter-cooperation-networks/search-filter-cooperation-networks.component';
import { SearchFilterCourseUnitsComponent } from './search-filters/search-filter-course-units/search-filter-course-units.component';
import {
    SearchFilterCurriculumPeriodsComponent,
} from './search-filters/search-filter-curriculum-periods/search-filter-curriculum-periods.component';
import { SearchFilterDateRangeComponent } from './search-filters/search-filter-date-range/search-filter-date-range.component';
import {
    SearchFilterDropdownSelectComponent,
} from './search-filters/search-filter-dropdown-select/search-filter-dropdown-select.component';
import {
    SearchFilterOpenUniversityCartStateComponent,
} from './search-filters/search-filter-open-university-cart-state/search-filter-open-university-cart-state.component';
import {
    SearchFilterOpenUniversityProductStateComponent,
} from './search-filters/search-filter-open-university-product-state/search-filter-open-university-product-state.component';
import {
    SearchFilterOrganisationIdComponent,
} from './search-filters/search-filter-organisation-id/search-filter-organisation-id.component';
import {
    SearchFilterQualificationStateComponent,
} from './search-filters/search-filter-qualification-state/search-filter-qualification-state.component';
import { SearchFilterStudyPeriodsComponent } from './search-filters/search-filter-study-periods/search-filter-study-periods.component';
import { SearchFilterUniversitiesComponent } from './search-filters/search-filter-universities/search-filter-universities.component';
import { SearchFilterDirective } from './search-filters/search-filter.directive';
import { SearchResultCountsComponent } from './search-result-counts/search-result-counts.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchComponent } from './search.component';

@NgModule({
    declarations: [
        SearchComponent,
        SearchResultsComponent,
        SearchResultCountsComponent,
        SearchFilterCodeBookComponent,
        SearchFilterComboboxComponent,
        SearchFilterCooperationNetworksComponent,
        SearchFilterCurriculumPeriodsComponent,
        SearchFilterDateRangeComponent,
        SearchFilterDirective,
        SearchFilterDropdownSelectComponent,
        SearchFilterOpenUniversityProductStateComponent,
        SearchFilterOrganisationIdComponent,
        SearchFilterQualificationStateComponent,
        SearchFilterCourseUnitsComponent,
        SearchFilterOpenUniversityCartStateComponent,
        SearchFilterStudyPeriodsComponent,
        SearchFilterUniversitiesComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
    ],
    exports: [
        SearchComponent,
        SearchResultsComponent,
        SearchResultCountsComponent,
        SearchFilterCodeBookComponent,
        SearchFilterComboboxComponent,
        SearchFilterCooperationNetworksComponent,
        SearchFilterCurriculumPeriodsComponent,
        SearchFilterDateRangeComponent,
        SearchFilterDirective,
        SearchFilterDropdownSelectComponent,
        SearchFilterOpenUniversityProductStateComponent,
        SearchFilterOrganisationIdComponent,
        SearchFilterQualificationStateComponent,
        SearchFilterCourseUnitsComponent,
        SearchFilterOpenUniversityCartStateComponent,
        SearchFilterStudyPeriodsComponent,
        SearchFilterUniversitiesComponent,
    ],
})
export class CommonSearchModule {}
