import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FudisSelectOption } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import {
    AnyModuleRule,
    ContentFilter,
    EntityWithRule,
    ModuleResultItem,
    OtmId,
    SearchValidity,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { CreditRangePipe } from '../../../number/credit-range.pipe';
import { ModuleEntityService } from '../../../service/module-entity.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

interface ModuleSearchOptionType {
    value: ModuleResultItem,
    label: string,
    subLabel?: string
}

export interface ParentModule extends EntityWithRule {
    contentFilter?: ContentFilter
}

@Component({
    selector: 'sis-plan-structure-any-module-rule',
    templateUrl: './plan-structure-any-module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureAnyModuleRuleComponent implements OnDestroy {
    @Input({ required: true }) rule: AnyModuleRule;
    @Input({ required: true }) parentModule: ParentModule;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) validatablePlan: ValidatablePlan;
    @Input({ required: true }) ruleDepthLevel: number;
    @Input({ required: true }) headingLevel: number;
    @Input({ required: true }) selectionUIState: 'ACTIVE' | 'DISABLED' | 'SELECTABLE' = 'ACTIVE';
    @Input({ required: true }) currentUniversityOrgId: OtmId;
    @Input({ required: true }) curriculumPeriodIds: OtmId[];

    moduleSelectControl = new FormControl<null | FudisSelectOption<ModuleSearchOptionType>>(null);
    MINOR_STUDY_RIGHT_SELECTION_TYPE_URN = 'urn:code:study-right-selection-type:minor-study-right';

    private destroy$ = new Subject<void>();
    protected searchTextUpdateSubject = new Subject<string | null>();
    protected searchResults = new BehaviorSubject<FudisSelectOption<ModuleSearchOptionType>[]>([]);

    constructor(
        private appErrorHandler: AppErrorHandler,
        private moduleEntityService: ModuleEntityService,
        private creditRangePipe: CreditRangePipe,
        private translocoService: TranslocoService,
    ) {
        this.searchTextUpdateSubject.pipe(
            tap(() => this.searchResults?.next([])),
            filter(textQuery => textQuery?.trim() !== ''),
            switchMap(textQuery => this.searchModules(textQuery)),
            takeUntil(this.destroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    searchModules(textQuery: string): Observable<ModuleSearchOptionType[]> {
        const searchParameters = {
            fullTextQuery: textQuery,
            orgRootId: this.currentUniversityOrgId,
            curriculumPeriodIds: this.curriculumPeriodIds,
            validity: 'ONGOING_AND_FUTURE' as SearchValidity,
            codeUrns: _.get(this.parentModule, 'contentFilter.studyRightSelectionType') === this.MINOR_STUDY_RIGHT_SELECTION_TYPE_URN ?
                [this.MINOR_STUDY_RIGHT_SELECTION_TYPE_URN] : [],
            limit: 100,
            start: 0,
        };

        return this.moduleEntityService.searchUnauthenticated(searchParameters)
            .pipe(
                map(modules => modules.searchResults.map(module => ({
                    value: module,
                    label: `${module.name} (${this.creditRangePipe.transform(module.credits)})`,
                    subLabel: `${module.code} | ${this.translocoService.translate('SIS_COMPONENTS.STUDY.STUDY_MODULE')}`,
                }))),
                tap(opts => {
                    this.searchResults?.next(opts);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    updateSelected(option: FudisSelectOption<ModuleSearchOptionType>): void {
        // TODO: add the selected option to the modules shown underneath the search
    }
}
