import * as _ from 'lodash-es';
import angular from 'angular';
export const percentFilterModule = 'sis-components.number.percentFilter';
(function () {
  angular.module('sis-components.number.percentFilter', []).filter('percent', percent).filter('percentWithDecimals', percentWithDecimals);

  /**
   * @ngInject
   */
  function percent() {
    return function (input) {
      if (_.isNumber(input)) {
        return _.round(100 * input, 0) + ' %';
      }
      return '';
    };
  }
  function percentWithDecimals() {
    return input => {
      if (_.isNumber(input)) {
        return `${100 * input} %`;
      }
      return '';
    };
  }
})();