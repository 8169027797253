import {
    ChangeDetectionStrategy,
    Component, computed,
    inject, input,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import { EntityWithRule, ModuleRule, Rule } from 'common-typescript/types';
import * as _ from 'lodash-es';

import { RULE_SERVICE } from '../../../ajs-upgraded-modules';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../../plan/plan-actions-service/plan-actions.service';
import { PlanRuleData } from '../../../service/plan-rule-data.service';
import { PlanRuleSelectionService } from '../../../service/plan-rule-selection.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-module-rule',
    templateUrl: './plan-structure-module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureModuleRuleComponent {

    rule = input.required<ModuleRule>();
    parentModule = input.required<EntityWithRule>();
    planValidationResult = input.required<PlanValidationResult>();
    planStateObject = input.required<PlanStateObject>();
    planRuleData = input.required<PlanRuleData>();
    validatablePlan = input.required<ValidatablePlan>();
    selectionUIState = input<'ACTIVE' | 'DISABLED' | 'SELECTABLE'>('ACTIVE');
    planData = input.required<PlanData>();
    ruleDepthLevel = input.required<number>();
    headingLevel = input.required<number>();

    ruleService = inject(RULE_SERVICE);
    planRuleSelectionService: PlanRuleSelectionService = inject(PlanRuleSelectionService);
    planActionsService: PlanActionsService = inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN);

    ruleValidationResult = computed(this.ruleValidationResultComputation());
    ruleModule = computed(this.ruleModuleComputation());
    parentRule = computed<Rule | null>(this.parentRuleComputation());
    isParentRuleSelectAllRule = computed<boolean>(this.isParentRuleSelectAllRuleComputation());
    isParentSelectOneRule = computed<boolean>(this.isSelectOneRuleComputation());

    isRuleSelectionDisabled = computed(this.isRuleSelectionDisabledComputation());
    showMandatorySelection = computed(this.showMandatorySelectionComputation());

    action = computed(this.actionComputation());
    actionInputType = computed(this.actionInputTypeComputation());
    actionInputName = computed(this.actionInputNameComputation());

    handleToggle(): void {
        const active = this.ruleValidationResult().active;
        const module = this.ruleModule();
        const parentModule = this.parentModule();
        if (!active) {
            this.planActionsService?.selectModule(module, parentModule);
        } else {
            this.planActionsService?.removeModule(module, parentModule);
        }
    }

    ruleValidationResultComputation(): () => any {
        return () => _.get(this.planValidationResult().ruleValidationResults, [this.parentModule().id, this.rule().localId]);
    }

    ruleModuleComputation(): () => EntityWithRule {
        return () => this.planRuleData().ruleModuleVersionsByGroupId[this.rule().moduleGroupId];
    }

    parentRuleComputation(): () => Rule | null {
        return () => this.ruleService.getParentRule(this.parentModule().rule, this.rule());
    }

    isParentRuleSelectAllRuleComputation(): () => boolean {
        return () => this.ruleService.isSelectAllRule(this.parentRule());
    }

    isRuleSelectionDisabledComputation(): () => boolean {
        return () => this.selectionUIState() === 'DISABLED'
            || (this.isParentRuleSelectAllRule() && this.ruleValidationResult()?.active)
            || this.planRuleSelectionService.isModuleSelectionDisabled(this.ruleModule(), this.parentModule(), this.validatablePlan());
    }

    isSelectOneRuleComputation(): () => boolean {
        return () => this.ruleService.isSelectOneRule(this.parentRule());
    }

    showMandatorySelectionComputation() {
        return () => this.ruleValidationResult()?.active && (this.isParentRuleSelectAllRule() || !this.parentRule());
    }

    actionComputation() {
        return () => this.isRuleSelectionDisabled() ? undefined : 'select-to-plan' as const;
    }

    actionInputTypeComputation() {
        return () => this.isParentSelectOneRule() ? 'radio-button' : 'checkbox';
    }

    actionInputNameComputation() {
        return () => this.parentRule() ? this.parentRule().localId : this.rule().localId;
    }
}
