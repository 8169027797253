<ng-container *transloco="let t">
  <h3>{{t(titleTranslationKey)}}</h3>
  <ng-container *ngIf="cooperationNetworkStatus?.direction === 'INBOUND'; then inbound; else outbound"></ng-container>
  <ng-template #inbound>
    <sis-description-list>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.INBOUND_ORGANISATION')}}</dt>
        <dd *ngIf="cooperationNetworkStatus?.universityOrgId | organisation | async as university; else emptyField">
          {{university.name | localizedString}}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.INBOUND_ORGANISATION_TKCODE')}}</dt>
        <dd>{{cooperationNetworkStatus?.organisationTkCode ?? '-'}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.COOPERATION_NETWORK')}}</dt>
        <dd
          *ngIf="cooperationNetworkStatus?.cooperationNetworkId | cooperationNetwork | async as cooperationNetwork; else emptyField">
          {{(cooperationNetwork.name | localizedString) + ', ' + cooperationNetwork.abbreviation}}
        </dd>
      </ng-template>
    </sis-description-list>
  </ng-template>
  <ng-template #outbound>
    <sis-description-list>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_ORGANISATION')}}</dt>
        <dd *ngIf="cooperationNetworkStatus?.universityOrgId | organisation | async as university; else emptyField">
          {{university.name | localizedString}}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_ORGANISATION_TKCODE')}}</dt>
        <dd>{{cooperationNetworkStatus?.organisationTkCode ?? '-'}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_STUDY_RIGHT')}}</dt>
        <dd>{{cooperationNetworkStatus?.homeStudyRightId ?? '-'}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.COOPERATION_NETWORK')}}</dt>
        <dd
          *ngIf="cooperationNetworkStatus?.cooperationNetworkId | cooperationNetwork | async as cooperationNetwork; else emptyField">
          {{(cooperationNetwork.name | localizedString) + ', ' + cooperationNetwork.abbreviation}}
        </dd>
      </ng-template>
      <ng-template #listItem>
        <dt>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.ATTAINMENT_RIPA.OUTBOUND_STATUS_TITLE')}}</dt>
        <dd>{{t(this.outboundStatusTranslationKey)}}</dd>
      </ng-template>
    </sis-description-list>
  </ng-template>
  <ng-template #emptyField>
    <dd>-</dd>
  </ng-template>
</ng-container>
