import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OtmId, Passport } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { isAccessDeniedResponse } from '../../error-handler/type-guards';
import { PassportEntityService } from '../../service/passport-entity.service';
import { createPassportEditModalOpener } from '../edit-passport-modal/edit-passport-modal.component';

interface PassportVisibilityMapping {
    [id: OtmId]: { isVisible: boolean }
}
@Component({
    selector: 'sis-passport-info-view',
    templateUrl: './passport-info-view.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassportInfoViewComponent implements OnInit, AfterViewChecked {
    @Input() studentId: OtmId;
    @Input() isEditingEnabled: boolean = false;

    passports$: Observable<Passport[]>;
    passportVisibilityMappings: PassportVisibilityMapping;
    showAccessForbiddenNotification: boolean = false;
    showGenericErrorNotification: boolean = false;
    createPassportEditModalOpener = createPassportEditModalOpener();

    constructor(
        private passportEntityService: PassportEntityService,
        private appErrorHandler: AppErrorHandler,
        private destroyRef: DestroyRef,
        private changeDetector: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.passports$ = this.passportEntityService.getActivePassportsByPersonId(this.studentId).pipe(
            tap(passports => {
                this.passportVisibilityMappings =
                    _.reduce(
                        passports,
                        (result, value) => ({ ...result, [value.id]: { isVisible: false } }),
                        {},
                    );
            }),
            takeUntilDestroyed(this.destroyRef),
            catchError(err => {
                if (isAccessDeniedResponse(err.error)) {
                    this.showAccessForbiddenNotification = true;
                    return EMPTY;
                }
                this.showGenericErrorNotification = true;
                return throwError(() => err);
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    ngAfterViewChecked(): void { this.changeDetector.detectChanges(); }

    togglePassportVisibility(id: OtmId) {
        this.passportVisibilityMappings = {
            ...this.passportVisibilityMappings,
            [id]: { isVisible: !this.passportVisibilityMappings[id].isVisible },
        };
    }

    isPassportVisible(id: OtmId) {
        return this.passportVisibilityMappings[id].isVisible;
    }

    openEditModal(passports: Passport[]): void {
        this.createPassportEditModalOpener(this.studentId, passports);
    }
}
