import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { CommonSearchFilters, OtmId } from 'common-typescript/types';
import { BehaviorSubject, combineLatestWith, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { Option } from '../../../select/combobox/combobox.component';
import { OrganisationEntityService } from '../../../service/organisation-entity.service';

/**
 * A search filter component that allows selecting one or more universities.
 *
 * If you're implementing a search that is targeted at the data of a specific university, you won't need this
 * filter. Instead, configure {@link SearchService} with {@link SearchServiceConfig#universityOrgIdEnabled}.
 */
@Component({
    selector: 'sis-search-filter-universities',
    templateUrl: './search-filter-universities.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterUniversitiesComponent {

    /**
     * The key to use for the search filter values. Avoid using `universityOrgId` to prevent conflicts with
     * the built-in {@link CommonSearchFilters#universityOrgId}.
     */
    @Input({ required: true }) key: string;

    @Input({ required: true }) label: string;

    @Input()
    set excludedUniversityIds(ids: OtmId | OtmId[]) {
        this.excludedUniversityIds$.next(((Array.isArray(ids) ? ids : [ids])).filter(Boolean));
    }

    private readonly localeService = inject(LocaleService);
    private readonly organisationService = inject(OrganisationEntityService);

    private readonly excludedUniversityIds$ = new BehaviorSubject<OtmId[]>([]);

    options$: Observable<Option[]> = this.organisationService.getRootOrganisations()
        .pipe(
            catchError(e => {
                console.error('Error fetching root organisations', e);
                return of([]);
            }),
            map(organisations => organisations.map(organisation => ({
                label: this.localeService.localize(organisation.name),
                value: organisation.id,
            }))),
            map(options => options.sort((a, b) => a.label.localeCompare(b.label))),
            combineLatestWith(this.excludedUniversityIds$),
            map(([options, excludedIds]) => options.filter(({ value }) => !excludedIds.includes(value))),
        );
}
