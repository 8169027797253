import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    PersonWithCourseUnitRealisationResponsibilityInfoType,
    PublicPerson,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { take, tap } from 'rxjs/operators';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PersonNameService } from '../../service/person-name.service';
import { PublicPersonEntityService } from '../../service/public-person-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-realisation-responsibility-info',
    templateUrl: './course-unit-realisation-responsibility-info.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitRealisationResponsibilityInfoComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnitRealisationResponsibilityInfo',
        directiveName: 'sisCourseUnitRealisationResponsibilityInfo',
    };

    responsiblePersonsById: { [id: string]: PublicPerson } = {};
    sortedResponsibilityInfos: PersonWithCourseUnitRealisationResponsibilityInfoType[] = [];

    @Input() responsibilityInfos: PersonWithCourseUnitRealisationResponsibilityInfoType[];
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private personNameService: PersonNameService,
        private publicPersonEntityService: PublicPersonEntityService,
    ) {}

    ngOnChanges() {
        this.updateResponsiblePersons();
    }

    updateResponsiblePersons() {
        const responsiblePersonIds = _.chain(this.responsibilityInfos)
            .map('personId')
            .uniq()
            .compact()
            .value();
        this.publicPersonEntityService.getByIds(responsiblePersonIds).pipe(
            take(1),
            tap((persons) => {
                this.responsiblePersonsById = _.keyBy(persons, 'id');
                this.sortedResponsibilityInfos = _.chain(this.responsibilityInfos)
                    .orderBy(
                        [
                            info => info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:administrative-person',
                            info => info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:contact-info',
                            info => info.roleUrn === 'urn:code:course-unit-realisation-responsibility-info-type:teacher',
                            info => !info.personId,
                            info => _.upperCase(this.personNameService.getFullName(_.get(this.responsiblePersonsById, info.personId))),
                        ],
                    )
                    .value();
            }),
            this.appErrorHandler.defaultErrorHandler());

    }

}
