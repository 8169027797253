<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title">{{t('PROFILE.ATTAINMENT.MARK_AS_SECONDARY_MODAL_TITLE')}}</h2>
    <button type="button"
            class="modal-close"
            (click)="this.activeModal.dismiss()"
            [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
      <sis-icon icon="ring-close"></sis-icon>
    </button>
  </div>
  <div class="modal-body sis-mb-lg">
    <ng-container *ngIf="values.attainment.personId | personInfo | async as person">
      <sis-contextual-notification
        [variant]="'warning'">
        {{t('PROFILE.ATTAINMENT.MARK_AS_SECONDARY_MODAL_NOTIFICATION_TEXT', {
        personFirstNames: person.firstNames,
        personLastName: person.lastName,
        personStudentNumber: person.studentNumber
      })}}
      </sis-contextual-notification>
      <p class="sis-mt-md">
        {{t('PROFILE.ATTAINMENT.MARK_AS_SECONDARY_MODAL_TEXT', {
        studyCode: values.attainment | attainmentCode | async,
        studyName: values.attainment | attainmentName | async | localizedString,
        personFirstNames: person.firstNames,
        personLastName: person.lastName,
        personStudentNumber: person.studentNumber
      })}}
      </p>
    </ng-container>
  </div>
  <div class="modal-footer">
    <sis-button [variant]="'primary'"
                [hollow]="true"
                [size]="'lg'"
                (clicked)="this.activeModal.dismiss()">
      {{t('CANCEL')}}
    </sis-button>
    <sis-button [variant]="'primary'"
                [dataCy]="'mark-as-secondary-button'"
                [size]="'lg'"
                (clicked)="this.markAttainmentAsSecondary()">
      {{t('PROFILE.ATTAINMENT.MARK_AS_SECONDARY_MODAL_BUTTON')}}
    </sis-button>
  </div>
</ng-container>

