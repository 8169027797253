import { Injectable } from '@angular/core';
import { StudyGroupSet } from 'common-typescript/types';
import * as _ from 'lodash-es';

export type SgsAllocationRuleType =
    '0_0_HAS_GROUPS' |
    '0_0' |
    '0_NULL' |
    'X_NULL' |
    '0_Y' |
    'X_Y' |
    'X_X' |
    'X_X_GROUP_COUNT_X';
export const EnrolmentStudySubGroupPriority = {
    PRIMARY: 'PRIMARY',
    SUITABLE: 'SUITABLE',
    NOT_SUITABLE: 'NOT_SUITABLE',
} as const;
/**
 * Reimplementation of the AngularJS `enrolmentValidationService`. As long as both versions exist, the common parts need
 * to be kept in sync.
 */
@Injectable({ providedIn: 'root' })
export class EnrolmentValidationService {

    /**
     * Returns the study group set "allocation rule type", which can be used to determine the correct translation key for
     * describing how many study groups a student can/must select. This function is a more flexible implementation of the
     * legacy AngularJS `enrolmentValidationService.getSgsAllocationRuleTranslationKey()`, as this function allows changing
     * the translation key prefix depending on the use case.
     */
    getSgsAllocationRuleType(studyGroupSet: StudyGroupSet): SgsAllocationRuleType | null {
        const { min, max } = studyGroupSet?.subGroupRange ?? {};
        const allSubGroupsCancelled = studyGroupSet?.studySubGroups?.every(ssg => ssg.cancelled);
        if (!Number.isFinite(min)) {
            return null;
        }
        if (!Number.isFinite(max)) {
            return min === 0 ? '0_NULL' : 'X_NULL';
        }
        if (max === 0 && studyGroupSet?.studySubGroups?.length > 0 && !allSubGroupsCancelled) {
            return '0_0_HAS_GROUPS';
        }
        if (max === 0) {
            return '0_0';
        }
        if (min < max) {
            return 'X_Y';
        }
        if (min === studyGroupSet?.studySubGroups?.length) {
            return 'X_X_GROUP_COUNT_X';
        }

        return 'X_X';
    }

    getSgsAllocationRuleTranslationValues(studyGroupSet?: StudyGroupSet): { [key: string]: number } {
        const min = _.get(studyGroupSet, 'subGroupRange.min');
        if (_.isNil(min)) {
            // studyGroupSet or studyGroupSet.subGroupRange is undefined
            return {};
        }
        return {
            min,
            max: studyGroupSet.subGroupRange.max,
            groupCount: studyGroupSet.studySubGroups?.length,
        };
    }

    areConfirmedStudySubGroupsValidInStudyGroupSet(studyGroupSet: StudyGroupSet, confirmedSsgIds: string[]): boolean {
        const nonCancelled = _.reject(_.get(studyGroupSet, 'studySubGroups'), 'cancelled');
        const confirmedStudySubGroupsCount = nonCancelled
            .filter((ssg: any) => confirmedSsgIds.includes(ssg.id))
            .length;
        return this.isValidNumberOfGroupsForStudyGroupSet(studyGroupSet, confirmedStudySubGroupsCount);
    }

    isValidNumberOfGroupsForStudyGroupSet(studyGroupSet: any, count: number): boolean {
        return (!studyGroupSet.subGroupRange.min || count >= studyGroupSet.subGroupRange.min) &&
            (!studyGroupSet.subGroupRange.max || count <= studyGroupSet.subGroupRange.max);
    }
}
