import {
    ChangeDetectionStrategy,
    Component,
    Input, OnChanges,
    OnInit, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { CommonSearchFilters } from 'common-typescript/types';
import { isEqual } from 'lodash';
import { map, Observable } from 'rxjs';

import { Option } from '../../../select/dropdown-select/dropdown-select.component';
import { SearchService } from '../../search.service';

export interface TOption<T> extends Option {
    value: T,
}

/**
 * Reusable dropdown wrapper for simple search filters which allows selecting value from a given list of options.
 * Requires that some parent component provides a {@link SearchService} instance of type T.
 */
@Component({
    selector: 'sis-search-filter-dropdown-select',
    templateUrl: './search-filter-dropdown-select.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterDropdownSelectComponent <TFilters extends CommonSearchFilters,
    TKey extends keyof TFilters, TValue extends TFilters[TKey]> implements OnInit, OnChanges {

    constructor(private searchService: SearchService<TFilters>) {}

    /** The name of the filter whose value this component controls */
    @Input({ required: true }) key: TKey;
    /** The label to show above the combobox */
    @Input({ required: true }) label: string;
    /** The full list of options to select from */
    @Input({ required: true }) options: TOption<TValue>[];

    selection$: Observable<TOption<TValue>>;

    ngOnInit() {
        this.initSelection();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.key || changes.options) {
            this.initSelection();
        }
    }

    private initSelection() {
        this.selection$ = this.searchService.searchParameters$.pipe(
            map(parameters => parameters?.filters?.[this.key] ?? null),
            map((value: TFilters[TKey]) => this.options?.find(option => isEqual(value, option.value)) ?? null),
        );
    }

    onSelectionChange(value: TValue) {
        this.searchService.patchFilters(<Partial<TFilters>>{
            [this.key]: value,
        });
    }
}
