import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitResultItem, ModuleResultItem, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-inline-search-additional-data-row.component.ts',
    templateUrl: './inline-search-additional-data-row.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class InlineSearchAdditionalDataRowComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.inlineSearchAdditionalDataRow',
        directiveName: 'sisInlineSearchAdditionalDataRow',
    };

    @Input() result: CourseUnitResultItem | ModuleResultItem;
    @Input() textQuery: string;
    @Input() homeUniversityOrgId: OtmId;
    @Input() searchTarget: 'CourseUnit' | 'Module';

    isHomeUniversityResult(result: CourseUnitResultItem | ModuleResultItem) {
        if (this.searchTarget === 'CourseUnit') {
            const courseUnitResult = result as CourseUnitResultItem;
            return _.includes(courseUnitResult.universityOrgIds, this.homeUniversityOrgId);
        }
        return true;
    }

    getResultUniversityOrgIds(result: CourseUnitResultItem | ModuleResultItem) {
        if (this.searchTarget !== 'CourseUnit') {
            return [];
        }
        const courseUnitResult = result as CourseUnitResultItem;
        return courseUnitResult.universityOrgIds;
    }

}
