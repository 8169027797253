<ng-container *transloco="let t">
  <div class="app-calendar-enrolments" data-cy="calendar-enrolments">
    @if (dataSignal()) {
      <fudis-grid [columns]="1" [rowGap]="'sm'">
        @if (_currentView() === 'NOT_STARTED_AND_ACTIVE') {
          <div class="app-calendar-enrolments__filters">
            <fudis-checkbox-group [label]="t('CALENDAR_FILTERS.FILTER_BY_ENROLMENT_STATE')" [formGroup]="formFilters">
              @for (filter of formFilterOptionsWithCount(); track filter.controlName) {
                <fudis-checkbox [label]="filter.label"
                                [controlName]="filter.controlName">
                </fudis-checkbox>
              }
            </fudis-checkbox-group>
          </div>
        }
        @for (entry of sortedVisibleEnrolmentsByCourseUnitId(); track entry[0]) {
          <fudis-grid-item>
            <fudis-grid [rowGap]="'xs'">
              @for (enrolment of entry[1]; track enrolment.id) {
                <app-calendar-course-unit-realisation [attr.data-cy]="'calendar-enrolment'"
                                                      [attr.id]="'anchor' + enrolment.courseUnitRealisationId"
                                                      [enrolment]="enrolment"
                                                      [enrolmentCourseUnit]="dataSignal().enrolmentCourseUnitsById[enrolment.courseUnitId]"
                                                      [enrolmentAssessmentItem]="dataSignal().enrolmentAssessmentItemsById[enrolment.assessmentItemId]"
                                                      [enrolmentCourseUnitRealisation]="dataSignal().enrolmentCourseUnitRealisationsById[enrolment.courseUnitRealisationId]"
                                                      [enrolmentCalculationConfig]="dataSignal().enrolmentCalculationConfigsByCourseUnitRealisationId[enrolment.courseUnitRealisationId]"
                                                      [universities]="dataSignal().universities"
                                                      [initialExpanded]="this.initialExpandedCurIds.has(enrolment.courseUnitRealisationId)"
                                                      (onExpandedChange)="onExpandedChange($event)"
                                                      (onViewInCalendar)="onViewInCalendar($event)"
                                                      (onToggleStudySubGroupInCalendar)="onToggleStudySubGroupInCalendar($event)"
                                                      (onOpenCourseUnitInfo)="onOpenCourseUnitInfo($event)"
                                                      (onOpenQuestionnaire)="onOpenQuestionnaire($event)"
                                                      (onRemoveFromCalendar)="onRemoveFromCalendar($event)"
                                                      (onEnrolAction)="onEnrol($event)"
                                                      (onUpdateAction)="onUpdate($event)"
                                                      (onModifyGroupsAction)="onModifyGroups($event)"
                                                      (onCancelAction)="onCancelEnrolment($event)"
                                                      (onAbortAction)="onAbort($event)">
                </app-calendar-course-unit-realisation>
              }
            </fudis-grid>
          </fudis-grid-item>
        }
      </fudis-grid>
    }
  </div>
</ng-container>






