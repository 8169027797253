import { Pipe, PipeTransform } from '@angular/core';
import { StudyEvent } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';

@Pipe({ name: 'studyEventDateRange' })
export class StudyEventDateRangePipe implements PipeTransform {

    transform(studyEvent: StudyEvent): string {
        if (_.isEmpty(studyEvent?.events)) {
            return '';
        }

        const start = moment(_.first(studyEvent.events).start);
        const end = moment(_.last(studyEvent.events).start);

        if (start === null && end === null) {
            return '';
        }

        if (start.isSame(end)) {
            return end.format('D.M.YYYY');
        }

        if (start.year() === end.year()) {
            return _.join([start.format('D.M.'), end.format('D.M.YYYY')], '–');
        }

        return _.join([start.format('D.M.YYYY'), end.format('D.M.YYYY')], '–');
    }
}
