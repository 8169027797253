import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isEqual } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { Option } from '../../../select/combobox/combobox.component';
import { SearchService } from '../../search.service';

/**
 * Reusable combobox wrapper for simple search filters which allows selecting multiple values from a given list of options.
 * Requires that some parent component provides a {@link SearchService} instance.
 */
@Component({
    selector: 'sis-search-filter-combobox',
    templateUrl: './search-filter-combobox.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComboboxComponent implements OnInit {

    private readonly searchService = inject(SearchService);
    private readonly destroyRef = inject(DestroyRef);

    /** The name of the filter whose value this component controls */
    @Input({ required: true }) key: string;
    /** The label to show above the combobox */
    @Input({ required: true }) label: string;
    /** The full list of options to select from */
    @Input({ required: true }) options: Option[];

    selections$: Observable<Option[]>;

    ngOnInit() {
        this.selections$ = this.searchService.searchParameters$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map(parameters => parameters?.filters?.[this.key] ?? []),
                map((values: unknown[]) => this.options
                    ?.filter(option => values.some(value => isEqual(value, option.value))) ?? []),
            );
    }

    onSelectionChange(selections: Option[]) {
        this.searchService.patchFilters({
            [this.key]: selections.map(selection => selection.value),
        });
    }
}
