import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    Education,
    GradeAverageFrontpageComponentSetting,
    Module,
    OtmId,
    Plan,
    StudyProgressFrontpageComponentSetting,
    StudyRight,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { catchError, combineLatest, Observable, of, shareReplay, switchMap, take, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { UserSettingsEntityService } from 'sis-components/service/user-settings-entity.service';

import { MyStudyRightService } from '../../../common/service/my-study-right.service';
import { PlanLoaderService } from '../../../common/service/plan-loader.service';

type WidgetSettings = StudyProgressFrontpageComponentSetting | GradeAverageFrontpageComponentSetting;

type SupportedWidget = 'app-study-progress-graph' | 'app-grade-average';

interface PlanData {
    plan: Plan;
    /** The modules directly under the root of the plan (i.e. the education) */
    rootModules: Module[];
    studyRight: StudyRight;
    education: Education;
}

interface SelectedStudyRightIdAndModuleId {
    studyRightId: OtmId;
    moduleId: OtmId;
}

export interface DropdownSelections {
    plan: Plan;
    module: Module;
    studyRightId: OtmId;
}

@Component({
    selector: 'app-select-plan-dropdown',
    templateUrl: './select-plan-dropdown.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPlanDropdownComponent implements OnInit {
    @Input() widget: SupportedWidget;

    @Output() selectionChange = new EventEmitter<DropdownSelections>();

    private readonly myStudyRightService = inject(MyStudyRightService);
    private readonly educationEntityService = inject(EducationEntityService);
    private readonly planLoader = inject(PlanLoaderService);
    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly localeService = inject(LocaleService);
    private readonly userSettingsEntityService = inject(UserSettingsEntityService);
    private readonly moduleEntityService = inject(ModuleEntityService);

    planData$: Observable<PlanData[]>;
    options$: Observable<Option[]>;

    readonly searchFailed = signal(false);
    readonly selection = signal<SelectedStudyRightIdAndModuleId>(null);
    readonly studyRightCount = toSignal(this.myStudyRightService.studyRights$
        .pipe(map(studyRights => studyRights.length), catchError(() => of(null))));

    ngOnInit() {
        this.planData$ = this.getPlanData();
        this.options$ = this.getDropdownOptions();
        this.resolveInitialSelection();
    }

    private getPlanData(): Observable<PlanData[]> {
        return combineLatest([
            this.myStudyRightService.studyRights$
                .pipe(switchMap(studyRights => combineLatest([
                    of(studyRights),
                    this.educationEntityService.getByIds(studyRights.map(studyRight => studyRight.educationId)),
                ]))),
            this.planLoader.primaryPlans$
                .pipe(switchMap(plans => plans.length ? combineLatest(plans.map(plan => this.getRootModulesForPlan(plan))) : of([]))),
        ])
            .pipe(
                map(([[studyRights, educations], plansWithModules]) => plansWithModules
                    .map(({ plan, rootModules }) => ({
                        plan,
                        rootModules,
                        studyRight: studyRights.find(sr => sr.educationId === plan.rootId &&
                            sr.learningOpportunityId === plan.learningOpportunityId),
                        education: educations.find(education => education.id === plan.rootId),
                    }))
                    .filter(item => !!item.studyRight && !!item.education && item.rootModules.length > 0)
                    .sort(this.sortByEducationName()),
                ),
                catchError((error) => {
                    this.searchFailed.set(true);
                    return throwError(() => error);
                }),
                this.appErrorHandler.defaultErrorHandler([]),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
    }

    private getDropdownOptions(): Observable<Option[]> {
        return this.planData$.pipe(
            map(planData => planData.flatMap(item => [
                {
                    value: item.education.id,
                    label: this.localeService.localize(item.education.name),
                    header: true,
                },
                ...item.rootModules
                    .sort(this.sortByEducationPhase(item.education))
                    .map(module => ({
                        value: {
                            studyRightId: item.studyRight.id,
                            moduleId: module.id,
                        },
                        label: this.localeService.localize(module.name),
                    })),
            ])),
        );
    }

    private resolveInitialSelection() {
        combineLatest([this.options$, this.userSettingsEntityService.getOwnSettings()])
            .pipe(take(1))
            .subscribe(([options, settings]) => {
                const { studyRightId, moduleId } = (settings?.componentSettings?.[this.widget] as WidgetSettings) ?? {};
                if (options.some(option => !option.header && _.isEqual(option.value, { studyRightId, moduleId }))) {
                    // The stored component settings match with an item in the dropdown -> we can use the stored settings
                    this.updateSelection({ studyRightId, moduleId });
                } else {
                    // Otherwise pick the first selectable option in the list and use that
                    this.updateSelection(options.find(option => !option.header)?.value);
                }
            });
    }

    private getRootModulesForPlan(plan: Plan) {
        const rootModuleIds = plan.moduleSelections
            .filter(selection => selection?.parentModuleId === plan.rootId)
            .map(selection => selection.moduleId);
        return this.moduleEntityService.getByIds(rootModuleIds)
            .pipe(map(rootModules => ({ plan, rootModules })));
    }

    private sortByEducationName(): (a: PlanData, b: PlanData) => number {
        return (a: PlanData, b: PlanData) =>
            this.localeService.localize(a?.education?.name)?.localeCompare(this.localeService.localize(b?.education?.name)) ?? 0;
    }

    private sortByEducationPhase(education: Education): (a: Module, b: Module) => number {
        return (a: Module, b: Module) => {
            if (!education?.structure?.phase1?.options) {
                return 0;
            }

            if (education.structure.phase1.options.some(option => option?.moduleGroupId === a?.groupId)) {
                return -1; // a comes before b
            }
            if (education.structure.phase1.options.some(option => option?.moduleGroupId === b?.groupId)) {
                return 1; // b comes before a
            }
            return 0;
        };
    }

    private updateSelection(selection: SelectedStudyRightIdAndModuleId = null) {
        this.selection.set(selection);
        this.planData$
            .pipe(take(1))
            .subscribe(planData => {
                const planDataEntry = planData.find(item => item.studyRight.id === selection?.studyRightId);
                this.selectionChange.emit({
                    plan: planDataEntry?.plan,
                    module: planDataEntry?.rootModules.find(module => module.id === selection?.moduleId),
                    studyRightId: selection?.studyRightId,
                });
            });
    }

    onSelect(selection: SelectedStudyRightIdAndModuleId) {
        this.updateSelection(selection);
        this.userSettingsEntityService.getOwnSettings()
            .pipe(
                take(1),
                map(_.cloneDeep),
                switchMap(settings => {
                    // When user navigates to frontpage first time, 'select-widgets.component#firstTimeOpenSave' saves default selections.
                    // This logic will trust, that the ComponentSetting of the widget using this component exists
                    // inside userSettings.componentSettings, when user does changes to moduleId selection.
                    const widgetSettings = settings.componentSettings[this.widget] as WidgetSettings;
                    widgetSettings.studyRightId = selection?.studyRightId;
                    widgetSettings.moduleId = selection?.moduleId;
                    return this.userSettingsEntityService.saveOwnSettings(settings);
                }),
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }
}
