import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ValidatablePlan } from 'common-typescript';
import { MaxLength } from 'common-typescript/constants';
import {
    Attainment,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    DegreeProgramme,
    Module,
    ModuleContentWorkflow,
    ModuleType,
    OtmId,
    StudyModule,
    Workflow,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ModalService } from 'sis-common/modal/modal.service';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { AlertsService, AlertType } from '../../alerts/alerts-ng.service';
import { maxLength, required } from '../../form/form-validators';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { WorkflowEntityService } from '../../service/workflow-entity.service';

export interface CreateModuleContentWorkflowModalValues {
    module: Module;
    validatablePlan: ValidatablePlan;
    workflowType: 'CustomModuleContentWorkflow' | 'RequiredModuleContentWorkflow';
    studyRightId: OtmId;
}

@Component({
    selector: 'sis-create-module-content-workflow-modal',
    templateUrl: './create-module-content-workflow-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CreateModuleContentWorkflowModalComponent implements OnInit {

    module: Module;
    validatablePlan: ValidatablePlan;
    studyRightId: OtmId;
    moduleContentWorkflowType: 'CustomModuleContentWorkflow' | 'RequiredModuleContentWorkflow';

    selectedCourseUnits: CourseUnit[];
    selectedModules: Module[];
    selectedCustomCourseUnitAttainments: CustomCourseUnitAttainment[];
    selectedCustomModuleAttainments: CustomModuleAttainment[];
    customStudyDrafts: CustomStudyDraft[];
    attainments: Attainment[];
    form: FormGroup;

    constructor(
        @Inject(ModalService.injectionToken) private values: CreateModuleContentWorkflowModalValues,
        public activeModal: NgbActiveModal,
        private fb: SisFormBuilder,
        public uuidService: UuidService,
        public alertsService: AlertsService,
        public workflowEntityService: WorkflowEntityService,
        private translateService: TranslateService,
    ) {
        this.module = values.module;
        this.validatablePlan = values.validatablePlan;
        this.studyRightId = values.studyRightId;
    }

    ngOnInit() {
        this.selectedCourseUnits = this.validatablePlan.getSelectedCourseUnitsUnderModule(this.module);
        this.selectedCustomCourseUnitAttainments = this.validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(this.module);
        this.selectedModules = this.validatablePlan.getSelectedModulesUnderModule(this.module);
        this.selectedCustomModuleAttainments = this.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(this.module);
        this.customStudyDrafts = this.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.module.id);
        const selectedCourseUnitAttainments = _.chain(this.selectedCourseUnits)
            .map(courseUnit => this.validatablePlan.getCourseUnitAttainmentByGroupId(courseUnit.groupId) as Attainment)
            .compact()
            .value();
        const selectedModuleAttainments = _.chain(this.selectedModules)
            .map(module => this.validatablePlan.getModuleAttainmentByGroupId(module.groupId) as Attainment)
            .compact()
            .value();
        this.attainments = _.concat(selectedCourseUnitAttainments, selectedModuleAttainments);

        this.form = this.fb.group({
            applicationRationale: ['', [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]],
            cancellingDisabled: [false],
        });
    }

    get applicationRationale(): FormControl {
        return this.form?.get('applicationRationale') as FormControl;
    }

    get cancellingDisabled(): FormControl {
        return this.form?.get('cancellingDisabled') as FormControl;
    }

    onSubmit() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
            return;
        }
        const workflow = {
            id: this.uuidService.randomOtmId(),
            type: 'CustomModuleContentWorkflow',
            studentId: this.validatablePlan.plan.userId,
            studyRightId: this.studyRightId,
            educationId: this.validatablePlan.plan.rootId,
            parentModuleId: _.get(this.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(this.module), 'id'),
            approvedModuleId: this.module.id,
            originalReferredPlanId: this.validatablePlan.plan.id,
            applicationRationale: this.form.get('applicationRationale').value,
            cancellingDisabled: this.form.get('cancellingDisabled').value,
            organisations: this.getParentWithOrganisationsRecursively(this.module.id)?.organisations,
            courseUnitSelections: _.chain(this.selectedCourseUnits)
                .map('groupId')
                .compact()
                .uniq()
                .value(),
            moduleSelections: _.chain(this.selectedModules)
                .map('groupId')
                .compact()
                .uniq()
                .value(),
            customCourseUnitAttainmentSelections: _.chain(this.selectedCustomCourseUnitAttainments)
                .map('id')
                .compact()
                .uniq()
                .value(),
            customModuleAttainmentSelections: _.chain(this.selectedCustomModuleAttainments)
                .map('id')
                .compact()
                .uniq()
                .value(),
            customStudyDrafts: this.customStudyDrafts,
            initiatorType: 'STAFF',
            mustPrintDecision: false,
        } as Partial<ModuleContentWorkflow>;
        this.workflowEntityService.add(workflow as Workflow).subscribe({
            next: (result: Workflow) => {
                this.alertsService.addAlert({
                    message: this.translateService.instant('CREATE_CUSTOM_MODULE_CONTENT_WORKFLOW_MODAL.SUCCESS_ALERT'),
                    type: AlertType.SUCCESS,
                });
                this.activeModal.close(workflow);
            },
            error: () => this.alertsService.addFormSubmissionFailureAlert(),
        });
    }

    onCancel() {
        this.activeModal.dismiss();
    }

    getParentWithOrganisationsRecursively(moduleId: OtmId): StudyModule | DegreeProgramme {
        if (_.isNil(moduleId)) {
            return null;
        }
        const module = _.get(this.validatablePlan.modulesById, moduleId);
        if (_.isNil(module)) {
            return null;
        }
        if (module.type === ModuleType.STUDY_MODULE) {
            return module as StudyModule;
        }
        if (module.type === ModuleType.DEGREE_PROGRAMME) {
            return module as DegreeProgramme;
        }
        if (module.type === ModuleType.GROUPING_MODULE) {
            return this.getParentWithOrganisationsRecursively(this.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module).id);
        }
        return null;
    }

}
