import { HttpParams } from '@angular/common/http';
import {
    EntityStore,
    getEntityType as EntityType,
    getEntityType,
    getIDType as ID,
    QueryEntity,
    SelectOptions,
} from '@datorama/akita';
import { HttpConfig, NgEntityService } from '@datorama/akita-ng-entity-service';
import { EntityMetadata } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { DataloaderConfig, SisuDataLoader } from './SisuDataLoader';

type TWithMetadata<T> = T & { metadata: EntityMetadata };

/**
 * Service which extends the NgEntityService from Akita and offers functionality utilising the store over the generated REST-functions
 */
export class EntityService<T> extends NgEntityService<T> {

    readonly query: QueryEntity<T>;
    protected readonly dataloader: SisuDataLoader<ID<T>, EntityType<T>, EntityType<T>>;
    protected readonly MAX_URL_LENGTH = 2000;

    constructor(
        storeType: new () => EntityStore<T>,
        queryType: new (store: EntityStore<T>) => QueryEntity<T>,
        config?: DataloaderConfig<ID<T>, EntityType<T>, EntityType<T>>,
    ) {
        super(new storeType());
        this.query = new queryType(this.store);
        this.dataloader = this.createDataloader(config);
        this.getId = this.getId.bind(this);
    }

    createDataloader(config?: DataloaderConfig<ID<T>, EntityType<T>, EntityType<T>>): SisuDataLoader<ID<T>, EntityType<T>, EntityType<T>> {
        return new SisuDataLoader<ID<T>, EntityType<T>, EntityType<T>>(
            {
                ...this.createDefaultDataloaderConfig(),
                ...config,
            },
        );
    }

    private createDefaultDataloaderConfig(): DataloaderConfig<ID<T>, EntityType<T>, EntityType<T>> {
        return {
            getByIdsCall: ids => this.createGetByIdsCall(ids),
            successEntitiesCallback: entities => this.successEntitiesCallback(entities),
            resultExtractor: (id, entities) => this.resultExtractor(id, entities),
            bufferSize: 50,
            bufferTime: 20,
        };
    }

    resultExtractor(id: ID<T>, entities: EntityType<T>[]) {
        return entities.find(entity => this.getId(entity) === id);
    }

    successEntitiesCallback(entities: EntityType<T>[]) {
        this.store.upsertMany(entities);
    }

    /**
     * Gets the entity with the given id from the entity store if it exists, otherwise gets it from the REST-endpoint.
     * Always returns Observable to the store.
     *
     * @param id Id of entity
     * @param bypassStore Ignore store and get directly from the REST-endpoint
     * @param config Config object for headers, params, url and response-mapping
     */
    getById(id: ID<T>, bypassStore: boolean = false, config: HttpConfig = {}): Observable<EntityType<T>> {
        if (!id) {
            return throwError(() => new Error('The id was missing!'));
        }
        if (!bypassStore && this.query.hasEntity(id)) {
            return this.query.selectEntity(id);
        }

        return this.dataloader.load(id)
            .pipe(switchMap(() => this.query.selectEntity(id)));
    }

    createGetByIdsCall(ids: ID<T>[]): Observable<EntityType<T>[]> {
        return this.getHttp().get<EntityType<T>[]>(
            this.api,
            { params: new HttpParams().set(this.store.idKey, ids.toString()) },
        );
    }

    /**
     * Gets the entities with the given ids from the entity store if they all exist, otherwise gets them from the REST-endpoint.
     * Always returns Observable to the store.
     *
     * @param ids Ids of entities
     * @param config Config object for headers and params
     * @param bypassStore Ignore store and get directly from the REST-endpoint
     */
    getByIds(ids: ID<T>[], bypassStore: boolean = false, config: HttpConfig = {}): Observable<EntityType<T>[]> {
        const filteredIds = _.uniq(ids ?? []).filter(Boolean);
        if (filteredIds.length === 0) {
            return of([]);
        }
        return combineLatest(filteredIds.map(id => this.getById(id, bypassStore, config)));
    }

    /**
     * Refreshes entity from backend if it exists in the store.
     *
     * @param id Id of entity to refresh
     */
    refresh(id: ID<T>): void {
        if (this.query.hasEntity(id)) {
            this.getById(id, true).pipe(
                take(1),
            ).subscribe();
        }
    }

    selectLoading() {
        return this.query.selectLoading();
    }

    /**
     * Similar to getByIds, Gets the entities with the given ids from the REST-endpoint.
     * Always returns Observable to the store. Main difference is that this function can query with id's other than it's own id and into a custom endpoint.
     * As a  example, with this you can query enrolmentAllocationCount [] with courseUnitRealisationIds. Store cache's functions with id's, so caching does not work as well
     * as in getByIds. However, this does attempt to save the entities to the store if the object has an id, so an use case would be "search these objects with this custom id-field",
     * and then in the following queries getByIds can be used to fetch these same objects.
     *
     * @param baseUrl is the custom url to query, usually the one configured in xxxEntityStores config.backend.endpoint object.
     * @param queryIdKey The key to query with, e.g. courseUnitRealisationIds
     * @param ids the Ids to query with, e.g. array of courseUnitRealisationIds
     * @param config Config object for headers and params
     */
    getByPropertyIds(baseUrl: string, queryIdKey: string, ids: ID<T>[], config: HttpConfig = {}): Observable<EntityType<T>[]> {
        const entityIds = (ids || []).filter(Boolean);
        return forkJoin(this.batchQuery(baseUrl, queryIdKey, entityIds, config))
            .pipe(
                // combine the resulting array of arrays with the result objects
                map((entities: EntityType<T>[][]) => entities.flat()),
                // If objects have an id, update store with entities by hand because regular http.get does not update them.
                tap((entities: EntityType<T>[]) => {
                    if (entities && entities.length > 0 && _.first(entities).hasOwnProperty(this.store.idKey)) {
                        this.store.upsertMany(entities);
                    }
                }),
                // Check if the objects have an id, and if they do, return store Observable.
                // Otherwise return bare observable, so the subscribers get notified.
                switchMap((entities: EntityType<T>[]) => {
                    let idArray: ID<T>[] = [];
                    if (entities.length > 0 && _.first(entities).hasOwnProperty(this.store.idKey)) {
                        idArray = entities.map(entity => entity[this.store.idKey as keyof typeof entity] as ID<T>);
                    }
                    return idArray.length > 0 ? this.query.selectMany(idArray) : of(entities);
                }),
            );
    }

    /**
     * Selects the entity by id from the entity store.
     *
     * @param id Entity id
     */
    selectEntity(id: ID<T>): Observable<EntityType<T>> {
        return this.query.selectEntity(id);
    }

    /**
     * Selects multiple entities by ids from the entity store.
     *
     * @param ids Entity ids
     */
    selectMany(ids: ID<T>[]): Observable<EntityType<T>[]> {
        return this.query.selectMany(ids);
    }

    /**
     * Selects all entities from the entity store with possible options like filtering and limiting
     *
     * @param options Object containing options for filtering, limiting and object resolving
     */
    selectAll(options?: SelectOptions<getEntityType<T>>) {
        return this.query.selectAll(options);
    }

    /**
     * Constructs an array of observables based on the given relation enums and the defined relations in the service
     *
     * @param availableRelations Array of available relation enums for a given entity type
     * @param requestedRelations Array of selected relation enums that should be resolved
     * @param relationObservables Object containing a mapping from relation string to the corresponding service method
     * @param resolveTarget Any entity structure for which relations should be resolved. This is usually an object or an array but can in principle be anything.
     */
    constructRelationObservables<E, R>(availableRelations: R[], requestedRelations: R[], relationObservables: { [key: string]: Function }, resolveTarget: E) {
        return availableRelations.map(relation =>
            requestedRelations.includes(relation) ?
                relationObservables[relation.toString()](resolveTarget) :
                of(undefined),
        );
    }

    /**
     * Returns a new array that contains only unique and "non-empty" values from the source array. An empty value
     * here means `null`, `undefined` or an empty string. Useful when resolving HTTP query parameter values.
     */
    protected removeEmptyAndDuplicateValues<V extends string | boolean | number>(values: V | V[]): V[] {
        return [...new Set(Array.isArray(values) ? values : [values])]
            .filter(value => value !== null && value !== undefined && value.toString().trim().length > 0);
    }

    /**
     * Returns a pipeable RxJS operator that upserts the entity emitted by the source observable to the store, and
     * switches to a new observable that selects the entity in question from the store. Useful when wanting to cache
     * and listen to subsequent changes for an entity fetched from the backend from a non-CRUD endpoint.
     *
     * Note that the observable won't complete on its own, but will stay active and keep emitting new values until
     * unsubscribing from it.
     */
    protected upsertAndSwitchToStoreObservable(): (source: Observable<EntityType<T>>) => Observable<EntityType<T>> {
        return source => source.pipe(
            tap(entity => {
                const currentEntity = this.query.getEntity(this.getId(entity));
                if (!currentEntity || !this.hasRevision(currentEntity) || !this.hasRevision(entity) || this.hasSmallerRevisionThan(currentEntity, entity)) {
                    this.store.upsert(this.getId(entity), entity);
                } else {
                    console.debug('Skipped upsert. Newer entity in store.');
                }
            }),
            switchMap(entity => this.selectEntity(this.getId(entity))),
        );
    }

    private hasRevision(entity: EntityType<T>) {
        return (<TWithMetadata<T>> entity)?.metadata?.revision > 0;
    }

    private hasSmallerRevisionThan(entity: EntityType<T>, otherEntity: EntityType<T>) {
        return (<TWithMetadata<T>> entity)?.metadata?.revision < (<TWithMetadata<T>> otherEntity)?.metadata?.revision;
    }

    /**
     * Returns a pipeable RxJS operator that upserts all values emitted by the source observable to the store, and
     * switches to a new observable that selects multiple entities from the store. Useful when wanting to cache and
     * listen to subsequent changes for a list of entities fetched from the backend from a non-CRUD endpoint.
     *
     * Note that the observable won't complete on its own, but will stay active and keep emitting new values until
     * unsubscribing from it.
     *
     * @param options If provided, returns an `Observable` which selects entities from the store using the given
     * select options. Otherwise, exactly the emitted entities are selected from the store using their own ids.
     */
    protected upsertManyAndSwitchToStoreObservable(
        options?: SelectOptions<EntityType<T>>,
    ): (source: Observable<EntityType<T>[]>) => Observable<EntityType<T>[]> {
        return source => source.pipe(
            tap(entities => this.store.upsertMany(entities)),
            switchMap(entities => options ? this.selectAll(options) : this.selectMany(entities.map(this.getId))),
        );
    }

    private getId(entity: EntityType<T>): ID<T> {
        return _.get(entity, this.store.idKey);
    }

    /**
     * General implementation of a batch query. Splits queries that have too long request url into multiple queries.
     *
     * @param baseUrl the url to query
     * @param idKey the idKey to input into the query, can be as simple as 'id'
     * @param ids Ids of entities
     * @param config Config object for headers and params
     */
    private batchQuery(baseUrl: string, idKey: string, ids: ID<T>[], config: HttpConfig = {}): Observable<EntityType<T>[]>[] {
        let idsString = ids.toString();
        let commaIndex = 0;
        const requests = [];
        const otherParams = config.params ?
            _.map(config.params, (value, key) => `&${key}=${value.toString()}`).join('') :
            '';

        while (idsString.length > 0) {
            const url = `${baseUrl}?${idKey}=${idsString}${otherParams}`;
            commaIndex = url.length > this.MAX_URL_LENGTH ?
                // Trim ids such that the url length is reduced to less than the max url length.
                idsString.lastIndexOf(',', idsString.length - (url.length - this.MAX_URL_LENGTH + 1)) :
                idsString.length;

            const idSubset = idsString.substr(0, commaIndex);
            idsString = idsString.substr(commaIndex + 1);

            const configWithIds: object = _.merge({ params: { [idKey]: idSubset } }, config);
            requests.push(
                this.getHttp().get(baseUrl, configWithIds) as Observable<EntityType<T>[]>,
            );
        }
        return requests;
    }

    setLoading(loading: boolean) {
        this.store.setLoading(loading);
    }
}

