import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, FormRecord } from '@angular/forms';
import { FudisDialogService } from '@funidata/ngx-fudis';
import {
    DisclosureAuthorization,
    DisclosureAuthorizationCategory,
    LocalizedString,
    OtmId,
    PrivatePerson,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, Observable, skip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    disclosureAuthorizationsEditModalOpener,
} from '../disclosure-authorization/disclosure-authorizations-edit-modal/disclosure-authorizations-edit-modal.component';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import {
    DisclosureAuthorizationCategoryEntityService,
} from '../service/disclosure-authorization-category-entity.service';
import { DisclosureAuthorizationEntityService } from '../service/disclosure-authorization-entity.service';
import { UniversityService } from '../service/university.service';

export interface PartialDisclosureAuthorization {
    authorized: boolean;
    id: OtmId;
    description: LocalizedString;
}

export interface DisclosureAuthorizationFormInterface {
    authorized: FormControl<boolean>;
    description: FormRecord<FormControl<string>>;
    id: FormControl<OtmId>;
}

export interface DisclosureAuthorizationForm { answers: FormArray<FormGroup<DisclosureAuthorizationFormInterface>> }

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-disclosure-authorizations',
    templateUrl: 'disclosure-authorization.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclosureAuthorizationsComponent implements OnInit, AfterViewInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.disclosureAuthorizations.downgraded',
        directiveName: 'sisDisclosureAuthorizations',
    };

    constructor(
        private universityService: UniversityService,
        private appErrorHandler: AppErrorHandler,
        private disclosureAuthorizationService: DisclosureAuthorizationEntityService,
        private disclosureAuthorizationCategoryService: DisclosureAuthorizationCategoryEntityService,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: FudisDialogService,
    ) {}

    @Input() person: PrivatePerson;
    @Input() editDisabled?: boolean = false;
    @Input() staffEdit?: boolean = false;
    @ViewChildren('disclosureAuthEditButton') disclosureAuthEditButton: QueryList<ElementRef>;

    categories: DisclosureAuthorizationCategory[];
    answers: DisclosureAuthorization[];
    categoriesWithoutAnswers: DisclosureAuthorizationCategory[];
    universityOrgId: OtmId;
    loaded$: Observable<[DisclosureAuthorizationCategory[], DisclosureAuthorization[]]>;
    openEditDialog = disclosureAuthorizationsEditModalOpener();

    ngOnInit(): void {
        this.universityOrgId = this.universityService.getCurrentUniversityOrgId();
        this.load();
    }

    ngAfterViewInit(): void {
        // Return focus to start edit button after closing edit mode
        this.disclosureAuthEditButton.changes.pipe(skip(1)).subscribe(() => {
            if (this.disclosureAuthEditButton.length > 0) {
                document.getElementById('disclosureAuthEditButton').focus();
            }
        });
    }

    startEdit(): void {
        this.openEditDialog(this.person, this.staffEdit, this.categories, this.answers, () => this.reset());
    }

    reset(): void {
        this.dialogService.close();
        this.load();
        this.changeDetectorRef.markForCheck();
    }

    load(): void {
        this.categories = undefined;
        this.answers = undefined;
        this.categoriesWithoutAnswers = undefined;
        this.loaded$ = combineLatest([
            this.disclosureAuthorizationCategoryService.getCategories(this.universityOrgId),
            this.disclosureAuthorizationService.getActiveAuthorizations(this.person?.id, this.universityOrgId),
        ]).pipe(
            tap(([
                categories,
                answers,
            ]) => {
                this.categories = categories;
                this.answers = answers;
                this.categoriesWithoutAnswers = this.getUnansweredCategories(categories);
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    getUnansweredCategories(categories: DisclosureAuthorizationCategory[]): DisclosureAuthorizationCategory[] {
        return _.chain(categories)
            .reject(category => !!this.answers.find(ans => ans.disclosureCategoryId === category.id))
            .value();
    }

    getAnswer(disclosureCategoryId: OtmId): DisclosureAuthorization {
        return _.find(this.answers, answer => answer.disclosureCategoryId === disclosureCategoryId);
    }

    getDescription(disclosureCategoryId: OtmId): LocalizedString {
        return _.find(this.categories, cat => cat.id === disclosureCategoryId)?.description;
    }

    hasCategoriesWithoutAnswers(): boolean {
        return !!this.categoriesWithoutAnswers && this.categoriesWithoutAnswers.length > 0;
    }

    hasAnswers(): boolean {
        return !!this.answers && this.answers.length > 0;
    }

    hasGranted(): boolean {
        return _.some(this.answers, 'authorized');
    }

    hasDenied(): boolean {
        return _.some(this.answers, ['authorized', false]);
    }

    authorizedAnswers(): DisclosureAuthorization[] {
        return _.filter(this.answers, answer => answer.authorized);
    }

    notAuthorizedAnswers(): DisclosureAuthorization[] {
        return _.filter(this.answers, answer => !answer.authorized);
    }

    changedByOther(answer: DisclosureAuthorization) {
        return answer.changedByPersonId !== answer.privatePersonId;
    }

    hasClassifiedData(): boolean {
        if (this.person) {
            const classifiedInfo = _.get(this.person, 'classifiedPersonInfo');
            if (classifiedInfo) {
                return _.get(classifiedInfo, 'isMunicipalityUrnClassified', false)
                    || _.get(classifiedInfo, 'isPhoneNumberClassified', false)
                    || _.get(classifiedInfo, 'isPrimaryAddressClassified', false)
                    || _.get(classifiedInfo, 'isSecondaryAddressClassified', false)
                    || _.get(classifiedInfo, 'isSecondaryEmailClassified', false);
            }
        }
        return false;
    }
}
