import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PersonDataChange, PrivatePerson } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { skip } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import {
    PersonalInfoHistoryDialogComponent,
} from 'sis-components/person/personal-info-history-dialog/personal-info-history-dialog.component';
import { ClassifiedVisibility } from 'sis-components/person/utils/basic-info-utils';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

@Component({
    selector: 'app-basic-info',
    templateUrl: './basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoComponent implements OnInit, AfterViewInit {

    @ViewChildren('dialogOpenTriggerButton') dialogOpenTriggerButton: QueryList<ElementRef>;
    @ViewChild('editContactDialog') editContactDialog: TemplateRef<unknown>;
    @Input() studentProfile: PrivatePerson;

    isEditEnabledByUniversity = false;
    classifiedInfoEditEnabledByUniversity = false;
    studentProfileCopy: PrivatePerson;
    history: PersonDataChange[] = null;

    classifiedVisibility: ClassifiedVisibility = {
        phoneNumberVisible: false,
        secondaryEmailVisible: false,
        primaryAddressVisible: false,
        secondaryAddressVisible: false,
        municipalityUrnVisible: false,
    };

    constructor(private universityService: UniversityService,
                private appErrorHandler: AppErrorHandler,
                private privatePersonEntityService: PrivatePersonEntityService,
                private dialogService: FudisDialogService,
    ) {}

    ngOnInit(): void {
        this.studentProfileCopy = { ...this.studentProfile };

        this.universityService.getCurrentUniversitySettings()
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
            .subscribe((universitySettings) => {
                if (_.get(universitySettings, 'frontendFeatureToggles.studentDetailsEditEnabled') !== false) {
                    this.isEditEnabledByUniversity = true;
                }
                if (_.get(universitySettings, 'frontendFeatureToggles.studentClassifiedInfoToggleable') !== false) {
                    this.classifiedInfoEditEnabledByUniversity = true;
                }
            });
    }

    ngAfterViewInit() {
        // Return focus to toggle button when cancelling cancel-operation
        this.dialogOpenTriggerButton.changes.pipe(skip(1)).subscribe(() => {
            if (this.dialogOpenTriggerButton.length > 0) {
                document.getElementById('dialogOpenTriggerButton').focus();
            }
        });
    }

    openPersonalInfoHistoryDialog(): void {
        if (!this.history) {
            this.privatePersonEntityService.getFullPersonDataChanges(this.studentProfileCopy.id)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                ).subscribe(result => {
                    this.history = result;
                    this.callPersonalInfoHistoryDialogOpener();
                });
        } else {
            this.callPersonalInfoHistoryDialogOpener();
        }
    }

    private callPersonalInfoHistoryDialogOpener() {
        this.dialogService.open(
            PersonalInfoHistoryDialogComponent,
            { data: {
                history: this.history,
                showCorrectedRows: false,
                studentId: this.studentProfile.id,
                arePICsIncludedInHistory: true,
            } },
        );
    }
}
