import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxLength } from 'common-typescript/constants';
import { Plan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

import { AlertsService } from '../../alerts/alerts-ng.service';
import { maxLength, required } from '../../form/form-validators';
import { SisFormBuilder } from '../../form/sis-form-builder.service';

export interface EditPlanNamePlanModalValues {
    plan: Plan;
}

@Component({
    selector: 'sis-edit-plan-name-modal',
    templateUrl: './edit-plan-name-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EditPlanNameModalComponent implements OnInit {

    plan: Plan;
    form: FormGroup;

    constructor(
        @Inject(ModalService.injectionToken) private values: EditPlanNamePlanModalValues,
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
        public activeModal: NgbActiveModal,
    ) {
        this.plan = values.plan;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.plan.name, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
        });
    }

    onCancel() {
        this.activeModal.dismiss();
    }

    onSubmit() {
        if (this.form.valid) {
            this.plan.name = this.name.value.trim();
            this.activeModal.close(this.plan);
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
            this.form.markAllAsTouched();
        }
    }

    get name(): FormControl {
        return this.form?.get('name') as FormControl;
    }

}
