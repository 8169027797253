<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form
        [titleVariant]="'lg'"
        [title]="t('PROFILE.PASSPORT_INFO.TITLE')"
        [level]="2">
        <fudis-form-content>
          <fudis-grid [columns]="2" [rowGap]="'xxs'" [columnGap]="'md'">
            @for (passport of passports.controls; track passport; let  i = $index) {
              <fudis-heading [level]="3" [variant]="'sm'">
                {{ t('PROFILE.PASSPORT_INFO.PASSPORT') }} {{ i+1 }}
                <fudis-button
                  (handleClick)="delete(i)"
                  [labelHidden]="true"
                  [icon]="'delete'"
                  [size]="'icon-only'"
                  [variant]="'tertiary'"
                  [label]=""
                />
              </fudis-heading>
              <fudis-grid-item>
                <fudis-text-input
                  [control]="passport.controls.issuer"
                  [label]="t('PROFILE.PASSPORT_INFO.ISSUER')"
                  [helpText]="t('PROFILE.PASSPORT_INFO.ISSUER_HELP_TEXT')">
                </fudis-text-input>
              </fudis-grid-item>
              <fudis-grid-item>
                <fudis-text-input
                  [control]="passport.controls.passportNumber"
                  [label]="t('PROFILE.PASSPORT_INFO.PASSPORT_NUMBER')">
                </fudis-text-input>
              </fudis-grid-item>
              <fudis-grid-item
                [columns]="'stretch'"
                [class]="'fudis-ml-xxl fudis-mr-xxl fudis-mb-xl'"
              >
                <label>{{ t('FIELD_NAMES.validityPeriod') }}</label>
                <fudis-date-range>
                  <fudis-datepicker
                          fudisDateStart
                          [label]="t('SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.START_DATE')"
                          [control]="passport.controls.validityPeriodStartDate"
                  />
                  <fudis-datepicker
                          fudisDateEnd
                          [label]="t('SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.END_DATE')"
                          [control]="passport.controls.validityPeriodEndDate"
                  />
                </fudis-date-range>
              </fudis-grid-item>
            }
          </fudis-grid>
          <fudis-button
            [label]="t('PROFILE.PASSPORT_INFO.NEW_PASSPORT')"
            (handleClick)="add()"
            [variant]="'tertiary'"
            [icon]="'plus'"
          />
        </fudis-form-content>
      </fudis-form>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button fudisDialogClose variant="secondary" [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')"/>
      <fudis-button fudisFormSubmit [formValid]="form.valid" (handleClick)="save()" [label]="t('SIS_COMPONENTS.BUTTON.SAVE')"/>
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>
