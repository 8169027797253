import { Injectable } from '@angular/core';
import { CourseUnitRealisation, LocalDateTimeString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';

export interface EnrolmentPeriodDates {
    startDateTime: LocalDateTimeString;
    endDateTime: LocalDateTimeString;
    lateEnrolmentEnd: LocalDateTimeString;
    cancellationEndDateTime: LocalDateTimeString;
}

/**
 * This service is an almost direct copy of the old AngularJS `enrolmentPeriodDateService`. Methods and unit tests were
 * directly copied from the old service with some minor changes.
 */
@Injectable({
    providedIn: 'root',
})
export class EnrolmentPeriodDateService {

    private getStartDateTime(cur: CourseUnitRealisation): LocalDateTimeString | undefined {
        return cur?.enrolmentPeriod?.startDateTime;
    }

    private getEndDateTime(cur: CourseUnitRealisation): LocalDateTimeString | undefined {
        return cur?.enrolmentPeriod?.endDateTime;
    }

    private getLateEnrolmentEndDateTime(cur: CourseUnitRealisation): LocalDateTimeString {
        return cur?.lateEnrolmentEnd;
    }

    private hasEnrolmentStartAndEnd(cur: CourseUnitRealisation): boolean {
        return !!this.getStartDateTime(cur) && !!this.getEndDateTime(cur);
    }

    enrolmentPeriodOngoing(cur: CourseUnitRealisation) {
        if (!this.hasEnrolmentStartAndEnd(cur)) {
            return false;
        }
        const now = moment();
        return now.isAfter(this.getStartDateTime(cur)) && now.isBefore(this.getEndDateTime(cur));

    }

    lateEnrolmentPeriodOngoing(cur: CourseUnitRealisation): boolean {
        if (!this.hasEnrolmentStartAndEnd(cur)) {
            return false;
        }
        const lateEnrolmentEndDateTime = this.getLateEnrolmentEndDateTime(cur);
        if (!lateEnrolmentEndDateTime) {
            return false;
        }
        const now = moment();
        return now.isAfter(this.getEndDateTime(cur)) && now.isBefore(lateEnrolmentEndDateTime);

    }

    cancellationPossible(cur: CourseUnitRealisation): boolean {
        if (!this.hasEnrolmentStartAndEnd(cur)) {
            return false;
        }
        const now = moment();
        const dates = this.getEnrolmentDates(cur);
        return now.isAfter(dates.startDateTime) && now.isBefore(dates.cancellationEndDateTime);

    }

    activityPeriodOnGoing(cur: CourseUnitRealisation): boolean {
        const startDate = _.get(cur, 'activityPeriod.startDate');
        const endDate = _.get(cur, 'activityPeriod.endDate');
        return startDate && endDate && moment().isBetween(startDate, endDate, 'day', '[)');
    }

    activityPeriodEnded(cur: CourseUnitRealisation): boolean {
        const endDate = _.get(cur, 'activityPeriod.endDate');
        return endDate && moment().isAfter(endDate);
    }

    enrolmentPeriodStarted(cur: CourseUnitRealisation): boolean {
        if (!this.hasEnrolmentStartAndEnd(cur)) {
            return false;
        }
        return moment().isAfter(this.getStartDateTime(cur));
    }

    allEnrolmentPeriodsHaveEnded(cur: CourseUnitRealisation): boolean {
        if (!this.hasEnrolmentStartAndEnd(cur)) {
            return false;
        }
        return this.enrolmentPeriodStarted(cur) &&
            !this.enrolmentPeriodOngoing(cur) &&
            !this.lateEnrolmentPeriodOngoing(cur);
    }

    getEnrolmentDates(cur: CourseUnitRealisation): EnrolmentPeriodDates {
        const cancellationEndDateTime = _.get(cur, 'enrolmentAdditionalCancellationEnd');
        return {
            startDateTime: this.getStartDateTime(cur),
            endDateTime: this.getEndDateTime(cur),
            lateEnrolmentEnd: this.getLateEnrolmentEndDateTime(cur),
            cancellationEndDateTime: !_.isNil(cancellationEndDateTime) ? cancellationEndDateTime : this.getEndDateTime(cur),
        };
    }

    continuousEnrolmentPeriodOngoing(courseUnitRealisation: CourseUnitRealisation): boolean {
        return !!courseUnitRealisation.continuousEnrolment && this.enrolmentPeriodOngoing(courseUnitRealisation);
    }

    lateOrContinuousPeriodOngoing(courseUnitRealisation: CourseUnitRealisation) {
        return this.lateEnrolmentPeriodOngoing(courseUnitRealisation)
            || this.continuousEnrolmentPeriodOngoing(courseUnitRealisation);
    }
}
