<ng-container *transloco="let t">
  <div *ngIf="initializeForm$ | async" class="attainment-misregistration-modal" data-cy="attainment-misregistration-modal">
    <div class="modal-header">
      <h2 class="modal-title">
        {{ t('PROFILE.ATTAINMENT.MISREGISTRATION_MODAL_TITLE') }}
      </h2>
      <button type="button"
              class="modal-close"
              (click)="modalInstance.dismiss()"
              [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
        <sis-icon icon="ring-close"></sis-icon>
      </button>
    </div>

    <div class="modal-body" *ngIf="!attainment.misregistration">
      <ng-container *ngIf="attainment.personId | personInfo | async as person">
        <p class="misregistration-rationale-modal-text">
          {{ t('PROFILE.ATTAINMENT.MISREGISTRATION_MODAL_TEXT',
          {
            studyCode: attainment | attainmentCode | async,
            studyName: attainment | attainmentName | async | localizedString,
            personFirstNames: person.firstNames,
            personLastName: person.lastName,
            personStudentNumber: person.studentNumber
          })
          }}
        </p>
        <sis-contextual-notification variant="warning" class="misregistration-rationale">
          <div *ngIf="attainment.personId | personInfo | async as person">
            {{ t('PROFILE.ATTAINMENT.MISREGISTRATION_MODAL_NOTIFICATION_TEXT',
            {
              personFirstNames: person.firstNames,
              personLastName: person.lastName,
              personStudentNumber: person.studentNumber
            })
            }}
          </div>
        </sis-contextual-notification>
      </ng-container>
      <form class="sis-mt-md" name="attainmentMisregistrationForm">
        <div class="form-group misregistration-rationale">
          <sis-text-field
            [control]="misregistrationRationale"
            [label]="t('PROFILE.ATTAINMENT.MISREGISTRATION_RATIONALE')"
            [showLabelIcon]="true"
            [textarea]="true"
            [type]="'text'"
            [placeholder]="t('PROFILE.ATTAINMENT.MISREGISTRATION_PLACEHOLDER')">
          </sis-text-field>
        </div>
        <div *ngIf="!!reopenApplication" class="form-group">
          <label class="control-label required" [ngClass]="getLabelState(reopenApplication)">{{ t('PROFILE.ATTAINMENT.REOPEN_APPLICATION_LABEL') }}</label>
          <sis-radio-button [control]="reopenApplication"
                            [translatedLabel]="t('BOOLEAN.YES')"
                            [value]="true"
          ></sis-radio-button>
          <sis-radio-button [control]="reopenApplication"
                            [translatedLabel]="t('BOOLEAN.NO')"
                            [value]="false"
          ></sis-radio-button>
          <p class="help-block">{{ t('PROFILE.ATTAINMENT.REOPEN_APPLICATION_HELP_BLOCK') }}</p>
        </div>
      </form>
    </div>

    <div class="modal-body" *ngIf="attainment.misregistration">
      <sis-contextual-notification variant="danger" class="misregistration-rationale">
        <div>{{t('ATTAINMENTS.ATTAINMENT_MISREGISTRATION_MODAL.ALREADY_MISREGISTERED')}}</div>
      </sis-contextual-notification>

      <label class="form-label">{{t('ATTAINMENTS.ATTAINMENT_MISREGISTRATION_MODAL.MISREGISTRATION_RATIONALE_TITLE')}}</label>
      <p class="form-control-static">
        {{ attainment.misregistrationRationale }}
      </p>
    </div>

    <div class="modal-footer" *ngIf="!attainment.misregistration">
      <sis-button [hollow]="true" (clicked)="modalInstance.dismiss()">{{ t('BUTTON.CANCEL') }}</sis-button>
      <sis-button [dataCy]="'misregistration-modal-button'"
                  [disabled]="form.invalid"
                  (clicked)="ok()">
        {{ t('PROFILE.ATTAINMENT.MISREGISTRATION_MODAL_BUTTON') }}
      </sis-button>
    </div>

    <div class="modal-footer" *ngIf="attainment.misregistration">
      <sis-button (clicked)="modalInstance.dismiss()">{{ t('BUTTON.CLOSE') }}</sis-button>
    </div>

  </div>
</ng-container>

