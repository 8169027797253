import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    CeeposPaymentConfirmation, PaymentSystemLog,
} from 'common-typescript/types';
import { catchError, retry, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PaymentSystemLogEntityService } from 'sis-components/service/payment-system-log-entity.service';
import { PaymentSystemService } from 'sis-components/service/payment-system.service';

import { CeeposPaymentService } from '../../../common/service/ceepos-payment.service';

@Component({
    selector: 'app-ceepos-payment-complete',
    templateUrl: './ceepos-payment-complete.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CeeposPaymentCompleteComponent implements OnInit {

    constructor(
        private paymentSystemLogEntityService: PaymentSystemLogEntityService,
        private paymentSystemService: PaymentSystemService,
        private stateService: StateService,
        private appErrorHandler: AppErrorHandler,
        private ceeposPaymentService: CeeposPaymentService,
    ) {}

    ngOnInit(): void {
        const ceeposPaymentConfirmation: CeeposPaymentConfirmation = this.ceeposPaymentService.readCeeposResponseFromQueryParams();

        this.logCeeposPaymentConfirmation(ceeposPaymentConfirmation);
        this.paymentSystemService.completePayment(undefined, ceeposPaymentConfirmation)
            .pipe(
                take(1),
                retry({ count: 3, delay: 10000 }), // if error, wait 10 seconds and try the request again (max 3 times)
                catchError((err) => this.redirectToPaymentProcessingPage(err)),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (payment) => {
                    if (payment?.status === 'PAID') {
                        this.stateService.go('student.open-university-cart.payment-successful', { paymentId: payment.id });
                    } else if (payment?.status === 'CANCELLED') {
                        this.stateService.go('student.open-university-cart.payment-failed');
                    } else {
                        this.stateService.go('student.open-university-cart.payment-processing');
                    }
                },
            });
    }

    private logCeeposPaymentConfirmation(ceeposPaymentConfirmation: CeeposPaymentConfirmation) {
        const paymentSystemLog: Partial<PaymentSystemLog> = {
            jsonLog: JSON.stringify(ceeposPaymentConfirmation),
            paymentId: ceeposPaymentConfirmation.Id,
            paymentSystem: 'CEEPOS',
            paymentSystemLogMsgDirection: 'FRONTEND_INBOUND',
        };
        this.paymentSystemLogEntityService.create(paymentSystemLog);
    }

    private redirectToPaymentProcessingPage(err: any) {
        this.stateService.go('student.open-university-cart.payment-processing');
        return throwError(() => err);
    }
}
