import { Pipe, PipeTransform } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import moment from 'moment';

@Pipe({ name: 'localDateFormat' })
export class LocalDateFormatPipe implements PipeTransform {
    transform(value: string | Date | moment.Moment): any {
        return dateUtils.formatDateIfValid(value, 'l');
    }
}
