import { Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    CourseUnitRealisation,
    StudyGroupSet,
} from 'common-typescript/types';
import * as _ from 'lodash-es';

@Component({
    selector: 'sis-course-unit-realisation-structure',
    templateUrl: './course-unit-realisation-structure.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitRealisationStructureComponent implements OnChanges {

    studyGroupSets: StudyGroupSet[] = [];

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() language: string;

    ngOnChanges() {
        if (this.courseUnitRealisation && !_.isEmpty(this.courseUnitRealisation.studyGroupSets)) {
            this.studyGroupSets = this.courseUnitRealisation.studyGroupSets;
        }
    }

}
