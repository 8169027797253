import angular from 'angular';
import * as _ from 'lodash-es';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { CommonCodeService } from 'sis-components/service/common-code.service.ts';
export const localizedUrnNameDisplayModule = 'sis-components.codes.localizedUrnNameDisplay';

/**
 * Use CodeLocalizedNamePipe instead of "upgraded version of localizedUrnNameDisplay component" when upgrading usages to Angular. Implement
 * fallback to the pipe or new "fallback-pipe" if the fallback is really needed.
 */
(function () {
  LocalizedUrnNameDisplayController.$inject = ["localeService", "commonCodeService", "defaultPromiseHandler", "$timeout"];
  angular.module(localizedUrnNameDisplayModule, [localeServiceModule, CommonCodeService.downgrade.moduleName]).component('localizedUrnNameDisplay', {
    bindings: {
      urn: '<'
    },
    controller: LocalizedUrnNameDisplayController,
    template: '<span>{{$ctrl.localizedName}}</span>'
  });
  function LocalizedUrnNameDisplayController(localeService, commonCodeService, defaultPromiseHandler, $timeout) {
    const $ctrl = this;
    $ctrl.localizedName = '';
    $ctrl.$onChanges = function () {
      if (!$ctrl.urn) {
        $ctrl.localizedName = '';
        return;
      }
      commonCodeService.getCode($ctrl.urn).then(code => {
        if (_.get(code, 'name')) {
          $timeout(() => {
            $ctrl.localizedName = code.name[localeService.getCurrentLocale()] || fallback(code);
          });
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    function fallback(code) {
      return _.get(code, 'name.fi') || _.get(code, 'name.en') || _.get(code, 'name.sv') || '';
    }
  }
})();