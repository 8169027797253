import { Pipe, PipeTransform } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Pipe({ name: 'courseUnitRealisationName' })
export class CourseUnitRealisationNamePipe implements PipeTransform {

    constructor(private localeService: LocaleService) {}

    transform(cur: CourseUnitRealisation, useFullName: boolean = false, preferredLanguage?: string): any {
        if (!cur) {
            return undefined;
        }

        const nameKey = useFullName ? 'fullName' : 'name';
        return this.localeService.localize(_.result(cur, nameKey, cur.name), preferredLanguage);
    }
}
