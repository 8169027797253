<div class="clear">
  <ul class="tree-list tree-list-study-plan clearfix">
    <li class="{{classes}}">
      <div class="module-border" [class.collapsed]="!isOpen" [class.module-border-selected]="uiStateObject.selectedModule?.id === module.id">
        <div class="tree-list-heading tree-list-heading-has-children clearfix no-padding">
          <div class="accordion-panel accordion-panel-long-title"
               [class.accordion-panel-primary]="uiStateObject.selectedModule?.id === module.id"
               [class.accordion-panel-noblock]="uiStateObject.selectedModule?.id !== module.id"
               [class.clickable]="!moduleStateObject.attainmentId"
               [class.highlighted]="highlighted">
            <div class="header has-header-items">
              <button type="button"
                      class="collapse non-button"
                      (click)="isOpen = !isOpen"
                      [class.collapsed]="!isOpen"
                      [class.disabled]="isImplicit"
                      [attr.aria-expanded]="isOpen"
                      [attr.aria-label]="(module.name | localizedString)">
                <sis-icon icon="chevron-ring-fill" [iconClass]="isOpen ? 'cw-90' : null"></sis-icon>
              </button>
              <ng-container *ngIf="moduleStateObject.studyRightState">
                <sis-icon icon="junction"></sis-icon>
                <span class="visually-hidden" translate>ARIA_LABEL.STUDY_RIGHT</span>
              </ng-container>
              <div class="title" (click)="handleModuleHeaderClick()">
                <span class="plan-module-name">{{module.name | localizedString}}</span>
                <span class="additional">
                  <span *ngIf="moduleStateObject.isAttainmentAboutToExpire" class="tiny-badge accent">
                    <span translate>PLAN.MODULE.EXPIRATION_IMMINENT</span>
                    {{ moduleStateObject.attainmentExpiryDate | localDateFormat }}
                  </span>
                  <span *ngIf="moduleStateObject.invalidSelection && moduleStateObject.hasModuleContentApproval" class="tiny-badge danger">
                    <span translate>PLAN.INVALID_SELECTIONS.NOTIFICATION</span>
                  </span>
                  <span *ngIf="moduleStateObject.invalidSelectionAccordingToModuleContentApproval" class="tiny-badge danger">
                    <span translate>PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION</span>
                  </span>
                  <span *ngIf="module.documentState === 'DRAFT' || module.documentState === 'DELETED'"
                        class="tiny-badge"
                        [class.accent]="module.documentState === 'DRAFT'"
                        [class.danger]="module.documentState === 'DELETED'"
                        [translate]="('VISIBLE_DOCUMENT_STATE.'+module.documentState)">
                  </span>
                  {{ module.code }}
                  <span *ngIf="moduleStateObject.studyRightState">
                    | <span>{{ ('PLAN_STUDY_RIGHT_STATE.' + moduleStateObject.studyRightState) | translate }}</span>
                  </span>
                </span>
              </div>
              <div class="cell info-box" *ngIf="isImplicit">
                <div><sis-icon icon="info"></sis-icon></div>
                <div class="info-text">{{'PLAN.MODULE.IMPLICIT_IN_MODULE' |translate}} {{ parentModule.name | localizedString }}</div>
              </div>
              <div class="cell average"
                   *ngIf="moduleStateObject.gradeAverage"
                   [class.clickable]="!moduleStateObject.attainmentId">
                <div>{{moduleStateObject.gradeAverage}}</div>
              </div>
              <div class="cell status">
                <div class="state-attained" *ngIf="moduleStateObject.attainmentId">
                  <span translate="SIS_COMPONENTS.PLAN_STATE.ATTAINED"></span>
                  <sis-icon icon="achievement" color="success"></sis-icon>
                  <span class="grade-text"> {{ moduleStateObject.grade.abbreviation | localizedString }} </span>
                </div>
                <div *ngIf="moduleStateObject.moduleValidationResult.state!=='ATTAINED'"
                     [ngSwitch]="moduleStateObject.planState"
                     class="cell status">
                  <div *ngSwitchCase="'INVALID'"
                       [class.state-gray]="uiStateObject.selectedModule?.id === module.id"
                       [class.state-invalid]="uiStateObject.selectedModule?.id !== module.id">
                    <sis-icon icon="alert-fill"></sis-icon>
                    <span *ngIf="moduleStateObject.planState">{{('SIS_COMPONENTS.PLAN_STATE.' + moduleStateObject.planState) | translate}}</span>
                  </div>
                  <div *ngSwitchCase="'MODULECONTENTAPPROVAL_VALIDATION_STATE_INVALID_REQUESTED'"
                       [class.state-gray]="uiStateObject.selectedModule?.id === module.id"
                       [class.state-invalid]="uiStateObject.selectedModule?.id !== module.id">
                    <sis-icon icon="alert-fill"></sis-icon>
                    <span *ngIf="moduleStateObject.planState">{{('SIS_COMPONENTS.PLAN_STATE.' + moduleStateObject.planState) | translate}}</span>
                  </div>
                  <div *ngSwitchCase="'MODULECONTENTAPPROVAL_VALIDATION_STATE_INVALID_ACCEPTED'"
                       [class.state-gray]="uiStateObject.selectedModule?.id === module.id"
                       [class.state-invalid]="uiStateObject.selectedModule?.id !== module.id">
                    <sis-icon icon="alert-fill"></sis-icon>
                    <span *ngIf="moduleStateObject.planState">{{('SIS_COMPONENTS.PLAN_STATE.' + moduleStateObject.planState) | translate}}</span>
                  </div>
                  <div *ngSwitchDefault>
                    <span *ngIf="moduleStateObject.planState">{{('SIS_COMPONENTS.PLAN_STATE.' + moduleStateObject.planState) | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="cell module-credits">
                <sis-tooltip *ngIf="targetCredits"
                             [tooltipText]="('PROFILE.ATTAINMENT.CREDITS' | translate) + ' ' + ( targetCredits | creditRange )"
                             [appendToBody]="true">
                  <div class="credit-range sis-tooltip-trigger-content">
                    <span [class.no-target-credits]="!targetCredits">
                      <span>{{targetCredits | creditRange:'TEXT':true}}</span>
                    </span>
                  </div>
                </sis-tooltip>
                <div class="planned-attained-credits" (click)="openModuleSelections()">
                  <sis-tooltip *ngIf="moduleStateObject.moduleValidationResult.plannedCredits && moduleStateObject.moduleValidationResult.plannedCredits.min !== 0 && moduleStateObject.moduleValidationResult.plannedCredits.max !== 0"
                               [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS' | translate) + ' ' + (moduleStateObject.moduleValidationResult.plannedCredits | creditRange)"
                               [appendToBody]="true"
                               tooltip-placement="top-right">
                    <div class="planned-credits sis-tooltip-trigger-content">
                      {{moduleStateObject.moduleValidationResult.plannedCredits | creditRange:'TEXT':true}}
                    </div>
                  </sis-tooltip>
                  <sis-tooltip *ngIf="!moduleStateObject.moduleValidationResult.plannedCredits || moduleStateObject.moduleValidationResult.plannedCredits.min === 0 && moduleStateObject.moduleValidationResult.plannedCredits.max === 0"
                               [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.SELECTED_CREDITS' | translate) + ' - ' + ('CREDITS' | translate)"
                               [appendToBody]="true"
                               tooltip-placement="top-right">
                    <div class="planned-credits sis-tooltip-trigger-content">
                      -
                    </div>
                  </sis-tooltip>
                  <sis-tooltip *ngIf="moduleStateObject.moduleValidationResult.attainedCredits"
                               [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.ATTAINED' | translate) + ' ' + (moduleStateObject.moduleValidationResult.attainedCredits | decimalNumber) + ' ' + ('CREDITS' | translate)"
                               [appendToBody]="true"
                               tooltip-placement="top-left">
                    <div class="attained-credits sis-tooltip-trigger-content">
                      {{moduleStateObject.moduleValidationResult.attainedCredits | decimalNumber}}
                    </div>
                  </sis-tooltip>
                  <sis-tooltip *ngIf="!moduleStateObject.moduleValidationResult.attainedCredits"
                               [tooltipText]="('SIS_COMPONENTS.PLAN.LABEL.ATTAINED' | translate) + ' - ' + ('CREDITS' | translate)"
                               [appendToBody]="true"
                               tooltip-placement="top-left">
                    <div class="attained-credits sis-tooltip-trigger-content">
                      -
                    </div>
                  </sis-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!moduleStateObject.attainmentId">
            <div *ngIf="!isImplicit && isOpen" class="tree-list-body" [class.collapsed]="!isOpen">
              <div class="row">
                <div *ngFor="let courseUnitId of planStateObject.modules[module.id].selectedCourseUnitIds" class="col-sm-6 tree-list-body-inner">
                  <sis-plan-course-unit *ngIf="!planStateObject.courseUnits[courseUnitId].isSubstituted"
                                        [courseUnit]="planData.courseUnitsById[courseUnitId]"
                                        [parentModule]="module"
                                        [planValidationResult]="planValidationResult"
                                        [planStateObject]="planStateObject"
                                        [planData]="planData"></sis-plan-course-unit>
                  <div *ngIf="planStateObject.courseUnits[courseUnitId].isSubstituted"
                       [class.courseunit-substitution-group]="planStateObject.courseUnits[courseUnitId].substitutedBy.length > 1">
                    <sis-plan-course-unit *ngFor="let substituteCourseUnitId of planStateObject.courseUnits[courseUnitId].substitutedBy"
                                          [courseUnit]="planData.courseUnitsById[substituteCourseUnitId]"
                                          [planValidationResult]="planValidationResult"
                                          [planStateObject]="planStateObject"
                                          [planData]="planData"
                                          [substitutedCourseUnitId]="courseUnitId"></sis-plan-course-unit>
                  </div>
                </div>
                <div *ngFor="let customStudyDraftId of planStateObject.modules[module.id].selectedCustomStudyDraftIds" class="col-sm-6 tree-list-body-inner">
                  <sis-plan-custom-study-draft [customStudyDraft]="planData.customStudyDraftsById[customStudyDraftId]"
                                               [planStateObject]="planStateObject"></sis-plan-custom-study-draft>
                </div>
                <div *ngFor="let customCourseUnitAttainmentId of planStateObject.modules[module.id].selectedCustomCourseUnitAttainmentIds" class="col-sm-6 tree-list-body-inner">
                  <sis-plan-custom-course-unit-attainment
                    [customCourseUnitAttainment]="$any(planData.attainmentsById[customCourseUnitAttainmentId])"
                    [planStateObject]="planStateObject">
                  </sis-plan-custom-course-unit-attainment>
                </div>
              </div>
            </div>
            <div *ngIf="isOpen">
              <sis-plan-module *ngFor="let moduleId of planStateObject.modules[module.id].selectedModuleIds"
                               [module]="planData.modulesById[moduleId]"
                               [planValidationResult]="planValidationResult"
                               [planStateObject]="planStateObject"
                               [planData]="planData"
                               [uiStateObject]="uiStateObject"
                               [isImplicit]="false"
                               [level]="level+1">
              </sis-plan-module>
              <sis-plan-custom-module-attainment *ngFor="let customModuleAttainmentId of planStateObject.modules[module.id].selectedCustomModuleAttainmentIds"
                                                 [customModuleAttainment]="$any(planData.attainmentsById[customModuleAttainmentId])"
                                                 [planStateObject]="planStateObject"
                                                 [planData]="planData"
                                                 [uiStateObject]="uiStateObject"
                                                 [level]="level+1"></sis-plan-custom-module-attainment>
            </div>
          </div>
          <div *ngIf="isOpen && moduleStateObject.attainmentId">
            <sis-attainment-node-structure [attainmentNodes]="this.attainment?.nodes"
                                           [planStateObject]="planStateObject"
                                           [planData]="planData"
                                           [uiStateObject]="uiStateObject"
                                           [level]="level">
            </sis-attainment-node-structure>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
