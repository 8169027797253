import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/constants';
import {
    OtmId,
    StudyRight,
    StudyRightState,
    StudyRightTermRegistrations,
    TermRegistration,
    TermRegistrationCounts,
    Urn,
} from 'common-typescript/types';
import moment from 'moment';
import { firstValueFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
        updateTermRegistrations(studyRightId: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/study-rights/${studyRightId}/term-registrations`;
        },
        getTermRegistrationCounts(studyRightId: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/term-registration-counts/${studyRightId}`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'term-registrations',
})
export class StudyRightTermRegistrationsEntityService extends EntityService<StudyRightTermRegistrationsState> {
    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studyRightTermRegistrationsEntityService',
        serviceName: 'studyRightTermRegistrationsEntityService',
    };

    constructor() {
        super(StudyRightTermRegistrationsStore, StudyRightTermRegistrationsQuery);
    }

    /**
     * Note that backend returns a StudyRight (with enriched term registrations). We force loading of SRTR object.
     */
    updateTermRegistrations(studyRightId: OtmId, termRegistrations: TermRegistration[]): Observable<StudyRightTermRegistrations> {
        return this.getHttp().put<StudyRight>(CONFIG.ENDPOINTS.updateTermRegistrations(studyRightId), termRegistrations)
            .pipe(switchMap(() => this.getById(studyRightId, true)));
    }

    /**
     * Note that backend returns a StudyRight (with enriched term registrations). We force loading of SRTR object.
     * Note also that this is "add term registration" but the usual use case is to change missing -> attending for TR.
     */
    updateTermRegistrationTypes(studyRightId: OtmId, termRegistrations: TermRegistration[]): Observable<StudyRightTermRegistrations> {
        return this.getHttp().post<StudyRight>(CONFIG.ENDPOINTS.updateTermRegistrations(studyRightId), termRegistrations)
            .pipe(switchMap(() => this.getById(studyRightId, true)));
    }

    getTermRegistrationCounts(studyRightId: OtmId): Observable<TermRegistrationCounts> {
        return this.getHttp().get<TermRegistrationCounts>(CONFIG.ENDPOINTS.getTermRegistrationCounts(studyRightId));
    }

    getTermRegistrationCountsPromise(studyRightId: OtmId): Promise<TermRegistrationCounts> {
        return firstValueFrom(this.getTermRegistrationCounts(studyRightId));
    }

    getStatusBadgeColor(studyRight: StudyRight, currentTermRegistration: TermRegistration, registrationRequired: boolean, educationType: Urn,
                        studyRightCurrentState: StudyRightState) {
        if (studyRightCurrentState === 'ACTIVE') {
            const termRegistrationType = currentTermRegistration?.termRegistrationType;
            if (termRegistrationType === 'ATTENDING') {
                return 'success';
            }
            if (termRegistrationType === 'NONATTENDING') {
                return 'accent';
            }
            return registrationRequired ? 'danger' : 'success';
        }
        if (studyRightCurrentState === 'PASSIVE' && this.studyRightValidityDateExpired(educationType, studyRight)) {
            return 'secondary';
        }
        if (studyRightCurrentState === 'PASSIVE' || studyRightCurrentState === 'DENIED') {
            return 'danger';
        }
        return 'secondary';
    }

    /**
     * Non degree education is never expired according to this method.
     */
    studyRightValidityDateExpired(educationType: Urn, studyRight: StudyRight) {
        if (educationType?.includes(':degree-education')) {
            return dateUtils.isRangeBefore(moment(), studyRight.valid);
        }
        return false;
    }
}

type StudyRightTermRegistrationsState = EntityState<StudyRightTermRegistrations, OtmId>;

@StoreConfig({ name: 'study-right-term-registrations', idKey: 'studyRightId' })
class StudyRightTermRegistrationsStore extends EntityStore<StudyRightTermRegistrationsState> {}

class StudyRightTermRegistrationsQuery extends QueryEntity<StudyRightTermRegistrationsState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<StudyRightTermRegistrationsState>) {
        super(store);
    }
}
