import angular from 'angular';
import * as _ from 'lodash-es';
import { commonModuleServiceModule } from 'sis-components/service/module.service';
import { localizedUrnNameDisplayModule } from 'sis-components/codes/localizedUrnNameDisplay.component';
import { localizedMarkupStringFilterModule } from 'sis-components/string/localizedMarkupString.filter';
import { LocalizedMarkupStringViewComponent } from 'sis-components/localized-markup-string-view/localized-markup-string-view.component.ts';
import minorSelectionModalTpl from './minorSelectionModal.tpl.html';
(function () {
  minorSelectionModalController.$inject = ["$q", "$rootScope", "$translate", "minorStudyRightService", "alertsService", "universityService", "StudyRightMinorSelectionState", "defaultPromiseHandler", "commonModuleService", "$timeout"];
  angular.module('student.plan.minorSelectionModal', ['pascalprecht.translate', 'sis-common.errorhandler.errorService', 'sis-common.university', 'sis-components.alertsService', 'student.common.components.moduleBox', 'student.common.service.minorStudyRightService', commonModuleServiceModule, localizedUrnNameDisplayModule, localizedMarkupStringFilterModule, LocalizedMarkupStringViewComponent.downgrade.moduleName]).component('minorSelectionModal', {
    bindings: {
      resolve: '<',
      close: '&'
    },
    controller: minorSelectionModalController,
    template: minorSelectionModalTpl
  });
  function minorSelectionModalController($q, $rootScope, $translate, minorStudyRightService, alertsService, universityService, StudyRightMinorSelectionState, defaultPromiseHandler, commonModuleService, $timeout // NOSONAR
  ) {
    var ctrl = this;
    ctrl.$onInit = function () {
      _.assign(ctrl, ctrl.resolve);
      ctrl.education = ctrl.studyRight.education;
      ctrl.hasMinorSelection = !!minorStudyRightService.getMinorSelection(ctrl.module, ctrl.studyRight, ctrl.validatablePlan);
      ctrl.moduleSelectedUnderStudyRightPhase = minorStudyRightService.isModuleSelectedUnderStudyRightPhase(ctrl.module, ctrl.studyRight, ctrl.validatablePlan);
      ctrl.teacherAcceptanceRequired = minorStudyRightService.isTeacherAcceptanceRequired(ctrl.module);
      ctrl.canConfirm = false;
      if (!ctrl.hasMinorSelection) {
        ctrl.canConfirm = ctrl.moduleSelectedUnderStudyRightPhase && !ctrl.teacherAcceptanceRequired;
      }

      // Set up feature toggles from university settings
      ctrl.minorSelectionsConfirmable = true;
      if (ctrl.canConfirm) {
        universityService.getCurrentUniversitySettings().then(settings => {
          ctrl.minorSelectionsConfirmable = _.get(settings, 'frontendFeatureToggles.minorSelectionsConfirmable', true);
          ctrl.canConfirm = ctrl.minorSelectionsConfirmable;
        });
      }
      const moduleGroupIds = _.chain(['phase1MinorSelections', 'phase2MinorSelections']).flatMap(property => _.get(ctrl.studyRight, property, [])).map('moduleGroupId').compact().uniq().sort().value();
      commonModuleService.findByGroupIds(moduleGroupIds).then(result => {
        ctrl.modulesByGroupId = _.keyBy(result, 'groupId');
      });
    };
    ctrl.confirmSelection = function () {
      return minorStudyRightService.confirmSelection(ctrl.module, ctrl.studyRight, ctrl.validatablePlan).then(function () {
        $rootScope.$broadcast('studyRightChanged');
        alertsService.success($translate.instant('MINOR_SELECTION_MODAL.SELECTION_CONFIRMED_SUCCESSFULLY'));
        $timeout(function () {
          alertsService.dismissAlertIfExists('plan-alert-minor-selection-confirmation-' + ctrl.module.id);
        }, 300); // dismissing right after alert seems not to work properly without delay
        ctrl.close();
      }, function (error) {
        ctrl.error = true;
        return $q.reject(error);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };

    /**
     * Assume that groupId is in studyRight.acceptedSelectionPath as child group id.
     */
    ctrl.getChildOptionNamingUrn = function (groupId) {
      var namingType;
      var acceptedSelectionPath = ctrl.studyRight.acceptedSelectionPath;
      var structure = ctrl.education.structure;
      if (groupId === acceptedSelectionPath.educationPhase1ChildGroupId) {
        namingType = getNamingType(_.get(structure, 'phase1.options', []), acceptedSelectionPath.educationPhase1GroupId);
      }
      if (groupId === acceptedSelectionPath.educationPhase2ChildGroupId) {
        namingType = getNamingType(_.get(structure, 'phase2.options', []), acceptedSelectionPath.educationPhase2GroupId);
      }
      return namingType;
      function getNamingType(options, phaseGroupId) {
        if (!phaseGroupId) {
          return undefined;
        }
        const option = _.find(options, {
          moduleGroupId: phaseGroupId
        });
        return _.get(option, 'childNaming');
      }
    };
    ctrl.getEducationPhaseNameForModule = function () {
      return _.get(minorStudyRightService.getEducationPhaseForModule(ctrl.module, ctrl.studyRight, ctrl.validatablePlan), 'name');
    };

    // Module might not be in plan
    ctrl.getModuleByGroupId = groupId => _.get(ctrl.modulesByGroupId, groupId, ctrl.validatablePlan.getModuleInPlanByGroupId(groupId));
    ctrl.getModuleNameForGroupId = groupId => _.get(ctrl.getModuleByGroupId(groupId), 'name');
    ctrl.toApprovedMinorSelections = function (minorSelections) {
      return _.chain(minorSelections).filter({
        selectionState: StudyRightMinorSelectionState.APPROVED
      }).value();
    };
    ctrl.toRequestedMinorSelections = function (minorSelections) {
      return _.chain(minorSelections).filter({
        selectionState: StudyRightMinorSelectionState.REQUESTED
      }).value();
    };
    ctrl.getViewState = function (minorSelection) {
      const module = ctrl.getModuleByGroupId(minorSelection.moduleGroupId);
      return minorStudyRightService.getViewStateForModule(module, ctrl.studyRight, ctrl.validatablePlan);
    };
    ctrl.cancel = function () {
      ctrl.close();
    };
  }
})();