import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MaxLength } from 'common-typescript/constants';
import {
    AssessmentItem,
    EnrolmentConstraint,
    EnrolmentRight,
    EnrolmentRightUpdateRequest,
    LocalDateRange,
    OtmId,
} from 'common-typescript/types';
import { isEqual } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AlertsService, AlertType } from '../../../alerts/alerts-ng.service';
import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { isInteger, maxLength, min, required } from '../../../form/form-validators';
import { getLabelState, getLocalDateRangeEditorValue } from '../../../form/formUtils';
import { LocalDateRangeForm, SisFormBuilder } from '../../../form/sis-form-builder.service';
import { SisFormControl } from '../../../form/sis-form-control';
import { AssessmentItemEntityService } from '../../../service/assessment-item-entity.service';
import { EnrolmentRightEntityService } from '../../../service/enrolment-right-entity.service';
import { OpenUniversityProductEntityService } from '../../../service/open-university-product-entity.service';
import { isCourseUnitEnrolmentRight } from '../enrolment-right.type-guards';

export interface EditModalValues {
    enrolmentRight: EnrolmentRight;
}

export interface EnrolmentConstraintControls {
    assessmentItemId: FormControl<OtmId>;
    allowAllCourseUnitRealisations: FormControl<boolean>;
    allowedCourseUnitRealisationIds: FormControl<OtmId[]>;
    maxNumberOfEnrolments: SisFormControl<number>;
    unlimitedEnrolments: FormControl<boolean>;
}

interface EnrolmentRightForm {
    changeMessage: FormControl<string>;
    enrolmentConstraints: FormArray<FormGroup<EnrolmentConstraintControls>>;
    validityPeriod: FormGroup<LocalDateRangeForm>;
}

function enrolmentConstraintsToAllowedCURIdMap(constraints: EnrolmentConstraint[]): { [aiId: string]: OtmId[] | null } {
    return constraints?.filter(Boolean).reduce(
        (result, constraint) => ({ ...result, [constraint.assessmentItemId]: constraint.allowedCourseUnitRealisationIds }),
        {},
    ) ?? {};
}

@Component({
    selector: 'sis-enrolment-right-edit-modal',
    templateUrl: './enrolment-right-edit-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentRightEditModalComponent implements OnInit, OnDestroy {

    readonly getLabelState = getLabelState;

    destroyed$ = new Subject<void>();
    form: FormGroup<EnrolmentRightForm>;
    editedValidityPeriod: LocalDateRange;
    usedEnrolmentsByAiId: { [aiId: string]: number } = {};

    assessmentItems$: Observable<AssessmentItem[]>;
    productAllowedCURIdsByAssessmentItemId$: Observable<{ [aiId: string]: OtmId[] | null }>;
    productId: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private alertService: AlertsService,
        private assessmentItemService: AssessmentItemEntityService,
        private enrolmentRightService: EnrolmentRightEntityService,
        private openUniversityProductService: OpenUniversityProductEntityService,
        private fb: SisFormBuilder,
        private modal: NgbActiveModal,
        private translate: TranslateService,
        @Inject(ModalService.injectionToken) public values: EditModalValues,
    ) {}

    readonly entityId = (i: number, entity: { id: OtmId }) => entity?.id ?? entity;

    ngOnInit(): void {
        if (this.values?.enrolmentRight && isCourseUnitEnrolmentRight(this.values?.enrolmentRight)) {
            const { id, courseUnitId, enrolmentConstraints, openUniversityProductId, validityPeriod } = this.values.enrolmentRight;
            this.productId = openUniversityProductId;
            this.editedValidityPeriod = validityPeriod;
            this.enrolmentRightService.countUsedEnrolments(id)
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler([]))
                .subscribe(usedEnrolments => {
                    this.usedEnrolmentsByAiId = usedEnrolments.reduce(
                        (result, usedEnrolment) => ({ ...result, [usedEnrolment.assessmentItemId]: usedEnrolment.enrolmentCount }),
                        {},
                    );
                    this.form = this.buildForm(enrolmentConstraints, validityPeriod, this.usedEnrolmentsByAiId);
                });

            this.assessmentItems$ = this.assessmentItemService
                .getByIdsSorted(enrolmentConstraints.map(constraint => constraint.assessmentItemId), courseUnitId);

            // This can be null when using legacy endpoint to import enrolmentRights.
            if (openUniversityProductId) {
                this.productAllowedCURIdsByAssessmentItemId$ = this.openUniversityProductService.getById(openUniversityProductId)
                    .pipe(map(product => enrolmentConstraintsToAllowedCURIdMap(product.enrolmentConstraints)));
            }
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    getEnrolmentConstraintControls(assessmentItemId: OtmId): FormGroup<EnrolmentConstraintControls> {
        return this.form.controls.enrolmentConstraints.controls
            .find(control => control.value.assessmentItemId === assessmentItemId);
    }

    onCancel(): void {
        this.modal.dismiss();
    }

    onSubmit(): void {
        if (this.form?.valid) {
            const { enrolmentConstraints, validityPeriod, changeMessage } = this.form.controls;
            const updateRequest: EnrolmentRightUpdateRequest = {
                enrolmentConstraints: enrolmentConstraints.value.map(constraint => ({
                    assessmentItemId: constraint.assessmentItemId,
                    allowedCourseUnitRealisationIds: constraint.allowAllCourseUnitRealisations ?
                        null : constraint.allowedCourseUnitRealisationIds,
                    maxNumberOfEnrolments: constraint.unlimitedEnrolments ? null : constraint.maxNumberOfEnrolments,
                })),
                validityPeriod: getLocalDateRangeEditorValue(validityPeriod),
                changeMessage: changeMessage.value,
            };
            this.enrolmentRightService.update(this.values?.enrolmentRight?.id, updateRequest)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe(() => {
                    this.alertService.addAlert({
                        type: AlertType.SUCCESS,
                        message: this.translate.instant('OPENUNIVERSITY.STUDY_RIGHTS.ENROLMENT_RIGHT_EDIT_MODAL.UPDATE_SUCCESSFUL'),
                    });
                    this.modal.close();
                },
                );
        } else {
            this.form.markAllAsTouched();
            this.alertService.addFormSubmissionFailureAlert();
        }
    }

    private buildForm(
        enrolmentConstraints: EnrolmentConstraint[],
        initialValidityPeriod: LocalDateRange,
        usedEnrolmentsByAiId: { [aiId: string]: number },
    ): FormGroup<EnrolmentRightForm> {
        const form = new FormGroup<EnrolmentRightForm>({
            enrolmentConstraints: new FormArray(
                enrolmentConstraints?.map(constraint => new FormGroup({
                    assessmentItemId: this.fb.control(constraint.assessmentItemId, [required()]),
                    allowAllCourseUnitRealisations: this.fb.control(constraint.allowedCourseUnitRealisationIds === null),
                    allowedCourseUnitRealisationIds: this.fb.control(constraint.allowedCourseUnitRealisationIds ?? []),
                    maxNumberOfEnrolments: this.fb.sisFormControl(
                        { value: constraint.maxNumberOfEnrolments, disabled: constraint.maxNumberOfEnrolments === null },
                        [required(), isInteger(), min(usedEnrolmentsByAiId[constraint.assessmentItemId] ?? 0)],
                    ),
                    unlimitedEnrolments: this.fb.control(constraint.maxNumberOfEnrolments === null),
                })),
            ),
            validityPeriod: this.fb.localDateRange(initialValidityPeriod, { required: true }),
            changeMessage: this.fb.control('', [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
        });

        form.get('validityPeriod').valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                map(getLocalDateRangeEditorValue),
                filter(dateRange => !!dateRange?.startDate && !!dateRange?.endDate),
                distinctUntilChanged(isEqual),
            )
            .subscribe(dateRange => this.editedValidityPeriod = dateRange);

        form.controls.enrolmentConstraints.controls
            .forEach(constraintGroup => constraintGroup.controls.unlimitedEnrolments.valueChanges
                .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
                .subscribe(unlimitedEnrolments => unlimitedEnrolments ?
                    constraintGroup.controls.maxNumberOfEnrolments.disable() : constraintGroup.controls.maxNumberOfEnrolments.enable()),
            );

        return form;
    }
}
