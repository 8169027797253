<div *ngIf="customModuleAttainment" class="studymodule {{customModuleAttainmentStateObject?.colorCategoryCssClass}}" [class.dimmed]="dimmed">
    <div class="studymodule-top-row">
        <div class="title-box">
            <span class="studymodule-title">
                <sis-tooltip [tooltipText]="customModuleAttainment.name | localizedString" [appendToBody]="true">
                    <span class="sis-tooltip-trigger-content">{{ customModuleAttainment.name | localizedString }}</span>
                </sis-tooltip>
            </span>
            <span class="studymodule-code">
                <sis-button [link]="true" (clicked)="openModuleInfoModal()" [ariaLabel]="customModuleAttainment.code + ' ' + (customModuleAttainment.name | localizedString) + ' ' + ('ARIA_LABEL.OPEN_INFO' | translate)">
                    <sis-icon icon="brochure"></sis-icon>{{ customModuleAttainment.code }}
                </sis-button>
            </span>
        </div><!--
     --><div class="extra-items-holder">
            <ng-content select="[custom-module-attainment-action]">
            </ng-content><!--
         --><div class="grade-box">
                <sis-icon icon="achievement"></sis-icon>
                <span class="grade-text">{{ customModuleAttainmentStateObject?.grade.abbreviation | localizedString }}</span>
            </div><!--
         --><div class="credits-box">
                <span class="credit-amount">{{ customModuleAttainment.credits | decimalNumber }}</span>
                <span class="credit-abbreviation" translate="SIS_COMPONENTS.CREDIT_RANGE.CREDITS_ABBR"></span>
            </div>
        </div>
    </div>
    <div class="studymodule-notification-bar">
        <ng-content select=".notifications"></ng-content>
        <div class="studymodule-notification" *ngIf="customModuleAttainmentStateObject?.invalidSelection && !customModuleAttainmentStateObject.hasModuleContentApproval">
            <span class="studymodule-notification-content">
                <span translate="PLAN.INVALID_SELECTIONS.NOTIFICATION"></span>
            </span><!--
         --><span class="studymodule-notification-header"><sis-icon icon="alert-fill"></sis-icon></span>
        </div>
        <div class="studymodule-notification" *ngIf="customModuleAttainmentStateObject?.invalidSelectionAccordingToModuleContentApproval">
            <span class="studymodule-notification-content">
                <span translate="PLAN.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL.NOTIFICATION"></span>
            </span><!--
         --><span class="studymodule-notification-header"><sis-icon icon="alert-fill"></sis-icon></span>
        </div>
    </div>
</div>
<div *ngIf="!customModuleAttainment" class="studymodule attainment-data-not-found">
    <div class="studymodule-top-row">
        <div class="title-box">
            <span class="studymodule-title">
                <span translate="SIS_COMPONENTS.CUSTOM_MODULE_ATTAINMENT_BOX.ATTAINMENT_DATA_NOT_FOUND">
                </span>
            </span>
        </div><!--
     --><div class="extra-items-holder">
            <ng-content select="[unknown-custom-module-attainment-action]">
            </ng-content><!--
         --><div class="grade-box">
              <sis-icon icon="achievement"></sis-icon>
            </div><!--
         --><div class="credits-box">
                <sis-icon icon="alert-fill"></sis-icon>
            </div>
        </div>
    </div>
</div>
