import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItemAttainment,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    ModuleAttainment } from 'common-typescript/types';
import * as _ from 'lodash-es';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'div[sis-grade-average-description]',
    templateUrl: './grade-average-description.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GradeAverageDescriptionComponent {
    _attainment: DegreeProgrammeAttainment | ModuleAttainment | CustomModuleAttainment | AssessmentItemAttainment | CourseUnitAttainment | CustomCourseUnitAttainment;
    popoverShown = false;
    showInfo: boolean;
    @Input() set attainment(attainment: DegreeProgrammeAttainment | ModuleAttainment | CustomModuleAttainment | AssessmentItemAttainment | CourseUnitAttainment | CustomCourseUnitAttainment) {
        this._attainment = attainment;
        this.showInfo = this.calculateLessThanHalfCreditsUsedForAverage();
    }

    isOpen() {
        this.popoverShown = true;
    }

    isHidden() {
        this.popoverShown = false;
    }

    calculateLessThanHalfCreditsUsedForAverage(): boolean {
        const { gradeAverage } = this._attainment;
        if (gradeAverage?.gradeScaleId !== 'sis-0-5') {
            return false;
        }
        return !_.isNumber(gradeAverage.value) || gradeAverage.totalIncludedCredits * 2 < this._attainment.credits;
    }
}
