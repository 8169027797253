import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    computed,
    inject,
    input, OnInit, output,
    signal,
    Signal,
    ViewEncapsulation,
} from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import {
    AssessmentItem,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment, EnrolmentCalculationConfigForPerson, EnrolmentStudySubGroup,
    LearningEnvironmentUrl, LocalDateString, Organisation, OtmId, StudyEvent, StudyGroupSet, StudySubGroup,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { CourseUnitRealisationNameService } from 'sis-common/name/course-unit-realisation-name.service';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { ENROLMENT_VALIDATION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { EnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Icon } from 'sis-components/icon/icon.component';
import { EnrolmentPeriodDates, EnrolmentPeriodDateService } from 'sis-components/service/enrolment-period-date.service';
import { EnrolmentStudySubGroupPriority } from 'sis-components/service/enrolment-validation.service';
import { UniversityService } from 'sis-components/service/university.service';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../../../../common/common.module';
import { EnrolmentModule as StudentCommonEnrolmentModule } from '../../../../common/components/enrolment/enrolment.module';

import {
    CalendarCurStudySubGroupEventInfoComponent,
} from './calendar-cur-study-sub-group-event-info/calendar-cur-study-sub-group-event-info.component';

/**
 * Do note that this component still depends on (global) styles from 'course-unit-realisation.scss'.
 * Be careful when altering the html structure as it might break the styling.
 */
@Component({
    selector: 'app-calendar-course-unit-realisation',
    templateUrl: './calendar-course-unit-realisation.component.html',
    standalone: true,
    imports: [
        SisComponentsModule,
        SisCommonModule,
        NgxFudisModule,
        CommonModule,
        CalendarCurStudySubGroupEventInfoComponent,
        EnrolmentModule,
        StudentCommonEnrolmentModule,
        StudentCommonModule,
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarCourseUnitRealisationComponent implements OnInit {

    courseUnitRealisationNameService = inject(CourseUnitRealisationNameService);
    enrolmentPeriodDateService = inject(EnrolmentPeriodDateService);
    enrolmentValidationService = inject(ENROLMENT_VALIDATION_SERVICE);
    localeService = inject(LocaleService);
    universityService = inject(UniversityService);
    appErrorHandler = inject(AppErrorHandler);
    changeDetectorRef = inject(ChangeDetectorRef);

    enrolment = input<Enrolment>();
    enrolmentCourseUnitRealisation = input<CourseUnitRealisation>();
    enrolmentCourseUnit = input<CourseUnit>();
    enrolmentAssessmentItem = input<AssessmentItem>();
    enrolmentCalculationConfig = input<EnrolmentCalculationConfigForPerson>();
    universities = input<Organisation[]>();
    initialExpanded = input<boolean>();
    onExpandedChange = output<{ courseUnitRealisationId: OtmId, expanded: boolean }>();
    onOpenQuestionnaire = output<{ enrolment: Enrolment, courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit }>();
    onOpenCourseUnitInfo = output<{ courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation }>();
    onViewInCalendar = output<string>();
    onToggleStudySubGroupInCalendar = output<Enrolment>();
    onRemoveFromCalendar = output<Enrolment>();
    onEnrolAction = output<{ enrolment: Enrolment, courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit }>();
    onUpdateAction = output<{ enrolment: Enrolment, courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit }>();
    onModifyGroupsAction = output<Enrolment>();
    onCancelAction = output<{
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }>();

    onAbortAction = output<{
        enrolment: Enrolment,
        courseUnitRealisation: CourseUnitRealisation,
        courseUnit: CourseUnit
    }>();

    expanded = signal(false);

    courseUnitRealisationName = computed(() =>
        this.courseUnitRealisationNameService.generateFullNameFromCourseUnit(
            this.enrolmentCourseUnitRealisation(),
            this.enrolmentCourseUnit()));

    learningEnvironmentLink: Signal<LearningEnvironmentUrl | undefined> = computed(() => {
        const language = this.localeService.getCurrentLanguage();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const learningEnvironmentLink = _.find(courseUnitRealisation.learningEnvironments, { language, primary: true });
        if (learningEnvironmentLink) {
            return learningEnvironmentLink;
        }
        return _.find(courseUnitRealisation.learningEnvironments, { language });
    });

    enrolmentPeriodDates = computed(() => this.enrolmentPeriodDateService.getEnrolmentDates(this.enrolmentCourseUnitRealisation()));
    enrolmentPeriodStartWeekDay = computed(() => moment(this.enrolmentPeriodDates().startDateTime).locale(this.localeService.getCurrentLanguage()).format('ddd'));
    enrolmentPeriodEndWeekDay = computed(() => moment(this.enrolmentPeriodDates().endDateTime).locale(this.localeService.getCurrentLanguage()).format('ddd'));
    enrolmentPeriodLateEnrolmentEndWeekDay = computed(() => moment(this.enrolmentPeriodDates().lateEnrolmentEnd).locale(this.localeService.getCurrentLanguage()).format('ddd'));
    enrolmentPeriodCancellationEndWeekDay = computed(() => moment(this.enrolmentPeriodDates().cancellationEndDateTime).locale(this.localeService.getCurrentLanguage()).format('ddd'));
    hasEnrolmentPeriodStarted = computed(() => this.enrolmentPeriodDateService.enrolmentPeriodStarted(this.enrolmentCourseUnitRealisation()));

    // TODO: recheck if these can be removed
    priorityMaps = computed(() => {
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const studySubGroupPriorityMap = this.enrolmentValidationService.createEnrolmentStudySubGroupPriorityMap(enrolment, courseUnitRealisation);
        const isEditableBySsgId = this.enrolmentValidationService.createIsEditableBySsgIdMap(studySubGroupPriorityMap, courseUnitRealisation);
        _.chain(courseUnitRealisation.studyGroupSets)
            .flatMap('studySubGroups')
            .filter('cancelled')
            .forEach((ssg) => {
                studySubGroupPriorityMap[ssg.id] = 'NOT_SUITABLE';
                isEditableBySsgId[ssg.id] = false;
            })
            .value();

        const ssgPriorityOptionsBySsgId = this.enrolmentValidationService.createDropdownOptionsForStudySubGroups(courseUnitRealisation, enrolment);

        return {
            studySubGroupPriorityMap,
            isEditableBySsgId,
            ssgPriorityOptionsBySsgId,
        };
    });

    hasSpaceForEnrolledEnrolmentsInCur = computed(() => {
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        const maxSelected = _.get(enrolmentCalculationConfig, 'enrolmentAllocationCounts.maxSelected');
        const enrolledCount = _.get(enrolmentCalculationConfig, 'enrolmentAllocationCounts.enrolledCount');
        if (!_.isNumber(maxSelected) || !_.isNumber(enrolledCount)) {
            // Some shady stuff is happening here, allow user to enrol and delegate the validation to backend
            return true;
        }
        return enrolledCount < maxSelected;
    });

    hasSpaceInSelectedStudySubGroups = computed(() => {
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        const { studySubGroupPriorityMap } = this.priorityMaps();
        return !_.chain(this.getEnrolmentStudySubGroups(enrolment))
            .map('studySubGroupId')
            .filter(ssgId => _.includes(
                [EnrolmentStudySubGroupPriority.PRIMARY, EnrolmentStudySubGroupPriority.SUITABLE],
                studySubGroupPriorityMap[ssgId],
            ))
            .find((ssgId) => this.isStudySubGroupFull(ssgId, courseUnitRealisation, enrolmentCalculationConfig))
            .value();
    });

    externalUniversityNames = computed(() => {
        const universities = this.universities();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return _.chain(universities)
            .filter(university => _.includes(courseUnitRealisation.universityOrgIds, university.id))
            .map('name')
            .map((name) => this.localeService.localize(name))
            .join(', ')
            .value();
    });

    notifications: Signal<{ translation: string }[]> = computed(() => {
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const notifications: { translation: string }[] = [];
        if (courseUnitRealisation?.flowState === 'CANCELLED') {
            notifications.push({ translation: 'ENROLMENTS.CUR_CANCELLED' });
            return notifications;
        }
        if (!this.enrolmentPossibleForUniversity(courseUnitRealisation)) {
            return notifications;
        }
        if (!_.includes(['NOT_ENROLLED', 'REJECTED'], enrolment.state)) {
            return notifications;
        }
        const rejected = enrolment.state === 'REJECTED';
        const enrolmentPeriodOngoing = this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation);
        if (courseUnitRealisation.continuousEnrolment) {
            if (!enrolmentPeriodOngoing) {
                return notifications;
            }
            if (rejected) {
                notifications.push({ translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_CONTINUOUS_REJECTED' });
                return notifications;
            }
            notifications.push({ translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED' });
            return notifications;
        }
        if (enrolmentPeriodOngoing) {
            if (enrolment.state === 'NOT_ENROLLED') {
                notifications.push({ translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED' });
                return notifications;
            }
            return notifications;
        }

        if (!this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation)) {
            return notifications;
        }
        if (rejected) {
            notifications.push({ translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_LATE_REJECTED' });
            return notifications;
        }
        notifications.push({ translation: 'COURSE_UNIT_REALISATION.PRIORITIES_NOT_SELECTED_LATE' });
        return notifications;
    });

    warnings: Signal<{ translation: string }[]> = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolment = this.enrolment();
        const warnings = [];
        if (this.showEnrolmentInExternalUniWarning(courseUnitRealisation, enrolment)) {
            warnings.push({
                translation: 'COURSE_UNIT_REALISATION.ENROLMENT_IN_EXTERNAL_UNI',
            });
        }
        if (this.showUpcomingExceptionWarning(courseUnitRealisation, enrolment)) {
            warnings.push({
                translation: 'COURSE_UNIT_REALISATION.HAS_CANCELLATIONS_WITHIN_ONE_WEEK',
            });
        }
        return warnings;
    });

    enrolmentPossibleForUniversitySignal = computed(() => this.enrolmentPossibleForUniversity(this.enrolmentCourseUnitRealisation()));

    isEnrolButtonVisible = computed(() => {
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        if (!this.enrolmentPossibleForCur(courseUnitRealisation)) {
            return false;
        }
        if (enrolment.state === 'REJECTED') {
            return this.enrolmentPeriodDateService.lateOrContinuousPeriodOngoing(courseUnitRealisation);
        }
        if (enrolment.state === 'NOT_ENROLLED') {
            return this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation) ||
                this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation);
        }
        if (enrolment.state === 'INVALID') {
            return this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation)
                || this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation);
        }
        return false;
    });

    isEnrolButtonEnabled = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        if (!this.enrolmentPeriodDateService.lateOrContinuousPeriodOngoing(courseUnitRealisation)) {
            // normal period does not need extra checks
            return true;
        }
        return this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation) ? this.areCalculationResultsConfirmed(enrolmentCalculationConfig) : true;
    });

    enrolButtonTranslationKey = computed(() => {
        const enrolment = this.enrolment();
        if (enrolment.state === 'NOT_ENROLLED') {
            return 'ENROLMENT.ENROL';
        }
        if (enrolment.state === 'REJECTED') {
            return 'ENROLMENT.RE_ENROL';
        }
        if (enrolment.state === 'INVALID') {
            return 'ENROLMENT.RE_ENROL';
        }
        return '';
    });

    isUpdateButtonIsVisible = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolment = this.enrolment();
        if (!this.enrolmentPossibleForCur(courseUnitRealisation)) {
            return false;
        }
        return enrolment.state === 'PROCESSING' &&
            // Immediately after enrol on late or continuous period, the enrolment is in PROCESSING state. Update should be
            // disabled during this.
            !courseUnitRealisation.continuousEnrolment &&
            this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation);
    });

    isExternalEnrolmentButtonVisible = computed(() => this.enrolmentCourseUnitRealisation().usesExternalEnrolment);

    isExternalEnrolmentButtonDisabled = computed(() => !this.enrolmentCourseUnitRealisation().externalEnrolmentLink);

    externalEnrolmentLink = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        if (_.get(courseUnitRealisation, 'externalEnrolmentLink.url')) {
            const url = this.localeService.localize(courseUnitRealisation.externalEnrolmentLink.url);
            return url || '';
        }
        return '';
    });

    externalEnrolmentLinkLabel = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return _.get(courseUnitRealisation, 'externalEnrolmentLink.label');
    });

    isModifyGroupsButtonVisible = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolment = this.enrolment();
        if (!this.enrolmentPossibleForCur(courseUnitRealisation)) {
            return false;
        }
        if (!courseUnitRealisation.confirmedStudySubGroupModificationAllowed || enrolment.state !== 'ENROLLED') {
            return false;
        }
        const dateTime = this.enrolmentValidationService.resolveConfirmedStudySubGroupModificationEnd(courseUnitRealisation);
        return this.isDefinedAndInFuture(dateTime);
    });

    isCancelButtonVisible = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolment = this.enrolment();
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        if (!this.enrolmentPossibleForCur(courseUnitRealisation)) {
            return false;
        }
        if (!_.includes(['PROCESSING', 'INVALID', 'ENROLLED'], enrolment.state)) {
            return false;
        }
        // on continuous enrolment you can cancel between cur.enrolmentPeriod.start and cur.activityPeriod.start.
        if (courseUnitRealisation.continuousEnrolment) {
            return this.enrolmentPeriodDateService.enrolmentPeriodStarted(courseUnitRealisation) && this.curActivityHasNotStarted(courseUnitRealisation);
        }
        if (this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation)) {
            return true;
        }
        // Now must be 'additional cancellation' period if isCancellationPeriodOngoing returns true
        return this.isCancellationPeriodOngoing(courseUnitRealisation, enrolmentPeriodDates);
    });

    isCancelButtonEnabled = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        const isCancelButtonVisible = this.isCancelButtonVisible();

        if (!isCancelButtonVisible) {
            return false;
        }
        if (courseUnitRealisation.continuousEnrolment) {
            return true;
        }
        if (this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation)) {
            return true;
        }
        return this.areCalculationResultsConfirmed(enrolmentCalculationConfig)
            && this.isCancellationPeriodOngoing(courseUnitRealisation, enrolmentPeriodDates);
    });

    isAbortButtonVisible = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        const enrolment = this.enrolment();
        const isCancelButtonVisible = this.isCancelButtonVisible();
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();

        if (!this.enrolmentPossibleForCur(courseUnitRealisation)) {
            return false;
        }
        if (enrolment.state !== 'ENROLLED' ||
            !this.enrolmentPeriodDateService.activityPeriodOnGoing(courseUnitRealisation)) {
            return false;
        }
        return !isCancelButtonVisible &&
            (this.areCalculationResultsConfirmed(enrolmentCalculationConfig) || courseUnitRealisation.continuousEnrolment);
    });

    maxSelectedIsNull = computed(() => _.isNil(this.enrolmentCalculationConfig()?.enrolmentAllocationCounts?.maxSelected));

    showQuestionnaireLink = computed(() => {
        const isEnrolButtonVisible = this.isEnrolButtonVisible();
        const isUpdateButtonIsVisible = this.isUpdateButtonIsVisible();
        const isCancelButtonVisible = this.isCancelButtonVisible();
        const isCancelButtonEnabled = this.isCancelButtonEnabled();
        const enrolmentEndedAndNotEnrolled = this.enrolmentEndedAndNotEnrolled();

        return !isEnrolButtonVisible &&
            !isUpdateButtonIsVisible &&
            (!isCancelButtonVisible ||
                (isCancelButtonVisible && isCancelButtonEnabled)) &&
            !enrolmentEndedAndNotEnrolled;
    });

    enrolmentEndedAndNotEnrolled = computed(() => {
        const enrolment = this.enrolment();
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        return enrolment.state === 'NOT_ENROLLED' && this.hasEnrolmentPeriodEnded(enrolmentPeriodDates);
    });

    lateEnrolmentPeriodOngoing = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation);
    });

    lateOrContinuousPeriodOngoing = computed(() => {
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return this.enrolmentPeriodDateService.lateOrContinuousPeriodOngoing(courseUnitRealisation);
    });

    showOrderingRules = computed(() => {
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        return enrolmentCalculationConfig?.orderingRules?.length > 0;
    });

    showFailedRequirementRules = computed(() => {
        const enrolment = this.enrolment();
        const enrolmentCalculationConfig = this.enrolmentCalculationConfig();
        return this.hasValidStatesForFailedRequirementRules(enrolment) && this.hasFailedRequirementPersonRules(enrolmentCalculationConfig);
    });

    failedEnrolmentRequirementRules = computed(() => this.enrolmentCalculationConfig()?.requirementRules.filter(rule => !rule.result));

    showEnrolmentPeriodStartTimeInfo = computed(() => {
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        return this.isDefinedAndInFuture(_.get(enrolmentPeriodDates, 'startDateTime'));
    });

    showSelectionsConfirmedOnInfo = computed(() => {
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return this.isDefinedAndInFuture(_.get(enrolmentPeriodDates, 'endDateTime'))
            && !(this.enrolmentPeriodDateService.continuousEnrolmentPeriodOngoing(courseUnitRealisation) && enrolment.state === 'ENROLLED');
    });

    showAnswersModifiableUntilInfo = computed(() => {
        const enrolmentPeriodDates = this.enrolmentPeriodDates();
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return this.isDefinedAndInFuture(_.get(enrolmentPeriodDates, 'endDateTime'))
            && !(this.enrolmentPeriodDateService.continuousEnrolmentPeriodOngoing(courseUnitRealisation) && enrolment.state === 'ENROLLED');
    });

    canRemoveFromCalendar = computed(() => {
        const enrolment = this.enrolment();
        const courseUnitRealisation = this.enrolmentCourseUnitRealisation();
        return !_.includes(['ENROLLED', 'PROCESSING', 'INVALID'], enrolment.state) || courseUnitRealisation.flowState === 'CANCELLED';
    });

    ngOnInit(): void {
        this.expanded.set(!!this.initialExpanded());
    }

    onToggleExpanded() {
        this.expanded.set(!this.expanded());
        this.onExpandedChange.emit({
            courseUnitRealisationId: this.enrolmentCourseUnitRealisation().id,
            expanded: this.expanded(),
        });
    }

    hasValidStatesForFailedRequirementRules(enrolment: Enrolment) {
        return (enrolment.state === 'PROCESSING' &&
                enrolment.processingState === 'REQ_NOT_FULFILLED') ||
            (enrolment.state === 'REJECTED' &&
                enrolment.processingState === 'NOT_SELECTED');
    }

    hasFailedRequirementPersonRules(enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson) {
        return enrolmentCalculationConfig?.requirementRules.filter(rule => !rule.result).length > 0;
    }

    onToggleSubGroupInCalendar($event: any, studySubGroupId: OtmId, enrolment: Enrolment) {
        const checked = $event.target.checked;
        const enrolmentStudySubGroup = this.getEnrolmentStudySubGroup(studySubGroupId, enrolment);
        const updatedEnrolment = _.cloneDeep(enrolment);
        if (!enrolmentStudySubGroup) {
            // This is a hack inherited form the old code. I am not sure in which case this would happen.
            const newEnrolmentStudySubGroup = {
                studySubGroupId,
                isInCalendar: true,
                enrolmentStudySubGroupPriority: EnrolmentStudySubGroupPriority.NOT_SUITABLE,
            };

            updatedEnrolment.studySubGroups.push(newEnrolmentStudySubGroup);
        } else {
            _.map(updatedEnrolment.studySubGroups, (ssg) => {
                if (ssg.studySubGroupId === studySubGroupId) {
                    ssg.isInCalendar = checked;
                }
                return ssg;
            });
        }
        this.onToggleStudySubGroupInCalendar.emit(updatedEnrolment);
    }

    clickCourseUnitInfoLink(event: MouseEvent) {
        switch (event.button) {
            // Main button (usually mouse's left button)
            case 0:
                this.onOpenCourseUnitInfo.emit({ courseUnit: this.enrolmentCourseUnit(), courseUnitRealisation: this.enrolmentCourseUnitRealisation() });
                break;
            // Auxiliary button (usually the wheel or middle button)
            case 1:
            default:
                break;
        }
    }

    isDefinedAndInFuture(dateTime: string): boolean {
        return !!dateTime && moment().isBefore(dateTime);
    }

    curActivityHasNotStarted(courseUnitRealisation: CourseUnitRealisation): boolean {
        return this.isDefinedAndInFuture(_.get(courseUnitRealisation, 'activityPeriod.startDate'));
    }

    isCancellationPeriodOngoing(courseUnitRealisation: CourseUnitRealisation, enrolmentPeriodDates: EnrolmentPeriodDates): boolean {
        return this.isDefinedAndInFuture(_.get(enrolmentPeriodDates, 'cancellationEndDateTime'));
    }

    showUpcomingExceptionWarning(cur: CourseUnitRealisation, enrolment: Enrolment): boolean {
        return enrolment.state === 'ENROLLED' && this.curHasUpcomingExceptionsWithinOneWeek(cur, enrolment);
    }

    curHasUpcomingExceptionsWithinOneWeek(cur: CourseUnitRealisation, enrolment: Enrolment): boolean {
        let exceptionDates: LocalDateString[] = [];
        const studySubGroups = _.flatMap(cur.studyGroupSets, (sgs) => this.getStudySubGroupsForDefaultView(sgs, enrolment));
        const studyEvents: StudyEvent[] = _.chain(studySubGroups)
            .flatMap('studyEvents')
            .compact()
            .value();
        _.forEach(studyEvents, (studyEvent) => {
            exceptionDates = _.concat(exceptionDates, studyEvent.exceptions, studyEvent.cancellations);
        });
        if (_.isEmpty(exceptionDates)) {
            return false;
        }
        const currentMoment = moment();
        const weekAfterMoment = moment().add(7, 'days');
        return _.some(exceptionDates, date =>
            moment(date).isBetween(currentMoment, weekAfterMoment, 'day', '[]'));
    }

    showStudySubGroupPriority(studySubGroup: StudySubGroup): boolean {
        return this.priorityMaps().ssgPriorityOptionsBySsgId[studySubGroup.id]
        && (this.isEnrolButtonVisible() || this.isUpdateButtonIsVisible());
    }

    areAllStudySubGroupsMandatory(sgs: StudyGroupSet): boolean {
        const min = _.get(sgs, 'subGroupRange.min');
        if (!_.isNil(min)) {
            return min === _.size(sgs.studySubGroups);
        }
        return false;
    }

    getStudySubGroupsForDefaultView(studyGroupSet: StudyGroupSet, enrolment: Enrolment): StudySubGroup[] {
        if (enrolment.state !== 'ENROLLED') {
            return studyGroupSet.studySubGroups;
        }
        return _.filter(studyGroupSet.studySubGroups, ssg => this.isSsgInConfirmedSsgs(ssg.id, enrolment));
    }

    getStudySubGroupsForExtendedView(studyGroupSet: StudyGroupSet, enrolment: Enrolment): StudySubGroup[] {
        if (enrolment.state !== 'ENROLLED') {
            return [];
        }
        return _.filter(studyGroupSet.studySubGroups, ssg => !this.isSsgInConfirmedSsgs(ssg.id, enrolment));
    }

    isEnrolmentTentativelySelected(enrolment: Enrolment): boolean {
        return enrolment.state === 'PROCESSING' &&
            _.includes(['SELECTED', 'CURRENTLY_SELECTED'], enrolment.processingState);
    }

    isStudySubGroupConfirmed(studySubGroupId: OtmId, enrolment: Enrolment): boolean {
        return enrolment.state === 'ENROLLED' && this.isSsgInConfirmedSsgs(studySubGroupId, enrolment);
    }

    isStudySubGroupTentative(studySubGroupId: OtmId, enrolment: Enrolment): boolean {
        return this.isEnrolmentTentativelySelected(enrolment) && _.includes(enrolment.tentativeStudySubGroupIds, studySubGroupId);
    }

    isSsgInConfirmedSsgs(studySubGroupId: OtmId, enrolment: Enrolment): boolean {
        return _.includes(enrolment.confirmedStudySubGroupIds, studySubGroupId);
    }

    showEnrolmentInExternalUniWarning(cur: CourseUnitRealisation, enrolment: Enrolment): boolean {
        return this.enrolmentPeriodDateService.enrolmentPeriodOngoing(cur)
            && enrolment.state === 'NOT_ENROLLED'
            && !this.enrolmentPossibleForUniversity(cur);
    }

    enrolmentPossibleForCur(courseUnitRealisation: CourseUnitRealisation) {
        return !courseUnitRealisation.usesExternalEnrolment
            && this.enrolmentPossibleForUniversity(courseUnitRealisation)
            && courseUnitRealisation.flowState !== 'CANCELLED';
    }

    enrolmentPossibleForUniversity(cur: CourseUnitRealisation): boolean {
        return this.enrolmentPossibleForHomeUniversity(cur) || this.enrolmentPossibleForCooperationNetwork(cur);
    }

    enrolmentPossibleForCooperationNetwork(cur: CourseUnitRealisation): boolean {
        return !this.enrolmentPossibleForHomeUniversity(cur) && !_.isEmpty(_.get(cur, 'cooperationNetworkDetails.networks'));
    }

    enrolmentPossibleForHomeUniversity(cur: CourseUnitRealisation): boolean {
        return _.includes(_.get(cur, 'universityOrgIds'), this.universityService.getCurrentUniversityOrgId());
    }

    getEnrolmentStudySubGroups(enrolment: Enrolment): EnrolmentStudySubGroup[] {
        return _.get(enrolment, 'studySubGroups', []);
    }

    getCurStudySubGroups(courseUnitRealisation: CourseUnitRealisation): StudySubGroup[] {
        return _.flatMap(_.get(courseUnitRealisation, 'studyGroupSets', []), 'studySubGroups');
    }

    getCurStudySubGroup(studySubGroupId: OtmId, courseUnitRealisation: CourseUnitRealisation): StudySubGroup {
        return _.find(this.getCurStudySubGroups(courseUnitRealisation), { id: studySubGroupId });
    }

    isStudySubGroupFull(ssgId: OtmId, courseUnitRealisation: CourseUnitRealisation, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): boolean {
        const ssgSize = _.get(this.getCurStudySubGroup(ssgId, courseUnitRealisation), 'size');
        if (_.isNil(ssgSize)) {
            return false;
        }
        return this.getEnrolledCountForSsg(ssgId, enrolmentCalculationConfig) >= ssgSize;
    }

    getEnrolledAmountTranslationKey(courseUnitRealisation: CourseUnitRealisation) {
        if (!courseUnitRealisation.continuousEnrolment &&
            (!this.enrolmentPeriodDateService.enrolmentPeriodStarted(courseUnitRealisation)
                || this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation))) {
            return 'COURSE_UNIT_REALISATION.TENTATIVE_AND_TOTAL_GROUP_SIZE';
        }
        return 'COURSE_UNIT_REALISATION.CONFIRMED_AND_TOTAL_GROUP_SIZE';
    }

    getEnrolledAmountIcon(ssgId: OtmId, courseUnitRealisation: CourseUnitRealisation, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): Icon {
        if (courseUnitRealisation.continuousEnrolment || this.enrolmentPeriodDateService.lateEnrolmentPeriodOngoing(courseUnitRealisation)) {
            if (this.isStudySubGroupFull(ssgId, courseUnitRealisation, enrolmentCalculationConfig)) {
                return 'lock';
            }
            return 'seats';
        }
        if (!this.enrolmentPeriodDateService.enrolmentPeriodStarted(courseUnitRealisation)
            || this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation)) {
            return 'hourglass';
        }
        return 'lock';
    }

    getEnrolledCountForSsg(studySubGroupId: OtmId, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): number {
        return _.get(this.getAllocationCountsForSsg(studySubGroupId, enrolmentCalculationConfig), 'enrolledCount', 0);
    }

    getTentativeCountForSsg(studySubGroupId: OtmId, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): number {
        return _.get(this.getAllocationCountsForSsg(studySubGroupId, enrolmentCalculationConfig), 'tentativeCount', 0);
    }

    getAllocationCountsForSsg(studySubGroupId: OtmId, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): {
        enrolledCount: number,
        selectedCount: number
    } {
        return _.chain(enrolmentCalculationConfig)
            .get('enrolmentAllocationCounts.studySubGroupAllocationCounts', [])
            .find({ studySubGroupId })
            .value();
    }

    getEnrolledAmountCount(studySubGroupId: OtmId, courseUnitRealisation: CourseUnitRealisation, enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): number {

        if (courseUnitRealisation.continuousEnrolment) {
            return this.getEnrolledCountForSsg(studySubGroupId, enrolmentCalculationConfig);
        }
        if (!this.enrolmentPeriodDateService.enrolmentPeriodStarted(courseUnitRealisation)
            || this.enrolmentPeriodDateService.enrolmentPeriodOngoing(courseUnitRealisation)) {
            return this.getTentativeCountForSsg(studySubGroupId, enrolmentCalculationConfig)
                + this.getEnrolledCountForSsg(studySubGroupId, enrolmentCalculationConfig);
        }
        return this.getEnrolledCountForSsg(studySubGroupId, enrolmentCalculationConfig);
    }

    hasStudyEvents(studySubGroup: StudySubGroup): boolean {
        return studySubGroup?.studyEventIds?.length > 0;
    }

    areCalculationResultsConfirmed(enrolmentCalculationConfig: EnrolmentCalculationConfigForPerson): boolean {
        return _.get(enrolmentCalculationConfig, 'enrolmentCalculationState', '') === 'CONFIRMED';
    }

    hasEnrolmentPeriodEnded(enrolmentPeriodDates: EnrolmentPeriodDates): boolean {
        const endDateTime = _.get(enrolmentPeriodDates, 'endDateTime');
        return !!endDateTime && moment().isAfter(endDateTime);
    }

    getTentativeGroupsForSgs(studyGroupSet: StudyGroupSet, enrolment: Enrolment): StudySubGroup[] {
        return _.filter(studyGroupSet.studySubGroups, ssg => this.isStudySubGroupTentative(ssg.id, enrolment));
    }

    canToggleSubGroupInCalendar(studySubGroupId: OtmId, enrolment: Enrolment): boolean {
        return !this.isStudySubGroupConfirmed(studySubGroupId, enrolment);
    }

    getEnrolmentStudySubGroup(studySubGroupId: OtmId, enrolment: Enrolment): EnrolmentStudySubGroup {
        return _.find(this.getEnrolmentStudySubGroups(enrolment), { studySubGroupId });
    }

    getEnrolmentStudySubGroupOrEmptyObject(studySubGroupId: OtmId, enrolment: Enrolment): EnrolmentStudySubGroup | null {
        return this.getEnrolmentStudySubGroup(studySubGroupId, enrolment) ?? null;
    }
}
