import angular from 'angular';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import customModuleAttainmentBoxTpl from './customModuleAttainmentBox.tpl.html';
angular.module('sis-components.attainment.customModuleAttainmentBox', ['sis-components.service.validAttainmentFilterService', 'sis-components.service.colorService', IconComponent.downgrade.moduleName]).component('customModuleAttainmentBox', {
  template: customModuleAttainmentBoxTpl,
  transclude: true,
  bindings: {
    attainment: '<',
    notifications: '<',
    grade: '<',
    validatablePlan: '<',
    planValidationResult: '<?'
  },
  controller: ["validAttainmentFilterService", "colorService", "$log", function (validAttainmentFilterService, colorService, $log) {
    const $ctrl = this;
    $ctrl.$onChanges = () => {
      const customModuleAttainmentId = _.get($ctrl.attainment, 'id');
      const customModuleAttainmentValidationResults = _.get($ctrl.planValidationResult, 'customModuleAttainmentValidationResults');
      $ctrl.customModuleAttainmentValidationResult = _.get(customModuleAttainmentValidationResults, customModuleAttainmentId);
    };
    $ctrl.isInvalidSelection = () => _.get($ctrl.customModuleAttainmentValidationResult, 'invalidSelection') === true;
    $ctrl.isInvalidAccordingToModuleContentApproval = () => _.get($ctrl.customModuleAttainmentValidationResult, 'invalidAccordingToModuleContentApproval') === true;
    $ctrl.hasModuleContentApproval = () => _.get($ctrl.customModuleAttainmentValidationResult, 'invalidAccordingToModuleContentApproval') !== undefined;
    $ctrl.isAboutToExpire = attainment => {
      if (!$ctrl.validatablePlan) {
        return false;
      }
      return validAttainmentFilterService.isAttainmentAboutToExpire(attainment) && !validAttainmentFilterService.isAttached(attainment, $ctrl.validatablePlan.getAllAttainments());
    };
    $ctrl.getModuleColorCategory = () => {
      try {
        const colorCategory = colorService.getModuleColorCategory($ctrl.validatablePlan, _.get($ctrl, 'attainment.id'));
        if (colorCategory) {
          return `cu-color-${colorCategory}`;
        }
        return undefined;
      } catch (e) {
        $log.error('Failed to getModuleColorCategory', e);
        return undefined;
      }
    };
  }]
});