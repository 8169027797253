import angular from 'angular';
import * as _ from 'lodash-es';
import { decimalNumberFilterModule } from 'sis-components/number/decimalNumber.filter';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import moduleContentApplicationTpl from './moduleContentApplicationStructure.tpl.html';
(function () {
  angular.module('sis-components.applications.moduleContentApplicationStructure', [decimalNumberFilterModule, TooltipComponent.downgrade.moduleName]).component('moduleContentApplicationStructure', {
    template: moduleContentApplicationTpl,
    controller: moduleContentApplicationController,
    controllerAs: 'ctrl',
    bindings: {
      module: '<',
      selectedCourseUnits: '<',
      selectedCourseUnitAttainmentsByGroupId: '<',
      selectedModules: '<',
      selectedModuleAttainmentsByGroupId: '<',
      selectedCustomCourseUnitAttainments: '<',
      selectedCustomModuleAttainments: '<',
      selectedGroupingModules: '<',
      selectedCustomStudyDrafts: '<',
      gradeScalesById: '<'
    }
  });
  function moduleContentApplicationController() {
    const ctrl = this;
    ctrl.$onChanges = () => {
      ctrl.customStudyDraftsById = _.keyBy(ctrl.selectedCustomStudyDrafts, 'id');
      ctrl.customCourseUnitAttainmentsByCustomStudyDraftId = _.chain(ctrl.selectedCustomCourseUnitAttainments).filter(ccua => !!ccua.customStudyDraftId).keyBy('customStudyDraftId').value();
      ctrl.effectiveCustomStudyDrafts = _.filter(ctrl.selectedCustomStudyDrafts, csd => !_.get(ctrl.customCourseUnitAttainmentsByCustomStudyDraftId, csd.id));
    };
    ctrl.getRelatedCustomStudyDraftForCustomCourseUnitAttainment = customCourseUnitAttainment => {
      if (!customCourseUnitAttainment.customStudyDraftId) {
        return null;
      }
      return _.get(ctrl.customStudyDraftsById, customCourseUnitAttainment.customStudyDraftId);
    };
  }
})();