import { PrivatePerson, Urn } from 'common-typescript/types';
import * as _ from 'lodash-es';

export interface ClassifiedVisibility {
    phoneNumberVisible: boolean,
    secondaryEmailVisible: boolean,
    primaryAddressVisible: boolean,
    secondaryAddressVisible: boolean,
    municipalityUrnVisible: boolean,
}
export const COUNTRY_URN_FI = 'urn:code:country:246';
export const FINNISH_CITIZENSHIP_URNS = [COUNTRY_URN_FI, 'urn:code:country:248'];
export const VALIDATION_GROUPS = ['Draft', 'Active'];

export const basicInfoUtils = {
    isClassifiedInfoVisible(fieldName: string, classifiedVisibility: ClassifiedVisibility, person: PrivatePerson): boolean {
        const fieldOverride = `${_.camelCase(fieldName)}Visible`;
        const capitalizedFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const classifiedField = `classifiedPersonInfo.is${capitalizedFieldName}Classified`;
        const overridden = _.get(classifiedVisibility, fieldOverride);
        const classified = !_.get(person, classifiedField);
        return overridden || classified;
    },

    isClassified(fieldName: string, person: PrivatePerson): boolean {
        const capitalizedFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const classifiedField = `classifiedPersonInfo.is${capitalizedFieldName}Classified`;
        return !!_.get(person, classifiedField);
    },

    toggleFieldVisibility(fieldName: string, classifiedVisibility: ClassifiedVisibility): ClassifiedVisibility {
        const field = `${_.camelCase(fieldName)}Visible`;
        return _.set(classifiedVisibility, field, !_.get(classifiedVisibility, field));
    },

    isFinnishCitizen(urns: Urn[]): boolean {
        return _.intersection(urns, FINNISH_CITIZENSHIP_URNS || []).length > 0;
    },
};
