<ng-container *transloco="let t">
  <div class="row">
    <div class="col-12">
      <!-- Course unit realisation box info -->
      <div class="courseunit-realisation" [ngClass]="'cu-color-' + enrolment().colorIndex">
        <div class="courseunit-realisation-top-row">
          <div class="credits-box">
          <span class="credit-amount">
            {{ enrolmentAssessmentItem().credits | creditRange:'TEXT':true }}
            <span class="credit-abbreviation">{{ t('CREDITS') }}</span>
          </span>
          </div>
          <div class="title-box">
          <span fudisTooltip [tooltip]="courseUnitRealisationName() | localizedString"
                class="courseunit-realisation-title">
            @if (enrolmentCourseUnitRealisation().flowState === 'CANCELLED') {
              <fudis-badge [variant]="'danger'" [content]="t('FLOW_STATE.CANCELLED')"></fudis-badge>
            }
            {{ courseUnitRealisationName() | localizedString }}
          </span>
            <span class="courseunit-realisation-specifier">
            @if (learningEnvironmentLink()) {
              <sis-external-link [href]="learningEnvironmentLink().url">
                {{ enrolmentCourseUnitRealisation().name | localizedString }}
              </sis-external-link>
            } @else {
              {{ enrolmentCourseUnitRealisation().name | localizedString }}
            }
          </span>
            <span class="courseunit-realisation-date">
            {{ enrolmentCourseUnitRealisation().activityPeriod | localDateRange }}
          </span>
          </div>
          <div class="additional-box">
            <button type="button"
                    [attr.aria-label]="courseUnitRealisationName() | localizedString"
                    [attr.aria-expanded]="expanded()"
                    (click)="onToggleExpanded()"
                    class="button-box non-button"
                    data-cy="expand-cur-chevron">
              <sis-icon [icon]="'chevron-ring'" [iconClass]="expanded() ? 'cw-90' : null"/>
            </button>
          </div>
        </div>
        <!-- Notifications and warnings -->
        @if (notifications().length > 0) {
          <div class="cu-notification-bar">
            @for (notification of notifications(); track notification.translation) {
              <div fudisTooltip class="cu-notification" [tooltip]="t(notification.translation, {
              endDate: (enrolmentPeriodDates().endDateTime | localDateFormat),
              lateEndDate: (enrolmentCourseUnitRealisation().lateEnrolmentEnd | localDateFormat),
              })">
                <span class="cu-notification-header"><sis-icon
                  icon="info"></sis-icon></span>
                <span class="cu-notification-content">{{
                    t(notification.translation, {
                      endDate: (enrolmentPeriodDates().endDateTime | localDateFormat),
                      lateEndDate: (enrolmentCourseUnitRealisation().lateEnrolmentEnd | localDateFormat),
                    })
                  }}</span>
              </div>
            }
          </div>
        }
        @if (warnings().length > 0) {
          <div class="cu-notification-bar">
            @for (warning of warnings(); track warning.translation) {
              <div fudisTooltip [tooltip]="t(warning.translation)" class="cu-notification">
                <span class="cu-notification-header"><sis-icon icon="alert-fill"></sis-icon></span>
                <span class="cu-notification-content">{{ t(warning.translation) }}</span>
              </div>
            }
          </div>
        }
        <!-- Expanded content -->
        @if (expanded()) {
          <div class="courseunit-realisation-bottom-row">
            <div class="event-groups">
              @for (studyGroupSet of enrolmentCourseUnitRealisation().studyGroupSets; track studyGroupSet.localId) {
                @if (enrolmentPossibleForUniversitySignal()) {
                  <div class="event-info row">
                    <div class="col-12 event-title">{{ studyGroupSet.name | localizedString }}</div>
                  </div>
                }
                @for (studySubGroup of this.getStudySubGroupsForDefaultView(studyGroupSet, this.enrolment()); track studySubGroup.id) {
                  <div class="row single-group"
                       data-cy="default-study-sub-group">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-8 col-md-7 col-lg-8 single-group-event-titles">
                          <span class="event-title">
                            @if (studySubGroup.cancelled) {
                              <fudis-badge [variant]="'danger'" [content]="t('FLOW_STATE.CANCELLED')" class="sis-mr-xxs"></fudis-badge>
                            }
                            <span class="name-with-icon">
                            {{ studySubGroup.name | localizedString }}
                              @if (isStudySubGroupConfirmed(studySubGroup.id, enrolment())) {
                                <span class="enrolled-indicator"
                                      [attr.aria-label]="t('ARIA_LABEL.CONFIRMED_ENROLMENT')">
                                <sis-icon icon="check"></sis-icon>
                              </span>
                              }
                              @if (isStudySubGroupTentative(studySubGroup.id, enrolment())) {
                                <span fudisTooltip
                                      [tooltip]="t('COURSE_UNIT_REALISATION.TENTATIVELY_SELECTED_STUDY_SUB_GROUP_TOOLTIP')"
                                      class="enrolled-indicator">
                                <sis-icon icon="waiting-approval"></sis-icon>
                              </span>
                              }
                          </span>
                          </span>

                        </div>
                        <div class="col-4 col-md-5 col-lg-4 enrolled-amount">
                          <span fudisTooltip
                                [tooltip]="t(getEnrolledAmountTranslationKey(enrolmentCourseUnitRealisation()))">
                            <sis-icon
                              [icon]="getEnrolledAmountIcon(studySubGroup.id, enrolmentCourseUnitRealisation(), enrolmentCalculationConfig())"></sis-icon>
                            {{ getEnrolledAmountCount(studySubGroup.id, enrolmentCourseUnitRealisation(), enrolmentCalculationConfig()) }}
                            /{{ studySubGroup.size }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8 col-sm-9 col-md-10 col-lg-8">
                          @if (showStudySubGroupPriority(studySubGroup) && !priorityMaps().isEditableBySsgId[studySubGroup.id] && !areAllStudySubGroupsMandatory(studyGroupSet)) {
                            {{ t('ENROLMENT.STUDY_SUB_GROUP_PRIORITY.' + priorityMaps().studySubGroupPriorityMap[studySubGroup.id]) }}
                          }
                        </div>
                      </div>
                      <div class="row">
                        @if (hasStudyEvents(studySubGroup)) {
                          <div class="col-12 study-event-header">
                            {{ t('COURSE_UNIT_REALISATION.TIME_AND_PLACE_INFO') }}
                          </div>
                        }
                      </div>
                      <div class="row">
                        <div class="col-12">
                          @if (!isStudySubGroupConfirmed(studySubGroup.id, enrolment()) && hasStudyEvents(studySubGroup)) {
                            <div class="checkbox checkbox-toggle sis-toggle">
                              <label>
                                <input type="checkbox"
                                       [id]="studySubGroup.id"
                                       class="form-control"
                                       [checked]="getEnrolmentStudySubGroupOrEmptyObject(studySubGroup.id, enrolment())?.isInCalendar"
                                       [disabled]="!canToggleSubGroupInCalendar(studySubGroup.id, enrolment())"
                                       (change)="onToggleSubGroupInCalendar($event, studySubGroup.id, enrolment())"/>
                                <span> {{ t('COURSE_UNIT_REALISATION.SHOW_EVENTS_IN_CALENDAR') }}</span>
                              </label>
                            </div>
                          }
                        </div>
                      </div>
                      @if (studySubGroup.studyEventIds | studyEvents | async; as studyEvents) {
                        <app-calendar-cur-study-sub-group-event-info [studySubGroup]="studySubGroup"
                                                                     [studyEvents]="studyEvents"
                                                                     [exceptionsVisible]="showUpcomingExceptionWarning(enrolmentCourseUnitRealisation(), enrolment())"
                                                                     (onViewInCalendar)="onViewInCalendar.emit($event)">
                        </app-calendar-cur-study-sub-group-event-info>
                      }
                    </div>
                  </div>
                }
                @if (getStudySubGroupsForExtendedView(studyGroupSet, enrolment()).length > 0) {
                  <div class="row single-group enrolled-show-other-ssg">
                    <fudis-expandable [variant]="'lite'"
                                      [level]="2"
                                      [padding]="'small'"
                                      [title]="t('ENROLMENT.OTHER_STUDY_SUB_GROUPS')">
                      <ng-template fudisExpandableContent>
                        @for (studySubGroup of getStudySubGroupsForExtendedView(studyGroupSet, enrolment()); track studySubGroup.id) {
                          <div class="row single-group">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-8 single-group-event-titles">
                                    <span class="event-title">
                                      @if (studySubGroup.cancelled) {
                                        <fudis-badge [variant]="'danger'"
                                                     [content]="t('FLOW_STATE.CANCELLED')"></fudis-badge>
                                      }
                                      {{ studySubGroup.name | localizedString }}
                                    </span>
                                </div>
                                <div class="col-4 enrolled-amount">
                                    <span fudisTooltip
                                          [tooltip]="t(getEnrolledAmountTranslationKey(enrolmentCourseUnitRealisation()))">
                                      <sis-icon
                                        [icon]="getEnrolledAmountIcon(studySubGroup.id, enrolmentCourseUnitRealisation(), enrolmentCalculationConfig())">
                                      </sis-icon>
                                      {{ getEnrolledAmountCount(studySubGroup.id, enrolmentCourseUnitRealisation(), enrolmentCalculationConfig()) }}
                                      /{{ studySubGroup.size }}
                                    </span>
                                </div>
                              </div>
                              <div class="row">
                                @if (hasStudyEvents(studySubGroup)) {
                                  <div class="col-12 study-event-header">
                                    {{ t('COURSE_UNIT_REALISATION.TIME_AND_PLACE_INFO') }}
                                  </div>
                                }
                              </div>
                              <div class="row">
                                <div class="col-12">
                                  @if (!isStudySubGroupConfirmed(studySubGroup.id, enrolment()) && hasStudyEvents(studySubGroup)) {
                                    <div class="checkbox checkbox-toggle sis-toggle">
                                      <label>
                                        <input type="checkbox"
                                               [id]="studySubGroup.id"
                                               class="form-control"
                                               [checked]="getEnrolmentStudySubGroupOrEmptyObject(studySubGroup.id, enrolment())?.isInCalendar"
                                               [disabled]="!canToggleSubGroupInCalendar(studySubGroup.id, enrolment())"
                                               (change)="onToggleSubGroupInCalendar($event, studySubGroup.id, enrolment())"/>
                                        <span> {{ t('COURSE_UNIT_REALISATION.SHOW_EVENTS_IN_CALENDAR') }}</span>
                                      </label>
                                    </div>
                                  }
                                </div>
                              </div>
                              @if (studySubGroup.studyEventIds | studyEvents | async; as studyEvents) {
                                <app-calendar-cur-study-sub-group-event-info [studySubGroup]="studySubGroup"
                                                                             [studyEvents]="studyEvents"
                                                                             [exceptionsVisible]="showUpcomingExceptionWarning(enrolmentCourseUnitRealisation(), enrolment())"
                                                                             (onViewInCalendar)="onViewInCalendar.emit($event)">
                                </app-calendar-cur-study-sub-group-event-info>
                              }
                            </div>
                          </div>
                        }
                      </ng-template>
                    </fudis-expandable>
                  </div>
                }
              }
            </div>
            @if (enrolmentCourseUnitRealisation().flowState === 'PUBLISHED') {
              <div class="enrolment-block">
                <div class="row">
                  <div class="enrolment-buttons col-12">
                    @if (!enrolmentPossibleForUniversitySignal()) {
                      <div class="enrol-blocked-info not-in-my-university">
                        <sis-icon icon="alert"></sis-icon>
                        {{ t('ENROLMENT.NOT_IN_MY_UNIVERSITY', {name: externalUniversityNames()}) }}
                      </div>
                    }
                    @if (enrolmentPossibleForCooperationNetwork(enrolmentCourseUnitRealisation())) {
                      <div class="enrol-blocked-info not-in-my-university">
                        <sis-icon icon="info"></sis-icon>
                        {{ t('ENROLMENT.COOPERATION_IN_UNIVERSITY', {name: externalUniversityNames()}) }}
                      </div>
                    }
                    @if (isEnrolButtonVisible()) {
                      <div class="button-holder enrol-button">
                        <sis-tooltip
                          [tooltipText]="enrolmentPeriodStartWeekDay() + ' ' + enrolmentPeriodDates() | localDateTimeFormat"
                          [tooltipEnabled]="!hasEnrolmentPeriodStarted()"
                          [appendToBody]="true">
                          <div class="sis-tooltip-trigger-content">
                            <div class="row button-and-info">
                              @if (hasSpaceForEnrolledEnrolmentsInCur() && !this.hasSpaceInSelectedStudySubGroups()) {
                                <div class="col-12 enrol-blocked-info">
                                  {{ t('ENROLMENT.SELECTION_IS_FULL') }}
                                </div>
                              }
                              @if (!hasSpaceForEnrolledEnrolmentsInCur()) {
                                <div class="col-12 enrol-blocked-info">
                                  {{ t('ENROLMENT.COURSE_UNIT_REALISATION_IS_FULL') }}
                                </div>
                              }
                              <fudis-button [variant]="'primary'"
                                            [disabled]="!isEnrolButtonEnabled()"
                                            [size]="'small'"
                                            (handleClick)="onEnrolAction.emit({
                                              enrolment: enrolment(),
                                              courseUnitRealisation: enrolmentCourseUnitRealisation(),
                                              courseUnit: enrolmentCourseUnit(),
                                            })"
                                            [attr.data-cy]="enrolment().state === 'NOT_ENROLLED' ? 'enrol-modal-button' : 're-enrol-modal-button'"
                                            [label]="t(enrolButtonTranslationKey())">
                              </fudis-button>
                            </div>
                          </div>
                        </sis-tooltip>
                      </div>
                    }
                    @if (isUpdateButtonIsVisible()) {
                      <div class="button-holder enrol-button">
                        <sis-tooltip
                          [tooltipText]="enrolmentPeriodStartWeekDay() + ' ' + enrolmentPeriodDates() | localDateTimeFormat"
                          [tooltipEnabled]="!hasEnrolmentPeriodStarted()"
                          [appendToBody]="true">
                          <div class="sis-tooltip-trigger-content">
                            <div class="row button-and-info">
                              <div class="col-12">
                                <fudis-button [label]="t('ENROLMENT.UPDATE_ENROLMENT')"
                                              [variant]="'primary'"
                                              [size]="'small'"
                                              (handleClick)="onUpdateAction.emit({
                                                enrolment: enrolment(),
                                                courseUnitRealisation: enrolmentCourseUnitRealisation(),
                                                courseUnit: enrolmentCourseUnit(),
                                            })"
                                              [attr.data-cy]="'update-enrolment-modal-button'">
                                </fudis-button>
                              </div>
                            </div>
                          </div>
                        </sis-tooltip>
                      </div>
                    }
                    @if (isExternalEnrolmentButtonVisible()) {
                      <div class="button-holder enrol-button">
                        @if (!isExternalEnrolmentButtonDisabled()) {
                          <a fudisLink [title]="externalEnrolmentLinkLabel()
                             ?  t('ENROLMENT.ENROL') + ' ' + externalEnrolmentLinkLabel()
                            :  t('ENROLMENT.EXTERNAL_ENROLMENT_LINK')"
                             [external]="true"
                             [href]="externalEnrolmentLink()"></a>
                        } @else {
                          <sis-contextual-notification
                            [variant]="'warning'">{{ t('ENROLMENT.MISSING_EXTERNAL_ENROLMENT_LINK') }}
                          </sis-contextual-notification>
                        }
                      </div>
                    }
                    @if (isModifyGroupsButtonVisible()) {
                      <div class="button-holder enrol-button">
                        <div class="row button-and-info">
                          <div class="col-12">
                            <fudis-button [variant]="'primary'"
                                          [size]="'small'"
                                          [attr.data-cy]="'modify-groups-modal-button'"
                                          (handleClick)="onModifyGroupsAction.emit(enrolment())"
                                          [label]="t('ENROLMENT.MODIFY_GROUPS')">
                            </fudis-button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="enrolment-information col-12">
                    <div class="row">
                      <div class="col-12 realisation-max-selected"
                           data-cy="realisation-max-selected-label">
                        <span>{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.MAX_SELECTED') }}</span>
                        @if (!maxSelectedIsNull()) {
                          <span>{{ enrolmentCalculationConfig().enrolmentAllocationCounts.maxSelected }}</span>
                        } @else {
                          <span>{{ t('COMMON.NOT_DEFINED') }}</span>
                        }
                      </div>
                      @if (enrolment().state !== 'NOT_ENROLLED') {
                        <div class="col-12">
                          <app-enrolment-state-indicator [enrolment]="enrolment()"
                                                         [courseUnitRealisation]="enrolmentCourseUnitRealisation()">

                          </app-enrolment-state-indicator>
                        </div>
                      }
                      @if (showQuestionnaireLink()) {
                        <div class="col-12 questionnaire-answers">
                          <button type="button"
                                  class="link-button"
                                  (click)="onOpenQuestionnaire.emit({
                                  enrolment: enrolment(),
                                  courseUnitRealisation: enrolmentCourseUnitRealisation(),
                                  courseUnit: enrolmentCourseUnit(),
                                  })">
                            {{ t('ENROLMENT_QUESTIONS.SHOW_ANSWERS') }}
                          </button>
                        </div>
                      }
                      @if (enrolmentEndedAndNotEnrolled() && !lateEnrolmentPeriodOngoing()) {
                        <div class="col-12 enrolment-state">
                          {{ t('ENROLMENT.ENROLMENT_HAS_ENDED') }}
                        </div>
                      }
                      @if (enrolment().state === 'PROCESSING') {
                        <div class="col-12">
                          <app-processing-state-indicator [enrolment]="enrolment()">
                          </app-processing-state-indicator>
                        </div>
                      }
                      @if (isEnrolmentTentativelySelected(enrolment())) {
                        <div class="col-12 tentative-groups">
                          <div>
                            {{ t('ENROLMENT.TENTATIVELY_SELECTED_TO_THESE_GROUPS') }}
                          </div>
                          @for (studyGroupSet of enrolmentCourseUnitRealisation().studyGroupSets; track studyGroupSet.localId) {
                            <div>
                              <span>{{ studyGroupSet.name | localizedString }}: </span>
                              @for (studySubGroup of getTentativeGroupsForSgs(studyGroupSet, enrolment()); track studySubGroup.id) {
                                <span
                                  class="single-tentative-group">{{ studySubGroup.name|localizedString }}@if (!$last) {
                                  <span>,</span>
                                }</span>
                              }
                            </div>
                          }
                        </div>
                      }
                      @if (enrolment().state === 'INVALID') {
                        <div class="col-12 enrolment-state">
                          <sis-icon icon="alert" color="danger"></sis-icon>
                          <span>{{ t('ENROLMENT.INVALID_INFO') }}</span>
                        </div>
                      }
                      @if (enrolment().state === 'REJECTED') {
                        <div class="col-12 enrolment-state">
                          <sis-icon icon="fail" color="danger"></sis-icon>
                          @if (!showFailedRequirementRules()) {
                            <span>{{ t('ENROLMENT.NOT_SELECTED_INFO') }}</span>
                          } @else {
                            <span>{{ t('ENROLMENT.REQ_NOT_FULFILLED_INFO') }}</span>
                          }
                        </div>
                      }
                      @if (showFailedRequirementRules() || showOrderingRules()) {
                        <div class="col-12 processing-state-information-rules">
                          @if (showFailedRequirementRules()) {
                            <div class="enrolment-failed-requirement-rules">
                              <div>
                                <span
                                  class="enrolment-state">{{ t('ENROLMENT.FAILED_REQUIREMENT_PERSON_RULES') }}</span>
                              </div>
                              <div>
                                @for (failedRequirementRule of failedEnrolmentRequirementRules(); track $index) {
                                  <span
                                    class="failed-requirement-rule">{{ failedRequirementRule.rule | personRequirementRuleName | async }}</span>
                                }
                              </div>
                            </div>
                          }
                          @if (showOrderingRules()) {
                            <div class="enrolment-ordering-rules">
                              <div>
                                <span
                                  class="enrolment-state">{{ t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ORDERING_PERSON_RULES') }}</span>
                              </div>
                              <div>
                                <span>{{ t('ENROLMENT.CHECK_ORDERING_RULES') }}</span>
                              </div>
                            </div>
                          }
                        </div>
                      }
                      <div class="col-12 enrolment-information-help">
                        @if (showEnrolmentPeriodStartTimeInfo()) {
                          <span class="enrolment-start-end-date">
                            {{
                              t('CALENDAR_ENROLMENT.ENROLMENT_PERIOD_STARTS', {
                                day: enrolmentPeriodStartWeekDay(),
                                date: (enrolmentPeriodDates().startDateTime | localDateFormat),
                                time: (enrolmentPeriodDates().startDateTime | localTimeFormat)
                              })
                            }}
                          </span>
                        }
                        @if (showSelectionsConfirmedOnInfo()) {
                          <span class="enrolment-start-end-date">
                             {{
                              t('CALENDAR_ENROLMENT.ENROLMENT_PERIOD_ENDS', {
                                day: enrolmentPeriodEndWeekDay(),
                                date: (enrolmentPeriodDates().endDateTime | localDateFormat),
                                time: (enrolmentPeriodDates().endDateTime | localTimeFormat)
                              })
                            }}
                          </span>
                        }
                        @if (lateEnrolmentPeriodOngoing() && enrolment().state !== 'ENROLLED') {
                          <span class="enrolment-start-end-date">
                            {{
                              t('CALENDAR_ENROLMENT.LATE_ENROLMENT_PERIOD_ENDS', {
                                day: enrolmentPeriodLateEnrolmentEndWeekDay(),
                                date: (enrolmentPeriodDates().lateEnrolmentEnd | localDateFormat),
                                time: (enrolmentPeriodDates().lateEnrolmentEnd | localTimeFormat)
                              })
                            }}
                          </span>
                        }
                        @if (lateOrContinuousPeriodOngoing() && isEnrolButtonVisible()) {
                          <span class="enrolments-confirmed-immediately">
                            {{ t('ENROLMENT.ENROLMENTS_ARE_CONFIRMED_IMMEDIATELY') }}
                          </span>
                        }
                        @if (showAnswersModifiableUntilInfo() && enrolmentPeriodDates().endDateTime === enrolmentPeriodDates().cancellationEndDateTime) {
                          <span>{{ t('ENROLMENT.ANSWERS_MODIFIABLE_UNTIL_END_OF_ENROLMENT') }}</span>
                        }
                        @if (showAnswersModifiableUntilInfo() && enrolmentPeriodDates().endDateTime !== enrolmentPeriodDates().cancellationEndDateTime) {
                          <span>{{
                              t('CALENDAR_ENROLMENT.ANSWERS_MODIFIABLE_UNTIL_END_OF_ENROLMENT_AND_CANCELLATION_POSSIBLE_UNTIL',
                                {
                                  day: enrolmentPeriodCancellationEndWeekDay(),
                                  date: (enrolmentPeriodDates().cancellationEndDateTime | localDateFormat),
                                })
                            }}</span>
                        }
                        @if (isCancelButtonEnabled()
                        && isCancelButtonVisible()
                        && !enrolmentEndedAndNotEnrolled()
                        && enrolmentPeriodDates().endDateTime !== enrolmentPeriodDates().cancellationEndDateTime
                        && !showAnswersModifiableUntilInfo()) {
                          <span> {{
                              t('CALENDAR_ENROLMENT.CANCELLATION_POSSIBLE_UNTIL', {
                                day: enrolmentPeriodCancellationEndWeekDay(),
                                date: (enrolmentPeriodDates().cancellationEndDateTime | localDateFormat)
                              })
                            }}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div class="links">
              <span class="links-title"> {{ t('COURSE_UNIT_REALISATION.LABEL_COURSE_UNIT_INFO') }} </span>
              <span class="links-wrapper">
                <a data-cy="course-unit-info-link"
                   (click)="$event.preventDefault()"
                   (mousedown)="clickCourseUnitInfoLink($event)"
                   [href]="'/student/courseunit/'+enrolmentCourseUnit().id"
                   [attr.aria-label]="enrolmentCourseUnit().code + ' ' + (enrolmentCourseUnit().name | localizedString) + ' ' + (t('ARIA_LABEL.OPEN_INFO'))">
                    <sis-icon icon="brochure" color="primary"></sis-icon>
                  {{ enrolmentCourseUnit().code }}
                  , {{ enrolmentCourseUnit().name | localizedString }}
                </a>
              </span>
            </div>
            @if(enrolmentCourseUnitRealisation().learningEnvironments?.length > 0) {
              <div class="learning-environments">
                <span class="links-title">{{ t('COURSE_UNIT_REALISATION.LABEL_LEARNING_ENVIRONMENTS') }}</span>
                <app-learning-environments [learningEnvironments]="enrolmentCourseUnitRealisation().learningEnvironments"></app-learning-environments>
              </div>
            }
            @if (canRemoveFromCalendar()) {
              <div class="button-holder">
                <fudis-button [label]="t('REMOVE_FROM_CALENDAR')"
                              [icon]="'delete'"
                              [variant]="'tertiary'"
                              [size]="'small'"
                              (handleClick)="onRemoveFromCalendar.emit(enrolment())"
                              [attr.data-cy]="'remove-enrolment-button'">
                </fudis-button>
              </div>
            }
            @if (isCancelButtonVisible()) {
              <div class="button-holder">
                <fudis-button [disabled]="!isCancelButtonEnabled()"
                              [label]="t('ENROLMENT.CANCEL_ENROLMENT')"
                              [variant]="'tertiary'"
                              [tooltip]="!isCancelButtonEnabled() ? t('ENROLMENT.ENROLMENTS_NOT_YET_PROCESSED') : null"
                              [size]="'small'"
                              [icon]="'back'"
                              [attr.data-cy]="'cancel-enrolment-modal-button'"
                              (handleClick)="onCancelAction.emit({
                                enrolment: enrolment(),
                                courseUnitRealisation: enrolmentCourseUnitRealisation(),
                                courseUnit: enrolmentCourseUnit()
                              })">
                </fudis-button>
              </div>
            }
            @if (isAbortButtonVisible()) {
              <div class="button-holder">
                <fudis-button [label]="t('ENROLMENT.ABORT_ENROLMENT')"
                              [variant]="'tertiary'"
                              [size]="'small'"
                              [icon]="'back'"
                              [attr.data-cy]="'abort-enrolment-button'"
                              (handleClick)="onAbortAction.emit({
                                enrolment: enrolment(),
                                courseUnitRealisation: enrolmentCourseUnitRealisation(),
                                courseUnit: enrolmentCourseUnit()
                              })">
                </fudis-button>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</ng-container>

