<sis-tab-content-switch [tabs]="tabs" [type]="'pills'" (currentIndexChange)="onTabChange($event)"></sis-tab-content-switch>

<div *ngIf="openTabIndex === 0">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NAME</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.name | localizedString }}
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NAME_SPECIFIER</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.nameSpecifier | localizedString }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.FULL_NAME</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TYPE</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.courseUnitRealisationTypeUrn | codeLocalizedName | async }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TEACHING_LANGUAGE</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.teachingLanguageUrn | codeLocalizedName | async }}
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.STUDY_FORMAT</h4>
        <p class="form-control-static">
          <sis-localized-markup-string-view [content]="courseUnitRealisation.studyFormat"></sis-localized-markup-string-view>
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.CLASSIFICATION</h4>
        <p class="form-control-static" *ngFor="let customUrn of this.currentUniversityCustomCodeUrns">
          {{ customUrn | codeLocalizedName | async }}
        </p>
        <p *ngIf="!this.currentUniversityCustomCodeUrns || this.currentUniversityCustomCodeUrns.length === 0" class="form-control-static">
          -
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LEARNING_ENVIRONMENTS</h4>
        <p class="form-control-static" *ngFor="let learningEnvironment of courseUnitRealisation.learningEnvironments">
          <sis-learning-environment [learningEnvironment]="learningEnvironment"></sis-learning-environment>
        </p>
        <div *ngIf="!courseUnitRealisation.learningEnvironments || courseUnitRealisation.learningEnvironments.length === 0"
             class="form-control-static">
          -
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TWEET_TEXT</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.tweetText | localizedString }}
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LEARNING_ENVIRONMENT_DESCRIPTION</h4>
        <p class="form-control-static">
          {{ courseUnitRealisation.learningEnvironmentDescription | localizedString }}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LEARNING_MATERIAL</h4>
        <div class="form-control-static">
          <sis-localized-markup-string-view [content]="courseUnitRealisation.learningMaterial"></sis-localized-markup-string-view>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LITERATURE</h4>
        <p *ngFor="let item of courseUnitRealisation.literature">
          <sis-literature [literature]="item"></sis-literature>
        </p>
        <p *ngIf="!courseUnitRealisation.literature || courseUnitRealisation.literature.length === 0">
          -
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ACTIVITY_PERIOD</h4>
        <p class="form-control-static">{{ courseUnitRealisation.activityPeriod | localDateRange }}</p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.UNIVERSITIES</h4>
        <p class="form-control-static"
           *ngFor="let universityOrgId of courseUnitRealisation.universityOrgIds">
          {{ universityOrgId | organisation | async | entityName | localizedString}}
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group-mimic">
        <h4 translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ADDITIONAL_INFO</h4>
        <div class="form-control-static">
          <sis-localized-markup-string-view [content]="courseUnitRealisation.additionalInfo"></sis-localized-markup-string-view>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="teacher">
        <h4 translate="STUDIES.COURSE_UNIT_ASSESSMENT_ITEMS.RESPONSIBILITY_INFOS"></h4>
        <sis-course-unit-realisation-responsibility-info [responsibilityInfos]="courseUnitRealisation.responsibilityInfos"
                                                         [language]="language">
        </sis-course-unit-realisation-responsibility-info>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <sis-responsible-organisation-info [organisations]="courseUnitRealisation.organisations"></sis-responsible-organisation-info>
    </div>
  </div>
</div>

<div *ngIf="openTabIndex === 1">
  <div class="form-group-mimic">
    <div class="row" *ngIf="courseUnitRealisation.enrolmentPeriod">
      <div class="col-12 col-lg-10 col-xl-8">
            <span class="control-label form-control-static tiny-static-form-group" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ENROLMENT_TIME"></span>
            <span class="form-control-static"><strong> {{ courseUnitRealisation.enrolmentPeriod | localDateTimeRange }}</strong></span>
        </div>
      </div>
  </div>

  <ng-container *ngIf="enrolmentCalculationConfig$ | async as enrolmentCalculationConfig">
    <div class="row">
        <div class="col-12 col-lg-10 col-xl-8">
          <sis-course-unit-realisation-seats [enrolmentCalculationConfig]="enrolmentCalculationConfig"></sis-course-unit-realisation-seats>
        </div>
    </div>

    <div class="row">
      <div *ngIf="hasRequirementPersonRules(enrolmentCalculationConfig)" class="col-12 col-lg-10 col-xl-8">
        <div class="form-group-mimic">
          <h4 translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.REQUIREMENT_PERSON_RULES"></h4>
          <p class="guidance" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.REQUIREMENT_PERSON_RULES_HELP"></p>
          <ul class="person-rules">
            @for (rule of enrolmentCalculationConfig.requirementPersonRules; track $index) {
              <li>{{ rule | personRequirementRuleName | async}}</li>
            }
          </ul>
        </div>
      </div>
      <div *ngIf="hasOrderingPersonRules(enrolmentCalculationConfig)" class="col-12 col-lg-10 col-xl-8">
        <div class="form-group-mimic">
          <h4 translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ORDERING_PERSON_RULES"></h4>
          <p class="guidance" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ORDERING_PERSON_RULES_HELP"></p>
          <ul class="person-rules ordering-person-rules">
            @for (rule of enrolmentCalculationConfig.orderingPersonRules; track $index) {
              <li>{{ rule | personRequirementRuleName | async}}</li>
            }
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="!hasRequirementPersonRules(enrolmentCalculationConfig) && !hasOrderingPersonRules(enrolmentCalculationConfig) && !courseUnitRealisation.enrolmentPeriod" class="col-12">
        <div class="form-group-mimic">
          <p class="sis-placeholder" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.NO_INFORMATION_GIVEN"></p>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="openTabIndex === 2">
  <div class="row">
    <div class="col-12">
      <div class="form-group-mimic">
        <sis-course-unit-realisation-structure [courseUnitRealisation]="courseUnitRealisation" [language]="language"></sis-course-unit-realisation-structure>
      </div>
    </div>
  </div>
</div>
