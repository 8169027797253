import {
    Component,
    Inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import {
    MessageConversation,
    OtmId,
    ReceivedMessageTypeFilter,
    SearchFilterType,
    SearchResult,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, tap } from 'rxjs';
import {
    first,
    map,
} from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import {
    COMMON_MESSAGE_SERVICE,
    SEARCH_PARAMETERS,
} from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option as MassActionOption } from 'sis-components/menuButton/menu-button.component';
import {
    MessageConversationService,
    MessageConversationsSearch,
} from 'sis-components/service/message-conversation.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-student-message-conversations-list',
    templateUrl: './student-message-conversations-list.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudentMessageConversationsListComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.messages.studentMessageConversationsList',
        directiveName: 'studentMessageConversationsList',
    };

    @Input() recipientId: string;

    @Input() filterType: ReceivedMessageTypeFilter = 'STUDENT';

    @Input() filter: 'ALL' | 'MESSAGE' | 'NOTIFICATION' | 'ARCHIVED' = 'ALL';

    @Input() returnStateParams: null;

    result: SearchResult<MessageConversation>;
    conversations$: Observable<MessageConversation[]>;
    searchParameters: any;
    searchParameterOptions: any;
    messageConversationsSearch: MessageConversationsSearch;
    itemsPerPage = 10;
    totalItems = 0;
    fullTextQuery = '';
    currentPage: number;
    massActionOptions: MassActionOption[];
    selectedMessageConversationIds: OtmId[] = [];
    allSelected = false;
    isUnreadMessagesSelected: boolean;
    isArchivedMessagesSelected = false;
    unreadMessagesCount: number;

    constructor(private messageConversationService: MessageConversationService,
                private translateService: TranslateService,
                private stateService: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private appErrorHandler: AppErrorHandler,
                private alertsService: AlertsService,
                private translocoService: TranslocoService,
                @Inject(COMMON_MESSAGE_SERVICE) private commonMessageService: any,
                @Inject(SEARCH_PARAMETERS) private SearchParameters: any,
    ) {
        this.searchParameters = new SearchParameters().fetchLimitedResults();
        this.searchParameterOptions = {
            searchString: {
                name: 'SEARCHSTRING',
                type: SearchFilterType.NO_POPOVER,
                hide: true,
            },
            messageType: {
                name: 'MESSAGETYPE',
                type: SearchFilterType.MULTI,
                options: this.commonMessageService.getMessageTypeOptionsForSearch(),
            },
            messageReadState: {
                name: 'MESSAGEREADSTATE',
                type: SearchFilterType.MULTI,
                options: this.commonMessageService.getMessageStateOptionsForSearch(),
            },
        };
        this.search = this.search.bind(this);

    }

    ngOnInit() {
        this.currentPage = _.get(this.returnStateParams, 'page') || 1;
        this.initMessageConversationsSearch();
        this.massActionOptions = this.getMassActionOptions();
        this.search();
    }

    toggleSelectAll(): void {
        if (this.allSelected) {
            this.allSelected = false;
            this.unselectAll();
        } else {
            this.allSelected = true;
            this.selectAll();
        }
    }

    toggleConversationSelection(conversationId: OtmId): void {
        if (_.includes(this.selectedMessageConversationIds, conversationId)) {
            _.pull(this.selectedMessageConversationIds, conversationId);
        } else {
            this.selectedMessageConversationIds.push(conversationId);
        }
    }

    selectAll(): void {
        const allIds = _.map(this.result.searchResults, 'conversationId');
        this.selectedMessageConversationIds = allIds;
    }

    unselectAll(): void {
        this.selectedMessageConversationIds = [];
    }

    search(): void {
        this.conversations$ = this.messageConversationService.searchMessageConversations(
            this.messageConversationsSearch,
            'STUDENT',
        )
            .pipe(
                map((response) => {
                    this.result = response;
                    this.totalItems = response.total;
                    this.unreadMessagesCount = _.sum(_.map(response.searchResults, 'messagesUnread'));
                    return response.searchResults;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    onPageChange(currentPage: number): void {
        const page = _.isFinite(currentPage) ? currentPage : 1;
        this.selectedMessageConversationIds = [];
        this.allSelected = false;
        this.setPage(page);
        this.search();
    }

    setPage(page: number) {
        this.currentPage = page;
        this.messageConversationsSearch.start = (this.currentPage - 1) * this.itemsPerPage;
    }

    filterUnreadMessages(filter: boolean) {
        this.isUnreadMessagesSelected = filter ? filter : null;
        this.messageConversationsSearch.isRead = _.isNil(this.isUnreadMessagesSelected) ? null : !this.isUnreadMessagesSelected as boolean;
        this.search();
    }

    filterArchivedMessages(filter: boolean) {
        this.isArchivedMessagesSelected = filter;
        this.messageConversationsSearch.isArchived = this.isArchivedMessagesSelected;
        this.search();
    }

    initMessageConversationsSearch(): void {
        this.messageConversationsSearch = {
            recipientId: this.recipientId,
            studentSearch: '',
            filterType: this.filterType,
            messageTypes: [],
            messageCategoryType: null,
            isRead: null,
            isArchived: this.isArchivedMessagesSelected,
            start: ((_.get(this.returnStateParams, 'page') || 1) - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
        };
    }

    moveToMessageConversation(messageConversationId: string) {
        this.stateService.go('student.logged-in.message-conversations.message-conversation', {
            messageConversationId,
            returnRoute: this.uiRouterGlobals.current.name,
            returnParams: {
                page: this.currentPage,
                isRead: this.messageConversationsSearch.isRead,
            },
        });
    }

    getMassActionOptions(): MassActionOption[] {
        if (this.filter === 'ARCHIVED') {
            return [
                {
                    name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_NOT_ARCHIVED'),
                    action: () => this.markSelectedMessageConversationsAsNotArchived(),
                },
            ];
        }
        return [
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_READ'),
                action: () => this.markSelectedMessageConversationsAsRead(),
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_UNREAD'),
                action: () => this.markSelectedMessageConversationsAsUnread(),
            },
            {
                name: this.translateService.instant('SIS_COMPONENTS.MESSENGER.MESSAGE_CONVERSATION.MARK_AS_ARCHIVED'),
                action: () => this.markSelectedMessageConversationsAsArchived(),
            },
        ];
    }

    markSelectedMessageConversationsAsRead(): void {
        this.messageConversationService.markMessageConversationsAsRead(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_READ_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_READ_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsUnread(): void {
        this.messageConversationService.markMessageConversationsAsUnread(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_UNREAD_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.MARK_UNREAD_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsArchived(): void {
        this.messageConversationService.markMessageConversationsAsArchived(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.ARCHIVE_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.ARCHIVE_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    markSelectedMessageConversationsAsNotArchived(): void {
        this.messageConversationService.markMessageConversationsAsNotArchived(this.selectedMessageConversationIds)
            .pipe(
                first(),
                tap((res) => {
                    this.showSuccessAlert(
                        'SIS_COMPONENTS.MESSENGER.ALERTS.UNARCHIVE_SUCCESS_SINGULAR',
                        'SIS_COMPONENTS.MESSENGER.ALERTS.UNARCHIVE_SUCCESS_PLURAL',
                    );
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(this.search);
    }

    showSuccessAlert(singularMessageKey: string, pluralMessageKey: string): void {
        let alertMessage: string;
        const messageCount = _.size(this.selectedMessageConversationIds);
        if (messageCount === 1) {
            alertMessage = this.translocoService.translate(singularMessageKey);
        } else {
            alertMessage = this.translocoService.translate(pluralMessageKey, { count: messageCount });
        }
        this.alertsService.addAlert({
            message: alertMessage,
            type: AlertType.SUCCESS,
        });
    }

}
