<ng-container >
    <legend>
        <label id="search-filter-dropdown-select">{{ label }}</label>
    </legend>
    <sis-dropdown-select
            [ariaLabelledBy]="'search-filter-dropdown-select'"
            [selection]="(selection$ | async)?.value"
            [options]="options"
            (selectionChange)="onSelectionChange($event)"
            [clearable]="true"
    >
    </sis-dropdown-select>
</ng-container>