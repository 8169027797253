<ng-container *transloco="let t">
  @if (studyEvents()?.length > 0) {
    @for (studyEvent of orderedStudyEvents(); track studyEvent.id) {
      <div class="study-event">
        <app-calendar-cur-study-sub-group-study-event-summary [studyEvent]="studyEvent"
                                                              [studySubGroup]="studySubGroup()"
                                                              (onViewInCalendar)="onViewInCalendar.emit($event)">
        </app-calendar-cur-study-sub-group-study-event-summary>
      </div>
    }
  }
  @if (irregularEvents()?.length > 0) {
    <sis-show-more (click)="showExceptions.set(!showExceptions())"
                   [attr.aria-expanded]="showExceptions()"
                   [text]="t('STUDY_SUB_GROUP_EVENT_INFO.EXCEPTIONS_AND_ADDITIONAL_INFO')">
    </sis-show-more>
    @if (showExceptions()) {
      @for (event of irregularEvents(); track event.start) {
        <div class="irregular-event">
          <div class="row event-detail-row">
            <div class="col-2">
              {{ getEventWeekDayAndDate(event.start) }}
            </div>
            <div class="col-3">
              {{ getEventTimeRange(event) }}
            </div>
            <div class="col-6">
              @if (event.cancelled) {
                <fudis-badge [variant]="'danger'" [content]="t('STUDY_SUB_GROUP_EVENT_INFO.CANCELLED')">
                </fudis-badge>
              }
              @if (event.excluded) {
                <fudis-badge [variant]="'secondary'" [content]="t('STUDY_SUB_GROUP_EVENT_INFO.EXCLUDED')">
                </fudis-badge>
              }
              @if (!event.cancelled && !event.excluded) {
                <div>
                  @if (event.irregularLocationIds?.length === 0) {
                    <span>
                      <sis-icon icon="pin-small"></sis-icon>
                      <span class="no-location">
                        {{ t('STUDY_SUB_GROUP_EVENT_INFO.LOCATIONS_REMOVED') }}
                      </span>
                    </span>
                  }
                  @if (event.irregularLocationIds?.length > 0) {
                    <div>
                      @for (locationId of event.irregularLocationIds; track locationId) {
                        @if (locationId | location | async; as location) {
                          <div>
                            <sis-icon icon="pin-small"></sis-icon>
                            <span class="location">
                              {{ location.name | localizedString }}
                            </span>
                          </div>
                        }
                      }
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          @if (event.notice) {
            <div class="row event-detail-row">
              <div class="col-12 event-notice">
                {{ event.notice | localizedString }}
              </div>
            </div>
          }
        </div>
      }
    }
  }
</ng-container>
