import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TransitionOptions } from '@uirouter/core';
import { OtmId, SearchResult, StudentWorkflow, Workflow } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { Icon } from '../../icon/icon.component';

export interface SortAndPaginationEvent {
    start: number;
    itemsPerPage: number;
    sortColumn: string;
    reverse: boolean;
}

export interface WorkflowLinkCreator {
    state: (workflow: Workflow) => string;
    params: (workflow: Workflow) => any;
    options?: TransitionOptions;
}

export type WorkflowListColumn = keyof StudentWorkflow | 'lastHandlerPerson' | 'name' | 'studentName' | 'studyRight';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-student-workflow-list',
    templateUrl: './student-workflow-list.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentWorkflowListComponent implements OnInit, OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applications.studentWorkflowList.downgraded',
        directiveName: 'sisStudentWorkflowList',
    };

    @Input() studentWorkflowSearchResult: SearchResult<StudentWorkflow>;

    @Input() columns: WorkflowListColumn[];

    @Input() reverse: boolean;

    @Input() sortColumn: WorkflowListColumn;

    @Input() sortColumns: WorkflowListColumn[];

    @Input() studentPageView: boolean;

    @Input() userId: OtmId;

    /** Determines the state where the navigation link for a given workflow should point to. */
    @Input({ required: true }) workflowLinkCreator: WorkflowLinkCreator;

    @Output() setSortAndPagination = new EventEmitter<SortAndPaginationEvent>();

    @Output() editHandler = new EventEmitter<OtmId>();

    readonly maxSize = 5;
    public currentPage: number;
    public isOnStudentPage: boolean;
    public totalItems: number;
    public itemsPerPage: number;

    constructor(
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.currentPage = this.currentPage ?? 1;
        this.isOnStudentPage = !!this.studentPageView;
    }

    ngOnChanges() {
        this.totalItems = _.get(this.studentWorkflowSearchResult, 'total', 0);
        this.itemsPerPage = _.get(this.studentWorkflowSearchResult, 'limit', 20);
        this.currentPage = (_.get(this.studentWorkflowSearchResult, 'start', 0) / this.itemsPerPage) + 1;
    }

    setOrder(sortColumn: WorkflowListColumn) {
        this.reverse = (this.sortColumn === sortColumn) ? !this.reverse : false;
        this.sortColumn = sortColumn;
        this.currentPage = 1;
        this.onPageChange(this.currentPage);
    }

    isSortColumn(columnName: string) {
        return _.includes(this.sortColumns, columnName);
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        const start = (this.currentPage - 1) * this.itemsPerPage;

        this.setSortAndPagination.emit(
            {
                start,
                itemsPerPage: this.itemsPerPage,
                sortColumn: this.sortColumn,
                reverse: this.reverse,
            });

    }

    getStatusIcon(state: string): Icon {
        switch (state) {
            case 'REQUESTED':
            case 'IN_HANDLING':
                return 'hourglass';
            case 'REJECTED':
                return 'fail';
            case 'CANCELLED':
                return 'fail';
            case 'ACCEPTED':
                return 'check';
            case 'REVOKED':
                return 'fail';
            case 'CONDITIONAL':
                return 'check';
            case 'ACCEPTANCE_REVOKED':
                return 'fail';
            case 'SUPPLEMENT_REQUESTED':
                return 'back';
            default:
                return 'hourglass';
        }
    }

    getStatusIconClass(state: string) {
        switch (state) {
            case 'REQUESTED':
            case 'IN_HANDLING':
            case 'REJECTED':
            case 'ACCEPTED':
            case 'CONDITIONAL':
                return null;
            case 'CANCELLED':
            case 'REVOKED':
            case 'ACCEPTANCE_REVOKED':
                return 'cancelled';
            case 'SUPPLEMENT_REQUESTED':
            default:
                return null;
        }
    }

    getSorterAriaLabel(orderKey: string) {
        let ariaLabel;
        if (this.sortColumn === orderKey) {
            ariaLabel = `${this.translate.instant('ARIA_LABEL.ACTIVE')} `;
            if (!this.reverse) {
                switch (orderKey) {
                    case 'code':
                    case 'state':
                        ariaLabel += this.translate.instant('ARIA_LABEL.ASCENDING_ALPHABETICAL_ORDER');
                        break;
                    case 'lastHandledTime':
                    case 'creationTime':
                        ariaLabel += this.translate.instant('ARIA_LABEL.ASCENDING_DATE');
                        break;
                    default:
                        break;
                }
            } else {
                switch (orderKey) {
                    case 'code':
                    case 'state':
                        ariaLabel += this.translate.instant('ARIA_LABEL.DESCENDING_ALPHABETICAL_ORDER');
                        break;
                    case 'lastHandledTime':
                    case 'creationTime':
                        ariaLabel += this.translate.instant('ARIA_LABEL.DESCENDING_DATE');
                        break;
                    default:
                        break;
                }
            }
        } else {
            ariaLabel = this.translate.instant('ARIA_LABEL.ACTIVATE');
        }
        return ariaLabel;
    }
}
