import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import * as _ from 'lodash-es';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UuidService } from 'sis-common/uuid/uuid.service';

export interface Option {
    /** The underlying value of the option (will not be shown in the UI) */
    value: any;
    /** The label that will be shown for this option in the UI */
    label: string;
    /** Optional secondary label shown beside the primary label with less emphasis. */
    secondaryLabel?: string;
    /** Controls whether this option is disabled in the dropdown */
    disabled?: boolean;
    /** Controls whether this option is a header in the list of options */
    header?: boolean;
    /** Accessibility helper to figure out item indexes with headers */
    indexId?: number;
}

/** Internal header option interface */
interface HeaderOption {
    headerLabel: string;
    options: Option[];
}

@Component({
    selector: 'sis-combobox',
    templateUrl: './combobox.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ComboboxComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
    @Input() id?: string;
    @Input() options: Option[] = [];
    @Input() selected: Option[] = [];
    @Input() label: string;
    @Input() placeholder?: string = '';
    @Input() helpBlock?: string;
    @Output() predictiveSearch? = new EventEmitter<string>();
    @Output() optionChange = new EventEmitter<Option[]>();
    @Input() predictive?: boolean = false;

    predictiveSearchWithDebounce = new Subject<string>();
    visibleSelectedItems: any[] = [];
    hiddenItemCount = 0;
    searchTerm = '';
    showDropdown = false;
    onFocus = false;
    nonGroupedOptions: Option[] = [];
    groupedOptions: { [key: string]: HeaderOption } = {};
    destroyed$ = new Subject<void>();

    private componentInitialized: boolean = false;

    @ViewChild('comboboxElement') private comboboxElement: ElementRef;
    @ViewChild('selectedItemsElement') private selectedItemsElement: ElementRef;
    @ViewChild('inputElement') private inputElement: ElementRef;
    @ViewChild('controlsElement') private controlsElement: ElementRef;
    @ViewChild('searchIconElement') private searchIconElement: ElementRef;
    @ViewChild('menuButtonElement') private menuButtonElement: ElementRef;
    @ViewChild('deleteButtonElement') private deleteButtonElement: ElementRef;
    @ViewChild('dropdownElement') private dropdownElement: ElementRef;

    constructor(private changeDetectorRef: ChangeDetectorRef, private uuidService: UuidService) {
    }

    ngOnInit() {
        this.id = this.id ? this.id : this.uuidService.randomUUID();
        this.mapOptionsToGroupedAndNonGroupedOptions(this.options);
        this.predictiveSearchWithDebounce.pipe(
            takeUntil(this.destroyed$),
            debounceTime(700),
        )
            .subscribe((searchStr) => {
                this.predictiveSearch.emit(searchStr);
            });
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    private mapOptionsToGroupedAndNonGroupedOptions(options: Option[]) {
        let latestHeader: string | null = null;

        this.groupedOptions = {};
        this.nonGroupedOptions = [];

        // Loop through all options and categorize them in header groups and non-header groups.
        options.forEach((option, index) => {
            if (option.header) {
                latestHeader = option.value;
                this.groupedOptions = {
                    ...this.groupedOptions,
                    [option.value]: { headerLabel: option.label, options: [] },
                };
            } else if (!option.header && latestHeader === null) {
                option.indexId = index;
                this.nonGroupedOptions.push(option);
            } else if (latestHeader) {
                option.indexId = index - Object.keys(this.groupedOptions).length;
                this.groupedOptions[latestHeader].options.push(option);
            }
        });

        // Remove headers where there is no options
        Object.keys(this.groupedOptions).forEach(key => {
            if (this.groupedOptions[key].options.length === 0) {
                delete this.groupedOptions[key];
            }
        });
    }

    ngAfterViewChecked() {
        if (!this.componentInitialized) {
            this.updateVisibleItems();
            this.componentInitialized = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.options && changes.options.previousValue) && !_.isEqual(changes.options.previousValue, changes.options.currentValue)) {
            this.mapOptionsToGroupedAndNonGroupedOptions(changes.options.currentValue);
        }
        if ((changes.selected && changes.selected.previousValue) && !_.isEqual(changes.selected.previousValue, changes.selected.currentValue)) {
            this.selected = _.cloneDeep(changes.selected.currentValue);
            this.updateVisibleItems();
        }
    }

    search(event: any): void {
        this.searchTerm = event.target.value;

        if (event.key !== 'Enter' && event.key !== 'ArrowDown') {
            if (this.searchTerm !== '' && event.key !== 'Escape') {
                if (this.predictive) {
                    this.predictiveSearchWithDebounce.next(this.searchTerm);
                } else {
                    const filteredOptions = this.options.filter(option => option.header || option.label.toLowerCase().includes(this.searchTerm.toLowerCase()));
                    this.mapOptionsToGroupedAndNonGroupedOptions(filteredOptions);
                }
                if (!this.showDropdown) {
                    this.showDropdown = true;
                }
            } else {
                this.showDropdown = false;
                this.mapOptionsToGroupedAndNonGroupedOptions(this.options);
            }
        }
    }

    removeOption(option: Option): void {
        this.selected = this.selected.filter(item => !_.isEqual(option.value, item.value));
        this.optionChange.emit(this.selected);
    }

    removeSelected(event: any, option: Option) {
        if (event.target.nextElementSibling) {
            event.target.nextElementSibling.focus();
        } else if (event.target.previousElementSibling) {
            event.target.previousElementSibling.focus();
        } else {
            this.inputElement.nativeElement.focus();
        }
        new Promise(res => setTimeout(res, 100)).then(() => { this.removeOption(option); }); // Wait for the focus to change before removing the item
    }

    select(option: Option): void {
        if (this.isChecked(option)) {
            this.removeOption(option);
        } else {
            this.selected.push(option);
            this.optionChange.emit(this.selected);
        }
        this.updateVisibleItems();
    }

    updateVisibleItems() {
        const containerWidth = this.comboboxElement.nativeElement?.offsetWidth;
        const menuButtonWidth = this.predictive ? this.searchIconElement?.nativeElement?.offsetWidth : this.menuButtonElement?.nativeElement?.offsetWidth;
        const deleteButtonWidth = this.deleteButtonElement?.nativeElement?.offsetWidth ? this.deleteButtonElement?.nativeElement?.offsetWidth : 0;

        let availableWidth = containerWidth - menuButtonWidth - deleteButtonWidth;
        this.visibleSelectedItems = [];
        this.hiddenItemCount = 0;

        /* Set max width for the selectedItemsElement, because even when the text is truncated with ellipsis (less-file),
        it still has its original width, that would push the combobox buttons out of the box */
        if (this.selectedItemsElement) this.selectedItemsElement.nativeElement.style.maxWidth = `${availableWidth}px`;

        this.selected.forEach((item, index) => {
            if (this.onFocus) {
                this.visibleSelectedItems.push(item);
            } else {
                const itemWidth = this.computeSelectedItemWidth(item.label);
                if (index === 0 && availableWidth < itemWidth) {
                    this.visibleSelectedItems.push(item);
                    availableWidth = 0;
                } else if (itemWidth < availableWidth) {
                    this.visibleSelectedItems.push(item);
                    availableWidth -= itemWidth;
                } else {
                    this.hiddenItemCount = this.hiddenItemCount + 1;
                }
            }
        });

        if (this.hiddenItemCount > 0 && this.visibleSelectedItems.length > 1) {
            const hiddenIndicatorWidth = this.computeHiddenIndicatorWidth(this.hiddenItemCount);
            if (hiddenIndicatorWidth >= availableWidth) {
                // Remove the last item from visible items to make space for the indicator
                this.visibleSelectedItems.pop();
                this.hiddenItemCount = this.hiddenItemCount + 1;
            }
        }

        if (!this.onFocus) {
            this.searchTerm = '';
            this.showDropdown = false;
            this.mapOptionsToGroupedAndNonGroupedOptions(this.options);
        }
        this.changeDetectorRef.detectChanges();
    }

    computeSelectedItemWidth(item: string): number {
        // Append the selected items off-screen to get the width of the elements, then remove the items and return the width
        // The styling is not 100% accurate, most important is to get the approx. width
        const offscreenDiv = document.createElement('div');
        offscreenDiv.classList.add('hidden');
        document.body.appendChild(offscreenDiv);

        const selectedItem = document.createElement('span');
        selectedItem.innerText = item;
        selectedItem.style.fontSize = '16px';
        selectedItem.style.padding = '35px';
        offscreenDiv.appendChild(selectedItem);

        const width = selectedItem.offsetWidth;

        document.body.removeChild(offscreenDiv);

        return width;
    }

    computeHiddenIndicatorWidth(hiddenCount: number): number {
        // Same logic as in computeSelectedItemWidth-function
        const offscreenDiv = document.createElement('div');
        offscreenDiv.classList.add('hidden');
        document.body.appendChild(offscreenDiv);

        const indicator = document.createElement('span');
        indicator.innerText = `+ ${hiddenCount}`;
        indicator.style.fontSize = '16px';
        indicator.style.padding = '8px';
        offscreenDiv.appendChild(indicator);

        const width = indicator.offsetWidth;

        document.body.removeChild(offscreenDiv);

        return width;
    }

    isChecked(option: Option): boolean {
        return this.selected.some(e => _.isEqual(e.value, option.value));
    }

    clearAll() {
        this.selected = [];
        this.searchTerm = '';
        this.visibleSelectedItems = [];
        this.hiddenItemCount = 0;
        this.inputElement.nativeElement.focus();
        this.optionChange.emit(this.selected);
        this.toggleMenu(false);
        this.mapOptionsToGroupedAndNonGroupedOptions(this.options);
        this.changeDetectorRef.detectChanges();
    }

    toggleMenu(isOpen?: boolean): void {
        if (!this.predictive) {
            this.showDropdown = isOpen !== undefined ? isOpen : !this.showDropdown;
        }
        if (this.predictive && isOpen !== undefined) this.showDropdown = isOpen;
    }

    /* Component focus handling */
    @HostListener('document:keyup', ['$event'])
    @HostListener('document:click', ['$event'])
    focusHandler(event: any) {
        if (this.isFocused(event)) {
            this.toggleFocus(true);
        } else {
            this.toggleFocus(false);
            this.toggleMenu(false);
        }
        this.updateVisibleItems();
    }

    toggleFocus(onFocus?: boolean): void {
        this.onFocus = onFocus !== undefined ? onFocus : !this.onFocus;
    }

    isFocused(event: any): boolean {
        const element = document.getElementById(this.id);
        return element ? element.contains(event.target) : false;
    }

    showNoResults(): boolean {
        return Object.keys(this.groupedOptions).length === 0 && this.nonGroupedOptions.length === 0 && this.searchTerm !== '';
    }

    hasGroupedOptions(): boolean {
        return Object.keys(this.groupedOptions).length > 0;
    }

    headersToLoop() {
        return Object.keys(this.groupedOptions);
    }

    /* Component keydown handling */
    @HostListener('keydown', ['$event'])
    private keydownHandler(event: any): void {
        if (event.key !== 'Tab' || event.key !== 'Shift') {
            if (this.selectedItemsElement?.nativeElement?.contains(event.target)) {
                this.selectedItemsKeyPressHandler(event);
            }
            if ((event.key === 'ArrowDown' || event.key === 'Enter') && this.inputElement.nativeElement.contains(event.target)) {
                if (!this.showDropdown) this.toggleMenu();
                setTimeout(() => {
                    const input = this.dropdownElement?.nativeElement?.firstElementChild;
                    input?.querySelector('input')?.focus();
                });
            }
        }
    }

    handleMenuKeyDown(event: any, index?: number | undefined) {
        if ((event.key === 'ArrowRight' || event.key === 'ArrowDown')) {
            event.preventDefault();
            this.goToNext(index);
        } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp')) {
            event.preventDefault();
            this.goToPrevious(index);
        } else if (event.key === 'Escape') {
            event.preventDefault();
            this.toggleMenu(false);
            this.inputElement.nativeElement.focus();
        }
    }

    private goToNext(index: number | undefined) {
        const totalGroupedOptionsLength = Object.keys(this.groupedOptions).reduce((acc, key) => acc + this.groupedOptions[key].options.length, 0);
        if (index === totalGroupedOptionsLength - 1 || index === this.nonGroupedOptions.length - 1) {
            this.dropdownElement.nativeElement.querySelector(`#sis-combobox-${this.id}-option-0 input`)?.focus();
        } else if (index !== undefined) {
            this.dropdownElement.nativeElement.querySelector(`#sis-combobox-${this.id}-option-${index + 1} input`)?.focus();
        }
    }

    private goToPrevious(index: number | undefined) {
        if (index === 0) {
            if (Object.keys(this.groupedOptions).length > 0) {
                const totalOptionsLength = Object.keys(this.groupedOptions).reduce((acc, key) => acc + this.groupedOptions[key].options.length, 0);
                this.dropdownElement.nativeElement.querySelector(`#sis-combobox-${this.id}-option-${totalOptionsLength - 1} input`)?.focus();
            } else {
                this.dropdownElement.nativeElement.querySelector(`#sis-combobox-${this.id}-option-${this.nonGroupedOptions.length - 1} input`)?.focus();
            }
        } else if (index !== undefined) {
            this.dropdownElement.nativeElement.querySelector(`#sis-combobox-${this.id}-option-${index - 1} input`)?.focus();
        }
    }

    selectedItemsKeyPressHandler(event: any) {
        if ((event.key === 'ArrowRight' || event.key === 'ArrowDown')) {
            event.preventDefault();
            if (event.target.nextElementSibling) {
                event.target.nextElementSibling.focus();
            }
        } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp')) {
            event.preventDefault();
            if (event.target.previousElementSibling) {
                event.target.previousElementSibling.focus();
            }
        } else if (event.key === 'Escape') {
            this.toggleMenu(false);
            this.inputElement.nativeElement.focus();
        }
    }
}
