import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { MaxLength } from 'common-typescript/constants';
import { EnrolmentRight } from 'common-typescript/types';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AlertsService } from '../../../alerts/alerts-ng.service';
import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { maxLength, required } from '../../../form/form-validators';
import { SisFormBuilder } from '../../../form/sis-form-builder.service';
import { EnrolmentRightEntityService } from '../../../service/enrolment-right-entity.service';
import { isCourseUnitEnrolmentRight } from '../enrolment-right.type-guards';

export interface CancelModalValues {
    enrolmentRight: EnrolmentRight;
}

@Component({
    selector: 'sis-enrolment-right-cancel-modal',
    templateUrl: './enrolment-right-cancel-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentRightCancelModalComponent implements OnInit {

    form: FormGroup;
    dateEditorMaxDate: NgbDateStruct;
    validatorMaxDate: moment.MomentInput;
    productId: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private enrolmentRightService: EnrolmentRightEntityService,
        private modal: NgbActiveModal,
        private fb: SisFormBuilder,
        private dateParserFormatter: NgbDateParserFormatter,
        @Inject(ModalService.injectionToken) public values: CancelModalValues,
    ) {}

    ngOnInit() {
        // formControl validation maxDate is required to be in specific format, so that error translation key is showing in view.
        // local-date-editor's own maxDate also uses specific date format style to disable wanted dates from datepicker.
        // That is the reason why we use two different dateformat styles here.
        const maxDate = moment().subtract(1, 'days').format('DD.MM.YYYY');
        this.validatorMaxDate = moment().format('YYYY-MM-DD');
        this.dateEditorMaxDate = this.dateParserFormatter.parse(maxDate);

        this.form = this.fb.group({
            cancelReason: new FormControl(null, [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
            cancelDate: this.fb.localDate(maxDate,
                                          {
                                              required: true,
                                              maxDate: this.validatorMaxDate,
                                          }),
        });

        if (this.values?.enrolmentRight && isCourseUnitEnrolmentRight(this.values?.enrolmentRight)) {
            const { openUniversityProductId } = this.values.enrolmentRight;
            this.productId = openUniversityProductId;
        }
    }

    onCancel(): void {
        this.modal.dismiss();
    }

    onSubmit(): void {
        if (this.form.valid) {
            const cancelDate = moment(this.cancelDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD');
            this.enrolmentRightService.cancelEnrolmentRights(this.values?.enrolmentRight?.id, this.cancelReason.value, cancelDate)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe(this.modal.close);
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
            this.form.markAsTouched();
        }
    }

    get cancelReason(): FormControl {
        return this.form.get('cancelReason') as FormControl;
    }

    get cancelDate(): FormControl {
        return this.form.get('cancelDate') as FormControl;
    }
}
