import angular from 'angular';
import * as _ from 'lodash-es';
export const plainTextFilterModule = 'sis-components.string.plainTextFilter';
(function () {
  angular.module(plainTextFilterModule, []).filter('plainText', plainText);

  /**
   * @ngInject
   */
  function plainText() {
    return function (text) {
      if (_.isString(text)) {
        // from https://stackoverflow.com/a/17315483
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
      }
      return '';
    };
  }
})();