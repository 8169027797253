import {
    AfterViewInit,
    Component,
    OnInit,
    signal,
    ViewChild,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { LanguageChangeService } from '../../l10n/language-change.service';
import { ActiveUserRoleService, UserRole } from '../../service/active-user-role.service';

interface LanguageSelection {
    language: string;
    name: LocalizedString | string;
}

@Component({
    selector: 'sis-language-selection-menu',
    templateUrl: './language-selection-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectionMenuComponent implements OnInit, AfterViewInit {

    languageSelections: LanguageSelection[] = [];

    constructor(private localeService: LocaleService,
                private languageChangeService: LanguageChangeService,
                private activeUserRoleService: ActiveUserRoleService,
    ) {}

    /** View child for the component's wrapper div */
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    private activeUserRole: UserRole | null;
    currentSiteTitle: WritableSignal<string | null> = signal(null);

    ngOnInit() {
        this.activeUserRole = this.activeUserRoleService.activeUserRole;
        this.initLanguageSelections();
    }

    ngAfterViewInit() {
        if (this.languageChangeService.languageWasChanged.getValue()) {
            const selectedLanguageButton = document.getElementById('language-selection-toggle') as HTMLElement;
            selectedLanguageButton?.focus();
            setTimeout(() => {
                this.liveAnnounceSiteTitle();
            }, 100);

        }
    }

    get currentLanguage(): string {
        return this.localeService.getCurrentLanguage();
    }

    get currentLanguageName(): string {
        return this.languageName(this.currentLanguage);
    }

    changeLanguage(language: string): void {
        this.languageChangeService.use(language);
    }

    /** Close language selection menu when next targeted element is not inside the menu */
    blurMenu(event: any) {
        if (this.dropdown?.isOpen() && !document.querySelector('sis-language-selection-menu').contains(event.relatedTarget)) {
            this.dropdown.close();
        }
    }

    liveAnnounceSiteTitle() {
        switch (this.activeUserRole) {
            case UserRole.Student:
                this.currentSiteTitle.set('PAGE_TITLE.STUDENT');
                break;
            case UserRole.Teacher:
                this.currentSiteTitle.set('PAGE_TITLE.TEACHER');
                break;
            case UserRole.Staff:
                this.currentSiteTitle.set('PAGE_TITLE.STAFF');
                break;
            default:
                this.currentSiteTitle.set('PAGE_TITLE.STUDENT');
                break;
        }
    }

    private initLanguageSelections(): void {
        this.localeService.getLocalizedLanguageNames()
            .subscribe((languageNames) => {
                this.languageSelections = this.localeService.getOfficialLanguages()
                    .map(language => ({ language, name: languageNames[language] }));

                // Languages are hardcoded in language menu since language options should not be translated
                this.languageSelections.forEach((value) => {
                    value.name = this.languageName(value.language);
                });
            });
    }

    private languageName(language: string): string {
        if (language === 'fi') return 'Suomeksi (FI)';
        if (language === 'sv') return 'På svenska (SV)';
        if (language === 'en') return 'In English (EN)';
        return '';
    }
}
