import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { MaxLength } from 'common-typescript/constants';
import { Attainment, LocalizedString, Workflow } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { maxLength, required } from '../../form/form-validators';
import { getLabelState } from '../../form/formUtils';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { InfoDialogService } from '../../info-dialog/info-dialog.service';
import { STUDENT_WORKFLOW_APPLICATION_TYPE } from '../../model/student-workflow-constants';
import { AttainedQualificationEntityService } from '../../service/attained-qualification-entity.service';
import { AttainmentEntityService } from '../../service/attainment-entity.service';
import { PrivatePersonBasicInfoEntityService } from '../../service/private-person-basic-info-entity.service';
import { QualificationEntityService } from '../../service/qualification-entity.service';
import { WorkflowEntityService } from '../../service/workflow-entity.service';

export interface AttainmentMisregistrationtModalValues {
    attainment: Attainment;
}

interface MisregistrationRationaleForm {
    misregistrationRationale: FormControl<string>
    reopenApplication?: FormControl<boolean>
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-attainment-misregistration-modal',
    templateUrl: './attainment-misregistration-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentMisregistrationModalComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.downgraded.sisAttainmentMisregistrationModal',
        directiveName: 'sisAttainmentMisregistrationModal',
    };

    attainment: Attainment;
    form: FormGroup<MisregistrationRationaleForm>;
    initializeForm$: Observable<boolean>;
    attainmentName: LocalizedString;
    attainmentCode: string;
    studentName: string;
    studentNumber: string;

    protected readonly getLabelState = getLabelState;

    constructor(
        private fb: SisFormBuilder,
        public modalInstance: NgbActiveModal,
        private attainedQualificationEntityService: AttainedQualificationEntityService,
        private qualificationEntityService: QualificationEntityService,
        private infoDialogService: InfoDialogService,
        private translate: TranslateService,
        private localeService: LocaleService,
        private attainmentEntityService: AttainmentEntityService,
        private privatePersonBasicInfoEntityService: PrivatePersonBasicInfoEntityService,
        private workflowEntityService: WorkflowEntityService,
        private appErrorHandler: AppErrorHandler,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        @Inject(ModalService.injectionToken) public values: AttainmentMisregistrationtModalValues,
    ) {}

    ngOnInit() {
        this.attainment = this.values.attainment;
        this.attainmentEntityService.getAttainmentName(this.attainment).subscribe(res => this.attainmentName = res);
        this.attainmentEntityService.getAttainmentCode(this.attainment).subscribe(res => this.attainmentCode = res);
        this.privatePersonBasicInfoEntityService.getById(this.attainment.personId).subscribe(res => {
            this.studentName = [res.firstNames, res.lastName].join(' ');
            this.studentNumber = res.studentNumber;
        });
        this.initializeForm$ = (this.attainment.workflowId ? this.workflowEntityService.getById(this.attainment.workflowId, true) : of(null))
            .pipe(
                take(1),
                tap((workflow?) => this.buildForm(workflow)),
                map(() => true),
                this.appErrorHandler.defaultErrorHandler());
    }

    buildForm(workflow?: Workflow) {
        this.form = this.fb.group<MisregistrationRationaleForm>({
            misregistrationRationale: this.fb.control(this.attainment.misregistrationRationale, [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
        });
        if (workflow) {
            if (this.reopeningApplicationAllowed(workflow)) {
                this.form.addControl('reopenApplication', this.fb.control(null, required()));
            }
        }
    }

    get misregistrationRationale(): FormControl<string> {
        return this.form?.controls?.misregistrationRationale;
    }

    get reopenApplication(): FormControl<boolean> {
        return this.form?.controls?.reopenApplication;
    }

    /**
     * Reopening application allowed only for attainments with AHOT application.
     *
     * @param workflow
     * @private
     */
    private reopeningApplicationAllowed(workflow: Workflow): boolean {
        return [
            STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_INCLUSION_APPLICATION,
            STUDENT_WORKFLOW_APPLICATION_TYPE.PRIOR_LEARNING_SUBSTITUTION_APPLICATION,
            STUDENT_WORKFLOW_APPLICATION_TYPE.CUSTOM_ATTAINMENT_APPLICATION,
        ].some(type => type === workflow?.application?.type);
    }

    ok() {
        this.attainmentEntityService.misregister(this.attainment.id, {
            misregistrationRationale: this.misregistrationRationale.value,
            reopenApplication: this.reopenApplication?.value || false,
        }).pipe(
            catchError((error) => {
                if (this.isAttainmentReferencedByQualificationError(error)) {
                    this.modalInstance.close();
                    this.openMisregistrationQualificationModal();
                    return EMPTY;
                }
                return throwError(() => error);
            }),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe(() => {
            this.modalInstance.close();
            if (this.reopenApplication?.value) {
                this.navigateToApplication();
            }
        });
    }

    private isAttainmentReferencedByQualificationError(errorResponse: any): boolean {
        return _.get(errorResponse, 'error.messageKey') === 'ERROR.BACKEND.CANNOT_MISREGISTER_IF_REFERENCED_BY_QUALIFICATION';
    }

    private getAttainedQualificationForAttainment(attainedQualifications: any) {
        return _.find(
            attainedQualifications,
            attainedQualification => _.includes(attainedQualification.attainmentIds, this.attainment.id),
        );
    }

    private getMisregistrationQualificationErrorDescription1(qualification: any) {
        return this.translate.instant(
            'PROFILE.ATTAINMENT.MISREGISTRATION_QUALIFICATION_ERROR_DESCRIPTION_1',
            {
                studyCode: this.attainmentCode,
                studyName: this.localeService.localize(this.attainmentName),
                studentName: this.studentName,
                studentNumber: this.studentNumber,
                qualificationCode: _.get(qualification, 'code'),
                qualificationName: this.localeService.localize(_.get(qualification, 'name')),
            },
        );
    }

    private openMisregistrationQualificationModal() {
        this.attainedQualificationEntityService.getByPersonId(this.attainment.personId)
            .pipe(
                map((attainedQualifications: any) => this.getAttainedQualificationForAttainment(attainedQualifications)),
                switchMap(aq => this.qualificationEntityService.getById(aq.qualificationId)),
                take(1),
                map(qualification => this.getMisregistrationQualificationErrorDescription1(qualification)),
                switchMap(
                    description => from(this.infoDialogService.open({
                        title: 'PROFILE.ATTAINMENT.MISREGISTRATION_QUALIFICATION_ERROR_TITLE',
                        descriptions: [
                            description,
                            'PROFILE.ATTAINMENT.MISREGISTRATION_QUALIFICATION_ERROR_DESCRIPTION_2',
                        ],
                    })),
                ),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe();
    }

    private navigateToApplication() {
        if (this.uiRouterGlobals.current.name.startsWith('staff.')) {
            this.stateService.go('staff.logged-in.transactions.studentApplications.studentApplication', {
                studentApplicationId: this.attainment.workflowId,
            }, { reload: true });
        } else {
            window.location.href = `/staff/studies/staff/transactions/student-applications/${this.attainment.workflowId}`;
        }
    }
}
