import * as _ from 'lodash-es';

import {
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    EntityWithRule,
    Rule,
} from '../../../../types/generated/common-backend.js';
import { RuleSortDetails } from '../../../customTypes.js';
import { PlanValidationResult } from '../planValidationResult.js';
import { ValidatablePlan } from '../validatablePlan.js';

export class ModuleContext {

    module: EntityWithRule;

    unmatchedModulesById: { [id: string]: EntityWithRule } = {};
    unmatchedCourseUnitsById: { [id: string]: CourseUnit } = {};
    unmatchedModulesByGroupId: { [groupId: string]: EntityWithRule } = {};
    unmatchedCourseUnitsByGroupId: { [groupId: string]: CourseUnit } = {};
    unmatchedCustomModuleAttainmentsById: { [id: string]: CustomModuleAttainment } = {};
    unmatchedCustomCourseUnitAttainmentsById: { [id: string]: CustomCourseUnitAttainment } = {};
    unmatchedCustomStudyDraftsById: { [localId: string]: CustomStudyDraft } = {};
    validatablePlan: ValidatablePlan;
    ruleSortDetailsMap: { [id: string]: RuleSortDetails } = {};

    // 1-based index because 0 is false
    moduleIndex = 1;
    courseUnitIndex = 1;

    constructor(module: EntityWithRule, validatablePlan: ValidatablePlan, getRuleSortDetailsMap: (rule: Rule, moduleContext: ModuleContext) => { [id: string]: RuleSortDetails }) {

        this.module = module;
        this.unmatchedModulesById = _.keyBy(validatablePlan.getSelectedModulesUnderModule(module), 'id');
        this.unmatchedCourseUnitsById = _.keyBy(validatablePlan.getSelectedCourseUnitsUnderModule(module), 'id');
        this.unmatchedModulesByGroupId = _.keyBy(validatablePlan.getSelectedModulesUnderModule(module), 'groupId');
        this.unmatchedCourseUnitsByGroupId = _.keyBy(validatablePlan.getSelectedCourseUnitsUnderModule(module), 'groupId');
        this.unmatchedCustomModuleAttainmentsById = _.keyBy(validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module), 'id');
        this.unmatchedCustomCourseUnitAttainmentsById = _.keyBy(validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module), 'id');
        this.unmatchedCustomStudyDraftsById = _.keyBy(validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id), 'id');
        this.validatablePlan = validatablePlan;
        this.ruleSortDetailsMap = getRuleSortDetailsMap(this.module.rule, this);

    }

    consumeModule(module: EntityWithRule, planValidationResult: PlanValidationResult): boolean {
        if (this.validatablePlan.isSelectedParentOfModule(this.module, module)) {
            planValidationResult.moduleIndexes[module.id] = this.moduleIndex;
            this.moduleIndex += 1;
            delete this.unmatchedModulesById[module.id];
            delete this.unmatchedModulesByGroupId[module.groupId];
            return true;
        }
        return false;
    }

    consumeCourseUnit(courseUnit: CourseUnit, planValidationResult: PlanValidationResult): boolean {
        if (this.validatablePlan.isSelectedParentOfCourseUnit(this.module, courseUnit)) {
            planValidationResult.courseUnitIndexes[courseUnit.id] = this.courseUnitIndex;
            this.courseUnitIndex += 1;
            delete this.unmatchedCourseUnitsById[courseUnit.id];
            delete this.unmatchedCourseUnitsByGroupId[courseUnit.groupId];
            return true;
        }
        return false;
    }

    consumeCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment, planValidationResult: PlanValidationResult): boolean {
        if (this.validatablePlan.isSelectedParentOfCustomModuleAttainment(this.module, customModuleAttainment)) {
            planValidationResult.customModuleAttainmentIndexes[customModuleAttainment.id] = this.moduleIndex;
            this.moduleIndex += 1;
            delete this.unmatchedCustomModuleAttainmentsById[customModuleAttainment.id];
            return true;
        }
        return false;
    }

    consumeCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment, planValidationResult: PlanValidationResult): boolean {
        if (this.validatablePlan.isSelectedParentOfCustomCourseUnitAttainment(this.module, customCourseUnitAttainment)) {
            planValidationResult.customCourseUnitAttainmentIndexes[customCourseUnitAttainment.id] = this.courseUnitIndex;
            this.courseUnitIndex += 1;
            delete this.unmatchedCustomCourseUnitAttainmentsById[customCourseUnitAttainment.id];
            return true;
        }
        return false;
    }

    consumeCustomStudyDraft(customStudyDraft: CustomStudyDraft): void {
        delete this.unmatchedCustomStudyDraftsById[customStudyDraft.id];
    }

    isEmpty(): boolean {
        return _.isEmpty(this.unmatchedModulesById) &&
            _.isEmpty(this.unmatchedCourseUnitsById) &&
            _.isEmpty(this.unmatchedCustomModuleAttainmentsById) &&
            _.isEmpty(this.unmatchedCustomCourseUnitAttainmentsById) &&
            _.isEmpty(this.unmatchedCustomStudyDraftsById);
    }

    hasUnmatchedCustomStudyDrafts(): boolean {
        return !_.isEmpty(this.unmatchedCustomStudyDraftsById);
    }

}
