<div #navigation>
  <ng-container *transloco="let t">
    <div class="sisu-navigation">
      <div class="sisu-navigation__logo-wrapper"
           [class.sisu-navigation__logo-wrapper--hidden]="focusMode()">
        <a *ngIf="homeState"
           [uiSref]="homeState"
           class="sisu-navigation__link--default"
           [attr.aria-current]="activeIndex() === -1 ? 'page' : false"
           [attr.aria-label]="t('NAVIGATION.FRONTPAGE')"
           [attr.data-cy]="'top-navigation-frontpage-link'">
          <ng-container *ngTemplateOutlet="sisuLogo"/>
        </a>
        <a *ngIf="!homeState && homeUrl"
           [href]="homeUrl"
           class="sisu-navigation__link--default"
           [attr.aria-current]="activeIndex() === -1 ? 'page' : false"
           [attr.aria-label]="t('NAVIGATION.FRONTPAGE')"
           [attr.data-cy]="'top-navigation-frontpage-link'">
          <ng-container *ngTemplateOutlet="sisuLogo"/>
        </a>
        <ng-template #sisuLogo>
          <img ngSrc="Sisu_logo_nega.svg" alt="Sisu logo" [width]="44" [height]="18"
               class="sisu-navigation__link__logo"/>
        </ng-template>
        <div [class.sisu-navigation__logo-wrapper--hidden]="isMobile()"
             class="sisu-navigation__logo-wrapper__divider"
             aria-hidden="true"></div>
      </div>
      <div *ngIf="focusMode()" class="sisu-navigation__focus-mode-navigation">
        <ng-container *ngTemplateOutlet="focusModeNavigation"/>
      </div>
      <nav #navigationLinks [class.sisu-navigation__links--hidden]="isMobile() || focusMode()"
           [attr.aria-label]="t('ARIA_LABEL.MAIN_MENU')"
           data-cy="desktop-main-navigation"
           class="sisu-navigation__links">
        <ng-container [ngTemplateOutlet]="navigationLinksTpl"
                      [ngTemplateOutletContext]="{className: '--default'}"></ng-container>
      </nav>
      <div #side
           [class.sisu-navigation__side--hidden]="isMobile()"
           data-cy="desktop-side-navigation"
           class="sisu-navigation__side">
        <div class="sisu-navigation__side__items">
          <sis-top-navigation-icon *ngIf="frontendName === 'STUDENT'"
                                   [class.sisu-navigation__side--hidden]="focusMode()"
                                   [variant]="'open-university-cart'"/>
          <sis-top-navigation-icons [class.sisu-navigation__side--hidden]="focusMode()" [frontendName]="frontendName"/>
          @switch (authenticated()) {
            <!-- authenticated() is undefined until login state has been resolved; neither option should be rendered at that point -->
            @case (true) {
              <div *ngIf="frontendName !== 'ADMIN' && !focusMode()" class="sis-sr-only" aria-live="assertive"
                   [attr.aria-label]="('ARIA_LABEL.MESSAGES' | transloco) + ', ' +  (notificationObservable | async) + ' ' + ('ARIA_LABEL.UNREAD' | transloco)"></div>
              <sis-user-settings-menu [isFocusMode]="!!focusMode()"/>
            }
            @case (false) {
              <sis-login/>
            }
          }
        </div>
      </div>
      <div *ngIf="isMobile()" data-cy="mobile-side-navigation" class="sisu-navigation__side">
        <div #mobileSide class="sisu-navigation__side__items">
          <sis-top-navigation-icon *ngIf="frontendName === 'STUDENT'" [variant]="'open-university-cart'"/>
          <button
            #mobileNavButton
            class="sisu-navigation__link sisu-navigation__link__mobile-menu-button"
            data-cy="mobile-menu-button"
            (click)="toggleMobileMenu()"
            (keydown)="onKeyboardButtonInteraction($event)"
            [attr.aria-expanded]="isMobileMenuOpen()"
            [attr.aria-label]="t('ARIA_LABEL.MAIN_MENU')">
            <fudis-icon class="sisu-navigation__link__mobile-menu-button__icon" [icon]="isMobileMenuOpen() ? 'close-big' : 'menu'" [color]="'white'"/>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isMobileMenuOpen()" [ngTemplateOutlet]="mobileNavigationTpl"></ng-container>

    <ng-template #focusModeNavigation>
      <a #exitLinkFirst
         (click)="toggleFocusOnCart()"
         *ngIf="isValidState(focusMode().exitState, focusMode().exitStateParams)"
         [uiSref]="focusMode().exitState"
         [uiParams]="focusMode().exitStateParams"
         class="sisu-navigation__link--default"
         data-cy="top-navigation-focus-mode-exit-link">
        <ng-container *ngTemplateOutlet="exitLinkContent"/>
      </a>
      <a #exitLinkSecond
         (click)="toggleFocusOnCart()"
         *ngIf="focusMode().exitUrl"
         [href]="focusMode().exitUrl"
         class="sisu-navigation__link--default"
         data-cy="top-navigation-focus-mode-exit-link">
        <ng-container *ngTemplateOutlet="exitLinkContent"/>
      </a>
      <ng-template #exitLinkContent>
        <fudis-icon icon="arrow-solid" rotate="flip-180" color="white"/>
        <span *ngIf="!isMobile()">{{ t('SIS_COMPONENTS.BUTTON.EXIT') }}</span>
        <span class="sis-sr-only">{{ t('SIS_COMPONENTS.BUTTON.EXIT') }}</span>
      </ng-template>
      <span *ngIf="!isMobile()" class="sisu-navigation__link--default sisu-navigation__link--default--active"
            aria-current="page">
          {{ t(focusMode().titleKey) }}
   </span>
    </ng-template>

    <ng-template #navigationLinksTpl let-className="className">
      @let linkClass = 'sisu-navigation__link' + className ;
      <ol [ngClass]="'sisu-navigation__links__list sisu-navigation__links__list' + className">
        <li *ngFor="let item of mainNavigationItems; index as index"
            [class]="'sisu-navigation__links__list__item' + className + ' sisu-navigation__links__list__item ' + linkClass">
          <a *ngIf="isValidState(item.toState); else externalNavLink"
             [class]="index === activeIndex() ? linkClass + '--active ' + linkClass : linkClass"
             [uiSref]="isValidState(item.toState) ? item.toState : null"
             [uiParams]="isValidState(item.toState) ? item.toParams: null"
             [attr.aria-current]="index === activeIndex() ? 'page' : false"
             [attr.data-cy]="item.linkId"
             [id]="item.linkId"
             (keydown)="onKeyboardButtonInteraction($event)">
            <ng-container *ngTemplateOutlet="navLinkContent"/>
          </a>
          <ng-template #externalNavLink>
            <a [class]="index === activeIndex() ? linkClass + '--active ' + linkClass : linkClass"
               [href]="item.toUrl"
               [attr.aria-current]="index === activeIndex() ? 'page' : false"
               [attr.data-cy]="item.linkId"
               [id]="item.linkId"
               (keydown)="onKeyboardButtonInteraction($event)">
              <ng-container *ngTemplateOutlet="navLinkContent"/>
            </a>
          </ng-template>
          <ng-template #navLinkContent>
            {{ t(item.translationKey) }}
            <fudis-icon *ngIf="index === activeIndex() && isMobile()" class="d-block float-end sisu-navigation__links__list__item--mobile__pointer"
                        [icon]="'check'"
                        [color]="'primary'"/>
          </ng-template>
        </li>
      </ol>
    </ng-template>

    <ng-template #mobileNavigationTpl>
      <div #mobileMenu data-cy="mobile-main-navigation" class="sisu-navigation__mobile-menu">
        <div *ngIf="displayName && !focusMode()" class="sisu-navigation__mobile-menu__user" id="user-info">
          <p class="sisu-navigation__mobile-menu__user-name">{{ displayName }}</p>
          <span class="d-block px-2" aria-hidden="true">|</span>
          <p class="sisu-navigation__mobile-menu__user-role"
             data-cy="user-name-role">{{ activeRoleNameKey | translate }}</p>
        </div>
        <nav *ngIf="!focusMode()">
          <ng-container [ngTemplateOutlet]="navigationLinksTpl"
                        [ngTemplateOutletContext]="{className: '--mobile'}"></ng-container>
        </nav>
        <sis-top-navigation-icons *ngIf="!focusMode()" [mobileMode]="true"
                                  [navCollapsed]="true"
                                  [frontendName]="frontendName"
                                  (keydownPressed)="onKeyboardButtonInteraction($event)"/>
        <div role="menu">
          <sis-university-menu (keydownPressed)="onKeyboardButtonInteraction($event)" *ngIf="!focusMode() && authenticated()"
                               [mobileMode]="true"/>
          <sis-user-role-menu (keydownPressed)="onKeyboardButtonInteraction($event)" *ngIf="!focusMode() && authenticated()"
                              [mobileMode]="true"/>
          <sis-help-and-feedback-menu (keydownPressed)="onKeyboardButtonInteraction($event)" [mobileMode]="true"/>
          <sis-logout (keydownPressed)="onKeyboardButtonInteraction($event)" *ngIf="authenticated()"
                      [mobileMode]="true"/>
          <sis-login (keydownPressed)="onKeyboardButtonInteraction($event)" *ngIf="!authenticated()"
                     [mobileMode]="true"/>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
