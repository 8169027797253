import * as _ from 'lodash-es';
import { asyncScheduler, Observable } from 'rxjs';
import { exhaustMap, filter, map, throttleTime } from 'rxjs/operators';

/**
 * Only single concurrent search allowed. Ignores new searches if previous has not been completed.
 *
 * @param search function which takes in search parameters
 * @param throttle time in milliseconds, defaults to 300
 */
export function singleConcurrentSearchWithThrottle<P, R>(search: (params: P) => Observable<R>, throttle?: number) {
    return (source: Observable<P>) => source
        .pipe(
            filter(searchParameters => searchParameters !== null),
            throttleTime(throttle ? throttle : 300, asyncScheduler, { leading: true, trailing: true }),
            map(_.cloneDeep),
            exhaustMap(searchParameters => search(searchParameters)),
        );
}
