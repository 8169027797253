import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

/**
 * A search filter component which allows filtering course units to those that be completed by either:
 * - only completing an exam
 * - only doing independent work
 *
 * The component will set the matching assessment item type into the search filters under the key 'assessmentItemType'.
 *
 *  Requires that some parent component provides a {@link SearchService} instance of type T extends CourseUnitSearchFilters.
 *
 */
@Component({
    selector: 'app-search-filter-assessment-item-type',
    templateUrl: './search-filter-assessment-item-type.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterAssessmentItemTypeComponent {}
