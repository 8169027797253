<ng-container *transloco="let t">
  <div class="sis-px-md px-md-0 sis-pt-md" aria-labelledby="student-profile-basic-info-tab" id="basic-info" role="tabpanel" tabindex="0">
    <fudis-section [level]="2" [titleVariant]="'lg'" [title]="t('PERSONAL_INFORMATION')">
      <fudis-section-actions></fudis-section-actions>
      <fudis-section-content>
        <fudis-grid [classes]="'fudis-mt-md'" [columns]="{ sm: 1, md: 2 }">
          <fudis-grid-item>
            <fudis-section
                    [classes]="'fudis-mt-xs'"
                    [title]="t('PROFILE.NAME_AND_OTHER_PERSONAL_INFO')"
                    [titleVariant]="'sm'"
                    [level]="3">
              <fudis-section-content>
                <app-basic-info-personal-info [person]="studentProfileCopy"/>
                <fudis-button
                        class="fudis-mt-md"
                        [variant]="'secondary'"
                        [size]="'medium'"
                        [label]="t('FIELD_NAMES.personDataChange')"
                        (handleClick)="openPersonalInfoHistoryDialog()"
                />
                <app-basic-info-personal-info-details [person]="studentProfileCopy" [(classifiedVisibility)]="classifiedVisibility"/>
                <sis-passport-info-view [studentId]="studentProfile.id"/>
              </fudis-section-content>
            </fudis-section>
          </fudis-grid-item>
          <fudis-grid-item>
            <app-contact-information
                    [studentProfile]="studentProfileCopy"
                    [isEditEnabledByUniversity]="isEditEnabledByUniversity"
                    (updatedStudentChange)="studentProfileCopy = $event"
            >
            </app-contact-information>

            <sis-disclosure-authorizations [person]="studentProfileCopy"
                                           [editDisabled]="!isEditEnabledByUniversity">
            </sis-disclosure-authorizations>
            <div class="student-personal-data-safety-nondisclosure">
              <fudis-heading [level]="3" [variant]="'sm'">{{ t('FIELD_NAMES.personalDataSafetyNonDisclosure') }}</fudis-heading>
              <fudis-body-text [variant]="'lg-light'">
                <ng-container *ngIf="studentProfileCopy?.personalDataSafetyNonDisclosure">
                  {{ t('PROFILE.PERSONAL_DATA_SAFETY_NON_DISCLOSURE') }}
                </ng-container>
                <ng-container *ngIf="!studentProfileCopy?.personalDataSafetyNonDisclosure">
                  {{ t('PROFILE.NO_PERSONAL_DATA_SAFETY_NON_DISCLOSURE') }}
                </ng-container>
              </fudis-body-text>
            </div>
          </fudis-grid-item>
        </fudis-grid>
      </fudis-section-content>
    </fudis-section>
  </div>
</ng-container>
