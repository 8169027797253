import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OtmId, SearchParameters } from 'common-typescript/types';
import { isEqual, omit } from 'lodash-es';
import {
    distinctUntilChanged,
    finalize,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { silentErrorHandler } from 'sis-components/error-handler/silent-error-handler';
import { SearchService } from 'sis-components/search-ng/search.service';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';

import { CourseCartEntityService } from '../../common/service/course-cart-entity.service';
import { CourseUnitSearchFilters } from '../search.types';
import { getStudyPeriodInfoLoader } from '../search.utils';

const RESULTS_PER_PAGE = 20;

@Component({
    selector: 'app-search-main',
    templateUrl: './search-main.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: SearchService,
            useFactory: () => new SearchService<CourseUnitSearchFilters>({
                storageKey: 'searchParameters.student.search.main',
                universityOrgIdEnabled: true,
                defaultOptions: {
                    start: 0,
                    limit: RESULTS_PER_PAGE,
                },
            }),
        },
    ],
})
export class SearchMainComponent implements OnInit, OnDestroy {

    currentPage = 1;

    courseCartCourseUnitIds: OtmId[] = [];

    destroyed$ = new Subject<void>();

    /**
     * Indicates whether a search request is pending. Used to clear the aria live region for screen readers
     * during request execution, as otherwise the screen reader would not announce the search result info if
     * its contents didn't change (e.g. new search produces the same amount of results as the previous one).
     */
    searching = false;

    searchSortOptions: Option[] = [
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.MATCH'), value: null },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_ASC'), value: 'name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_DESC'), value: '-name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_ASC'), value: 'credits' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_DESC'), value: '-credits' },
    ];

    private readonly validParameters$ = new ReplaySubject<SearchParameters<CourseUnitSearchFilters>>();
    readonly searchResult$ = this.validParameters$
        .pipe(
            singleConcurrentSearchWithThrottle(parameters => this.executeSearch(parameters)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

    readonly curriculumPeriodIds$: Observable<OtmId[]> = this.searchService.searchParameters$
        .pipe(
            map(params => params.filters?.curriculumPeriodId ?? []),
            map(ids => (Array.isArray(ids) ? ids : [ids]).filter(Boolean)),
            distinctUntilChanged(isEqual),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

    readonly curriculumPeriods$ = this.searchService.universityOrgId$
        .pipe(
            switchMap(id => this.curriculumPeriodService.getByUniversityOrgId(id)
                .pipe(catchError(() => of([])))),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

    readonly studyPeriodInfos$ = getStudyPeriodInfoLoader(this.searchService.universityOrgId$);

    constructor(
        private authService: AuthService,
        private courseCartEntityService: CourseCartEntityService,
        private translate: TranslateService,
        private appErrorHandler: AppErrorHandler,
        private curriculumPeriodService: CurriculumPeriodEntityService,
        private courseUnitService: CourseUnitEntityService,
        protected searchService: SearchService<CourseUnitSearchFilters>,
    ) {
    }

    ngOnInit(): void {
        if (this.isLoggedIn()) {
            this.courseCartEntityService.getCourseCart(true)
                .pipe(
                    takeUntil(this.destroyed$),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((ids) => this.courseCartCourseUnitIds = ids);
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    onSearchParameterChange(parameters: SearchParameters<CourseUnitSearchFilters>) {
        this.validParameters$.next(parameters);
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

    sort(sortValue: string) {
        this.searchService.sort(sortValue);
    }

    addCourseUnitToCourseCart(courseUnitId: string) {
        this.courseCartEntityService.addCurToCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    removeCourseUnitFromCourseCart(courseUnitId: string) {
        this.courseCartEntityService.deleteCurFromCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    onPaginationChange(newPage: number) {
        this.currentPage = newPage;
        this.searchService.patchOptions({ start: (newPage - 1) * RESULTS_PER_PAGE });
        document.getElementById('results-show-guide')?.focus();
    }

    get resultsPerPage() {
        return RESULTS_PER_PAGE;
    }

    private executeSearch({ filters, options }: SearchParameters<CourseUnitSearchFilters>) {
        this.searching = true;
        return this.courseUnitService.searchActive({
            ...options,
            ...(omit(filters, ['attainmentLanguage', 'studyLevel', 'teachingLanguage'])),
            assessmentItemType: filters.assessmentItemType,
            codeUrn: [...(filters.attainmentLanguage ?? []), ...(filters.studyLevel ?? [])],
            curCodeUrn: filters.teachingLanguage,
            sort: options.sort?.join(','),
            validity: 'ONGOING_AND_FUTURE',
        })
            .pipe(
                tap(results => this.currentPage = Math.floor((results?.start ?? 0) / RESULTS_PER_PAGE) + 1),
                finalize(() => this.searching = false),
                silentErrorHandler,
            );
    }
}
