<div class="table-responsive">
  <table class="table styled-table">
      <caption *ngIf="caption">{{ caption }}</caption>
    <thead>
    <tr>
      <th>{{'FIELD_NAMES.code' | translate}}</th>
      <th>
        {{'PROFILE.PAYMENTS.TABLE.PAYMENT_CATEGORY_DESCRIPTION' | translate}}
        <fudis-language-badge-group [translatedLanguages]="providedLanguages" (handleClick)="changeLanguageBadgeContent($event)"></fudis-language-badge-group>
      </th>
      <th>{{'PROFILE.PAYMENTS.TABLE.STUDY_TERM' | translate}}</th>
      <th>{{'PROFILE.PAYMENTS.TABLE.PAYMENT_AMOUNT' | translate}}</th>
      <th>{{'PROFILE.PAYMENTS.TABLE.PAYMENT_DATE' | translate}}</th>
      <th>{{'PROFILE.PAYMENTS.TABLE.REGISTRATION_DATE' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let payment of payments">
      <ng-container *ngIf="payment.paymentCategoryId | paymentCategory | async as category">
        <td>{{category.code}}</td>
        <td [attr.lang]="selectedDescriptionLanguage">{{getDescriptionWithSelectedLanguage(category)}}</td>
        <td>{{getStudyTermNameTranslationKey(payment) | translate}}</td>
        <td>{{payment.paymentAmount | currency:null:''}}</td>
        <td>{{payment.paymentDate | localDateFormat}}</td>
        <td>{{payment.registrationDate | localDateFormat}}</td>
        <td class="info-icon">
          <sis-tooltip *ngIf="payment.invalidated"
                       [tooltipText]="'PROFILE.PAYMENTS.TABLE.INVALIDATION_RATIONALE' | translate: {invalidationRationale: payment.invalidationRationale}"
                       [tooltipBlockElement]="true"
                       tooltipClass="tooltip-wide"
                       [attr.aria-label]="'PROFILE.PAYMENTS.TABLE.INVALIDATION_RATIONALE' | translate: {invalidationRationale: payment.invalidationRationale}">
            <sis-icon icon="info-circle" color="primary" class="sis-tooltip-trigger-content"></sis-icon>
          </sis-tooltip>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
</div>
