import { Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { EntityWithRule, Module, ModuleAttainment, ModuleRule, OtmId, Rule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COMMON_MODULE_SERVICE,
} from '../../../ajs-upgraded-modules';
import { PlanRuleSelectionService } from '../../../service/plan-rule-selection.service';
import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { StudySelectorService } from '../../../service/study-selector.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../../plan-actions-service/plan-actions.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-module-rule',
    templateUrl: './module-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class ModuleRuleComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.moduleRule',
        directiveName: 'sisModuleRule',
    };

    moduleRule: ModuleRule;
    module: Module;

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    constructor(
        private studySelectorService: StudySelectorService,
        protected planRuleSelectionService: PlanRuleSelectionService,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
        @Inject(COMMON_MODULE_SERVICE) private commonModuleService: any,
    ) {}

    ngOnInit() {
        this.moduleRule = this.rule as ModuleRule;
        this.module = _.find(_.values(this.planData.modulesById), { groupId: this.moduleRule.moduleGroupId });
        if (!this.module) {
            const moduleAttainment = this.validatablePlan.getModuleAttainmentByGroupId(this.moduleRule.moduleGroupId);
            if (moduleAttainment) {
                this.module = _.get(this.validatablePlan.modulesById, (moduleAttainment as ModuleAttainment).moduleId) as Module;
            } else {
                this.module = this.validatablePlan.getModuleInPlanByGroupId(this.moduleRule.moduleGroupId) as Module;
            }
        }
        if (!this.module) {
            const parentCurriculumPeriodIds = this.validatablePlan.getCurriculumPeriodIdsRecursively(this.parentModule);
            const planCurriculumPeriodId = this.validatablePlan.plan.curriculumPeriodId;
            this.commonModuleService.getDataForStudySelector(this.moduleRule.moduleGroupId).then((studySelectorData: any) => {
                this.module = this.studySelectorService.selectStudyOfGroup(
                    studySelectorData.allCourseUnitVersions,
                    parentCurriculumPeriodIds,
                    planCurriculumPeriodId,
                    studySelectorData.allCurriculumPeriods,
                ) as Module;
            });

        }
    }

    toggle(event: Event) {
        event.preventDefault();
        if (!this.planRuleSelectionService.isModuleSelectionDisabled(this.module, this.parentModule, this.validatablePlan)) {
            if (this.isSelectedModule(this.module.id)) {
                this.planActionsService?.removeModule?.(this.module, this.parentModule);
            } else {
                this.planActionsService?.selectModule?.(this.module, this.parentModule);
            }
        }
    }

    isSelectedModule(moduleId: OtmId) {
        const moduleStateObject = _.get(this.planStateObject.modules, moduleId);
        return moduleStateObject && moduleStateObject.selectedParentModuleId === this.parentModule.id;
    }
}
