import angular from 'angular';
import * as _ from 'lodash-es';
import creditRangeEditorTpl from './creditRangeEditor.tpl.html';
export const creditRangeEditorModule = 'sis-components.number.creditRangeEditor';

/**
 @Deprecated
 upgraded to NumberRangeEditorComponent in lib/number/number-range-editor/number-range-editor.component.ts
 */
(function () {
  angular.module(creditRangeEditorModule, ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter', 'sis-components.number.decimalInput']).directive('creditRangeEditor', creditRangeEditorDirective).controller('creditRangeEditorController', creditRangeEditorController);
  /**
   * @ngInject
   */
  function creditRangeEditorDirective() {
    return {
      restrict: 'E',
      require: ['^^form', 'ngModel'],
      scope: {
        name: '@',
        minMin: '@',
        minMax: '@',
        maxMin: '@',
        maxMax: '@',
        maxRequired: '@',
        minRequired: '@'
      },
      template: creditRangeEditorTpl,
      controller: 'creditRangeEditorController as ctrl',
      bindToController: true,
      link(scope, element, attrs, ctrls) {
        const {
          ctrl
        } = scope;
        [ctrl.form, ctrl.ngModel] = ctrls;
        function hasValues(value) {
          return _.filter(_.values(value), _.isNumber).length > 0;
        }

        // remove empties from model values. if no values, then the model is null.
        ctrl.ngModel.$parsers.push(value => {
          if (hasValues(value)) {
            return _.omitBy(value, _.isNil);
          }
          return null;
        });

        // if model value is null, fill view model with empty object
        ctrl.ngModel.$formatters.push(value => {
          if (_.isNil(value)) {
            return {
              min: null,
              max: null
            };
          }
          return value;
        });
        ctrl.ngModel.$validators.minLowerThanOrEqualToMax = function (modelValue) {
          return !modelValue || !_.isNumber(modelValue.min) || !_.isNumber(modelValue.max) || modelValue.min <= modelValue.max;
        };
      }
    };
  }
  function creditRangeEditorController() {
    const ctrl = this;

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    ctrl.onChange = function () {
      if (_.isFunction(_.get(ctrl.ngModel, '$setViewValue'))) {
        ctrl.ngModel.$setViewValue(angular.copy(ctrl.ngModel.$viewValue));
      }
    };
  }
})();