<div class="read-more" [ngClass]="{truncated: this.truncated}">
  <div class="visible-content" [ngStyle]="{'max-height.px': this.contentMaxHeight()}">
    <div class="full-content">
      <sis-icon icon="info-circle" color="white"></sis-icon>
      <div>
        <sis-localized-markup-string-view *ngIf="contentHtml" [content]="contentHtml" />
        <ng-container *ngIf="contentTranslateKey">
          <transloco [key]="contentTranslateKey" [params]="contentTranslateParameters"></transloco>
        </ng-container>
      </div>
    </div>
  </div>
  <sis-button *ngIf="this.truncated" [link]="true" (clicked)="this.openReadMore()" class="link-button">
    <ng-container *transloco="let t">
      {{ t('SIS_COMPONENTS.READ_MORE.READ_MORE') }}...
    </ng-container>
  </sis-button>
</div>
