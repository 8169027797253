import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UniversitySettings } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { UniversityService } from './../service/university.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-university-iframe',
    templateUrl: './university-iframe.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversityIframeComponent implements AfterContentInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.university-iframe.downgraded',
        directiveName: 'sisUniversityIframe',
    };

    childOrigin: string = '*';
    contentUrl: string = '';

    isClosed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    universitySettings$: Observable<UniversitySettings>;

    @ViewChild('iframeElement') iframeElement: ElementRef<HTMLIFrameElement>;
    @ViewChild('dialogElement') dialogElement: ElementRef<HTMLElement>;
    @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;

    constructor(private localeService: LocaleService,
                private universityService: UniversityService) {
    }

    ngAfterContentInit() {
        this.universitySettings$ = this.updateContentUrl();
    }

    toggle = () => {
        if (this.isClosed$.value) {
            this.openIframe();
        } else {
            this.closeIframe();
        }
    };

    openIframe = () => {
        this.isClosed$.next(false);

        window.addEventListener('message', this.receiveMessage);
        window.addEventListener('keyup', this.escHandler);

        this.iframeElement.nativeElement.src = this.contentUrl;
        this.iframeElement.nativeElement.onload = () => {
            this.iframeElement.nativeElement.contentWindow.postMessage('open', '*');
        };
    };

    closeIframe = () => {
        this.isClosed$.next(true);

        window.removeEventListener('keyup', this.escHandler);
        window.removeEventListener('message', this.receiveMessage);

        this.iframeElement.nativeElement.classList.remove('opened');
        this.iframeElement.nativeElement.classList.add('closed');
        this.moveFocusToElement('open-university-iframe-button');
    };

    visualizeIFrameOpening() {
        this.iframeElement.nativeElement.classList.remove('closed');
        this.iframeElement.nativeElement.classList.add('opened');
        this.moveFocusToElement('close-university-iframe-button');
    }

    receiveMessage = (message: any) => {
        const origin = message.origin || message.originalEvent.origin;
        if (this.extractDomain(origin) !== this.extractDomain(this.childOrigin)) {
            return;
        }

        if (message.data === 'opened') this.visualizeIFrameOpening();
        if (message.data === 'close') this.closeIframe();

        // if height message received, set height
        if (typeof message.data === 'string' && message.data.indexOf('height-', 0) === 0) {
            const newHeight = message.data.substring((message.data.indexOf('-')) + 1);
            this.iframeElement.nativeElement.style.height = `${newHeight}px`;
            this.closeButton.nativeElement.style.top = `${Number(newHeight) + 50}px`;
        }
    };

    updateContentUrl = () => this.universityService.getCurrentUniversitySettings().pipe(
        take(1),
        tap((settings) => {
            const lang = this.localeService.getCurrentLanguage();
            this.contentUrl = _.get(settings, `universityNaviContentUrl.${lang}`, '');
            this.childOrigin = this.getOrigin();
        }));

    getOrigin() {
        const re = /^http[s]?[:]?\/\/[^\/]*/;
        const fromContentUrl = re.exec(this.contentUrl);
        if (fromContentUrl && fromContentUrl.length === 1) {
            return fromContentUrl[0];
        }
        const { protocol, port, host } = window.location;
        // For local dev env we have a relative url for iframe src, so we use the current location for origin.
        return `${protocol}://${host}:${port}`;
    }

    getUniversityName = () => this.universityService.getCurrentUniversity().name;

    extractDomain = (url: string) => {
        let domain;
        // find & remove protocol (http, ftp, etc.) and get domain
        if (url.indexOf('://') > -1) {
            domain = url.split('/')[2];
        } else {
            domain = url.split('/')[0];
        }
        // find & remove port number
        domain = domain.split(':')[0];
        return domain;
    };

    escHandler = (event: any) => {
        if (event.keyCode === 27) this.closeIframe();
    };

    moveFocusToElement(location: string) {
        const targetElement = document.getElementById(location);
        if (targetElement) targetElement.focus();
    }
}
