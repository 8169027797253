import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CompositeRule, CourseUnitCountRule, CreditRange, DecimalNumberRange, Education, Module, RangeValidationResultState, Rule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-course-unit-count-phrase',
    templateUrl: './course-unit-count-phrase.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CourseUnitCountPhraseComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.header.courseUnitCountPhrase',
        directiveName: 'sisCourseUnitCountPhrase',
    };

    isOk: boolean;
    implicitCount: number;
    directCount: number;
    selected: number;
    tooFewSelected: boolean;
    tooManySelected: boolean;
    addRange: DecimalNumberRange;
    removeRange: DecimalNumberRange;
    targetRange: DecimalNumberRange;
    require: DecimalNumberRange;
    courseUnitCountRule: CourseUnitCountRule;

    @Input() ruleValidationResult: any;
    @Input() rule: Rule;

    ngOnChanges() {
        this.update();
    }

    update(): void {
        this.courseUnitCountRule = this.rule as CourseUnitCountRule;
        this.isOk = this.ruleValidationResult.result === RangeValidationResultState.OK;
        this.require = this.courseUnitCountRule.count;
        this.selected = _.get(this.ruleValidationResult, 'directCount');
        this.tooFewSelected = this.selected < this.courseUnitCountRule.count.min;
        this.tooManySelected = !!this.courseUnitCountRule.count.max && this.selected > this.courseUnitCountRule.count.max;
        if (this.tooFewSelected) {
            this.addRange = this.getAddRange();
        }
        if (this.tooManySelected) {
            this.removeRange = this.getRemoveRange();
        }
        this.targetRange = this.getTargetRange();
    }

    getAddRange(): DecimalNumberRange {

        return {
            min: this.ruleValidationResult.minRequired,
            max: this.ruleValidationResult.maxAllowed,
        };
    }

    getRemoveRange(): DecimalNumberRange {
        return {
            min: this.selected - this.courseUnitCountRule.count.max,
            max: this.selected - this.courseUnitCountRule.count.min,
        };
    }

    getTargetRange(): DecimalNumberRange {
        if (this.ruleValidationResult) {
            return {
                min: _.get(this.ruleValidationResult, 'minRequired'),
                max: _.get(this.ruleValidationResult, 'maxAllowed'),
            } as DecimalNumberRange;
        }
        return null;
    }

}
