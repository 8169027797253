<div ngbDropdown class="user-settings-menu" #dropdown=ngbDropdown>
  <button type="button"
          (keyup)="onKeyboardButtonInteraction($event)"
          [attr.aria-haspopup]="'menu'"
          [attr.aria-controls]="'sis-user-settings-menu'"
          ngbDropdownToggle
          class="non-button user-settings-menu__toggle"
          data-cy="user-settings-menu"
          aria-labelledby="user-info">
    <div id="user-info">
      <div class="user-settings-menu__toggle__name">{{displayName}}</div>
      <div class="user-settings-menu__toggle__role" data-cy="user-name-role">{{activeRoleNameKey | transloco }}</div>
    </div>
    <fudis-icon [icon]="'chevron'" [color]="'white'" [rotate]="'cw-90'"/>
  </button>
  <div ngbDropdownMenu id="sis-user-settings-menu" role="menu" class="dropdown-menu-right" data-cy="user-settings-menu">
    <sis-university-menu *ngIf="!isFocusMode" (keydownPressed)="onKeyboardButtonInteraction($event)"/>
    <sis-user-role-menu *ngIf="!isFocusMode" (keydownPressed)="onKeyboardButtonInteraction($event)"/>
    <sis-help-and-feedback-menu (keydownPressed)="onKeyboardButtonInteraction($event)" [navCollapsed]="isFocusMode" />
    <sis-logout (blurLogout)="blurMenu($event)" (keydownPressed)="onKeyboardButtonInteraction($event)"/>
  </div>
</div>
