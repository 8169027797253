import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    CourseUnitRealisation,
    Enrolment,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';

import { StudyGroupSetFormGroup, StudySubGroupFormGroup } from '../enrolment-wizard.component';
import { isLateEnrolment, StudyGroupSetInfo } from '../enrolmentWizardUtils';

@Component({
    selector: 'app-study-group-container',
    templateUrl: './study-group-container.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyGroupContainerComponent {
    @Input() form: FormGroup<StudyGroupSetFormGroup>;
    @Input() enrolment: Enrolment;
    @Input() studyGroupSetInfo: StudyGroupSetInfo;
    @Input() showErrors?: boolean = false;
    @Input() isConfirmedSsgEdit?: boolean = false;

    get targetStudySubGroupAmount(): FormControl<number> {
        return this.form?.controls?.targetStudySubGroupAmount as FormControl<number>;
    }

    get studySubGroups(): FormGroup<StudySubGroupFormGroup> {
        return this.form?.controls?.studySubGroups as FormGroup<StudySubGroupFormGroup>;
    }

    getStudySubGroup(ssgId: OtmId): FormGroup {
        return this.studySubGroups?.get(ssgId) as FormGroup;
    }

    isInCalendar = (id: OtmId) => !!_.find(this.enrolment.studySubGroups, { studySubGroupId: id, isInCalendar: true });

    showAdditionalInfo(courseUnitRealisation: CourseUnitRealisation): boolean {
        return courseUnitRealisation?.continuousEnrolment || isLateEnrolment(courseUnitRealisation);
    }
}
