<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form
        [title]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.ADD_CUSTOM_STUDY_DRAFT_TITLE')"
        [level]="2"
        [helpText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CUSTOM_STUDY_DRAFT_GUIDANCE')"
        [errorSummaryTitle]="t('ERROR-SUMMARY.HELP_TEXT')">
        <fudis-form-actions>
          <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" [variant]="'secondary'" />
          <fudis-button fudisFormSubmit [formValid]="customStudyDraftForm.valid" [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.BUTTON_ADD')" (handleClick)="addStudyDraft()" />
        </fudis-form-actions>
        <fudis-form-content>
          <fudis-grid [columns]="1" [rowGap]="'sm'">
            <fudis-text-input
              [id]="'name'"
              [helpText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.NAME_GUIDANCE')"
              [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.NAME')"
              [control]="customStudyDraftForm.controls.name"
            />
            <fudis-text-input
              [id]="'credits'"
              [helpText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CREDITS_GUIDANCE')"
              [size]="'sm'"
              [type]="'number'"
              [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.CREDITS') + ' (' + t('CREDITS') + ')'"
              [control]="customStudyDraftForm.controls.credits"
            />
            <fudis-text-input
              [id]="'location'"
              [helpText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.LOCATION_GUIDANCE')"
              [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.LOCATION')"
              [control]="customStudyDraftForm.controls.location"
            />
            <fudis-text-area
              [id]="'description'"
              [helpText]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION_GUIDANCE')"
              [label]="t('STUDENT_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION')"
              [control]="customStudyDraftForm.controls.description"
            />
          </fudis-grid>
        </fudis-form-content>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
