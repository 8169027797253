import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input, OnInit, output,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { TranslocoDirective } from '@ngneat/transloco';
import { Event, LocalDateTimeString, StudyEvent, StudySubGroup } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { STUDY_EVENT_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import {
    CalendarCurStudySubGroupStudyEventSummaryComponent,
} from '../calendar-cur-study-sub-group-study-event-summary/calendar-cur-study-sub-group-study-event-summary.component';

/**
 * Do note that this component still depends on (global) styles from 'course-unit-realisation.scss'.
 * Be careful when altering the html structure as it might break the styling.
 */
@Component({
    selector: 'app-calendar-cur-study-sub-group-event-info',
    templateUrl: './calendar-cur-study-sub-group-event-info.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoDirective,
        SisComponentsModule,
        SisCommonModule,
        CalendarCurStudySubGroupStudyEventSummaryComponent,
        NgxFudisModule,
    ],
})
export class CalendarCurStudySubGroupEventInfoComponent implements OnInit {
    commonStudyEventService = inject(STUDY_EVENT_SERVICE);
    localeService = inject(LocaleService);

    studySubGroup = input<StudySubGroup>();
    exceptionsVisible = input<boolean>();
    studyEvents = input<StudyEvent[]>();
    onViewInCalendar = output<string>();

    showExceptions = signal(false);

    irregularEvents = computed<Event[]>(() => this.commonStudyEventService.getIrregularEventsForStudent(this.studyEvents()));
    orderedStudyEvents = computed<StudyEvent[]>(() => this.commonStudyEventService.orderStudyEventsByTime(this.studyEvents()));

    ngOnInit(): void {
        this.showExceptions.set(this.exceptionsVisible());
    }

    getEventWeekDayAndDate(dateTime: LocalDateTimeString) {
        return `${moment(dateTime).locale(this.localeService.getCurrentLanguage()).format('ddd')} ${moment(dateTime).format('D.M.')}`;
    }

    getEventTimeRange(event: Event) {
        return _.join([moment(event.start).format('H.mm'), moment(event.end).format('H.mm')], '–');
    }

}
