import {
    ChangeDetectionStrategy,
    Component, Inject,
    Input, OnChanges, SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult } from 'common-typescript';
import { Education } from 'common-typescript/types';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { EducationStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';

@Component({
    selector: 'sis-plan-structure-education',
    templateUrl: './plan-structure-education.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureEducationComponent implements OnChanges {

    educationStateObject: EducationStateObject;

    @Input({ required: true }) education: Education;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;

    headingLevel: number = 2;

    constructor(@Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {}

    ngOnChanges(changes: SimpleChanges) {
        this.educationStateObject = this.planStateObject?.education;
    }

    openModule() {
        this.planActionsService.openModule(this.education);
    }
}
