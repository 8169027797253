import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

@Pipe({
    name: 'plainText',
})
export class PlainTextPipe implements PipeTransform {

    transform(text: any, args?: any): any {
        if (_.isString(text)) {
            // from https://stackoverflow.com/a/17315483
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        }
        return '';
    }

}
