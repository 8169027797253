import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-localized-markup-string',
    templateUrl: './localized-markup-string.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LocalizedMarkupStringComponent implements OnInit, OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.localized-markup-string.downgraded',
        directiveName: 'sisLocalizedMarkupString',
    };

    @Input() label?: string;
    @Input() value: LocalizedString;

    invalidLocales: string[];
    locales: string[];
    selectedLocale: string;

    constructor(
        private localeService: LocaleService,
    ) {
        this.locales = this.localeService.getSupportedLanguages();
        this.selectedLocale = this.localeService.getCurrentLanguage();
    }

    ngOnInit(): void {
        this.invalidLocales = this.locales.filter(locale => this.noContent(locale));
    }

    ngOnChanges(): void {
        this.invalidLocales = this.locales.filter(locale => this.noContent(locale));
    }

    selectLocale(locale: string): void {
        this.selectedLocale = locale;
    }

    private noContent(locale: string) {
        if (_.isNil(this.value)) {
            return true;
        }
        return (_.isNil(this.value[locale]) || _.isEmpty(this.value[locale]));
    }
}
