import angular from 'angular';
import angularTranslate from 'angular-translate';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { localDateRangeFilterModule } from 'sis-components/date/filters/localDateRange/localDateRange.filter';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { commonModuleServiceModule } from 'sis-components/service/module.service';
import { commonPlanSelectionServiceModule } from 'sis-components/service/planSelection.service';
import planStructureTpl from './planStructure.tpl.html';
(function () {
  PlanStructureController.$inject = ["$q", "$translate", "localeService", "localDateRangeFilter", "commonEducationService", "defaultPromiseHandler", "commonStudentApplicationService", "commonStudyRightService", "planStudyRightService", "planValidationResultHelper", "commonModuleService"];
  angular.module('sis-components.plan.planStructure', ['sis-components.service.educationService', 'sis-components.plan.planModule', 'sis-common.errorhandler.errorService', 'sis-components.attainment.tree', 'sis-components.service.studentApplicationService', 'sis-components.service.studyRightService', 'sis-components.service.planStudyRightService', IconComponent.downgrade.moduleName, angularTranslate, commonModuleServiceModule, localDateRangeFilterModule, localeServiceModule, planValidationResultHelperModule, commonPlanSelectionServiceModule]).component('planStructure', {
    template: planStructureTpl,
    controller: PlanStructureController,
    bindings: {
      plan: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      moduleContentApprovalId: '<?'
    }
  });
  function PlanStructureController($q, $translate, localeService, localDateRangeFilter, commonEducationService, defaultPromiseHandler,
  // NOSONAR
  commonStudentApplicationService, commonStudyRightService, planStudyRightService, planValidationResultHelper, commonModuleService) {
    const $ctrl = this;
    $ctrl.$onInit = function () {
      $ctrl.ready = false;
      $ctrl.plan.cacheModulesAndCourseUnits();
      $ctrl.isOpen = true;
      $ctrl.titleAriaLabel = undefined;
    };
    $ctrl.$onChanges = changesObject => {
      if (changesObject.plan && changesObject.plan.currentValue) {
        $ctrl.ready = false;
        const newPlan = changesObject.plan.currentValue;
        const promises = [];
        const educationPromise = commonEducationService.findById(newPlan.rootId).then(education => {
          $ctrl.education = education;
          return education;
        }).then(education => {
          $ctrl.childModuleSelections = $ctrl.validatablePlan.getSelectedModulesById(education);
          if (_.every($ctrl.childModuleSelections, {
            type: 'DegreeProgramme'
          })) {
            $ctrl.childModuleSelections = commonModuleService.getDegreeProgrammesOrderedByTypeUrn($ctrl.childModuleSelections);
          }
          $ctrl.educationValidationResults = planValidationResultHelper.getModuleValidationResult(_.get(education, 'id'), $ctrl.planValidationResult);
          setTitleAriaLabel();
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        promises.push(educationPromise);
        const moduleContentApprovalPromise = commonStudentApplicationService.getApplicationsForStudentByTypes($ctrl.plan.userId, ['CustomModuleContentApplication', 'RequiredModuleContentApplication']).then(applications => {
          $ctrl.moduleContentApprovals = _.filter(applications, moduleContentApplication => _.includes(['REQUESTED', 'ACCEPTED', 'CONDITIONAL', 'IN_HANDLING'], moduleContentApplication.state) && moduleContentApplication.educationId === $ctrl.plan.rootId);
          _.map(applications, moduleContentApplication => {
            if (moduleContentApplication.id === $ctrl.moduleContentApprovalId) {
              $ctrl.highlightedModuleId = moduleContentApplication.approvedModuleId;
            }
          });
          return $ctrl.moduleContentApprovals;
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        promises.push(moduleContentApprovalPromise);
        const studyRightPromise = commonStudyRightService.findByPersonId($ctrl.plan.userId, false, false).then(studyRights => {
          $ctrl.studyRights = studyRights;
          return $ctrl.studyRights;
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        promises.push(studyRightPromise);
        $q.all(promises).then(() => {
          $ctrl.matchingStudyRight = _.find($ctrl.studyRights, studyRight => {
            if (studyRight.educationId !== $ctrl.education.id) {
              return false;
            }
            if (studyRight.learningOpportunityId !== newPlan.learningOpportunityId) {
              return false;
            }
            if (studyRight.documentState !== 'ACTIVE') {
              return false;
            }
            if (!_.includes(['ACTIVE', 'ACTIVE_NONATTENDING', 'PASSIVE', 'NOT_STARTED'], studyRight.state)) {
              return false;
            }
            return true;
          });
          $ctrl.educationOptions = planStudyRightService.getValidatedEducationOptions($ctrl.validatablePlan, $ctrl.education, $ctrl.matchingStudyRight);
          $ctrl.selectionPathInPlan = planStudyRightService.getSelectionPathInPlan($ctrl.validatablePlan, $ctrl.education);
          $ctrl.ready = true;
        });
      }
      function setTitleAriaLabel() {
        const educationName = localeService.getLocalizedValue($ctrl.education.name);
        const validityPeriodHeading = $translate.instant('SIS_COMPONENTS.PLAN.EDUCATION_TOOLTIP.VALIDITY_PERIOD');
        const validityPeriod = localDateRangeFilter($ctrl.education.validityPeriod);
        $ctrl.titleAriaLabel = `${educationName}, ${validityPeriodHeading} ${validityPeriod}`;
      }
    };
  }
})();