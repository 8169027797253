import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SisValidationErrors } from 'common-typescript/types';
import { omit } from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

export const SEARCH_STRING_MAX_LENGTH = 255;

@Component({
    selector: 'sis-input-with-debounce',
    templateUrl: './input-with-debounce.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InputWithDebounceComponent implements OnInit, OnDestroy {
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();
    @Input() name: string;
    @Input() debounce = 0;
    @Input() placeholder: string;
    @Input() classes: string;

    valueChanged = new Subject<string>();
    validationErrors: SisValidationErrors;

    ngOnInit() {
        this.valueChanged
            .pipe(
                filter(text => text.length <= SEARCH_STRING_MAX_LENGTH),
                debounceTime(this.debounce),
                distinctUntilChanged(),
            )
            .subscribe(newValue => this.valueChange.emit(newValue));
    }

    onValueChange(newValue: string) {
        this.valueChanged.next(newValue);
    }

    ngOnDestroy(): void {
        this.valueChanged.unsubscribe();
    }

    checkSearchLength($event: KeyboardEvent) {
        const target = $event.target as HTMLInputElement;

        if (target.value.length > SEARCH_STRING_MAX_LENGTH) {
            this.validationErrors = {
                maxLength: {
                    translationKey: 'INPUT_ERRORS.SEARCH_QUERY_TEXT_TOO_LONG',
                },
            };
        } else {
            this.validationErrors = omit(this.validationErrors, ['maxLength']);
        }
    }
}
