import angular from 'angular';
import * as _ from 'lodash-es';
export const planValidationResultHelperModule = 'sis-components.service.planValidationResultHelper';
(function () {
  angular.module('sis-components.service.planValidationResultHelper', []).factory('planValidationResultHelper', planValidationResultHelper);

  /**
   * @ngInject
   */
  function planValidationResultHelper() {
    const api = {
      getCourseUnitValidationResult: (courseUnitId, planValidationResult) => {
        if (!courseUnitId || !planValidationResult) {
          return null;
        }
        const courseUnitValidationResults = _.get(planValidationResult, 'courseUnitValidationResults');
        return _.get(courseUnitValidationResults, courseUnitId);
      },
      getModuleValidationResult: (moduleId, planValidationResult) => {
        if (!moduleId || !planValidationResult) {
          return null;
        }
        const moduleValidationResults = _.get(planValidationResult, 'moduleValidationResults');
        return _.get(moduleValidationResults, moduleId);
      },
      getCustomCourseUnitAttainmentValidationResult: (customCourseUnitAttainmentId, planValidationResult) => {
        if (!customCourseUnitAttainmentId || !planValidationResult) {
          return null;
        }
        const customCourseUnitAttainmentValidationResults = _.get(planValidationResult, 'customCourseUnitAttainmentValidationResults');
        return _.get(customCourseUnitAttainmentValidationResults, customCourseUnitAttainmentId);
      },
      getCustomModuleAttainmentValidationResult: (customModuleAttainmentId, planValidationResult) => {
        if (!customModuleAttainmentId || !planValidationResult) {
          return null;
        }
        const customModuleAttainmentValidationResults = _.get(planValidationResult, 'customModuleAttainmentValidationResults');
        return _.get(customModuleAttainmentValidationResults, customModuleAttainmentId);
      },
      getRuleValidationResult: (parentModuleId, ruleId, planValidationResult) => {
        if (!parentModuleId || !ruleId || !planValidationResult) {
          return null;
        }
        const ruleValidationResults = _.get(planValidationResult, 'ruleValidationResults');
        return _.get(_.get(ruleValidationResults, parentModuleId), ruleId);
      },
      getDisplayableValidationResult(planValidationResult) {
        const result = _.cloneDeep(planValidationResult);
        _.unset(result, 'validatablePlan');
        _.unset(result, 'rootValidationResult.validatablePlan');
        _.forEach(_.values(result.ruleValidationResults), ruleValidationResultsForModule => {
          _.forEach(_.values(ruleValidationResultsForModule), ruleValidationResult => {
            if (!_.isEmpty(ruleValidationResult.selectedCourseUnitsById)) {
              ruleValidationResult.selectedCourseUnitIds = _.keys(ruleValidationResult.selectedCourseUnitsById);
              _.unset(ruleValidationResult, 'selectedCourseUnitsById');
            }
            if (!_.isEmpty(ruleValidationResult.selectedCustomCourseUnitAttainmentsById)) {
              ruleValidationResult.selectedCustomCourseUnitAttainmentIds = _.keys(ruleValidationResult.selectedCustomCourseUnitAttainmentsById);
              _.unset(ruleValidationResult, 'selectedCustomCourseUnitAttainmentsById');
            }
            if (!_.isEmpty(ruleValidationResult.selectedCustomStudyDraftsById)) {
              ruleValidationResult.selectedCustomStudyDraftIds = _.keys(ruleValidationResult.selectedCustomStudyDraftsById);
              _.unset(ruleValidationResult, 'selectedCustomStudyDraftsById');
            }
            if (!_.isEmpty(ruleValidationResult.selectedModulesById)) {
              ruleValidationResult.selectedModuleIds = _.keys(ruleValidationResult.selectedModulesById);
              _.unset(ruleValidationResult, 'selectedModulesById');
            }
            if (!_.isEmpty(ruleValidationResult.selectedCustomModuleAttainmentsById)) {
              ruleValidationResult.selectedCustomModuleAttainmentIds = _.keys(ruleValidationResult.selectedCustomModuleAttainmentsById);
              _.unset(ruleValidationResult, 'selectedCustomModuleAttainmentsById');
            }
            if (!_.isEmpty(ruleValidationResult.matchingCourseUnitsByGroupId)) {
              ruleValidationResult.matchingCourseUnitGroupIds = _.keys(ruleValidationResult.matchingCourseUnitsByGroupId);
              _.unset(ruleValidationResult, 'matchingCourseUnitsByGroupId');
            }
            if (!_.isEmpty(ruleValidationResult.matchingModulesByGroupId)) {
              ruleValidationResult.matchingModuleGroupIds = _.keys(ruleValidationResult.matchingModulesByGroupId);
              _.unset(ruleValidationResult, 'matchingModulesByGroupId');
            }
            if (!_.isEmpty(ruleValidationResult.matchingCustomCourseUnitAttainmentsById)) {
              ruleValidationResult.matchingCustomCourseUnitAttainmentIds = _.keys(ruleValidationResult.matchingCustomCourseUnitAttainmentsById);
              _.unset(ruleValidationResult, 'matchingCustomCourseUnitAttainmentsById');
            }
            if (!_.isEmpty(ruleValidationResult.matchingCustomModuleAttainmentsById)) {
              ruleValidationResult.matchingCustomModuleAttainmentIds = _.keys(ruleValidationResult.matchingCustomModuleAttainmentsById);
              _.unset(ruleValidationResult, 'matchingCustomModuleAttainmentsById');
            }
          });
        });
        return result;
      }
    };
    return api;
  }
})();