import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { numberUtils } from 'common-typescript';
import { CreditRange } from 'common-typescript/types';
import * as _ from 'lodash-es';

export type CreditRangePipeVariant = 'TEXT' | 'SYMBOLS';

/**
 * Transforms a CreditRange object to a string. Has two variants, TEXT and SYMBOLS. They differ in how partial credit ranges are
 * handled (i.e. ones that only have min or max defined). The TEXT variant prefixes the output with a text that indicates whether
 * the value represents minimum or maximum credits (e.g. "min. 5"). The SYMBOLS variant uses {greater,less}-or-equal-than symbols
 * (e.g. "≥ 5").
 *
 * The pipe also adds a credit abbreviation suffix to the output by default; this can be disabled with the omitSuffix parameter.
 *
 * The TEXT and SYMBOL variants are equivalent with the creditRange and creditRangeShort filters in AngularJS, respectively.
 */
@Pipe({ name: 'creditRange' })
export class CreditRangePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(credits: CreditRange, variant: CreditRangePipeVariant = 'TEXT', omitSuffix = false): any {
        if (_.isNil(credits) || (!_.isFinite(credits.min) && !_.isFinite(credits.max))) {
            return '';
        }

        const suffix = omitSuffix ? '' : ` ${this.translateService.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ABBR')}`;

        const min = numberUtils.numberToString(credits.min);
        const max = numberUtils.numberToString(credits.max);

        let output = null;
        if (!!min && !max) {
            const translationKey = variant === 'TEXT' ?
                'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN' : 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_SHORT';
            output = this.translateService.instant(translationKey, { value: min });
        }
        if (!min && !!max) {
            const translationKey = variant === 'TEXT' ?
                'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX' : 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_SHORT';
            output = this.translateService.instant(translationKey, { value: max });
        }
        if (!!min && !!max && min === max) {
            output = min;
        }
        if (!!min && !!max && min !== max) {
            output = `${min}–${max}`;
        }

        return _.isEmpty(output) ? '' : `${output}${suffix}`;
    }
}
