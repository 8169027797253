import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { StateService, UiOnParamsChanged } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import {
    CourseUnit,
    CustomStudyDraft, DPSMBase,
    EntityWithRule,
    OtmId,
    Plan, StudyRight, StudyRightState, StudyTermLocator, TermRegistration,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    map,
    merge,
    Observable, of,
    OperatorFunction,
    ReplaySubject, shareReplay,
    Subject,
    Subscription,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import {
    CustomAttainmentCreditInfoModalComponent,
} from 'sis-components/attainment/custom-attainment-credit-info-modal/custom-attainment-credit-info-modal.component';
import { getConfirmationModalOpener } from 'sis-components/confirm/confirm-dialog.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Option } from 'sis-components/menuButton/menu-button.component';
import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
    UiOperation,
    UiOperationType,
} from 'sis-components/plan/plan-actions-service/plan-actions.service';
import { CurrentStudyTermService } from 'sis-components/service/current-study-term.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { PlanStateService } from 'sis-components/service/plan-state.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';

import {
    StudentCourseUnitInfoModalComponent,
} from '../common/components/course-unit-info-modal/student-course-unit-info-modal/student-course-unit-info-modal.component';
import {
    StudentModuleInfoModalComponent,
} from '../common/components/module-info/student-module-info-modal/student-module-info-modal.component';
import {
    StudentCustomStudyDraftModalComponent,
    StudentCustomStudyDraftModalValues, StudentCustomStudyDraftOptions,
} from '../plan/custom-study-draft-modal/custom-study-draft-modal.component';
import {
    OutdatedCourseUnitsModalService,
} from '../plan/outdated-course-units-modal/outdated-course-units-modal.service';
import {
    planStudyRightModalOpener,
    PlanStudyRightValues,
} from '../plan/planStudyRightModal/planStudyRightModal.component';

import { copyPlanModalOpener } from './copy-plan-modal/copy-plan-modal.component';
import { createPlanModalOpener } from './create-plan-modal/create-plan-modal.component';
import {
    editPlanNameModalOpener,
} from './edit-plan-ng-name-modal/edit-plan-ng-name-modal.component';
import { PlanDataService, PlanStructureData } from './plan-data.service';
import {
    planStructureEditModalOpener,
} from './plan-structure/plan-structure-edit-modal/plan-structure-edit-modal.component';

@Component({
    selector: 'app-plan-ng',
    templateUrl: './plan-ng.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanNgComponent implements OnInit, OnDestroy, UiOnParamsChanged {
    @Input() set planId(value: OtmId) {
        this.planIdInputSubject$.next(value);
    }

    planIdInputSubject$ = new ReplaySubject<OtmId>(1);

    data$: Observable<PlanStructureData>;
    menuOptions$: Observable<Option[]>;

    uiOperationSubscription: Subscription;

    destroyed$: Subject<void> = new Subject<void>();

    planMenuOptionsButtonId = 'planMenuOptionsButton';

    missingTermRegistration$: Observable<boolean>;
    termRegistrationRequired$: Observable<boolean>;
    matchingStudyRight$: Observable<StudyRight>;

    private readonly planStructureEditModalOpener = planStructureEditModalOpener();
    private readonly editPlanNameModalOpener = editPlanNameModalOpener();
    private readonly copyPlanModalOpener = copyPlanModalOpener();
    private readonly planStudyRightModalOpener = planStudyRightModalOpener();
    private readonly confirmDialogOpener = getConfirmationModalOpener();
    private readonly createPlanModalOpener = createPlanModalOpener();

    constructor(private planEntityService: PlanEntityService,
                private studyRightEntityService: StudyRightEntityService,
                private planStateService: PlanStateService,
                private appErrorHandler: AppErrorHandler,
                private stateService: StateService,
                private modalService: ModalService,
                private alertService: AlertsService,
                private translocoService: TranslocoService,
                private planDataService: PlanDataService,
                private currentStudyTermService: CurrentStudyTermService,
                private outdatedCourseUnitsModalService: OutdatedCourseUnitsModalService,
                @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    ngOnInit() {
        this.data$ = this.planDataService.createDataObservable(this.planIdInputSubject$.asObservable()).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        this.menuOptions$ = this.data$.pipe(map(data => this.planMenuOptions(data)));
        this.uiOperationSubscription = this.createUiOperationSubscription();
        this.missingTermRegistration$ = this.createResolveTermRegistrationStatusObservable();
        this.termRegistrationRequired$ = this.createIsTermRegistrationRequiredObservable();
        this.matchingStudyRight$ = this.createMatchingStudyRightObservable();
    }

    /**
     * When the ui-router state is set to 'dynamic' the parameters will not be resolved after the initial values have been resolved,
     * so the input 'planId' is not updated on plan change. This hook is used to manually update the planId from
     * new params. It also works when navigating the browser history.
     */
    uiOnParamsChanged(newParams: { planId: OtmId }, trans?: Transition): void {
        this.planIdInputSubject$.next(newParams.planId);
    }

    handlePlanChange(selectedPlanId: OtmId): void {
        // This state is set as 'dynamic'. This prevents destroying and recreating the component when
        // a plan is selected, but still updates the browser history properly. For keyboard users the current
        // focus will be preserved on the current element without any other tricks.
        this.stateService.go('.', { planId: selectedPlanId });
    }

    /* Ui operations */

    handleUiOperation(): OperatorFunction<[UiOperation, PlanStructureData], void> {
        return map(([uiOperation, data]) => {
            switch (uiOperation.uiOperationType) {
                case UiOperationType.OPEN_MODULE:
                    this.handleOpenModuleUiOperation(uiOperation.target as EntityWithRule, data);
                    break;
                case UiOperationType.OPEN_CUSTOM_STUDY_DRAFT_INFO_MODAL: {
                    const customStudyDraftModalValues = this.resolveStudentCustomStudyDraftModalValues(data, uiOperation);
                    this.modalService.open(
                        StudentCustomStudyDraftModalComponent,
                        customStudyDraftModalValues,
                        { size: 'sm' },
                    );
                    break;
                }
                case UiOperationType.OPEN_CUSTOM_ATTAINMENT_INFO_MODAL:
                    this.modalService.open(
                        CustomAttainmentCreditInfoModalComponent,
                        uiOperation.target,
                        { closeWithOutsideClick: true, size: 'lg' },
                    );
                    break;
                case UiOperationType.OPEN_COURSE_UNIT_INFO_MODAL: {
                    this.modalService.open(
                        StudentCourseUnitInfoModalComponent,
                        {
                            courseUnit: uiOperation.target,
                            validatablePlan: data.validatablePlan,
                        },
                        { closeWithOutsideClick: true, size: 'xl', windowClass: 'brochure-modal' },
                    );
                    break;
                }
                case UiOperationType.OPEN_MODULE_INFO_MODAL: {
                    const module = uiOperation.target as DPSMBase;
                    const isModuleInPlan = data.validatablePlan?.isModuleInPlan(module.id) || false;
                    this.modalService.open(
                        StudentModuleInfoModalComponent,
                        {
                            module: uiOperation.target,
                            validatablePlan: isModuleInPlan ? data.validatablePlan : null,
                            studyRight: data.matchingStudyRight,
                            planValidationResult: isModuleInPlan ? data.planValidationResult : null,
                        },
                        { closeWithOutsideClick: true, size: 'xl', windowClass: 'brochure-modal' },
                    );
                    break;
                }
                default:
                    console.warn(`Handler for UiOperation '${uiOperation.uiOperationType}' is not implemented yet.`);
                    break;
            }
        });
    }

    handleOpenModuleUiOperation(module: EntityWithRule, data: PlanStructureData): void {
        this.planStructureEditModalOpener({
            module,
            validatablePlan: data.validatablePlan,
            validatablePlanStudyRight: data.matchingStudyRight,
            education: data.validatablePlanEducation,
        });
    }

    createUiOperationSubscription(): Subscription {
        return this.planActionsService.uiOperationSubject
            .pipe(
                withLatestFrom(this.data$),
                takeUntil(this.destroyed$),
                this.handleUiOperation(),
            ).subscribe();
    }

    createResolveTermRegistrationStatusObservable(): Observable<boolean> {
        return this.currentStudyTermService.currentStudyTermLocator$.pipe(
            switchMap((currentStudyTermLocator) => this.data$.pipe(
                map(data => this.isTermRegistrationMissing(currentStudyTermLocator, data)),
                this.appErrorHandler.defaultErrorHandler(),
            )),
        );
    }

    createIsTermRegistrationRequiredObservable(): Observable<boolean> {
        return this.data$.pipe(
            switchMap((data) => {
                const matchingStudyRight: StudyRight = _.find(data.allStudentStudyRights, {
                    educationId: data.validatablePlan.plan.rootId,
                    learningOpportunityId: data.validatablePlan.plan.learningOpportunityId,
                    documentState: 'ACTIVE',
                });

                if (!matchingStudyRight) {
                    return of(false);
                }

                return this.studyRightEntityService.isTermRegistrationRequired(matchingStudyRight).pipe(
                    this.appErrorHandler.defaultErrorHandler(),
                );
            }),
        );
    }

    resolveStudentCustomStudyDraftModalValues(data: PlanStructureData, uiOperation: UiOperation): StudentCustomStudyDraftModalValues {
        const studentId = data?.validatablePlan.plan.userId;
        const customStudyDraft = uiOperation.target as CustomStudyDraft;
        const options = this.customStudyDraftOptions(data);
        return { studentId, customStudyDraft, options };
    }

    customStudyDraftOptions(data: PlanStructureData): StudentCustomStudyDraftOptions {
        const options = {
            customAttainmentApplicationsDisabled: !this.planStateService.canApplyForCustomAttainments(data.validatablePlan, data.validatablePlanEducation, data.matchingStudyRight),
        };
        return options;
    }

    /* Plan menu options and actions */

    planMenuOptions(data: PlanStructureData): Option[] {
        return [
            {
                action: () => this.openCreateNewPlanModal(),
                name: 'STUDY_PLAN_CREATE_NEW',
            },
            {
                action: () => this.openEditPlanNameModal(data.plan),
                name: 'PLAN.RENAME',
            },
            {
                action: () => this.deletePlanMenuAction(data.plan),
                disabled: () => this.isDeletePlanMenuActionDisabled(data.plan, data.allStudentStudyRights),
                name: 'DELETE_PLAN',
            },
            {
                action: () => this.markAsPrimary(data.plan),
                disabled: () => data.plan.primary,
                name: 'MAKE_PRIMARY',
            },
            {
                action: () => this.openCopyPlanModal(data),
                name: 'PLAN.COPY_NEW',
            },
            {
                action: () => this.openPlanStudyRightModalMenuAction(data),
                hide: () => !data.matchingStudyRight,
                name: 'PLAN.SHOW_SELECTION_PATHS',
            },
            {
                action: () => this.openCreatePriorLearningInclusionApplication(data.plan.id),
                disabled: () => !this.planStateService.canApplyForCustomAttainments(data.validatablePlan, data.validatablePlanEducation, data.matchingStudyRight),
                hide: () => !_.get(data.universitySettings, 'frontendFeatureToggles.priorLearningInclusionApplicationEnabled', true),
                name: 'PLAN.OPEN_PRIOR_LEARNING_INCLUSION_APPLICATION',
            },
            {
                action: () => this.openCustomAttainmentApplication(data.plan.id),
                disabled: () => !this.planStateService.canApplyForCustomAttainments(data.validatablePlan, data.validatablePlanEducation, data.matchingStudyRight),
                hide: () => !_.get(data.universitySettings, 'frontendFeatureToggles.customCourseCreditApplicationEnabled', true),
                name: 'PLAN.OPEN_CUSTOM_ATTAINMENT_APPLICATION',
            },
        ];
    }

    openCreateNewPlanModal(): void {
        const dialogOpener = this.createPlanModalOpener();
        dialogOpener.afterClosed().subscribe((createdPlan) => {
            this.focusToPlanMenuOptionsButton();
            if (createdPlan) {
                this.handlePlanChange(createdPlan.id);
                this.alertService.addTemporaryAlert({
                    message: this.translocoService.translate('PLAN.CREATE_PLAN_MODAL.SUCCESS_NOTIFICATION', { name: createdPlan.name }),
                    type: AlertType.SUCCESS,
                });
            }
        });
    }

    openCopyPlanModal(data: PlanStructureData): void {
        const modalOpener = this.copyPlanModalOpener(data.plan.id);

        const modalStream = merge(
            modalOpener.closed,
            modalOpener.dismissed,
        );
        modalStream.pipe(
            takeUntil(this.destroyed$),
        ).subscribe((planId: OtmId) => {
            this.focusToPlanMenuOptionsButton();
            if (planId) {
                this.handlePlanChange(planId);
            }
        });
    }

    openPlanStudyRightModalMenuAction(data: PlanStructureData): void {
        const modalValues: PlanStudyRightValues = {
            education: data.validatablePlanEducation,
            educationOptions: data.educationOptions,
            studyRight: data.matchingStudyRight,
            validatablePlan: data.validatablePlan,
        };
        // The modal modifies these input values directly so create a copy to avoid errors
        const modalOpener = this.planStudyRightModalOpener(_.cloneDeep(modalValues));
        const closedHandler = modalOpener.closed.pipe(
            switchMap((studyRight) =>
                // Force study right refresh in the entity store for now
                // TODO: Can be removed once planStudyRightModal uses the entity service for updating the selection path
                this.studyRightEntityService.getById(studyRight.id, true).pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )),
        );
        const modalStream = merge(
            closedHandler,
            modalOpener.dismissed,
        );

        modalStream.subscribe(() => this.focusToPlanMenuOptionsButton());
    }

    openEditPlanNameModal(plan: Plan): void {
        if (plan) {
            const modalOpener = this.editPlanNameModalOpener(plan.id);

            const modalStream = merge(
                modalOpener.closed,
                modalOpener.dismissed,
            );
            modalStream.pipe(
                takeUntil(this.destroyed$),
            ).subscribe(() => this.focusToPlanMenuOptionsButton());
        }
    }

    markAsPrimary(plan: Plan): void {
        if (plan && !plan.primary) {
            this.planEntityService.markAsPrimaryAsStudent(plan.id, plan)
                .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
                .subscribe((primaryPlan: Plan) => {
                    this.focusToPlanMenuOptionsButton();
                    this.alertService.addTemporaryAlert({
                        message: this.translocoService.translate('PLAN.PLAN_IS_PRIMARY', { planName: primaryPlan.name }),
                        type: AlertType.SUCCESS,
                    });
                });
        }
    }

    openCustomAttainmentApplication(planId: OtmId): void {
        this.stateService.go('student.logged-in.profile.applications.create-custom-attainment-application', { planId });
    }

    openCreatePriorLearningInclusionApplication(planId: OtmId): void {
        this.stateService.go('student.logged-in.profile.applications.create-prior-learning-inclusion-application', { planId });
    }

    deletePlanMenuAction(plan: Plan): void {
        const modalRef = this.confirmDialogOpener({
            title: 'PLAN.DELETE_PLAN_MODAL.TITLE',
            description: 'PLAN.DELETE_PLAN_MODAL.DESCRIPTION',
            name: plan.name,
            confirmText: 'PLAN.DELETE_PLAN_MODAL.BUTTON_DELETE',
            cancelText: 'PLAN.DELETE_PLAN_MODAL.BUTTON_CANCEL',
        });
        const dismissedHandler = modalRef.dismissed.pipe(
            tap(() => {
                this.focusToPlanMenuOptionsButton();
            }),
        );
        const closedHandler = modalRef.closed.pipe(
            switchMap(() => this.planEntityService.deleteMyPlan(plan.id).pipe(take(1))),
            tap(() => {
                this.focusToPlanMenuOptionsButton();
                this.alertService.addTemporaryAlert({
                    message: this.translocoService.translate('PLAN.DELETE_PLAN_MODAL.SUCCESS_NOTIFICATION', { name: plan.name }),
                    type: AlertType.SUCCESS,
                });
            }),
        );
        merge(dismissedHandler, closedHandler).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }

    isDeletePlanMenuActionDisabled(plan: Plan, allStudyRights: StudyRight[]): boolean {
        const hasStudyRight = allStudyRights.some(studyRight => studyRight.educationId === plan.rootId);
        if (hasStudyRight && plan.primary) {
            return true;
        }
        return false;
    }

    focusToPlanMenuOptionsButton(): void {
        document.getElementById(this.planMenuOptionsButtonId)?.focus();
    }

    openOutdatedCourseUnitsModal(data: PlanStructureData, outDatedCourseUnitsInPlan: [CourseUnit, CourseUnit][]) {
        this.outdatedCourseUnitsModalService.open({ courseUnits: outDatedCourseUnitsInPlan, validatablePlan: data.validatablePlan })
            .subscribe(
                {
                    next: () => { /* Modal was closed */ },
                    error: () => { /* Modal was closed */ },
                },
            );
    }

    noStudyRightForStudyPlan(data: PlanStructureData): boolean {
        if (!data.validatablePlan?.plan || !data.allStudentStudyRights) {
            return false;
        }
        return !_.some(data.allStudentStudyRights, (studyRight) =>
            studyRight.educationId === data.validatablePlan.plan.rootId &&
                studyRight.learningOpportunityId === data.validatablePlan.plan.learningOpportunityId &&
                studyRight.documentState === 'ACTIVE');
    }

    studyRightForStudyPlanButNoneActive(data: PlanStructureData): boolean {
        if (!data.validatablePlan?.plan || !data.allStudentStudyRights) {
            return false;
        }

        if (!this.noStudyRightForStudyPlan(data)) {
            const allowedStates: StudyRightState[] = ['ACTIVE', 'ACTIVE_NONATTENDING', 'NOT_STARTED'];
            return !_.some(data.allStudentStudyRights, (studyRight: StudyRight) =>
                studyRight.educationId === data.validatablePlan.plan.rootId &&
                studyRight.learningOpportunityId === data.validatablePlan.plan.learningOpportunityId &&
                studyRight.documentState === 'ACTIVE' &&
                _.includes(allowedStates, studyRight.state));
        }
        return false;
    }

    isTermRegistrationMissing(currentStudyTermLocator: StudyTermLocator, data: PlanStructureData): boolean {
        if (!data.validatablePlan?.plan || !data.allStudentStudyRights) {
            return false;
        }

        if (!this.studyRightForStudyPlanButNoneActive(data)) {
            const matchingStudyRight: StudyRight = _.find(data.allStudentStudyRights, {
                educationId: data.validatablePlan.plan.rootId,
                learningOpportunityId: data.validatablePlan.plan.learningOpportunityId,
                documentState: 'ACTIVE',
            });

            if (!matchingStudyRight) {
                return false;
            }

            const matchingTermRegistration: TermRegistration = _.find(matchingStudyRight.termRegistrations, {
                studyTerm: {
                    studyYearStartYear: currentStudyTermLocator?.studyYearStartYear,
                    termIndex: currentStudyTermLocator?.termIndex,
                },
            });

            if (!matchingTermRegistration) {
                return true;
            }

            return (matchingTermRegistration.termRegistrationType === 'MISSING' || matchingTermRegistration.termRegistrationType === 'NEGLECTED');
        }
        return false;
    }

    createMatchingStudyRightObservable(): Observable<StudyRight> {
        return this.currentStudyTermService.currentStudyTermLocator$.pipe(
            switchMap(() => this.data$.pipe(
                map(data => this.findMatchingStudyRight(data)),
                this.appErrorHandler.defaultErrorHandler(),
            )),
        );
    }

    private findMatchingStudyRight(data: PlanStructureData): StudyRight {
        if (data.allStudentStudyRights) {
            return _.find(data.allStudentStudyRights, {
                educationId: data.validatablePlan.plan.rootId,
                learningOpportunityId: data.validatablePlan.plan.learningOpportunityId,

            });
        }
    }

    openTutoring(): void {
        this.planIdInputSubject$?.subscribe(planId => {
            if (planId) {
                this.stateService.go('student.logged-in.plan.tutoring.new-messages', { planId });
            }
        });
    }
}
