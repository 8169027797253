<ng-container *transloco="let t">
  <div class="row">
    <div class="col-xl-4 col-md-6 col-sm-12">
      <fudis-select
        [control]="selectPlanControl"
        [label]="t('ENTITY_NAMES.plan')"
        [selectionClearButton]="false"
        (selectionUpdate)="selectPlan.emit($event.value)">
        <ng-template fudisSelectOptions>
          <fudis-select-group
            *ngFor="let key of Object.keys(mappedPlanStructure)"
            [label]="t((key | education | async)?.name | localizedString)">
            <fudis-select-option
              *ngFor="let option of mappedPlanStructure[key]"
              [data]="{
                value: option.id,
                label: option.name + (option.primary ? ' (' + (t('PRIMARY_STUDY_PLAN')) + ')': ''),
                subLabel: t('STUDY_PLAN_SELECTOR_SUB_TEXT',
                    {
                      learningOpportunityName: option.learningOpportunityId | learningOpportunityName: (key | education | async) | localizedString,
                      curriculumPeriodName: option.curriculumPeriodId | curriculumPeriodName | async,
                      modified: option.metadata.lastModifiedOn | localDateFormat
                    })
                }">
            </fudis-select-option>
          </fudis-select-group>
        </ng-template>
      </fudis-select>
    </div>
    <div class="col-lg-6 ms-xl-5 ps-xl-5">
      <app-plan-header-info-list [currentPlan]="this.currentPlan"></app-plan-header-info-list>
    </div>
  </div>
  <hr class="sis-hr" />
</ng-container>


