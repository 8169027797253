import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import angular from 'angular';
import { StudyPeriodLocator, TimelineNote } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { Option } from 'sis-components/menuButton/menu-button.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-timeline-note',
    templateUrl: './timeline-note.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineNoteComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.timing.line.timeline-note',
        directiveName: 'appTimelineNote',
    };

    @Input() locator: StudyPeriodLocator;
    @Input() note: TimelineNote;
    @Input() active: boolean;

    /**
     * The $scope instance of the containing AngularJS component. Output event emissions need to be wrapped in $scope.$apply()
     * to remove any potential delays during event emissions caused by separate AngularJS/Angular change detection mechanisms.
     */
    @Input() scope: angular.IScope;

    @Output() deleteNote = new EventEmitter<void>();
    @Output() startTiming = new EventEmitter<void>();
    @Output() startMoving = new EventEmitter<void>();
    @Output() editTitle = new EventEmitter<void>();

    readonly menuItems: Option[] = [
        { action: () => this.emitInScope(this.deleteNote), name: 'DELETE' },
        { action: () => this.emitInScope(this.startTiming), name: 'TIMELINE.EDIT_NOTE_PERIOD' },
        { action: () => this.emitInScope(this.startMoving), name: 'TIMELINE.MOVE_NOTE_PERIOD' },
        { action: () => this.emitInScope(this.editTitle), name: 'TIMELINE.EDIT_NOTE_TITLE' },
    ];

    isFirst() {
        return _.indexOf(this.note.notePeriods, this.locator) === 0;
    }

    isMiddle() {
        const index = _.indexOf(this.note.notePeriods, this.locator);
        return index > 0 && index < this.note.notePeriods.length - 1;
    }

    isLast() {
        return _.indexOf(this.note.notePeriods, this.locator) === this.note.notePeriods.length - 1;
    }

    emitInScope(emitter: EventEmitter<void>): void {
        this.scope.$apply(() => emitter.emit());
    }
}
