import {
    ChangeDetectionStrategy,
    Component, EventEmitter,
    Inject,
    Input,
    OnChanges,
    Optional, Output,
    ViewEncapsulation,
} from '@angular/core';
import { Attainment, CourseUnit, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { CourseUnitStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';
import { UniversityService } from '../../service/university.service';
import { Action, InputType } from '../../study-boxes/study-action-box/study-action-box.component';

@Component({
    selector: 'sis-plan-structure-course-unit',
    templateUrl: './plan-structure-course-unit.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCourseUnitComponent implements OnChanges {

    @Input({ required: true }) courseUnit: CourseUnit;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;
    @Input() selected?: boolean;
    @Input() action?: Action;
    @Input() inputType?: InputType;
    @Input() actionInputId: string;
    @Input() actionInputName: string;
    @Output() actionChange? = new EventEmitter<OtmId>();

    courseUnitStateObject: CourseUnitStateObject;
    attainment: Attainment;
    isSubstituted: boolean = false;
    studyState: 'DRAFT' | 'DELETED';
    invalidSelection: boolean;
    isCrossStudyCourseUnit: boolean = false;
    isAboutToExpire: boolean;
    newerVersionAvailable: boolean = false;
    constructor(@Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
                private universityService: UniversityService) {
    }

    ngOnChanges(): void {
        this.courseUnitStateObject = _.get(this.planStateObject.courseUnits, this.courseUnit.id);
        if (this.courseUnitStateObject) {
            this.attainment = this.planData.attainmentsById[this.courseUnitStateObject.attainmentId];
            this.isSubstituted = this.attainment?.state === 'SUBSTITUTED';
            this.invalidSelection = this.courseUnitStateObject.invalidSelection && !this.courseUnitStateObject.hasModuleContentApproval;
            this.isAboutToExpire = this.courseUnitStateObject.isAttainmentAboutToExpire;
            this.newerVersionAvailable = this.courseUnitStateObject.newerVersionAvailable;
        }
        const isCourseUnitsOrganisationSameAsStudents = this.courseUnit?.universityOrgIds?.includes(this.universityService.getCurrentUniversityOrgId());
        if (!isCourseUnitsOrganisationSameAsStudents && this.courseUnit?.cooperationNetworkDetails?.networks.length > 0) {
            this.isCrossStudyCourseUnit = true;
        }
        if (this.courseUnit.documentState !== 'ACTIVE') {
            this.studyState = this.courseUnit.documentState;
        }
    }

    openCourseUnitInfoModal() {
        this.planActionsService?.openCourseUnitInfoModal?.(this.courseUnit);
    }

}
