import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { PrerequisiteGroupEditValues } from './prerequisiteGroupEditModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'prerequisite-group-edit-modal' })
export class PrerequisiteGroupEditModalDirective extends UpgradeComponent {

    @Output() close: EventEmitter<any>;
    @Output() dismiss: EventEmitter<any>;
    @Input() resolve: PrerequisiteGroupEditValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('prerequisiteGroupEditModal', elementRef, injector);
    }
}
