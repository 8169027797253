import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getIDType, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpAddConfig, HttpDeleteConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    CourseUnitEnrolmentRight,
    EnrolmentRight,
    EnrolmentRightAddRequest,
    EnrolmentRightUpdateRequest,
    OtmId,
    ResolvedEnrolmentRightState,
    UsedEnrolments,
} from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { simpleObjectToQueryParams } from '../search-ng/search-utils';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        enrolmentRight(id: OtmId) {
            return `${this.backend}/enrolment-rights/${id}`;
        },
        getByCourseUnitIds() {
            return `${this.backend}/enrolment-rights/by-course-unit`;
        },
        getByOpenUniversityProductIds() {
            return `${this.backend}/enrolment-rights/by-open-university-product`;
        },
        getByOpenUniversityCartIds() {
            return `${this.backend}/enrolment-rights/by-open-university-cart`;
        },
        getByStudyRightIds() {
            return `${this.backend}/enrolment-rights/by-study-right`;
        },
        getByCourseUnitGroupIds() {
            return `${this.backend}/enrolment-rights/by-course-unit-group-id`;
        },
        findEligibleEnrolmentRights() {
            return `${this.backend}/enrolment-rights/eligible-for-enrolment`;
        },
        countUsedEnrolments() {
            return `${this.backend}/enrolment-rights/used-enrolments`;
        },
        cancel() {
            return `${this.backend}/enrolment-rights/cancel`;
        },
        add() {
            return `${this.backend}/enrolment-rights/add`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'enrolment-rights',
})
export class EnrolmentRightEntityService extends EntityService<EnrolmentRightState> {

    constructor() {
        super(EnrolmentRightStore, EnrolmentRightQuery);
    }

    getResolvedEnrolmentRightState(enrolmentRight: EnrolmentRight): ResolvedEnrolmentRightState {
        const currentDate = new Date();
        if (enrolmentRight.state === 'ACTIVATED' && currentDate < new Date(enrolmentRight.validityPeriod.startDate)) {
            return 'ACTIVATED_FUTURE';
        }
        if (enrolmentRight.state === 'ACTIVATED' && currentDate >= new Date(enrolmentRight.validityPeriod.startDate)
            && currentDate < new Date(enrolmentRight.validityPeriod.endDate)) {
            return 'ACTIVATED_ACTIVE';
        }
        if (enrolmentRight.state === 'ACTIVATED' && currentDate >= new Date(enrolmentRight.validityPeriod.endDate)) {
            return 'ACTIVATED_EXPIRED';
        }
        if (enrolmentRight.state === 'CANCELLED') {
            return 'CANCELLED';
        }
        return null;
    }

    getByCourseUnitIds(courseUnitIds: OtmId | OtmId[]): Observable<EnrolmentRight[]> {
        const ids = this.removeEmptyAndDuplicateValues(courseUnitIds);
        if (ids.length === 0) {
            return of([]);
        }

        return this.getHttp().get(CONFIG.ENDPOINTS.getByCourseUnitIds(), { params: { courseUnitId: ids } })
            .pipe(this.upsertManyAndSwitchToStoreObservable({ filterBy: entity => ids.includes(entity.courseUnitId) }));
    }

    getByOpenUniversityProductIds(openUniversityProductIds: OtmId | OtmId[]): Observable<CourseUnitEnrolmentRight[]> {
        const ids = this.removeEmptyAndDuplicateValues(openUniversityProductIds);
        if (ids.length === 0) {
            return of([]);
        }

        return this.getHttp().get<CourseUnitEnrolmentRight[]>(CONFIG.ENDPOINTS.getByOpenUniversityProductIds(), { params: { openUniversityProductId: ids } })
            .pipe(
                this.upsertManyAndSwitchToStoreObservable({ filterBy: entity => ids.includes((entity as CourseUnitEnrolmentRight).openUniversityProductId) }),
                map(er => er as CourseUnitEnrolmentRight[]),
            );
    }

    getByOpenUniversityCartIds(openUniversityCartIds: OtmId | OtmId[]): Observable<CourseUnitEnrolmentRight[]> {
        const ids = this.removeEmptyAndDuplicateValues(openUniversityCartIds);
        if (ids.length === 0) {
            return of([]);
        }
        return this.getHttp().get<CourseUnitEnrolmentRight[]>(CONFIG.ENDPOINTS.getByOpenUniversityCartIds(), { params: { openUniversityCartId: ids } })
            .pipe(
                this.upsertManyAndSwitchToStoreObservable({ filterBy: entity => ids.includes((entity as CourseUnitEnrolmentRight).openUniversityCartId) }),
                map(er => er as CourseUnitEnrolmentRight[]),
            );
    }

    getByStudyRightIds(studyRightIds: OtmId | OtmId[]): Observable<EnrolmentRight[]> {
        const ids = this.removeEmptyAndDuplicateValues(studyRightIds);
        if (ids.length === 0) {
            return of([]);
        }

        return this.getHttp().get(CONFIG.ENDPOINTS.getByStudyRightIds(), { params: { studyRightId: ids } })
            .pipe(this.upsertManyAndSwitchToStoreObservable({ filterBy: entity => ids.includes(entity.studyRightId) }));
    }

    getByCourseUnitGroupIds(courseUnitGroupIds: OtmId[]): Observable<EnrolmentRight[]> {
        if (!courseUnitGroupIds || courseUnitGroupIds.length === 0) {
            return of([]);
        }

        return this.getHttp().get(CONFIG.ENDPOINTS.getByCourseUnitGroupIds(), { params: { courseUnitGroupIds } })
            .pipe(this.upsertManyAndSwitchToStoreObservable());
    }

    findEligibleEnrolmentRights(
        studyRightId: OtmId,
        courseUnitId: OtmId,
        assessmentItemId: OtmId,
        courseUnitRealisationId: OtmId,
        personId?: OtmId,
    ): Observable<CourseUnitEnrolmentRight[]> {
        const params = simpleObjectToQueryParams({
            studyRightId,
            courseUnitId,
            assessmentItemId,
            courseUnitRealisationId,
            personId,
        });
        return this.getHttp().get<CourseUnitEnrolmentRight[]>(CONFIG.ENDPOINTS.findEligibleEnrolmentRights(), { params });
    }

    countUsedEnrolments(enrolmentRightIds: OtmId | OtmId[]): Observable<UsedEnrolments[]> {
        const ids = this.removeEmptyAndDuplicateValues(enrolmentRightIds);
        if (ids.length === 0) {
            return of([]);
        }

        return this.getHttp().get<UsedEnrolments[]>(CONFIG.ENDPOINTS.countUsedEnrolments(), { params: { enrolmentRightId: ids } });
    }

    cancelEnrolmentRights(enrolmentRightIds: OtmId | OtmId[], cancelReason: string, cancelDate: string): Observable<EnrolmentRight[]> {
        const ids = this.removeEmptyAndDuplicateValues(enrolmentRightIds);
        if (ids.length === 0) {
            return of([]);
        }
        return this.getHttp().put(CONFIG.ENDPOINTS.cancel(), { enrolmentRightIds: ids, cancelReason, cancelDate })
            .pipe(this.upsertManyAndSwitchToStoreObservable({ filterBy: entity => ids.includes(entity.id) }));
    }

    /**
     * Update an existing enrolment right based on the parameters in the given update request object.
     */
    update<T>(id: OtmId, updateRequest: EnrolmentRightUpdateRequest): Observable<T> {
        return super.update(id, updateRequest);
    }

    addEnrolmentRight(request: EnrolmentRightAddRequest): Observable<EnrolmentRight> {
        return this.getHttp().post(CONFIG.ENDPOINTS.add(), request)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    override add<T>(entity: EnrolmentRight, config?: HttpAddConfig<T>): Observable<T> {
        // Generic create operation is not supported for enrolment rights
        throw new Error('Not supported');
    }

    /**
     * Not supported!
     */
    override delete<T>(id: getIDType<EnrolmentRightState>, config?: HttpDeleteConfig<T>): Observable<T> {
        throw new Error('Not supported');
    }
}

type EnrolmentRightState = EntityState<EnrolmentRight, OtmId>;

class EnrolmentRightQuery extends QueryEntity<EnrolmentRightState> {
    constructor(protected store: EnrolmentRightStore) {
        super(store);
    }
}

@StoreConfig({ name: 'enrolment-rights' })
class EnrolmentRightStore extends EntityStore<EnrolmentRightState> {}
