<div class="selection-box-container"
     [ngClass]="{'selected-highlight': control.value === value }">
    <div class="selection-box-inputs">
        <span *ngIf="(disabled || ariaDisabled) && disabledReason"
              class="group-is-full-element">
            <sis-tiny-badge variant="danger">{{ disabledReason | translate }}</sis-tiny-badge>
        </span>
        <sis-radio-button *ngIf="type === 'radio'"
                          [required]="required"
                          [translatedLabel]="translatedTitle"
                          [control]="control"
                          [value]="value"
                          [id]="id"
                          [name]="name"
                          [disabled]="disabled"
                          [ariaDisabled]="ariaDisabled">
        </sis-radio-button>
        <sis-checkbox *ngIf="type === 'checkbox'"
                      [label]="translatedTitle"
                      [name]="name"
                      [checked]="control.value"
                      [id]="id"
                      [disabled]="disabled"
                      [ariaDisabled]="ariaDisabled"
                      (checkedChange)="updateCheckboxValue($event)">
        </sis-checkbox>
        <ng-content></ng-content>
    </div>
    <!-- DO NOT REMOVE THIS! This absolute element makes whole div clickable -->
    <div class="selection-box-clickable-area" (click)="clickInput()">
    </div>
</div>
