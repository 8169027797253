import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript/constants';
import { CourseUnitResultItem, CurriculumPeriod, LocalDateRange, Organisation, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';

import { StudyPeriodInfo } from '../search.types';

@Component({
    selector: 'app-search-result-row',
    templateUrl: './search-result-row.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchResultRowComponent implements OnInit, OnDestroy, OnChanges {
    @Input() studyPeriods: StudyPeriodInfo[];
    @Input() curriculumPeriods: CurriculumPeriod[];

    @Input() result: CourseUnitResultItem;
    @Input() term: string;
    @Input() searchFilterCurriculumPeriodIds: OtmId[];
    @Input() courseCartCourseUnitIds: OtmId[];
    @Input() loggedIn: boolean;
    @Input() set hasOpenUniversityLink(hasOpenUniversityLink: boolean) {
        this.courseUnitTitleLink = hasOpenUniversityLink ? 'student.course-unit.open-university' : 'student.course-unit';
    }

    @Output() addCourseUnitToCourseCart = new EventEmitter<string>();
    @Output() removeCourseUnitFromCourseCart = new EventEmitter<string>();

    isInCart: boolean;
    matchingOtherLang: boolean;
    organisationNames: string;
    destroyed$ = new Subject<void>();

    renderedActivityPeriods: string;
    renderedCurriculumPeriods: string;
    curLangCodeUrns: string[] = [];
    courseUnitTitleLink: string = 'student.course-unit';

    constructor(
        private localeService: LocaleService,
        private translate: TranslateService,
        private organisationEntityService: OrganisationEntityService,
    ) {
    }

    ngOnInit(): void {
        this.matchingOtherLang = this.matchInOtherLang(this.result.name, this.result.nameMatch);

        this.curriculumPeriods = _.chain(this.curriculumPeriods)
            .filter(curriculumPeriod => _.includes(this.result.curriculumPeriodIds, curriculumPeriod.id))
            .orderBy('activePeriod.startDate')
            .value();

        this.renderOrganisationNames();
        // "result.curCodeUrns" can include multiple types of a urns (e.g.: "urn:code:course-unit-realisation-type:teaching-participation-lectures"),
        // but we only need the language urns.
        this.curLangCodeUrns = (this.result.curCodeUrns || []).filter((urn: string) => urn.includes('urn:code:language'));
        this.renderedActivityPeriods = this.renderActivityPeriods();
        this.renderedCurriculumPeriods = this.renderCurriculumPeriods();
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseCartCourseUnitIds) {
            this.isInCourseCart();
        }

        if (changes.curriculumPeriods) {
            this.curriculumPeriods = this.getDeepCopy(this.curriculumPeriods);
        }
    }

    isInCourseCart() {
        this.isInCart = _.includes(this.courseCartCourseUnitIds, this.result.id);
    }

    renderActivityPeriods(): string {
        const activityPeriods: LocalDateRange[] = this.result.activityPeriods || [];
        const overlappingStudyPeriods: StudyPeriodInfo[][] = this.getStudyPeriodsOverlappingActivityPeriods(this.studyPeriods, activityPeriods);
        const groupedStudyPeriods: GroupedStudyPeriod[] = this.groupStudyPeriodsPerYearOrRange(overlappingStudyPeriods);

        return groupedStudyPeriods.length > 0 ?
            _.map(groupedStudyPeriods, group => this.renderStudyPeriodGroup(group)).join(', ') :
            this.translate.instant('SEARCH_RESULT.NO_INFO');
    }

    /**
     * Iterates given activity periods and returns overlapping study periods per activity period, discarding
     * empty overlaps and duplicates. The items in the returned array are candidates for showing up as separate
     * items on the page, though they may still be combined in {@link groupStudyPeriodsPerYearOrRange}.
     *
     * @param studyPeriods study periods to filter against the activity periods
     * @param activityPeriods course unit's activity periods
     */
    getStudyPeriodsOverlappingActivityPeriods(studyPeriods: StudyPeriodInfo[], activityPeriods: LocalDateRange[]): StudyPeriodInfo[][] {
        return _.chain(activityPeriods)
            .map(_.partial(this.mapActivityPeriodToOverlappingStudyPeriods, studyPeriods))
            .reject(_.isEmpty)
            .uniqBy(filteredStudyPeriods => _.map(filteredStudyPeriods, studyPeriod =>
                `${studyPeriod.name} ${studyPeriod.studyYearName}`).join(','))
            .value();
    }

    /**
     * Returns the study periods overlapping with the given activity period.
     *
     * @param studyPeriods study periods to filter against the activity period
     * @param activityPeriod course unit's activity period
     */
    mapActivityPeriodToOverlappingStudyPeriods(studyPeriods: StudyPeriodInfo[], activityPeriod: LocalDateRange): StudyPeriodInfo[] {
        return _.filter(studyPeriods, studyPeriod => dateUtils.dateRangesOverlap(
            studyPeriod.valid.startDate, studyPeriod.valid.endDate, activityPeriod.startDate, activityPeriod.endDate,
        ));
    }

    /**
     * Groups given study periods to be listed as separate items on the page:
     * - individual study periods are grouped by their study year
     * - study period ranges are left as they are
     *
     * @param studyPeriodsPerActivityPeriod an array of arrays of study periods
     */
    groupStudyPeriodsPerYearOrRange(studyPeriodsPerActivityPeriod: StudyPeriodInfo[][]): GroupedStudyPeriod[] {
        return _.reduce(studyPeriodsPerActivityPeriod, (groupedStudyPeriods, studyPeriods) => {
            const year: string = studyPeriods[0].studyYearName;
            if (studyPeriods.length > 1) {
                groupedStudyPeriods.push({ studyPeriods, isRange: true });
            } else {
                const existingGroup: GroupedStudyPeriod = groupedStudyPeriods.find((period: GroupedStudyPeriod) => !period.isRange && period.year === year);
                if (existingGroup) {
                    existingGroup.studyPeriods.push(studyPeriods[0]);
                } else {
                    groupedStudyPeriods.push({ year, studyPeriods, isRange: false });
                }
            }

            return groupedStudyPeriods;
        }, []);
    }

    /**
     * Renders a study period group to display on the page.
     *
     * @param studyPeriodGroup a group of study periods to show as a list item
     */
    renderStudyPeriodGroup(studyPeriodGroup: GroupedStudyPeriod): string {
        return studyPeriodGroup.isRange ?
            this.renderStudyPeriodRange(studyPeriodGroup.studyPeriods) :
            this.renderSeparateStudyPeriods(studyPeriodGroup.studyPeriods);
    }

    /**
     * Renders a continuous range of study periods.
     *
     * @param studyPeriods study periods constituting a range
     */
    renderStudyPeriodRange(studyPeriods: StudyPeriodInfo[]): string {
        const firstPeriod = _.first(studyPeriods);
        const lastPeriod = _.last(studyPeriods);

        if (firstPeriod.studyYearName !== lastPeriod.studyYearName) {
            return `${firstPeriod.name} (${firstPeriod.studyYearName})–${lastPeriod.name} (${lastPeriod.studyYearName})`;
        }
        return `${firstPeriod.name}–${lastPeriod.name} (${firstPeriod.studyYearName})`;
    }

    /**
     * Renders study periods within the same study year that don't constitute a continuous range.
     *
     * @param studyPeriods study periods within the same study year
     */
    renderSeparateStudyPeriods(studyPeriods: StudyPeriodInfo[]): string {
        if (studyPeriods.length === 1) {
            return `${studyPeriods[0].name} (${studyPeriods[0].studyYearName})`;
        }

        const allButLastPeriodNames = _.chain(studyPeriods)
            .dropRight()
            .map('name')
            .join(', ');
        const lastPeriodName = _.last(studyPeriods).name;
        return `${allButLastPeriodNames} ${this.translate.instant('AND')} ${lastPeriodName} (${studyPeriods[0].studyYearName})`;
    }

    renderCurriculumPeriods(): string {
        const curriculumPeriodsToRender: CurriculumPeriod[] = _.isEmpty(this.searchFilterCurriculumPeriodIds) ?
            this.getMostRelevantCurriculumPeriods() :
            this.getCurriculumPeriodsMatchingSearchFilter();
        return _.map(curriculumPeriodsToRender, curriculumPeriod =>
            this.localeService.localize(curriculumPeriod.abbreviation)).join(', ');
    }

    /**
     * Returns the curriculum periods of the search result that match the curriculum periods in the search
     * filter.
     */
    getCurriculumPeriodsMatchingSearchFilter(): CurriculumPeriod[] {
        return _.filter(this.curriculumPeriods, curriculumPeriod =>
            _.includes(this.searchFilterCurriculumPeriodIds, curriculumPeriod.id));
    }

    /**
     * Returns the most relevant curriculum periods of the search result, in practice only one in this order:
     *
     * 1. one that is active now
     * 2. closest one active in the future
     * 3. closest one active in the past
     */
    getMostRelevantCurriculumPeriods(): CurriculumPeriod[] {
        const now = moment();

        const currentCurriculumPeriod: CurriculumPeriod = _.find(this.curriculumPeriods, curriculumPeriod =>
            dateUtils.rangeContains(now, curriculumPeriod.activePeriod));
        if (currentCurriculumPeriod) {
            return [currentCurriculumPeriod];
        }

        const partitionedPeriods: [CurriculumPeriod[], CurriculumPeriod[]] = _.partition(this.curriculumPeriods, curriculumPeriod =>
            dateUtils.isRangeAfter(now, curriculumPeriod.activePeriod));
        const closestPeriodInTheFuture: CurriculumPeriod = _.first(partitionedPeriods[0]);
        if (closestPeriodInTheFuture) {
            return [closestPeriodInTheFuture];
        }
        const closestPeriodInThePast: CurriculumPeriod = _.last(partitionedPeriods[1]);
        if (closestPeriodInThePast) {
            return [closestPeriodInThePast];
        }

        return [];
    }

    renderOrganisationNames() {
        this.organisationEntityService.getByIds(this.result.organisations)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((organisations: Organisation[]) => {
                this.organisationNames = organisations
                    .map(organisation => this.localeService.localize(organisation.name))
                    .join(', ');
            });
    }

    addToCart() {
        this.addCourseUnitToCourseCart.emit(this.result.id);
    }

    removeFromCart() {
        this.removeCourseUnitFromCourseCart.emit(this.result.id);
    }

    matchInOtherLang(valueInUILang: string, matchedValue: string): boolean {
        return valueInUILang !== matchedValue && /<b>/.test(matchedValue);
    }

    // For the situations where object is passed inside multiple child -components, and all children are modifying the object.
    getDeepCopy(obj: any): any {
        return _.cloneDeep(obj);
    }
}

interface GroupedStudyPeriod {
    isRange: boolean;
    year: string;
    studyPeriods: StudyPeriodInfo[];
}
