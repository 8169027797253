import angular from 'angular';
import * as _ from 'lodash-es';
import courseUnitRuleTpl from './courseUnitRule.tpl.html';
import { BaseCourseUnitRuleController } from './BaseCourseUnitRuleController';
(function () {
  CourseUnitRuleController.$inject = ["$q", "PlanState", "uiContext", "courseUnitInfoModal", "$scope", "planContextEventType", "defaultPromiseHandler", "validAttainmentFilterService"];
  angular.module('student.plan.rules').component('courseUnitRule', {
    template: courseUnitRuleTpl,
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<'
    },
    controller: CourseUnitRuleController
  });
  function CourseUnitRuleController(
  // NOSONAR
  $q, PlanState, uiContext, courseUnitInfoModal, $scope, planContextEventType, defaultPromiseHandler, validAttainmentFilterService) {
    const ctrl = this;
    ctrl.courseUnits = [];
    ctrl.courseUnit = undefined;
    ctrl.defaultVersion = undefined;
    ctrl.$onInit = function () {
      BaseCourseUnitRuleController.call(this, uiContext, PlanState, courseUnitInfoModal, validAttainmentFilterService);
      $scope.$on(planContextEventType.planBuilt, refreshCourseUnits);
      refreshCourseUnits().catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.getValidatablePlan = function () {
      return _.get(uiContext, 'planContext.validatablePlan');
    };
    function refreshCourseUnits() {
      const plannedCourseUnit = uiContext.planContext.getPlannedCourseUnit(ctrl.rule.courseUnitGroupId);
      if (plannedCourseUnit) {
        ctrl.courseUnit = plannedCourseUnit;
        ctrl.defaultVersion = plannedCourseUnit;
        setSelectedParentIfOtherThanThisModule();
        return $q.when();
      }
      const unplannedCourseUnit = getUnplannedCourseUnit(ctrl.rule.courseUnitGroupId);
      if (unplannedCourseUnit) {
        ctrl.courseUnit = unplannedCourseUnit;
        setSelectedParentIfOtherThanThisModule();
        return $q.when();
      }
      return setDefaultVersion();
    }
    function setDefaultVersion() {
      if (ctrl.defaultVersion) {
        ctrl.courseUnit = ctrl.defaultVersion;
        setSelectedParentIfOtherThanThisModule();
        return $q.when();
      }
      return uiContext.planContext.getCourseUnitOfGroup(ctrl.rule.courseUnitGroupId, ctrl.module.getCurriculumPeriodIds()).then(courseUnitVersion => {
        if (courseUnitVersion) {
          ctrl.defaultVersion = courseUnitVersion;
        }
        ctrl.courseUnit = ctrl.defaultVersion;
        setSelectedParentIfOtherThanThisModule();
      });
    }
    function getUnplannedCourseUnit(courseUnitGroupId) {
      return _.find(uiContext.unplannedCourseUnits, {
        groupId: courseUnitGroupId
      });
    }
    function setSelectedParentIfOtherThanThisModule() {
      const validatablePlan = ctrl.getValidatablePlan();
      if (!validatablePlan) {
        ctrl.selectedParent = undefined;
      } else {
        const selectedParent = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(ctrl.courseUnit);
        if (selectedParent && selectedParent.id !== ctrl.module.id) {
          ctrl.selectedParent = selectedParent;
        } else {
          ctrl.selectedParent = undefined;
        }
      }
    }
  }
})();