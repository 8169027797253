import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/constants';
import {
    CalendarEvent,
    OtmId,
} from 'common-typescript/types';

import { EntityService } from '../service/entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ilmo/api',
        getCalendarEvents() {
            return `${this.backend}/calendar-events`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'calendar-events',
})
export class CalendarEventEntityService extends EntityService<CalendarEventState> {

    constructor(
    ) {
        super(CalendarEventStore, CalendarEventQuery);
    }

    getCalendarEvents(startDate: Date, endDate: Date) {
        const params = { startDate: dateUtils.convertDateToIsoLocalDate(startDate), endDate: dateUtils.convertDateToIsoLocalDate(endDate) };
        return this.getHttp().get<CalendarEvent[]>(CONFIG.ENDPOINTS.getCalendarEvents(), { params });
    }
}

type CalendarEventState = EntityState<CalendarEvent, OtmId>;
@StoreConfig({ name: 'calendar-events' })
class CalendarEventStore extends EntityStore<CalendarEventState> {}

class CalendarEventQuery extends QueryEntity<CalendarEventState> {
    constructor(protected store: CalendarEventStore) {
        super(store);
    }
}
