import angular from 'angular';
import * as _ from 'lodash-es';
export const uiStateStoreModule = 'student.common.utils.uiStateStore';
(function () {
  uiStateStoreFactory.$inject = ["$q"];
  angular.module(uiStateStoreModule, []).factory('uiStateStore', uiStateStoreFactory);
  function uiStateStoreFactory($q) {
    var uiStateStore = this;
    uiStateStore.storeField = storeField;
    uiStateStore.readFieldAndCallFunc = readFieldAndCallFunc;
    uiStateStore.readAndSetField = readAndSetField;
    uiStateStore.readField = readField;
    uiStateStore.clear = clear;
    return uiStateStore;

    // Some tests need this
    function clear() {
      sessionStorage.clear();
    }
    function storeField(prefix, k, value) {
      var key = prefix + k;
      if (value !== undefined && value !== null) {
        var json = JSON.stringify(value);
        sessionStorage.setItem(key, json);
      } else {
        sessionStorage.removeItem(key);
      }
    }
    function readField(prefix, k, defaultValue) {
      var key = prefix + k;
      var json = sessionStorage.getItem(key);
      if (json !== null && json !== undefined) {
        return JSON.parse(json);
      }
      return null || defaultValue || null;
    }

    /**
     * @returns a promise
     */
    function readFieldAndCallFunc(prefix, k, func, defaultValue) {
      var val = readField(prefix, k, defaultValue);
      var retVal;
      if (val !== null) {
        retVal = func(val);
      }
      // wrap return value in a promise if not already
      return retVal && retVal.then ? retVal : $q.when(retVal);
    }
    function readAndSetField(prefix, key, object, defaultValue) {
      var val = readField(prefix, key, defaultValue);
      _.set(object, key, val);
    }
  }
})();