import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Education, OtmId } from 'common-typescript/types';
import { chain, concat, filter, has, isNil, keyBy, map } from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';

import { CheckboxTreeOption } from '../../checkbox-tree/checkbox-tree.component';
import { LocalDateRangePipe } from '../../date/pipes/local-date-range/local-date-range.pipe';

export interface SelectEducationModalValues {
    selectedIds: OtmId[];
    educations: Education[];
    hideLearningOpportunities: boolean;
    customTitle?: string;
}

@Component({
    selector: 'sis-select-education',
    templateUrl: './select-education.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEducationComponent implements OnInit {
    selectedIds: OtmId[];
    educations: Education[];
    options: CheckboxTreeOption[];
    selected: CheckboxTreeOption[] = [];
    optionTree: CheckboxTreeOption;
    rootId = 'select-education-root';
    hideLearningOpportunities: boolean;
    customTitle: string;

    @Output() selectedChange = new EventEmitter<CheckboxTreeOption[]>();

    constructor(
        private localeService: LocaleService,
        private modal: NgbActiveModal,
        private translateService: TranslateService,
        private localDateRange: LocalDateRangePipe,
        @Inject(ModalService.injectionToken) private values: SelectEducationModalValues,
    ) {
        this.selectedIds = values.selectedIds;
        this.educations = values.educations;
        this.hideLearningOpportunities = values.hideLearningOpportunities;
        this.customTitle = values.customTitle;
    }

    ngOnInit(): void {
        this.sortEducationsByStartDateDescendingAndNameAscending();
        this.optionTree = this.buildOptionTree(this.educations);
        this.options = this.flattenChildren([this.optionTree]);
        this.selected = this.resolveSelection(this.selectedIds);
    }

    private buildOptionTree(educations: Education[]): CheckboxTreeOption {
        const options = educations.map((education) => {
            let childElements: CheckboxTreeOption[] = [];

            if (!this.hideLearningOpportunities && has(education, 'structure.learningOpportunities')) {
                childElements = map(education.structure.learningOpportunities, learningOpportunity => ({
                    value: this.mapLocalIdToOptionValue(learningOpportunity.localId, education.id),
                    label: learningOpportunity.name,
                    parentId: education.id,
                    children: [],
                }));
            }
            return {
                value: education.id,
                label: education.name,
                secondaryLabel: this.localeService.getLocalizedString(this.localDateRange.transform(education.validityPeriod)),
                parentId: this.rootId,
                children: childElements,
                code: education.code,
            };
        });

        return {
            parentId: null,
            label: this.localeService.getLocalizedString('SIS_COMPONENTS.HIERARCHY_SELECTOR_MODAL.ALL_EDUCATIONS'),
            value: this.rootId,
            children: options,
        };
    }

    private sortEducationsByStartDateDescendingAndNameAscending(): void {
        this.educations.sort((a, b) => {
            const ap = a.validityPeriod;
            const bp = b.validityPeriod;
            const aStart = new Date(ap.startDate);
            const bStart = new Date(bp.startDate);
            return bStart.getTime() - aStart.getTime();
        });
        this.educations.sort((a, b) =>
            this.localeService.localize(a.name).localeCompare(this.localeService.localize(b.name)));
    }

    selectChange = (option: CheckboxTreeOption): void => {
        const isSelected = !!this.selected.find(({ value }) => value === option.value);
        if (isSelected) {
            this.selected = this.selected.filter(({ value }) => value !== option.value);
            return;
        }

        this.selected.push(option);
        const deepChildrenIds = this.flattenChildren(option.children)
            .map(child => child.value);
        this.selected = this.selected.filter(({ value }) => !deepChildrenIds.includes(value));
    };

    private flattenChildren(nodes: CheckboxTreeOption[]): CheckboxTreeOption[] {
        let children: CheckboxTreeOption[] = [];
        return nodes.map((node: any) => {
            if (node.children && node.children.length) {
                children = [...children, ...node.children];
            }
            return node;
        }).concat(children.length ? this.flattenChildren(children) : children);
    }

    confirmSelection() {
        let selectedEducations: CheckboxTreeOption[];
        let selectedLearningOpportunities: CheckboxTreeOption[];

        if (this.selected.some(selection => selection.value === this.rootId)) {
            selectedEducations = this.selected[0].children;
            selectedLearningOpportunities = [];
        } else {
            selectedEducations = filter(this.selected, selection => selection.parentId === this.rootId);
            selectedLearningOpportunities = filter(this.selected, selection => selection.parentId !== this.rootId);
            const educationsToSelect = this.getLearningOpportunityEducationsToSelect(selectedEducations, selectedLearningOpportunities);

            selectedEducations = concat(selectedEducations, educationsToSelect);
        }

        const mappedEducations = map(selectedEducations, education => ({
            id: education.value,
            name: education.label,
            secondaryLabel: education.secondaryLabel,
            children: chain(selectedLearningOpportunities)
                .filter(lo => lo.parentId === education.value)
                .map(lo => ({
                    localId: this.mapOptionValueToLocalId(lo.value, education.value),
                    name: lo.label,
                    secondaryLabel: education.secondaryLabel,
                }))
                .value(),
        }));

        this.modal.close(mappedEducations);
    }

    private getLearningOpportunityEducationsToSelect(selectedEducations: CheckboxTreeOption[], selectedLearningOpportunities: CheckboxTreeOption[]): any {
        const keyedSelectedEducations = keyBy(selectedEducations, 'value');
        return chain(selectedLearningOpportunities)
            .map('parentId')
            .uniq()
            .filter(parentId => isNil(keyedSelectedEducations[parentId]))
            .map(parentId => keyBy(this.options, 'value')[parentId])
            .value();
    }

    private idToOptionMapper = (options: CheckboxTreeOption[]) =>
        (id: string): CheckboxTreeOption => options.find(({ value }) => value === id);

    get defaultExpanded() {
        return [this.rootId];
    }

    close() {
        this.modal.dismiss();
    }

    reset() {
        this.selected = [];
    }

    private mapLocalIdToOptionValue(localId: string, educationId: string) {
        return `${educationId}_${localId}`;
    }

    private mapOptionValueToLocalId(value: string, educationId: string) {
        return value.split(`${educationId}_`)[1];
    }

    private resolveSelection(selectedIds: OtmId[]) {
        const parentsOfChildren = this.options.filter(option => selectedIds.includes(option.value)).filter(option => option.parentId !== this.rootId)
            .map(option => option.parentId);
        const filteredIds = selectedIds.filter(id => !parentsOfChildren.includes(id));
        return filteredIds.map(this.idToOptionMapper(this.options));
    }
}
