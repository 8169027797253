import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

import { NavUtilsService } from '../../service/nav-utils.service';

@Component({
    selector: 'sis-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurLogout = new EventEmitter<any>();

    logoutUrl: string;

    constructor(private configService: ConfigService,
                private navUtilsService: NavUtilsService,
                private authService: AuthService) {}

    ngOnInit() {
        this.logoutUrl = this.getLogoutUrl();
    }

    logout() {
        this.authService.clear();
    }

    private getLogoutUrl(): string {
        let logoutUrl = '';
        const universityConfig = this.configService.get();

        if (universityConfig.logoutUrl) {
            const logoutUrlPrefix = (universityConfig.logoutUrl || '').startsWith('http') ? '' : window.location.origin;
            const targetUrl = `${window.location.origin}${this.navUtilsService.getContextPath()}/login?loggedOut=true`;

            logoutUrl = `${logoutUrlPrefix}${universityConfig.logoutUrl}${targetUrl}`;
        }

        return logoutUrl;
    }
}
