import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { EnrolmentQuestionnaireValues } from './enrolmentQuestionnaireModal-ng.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'enrolment-questionnaire-modal' })
export class EnrolmentQuestionnaireModalDirective extends UpgradeComponent {

    @Output() close: EventEmitter<void>;
    @Input() resolve: EnrolmentQuestionnaireValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('enrolmentQuestionnaireModal', elementRef, injector);
    }
}
