import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpUpdateConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/constants';
import {
    AdmissionTarget,
    CurriculumPeriod,
    DocumentState,
    Education,
    LocalDateString,
    LocalId,
    Module,
    OiliPayment,
    OtmId,
    Plan,
    SearchResult,
    StudyRightQuery as SRQ,
    StudentApplicationState,
    StudyRight,
    StudyRightActivePhaseModuleGroupId,
    StudyRightExtensionApplication,
    StudyRightQueryCounts,
    StudyRightResultItem,
    StudyRightSearchRequest,
    StudyRightState,
    StudyRightVerificationRequest,
    TermRegistrationWithPaymentInfo,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { searchRequestToQueryParams } from '../search-ng/search-utils';
import { SisuDataLoader } from '../service/SisuDataLoader';
import { UniversityService } from '../service/university.service';
import { getStudyTermsElapsedBetweenDates } from '../study-terms/study-year-utils';

import { AdmissionTargetEntityService } from './admission-target-entity.service';
import { CurriculumPeriodEntityService } from './curriculum-period-entity.service';
import { EducationEntityService } from './education-entity.service';
import { EntityService } from './entity.service';
import { ModuleEntityService } from './module-entity.service';
import { PaymentCategoryEntityService } from './payment-category-entity.service';
import { PreviewModelObject } from './preview-model.service';
import { StudentApplicationEntityService } from './student-application-entity.service';
import { StudentPaymentEntityService } from './student-payment-entity.service';
import { TermRegistrationRequirementsEntityService } from './term-registration-requirements-entity.service';

const CONFIG = {
    ENDPOINTS: {
        baseUrl: '/ori/api',
        get searchStudyRights(): string {
            return `${this.baseUrl}/study-rights/search`;
        },
        get getStudyRightActivePhaseModuleGroupIds(): string {
            return `${this.baseUrl}/study-rights/study-right-active-phase-module-group-ids`;
        },
        studyRightsForStudent(studentId: OtmId): string {
            return `${this.baseUrl}/study-rights/person/${studentId}`;
        },
        get studyRightsForCurrentUser(): string {
            return `${this.baseUrl}/study-rights/own`;
        },
        get studyRightCountByAdmissionTarget(): string {
            return `${this.baseUrl}/study-rights/count-by-admission-target`;
        },
        verify(studyRightId: OtmId): string {
            return `${this.baseUrl}/study-rights/${studyRightId}/verify`;
        },
        get cooperationNetworkRightUpdate(): string {
            return `${this.baseUrl}/study-rights/cooperation-network-rights-update`;
        },
        cooperationNetworkCandidateCount(): string {
            return `${this.baseUrl}/study-rights/cooperation-network-candidate-count`;
        },
    },
};

export interface SelectionPathModules {
    phase1Module?: Module;
    phase2Module?: Module;
}

export type PhaseId = 'phase1' | 'phase2';

export interface StudyRightFilters {
    /** Allow study rights with this educationId to pass */
    educationId?: OtmId;
    /** Allow study rights with this learningOpportunityId to pass */
    learningOpportunityId?: LocalId;
    /** Allow study rights whose validity is currently ongoing to pass */
    onlyOngoing?: boolean;
    /** Allow study rights with any of these states to pass */
    states?: StudyRightState[];
}

export interface StudyRightExpirationInfo {
    isExpired: boolean;
    studyRightId: OtmId;
    studyRight: StudyRight,
    inclusiveEndDate: LocalDateString;
    pendingExtensionApplication: StudyRightExtensionApplication | null;
}

/**
 * Returns a pipeable RxJS operator function which can be applied on an observable emitting arrays of study rights.
 * This operator will filter the emitted arrays to only contain study rights that satisfy the given filters.
 */
export function filterStudyRights(filters?: StudyRightFilters): (source: Observable<StudyRight[]>) => Observable<StudyRight[]> {
    return (source: Observable<StudyRight[]>) => source.pipe(
        map(studyRights => filterStudyRightsSynchronously(studyRights, filters)),
    );
}

/**
 * This will return those of the given study rights (in original order), that satisfy the given filters.
 */
export function filterStudyRightsSynchronously(source: readonly StudyRight[], filters?: StudyRightFilters): StudyRight[] {
    if (!filters || Object.values(filters).filter(Boolean).length === 0) {
        // No filters provided, return copy of the source
        return source.slice();
    }

    return source.filter(studyRight => {
        if (!!filters.educationId && studyRight.educationId !== filters.educationId) {
            return false;
        }

        if (!!filters.learningOpportunityId && studyRight.learningOpportunityId !== filters.learningOpportunityId) {
            return false;
        }

        if (!!filters.onlyOngoing && !dateUtils.rangeContains(moment(), studyRight.valid)) {
            return false;
        }

        if (!!filters.states?.length && !filters.states.includes(studyRight.state)) {
            return false;
        }

        return true;
    });
}

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.baseUrl,
    resourceName: 'study-rights',
})
export class StudyRightEntityService extends EntityService<StudyRightEntityState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studyRightEntityService',
        serviceName: 'studyRightEntityService',
    };

    private readonly studyRightActivePhaseModuleGroupIdStore: StudyRightActivePhaseModuleGroupIdStore;
    private readonly studyRightActivePhaseModuleGroupIdQuery: StudyRightActivePhaseModuleGroupIdQuery;
    private readonly studyRightActivePhaseModuleGroupIdDataLoader: SisuDataLoader<OtmId, StudyRightActivePhaseModuleGroupId, StudyRightActivePhaseModuleGroupId>;

    constructor(private educationEntityService: EducationEntityService,
                private paymentCategoryEntityService: PaymentCategoryEntityService,
                private studentPaymentEntityService: StudentPaymentEntityService,
                private universityService: UniversityService,
                private admissionTargetEntityService: AdmissionTargetEntityService,
                private termRegistrationRequirementsService: TermRegistrationRequirementsEntityService,
                private curriculumPeriodEntityService: CurriculumPeriodEntityService,
                private moduleEntityService: ModuleEntityService,
                private studentApplicationEntityService: StudentApplicationEntityService) {
        super(StudyRightStore, StudyRightQuery);
        this.studyRightActivePhaseModuleGroupIdStore = new StudyRightActivePhaseModuleGroupIdStore();
        this.studyRightActivePhaseModuleGroupIdQuery = new StudyRightActivePhaseModuleGroupIdQuery(this.studyRightActivePhaseModuleGroupIdStore);
        this.studyRightActivePhaseModuleGroupIdDataLoader = new SisuDataLoader<OtmId, StudyRightActivePhaseModuleGroupId, StudyRightActivePhaseModuleGroupId>(
            {
                getByIdsCall: studyRightIds => this.getStudyRightActivePhaseModuleGroupIdsCall(studyRightIds),
                successEntitiesCallback: entities => this.studyRightActivePhaseModuleGroupIdStore.upsertMany(entities),
                resultExtractor: (studyRightId, entities) => entities.find(entity => entity.studyRightId === studyRightId),
            },
        );
    }

    readonly searchRelationObservables: { [key: string]: Function } = {
        [StudyRightResultItemsRelation.STUDY_RIGHTS]: (studyRightResultItems: StudyRightResultItem[]) => this.getByIds(studyRightResultItems.flatMap(
            item => [...item.otherStudyRights.map(studyRight => studyRight.id), item.studyRightId]),
        ),
        [StudyRightResultItemsRelation.EDUCATIONS]: (studyRightResultItems: StudyRightResultItem[]) => this.educationEntityService.getByIds(
            _.uniq(studyRightResultItems.flatMap(
                item => [...item.otherStudyRights.map(studyRight => studyRight.educationId), item.educationId],
            )),
        ),
        [StudyRightResultItemsRelation.ADMISSION_TARGETS]: (studyRightResultItems: StudyRightResultItem[]) => this.admissionTargetEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId())
            .pipe(
                map((admissionTargets) => {
                    const admissionTargetIds = _.uniq(studyRightResultItems.flatMap(
                        item => [...item.otherStudyRights.map(studyRight => studyRight.admissionTargetId), item.admissionTargetId],
                    ));
                    return admissionTargets.filter(admissionTarget => admissionTargetIds.includes(admissionTarget.id));
                }),
            ),
    };

    readonly searchRelationEnums: StudyRightResultItemsRelation[] = Object.values(StudyRightResultItemsRelation);

    /**
     * Verifies a study right, i.e. changes documentState to ACTIVE, validates it (using ACTIVE level validations), and saves it.
     * If the study right is a paid one, the (first) tuition fee obligation period can be provided as well and it will be saved
     * in the same atomic operation.
     */
    verify(
        studyRightId: OtmId,
        request: Partial<StudyRightVerificationRequest>,
        config?: HttpUpdateConfig<StudyRight>,
    ): Observable<StudyRight> {
        return this.getHttp().put<StudyRight>(CONFIG.ENDPOINTS.verify(studyRightId), request, config)
            .pipe(
                tap(studyRight => this.store.upsert(studyRight.id, studyRight)),
                switchMap(studyRight => this.query.selectEntity(studyRight.id)),
            );
    }

    loadSearchRelations(studyRightResultItems: StudyRightResultItem[], requestedRelations: StudyRightResultItemsRelation[]): Observable<StudyRightResultItemsWithResolvedData> {
        return combineLatest([
            of(studyRightResultItems),
            ...this.constructRelationObservables<StudyRightResultItem[], StudyRightResultItemsRelation>(this.searchRelationEnums, requestedRelations, this.searchRelationObservables, studyRightResultItems),
        ]).pipe(
            map(data => data as unknown as [StudyRightResultItem[], StudyRight[], Education[], AdmissionTarget[]]),
            map(
                (data) => {
                    const [studyRightSearchResultItems, studyRights, educations, admissionTargets] = data;
                    return {
                        admissionTargets, educations, studyRights, studyRightResultItems: studyRightSearchResultItems,
                    };
                },
            ),
        );
    }

    searchStudyRights(searchRequest?: Partial<StudyRightSearchRequest>): Observable<SearchResult<StudyRightResultItem>> {
        const options = { params: this.toQueryParams(searchRequest) };
        return this.getHttp().get<SearchResult<StudyRightResultItem>>(CONFIG.ENDPOINTS.searchStudyRights, options);
    }

    findCandidateCountForGrantingCooperationNetworkRight(targetGroups: SRQ[]): Observable<StudyRightQueryCounts> {
        return this.getHttp().post<StudyRightQueryCounts>(CONFIG.ENDPOINTS.cooperationNetworkCandidateCount(), targetGroups);
    }

    getStudyRightsByStudentId(studentId: OtmId): Observable<StudyRight[]> {
        if (!studentId) {
            return null;
        }
        return this.getHttp().get<StudyRight[]>(CONFIG.ENDPOINTS.studyRightsForStudent(studentId))
            .pipe(
                tap(studyRights => this.store.upsertMany(studyRights)),
                switchMap(() => this.selectAll({
                    filterBy: studyRight => studyRight.studentId === studentId,
                })),
            );
    }

    /**
     * Fetches the study rights of the currently authenticated user,
     * and keeps returning subsequent values if an affecting change occurs.
     */
    getStudyRightsForCurrentUser(): Observable<StudyRight[]> {
        return this.getHttp().get<StudyRight[]>(CONFIG.ENDPOINTS.studyRightsForCurrentUser)
            .pipe(
                tap(studyRights => this.store.upsertMany(studyRights)),
                switchMap((studyRights) => {
                    const ids = new Set(studyRights.map(sr => sr.id));
                    return this.selectAll({ filterBy: studyRight => ids.has(studyRight.id) });
                }),
            );
    }

    /**
     * Fetches all study rights for the current user, and returns the ones matching with the given plan
     * (regardless of state or validity period).
     */
    findStudyRightsForPlan(plan: Plan): Observable<StudyRight[]> {
        if (!plan) {
            return of([]);
        }
        return this.getStudyRightsForCurrentUser()
            .pipe(filterStudyRights({
                educationId: plan.rootId,
                learningOpportunityId: plan.learningOpportunityId,
            }));
    }

    getStudyRightActivePhaseModuleGroupId(studyRightId: OtmId, bypassStore = false): Observable<StudyRightActivePhaseModuleGroupId> {
        if (!studyRightId) {
            return throwError(() => new Error('The study right id was missing!'));
        }
        if (!bypassStore && this.studyRightActivePhaseModuleGroupIdQuery.hasEntity(studyRightId)) {
            return this.studyRightActivePhaseModuleGroupIdQuery.selectEntity(studyRightId);
        }
        return this.studyRightActivePhaseModuleGroupIdDataLoader.load(studyRightId)
            .pipe(switchMap(() => this.studyRightActivePhaseModuleGroupIdQuery.selectEntity(studyRightId)));
    }

    private getStudyRightActivePhaseModuleGroupIdsCall(studyRightIds: OtmId[]): Observable<StudyRightActivePhaseModuleGroupId[]> {
        return this.getHttp().get<StudyRightActivePhaseModuleGroupId[]>(
            CONFIG.ENDPOINTS.getStudyRightActivePhaseModuleGroupIds,
            { params: { studyRightIds: studyRightIds.toString() } },
        );
    }

    getTermRegistrationsWithPaymentInfo(studyRightId: OtmId, bypassStore = false): Observable<TermRegistrationWithPaymentInfo[]> {
        return this.getById(studyRightId, bypassStore)
            .pipe(
                switchMap(studyRight => combineLatest([
                    of(studyRight.termRegistrations),
                    this.studentPaymentEntityService.findForStudent(studyRight.studentId)
                        .pipe(
                            map(payments =>
                                payments.filter(payment => payment.type === 'OILI_PAYMENT' && !payment.invalidated) as OiliPayment[]),
                        ),
                    this.paymentCategoryEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId())
                        .pipe(first()),
                ])),
                map(([termRegistrations, studentPayments, paymentCategories]) => {
                    const studentUnionMembershipFeePaymentCategoryIds = paymentCategories
                        .filter(category => category.isStudentUnionMembershipFee)
                        .map(category => category.id);
                    return termRegistrations.map((registration) => {
                        const paymentsForStudyTerm = studentPayments
                            .filter(payment => payment.studyTerm &&
                                payment.studyTerm.studyYearStartYear === registration.studyTerm.studyYearStartYear &&
                                payment.studyTerm.termIndex === registration.studyTerm.termIndex);
                        return {
                            ...registration,
                            studentUnionMembershipFeePaid: paymentsForStudyTerm
                                .some(payment => studentUnionMembershipFeePaymentCategoryIds.includes(payment.paymentCategoryId)),
                        };
                    });
                }));
    }

    /**
     * Resolves the "primary" modules (i.e. top-level modules) of the accepted selection path of a study right.
     * For each module group id, the returned version is chosen by first resolving the curriculum period which is active
     * during the study right validity start date, and then selecting the active version of the module which references
     * that curriculum period.
     *
     * This version selection logic is primarily meant for resolving the correct tuition fee for paid study rights, and
     * the same logic is not guaranteed to be applicable for other use cases.
     */
    resolveSelectionPathPrimarySelectionVersions(studyRight: StudyRight): Observable<SelectionPathModules>;
    resolveSelectionPathPrimarySelectionVersions(
        studyRightStartDate: LocalDateString,
        educationPhase1GroupId: OtmId,
        educationPhase2GroupId?: OtmId,
    ): Observable<SelectionPathModules>;
    resolveSelectionPathPrimarySelectionVersions(
        studyRightOrStartDate: StudyRight | LocalDateString,
        educationPhase1GroupId?: OtmId,
        educationPhase2GroupId?: OtmId,
    ): Observable<SelectionPathModules> {
        let startDate: LocalDateString;
        let phase1GroupId: OtmId;
        let phase2GroupId: OtmId;
        if (typeof studyRightOrStartDate === 'string') {
            startDate = studyRightOrStartDate;
            phase1GroupId = educationPhase1GroupId;
            phase2GroupId = educationPhase2GroupId;
        } else {
            startDate = studyRightOrStartDate?.valid?.startDate;
            phase1GroupId = studyRightOrStartDate?.acceptedSelectionPath?.educationPhase1GroupId;
            phase2GroupId = studyRightOrStartDate?.acceptedSelectionPath?.educationPhase2GroupId;
        }

        if (!startDate || !phase1GroupId) {
            return of({ phase1Module: null, phase2Module: null });
        }

        const universityOrgId = this.universityService.getCurrentUniversityOrgId();

        return this.curriculumPeriodEntityService.getByUniversityOrgId(universityOrgId)
            .pipe(
                take(1),
                switchMap((curriculumPeriods: CurriculumPeriod[]): Observable<Module[][]> => {
                    const curriculumPeriod = (curriculumPeriods || []).find(({ activePeriod }) =>
                        dateUtils.rangeContains(startDate, activePeriod));
                    if (!curriculumPeriod) {
                        return of([]) as Observable<Module[][]>;
                    }
                    return forkJoin([phase1GroupId, phase2GroupId].filter(Boolean).map(groupId => this.moduleEntityService.getByGroupIdsAndCurriculumPeriodIdAndDocumentStates([groupId], curriculumPeriod.id, ['ACTIVE'])));
                }),
                map(([phase1Modules, phase2Modules]) => ({
                    phase1Module: _.first(phase1Modules) || null,
                    phase2Module: _.first(phase2Modules) || null,
                })),
            );
    }

    /**
     * Returns the amount of study rights with the given `documentState`, grouped by their admission target ids.
     * Optionally the results can also be filtered by the document state of the corresponding student.
     *
     * Study rights with no admission target id are grouped under an entry with an empty string as the key.
     *
     * @param documentState The `documentState` of the study rights to count
     * @param studentDocumentState The `documentState` of the students whose study rights to count
     */
    getStudyRightCountByAdmissionTarget(
        documentState: DocumentState,
        studentDocumentState?: DocumentState,
    ): Observable<{ [admissionTargetId: string]: number }> {
        return this.getHttp().get<{ [admissionTargetId: string]: number }>(
            CONFIG.ENDPOINTS.studyRightCountByAdmissionTarget,
            { params: _.omitBy({ documentState, studentDocumentState }, _.isEmpty) },
        );
    }

    /**
     * Returns a pipeable RxJS operator function which can be applied on an observable emitting arrays of study rights.
     * This operator will filter the emitted arrays to only contain study rights based on whether they require term
     * registrations or not (depending on the value of `isRequired`).
     *
     * @param isRequired If true, will filter out all study rights that don't require term registrations. If false, filters
     * out all study rights that do require term registrations.
     */
    filterByTermRegistrationRequirement(isRequired: boolean): (source: Observable<StudyRight[]>) => Observable<StudyRight[]> {
        return (source: Observable<StudyRight[]>) => source.pipe(
            switchMap(studyRights => combineLatest([
                of(studyRights),
                forkJoin(studyRights.reduce((acc, sr) => ({ ...acc, [sr.id]: this.isTermRegistrationRequired(sr) }), {})),
            ])),
            map(([studyRights, requirementMap]) => studyRights.filter(studyRight => requirementMap[studyRight.id] === isRequired)),
        );
    }

    isTermRegistrationRequired(studyRight: StudyRight): Observable<boolean> {
        if (!studyRight?.educationId) {
            return throwError(() => new Error('Study right or education id was missing'));
        }
        return this.educationEntityService.getById(studyRight.educationId).pipe(
            take(1),
            switchMap(education => this.termRegistrationRequirementsService.isTermRegistrationRequired(education.educationType)),
        );
    }

    startCooperationNetworkRightUpdateJob(cooperationNetworkId: OtmId): Observable<void> {
        if (!cooperationNetworkId) {
            return throwError(() => new Error('Cooperation network id missing'));
        }
        return this.getHttp().post<void>(
            CONFIG.ENDPOINTS.cooperationNetworkRightUpdate,
            null,
            { params: { cooperationNetworkId } },
        );
    }

    private toQueryParams(searchRequest?: Partial<StudyRightSearchRequest>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }
        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                admissionTargetId: searchRequest.admissionTargetId,
                personalIdentityCode: !searchRequest.personalIdentityCode ? null : searchRequest.personalIdentityCode.trim().toUpperCase(),
            },
            _.isEmpty,
        );
    }

    isActivePhase(studyRight: StudyRight, phaseOption: PreviewModelObject, phaseId: PhaseId): boolean {
        if (!phaseOption.matchesAcceptedSelectionPath) {
            return false;
        }
        return phaseId === this.getActivePhaseId(studyRight);
    }

    hasPhase1Personalized(studyRight: StudyRight): boolean {
        return !!studyRight?.personalizedSelectionPath?.phase1;
    }

    hasPhase2Personalized(studyRight: StudyRight): boolean {
        return !!studyRight?.personalizedSelectionPath?.phase2;
    }

    private getActivePhaseId(studyRight: StudyRight): PhaseId {
        if (!this.hasPhaseGraduationDatePassed(studyRight, 'phase1')) {
            return 'phase1';
        }
        return this.hasPhaseGraduationDatePassed(studyRight, 'phase2') ? null : 'phase2';
    }

    private hasPhaseGraduationDatePassed(studyRight: StudyRight, phaseId: PhaseId): boolean {
        const srGraduation = studyRight.studyRightGraduation;
        const phaseGraduationDate = phaseId === 'phase1' ? srGraduation?.phase1GraduationDate : srGraduation?.phase2GraduationDate;
        return !_.isNil(phaseGraduationDate) && moment(phaseGraduationDate).isBefore(moment(), 'days');
    }

    /**
     * Returns expiration information for the given studyRights limited to a single student. Information is returned
     * for study rights that have expired or are expiring in the current study term.
     *
     * The returned information is tailored for being shown in a context notification.
     *
     * @param studentId Student whose study rights and extension applications to examine
     * @param studyRights Study rights to examine. Should belong to student specified with studentId.
     * @returns Array of StudyRightExpirationInfo objects
     */
    getStudyRightExpirationInfosForStudyRights(studentId: OtmId, studyRights: StudyRight[]): Observable<StudyRightExpirationInfo[]> {
        if (_.isEmpty(studyRights)) {
            return of([]);
        }
        if (studyRights.some(sr => sr.studentId !== studentId)) {
            throw new Error('Not all study rights do not belong to the given student');
        }

        return this.getStudyRightExpirationInfos(studentId, studyRights, true);
    }

    getStudyRightExpirationInfos(studentId: OtmId, studyRights: readonly StudyRight[], acceptExpired: boolean): Observable<StudyRightExpirationInfo[]> {
        if (studyRights.some(sr => sr.studentId !== studentId)) {
            throw new Error('Not all study rights do not belong to the given student');
        }

        const requiredApplicationStates: StudentApplicationState[] = ['REQUESTED', 'IN_HANDLING'];
        const fetchPendingExtensionApplications = this.studentApplicationEntityService.getApplicationsForStudentByTypes(
            studentId,
            ['STUDY_RIGHT_EXTENSION_APPLICATION'],
        ).pipe(
            map((applications: StudyRightExtensionApplication[]) => applications.filter(application => requiredApplicationStates.some(state => state === application.state))),
        );

        const requiredStudyRightStates: StudyRightState[] = ['ACTIVE', 'ACTIVE_NONATTENDING', 'NOT_STARTED', 'PASSIVE'];
        return combineLatest([
            fetchPendingExtensionApplications,
            of(studyRights),
            this.educationEntityService.getByIds(studyRights.map(sr => sr.educationId)),
        ]).pipe(
            map(([applications, srs, educations]) => {
                const now = moment();

                return srs.filter(sr => {
                    const education = educations.find(edu => edu?.id === sr.educationId);
                    return education?.educationType?.startsWith('urn:code:education-type:degree-education');
                }).filter(
                    sr => requiredStudyRightStates.includes(sr.state),
                )
                    .filter(sr => !!sr.valid?.endDate)
                    .filter(sr => !sr.transferOutDate)
                    .filter(sr => acceptExpired || moment(sr.valid.endDate).isAfter(now))
                    .map(sr => {
                        const pendingApplication = applications.find(appl => appl.studyRightId === sr.id);
                        const endDate = moment(sr.valid.endDate);
                        return <StudyRightExpirationInfo>{
                            studyRight: sr,
                            studyRightId: sr.id,
                            isExpired: !endDate.isAfter(now),
                            inclusiveEndDate: endDate.subtract(1, 'day').format('YYYY-MM-DD'),
                            pendingExtensionApplication: pendingApplication || null,
                        };
                    })
                    .filter(sr => getStudyTermsElapsedBetweenDates(now, sr.inclusiveEndDate) <= 0);
            }),
        );
    }

    getValidCooperationNetworkIds(studyRight: StudyRight) {
        const currentMoment = moment();
        return _.chain(studyRight.cooperationNetworkRights)
            .filter(cooperationNetworkRight =>
                !(cooperationNetworkRight.validityPeriod?.endDate && currentMoment.isSameOrAfter(cooperationNetworkRight.validityPeriod.endDate)))
            .map(cooperationNetworkRight => cooperationNetworkRight.cooperationNetworkId)
            .value();
    }
}

export enum StudyRightResultItemsRelation {
    STUDY_RIGHTS = 'studyRights',
    EDUCATIONS = 'educations',
    ADMISSION_TARGETS = 'admissionTargets',
}

export interface StudyRightResultItemsWithResolvedData {
    studyRightResultItems: StudyRightResultItem[];
    [StudyRightResultItemsRelation.STUDY_RIGHTS]?: StudyRight[];
    [StudyRightResultItemsRelation.EDUCATIONS]?: Education[];
    [StudyRightResultItemsRelation.ADMISSION_TARGETS]?: AdmissionTarget[];
}

type StudyRightEntityState = EntityState<StudyRight, OtmId>;

@StoreConfig({ name: 'study-rights' })
class StudyRightStore extends EntityStore<StudyRightEntityState> {
}

class StudyRightQuery extends QueryEntity<StudyRightEntityState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<StudyRightEntityState>) {
        super(store);
    }
}

type StudyRightActivePhaseModuleGroupIdEntityState = EntityState<StudyRightActivePhaseModuleGroupId, OtmId>;

@StoreConfig({ name: 'study-right-active-phase-module-group-ids', idKey: 'studyRightId' })
class StudyRightActivePhaseModuleGroupIdStore extends EntityStore<StudyRightActivePhaseModuleGroupIdEntityState> {
}

class StudyRightActivePhaseModuleGroupIdQuery extends QueryEntity<StudyRightActivePhaseModuleGroupIdEntityState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<StudyRightActivePhaseModuleGroupIdEntityState>) {
        super(store);
    }
}

