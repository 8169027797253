import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { OrganisationRoleShare, PersonWithModuleResponsibilityInfoType } from 'common-typescript/types';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-course-unit-info-responsibility-teachers-contact-info',
    templateUrl: './course-unit-info-responsibility-teachers-contact-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoResponsibilityTeachersContactInfoComponent implements OnChanges {
    @Input() responsibilityInfos: PersonWithModuleResponsibilityInfoType[];
    @Input() organisations: OrganisationRoleShare[];
    @Input() language: string;

    hasCoordinatingOrganisations = false;

    COORDINATING_ORGANISATION_ROLE_URN = 'urn:code:organisation-role:coordinating-organisation';
    RESPONSIBLE_ORGANISATION_ROLE_URN = 'urn:code:organisation-role:responsible-organisation';

    ngOnChanges() {
        this.hasCoordinatingOrganisations = _.some(
            this.organisations,
            { roleUrn: this.COORDINATING_ORGANISATION_ROLE_URN },
        );
    }
}
