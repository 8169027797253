<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form [title]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.TITLE')" [level]="2" [errorSummaryTitle]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.FORM_ERROR_SUMMARY_INFO')">
        <fudis-form-content>
            <fudis-section [level]="2" [titleVariant]="'lg'" [title]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.GRADUATION_INSTRUCTIONS_TITLE')" class="fudis-mb-lg">
              <fudis-section-content>
                <app-graduation-instructions-url [organisationId]="data.organisationId"></app-graduation-instructions-url>
              </fudis-section-content>
            </fudis-section>
            <fudis-section [level]="2" [titleVariant]="'lg'" [title]="t(data.modalTitle)">
              <fudis-section-content>
                <fudis-body-text [variant]="'lg-light'">{{ t(data.infoText) }}</fudis-body-text>
                <fudis-body-text [variant]="'lg-light'">{{ t(data.infoQuestion) }}</fudis-body-text>
                <a fudisLink [title]="t(data.continueButtonText)" class="fudis-mt-sm" [external]="true" [href]="getLocalizedSurveyUrl()"></a>
                <fudis-checkbox-group [formGroup]="surveyFormGroup" class="fudis-mt-lg"
                                      [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.CONFIRM_LABEL')">
                  <fudis-checkbox [controlName]="'surveyCompleted'"
                                  [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.CONFIRM_VALUE')"></fudis-checkbox>
                </fudis-checkbox-group>
              </fudis-section-content>
            </fudis-section>
          <fudis-body-text [variant]="'lg-light'" class="fudis-mt-lg">{{ t('GRADUATION.APPLY_FOR_GRADUATION_MODAL.INFO_TEXT') }}</fudis-body-text>
        </fudis-form-content>
        <fudis-form-actions>
          <fudis-button fudisDialogClose variant="secondary" [label]="t('CANCEL')"/>
          <fudis-button fudisFormSubmit [formValid]="surveyFormGroup.valid"
                        [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.APPLY_FOR_GRADUATION_BUTTON')"
                        (handleClick)="applyForGraduation()"/>
        </fudis-form-actions>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
