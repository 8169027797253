<ng-container *transloco="let t">
  <ng-container *ngIf="attainment | attainmentName | async | localizedString as attainmentName">
    <div class="modal-header">
      <h2 id="attainment-details-heading">{{attainmentName}}, {{ attainment | attainmentCode | async }}</h2>
      <button type="button"
              class="modal-close"
              (click)="modal.dismiss()"
              [attr.aria-label]="t('ARIA_LABEL.CLOSE')">
        <sis-icon icon="ring-close"></sis-icon>
      </button>
    </div>
    <sis-contextual-notification *ngIf="!attainment.primary" variant="warning" class="form-group">
      <div>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.SECONDARY_ATTAINMENT_NOTIFICATION')}}</div>
    </sis-contextual-notification>
    <sis-contextual-notification *ngIf="externallyImportedDPAttainment" variant="danger" class="form-group">
      <div>{{t('ATTAINMENTS.ATTAINMENT_DETAILS.DEGREE_PROGRAM_ATTAINMENT_EXTERNAL_EVALUATION_WARNING')}}</div>
    </sis-contextual-notification>
    <sis-contextual-notification *ngIf="attainment.misregistration" variant="danger" class="form-group">
      <p>
        {{t('ATTAINMENTS.ATTAINMENT_DETAILS.MISREGISTRATION_NOTIFICATION')}}
      </p>
      <p>
        {{t('ATTAINMENTS.ATTAINMENT_DETAILS.MISREGISTRATION_RATIONALE')}}:
        {{ attainment.misregistrationRationale }}
      </p>
    </sis-contextual-notification>
    <div class="modal-body">
      <sis-degree-programme-attainment-details *ngIf="attainment.type === 'DegreeProgrammeAttainment'"
                                               [attainment]="$any(attainment)"
                                               [expirationImminent]="expirationImminent">
      </sis-degree-programme-attainment-details>
      <sis-module-attainment-details *ngIf="attainment.type === 'ModuleAttainment'"
                                     [attainment]="$any(attainment)"
                                     [secondaryAttainments]="secondaryAttainments"
                                     [expirationImminent]="expirationImminent">
      </sis-module-attainment-details>
      <sis-custom-module-attainment-details *ngIf="attainment.type === 'CustomModuleAttainment'"
                                            [attainment]="$any(attainment)"
                                            [secondaryAttainments]="secondaryAttainments"
                                            [expirationImminent]="expirationImminent">
      </sis-custom-module-attainment-details>
      <sis-course-unit-attainment-details *ngIf="attainment.type === 'CourseUnitAttainment'"
                                          [attainment]="$any(attainment)"
                                          [secondaryAttainments]="secondaryAttainments"
                                          [expirationImminent]="expirationImminent">
      </sis-course-unit-attainment-details>
      <sis-custom-course-unit-attainment-details *ngIf="attainment.type === 'CustomCourseUnitAttainment'"
                                                 [attainment]="$any(attainment)"
                                                 [secondaryAttainments]="secondaryAttainments"
                                                 [expirationImminent]="expirationImminent">
      </sis-custom-course-unit-attainment-details>
      <sis-assessment-item-attainment-details *ngIf="attainment.type === 'AssessmentItemAttainment'"
                                              [attainment]="$any(attainment)"
                                             [secondaryAttainments]="secondaryAttainments"
                                             [expirationImminent]="expirationImminent">>
      </sis-assessment-item-attainment-details>
    </div>
    <div class="modal-footer">
      <sis-button (clicked)="modal.dismiss()">{{ t('COMMON.CLOSE') }}</sis-button>
    </div>
  </ng-container>
</ng-container>
