import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Module, ModuleResultItem, OtmId, SearchResult, UniversityOrganisation } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_MODULE_SERVICE, SEARCH_PARAMETERS } from '../../ajs-upgraded-modules';
import { filterInput } from '../../typeahead/typeahead.component';
import { convertAJSPromiseToNative } from '../../util/utils';

interface Selection {
    name: string;
    id: OtmId;
}

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-module-search',
    templateUrl: './module-search.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleSearchComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.module.moduleSearch',
        directiveName: 'sisModuleSearch',
    };

    @Input() disabled = false;
    @Input() includeDrafts = false;

    /** This component will clear model if university is changed so that 'model' (module) hopefully always belongs to
        'university'. However it is possible to have module from 'wrong university' by updating 'model' binding. */
    @Input() model?: Module | null;
    @Output() modelChange = new EventEmitter<Module>();
    @Input() moduleType: string;
    @Input() required = false;
    /** Clears selection if university changes (needed).
        Clears initial module on initialisation, fix this if needed. */
    @Input() university: UniversityOrganisation;

    selection?: Selection;
    truncated = false;

    constructor(private localeService: LocaleService,
                private translateService: TranslateService,
                @Inject(COMMON_MODULE_SERVICE) private commonModuleService: any,
                @Inject(SEARCH_PARAMETERS) private SearchParameters: any) {
        this.doSearch = this.doSearch.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (_.get(changes, 'university.previousValue.id') !== _.get(changes, 'university.currentValue.id')) {
            this.model = undefined;
            this.updateSelection();
        }
        if (_.has(changes, 'model')) {
            this.updateSelection();
        }
    }

    onTextClick(event: MouseEvent): void {
        const target = event.target as HTMLInputElement;
        if (target.select) {
            target.select();
        }
    }

    removeSelection(): void {
        this.updateModel(null);
    }

    onSelect(selectedSearchResultObject: ModuleResultItem): void {
        if (!_.get(selectedSearchResultObject, 'id')) {
            this.removeSelection();
        } else {
            this.commonModuleService.findById(selectedSearchResultObject.id)
                .then((module: Module) => this.updateModel(module));
        }
    }

    doSearch(textQuery$: Observable<string>): Observable<ModuleResultItem[]> {
        return textQuery$.pipe(
            filterInput(),
            switchMap((textQuery) => {
                const params = new this.SearchParameters();
                params.universityOrgId.toggleValue(this.university);
                params.searchString.toggleValue(textQuery);
                if (!_.isNil(this.moduleType)) {
                    params.moduleType.toggleValue({ id: this.moduleType });
                }
                if (this.includeDrafts) {
                    params.setOverridingDefaults({ documentState: ['ACTIVE', 'DRAFT'] });
                    return from(convertAJSPromiseToNative(this.commonModuleService.search(params)));
                }
                return from(convertAJSPromiseToNative(this.commonModuleService.searchActive(params)));
            }),
            map((result: SearchResult<ModuleResultItem>) => {
                this.truncated = result.truncated;
                if (result.total > result.searchResults.length) {
                    result.searchResults = this.appendTooManyResultsNotifierObject(result.searchResults);
                }
                return result.searchResults;
            }),
        );
    }

    renderModule(module: ModuleResultItem): string {
        return module.name;
    }

    private updateSelection(): void {
        if (this.model) {
            this.selection = {
                name: this.localeService.localize(this.model.name),
                id: this.model.id,
            };
        } else {
            this.selection = undefined;
        }
    }

    private updateModel(module: Module | null): void {
        const emitEvent = this.model !== null || module !== null;
        this.model = module;
        this.updateSelection();
        // With this if we won't be spamming nulls into event emitter
        if (emitEvent) {
            this.modelChange.emit(module);
        }
    }

    private appendTooManyResultsNotifierObject(results: ModuleResultItem[]): ModuleResultItem[] {
        const notifier = {
            name: this.translateService.instant('SIS_COMPONENTS.MODULE_SEARCH.TOO_MANY_RESULTS'),
            disabled: true,
        } as Partial<ModuleResultItem>;
        return results.concat(notifier as ModuleResultItem);
    }
}
