import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ISO_LOCAL_TIME_FORMAT,
    MaxLength,
    MaxSize,
    MOMENT_DATE_DISPLAY_FORMAT,
    MOMENT_DATE_TIME_DISPLAY_FORMAT,
    MOMENT_TIME_DISPLAY_FORMAT,
} from 'common-typescript/constants';
import { SisValidationErrors, SisValidatorFn } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment, { ISO_8601 } from 'moment';

import {
    getLocalDateRangeEditorValue,
    getLocalDateTimeEditorValue,
    getLocalDateTimeRangeEditorValue,
    getLocalTimeRangeEditorValue,
    getNumericInputValue,
    isEmptyInputValue,
    isFormGroup,
} from './formUtils';

/**
 * Validator function that requires that all child controls of the given control have non-empty values.
 * If the control doesn't have child controls it is considered to be valid.
 *
 * @param errorKey The translation key of the validation error.
 * @param ignoreUntouched Ignore untouched child controls in validity check. This can be useful for
 * example when child controls are removed and added dynamically.
 * @return A validator function that returns an error map with the `allChildValuesRequired` property if the
 * validation check fails, otherwise null
 */
export function allChildValuesRequired(errorKey: string, ignoreUntouched: boolean = false): SisValidatorFn {
    return (control: FormGroup | FormArray) => {
        if (_.isNil(control) || _.isEmpty(control.controls)) {
            return null;
        }
        let childControls = Object.values(control.controls);
        if (ignoreUntouched) {
            childControls = childControls.filter(child => child.touched);
        }
        const anyValuesMissing = childControls.map(child => required()(child)).some(error => !_.isNil(error));
        return anyValuesMissing ? { allChildValuesRequired: { translationKey: errorKey } } : null;
    };
}

export function email(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.EMAIL'): SisValidatorFn {
    return (control: AbstractControl) => _.isNil(Validators.email(control)) ? null : { email: { translationKey: errorKey } };
}

/**
 * Validates that the form control value is parseable to a whole number. Null and empty values are considered valid.
 */
export function isInteger(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.IS_INTEGER'): SisValidatorFn {
    return (control: FormControl) => {
        if (isEmptyInputValue(control?.value) || _.isInteger(getNumericInputValue(control))) {
            return null;
        }
        return { isInteger: { translationKey: errorKey } };
    };
}

/**
 * Validates that the form control value is parseable to a finite number. Null and empty values are considered valid.
 */
export function isNumber(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.IS_NUMBER'): SisValidatorFn {
    return (control: FormControl) => {
        if (isEmptyInputValue(control?.value) || _.isFinite(getNumericInputValue(control))) {
            return null;
        }
        return { isNumber: { translationKey: errorKey } };
    };
}

export function localDate(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.LOCAL_DATE'): SisValidatorFn {
    return (control: FormControl) => !control.value || moment(control.value, MOMENT_DATE_DISPLAY_FORMAT, true).isValid() ?
        null : { localDate: { translationKey: errorKey } };
}

/**
 * Validator function that requires all provided locale form groups to have values with same keys.
 * As an example, ensures that all have values in fi-column and none in sv- and en-columns.
 * Has to be called with .setValidators([]) after initial form building
 *
 * @param errorKey Error message key
 * @param localeFormGroups form groups to validate
 * @param errorKeyForValues optional parameter for alternative error message for fields with missing values
 */
export function localesMustMatch(errorKey: string, localeFormGroups: FormGroup[], errorKeyForValues?: string): SisValidatorFn {
    return (control: FormGroup) => {
        if (!control || !control.value) {
            return null;
        }
        const localesValid = _.every(_.keys(control.value), (key) => _.every(localeFormGroups, (localeToValidate) => localeToValidate.value[key] ? control.value[key] : !control.value[key]));
        if (localesValid) {
            return null;
        }
        if (!errorKeyForValues) {
            return { localesMustMatch: { translationKey: errorKey } };
        }
        _.keys(control.value).forEach((k) => {
            localeFormGroups.forEach(formGroup => (!formGroup.value[k]) && delete formGroup.value[k]);
            return (!control.value[k]) && delete control.value[k];
        });
        const mostKeys = _.maxBy(localeFormGroups, formGroup => _.keys(formGroup.value).length);
        const missingFieldsErrorKey = hasMissingFields(errorKeyForValues, mostKeys)(control);
        return missingFieldsErrorKey ? missingFieldsErrorKey : { localesMustMatch: { translationKey: errorKey } };
    };
}

export function localTime(errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_TIME_EDITOR.INVALID_TIME'): SisValidatorFn {
    return (control: FormControl) => !control.value || moment(control.value, MOMENT_TIME_DISPLAY_FORMAT, true).isValid() ?
        null : { localTime: { translationKey: errorKey } };
}

/**
 * Validates that the form control value is a number less than or equal to the given value (analogous to
 * {@link Validators.max}). The control value can either be a primitive number or a string (see
 * {@link getNumericInputValue}). The error object contains the `max` value in the translation params, making
 * it possible to display it in the localized error message.
 */
export function max(maxValue: number, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX'): SisValidatorFn {
    if (!_.isFinite(maxValue)) {
        return noOpValidator();
    }
    return (control: FormControl) => {
        const value = getNumericInputValue(control);
        return !_.isNumber(value) || value <= maxValue ? null : {
            max: {
                translationKey: errorKey,
                translationParams: { max: maxValue },
            },
        };
    };
}
export function atMost(otherControl: FormControl, errorKey: string): SisValidatorFn {
    return (control: FormControl) => {
        const value = getNumericInputValue(control);
        const otherValue = getNumericInputValue(otherControl);
        return (!_.isNumber(value) || !_.isNumber(otherValue)) || value <= otherValue ? null : {
            atMost: {
                translationKey: errorKey,
            },
        };
    };
}

/**
 * "Wrapper" for {@link Validators.maxLength} for arrays. The error object contains the `maxSize` value in the translation params,
 * making it possible to display it in the localized error message.
 */
export function maxArrayLength(maxSize: MaxSize, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_SIZE'): SisValidatorFn {
    if (!_.isFinite(maxSize) || maxSize < 1) {
        return noOpValidator();
    }
    return (control: AbstractControl) =>
        _.isNil(Validators.maxLength(maxSize)(control)) ? null : {
            maxSize: {
                translationKey: errorKey,
                translationParams: { maxSize },
            },
        };
}

/**
 * Validates that the form control value is earlier than (and not equal to) the given date. Can be applied to a local
 * date time editor component, or any `FormControl` where the value is a date string in the Finnish locale (e.g.
 * '1.1.2020'), such as the local date editor component.
 *
 * @param date The maximum date to validate against. If the date contains a time part, the time will be taken into
 * account in the comparisons.
 * @param errorKey The translation key of the validation error. The validator will provide the given maximum date
 * as a translation parameter with the key 'maxDate', so it can be used in the translated value.
 */
export function maxDate(date: moment.MomentInput, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_DATE'): SisValidatorFn {
    const parsedMaxDate = moment(date, moment.ISO_8601);
    if (!parsedMaxDate.isValid()) {
        return noOpValidator();
    }
    return (control: FormControl) => {
        // If the control is a FormGroup, assume it's for a date time editor. Otherwise assume it's for a date editor.
        const controlDate = isFormGroup(control) ?
            moment(getLocalDateTimeEditorValue(control), ISO_8601) : moment(control.value, MOMENT_DATE_DISPLAY_FORMAT, true);
        if (controlDate.isValid() && controlDate.isSameOrAfter(parsedMaxDate)) {
            const format = isFormGroup(control) ? MOMENT_DATE_TIME_DISPLAY_FORMAT : MOMENT_DATE_DISPLAY_FORMAT;
            return {
                maxDate: {
                    translationKey: errorKey,
                    translationParams: { maxDate: parsedMaxDate.format(format) },
                },
            };
        }

        return null;
    };
}

export function maxDuration(duration: moment.Duration, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_DURATION'): SisValidatorFn {
    if (!duration.isValid()) {
        return noOpValidator();
    }
    return (formGroup: FormGroup) => {
        const range = getLocalTimeRangeEditorValue(formGroup);
        if (range && moment.duration(range.duration).asMilliseconds() > duration.asMilliseconds()) {
            return {
                maxDuration: {
                    translationKey: errorKey,
                },
            };
        }
        return null;
    };
}

/**
 * Wrapper for {@link Validators.maxLength}. The error object contains the `maxLength` value in the translation params,
 * making it possible to display it in the localized error message.
 */
export function maxLength(length: MaxLength | number, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH'): SisValidatorFn {
    if (!_.isFinite(length) || length < 1) {
        return noOpValidator();
    }
    return (control: AbstractControl) =>
        _.isNil(Validators.maxLength(length)(control)) ? null : {
            maxLength: {
                translationKey: errorKey,
                translationParams: { maxLength: length },
            },
        };
}

export function maxLocalTime(time: moment.MomentInput, errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_TIME_EDITOR.MAX_TIME'): SisValidatorFn {
    const maxTime = moment(time, MOMENT_TIME_DISPLAY_FORMAT);
    if (!maxTime.isValid()) {
        return noOpValidator();
    }
    return (control: FormControl) => {
        const controlTime = moment(control.value, MOMENT_TIME_DISPLAY_FORMAT);
        if (controlTime.isAfter(maxTime)) {
            return {
                maxLocalTime: {
                    translationKey: errorKey,
                },
            };
        }
        return null;
    };
}

/**
 * Validates that the form control value is a number greater than or equal to the given value (analogous to
 * {@link Validators.min}). The control value can either be a primitive number or a string (see
 * {@link getNumericInputValue}). The error object contains the `min` value in the translation params, making
 * it possible to display it in the localized error message.
 */
export function min(minValue: number, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN'): SisValidatorFn {
    return minValidator(minValue, errorKey, true);
}

/**
 * Validates that the form control value is equal to or later than the given date. Can be applied to a local date time
 * editor component, or any `FormControl` where the value is a date string in the Finnish locale (e.g. '1.1.2020'),
 * such as the local date editor component.
 *
 * @param date The minimum date to validate against. If the date contains a time part, the time will be taken into
 * account in the comparisons.
 * @param errorKey The translation key of the validation error. The validator will provide the given minimum date
 * as a translation parameter with the key 'minDate', so it can be used in the translated value.
 */
export function minDate(date: moment.MomentInput, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN_DATE'): SisValidatorFn {
    const parsedMinDate = moment(date, moment.ISO_8601);
    if (!parsedMinDate.isValid()) {
        return noOpValidator();
    }
    return (control: FormGroup | FormControl) => {
        // If the control is a FormGroup, assume it's for a date time editor. Otherwise assume it's for a date editor.
        const controlDate = isFormGroup(control) ?
            moment(getLocalDateTimeEditorValue(control), ISO_8601) : moment(control.value, MOMENT_DATE_DISPLAY_FORMAT, true);
        if (controlDate.isValid() && controlDate.isBefore(parsedMinDate)) {
            const format = isFormGroup(control) ? MOMENT_DATE_TIME_DISPLAY_FORMAT : MOMENT_DATE_DISPLAY_FORMAT;
            return {
                minDate: {
                    translationKey: errorKey,
                    translationParams: { minDate: parsedMinDate.format(format) },
                },
            };
        }

        return null;
    };
}

export function minExclusive(minValue: number, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN_EXCLUSIVE'): SisValidatorFn {
    return minValidator(minValue, errorKey, false);
}

/**
 * Wrapper for {@link Validators.minLength}. The error object contains the `minLength` value in the translation params,
 * making it possible to display it in the localized error message.
 */
export function minLength(length: number, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN_LENGTH'): SisValidatorFn {
    if (!_.isFinite(length) || length < 1) {
        return noOpValidator();
    }
    return (control: AbstractControl) =>
        _.isNil(Validators.minLength(length)(control)) ? null : {
            minLength: {
                translationKey: errorKey,
                translationParams: { minLength: length },
            },
        };
}

export function minLocalTime(time: moment.MomentInput, errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_TIME_EDITOR.MIN_TIME'): SisValidatorFn {
    const minTime = moment(time, MOMENT_TIME_DISPLAY_FORMAT);
    if (!minTime.isValid()) {
        return noOpValidator();
    }
    return (control: FormControl) => {
        const controlTime = moment(control.value, MOMENT_TIME_DISPLAY_FORMAT);
        if (controlTime.isSameOrBefore(minTime)) {
            return {
                minLocalTime: {
                    translationKey: errorKey,
                },
            };
        }
        return null;
    };
}

/**
 * Validator that expects the given control to have child controls `min` and `max`, that both controls have numeric values (or a
 * string that can be parsed into a number), and that the value of `min` is less or equal to the value of `max`. If either control is
 * missing, has no value, or has a non-numeric value, the control is considered valid.
 */
export function minLessOrEqualToMax(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MIN_LESS_OR_EQUAL_TO_MAX'): SisValidatorFn {
    return (control: AbstractControl) => {
        if (!isFormGroup(control)) {
            return null;
        }
        const minValue = getNumericInputValue(control?.get('min') as FormControl);
        const maxValue = getNumericInputValue(control?.get('max') as FormControl);
        if (minValue !== null && maxValue !== null && minValue > maxValue) {
            return { minLessOrEqualToMax: { translationKey: errorKey } };
        }

        return null;
    };
}

/**
 * Validates that the input element has a certain property, as an example id or name.
 */
export function mustHaveProperty(property: string, errorMessage: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'): SisValidatorFn {
    return (control: AbstractControl) => !control.value || !control.value[property] ? { mustHaveProperty: { translationKey: errorMessage } } : null;
}

/**
 * Validator function that requires that at least one child control of the given control has a non-empty value.
 * If the control doesn't have child controls it is considered to be valid.
 *
 * @return A validator function that returns an error map with the `oneChildValueRequired` property if the
 * validation check fails, otherwise null.
 */
export function oneChildValueRequired(errorKey: string): SisValidatorFn {
    return (control: FormGroup | FormArray) => {
        if (_.isNil(control) || _.isEmpty(control.controls)) {
            return null;
        }
        const childControls = Object.values(control.controls);
        const allValuesMissing = childControls.map(child => required()(child)).every(error => !_.isNil(error));
        return allValuesMissing ? { oneChildValueRequired: { translationKey: errorKey } } : null;
    };
}

/**
 * Conditional version of the oneChildValueRequired validator
 *
 * @param predicate Condition for this validator to be active
 * @param errorKey Translation key for error message
 */
export function oneChildValueRequiredIf(predicate: () => boolean, errorKey: string): SisValidatorFn {
    return (control: AbstractControl) => predicate() ? oneChildValueRequired(errorKey)(control) : null;
}

/**
 * Validator function that requires that one child control value is true. Intended to be used with checkbox groups.
 *
 * @param errorKey The translation key of the validation error
 */
export function oneChildValueSelected(errorKey?: string): SisValidatorFn {
    return (control: FormGroup | FormArray): SisValidationErrors => {
        if (_.isNil(control) || _.isEmpty(control)) {
            return null;
        }
        const someTrueForCondition = Object.values(control.controls).some((childControl: AbstractControl) => childControl.value);
        return !someTrueForCondition ? {
            oneChildValueRequired: { translationKey: errorKey },
        } : null;
    };
}

/**
 * Conditional version of the oneChildValueSelected validator. Intended to be used with checkbox groups.
 *
 * @param predicate Condition for this validator to be active
 * @param errorKey The translation key of the validation error
 */
export function oneChildValueSelectedIf(predicate: () => boolean, errorKey?: string): SisValidatorFn {
    return (control: FormGroup | FormArray) => predicate() ? oneChildValueSelected(errorKey)(control) : null;
}

export function pattern(regex: string | RegExp, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.PATTERN'): SisValidatorFn {
    return (control: AbstractControl) => _.isNil(Validators.pattern(regex)(control)) ? null : {
        pattern: {
            translationKey: errorKey,
            translationParams: { pattern: regex },
        },
    };
}

export function required(errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'): SisValidatorFn {
    return (control: AbstractControl) => _.isNil(Validators.required(control)) ? null : { required: { translationKey: errorKey } };
}

/**
 * A variant of `required` that only runs the validation if the given predicate returns true. Useful for conditional
 * validations e.g. where a field is only required if some other field(s) have a certain value.
 */
export function requiredIf(predicate: () => boolean, errorKey: string = 'SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'): SisValidatorFn {
    return (control: AbstractControl) => predicate() ? required(errorKey)(control) : null;
}

/**
 * Returns a validator meant for the local date range editor, which makes sure that startDate or endDate
 * is defined.
 */
export function startDateOrEndDateRequired(errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.START_OR_END_DATE_IS_REQUIRED'): SisValidatorFn {
    return (formGroup: FormGroup) => {
        const range = getLocalDateRangeEditorValue(formGroup);
        if (!range || (!range.startDate && !range.endDate)) {
            return { startOrEndRequired: { translationKey: errorKey } };
        }
        return null;
    };
}

/**
 * Returns a validator meant for the local date range editor, which makes sure the startDate and endDate
 * are in the correct order.
 */
export function startDateBeforeEndDate(errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_DATE_RANGE_EDITOR.START_DATE_MUST_BE_BEFORE_END_DATE'): SisValidatorFn {
    return (formGroup: FormGroup) => {
        const range = getLocalDateRangeEditorValue(formGroup);
        if (range && range.startDate && range.endDate && moment(range.startDate).isSameOrAfter(range.endDate)) {
            return { startDateBeforeEndDate: { translationKey: errorKey } };
        }
        return null;
    };
}

/**
 * Returns a validator meant for the local date time range editor, which makes sure the startDateTime and endDateTime
 * are in the correct order.
 */
export function startDateTimeBeforeEndDateTime(errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_DATE_TIME_RANGE_EDITOR.START_MUST_BE_BEFORE_END'): SisValidatorFn {
    return (formGroup: FormGroup) => {
        const range = getLocalDateTimeRangeEditorValue(formGroup);
        if (range && range.startDateTime && range.endDateTime && moment(range.startDateTime).isSameOrAfter(range.endDateTime)) {
            return { startDateTimeBeforeEndDateTime: { translationKey: errorKey } };
        }
        return null;
    };
}

/**
 * Returns a validator meant for the local time range editor, which makes sure that startTime is before endTime
 */
export function startTimeBeforeEndTime(errorKey: string = 'SIS_COMPONENTS.DATE.LOCAL_TIME_RANGE_EDITOR.END_MUST_BE_AFTER_START'): SisValidatorFn {
    return (formGroup: FormGroup) => {
        const range = getLocalTimeRangeEditorValue(formGroup);
        const startTime = moment(range.startTime, ISO_LOCAL_TIME_FORMAT, true);
        const endTime = moment(range.endTime, ISO_LOCAL_TIME_FORMAT, true);
        if (range && range.startTime && range.endTime && startTime.isSameOrAfter(endTime)) {
            return { startTimeBeforeEndTime: { translationKey: errorKey } };
        }
        return null;
    };
}

export function not<T>(value: T, errorKey: string): SisValidatorFn {
    return (control: FormControl<T>) => control.value === value ? {
        not: {
            translationKey: errorKey,
        },
    } : null;
}

export function cannotBeFilledIfOtherControlEmpty(otherControl: FormControl, errorKey: string): SisValidatorFn {
    return (control: FormControl) => otherControl.value === null && control.value !== null ? {
        cannotBeFilledIfOtherControlEmpty: {
            translationKey: errorKey,
        },
    } : null;
}

// Non-exported helper validators

/**
 * Helper validation function to find properties that are missing values.
 * Takes as input the form group with most properties with actual values, and then returns an error message with
 * all the missing properties of this form group. Returns null if this field has values in all properties.
 */
function hasMissingFields(errorKey: string, mostKeys: FormGroup): SisValidatorFn {
    return (control: FormGroup) => {
        const keysMissing: string[] = [];
        if (!mostKeys.value) {
            return null;
        }
        if (!control.value || _.keys(control.value).length < _.keys(mostKeys.value).length) {
            _.keys(mostKeys.value).forEach((key) => {
                if (!control.value || !control.value[key]) {
                    keysMissing.push(` ${key}`);
                }
            });
            return { hasMissingFields: { translationKey: errorKey, translationParams: { keysMissing } } };
        }
        return null;
    };
}

function minValidator(minValue: number, errorKey: string, inclusive: boolean) {
    if (!_.isFinite(minValue)) {
        return noOpValidator();
    }
    return (control: FormControl) => {
        const value = getNumericInputValue(control);
        const numberOk = !_.isNumber(value) || (inclusive ? value >= minValue : value > minValue);
        return numberOk ? null : {
            min: {
                translationKey: errorKey,
                translationParams: { min: minValue },
            },
        };
    };
}

function noOpValidator(): SisValidatorFn {
    return (): null => null;
}

