import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Education, OtmId, Plan } from 'common-typescript/types';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { PlanOption, PlanSelectService } from '../../../common/service/plan-select.service';

@Component({
    selector: 'app-choose-plan',
    templateUrl: './choose-plan.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        TranslateModule,
        NgbDropdownModule,
    ],
})
export class ChoosePlanComponent implements OnInit {

    @Input({ required: true }) plans: Plan[];
    @Input({ required: true }) planEducations: Education[];
    @Input({ required: true }) selectedPlanId: OtmId;
    @Output() selected = new EventEmitter<OtmId>();

    selectedPlan: Plan;
    options: PlanOption[];

    constructor(private planSelectService: PlanSelectService) {}

    ngOnInit() {
        this.selectPlan(this.selectedPlanId);
        this.options = this.planSelectService.createSelectOptionsFromPlansAndEducations(this.plans, this.planEducations);
        // TODO: Remove this temporary hack for the old component
        if (!this.planEducations) {
            this.options = this.planSelectService.createSelectOptions(this.plans);
        }
    }

    onSelect(planId: OtmId): void {
        this.selectPlan(planId);
        this.selected.emit(planId);
    }

    private selectPlan(planId: OtmId): void {
        this.selectedPlan = (this.plans || []).find(plan => plan.id === planId);
    }
}
