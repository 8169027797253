import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService, FudisGroupValidators } from '@funidata/ngx-fudis';
import { FudisCheckboxGroupFormGroup } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from '@uirouter/core';
import { LocalizedString, OtmId } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

export interface MandatoryGraduationSurveyValues {
    organisationId: OtmId,
    degreeProgrammeId: OtmId,
    planId: OtmId,
    surveyUrl: LocalizedString;
    infoText: string;
    infoQuestion: string;
    modalTitle: string;
    continueButtonText: string;
}

@Component({
    selector: 'app-mandatory-graduation-survey-modal',
    templateUrl: './mandatory-graduation-survey-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MandatoryGraduationSurveyModalComponent {
    surveyFormGroup: FormGroup<FudisCheckboxGroupFormGroup<object>>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MandatoryGraduationSurveyValues,
        private fudisDialogService: FudisDialogService,
        private localeService: LocaleService,
        private stateService: StateService,
        private transloco: TranslocoService,
    ) {
        this.surveyFormGroup = new FormGroup<FudisCheckboxGroupFormGroup<object>>({
            surveyCompleted: new FormControl<boolean>(false),
        }, [FudisGroupValidators.oneRequired(this.transloco.translate('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.REQUIRED_VALIDATOR_MESSAGE'))]);
    }

    getLocalizedSurveyUrl() {
        return this.localeService.localize(this.data.surveyUrl);
    }

    applyForGraduation() {
        if (this.surveyFormGroup.valid) {
            this.stateService.go('student.logged-in.profile.applications.create-degree-programme-attainment-application',
                                 {
                                     degreeProgrammeId: this.data.degreeProgrammeId,
                                     planId: this.data.planId,
                                 });
            this.fudisDialogService.close();
        }
    }
}
