import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';
import {
    Attainment,
    DegreeProgramme, DegreeProgrammeAttainment,
    DegreeProgrammeAttainmentWorkflow, LocalizedUrl,
    Module,
    ModuleAttainment,
    OtmId, StudentApplication,
    UniversitySettings,
    Workflow,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    combineLatest,
    combineLatestWith,
    merge,
    Observable, of,
    shareReplay,
    Subject,
    switchMap,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { AuthService } from 'sis-common/auth/auth-service';
import { ModalService } from 'sis-common/modal/modal.service';
import { isDegreeProgrammeAttainment, isModuleAttainment } from 'sis-components/attainment/AttainmentUtil';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_WORKFLOW_STATE } from 'sis-components/model/student-workflow-constants';
import { ModuleEntityService } from 'sis-components/service/module-entity.service';
import { UniversityService } from 'sis-components/service/university.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

import { AttainmentStudentService } from '../../../service/attainment-student.service';
import { PreviewModeService } from '../../../utils/preview-mode.service';
import { isAMKDegree, isBachelorsDegree, resolveGraduationModalValues } from '../../graduation/graduation-utils';
import {
    MandatoryGraduationSurveyModalComponent,
    MandatoryGraduationSurveyValues,
} from '../../graduation/mandatory-graduation-survey-modal/mandatory-graduation-survey-modal.component';
import { ModuleInfoModalValues } from '../module-info-modal.service';
import {
    ModuleInfoVersionChangeWarningComponent,
} from '../module-info-version-change-warning/module-info-version-change-warning.component';
import { ModuleInfoService } from '../module-info.service';

interface ModuleInfoModalData {
    showModuleAttainmentApplicationBlock: boolean;
    showDegreeProgrammeWorkflowRequestedBlock: boolean;
    showGraduationSection: boolean;
    compatibleModuleIds: OtmId[];
    module: Module;
    initialModuleAttainment: ModuleAttainment | DegreeProgrammeAttainment;
    selectedModuleAttainment: ModuleAttainment | DegreeProgrammeAttainment;
    degreeProgrammeAttainmentWorkflow: DegreeProgrammeAttainmentWorkflow;
    moduleVersions: Module[];
    canUseCurrentVersion: boolean;
}

@Component({
    selector: 'app-module-info-modal',
    templateUrl: './module-info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleInfoModalComponent implements OnInit {

    data$: Observable<Partial<ModuleInfoModalData>>;

    versionChangeSubject$: Subject<Module> = new Subject<Module>();
    @ViewChild('showModuleAttainmentApplicationButton') showModuleAttainmentApplicationButtonRef: ElementRef;

    attainmentApplicationSendPending = false;
    mandatoryGraduationModalValues: MandatoryGraduationSurveyValues;

    constructor(@Inject(ModalService.injectionToken) public values: ModuleInfoModalValues,
                private fudisDialogService: FudisDialogService,
                private modalService: ModalService,
                private universityService: UniversityService,
                private moduleEntityService: ModuleEntityService,
                private workflowEntityService: WorkflowEntityService,
                private attainmentStudentService: AttainmentStudentService,
                private appErrorHandler: AppErrorHandler,
                private authService: AuthService,
                private previewMode: PreviewModeService,
                private moduleInfoService: ModuleInfoService,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
                private stateService: StateService,
                private changeDetectorRef: ChangeDetectorRef,
                public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        const initialModule$ = this.createInitialModuleObservable();
        const selectedModule$ = merge(initialModule$, this.versionChangeSubject$);
        const moduleAttainments$ = this.createModuleAttainmentsObservable();
        const initialModuleAttainment$ = this.createModuleAttainmentObservable(moduleAttainments$, initialModule$);
        const selectedModuleAttainment$ = this.createModuleAttainmentObservable(moduleAttainments$, selectedModule$);
        const moduleVersions$ = this.createModuleVersionsObservable(initialModule$);
        const compatibleVersionIds$ = this.createCompatibleModuleVersionIdsObservable(moduleVersions$, initialModuleAttainment$, moduleAttainments$);
        const canUseCurrentVersion$ = this.createCanUseCurrentVersionObservable(compatibleVersionIds$, selectedModule$);
        const universitySettings$ = this.createUniversitySettingsObservable();
        // Study module specific observables
        const showModuleAttainmentApplicationBlock$ = universitySettings$.pipe(
            map((universitySettings) =>
                (universitySettings?.frontendFeatureToggles?.moduleAttainmentApplicationEnabled ?? true)
                && !!this.values?.moduleAttainmentApplicationWrapper),
        );
        // Degree programme specific observables
        const workflows$ = this.createStudentWorkflowsObservable();
        const degreeProgrammeAttainmentWorkflow$ = this.createDegreeProgrammeAttainmentWorkflowObservable(workflows$, selectedModule$);
        const showGraduationSection$ = this.createShowGraduationSectionObservable(universitySettings$, selectedModule$);
        const showDegreeProgrammeWorkflowRequestedBlock$ = degreeProgrammeAttainmentWorkflow$
            .pipe(map(workflow => !!workflow));

        this.data$ = combineLatest({
            showModuleAttainmentApplicationBlock: showModuleAttainmentApplicationBlock$,
            showDegreeProgrammeWorkflowRequestedBlock: showDegreeProgrammeWorkflowRequestedBlock$,
            degreeProgrammeAttainmentWorkflow: degreeProgrammeAttainmentWorkflow$,
            showGraduationSection: showGraduationSection$,
            moduleVersions: moduleVersions$,
            module: selectedModule$,
            initialModuleAttainment: initialModuleAttainment$,
            selectedModuleAttainment: selectedModuleAttainment$,
            canUseCurrentVersion: canUseCurrentVersion$,
        });
    }

    /**
     * Fetches the initial module that was given as the modal values-parameter.
     */
    createInitialModuleObservable(): Observable<Module> {
        return this.moduleEntityService.getById(this.values.moduleId)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    /**
     * Create an observable that returns available versions for the given input module.
     *
     * @param module$ Module that will be used to find the versions (by groupId)
     */
    createModuleVersionsObservable(module$: Observable<Module>): Observable<Module[]> {
        return module$.pipe(
            switchMap((module) => this.getAuthenticatedOrUnauthenticatedFindByGroupIdCall(module.groupId)
                .pipe(map((versions) => [versions, module])),
            ),
            map(([versions, module]: [Module[], Module]) => {
                if (!versions.find((version) => version.id === module.id)) {
                    return [...versions, module];
                }
                return versions;
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    /**
     * Return all compatible module version ids. Uses the ModuleInfoService.
     *
     * @param moduleVersions$
     * @param moduleAttainment$
     * @param moduleAttainments$
     */
    createCompatibleModuleVersionIdsObservable(moduleVersions$: Observable<Module[]>,
                                               moduleAttainment$: Observable<ModuleAttainment | DegreeProgrammeAttainment>,
                                               moduleAttainments$: Observable<(ModuleAttainment | DegreeProgrammeAttainment)[]>): Observable<OtmId[]> {
        return moduleVersions$.pipe(
            combineLatestWith(moduleAttainment$, moduleAttainments$),
            switchMap(([versions, attainment, attainments]) => {
                if (!attainment) {
                    return this.moduleInfoService.getModuleVersionCompatibility(versions, attainments);
                }
                return of([]);
            }),
        );
    }

    /**
     * Uses the authenticated call if we are in preview mode. Otherwise, use the unauthenticated endpoint.
     *
     * @param groupId GroupId of the module.
     */
    getAuthenticatedOrUnauthenticatedFindByGroupIdCall(groupId: OtmId): Observable<Module[]> {
        if (this.previewMode.isPreviewMode()) {
            return this.moduleEntityService.findByGroupId(groupId);
        }
        return this.moduleEntityService.findByGroupIdUnauthenticated(groupId);
    }

    /**
     * Creates an observable that returns valid attainments for the currently logged in student.
     * The valid attainments are then filtered to return only the ModuleAttainments and DegreeProgrammeAttainments.
     * If viewed in preview mode an empty array is returned instead.
     */
    createModuleAttainmentsObservable(): Observable<(ModuleAttainment | DegreeProgrammeAttainment)[]> {
        let validStudentAttainments$: Observable<Attainment[]> = of([]);
        if (!this.previewMode.isPreviewMode()) {
            validStudentAttainments$ = this.attainmentStudentService.getMyValidAttainments()
                .pipe(this.appErrorHandler.defaultErrorHandler());
        }
        return validStudentAttainments$
            .pipe(shareReplay(1),
                  map(attainments => attainments.filter(
                      attainment => isModuleAttainment(attainment) || isDegreeProgrammeAttainment(attainment),
                  ) as (ModuleAttainment | DegreeProgrammeAttainment)[]));
    }

    /**
     * Finds any ModuleAttainment or DegreeProgrammeAttainment that matches the currently selected module id.
     *
     * @param moduleAttainments$ List of attainments to search for the module attainment.
     * @param selectedModule$ The selected module to find the attainment for.
     */
    createModuleAttainmentObservable(moduleAttainments$: Observable<(ModuleAttainment | DegreeProgrammeAttainment)[]>,
                                     selectedModule$: Observable<Module>): Observable<ModuleAttainment | DegreeProgrammeAttainment> {
        return moduleAttainments$
            .pipe(
                combineLatestWith(selectedModule$),
                map(([attainments, selectedModule]) =>
                    attainments.find(attainment => attainment.moduleId === selectedModule.id)),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    /**
     * Returns true if compatibleVersionIds contains the selected module id.
     *
     * @param compatibleVersionIds$ Ids of modules that can be chosen.
     * @param selectedModule$ The module that is currently selected in the version selector.
     */
    createCanUseCurrentVersionObservable(compatibleVersionIds$: Observable<OtmId[]>, selectedModule$: Observable<Module>): Observable<boolean> {
        return compatibleVersionIds$.pipe(
            combineLatestWith(selectedModule$),
            map(([compatibleVersionIds, selectedModule]) => compatibleVersionIds.some((versionId) => versionId === selectedModule.id)),
        );
    }

    /**
     * Creates an observable that returns the current university settings with error handler.
     */
    createUniversitySettingsObservable(): Observable<UniversitySettings> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    /**
     * Fetches all workflows for the current user.
     * Returns an empty array if preview mode is enabled.
     */
    createStudentWorkflowsObservable(): Observable<Workflow[]> {
        let workflowObservable$ = of([]);
        if (!this.previewMode.isPreviewMode()) {
            workflowObservable$ = this.workflowEntityService.getWorkflowsByStudentId(this.authService.personId());
        }
        return workflowObservable$
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                shareReplay(1),
            );
    }

    /**
     * Searches for DegreeProgrammeAttainmentWorkflow related to the currently selected module.
     *
     * @param workflows$ Array of workflows to search.
     * @param selectedModule$ The module that the search is performed for.
     */
    createDegreeProgrammeAttainmentWorkflowObservable(workflows$: Observable<Workflow[]>, selectedModule$: Observable<Module>): Observable<DegreeProgrammeAttainmentWorkflow> {
        return workflows$
            .pipe(
                map((workflows) => workflows.filter(workflow => workflow.type === 'DegreeProgrammeAttainmentWorkflow')),
                combineLatestWith(selectedModule$),
                map(([workflows, selectedModule]) => (workflows as DegreeProgrammeAttainmentWorkflow[]).find(
                    workflow => workflow.moduleId === selectedModule.id
                        && [STUDENT_WORKFLOW_STATE.REQUESTED, STUDENT_WORKFLOW_STATE.IN_HANDLING]
                            .includes(workflow.state as STUDENT_WORKFLOW_STATE),
                )),
            );
    }

    /**
     * Graduation section is shown if the current module type is 'DegreeProgramme' and
     * degree programme attainment application are enabled in university settings.
     *
     * @param universitySettings$
     * @param selectedModule$
     */
    createShowGraduationSectionObservable(universitySettings$: Observable<UniversitySettings>, selectedModule$: Observable<Module>): Observable<boolean> {
        return combineLatest([universitySettings$, selectedModule$])
            .pipe(
                map(([universitySettings, module]) => {
                    const showGraduationSection = this.showGraduationSection(universitySettings, module);
                    this.mandatoryGraduationModalValues = showGraduationSection ? this.getMandatoryGraduationModalValues(module as DegreeProgramme, universitySettings) : null;
                    return showGraduationSection;
                }),
            );
    }

    showGraduationSection(universitySettings: UniversitySettings, module: Module): boolean {
        return this.isDegreeProgramme(module) && this.degreeProgrammeAttainmentApplicationEnabled(universitySettings);
    }

    isDegreeProgramme(module: Module): module is DegreeProgramme {
        return module.type === 'DegreeProgramme';
    }

    degreeProgrammeAttainmentApplicationEnabled(universitySettings: UniversitySettings): boolean {
        return universitySettings?.frontendFeatureToggles?.degreeProgrammeAttainmentApplicationEnabled ?? true;
    }

    navigateToDegreeProgrammeAttainmentWorkflowCreation(degreeProgramme: Module): void {
        this.stateService.go('student.logged-in.profile.applications.create-degree-programme-attainment-application',
                             {
                                 planId: this.values.planId,
                                 degreeProgrammeId: degreeProgramme.id,
                             });
        this.activeModal.close();
    }

    openMandatoryGraduationSurveyModal() {
        this.activeModal.close();
        this.fudisDialogService.open(MandatoryGraduationSurveyModalComponent, { data: this.mandatoryGraduationModalValues });
    }

    getMandatoryGraduationModalValues = (degreeProgramme: DegreeProgramme, universitySettings: UniversitySettings): MandatoryGraduationSurveyValues => {
        const graduationSurveyWithConfirmationEnabled =
            _.get(universitySettings, 'frontendFeatureToggles.mandatoryGraduationSurveyEnabled', false);
        if (!graduationSurveyWithConfirmationEnabled) return null;

        let surveyUrl: LocalizedUrl;
        let graduateType: string;

        if (isBachelorsDegree(degreeProgramme)) {
            surveyUrl = _.get(universitySettings, 'bachelorsGraduateSurveyUrl');
            graduateType = 'BACHELORS';
        }
        if (isAMKDegree(degreeProgramme)) {
            surveyUrl = _.get(universitySettings, 'amkGraduateSurveyUrl');
            graduateType = 'AMK';
        }
        if (!surveyUrl) return null;

        return {
            degreeProgrammeId: degreeProgramme.id,
            organisationId: this.values.organisationId,
            planId: this.values.planId,
            ...resolveGraduationModalValues(graduateType, surveyUrl),
        };
    };

    openVersionChangeWarningModal(fromModuleId: OtmId, toModuleId: OtmId) {
        this.modalService.open(ModuleInfoVersionChangeWarningComponent, { fromModuleId, toModuleId })
            .result.then(() => { /* Modal was closed */ })
            .catch(() => { /* Modal was closed */ });
    }

    navigateToModuleAttainmentApplication(applications: StudentApplication[]) {
        const firstApplication = applications.find(Boolean);
        if (firstApplication) {
            this.stateService.go(
                'student.logged-in.profile.applications.module-attainment-application',
                { applicationId: firstApplication.id },
            );
            this.activeModal.close();
        }
    }

    applyForModuleAttainmentApplication() {
        if (this.attainmentApplicationSendPending) {
            return;
        }
        this.attainmentApplicationSendPending = true;
        this.values.moduleAttainmentApplicationWrapper.applyForModuleAttainment().then(
            () => {
                // applyForModuleAttainment modifies the moduleAttainmentApplicationWrapper itself,
                // and because of that we need to run change detection manually
                this.changeDetectorRef.detectChanges();
                // Move the focus to show -button that appears to the place of the apply -button
                this.showModuleAttainmentApplicationButtonRef.nativeElement.focus();
            },
        )
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler)
            .finally(() => {
                this.attainmentApplicationSendPending = false;
            });
    }
}
