<ng-container *transloco="let t">
  @if (ruleCourseUnit()) {
    @if (isImplicit()) {
      <sis-plan-structure-implicit-course-unit [courseUnit]="ruleCourseUnit()"
                                               [headingLevel]="headingLevel()"
                                               [planData]="planData()"
                                               [inputType]="actionInputType()"
                                               [actionInputId]="rule().localId"
                                               [actionInputName]="actionInputName()"
                                               [selected]="this.ruleValidationResult()?.active"
                                               [action]="action()"
                                               [planStateObject]="planStateObject()"
                                               (actionChange)="handleToggle()"
                                               [showSkipLink]="false">
      </sis-plan-structure-implicit-course-unit>
    } @else {
      <sis-plan-structure-course-unit [courseUnit]="ruleCourseUnit()"
                                      [actionInputId]="rule().localId"
                                      [actionInputName]="actionInputName()"
                                      [headingLevel]="headingLevel()"
                                      [planData]="planData()"
                                      [inputType]="actionInputType()"
                                      [selected]="this.ruleValidationResult()?.active"
                                      [action]="action()"
                                      [planStateObject]="planStateObject()"
                                      (actionChange)="handleToggle()">
        @if (showMandatorySelection()) {
          <sis-study-notification-box>
            {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BOX_NOTIFICATION.MANDATORY_SELECTION') }}
          </sis-study-notification-box>
        }
      </sis-plan-structure-course-unit>
    }
  }
</ng-container>
