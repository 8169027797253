// eslint-disable-next-line import/no-extraneous-dependencies
import angular from 'angular';
// eslint-disable-next-line import/no-extraneous-dependencies
import angularTranslate from 'angular-translate';
import * as _ from 'lodash-es';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import markupStringEditorTpl from './markupStringEditor.tpl.html';
export const markupStringEditorModule = 'sis-components.string.markupStringEditor';
/* global MediumEditor */

(function () {
  markupStringEditorController.$inject = ["$translate", "localeService", "$element"];
  markupStringEditorDirective.$inject = ["$timeout"];
  angular.module(markupStringEditorModule, [angularTranslate, 'angular-medium-editor', TooltipComponent.downgrade.moduleName]).directive('markupStringEditor', markupStringEditorDirective).controller('markupStringEditorController', markupStringEditorController);

  /**
   * @ngInject
   */
  function markupStringEditorDirective($timeout) {
    return {
      require: ['ngModel', '^^form', 'markupStringEditor'],
      restrict: 'E',
      scope: {
        name: '@',
        onChangeCallback: '&onChangeCallback',
        hideErrors: '@',
        placeholder: '@',
        minLength: '@',
        maxLength: '@',
        disableEditing: '<'
      },
      template: markupStringEditorTpl,
      controller: 'markupStringEditorController as ctrl',
      bindToController: true,
      transclude: true,
      link: function (scope, element, attrs, ctrls) {
        const ngModel = ctrls[0];
        const form = ctrls[1];
        const ctrl = ctrls[2];
        if (_.isEmpty(attrs.name)) {
          // eslint-disable-next-line no-throw-literal
          throw '"name" attribute is required';
        }
        ctrl.ngModel = ngModel;
        ctrl.form = form;
        ctrl.directiveElement = element;

        // some browsers generate different data here for empty strings.
        // for example firefox creates an empty <br> tag,
        // and we sometimes have empty <p>
        ctrl.isEmptyValue = function (value) {
          value = _.replace(value, /<\s*br\s*\/?>/gi, '');
          value = _.replace(value, /<\s*\/?\s*p\s*\/?\s*>/gi, '');
          value = _.trim(value);
          return _.isEmpty(value);
        };

        // trim & remove empties from model values.
        // wrap non-empties inside <div> element.
        // if no values, then the model is null.
        // replace h3 -> h5, h4 -> h6, due to medium editor bug in h5
        ngModel.$parsers.push(value => {
          /* eslint-disable no-useless-escape */
          value = _.replace(value, /<(\/?)\h3>/gi, '<$1h5>');
          value = _.replace(value, /<(\/?)\h4>/gi, '<$1h6>');
          value = _.trim(value);
          if (ctrl.isEmptyValue(value)) {
            return undefined;
          }
          return angular.element(`<div>${value}</div>`).html();
        });

        // if model value is null, fill view model with empty object.
        // temporarily internally use h3 to represent h5 and h6 h4 represent
        // h6 due to medium editor bug in h5
        ngModel.$formatters.push(value => {
          value = _.replace(value, '<h5>', '<h3>');
          value = _.replace(value, '</h5>', '</h3>');
          value = _.replace(value, '<h6>', '<h4>');
          value = _.replace(value, '</h6>', '</h4>');
          return value;
        });
        $timeout(() => {
          ctrl.form.$setPristine();
        });
      }
    };
  }

  /**
   * @ngInject
   */
  function markupStringEditorController($translate, localeService, $element) {
    const ctrl = this;
    ctrl.$onInit = function () {
      const ClearFormattingButton = MediumEditor.extensions.button.extend({
        name: 'clearFormatting',
        aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.CLEAR_FORMATTING'),
        contentDefault: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.CLEAR_FORMATTING'),
        init: function () {
          MediumEditor.extensions.button.prototype.init.call(this);
        },
        handleClick: function () {
          document.execCommand('formatBlock', false, 'p');
          document.execCommand('removeformat');

          // Ensure the editor knows about an html change so watchers are notified
          // ie: <textarea> elements depend on the editableInput event to stay synchronized
          this.base.checkContentChanged();
        }
      });
      ctrl.mediumEditorOptions = {
        buttonLabels: false,
        imageDragging: false,
        autoLink: true,
        toolbar: {
          buttons: [{
            name: 'bold',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.BOLD'),
            contentDefault: '<span>B</span>'
          }, {
            name: 'italic',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.ITALIC'),
            contentDefault: '<span>I</span>'
          }, {
            name: 'superscript',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.SUPERSCRIPT'),
            contentDefault: '<span>T<span class="superscript">2</span></span>'
          }, {
            name: 'subscript',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.SUBSCRIPT'),
            contentDefault: '<span>T<span class="subscript">2</span></span>'
          }, {
            name: 'anchor',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.LINK'),
            contentDefault: '<svg width="32" height="32" class="hidden" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="link" aria-hidden="true" fill="#fff"><path class="a" d="M15.666,18.0432a1.755,1.755,0,0,1-1.2456-.5127l-1.5024-1.503a.5.5,0,0,1,.707-.707l1.502,1.5029A.7935.7935,0,0,0,16.2461,16.8l4.16-4.16a.7952.7952,0,0,0,.0215-1.1206l-1.8017-1.8a.7923.7923,0,0,0-1.1192.0225l-.9238.9243a.5.5,0,1,1-.707-.707L16.8,9.0344a1.7955,1.7955,0,0,1,2.5332-.022l1.8027,1.8008a1.797,1.797,0,0,1-.0224,2.5337l-4.16,4.16A1.8182,1.8182,0,0,1,15.666,18.0432Z"/><path class="a" d="M12.06,21.6476a1.755,1.755,0,0,1-1.2461-.5127L9.0112,19.3342A1.7977,1.7977,0,0,1,9.0337,16.8l4.1606-4.16a1.7937,1.7937,0,0,1,2.5328-.0229L17.23,14.12a.5.5,0,0,1-.707.707L15.02,13.3239a.792.792,0,0,0-1.1186.023l-4.16,4.16a.7959.7959,0,0,0-.022,1.1211l1.8018,1.8a.7935.7935,0,0,0,1.1191-.0224l.9239-.9248a.5.5,0,0,1,.707.707l-.9238.9248A1.8178,1.8178,0,0,1,12.06,21.6476Z"/></svg>'
          }, {
            name: 'h3',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.HEADING5'),
            contentDefault: '<span>H5</span>'
          }, {
            name: 'h4',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.HEADING6'),
            contentDefault: '<span>H6</span>'
          }, {
            name: 'unorderedlist',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.UNORDERED_LIST'),
            contentDefault: '<svg width="32" height="32" class="hidden" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="bullets" aria-hidden="true" fill="#fff"><path class="a" d="M22,18.5H11v-1H22Zm-13,0H8v-1H9Zm13-4H11v-1H22Zm-13,0H8v-1H9Zm13-4H11v-1H22Zm-13,0H8v-1H9Z"/></svg>'
          }, {
            name: 'orderedlist',
            aria: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.ORDERED_LIST'),
            contentDefault: '<svg width="32" height="32" class="hidden" preserveAspectRatio="xMidYMin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="numbering" aria-hidden="true" fill="#fff"><path class="a" d="M7.6006,8v.79h.75v3h.79V8Z"/><path class="a" d="M7.29,13v.751h-.751v.789H7.33v-.75H9.54v.71H8.04v.75H7.29v.751h-.751v.789H10.33v-.789H8.08V15.29h1.5v-.75h.75v-.789H9.58V13Z"/><path class="a" d="M6.5,18v.79h3v.71H7.25v.79H9.5V21h-3v.79H9.54v-.75h.75V18.75H9.54V18Z"/><rect class="b" x="12.5" y="10.0002" width="11" height="1"/><rect class="b" x="12.5" y="14.0002" width="11" height="1"/><rect class="b" x="12.5" y="18.0002" width="11" height="1"/></svg>'
          }, {
            name: 'clearFormatting'
          }],
          relativeContainer: $element[0]
        },
        extensions: {
          clearFormatting: new ClearFormattingButton({
            label: 'My label'
          })
        },
        anchor: {
          placeholderText: $translate.instant('SIS_COMPONENTS.STRING.LOCALIZED_MARKUP_STRING_EDITOR.TYPE_A_LINK'),
          linkValidation: true
        },
        paste: {
          forcePlainText: true,
          cleanPastedHTML: true,
          cleanAttrs: ['class', 'style', 'dir']
        },
        placeholder: false,
        static: true,
        align: 'right',
        targetBlank: true
      };
    };

    // $setViewValue is needed because angular ngModel does not do deep watch on the object, and will not notice
    // changes in the underlying objects.
    //
    // in certain scenarios ctrl.ngModel may not be initialized
    // when update is called the first time, because controller is created before ngModel is set in the
    // link function
    ctrl.onChangeFunc = function () {
      if (_.isFunction(_.get(ctrl.ngModel, '$setViewValue'))) {
        ctrl.ngModel.$setViewValue(angular.copy(ctrl.ngModel.$viewValue));
        if (ctrl.onChangeCallback) {
          ctrl.onChangeCallback(ctrl.ngModel.$modelValue);
        }
      }
    };
  }
})();