import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { dateUtils } from 'common-typescript/constants';
import { CurriculumPeriod, OtmId, YearRange } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';

function isActive(period: CurriculumPeriod, date: Moment = moment()): boolean {
    return (period?.activePeriod?.startDate || period?.activePeriod?.endDate) &&
        dateUtils.dateRangeContains(date, period.activePeriod.startDate, period?.activePeriod?.endDate);
}

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        getByUniversityOrgId() {
            return `${this.backend}/curriculum-periods`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'curriculum-periods',
})
export class CurriculumPeriodEntityService extends EntityService<CurriculumPeriodState> {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.curriculumPeriodEntityService',
        serviceName: 'curriculumPeriodEntityService',
    };

    private sortOrder = ['universityOrgId', 'activePeriod.startDate'];

    constructor(private universityService: UniversityService) {
        super(CurriculumPeriodStore, CurriculumPeriodQuery);
    }

    getByUniversityOrgId(ids: OtmId | OtmId[]): Observable<CurriculumPeriod[]> {
        const url = CONFIG.ENDPOINTS.getByUniversityOrgId();

        return this.getHttp()
            .get<CurriculumPeriod[]>(
            url,
            {
                params: {
                    universityOrgId: ids,
                },
            }).pipe(tap((curriculumPeriods: CurriculumPeriod[]) => this.store.upsertMany(curriculumPeriods)));
    }

    findCurrentCurriculumPeriod(universityOrgId: OtmId = this.universityService.getCurrentUniversityOrgId()): Observable<CurriculumPeriod> {
        return this.getByUniversityOrgId(universityOrgId)
            .pipe(
                take(1),
                map(periods => periods.find(period => isActive(period)) ?? null),
            );
    }

    findDefaultCurriculumPeriodId(universityOrgId: OtmId = this.universityService.getCurrentUniversityOrgId()): Observable<OtmId> {
        return this.getByUniversityOrgId([universityOrgId])
            .pipe(
                take(1),
                map((curriculumPeriods: CurriculumPeriod[]) => _.sortBy(curriculumPeriods, this.sortOrder)),
                map((curriculumPeriods: CurriculumPeriod[]) => {
                    let current: CurriculumPeriod;
                    let next: CurriculumPeriod;
                    _.forEach(curriculumPeriods, (curriculumPeriod) => {
                        if (isActive(curriculumPeriod)) {
                            current = curriculumPeriod;
                            const index = _.findIndex(curriculumPeriods, curriculumPeriod);
                            if (index !== -1) {
                                next = curriculumPeriods[index + 1];
                            }
                            return false;
                        }
                        return true;
                    });
                    return next?.id ?? current?.id;
                }));
    }

    /**
     * Combines the start and end years of the given curriculum periods into a single year range. Any gaps between the
     * active periods of the curriculum periods are ignored; the `startYear` is the year of the earliest start date and
     * the `endYear` is the year of the latest end date. If any curriculum period is missing the active period end date,
     * the `endYear` of the returned year range will be `null`.
     */
    resolveCombinedYearRange(curriculumPeriods: CurriculumPeriod[]): YearRange {
        if (!curriculumPeriods.length) {
            return { startYear: null, endYear: null };
        }
        const earliestStartDate = _.min(curriculumPeriods.map(cp => cp.activePeriod?.startDate).filter(Boolean));
        const startYear = earliestStartDate ? moment(earliestStartDate).year() : null;
        const endDates = curriculumPeriods.map(cp => cp.activePeriod?.endDate);
        const endYear = endDates.every(Boolean) ? moment(_.max(endDates)).year() : null;
        return { startYear, endYear };
    }

    getCurriculumPeriodsForSearch(universityOrgId: OtmId, planCurriculumPeriodId: OtmId): Observable<CurriculumPeriod[]> {
        return this.getByUniversityOrgId(universityOrgId)
            .pipe(map(curriculumPeriods =>
                this.filterCurriculumPeriodsForSearch(
                    planCurriculumPeriodId,
                    curriculumPeriods,
                ),
            ));
    }

    private filterCurriculumPeriodsForSearch(planCurriculumPeriodId: OtmId, curriculumPeriods: CurriculumPeriod[]): CurriculumPeriod[] {
        const result: CurriculumPeriod[] = [];
        this.sortCurriculumPeriods(curriculumPeriods);
        const firstIndex = _.findIndex(curriculumPeriods, (curriculumPeriod) => curriculumPeriod.id === planCurriculumPeriodId);
        if (firstIndex === -1) {
            return [];
        }
        if (firstIndex !== curriculumPeriods.length - 1) {
            result.push(curriculumPeriods[firstIndex]);
            const remainingCurriculumPeriods = _.slice(curriculumPeriods, firstIndex + 1);
            const currentMoment = moment();
            _.forEach(remainingCurriculumPeriods, (cp) => {
                if (moment(cp.activePeriod.startDate).isSameOrBefore(currentMoment, 'day')) {
                    result.push(cp);
                }
            });
            const lastIndex = _.indexOf(remainingCurriculumPeriods, _.last(result));
            const lastResult = _.last(result);
            if (currentMoment.isBetween(lastResult.activePeriod.startDate, lastResult.activePeriod.endDate, 'days', '[)') &&
                lastIndex !== remainingCurriculumPeriods.length - 1
            ) {
                result.push(remainingCurriculumPeriods[lastIndex + 1]);
            }
        }
        return result;
    }

    private sortCurriculumPeriods(curriculumPeriods: CurriculumPeriod[]) {
        curriculumPeriods.sort((a, b) => moment(a.activePeriod.startDate).isBefore(b.activePeriod.startDate, 'day') ? -1 : 1);
    }
}

type CurriculumPeriodState = EntityState<CurriculumPeriod, OtmId>;

@StoreConfig({ name: 'curriculum-periods' })
class CurriculumPeriodStore extends EntityStore<CurriculumPeriodState> {}

class CurriculumPeriodQuery extends QueryEntity<CurriculumPeriodState> {
    constructor(protected store: CurriculumPeriodStore) {
        super(store);
    }
}
