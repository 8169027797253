import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Attainment, ModuleAttainment } from 'common-typescript/types';

@Component({
    selector: 'sis-module-attainment-details',
    templateUrl: './module-attainment-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleAttainmentDetailsComponent {
    @Input() attainment: ModuleAttainment;
    @Input() secondaryAttainments: Attainment[];
    @Input() expirationImminent: boolean;
}
