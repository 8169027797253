creditRangeWithSpans.$inject = ["$translate"];
creditRangeInParentheses.$inject = ["$translate"];
creditRangeLongAttained.$inject = ["$translate"];
creditRangeLong.$inject = ["$translate"];
creditRangeShort.$inject = ["$translate"];
creditRange.$inject = ["$translate"];
import angular from 'angular';
import { numberUtils } from "common-typescript";
export const creditRangeFilterModule = 'sis-components.number.creditRangeFilter';
angular.module(creditRangeFilterModule, ['sis-components.number.decimalNumberFilter'])
// upgraded in frontend-angular/projects/sis-components/webapp/lib/number/credit-range.pipe.ts
.filter('creditRange', creditRange)
// upgraded in frontend-angular/projects/sis-components/webapp/lib/number/credit-range.pipe.ts
.filter('creditRangeShort', creditRangeShort)
// upgraded in frontend-angular/projects/sis-components/webapp/lib/number/credit-range-long.pipe.ts
.filter('creditRangeLong', creditRangeLong)
// upgraded in frontend-angular/projects/sis-components/webapp/lib/number/credit-range-long.pipe.ts
.filter('creditRangeLongAttained', creditRangeLongAttained).filter('creditRangeInParentheses', creditRangeInParentheses).filter('creditRangeWithSpans', creditRangeWithSpans);
function creditRangeToString(input, $translate, short, appendOpAbbr) {
  if (input) {
    const minValue = numberUtils.numberToString(input.min);
    const maxValue = numberUtils.numberToString(input.max);
    let textKey;
    const suffix = appendOpAbbr ? $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ABBR') : '';
    if (!minValue) {
      if (!maxValue) {
        return '';
      }
      textKey = short ? 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_SHORT' : 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX';
      return $translate.instant(textKey, {
        value: maxValue
      }) + suffix;
    }
    if (!maxValue) {
      textKey = short ? 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_SHORT' : 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN';
      return $translate.instant(textKey, {
        value: minValue
      }) + suffix;
    }
    if (minValue === maxValue) {
      return minValue + suffix;
    }
    return `${minValue}–${maxValue}${suffix}`;
  }
  return '';
}

/**
 * @ngInject
 */
function creditRange($translate) {
  return function (input, appendOpAbbr) {
    return creditRangeToString(input, $translate, false, appendOpAbbr);
  };
}

/**
 * @ngInject
 */
function creditRangeShort($translate) {
  return function (input, appendOpAbbr) {
    return creditRangeToString(input, $translate, true, appendOpAbbr);
  };
}

/**
 * @ngInject
 */
function creditRangeLong($translate) {
  return function (input) {
    if (input) {
      const minValue = numberUtils.numberToString(input.min);
      const maxValue = numberUtils.numberToString(input.max);
      if (!minValue) {
        if (!maxValue) {
          return '';
        }
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_DESCRIPTION', {
          value: maxValue
        });
      }
      if (!maxValue) {
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_DESCRIPTION', {
          value: minValue
        });
      }
      if (minValue === maxValue) {
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ONE_DESCRIPTION', {
          value: minValue
        });
      }
      return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_RANGE_DESCRIPTION', {
        min: minValue,
        max: maxValue
      });
    }
    return '';
  };
}
function creditRangeLongAttained($translate) {
  return function (input) {
    if (input) {
      const minValue = numberUtils.numberToString(input.min);
      const maxValue = numberUtils.numberToString(input.max);
      if (!minValue) {
        if (!maxValue) {
          return '';
        }
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_DESCRIPTION_ATTAINED', {
          value: maxValue
        });
      }
      if (!maxValue) {
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_DESCRIPTION_ATTAINED', {
          value: minValue
        });
      }
      if (minValue === maxValue) {
        return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ONE_DESCRIPTION_ATTAINED', {
          value: minValue
        });
      }
      return $translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_RANGE_DESCRIPTION_ATTAINED', {
        min: minValue,
        max: maxValue
      });
    }
    return '';
  };
}

/**
 * @ngInject
 */
function creditRangeInParentheses($translate) {
  return function (input) {
    if (input) {
      const text = creditRangeToString(input, $translate);
      return `(${text} ${$translate.instant('SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_ABBR')})`;
    }
    return '';
  };
}

/**
 * @ngInject
 * If the input is a range, the return value of the pipe has to be bound to the elements innerHTML property. Avoid doing this.
 */
function creditRangeWithSpans($translate) {
  return function (input) {
    if (input) {
      const minValue = numberUtils.numberToString(input.min);
      const maxValue = numberUtils.numberToString(input.max);
      let textKey = '';
      if (!minValue) {
        if (!maxValue) {
          return '';
        }
        textKey = 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MAX_SHORT';
        return $translate.instant(textKey, {
          value: maxValue
        });
      }
      if (!maxValue) {
        textKey = 'SIS_COMPONENTS.NUMBER.CREDIT_RANGE_FILTER.CREDITS_MIN_SHORT';
        return $translate.instant(textKey, {
          value: minValue
        });
      }
      if (minValue === maxValue) {
        return minValue;
      }
      return `<span class="credit-range">${minValue}</span>–<span class="credit-range">${maxValue}</span>`;
    }
    return '';
  };
}