<ng-container *transloco="let t">
  <div class="module-content-approval-message messenger-inner" id="{{ message.id }}">
    <div class="row message-title-row">
      <div class="col-6 col-sm-8 col-xl-9 message-title">
        <div>{{ t('SIS_COMPONENTS.MESSENGER.MESSAGE.TYPE.' + message.type + '.NAME') }}</div>
        <div class="message-content-title">{{ message.title }}</div>
      </div>
      <div class="col-6 col-sm-4 col-xl-3 message-info">
        <span class="messenger-timestamp">{{ message.messageTime | localDateTimeFormat }}</span>
        <span>{{ message.senderFirstNames + ' ' + message.senderLastName }}</span>
      </div>
    </div>

    <div class="row messenger-message">
      <div class="col-12 message-comment">{{ message.comment }}</div>
    </div>
  </div>
</ng-container>
