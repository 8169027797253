<ng-container *transloco="let t">
  <fudis-dialog [size]="'lg'">
    <fudis-dialog-content>
      <fudis-heading [level]="1" [variant]="'lg'">{{ t('PROFILE.PERSONAL_INFO_HISTORY.TABLE_TITLE') }}</fudis-heading>
      <div class="table-responsive">
        <table class="sis-table styled-table personal-history-dialog-table">
          <caption>{{ t('PROFILE.PERSONAL_INFO_HISTORY.TABLE_CAPTION') }}</caption>
          <thead>
            <tr>
              <th>{{ t('FIELD_NAMES.firstNames') }}</th>
              <th>{{ t('FIELD_NAMES.lastName') }}</th>
              <th>{{ t('FIELD_NAMES.personalIdentityCode') }}</th>
              <th>{{ t('PROFILE.PERSONAL_INFO_HISTORY.VALID_UNTIL') }}</th>
              <th>{{ t('PROFILE.PERSONAL_INFO_HISTORY.MODIFIED') }}</th>
            </tr>
          </thead>
          <tbody>
            @for (historyItem of sortedHistory; track historyItem) {
              @if (!historyItem.correction || (historyItem.correction && data?.showCorrectedRows)) {
                <tr [id]="'history-row-' + historyItem.id">
                  <td>{{ historyItem.oldFirstNames }}</td>
                  <td>{{ historyItem.oldLastName }}</td>
                  <td>
                    <fudis-body-text class="personal-history-dialog-table__table-pic-column-text"
                                     [attr.aria-hidden]="!isPICVisible(historyItem.id)"
                                     [id]="'history-PIC-' + historyItem.id"
                    >
                      {{
                        isPICVisible(historyItem.id) ?
                          studentPICVisibilityMappings[historyItem.id].value :
                          '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                      }}
                      </fudis-body-text>
                      <fudis-button
                        [id]="'PIC-visibility-button-' + historyItem.id"
                        [icon]="isPICVisible(historyItem.id) ? 'eye-blind' : 'eye'"
                        [label]="isPICVisible(historyItem.id) ? t('PROFILE.PERSONAL_INFO_HISTORY.HIDE') : t('PROFILE.PERSONAL_INFO_HISTORY.SHOW')"
                        [ariaLabel]="isPICVisible(historyItem.id) ? t('ARIA_LABEL.HIDE_PERSONAL_ID_NUMBER') : t('ARIA_LABEL.SHOW_PERSONAL_ID_NUMBER')"
                        [labelHidden]="true"
                        (handleClick)="togglePICVisibility(historyItem.id)"
                        [size]="'icon-only'"
                        variant="tertiary"
                      />
                  </td>
                  <td>{{ historyItem.validUntil | localDateFormat }}</td>
                  @if (historyItem.correction) {
                    <td>{{ t('PROFILE.PERSONAL_INFO_HISTORY.CORRECTED') }} {{ historyItem.modified | localDateFormat }}</td>
                  }
                  @else {
                    <td>{{ t('PROFILE.PERSONAL_INFO_HISTORY.UPDATED') }} {{ historyItem.modified | localDateFormat }}</td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
      </div>
      <fudis-dialog-actions>
        <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CLOSE')" [variant]="'primary'" />
      </fudis-dialog-actions>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
