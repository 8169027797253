<div class="module-search" [class.disabled]="disabled">
  <sis-typeahead
    class="multiple-line-items"
    (click)="onTextClick($event)"
    [disabled]="disabled"
    [inputFormatter]="renderModule"
    [model]="selection"
    (modelChange)="onSelect($event)"
    [placeholder]="'SIS_COMPONENTS.MODULE_SEARCH.INPUT_PLACE_HOLDER' | translate"
    [required]="required"
    [resultTemplate]="moduleSearchResult"
    (selectItem)="$event.item.disabled ? $event.preventDefault() : onSelect($event.item)"
    [typeahead]="doSearch"
  ></sis-typeahead>
  <sis-button (clicked)="removeSelection()" [link]="true" class="remove" *ngIf="!!selection && !disabled" [ariaLabel]="'ARIA_LABEL.DELETE' | translate">
    <sis-icon icon="close"></sis-icon>
  </sis-button>
  <div class="row" *ngIf="truncated">
    <div class="col-12">
      <ul class="error-list">
        <li>
          <span>{{'SIS_COMPONENTS.MODULE_SEARCH.TOO_MANY_RESULTS' | translate}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #moduleSearchResult let-result="result">
  <div [class.disabled]="result.disabled">
    <div class="row">
      <div class="col-7 additional-data">{{ result.code }}</div>
      <div *ngIf="result.credits"
           class="col-4 additional-data text-right">{{ result.credits | creditRange: 'SYMBOLS' }}</div>
    </div>
    <div class="row">
      <div class="col-12">{{ result.name }}</div>
    </div>

    <div class="row" *ngIf="result.nameMatch !== result.name">
      <div class="col-12 additional-data">
        <span>{{ result.nameMatch }}</span>
      </div>
    </div>
  </div>
</ng-template>
