import { Injectable } from '@angular/core';
import {
    CourseUnit,
    CurriculumPeriod,
    LocalDateRange,
    LocalDateTimeRange,
    Module,
    PartialBy,
    SearchRequest,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class SearchUtilsService {

    static downgrade: ServiceDowngradeMappings = {
        serviceName: 'SearchUtils',
        moduleName: 'sis-components.service.searchUtils',
    };

    constructor(private localeService: LocaleService) {}

    filterByGroupId<T extends CourseUnit | Module>(versions: T[]): T[] {
        const versionsByGroupIds = _.values(_.groupBy(versions, 'groupId'));

        const latestVersions = _.map(versionsByGroupIds, (versionsForGroupId) => {
            const versionsSortedByActivePeriodEndDate = _.sortBy(versionsForGroupId, (version) => {
                // @ts-ignore
                const curriculumPeriods = version.curriculumPeriods as CurriculumPeriod[];
                const latestCurriculumPeriod = _.last(_.sortBy(curriculumPeriods, cp => moment(cp.activePeriod.endDate)));
                return latestCurriculumPeriod && latestCurriculumPeriod.activePeriod ?
                    moment(latestCurriculumPeriod.activePeriod.endDate) : null;
            });
            return _.last(versionsSortedByActivePeriodEndDate);
        });

        return _.sortBy(latestVersions, (version: T) => this.localeService.localize(version.name)) as T[];
    }
}
