import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation, Enrolment } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-enrolment-state-indicator',
    templateUrl: './enrolment-state-indicator.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class EnrolmentStateIndicatorComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.common.components.enrolment.enrolmentStateIndicatorNg',
        directiveName: 'appEnrolmentStateIndicator',
    };

    @Input() enrolment: Enrolment;
    @Input() courseUnitRealisation: CourseUnitRealisation;
}
