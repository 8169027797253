import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    AnyCourseUnitRule, AnyModuleRule, CompositeRule,
    CourseUnit, CourseUnitCountRule,
    CourseUnitRule, CreditsRule,
    EntityWithRule,
    Module,
    ModuleRule,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatestWith, forkJoin, from, map, Observable, of } from 'rxjs';

import { COMMON_MODULE_SERVICE, COURSE_UNIT_SERVICE } from '../ajs-upgraded-modules';
import { convertAJSPromiseToNative } from '../util/utils';

import { StudySelectorService } from './study-selector.service';

/**
 * Resolved versions to be shown in rule selectors.
 */
export interface PlanRuleData {
    ruleCourseUnitVersionsByGroupId: { [id: OtmId]: CourseUnit } | null
    ruleModuleVersionsByGroupId: { [id: OtmId]: EntityWithRule } | null
}
type RuleSubTypes = CourseUnitRule | ModuleRule | AnyCourseUnitRule | AnyModuleRule | CreditsRule | CourseUnitCountRule | CompositeRule;

@Injectable({
    providedIn: 'root',
})
/**
 * This is a helper service for handling data related to rules. Used for resolving versions of course units and modules
 * that should be shown when making selections.
 */
export class PlanRuleDataService {

    constructor(private studySelectorService: StudySelectorService,
                @Inject(COMMON_MODULE_SERVICE) private moduleService: any,
                @Inject(COURSE_UNIT_SERVICE) private courseUnitService: any) { }

    /**
     * Resolves course unit and module versions to be shown in rule selector.
     * @param validatablePlan validatable plan
     * @param parentModule parent module (EntityWithRule) that contains the rule tree
     */
    resolvePlanRuleData(validatablePlan: ValidatablePlan, parentModule: EntityWithRule): Observable<PlanRuleData> {
        // Collect all unique groupIds from rules
        const courseUnitGroupIds = _.uniq(this.collectCourseUnitGroupIds(parentModule.rule as RuleSubTypes));
        const moduleGroupIds = _.uniq(this.collectModuleGroupIds(parentModule.rule as RuleSubTypes));

        const courseUnitsByGroupId$ = this.createCourseUnitVersionsObservable(courseUnitGroupIds, validatablePlan, parentModule);
        const modulesByGroupId$ = this.createModuleVersionsObservable(moduleGroupIds, validatablePlan, parentModule);
        return courseUnitsByGroupId$.pipe(
            combineLatestWith(modulesByGroupId$),
            map(([ruleCourseUnitVersionsByGroupId, ruleModuleVersionsByGroupId]) => ({
                ruleCourseUnitVersionsByGroupId,
                ruleModuleVersionsByGroupId,
            })));
    }

    /**
     * Creates an observable for resolving course unit versions to be shown in rule selector. Notice that the calls made to the backend will not be batched.
     * @param courseUnitGroupIds Collected course unit groupIds from the rule tree
     * @param validatablePlan validatable plan
     * @param parentModule parent module for the rule
     * @private
     */
    private createCourseUnitVersionsObservable(courseUnitGroupIds: OtmId[], validatablePlan: ValidatablePlan, parentModule: EntityWithRule): Observable<{ [id: OtmId]: CourseUnit } | null> {
        if (courseUnitGroupIds.length > 0) {
            return forkJoin(courseUnitGroupIds.map(groupId =>
                this.resolveCourseUnitVersion(groupId, validatablePlan, parentModule))).pipe(
                map(courseUnits => _.keyBy(courseUnits, 'groupId')),
            );
        }
        return of(null);
    }

    /**
     * Creates an observable for resolving module versions to be shown in rule selector. Notice that the calls made to the backend will not be batched.
     * @param moduleGroupIds Collected module groupIds from the rule tree
     * @param validatablePlan validatable plan
     * @param parentModule parent module for the rule
     * @private
     */
    private createModuleVersionsObservable(moduleGroupIds: OtmId[], validatablePlan: ValidatablePlan, parentModule: EntityWithRule): Observable<{ [id: OtmId]: EntityWithRule } | null> {
        if (moduleGroupIds.length > 0) {
            return forkJoin(moduleGroupIds.map(groupId =>
                this.resolveModuleVersion(groupId, validatablePlan, parentModule))).pipe(
                map(modules => _.keyBy(modules, 'groupId')),
            );
        }
        return of(null);
    }

    /**
     * Resolves course unit version to be shown in rule selector. The selection logic is as follows:
     * 1. Attained version
     * 2. Selected version
     * 3. Resolved unselected version using courseUnitService.getDataForStudySelector
     * @param groupId  groupId of the course unit from the rule
     * @param validatablePlan validatable plan
     * @param parentModule parent module for the rule
     * @private
     */
    private resolveCourseUnitVersion(groupId: OtmId, validatablePlan: ValidatablePlan, parentModule: EntityWithRule): Observable<CourseUnit> {
        const courseUnitAttainment = validatablePlan.getCourseUnitAttainmentByGroupId(groupId);
        if (courseUnitAttainment) {
            return of(_.get(validatablePlan.courseUnitsById, courseUnitAttainment.courseUnitId));
        }
        const courseUnitInPlan = validatablePlan.getCourseUnitInPlanByGroupId(groupId);
        if (courseUnitInPlan) {
            return of(courseUnitInPlan);
        }

        const parentCurriculumPeriodIds = validatablePlan.getCurriculumPeriodIdsRecursively(parentModule);
        const planCurriculumPeriodId = validatablePlan.plan.curriculumPeriodId;
        // TODO: This makes individual calls for each id (this is a problem in older implementations too).
        // Maybe batching or caching could be used here?
        return from(convertAJSPromiseToNative(this.courseUnitService.getDataForStudySelector(groupId)))
            .pipe(map((studySelectorData: any) => this.studySelectorService.selectStudyOfGroup(
                studySelectorData.allCourseUnitVersions,
                parentCurriculumPeriodIds,
                planCurriculumPeriodId,
                studySelectorData.allCurriculumPeriods,
            ) as CourseUnit));
    }

    /**
     * Resolves module version to be shown in rule selector. The selection logic is as follows:
     *  1. Attained version
     *  2. Selected version
     *  3. Resolved unselected version using moduleService.getDataForStudySelector
     * @param groupId groupId of the module from the rule
     * @param validatablePlan validatable plan
     * @param parentModule parent module for the rule
     * @private
     */
    private resolveModuleVersion(groupId: OtmId, validatablePlan: ValidatablePlan, parentModule: EntityWithRule): Observable<EntityWithRule> {
        const moduleAttainment = validatablePlan.getModuleAttainmentByGroupId(groupId);
        if (moduleAttainment) {
            return of(_.get(validatablePlan.modulesById, moduleAttainment.moduleId));
        }
        const moduleInPlan = validatablePlan.getModuleInPlanByGroupId(groupId);
        if (moduleInPlan) {
            return of(moduleInPlan);
        }

        const parentCurriculumPeriodIds = validatablePlan.getCurriculumPeriodIdsRecursively(parentModule);
        const planCurriculumPeriodId = validatablePlan.plan.curriculumPeriodId;
        // TODO: This makes individual calls for each id (this is a problem in older implementations too).
        // Maybe batching or caching could be used here?
        return from(convertAJSPromiseToNative(this.moduleService.getDataForStudySelector(groupId)))
            .pipe(map((studySelectorData: any) => this.studySelectorService.selectStudyOfGroup(
                // This is actually all module versions, but the field is named like this
                studySelectorData.allCourseUnitVersions,
                parentCurriculumPeriodIds,
                planCurriculumPeriodId,
                studySelectorData.allCurriculumPeriods,
            ) as Module));
    }

    /**
     * Recursively collects all course unit groupIds under the given rule tree.
     * @param rule rule where to collect course unit groupIds from
     * @private
     */
    private collectCourseUnitGroupIds(rule: RuleSubTypes): OtmId[] {
        let groupIds: OtmId[] = [];
        if (rule.type === 'CourseUnitRule') {
            groupIds.push(rule.courseUnitGroupId);
        }
        if ('rule' in rule) {
            groupIds = groupIds.concat(this.collectCourseUnitGroupIds(rule.rule as RuleSubTypes));
        }
        if ('rules' in rule) {
            rule.rules.forEach(childRule => {
                groupIds = groupIds.concat(this.collectCourseUnitGroupIds(childRule as RuleSubTypes));
            });
        }
        return groupIds;
    }

    /**
     * Recursively collects all module groupIds under the given rule tree.
     * @param rule rule where to collect module groupIds from
     * @private
     */
    private collectModuleGroupIds(rule: RuleSubTypes): OtmId[] {
        let groupIds: OtmId[] = [];
        if (rule.type === 'ModuleRule') {
            groupIds.push(rule.moduleGroupId);
        }
        if ('rule' in rule) {
            groupIds = groupIds.concat(this.collectModuleGroupIds(rule.rule as RuleSubTypes));
        }
        if ('rules' in rule) {
            rule.rules.forEach(childRule => {
                groupIds = groupIds.concat(this.collectModuleGroupIds(childRule as RuleSubTypes));
            });
        }
        return groupIds;
    }

}
