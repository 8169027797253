import angular from 'angular';
import 'js-data';
import './assessmentItem.model';
import './courseUnit.model';
import './degreeTitle.model';
import './gradeScale.model';
import './language.model';
import './module.model';
(function () {
  attainmentJSDataModel.$inject = ["DS"];
  angular.module('sis-components.model.attainment', ['js-data', 'sis-components.model.assessmentItem', 'sis-components.model.courseUnit', 'sis-components.model.degreeTitle', 'sis-components.model.module', 'sis-components.model.gradeScale', 'sis-components.model.language']).factory('attainmentJSDataModel', attainmentJSDataModel).constant('attainmentEndpoint', {
    DEFAULT: '/ori/api/attainments',
    ATTAINMENTS_BY_IDS: '/ori/api/attainments/{personId}/by-ids',
    MY_ATTAINMENTS: '/ori/api/my-attainments',
    SEARCH: '/ori/api/attainments/search',
    FOR_STUDENT_APPLICATION: '/ori/api/attainments/student-applications',
    FOR_MODULE_CONTENT_APPLICATION: '/ori/api/attainments/module-content-application-attainments',
    CREATE_SEPARATE_MODULE_ATTAINMENT: '/ori/api/attainments/create-separate-module-attainment',
    MODULE_ATTAINMENT_CHECK_FOR_POTENTIAL_GRADUATION: '/ori/api/attainments/module-attainment-check-for-potential-graduation',
    functional: {
      /**
       * @return {string}
       */
      SEARCH_FOR_PERSON(personId) {
        return ['/ori/api/attainments', personId, 'search'].join('/');
      },
      /**
       * @return {string}
       */
      MARK_AS_SECONDARY(id) {
        return ['/ori/api/attainments', id, 'mark-as-secondary'].join('/');
      }
    }
  }).constant('ATTAINMENT_STATE', {
    ATTAINED: 'ATTAINED',
    INCLUDED: 'INCLUDED',
    SUBSTITUTED: 'SUBSTITUTED',
    FAILED: 'FAILED'
  }).run(() => {});
  /**
   * @ngInject
   */
  function attainmentJSDataModel(DS) {
    return DS.defineResource({
      name: 'attainment',
      endpoint: '/ori/api/attainments',
      relations: {
        hasOne: {
          assessmentItem: {
            localField: 'assessmentItem',
            localKey: 'assessmentItemId'
          },
          courseUnit: {
            localField: 'courseUnit',
            localKey: 'courseUnitId'
          },
          module: {
            localField: 'module',
            localKey: 'moduleId'
          },
          gradeScale: {
            localField: 'gradeScale',
            localKey: 'gradeScaleId'
          },
          language: {
            localField: 'attainmentLanguage',
            localKey: 'attainmentLanguageUrn'
          },
          studyField: {
            localField: 'studyField',
            localKey: 'studyFieldUrn'
          },
          // Only for degreeProgrammeAttainment
          degreeTitle: {
            localField: 'degreeTitle',
            localKey: 'degreeTitleUrn'
          },
          // Only for degreeProgrammeAttainment
          educationClassification: {
            localField: 'educationClassification',
            localKey: 'educationClassificationUrn'
          }
        }
      }
    });
  }
})();