<ng-container *transloco="let t">
  <sis-common-attainment-details [attainment]="attainment" [secondaryAttainments]="secondaryAttainments">
    <ng-template *ngIf="expirationImminent" #expirationNotification>
      <sis-attainment-expiration-notification [attainment]="attainment"></sis-attainment-expiration-notification>
    </ng-template>

    <ng-template *ngIf="attainment.rdiCredits ||
    (attainment?.assessmentItemId | assessmentItem | async)?.rdiCreditsEnabled === 'ENABLED'" #rdiCredits>
      <div class="form-group-mimic" role="listItem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.RDI_CREDITS') }}</dt>
        <dd>{{attainment?.rdiCredits ?? '-'}}<dd>
      </div>
    </ng-template>
    <ng-template #verifierAndRegistrationDate>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.VERIFIER_AND_REGISTRATION_DATE') }}</dt>
        <dd>{{attainment.verifierPersonId ? (attainment.verifierPersonId | publicPerson | async | personFullNameReversed) : '-'}}, {{attainment.registrationDate | localDateFormat}}</dd>
      </div>
    </ng-template>
    <ng-template #administrativeNote>
      <div class="form-group-mimic" role="listitem">
        <dt>{{ t('ATTAINMENTS.ATTAINMENT_DETAILS.ADMINISTRATIVE_NOTE') }}</dt>
        <dd>{{ attainment.administrativeNote || '-' }}</dd>
      </div>
    </ng-template>
    <ng-template *ngIf="attainment?.cooperationNetworkStatus" #cooperationNetworkStatus>
      <sis-attainment-cooperation-network-status-details
        [cooperationNetworkStatus]="attainment.cooperationNetworkStatus"
        data-cy="attainment-ripa">
      </sis-attainment-cooperation-network-status-details>
    </ng-template>
  </sis-common-attainment-details>
</ng-container>
