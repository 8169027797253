import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Code, CodeBook } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { CommonCodeService } from '../../service/common-code.service';

/**
 * CustomCodeUrnsComponent can be used to display all types of (custom)CodeUrns.
 * Depending on the object type codeUrns are mapped in two different ways (don't ask why):
 *
 * StudyRight/OpenUniversityProduct:
 * public Set<Urn> codeUrns = new LinkedHashSet<>();
 *
 * Cur/StudyModule/DegreeProgramme/CourseUnit/etc.:
 * public Map<Urn, List<Urn>> customCodeUrns;
 *
 * If you want to pass Map -type customCodeUrns inside input, then lists inside the map will be flatMapped as one list.
 * There is more elegant ways to parse customCodeUrns Map data, but it would require bigger refactoring
 * if we want to keep this component generic as possible.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-custom-code-urns',
    templateUrl: './custom-code-urns.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomCodeUrnsComponent implements OnChanges {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.customCodeUrnsNg',
        directiveName: 'sisCustomCodeUrns',
    };

    constructor(private commonCodeService: CommonCodeService,
                private changeDetectorRef: ChangeDetectorRef,
                private appErrorHandler: AppErrorHandler,
    ) { }

    @Input() codeUrns?: string[];
    // Use codeUrnsMap input only if (custom)CodeUrns type is Map. Otherwise use codeUrns input.
    @Input() codeUrnsMap?: { [index: string]: string[] };
    @Input() classificationScope: string;
    customCodebooks: CodeBook[];
    codebooks: CodeBook[];
    customCodesByCodebookUrnMap: { [index: string]: string[] } = {};
    customCodes: { [index: string]: Code } = {};

    ngOnChanges(): void {
        if (!this.customCodebooks) {
            this.commonCodeService.getCustomCodebooksByClassificationScopeUrnObservable(`urn:code:classification-scope:${this.classificationScope}`)
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe((codebooks: CodeBook[]) => {
                    this.customCodebooks = codebooks;
                    this.calculateCodes();
                    this.changeDetectorRef.markForCheck();
                });
        } else {
            this.calculateCodes();
        }
    }

    calculateCodes() {
        this.customCodesByCodebookUrnMap = {};
        this.codebooks = [];
        this.customCodes = {};

        if (this.codeUrnsMap) {
            this.codeUrns = (Object.values(this.codeUrnsMap)?.flatMap(urnValue => urnValue) || []);
        }

        _.forEach(this.codeUrns, (customCodeUrn) => {
            const relevantCodebook: CodeBook = _.find(this.customCodebooks, customCodebook =>
                _.some(_.get(customCodebook, 'codes'), { urn: customCodeUrn }));
            if (relevantCodebook) {
                this.customCodes[customCodeUrn] = _.find(relevantCodebook.codes, { urn: customCodeUrn });
                if (!_.find(this.codebooks, { urn: relevantCodebook.urn })) {
                    this.codebooks.push(relevantCodebook);
                }
                const urnsForCodebook = this.customCodesByCodebookUrnMap[relevantCodebook.urn] || [];
                urnsForCodebook.push(customCodeUrn);
                this.customCodesByCodebookUrnMap[relevantCodebook.urn] = urnsForCodebook;
            }
        });
    }

}
