import { SisStateDeclaration } from 'sis-components/router/types';

import { STUDENT_CALENDAR_ABORTED_AND_FINISHED, STUDENT_CALENDAR_ENROLMENTS, STUDENT_CALENDAR_ENROLMENTS_NOT_ENROLLED } from './calendar.route.constant';
import { CalendarEnrolmentsComponent } from './right-panel/calendar-enrolments/calendar-enrolments.component';
import {
    CourseUnitsNotInCalendarComponent,
} from './right-panel/course-units-not-in-calendar/course-units-not-in-calendar.component';

export const calendarStates: SisStateDeclaration[] = [
    {
        name: `${STUDENT_CALENDAR_ENROLMENTS}`,
        url: '/enrolments',
        headerParams: {
            displayNameKey: 'COURSE_UNIT_REALISATIONS',
        },
        resolve: {
            currentView: () => 'NOT_STARTED_AND_ACTIVE',
        },
        component: CalendarEnrolmentsComponent,
    },
    {
        name: STUDENT_CALENDAR_ENROLMENTS_NOT_ENROLLED,
        url: '/not-enrolled',
        headerParams: {
            displayNameKey: 'UNSCHEDULED_COURSE_UNITS',
        },
        component: CourseUnitsNotInCalendarComponent,
    },
    {
        name: STUDENT_CALENDAR_ABORTED_AND_FINISHED,
        url: '/aborted-and-finished-enrolments',
        headerParams: {
            displayNameKey: 'FINISHED_AND_ABORTED_ENROLMENTS',
        },
        component: CalendarEnrolmentsComponent,
        resolve: {
            currentView: () => 'FINISHED_AND_ABORTED',
        },
    },
];
