import * as angular from 'angular';
import {
    ModuleToggles,
    Organisation,
    OtmId,
    UniversityOrganisation,
    UniversityPaths,
    UniversitySettings,
} from 'common-typescript/types';
import * as _ from 'lodash-es';

import { configModule } from '../config/config.module';
import { ConfigService } from '../config/config.service';

/**
 * @deprecated Use sis-components university.service
 */
export const commonUniversityServiceModule = 'sis-common.university';

/**
 * @deprecated Use sis-components university.service
 */
export class UniversityService {
    private university: UniversityOrganisation;

    constructor(private $log: angular.ILogService,
                private $http: angular.IHttpService,
                private selectedUniversityStorage: SelectedUniversityStorage,
                private configService: ConfigService,
                private universityPaths: UniversityPaths,
                private moduleToggleOverrideStorage: ModuleToggleOverrideStorage) {
        this.university = null;
    }

    static universityServiceFactory($log: angular.ILogService,
                                    $http: angular.IHttpService,
                                    selectedUniversityStorage: SelectedUniversityStorage,
                                    configService: ConfigService,
                                    universityPaths: UniversityPaths,
                                    moduleToggleOverrideStorage: ModuleToggleOverrideStorage) {
        return new UniversityService($log, $http, selectedUniversityStorage, configService, universityPaths, moduleToggleOverrideStorage);
    }

    getUniversities(): angular.IPromise<Organisation[]> {
        return this.$http.get(this.universityPaths.organisationRoots, { cache: true })
            .then(response => (response.data as Organisation[]));
    }

    setCurrentUniversityOrgId(id: OtmId): UniversityOrganisation {
        this.university = _.find(this.configService.get().homeOrganisations, { id });

        if (!this.university) {
            throw Error(`Setting current university to ${id} failed, not any home organisation`);
        }

        this.selectedUniversityStorage.store(this.university.id);

        return this.university;
    }

    getCurrentUniversity(): UniversityOrganisation {
        if (!this.university) {
            const universityOrgId = this.selectedUniversityStorage.retrieve();

            this.university = _.find(this.configService.get().homeOrganisations, { id: universityOrgId });

            if (!this.university) {
                const defaultUniversityOrgId = _.get(_.first(this.configService.get().homeOrganisations), 'id');

                this.$log.warn('selectedUniversity not found returning first', 'defaultUniversityOrgId', defaultUniversityOrgId,
                               'localStorageValue:', universityOrgId, 'UniversityConfig:', this.configService.get());

                this.university = this.setCurrentUniversityOrgId(defaultUniversityOrgId);
            }
        }

        return this.university;
    }

    getCurrentUniversityOrgId(): OtmId {
        return this.getCurrentUniversity().id;
    }

    /**
     * If the university configuration isTestModeEnabled is true, the ModuleToggles property of UniversitySettings be overridden by the values in the moduleToggleOverrides property in local storage.
     * This allows the user to locally set module toggles without the need for updating UniversitySettings in the back end, which makes for easier testing.
     * isTestModeEnabled should never be true in production environments.
     * */
    getCurrentUniversitySettings(bypassModuleToggleOverride?: boolean): angular.IPromise<UniversitySettings> {
        return this.$http.get(this.universityPaths.universitySettings + this.getCurrentUniversityOrgId(), { cache: true })
            .then(response => (response.data as UniversitySettings))
            .then((settings: UniversitySettings) => {
                if (!this.configService.get().isTestModeEnabled || bypassModuleToggleOverride) {
                    return settings;
                }
                return this.addLocalOverrides(settings);
            })
            .catch((err) => {
                this.$log.warn('Failed to get university settings for university.', 'CurrentUniversityOrgId',
                               this.getCurrentUniversityOrgId(), err);
                return ({} as UniversitySettings);
            });
    }

    addLocalOverrides(universitySettings: UniversitySettings) {
        const localOverrides: ModuleToggles = JSON.parse(this.moduleToggleOverrideStorage.retrieve());
        if (!localOverrides) {
            return universitySettings;
        }
        const moduleTogglesWithOverrides: ModuleToggles = { ...universitySettings.moduleToggles };

        for (const [key, value] of Object.entries(localOverrides)) {
            const typedKey = key as keyof ModuleToggles;
            moduleTogglesWithOverrides[typedKey] = value;
        }
        return {
            ...universitySettings,
            moduleToggles: {
                ...moduleTogglesWithOverrides,
            },
        };
    }

    updateCurrentUniversitySettings(universitySettings: UniversitySettings) {
        return this.$http.put(this.universityPaths.universitySettings + this.getCurrentUniversityOrgId(), universitySettings)
            .then(response => response.data);
    }
}
UniversityService.universityServiceFactory.$inject = ['$log', '$http', 'selectedUniversityStorage', 'configService', 'universityPaths', 'moduleToggleOverrideStorage'];

export class SelectedUniversityStorage {
    private readonly storageKey = 'selected_university';

    static selectedUniversityStorageFactory() {
        return new SelectedUniversityStorage();
    }

    store(token: OtmId): void {
        return localStorage.setItem(this.storageKey, token);
    }

    retrieve(): OtmId {
        return localStorage.getItem(this.storageKey);
    }
}

export class ModuleToggleOverrideStorage {
    private readonly storageKey = 'moduleToggleOverrides';

    static moduleToggleOverrideStorageFactory() {
        return new ModuleToggleOverrideStorage();
    }

    retrieve() {
        return localStorage.getItem(this.storageKey);
    }
}

angular.module(commonUniversityServiceModule, [configModule])
    .factory('selectedUniversityStorage', SelectedUniversityStorage.selectedUniversityStorageFactory)
    .factory('moduleToggleOverrideStorage', ModuleToggleOverrideStorage.moduleToggleOverrideStorageFactory)
    .factory('universityService', UniversityService.universityServiceFactory)
    .constant('universityPaths', {
        organisationRoots: '/kori/api/organisations/roots',
        universitySettings: '/kori/api/university-settings/',
    });
