import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, OtmId, Plan, StudyRight } from 'common-typescript/types';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    RemoveSubstitutionsEvent,
    SelectSubstitutionsEvent,
} from './course-unit-info-modal-substitution-correspondences/course-unit-info-modal-substitution-correspondences.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-course-unit-info-modal-substitutions',
    templateUrl: './course-unit-info-modal-substitutions.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalSubstitutionsComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.downgraded.courseUnitInfoModalSubstitution.courseUnitInfoModalSubstitutions',
        directiveName: 'appCourseUnitInfoModalSubstitutions',
    };

    @Input() plan: Plan;
    @Input() courseUnit: CourseUnit;
    @Input() displayNamesByCourseUnitId: any;
    @Input() versionChangeInProgress: boolean;
    @Input() validatablePlan: ValidatablePlan;
    @Input() openSubstitutionForm: Function;
    @Input() openApplication: Function;
    @Input() studyRight: StudyRight;
    @Input() currentUniversityOrgId: string;
    @Output() selectSubstitutions = new EventEmitter<SelectSubstitutionsEvent>();
    @Output() removeSubstitutions = new EventEmitter<RemoveSubstitutionsEvent>();
    @Output() openCourseUnitModal = new EventEmitter<OtmId>();
}
