import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Education, MobilityPeriod, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { MobilityPeriodEntityService } from '../../service/mobilityPeriod-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-mobility-periods',
    templateUrl: './mobility-periods.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class MobilityPeriodsComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.mobilityPeriodsNg',
        directiveName: 'sisMobilityPeriods',
    };

    constructor(
        private mobilityPeriodEntityService: MobilityPeriodEntityService,
        private appErrorHandler: AppErrorHandler,
    ) { }

    @Input() studyRightId: OtmId;
    @Input() education: Education;
    mobilityPeriods: MobilityPeriod[] = [];
    private subscription = Subscription.EMPTY;

    ngOnInit(): void {
        this.subscription = this.mobilityPeriodEntityService.getActiveByStudyRightId(this.studyRightId)
            .pipe(
                map(periods => _.orderBy(periods, ['activityPeriod.startDate'], ['desc'])),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: periods => this.mobilityPeriods = periods,
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
