import {
    Component,
    Inject,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from '@uirouter/angular';
import { ValidatablePlan } from 'common-typescript';
import { MaxLength } from 'common-typescript/constants';
import {
    Attainment, AttainmentType, CourseUnitAttainment, Education,
    Plan,
    StudyRight, StudyRightExtension,
    StudyRightExtensionWorkflowApplication,
    TermRegistrationCounts,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { max, maxLength, min, required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import {
    getValidationErrorSummaryDetail,
    ValidationErrorSummaryDetail,
} from 'sis-components/form/validation-error-summary/validation-error-summary.component';
import { STUDENT_WORKFLOW_APPLICATION_TYPE } from 'sis-components/model/student-workflow-constants';
import { UiRouterService } from 'sis-components/router/ui-router.service';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import {
    StudyRightTermRegistrationsEntityService,
} from 'sis-components/service/study-right-term-registrations-entity.service';
import { AttainedCreditsByStudyTerm, StudyTermService } from 'sis-components/service/study-term.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

interface CreateExtensionApplicationForm {
    requestedTerms: FormControl<number>;
    applicationRationale: FormControl<string>;
    delayRationale: FormControl<string>;
    mustPrintDecision: FormControl<boolean>;
}

@Component({
    selector: 'app-create-extension-application',
    templateUrl: './create-extension-application.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CreateExtensionApplicationComponent implements OnInit {

    @Input() plan: Plan;
    @Input() studyRight: StudyRight;
    @Input() education: Education;

    protected readonly getLabelState = getLabelState;

    workflowApplication: Partial<StudyRightExtensionWorkflowApplication> = {};
    attainments: Attainment[];
    termRegistrationCounts: TermRegistrationCounts;
    attainedCreditsByStudyTerm: AttainedCreditsByStudyTerm[];
    creditsPerTerm: number;
    plannedStudies: any;
    form: FormGroup<CreateExtensionApplicationForm>;
    isFormSubmitted = false;
    requestedTermsOptions: Option[] = [];
    validationDetails: ValidationErrorSummaryDetail[] = [];

    constructor(
        @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
        private studyTermService: StudyTermService,
        private fb: SisFormBuilder,
        private translocoService: TranslocoService,
        private uiRouterService: UiRouterService,
        private appErrorHandler: AppErrorHandler,
        private studyRightTermRegistrationsEntityService: StudyRightTermRegistrationsEntityService,
        private workflowEntityService: WorkflowEntityService,
        private state: StateService,
        private alertsService: AlertsService,
    ) { }

    ngOnInit(): void {
        this.form = this.buildForm();

        const previousExtensions: StudyRightExtension[] = _.chain(_.get(this.studyRight, 'studyRightExtensions', []))
            .filter({ state: 'ACTIVE' })
            .orderBy(['grantDate'], ['desc'])
            .value();

        this.workflowApplication = {
            type: STUDENT_WORKFLOW_APPLICATION_TYPE.STUDY_RIGHT_EXTENSION_APPLICATION,
            planId: this.plan.id,
            studyRightId: this.studyRight.id,
            educationId: this.studyRight.educationId,
            studyRightValidity: this.studyRight.valid,
            termRegistrations: this.studyRight.termRegistrations,
            previousExtensions,
        };

        this.studyRightTermRegistrationsEntityService.getTermRegistrationCounts(this.studyRight.id)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe((termRegistrationCounts) => {
                this.termRegistrationCounts = termRegistrationCounts;
                this.commonPlanService.getValidatablePlan(_.cloneDeep(this.plan), false, true)
                    .then((validatablePlan: ValidatablePlan) => {
                        // Add the cleaned plan to the application
                        this.workflowApplication.planSnapshot = validatablePlan.plan;
                        this.attainments = validatablePlan.getAllCourseUnitAndCustomCourseUnitAttainmentsInPlan();

                        this.workflowApplication.attainmentIds = this.attainments.map(attainment => attainment.id);

                        const attainedCreditsTotal = _.sumBy(this.attainments, 'credits');
                        this.creditsPerTerm = this.termRegistrationCounts.usedAttendanceTerms > 0 ?
                            _.round(attainedCreditsTotal / this.termRegistrationCounts.usedAttendanceTerms, 1) : 0;

                        const attainedCourseUnitIds: string[] = this.attainments
                            .filter(({ type }) => type === AttainmentType.COURSE_UNIT_ATTAINMENT)
                            .map(attainment => (attainment as CourseUnitAttainment).courseUnitId);

                        this.studyTermService.groupAttainedCreditsByStudyTerm(this.attainments, this.studyRight.valid.startDate, moment().toISOString(true), this.studyRight.termRegistrations)
                            .pipe(
                                take(1),
                                this.appErrorHandler.defaultErrorHandler(),
                            )
                            .subscribe((attainedCreditsByStudyTerm: AttainedCreditsByStudyTerm[]) => {
                                this.attainedCreditsByStudyTerm = attainedCreditsByStudyTerm;
                            });

                        this.commonPlanService.groupPlannedStudiesByStudyTerm(validatablePlan.plan, moment(), attainedCourseUnitIds)
                            .then((plannedStudies: any) => {
                                this.plannedStudies = plannedStudies;
                            });
                        this.commonPlanService.getStudyProgressByEducationPhase(validatablePlan, this.studyRight)
                            .then((studyProgressByEducationPhase: any) => {
                                this.workflowApplication.phase1Progress = studyProgressByEducationPhase.phase1Progress;
                                this.workflowApplication.phase2Progress = studyProgressByEducationPhase.phase2Progress;
                            });
                    });
            });
        this.addTranslationsToOptions();

    }

    buildForm(): FormGroup {
        return this.fb.group({
            requestedTerms: this.fb.control(this.workflowApplication.requestedTerms, [required(), min(1), max(4)]),
            applicationRationale: this.fb.control(this.workflowApplication.applicationRationale, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]),
            delayRationale: this.fb.control(this.workflowApplication.delayRationale, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]),
            mustPrintDecision: this.fb.control(this.workflowApplication.mustPrintDecision, [required()]),
        });
    }

    get requestedTerms() {
        return this.form?.controls.requestedTerms;
    }

    get applicationRationale() {
        return this.form?.controls.applicationRationale;
    }

    get delayRationale() {
        return this.form?.controls.delayRationale;
    }

    get mustPrintDecision() {
        return this.form?.controls.mustPrintDecision;
    }

    submitApplication(): void {
        if (this.isFormSubmitted) {
            return;
        }
        this.isFormSubmitted = true;
        if (!this.form.valid || !this.termRegistrationCounts) {
            this.form.markAllAsTouched();
            this.validationDetails = this.getValidationErrors();
            this.isFormSubmitted = false;
            return;
        }
        this.workflowApplication.usedAttendanceTerms = this.termRegistrationCounts.usedAttendanceTerms;
        this.workflowApplication.usedAbsenceTerms = this.termRegistrationCounts.usedAbsenceTerms;
        this.workflowApplication.usedStatutoryAbsenceTerms = this.termRegistrationCounts.usedStatutoryAbsenceTerms;
        this.workflowApplication.termsWithoutRegistration = this.termRegistrationCounts.termsWithoutRegistration;
        this.workflowApplication = {
            ...this.workflowApplication,
            ...this.form.value,
        };

        this.workflowEntityService.createAsStudent(this.workflowApplication).pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe((savedStudentWorkflow) => {
            this.alertsService.success('PROFILE.APPLICATIONS.APPLICATION_SENT');
            this.state.go('student.logged-in.profile.applications.study-right-extension-workflow',
                          { applicationId: savedStudentWorkflow.id });
        });
    }

    exitApplication(): void {
        this.uiRouterService.goToPreviousState();
    }

    private addTranslationsToOptions(): void {
        combineLatest([
            this.semesterSingleTranslation(),
            this.semesterPluralTranslation(),
        ]).pipe(
            take(1),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe((translations) => {
            this.requestedTermsOptions[0] = { value: 1, label: `1 ${translations[0]}` };
            this.requestedTermsOptions[1] = { value: 2, label: `2 ${translations[1]}` };
            this.requestedTermsOptions[2] = { value: 3, label: `3 ${translations[1]}` };
            this.requestedTermsOptions[3] = { value: 4, label: `4 ${translations[1]}` };
        });
    }

    private semesterSingleTranslation(): Observable<string> {
        return this.translocoService.selectTranslate('PROFILE.EXTENSIONS.SEMESTER');
    }

    private semesterPluralTranslation(): Observable<string> {
        return this.translocoService.selectTranslate('PROFILE.EXTENSIONS.SEMESTERS');
    }

    private getValidationErrors(): ValidationErrorSummaryDetail[] {
        const errorArr: ValidationErrorSummaryDetail[] = [];
        if (this.requestedTerms?.invalid) {
            errorArr.push(getValidationErrorSummaryDetail(
                this.requestedTerms,
                this.translocoService.translate('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.REQUESTED_TERMS_INPUT_INVALID'),
                'requested-terms-focus-id'),
            );
        }
        if (this.delayRationale?.invalid) {
            errorArr.push(getValidationErrorSummaryDetail(
                this.delayRationale,
                this.translocoService.translate('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.DELAY_RATIONALE_INPUT_INVALID'),
                'delay-rationale-focus-id'),
            );
        }
        if (this.applicationRationale?.invalid) {
            errorArr.push(getValidationErrorSummaryDetail(
                this.applicationRationale,
                this.translocoService.translate('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLICATION_RATIONALE_INPUT_INVALID'),
                'application-rationale-focus-id'),
            );
        }
        if (this.mustPrintDecision?.invalid) {
            errorArr.push(getValidationErrorSummaryDetail(
                this.mustPrintDecision,
                this.translocoService.translate('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.MUST_PRINT_DECISION_INPUT_INVALID'),
                'must-print-decision-focus-id'),
            );
        }
        return errorArr;
    }
}
