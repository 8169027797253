import angular from 'angular';
import * as _ from 'lodash-es';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const gradeScaleModule = 'sis-components.service.gradeScale';
(function () {
  commonGradeScaleService.$inject = ["gradeScaleJSDataModel", "$q", "jsDataCacheHelper"];
  angular.module(gradeScaleModule, ['sis-components.model.gradeScale', jsDataCacheHelperModule]).factory('commonGradeScaleService', commonGradeScaleService);

  /**
   * @ngInject
   */
  function commonGradeScaleService(gradeScaleJSDataModel, $q, jsDataCacheHelper) {
    const api = {
      /**
       * Returns promise. Resolved object is the gradeScale.
       */
      getGradeScale: gradeScaleId => gradeScaleJSDataModel.find(gradeScaleId),
      /**
       * Returns promise. Resolved object is array of gradeScales.
       */
      findByIds: gradeScaleIds => jsDataCacheHelper.findByIds(gradeScaleJSDataModel, gradeScaleIds),
      /**
       * Returns promise. Resolved object is a <gradeScaleId, gradeScale> map.
       */
      getGradeScales: attainments => api.findByIds(_.map(attainments, 'gradeScaleId')).then(gradeScales => _.keyBy(gradeScales, 'id'))
    };
    return api;
  }
})();