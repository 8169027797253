import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import { CreditRange, CustomCourseUnitAttainment } from 'common-typescript/types';
import * as _ from 'lodash-es';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { CustomCourseUnitAttainmentStateObject, PlanStateObject } from '../../service/plan-state.service';
import { StudyBoxType } from '../../study-boxes/study-box/study-box.component';

@Component({
    selector: 'sis-plan-structure-custom-course-unit-attainment',
    templateUrl: './plan-structure-custom-course-unit-attainment.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCustomCourseUnitAttainmentComponent implements OnChanges {
    @Input({ required: true }) customCourseUnitAttainment: CustomCourseUnitAttainment;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) headingLevel: number;

    customCourseUnitAttainmentStateObject: CustomCourseUnitAttainmentStateObject;
    visibleCredits: CreditRange | undefined;
    readonly studyBoxType = StudyBoxType.customAttainment;

    constructor(@Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) { }

    ngOnChanges() {
        if (this.customCourseUnitAttainment) {
            this.customCourseUnitAttainmentStateObject = _.get(this.planStateObject.customCourseUnitAttainments, this.customCourseUnitAttainment.id);
            this.visibleCredits = { min: this.customCourseUnitAttainment.credits, max: this.customCourseUnitAttainment.credits };
        }
    }

    openCustomCourseUnitAttainmentInfoModal() {
        this.planActionsService?.openCustomCourseUnitAttainmentInfoModal?.(this.customCourseUnitAttainment);
    }
}

