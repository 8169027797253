import { Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CourseUnit, DocumentState, Module, PrerequisiteGroup } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    COMMON_MODULE_SERVICE,
    COURSE_UNIT_SERVICE,
} from '../../ajs-upgraded-modules';
import { isCourseUnitPrerequisite, isModulePrerequisite } from '../prerequisite-utils';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prerequisites',
    templateUrl: './prerequisites.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PrerequisitesComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.courseUnit.prerequisites',
        directiveName: 'sisPrerequisites',
    };

    courseUnitsByGroupId: { [id: string]: CourseUnit } = {};
    modulesByGroupId: { [id: string]: Module } = {};

    @Input() prerequisites: PrerequisiteGroup[];
    @Input() documentStates: DocumentState[];
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h4
     */
    @Input() level?: number = 4;

    readonly isCourseUnitPrerequisite = isCourseUnitPrerequisite;
    readonly isModulePrerequisite = isModulePrerequisite;

    constructor(
        @Inject(COURSE_UNIT_SERVICE) private commonCourseUnitService: any,
        @Inject(COMMON_MODULE_SERVICE) private commonModuleService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnChanges() {
        const courseUnitGroupIds = _.chain(this.prerequisites)
            .flatMap('prerequisites')
            .map('courseUnitGroupId')
            .compact()
            .uniq()
            .value();
        this.commonCourseUnitService.findByGroupIds(courseUnitGroupIds, undefined, this.documentStates)
            .then((courseUnits: CourseUnit[]) => {
                this.courseUnitsByGroupId = _.keyBy(courseUnits, 'groupId');
            })
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
        const moduleGroupIds = _.chain(this.prerequisites)
            .flatMap('prerequisites')
            .map('moduleGroupId')
            .compact()
            .uniq()
            .value();
        this.commonModuleService.findByGroupIds(moduleGroupIds, undefined, undefined, this.documentStates)
            .then((modules: Module[]) => {
                this.modulesByGroupId = _.keyBy(modules, 'groupId');
            })
            .catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

}
