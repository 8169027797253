import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { MaxLength } from 'common-typescript/constants';
import { OtmId, Plan } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable, take, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import {
    getValidationErrorSummaryDetail,
    ValidationErrorSummaryDetail,
} from 'sis-components/form/validation-error-summary/validation-error-summary.component';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';

interface CopyPlanForm {
    name: FormControl<string>;
}

export function copyPlanModalOpener(): (planId: OtmId) => NgbModalRef {
    const modalService = inject(ModalService);
    return planId => modalService.open(CopyPlanModalComponent, planId, { size: 'sm' });
}
@Component({
    selector: 'app-copy-plan-modal',
    templateUrl: './copy-plan-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyPlanModalComponent implements OnInit {
    formSubmitted: boolean = false; // to avoid double-clicks
    plan$: Observable<Plan>;
    form: FormGroup<CopyPlanForm>;
    validationDetails: ValidationErrorSummaryDetail[] = [];

    constructor(
        @Inject(ModalService.injectionToken) private planId: OtmId,
        public modal: NgbActiveModal,
        private formBuilder: SisFormBuilder,
        private planEntityService: PlanEntityService,
        private appErrorHandler: AppErrorHandler,
        private translocoService: TranslocoService,
        private alertsService: AlertsService,
    ) {}

    ngOnInit(): void {
        this.plan$ = this.planEntityService.getById(this.planId)
            .pipe(
                take(1),
                tap((plan: Plan) => {
                    this.buildForm(plan);
                }),
            );
    }

    buildForm(plan: Plan) {
        const copyText = this.translocoService.translate('PLAN.COPY_PLAN_MODAL.DEFAULT_PLAN_COPY_NAME_SUFFIXATION');
        const planName = `${plan.name} ${copyText}`;
        this.form = this.formBuilder.group({
            name: this.formBuilder.control(planName, { validators: [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)] }),
        });
    }

    save(plan: Plan): void {
        if (!this.formSubmitted) {
            if (this.form.valid) {
                this.formSubmitted = true;
                const copyPlan = {
                    ...plan,
                    name: this.name.value.trim(),
                };
                _.unset(copyPlan, 'id');
                _.unset(copyPlan, 'metadata');
                copyPlan.primary = false;
                this.planEntityService.createMyPlan(copyPlan)
                    .pipe(
                        take(1),
                        this.appErrorHandler.defaultErrorHandler(),
                    ).subscribe((newPlan) => {
                        this.modal.close(newPlan.id);
                        this.alertsService.addTemporaryAlert({
                            type: AlertType.SUCCESS,
                            message: this.translocoService.translate('PLAN.COPY_PLAN_MODAL.COPY_PLAN_SUCCESS', { oldPlanName: plan.name, copyPlanName: newPlan.name }),
                        });
                    });
            } else {
                this.validationDetails = [
                    getValidationErrorSummaryDetail(
                        this.name,
                        this.translocoService.translate('PLAN.COPY_PLAN_MODAL.VALIDATION.ERROR_NAME_INPUT'),
                        'plan-plan-copy-modal-name-input-id'),
                ];
            }
        }

    }

    get name(): FormControl {
        return this.form.controls.name;
    }

}
