import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { CeeposPayment, CeeposPaymentConfirmation } from 'common-typescript/types';

@Injectable({
    providedIn: 'root',
})
export class CeeposPaymentService {

    constructor(
        private uiRouterGlobals: UIRouterGlobals,
    ) {}

    readCeeposResponseFromQueryParams(): CeeposPaymentConfirmation {
        return {
            Id: this.uiRouterGlobals.params?.Id,
            Status: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.Status),
            Reference: this.uiRouterGlobals.params?.Reference,
            Payments: this.parsePayments(),
            Hash: this.uiRouterGlobals.params?.Hash,
        } as CeeposPaymentConfirmation;
    }

    private convertQueryParamToInteger(param: string): number {
        // params are sent as string so convert them to integer
        const num = Number.parseInt(param, 10);
        return Number.isNaN(num) ? undefined : num;
    }

    private parsePayments(): CeeposPayment[] {
        let payments: CeeposPayment[] = [];
        if (this.uiRouterGlobals.params?.PaymentMethod instanceof Array) {
            for (let i = 0; i < this.uiRouterGlobals.params.PaymentMethod.length; i = i + 1) {
                const payment: CeeposPayment = {
                    PaymentMethod: this.convertQueryParamToInteger(this.uiRouterGlobals.params.PaymentMethod[i]),
                    PaymentSum: this.convertQueryParamToInteger(this.uiRouterGlobals.params.PaymentSum[i]),
                    Timestamp: this.uiRouterGlobals.params.Timestamp[i],
                    PaymentDescription: this.uiRouterGlobals.params.PaymentDescription[i],
                };
                payments.push(payment);
            }
        } else {
            const payment: CeeposPayment = {
                PaymentMethod: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.PaymentMethod),
                PaymentSum: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.PaymentSum),
                Timestamp: this.uiRouterGlobals.params?.Timestamp,
                PaymentDescription: this.uiRouterGlobals.params?.PaymentDescription,
            };
            payments = Object.values(payment).every(el => el === undefined) ? [] : [payment];
        }
        return payments;
    }
}
