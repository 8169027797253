import * as _ from 'lodash-es';
import { CommonCodeService } from 'sis-components/service/common-code.service.ts';
import customAttainmentApplicationSummaryTpl from './customAttainmentApplicationSummary.tpl.html';
'use strict';
(function () {
  customAttainmentApplicationSummaryController.$inject = ["$q", "commonModuleService", "commonStudyRightService", "preferredLanguageJSDataModel", "commonCodeService"];
  angular.module('sis-components.applications.customApplicationAttainmentSummary', ['sis-components.applications.textWithDefaultMissingValue', 'sis-components.model.preferredLanguage', 'sis-components.service.moduleService', 'sis-components.service.studyRightService', CommonCodeService.downgrade.moduleName]).component('customAttainmentApplicationSummary', {
    template: customAttainmentApplicationSummaryTpl,
    bindings: {
      application: '<',
      shortVersion: '<',
      showLinksForStaff: '<'
    },
    controller: customAttainmentApplicationSummaryController
  });

  /**
   * @ngInject
   */
  function customAttainmentApplicationSummaryController($q, commonModuleService, commonStudyRightService, preferredLanguageJSDataModel, commonCodeService) {
    const ctrl = this;
    ctrl.$onInit = function () {
      if (ctrl.showLinksForStaff) {
        ctrl.studentLink = getStudentLink();
        ctrl.studyPlanLink = getStudyPlanLink();
      }
      if (!ctrl.shortVersion) {
        preferredLanguageJSDataModel.lazyGetAll().then(languages => {
          ctrl.preferredLanguages = languages;
        });
        resolveEducation(ctrl.application);
        resolvePlannedParentModule(ctrl.application);
      }
    };
    ctrl.getLanguageName = function (languageUrn) {
      return _.get(_.find(ctrl.preferredLanguages, {
        urn: languageUrn
      }), 'name');
    };
    function resolveEducation(application) {
      if (application.studyRightId) {
        commonStudyRightService.findById(application.studyRightId, false, true).then(studyRight => {
          ctrl.educationPhase1ChildGroupId = studyRight.acceptedSelectionPath?.educationPhase1ChildGroupId;
          ctrl.educationPhase2ChildGroupId = studyRight.acceptedSelectionPath?.educationPhase2ChildGroupId;
          ctrl.phase1GraduationDate = studyRight.studyRightGraduation?.phase1GraduationDate;
          ctrl.education = studyRight.education;
          resolveChildModuleName();
          resolveChildNaming();
        });
      }
    }
    function resolveChildModuleName() {
      ctrl.childGroupId = ctrl.phase1GraduationDate ? ctrl.educationPhase2ChildGroupId : ctrl.educationPhase1ChildGroupId;
      commonModuleService.findByGroupIds([ctrl.childGroupId]).then(mod => {
        ctrl.childModule = mod.length !== 0 ? mod[0] : null;
      });
    }
    function resolveChildNaming() {
      ctrl.options = ctrl.phase1GraduationDate ? ctrl.education.structure?.phase2?.options : ctrl.education.structure?.phase1?.options;
      ctrl.option = _.find(ctrl.options, opt => _.some(opt.childOptions, {
        moduleGroupId: ctrl.childGroupId
      }));
      commonCodeService.getCode(ctrl.option?.childNaming).then(code => {
        ctrl.childNamingCode = code;
      });
    }
    function resolvePlannedParentModule(application) {
      if (application.plannedParentModuleId) {
        return commonModuleService.findById(application.plannedParentModuleId).then(module => {
          ctrl.plannedParentModule = module;
        });
      }
    }
    function getStudentLink() {
      if (!ctrl.application.student) {
        return null;
      }
      const studentId = ctrl.application.student.id;
      return `/tutor/role/staff/student/${studentId}/basic/basic-info`;
    }
    function getStudyPlanLink() {
      if (!ctrl.application.student || !ctrl.application.planId) {
        return null;
      }
      const studentId = ctrl.application.student.id;
      const studyPlanId = ctrl.application.planId;
      return `/tutor/role/staff/student/${studentId}/tutoring/plan/${studyPlanId}/structure`;
    }
  }
})();