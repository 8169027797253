import angular from 'angular';
import angularTranslate from 'angular-translate';
import {
    LocalDateRange,
    Organisation,
    OrganisationRoleShare,
    OrganisationRoleShareValidityGroupValidationErrors,
    OtmId,
    Urn,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
// @ts-ignore (implicit 'any' type)
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { UuidService } from 'sis-common/uuid/uuid.service';

// @ts-ignore (implicit 'any' type)
import { localDateRangeFilterModule } from '../date/filters/localDateRange/localDateRange.filter';
import { CodeSelectEditorComponent } from '../select/code-select-editor.component';

import { OrganisationRoleShareValidityGroup } from './organisation-role-share-validity-group.service';
// @ts-ignore ('cannot find module' for HTML template)
import organisationRoleShareValidityGroupEditorComponentHtml from './organisationRoleShareValidityGroupEditor.component.html';
// @ts-ignore (implicit 'any' type)
import { searchOrganisationModule } from './searchOrganisation.comp';

export const organisationRoleShareValidityGroupEditorModule = 'sis-components.organisation.organisationRoleShareValidityGroupEditor';

type PartialOrganisationRoleSharesWithIdAndOrganisation = Partial<OrganisationRoleShare & { _id: OtmId; organisation: Organisation }>;

class OrganisationRoleShareValidityGroupEditor {
    private disabledOrganisationIds: OtmId[];
    private endDateRequired: boolean;
    private endDateDisabled: boolean;
    private formName: string;
    private nextValidityGroup: OrganisationRoleShareValidityGroup;
    private onChange: Function;
    private isValid?: boolean;
    private organisationRoleShares: PartialOrganisationRoleSharesWithIdAndOrganisation[];
    private organisationRoleUrn: Urn;
    private emptyOrganisationsAllowed: boolean;
    private organisationService: Function;
    private previousValidityGroup: OrganisationRoleShareValidityGroup;
    private startDateDisabled: boolean;
    private startDateRequired: boolean;
    private validationErrors: OrganisationRoleShareValidityGroupValidationErrors;
    private validityPeriod: LocalDateRange;

    // If you change the constructor parameters, remember to update the "OrganisationRoleShareValidityGroupEditor.$inject = ..." line below too.
    constructor(private $scope: any, private uuidService: any) {}

    $onInit(): void {
        if (_.isNil(this.organisationRoleShares)) {
            this.organisationRoleShares = [];
        }
        _.forEach(this.organisationRoleShares, (share) => {
            if (_.isUndefined(share._id)) {
                share._id = this.uuidService.randomUUID();
            }
        });

        if (_.isNil(this.disabledOrganisationIds)) {
            this.disabledOrganisationIds = [];
        }

        if (_.isNil(this.organisationRoleUrn)) {
            throw new Error('organisationRoleShareValidityGroupEditor component: organisationRoleUrn attribute is required');
        }

        if (_.isEmpty(this.organisationService)) {
            throw new Error('organisationRoleShareValidityGroupEditor component: organisationService attribute is required');
        }

        this.validationErrors = {
            itemsWithOrganisationIdAndEducationalInstitutionUrn: false,
            itemsWithoutOrganisationIdAndEducationalInstitutionUrn: false,
            itemsWithoutEndDate: false,
            itemsWithoutOrganisationId: false,
            itemsWithoutRoleUrn: false,
            itemsWithoutShare: false,
            itemsWithoutStartDate: false,
            hasDuplicates: false,
            hasGapBetweenValidityGroups: false,
            hasOverlappingValidityGroups: false,
            sumOfSharesNotEqualToHundred: false,
        };

        // Validate form through scope since ng-model cannot be used in Angular through upgrading
        this.$scope.$watch(
            () => this.validationErrors,
            (newValue: any, oldValue: any, scope: any) => {
                scope[this.formName].$setValidity(`form ${this.formName} invalid`, !_.some(this.validationErrors));
                this.isValid = scope[this.formName].$valid;
            },
            true,
        );
    }

    $onChanges(changesObject: any) {
        const previousValidityGroup = _.get(changesObject, 'previousValidityGroup.currentValue');
        const nextValidityGroup = _.get(changesObject, 'nextValidityGroup.currentValue');

        this.startDateDisabled = !this.isCoordinatingOrganisationUrn() && _.isNil(previousValidityGroup) && _.isNil(this.validityPeriod.startDate);
        this.startDateRequired = !_.isNil(previousValidityGroup);

        this.endDateDisabled = !this.isCoordinatingOrganisationUrn() && _.isNil(nextValidityGroup) && _.isNil(this.validityPeriod.endDate);
        this.endDateRequired = !_.isNil(nextValidityGroup);
    }

    validityPeriodDisabled() {
        return !this.isCoordinatingOrganisationUrn() &&
            _.isNil(this.previousValidityGroup) &&
            _.isNil(this.nextValidityGroup) &&
            _.isNil(this.validityPeriod.startDate) &&
            _.isNil(this.validityPeriod.endDate);
    }

    remove(organisationRoleShareToBeRemoved: PartialOrganisationRoleSharesWithIdAndOrganisation) {
        this.organisationRoleShares = _.filter(this.organisationRoleShares, share => share._id !== organisationRoleShareToBeRemoved._id);
        this.handleChange();
    }

    addNew() {
        this.organisationRoleShares = _.concat(this.organisationRoleShares, {
            _id: this.uuidService.randomUUID(),
            share: 1.0,
            roleUrn: this.organisationRoleUrn,
            validityPeriod: this.validityPeriod,
        });
        this.handleChange();
    }

    addOrganisationOrEducationalInstitutionTranslationKey(): string {
        return this.isCoordinatingOrganisationUrn() ?
            'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ADD_ORGANISATION_OR_EDUCATIONAL_INSTITUTION' :
            'SIS_COMPONENTS.ORGANISATION.ORGANISATION_ROLE_SHARE_VALIDITY_GROUP_EDITOR.ADD_ORGANISATION';
    }

    dateChanged() {
        this.organisationRoleShares = _.map(this.organisationRoleShares, share => _.assign({}, share, { validityPeriod: this.validityPeriod }));
        this.handleChange();
    }

    /* called with null organisation when value is removed */
    organisationSelected(organisationRoleShareToBeChanged: PartialOrganisationRoleSharesWithIdAndOrganisation, organisation: Organisation) {
        this.organisationRoleShares = _.map(
            this.organisationRoleShares,
            share => share._id === organisationRoleShareToBeChanged._id
                ? _.assign({}, share, { organisation, organisationId: organisation ? organisation.id : null })
                : share);
        this.handleChange();
    }

    shareChanged() {
        this.handleChange();
    }

    handleChange() {
        // Set form to dirty so that AngularJS-forms work correctly
        this.$scope[this.formName].$setDirty();
        this.onChange();
    }

    isCoordinatingOrganisationUrn(): boolean {
        return this.organisationRoleUrn === 'urn:code:organisation-role:coordinating-organisation';
    }

    validateSumOfSharesNotEqualToHundred() {
        const sum = _.sumBy(this.organisationRoleShares, share => 100 * share.share);
        return this.validationErrors.sumOfSharesNotEqualToHundred = _.size(this.organisationRoleShares) > 0 && _.round(sum, 0) !== 100;
    }

    validateHasDuplicates() {
        const organisationIds = _.filter(_.map(this.organisationRoleShares, 'organisationId'), id => !_.isNil(id));
        const educationalInstitutionUrns = _.filter(_.map(this.organisationRoleShares, 'educationalInstitutionUrn'), urn => !_.isNil(urn));
        return this.validationErrors.hasDuplicates =
            _.some(_.countBy(organisationIds), x => x > 1) ||
            _.some(_.countBy(educationalInstitutionUrns), x => x > 1);
    }

    validateItemsWithoutOrganisationId() {
        if (this.emptyOrganisationsAllowed) {
            return this.validationErrors.itemsWithoutOrganisationId = false;
        }

        if (this.isCoordinatingOrganisationUrn()) {
            return this.validationErrors.itemsWithoutOrganisationId = false;
        }
        return this.validationErrors.itemsWithoutOrganisationId =
            _.isEmpty(this.organisationRoleShares) ||
            _.some(this.organisationRoleShares, share => _.isNil(share.organisationId));
    }

    validateItemsWithoutOrganisationIdAndEducationalInstitutionUrn() {
        if (!this.isCoordinatingOrganisationUrn()) {
            return this.validationErrors.itemsWithoutOrganisationId = false;
        }
        const onlyOneValidationGroup = _.isNil(this.previousValidityGroup) && _.isNil(this.nextValidityGroup);
        if (onlyOneValidationGroup && _.isEmpty(this.organisationRoleShares)) {
            return this.validationErrors.itemsWithoutOrganisationIdAndEducationalInstitutionUrn = false;
        }
        return this.validationErrors.itemsWithoutOrganisationIdAndEducationalInstitutionUrn =
            _.isEmpty(this.organisationRoleShares) ||
            _.some(this.organisationRoleShares, share => _.isNil(share.organisationId) && _.isNil(share.educationalInstitutionUrn));
    }

    validateItemsWithoutShare() {
        return this.validationErrors.itemsWithoutShare = _.some(this.organisationRoleShares, share => _.isNil(share.share));
    }

    validateHasGapBetweenValidityGroups() {
        if (_.isNil(this.previousValidityGroup) || this.isCoordinatingOrganisationUrn()) {
            return this.validationErrors.hasGapBetweenValidityGroups = false;
        }
        const previousEndDate = _.get(this.previousValidityGroup, 'validityPeriod.endDate');
        if (_.isNil(previousEndDate) || _.isNil(this.validityPeriod.startDate)) {
            return this.validationErrors.hasGapBetweenValidityGroups = true;
        }
        // closed beginning, open end -> date must be the same if they are continuous
        return this.validationErrors.hasGapBetweenValidityGroups = moment(this.validityPeriod.startDate).diff(moment(previousEndDate), 'days') !== 0;
    }

    validateHasOverlappingValidityGroups() {
        if (_.isNil(this.previousValidityGroup)) {
            return this.validationErrors.hasOverlappingValidityGroups = false;
        }
        const previousEndDate = _.get(this.previousValidityGroup, 'validityPeriod.endDate');
        if (_.isNil(previousEndDate) || _.isNil(this.validityPeriod.startDate)) {
            return this.validationErrors.hasOverlappingValidityGroups = true;
        }
        return this.validationErrors.hasOverlappingValidityGroups = moment(this.validityPeriod.startDate).isBefore(moment(previousEndDate));
    }

    validateItemsWithOrganisationIdAndEducationalInstitutionUrn() {
        return this.validationErrors.itemsWithOrganisationIdAndEducationalInstitutionUrn = _.some(this.organisationRoleShares, share =>
            !_.isNil(share.organisationId) && !_.isNil(share.educationalInstitutionUrn));
    }

    validateItemsWithoutStartDate() {
        return this.validationErrors.itemsWithoutStartDate = this.startDateRequired && _.isNil(this.validityPeriod.startDate);
    }

    validateItemsWithoutEndDate() {
        return this.validationErrors.itemsWithoutEndDate = this.endDateRequired && _.isNil(this.validityPeriod.endDate);
    }
}
OrganisationRoleShareValidityGroupEditor.$inject = ['$scope', 'uuidService'];

angular.module(organisationRoleShareValidityGroupEditorModule, [
    angularTranslate,
    CodeSelectEditorComponent.downgrade.moduleName,
    localizedStringFilterModule,
    localDateRangeFilterModule,
    'sis-components.date.localDateRangeEditor',
    searchOrganisationModule,
    UuidService.downgrade.moduleName,
]).component('organisationRoleShareValidityGroupEditor', {
    template: organisationRoleShareValidityGroupEditorComponentHtml,
    controller: OrganisationRoleShareValidityGroupEditor,
    controllerAs: 'ctrl',
    bindings: {
        disabledOrganisationIds: '<',
        formName: '@',
        nextValidityGroup: '<',
        onChange: '&',
        isValid: '=?',
        organisationRoleShares: '=',
        emptyOrganisationsAllowed: '<',
        organisationRoleUrn: '<',
        organisationService: '<',
        previousValidityGroup: '<',
        validityPeriod: '=',
    },
});
