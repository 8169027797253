import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxLength } from 'common-typescript/constants';
import { EnrolmentRightAddRequest, OpenUniversityProduct, StudentResultItem } from 'common-typescript/types';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';

import { AlertsService } from '../../../alerts/alerts-ng.service';
import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { maxLength, required } from '../../../form/form-validators';
import { getLabelState } from '../../../form/formUtils';
import { SisFormBuilder } from '../../../form/sis-form-builder.service';
import { EnrolmentRightEntityService } from '../../../service/enrolment-right-entity.service';

export interface AddModalValues {
    product: OpenUniversityProduct;
}

@Component({
    selector: 'sis-enrolment-right-add-modal',
    templateUrl: './enrolment-right-add-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentRightAddModalComponent implements OnInit {
    form: FormGroup;
    getLabelState = getLabelState;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private enrolmentRightService: EnrolmentRightEntityService,
        private modal: NgbActiveModal,
        private fb: SisFormBuilder,
        @Inject(ModalService.injectionToken) public values: AddModalValues,
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            studentId: new FormControl(null, [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
            openUniversityProductId: new FormControl(this.values.product?.id, [required(), maxLength(MaxLength.MAX_MEDIUM_STRING_LENGTH)]),
        });
    }

    onCancel(): void {
        this.modal.dismiss();
    }

    onSubmit(): void {
        if (this.form.valid) {
            const request: EnrolmentRightAddRequest = {
                openUniversityProductId: this.openUniversityProductId.value,
                studentId: this.studentId.value,
            };
            this.enrolmentRightService.addEnrolmentRight(request)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe(this.modal.close);
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
            this.form.markAllAsTouched();
        }
    }

    get studentId(): FormControl {
        return this.form.get('studentId') as FormControl;
    }

    get openUniversityProductId(): FormControl {
        return this.form.get('openUniversityProductId') as FormControl;
    }

    selectStudent(student?: StudentResultItem): void {
        if (student) {
            this.studentId.setValue(student.id);
            this.studentId.markAsDirty();
        } else {
            this.studentId.setValue(null);
        }
    }
}
