import angular from 'angular';
import * as _ from 'lodash-es';
(function () {
  commonTimingTemplateService.$inject = ["$http", "$log"];
  angular.module('sis-components.service.timingTemplateService', []).factory('commonTimingTemplateService', commonTimingTemplateService);

  /**
   * @ngInject
   */
  function commonTimingTemplateService($http, $log) {
    return {
      /**
       * Gets available timing templates by module id and specified document states. Requires user role 'STAFF'.
       * @param moduleId
       * @param documentState - array of document states
       */
      getAvailableTimingTemplates(moduleId, documentState) {
        const params = {
          params: {
            moduleId,
            documentState
          }
        };
        return $http.get('/kori/api/authenticated/timing-templates', params).then(result => result.data);
      },
      /**
       * Gets available active timing templates by module id.
       * @param moduleId
       */
      getActiveTimingTemplates(moduleId) {
        const params = {
          params: {
            moduleId
          }
        };
        return $http.get('/kori/api/timing-templates', params).then(result => result.data);
      },
      /**
       * Filters the given timing templates by given restrictions.
       * @param timingTemplates The timing templates to filter
       * @param universityOrgId The id of the university hosting the degree programme for which the timing templates are
       * @param curriculumPeriods The curriculum periods the templates are supposed to be involved with
       * @return A list that can be used for the selection menu
       */
      filterTimingTemplates(timingTemplates, universityOrgId, curriculumPeriods) {
        $log.debug(`Filtering ${timingTemplates.length} template(s)`);
        const templates = [];
        _.forEach(curriculumPeriods, curriculumPeriod => {
          const curriculumPeriodStartYear = new Date(_.get(curriculumPeriod, 'activePeriod.startDate')).getFullYear();
          const curriculumPeriodEndYear = new Date(_.get(curriculumPeriod, 'activePeriod.endDate')).getFullYear();
          _.filter(timingTemplates, timingTemplate => {
            if (timingTemplate.universityOrgId === universityOrgId && timingTemplate.studyYear >= curriculumPeriodStartYear && timingTemplate.studyYear < curriculumPeriodEndYear) {
              templates.push(timingTemplate);
            }
          });
        });
        const filtered = _.sortBy(templates, 'studyYear');
        $log.debug(`Templates available after filtering: ${filtered.length}`);
        return filtered;
      }
    };
  }
})();