import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { Option } from '../../../select/combobox/combobox.component';
import { CommonCodeService, GroupedCodes } from '../../../service/common-code.service';

function alphabeticalSort(a: Option, b: Option) {
    return (a?.label ?? '').localeCompare(b?.label ?? '');
}

@Component({
    selector: 'sis-search-filter-code-book',
    templateUrl: './search-filter-code-book.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterCodeBookComponent {

    @Input({ required: true })
    set codeBookUrn(urn: string) {
        this.options$ = !urn ? EMPTY : this.codeBookService.getGroupedCodesObservable(urn)
            .pipe(
                map(codes => this.toOptions(codes)),
                catchError(e => {
                    console.error(`Error fetching code book values with URN '${urn}'`, e);
                    return of([]);
                }),
            );
    }

    @Input({ required: true }) key: string;

    @Input({ required: true }) label: string;

    options$: Observable<Option[]>;

    private readonly codeBookService = inject(CommonCodeService);
    private readonly localeService = inject(LocaleService);
    private readonly transloco = inject(TranslocoService);

    private toOptions(codes: GroupedCodes): Option[] {
        const universityCodeOptions = Object.values(codes.universityCodes ?? {})
            .map(code => ({ label: this.localeService.localize(code.name), value: code.urn }))
            .sort(alphabeticalSort);

        const otherCodeOptions = Object.values(codes.otherCodes ?? {})
            .map(code => ({ label: this.localeService.localize(code.name), value: code.urn }))
            .sort(alphabeticalSort);

        if (universityCodeOptions.length > 0 && otherCodeOptions.length > 0) {
            return [
                {
                    label: this.transloco.translate('SIS_COMPONENTS.SELECT.MY_UNIVERSITY'),
                    value: 'MY_UNIVERSITY',
                    header: true,
                },
                ...universityCodeOptions,
                {
                    label: this.transloco.translate('SIS_COMPONENTS.SELECT.OTHER_UNIVERSITIES'),
                    value: 'OTHER_UNIVERSITIES',
                    header: true,
                },
                ...otherCodeOptions,
            ];
        }

        return [...universityCodeOptions, ...otherCodeOptions];
    }
}
