import { Injectable } from '@angular/core';

import { navUrls, NavUtilsService } from './nav-utils.service';

@Injectable({ providedIn: 'root' })
export class ActiveUserRoleService {
    private _activeUserRole: UserRole | null | undefined;

    constructor(private readonly _navUtilsService: NavUtilsService) {}

    /**
     * Returns the active user role, or null if none is active.
     * This doesn't change during a frontend session,
     * as it's assumed that the role changes through navigation (i.e. document reload).
     */
    get activeUserRole(): UserRole | null {
        if (this._activeUserRole === undefined) {
            this._activeUserRole = this.getActiveUserRoleBasedOnPath();
        }

        return this._activeUserRole;
    }

    private getActiveUserRoleBasedOnPath(): UserRole | null {
        switch (this._navUtilsService.getSelectedContextPath()) {
            case navUrls.STUDENT_URL:
                return UserRole.Student;
            case navUrls.TEACHER_URL:
                return UserRole.Teacher;
            case navUrls.STAFF_URL:
                // fall through
            case '/staff':
                return UserRole.Staff;
            case navUrls.ADMIN_URL:
                return UserRole.Admin;
            default:
                return null;
        }
    }
}

export enum UserRole {
    Student = 1,
    Teacher,
    Staff,
    Admin,
}
