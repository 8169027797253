import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { numberUtils } from 'common-typescript';
import { CustomStudyDraft } from 'common-typescript/types';

@Component({
    selector: 'sis-custom-study-draft-summary',
    templateUrl: './custom-study-draft-summary.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomStudyDraftSummaryComponent implements OnChanges {

    heading: string;

    @Input() customStudyDraft: CustomStudyDraft;

    constructor(
        private translateService: TranslateService,
    ) {}

    ngOnChanges() {
        const title = this.translateService.instant('CUSTOM_STUDY_DRAFT_SUMMARY.TITLE');
        const creditsAbbr = this.translateService.instant('CUSTOM_STUDY_DRAFT_SUMMARY.CREDITS_ABBR');
        const credits = numberUtils.numberToString(this.customStudyDraft.credits);
        this.heading = `${title} ${this.customStudyDraft.name}, ${credits} ${creditsAbbr}`;
    }
}
