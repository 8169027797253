import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OrganisationRoleShareBase, Urn } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    OrganisationRoleShareValidityGroup,
    OrganisationRoleShareValidityGroupService,
} from '../organisation-role-share-validity-group.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-organisation-role-share',
    templateUrl: './organisation-role-share.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OrganisationRoleShareComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.organisation.organisation-role-share-ng',
        directiveName: 'sisOrganisationRoleShare',
    };

    constructor(private validityGroupService: OrganisationRoleShareValidityGroupService) { }

    /** Determines grouping panel heading's semantic aria-level for screen readers, default is equivalent for h4 */
    @Input() level?: number = 4;
    @Input() hideLabel: boolean;
    @Input() required: boolean;

    @Input() set organisations(organisations: OrganisationRoleShareBase[]) {
        this._organisations = organisations || [];
        this.organisationsChanged();
    }

    get organisations() {
        return this._organisations;
    }

    @Input() set organisationRoleUrn(organisationRoleUrn: Urn) {
        this._organisationRoleUrn = organisationRoleUrn;
        this.organisationsChanged();
    }

    get organisationRoleUrn() {
        return this._organisationRoleUrn;
    }

    validityGroups: OrganisationRoleShareValidityGroup[] = [];
    showValidityPeriod: boolean;
    private _organisations: OrganisationRoleShareBase[] = [];
    private _organisationRoleUrn: Urn;

    private organisationsChanged(): void {
        this.validityGroups = this.validityGroupService.build(_.filter(this.organisations, { roleUrn: this.organisationRoleUrn }));
        this.showValidityPeriod = (
            this.isCoordinatingOrganisation() &&
                this.validityGroups.length > 0 &&
                _.some(this.validityGroups, validityGroup =>
                    !_.isNil(validityGroup.validityPeriod.endDate) || !_.isNil(validityGroup.validityPeriod.startDate),
                )
        ) ||
            this.validityGroups.length > 1;
    }

    isCoordinatingOrganisation(): boolean {
        return this.organisationRoleUrn === 'urn:code:organisation-role:coordinating-organisation';
    }

    getLabelKey(): string {
        switch (this.organisationRoleUrn) {
            case 'urn:code:organisation-role:coordinating-organisation':
                return 'FIELD_NAMES.coordinatingOrganisations';
            case 'urn:code:organisation-role:responsible-organisation':
                return 'FIELD_NAMES.responsibleOrganisations';
            case 'urn:code:organisation-role:source-organisation':
                return 'FIELD_NAMES.sourceOrganisations';
            default:
                return '';
        }
    }
}
