<sis-search-filter-dropdown-select
        *transloco="let t"
        [key]="'assessmentItemType'"
        [label]="t('SEARCH.FILTER_TAGS.ASSESSMENTITEMTYPE')"
        [options]="[
        {
            label: t('SEARCH.FILTER_TAGS.SHOW_ASSESSMENT_ITEM_TYPE_EXAM'),
            value: 'urn:code:assessment-item-type:exam',
        },
        {
            label: t('SEARCH.FILTER_TAGS.SHOW_ASSESSMENT_ITEM_TYPE_INDEPENDENT_WORK'),
            value: 'urn:code:assessment-item-type:independent-work',
        },
    ]"
/>
