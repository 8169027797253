import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
    CourseUnit,
    EnrolmentRight,
    OtmId,
    StudyRight,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { defaultIfEmpty, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { EnrolmentRightEntityService } from 'sis-components/service/enrolment-right-entity.service';

import {
    openUniversityEnrolmentModalOpener,
} from '../../open-university-enrolment/open-university-enrolment-modal/open-university-enrolment-modal.component';

@Component({
    selector: 'app-open-studies-untimed',
    templateUrl: './open-studies-untimed.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenStudiesUntimedComponent implements OnInit, OnChanges {
    @Input() studyRight: StudyRight;
    data$: Observable<any[]>;
    visibleActiveCourseUnits: any[] = [];
    allActiveCourseUnits: any[] = [];
    visibleUpcomingCourseUnits: any[] = [];
    allUpcomingCourseUnits: any[] = [];
    readonly defaultCourseUnitCount = 6;

    openEnrolmentModal = openUniversityEnrolmentModalOpener();

    constructor(private localeService: LocaleService,
                private appErrorHandler: AppErrorHandler,
                private enrolmentRightEntityService: EnrolmentRightEntityService,
                private courseUnitEntityService: CourseUnitEntityService) {}

    ngOnInit() {
        this.initData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.studyRight.firstChange && (changes.studyRight.previousValue !== changes.studyRight.currentValue)) {
            this.initData();
        }
    }

    initData() {
        this.visibleActiveCourseUnits = [];
        this.allActiveCourseUnits = [];
        this.visibleUpcomingCourseUnits = [];
        this.allUpcomingCourseUnits = [];

        if (this.studyRight) {
            this.data$ = this.getEnrolmentRights().pipe(
                concatMap(enrolmentRights =>
                    this.getCourseUnits(enrolmentRights.map(enrolmentRight => enrolmentRight.courseUnitId))
                        .pipe(map(courseUnits => [enrolmentRights, courseUnits]))),
                map(([enrolmentRights, courseUnits]) => enrolmentRights.map(enrolmentRight => {
                    const courseUnit = _.find(courseUnits, { id: _.get(enrolmentRight, 'courseUnitId') });
                    return {
                        courseUnit,
                        enrolmentRight,
                    };
                })),
                tap((studies) => this.sortStudies(studies)),
                tap(() => this.setVisibleActiveCourseUnits(false)),
                tap(() => this.setVisibleUpcomingCourseUnits(false)),
                defaultIfEmpty([]),
            );
        }
    }

    sortStudies(studies: any) {
        const currentLocale: string = this.localeService.getCurrentLanguage();
        const orderedStudies = _.orderBy(
            studies,
            ['enrolmentRight.validityPeriod.startDate', `courseUnit.name.${currentLocale}`],
            ['asc', 'asc'],
        );

        this.allActiveCourseUnits = orderedStudies.filter(item => (
            moment().isBetween(item.enrolmentRight.validityPeriod.startDate, item.enrolmentRight.validityPeriod.endDate)
        ));

        this.allUpcomingCourseUnits = orderedStudies.filter(item => (
            moment().isBefore(item.enrolmentRight.validityPeriod.startDate)
        ));
    }

    getCourseUnits(courseUnitIds: OtmId[]): Observable<CourseUnit[]> {
        return this.courseUnitEntityService.getByIds(courseUnitIds)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    getEnrolmentRights(): Observable<EnrolmentRight[]> {
        return this.enrolmentRightEntityService.getByStudyRightIds(this.studyRight.id).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map(enrolmentRights => enrolmentRights.filter(
                enrolmentRight => enrolmentRight.state === 'ACTIVATED'
                    && enrolmentRight.documentState === 'ACTIVE'
                    && enrolmentRight.type === 'CUR_ENROLMENT'
                    && moment(enrolmentRight.validityPeriod.endDate).isAfter(moment()))),
        );
    }

    setVisibleActiveCourseUnits = (more: boolean): void => {
        let count = more ? this.visibleActiveCourseUnits.length + this.defaultCourseUnitCount : this.visibleActiveCourseUnits.length - this.defaultCourseUnitCount;
        if (count < this.defaultCourseUnitCount) count = this.defaultCourseUnitCount;
        const value = this.allActiveCourseUnits.length < count ? this.allActiveCourseUnits.length : count;
        this.visibleActiveCourseUnits = this.allActiveCourseUnits.slice(0, value);
    };

    setVisibleUpcomingCourseUnits = (more: boolean): void => {
        let count = more ? this.visibleUpcomingCourseUnits.length + this.defaultCourseUnitCount : this.visibleUpcomingCourseUnits.length - this.defaultCourseUnitCount;
        if (count < this.defaultCourseUnitCount) count = this.defaultCourseUnitCount;
        const value = this.allUpcomingCourseUnits.length < count ? this.allUpcomingCourseUnits.length : count;
        this.visibleUpcomingCourseUnits = this.allUpcomingCourseUnits.slice(0, value);
    };
}
