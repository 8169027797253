import angular from 'angular';
import * as _ from 'lodash-es';
import anyCourseUnitRuleTpl from './anyCourseUnitRule.tpl.html';
import { BaseCourseUnitRuleController } from './BaseCourseUnitRuleController';
(function () {
  AnyCourseUnitRuleController.$inject = ["$scope", "$log", "uiContext", "PlanState", "courseUnitInfoModal", "ruleSearchParametersService", "defaultPromiseHandler", "rawPlanEditService", "planContextEventType", "validAttainmentFilterService", "searchResultHelperService", "confirmDialogService", "universityService", "commonStudyRightService"];
  angular.module('student.plan.rules').component('anyCourseUnitRule', {
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      studyRight: '<',
      openCustomStudyDraftInfoModalCallback: '&'
    },
    controller: AnyCourseUnitRuleController,
    template: anyCourseUnitRuleTpl
  });
  function AnyCourseUnitRuleController(
  // NOSONAR
  $scope, $log, uiContext, PlanState, courseUnitInfoModal, ruleSearchParametersService, defaultPromiseHandler, rawPlanEditService, planContextEventType, validAttainmentFilterService, searchResultHelperService, confirmDialogService, universityService, commonStudyRightService) {
    const ctrl = this;
    ctrl.getValidatablePlan = function () {
      return _.get(uiContext, 'planContext.validatablePlan');
    };
    $scope.$on(planContextEventType.planBuilt, initialize);
    ctrl.$onInit = function () {
      ctrl.ripaCheckboxChecked = false;
      ctrl.effectiveCooperationNetworkIdsForSearch = null;
      initialize();
    };
    function initialize() {
      if (_.isNil(uiContext.planContext)) {
        return;
      }
      ctrl.crossStudyFeaturesEnabled = false;
      BaseCourseUnitRuleController.call(ctrl, uiContext, PlanState, courseUnitInfoModal, validAttainmentFilterService);
      uiContext.planContext.toPlan().then(plan => {
        ctrl.rawPlan = plan;
        return ruleSearchParametersService.getCurriculumPeriodsForSearch(plan.curriculumPeriodId).then(curriculumPeriods => {
          ctrl.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      universityService.getCurrentUniversitySettings().then(universitySettings => {
        ctrl.crossStudyFeaturesEnabled = _.get(universitySettings, 'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent') || false;
        if (ctrl.crossStudyFeaturesEnabled && ctrl.studyRight) {
          ctrl.cooperationNetworkIds = commonStudyRightService.getValidCooperationNetworkIds(ctrl.studyRight);
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    ctrl.selectSuitableVersionOfCourseUnit = function (courseUnit) {
      uiContext.planContext.selectCourseUnitByGroupId(ctrl.module, _.get(courseUnit, 'groupId'));
    };
    ctrl.courseUnits = function () {
      let ret = [];
      const ruleValidationResult = ctrl.getRuleValidationResult();
      if (ruleValidationResult) {
        ret = _.filter(_.values(ruleValidationResult.selectedCourseUnitsById), courseUnit => !ctrl.validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit));
      }
      if (uiContext.unplannedCourseUnits) {
        _.map(uiContext.unplannedCourseUnits, cu => {
          ret.push(cu);
        });
      }
      return _.uniqBy(ret, 'id');
    };
    ctrl.customCourseUnitAttainments = () => {
      let ret = [];
      const ruleValidationResult = ctrl.getRuleValidationResult();
      if (ruleValidationResult) {
        ret = _.values(ruleValidationResult.selectedCustomCourseUnitAttainmentsById);
      }
      if (uiContext.unplannedCustomCourseUnitAttainments) {
        _.map(uiContext.unplannedCustomCourseUnitAttainments, ccua => {
          ret.push(ccua);
        });
      }
      return ret;
    };
    ctrl.customStudyDrafts = () => {
      const ruleValidationResult = ctrl.getRuleValidationResult();
      return ruleValidationResult ? _.values(ruleValidationResult.selectedCustomStudyDraftsById) : [];
    };
    ctrl.isCourseUnitSelectionDisabled = courseUnit => uiContext.planContext.isCourseUnitSelectionDisabled(courseUnit, ctrl.module);
    ctrl.isSearchResultDisabled = courseUnitSearchResult => searchResultHelperService.isCourseUnitSearchResultDisabled(courseUnitSearchResult, ctrl.module, ctrl.validatablePlan);
    ctrl.getStatusIcon = function (searchItem) {
      const courseUnit = uiContext.planContext.getCourseUnit(searchItem.id, undefined);
      if (courseUnit) {
        if (ctrl.isSelected(courseUnit)) {
          return 'delete';
        }
        return ctrl.isCourseUnitSelectionDisabled(courseUnit) && !ctrl.isCourseUnitRemoveAllowed(courseUnit) ? 'lock' : 'place';
      }
      return undefined;
    };
    ctrl.selectCustomCourseUnitAttainment = function (customCourseUnitAttainment) {
      rawPlanEditService.selectCustomCourseUnitAttainment(customCourseUnitAttainment.id, ctrl.module.id, ctrl.rawPlan);
      return uiContext.planContext.buildAndUpdate(ctrl.rawPlan);
    };
    ctrl.unselectCustomCourseUnitAttainment = function (customCourseUnitAttainment) {
      rawPlanEditService.removeCustomCourseUnitAttainment(customCourseUnitAttainment.id, ctrl.rawPlan);
      return uiContext.planContext.buildAndUpdate(ctrl.rawPlan);
    };
    ctrl.openCustomStudyDraftInfo = customStudyDraft => {
      ctrl.openCustomStudyDraftInfoModalCallback({
        customStudyDraft
      });
    };
    ctrl.removeCustomStudyDraft = customStudyDraftId => {
      const rawPlan = ctrl.validatablePlan.plan;
      const customStudyDraft = _.find(rawPlan.customStudyDrafts, {
        id: customStudyDraftId
      });
      if (!customStudyDraft) {
        $log.warn('Could not find customStudyDraft by id: ', customStudyDraftId);
        return;
      }
      const confirmTexts = {
        title: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.TITLE',
        description: 'PLAN.DELETE_CUSTOM_STUDY_DRAFT_MODAL.DESCRIPTION',
        name: customStudyDraft.name
      };
      confirmDialogService.confirm(confirmTexts).then(() => {
        rawPlanEditService.removeCustomStudyDraft(customStudyDraftId, rawPlan);
        uiContext.planContext.buildAndUpdate(rawPlan);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.toggleRipaStudiesForSearch = $event => {
      ctrl.ripaCheckboxChecked = $event;
      ctrl.effectiveCooperationNetworkIdsForSearch = $event === true ? ctrl.cooperationNetworkIds : null;
      $scope.$applyAsync();
    };
  }
})();