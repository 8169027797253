<ng-container *transloco="let t">
  <sis-study-box [name]="module.name"
                 [level]="headingLevel"
                 [credits]="module.targetCredits"
                 [code]="module.code"
                 [attainedCredits]="attainment?.credits"
                 [gradeId]="attainment?.gradeId"
                 [gradeScaleId]="attainment?.gradeScaleId"
                 [type]="boxType"
                 [id]="module.id"
                 [studyState]="studyState"
                 [selected]="selected"
                 [action]="action"
                 [inputType]="inputType"
                 [actionInputId]="actionInputId"
                 [actionInputName]="actionInputName"
                 (actionChange)="actionChange.emit($event)"
                 (nameClickAction)="openStudyModuleInfoModal()">
    <ng-container study-box="notifications">
      <ng-content></ng-content>
      <sis-study-notification-box *ngIf="invalidSelection" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_SELECTION') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="moduleStateObject?.isInvalidAccordingToModuleContentApprovalRequested" type="alert">{{ t('SIS_COMPONENTS.STUDY.INVALID_ACCORDING_TO_MODULE_CONTENT_APPROVAL') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="moduleStateObject?.isInvalidAccordingToModuleContentApprovalAccepted" type="alert">{{ t('SIS_COMPONENTS.STUDY.CONTENT_NOT_AS_APPROVED') }}</sis-study-notification-box>
      <sis-study-notification-box *ngIf="attainment && moduleStateObject?.isAttainmentAboutToExpire" type="info">{{ t('SIS_COMPONENTS.STUDY.EXPIRATION_IMMINENT', { expiryDate: moduleStateObject.attainmentExpiryDate | localDateFormat}) }}</sis-study-notification-box>
    </ng-container>
  </sis-study-box>
</ng-container>
