<ng-container *transloco="let t">
  <label class="sis-radio control control-radio" [class.control-inline]="inline">
    <span>{{ translatedLabel ?? t(label) }}
      <sis-tiny-badge *ngIf="badgeText"
                      data-cy="radio-button-badge"
                      class="label-badge"
                      [variant]="badgeVariant">{{ t(badgeText) }}</sis-tiny-badge>
      <span *ngIf="guidance" class="sis-guidance">{{ t(guidance) }}</span>
    </span>
    <!--attr.disable is a workaround for disabling radio buttons in a group that share the same control.
        More info: https://stackoverflow.com/questions/48890608/disable-a-radio-button-conditionally-in-a-radio-button-group-inside-reactive-for-->
    <!-- name & attr.name => we need both: 'name' for ng to group radio buttons correctly and 'attr.name' to group native radio buttons.
         Also depending which screen reader you are using, it uses one or the other to group radio buttons correctly.
     -->
    <input type="radio"
           [formControl]="control"
           [value]="value"
           [attr.name]="name"
           [name]="name"
           [attr.disabled]="disabled ? true : null"
           [attr.id]="id"
           [attr.aria-describedby]="ariaDescribedBy"
           [attr.required]="required"
           [attr.aria-disabled]="ariaDisabled ? true : null"
           (click)="preventSelection($event)"
           data-cy="radio-button">
    <span class="control-indicator"></span>
  </label>
</ng-container>
