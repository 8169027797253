import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dateUtils } from 'common-typescript/constants';
import {
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    EnrolmentData,
    EnrolmentDataChange,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import {
    exhaustMap,
    first,
    forkJoin,
    merge,
    Observable,
    of, scan, shareReplay, startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
    timer, Timestamp, withLatestFrom,
} from 'rxjs';
import { filter, finalize, map, share, take, timestamp } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { ConfirmDialogService } from 'sis-components/confirm/confirm-dialog.service';
import { enrolmentDataChangeDescriptionFetcher } from 'sis-components/enrolment/enrolment-data-change-utils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';
import { NotificationsService } from 'sis-components/service/notifications/notifications.service';

import { EnrolmentStudentService } from '../../common/service/enrolment-student.service';
import { enrolmentAbortModalOpener } from '../enrolment-abort-modal/enrolment-abort-modal.component';
import { enrolmentCancellationModalOpener } from '../enrolment-cancellation-modal/enrolment-cancellation-modal.component';
import { CancelEvent } from '../enrolment-state-course-units/enrolment-state-course-units.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-enrolments-main-container',
    templateUrl: './enrolments-main-container.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentsMainContainerComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.enrolments.enrolmentsMainContainer.downgraded',
        directiveName: 'appEnrolmentsMainContainer',
    };

    @ViewChild('updateStatus') updateStatus: ElementRef;

    autoUpdate$: Subject<Enrolment> = new Subject<Enrolment>();
    enrolmentDataChangeEvents$: Observable<Timestamp<EnrolmentDataChange>[]>;
    enrolmentsData$: Observable<EnrolmentData[]>;
    enrolments: Enrolment[] = [];
    destroyed$ = new Subject<void>();
    isMobileView$: Observable<boolean>;
    loading$ = new Subject<boolean>();
    private resetSignal = new Subject<void>();
    private enrolmentChangeListener: Observable<EnrolmentDataChange>;

    private readonly enrolmentDataChangeDescriptionFetcher = enrolmentDataChangeDescriptionFetcher();

    private readonly openEnrolmentAbortModal = enrolmentAbortModalOpener();
    private readonly openEnrolmentCancellationModal = enrolmentCancellationModalOpener();

    private enrolmentUpdateHandler$ = new Subject<void>();

    realisationLists = [
        {
            titleKey: 'ENROLMENTS.ENROLMENT_MISSING',
            enrolmentTypeKeys: ['NOT_ENROLLED'],
            emptyListTextKey: 'ENROLMENTS.ENROLMENT_MISSING_EMPTY_LIST',
        },
        {
            titleKey: 'ENROLMENTS.ENROLMENT_PROCESSING',
            enrolmentTypeKeys: ['PROCESSING'],
            emptyListTextKey: 'ENROLMENTS.ENROLMENT_PROCESSING_EMPTY_LIST',
        },
        {
            titleKey: 'ENROLMENTS.ENROLMENT_CONFIRMED',
            enrolmentTypeKeys: ['REJECTED', 'INVALID', 'ENROLLED'], // This array order also defines the order of the enrolments in the list
            emptyListTextKey: 'ENROLMENTS.ENROLMENT_CONFIRMED_EMPTY_LIST',
        },
    ];

    constructor(
        private enrolmentStudentService: EnrolmentStudentService,
        private appErrorHandler: AppErrorHandler,
        private courseUnitEntityService: CourseUnitEntityService,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private localeService: LocaleService,
        private alertsService: AlertsService,
        private confirmDialogService: ConfirmDialogService,
        private translate: TranslateService,
        private breakpointService: BreakpointService,
        private notificationService: NotificationsService,
    ) {
    }

    ngOnInit(): void {
        this.enrolmentChangeListener = this.notificationService.getEventsObservable<EnrolmentDataChange>('enrolmentChange').pipe(
            takeUntil(this.destroyed$),
            filter(event => event.personId !== event.changedBy),
            share(),
        );
        this.initializeLoading();
        this.initializeMobileViewListener();
        this.initializeEnrolmentsData();
        this.initializeEnrolmentDataChangeEventsObservable();
        this.startAutoUpdateListener();
        this.enrolmentChangeListener.subscribe();
    }

    private initializeLoading() {
        this.enrolmentStudentService.selectLoading().pipe(takeUntil(this.destroyed$), tap((loading) => this.loading$.next(loading))).subscribe();
    }

    private initializeMobileViewListener() {
        this.isMobileView$ = this.breakpointService.breakpoint$
            .pipe(
                takeUntil(this.destroyed$),
                map((breakpoint) => breakpoint < Breakpoint.MD),
            );
    }

    private initializeEnrolmentsData() {
        this.enrolmentsData$ = this.loadEnrolments();
    }

    private createEnrolmentUpdateHandler() {
        return this.enrolmentUpdateHandler$.asObservable()
            .pipe(
                tap(() => this.loading$.next(true)),
                tap(() => this.resetEvents()),
                exhaustMap(() =>
                    this.enrolmentStudentService.getAllEnrolments(true)
                        .pipe(take(1), this.appErrorHandler.defaultErrorHandler()),
                ),
            );
    }

    private loadEnrolments(): Observable<EnrolmentData[]> {
        return merge(
            this.enrolmentStudentService.getAllEnrolments(true),
            this.createEnrolmentUpdateHandler(),
        )
            .pipe(
                tap(enrolments => this.enrolments = enrolments),
                tap(() => this.loading$.next(true)),
                switchMap((enrolments: Enrolment[]) => forkJoin([
                    of(enrolments),
                    this.courseUnitEntityService.getByIds(enrolments.map(e => e.courseUnitId)).pipe(first()),
                    this.courseUnitRealisationEntityService.getByIds(enrolments.map(e => e.courseUnitRealisationId)).pipe(first()),
                ])),
                map(([enrolments, courseUnits, courseUnitRealisations]) => this.filterEnrolmentResults(enrolments, courseUnits, courseUnitRealisations)
                    .map((enrolment) => ({
                        enrolment,
                        courseUnitRealisation: this.getCur(enrolment, courseUnitRealisations),
                        courseUnit: this.getCu(enrolment, courseUnits),
                    } as EnrolmentData))),
                this.appErrorHandler.defaultErrorHandler(),
                tap(() => this.loading$.next(false)),
                finalize(() => this.loading$.next(false)),
            );
    }

    private initializeEnrolmentDataChangeEventsObservable() {
        this.enrolmentDataChangeEvents$ =
            merge(
                this.enrolmentChangeListener,
                this.resetSignal.pipe(map(() => undefined)),
            )
                .pipe(
                    timestamp(),
                    scan(
                        (acc, dataChangeWithTimestamp) =>
                            !dataChangeWithTimestamp.value ? [] : [...(acc.filter(event => event.value.enrolmentId !== dataChangeWithTimestamp.value.enrolmentId)), dataChangeWithTimestamp],
                        [] as Timestamp<EnrolmentDataChange>[],
                    ),
                    startWith([] as Timestamp<EnrolmentDataChange>[]),
                    tap((events: Timestamp<EnrolmentDataChange>[]) => {
                        if (events.length > 0) {
                            console.debug('Received event', _.last(events));
                        }
                    }),
                    shareReplay(1),
                );
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    getEnrolmentsByType(enrolmentsData: EnrolmentData[], keys: ('NOT_ENROLLED' | 'PROCESSING' | 'ENROLLED' | 'REJECTED' | 'INVALID')[]): Enrolment[] {
        return keys.map(key => _.filter(enrolmentsData.map(data => data.enrolment), ['state', key])).flat();
    }

    private getCu(enrolment: Enrolment, courseUnits: CourseUnit[]) {
        return courseUnits.find(cur => cur.id === enrolment.courseUnitId);
    }

    private getCur(enrolment: Enrolment, courseUnitRealisations: CourseUnitRealisation[]) {
        return courseUnitRealisations.find(cur => cur.id === enrolment.courseUnitRealisationId);
    }

    private filterEnrolmentResults(enrolments: Enrolment[], courseUnits: CourseUnit[], courseUnitRealisations: CourseUnitRealisation[]) {
        const filteredEnrolments = this.filterActiveEnrolments(enrolments, courseUnitRealisations);
        return this.sortEnrolments(filteredEnrolments, courseUnits, courseUnitRealisations);
    }

    private filterActiveEnrolments(enrolments: Enrolment[], courseUnitRealisations: CourseUnitRealisation[]) {
        // Remove enrolments that have no realisation or have realisation that has ended
        return enrolments.filter(enrolment => {
            const courseUnitRealisation = this.getCur(enrolment, courseUnitRealisations);
            if (!courseUnitRealisation) return false;
            return !dateUtils.isRangeBefore(moment(), courseUnitRealisation.activityPeriod);

        });
    }

    private getLocalizedName(courseUnit: CourseUnit, courseUnitRealisation: CourseUnitRealisation) {
        return `${this.localeService.localize(courseUnit.name)}, ${this.localeService.localize(courseUnitRealisation.name)}`;
    }

    sortEnrolments(enrolments: Enrolment[], courseUnits: CourseUnit[], courseUnitRealisations: CourseUnitRealisation[]): Enrolment[] {
        // Sort realisations by enrolment end date.
        enrolments.sort((a, b) => {
            const aCur = this.getCur(a, courseUnitRealisations);
            const bCur = this.getCur(b, courseUnitRealisations);

            const aEnd = aCur?.enrolmentPeriod?.endDateTime;
            const bEnd = bCur?.enrolmentPeriod?.endDateTime;
            if (!aEnd) return 1;
            if (!bEnd) return -1;

            // If two realisations have the same end dates, sort alphabetically.
            if (aEnd === bEnd) {
                return this.getLocalizedName(this.getCu(a, courseUnits), this.getCur(a, courseUnitRealisations))
                    .localeCompare(this.getLocalizedName(this.getCu(a, courseUnits), this.getCur(a, courseUnitRealisations)));
            }
            return new Date(aEnd).getTime() - new Date(bEnd).getTime();
        });

        return enrolments;
    }

    handleEnrolment(enrolment: Enrolment) {
        this.autoUpdate$.next(enrolment);
    }

    handleRemoveEnrolment(enrolment: Enrolment) {
        this.confirmDialogService.confirm({
            title: this.translate.instant('ENROLMENT.CONFIRM_REMOVE_TITLE'),
            description: this.translate.instant('ENROLMENT.CONFIRM_REMOVE_INFO'),
        }).then(
            () => {
                this.removeEnrolment(enrolment);
            },
            () => {
                // Do nothing
            },
        );
    }

    removeEnrolment(enrolment: Enrolment) {
        this.autoUpdate$.next(enrolment);
        this.enrolmentStudentService.delete(enrolment.id)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => this.handleRemoveResult(enrolment));
    }

    handleRemoveResult(enrolment: Enrolment) {
        this.showSuccessAlert('ENROLMENT_DELETE_SUCCESS');
    }

    handleCancelEnrolment(event: CancelEvent) {
        const enrolment = event.enrolment;
        const action = event.action;
        if (action === 'abort') {
            this.openEnrolmentAbortModal(enrolment)
                .closed.pipe(
                    switchMap((abortedEnrolment: Enrolment) => this.removeEventsFromCalendar(abortedEnrolment)),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe((abortedEnrolment: Enrolment) => this.handleEnrolment(abortedEnrolment));
        } else if (action === 'cancel') {
            this.openEnrolmentCancellationModal(enrolment)
                .closed.subscribe((cancelledEnrolment) => this.handleEnrolment(cancelledEnrolment));
        }
    }

    removeEventsFromCalendar(enrolment: Enrolment): Observable<Enrolment> {
        const updatedEnrolment = _.cloneDeep(enrolment);
        updatedEnrolment.studySubGroups.forEach(ssg => ssg.isInCalendar = false);
        return this.enrolmentStudentService.update(enrolment.id, updatedEnrolment);
    }

    showSuccessAlert(messageKey: string) {
        this.alertsService.addTemporaryAlert({
            type: AlertType.INFO,
            message: this.translate.instant(`ENROLMENT.${messageKey}`),
        });
    }

    updateButtonClicked() {
        this.updateStatus.nativeElement.setAttribute('aria-live', 'polite');
        this.enrolmentUpdateHandler$.next();
    }

    getEnrolmentChangeDescription(enrolmentDataChange: EnrolmentDataChange, enrolmentsData: EnrolmentData[]) {
        const enrolmentData = this.getEnrolmentData(enrolmentDataChange.enrolmentId, enrolmentsData);
        return this.enrolmentDataChangeDescriptionFetcher(enrolmentDataChange, enrolmentData);
    }

    private getEnrolmentData(enrolmentId: OtmId, enrolmentsData: EnrolmentData[]) {
        return enrolmentsData.find(data => data.enrolment.id === enrolmentId);
    }

    private startAutoUpdateListener() {
        this.autoUpdate$
            .pipe(
                withLatestFrom(this.enrolmentDataChangeEvents$),
                this.takeOnlyEventsFromAMomentAgo(),
                this.reloadEnrolmentsIfThereWasAnEvent(),
                this.switchToListeningToEventsAndAutomaticallyUpdate(),
                takeUntil(this.destroyed$),
                finalize(() => console.debug('Auto update listener finished')),
            ).subscribe();

    }

    private switchToListeningToEventsAndAutomaticallyUpdate() {
        return switchMap(([enrolment, _events]: [enrolment: Enrolment, _events: any]) => this.enrolmentChangeListener
            .pipe(
                takeUntil(timer(5000)), // listen changes for 5 second and automatically update the store
                filter(enrolmentDataChange => enrolmentDataChange.enrolmentId === enrolment.id),
                this.filterOnlyNewEnrolments(enrolment),
                tap((_e) => console.debug('Fetching changes to', enrolment.id)),
                tap((_e) => this.enrolmentUpdateHandler$.next()),
                finalize(() => console.debug('Stopped listening to changes')),
            ));
    }

    private filterOnlyNewEnrolments(enrolment: Enrolment) {
        return filter((enrolmentDataChange: EnrolmentDataChange) =>
            !enrolmentDataChange?.enrolmentRevision ||
            !enrolment?.metadata?.revision ||
            enrolmentDataChange.enrolmentRevision > enrolment.metadata?.revision,
        );
    }

    private reloadEnrolmentsIfThereWasAnEvent() {
        return tap(([enrolment, events]: [enrolment: Enrolment, events: Timestamp<EnrolmentDataChange>[]]) => {
            if (events.map(change => change.value.enrolmentId).includes(enrolment.id)) {
                this.enrolmentUpdateHandler$.next();
            }
        });
    }

    getEnrolments(enrolmentsData: EnrolmentData[]): Enrolment[] {
        return enrolmentsData.map(d => d.enrolment);
    }

    private resetEvents() {
        this.resetSignal.next();
    }

    private takeOnlyEventsFromAMomentAgo() {
        return map(([enrolment, events]: [enrolment: Enrolment, events: Timestamp<EnrolmentDataChange>[]]) => {
            const momentAgo = Date.now() - 5000;
            return [enrolment, events.filter(e => e.timestamp > momentAgo)];
        });
    }

}
