<ng-container *ngIf="loaded$ | async">
  <ng-container *transloco="let t">
    <fudis-section
      [title]="t('PROFILE.DISCLOSURE_AUTHORIZATION.AUTHORIZATION_TITLE')"
      [titleVariant]="'sm'"
      [level]="3"
      data-cy="disclosure-authorizations-section"
    >
      @if (!this.editDisabled) {
        <fudis-section-actions>
          <fudis-button
            #disclosureAuthEditButton
            [id]="'disclosureAuthEditButton'"
            [variant]="'primary'"
            [label]="t('SIS_COMPONENTS.BUTTON.EDIT')"
            (handleClick)="startEdit()"
          />
        </fudis-section-actions>
      }
      <fudis-section-content>
        <div class="disclosure-authorizations">
          <p class="sis-guidance" *ngIf="!this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.NOTE_ON_AUTHORIZATION_USE')}}</p>
          <p class="sis-guidance" *ngIf="this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.STAFF.NOTE_ON_AUTHORIZATION_USE')}}</p>
          <sis-contextual-notification *ngIf="this.hasCategoriesWithoutAnswers()" [variant]="'warning'">
            <span *ngIf="!this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.NO_SELECTIONS_MADE')}}</span>
            <span *ngIf="this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.STAFF.NO_SELECTIONS_MADE')}}</span>
            <ul>
              <li *ngFor="let category of  this.categoriesWithoutAnswers">
                <span>{{category.description|localizedString}}</span>
              </li>
            </ul>
          </sis-contextual-notification>
          <sis-contextual-notification *ngIf="this.hasClassifiedData()" [variant]="'warning'">
            <span *ngIf="!this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.CLASSIFIED_SELECTIONS')}}</span>
            <span *ngIf="this.staffEdit">{{t('PROFILE.DISCLOSURE_AUTHORIZATION.STAFF.CLASSIFIED_SELECTIONS')}}</span>
          </sis-contextual-notification>
          <div *ngIf="this.hasAnswers() && this.hasGranted()">
            <!--Need to use transloco element here because otherwise strong tag would appear in ui with translation-->
            <p class="authorization-text"><transloco key="PROFILE.DISCLOSURE_AUTHORIZATION.AUTHORIZATION_GRANTED"></transloco></p>
            <ul class="authorizations">
              <li *ngFor="let authorization of this.authorizedAnswers()" data-cy="disclosure-authorized">
                <ng-container *ngTemplateOutlet="popOver;context:{ authorization: authorization, authorized: true}"></ng-container>
              </li>
            </ul>
          </div>
          <div *ngIf="this.hasAnswers() && this.hasDenied()">
            <p class="authorization-text"><transloco key="PROFILE.DISCLOSURE_AUTHORIZATION.AUTHORIZATION_DENIED"></transloco></p>
            <ul class="authorizations">
              <li *ngFor="let authorization of this.notAuthorizedAnswers()" data-cy="disclosure-not-authorized">
                <ng-container *ngTemplateOutlet="popOver;context:{ authorization: authorization, authorized: false}"></ng-container>
              </li>
            </ul>
          </div>
        </div>
      </fudis-section-content>
    </fudis-section>

    <ng-template #popOver let-authorization="authorization" let-authorized="authorized">
      {{getDescription(authorization.disclosureCategoryId)|localizedString}}
      <sis-popover *ngIf="this.changedByOther(authorization) && this.staffEdit"
                   [popoverClass]="'dark'"
                   [popoverText]="t(authorized ? 'PROFILE.DISCLOSURE_AUTHORIZATION.GRANTED_BY_AND_DATE' :
                   'PROFILE.DISCLOSURE_AUTHORIZATION.DENIED_BY_AND_DATE', { fullName: authorization.changedByPersonId|publicPerson|async|personFullName, date: authorization.metadata.lastModifiedOn|date })"
                   [popoverPlacement]="'top'">
        <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('SIS-COMMON.ARIA_LABEL.SHOW_DETAILS')">
          <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
        </button>
      </sis-popover>
      <sis-popover *ngIf="this.changedByOther(authorization) && !this.staffEdit"
                   [popoverClass]="'dark'"
                   [popoverText]="t(authorized ? 'PROFILE.DISCLOSURE_AUTHORIZATION.GRANTED_BY_ADMIN_AND_DATE' :
                   'PROFILE.DISCLOSURE_AUTHORIZATION.DENIED_BY_ADMIN_DATE', {date: authorization.metadata.lastModifiedOn | date})"
                   [popoverPlacement]="'top'">
        <button type="button" class="non-button sis-popover-trigger-content" [attr.aria-label]="t('SIS-COMMON.ARIA_LABEL.SHOW_DETAILS')">
          <sis-icon [icon]="'info-circle'" [color]="'primary'"></sis-icon>
        </button>
      </sis-popover>
    </ng-template>
  </ng-container>
</ng-container>
