import { Pipe, PipeTransform } from '@angular/core';
import { StudyEvent } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';

@Pipe({ name: 'studyEventTimeRange' })
export class StudyEventTimeRangePipe implements PipeTransform {

    transform(studyEvent: StudyEvent): string {
        if (!studyEvent) {
            return '';
        }
        const startMoment = moment(studyEvent.startTime);
        const endMoment = moment(studyEvent.startTime).add(moment.duration(studyEvent.duration));
        return _.join([startMoment.format('H.mm'), endMoment.format('H.mm')], '–');
    }
}
