import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { PlanSelectorValues } from './planSelectorModal.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'plan-selector-modal' })
export class PlanSelectorModalDirective extends UpgradeComponent {

    @Output() close: EventEmitter<any>;
    @Output() dismiss: EventEmitter<any>;
    @Input() resolve: PlanSelectorValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('planSelectorModal', elementRef, injector);
    }
}
