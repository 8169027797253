import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import angular, { IFormController, INgModelController } from 'angular';
import {
    ISO_LOCAL_DATE_FORMAT,
    ISO_LOCAL_DATE_TIME_FORMAT,
    ISO_LOCAL_TIME_FORMAT,
    MOMENT_DATE_DISPLAY_FORMAT,
    MOMENT_TIME_DISPLAY_FORMAT,
} from 'common-typescript/constants';
import {
    DurationString,
    LocalDateRange,
    LocalDateString,
    LocalDateTimeRange,
    LocalDateTimeString,
    LocalTimeRange,
    LocalTimeString,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { RequestQueue } from 'sis-common/request/requestUtils';

import { calculateDuration } from '../date/timeUtils';

/**
 * Returns true, if the given value is "empty", i.e. `null`, `undefined`, empty string, or empty array.
 * This is how Angular core checks for empty values (see `validators.ts` in the Angular source code).
 */
export function isEmptyInputValue(value: any): boolean {
    return value === null || value === undefined || value.length === 0;
}

/**
 * Returns all ngModelControllers from the given AngularJS form, also from nested child forms.
 */
export function getAllControls(formController: angular.IFormController): (angular.INgModelController | angular.IFormController)[] {
    if (!_.hasIn(formController, '$getControls')) {
        return [];
    }

    const ngModelControllers = formController.$getControls()
        .filter((control: IFormController | INgModelController) => _.hasIn(control, '$modelValue'));
    const ngFormControllers = formController.$getControls()
        .filter((control: IFormController | INgModelController) => _.hasIn(control, '$getControls'));
    return ngModelControllers.concat(ngFormControllers.flatMap(getAllControls));
}

/**
 * A hackish way of determining if a form control has been defined as required or not. Works by invoking the control's
 * validation function with an empty control object and seeing if the validation results contain an error object with the
 * `required` or `mustHaveProperty` keys. Also works for `FormGroup`s and `FormArray`s with the `oneChildValueRequired`
 * or `allChildValuesRequired` validators. Adapted from https://stackoverflow.com/q/39819123/2782785
 */
export function isRequired(control: AbstractControl): boolean {
    if (_.isNil(control) || !_.isFunction(control.validator)) {
        return false;
    }

    const validationErrors = control.validator({} as AbstractControl);
    if (validationErrors && _.intersection(Object.keys(validationErrors), ['required', 'mustHaveProperty']).length > 0) {
        return true;
    }

    if (!_.isEmpty(_.get(control, 'controls'))) {
        const errors = control.validator(new FormGroup({ '': new FormControl() }));
        return !_.isNil(errors) && _.intersection(Object.keys(errors), ['oneChildValueRequired', 'allChildValuesRequired']).length > 0;
    }

    return false;
}

export function isFormArray(control: any): control is FormArray {
    return control instanceof FormArray;
}

export function isFormGroup(control: any): control is FormGroup {
    return control instanceof FormGroup;
}

export function isFormControl(control: any): control is FormControl {
    return control instanceof FormControl;
}

/**
 * Clear the form array contents, mark it as pristine and untouched, and optionally populate it with the given controls.
 * Note that any validators assigned to the array itself will be left untouched.
 */
export function resetAndReplaceFormArrayContents<T>(array: FormArray, newControls?: AbstractControl[]): void {
    array?.clear();
    array?.reset();
    newControls?.forEach(control => array?.push(control));
}

/**
 * Returns the direct child controls of the given control (i.e. does not recursively travel the tree).
 */
export function getChildControls(control: FormArray | FormGroup): AbstractControl[] {
    if (isFormArray(control)) {
        return control.controls;
    }
    if (isFormGroup(control)) {
        return Object.values(control.controls);
    }
    return [];
}

/**
 * Returns recursively all possible children including itself.
 */
export function getChildrenRecursive(control: AbstractControl): AbstractControl[] {
    const itself = [control];
    if (isFormArray(control) || isFormGroup(control)) {
        return itself.concat(getChildControls(control).flatMap(ctrl => getChildrenRecursive(ctrl)));
    }
    return itself;
}

/**
 * Returns the label state for the given child control.
 *
 * If the form group has validation errors, all child controls' label state is invalid.
 * Otherwise the state is based on the state of the given child control.
 *
 * @param control parent form group control
 * @param childControl child control whose label to return
 */
export function getChildControlLabelState(control: FormGroup, childControl: AbstractControl): string {
    if (control) {
        return (control.errors && (control.touched || control.dirty)) ? 'invalid' : getLabelState(childControl);
    }
    return '';
}

/**
 * Returns a CSS class that can be added to the label associated to the given form control to show an icon in front of the
 * label that indicates the current validation state of the form control. See ./form.scss
 */
export function getLabelState(control: AbstractControl): 'required' | 'valid' | 'invalid' | '' {
    if (control?.enabled) {
        if (control.valid && !isEmptyInputValue(control.value)) {
            return 'valid';
        }
        if (control.invalid && (control.touched || control.dirty)) {
            return 'invalid';
        }
        if (isRequired(control)) {
            return 'required';
        }
    }

    return '';
}

/**
 * Parses the value in the given form control (expecting it to be a date string in the `MOMENT_DATE_DISPLAY_FORMAT` format),
 * and if it is valid, returns it as an ISO-8601 date string. Otherwise returns null.
 */
export function getLocalDateEditorValue(controlOrValue: FormControl | string, isEndDate = false): LocalDateString | null {
    if (!controlOrValue) {
        return null;
    }
    const value = typeof controlOrValue === 'string' ? controlOrValue : controlOrValue.value;
    const date = moment(value, MOMENT_DATE_DISPLAY_FORMAT, true);
    if (!date.isValid()) {
        return null;
    }
    const adjustedDate = isEndDate ? date.add(1, 'day') : date;
    return adjustedDate.format(ISO_LOCAL_DATE_FORMAT);
}

/**
 * Parses the value in the given form control (expecting it to be a time string in the `MOMENT_TIME_DISPLAY_FORMAT` format),
 * and if it is valid, returns it as an HH:mm time string. Otherwise returns null.
 */
export function getLocalTimeEditorValue(formControl: FormControl): LocalTimeString | null {
    if (!formControl) {
        return null;
    }
    const time = moment(formControl.value, MOMENT_TIME_DISPLAY_FORMAT, true);
    if (!time.isValid()) {
        return null;
    }
    return time.format(ISO_LOCAL_TIME_FORMAT);
}

/**
 * Subtracts a day from given (exclusive) end date for display purposes.
 *
 * @param endDate date to extract from
 */
export function subtractDayFromEndDate(endDate?: LocalDateString): LocalDateString | undefined {
    if (!endDate) {
        return undefined;
    }
    const date = moment(endDate, ISO_LOCAL_DATE_FORMAT, true);
    if (!date.isValid()) {
        return undefined;
    }
    const adjustedDate = date.subtract(1, 'day');
    return adjustedDate.format(ISO_LOCAL_DATE_FORMAT);
}

/**
 * Converts the value of the given control to a primitive number. If the control value is a string, it has to either use
 * the Finnish number format (e.g. '1 234,56'), or use no locale-specific formatting (e.g. '1234.56').
 */
export function getNumericInputValue(control: FormControl<string | number | null>): number | null;
/**
 * Converts a numeric input value to a primitive number. If the given value is a string, it has to either use
 * the Finnish number format (e.g. '1 234,56'), or use no locale-specific formatting (e.g. '1234.56').
 */
export function getNumericInputValue(value: string | number): number | null;

export function getNumericInputValue(controlOrValue: FormControl<number | string | null> | number | string): number | null {
    const value = isFormControl(controlOrValue) ? controlOrValue.value : controlOrValue;
    if (_.isFinite(value)) {
        return value as number;
    }
    if (_.isNil(value) || !_.isString(value) || _.isEmpty(value.trim())) {
        return null;
    }
    const asNumber = _.toNumber(value.replace(/\s/g, '').replace(',', '.'));
    return _.isFinite(asNumber) ? asNumber : null;
}

/**
 * Converts a form value string containing money string (e.g. 101,10) to money string parameter that back-end accepts.
 * E.g. 101,10 -> EUR 101.10 / 100,20 € -> EUR 101.10
 *
 * @param moneyString String containing a form value of money.
 * @param currencyCode Three letter code for currency. Default value is EUR.
 * @param currencySymbol The symbolic UTF-8 value for currency symbol default is EUR-sign: € ('\u20ac').
 */
export function createMoneyString(moneyString: string, currencyCode = 'EUR', currencySymbol = '\u20ac'): string {
    if (!moneyString) {
        return null;
    }
    return `${currencyCode} ${moneyString.replace(/\s/g, '').replace(',', '.').replace(currencySymbol, '')}`;
}

/**
 * Converts a money string to form value, e.g. EUR 100.20 -> 100,20
 *
 * @param moneyString String containing a form value of money.
 * @param currencyCode Three letter code for currency. Default value is EUR.
 */
export function moneyStringToFormValue(moneyString: string, currencyCode = 'EUR'): string {
    return `${moneyString.replace(/\s/g, '').replace(currencyCode, '').replace('.', ',')}`;
}

/**
 * Converts a string value of money to front end displayValue. to form value, e.g. EUR 100.20 -> 100,20 €.
 *
 * @param moneyString String containing a form value of money.
 * @param currencyCode Three letter code for currency. Default value is EUR.
 * @param currencySymbol The symbolic UTF-8 value for currency symbol default is EUR-sign: € ('\u20ac').
 *
 * @deprecated Use the `MoneyPipe` instead
 */
export function createMoneyDisplayValue(moneyString: string, currencyCode = 'EUR', currencySymbol = '\u20ac'): string {
    return `${moneyString.replace(currencyCode, '').replace(/\s/g, '').replace('.', ',')} ${currencySymbol}`;
}

/**
 * Combines the values of the separate date and time `FormControl`s of a local date time editor component into a
 * `LocalDateTimeString`. Returns `null` if either control value is missing, empty, or syntactically invalid.
 * (i.e. the formGroup can have validation errors, as long as the values can be parsed into valid dates).
 *
 * @param formGroup The `FormGroup` of the local date time editor component
 */
export function getLocalDateTimeEditorValue(formGroup: FormGroup): LocalDateTimeString | null {
    if (!isFormGroup(formGroup) || !formGroup.contains('date') || !formGroup.contains('time')) {
        return null;
    }
    const date = moment(formGroup.get('date').value, MOMENT_DATE_DISPLAY_FORMAT, true);
    const time = moment(formGroup.get('time').value, MOMENT_TIME_DISPLAY_FORMAT, true);
    return date.isValid() && time.isValid() ? date.hour(time.hour()).minute(time.minute()).format(ISO_LOCAL_DATE_TIME_FORMAT) : null;
}

/**
 * Combines the values of the separate date and time `FormControl`s of a local date time editor component into a
 * `LocalDateTimeString`. Returns `null` if either control value is missing, empty, or syntactically invalid. Validation is
 * not affected by form controls disabled setting.
 * (i.e. the formGroup can have validation errors, as long as the values can be parsed into valid dates).
 *
 * @param formGroup The `FormGroup` of the local date time editor component
 */
export function getLocalDateTimeEditorRawValue(formGroup: FormGroup): LocalDateTimeString | null {
    if (!isFormGroup(formGroup) || !formGroup.get('date') || !formGroup.get('time')) {
        return null;
    }
    const date = moment(formGroup.get('date').value, MOMENT_DATE_DISPLAY_FORMAT, true);
    const time = moment(formGroup.get('time').value, MOMENT_TIME_DISPLAY_FORMAT, true);
    return date.isValid() && time.isValid() ? date.hour(time.hour()).minute(time.minute()).format(ISO_LOCAL_DATE_TIME_FORMAT) : null;
}

/**
 * Combines the values of the separate startDate and endDate `FormControl`s of a local date range
 * editor component into a `LocalDateRange`. Returns `null` if either child form control is missing.
 *
 * @param formGroupOrValue Either the `FormGroup` of the local date range editor component, or the
 * value of that `FormGroup` (the latter is useful e.g. in `valueChange` subscribers)
 */
export function getLocalDateRangeEditorValue(formGroupOrValue: FormGroup | { startDate?: string; endDate?: string }): LocalDateRange | null {
    if (!formGroupOrValue) {
        return null;
    }

    let value: { startDate?: string; endDate?: string };
    if (isFormGroup(formGroupOrValue)) {
        if (!formGroupOrValue.contains('startDate') || !formGroupOrValue.contains('endDate')) {
            return null;
        }
        value = { startDate: formGroupOrValue.get('startDate').value, endDate: formGroupOrValue.get('endDate').value };
    } else if (formGroupOrValue.hasOwnProperty('startDate') && formGroupOrValue.hasOwnProperty('endDate')) {
        value = formGroupOrValue;
    } else {
        return null;
    }

    return {
        startDate: getLocalDateEditorValue(value.startDate),
        endDate: getLocalDateEditorValue(value.endDate, true),
    };
}

/**
 * Combines the values of the separate startDateTime and endDateTime `FormGroup`s of a local date time range
 * editor component into a `LocalDateTimeRange`. Returns `null` if either child form group is missing.
 *
 * @param formGroup The `FormGroup` of the local date time range editor component
 */
export function getLocalDateTimeRangeEditorValue(formGroup: FormGroup): LocalDateTimeRange | null {
    if (!isFormGroup(formGroup) || !formGroup.contains('startDateTime') || !formGroup.contains('endDateTime')) {
        return null;
    }
    return {
        startDateTime: getLocalDateTimeEditorValue(formGroup.get('startDateTime') as FormGroup),
        endDateTime: getLocalDateTimeEditorValue(formGroup.get('endDateTime') as FormGroup),
    };
}

/**
 * Combines the values of the separate startTime and endTime `FormControls`s of a local time range
 * editor component into a `LocalTimeRange`. Returns `null` if either child controls is missing.
 *
 * @param formGroup The `FormGroup` of the local time range editor component
 */
export function getLocalTimeRangeEditorValue(formGroup: FormGroup): LocalTimeRange | null {
    if (!isFormGroup(formGroup) || !formGroup.get('startTime') || !formGroup.get('endTime')) {
        return null;
    }
    const startTime: LocalTimeString | null = getLocalTimeEditorValue(formGroup.get('startTime') as FormControl);
    const endTime: LocalTimeString | null = getLocalTimeEditorValue(formGroup.get('endTime') as FormControl);
    const duration: DurationString = calculateDuration(startTime, endTime);
    return {
        startTime,
        endTime,
        duration,
    };
}

/**
 * Optional options to modify the behaviour of {@link handleSubmission} function. Options having prefix 'custom' have
 * a default implementation that is executed if not otherwise defined.
 */
export interface SubmissionOptions<A> {
    pristineHandler?: (form: AbstractControl) => void;
    allowInvalid?: boolean;
    nextAttribute?: A;
    invalidHandler?: (form: AbstractControl) => void;
}

/**
 * Helper function designed to handle form submissions in a consistent manner when using RequestQueue.
 *
 * @param form Form to be validated and used as basis for submit request.
 * @param queue$ {@link RequestQueue} instance to be used for making the submit request.
 * @param options Optional {@link SubmissionOptions} object for modifying the submission behaviour in various ways.
 */
export function handleSubmission<A>(form: AbstractControl,
                                    queue$: RequestQueue<A, any>,
                                    options: SubmissionOptions<A> = {}): void {
    if (!form || !queue$) return;
    if (!queue$.ignoreConcurrent) {
        console.warn('RequestQueue is not set to ignore concurrent requests!');
    }

    const { pristineHandler, allowInvalid, nextAttribute, invalidHandler } = options;

    const invalidCallback = allowInvalid ? (() => queue$.next(nextAttribute)) : (!!invalidHandler ? (() => invalidHandler(form)) : undefined);

    submitFormWithOptions(form, {
        invalidCallback,
        pristineCallback: !!pristineHandler ? (() => pristineHandler(form)) : undefined,
        validCallback: () => queue$.next(nextAttribute),
    });
}

function focusToFirstFocusableError() {
    setTimeout(() => {
        const elements = document.querySelectorAll('[sisFocusableError]');
        const firstError = elements.length > 0 ? elements[0] as HTMLElement : undefined;
        if (firstError) {
            firstError.focus();
        }
    }, 0);
}

/**
 * Execute validCallback if form is valid. Execute invalidCallback if form is invalid.
 * @param form
 * @param validCallback
 * @param invalidCallback
 */
export function submitForm(form: AbstractControl, validCallback: () => void, invalidCallback?: () => void) {
    submitFormWithOptions(form, { validCallback, invalidCallback });
}

/**
 * Check form validity and execute necessary callbacks based on this. Handles the most common scenarios with forms.
 * @param form to check
 * @param options to use. Only specified callbacks can be executed. If preventFocusOnValidationError is false,
 * focus will be moved to the first possible focusable error when form is invalid.
 */
export function submitFormWithOptions(form: AbstractControl, options?: { pristineCallback?: () => void, validCallback?: () => void, invalidCallback?: () => void, preventFocusOnValidationError?: boolean }) {
    if (form.invalid) {
        form.markAllAsTouched();
        if (!!options?.invalidCallback) {
            options.invalidCallback();
        }
        if (!options?.preventFocusOnValidationError) {
            focusToFirstFocusableError();
        }
    } else if (form.pristine && !!options?.pristineCallback) {
        options.pristineCallback();
    } else if (form.valid && !!options?.validCallback) {
        options.validCallback();
    }
}
