import { inject } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { OtmId } from 'common-typescript/types';
import moment from 'moment';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { StudyYearsEntityService } from 'sis-components/service/study-years-entity.service';

import { StudyPeriodInfo } from './search.types';

/**
 * Returns an observable which emits `StudyPeriodInfo` objects based on the university id values emitted
 * by the given observable. The results contain ongoing and future study periods for the current study
 * year, and all study periods for one upcoming study year. For each study year, checks if there are
 * study periods with identical names (can happen with summer periods). If duplicates are found, adds
 * specifiers to the names of those periods to make it possible to distinguish them in the UI.
 */
export function getStudyPeriodInfoLoader(universityOrgId$: Observable<OtmId>): Observable<StudyPeriodInfo[]> {
    const studyYearsService = inject(StudyYearsEntityService);
    return universityOrgId$
        .pipe(
            switchMap(id => studyYearsService.getCurrentAndUpcomingStudyYears(id)),
            map(studyYears => studyYears
                .flatMap(studyYear => studyYearsService.addSpecifiersToDuplicatePeriodNames(studyYear)
                    .filter(period => !dateUtils.isRangeBefore(moment(), period.valid))
                    .map(period => ({
                        name: period.uniqueName,
                        studyYearName: studyYear.name,
                        valid: period.valid,
                    })))),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
}
