import { Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    EntityWithRule,
    Grade,
    GradeAverageCalculationMethod,
    GradeAverageCalculationResult,
    GradeScale,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { isAssessmentItemAttainment, isModuleLikeAttainment, toChildAttainmentIds } from '../attainment/AttainmentUtil';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class CommonGradeAverageService {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.service.gradeAverageService',
        serviceName: 'commonGradeAverageService',
    };

    private calculateAverageWeightedByCredits(
        attainmentIds: OtmId[], allAttainments: Attainment[], gradeScale: GradeScale,
    ): GradeAverageCalculationResult {

        const attainmentMap: Map<OtmId, Attainment> = new Map();
        allAttainments.forEach(attainment => attainmentMap.set(attainment.id, attainment));
        const attainmentsWithCredits = _.chain(attainmentIds)
            .map(id => attainmentMap.get(id))
            .compact()
            .filter(attainment => !_.isNil(attainment.credits))
            .value();
        const totalCredits = _.chain(attainmentsWithCredits)
            .map('credits')
            .compact()
            .reduce((x, y) => x + y, 0)
            .value();
        const numericScale = !_.chain(gradeScale.grades)
            .map('numericCorrespondence')
            .some(_.isNil)
            .value();
        if (!numericScale) {
            const passedGrades = _.filter(gradeScale.grades, 'passed');
            if (_.size(passedGrades) === 1 &&
                !_.isEmpty(attainmentsWithCredits) &&
                _.every(attainmentsWithCredits, att => att.state !== 'FAILED')
            ) {
                return {
                    method: undefined,
                    gradeScaleId: gradeScale.id,
                    proposedGradeId: passedGrades[0].localId,
                    gradeAverage: undefined,
                    totalRequestedCredits: totalCredits,
                    totalIncludedCredits: totalCredits,
                };
            }
            return {
                method: undefined,
                gradeScaleId: gradeScale.id,
                proposedGradeId: undefined,
                gradeAverage: undefined,
                totalRequestedCredits: totalCredits,
                totalIncludedCredits: 0,
            };
        }
        const gradesById: { [index: number]: Grade } = _.keyBy(gradeScale.grades, 'localId');

        const includedAttainments = _.chain(attainmentsWithCredits)
            .filter({ gradeScaleId: gradeScale.id })
            .filter(attainment => !!gradesById[attainment.gradeId])
            .value();
        const includedCredits = _.chain(includedAttainments)
            .map('credits')
            .reduce((x, y) => x + y, 0)
            .value();
        const weightedGrades = _.chain(includedAttainments)
            .map(attainment => attainment.credits * gradesById[attainment.gradeId].numericCorrespondence)
            .reduce((x, y) => x + y, 0)
            .value();

        const gradeAverage = includedCredits > 0 ? weightedGrades / includedCredits : undefined;
        let proposedGradeId;
        if (!_.isNil(gradeAverage)) {
            proposedGradeId = _.chain(gradeScale.grades)
                .map(grade => ({
                    distance: Math.abs(grade.numericCorrespondence - gradeAverage),
                    numericCorrespondence: grade.numericCorrespondence,
                    gradeId: grade.localId,
                }))
                .orderBy(['distance', 'numericCorrespondence'], ['asc', 'desc'])
                .head()
                .get('gradeId')
                .value();
        }

        return {
            proposedGradeId,
            gradeAverage,
            method: undefined,
            gradeScaleId: gradeScale.id,
            totalRequestedCredits: totalCredits,
            totalIncludedCredits: includedCredits,
        };
    }

    /**
     * Supports two calculation methods:
     *
     * ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS: calculates mean weighted by credits for attainments given in
     * attainmentIds argument. Argument allAttainments (array) should contain each attainment that is
     * referenced by attainmentIds argument.
     *
     * COURSE_UNIT_ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS: calculates mean weighted by credits for course unit
     * level attainments. For example assume that one id in attainmentIds belongs to ModuleAttainment then
     * all (Custom)CourseUnitAttainments that have ModuleAttainment as ancestor on any level are included in
     * calculation. AssessmentItemAttainment is not allowed in attainmentIds argument. Argument allAttainments
     * should contain all attainments of student (you are probably counting this for student) so that all
     * necessary child attainments are present during calculation.
     *
     * @param attainmentIds, data set for which average will be calculated
     * @param allAttainments, array holding all necessary attainments, possibly all of student
     * @param gradeScale, the scale for which a proposed grade will be produced
     * @param method, how the average will be calculated.
     * @returns GradeAverageCalculationResult
     */
    calculateGradeAverage(
        attainmentIds: OtmId[], allAttainments: Attainment[], gradeScale: GradeScale, method: GradeAverageCalculationMethod,
    ) {
        const attainmentMap: Map<OtmId, Attainment> = new Map();
        allAttainments.forEach(attainment => attainmentMap.set(attainment.id, attainment));

        let resolvedAttainmentIds: OtmId[];
        if (method === 'COURSE_UNIT_ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS') {
            this.checkForAssessmentItemAttainments(attainmentIds, attainmentMap, method);
            resolvedAttainmentIds = resolveAttainmentsForCourseUnitWeightedByCredits(attainmentIds);
            resolvedAttainmentIds = resolvedAttainmentIds.filter(id => !isModuleLikeAttainment(attainmentMap.get(id)));
        } else if (method === 'COURSE_UNIT_AND_EMPTY_MODULE_ARITHMETIC_MEAN_WEIGHTED_BY_CREDITS') {
            this.checkForAssessmentItemAttainments(attainmentIds, attainmentMap, method);
            resolvedAttainmentIds = resolveAttainmentsForCourseUnitWeightedByCredits(attainmentIds);
            resolvedAttainmentIds = _.concat(
                resolvedAttainmentIds.filter(id => !isModuleLikeAttainment(attainmentMap.get(id))),
                resolvedAttainmentIds.filter((id) => {
                    const attainment = attainmentMap.get(id);
                    return isModuleLikeAttainment(attainment) && toChildAttainmentIds(attainment).length === 0;
                }),
            );
        } else if (method === 'ARITHMETIC_MEAN_WEIGHTING_BY_CREDITS') {
            resolvedAttainmentIds = attainmentIds;
        } else {
            throw new Error(`Unsupported method : ${method}`);
        }
        const result = this.calculateAverageWeightedByCredits(resolvedAttainmentIds, allAttainments, gradeScale);
        result.method = method;
        return result;

        function resolveAttainmentsForCourseUnitWeightedByCredits(attIds: OtmId[]): OtmId[] {
            const collectIdsRecursively: ((id: OtmId) => OtmId[]) = (id) => {
                const attainment = attainmentMap.get(id);
                if (isModuleLikeAttainment(attainment)) {
                    return [id].concat(toChildAttainmentIds(attainment).flatMap(collectIdsRecursively));
                }
                return [id];
            };

            return attIds.flatMap(collectIdsRecursively);
        }
    }

    private checkForAssessmentItemAttainments(
        attainmentIds: OtmId[], attainmentMap: Map<OtmId, Attainment>, method: GradeAverageCalculationMethod,
    ) {
        const assessmentItemAttainmentsRequested = _.chain(attainmentIds)
            .map(id => attainmentMap.get(id))
            .some(isAssessmentItemAttainment)
            .value();
        if (assessmentItemAttainmentsRequested) {
            throw new Error(`Calculation method ${method} does not support assessment item attainments.`);
        }
    }

    /**
     * Returns all 'top-level' attainment ids (not AssessmentItemIds) for module selection.
     * If the module itself is attained then returns singleton list of its attainment id.
     * If module has a non attained module selection 'moduleA', then instead of moduleAs attainment id (which
     * does not exists because it is not attained) a list of moduleAs attainment ids are search (recursion.)
     * recursively.
     */
    getAttainmentIdsForModule(rootModule: EntityWithRule, validatablePlan: ValidatablePlan) {
        function getAttainmentIdsForModule(module: EntityWithRule): OtmId[] {
            if (validatablePlan.isModuleAttained(module.id)) {
                return [validatablePlan.getModuleAttainment(module.id).id];
            }
            const moduleRelatedAttainmentIds = _.flatMap(
                validatablePlan.getSelectedModulesUnderModule(module), getAttainmentIdsForModule,
            );
            const courseUnitAttainments = _.chain(validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module))
                .map('id')
                .filter(id => validatablePlan.isCourseUnitAttained(id))
                .map(id => validatablePlan.getCourseUnitAttainment(id))
                .value();
            const customModuleAttainments = validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module);
            const customCuAttainments = validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module);
            return _.concat(
                moduleRelatedAttainmentIds,
                _.map(courseUnitAttainments, 'id'),
                _.map(customCuAttainments, 'id'),
                _.map(customModuleAttainments, 'id'),
            );
        }

        return getAttainmentIdsForModule(rootModule);
    }
}
