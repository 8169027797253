import { Component, Inject, Input, OnChanges, Optional, ViewEncapsulation } from '@angular/core';
import {
    Attainment,
    CourseUnit,
    CreditRange,
    EntityWithRule,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { CourseUnitStateObject, PlanData, PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-course-unit',
    templateUrl: './plan-course-unit.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class PlanCourseUnitComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.planCourseUnit',
        directiveName: 'sisPlanCourseUnit',
    };

    courseUnitStateObject: CourseUnitStateObject;
    courseUnitsById: { [id: string]: CourseUnit };
    attainment: Attainment;
    visibleCredits: CreditRange;
    isSubstitution: boolean;
    courseUnitInfoModalEnabled: boolean;
    showExpiryWarning: boolean;

    @Input() courseUnit: CourseUnit;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() isImplicit: boolean;
    @Input() parentModule?: EntityWithRule;
    @Input() substitutedCourseUnitId?: OtmId;

    constructor(
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnChanges() {
        this.isSubstitution = false;
        this.courseUnitInfoModalEnabled = true;
        this.showExpiryWarning = true;
        this.courseUnitStateObject = _.get(this.planStateObject.courseUnits, this.courseUnit.id);
        if (this.courseUnitStateObject) {
            this.attainment = this.planData.attainmentsById[this.courseUnitStateObject.attainmentId];
        }

        this.visibleCredits = this.attainment ? { min: this.attainment.credits, max: this.attainment.credits } : this.courseUnit.credits;
    }

    openCourseUnitInfoModal() {
        this.planActionsService?.openCourseUnitInfoModal?.(this.courseUnit);
    }

}
