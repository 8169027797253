import angular from 'angular';
import * as _ from 'lodash-es';
import compositeRuleTpl from './compositeRule.tpl.html';
(function () {
  angular.module('student.plan.rules').component('compositeRule', {
    template: compositeRuleTpl,
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      studyRight: '<',
      openCustomStudyDraftInfoModalCallback: '&'
    },
    controller: ["RangeValidationResult", "uiContext", "Range", "$scope", function (RangeValidationResult, uiContext, Range, $scope) {
      const ctrl = this;
      ctrl.isOK = isOK;
      ctrl.getTargetRange = getTargetRange;
      ctrl.getSubRuleClass = getSubRuleClass;
      ctrl.sortedRules = [];
      ctrl.$onChanges = function () {
        ctrl.sortedRules = _.sortBy(ctrl.rule.rules, rule => {
          switch (rule.type) {
            case 'CourseUnitRule':
            case 'AnyCourseUnitRule':
              return 1;
            case 'ModuleRule':
            case 'AnyModuleRule':
              return 2;
            default:
              return 3;
          }
        });
        ctrl.ruleValidationResult = getRuleValidationResult();
      };
      ctrl.handleResizeEvent = () => {
        $scope.$evalAsync();
      };
      ctrl.openCustomStudyDraftInfo = customStudyDraft => {
        ctrl.openCustomStudyDraftInfoModalCallback({
          customStudyDraft
        });
      };
      function getRuleValidationResult() {
        return uiContext.planContext.getRuleValidationResult(ctrl.module.id, ctrl.rule.localId);
      }
      function isOK() {
        if (ctrl.ruleValidationResult) {
          return ctrl.ruleValidationResult.result === RangeValidationResult.OK;
        }
        return false;
      }
      function getTargetRange() {
        if (ctrl.ruleValidationResult) {
          return new Range(ctrl.ruleValidationResult.minRequired, ctrl.ruleValidationResult.maxAllowed);
        }
        return null;
      }
      function getSubRuleClass(subRule) {
        const classes = [];
        if (typeof subRule.isBlock === 'function' && subRule.isBlock()) {
          classes.push('sub-rule-block');
          classes.push(`sub-rule-block-level-${ctrl.rule.blockLevel}`);
          classes.push(`sub-rule-block-${ctrl.rule.blockLevel % 2 === 0 ? 'even' : 'odd'}`);
        } else {
          classes.push('sub-rule');
        }
        return classes.join(' ');
      }
    }]
  });
})();