<ng-container *transloco="let t">
  <fudis-dl class="fudis-mb-md fudis-mt-md" [columns]="{ sm: 1, md: 2 }" [rowGap]="'xl'" [columnGap]="'xl'">
    <fudis-dl-item>
      <fudis-dt [contentText]="t('FIELD_NAMES.callName')"/>
      <fudis-dd [contentText]="person.callName"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('FIELD_NAMES.studentNumber')"/>
      <fudis-dd [contentText]="person.studentNumber"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.USER_NAME')"/>
      <fudis-dd [contentText]="person.userName"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.GENDER')"/>
      <fudis-dd [contentText]="person.genderUrn | codeLocalizedName | async"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.CITIZENSHIP')"/>
      @for (countryUrn of person.citizenshipUrns; track countryUrn) {
        <fudis-dd [contentText]="countryUrn | codeLocalizedName | async"/>
      }
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.SCHOOL_EDUCATION_LANGUAGE')"/>
      @for (language of person.schoolEducationLanguageUrns; track language) {
        <fudis-dd [contentText]="language | codeLocalizedName | async"/>
      }
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.OPPIJANUMERO')"/>
      <fudis-dd [contentText]="person.oppijanumero"/>
    </fudis-dl-item>
    @if (person.secondOfficialLanguageStudyObligation && person.secondOfficialLanguageStudyObligation === 'EXEMPTION_GRANTED'){
      <fudis-dl-item>
        <fudis-dt [contentText]="t('PROFILE.SECOND_OFFICIAL_LANGUAGE_OBLIGATION.EXEMPTION_LABEL')"/>
        <fudis-dd [contentText]="t('PROFILE.SECOND_OFFICIAL_LANGUAGE_OBLIGATION.EXEMPTION_GRANTED')"/>
      </fudis-dl-item>
    }
    <fudis-dl-item>
      <fudis-dt [contentText]="t('FIELD_NAMES.municipalityUrn')"/>
      @if (isClassified('MunicipalityUrn')) {
        <fudis-dd
                [ariaLabel]="isClassifiedInfoVisible('MunicipalityUrn') ? null : t('ARIA_LABEL.HIDDEN_HOME_MUNICIPALITY')"
                [contentText]="isClassifiedInfoVisible('MunicipalityUrn') ?
                (person.municipalityUrn | codeLocalizedName | async) :
                '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'"
        >
          <fudis-button
                  [variant]="'tertiary'"
                  [size]="'small'"
                  [icon]="isClassifiedInfoVisible('MunicipalityUrn') ? 'eye-blind' : 'eye'"
                  [label]="isClassifiedInfoVisible('MunicipalityUrn') ? t('HIDE') : t('VIEW')"
                  (handleClick)="toggleFieldVisibility('MunicipalityUrn')"
          />
        </fudis-dd>
      } @else {
        <fudis-dd [contentText]="person.municipalityUrn | codeLocalizedName | async"/>
      }
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.BIRTH_DATE')"/>
      <fudis-dd [contentText]="person.dateOfBirth | localDateFormat"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.MOTHER_TONGUE')"/>
      <fudis-dd [contentText]="person.motherTongueUrn | codeLocalizedName | async"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.PREFERRED_LANGUAGE')"/>
      <fudis-dd [contentText]="person.preferredLanguageUrn | codeLocalizedName | async"/>
    </fudis-dl-item>
    <fudis-dl-item>
      <fudis-dt [contentText]="t('PROFILE.EMPLOYEE_NUMBER')"/>
      <fudis-dd [contentText]="person.employeeNumber"/>
    </fudis-dl-item>
  </fudis-dl>
</ng-container>
