import { ChangeDetectionStrategy, Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { MaxLength } from 'common-typescript/constants';
import { OtmId, Plan } from 'common-typescript/types';
import { Observable, take, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import {
    getValidationErrorSummaryDetail,
    ValidationErrorSummaryDetail,
} from 'sis-components/form/validation-error-summary/validation-error-summary.component';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';

export interface EditPlanNgNameModalValues {
    planId: OtmId
}

interface EditPlanNameForm {
    name: FormControl<string>;
}

export function editPlanNameModalOpener(): (planId: OtmId) => NgbModalRef {
    const modalService: ModalService = inject(ModalService);
    return (planId: OtmId) => modalService.open(
        EditPlanNgNameModalComponent,
        { planId },
        { size: 'sm' },
    );
}

@Component({
    selector: 'app-edit-plan-ng-name-modal',
    templateUrl: './edit-plan-ng-name-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPlanNgNameModalComponent implements OnInit {
    formSubmitted: boolean = false; // to avoid double-clicks
    planId: OtmId;
    plan: Plan;
    planNameForm: FormGroup<EditPlanNameForm>;
    validationDetails: ValidationErrorSummaryDetail[] = [];
    planObs$: Observable<Plan>;

    constructor(
        @Inject(ModalService.injectionToken) private values: EditPlanNgNameModalValues,
        public activeModal: NgbActiveModal,
        private planEntityService: PlanEntityService,
        private fb: SisFormBuilder,
        private appErrorHandler: AppErrorHandler,
        private alertsService: AlertsService,
        private translocoService: TranslocoService,
    ) {
        this.planId = values.planId;
    }

    initPlanNameForm(): void {
        this.planNameForm = this.fb.group(
            {
                name: ['', [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]],
            });
    }

    ngOnInit(): void {
        this.planObs$ = this.planEntityService.getById(this.planId)
            .pipe(
                take(1),
                tap((plan: Plan) => {
                    this.initPlanNameForm();
                    this.plan = plan;
                    this.name.setValue(this.plan.name);
                }),
            );
    }

    get name(): FormControl {
        return this.planNameForm.controls.name;
    }

    onSubmit() {
        if (!this.formSubmitted) {
            if (this.planNameForm.valid) {
                this.formSubmitted = true;
                this.plan.name = this.name.value.trim();
                this.planEntityService.updateMyPlan(this.plan)
                    .pipe(
                        take(1),
                        tap(() => this.activeModal.close()),
                        tap(() =>
                            this.alertsService.addTemporaryAlert({
                                type: AlertType.SUCCESS,
                                message: this.translocoService.translate('PLAN.EDIT_PLAN_NAME_MODAL.PLAN_NAME_EDIT_SUCCESS'),
                            })),
                        this.appErrorHandler.defaultErrorHandler())
                    .subscribe();
            } else {
                this.validationDetails = [
                    getValidationErrorSummaryDetail(
                        this.name,
                        this.translocoService.translate('PLAN.EDIT_PLAN_NAME_MODAL.VALIDATION.ERROR_NAME_INPUT'),
                        'plan-name-edit-modal-name-input-id'),
                ];
            }
        }
    }
}
