import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { StudentCommonModule } from '../common/common.module';
import { EnrolmentModule } from '../common/components/enrolment/enrolment.module';

import { calendarStates } from './calendar.route-ng';
import { OwnCalendarEventSummaryModalComponent } from './full-calendar/calendar-event/calendar-event-preview/own-calendar-event-summary-modal.component';
import { OwnCalendarEventComponent } from './full-calendar/calendar-event/own-calendar-event.component';
import { FullCalendarMessageService } from './full-calendar/full-calendar-message.service';
import { FullCalendarComponent } from './full-calendar/full-calendar.component';
import { StudyEventSummaryModalComponent } from './full-calendar/study-event/study-event-preview/study-event-summary-modal.component';
import { StudyEventComponent } from './full-calendar/study-event/study-event.component';
import { CalendarEnrolmentsComponent } from './right-panel/calendar-enrolments/calendar-enrolments.component';
import {
    CourseUnitsNotInCalendarComponent,
} from './right-panel/course-units-not-in-calendar/course-units-not-in-calendar.component';

@NgModule({
    declarations: [
        FullCalendarComponent,
        OwnCalendarEventComponent,
        StudyEventComponent,
        OwnCalendarEventSummaryModalComponent,
        StudyEventSummaryModalComponent,
    ],
    imports: [
        CommonModule,
        SisCommonModule,
        SisComponentsModule,
        StudentCommonModule,
        TranslateModule.forChild(),
        UIRouterModule.forChild({ states: calendarStates }),
        EnrolmentModule,
        FullCalendarModule,
        CalendarEnrolmentsComponent,
        CourseUnitsNotInCalendarComponent,
    ],
    providers: [
        FullCalendarMessageService,
    ],
})
export class CalendarModule {}
