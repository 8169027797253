<ng-container *transloco="let t">
  <div class="sis-plan-structure-composite-rule" role="group"
       [attr.id]="this.ruleFocusId()"
       [tabIndex]="-1"
       [attr.aria-labelledby]="selectionUIState !== 'DISABLED' ? ruleLegendId() : undefined">
    <sis-plan-structure-count-rule-header *ngIf="selectionUIState !== 'DISABLED'"
                                          [rule]="_rule()"
                                          [onlyShowRule]="selectionUIState === 'SELECTABLE'"
                                          [ruleLegendId]="ruleLegendId()"
                                          [ruleValidationResults]="ruleValidationResults()">
    </sis-plan-structure-count-rule-header>
    <sis-plan-structure-rule-error-list *ngIf="ruleErrors && ruleErrors()?.length > 0"
                                        [ruleErrors]="ruleErrors()">
    </sis-plan-structure-rule-error-list>
    <div class="sis-plan-structure-composite-rule__body">
      <ng-container *ngFor="let rule of courseUnitAndModuleRules(); trackBy: trackByFunc">
        <div class="sis-mb-sm sis-pr-sm">
          <sis-plan-structure-select-rule [rule]="rule"
                                          [headingLevel]="headingLevel"
                                          [ruleDepthLevel]="ruleDepthLevel + 1"
                                          [parentModule]="_parentModule()"
                                          [planData]="planData"
                                          [planRuleData]="planRuleData"
                                          [planStateObject]="planStateObject"
                                          [planValidationResult]="_planValidationResult()"
                                          [validatablePlan]="_validatablePlan()"
                                          [selectionUIState]="selectionUIState === 'ACTIVE' ? 'ACTIVE' : 'DISABLED'"
                                          [groupPrefix]="''">
          </sis-plan-structure-select-rule>
        </div>
      </ng-container>
      <ng-container *ngFor="let rule of groupRules(); index as i; trackBy: trackByFunc">
        <div class="list-group-item sis-mb-sm">
          <div [class]="{
           'rule-group': true,
           'rule-group--active': groupRuleUISelectionStates()[rule.localId],
          }">
            <div class="sis-pl-sm sis-mb-sm sis-pr-sm">
              <ng-container *ngIf="(selectionUIState === 'ACTIVE') || groupRuleUISelectionStates()[rule.localId]">
                @if (isSelectOneRule()) {
                  <div class="plan-structure-composite-single-rule-radio-button">
                    <sis-simple-radio-button
                      [id]="rule.localId"
                      [name]="_rule().localId"
                      [selected]="groupRuleUISelectionStates()[rule.localId]"
                      [label]="t('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP') + ' ' + (groupPrefix ? (groupPrefix + '.' + (i + 1)) : (i + 1))"
                      (selectedChange)="toggle($event, rule)"/>
                  </div>
                } @else {
                  <sis-checkbox [checked]="groupRuleUISelectionStates()[rule.localId]"
                                [id]="rule.localId"
                                (checkedChange)="toggle(!groupRuleUISelectionStates()[rule.localId], rule)"
                                [name]="_rule().localId"
                                [label]="(t('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP') + ' ' + (groupPrefix ? (groupPrefix + '.' + (i + 1)) : (i + 1)))">
                  </sis-checkbox>
                }
              </ng-container>
              <span *ngIf="selectionUIState === 'DISABLED'" class="sis-label">
                {{ t('PLAN_EDIT.SELECTION_MODAL.RULE_BODY.SELECTION_GROUP') }} {{ (groupPrefix ? (groupPrefix + '.' + (i + 1)) : (i + 1)) }}
              </span>
              <sis-plan-structure-select-rule [rule]="rule"
                                              [headingLevel]="headingLevel"
                                              [ruleDepthLevel]="ruleDepthLevel + 1"
                                              [groupPrefix]="groupPrefix ?  (groupPrefix + '.' + (i + 1)): (i + 1)"
                                              [parentModule]="_parentModule()"
                                              [planData]="planData"
                                              [planRuleData]="planRuleData"
                                              [planStateObject]="planStateObject"
                                              [planValidationResult]="_planValidationResult()"
                                              [validatablePlan]="_validatablePlan()"
                                              [selectionUIState]="this.resolveGroupUiState(rule)">
              </sis-plan-structure-select-rule>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

