import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpAddConfig, HttpUpdateConfig, NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OpenUniversityCart, OpenUniversityProduct, OpenUniversityUserBasicDetails, OtmId, PaymentId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { mono } from 'sis-common/mono/monoUtils';

import { OpenUniversityProductEntityService } from './open-university-product-entity.service';
import { SisuSimpleDataLoader } from './SisuSimpleDataLoader';

/**
 * Contains customer functionality (i.e. for students and purchasers) for open university carts.
 */
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: '/ilmo/api/open-university',
    resourceName: 'carts',
})
export class OpenUniversityCartCustomerService extends NgEntityService<OpenUniversityCartState> {

    private readonly query: QueryEntity<OpenUniversityCartState>;
    private readonly currentCartLoader: SisuSimpleDataLoader<OpenUniversityCart>;

    constructor(
        private productService: OpenUniversityProductEntityService,
    ) {
        super(new OpenUniversityCartStore());
        this.query = new OpenUniversityCartQuery(this.store);
        this.currentCartLoader = new SisuSimpleDataLoader<OpenUniversityCart>({
            requestCreator: () => mono(this.getHttp().get<OpenUniversityCart>(`${this.api}/current`)),
        });
    }

    getCurrentCart(bypassStore = false): Observable<OpenUniversityCart> {
        if (!bypassStore && this.query.hasActive()) {
            return this.query.selectActive();
        }

        return this.currentCartLoader.load()
            .pipe(this.setAndSelectActive());
    }

    refreshCurrentCart(): Subscription {
        return this.getCurrentCart(true)
            .pipe(take(1))
            .subscribe();
    }

    getActiveCart(): OpenUniversityCart {
        return this.query.getActive();
    }

    getCartByPaymentId(paymentId: PaymentId): Observable<OpenUniversityCart> {
        return this.getHttp().get<OpenUniversityCart>(`${this.api}/paymentId/${paymentId}`);
    }

    getWithActivationCode(cartId: OtmId, activationCode: string): Observable<OpenUniversityCart> {
        return this.getHttp().get<OpenUniversityCart>(`${this.api}/${cartId}/${activationCode}`);
    }

    /**
     * Returns an observable which emits the products added to the current cart. The observable will keep on emitting new values
     * as the set of products in the cart changes (but it will not emit when other changes are made to the cart which don't
     * affect the products).
     */
    getProductsInCurrentCart(bypassStore = false): Observable<OpenUniversityProduct[]> {
        return this.getCurrentCart(bypassStore)
            .pipe(
                map(cart => cart?.items?.map(item => item?.openUniversityProductId)?.filter(Boolean) ?? []),
                distinctUntilChanged((oldIds, newIds) => _.isEqual(_.uniq(oldIds).sort(), _.uniq(newIds).sort())),
                switchMap(productIds => this.productService.getByIds(productIds)),
            );
    }

    addProductsToCurrentCart(productId: OtmId | OtmId[]): Observable<OpenUniversityCart> {
        return this.getHttp().post<OpenUniversityCart>(`${this.api}/add-products`, null, { params: { productId } })
            .pipe(this.setAndSelectActive());
    }

    /**
     * Removes an item from the current cart based on the given product id, and returns an observable for the updated state of
     * the cart. If no matching item is found, simply returns an observable for the current cart.
     */
    removeProductFromCurrentCart(productId: OtmId): Observable<OpenUniversityCart> {
        if (this.query.hasActive()) {
            const cart = this.query.getActive();
            const itemId = cart.items?.find(item => item?.openUniversityProductId === productId)?.localId;
            if (itemId) {
                return this.getHttp().delete<OpenUniversityCart>(`${this.api}/${cart.id}/remove-item/${itemId}`)
                    .pipe(this.setAndSelectActive());
            }
        }

        return this.query.selectActive();
    }

    /**
     * Analogous to `removeProductFromCurrentCart()`, except that finding the item to remove is done based on the given course
     * unit id (i.e. removes an item which references a product which references the given course unit id). If no matching item
     * is found, simply returns an observable for the current cart.
     */
    removeCourseUnitFromCurrentCart(courseUnitId: OtmId): Observable<OpenUniversityCart> {
        if (this.query.hasActive()) {
            return this.getProductsInCurrentCart()
                .pipe(
                    take(1),
                    map(products => products?.find(product => product?.courseUnitId === courseUnitId)),
                    switchMap(product => product ? this.removeProductFromCurrentCart(product.id) : this.query.selectActive()),
                );
        }

        return this.query.selectActive();
    }

    isProductInCurrentCart(productId: OtmId): boolean {
        return !!productId && this.query.hasActive() &&
            (this.query.getActive().items?.some(item => item?.openUniversityProductId === productId) ?? false);
    }

    /**
     * Returns an observable which emits a boolean indicating whether any product from the given course unit is currently
     * in the cart. The observable will stay open and emit new values if the situation changes, i.e. if products from the
     * course unit are later added to/removed from the cart. The observable will not emit the same value twice in a row.
     */
    isProductFromCourseUnitInCurrentCart(courseUnitId: OtmId): Observable<boolean> {
        if (!courseUnitId) {
            return of(false);
        }
        return this.getProductsInCurrentCart()
            .pipe(
                map(products => products.some(product => product.courseUnitId === courseUnitId)),
                distinctUntilChanged(),
            );
    }

    isPaymentInProgress(): Observable<boolean> {
        return this.getCurrentCart(true)
            .pipe(map(cart => cart?.state === 'RESERVED'));
    }

    getReservationTimeLeft(cartId: OtmId): Observable<moment.Duration | null> {
        if (!cartId) {
            return of(null);
        }

        return this.getHttp().get(`${this.api}/${cartId}/reservation-time`)
            .pipe(map(result => !!result ? moment.duration(result) : null));
    }

    /**
     * Activates the cart and emits only one value: the activated cart.
     */
    activateOpenUniversityCartWithActivationCode(
        cartId: OtmId,
        activationCode: string,
        openUniversityUserBasicDetails?: OpenUniversityUserBasicDetails,
    ): Observable<OpenUniversityCart> {
        return this.getHttp().post<OpenUniversityCart>(
            `${this.api}/${cartId}/${activationCode}/activate`,
            openUniversityUserBasicDetails ?? null,
        );
    }

    override add<T>(entity: OpenUniversityCart, config?: HttpAddConfig<T>): Observable<T> {
        // Customers shouldn't be able to manipulate their carts freely, but instead only add/remove products
        throw new Error('Not supported');
    }

    override update<T>(id: OtmId, entity: Partial<OpenUniversityCart>, config?: HttpUpdateConfig<T>): Observable<T> {
        // Customers shouldn't be able to manipulate their carts freely, but instead only add/remove products
        throw new Error('Not supported');
    }

    private setAndSelectActive(): (source: Observable<OpenUniversityCart>) => Observable<OpenUniversityCart> {
        return source => source.pipe(
            switchMap((cart) => {
                if (cart) {
                    this.store.upsert(cart.id, cart);
                    this.store.setActive(cart.id);
                } else if (this.query.hasActive()) {
                    this.store.remove();
                }
                return this.query.selectActive();
            }),
        );
    }
}

interface OpenUniversityCartState extends EntityState<OpenUniversityCart, OtmId>, ActiveState {}

@StoreConfig({ name: 'open-university-carts-customer' })
class OpenUniversityCartStore extends EntityStore<OpenUniversityCartState> {}

class OpenUniversityCartQuery extends QueryEntity<OpenUniversityCartState> {
    constructor(protected store: OpenUniversityCartStore) {
        super(store);
    }
}

