import * as _ from 'lodash-es';

import {
    AssessmentItem,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    Module,
} from '../../../types/generated/common-backend.js';

import { RuleContext } from './context/ruleContext.js';
import { PlanValidationResult } from './planValidationResult.js';
import { ValidatablePlan } from './validatablePlan.js';

export class AttainmentValidation {

    private static validateChildModuleAttainment(module: Module, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult): RuleContext {
        const ruleContext = new RuleContext();
        AttainmentValidation.validateModuleAttainment(module, validatablePlan, ruleContext, planValidationResult);
        ruleContext.addModule(module);
        planValidationResult.moduleValidationResults[module.id] = ruleContext.getResults();
        return ruleContext;
    }

    private static validateChildCourseUnitAttainment(courseUnit: CourseUnit, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult): RuleContext {
        const ruleContext = new RuleContext();
        AttainmentValidation.validateCourseUnitAttainment(courseUnit, validatablePlan, ruleContext);
        ruleContext.addCourseUnit(courseUnit);
        planValidationResult.courseUnitValidationResults[courseUnit.id] = ruleContext.getResults();
        return ruleContext;
    }

    static validateModuleAttainment(module: Module, validatablePlan: ValidatablePlan, ruleContext: RuleContext, planValidationResult: PlanValidationResult): void {
        const attainment = validatablePlan.getModuleAttainment(module.id);
        if (attainment) {

            // Normal ModuleAttainment handling, attainedCredits and plannedCredits set from attainment only
            _.forEach(validatablePlan.getSelectedModulesUnderModule(module), (selectedModule) => {
                ruleContext.mergeChildAttainments(AttainmentValidation.validateChildModuleAttainment(selectedModule, validatablePlan, planValidationResult));
            });
            _.forEach(validatablePlan.getSelectedCourseUnitsUnderModule(module), (courseUnit) => {
                ruleContext.mergeChildAttainments(AttainmentValidation.validateChildCourseUnitAttainment(courseUnit, validatablePlan, planValidationResult));
            });
            ruleContext.mergeModuleAttainment(attainment);
        } else {

            // AttainmentGroupNode handling, attainedCredits and plannedCredits calculated
            _.forEach(validatablePlan.getSelectedModulesUnderModule(module), (selectedModule) => {
                ruleContext.mergeContext(AttainmentValidation.validateChildModuleAttainment(selectedModule, validatablePlan, planValidationResult));
            });
            _.forEach(validatablePlan.getSelectedCourseUnitsUnderModule(module), (courseUnit) => {
                ruleContext.mergeContext(AttainmentValidation.validateChildCourseUnitAttainment(courseUnit, validatablePlan, planValidationResult));
            });
        }
    }

    static validateCourseUnitAttainment(courseUnit: CourseUnit, validatablePlan: ValidatablePlan, ruleContext: RuleContext): void {
        const attainment = validatablePlan.getCourseUnitAttainment(courseUnit.id);
        if (attainment) {
            ruleContext.mergeCourseUnitAttainment(attainment);
        } else {
            // TODO: find out how to logging correctly in shared typescript modules
            console.log(`Module attainment has selected courseUnit without attainment ${courseUnit.id}`);
        }
        _.forEach(validatablePlan.getSelectedAssessmentItems(courseUnit), (assessmentItem) => {
            const assessmentItemAttainment = validatablePlan.getAssessmentItemAttainment(assessmentItem.id);
            if (assessmentItemAttainment) {
                AttainmentValidation.validateAssessmentItemAttainment(assessmentItem, validatablePlan);
            } else {
                console.log(`CourseUnit attainment has selected assessmentItem without attainment ${assessmentItem.id}`);
            }
            // TODO Not certain if assessmentitemattainment needs to be merged for
            // course unit attainments
        });
    }

    static validateAssessmentItemAttainment(assessmentItem: AssessmentItem, validatablePlan: ValidatablePlan): RuleContext {
        const attainment = validatablePlan.getAssessmentItemAttainment(assessmentItem.id);
        const ruleContext = new RuleContext();
        if (attainment) {
            ruleContext.mergeAssessmentItemAttainment(attainment);
        }
        ruleContext.addAssessmentItem(assessmentItem);
        return ruleContext;
    }

    static validateCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment, ruleContext: RuleContext): RuleContext {
        ruleContext.mergeCustomCourseUnitAttainment(customCourseUnitAttainment);
        return ruleContext;
    }

    static validateCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment, ruleContext: RuleContext): RuleContext {
        ruleContext.mergeCustomModuleAttainment(customModuleAttainment);
        return ruleContext;
    }

}
