import { Pipe, PipeTransform } from '@angular/core';
import {
    AbstractPersonRule,
} from 'common-typescript/types';
import { Observable } from 'rxjs';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { PersonRuleService } from '../service/person-rule.service';

@Pipe({
    name: 'personRequirementRuleName',
})
export class PersonRequirementRuleNamePipe implements PipeTransform {
    constructor(
        private appErrorHandler: AppErrorHandler,
        private personRuleService: PersonRuleService,
    ) {}

    transform(rule: AbstractPersonRule): Observable<string> {
        return this.personRuleService.fetchRuleInfoText(rule).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        );
    }
}
