import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    Education,
    EducationChildOption,
    EducationOption,
    EducationPhase,
    EducationResultItem, EducationUpdateRequest,
    LearningOpportunity,
    LearningOpportunitySelectionPath,
    LocalId,
    OtmId,
    SearchResult,
    Urn,
} from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        findAllStaffEducations(): string {
            return `${this.backend}/educations/staff`;
        },
        findByAdmissionTargetId(admissionTargetId: OtmId): string {
            return `${this.backend}/educations/by-admission-target/${admissionTargetId}`;
        },
        education(educationId: OtmId): string {
            return `${this.backend}/educations/${educationId}`;
        },
        educations(): string {
            return `${this.backend}/educations`;
        },
        findAllFromEducationSearch(): string {
            return `${this.backend}/authenticated/education-search`;
        },
        findAllForSelectionComponent(): string {
            return `${this.backend}/authenticated/educations/for-selection-component`;
        },
        findAll(): string {
            return `${this.backend}/authenticated/educations`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'educations',
})
export class EducationEntityService extends EntityService<EducationsState> {

    constructor() {
        super(EducationStore, EducationQuery);
    }

    /**
     * Resolves the education classification urns for a study right based on the given education, learning opportunity,
     * and selection path. The classification urns for each phase will be taken from the most specific level available
     * in the selection path, i.e.:
     *
     * 1. From the education child option, if one is selected in the selection path, and it has a classification urn defined
     * 2. From the education option, if one is selected in the selection path, and it has a classification urn defined
     * 3. From the learning opportunity, if it has a classification urn defined
     *
     * @param education The education from which to resolve the classification urns
     * @param learningOpportunityId The id of the learning opportunity referenced in the study right
     * @param selectionPath The (accepted) selection path of the study right
     */
    static resolveEducationClassificationUrns(
        education: Education,
        learningOpportunityId: LocalId,
        selectionPath?: Partial<LearningOpportunitySelectionPath>,
    ): { phase1EducationClassificationUrn?: Urn; phase2EducationClassificationUrn?: Urn } {
        let phase1EducationClassificationUrn: Urn = null;
        let phase2EducationClassificationUrn: Urn = null;
        if (education && learningOpportunityId) {
            const learningOpportunity = (education.structure.learningOpportunities || [])
                .find(lo => lo.localId === learningOpportunityId);
            phase1EducationClassificationUrn = this.getEducationClassificationUrn(
                education.structure.phase1,
                selectionPath?.educationPhase1GroupId,
                selectionPath?.educationPhase1ChildGroupId,
                learningOpportunity?.phase1EducationClassificationUrn,
            );
            phase2EducationClassificationUrn = this.getEducationClassificationUrn(
                education.structure.phase2,
                selectionPath?.educationPhase2GroupId,
                selectionPath?.educationPhase2ChildGroupId,
                learningOpportunity?.phase2EducationClassificationUrn,
            );
        }

        return { phase1EducationClassificationUrn, phase2EducationClassificationUrn };
    }

    private static getEducationClassificationUrn(
        educationPhase: EducationPhase,
        educationOptionGroupId: OtmId,
        educationChildOptionGroupId: OtmId,
        defaultValue: Urn,
    ): Urn | null {
        let educationOption: EducationOption;
        let educationChildOption: EducationChildOption;
        if (educationPhase && educationOptionGroupId) {
            educationOption = (educationPhase.options || [])
                .find(option => option.moduleGroupId === educationOptionGroupId);
            if (educationOption && educationChildOptionGroupId) {
                educationChildOption = (educationOption.childOptions || [])
                    .find(option => option.moduleGroupId === educationChildOptionGroupId);
            }
        }
        return educationChildOption?.educationClassificationUrn ?? educationOption?.educationClassificationUrn ?? defaultValue ?? null;
    }

    findByAdmissionTargetId(admissionTargetId: OtmId): Observable<Education[]> {
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.findByAdmissionTargetId(admissionTargetId))
            .pipe(
                tap(educations => this.store.upsertMany(educations)),
            );
    }

    getAllStaffEducations(): Observable<Education[]> {
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.findAllStaffEducations());
    }

    getAllEducations(universityOrgId: string): Observable<Education[]> {
        const options = universityOrgId ? { params: { universityOrgId, documentState: 'ACTIVE', includeExpired: 'true' } } :
            { documentState: 'ACTIVE', includeExpired: 'true' };
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.educations(), options);
    }

    findAllFromEducationSearch(searchParams: { [key: string]: string | boolean }): Observable<{ [key: string]: SearchResult<EducationResultItem> }> {
        return this.getHttp().get<{ [key: string]: SearchResult<EducationResultItem> }>(CONFIG.ENDPOINTS.findAllFromEducationSearch(), { params: searchParams });
    }

    // Returns one Education per groupId
    findAllForSelectionComponent(universityOrgId: string): Observable<Education[]> {
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.findAllForSelectionComponent(), { params: { universityOrgId } });
    }

    save(education: Education): Observable<Education> {
        return this.getHttp().put<Education>(CONFIG.ENDPOINTS.education(education.id), education);
    }

    override update<T = Education>(educationId: OtmId, educationUpdateRequest: EducationUpdateRequest): Observable<T> {
        return this.getHttp().put<T>(CONFIG.ENDPOINTS.education(educationId), educationUpdateRequest);
    }

    create(education: Education): Observable<Education> {
        return this.getHttp().post<Education>(CONFIG.ENDPOINTS.educations(), education);
    }

    isAgreementStudy(education: Education): boolean {
        return (education?.educationType || '').startsWith('urn:code:education-type:non-degree-education:agreement-studies');
    }

    isDegreeEducation(education: Education): boolean {
        return (education?.educationType || '').startsWith('urn:code:education-type:degree-education');
    }

    getLearningOpportunity(localId: LocalId, education: Education): LearningOpportunity {
        const learningOpportunities: LearningOpportunity[] = education?.structure?.learningOpportunities || [];
        return learningOpportunities.find(lo => lo.localId === localId);
    }

    findAll(searchParams: { [key: string]: string | string[] }): Observable<Education[]> {
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.findAll(), { params: searchParams });
    }

    findAllAsStudent(searchParams: { [key: string]: string | string[] }): Observable<Education[]> {
        return this.getHttp().get<Education[]>(CONFIG.ENDPOINTS.educations(), { params: searchParams });
    }

    requiresEnrolmentRight(educationId: OtmId): Observable<boolean> {
        return this.getById(educationId)
            .pipe(map(education => education.studySelectionRequirement === 'ENROLMENT_RIGHT_REQUIRED'));
    }
}

type EducationsState = EntityState<Education, OtmId>;

@StoreConfig({ name: 'educations' })
class EducationStore extends EntityStore<EducationsState> {}

class EducationQuery extends QueryEntity<EducationsState> {
    constructor(protected store: EducationStore) {
        super(store);
    }
}
