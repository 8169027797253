CourseUnitInfoModalController.$inject = ["$state", "$rootScope", "$controller", "$timeout", "STUDY_TYPE", "$translate", "$q", "defaultPromiseHandler", "gradeScaleJSDataModel", "planCourseUnitService", "localeService", "previewMode", "courseUnitJSDataModel", "commonCurriculumPeriodService", "languageJSDataModel", "COURSE_UNIT_URL", "courseUnitInfoModal", "AuthService", "commonStudyRightService", "clipboardService", "universityService", "commonPlanService", "newPlanSubstitutionService", "courseCartEntityService", "appErrorHandler"];
import * as _ from 'lodash-es';
import { take } from 'rxjs/operators';
import { CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT, OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT } from 'common-typescript/constants';

/**
 * @ngInject
 */
export function CourseUnitInfoModalController($state, $rootScope, $controller, $timeout, STUDY_TYPE, $translate, $q, defaultPromiseHandler, gradeScaleJSDataModel, planCourseUnitService, localeService, previewMode, courseUnitJSDataModel, commonCurriculumPeriodService, languageJSDataModel, COURSE_UNIT_URL, courseUnitInfoModal, AuthService, commonStudyRightService, clipboardService, universityService, commonPlanService, newPlanSubstitutionService, courseCartEntityService, appErrorHandler) {
  const ctrl = this;
  function initLanguages() {
    ctrl.language = localeService.getCurrentLocale();
  }

  // called after a new version is selected (not saved) in the modal
  ctrl.updatePlanStatus = () => {
    ctrl.showVersionSwitchNotification = false;
    ctrl.canSwitchToThisVersion = false;
    if (isCourseUnitInPlan() || isCourseUnitAttained() || isCourseUnitInPlanAsSubstitute()) {
      ctrl.showVersionSwitchNotification = true;
      isVersionSwitchAllowed().then(result => {
        ctrl.canSwitchToThisVersion = result;
      });
    }
  };

  /**
   * returns false when there is a plan, but the course unit is not placed in it. such cases include (but are not limited to):
   * - course unit modal opened from the course cart section of the left-side "Add to the plan" panel
   * - course unit modal opened from the right-side selection assistant, before it is placed
   * */
  function isCourseUnitInPlan() {
    return ctrl.validatablePlan?.isCourseUnitInPlan(ctrl.originalCourseUnit);
  }
  function isCourseUnitAttained() {
    return ctrl.validatablePlan?.isCourseUnitAttained(ctrl.originalCourseUnit.id);
  }
  function isCourseUnitInPlanAsSubstitute() {
    return ctrl.validatablePlan?.isCourseUnitInPlanAsSubstitute(ctrl.originalCourseUnit);
  }
  function isVersionSwitchAllowed() {
    return planCourseUnitService.isVersionSwitchAllowed(ctrl.courseUnit, ctrl.originalCourseUnit, ctrl.validatablePlan, ctrl.plan);
  }
  function loadCourseUnitRelations(cu) {
    gradeScaleJSDataModel.lazyGetAll();
    languageJSDataModel.lazyGetAll();
    return $q.all([cu.loadRelations(), commonCurriculumPeriodService.findByUniversityOrgId(cu.universityOrgIds), courseUnitJSDataModel.loadRelations(cu, ['gradeScale'])]);
  }
  ctrl.updateResponsibilityInfos = () => {
    // sort responsibility infos by responsible-teacher > contact-info first and then within each role responsibility infos
    // with personId reference first
    ctrl.sortedByNameResponsibilityInfos = _.chain(ctrl.courseUnit.responsibilityInfos).filter(info => _.get(info, 'roleUrn') !== 'urn:code:module-responsibility-info-type:administrative-person').orderBy([info => info.roleUrn === 'urn:code:module-responsibility-info-type:contact-info', info => !info.personId, info => _.upperCase(_.get(info, 'person.fullName'))]).value();
  };
  ctrl.$onInit = () => {
    window.scroll(0, 0);
    // 'data' is passed inside 'resolve' object in modals, directly in the controller otherwise
    if (ctrl.resolve) {
      ctrl.data = ctrl.resolve.data;
      ctrl.isModal = true;
    }
    ctrl.scope = $rootScope;

    /** The currently active `Tab` (don't mix this with `openTab`, which is the name of the tab to select initially) */
    ctrl.selectedTab = null;
    const defaultTabs = [{
      path: 'student.course-unit.brochure',
      id: 'course-unit-info-modal-brochure-tab',
      title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.INFO',
      name: 'BASIC'
    }, {
      path: 'student.course-unit.completion-methods',
      id: 'course-unit-info-modal-completion-methods-tab',
      title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.COMPLETION_METHODS',
      name: 'COMPLETION_METHODS'
    }, {
      path: 'student.course-unit.substitutions',
      id: 'course-unit-info-modal-substitutions-tab',
      title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.SUBSTITUTIONS',
      name: 'SUBSTITUTIONS'
    }];
    universityService.getCurrentUniversitySettings().then(settings => {
      // show open university tab if open university features are enabled for student in current university settings or fall back to default
      const openUniversityFeaturesEnabledForStudent = _.get(settings, 'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent', OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT);
      const crossStudyFeaturesEnabledForStudent = _.get(settings, 'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent', CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT);
      ctrl.tabs = [...defaultTabs, ...(openUniversityFeaturesEnabledForStudent ? [{
        path: 'student.course-unit.open-university',
        id: 'course-unit-info-modal-open-university-tab',
        title: 'COURSE_UNIT_INFO_MODAL.TAB_HEADINGS.OPEN_UNIVERSITY',
        name: 'OPEN_UNIVERSITY_OFFERING'
      }] : []), ...(crossStudyFeaturesEnabledForStudent ? [{
        path: 'student.course-unit.cross-study',
        id: 'course-unit-info-modal-cross-study-tab',
        title: 'STUDIES.CROSS_STUDY.TITLE',
        name: 'CROSS_STUDY'
      }] : [])];
    }).catch(error => {
      ctrl.tabs = defaultTabs;
      defaultPromiseHandler.loggingRejectedPromiseHandler(error);
    }).finally(() => ctrl.selectTab(ctrl.openTab ?? ctrl.resolve.data.openTab ?? ctrl.tabs[0].name));
    ctrl.isInCourseCart = false;
    ctrl.menuOptions = [];
    if (ctrl.isLoggedIn()) {
      courseCartEntityService.getCourseCart(true).pipe(take(1), appErrorHandler.defaultErrorHandler()).subscribe(ids => {
        ctrl.isInCourseCart = _.includes(ids, ctrl.data.courseUnit.id);
        ctrl.menuOptions = getMenuOptions();
      });
    }
    ctrl.showPlanLocation = false;
    ctrl.canSwitchToThisVersion = false;
    ctrl.versionChangeInProgress = false;
    ctrl.displayNamesByCourseUnitId = {};
    ctrl.forceEnableCalendarAdd = ctrl.data.forceEnableCalendarAdd;
    ctrl.courseUnit = ctrl.data.courseUnit;
    ctrl.currentUniversityOrgId = ctrl.data.currentUniversityOrgId;
    ctrl.completionMethods = ctrl.data.completionMethods;
    ctrl.plan = ctrl.data.plan;
    ctrl.validatablePlan = ctrl.data.validatablePlan;
    ctrl.courseUnitRealisation = ctrl.data.courseUnitRealisation;
    ctrl.attainments = ctrl.data.attainments;
    ctrl.originalCourseUnit = ctrl.courseUnit;
    ctrl.unplannedCourseUnit = ctrl.data.unplannedCourseUnit;
    ctrl.module = ctrl.data.module;
    ctrl.color = ctrl.data.color;
    ctrl.openUniversityOfferingPreselectedPurchase = ctrl.data.openUniversityOfferingPreselectedPurchase;
    if (ctrl.plan) {
      const courseUnitSelection = ctrl.plan.getCourseUnitSelection(ctrl.courseUnit);
      ctrl.parentModuleId = _.get(courseUnitSelection, 'parentModuleId');
      commonStudyRightService.getValidStudyRightByEducationId(ctrl.plan.rootId).then(result => {
        ctrl.studyRight = result;
      });
    }
    initLanguages();
    ctrl.updateResponsibilityInfos();
    if (previewMode.isPreviewMode()) {
      ctrl.previewModeConfig = {
        documentStates: previewMode.documentStatesForPreview(),
        url: COURSE_UNIT_URL.AUTHENTICATED_ENDPOINT_URL
      };
    }
    loadCourseUnitRelations(ctrl.courseUnit).then(() => {
      ctrl.updatePlanStatus();
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  ctrl.setCourseUnitVersion = cu => {
    $state.go('.', {
      courseUnitId: cu.id
    });
  };
  ctrl.versionIsChanged = () => ctrl.originalCourseUnit && _.get(ctrl.originalCourseUnit, 'id') !== ctrl.courseUnit.id;
  ctrl.getColor = () => ctrl.color ? ctrl.color : 'cu-color-0';
  ctrl.arrayHasValue = arr => !_.isEmpty(arr);
  ctrl.isLoggedIn = () => AuthService.loggedIn();
  ctrl.saveCourseUnitVersionChange = () => planCourseUnitService.changeCourseUnitVersion(ctrl.courseUnit, ctrl.originalCourseUnit.id, ctrl.plan).then(() => {
    ctrl.originalCourseUnit = ctrl.courseUnit;
    refreshValidatablePlan();
    ctrl.versionChangeInProgress = false;
  });
  ctrl.addCourseUnitToCourseCart = () => courseCartEntityService.addCurToCourseCart(ctrl.courseUnit.id).pipe(take(1), appErrorHandler.defaultErrorHandler()).subscribe(() => {
    $rootScope.$evalAsync(() => {
      ctrl.isInCourseCart = true;
      $rootScope.$broadcast('courseCartChanged');
      ctrl.menuOptions = getMenuOptions();
    });
  });
  ctrl.removeCourseUnitFromCourseCart = () => courseCartEntityService.deleteCurFromCourseCart(ctrl.courseUnit.id).pipe(take(1), appErrorHandler.defaultErrorHandler()).subscribe(() => {
    $rootScope.$evalAsync(() => {
      ctrl.isInCourseCart = false;
      $rootScope.$broadcast('courseCartChanged');
      ctrl.menuOptions = getMenuOptions();
    });
  });
  ctrl.getCreditTranslation = () => $translate.instant('CREDIT_SHORTHAND_NAME', undefined, undefined, ctrl.language);
  ctrl.hasCoordinatingOrganisations = () => _.some(ctrl.courseUnit.organisations, {
    roleUrn: 'urn:code:organisation-role:coordinating-organisation'
  });
  ctrl.openCourseUnitModal = courseUnitId => {
    courseUnitInfoModal.showCourseUnit(courseUnitId, ctrl.validatablePlan);
  };
  ctrl.selectTab = tabName => {
    $rootScope.$evalAsync(() => {
      this.selectedTab = ctrl.tabs.find(tab => tab.name === tabName);

      // open university preselection is done only when the component is shown for the first time
      if (tabName !== 'OPEN_UNIVERSITY_OFFERING') {
        ctrl.openUniversityOfferingPreselectedPurchase = undefined;
      }
    });
  };
  ctrl.removeSubstitutions = removeSubstitutionsEvent => {
    const {
      courseUnit,
      plan
    } = removeSubstitutionsEvent;
    newPlanSubstitutionService.removeSubstitution(courseUnit, plan).then(updatedPlan => {
      ctrl.plan = _.cloneDeep(updatedPlan);
      refreshValidatablePlan();
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  ctrl.selectSubstitutions = selectSubstitutionsEvent => {
    const {
      courseUnit,
      substitution,
      plan,
      substituteCourseUnitsByGroupId
    } = selectSubstitutionsEvent;
    newPlanSubstitutionService.saveSubstitution(courseUnit, substitution, plan, substituteCourseUnitsByGroupId).then(updatedPlan => {
      ctrl.plan = _.cloneDeep(updatedPlan);
      refreshValidatablePlan();
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  };
  function getMenuOptions() {
    return [ctrl.isInCourseCart ? {
      name: 'REMOVE_FROM_CART',
      action: () => ctrl.removeCourseUnitFromCourseCart()
    } : {
      name: 'ADD_TO_CART',
      action: () => ctrl.addCourseUnitToCourseCart()
    }, {
      name: 'COURSE_UNIT_INFO_NEW_TAB',
      action: () => window.open(ctrl.getBrochureUrl(), '_blank')
    }, {
      name: 'COURSE_UNIT_INFO_COPY_LINK',
      action: () => clipboardService.copy(ctrl.getBrochureUrl()),
      dataCy: ctrl.getBrochureUrl ? ctrl.getBrochureUrl() : undefined
    }];
  }
  function refreshValidatablePlan() {
    commonPlanService.getValidatablePlan(ctrl.plan).then(validatablePlan => {
      ctrl.validatablePlan = validatablePlan;
    }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
  }
  ctrl.close = () => ctrl.dismiss();
  const listener = $rootScope.$on('closeCourseInfoModal', ctrl.close);
  ctrl.$onDestroy = function () {
    listener();
  };
  ctrl.setModalCourseUnitVersion = cu => {
    ctrl.courseUnit = cu;
    ctrl.versionChangeInProgress = ctrl.versionIsChanged();
    ctrl.completionMethods = cu.completionMethods.filter(cm => cm.studyType !== STUDY_TYPE.OPEN_UNIVERSITY_STUDIES);
    ctrl.updateResponsibilityInfos();
    $timeout(() => ctrl.updatePlanStatus());
  };
  ctrl.openSubstitutionForm = function (courseUnitId, planId) {
    ctrl.dismiss();
    $state.go('student.logged-in.profile.applications.create-prior-learning-substitution-application', {
      planId,
      courseUnitId
    });
  };
  ctrl.openApplication = function (applicationId) {
    ctrl.dismiss();
    $state.go('student.logged-in.profile.applications.prior-learning-application', {
      applicationId
    });
  };
  ctrl.getBrochureUrl = () => $state.href('student.course-unit', {
    courseUnitId: ctrl.resolve.data.courseUnit.id
  }, {
    absolute: true
  });
  return ctrl;
}