import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'sis-common/auth/auth-service';

import { ActiveUserRoleService, UserRole } from '../../service/active-user-role.service';

@Component({
    selector: 'sis-user-settings-menu',
    templateUrl: './user-settings-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsMenuComponent implements OnInit {
    @Input() public isFocusMode?: boolean = false;

    activeRoleNameKey: string;
    dropdownMenu: HTMLElement;

    constructor(
        private readonly authService: AuthService,
        private readonly activeUserRoleService: ActiveUserRoleService,
    ) {}

    /** View child for the component's wrapper div */
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    ngOnInit() {
        this.activeRoleNameKey = this.getActiveRoleNameKey();
        this.dropdownMenu = document.getElementById('sis-user-settings-menu');
    }

    get displayName(): string {
        return this.authService.displayname();
    }

    private getActiveRoleNameKey(): string {
        switch (this.activeUserRoleService.activeUserRole) {
            case UserRole.Admin:
                return 'AUTH.APPROLE.ADMIN';
            case UserRole.Student:
                return 'AUTH.APPROLE.STUDENT';
            case UserRole.Staff:
                return 'AUTH.APPROLE.STAFF';
            case UserRole.Teacher:
                return 'AUTH.APPROLE.TEACHER';
            default:
                return null;
        }
    }

    /** Close settings menu when next targeted element is not inside the menu */
    blurMenu(event: any) {
        if (!this.dropdownMenu.contains(event.relatedTarget)) {
            this.dropdown.close();
        }
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.dropdown.isOpen() && event.code !== 'Tab') {
            const listItems = this.getDropdownItems() as HTMLInputElement[];

            if (listItems.length > 0) {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    const focusElement = this.getFocusedElement();
                    const siblingElementsCount = listItems.length - 1;
                    event.preventDefault();

                    const focusIndex = listItems.indexOf(focusElement);

                    let elementToFocus;

                    if (event.key === 'ArrowDown') {
                        elementToFocus = listItems[focusIndex < siblingElementsCount ? focusIndex + 1 : 0];
                    }

                    if (event.key === 'ArrowUp') {
                        elementToFocus = listItems[focusIndex > 0 ? focusIndex - 1 : siblingElementsCount];
                    }

                    elementToFocus.focus();

                } else if (event.code === 'Space') {
                    event.preventDefault();
                    const focusElement = this.getFocusedElement();
                    focusElement.click();
                }
            }
        }
    }

    private getFocusedElement() {
        return this.dropdownMenu.querySelector(':focus') as HTMLInputElement;
    }

    private getDropdownItems() {
        const listItems = this.dropdownMenu.querySelectorAll('a, button');
        return Array.prototype.filter.call(listItems, (item: any) => (!item.classList.contains('d-lg-none') && !item.classList.contains('d-none')));
    }
}
