<ng-container *transloco="let t">
  <fudis-grid [rowGap]="'xs'" [classes]="'fudis-mt-xs'">
    @if (selectionUIState === 'ACTIVE') {
      <div role="heading" class="fudis-heading__xs" [attr.aria-level]="headingLevel">
        {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.TITLE') }}
      </div>
      <fudis-select
              [size]="'lg'"
              [placeholder]="t('PLAN_EDIT.SELECTION_MODAL.SEARCH_PLACEHOLDER')"
              [label]="t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.SEARCH_COURSE_UNITS_LABEL')"
              [control]="courseUnitSelectControl"
              [variant]="'autocompleteType'"
              [helpText]="t('PLAN_EDIT.SELECTION_MODAL.SEARCH_HELP_TEXT')"
              [selectionClearButton]="false"
              (selectionUpdate)="updateSelected($event)"
              (filterTextUpdate)="searchTextUpdateSubject.next($event)"
      >
        <ng-template fudisSelectOptions>
          @if (searchResults | async; as options) {
           <fudis-select-option
                   *ngFor="let option of options"
                   [data]="option"/>
          }
        </ng-template>

      </fudis-select>
      <sis-checkbox
              [label]="t('PLAN_EDIT.RIPA_CHECKBOX_LABEL')"
              [checked]="this.ripaStudiesIncluded"
              (checkedChange)="this.toggleRipaStudiesForSearch($event)">
      </sis-checkbox>
      <fudis-hr/>
      <div role="heading" class="fudis-heading__xs" [attr.aria-level]="headingLevel + 1">
        {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_TITLE') }}
      </div>
      <fudis-grid-item>
        <fudis-body-text>
          {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_DESCRIPTION') }}
        </fudis-body-text>
        <sis-button
          [label]="t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.CUSTOM_STUDY_DRAFT_OPEN_READ_MORE')"
          (clicked)="openReadMoreAboutStudyDraftModal()"
          [link]="true">
        </sis-button>
      </fudis-grid-item>
      <fudis-button [label]="t('PLAN_EDIT.SELECTION_MODAL.ANY_COURSE_UNIT_RULE.ADD_NEW_CUSTOM_STUDY_DRAFT')"
                    [size]="'small'"
                    [variant]="'secondary'"
                    (handleClick)="openAddStudyDraftModal()">
      </fudis-button>
      <fudis-hr/>
      <div class="sis-mb-xs"></div>
    } @else {
      <fudis-body-text>
        {{ t('PLAN_EDIT.SELECTION_MODAL.ANY_MODULE_OR_COURSE_UNIT_TITLE') }}
      </fudis-body-text>
    }
  </fudis-grid>
</ng-container>





