import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sis-selection-box',
    templateUrl: './selection-box.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SelectionBoxComponent {

    @Input() translatedTitle: string;
    @Input() value: any;
    @Input() control: FormControl;
    @Input() type: 'radio' | 'checkbox';
    @Input() name?: string;
    @Input() id?: string;
    @Input() disabled?: boolean;
    @Input() disabledReason?: string;
    @Input() required?: boolean;

    @Input() ariaDisabled?: boolean;

    updateCheckboxValue(event: boolean) {
        this.control.setValue(event ? this.value : null);
        this.control.updateValueAndValidity();
    }

    clickInput() {
        const el = document.getElementById(this.id) as HTMLInputElement;
        if (el) {
            el.click();
        }
    }
}
