import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { PlanValidationResult } from 'common-typescript';
import { Education } from 'common-typescript/types';
import { PlanData, PlanStateObject } from 'sis-components/service/plan-state.service';

@Component({
    selector: 'app-plan-structure',
    templateUrl: './plan-structure.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureComponent {
    @Input() education: Education;
    @Input() planValidationResult: PlanValidationResult;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
}
