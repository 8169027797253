import angular from 'angular';
import * as _ from 'lodash-es';
import moment from 'moment';
import anyModuleRuleTpl from './anyModuleRule.tpl.html';
(function () {
  AnyModuleRuleController.$inject = ["$scope", "uiContext", "ruleSearchParametersService", "minorStudyRightService", "defaultPromiseHandler", "rawPlanEditService", "planContextEventType", "searchResultHelperService", "minorSelectionModalService", "StudyRightSelectionType"];
  angular.module('student.plan.rules').component('anyModuleRule', {
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      educationOptions: '<',
      studyRight: '<'
    },
    controller: AnyModuleRuleController,
    template: anyModuleRuleTpl
  });
  function AnyModuleRuleController(
  // NOSONAR
  $scope, uiContext, ruleSearchParametersService, minorStudyRightService, defaultPromiseHandler, rawPlanEditService, planContextEventType, searchResultHelperService, minorSelectionModalService, StudyRightSelectionType) {
    const $ctrl = this;
    $scope.$on(planContextEventType.planBuilt, initialize);
    $ctrl.$onInit = function () {
      initialize();
    };
    function initialize() {
      $ctrl.studyRightSelectionTypesForModuleSearch = getStudyRightSelectionTypesForModuleSearch();
      if (_.isNil(uiContext.planContext)) {
        return;
      }
      uiContext.planContext.toPlan().then(plan => {
        $ctrl.rawPlan = plan;
        $ctrl.selectedModules = $ctrl.getSelectedModules();
        return ruleSearchParametersService.getCurriculumPeriodsForSearch(plan.curriculumPeriodId).then(curriculumPeriods => {
          $ctrl.curriculumPeriodIds = _.map(curriculumPeriods, 'id');
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function getStudyRightSelectionTypesForModuleSearch() {
      if ($ctrl.hasMinorStudyRightContentFilter()) {
        return [StudyRightSelectionType.MINOR_STUDY_RIGHT];
      }
      return undefined;
    }
    function getRuleValidationResult() {
      return uiContext.planContext.getRuleValidationResult($ctrl.module.id, $ctrl.rule.localId);
    }
    function getModuleValidationResult(module) {
      const planValidationResult = uiContext.planContext.getPlanValidationResult();
      return _.get(_.get(planValidationResult, 'moduleValidationResults'), module.id);
    }
    $ctrl.hasMinorStudyRightContentFilter = () => _.get($ctrl.module, 'contentFilter.studyRightSelectionType') === StudyRightSelectionType.MINOR_STUDY_RIGHT;
    $ctrl.selectSuitableVersionOfModule = moduleFromSearchResult => {
      uiContext.planContext.selectModuleByGroupId($ctrl.module, _.get(moduleFromSearchResult, 'groupId'));
    };
    $ctrl.removeModule = module => {
      uiContext.planContext.removeModule(module);
    };
    $ctrl.isSearchResultDisabled = moduleSearchResult => searchResultHelperService.isModuleSearchResultDisabled(moduleSearchResult, $ctrl.module, $ctrl.validatablePlan);
    $ctrl.isInvalidSelection = module => _.get(getModuleValidationResult(module), 'invalidSelection') === true;
    $ctrl.isInvalidAccordingToModuleContentApproval = module => _.get(getModuleValidationResult(module), 'invalidAccordingToModuleContentApproval') === true;
    $ctrl.hasModuleContentApproval = module => _.get(getModuleValidationResult(module), 'invalidAccordingToModuleContentApproval') !== undefined;
    $ctrl.unselectCustomModuleAttainment = customModuleAttainment => {
      rawPlanEditService.removeCustomModuleAttainment(customModuleAttainment.id, $ctrl.rawPlan);
      return uiContext.planContext.buildAndUpdate($ctrl.rawPlan);
    };
    $ctrl.getSelectedModules = () => {
      let selectedModulesUnderAnyModuleRule = [];
      const selectedModulesById = _.get(getRuleValidationResult(), 'selectedModulesById');
      if (selectedModulesById) {
        selectedModulesUnderAnyModuleRule = _.values(selectedModulesById);
      }
      const selectedModuleIdsUnderModule = _.map($ctrl.validatablePlan.getSelectedModulesById($ctrl.module), 'id');
      return _.filter(selectedModulesUnderAnyModuleRule, module => _.includes(selectedModuleIdsUnderModule, module.id));
    };
    $ctrl.showMinorSelectionBlock = module => !!$ctrl.studyRight && !$ctrl.validatablePlan.isModuleAttained(module.id) && minorStudyRightService.isMinorSelection(module, $ctrl.validatablePlan);
    $ctrl.getApprovedMinorSelection = module => minorStudyRightService.getApprovedMinorSelection(module, $ctrl.studyRight, $ctrl.validatablePlan);
    $ctrl.isMinorSelectionOngoing = module => {
      const endDate = _.get($ctrl.getApprovedMinorSelection(module), 'validityPeriod.endDate');
      return _.isNil(endDate) || moment().isBefore(moment(endDate));
    };
    $ctrl.isTeacherAcceptanceRequired = module => minorStudyRightService.isTeacherAcceptanceRequired(module);
    $ctrl.openMinorSelectionModal = module => {
      minorSelectionModalService.open(module, $ctrl.studyRight, $ctrl.validatablePlan).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
  }
})();