import { Component, inject, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ValidatablePlan } from 'common-typescript';
import { MaxLength } from 'common-typescript/constants';
import {
    Attainment,
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    DegreeProgramme,
    LocalizedString,
    Module,
    ModuleContentWorkflowApplication,
    ModuleType,
    OtmId,
    StudyModule,
    StudyRight,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { take } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { maxLength, required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { UniversityService } from 'sis-components/service/university.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

export function moduleContentWorkflowApplicationModalOpener(): (approvalRequestType: 'CUSTOM' | 'REQUIRED', module: Module, validatablePlan: ValidatablePlan, matchingStudyRight: StudyRight) => NgbModalRef {
    const modalService = inject(ModalService);
    return (
        approvalRequestType: 'CUSTOM' | 'REQUIRED',
        module: Module,
        validatablePlan: ValidatablePlan,
        matchingStudyRight: StudyRight,
    ) => modalService.open(ModuleContentWorkflowModalComponent, {
        approvalRequestType,
        module,
        validatablePlan,
        matchingStudyRight,
    }, { size: 'lg' });
}
export interface ModuleContentWorkflowModalValues {
    approvalRequestType: 'CUSTOM' | 'REQUIRED';
    module: Module;
    validatablePlan: ValidatablePlan;
    matchingStudyRight: StudyRight;
}

@Component({
    selector: 'app-module-content-workflow-modal',
    templateUrl: './module-content-workflow-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleContentWorkflowModalComponent implements OnInit {

    approvalRequestType: string;
    moduleContentApplicationDescriptionText: LocalizedString;
    moduleContentApplicationDescriptionUrl: LocalizedString;
    parentModule: Module;
    selectedCourseUnits: CourseUnit[];
    selectedModules: Module[];
    selectedCustomCourseUnitAttainments: CustomCourseUnitAttainment[];
    selectedCustomModuleAttainments: CustomModuleAttainment[];
    customStudyDrafts: CustomStudyDraft[];
    attainments: Attainment[];
    validatablePlan: ValidatablePlan;

    moduleContentCommentFormControl: FormControl<string>;

    constructor(private universityService: UniversityService,
                private errorHandler: AppErrorHandler,
                private modal: NgbActiveModal,
                private alertsService: AlertsService,
                private fb: SisFormBuilder,
                private workflowEntityService: WorkflowEntityService,
                @Inject(ModalService.injectionToken) private values: ModuleContentWorkflowModalValues) {}

    readonly entityId = trackByEntityId;

    ngOnInit(): void {
        this.approvalRequestType = this.values.approvalRequestType;
        this.parentModule = this.values.module;
        this.validatablePlan = this.values.validatablePlan;
        this.selectedCourseUnits = this.validatablePlan.getSelectedCourseUnitsUnderModule(this.parentModule);
        const allSelectedModules = this.validatablePlan.getSelectedModulesUnderModule(this.parentModule);
        this.selectedModules = _.filter(allSelectedModules, selectedModule => selectedModule.type !== 'GroupingModule');
        this.selectedCustomCourseUnitAttainments = this.validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(this.parentModule);
        this.selectedCustomModuleAttainments = this.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(this.parentModule);
        this.customStudyDrafts = this.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.parentModule.id);

        this.universityService.getCurrentUniversitySettings()
            .pipe(take(1))
            .subscribe((settings) => {
                this.moduleContentApplicationDescriptionUrl = _.get(settings, 'moduleContentApplicationDescriptionUrl');
                this.moduleContentApplicationDescriptionText = _.get(settings, 'moduleContentApplicationDescriptionText');
            });

        const selectedCourseUnitAttainments = _.chain(this.selectedCourseUnits)
            .map(courseUnit => this.validatablePlan.getCourseUnitAttainmentByGroupId(courseUnit.groupId) as Attainment)
            .compact()
            .value();
        const selectedModuleAttainments = _.chain(this.selectedModules)
            .map(module => this.validatablePlan.getModuleAttainmentByGroupId(module.groupId) as Attainment)
            .compact()
            .value();
        this.attainments = _.concat(selectedCourseUnitAttainments, selectedModuleAttainments);

        this.moduleContentCommentFormControl = this.fb.control<string>(
            null,
            [
                required(),
                maxLength(MaxLength.MAX_LONG_STRING_LENGTH),
            ]);
    }

    dismiss(): void {
        this.modal.dismiss();
    }

    sendApplication(): void {
        this.moduleContentCommentFormControl?.markAllAsTouched();
        if (this.moduleContentCommentFormControl?.valid) {
            const workflowRationale = this.moduleContentCommentFormControl.value?.trim();
            const moduleContentWorkflowApplication = this.createModuleContentWorkflowApplication(workflowRationale);
            this.workflowEntityService.createAsStudent(moduleContentWorkflowApplication)
                .pipe(take(1), this.errorHandler.defaultErrorHandler())
                .subscribe({
                    next: (workflow) => {
                        this.modal.close(workflow);
                    },
                });
        } else {
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    createModuleContentWorkflowApplication(applicationRationale: string): Partial<ModuleContentWorkflowApplication> {
        const workflowType = this.approvalRequestType === 'CUSTOM' ? 'CustomModuleContentApplication' : 'RequiredModuleContentApplication';
        const parentWithOrganisations: StudyModule | DegreeProgramme = this.getParentWithOrganisationsRecursively(this.parentModule.id);

        return {
            applicationRationale,
            approvedModuleId: this.parentModule.id,
            courseUnitSelections: _.map(
                this.validatablePlan.getSelectedCourseUnitsUnderModule(this.parentModule),
                'groupId',
            ),
            customCourseUnitAttainmentSelections: _.map(
                this.validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(this.parentModule),
                'id',
            ),
            customModuleAttainmentSelections: _.map(
                this.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(this.parentModule),
                'id',
            ),
            customStudyDrafts: this.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(this.parentModule.id),
            educationId: this.validatablePlan.plan.rootId,
            moduleSelections: _.map(
                this.validatablePlan.getSelectedModulesUnderModule(this.parentModule),
                'groupId',
            ),
            originalReferredPlanId: this.validatablePlan.plan.id,
            parentModuleId: _.get(this.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(this.parentModule), 'id'),
            studyRightId: _.get(this.values.matchingStudyRight, 'id'),
            type: workflowType,

            organisations: parentWithOrganisations?.organisations,
        };
    }

    getParentWithOrganisationsRecursively(moduleId: OtmId): StudyModule | DegreeProgramme {
        if (_.isNil(moduleId)) {
            return null;
        }
        const module = _.get(this.validatablePlan.modulesById, moduleId);
        if (_.isNil(module)) {
            return null;
        }
        if (module.type === ModuleType.STUDY_MODULE) {
            return module as StudyModule;
        }
        if (module.type === ModuleType.DEGREE_PROGRAMME) {
            return module as DegreeProgramme;
        }
        if (module.type === ModuleType.GROUPING_MODULE) {
            return this.getParentWithOrganisationsRecursively(this.validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module).id);
        }
        return null;
    }
}
