import { DegreeProgramme, LocalizedUrl } from 'common-typescript/types';
import * as _ from 'lodash-es';

export const isBachelorsDegree = (degreeProgramme: DegreeProgramme) => _.get(degreeProgramme, 'degreeProgramTypeUrn') === 'urn:code:degree-program-type:bachelors-degree';

export const isAMKDegree = (degreeProgramme: DegreeProgramme) => {
    const urn = _.get(degreeProgramme, 'degreeProgramTypeUrn');
    return urn === 'urn:code:degree-program-type:amk-bachelors-degree' || urn === 'urn:code:degree-program-type:amk-masters-degree';
};

export const resolveGraduationModalValues = (graduateType: string, surveyUrl: LocalizedUrl) => ({
    surveyUrl,
    modalTitle: `GRADUATION.${graduateType}_GRADUATE_SURVEY_MODAL.TITLE`,
    infoText: `GRADUATION.${graduateType}_GRADUATE_SURVEY_MODAL.INFO_TEXT`,
    infoQuestion: `GRADUATION.${graduateType}_GRADUATE_SURVEY_MODAL.INFO_QUESTION`,
    continueButtonText: `GRADUATION.${graduateType}_GRADUATE_SURVEY_MODAL.CONTINUE_TO_SURVEY`,
});
