<div class="frontpage-container" *transloco="let t">
  <div class="banner">
    <div class="container-fluid px-4 py-4 banner-content">
          <div class="row sis-px-xs">
              <div class="col-12 col-md-6 p-0 mb-4 mb-md-0">
                  <h1 class="m-0 front-page-title">{{ t('FRONTPAGE.TITLE') }}</h1>
              </div>
              <div class="col-12 col-md-6 p-0">
                  <app-select-widgets class="float-md-end"
                                      [widgetOptions]="widgetOptions"
                  ></app-select-widgets>
              </div>
          </div>
      </div>
  </div>

  <div class="container-fluid px-3 py-4 px-md-5">
      <div class="sis-px-xs notifications-container">

          <sis-service-break-banner />

          <app-student-term-registration-period-info />

          <app-study-right-expiration-info />

      </div>
  </div>

  <ng-container *ngIf="userSettings$ | async as settings; else loading">
    <div class="container-fluid g-5 p-3 py-md-0 px-md-5">
      <div class="row sis-px-xs">
        <ng-container *ngIf="allComponentsHidden(settings)">
          <div class="col-12">
            <div class="eiku">
                <svg viewBox="0 0 62 35" class="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M0 2a2 2 0 0 1 2-2h58a2 2 0 0 1 2 2v31a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z" fill="#fff"/><path d="m21.946 12.326-.196 1.372h-3.57V16.4h3.108v1.372H18.18v2.842h3.822V22h-5.67v-9.674h5.614Zm4.458 0V22h-1.848v-9.674h1.848Zm4.916 0V22H29.47v-9.674h1.849Zm5.431 0-3.345 4.382L37.017 22H34.82l-3.416-5.166 3.346-4.508h2.002Zm9.053 6.51c0 .653-.14 1.237-.42 1.75-.27.504-.681.9-1.232 1.19-.541.29-1.195.434-1.96.434-1.157 0-2.044-.303-2.66-.91-.616-.616-.924-1.437-.924-2.464v-6.51h1.848v6.412c0 .681.14 1.19.42 1.526.29.336.728.504 1.316.504.597 0 1.036-.168 1.316-.504.29-.336.434-.845.434-1.526v-6.412h1.862v6.51ZM2 1.5h58v-3H2v3Zm58.5.5v31h3V2h-3ZM60 33.5H2v3h58v-3ZM1.5 33V2h-3v31h3Zm.5.5a.5.5 0 0 1-.5-.5h-3A3.5 3.5 0 0 0 2 36.5v-3Zm58.5-.5a.5.5 0 0 1-.5.5v3a3.5 3.5 0 0 0 3.5-3.5h-3ZM60 1.5a.5.5 0 0 1 .5.5h3A3.5 3.5 0 0 0 60-1.5v3Zm-58-3A3.5 3.5 0 0 0-1.5 2h3a.5.5 0 0 1 .5-.5v-3Z" fill="#1076DB"/>
                </svg>
            </div>
            <div class="no-components">
              <p>{{ t('FRONTPAGE.NO_WIDGETS_SELECTED') }}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let widgetOption of widgetOptions">
          <div *ngIf="isVisible(settings, widgetOption.value)" class="col-12 col-md-6 col-xl-4 col-xxl-3 sis-py-xs">
            <ng-container *ngComponentOutlet="widgetOption.component"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <sis-small-spinner />
  </ng-template>
</div>

