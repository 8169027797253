import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { OtmId, Passport } from 'common-typescript/types';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/ori/api',
        get baseUrl() {
            return `${this.backend}/passports`;
        },
        activePassportsByPersonId(personId: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/active/${personId}`;
        },
        deletePassport(passportId: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/${passportId}`;
        },
        createPassport() {
            return `${CONFIG.ENDPOINTS.baseUrl}`;
        },
        updatePassport(passportId: OtmId) {
            return `${CONFIG.ENDPOINTS.baseUrl}/${passportId}`;
        },
    },
};

@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'passports',
})
export class PassportEntityService extends EntityService<PassportState> {

    constructor() {
        super(PassportStore, PassportQuery);
    }

    getActivePassportsByPersonId(personId: OtmId): Observable<Passport[]> {
        return this.getHttp().get(CONFIG.ENDPOINTS.activePassportsByPersonId(personId))
            .pipe(
                tap((passports: Passport[]) => this.store.upsertMany(passports)),
                switchMap(() =>
                    this.query.selectAll({ filterBy: passport => passport.personId === personId })),
            );
    }

    deletePassport(passportId: OtmId): Observable<void> {
        return this.getHttp().delete<void>(CONFIG.ENDPOINTS.deletePassport(passportId))
            .pipe(
                tap(() => this.store.remove(passportId)),
            );
    }

    createPassport(passport: Partial<Passport>): Observable<Passport> {
        return this.getHttp().post<Passport>(CONFIG.ENDPOINTS.createPassport(), passport)
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    updatePassport(passportId: OtmId, request: Partial<Passport>): Observable<Passport> {
        return this.getHttp().put<Passport>(CONFIG.ENDPOINTS.updatePassport(passportId), request)
            .pipe(tap((passport) => this.store.upsert(passport.id, passport)));
    }

}

type PassportState = EntityState<Passport, OtmId>;

@StoreConfig({ name: 'passport' })
class PassportStore extends EntityStore<PassportState> {}

class PassportQuery extends QueryEntity<PassportState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(store: EntityStore<PassportState>) {
        super(store);
    }
}
