changeLearningOpportunityModalService.$inject = ["modalService", "$translate", "localizedStringFilter"];
import angular from 'angular';
import * as _ from 'lodash-es';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { ChangeLearningOpportunityModalComponent } from './changeLearningOpportunityModal.component.ts';
import changeLearningOpportunityModalTpl from './changeLearningOpportunityModal.tpl.html';
angular.module('student.plan.changeLearningOpportunityModal', ['sis-common.l10n.localizedStringFilter', ModalService.downgrade.moduleName]).factory('ChangeLearningOpportunityModal', changeLearningOpportunityModalService).component('changeLearningOpportunityModal', {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  template: changeLearningOpportunityModalTpl
});
function changeLearningOpportunityModalService(modalService, $translate, localizedStringFilter) {
  return {
    open: (name, education, studyRight, learningOpportunityId) => modalService.open(ChangeLearningOpportunityModalComponent, {
      name,
      learningOpportunityOptions: _.map(education.structure.learningOpportunities, learningOpportunity => {
        const inStudyRight = studyRight && studyRight.learningOpportunityId === learningOpportunity.localId;
        const labelSuffix = inStudyRight ? ` (${$translate.instant('PLAN.CHANGE_LEARNING_OPPORTUNITY_MODAL.BELONGS_TO_STUDY_RIGHT')})` : '';
        return {
          value: learningOpportunity.localId,
          label: localizedStringFilter(learningOpportunity.name) + labelSuffix
        };
      }),
      learningOpportunityId
    }).result
  };
}