import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationAttachments, Attachment } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { from, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { ApplicationAttachmentEntityService } from '../service/application-attachment-entity.service';

import { FileUploadInfoModalService } from './file-upload-info-modal.service';
import { FileItem } from './file-upload.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class ApplicationAttachmentUploadService implements OnDestroy {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sisComponents.service.ApplicationAttachmentUploadService',
        serviceName: 'applicationAttachmentUploadService',
    };

    destroyed$ = new Subject<void>();
    harmfulFiles: any = [];
    mimeTypesWithExtensions: Map<string, string[]> = new Map([
        ['application/pdf', ['.pdf']],
        ['text/plain', ['.txt']],
        ['application/msword', ['.doc']],
        ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', ['.docx']],
        ['image/png', ['.png']],
        ['image/jpeg', ['.jpeg', '.jpg']],
    ]);

    constructor(private appErrorHandler: AppErrorHandler,
                private translate: TranslateService,
                private applicationAttachmentEntityService: ApplicationAttachmentEntityService,
                private fileUploadInfoModalService: FileUploadInfoModalService) {
    }

    uploadApplicationAttachments(applicationId: any, addedFiles: any, profileId: string, metadata?: any, attachments?: Attachment[]) {
        if (!applicationId || !profileId) { // No need to create ApplicationAttachments
            return new Promise((resolve) => {
                resolve(true);
            });
        }
        const applicationAttachments = this.createApplicationAttachments(addedFiles, applicationId, profileId, metadata, attachments);
        return new Promise((resolve) => {
            this.applicationAttachmentEntityService
                .uploadFiles(applicationAttachments, _.map(addedFiles, file => file.file))
                .pipe(
                    takeUntil(this.destroyed$),
                    this.appErrorHandler.defaultErrorHandler(),
                )
                .subscribe({
                    next: (response: [ApplicationAttachments]) => {
                        this.harmfulFiles = _.filter(_.head(response)?.attachments, attachment => attachment.status === 'VERIFICATION_FAILED');
                        this.confirmRemoveOfHarmfulFiles(this.harmfulFiles);
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(`Failed to upload. Error: ${err}`);
                        resolve(true);
                    },
                    complete: () => {
                        resolve(true);
                    },
                });
        });
    }

    uploadApplicationAttachmentsObservable(applicationId: any, addedFiles: any, profileId: string, metadata?: any, attachments?: Attachment[]): Observable<[ApplicationAttachments]> {
        if (!applicationId || !profileId) { // No need to create ApplicationAttachments
            return from([]);
        }
        const applicationAttachments = this.createApplicationAttachments(addedFiles, applicationId, profileId, metadata, attachments);
        return this.applicationAttachmentEntityService
            .uploadFiles(applicationAttachments, _.map(addedFiles, file => file.file))
            .pipe(
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
                tap((response: [ApplicationAttachments]) => {
                    this.harmfulFiles = _.filter(_.head(response)?.attachments, attachment => attachment.status === 'VERIFICATION_FAILED');
                    this.confirmRemoveOfHarmfulFiles(this.harmfulFiles);
                }),
            );
    }

    createApplicationAttachments(addedFiles: [FileItem], applicationId: any, profileId: string, metadata?: any, attachments?: Attachment[]): any {
        return {
            applicationId,
            metadata,
            personId: profileId,
            attachments: this.createAttachments(addedFiles, attachments),
            minScannedOn: null,
        };
    }

    createAttachments(fileItems: [FileItem], attachments: Attachment[]) {
        const attachmentList: any = attachments ? [...attachments] : [];
        if (fileItems && fileItems.length > 0) {
            Array.from(fileItems).forEach((fileItem) => {
                attachmentList.push({
                    name: fileItem.name,
                    comment: fileItem.explanation,
                    fileType: fileItem.file.type ? fileItem.file.type : this.determineFileType(fileItem.file),
                    size: fileItem.file.size,
                });
            });
        }
        return attachmentList;
    }

    determineFileType(file: File) {
        const filePath = `.${_.toLower(_.last(_.split(file.name, '.')))}`;
        return [...this.mimeTypesWithExtensions.entries()]
            .filter(({ 1: v }) => _.includes(v, filePath))
            .map(([k]) => k)[0];
    }

    confirmRemoveOfHarmfulFiles = (harmfulFiles: any) => {
        if (harmfulFiles.length > 0) {
            this.fileUploadInfoModalService.confirm({
                title: this.translate.instant('FILE_UPLOAD.HARMFUL_FILE_TITLE'),
                confirmText: this.translate.instant('BUTTON.OK'),
                filesWithVirus: harmfulFiles,
                hideCancel: true,
            }).then(() => true).catch(() => {});
        }
    };

    ngOnDestroy(): void {
        this.destroyed$.next();
    }
}
