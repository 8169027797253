PlanSelectorModalCtrl.$inject = ["$scope"];
planSelectorService.$inject = ["modalService", "commonPlanService"];
import angular from 'angular';
import * as _ from 'lodash-es';
import { ModalService } from 'sis-common/modal/modal.service.ts';
import { PlanSelectorConfig } from './planSelectorConfig.ts';
import { LegacyPlanSelectorModalComponent } from './planSelectorModal.component.ts';
import planSelectorModalTpl from './planSelectorModal.tpl.html';
angular.module('sis-components.plan.planSelectorService', ['sis-components.service.planService', 'sis-components.plan.planSelector', ModalService.downgrade.moduleName]).constant('SELECTION_TYPES', {
  MODULE: 'module',
  COURSE_UNIT: 'courseUnit',
  CUSTOM_STUDY_DRAFT: 'customStudyDraft',
  ANY_COURSE_UNIT: 'anyCourseUnit',
  ANY_MODULE: 'anyModule'
}).service('planSelectorService', planSelectorService).component('planSelectorModal', {
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  },
  controller: PlanSelectorModalCtrl,
  template: planSelectorModalTpl
});
function planSelectorService(modalService, commonPlanService) {
  return {
    openPlanSelectorModal: (title, description, validatablePlan, planSelectorConfig) => commonPlanService.findById(_.get(validatablePlan, 'plan.id')).then(plan => {
      plan.cacheModulesAndCourseUnits();
      return plan;
    }).then(plan => modalService.open(LegacyPlanSelectorModalComponent, {
      description,
      plan,
      planSelectorConfig: planSelectorConfig || getDefaultConfig(),
      title,
      validatablePlan
    }).result)
  };
  function getDefaultConfig() {
    const config = new PlanSelectorConfig();
    config.allowModuleSelection = true;
    config.allowCourseUnitSelection = true;
    config.allowAnyCourseUnitRuleSelection = false;
    config.allowAnyModuleRuleSelection = false;
    return config;
  }
}
function PlanSelectorModalCtrl($scope) {
  const $ctrl = this;
  $ctrl.selection = undefined;
  $ctrl.changeSelection = selection => {
    $scope.$applyAsync(() => {
      $ctrl.selection = selection;
    });
  };
  $ctrl.ok = () => {
    $ctrl.close({
      $value: $ctrl.selection
    });
  };
}