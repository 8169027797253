import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SisValidationError, SisValidationErrors } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A simple presentational component for displaying form validation errors. The validation errors can be provided in three
 * different ways:
 *
 * - Provide the form control object whose validation errors to display using the `control` input property. This is the
 * recommended, simple way. This component will only be shown if the given control has validation errors, and the control
 * is either touched or dirty (which is the default behavior for showing validation errors in Angular).
 * - Provide a `SisValidationErrors` object using the `validationErrors` input property. The component will be shown
 * if the object contains validation errors.
 * - Provide a list of custom validation errors using content projection. Each projected `<p>` element will be shown as
 * a validation error. However, this is not recommended, but the other two methods should be used instead.
 *
 * When using the `control` and/or `validationErrors` input properties, do note that only errors of type `SisValidationError`
 * will be shown, as they contain a translation key for the error message to show. If the given control object has built-it
 * Angular validators defined for it, the errors from those validators will not be shown (as they do not contain an error
 * message to show, but only the name of the validator). Instead of using the built-in Angular form validators, use the ones
 * defined in `SisFormBuilder` (which contains wrappers for the built-in validators).
 */
@Component({
    selector: 'sis-validation-errors',
    templateUrl: './validation-errors.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ValidationErrorsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() control: AbstractControl;
    @Input() validationErrors: SisValidationErrors;
    @Input() asFormGroup = false;
    /**
     * REQUIRED FIELD. For accessibility reasons, formFieldId is required to link error message to a related input field.
     */
    @Input() formFieldId: string;
    /**
     * Aria Live region default behaviour is set to true which equals aria live assertive.
     * TODO: When all of the validation-errors have been linked with formFieldId to their related input field this default will be set to false.
     */
    @Input() ariaLive = true;

    validationErrors$: BehaviorSubject<{
        errors: SisValidationError[],
        hasChildErrors: boolean
    }> = new BehaviorSubject({ errors: [], hasChildErrors: false });

    destroyed$ = new Subject<void>();

    ngOnInit(): void {
        if (this.control) {
            this.updateErrors(this.control.errors, this.validationErrors);
            // Just listening to valueChanges does not work if async validator is used.
            merge(this.control.valueChanges, this.control.statusChanges)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(() => this.updateErrors(this.control.errors, this.validationErrors));
        }

        if (!this.formFieldId) {
            console.warn(
                'sis-validation-error is missing formFieldId for related input',
            );
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.validationErrors) {
            this.updateErrors(_.get(this.control, 'errors'), changes.validationErrors.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.validationErrors$.complete();
    }

    validateFormGroupChildErrors(): boolean {
        if (this.asFormGroup) {
            const ctrlFormGroup = this.control as FormGroup;
            return Object.keys(ctrlFormGroup.controls)
                .some(key => ctrlFormGroup.get(key).errors);
        }
        return false;
    }

    private updateErrors(...errorObjects: SisValidationErrors[]): void {
        const hasChildErrors = this.validateFormGroupChildErrors();
        const errors = _.uniq((errorObjects || [])
            .filter(Boolean)
            .flatMap(errorObject => Object.values(errorObject).filter(error => !_.isEmpty(error.translationKey))));
        this.validationErrors$.next({ errors, hasChildErrors });
    }
}
