import angular from 'angular';
import * as _ from 'lodash-es';
export const ruleServiceModule = 'sis-components.service.ruleService';
(function () {
  angular.module('sis-components.service.ruleService', []).factory('ruleService', ruleService);

  /**
   * @ngInject
   */
  function ruleService() {
    function getParentRuleRecursively(rootRule, childRule) {
      if (!_.isEmpty(_.get(rootRule, 'rules'))) {
        var matchingRule = _.find(rootRule.rules, function (rule) {
          return childRule.localId === rule.localId;
        });
        if (matchingRule) {
          return rootRule;
        } else {
          matchingRule = null;
          _.forEach(rootRule.rules, function (rule) {
            matchingRule = getParentRuleRecursively(rule, childRule);
            if (matchingRule) {
              return false;
            }
          });
          if (matchingRule) {
            return matchingRule;
          }
        }
      }
      var rule = _.get(rootRule, 'rule');
      if (rule && rule.localId === childRule.localId) {
        return rootRule;
      } else if (rule) {
        return getParentRuleRecursively(rule, childRule);
      }
      return null;
    }
    function getNewRuleStudyContext() {
      return {
        modules: [],
        courseUnits: [],
        customModuleAttainments: [],
        customCourseUnitAttainments: []
      };
    }
    function mergeRuleStudyContext(target, other) {
      target.modules = _.concat(target.modules, other.modules);
      target.courseUnits = _.concat(target.courseUnits, other.courseUnits);
      target.customModuleAttainments = _.concat(target.customModuleAttainments, other.customModuleAttainments);
      target.customCourseUnitAttainments = _.concat(target.customCourseUnitAttainments, other.customCourseUnitAttainments);
    }

    // rules have to be processed in right order, any*Rules last! this probably should apply across different levels of rules also.

    var api = {
      getParentRule: function (rootRule, childRule) {
        return getParentRuleRecursively(rootRule, childRule);
      },
      isSelectOneRule: function (rule) {
        if (!rule) {
          return false;
        }
        return rule.type === 'CompositeRule' && rule.rules.length > 1 && rule.require && rule.require.max === 1;
      },
      isSelectAllRule: function (rule) {
        if (!rule) {
          return false;
        }
        return rule.type === 'CompositeRule' && (rule.allMandatory === true || _.isNil(rule.require) || rule.require.min === rule.rules.length);
      },
      getMatchingStudiesByRule: function (rule, module, validatablePlan) {
        var unmatchedStudies = {
          modulesByGroupId: _.keyBy(validatablePlan.getSelectedModulesById(module), 'groupId'),
          courseUnitsByGroupId: _.keyBy(validatablePlan.getSelectedCourseUnitsById(module), 'groupId'),
          customModuleAttainments: validatablePlan.getSelectedCustomModuleAttainmentsById(module),
          customCourseUnitAttainments: validatablePlan.getSelectedCustomCourseUnitAttainmentsById(module)
        };
        var ruleStudyContext = getNewRuleStudyContext();
        var ruleContents = {};
        api.processStudiesByRule(rule, module, validatablePlan, unmatchedStudies, ruleContents, ruleStudyContext);
        return ruleContents;
      },
      isRuleObligatory: function (rootRule, subRule) {
        var rulePath = api.getRulePathFromRootToSubRule(rootRule, subRule);
        var allCompositeRules = _.filter(rulePath, {
          type: 'CompositeRule'
        });
        return !_.some(allCompositeRules, function (compositeRule) {
          return !api.isSelectAllRule(compositeRule);
        });
      },
      // isImplicitCourseUnitRuleObligatory determines whether courseUnitRule is considered obligatory
      // in the context of moving implicitly selected courseUnits in plan. courseUnitRule is obligatory
      // in this context if all compositeRules above it in the hierarchy are either 1) selectAll rules or
      // 2) compositeRules with only one subRule and with parentRule that is CreditsRule with credits.min > 0.

      isImplicitCourseUnitRuleObligatory: function (rootRule, subRule) {
        const rulePath = api.getRulePathFromRootToSubRule(rootRule, subRule);
        return _.every(rulePath, rule => {
          if (rule.type !== 'CompositeRule') {
            return true;
          }
          if (api.isSelectAllRule(rule)) {
            return true;
          }
          const parentRule = api.getParentRule(rootRule, rule);
          if (parentRule && parentRule.type === 'CreditsRule' && _.get(parentRule, 'credits.min') > 0 && _.size(rule.rules) === 1) {
            return true;
          }
          return false;
        });
      },
      getRulePathFromRootToSubRule: function (ruleOfParentModule, subRule) {
        var rulePath = [];
        rulePath.push(subRule);
        var rule = subRule;
        var higherRule = api.getParentRule(ruleOfParentModule, rule);
        while (higherRule) {
          rulePath.push(higherRule);
          rule = higherRule;
          higherRule = api.getParentRule(ruleOfParentModule, rule);
        }
        return _.reverse(rulePath);
      },
      allRules: function (rule) {
        var result = [];
        api.allRulesRecursively(rule, result);
        return result;
      },
      allRulesRecursively: function (rule, result) {
        result.push(rule);
        var subRule = _.get(rule, 'rule');
        if (subRule) {
          api.allRulesRecursively(subRule, result);
        }
        var subRules = _.get(rule, 'rules');
        if (subRules) {
          _.forEach(subRules, function (subRule) {
            api.allRulesRecursively(subRule, result);
          });
        }
      },
      getMatchingCourseUnitRule: (rootRule, courseUnitGroupId) => {
        const allRules = api.allRules(rootRule);
        return _.find(allRules, {
          type: 'CourseUnitRule',
          courseUnitGroupId: courseUnitGroupId
        });
      },
      getMatchingModuleRule: (rootRule, moduleGroupId) => {
        const allRules = api.allRules(rootRule);
        return _.find(allRules, {
          type: 'ModuleRule',
          moduleGroupId: moduleGroupId
        });
      },
      isRequiredCourseUnit: (parentModule, courseUnit) => {
        const ruleOfParentModule = _.get(parentModule, 'rule');
        const courseUnitGroupId = _.get(courseUnit, 'groupId');
        if (!ruleOfParentModule || !courseUnitGroupId) {
          return false;
        }
        const matchingCourseUnitRule = api.getMatchingCourseUnitRule(ruleOfParentModule, courseUnitGroupId);
        if (!matchingCourseUnitRule) {
          return false;
        }
        return api.isRuleObligatory(ruleOfParentModule, matchingCourseUnitRule);
      },
      isRequiredModule: (parentModule, module) => {
        const ruleOfParentModule = _.get(parentModule, 'rule');
        const moduleGroupId = _.get(module, 'groupId');
        if (!ruleOfParentModule || !moduleGroupId) {
          return false;
        }
        const matchingModuleRule = api.getMatchingModuleRule(ruleOfParentModule, moduleGroupId);
        if (!matchingModuleRule) {
          return false;
        }
        return api.isRuleObligatory(ruleOfParentModule, matchingModuleRule);
      },
      processStudiesByRule: function (rule, module, validatablePlan, unmatchedStudies, ruleContents, parentRuleStudyContext) {
        var ruleStudyContext = getNewRuleStudyContext();
        switch (rule.type) {
          case 'CompositeRule':
            var sortedRules = _.sortBy(rule.rules, function (subRule) {
              return _.includes(['AnyModuleRule', 'AnyCourseUnitRule'], subRule.type) ? 100 : 0;
            });
            _.forEach(sortedRules, function (subRule) {
              api.processStudiesByRule(subRule, module, validatablePlan, unmatchedStudies, ruleContents, ruleStudyContext);
            });
            _.set(ruleContents, rule.localId, ruleStudyContext);
            mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            break;
          case 'CourseUnitRule':
            var plannedCourseUnit = validatablePlan.getCourseUnitInPlanByGroupId(rule.courseUnitGroupId);
            if (plannedCourseUnit) {
              _.unset(unmatchedStudies.courseUnitsByGroupId, rule.courseUnitGroupId);
              ruleStudyContext.courseUnits.push(plannedCourseUnit);
            }
            _.set(ruleContents, rule.localId, ruleStudyContext);
            mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            break;
          case 'ModuleRule':
            var plannedModule = validatablePlan.getModuleInPlanByGroupId(rule.moduleGroupId);
            if (plannedModule) {
              _.unset(unmatchedStudies.modulesByGroupId, rule.moduleGroupId);
              ruleStudyContext.modules.push(plannedModule);
            }
            _.set(ruleContents, rule.localId, ruleStudyContext);
            mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            break;
          case 'AnyCourseUnitRule':
            _.forEach(unmatchedStudies.courseUnitsByGroupId, function (plannedCourseUnit) {
              _.unset(unmatchedStudies.courseUnitsByGroupId, plannedCourseUnit.groupId);
              ruleStudyContext.courseUnits.push(plannedCourseUnit);
            });
            _.remove(unmatchedStudies.customCourseUnitAttainments, function (plannedCustomCourseUnitAttainment) {
              ruleStudyContext.customCourseUnitAttainments.push(plannedCustomCourseUnitAttainment);
              return true;
            });
            _.set(ruleContents, rule.localId, ruleStudyContext);
            mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            break;
          case 'AnyModuleRule':
            _.forEach(unmatchedStudies.modulesByGroupId, function (plannedModule) {
              _.unset(unmatchedStudies.modulesByGroupId, plannedModule.groupId);
              ruleStudyContext.modules.push(plannedModule);
            });
            _.remove(unmatchedStudies.customModuleAttainments, function (plannedCustomModuleAttainment) {
              ruleStudyContext.customModuleAttainments.push(plannedCustomModuleAttainment);
              return true;
            });
            _.set(ruleContents, rule.localId, ruleStudyContext);
            mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            break;
          default:
            var subRule = _.get(rule, 'rule');
            if (subRule) {
              api.processStudiesByRule(subRule, module, validatablePlan, unmatchedStudies, ruleContents, ruleStudyContext);
              _.set(ruleContents, rule.localId, ruleStudyContext);
              mergeRuleStudyContext(parentRuleStudyContext, ruleStudyContext);
            }
            break;
        }
      }
    };
    return api;
  }
})();