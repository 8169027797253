import angular from 'angular';
import * as _ from 'lodash-es';
import creditsRuleTpl from './creditsRule.tpl.html';
(function () {
  CreditsRuleController.$inject = ["RangeValidationResult", "Range", "uiContext", "$log"];
  angular.module('student.plan.rules').component('creditsRule', {
    template: creditsRuleTpl,
    bindings: {
      module: '<',
      rule: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      studyRight: '<',
      openCustomStudyDraftInfoModalCallback: '&'
    },
    controller: CreditsRuleController
  });
  function CreditsRuleController(RangeValidationResult, Range, uiContext, $log) {
    const ctrl = this;
    ctrl.isOK = isOK;
    ctrl.getTargetRange = getTargetRange;
    ctrl.getPlannedCredits = getPlannedCredits;
    ctrl.$onChanges = () => {
      ctrl.moduleType = ctrl.module.type;
      ctrl.targetCredits = getTargetCredits();
      ctrl.ruleValidationResult = uiContext.planContext.getRuleValidationResult(ctrl.module.id, ctrl.rule.localId);
    };
    ctrl.openCustomStudyDraftInfo = customStudyDraft => {
      ctrl.openCustomStudyDraftInfoModalCallback({
        customStudyDraft
      });
    };
    function isOK() {
      if (ctrl.ruleValidationResult) {
        return ctrl.ruleValidationResult.result === RangeValidationResult.OK;
      }
      return false;
    }
    function getTargetRange() {
      if (ctrl.ruleValidationResult) {
        return new Range(ctrl.ruleValidationResult.minRequired, ctrl.ruleValidationResult.maxAllowed);
      }
      return null;
    }
    function getTargetCredits() {
      if (_.has(ctrl.module, 'targetCredits')) {
        const credits = _.result(ctrl.module, 'targetCredits');
        return new Range(credits.min, credits.max);
      }
      return null;
    }
    function getPlannedCredits() {
      if (ctrl.ruleValidationResult) {
        return ctrl.ruleValidationResult.plannedCredits;
      }
      $log.warn('no validationResults available for rule ', ctrl.rule);
      return new Range(0, 0);
    }
  }
})();