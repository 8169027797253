import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PlanMessage } from 'common-typescript/types';

@Component({
    selector: 'sis-module-content-application-message',
    templateUrl: './module-content-application-message.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModuleContentApplicationMessageComponent {
    @Input({ required: true }) message: PlanMessage;
}
