import { Component, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-skip-link',
    templateUrl: './skip-link.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SkipLinkComponent {

    static readonly SKIP_LINK_TARGET_ID: string = 'skip-link-target';

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.skipLink',
        directiveName: 'sisSkipLink',
    };

    moveToMain(): void {

        try {
            const target = document.getElementById(SkipLinkComponent.SKIP_LINK_TARGET_ID);

            // On few page templates linting fails because of htmlhint rule 'id-unique: true', even though non-unique ids are under separate ng-ifs.
            // This here below is just a workaround unless we find a way to disable htmlhint on specific files and lines. This happens in authSelectionPage.tpl.html and message.tpl.html
            if (!target) {
                const alternativeTarget = document.querySelector('h1');
                alternativeTarget.focus();
                return;
            }

            target.focus();
        } catch (error) {
            console.warn(error);
            console.warn('Could not find and focus on skip-link-target. Please check that the skip-link target element (usually the h1 title) has attributes id="skip-link-target" and tabindex="-1"');
        }

    }

    handleFocus(): void {
        document.body.classList.add('skip-link-active');
    }

    onBlur(): void {
        document.body.classList.remove('skip-link-active');
    }
}
