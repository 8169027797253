<ng-container *transloco="let t">
  @if (data$ | async; as data) {
    @if (data.allStudentPlans?.length > 0) {
      <div class="course-units-not-in-calendar">
        <app-choose-plan [plans]="data.allStudentPlans"
                         [planEducations]="data.planEducations"
                         [selectedPlanId]="data.selectedValidatablePlan.plan.id"
                         (selected)="onPlanChange($event)">
        </app-choose-plan>
        @for (item of data.notScheduledCourseUnitsByPeriod | keyvalue; track item.key) {
          @let periodId = item.key;
          @let courseUnits = item.value;
          <h2 class="fudis-heading__xs period">
            @if (periodId != 'undefined') {
              @let periodName = data.studyPeriodsByLocator[periodId]?.name;
              @let studyYearName = data.studyYearsByStartYear[parseStudyPeriodLocator(periodId)?.year]?.name;
              {{ periodName | localizedString }} / {{ studyYearName }}
            } @else {
              {{ t('NO_PERIOD') }}
            }
          </h2>
          <fudis-grid [columns]="1" [rowGap]="'none'">
            @for (courseUnit of courseUnits; track courseUnit.id) {
              <sis-course-unit-box [courseUnit]="courseUnit"
                                   (codeClickAction)="onOpenCourseUnitModal(courseUnit, data.selectedValidatablePlan)"
                                   [categoryCssClass]="data.courseUnitColorCategoriesById[courseUnit.id]
                ? 'cu-color-' + data.courseUnitColorCategoriesById[courseUnit.id]
                : undefined">
                <!-- TODO: Replace this enriched property -->
                @if ($any(courseUnit).$hasTeaching) {
                  <div course-unit-box="right-of-title" class="additional-box">
                    <sis-button class="button-box sis-mr-xs"
                                (clicked)="onOpenCourseUnitModal(courseUnit, data.selectedValidatablePlan)"
                                [variant]="'primary'"
                                [size]="'sm'"
                                [label]="t('CALENDAR_CHOOSE_TEACHING')">
                    </sis-button>
                  </div>
                }
              </sis-course-unit-box>
            }
          </fudis-grid>
        }
      </div>
    } @else {
      <sis-contextual-notification variant="warning">
        {{ t('CALENDAR_NO_PLANS') }}
        <a [uiSref]="'student.logged-in.planSelect'">{{ t('CALENDAR_NO_PLANS_LINK') }}</a>
      </sis-contextual-notification>
    }
  }
</ng-container>
