import * as _ from 'lodash-es';

export const numberUtils = {
    /**
     * Returns the given number as a string. If the input is a decimal number, rounds the output to the specified amount of
     * decimal places, and uses the given character as the decimal separator. Returns null if the input is not a number;
     */
    numberToString(input: number, precision = 2, decimalSeparator = ','): string | null {
        return _.isFinite(input) ? _.round(input, precision).toString().replace('.', decimalSeparator) : null;
    },

    /**
     * Creates an array of the specified length, filled with sequential integers. By default the range starts at 0;
     * this can be controlled with `startAt`.
     *
     * @param length The length of the returned array
     * @param startAt The first integer of the range
     */
    range(length: number, startAt = 0): ReadonlyArray<number> {
        return length > 0 ? Array.from({ length }, (__, i) => i + startAt) : [];
    },

    readableFileSizeString(bytes: number, numberOfDecimals = 1, decimalSeparator = ',') {
        const i = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1000));
        // eslint-disable-next-line no-restricted-properties
        return `${(bytes / 1000 ** i).toFixed(numberOfDecimals).replace('.', decimalSeparator)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
};
