import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

export type InputType = 'radio-button' | 'checkbox';

export type Action = 'place-to-plan' | 'select-to-plan' | 'remove-from-plan' | 'remove-selection';

@Component({
    selector: 'sis-study-action-box',
    templateUrl: './study-action-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyActionBoxComponent {

    @Input() action: Action;
    @Input() selected?: boolean;
    @Input() studyInfo: string;
    @Input() inputType: InputType;
    @Input() actionInputId: string;
    @Input() actionInputName: string;
    @Output() actionChange = new EventEmitter<void>();

    toggleSelected(): void {
        this.selected = !this.selected;
        this.actionChange.emit();
    }

}
