import angular from 'angular';
import * as _ from 'lodash-es';
import { PlanValidationTs } from 'common-typescript';
import { planValidationResultHelperModule } from 'sis-components/service/planValidationResultHelper.service';
import { commonModuleServiceModule } from 'sis-components/service/module.service';
(function () {
  printService.$inject = ["$q", "commonStudyPeriodService", "planValidationResultHelper", "commonModuleService"];
  angular.module('sis-components.printPlan.printService', ['sis-components.service.studyPeriodService', commonModuleServiceModule, planValidationResultHelperModule]).service('printService', printService);
  function printService($q, commonStudyPeriodService, planValidationResultHelper, commonModuleService) {
    var rows = [];
    var periodMap = {};
    function findPeriods(validatablePlan) {
      var locators = traverseModulesForLocators(validatablePlan, validatablePlan.rootModule);
      var promises = _.map(locators, function (locator) {
        if (!periodMap[locator]) {
          return $q.when(commonStudyPeriodService.getPeriodsFor(locator)).then(function (period) {
            periodMap[locator] = period;
          });
        }
      });
      return $q.all(promises);
    }
    function courseUnitToRowStructure(validatablePlan, planValidationResult, courseUnit, indent) {
      var courseUnitSubstitutedBy = validatablePlan.getSubstitutedBy(courseUnit);
      var substitutingCourseUnits = _.map(courseUnitSubstitutedBy, function (courseUnitId) {
        return _.get(validatablePlan.courseUnitsById, courseUnitId);
      });
      if (substitutingCourseUnits.length > 0) {
        _.forEach(substitutingCourseUnits, function (substitute) {
          rows.push(substituteCourseUnitStructureFrom(validatablePlan, planValidationResult, substitute, indent));
        });
      } else {
        rows.push(courseUnitStructureFrom(validatablePlan, planValidationResult, courseUnit, indent));
      }
    }
    function traversePlanStructure(validatablePlan, planValidationResult, module, indent) {
      indent = indent || 0;
      var selectedCourseUnits = validatablePlan.getSelectedCourseUnitsById(module);
      var selectedModules = validatablePlan.getSelectedModulesById(module);
      if (module.id === validatablePlan.rootModule.id && _.every(selectedModules, {
        type: 'DegreeProgramme'
      })) {
        selectedModules = commonModuleService.getDegreeProgrammesOrderedByTypeUrn(selectedModules);
      }
      var customCourseUnitAttainments = validatablePlan.getSelectedCustomCourseUnitAttainmentsById(module);
      var customModuleAttainments = validatablePlan.getSelectedCustomModuleAttainmentsById(module);
      _.forEach(selectedCourseUnits, function (courseUnit) {
        courseUnitToRowStructure(validatablePlan, planValidationResult, courseUnit, indent);
      });
      _.forEach(customCourseUnitAttainments, function (attainment) {
        rows.push(customCourseUnitAttainmentStructureFrom(attainment, indent));
      });
      _.forEach(selectedModules, function (loopedModule) {
        var moduleAttainment = validatablePlan.getModuleAttainment(loopedModule.id);
        if (moduleAttainment) {
          rows.push(moduleAttainmentStructureFrom(loopedModule, moduleAttainment, indent));
          traverseAttainmentStructure(validatablePlan, planValidationResult, moduleAttainment.nodes, indent + 1);
        } else {
          rows.push(moduleStructureFrom(validatablePlan, planValidationResult, loopedModule, indent));
          traversePlanStructure(validatablePlan, planValidationResult, loopedModule, indent + 1);
        }
      });
      _.forEach(customModuleAttainments, function (moduleAttainment) {
        rows.push(customModuleAttainmentStructureFrom(moduleAttainment, indent));
        traverseAttainmentStructure(validatablePlan, planValidationResult, moduleAttainment.nodes, indent + 1);
      });
      return rows;
    }

    /**
     * This function traverses the tree with the help of the 'nodes' property that lists the IDs of
     * each attainment (i.e. module or course unit) that is a child of a custom module attainment.
     */
    function traverseAttainmentStructure(validatablePlan, planValidationResult, attainmentNodes, indent) {
      _.forEach(attainmentNodes, function (node) {
        if (node.type === 'AttainmentGroupNode') {
          rows.push(attainmentGroupNodeStructureFrom(node, indent));
          traverseAttainmentStructure(validatablePlan, planValidationResult, node.nodes, indent + 1);
          return;
        }
        var attainmentId = _.get(node, 'attainmentId');
        var attainment = _.get(validatablePlan.getAllAttainments(), attainmentId);
        if (attainment) {
          if (attainment.type === 'CustomCourseUnitAttainment') {
            rows.push(customCourseUnitAttainmentStructureFrom(attainment, indent));
          } else if (attainment.type === 'CourseUnitAttainment') {
            var courseUnit = _.get(validatablePlan.courseUnitsById, attainment.courseUnitId);
            rows.push(courseUnitAttainmentStructureFrom(courseUnit, attainment, indent));
          } else if (attainment.type === 'CustomModuleAttainment') {
            rows.push(customModuleAttainmentStructureFrom(attainment, indent));
            traverseAttainmentStructure(validatablePlan, planValidationResult, attainment.nodes, indent + 1);
          } else if (attainment.type === 'ModuleAttainment' || attainment.type === 'DegreeProgrammeAttainment') {
            var module = _.get(validatablePlan.modulesById, attainment.moduleId);
            rows.push(moduleAttainmentStructureFrom(module, attainment, indent));
            traverseAttainmentStructure(validatablePlan, planValidationResult, attainment.nodes, indent + 1);
          }
        }
      });
    }
    function traverseModulesForLocators(validatablePlan, parent) {
      var locators = [];
      _.forEach(validatablePlan.getSelectedCourseUnitsById(parent), function (courseUnit) {
        var plannedPeriods = validatablePlan.getPlannedPeriods(courseUnit);
        if (plannedPeriods) {
          locators.push(plannedPeriods);
        }
        var courseUnitSubstitutedBy = validatablePlan.getSubstitutedBy(courseUnit);
        var substitutingCourseUnits = _.map(courseUnitSubstitutedBy, function (courseUnitId) {
          return _.get(validatablePlan.courseUnitsById, courseUnitId);
        });
        _.forEach(substitutingCourseUnits, function (cu) {
          var plannedPeriods = validatablePlan.getPlannedPeriods(cu);
          if (plannedPeriods) {
            locators.push(plannedPeriods);
          }
        });
      });
      _.forEach(validatablePlan.getSelectedModulesById(parent), function (module) {
        locators.push(traverseModulesForLocators(validatablePlan, module));
      });
      return _.uniq(_.sortBy(_.flattenDeep(locators)));
    }
    function courseUnitStructureFrom(validatablePlan, planValidationResult, courseUnit, indent) {
      var courseUnitAttainment = getCourseUnitAttainment(validatablePlan, courseUnit);
      if (courseUnitAttainment) {
        return courseUnitAttainmentStructureFrom(courseUnit, courseUnitAttainment, indent);
      }
      var attainedCredits = getCourseUnitAttainedCredits(planValidationResult, courseUnit);
      return {
        id: courseUnit.id,
        code: courseUnit.code,
        name: courseUnit.name,
        attainedCredits: attainedCredits,
        isAttained: attainedCredits > 0,
        indent: indent ? indent : 0,
        plannedCredits: getPlannedCourseUnitCredits(validatablePlan, planValidationResult, courseUnit),
        targetCredits: getCUTargetCredits(courseUnit),
        planned: getAllPlannedPeriods(validatablePlan, courseUnit)
      };
    }
    function moduleStructureFrom(validatablePlan, planValidationResult, module, indent) {
      var moduleAttainment = getModuleAttainment(validatablePlan, module);
      if (moduleAttainment) {
        return moduleAttainmentStructureFrom(module, moduleAttainment, indent);
      }
      return {
        id: module.id,
        code: module.code,
        name: module.name,
        isAttained: getModuleAttainedCredits(planValidationResult, module),
        isGroupingModule: module.type === 'GroupingModule',
        indent: indent ? indent : 0,
        plannedCredits: getPlannedModuleCredits(planValidationResult, module),
        targetCredits: getTargetCredits(module),
        attainedCredits: getModuleAttainedCredits(planValidationResult, module),
        planned: [],
        state: PlanValidationTs.getPlanStateForModule(module, validatablePlan, planValidationResult)
      };
    }
    function courseUnitAttainmentStructureFrom(courseUnit, attainment, indent) {
      return {
        id: attainment.id,
        code: courseUnit.code,
        name: courseUnit.name,
        indent: indent ? indent : 0,
        isAttained: true,
        attainment: attainment,
        attainmentDate: attainment.attainmentDate,
        targetCredits: attainment.credits,
        attainedCredits: attainment.credits,
        planned: []
      };
    }
    function moduleAttainmentStructureFrom(module, attainment, indent) {
      return {
        id: attainment.id,
        code: module.code,
        name: module.name,
        indent: indent ? indent : 0,
        isAttained: true,
        attainment: attainment,
        attainmentDate: attainment.attainmentDate,
        targetCredits: attainment.credits,
        attainedCredits: attainment.credits,
        state: 'ATTAINED',
        planned: []
      };
    }
    function attainmentGroupNodeStructureFrom(attainmentNode, indent) {
      return {
        id: '',
        code: '',
        name: attainmentNode.name,
        indent: indent ? indent : 0,
        isGroupingModule: true
      };
    }
    function customCourseUnitAttainmentStructureFrom(attainment, indent) {
      return {
        id: attainment.id,
        code: attainment.code,
        name: attainment.name,
        indent: indent ? indent : 0,
        isAttained: true,
        attainment: attainment,
        attainmentDate: attainment.attainmentDate,
        targetCredits: attainment.credits,
        attainedCredits: attainment.credits,
        state: 'ATTAINED',
        planned: []
      };
    }
    function customModuleAttainmentStructureFrom(moduleAttainment, indent) {
      return {
        id: moduleAttainment.id,
        code: moduleAttainment.code,
        name: moduleAttainment.name,
        indent: indent ? indent : 0,
        isGroupingModule: false,
        isAttained: true,
        attainment: moduleAttainment,
        attainmentDate: moduleAttainment.attainmentDate,
        targetCredits: moduleAttainment.credits,
        attainedCredits: moduleAttainment.credits,
        state: 'ATTAINED',
        planned: []
      };
    }
    function getPeriods(locators) {
      var periods = [];
      _.forEach(locators, function (loc) {
        periods.push(periodMap[loc]);
      });
      return periods;
    }
    function getCourseUnitAttainment(validatablePlan, courseUnit) {
      return validatablePlan.getCourseUnitAttainment(courseUnit.id);
    }
    function getModuleAttainment(validatablePlan, module) {
      return validatablePlan.getModuleAttainment(module.id);
    }
    function getPlannedCourseUnitCredits(validatablePlan, planValidationResult, courseUnit) {
      const result = planValidationResultHelper.getCourseUnitValidationResult(_.get(courseUnit, 'id'), planValidationResult);
      return _.get(result, 'plannedCredits') || null;
    }
    function getPlannedModuleCredits(planValidationResult, module) {
      const result = planValidationResultHelper.getModuleValidationResult(_.get(module, 'id'), planValidationResult);
      return _.get(result, 'plannedCredits') || null;
    }
    function getModuleAttainedCredits(planValidationResult, module) {
      const result = planValidationResultHelper.getModuleValidationResult(_.get(module, 'id'), planValidationResult);
      if (result && result.attainedCredits && result.attainedCredits > 0) {
        return result.attainedCredits;
      }
      return null;
    }
    function getCourseUnitAttainedCredits(planValidationResult, courseUnit) {
      const result = planValidationResultHelper.getCourseUnitValidationResult(_.get(courseUnit, 'id'), planValidationResult);
      if (result && result.attainedCredits && result.attainedCredits > 0) {
        return result.attainedCredits;
      }
      return null;
    }
    function getCUTargetCredits(courseUnit) {
      return _.result(courseUnit, 'credits');
    }
    function getTargetCredits(module) {
      return _.result(module, 'targetCredits');
    }
    function getAllPlannedPeriods(validatablePlan, courseUnit) {
      var allPeriods = [];
      var plannedPeriods = validatablePlan.getPlannedPeriods(courseUnit);
      _.forEach(getPeriods(plannedPeriods), function (period) {
        if (!_.isNil(period)) {
          allPeriods.push(period);
        }
      });
      return allPeriods;
    }
    function substituteCourseUnitStructureFrom(validatablePlan, planValidationResult, courseUnit, indent) {
      var courseUnitRow = courseUnitStructureFrom(validatablePlan, planValidationResult, courseUnit, indent);
      if (!courseUnitRow.state) {
        courseUnitRow.state = 'SUBSTITUTE';
      }
      return courseUnitRow;
    }
    var api = {
      printablePlanStructure: function (validatablePlan, planValidationResult) {
        rows = [];
        periodMap = {};
        return findPeriods(validatablePlan).then(function () {
          return traversePlanStructure(validatablePlan, planValidationResult, validatablePlan.rootModule, 0);
        });
      },
      courseUnitStructureFrom: courseUnitStructureFrom,
      // exposed for testing
      customCourseUnitAttainmentStructureFrom: customCourseUnitAttainmentStructureFrom,
      // exposed for testing
      traverseModulesForLocators: traverseModulesForLocators // exposed for testing
    };
    return api;
  }
})();