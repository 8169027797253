<div *ngIf="totalItems > 0">
  <table [attr.aria-label]="'ARIA_LABEL.STUDENT_WORKFLOWS' | translate" class="table styled-table">
    <thead>
    <tr>
      @for (column of columns; track column) {
        @if (column === 'initiatorType') {
          <th class="sis-untouchable" [attr.aria-hidden]="true"></th>
        } @else if (isSortColumn(column)) {
          <th class="sis-untouchable">
            <sis-sortable-table-column-header [name]="('STUDENT_APPLICATIONS.' + column)"
                                              [column]="column"
                                              [(reverse)]="reverse"
                                              [orderKey]="sortColumn"
                                              (orderKeyChange)="setOrder(column)"
                                              ariaLabelType="ALPHABETICAL_ORDER">
            </sis-sortable-table-column-header>
          </th>
        } @else {
          <th class="sis-untouchable">
            {{('STUDENT_APPLICATIONS.' + column) | translate}}
          </th>
        }
      }
    </tr>
    </thead>
    <tbody>
    @for (workflow of studentWorkflowSearchResult.searchResults; track workflow.id) {
      <tr data-cy="workflow-search-result">
        @for (column of columns; track column) {
          @switch (column) {
            @case ('name') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                <a [uiSref]="workflowLinkCreator.state(workflow)"
                   [uiParams]="workflowLinkCreator.params(workflow)"
                   [uiOptions]="workflowLinkCreator.options"
                   data-cy="workflow-name-link">
                  <span class="student-workflow-type">{{('STUDENT_APPLICATIONS.TYPE.' + workflow.type) | translate}}:</span>
                  <span>{{ $any(workflow).name || (workflow | workflowRelatedStudy | async | entityName | localizedString) || '-' }}</span>
                </a>
                @if (workflow.initiatorType === 'STAFF') {
                  <span class="visually-hidden">
                    <ng-container [ngTemplateOutlet]="initiatorText"
                                  [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
                  </span>
                }
              </td>
            }
            @case ('initiatorType') {
              <td [attr.aria-hidden]="true">
                @if (workflow.initiatorType === 'STAFF') {
                  <sis-icon color="primary" icon="info-circle"></sis-icon>
                  <span class="student-initiator">
                    <ng-container [ngTemplateOutlet]="initiatorText"
                                  [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
                  </span>
                }
              </td>
            }
            @case ('studyRight') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                @if (workflow.studyRightId | studyRightName | async; as educationName) {
                  {{educationName | localizedString}}
                }
              </td>
            }
            @case ('studentName') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                {{workflow.personLastName}}, {{workflow.personFirstNames}}
              </td>
            }
            @case ('code') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                {{workflow.code}}
              </td>
            }
            @case ('state') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                <span class="workflow-status workflow-status__{{this.isOnStudentPage ? 'student' : 'staff'}}">
                  <sis-icon [icon]="getStatusIcon(workflow.state)"
                            [iconClass]="getStatusIconClass(workflow.state)"></sis-icon>
                  <span class="field-value">{{('STUDENT_APPLICATIONS.STATE.' + workflow.state) | translate}}</span>
                </span>
              </td>
            }
            @case ('lastHandledTime') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                {{workflow.lastHandledTime | localDateFormat}}
              </td>
            }
            @case ('lastHandlerPerson') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                <span class="student-workflow-handler">
                  @let workflowNotReady = workflow.state === 'REQUESTED' || workflow.state === 'IN_HANDLING' || workflow.state === 'SUPPLEMENT_REQUESTED';
                  @if (workflow.lastHandlerPersonId | publicPerson | async; as publicPerson) {
                    <span class="notranslate">{{ publicPerson.lastName }}, {{publicPerson.firstName }}</span>
                    @if (workflowNotReady && !isOnStudentPage) {
                      <sis-button [link]="true"
                                  (clicked)="this.editHandler.emit(workflow.id)"
                                  [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                        <sis-tooltip [tooltipBlockElement]="true"
                                     [tooltipText]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate"
                                     [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                          <sis-icon [icon]="'edit'" [color]="'primary'" class="sis-tooltip-trigger-content"></sis-icon>
                        </sis-tooltip>
                      </sis-button>
                    }
                  }
                  @if (!workflow.lastHandlerPersonId && workflowNotReady && !isOnStudentPage) {
                    <sis-button [link]="true"
                                (clicked)="this.editHandler.emit(workflow.id)">{{'STUDENT_APPLICATIONS.SET_HANDLER' | translate}}
                    </sis-button>
                  }
                </span>
              </td>
            }
            @case ('creationTime') {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                {{workflow.creationTime | localDateFormat}}
              </td>
            }
            @default {
              <td [ngClass]="{'sorted-cell': sortColumn === column}">
                {{workflow[column]}}
              </td>
            }
          }
        }
      </tr>
    }
    </tbody>
  </table>

  <div class="row scroll-target">
    <div class="col-12"
         *ngIf="totalItems > itemsPerPage"
         data-cy="pagination-controls">
      <sis-pagination
        sisScrollIntoViewOnPageChange="div.scroll-target"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemsPerPage]="itemsPerPage"
        [maxSize]="maxSize"
        (currentPageChange)="onPageChange($event)">
      </sis-pagination>
    </div>
  </div>
</div>

<ng-template #initiatorText let-personId="personId">
  {{ personId && !this.isOnStudentPage
  ? ('STUDENT_APPLICATIONS.INITIATOR_PUBLIC' | translate:{personFullName: (personId | publicPerson | async | personFullName)})
  : ('STUDENT_APPLICATIONS.INITIATOR_ANON' | translate) }}
</ng-template>
