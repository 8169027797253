import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult } from 'common-typescript';
import { PlanEducationOptions, PlanValidationState } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { PlanStateObject } from 'sis-components/service/plan-state.service';
import { ScrollService } from 'sis-components/service/scroll.service';

import { PlanStructureData } from '../plan-data.service';

interface InvalidModuleInfo {
    id: string,
    name: string,
    focusId: string,
    title: string,
}

@Component({
    selector: 'app-plan-notification',
    templateUrl: './plan-notification.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PlanNotificationComponent implements AfterViewChecked, OnChanges {

    @Input() planStructureData: PlanStructureData;

    invalidModules: InvalidModuleInfo[] = [];

    invalidModulesSorted: InvalidModuleInfo[];

    constructor(
        private localeService: LocaleService,
        private scrollService: ScrollService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnChanges(): void {
        this.invalidModules = [];
        this.invalidModulesSorted = undefined;

        if (this.planStructureData) {
            const planValidationResult: PlanValidationResult = this.planStructureData.planValidationResult;
            const planStateObject: PlanStateObject = this.planStructureData.planStateAndData?.planStateObject;
            if (planValidationResult && planStateObject) {
                this.updateInvalidModules(planValidationResult, planStateObject);
            }
        } else {
            throw new Error('PlanStructureData was empty');
        }
    }

    ngAfterViewChecked(): void {
        if (!this.invalidModulesSorted) {
            if (this.invalidModules.length > 0) {
                this.invalidModulesSorted = this.invalidModules.toSorted(this.sortErrorOrder);
                this.changeDetectorRef.markForCheck();
            }
        }
    }

    protected handleFocusClick(event: Event, clickedId: string): void {
        event.preventDefault();

        this.scrollService.scrollToElementById(clickedId);
    }

    private updateInvalidModules(planValidationResult: PlanValidationResult, planStateObject: PlanStateObject): void {
        const moduleValidationResults = planValidationResult.moduleValidationResults;
        const modules = planStateObject.modules;
        const validatablePlan = planValidationResult.validatablePlan;

        if (moduleValidationResults && modules && validatablePlan) {
            _.forIn(moduleValidationResults, (value, key) => {
                if (value.contextualState === PlanValidationState.INVALID) {
                    const name = _.get(validatablePlan.modulesById, key)?.name;
                    const localName = this.localeService.localize(name);
                    const focusId = _.get(modules, key)?.elementId;

                    this.invalidModules.push({ id: key, name: localName, focusId, title: 'PLAN.STATE.INVALID_SELECTIONS_IN_PLAN_MODULE_TEXT' });
                }
            });

            const planEducationOptions: PlanEducationOptions[] = this.planStructureData.educationOptions ? Object.values(this.planStructureData.educationOptions) : [];
            const conflictsWithStudyRightPath = planEducationOptions.filter(option => option.isInPlan && _.get(option, 'studyRightState') === 'CONFLICTS_WITH_STUDY_RIGHT');
            if (conflictsWithStudyRightPath && conflictsWithStudyRightPath.length > 0) {
                conflictsWithStudyRightPath.forEach(option => {
                    const name = _.get(validatablePlan.modulesById, option.moduleId)?.name;
                    const localName = this.localeService.localize(name);
                    const focusId = _.get(modules, option.moduleId)?.elementId;

                    this.invalidModules.push({ id: option.moduleId, name: localName, focusId, title: 'PLAN.STATE.INVALID_SELECTION_CONFLICT_WITH_STUDY_RIGHT' });
                });
            }
        }
    }

    private sortErrorOrder(a: InvalidModuleInfo, b: InvalidModuleInfo): 0 | -1 | 1 {
        if (a.id === b.id) {
            return 0;
        }

        const elementA = document.getElementById(a.focusId);
        const elementB = document.getElementById(b.focusId);

        if (elementA && elementB) {
            const position = elementA.compareDocumentPosition(elementB);
            if (
                position === Node.DOCUMENT_POSITION_FOLLOWING ||
                position === Node.DOCUMENT_POSITION_CONTAINED_BY
            ) {
                return -1;
            } if (
                position === Node.DOCUMENT_POSITION_PRECEDING ||
                position === Node.DOCUMENT_POSITION_CONTAINS
            ) {
                return 1;
            }
        }
        return 0;
    }

}
