import angular from 'angular';
import * as _ from 'lodash-es';
import { PlanValidationTs } from 'common-typescript';
(function () {
  degreeProgrammeAttainmentApplicationService.$inject = ["$q", "AuthService", "commonStudentApplicationService", "moduleAttainmentApplicationService", "PlanState", "STUDENT_APPLICATION_STATE", "STUDENT_APPLICATION_TYPE"];
  angular.module('student.common.service.degreeProgrammeAttainmentApplicationService', ['sis-common.auth', 'sis-components.model.studentApplication', 'sis-components.service.studentApplicationService', 'student.common.service.moduleAttainmentApplicationService', 'student.shared']).service('degreeProgrammeAttainmentApplicationService', degreeProgrammeAttainmentApplicationService);
  function degreeProgrammeAttainmentApplicationService($q, AuthService, commonStudentApplicationService, moduleAttainmentApplicationService, PlanState, STUDENT_APPLICATION_STATE, STUDENT_APPLICATION_TYPE) {
    var api = {
      findByStates: function (states, bypassCache) {
        return commonStudentApplicationService.findAll({
          studentId: AuthService.personId()
        }, {
          bypassCache: !_.isNil(bypassCache) ? bypassCache : true
        }).then(applications => _.chain(applications).filter({
          type: STUDENT_APPLICATION_TYPE.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION
        }).filter(application => _.includes(states, application.state)).value());
      },
      findByModuleAndStates: function (moduleId, states) {
        return commonStudentApplicationService.getApplicationsForStudentByTypes(AuthService.personId(), [STUDENT_APPLICATION_TYPE.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION]).then(applications => _.chain(applications).filter(application => application.moduleId === moduleId && _.includes(states, application.state)).value());
      },
      existsByModuleAndStates: function (moduleId, states) {
        return commonStudentApplicationService.getApplicationsForStudentByTypes(AuthService.personId(), [STUDENT_APPLICATION_TYPE.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION]).then(applications => _.chain(applications).some(application => application.moduleId === moduleId && _.includes(states, application.state)).value());
      },
      create: function (application, module, studyRight, validatablePlan, planValidationResult) {
        if (module.type !== 'DegreeProgramme') {
          return $q.reject(`Module ${module.id} is not a degree programme.`);
        }
        if (!api.isPlanStateValidForApplicationSending(PlanValidationTs.getPlanStateForModule(_.get(validatablePlan.modulesById, module.id), validatablePlan, planValidationResult))) {
          return $q.reject(`Module ${module.id} is not in correct plan state.`);
        }
        if (!_.get(validatablePlan, 'plan.primary')) {
          return $q.reject(`Plan ${_.get(validatablePlan, 'plan.id')} is not primary.`);
        }
        return api.existsByModuleAndStates(module.id, [STUDENT_APPLICATION_STATE.REQUESTED, STUDENT_APPLICATION_STATE.IN_HANDLING]).then(applicationExists => {
          if (applicationExists) {
            return $q.reject(`Application already exists for degree programme ${module.id}.`);
          }
          const promises = [];
          const childModules = _.filter(validatablePlan.getSelectedModulesById(module), selectedModule => selectedModule.type === 'StudyModule' || selectedModule.type === 'GroupingModule' || selectedModule.graded === false);
          _.forEach(childModules, module => {
            promises.push(moduleAttainmentApplicationService.sendApplication(module, validatablePlan, studyRight, planValidationResult, true));
          });
          promises.push(moduleAttainmentApplicationService.createModuleOrDegreeProgrammeAttainmentWorkflow(createWorkflowApplication()));
          return $q.all(promises);
        });
        function createWorkflowApplication() {
          const {
            plan
          } = validatablePlan;
          application.moduleId = module.id;
          application.planId = validatablePlan.plan.id;
          application.studyRightId = studyRight.id;
          application.state = 'REQUESTED';
          application.type = 'DegreeProgrammeAttainmentApplication';
          application.planContent = {
            courseUnitSelections: _.cloneDeep(plan.courseUnitSelections),
            moduleSelections: _.cloneDeep(plan.moduleSelections),
            customCourseUnitAttainmentSelections: _.cloneDeep(plan.customCourseUnitAttainmentSelections),
            customModuleAttainmentSelections: _.cloneDeep(plan.customModuleAttainmentSelections),
            customStudyDrafts: _.cloneDeep(plan.customStudyDrafts)
          };
          return application;
        }
      },
      isPlanStateValidForApplicationSending: function (planState) {
        const validPlanStates = [PlanState.PLANNED, PlanState.APPROVED, PlanState.APPROVED_CONDITIONALLY, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.PARTS_ATTAINED, PlanState.IMPLICIT];
        return _.includes(validPlanStates, planState);
      }
    };
    return api;
  }
})();