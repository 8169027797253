import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CourseUnit, CourseUnitRealisation, DeepPartial, LocalizedString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';

import { LocaleService } from '../l10n/locale.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from '../types/angular-hybrid';

import { NameService } from './name.service';

export type PartialCUR = DeepPartial<Pick<CourseUnitRealisation, 'name' | 'nameSpecifier' | 'activityPeriod'>>;

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class CourseUnitRealisationNameService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-common.name.course-unit-realisation-service',
        serviceName: 'courseUnitRealisationNameService',
    };

    constructor(private localeService: LocaleService,
                private translateService: TranslateService,
                private nameService: NameService) {
    }

    private DATE_FORMAT_LONG = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_LONG';
    private DATE_FORMAT_SHORT = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_SHORT';
    private DATE_RANGE = 'COURSE_UNIT_REALISATION.COMPUTED_NAME_DATE_FORMAT_RANGE';

    generateFullName(courseUnitRealisation: PartialCUR) {
        const fullName = this.nameService.generateFullName(courseUnitRealisation);
        const activityPeriodName = this.activityPeriodAsText(courseUnitRealisation);
        this.localeService.getOfficialLanguages().forEach((locale) => {
            fullName[locale] = _.trim(`${fullName[locale]} ${activityPeriodName}`);
        });
        return fullName;
    }

    activityPeriodAsText(courseUnitRealisation: PartialCUR) {
        let activityPeriodName = '';
        if (courseUnitRealisation.activityPeriod) {
            const startDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.startDate, ''));
            const endDate = moment(_.defaultTo(courseUnitRealisation.activityPeriod.endDate, '')).subtract(1, 'days');
            let localizedName;
            const dateFormatLong = this.translateService.instant(this.DATE_FORMAT_LONG);
            const dateFormatShort = this.translateService.instant(this.DATE_FORMAT_SHORT);
            const hasDates = startDate.isValid() && endDate.isValid();
            const startAndEndDateAreSame = hasDates && endDate.isSame(startDate, 'day');
            const useShortStartDate = !startAndEndDateAreSame && hasDates && endDate.isSame(startDate, 'year');
            const startDateFormat = useShortStartDate ? dateFormatShort : dateFormatLong;
            if (hasDates && !startAndEndDateAreSame) {
                localizedName = this.translateService.instant(this.DATE_RANGE, {
                    start: moment(startDate).format(startDateFormat),
                    end: moment(endDate).format(dateFormatLong),
                });
            } else if (startDate.isValid()) {
                localizedName = moment(startDate).format(startDateFormat);
            } else if (endDate.isValid()) {
                localizedName = moment(endDate).format(startDateFormat);
            }
            activityPeriodName = localizedName;
        }
        return activityPeriodName;
    }

    generateFullNameFromCourseUnit(courseUnitRealisation: CourseUnitRealisation, courseUnit: CourseUnit): LocalizedString {
        if (courseUnitRealisation && courseUnit) {
            const fullName: LocalizedString = {};
            let courseUnitName = courseUnit.name;
            courseUnitName = !courseUnitName ? courseUnitRealisation.nameSpecifier : courseUnitName;
            courseUnitName = !courseUnitName ? {} : courseUnitName;
            let name = courseUnitRealisation.name;
            name = !name ? {} : name;
            const activityPeriodName = this.activityPeriodAsText(courseUnitRealisation);

            let fallbackName: string;
            let fallbackNameSpecifier: string;
            _.forEach(this.localeService.getOfficialLanguages(), (locale) => {
                if (!fallbackNameSpecifier && courseUnitName[locale] && courseUnitName[locale].trim().length > 0) {
                    fallbackNameSpecifier = courseUnitName[locale];
                }
                if (!fallbackName && name[locale] && name[locale].trim().length > 0) {
                    fallbackName = name[locale];
                }
            });

            fallbackName = fallbackName ? fallbackName : '';
            fallbackNameSpecifier = fallbackNameSpecifier ? fallbackNameSpecifier : '';

            _.forEach(this.localeService.getOfficialLanguages(), (locale) => {
                let nameSpecifierForLocale = courseUnitName[locale];
                nameSpecifierForLocale = nameSpecifierForLocale && nameSpecifierForLocale.trim().length > 0 ? nameSpecifierForLocale : fallbackNameSpecifier;
                nameSpecifierForLocale += nameSpecifierForLocale.length === 0 ? '' : ', ';

                let nameForLocale = name[locale];
                nameForLocale = nameForLocale && nameForLocale.trim().length > 0 ? nameForLocale : fallbackName;

                let activityPeriodForLocale = activityPeriodName;
                activityPeriodForLocale = activityPeriodForLocale ? ` ${activityPeriodForLocale}` : '';

                fullName[locale] = _.trim(nameSpecifierForLocale + nameForLocale + activityPeriodForLocale);
            });

            return fullName;
        }
        return this.generateFullName(courseUnitRealisation);
    }
}
