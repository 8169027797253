import { dateUtils } from 'common-typescript/constants';
import {
    AssessmentItemAttainment,
    Attainment,
    AttainmentGroupNode,
    AttainmentNode,
    AttainmentPriorityResolvable,
    AttainmentReferenceNode,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    DegreeProgrammeAttainment,
    LocalDateString,
    ModuleAttainment,
    OrganisationRoleShare,
    OrganisationRoleShareBase,
    OtmId,
    PersonWithAttainmentAcceptorType,
    PersonWithModuleResponsibilityInfoType,
    Urn,
} from 'common-typescript/types';
import { chain, isEqual, last, sortBy } from 'lodash-es';
import moment from 'moment';

export function isAssessmentItemAttainment(attainment: Attainment): attainment is AssessmentItemAttainment {
    return attainment?.type === 'AssessmentItemAttainment';
}

export function isCourseUnitAttainment(attainment: Attainment): attainment is CourseUnitAttainment {
    return attainment?.type === 'CourseUnitAttainment';
}

export function isModuleAttainment(attainment: Attainment): attainment is ModuleAttainment {
    return attainment?.type === 'ModuleAttainment';
}

export function isDegreeProgrammeAttainment(attainment: Attainment): attainment is DegreeProgrammeAttainment {
    return attainment?.type === 'DegreeProgrammeAttainment';
}

export function isCustomCourseUnitAttainment(attainment: Attainment): attainment is CustomCourseUnitAttainment {
    return attainment?.type === 'CustomCourseUnitAttainment';
}

export function isCustomModuleAttainment(attainment: Attainment): attainment is CustomModuleAttainment {
    return attainment?.type === 'CustomModuleAttainment';
}

export function isModuleLikeAttainment(attainment: Attainment): attainment is ModuleAttainment | DegreeProgrammeAttainment | CustomModuleAttainment {
    return isModuleAttainment(attainment) || isDegreeProgrammeAttainment(attainment) || isCustomModuleAttainment(attainment);
}

export function isCourseUnitLikeAttainment(attainment: Attainment): attainment is CourseUnitAttainment | CustomCourseUnitAttainment {
    return isCourseUnitAttainment(attainment) || isCustomCourseUnitAttainment(attainment);
}

export function isAttainmentReferenceNode(node: AttainmentNode): node is AttainmentReferenceNode {
    return node?.type === 'AttainmentReferenceNode';
}

export function isAttainmentGroupNode(node: AttainmentNode): node is AttainmentGroupNode {
    return node?.type === 'AttainmentGroupNode';
}

/**
 *
 * @param attainment that may be attached
 * @param attainments potential parents
 * @returns true if parent attainment was found, false otherwise
 */
export function isAttached(attainment: Attainment, attainments: Attainment[]): boolean {
    if (!attainment) {
        return false;
    }
    // Object.values used to support js-files and their oblivious usage of validatablePlan.getAllAttainment() which will not compile once
    // migrated to typescript
    return !!Object.values(attainments || []).find(att => toChildAttainmentIds(att).includes(attainment.id));
}

/**
 * Returns array copy of assessmentItemIds for CourseUnitAttainment with non null items.
 * Returns array of attainmentId found in node tree for ModuleAttainment, CustomModuleAttainment and
 * DegreeProgrammeAttainment with non null items.
 * Returns empty array for AssessmentItemAttainment and CustomCourseUnitAttainment.
 *
 * @param attainment whose child ids are asked
 * @returns new array of child attainment ids
 */
export function toChildAttainmentIds(attainment: Attainment): OtmId[] {
    if (isCourseUnitAttainment(attainment)) {
        return Object.assign([], attainment.assessmentItemAttainmentIds).filter(Boolean);
    }
    if (isModuleLikeAttainment(attainment)) {
        return collectIdsFromNodes(attainment.nodes);
    }
    if (isAssessmentItemAttainment(attainment) || isCustomCourseUnitAttainment(attainment)) {
        return [];
    }
    throw new Error(`Unsupported attainment type: ${attainment?.type}`);
}

function collectIdsFromNodes(nodes: AttainmentNode[]): OtmId[] {
    if (!nodes) {
        return [];
    }
    return nodes.flatMap((node) => {
        if (isAttainmentGroupNode(node)) {
            return collectIdsFromNodes(node.nodes);
        }
        if (isAttainmentReferenceNode(node)) {
            return [node.attainmentId];
        }
        return [];
    })
        .filter(Boolean);
}

/**
 * Returns true for 12 months leading to expiryDate of attainment. Expired attainment IS NOT ABOUT TO EXPIRE.
 */
export function isAboutToExpire(attainment: Attainment): boolean {
    if (!attainment.expiryDate) {
        return false;
    }
    return moment().isBetween(moment(attainment.expiryDate).subtract(12, 'months'), moment(attainment.expiryDate));
}

export function hasExpired(attainment: Attainment): boolean {
    if (!attainment.expiryDate) {
        return false;
    }
    return !moment(attainment.expiryDate).isAfter(moment());
}

export function toAttainmentAcceptorPerson(personId: OtmId): PersonWithAttainmentAcceptorType {
    return {
        personId,
        roleUrn: 'urn:code:attainment-acceptor-type:approved-by',
        text: null,
        title: null,
    };
}

/**
 * Maps the given responsibility roles into attainment acceptor persons. Only includes responsible teacher roles that are valid on
 * the given attainment date.
 */
export function resolveAttainmentAcceptorPersons(
    responsibilityInfos: PersonWithModuleResponsibilityInfoType[],
    attainmentDate: LocalDateString,
): PersonWithAttainmentAcceptorType[] {
    return (responsibilityInfos ?? [])
        .filter(responsibility => responsibility.roleUrn === 'urn:code:module-responsibility-info-type:responsible-teacher')
        .filter(responsibility => !!responsibility.personId)
        .filter(responsibility => dateUtils.isRoleValid(responsibility, attainmentDate))
        .map(responsibility => toAttainmentAcceptorPerson(responsibility.personId));
}

/**
 * Maps the given organisation roles into attainment organisations. Only includes roles that are valid on the given attainment date.
 * If the optional `role` argument is provided, returns only organisations with that role.
 */
export function resolveAttainmentOrganisations(
    organisations: OrganisationRoleShare[],
    attainmentDate: LocalDateString,
    role?: Urn,
): OrganisationRoleShareBase[] {
    return (organisations ?? [])
        .filter(org => !role || org.roleUrn === role)
        .filter(org => dateUtils.isRoleValid(org, attainmentDate))
        .map(({ educationalInstitutionUrn, organisationId, roleUrn, share }) => ({
            educationalInstitutionUrn,
            organisationId,
            roleUrn,
            share,
        }));
}

/**
 * Returns true if given attainment has higher or equal priority (compared by grade, credits and attainment date) than
 * the other attainment. Logic should be equal that is in backend.
 */
export function hasHigherOrEqualPriorityThan(
    attainment: AttainmentPriorityResolvable,
    other: AttainmentPriorityResolvable,
): boolean {

    if (attainment == null || other == null || attainment.gradeScaleId !== other.gradeScaleId) {
        // priorities are not comparable with each other
        return false;
    }

    return chain([other, attainment])
        .sortBy('gradeId', 'credits', 'attainmentDate')
        .last()
        .isEqual(attainment)
        .value();
}
