import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OtmId, StudyRightSemesterData } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { StudyRightSemesterCalculationService } from '../../service/study-right-semester-calculation.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-semesters-indicator',
    templateUrl: './semesters-indicator.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SemestersIndicatorComponent implements OnInit, OnDestroy {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.studyRight.semesters-indicator-ng',
        directiveName: 'sisSemestersIndicator',
    };

    neverExpires: boolean;
    setEndDateManually: boolean;
    unusedRange: number[];
    usedRange: number[];
    absentUnusedRange: number[];
    absentUsedRange: number[];
    extensionUnusedRange: number[];
    extensionUsedRange: number[];
    statutoryAbsentUsedRange: number[];
    extensionOngoing: boolean;
    private subscription = Subscription.EMPTY;
    terms: StudyRightSemesterData;
    /**
     * Determines grouping panel's heading's semantic aria-level for screen readers, default is equivalent for h6
     */
    @Input() level?: number = 6;
    @Input() studyRightId: OtmId;

    constructor(
        private studyRightSemesterCalculationService: StudyRightSemesterCalculationService,
    ) { }

    ngOnInit(): void {
        this.subscription = this.studyRightSemesterCalculationService.getStudyRightSemesterDataByStudyRightId(this.studyRightId)
            .subscribe((terms) => {
                this.refreshData(terms);
                this.terms = terms;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getAbsentColsOffset(statutoryAbsentUsed: number, absentUnused: number, maxCols: number): number {
        if (statutoryAbsentUsed < maxCols) {
            // Calculate the offset from a full width row, hence the 12
            return 12 - statutoryAbsentUsed - absentUnused;
        }
        // Always have at least offset-1, due to max col-7 in template
        return 1;
    }

    getUnusedAbsentAmount(terms: StudyRightSemesterData) {
        return terms.totalExtension ? 0 : terms.absentUnused;
    }

    refreshData(terms: StudyRightSemesterData) {
        if (!!terms) {
            this.neverExpires = terms.neverExpires;
            this.setEndDateManually = terms.setEndDateManually;
            this.extensionOngoing = terms.ongoing === 'EXTENSION';
        }
    }
}
