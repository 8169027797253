import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
    AssessmentItem, AssessmentItemAttainment,
    AttainmentType,
    CompletionMethod,
    CourseUnit,
    CourseUnitRealisation,
    Enrolment,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { forkJoin, Observable, of, Subject, switchMap, take } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { ModalService } from 'sis-common/modal/modal.service';
import { ENROLMENT_VALIDATION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import {
    CourseUnitDisplayNamesById,
    CourseUnitInfoService,
    CourseUnitInfoVersion,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { LocalDateRangePipe } from 'sis-components/date/pipes/local-date-range/local-date-range.pipe';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { CourseUnitRealisationEntityService } from 'sis-components/service/course-unit-realisation-entity.service';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

export interface SelectCourseUnitRealisationDialogValues {
    courseUnit: CourseUnit;
    assessmentItemIds: OtmId[];
    completionMethod: CompletionMethod;
}

@Component({
    selector: 'app-select-course-unit-realisation',
    templateUrl: './select-course-unit-realisation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCourseUnitRealisationComponent implements OnInit, OnDestroy {
    destroyed$ = new Subject<void>();

    _values: SelectCourseUnitRealisationDialogValues;
    assessmentItemsWithRealisations: Map<AssessmentItem, CourseUnitRealisation[]>;
    enrolmentForCourseUnitRealisations: { [key: OtmId]: Enrolment };
    completionMethod: CompletionMethod;
    courseUnit: CourseUnit;
    attainments: AssessmentItemAttainment[];
    form: FormGroup;
    courseUnitVersionDisplayName: Observable<String>;

    constructor(
        @Inject(ModalService.injectionToken) private values: SelectCourseUnitRealisationDialogValues,
        @Inject(ENROLMENT_VALIDATION_SERVICE) private enrolmentValidationService: any,
        private appErrorHandler: AppErrorHandler,
        public activeModal: NgbActiveModal,
        private courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private attainmentEntityService: AttainmentEntityService,
        private enrolmentStudentService: EnrolmentStudentService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private localizedString: LocalizedStringPipe,
        private localDateRange: LocalDateRangePipe,
        private fb: SisFormBuilder,
        private alertsService: AlertsService,
        private translateService: TranslateService,
        private courseUnitInfoService: CourseUnitInfoService,
    ) {
        this._values = values;
    }

    ngOnInit(): void {
        this.initForm();
        this.initData();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    private initForm(): void {
        this.form = this.fb.group({
            selectedCourseUnitRealisations: this.fb.array([]),
        });
    }

    private initData(): void {
        this.courseUnit = this._values.courseUnit;
        this.completionMethod = this._values.completionMethod;
        const assessmentItemIds = this._values.assessmentItemIds;
        this.courseUnitVersionDisplayName = this.getSelectableVersionsAndNames()
            .pipe(this.appErrorHandler.defaultErrorHandler(),
                  switchMap(([courseUnitVersions, displayNamesByCourseUnitId]) => of(displayNamesByCourseUnitId[this.courseUnit.id])));

        this.attainmentEntityService.findForPerson(this.authService.personId(), {
            assessmentItemId: assessmentItemIds,
            attainmentType: AttainmentType.ASSESSMENT_ITEM_ATTAINMENT,
            documentState: 'ACTIVE',
            primary: true,
        }).pipe(map(attainments => attainments as AssessmentItemAttainment[])).pipe(
            switchMap(attainments => {
                this.attainments = attainments;
                return this.fetchAssessmentItemsAndRealisations(assessmentItemIds).pipe(
                    switchMap(({ assessmentItems, courseUnitRealisations }) =>
                        this.enrolmentStudentService.findEnrolments(courseUnitRealisations.map(cur => cur.id), true).pipe(
                            map(enrolments => ({ assessmentItems, courseUnitRealisations, enrolments })),
                        ),
                    ),
                );
            }),
            takeUntil(this.destroyed$),
        ).subscribe(({ assessmentItems, courseUnitRealisations, enrolments }) => {
            this.processAssessmentItemsAndRealisations(assessmentItems, courseUnitRealisations);
            this.enrolmentForCourseUnitRealisations = _.keyBy(enrolments, 'courseUnitRealisationId');
            this.form = this.fb.group({
                selectedCourseUnitRealisations: this.fb.array(
                    courseUnitRealisations.filter(cur => this.isEnrolmentInCalendar(cur.id)).map(cur =>
                        this.fb.group({
                            courseUnitRealisationId: this.fb.control(cur.id),
                            assessmentItemId: this.fb.control(this.getAssessmentItemIdForCur(cur)),
                        }),
                    ),
                ),
            });
            this.cdr.detectChanges();
        });
    }

    fetchAssessmentItemsAndRealisations(assessmentItemIds: OtmId[]): Observable<{ assessmentItems: AssessmentItem[], courseUnitRealisations: CourseUnitRealisation[] }> {
        if (assessmentItemIds.length === 0) {
            return of({ assessmentItems: [], courseUnitRealisations: [] });
        }

        return this.assessmentItemEntityService.getByIds(assessmentItemIds).pipe(
            switchMap(assessmentItems =>
                this.courseUnitRealisationEntityService.getPublishedAndActiveCourseUnitRealisationsByAssessmentItemIds(assessmentItemIds).pipe(
                    map(courseUnitRealisations => ({ assessmentItems, courseUnitRealisations })),
                )),
        );
    }

    processAssessmentItemsAndRealisations(assessmentItems: AssessmentItem[], courseUnitRealisations: CourseUnitRealisation[]): void {
        const assessmentItemMap = new Map<AssessmentItem, CourseUnitRealisation[]>();
        assessmentItems.forEach(assessmentItem => {
            const courseUnitRealisationsForAssessmentItem = courseUnitRealisations.filter(
                courseUnitRealisation => courseUnitRealisation.assessmentItemIds.includes(assessmentItem.id),
            );
            assessmentItemMap.set(assessmentItem, _.orderBy(courseUnitRealisationsForAssessmentItem, 'activityPeriod.startDate'));
        });

        this.assessmentItemsWithRealisations = assessmentItemMap;
    }

    isEnrolmentInCalendar(courseUnitRealisationId: OtmId): boolean {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisationId];
        return enrolment ? _.get(enrolment, 'isInCalendar', false) : false;
    }

    getAssessmentItemIdForCur(courseUnitRealisation: CourseUnitRealisation): OtmId {
        return this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id].assessmentItemId;
    }

    isEnrolledToFromAnotherEntity(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && _.includes(['NOT_ENROLLED', 'PROCESSING', 'ENROLLED'], enrolment.state) &&
            (enrolment.assessmentItemId !== assessmentItem.id || enrolment.courseUnitId !== this._values.courseUnit.id);
    }

    getCompletionMethodIndex() {
        return this._values.courseUnit.completionMethods.findIndex((cm) => cm.localId === this.completionMethod.localId) + 1;
    }

    getCourseUnitRealisationLabel(courseUnitRealisation: CourseUnitRealisation) {
        return `${this.localizedString.transform(courseUnitRealisation.name)} ${this.localDateRange.transform(courseUnitRealisation.activityPeriod)}`;
    }

    getSelectableVersionsAndNames(): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.courseUnit);
    }

    isCourseUnitRealisationSelected(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        const courseUnitRealisationSelection = _.find(this.selectedCourseUnitRealisations().value, formValue =>
            formValue.courseUnitRealisationId === courseUnitRealisation.id && formValue.assessmentItemId === assessmentItem.id);
        return !!courseUnitRealisationSelection;
    }

    courseUnitRealisationCheckboxClicked(event: boolean, courseUnitRealisationId: OtmId, assessmentItemId: OtmId) {
        const selectedCourseUnitRealisations = this.selectedCourseUnitRealisations();
        event ? this.addValueToSelectedCourseUnitRealisations(courseUnitRealisationId, assessmentItemId) :
            selectedCourseUnitRealisations.removeAt(selectedCourseUnitRealisations.value.findIndex((formValue: any) => formValue.courseUnitRealisationId === courseUnitRealisationId));
    }

    private addValueToSelectedCourseUnitRealisations(courseUnitRealisationId: OtmId, assessmentItemId: OtmId) {
        const formArray: FormArray = this.form.get('selectedCourseUnitRealisations') as FormArray;

        // Remove same realisation selections before pushing
        for (let i = formArray.length - 1; i >= 0; i -= 1) {
            if (formArray.at(i).get('courseUnitRealisationId').value === courseUnitRealisationId) {
                formArray.removeAt(i);
            }
        }

        this.selectedCourseUnitRealisations().push(
            this.fb.group({
                courseUnitRealisationId: this.fb.control(courseUnitRealisationId),
                assessmentItemId: this.fb.control(assessmentItemId),
            }),
        );
    }

    isSelectionDisabled(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        return courseUnitRealisation.flowState === 'CANCELLED' ||
            this.enrolmentInProcessingOrProcessed(courseUnitRealisation) ||
            this.hasAttainment(courseUnitRealisation, assessmentItem) ||
            this.isEnrolledToFromAnotherEntity(courseUnitRealisation, assessmentItem);
    }

    hasEnrolled(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && enrolment.state === 'PROCESSING';
    }

    enrolmentInProcessingOrProcessed(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && enrolment.state !== 'NOT_ENROLLED';
    }

    enrolmentConfirmed(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && enrolment.state === 'ENROLLED';
    }

    enrolmentRejected(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && enrolment.state === 'REJECTED';
    }

    hasAborted(courseUnitRealisation: CourseUnitRealisation) {
        const enrolment = this.enrolmentForCourseUnitRealisations[courseUnitRealisation.id];
        return enrolment && (enrolment.state === 'ABORTED_BY_STUDENT' || enrolment.state === 'ABORTED_BY_TEACHER');
    }

    hasAttainment(courseUnitRealisation: CourseUnitRealisation, assessmentItem: AssessmentItem) {
        return !!this.attainments.find((a) => a.courseUnitRealisationId === courseUnitRealisation.id && a.assessmentItemId === assessmentItem.id);
    }

    hasAnyAttainment(courseUnitRealisations: CourseUnitRealisation[], assessmentItem: AssessmentItem) {
        return courseUnitRealisations.some((cur) => this.hasAttainment(cur, assessmentItem)) || !!this.attainments.find((a) =>
            !a.courseUnitRealisationId && a.assessmentItemId === assessmentItem.id);
    }

    private selectedCourseUnitRealisations(): FormArray {
        return this.form.get('selectedCourseUnitRealisations') as FormArray;
    }

    submit() {
        const allObservables: Observable<any>[] = [];
        const enrolledRealisations: CourseUnitRealisation[] = [];

        this.assessmentItemsWithRealisations.forEach((curs, assessmentItem) => {
            curs.forEach((cur) => {
                const selectedCur = this.selectedCourseUnitRealisations().value.find((formValue: any) =>
                    formValue.courseUnitRealisationId === cur.id && formValue.assessmentItemId === assessmentItem.id);

                const enrolment = this.enrolmentForCourseUnitRealisations[cur.id];

                if (selectedCur && !enrolment) {
                    const addObservable = this.enrolmentStudentService.add({
                        assessmentItemId: assessmentItem.id,
                        courseUnitRealisationId: cur.id,
                        courseUnitId: this._values.courseUnit.id,
                        personId: this.authService.personId(),
                        state: 'NOT_ENROLLED',
                        isInCalendar: true,
                        studySubGroups: this.enrolmentValidationService.createStudySubGroupsForEnrolment(cur),
                    } as Enrolment);
                    allObservables.push(addObservable);
                    enrolledRealisations.push(cur);
                    this.alertsService.addAlert({
                        type: AlertType.INFO,
                        message: this.translateService.instant('ENROLMENTS.ENROLMENT_ADDED'),
                    });
                } else if (this.shouldRemoveEnrolment(selectedCur, enrolment, assessmentItem.id)) {
                    const deleteObservable = this.enrolmentStudentService.delete(enrolment.id);
                    allObservables.push(deleteObservable);
                }
            });
        });

        if (allObservables.length > 0) {
            forkJoin(allObservables).pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler())
                .subscribe((results) => {
                    this.handleEnrolmentResults(_.compact(results), enrolledRealisations);
                });
        } else {
            this.activeModal.close();
        }
    }

    handleEnrolmentResults(createdEnrolments: Enrolment[], enrolledRealisations: CourseUnitRealisation[]) {
        if (enrolledRealisations.length !== 1) {
            this.activeModal.close();
            return;
        }

        const courseUnitRealisation = enrolledRealisations[0];
        const enrolment = createdEnrolments.find(e => e.courseUnitRealisationId === courseUnitRealisation.id);

        this.isEnrolmentOpen(courseUnitRealisation).subscribe((isEnrolmentOpen) => {
            if (isEnrolmentOpen) {
                this.activeModal.close({ selectedCourseUnitRealisation: courseUnitRealisation, enrolment });
            } else {
                this.activeModal.close();
            }
        });
    }

    isEnrolmentOpen(courseUnitRealisation: CourseUnitRealisation) {
        return this.enrolmentStudentService.getCalculationConfig(courseUnitRealisation.id)
            .pipe(
                map(enrolmentCalculationConfig =>
                    this.courseUnitRealisationEntityService.getEnrolmentPeriodValidity(courseUnitRealisation, enrolmentCalculationConfig?.enrolmentCalculationState) === 'ONGOING'),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    private shouldRemoveEnrolment(cur: CourseUnitRealisation, enrolment: Enrolment, assessmentItemId: OtmId) {
        return !cur && enrolment && enrolment.assessmentItemId === assessmentItemId && enrolment.state === 'NOT_ENROLLED';
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
