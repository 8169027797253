<ng-container *transloco="let t">
    <div id="student-application" class="row student-application create-extension-application">
        <sis-validation-error-summary [validationErrorDetails]="validationDetails"
                                      [description]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.INPUT_ERROR_SUMMARY_DESCRIPTION')">
        </sis-validation-error-summary>
        <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <div class="row">
                <div class="col-12">
                    <h2 tabindex="-1">{{ t('PROFILE.APPLICATIONS.EXTENSION_APPLICATION.STUDY_PROGRESS') }}</h2>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_RIGHT') }}</h4>
                        <span>{{this.education.name | localizedString}}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_RIGHT_VALIDITY') }}</h4>
                        <span>{{this.studyRight.valid | localDateRange}}</span>
                    </div>
                </div>

                <div class="col-12" *ngIf="this.workflowApplication.previousExtensions.length > 0">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.PREVIOUSLY_GRANTED_EXTENSIONS') }} </h4>
                        <sis-extension-info [extensions]="this.workflowApplication.previousExtensions"></sis-extension-info>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_TERMS_ATTENDING') }}</h4>
                        <sis-text-with-default-missing-value *ngIf="this.termRegistrationCounts"
                                                             [value]="this.termRegistrationCounts.usedAttendanceTerms.toString()"
                                                             [isOption]="true">
                        </sis-text-with-default-missing-value>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_TERMS_NONATTENDING') }}</h4>
                        <sis-text-with-default-missing-value *ngIf="this.termRegistrationCounts"
                                                             [value]="this.termRegistrationCounts.usedAbsenceTerms.toString()"
                                                             [isOption]="true">
                        </sis-text-with-default-missing-value>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_TERMS_STATUTORY_ABSENCES') }}</h4>
                        <sis-text-with-default-missing-value *ngIf="this.termRegistrationCounts"
                                                             [value]="this.termRegistrationCounts.usedStatutoryAbsenceTerms.toString()"
                                                             [isOption]="true">
                        </sis-text-with-default-missing-value>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.STUDY_TERMS_NO_REGISTRATION') }}</h4>
                        <sis-text-with-default-missing-value *ngIf="this.termRegistrationCounts"
                                                             [value]="this.termRegistrationCounts.termsWithoutRegistration.toString()"
                                                             [isOption]="true">
                        </sis-text-with-default-missing-value>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.ATTAINED_AND_REQUIRED_CREDITS') }}</h4>
                        <p><sis-education-phase-progress [educationPhaseProgress]="this.workflowApplication.phase1Progress"></sis-education-phase-progress></p>
                        <p><sis-education-phase-progress [educationPhaseProgress]="this.workflowApplication.phase2Progress"></sis-education-phase-progress></p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group-mimic">
                        <h4>{{ t('STUDENT_APPLICATIONS.AVERAGE_CREDITS_PER_STUDY_TERM') }}</h4>
                        <span>{{this.creditsPerTerm | decimalNumber}} {{'CREDITS' | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-12">
                    <h2>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.SPENT_STUDY_TERMS') }}</h2>
                    <sis-attained-credits-by-study-term *ngIf="this.attainedCreditsByStudyTerm"
                                                        [attainedCredits]="this.attainedCreditsByStudyTerm">
                    </sis-attained-credits-by-study-term>
                </div>

                <div class="col-12">
                    <h2>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.PLANNED_STUDIES') }}</h2>
                    <p class="guidance">
                        {{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.PLANNED_STUDIES_DESCRIPTION') }}
                    </p>
                    <br>
                    <sis-planned-studies-by-study-term *ngIf="this.plannedStudies"
                                                       [plannedStudies]="this.plannedStudies">
                    </sis-planned-studies-by-study-term>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <h2>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLIED_EXTENSION') }}</h2>
                    <div class="form-group">
                        <label [ngClass]="getLabelState(requestedTerms)">{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLIED_EXTENSION_PERIOD') }}</label>
                        <sis-dropdown-select *ngIf="this.requestedTermsOptions.length > 0"
                                             sisAutofocus [id]="'requested-terms-focus-id'"
                                             [control]="requestedTerms"
                                             [options]="requestedTermsOptions"
                                             [required]="true">
                        </sis-dropdown-select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <p class="guidance">{{ t('PROFILE.APPLICATIONS.EXTENSION_APPLICATION.SENSITIVE_INFO_NOTIFICATION') }}</p>
                        <sis-text-field sisAutofocus [id]="'delay-rationale-focus-id'"
                                        [control]="delayRationale"
                                        [label]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.DELAY_RATIONALE')"
                                        [textarea]="true"
                                        [helpBlock]="'PROFILE.APPLICATIONS.EXTENSION_APPLICATION.DELAY_RATIONALE_DESCRIPTION'">
                        </sis-text-field>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <sis-text-field sisAutofocus [id]="'application-rationale-focus-id'"
                                        [control]="applicationRationale"
                                        [label]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.APPLICATION_RATIONALE')"
                                        [textarea]="true"
                                        [helpBlock]="'PROFILE.APPLICATIONS.EXTENSION_APPLICATION.APPLICATION_RATIONALE_DESCRIPTION'">
                        </sis-text-field>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group checkbox-group">
                        <h4>{{ t('PROFILE.APPLICATIONS.DECISION_AND_DELIVERY') }}</h4>
                        <p class="help-block">{{ t('PROFILE.APPLICATIONS.DECISION_AND_DELIVERY_INFO') }}</p>
                        <sis-radio-button sisAutofocus [id]="'must-print-decision-focus-id'"
                                          [control]="mustPrintDecision"
                                          [value]="false"
                                          label="PROFILE.APPLICATIONS.DECISION_DELIVERY_IN_SISU"
                                          name="mustPrintDecision"/>
                        <sis-radio-button [control]="mustPrintDecision"
                                          [value]="true"
                                          label="PROFILE.APPLICATIONS.DECISION_DELIVERY_BY_MAIL"
                                          name="mustPrintDecision"/>
                    </div>
                </div>

                <div class="col-12 button-holder">
                    <sis-button [hollow]="true"
                                (clicked)="this.exitApplication()">
                        <sis-icon icon="back"></sis-icon> {{ t('SIS_COMPONENTS.BUTTON.EXIT') }}
                    </sis-button>
                    <sis-button (clicked)="this.submitApplication()">
                        {{ t('STUDENT_APPLICATIONS.SEND') }}
                    </sis-button>
                </div>

            </div>

        </div>

    </div>

</ng-container>
