import { ValidatablePlan } from 'common-typescript';
import { CourseUnit } from 'common-typescript/types';
import * as _ from 'lodash-es';

const CourseUnitHelper = {

    isSubstitute(courseUnit: CourseUnit, validatablePlan: ValidatablePlan) {
        const courseUnitSelectionWithAttainment = validatablePlan?.getCourseUnitSelection(courseUnit.id);
        return (
            courseUnitSelectionWithAttainment &&
            !_.isEmpty(
                _.get(courseUnitSelectionWithAttainment, 'substituteFor', []),
            )
        );
    },

    isEditable(courseUnit: CourseUnit, validatablePlan: ValidatablePlan) {
        return (
            validatablePlan?.plan &&
            validatablePlan.isCourseUnitInPlan(courseUnit) &&
            !validatablePlan.isCourseUnitAttained(_.get(courseUnit, 'id'))
        );
    },

};

export default CourseUnitHelper;
