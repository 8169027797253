import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import {
    AssessmentItem,
    CompletionMethod,
    CourseUnit,
    GradeRaiseAttempt,
    LocalId,
    OtmId,
    SisValidatorFn,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { forkJoin, Observable, pairwise, startWith } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LocalizedStringPipe } from 'sis-common/l10n/localized-string.pipe';
import { ModalService } from 'sis-common/modal/modal.service';
import { AlertsService, AlertType } from 'sis-components/alerts/alerts-ng.service';
import {
    CourseUnitDisplayNamesById,
    CourseUnitInfoService,
    CourseUnitInfoVersion,
} from 'sis-components/courseUnitInfo/course-unit-info.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { required } from 'sis-components/form/form-validators';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { CreditRangePipe } from 'sis-components/number/credit-range.pipe';
import { IntRangePipe } from 'sis-components/number/int-range.pipe';
import { SelectOption } from 'sis-components/select/select-combobox/select-combobox.component';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';

export interface CompletionMethodSelections {
    selectedCompletionMethod: CompletionMethod,
    selectedAssessmentItemIds: OtmId[],
    gradeRaiseAttempt: GradeRaiseAttempt,
    cancelGradeRaiseAttempt: boolean,
}

export interface SelectCompletionMethodDialogValues {
    completionMethodId: LocalId;
    assessmentItemSelections: OtmId[];
    courseUnit: CourseUnit;
    validatablePlan: ValidatablePlan,
    isGradeRaiseAttempt?: boolean,
    currentAttainmentId: OtmId,
    gradeRaiseAttempt: GradeRaiseAttempt,
}

@Component({
    selector: 'app-select-completion-method',
    templateUrl: './select-completion-method.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCompletionMethodComponent implements OnInit {
    _values: SelectCompletionMethodDialogValues;
    form: FormGroup;
    assessmentItems$: Observable<AssessmentItem[][]>;
    completionMethodAssessmentItemsRequireRange: Map<LocalId, { requireMin: number, requireMax: number, minCredits: number, maxCredits: number }> = new Map();
    options$: Observable<SelectOption[]>;

    readonly gradeRaiseAttemptControlValue = 'cancel_grade_raise_attempt';

    constructor(
        @Inject(ModalService.injectionToken) private values: SelectCompletionMethodDialogValues,
        public activeModal: NgbActiveModal,
        private fb: SisFormBuilder,
        private translocoService: TranslocoService,
        private creditRangePipe: CreditRangePipe,
        private localizedString: LocalizedStringPipe,
        private assessmentItemEntityService: AssessmentItemEntityService,
        private alertsService: AlertsService,
        private intRangePipe: IntRangePipe,
        private courseUnitInfoService: CourseUnitInfoService,
        private courseUnitEntityService: CourseUnitEntityService,
        private appErrorHandler: AppErrorHandler,
        private cdr: ChangeDetectorRef,
    ) {
        this._values = values;
    }

    ngOnInit(): void {
        if (this._values.isGradeRaiseAttempt && !this._values.gradeRaiseAttempt) {
            this.initCourseUnitVersionOptions();
        }

        this.assessmentItems$ = this.initData();
    }

    initCourseUnitVersionOptions() {
        this.options$ = this.getSelectableVersionsAndNames()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(), // Apply a default error handler
                map(([courseUnitVersions, displayNamesByCourseUnitId]) => {
                    const sortedCourseUnitVersions = _.sortBy(courseUnitVersions, 'curriculumPeriods[0].activePeriod.startDate');

                    const indexOfAttainedCourseUnit = _.findIndex(sortedCourseUnitVersions, courseUnitVersion =>
                        courseUnitVersion.courseUnit.id === this._values.courseUnit.id,
                    );

                    const filteredCourseUnitVersions = sortedCourseUnitVersions.slice(indexOfAttainedCourseUnit);

                    return this.courseUnitInfoService.createSelectOptions(filteredCourseUnitVersions, displayNamesByCourseUnitId);
                }),
            );
    }

    initData() {
        return forkJoin(this._values.courseUnit.completionMethods.map(cm =>
            this.calculateCompletionMethodAssessmentItemCreditsMinMax(cm))).pipe(
            tap(() => {
                this.form = this.fb.group({
                    selectCompletionMethod: this.fb.sisFormControl(this._values.completionMethodId, required('SELECT_COMPLETION_METHOD_MODAL.INVALID_COMPLETION_METHOD_ERROR')),
                    selectedAssessmentItems: this.fb.array(this._values.assessmentItemSelections.map(aii => this.fb.sisFormControl(aii)), this.getAssessmentItemsValidator(this._values.completionMethodId)),
                });

                /* Update validators after form values are initialized */
                this.completionMethodChanged(null, this._values.completionMethodId);

                this.form.controls['selectCompletionMethod'].valueChanges
                    .pipe(startWith(this._values.completionMethodId), pairwise())
                    .subscribe(([prev, next]: [any, any]) => this.completionMethodChanged(prev, next));
            }),
        );
    }

    getAssessmentItemsValidator(completionMethodId: LocalId): SisValidatorFn {
        const requirements = this.completionMethodAssessmentItemsRequireRange.get(completionMethodId);
        if (requirements) return this.createAssessmentItemsValidator(requirements.requireMin, requirements.requireMax);
        return null;
    }

    createAssessmentItemsValidator(min: number, max: number): SisValidatorFn {
        return (control: FormArray) =>
            control.controls.length >= min && control.controls.length <= max ? null : {
                requiredValues: {
                    translationKey: 'SELECT_COMPLETION_METHOD_MODAL.INVALID_ASSESSMENT_ITEMS_ERROR',
                    translationParams: {
                        amount: this.intRangePipe.transform({ min, max }),
                    },
                },
            };
    }

    completionMethodChanged(prev: any, next: any) {
        if (prev !== next) {
            const selectedAssessmentItems = this.selectedAssessmentItems();
            const completionMethod = this.getCompletionMethods().find((cm => cm.localId === next && cm.typeOfRequire === 'ALL_SELECTED_REQUIRED'));

            if (prev != null) {
                this.selectedAssessmentItems().clear();
            }

            this.selectedAssessmentItems().clearValidators();
            this.selectedAssessmentItems().markAsUntouched();

            if (completionMethod) {
                completionMethod.assessmentItemIds.forEach(aii => selectedAssessmentItems.push(this.fb.sisFormControl(aii)));
            } else {
                selectedAssessmentItems.setValidators(this.getAssessmentItemsValidator(next));
            }
        }
    }

    isAssessmentItemSelectedInCompletionMethod(assessmentItemId: OtmId, completionMethod: CompletionMethod) {
        if (this.selectCompletionMethod().value !== completionMethod.localId) return false;

        const assessmentItemSelection = _.find(this.selectedAssessmentItems().value, aii => aii === assessmentItemId);
        return !!assessmentItemSelection;
    }

    /**
     * Returns pre-translated completion method label.
     */
    getCompletionMethodLabel(completionMethod: CompletionMethod, index: number): string {
        const translatedLabel = this.translocoService.translate('COMPLETION_METHOD');
        const completionMethodRequirements = this.completionMethodAssessmentItemsRequireRange.get(completionMethod.localId);
        return `${translatedLabel} ${index + 1} (${this.creditRangePipe.transform({ min: completionMethodRequirements.minCredits, max: completionMethodRequirements.maxCredits })})`;
    }

    getAssessmentItemLabel(assessmentItem: AssessmentItem): string {
        return `${this.localizedString.transform(assessmentItem.name)} (${this.creditRangePipe.transform(assessmentItem.credits)})`;
    }

    assessmentItemCheckboxClicked(event: boolean, completionMethod: CompletionMethod, assessmentItemId: OtmId) {
        if (this.selectCompletionMethod().value !== completionMethod.localId) {
            this.updateCompletionMethodSelection(completionMethod);
        }

        const selectedAssessmentItems = this.selectedAssessmentItems();
        event ? selectedAssessmentItems.push(this.fb.control(assessmentItemId)) :
            selectedAssessmentItems.removeAt(selectedAssessmentItems.value.findIndex((aii: any) => aii === assessmentItemId));

        selectedAssessmentItems.markAsTouched();
        selectedAssessmentItems.updateValueAndValidity();
    }

    updateCompletionMethodSelection(completionMethod: CompletionMethod) {
        this.selectCompletionMethod().setValue(completionMethod.localId);
    }

    getCompletionMethods() {
        return this._values.courseUnit?.completionMethods.filter((cm: CompletionMethod) => cm.studyType !== 'OPEN_UNIVERSITY_STUDIES');
    }

    selectCompletionMethod(): FormControl {
        return this.form.get('selectCompletionMethod') as FormControl;
    }

    getAriaDescribedByIndex(index: number): string {
        return `range-help-${index} description-help-${index}`;
    }

    selectedAssessmentItems(): FormArray {
        return this.form.get('selectedAssessmentItems') as FormArray;
    }

    getSelectableVersionsAndNames(): Observable<[CourseUnitInfoVersion[], CourseUnitDisplayNamesById]> {
        return this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this._values.courseUnit);
    }

    changeVersion(courseUnitId: OtmId): void {
        this.getCourseUnit(courseUnitId)
            .subscribe((courseUnit: CourseUnit) => {
                this._values.courseUnit = courseUnit;
                this.initData().subscribe(() => {
                    this.cdr.markForCheck();
                });
            });
    }

    getCourseUnit(courseUnitId: OtmId) {
        return this.courseUnitEntityService.getById(courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler());
    }

    submit() {
        this.form.markAllAsTouched();
        this.selectCompletionMethod().updateValueAndValidity();
        this.selectedAssessmentItems().updateValueAndValidity();

        const selectedCompletionMethod = this.getCompletionMethods().find(cm => cm.localId === this.selectCompletionMethod().value);

        if (this.selectedAssessmentItems().valid && this.selectCompletionMethod().valid) {
            const cancelGradeRaiseAttempt = !!(this._values.isGradeRaiseAttempt && this.selectCompletionMethod().value === this.gradeRaiseAttemptControlValue);
            const isGradeRaiseAttempt = this._values.isGradeRaiseAttempt ? this._values.isGradeRaiseAttempt : false;
            const gradeRaiseAttempt: GradeRaiseAttempt = isGradeRaiseAttempt && !cancelGradeRaiseAttempt ? {
                completionMethodId: selectedCompletionMethod.localId,
                originalAttainmentId: this._values.currentAttainmentId,
                courseUnitId: this._values.courseUnit.id,
                selectedAssessmentItemIds: this.selectedAssessmentItems().value,
            } : null;

            const result: CompletionMethodSelections = cancelGradeRaiseAttempt ? {
                selectedCompletionMethod: null,
                selectedAssessmentItemIds: [],
                gradeRaiseAttempt,
                cancelGradeRaiseAttempt,
            } : {
                selectedCompletionMethod,
                selectedAssessmentItemIds: this.selectedAssessmentItems().value,
                gradeRaiseAttempt,
                cancelGradeRaiseAttempt,
            };

            this.activeModal.close(result);
            const alertTranslationKey = cancelGradeRaiseAttempt
                ? 'SELECT_COMPLETION_METHOD_MODAL.GRADE_RAISE_CANCEL_SUCCESS_ALERT'
                : 'SELECT_COMPLETION_METHOD_MODAL.SUCCESS_ALERT';
            const alertType = cancelGradeRaiseAttempt ? AlertType.SUCCESS : AlertType.INFO;
            this.alertsService.addTemporaryAlert({
                message: this.translocoService.translate(alertTranslationKey),
                type: alertType,
            });
        } else {
            if (this.selectCompletionMethod().invalid) document.getElementById('select-completion-method')?.focus();
            if (this.selectedAssessmentItems().invalid) document.getElementById(selectedCompletionMethod.localId)?.focus();
        }
    }

    calculateCompletionMethodAssessmentItemCreditsMinMax(completionMethod: CompletionMethod): Observable<AssessmentItem[]> {
        return this.assessmentItemEntityService.getByIds(completionMethod.assessmentItemIds).pipe(
            take(1),
            tap(assessmentItems => {
                let requireMin;
                let requireMax;

                if (completionMethod.typeOfRequire === 'OPTIONAL_WITH_REQUIRE_RANGE') {
                    requireMin = _.get(completionMethod, 'require.min');
                    requireMax = _.get(completionMethod, 'require.max');
                } else if (completionMethod.typeOfRequire === 'OPTIONAL_WITH_DESCRIPTION') {
                    requireMin = 1;
                    requireMax = assessmentItems.length;
                } else {
                    requireMin = assessmentItems.length;
                    requireMax = assessmentItems.length;
                }

                const sortedMinCredits = _.chain(assessmentItems)
                    .map(item => _.get(item, 'credits.min'))
                    .compact()
                    .sortBy()
                    .value();

                const minCredits = _.take(sortedMinCredits, requireMin).reduce((acc, val) => acc + val, 0);

                const sortedMaxCredits = _.chain(assessmentItems)
                    .map(item => _.get(item, 'credits.max'))
                    .compact()
                    .sortBy()
                    .reverse()
                    .value();

                const maxCredits = _.take(sortedMaxCredits, requireMax).reduce((acc, val) => acc + val, 0);

                this.completionMethodAssessmentItemsRequireRange.set(completionMethod.localId, { minCredits, maxCredits, requireMin, requireMax });
            }),
        );
    }

    cancel() {
        this.activeModal.dismiss();
    }
}
