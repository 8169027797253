import angular from 'angular';
import * as _ from 'lodash-es';
angular.module('student.common.service.educationsWithPlans', ['student.common.service.educationService', 'sis-components.service.studyRightService']).factory('educationsWithPlans', ["$q", "$log", "educationService", "commonStudyRightService", function ($q, $log, educationService, commonStudyRightService) {
  return {
    /**
     * Return Array of educations with plans
     */
    findMyPlans: function (plans) {
      const educationIds = _.uniq(_.map(plans, 'rootId'));
      return educationService.getEducationsByIds(educationIds).then(educations => {
        _.forEach(educations, education => {
          education.plans = _.filter(plans, {
            rootId: education.id
          });
        });
        return educations;
      });
    },
    filterEducationsWithActiveStudyRight: function (educations) {
      var promises = [];
      var educationIds = [];
      _.map(educations, function (education) {
        var promise = commonStudyRightService.getStudyRightByEducation(education).then(function (studyRights) {
          if (studyRights.length > 0) {
            $log.debug('active studyRights found for education', education.id, studyRights);
            educationIds.push(education.id);
          } else {
            $log.info('no active studyRight found for education', education.id);
          }
        });
        promises.push(promise);
      });
      return $q.all(promises).then(function () {
        return _.map(educationIds, function (id) {
          return _.find(educations, {
            id: id
          });
        });
      });
    }
  };
}]);