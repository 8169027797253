
import { inject } from '@angular/core';
import {
    CustomAttainmentWorkflow,
    CustomModuleContentWorkflow,
    DegreeProgrammeAttainmentWorkflow, LocalDateRange,
    ModuleAttainmentWorkflow,
    ModuleContentWorkflow,
    PriorLearningInclusionWorkflow,
    PriorLearningSubstitutionWorkflow,
    RequiredModuleContentWorkflow,
    StudyRightExtensionWorkflow,
    StudyTerm,
    Workflow,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { EMPTY, from, map, Observable } from 'rxjs';

import { COMMON_STUDY_PERIOD_SERVICE } from '../ajs-upgraded-modules';
import { STUDENT_WORKFLOW_TYPE } from '../model/student-workflow-constants';
import { UniversityService } from '../service/university.service';
import { convertAJSPromiseToNative } from '../util/utils';

export function isPriorLearningSubstitutionWorkflow(workflow: Workflow): workflow is PriorLearningSubstitutionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW;
}

export function isPriorLearningInclusionWorkflow(workflow: Workflow): workflow is PriorLearningInclusionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW;
}

export function isModuleAttainmentWorkflow(workflow: Workflow): workflow is ModuleAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.MODULE_ATTAINMENT_WORKFLOW;
}

export function isCustomAttainmentWorkflow(workflow: Workflow): workflow is CustomAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.CUSTOM_ATTAINMENT_WORKFLOW;
}

export function isRequiredModuleContentWorkflow(workflow: Workflow): workflow is RequiredModuleContentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.REQUIRED_MODULE_CONTENT_WORKFLOW;
}

export function isCustomModuleContentWorkflow(workflow: Workflow): workflow is CustomModuleContentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.CUSTOM_MODULE_CONTENT_WORKFLOW;
}

export function isModuleContentWorkflow(workflow: Workflow): workflow is ModuleContentWorkflow {
    return isCustomModuleContentWorkflow(workflow) || isRequiredModuleContentWorkflow(workflow);
}

export function isStudyRightExtensionWorkflow(workflow: Workflow): workflow is StudyRightExtensionWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.STUDY_RIGHT_EXTENSION_WORKFLOW;
}

export function isDegreeProgrammeAttainmentWorkflow(workflow: Workflow): workflow is DegreeProgrammeAttainmentWorkflow {
    return workflow.type === STUDENT_WORKFLOW_TYPE.DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW;
}

export function extensionValidityCalculator() {
    const studyPeriodService = inject(COMMON_STUDY_PERIOD_SERVICE);
    const universityService = inject(UniversityService);

    return (extensionStartDate: string, grantedTerms: number): Observable<LocalDateRange> => {
        if (!_.isEmpty(extensionStartDate) && _.isSafeInteger(grantedTerms)) {
            const startDate = moment(extensionStartDate);
            const universityOrgId = universityService.getCurrentUniversityOrgId();
            const firstYear = startDate.year() - 1;
            const numYears = Math.ceil(grantedTerms / 2) + 1;
            return from(convertAJSPromiseToNative<StudyTerm[]>(studyPeriodService.getStudyTerms(universityOrgId, firstYear, numYears)))
                .pipe(
                    map((studyTerms: StudyTerm[]) => {
                        const startTermIndex = _.findIndex(studyTerms, studyTerm => startDate.isBetween(studyTerm.valid.startDate, studyTerm.valid.endDate, 'day', '[)'));
                        return {
                            startDate: _.get(studyTerms, `[${startTermIndex}].valid.startDate`),
                            endDate: _.get(studyTerms, `[${startTermIndex + grantedTerms - 1}].valid.endDate`),
                        };
                    }),
                );
        }
        return EMPTY;
    };
}
