import * as _ from 'lodash-es';
import angular from 'angular';
import { jsDataCacheHelperModule } from 'sis-components/service/jsDataCacheHelper.service';
import { moduleJSDataModelModule } from 'sis-components/model/module.model';
import { commonCurriculumPeriodServiceModule } from 'sis-components/service/curriculumPeriod.service';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { findByIdsHelperModule } from '../utils/findByIdsHelper';
import { moduleModelModule } from '../model/moduleModel';
import { previewModeModule } from '../utils/previewMode';
import { educationServiceModule } from './educationService';
'use strict';
(function () {
  moduleService.$inject = ["$http", "$q", "$log", "moduleModel", "findByIdsHelper", "previewMode", "educationService", "commonCurriculumPeriodService", "localeService", "planModuleJSDataModel", "jsDataCacheHelper"];
  angular.module('student.common.service.moduleService', [commonCurriculumPeriodServiceModule, educationServiceModule, findByIdsHelperModule, localeServiceModule, moduleModelModule, previewModeModule, moduleJSDataModelModule, jsDataCacheHelperModule]).factory('moduleService', moduleService);
  function moduleService($http, $q, $log, moduleModel, findByIdsHelper, previewMode,
  // NOSONAR
  educationService, commonCurriculumPeriodService, localeService, planModuleJSDataModel, jsDataCacheHelper) {
    var baseUri = '/kori/api/modules';
    var authenticatedUri = '/kori/api/authenticated/modules';
    var searchUri = '/kori/api/module-search';
    var authenticatedSearchUri = '/kori/api/authenticated/module-search';
    var api = {
      get: id => {
        return api.findById(id);
      },
      getEducationById: function (id) {
        return educationService.getEducationById(id).then(function (result) {
          return toModules([result])[0];
        });
      },
      findById: function (id) {
        return api.findByIds([id]).then(function (list) {
          return list[0];
        });
      },
      findByIds: ids => {
        return jsDataCacheHelper.findByIds(planModuleJSDataModel, ids).then(data => {
          return toModules(data);
        });
      },
      findByGroupId: function (groupId, moduleCache) {
        var uri = previewMode.isPreviewMode() ? authenticatedUri : baseUri;
        var params = {
          groupId: groupId
        };
        if (previewMode.isPreviewMode()) {
          params.documentState = previewMode.documentStatesForPreview();
        }
        return planModuleJSDataModel.findAll(params, {
          endpoint: uri
        }).then(result => {
          return toModules(result, moduleCache);
        });
      },
      /*
          Optional parameters in params include:
              uiLang
              start
              limit
              validity
              curriculumPeriodIds
              organisationRootIds
              studyRightSelectionTypes
          For more information see fi.helsinki.otm.kori.web.ModuleController
       */
      search: function (fullTextQuery, params) {
        var uri = previewMode.isPreviewMode() ? authenticatedSearchUri : searchUri;
        var queryParams = {
          fullTextQuery: fullTextQuery,
          uiLang: _.get(params, 'uiLang', localeService.getCurrentLocale()),
          start: _.get(params, 'start', 0),
          limit: _.get(params, 'limit', 100),
          validity: params.validity,
          orgRootId: params.organisationRootIds,
          moduleType: 'StudyModule',
          curriculumPeriodId: _.get(params, 'curriculumPeriodIds'),
          codeUrn: _.get(params, 'codeUrn')
        };
        if (previewMode.isPreviewMode()) {
          queryParams.documentState = previewMode.documentStatesForPreview();
        }
        return $http.get(uri, {
          params: queryParams
        }).then(function (result) {
          return result.data;
        });
      },
      findModuleVersion: function (moduleGroupId) {
        return commonCurriculumPeriodService.getCurrentCurriculumPeriod().then(function (curriculumPeriod) {
          return $http.get(baseUri, {
            params: {
              groupId: moduleGroupId
            }
          }).then(function (response) {
            var versions = response.data;
            return commonCurriculumPeriodService.findVersionForCurriculumPeriodId(versions, curriculumPeriod.id).then(function (module) {
              return module;
            });
          }, function () {
            $log.error('Could not find module versions for groupId ' + moduleGroupId);
          });
        });
      }
    };
    function toModules(list, moduleCache) {
      return _.map(list, function (data) {
        if (moduleCache && moduleCache[data.id]) {
          return moduleCache[data.id];
        }
        return moduleModel.newModule(data);
      });
    }
    return api;
  }
})();