import angular from 'angular';
import * as _ from 'lodash-es';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { personGroupModelModule } from '../model/personGroup.model';
import { personGroupDetailsModelModule } from '../model/personGroupDetails.model';
import { jsDataCacheHelperModule } from './jsDataCacheHelper.service';
export const commonPersonGroupServiceModule = 'sis-components.service.commonPersonGroupService';
(function () {
  commonPersonGroupService.$inject = ["personGroupJSDataModel", "personGroupDetailsJSDataModel", "PersonGroupEndpoint", "PersonGroupDetailsEndpoint", "$http", "localeService", "jsDataCacheHelper"];
  angular.module(commonPersonGroupServiceModule, [localeServiceModule, personGroupModelModule, personGroupDetailsModelModule, jsDataCacheHelperModule]).factory('commonPersonGroupService', commonPersonGroupService);

  /**
   * @ngInject
   */
  function commonPersonGroupService(personGroupJSDataModel, personGroupDetailsJSDataModel, PersonGroupEndpoint, PersonGroupDetailsEndpoint, $http, localeService, jsDataCacheHelper) {
    // Create and save methods are in tutor / person group service as it is the only one that needs those
    const api = {
      searchByFullText(type, fullTextQuery, start, limit) {
        const params = {
          params: {
            uiLang: localeService.getCurrentLocale(),
            personGroupType: type,
            searchString: fullTextQuery,
            start: start || 0,
            limit: limit || 20
          }
        };
        return api.searchByParams(params);
      },
      searchByParams(params) {
        return $http.get(PersonGroupEndpoint.SEARCH, params).then(result => result.data);
      },
      findById(personGroupId, bypassCache) {
        return personGroupJSDataModel.find(personGroupId, {
          bypassCache: !!bypassCache
        });
      },
      findPersonGroupDetailsById(personGroupId) {
        return personGroupDetailsJSDataModel.find(personGroupId);
      },
      findPersonGroupDetailsByIds(personGroupIds) {
        return jsDataCacheHelper.findByIds(personGroupDetailsJSDataModel, personGroupIds);
      },
      getOwnPersonGroupDetails() {
        const options = {
          endpoint: PersonGroupDetailsEndpoint.OWN
        };
        return personGroupDetailsJSDataModel.findAll({}, options);
      }
    };
    return api;
  }
})();