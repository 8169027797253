import angular from 'angular';
import * as _ from 'lodash-es';
import { commonModuleServiceModule } from '../service/module.service';
import { commonCourseUnitServiceModule } from '../service/courseUnit.service';
import { ModuleBoxComponent } from '../module/module-box/module-box.component.ts';
import { CourseUnitBoxComponent } from '../courseUnit/course-unit-box/course-unit-box.component.ts';
import prerequisiteGroupsTpl from './prerequisiteGroups.tpl.html';
export const prerequisiteGroupsModule = 'sis-components.prerequisites.prerequisiteGroups';
(function () {
  prerequisiteGroupsCtrl.$inject = ["commonModuleService", "commonCourseUnitService", "defaultPromiseHandler"];
  angular.module(prerequisiteGroupsModule, ['sis-components.courseUnit', commonModuleServiceModule, commonCourseUnitServiceModule, ModuleBoxComponent.downgrade.moduleName, CourseUnitBoxComponent.downgrade.moduleName]).component('prerequisiteGroups', {
    template: prerequisiteGroupsTpl,
    controller: prerequisiteGroupsCtrl,
    bindings: {
      prerequisites: '<',
      documentStates: '<?'
    }
  });

  /**
   * @ngInject
   */
  function prerequisiteGroupsCtrl(commonModuleService, commonCourseUnitService, defaultPromiseHandler) {
    const $ctrl = this;
    $ctrl.courseUnitsByGroupId = {};
    $ctrl.modulesByGroupId = {};
    $ctrl.$onChanges = changesObj => {
      if (_.get(changesObj, 'documentStates')) {
        // clear results as they are not necessarily same with changed document states
        $ctrl.courseUnitsByGroupId = {};
        $ctrl.modulesByGroupId = {};
      }
      loadStudies();
    };

    // There could be some optimisation to not reload studies that are present. It would require a bit more technical and detailed
    // implementation.
    function loadStudies() {
      const cuGroupIds = _.chain($ctrl.prerequisites).flatMap('prerequisites').map('courseUnitGroupId').compact().uniq().value();
      commonCourseUnitService.findByGroupIds(cuGroupIds, undefined, $ctrl.documentStates).then(courseUnits => {
        $ctrl.courseUnitsByGroupId = _.merge($ctrl.courseUnitsByGroupId, _.keyBy(courseUnits, 'groupId'));
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      const mGroupIds = _.chain($ctrl.prerequisites).flatMap('prerequisites').map('moduleGroupId').compact().uniq().value();
      commonModuleService.findByGroupIds(mGroupIds, undefined, undefined, $ctrl.documentStates).then(modules => {
        $ctrl.modulesByGroupId = _.merge($ctrl.modulesByGroupId, _.keyBy(modules, 'groupId'));
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
  }
})();