import * as _ from 'lodash-es';

import {
    ModuleContentApprovalState,
    PlanValidationState,
    RangeValidationResultState,
} from '../../../types/baseTypes.js';
import {
    AnyCourseUnitRule,
    AnyModuleRule,
    CompositeRule, CourseUnit,
    CourseUnitCountRule, CourseUnitRule,
    CreditsRule, CustomCourseUnitAttainment, CustomModuleAttainment, CustomStudyDraft,
    EntityWithRule, ModuleRule,
    Rule,
} from '../../../types/generated/common-backend.js';
import { Range } from '../../model/range.js';
import { RangeValidation } from '../../plan/validation/rangeValidation.js';
import { PlanValidationStateService } from '../../service/planValidationState.service.js';
import { RuleSortingService } from '../../service/ruleSorting.service.js';
import { isModule } from '../planUtils.js';

import { AttainmentValidation } from './attainmentValidation.js';
import { ModuleContext } from './context/moduleContext.js';
import { RuleContext } from './context/ruleContext.js';
import { CourseUnitValidation } from './courseUnitValidation.js';
import { ModuleContentApplicationValidation } from './moduleContentApplicationValidation.js';
import { PlanValidationResult } from './planValidationResult.js';
import { ValidatablePlan } from './validatablePlan.js';

export class Validation {

    public static validateModule(module: EntityWithRule, validatablePlan: ValidatablePlan, planValidationResult: PlanValidationResult, customRule?: Rule): RuleContext {
        const ruleContext = new RuleContext();
        if (isModule(module) && validatablePlan.getModuleAttainment(module.id)) {
            AttainmentValidation.validateModuleAttainment(module, validatablePlan, ruleContext, planValidationResult);
        } else {
            Validation.validatePlan(module, validatablePlan, ruleContext, planValidationResult, customRule);
            if (['DegreeProgramme', 'StudyModule', 'GroupingModule'].includes(module.type) && ruleContext.state === PlanValidationState.ATTAINED) {
                ruleContext.mergeState(PlanValidationState.PARTS_ATTAINED);
            }
        }
        ruleContext.addModule(module);
        const moduleValidationResults = ruleContext.getResults();

        if (isModule(module) && module.moduleContentApprovalRequired) {
            moduleValidationResults.approvalRequiredState = ModuleContentApplicationValidation.getApprovalRequiredState(module, planValidationResult);
        }

        if (!!ruleContext.moduleContentApprovalValidationState) {
            moduleValidationResults.moduleContentApprovalValidationState = ruleContext.moduleContentApprovalValidationState;
            moduleValidationResults.moduleContentApprovalState = ModuleContentApplicationValidation.getModuleContentApprovalState(module, planValidationResult);
            moduleValidationResults.isModuleContentApproved = ModuleContentApplicationValidation.isModuleContentApproved(moduleValidationResults.moduleContentApprovalState);
        }

        moduleValidationResults.validationStateOfChildren = Validation.getValidationStateOfChildren(validatablePlan, module, planValidationResult);
        moduleValidationResults.hasInvalidOrMissingSelections = Validation.hasInvalidOrMissingSelections(validatablePlan, module, planValidationResult, customRule);

        planValidationResult.moduleValidationResults[module.id] = moduleValidationResults;

        return ruleContext;
    }

    public static validatePlan(module: EntityWithRule, validatablePlan: ValidatablePlan, ruleContext: RuleContext, planValidationResult: PlanValidationResult, customRule?: Rule): void {
        const moduleContext = new ModuleContext(module, validatablePlan, RuleSortingService.getRuleSortDetailsMap);
        const rule = customRule || module.rule;

        let ruleValidationResult;
        if (rule) {
            ruleValidationResult = Validation.validateRule(rule, validatablePlan, moduleContext, planValidationResult);
        }
        if (ruleValidationResult || !rule) {
            if (ruleValidationResult) {
                ruleContext.mergeContext(ruleValidationResult);
            }

            if (!moduleContext.isEmpty()) {
                _.forEach(moduleContext.unmatchedModulesById, (unMatchedModule) => {
                    ruleContext.mergePartial(Validation.validateModule(unMatchedModule, validatablePlan, planValidationResult), null);
                    _.set(planValidationResult.moduleValidationResults[unMatchedModule.id], 'invalidSelection', true);
                    planValidationResult.moduleIndexes[unMatchedModule.id] = null;
                });
                _.forEach(moduleContext.unmatchedCourseUnitsById, (courseUnit) => {
                    ruleContext.mergePartial(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult), null);
                    _.set(planValidationResult.courseUnitValidationResults[courseUnit.id], 'invalidSelection', true);
                    planValidationResult.courseUnitIndexes[courseUnit.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomModuleAttainmentsById, (customModuleAttainment) => {
                    ruleContext.mergeState(PlanValidationState.ATTAINED);
                    AttainmentValidation.validateCustomModuleAttainment(customModuleAttainment, ruleContext);
                    const cmaValidationResult = _.get(planValidationResult.customModuleAttainmentValidationResults, customModuleAttainment.id) || {};
                    _.set(cmaValidationResult, 'invalidSelection', true);
                    _.set(planValidationResult.customModuleAttainmentValidationResults, customModuleAttainment.id, cmaValidationResult);
                    planValidationResult.customModuleAttainmentIndexes[customModuleAttainment.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomCourseUnitAttainmentsById, (customCourseUnitAttainment) => {
                    ruleContext.mergeState(PlanValidationState.ATTAINED);
                    AttainmentValidation.validateCustomCourseUnitAttainment(customCourseUnitAttainment, ruleContext);
                    const ccuaValidationResult = _.get(planValidationResult.customCourseUnitAttainmentValidationResults, customCourseUnitAttainment.id) || {};
                    _.set(ccuaValidationResult, 'invalidSelection', true);
                    _.set(planValidationResult.customCourseUnitAttainmentValidationResults, customCourseUnitAttainment.id, ccuaValidationResult);
                    planValidationResult.customCourseUnitAttainmentIndexes[customCourseUnitAttainment.id] = null;
                });
                _.forEach(moduleContext.unmatchedCustomStudyDraftsById, (customStudyDraft) => {
                    ruleContext.addCustomStudyDraft(customStudyDraft);
                    ruleContext.addPlannedCredits(new Range(customStudyDraft.credits));
                    ruleContext.mergeState(PlanValidationState.INVALID);
                    Validation.validateContentFilterForCustomStudyDraft(customStudyDraft, validatablePlan, ruleContext);
                });

                // Module without rule (e.g. synthetic GroupingModule of ModuleAttainment) allows anything
                if (rule) {
                    ruleContext.mergeState(PlanValidationState.INVALID);
                }
            }
        }

        // Finally, module content approval can override invalid selections set in normal validation
        const matchingModuleContentApproval = validatablePlan.getEffectiveModuleContentApproval(module.id);
        if (matchingModuleContentApproval) {
            _.set(planValidationResult.matchingModuleContentApprovalsByModuleId, module.id, matchingModuleContentApproval);
            const mcaModuleContext = new ModuleContext(module, validatablePlan, RuleSortingService.getRuleSortDetailsMap);
            ruleContext.moduleContentApprovalValidationState =
                ModuleContentApplicationValidation.validateModuleContentApplication(matchingModuleContentApproval, mcaModuleContext, planValidationResult);

            if (ruleContext.moduleContentApprovalValidationState === ModuleContentApprovalState.INVALID) {
                ruleContext.state = PlanValidationState.INVALID;
                ruleContext.contextualState = PlanValidationState.INVALID;
            } else {
                ruleContext.state = Validation.getValidationStateOfChildren(validatablePlan, module, planValidationResult);
                ruleContext.contextualState = Validation.getContextualStateOfChildren(validatablePlan, module, planValidationResult);
            }
        } else if (isModule(module) && module.moduleContentApprovalRequired) {

            // updating ruleValidationResult does not seem to have any effect anywhere.
            // the state should probably be merged to ruleContext. For now it is left as it was.

            if (!_.isUndefined(ruleValidationResult) && _.get(ruleValidationResult, 'state')) {
                ruleValidationResult.state = PlanValidationStateService.higherPriorityOf(ruleValidationResult.state, PlanValidationState.INCOMPLETE);
            } else {
                ruleValidationResult = {
                    state: PlanValidationState.INCOMPLETE,
                };
            }

        }
    }

    public static getValidationStateOfChildren(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult): PlanValidationState {
        return Validation.getStateOfChildren(validatablePlan, module, planValidationResult, false);
    }

    public static getContextualStateOfChildren(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult): PlanValidationState {
        return Validation.getStateOfChildren(validatablePlan, module, planValidationResult, true);
    }

    public static getStateOfChildren(
        validatablePlan: ValidatablePlan,
        module: EntityWithRule,
        planValidationResult: PlanValidationResult,
        getContextualState: boolean = false,
    ): PlanValidationState {

        let mergedStateOfChildren: PlanValidationState;

        mergedStateOfChildren = PlanValidationState.EMPTY;

        const disallowedContextStatesForModule = getContextualState ? PlanValidationStateService.disallowedContextStatesForModule : [];

        const customStudyDrafts = validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id);

        if (!_.isEmpty(customStudyDrafts)) {
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(mergedStateOfChildren, PlanValidationState.PLANNED);
        }

        _.forEach(validatablePlan.getSelectedModulesUnderModule(module), (childModule) => {
            const moduleValidationState = _.get(planValidationResult.moduleValidationResults, [childModule.id, 'state']);
            const contextualValidationState = _.includes(disallowedContextStatesForModule, moduleValidationState) ? PlanValidationState.PLANNED : moduleValidationState;
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(
                mergedStateOfChildren,
                contextualValidationState,
            );
        });

        _.forEach(validatablePlan.getSelectedCourseUnitsUnderModule(module), (courseUnit) => {
            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(
                mergedStateOfChildren,
                _.get(_.get(planValidationResult.courseUnitValidationResults, courseUnit.id), 'state'));
        });

        if (!_.isEmpty(validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module)) ||
            !_.isEmpty(validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module))) {

            mergedStateOfChildren = PlanValidationStateService.higherPriorityOf(mergedStateOfChildren, PlanValidationState.ATTAINED);
        }

        if (mergedStateOfChildren === PlanValidationState.ATTAINED) {
            return PlanValidationState.PARTS_ATTAINED;
        }
        return mergedStateOfChildren;
    }

    public static hasInvalidOrMissingSelections(validatablePlan: ValidatablePlan, module: EntityWithRule, planValidationResult: PlanValidationResult, customRule?: Rule): boolean {
        const studies: any[] = [];
        const rule = customRule || module.rule;

        if (_.some(_.concat(
            studies,
            validatablePlan.getSelectedModulesUnderModule(module),
            validatablePlan.getSelectedCourseUnitsUnderModule(module),
            validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module),
            validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module)),
                   childStudy => _.get(Validation.getValidationResultForStudy(childStudy, planValidationResult), 'invalidSelection') === true)
        ) {
            return true;
        }

        return !!rule && Validation.isRuleMissingSelections(module, rule, planValidationResult);
    }

    public static isRuleMissingSelections(module: EntityWithRule, rule: any, planValidationResult: PlanValidationResult): boolean {
        const ruleValidationResults = _.get(_.get(planValidationResult.ruleValidationResults, module.id), <string> rule.localId);
        if (!ruleValidationResults) {
            return false;
        }
        if (_.get(ruleValidationResults, 'active') === false) {
            return false;
        }

        switch (_.get(rule, 'type')) {
            case 'CourseUnitRule':
                if (!_.get(ruleValidationResults, 'state') || _.get(ruleValidationResults, 'state') === PlanValidationState.EMPTY) {
                    return true;
                }
                break;
            case 'ModuleRule':
                if (!_.get(ruleValidationResults, 'selectedModulesById')) {
                    return true;
                }
                break;
            case 'CreditsRule':
                // credits rule is considered separately
                break;
            default:
                if (_.get(ruleValidationResults, 'result') === RangeValidationResultState.MORE_REQUIRED ||
                    _.get(ruleValidationResults, 'result') === RangeValidationResultState.LESS_REQUIRED) {
                    return true;
                }
        }

        return _.some(_.concat(rule.rule, rule.rules), subRule =>
            !!subRule && Validation.isRuleMissingSelections(module, subRule, planValidationResult));
    }

    private static getValidationResultForStudy(study: any, planValidationResult: PlanValidationResult): PlanValidationResult | null {
        if (['StudyModule', 'DegreeProgramme'].includes(study.type)) {
            return _.get(planValidationResult.moduleValidationResults, study.id, null);
        }
        if (study.type === 'CustomCourseUnitAttainment') {
            return _.get(planValidationResult.customCourseUnitAttainmentValidationResults, study.id, null);
        }
        if (study.type === 'CustomModuleAttainment') {
            return _.get(planValidationResult.customModuleAttainmentValidationResults, study.id, null);
        }

        // since the study did not match any of the above types, we assume that it is a courseUnit

        return _.get(planValidationResult.courseUnitValidationResults, study.id, null);
    }

    static validateRule(rule: Rule,
                        validatablePlan: ValidatablePlan,
                        moduleContext: ModuleContext,
                        planValidationResult: PlanValidationResult,
    ): RuleContext {

        switch (rule.type) {
            case 'CreditsRule':
                return Validation.validateCreditsRule(<CreditsRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CourseUnitCountRule':
                return Validation.validateCourseUnitCountRule(<CourseUnitCountRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CompositeRule':
                return Validation.validateCompositeRule(<CompositeRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'ModuleRule':
                return Validation.validateModuleRule(<ModuleRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'AnyModuleRule':
                return Validation.validateAnyModuleRule(<AnyModuleRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'CourseUnitRule':
                return Validation.validateCourseUnitRule(<CourseUnitRule> rule, validatablePlan, moduleContext, planValidationResult);
            case 'AnyCourseUnitRule':
                return Validation.validateAnyCourseUnitRule(<AnyCourseUnitRule> rule, validatablePlan, moduleContext, planValidationResult);
            default:
                return new RuleContext();
        }
    }

    static validateCreditsRule(
        creditsRule: CreditsRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        ruleContext.mergeContext(Validation.validateRule(creditsRule.rule, validatablePlan, moduleContext, planValidationResult));

        const result = RangeValidation.validateRange(new Range(creditsRule.credits), ruleContext.getActualCredits());
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));

        const ruleValidationResults = ruleContext.getResults({
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
        });
        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            creditsRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCourseUnitCountRule(
        courseUnitCountRule: CourseUnitCountRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        ruleContext.mergeContext(Validation.validateRule(courseUnitCountRule.rule, validatablePlan, moduleContext, planValidationResult));
        const directCount = _.size(ruleContext.matchingCourseUnitsByGroupId) + _.size(ruleContext.matchingCustomCourseUnitAttainmentsById);
        const result = RangeValidation.validateRange(
            new Range(courseUnitCountRule.count),
            new Range(directCount));
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));

        const ruleValidationResults = ruleContext.getResults({
            directCount,
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
        });
        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            courseUnitCountRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCompositeRule(
        compositeRule: CompositeRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        let directCount = 0;
        let implicitCount = 0;
        let implicitCourseUnitIds: string[] = [];
        let implicitModuleIds: string[] = [];

        const sortedRules = RuleSortingService.sortRules(compositeRule.rules, moduleContext.ruleSortDetailsMap);

        _.forEach(sortedRules, (rule) => {

            const childCtx = Validation.validateRule(rule, validatablePlan, moduleContext, planValidationResult);
            if (childCtx.isActive()) {
                directCount += 1;
                ruleContext.mergeContext(childCtx);
            } else if (childCtx.state === PlanValidationState.IMPLICIT) {
                implicitCount += 1;
                implicitCourseUnitIds = _.concat(implicitCourseUnitIds, childCtx.implicitCourseUnitIds);
                implicitModuleIds = _.concat(implicitModuleIds, childCtx.implicitModuleIds);
                ruleContext.mergeStatesFromOtherContext(childCtx);
            }
        });
        const require = _.defaultTo(compositeRule.require, {
            min: _.size(compositeRule.rules),
            max: _.size(compositeRule.rules),
        });
        const result = RangeValidation.validateRange(new Range(require), new Range(directCount, directCount + implicitCount));
        if (result.result === RangeValidationResultState.IMPLICIT || result.result === RangeValidationResultState.IMPLICIT_OK) {
            ruleContext.mergeImplicitSelections(implicitCourseUnitIds, implicitModuleIds);
        }
        ruleContext.mergePlanValidationState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.state));
        ruleContext.mergeContextualState(PlanValidationStateService.fromRangeValidationResult(result, ruleContext.contextualState));
        const ruleValidationResults = ruleContext.getResults({
            directCount,
            implicitCount,
            result: result.result,
            minRequired: result.minRequired,
            maxAllowed: result.maxAllowed,
            matchingModulesByGroupId: ruleContext.matchingModulesByGroupId,
            matchingCourseUnitsByGroupId: ruleContext.matchingCourseUnitsByGroupId,
            matchingCustomModuleAttainmentsById: ruleContext.matchingCustomModuleAttainmentsById,
            matchingCustomCourseUnitAttainmentsById: ruleContext.matchingCustomCourseUnitAttainmentsById,
        });
        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            compositeRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateModuleRule(
        moduleRule: ModuleRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        const module = validatablePlan.getModuleInPlanByGroupId(moduleRule.moduleGroupId);
        if (module) {
            if (moduleContext.consumeModule(module, planValidationResult)) {
                const result = Validation.validateModule(module, validatablePlan, planValidationResult);
                ruleContext.mergePartialRuleContextForModule(result);
            } else {
                ruleContext.addImplicitModule(module);
                ruleContext.mergeState(PlanValidationState.IMPLICIT);
            }
            Validation.validateContentFilterForModule(module, validatablePlan, ruleContext);
        }
        const ruleValidationResults = ruleContext.getResults({
            selectedModulesById: _.keyBy([module], 'id'),
        });

        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            moduleRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    static validateAnyModuleRule(
        anyModuleRule: AnyModuleRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        _.forEach(moduleContext.unmatchedModulesById, (module) => {
            moduleContext.consumeModule(module, planValidationResult);
            const result = Validation.validateModule(module, validatablePlan, planValidationResult);
            ruleContext.mergePartialRuleContextForModule(result);
            Validation.validateContentFilterForModule(module, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomModuleAttainmentsById, (customModuleAttainment) => {
            moduleContext.consumeCustomModuleAttainment(customModuleAttainment, planValidationResult);
            ruleContext.addCustomModuleAttainment(customModuleAttainment);
            AttainmentValidation.validateCustomModuleAttainment(customModuleAttainment, ruleContext);
            Validation.validateContentFilterForCustomAttainment(customModuleAttainment, validatablePlan, ruleContext);
        });
        const ruleValidationResults = ruleContext.getResults({
            selectedModulesById: _.keyBy(ruleContext.matchingModulesByGroupId, 'id'),
            selectedCustomModuleAttainmentsById: _.keyBy(ruleContext.matchingCustomModuleAttainmentsById, 'id'),
        });

        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            anyModuleRule,
            ruleValidationResults,
            planValidationResult,
        );
        return ruleContext;
    }

    static validateCourseUnitRule(
        courseUnitRule: CourseUnitRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        const courseUnit = validatablePlan.getCourseUnitInPlanByGroupId(courseUnitRule.courseUnitGroupId);
        if (courseUnit) {
            if (moduleContext.consumeCourseUnit(courseUnit, planValidationResult)) {
                ruleContext.mergeContext(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult));
            } else {
                ruleContext.addImplicitCourseUnit(courseUnit);
                ruleContext.mergeState(PlanValidationState.IMPLICIT);
            }
            Validation.validateContentFilterForCourseUnit(courseUnit, validatablePlan, ruleContext);
        }
        const ruleValidationResults = ruleContext.getResults({
            selectedCourseUnitsById: _.keyBy([courseUnit], 'id'),
        });

        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            courseUnitRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    static validateAnyCourseUnitRule(
        anyCourseUnitRule: AnyCourseUnitRule,
        validatablePlan: ValidatablePlan,
        moduleContext: ModuleContext,
        planValidationResult: PlanValidationResult,
    ): RuleContext {

        const ruleContext = new RuleContext();
        _.forEach(moduleContext.unmatchedCourseUnitsById, (courseUnit) => {
            moduleContext.consumeCourseUnit(courseUnit, planValidationResult);
            ruleContext.mergeContext(CourseUnitValidation.validateCourseUnit(courseUnit, validatablePlan, planValidationResult));

            Validation.validateContentFilterForCourseUnit(courseUnit, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomCourseUnitAttainmentsById, (customCourseUnitAttainment) => {
            moduleContext.consumeCustomCourseUnitAttainment(customCourseUnitAttainment, planValidationResult);
            ruleContext.addCustomCourseUnitAttainment(customCourseUnitAttainment);
            AttainmentValidation.validateCustomCourseUnitAttainment(customCourseUnitAttainment, ruleContext);
            Validation.validateContentFilterForCustomAttainment(customCourseUnitAttainment, validatablePlan, ruleContext);
        });

        _.forEach(moduleContext.unmatchedCustomStudyDraftsById, (customStudyDraft) => {
            moduleContext.consumeCustomStudyDraft(customStudyDraft);
            ruleContext.addCustomStudyDraft(customStudyDraft);
            ruleContext.addPlannedCredits(new Range(customStudyDraft.credits));
            Validation.validateContentFilterForCustomStudyDraft(customStudyDraft, validatablePlan, ruleContext);
        });

        const ruleValidationResults = ruleContext.getResults({
            selectedCourseUnitsById: _.keyBy(ruleContext.matchingCourseUnitsByGroupId, 'id'),
            selectedCustomCourseUnitAttainmentsById: _.keyBy(ruleContext.matchingCustomCourseUnitAttainmentsById, 'id'),
            selectedCustomStudyDraftsById: _.keyBy(ruleContext.matchingCustomStudyDraftsById, 'id'),
        });

        Validation.updateRuleValidationResult(
            moduleContext.module.id,
            anyCourseUnitRule,
            ruleValidationResults,
            planValidationResult,
        );

        return ruleContext;
    }

    private static validateContentFilterForModule(
        module: EntityWithRule,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType && !_.isEqual(studyRightSelectionType, _.get(module, 'studyRightSelectionType'))) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    private static validateContentFilterForCourseUnit(
        courseUnit: CourseUnit,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    static validateContentFilterForCustomAttainment(
        attainment: CustomCourseUnitAttainment | CustomModuleAttainment,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForCustomAttainment(attainment);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType && attainment.type !== 'CustomModuleAttainment') {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    static validateContentFilterForCustomStudyDraft(
        customStudyDraft: CustomStudyDraft,
        validatablePlan: ValidatablePlan,
        ruleContext: RuleContext,
    ): void {

        const parentModule = validatablePlan.getModule(customStudyDraft.parentModuleId);
        const studyRightSelectionType = _.get(parentModule, 'contentFilter.studyRightSelectionType');
        if (studyRightSelectionType) {
            ruleContext.mergeState(PlanValidationState.INVALID);
        }
    }

    private static updateRuleValidationResult(
        parentModuleId: string,
        rule: Rule,
        ruleValidationResult: any,
        planValidationResult: PlanValidationResult,
    ): void {

        if (!_.has(planValidationResult.ruleValidationResults, parentModuleId)) {
            planValidationResult.ruleValidationResults[parentModuleId] = {};
        }
        _.set(_.get(planValidationResult.ruleValidationResults, parentModuleId), <string> rule.localId, ruleValidationResult);
    }

}
