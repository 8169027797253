import { Injectable } from '@angular/core';
import {
    OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
} from 'common-typescript/constants';
import { ModuleToggles, Organisation, OtmId, UniversityOrganisation, UniversitySettings } from 'common-typescript/types';
import _, { get } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigService } from 'sis-common/config/config.service';
import { LocalStorageService } from 'sis-common/storage/local-storage.service';

import { OrganisationEntityService } from '../service/organisation-entity.service';
import { UniversitySettingsEntityService } from '../service/university-settings-entity.service';

@Injectable({ providedIn: 'root' })
export class UniversityService {

    private university: UniversityOrganisation;
    readonly storageKey = 'selected_university';
    constructor(private configService: ConfigService,
                private localStorageService: LocalStorageService,
                private organisationEntityService: OrganisationEntityService,
                private universitySettingsEntityService: UniversitySettingsEntityService,
                private logger: NGXLogger) {
        this.university = null;
    }

    /**
     * @deprecated If possible, use OrganisationEntityService.getRootOrganisations() directly
     */
    getUniversities(): Observable<Organisation[]> {
        return this.organisationEntityService.getRootOrganisations();
    }

    setCurrentUniversityOrgId(id: OtmId): UniversityOrganisation {
        this.university = _.find(this.configService.get().homeOrganisations, { id });

        if (!this.university) {
            throw Error(`Setting current university to ${id} failed, not any home organisation`);
        }
        this.localStorageService.setItem(this.storageKey, this.university.id);
        return this.university;
    }

    getCurrentUniversity(): UniversityOrganisation {
        if (!this.university) {
            const universityOrgId = this.localStorageService.getItem(this.storageKey);

            this.university = _.find(this.configService.get().homeOrganisations, { id: universityOrgId });

            if (!this.university) {
                const defaultUniversityOrgId = _.get(_.first(this.configService.get().homeOrganisations), 'id');
                this.university = this.setCurrentUniversityOrgId(defaultUniversityOrgId);
                this.logger.warn('selectedUniversity not found returning first', 'defaultUniversityOrgId', defaultUniversityOrgId,
                                 'localStorageValue:', universityOrgId, 'UniversityConfig:', this.configService.get());
            }
        }
        return this.university;
    }

    getCurrentUniversityOrgId(): OtmId {
        return this.getCurrentUniversity().id;
    }

    /**
     * If the university configuration isTestModeEnabled is true, the ModuleToggles property of UniversitySettings be overridden by the values in the moduleToggleOverrides property in local storage.
     * This allows the user to locally set module toggles without the need for updating UniversitySettings in the back end, which makes for easier testing.
     * isTestModeEnabled should never be true in production environments.
     * */
    getCurrentUniversitySettings(bypassModuleToggleOverride?: boolean): Observable<UniversitySettings> {
        return this.universitySettingsEntityService.getById(this.getCurrentUniversityOrgId()).pipe(
            switchMap(settings =>
                iif(
                    () => !this.configService.get().isTestModeEnabled || bypassModuleToggleOverride,
                    of(settings),
                    of(settings).pipe(map(value => this.addLocalOverrides(value))),
                ),
            ),
        );
    }

    addLocalOverrides(universitySettings: UniversitySettings) {
        const localOverrides: ModuleToggles = JSON.parse(this.localStorageService.getItem('moduleToggleOverrides'));
        if (!localOverrides) {
            return universitySettings;
        }
        const moduleTogglesWithOverrides: ModuleToggles = { ...universitySettings.moduleToggles };

        for (const [key, value] of Object.entries(localOverrides)) {
            const typedKey = key as keyof ModuleToggles;
            moduleTogglesWithOverrides[typedKey] = value;
        }
        return {
            ...universitySettings,
            moduleToggles: {
                ...moduleTogglesWithOverrides,
            },
        };
    }

    updateCurrentUniversitySettings(universitySettings: UniversitySettings): Observable<UniversitySettings> {
        return this.universitySettingsEntityService.updateById(this.getCurrentUniversityOrgId(), universitySettings);
    }

    getLocalStorageCurrentUniversityOrgId(): OtmId {
        return this.localStorageService.getItem(this.storageKey);
    }

    openUniversityFeaturesEnabledForStudent(): Observable<boolean> {
        return this.getCurrentUniversitySettings().pipe(
            map(settings => get(
                settings,
                'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent',
                OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
            )),
        );
    }
}
