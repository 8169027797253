import * as _ from 'lodash-es';
import angular from 'angular';
import { searchParameterModelsModule } from './searchParameterModels';
import { searchParameterServiceModule } from './searchParameters';
export const searchParameterStorageServiceModule = 'sis-components.search.searchParameterStorageService';
(function () {
  searchParameterStorageService.$inject = ["SearchParameter", "HiddenParameter", "$window"];
  angular.module(searchParameterStorageServiceModule, [searchParameterModelsModule, searchParameterServiceModule]).factory('searchParameterStorageService', searchParameterStorageService);

  /**
   * @ngInject
   */
  function searchParameterStorageService(SearchParameter, HiddenParameter, $window) {
    var searchParamPrefix = 'searchParameter_';
    return {
      saveSearchParameterToStorage: function (path, searchParameters) {
        var fieldsToBeSaved = _.pickBy(_.mapValues(searchParameters, function (value) {
          //Leave only fields with searchParameters
          if ((value instanceof SearchParameter || value instanceof HiddenParameter) && value.isValidForSearch()) {
            return value.getValues();
          }
        }), function (value) {
          // remove undefined values
          return value;
        });
        $window.sessionStorage.setItem(searchParamPrefix + path, angular.toJson(fieldsToBeSaved));
      },
      clearSearchParameterFromStorage: function (path) {
        $window.sessionStorage.removeItem(searchParamPrefix + path);
      },
      loadSearchParameterFromStorage: function (path, searchParameters) {
        var storageItem = $window.sessionStorage.getItem(searchParamPrefix + path);
        if (!storageItem) {
          return false;
        }
        var savedSearchParameters = angular.fromJson(storageItem);
        for (let key in searchParameters) {
          const value = searchParameters[key];
          // Clear all saved parameters, to avoid removed filters to be reset to default
          if ((value instanceof SearchParameter || value instanceof HiddenParameter) && value.isValidForSearch()) {
            value.removeAllValues();
          }
        }
        _.forEach(savedSearchParameters, function (values, key) {
          if (searchParameters[key] instanceof SearchParameter || searchParameters[key] instanceof HiddenParameter) {
            searchParameters[key].removeAllValues();
            if (values instanceof Array) {
              _.forEach(values, function (parameter) {
                searchParameters[key].toggleValue(parameter);
              });
            } else {
              searchParameters[key].toggleValue(values);
            }
          } else {
            searchParameters[key] = values;
          }
        });
        return true;
      }
    };
  }
})();