import * as _ from 'lodash-es';

import { PlanValidationState, RangeValidationResultState } from '../../types/baseTypes.js';
import { RangeValidationResult } from '../plan/validation/rangeValidationResult.js';

export class PlanValidationStateService {

    static readonly disallowedContextStatesForModule = [
        PlanValidationState.INVALID,
        PlanValidationState.INCOMPLETE,
        PlanValidationState.EMPTY,
    ];

    static fromRangeValidationResult(
        inputRangeValidationResult: RangeValidationResult,
        successState: PlanValidationState,
    ): PlanValidationState {

        let result;
        if (inputRangeValidationResult.result) {
            result = inputRangeValidationResult.result;
        }
        switch (result) {
            case RangeValidationResultState.IMPLICIT:
                return PlanValidationState.IMPLICIT;
            case RangeValidationResultState.MORE_REQUIRED:
                return PlanValidationState.INCOMPLETE;
            case RangeValidationResultState.LESS_REQUIRED:
                return PlanValidationState.INVALID;
            case RangeValidationResultState.IMPLICIT_OK:
                return successState;
            case RangeValidationResultState.OK:
                return successState;
            default:
                throw new Error(`Unrecognized Range.ValidationResult: ${result}`);
        }
    }

    static higherPriorityOf(state1: PlanValidationState, state2: PlanValidationState): PlanValidationState {
        const planValidationStateOrder = [
            PlanValidationState.INVALID,
            PlanValidationState.INCOMPLETE,
            PlanValidationState.PLANNED,
            PlanValidationState.APPROVED,
            PlanValidationState.APPROVED_CONDITIONALLY,
            PlanValidationState.APPROVAL_REQUESTED,
            PlanValidationState.APPROVAL_REQUESTED_PARTS_ATTAINED,
            PlanValidationState.APPROVED_CONDITIONALLY_PARTS_ATTAINED,
            PlanValidationState.APPROVED_PARTS_ATTAINED,
            PlanValidationState.PARTS_ATTAINED,
            PlanValidationState.ATTAINED,
            PlanValidationState.IMPLICIT,
            PlanValidationState.EMPTY];

        if (!state1) {
            return state2;
        }
        if (!state2) {
            return state1;
        }
        return _.indexOf(planValidationStateOrder, state1) < _.indexOf(planValidationStateOrder, state2) ? state1 : state2;
    }

}
