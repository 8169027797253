import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidatablePlan } from 'common-typescript';
import {
    Attainment,
    CourseUnit,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { OrganisationNameService } from 'sis-components/organisation/organisation-name.service';
import { UniversityService } from 'sis-components/service/university.service';

import {
    CourseUnitSubstitutionWithLocalId,
} from '../course-unit-info-modal-substitution-correspondences/course-unit-info-modal-substitution-correspondences.component';

import CourseUnitHelper from './course-unit-helper';
import { SubstitutionSelectOptionsService } from './substitution-select-options.service';

interface SubstitutionForm {
    selectedSubstitutionsLocalId: FormControl<string>;
}

export interface SubstitutionSelectOption extends CourseUnitSubstitutionWithLocalId {
    isSelectable: boolean;
    isAttainmentAlreadyAttached: boolean;
    isSubstituteCourseUnitAlreadyInPlan: boolean;
    belongsToCurrentUniversity: boolean;
    isGroup: boolean;
}

@Component({
    selector: 'app-course-unit-info-modal-substitutions-correspondences-select-form',
    templateUrl: './course-unit-info-modal-substitutions-correspondences-select-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitInfoModalSubstitutionsCorrespondencesSelectFormComponent implements OnInit, OnChanges {

    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;
    @Input() versionChangeInProgress: boolean;
    @Input() isLoggedIn: boolean;
    @Input() selected: OtmId;
    @Input() substitutionOptions: CourseUnitSubstitutionWithLocalId[];
    @Input() substituteCourseUnitsByGroupId: { [key: OtmId]: CourseUnit };
    @Input() attainmentsByCourseUnitGroupId: { [key: OtmId]: Attainment };
    @Input() allAttainments: Attainment[];
    @Output() selectSubstitution = new EventEmitter<Partial<{ selectedSubstitutionsLocalId: string }>>();
    @Output() openCourseUnitInfoModal = new EventEmitter<OtmId>();

    form: FormGroup<SubstitutionForm>;
    hasSubstitutes: boolean;
    isSubstitute: boolean;
    isEditable: boolean;
    hasSomeSubstitutesToSelect: boolean;
    universityName$: Observable<string>;
    mySubstitutionOptions: SubstitutionSelectOption[] = [];
    showAsGrouped: boolean;

    constructor(
        private fb: SisFormBuilder,
        private universityService: UniversityService,
        private organisationNameService: OrganisationNameService,
        private substitutionOptionsService: SubstitutionSelectOptionsService,
    ) {

    }

    ngOnInit() {
        this.form = this.fb.group({
            selectedSubstitutionsLocalId: this.fb.control(this.selected ?? null),
        });
        this.form.valueChanges.subscribe(localId => this.selectSubstitution.emit(localId));

        this.isSubstitute = CourseUnitHelper.isSubstitute(this.courseUnit, this.validatablePlan);

        this.isEditable = this.isLoggedIn && CourseUnitHelper.isEditable(this.courseUnit, this.validatablePlan);

        this.updateMySubstitutionOptions();

        this.universityName$ = this.organisationNameService.getOrgName(this.universityService.getCurrentUniversityOrgId());
    }

    ngOnChanges() {
        this.updateMySubstitutionOptions();
    }

    handleOpenCourseUnitInfoModal(courseUnitId: OtmId) {
        this.openCourseUnitInfoModal.emit(courseUnitId);
    }

    substitutionOptionByLocalId(index: number, substitutionOption: CourseUnitSubstitutionWithLocalId) {
        return substitutionOption.localId;
    }

    get selectedSubstitutionsLocalId(): FormControl {
        return this.form.get('selectedSubstitutionsLocalId') as FormControl;
    }

    getAttainmentByCourseUnitGroupId(courseUnitGroupId: OtmId) {
        return this.validatablePlan.getCourseUnitAttainmentByGroupId(courseUnitGroupId);
    }

    private updateMySubstitutionOptions() {
        this.mySubstitutionOptions = this.substitutionOptionsService.getSubstitutionSelectOptions(
            this.substitutionOptions,
            this.attainmentsByCourseUnitGroupId,
            this.allAttainments,
            this.validatablePlan,
            this.substituteCourseUnitsByGroupId,
            this.isEditable,
            this.versionChangeInProgress,
        );
        this.showAsGrouped = _.some(this.mySubstitutionOptions, option => option.isGroup);
        this.hasSomeSubstitutesToSelect = _.some(this.mySubstitutionOptions, substitutionOption => substitutionOption.isSelectable);
        this.hasSubstitutes = !_.isEmpty(this.mySubstitutionOptions) || !_.isEmpty(_.get(this.courseUnit, 'equivalentCoursesInfo'));
    }

}
