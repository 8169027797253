import { Pipe, PipeTransform } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { LocalDateRange, LocalDateTimeRange } from 'common-typescript/types';
import moment from 'moment';

/**
 * Is LocalDateRange / LocalDateTimeRange ongoing (compared to targetDate). Note that null/undefined range will always result in false.
 */
@Pipe({ name: 'dateRangeOngoing' })
export class DateRangeOngoingPipe implements PipeTransform {

    transform(value: LocalDateRange | LocalDateTimeRange, targetDate?: string): boolean {
        return !!value && dateUtils.rangeContains(moment(targetDate), value);
    }
}
