<sis-contextual-notification variant="light">
  <ng-container *ngIf="versionSwitchAllowed; then promptChange; else versionNotCompatible"></ng-container>
</sis-contextual-notification>

<ng-template #promptChange>
  <span>{{ versionCompatibleTranslationKey | translate }}</span>&nbsp;
  <sis-button [link]="true" data-cy="switch-to-this-version-button" (clicked)="saveVersionChange.emit()">{{ switchVersionTranslationKey | translate }}</sis-button>
</ng-template>

<ng-template #versionNotCompatible>
  <span>{{ versionNotCompatibleTranslationKey | translate }}</span>
</ng-template>
