import * as _ from 'lodash-es';
import angular from 'angular';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { displaySelectedParametersModule } from './displaySelectedParameters.component';
import { searchParameterServiceModule } from './searchParameters';
import { creditRangeFilterModule } from '../number/creditRange.filter';
import { searchParameterStorageServiceModule } from './searchParameterStorage.service';
export const searchParametersDirectiveModule = 'sis-components.search.searchParameters';
(function () {
  searchParameterSelections.$inject = ["$log", "$q", "HiddenParameter", "SearchParameter"];
  searchParameters.$inject = ["searchParameterStorageService", "$state"];
  angular.module(searchParametersDirectiveModule, ['ui.router', 'ui.router.upgrade', searchParameterServiceModule, creditRangeFilterModule, searchParameterStorageServiceModule, displaySelectedParametersModule, errorServiceModule]).directive('searchParameters', searchParameters).controller('SearchParameterSelections', searchParameterSelections);

  /**
   * @ngInject
   */
  function searchParameters(searchParameterStorageService, $state) {
    return {
      require: ['searchParameters', '^^searchAndResults'],
      controller: 'SearchParameterSelections as searchParamsCtrl',
      bindToController: {
        // see search and results directive
        customStorageKey: '<?'
      },
      scope: true,
      link: function (scope, elem, attr, ctrls) {
        var searchParamsCtrl = ctrls[0];
        var searchAndResults = ctrls[1];
        searchParamsCtrl.params = searchAndResults.searchParams;
        searchParamsCtrl.searchAction = searchAndResults.search;
        searchParamsCtrl.searchResults = searchAndResults.results;
        searchAndResults.saveSearchParameters = true;
        const storageKey = _.get(searchParamsCtrl, 'customStorageKey', $state.get('.').name);
        // Try to load only if storage is enabled
        if (!searchAndResults.disableStorage && searchParameterStorageService.loadSearchParameterFromStorage(storageKey, searchAndResults.searchParams)) {
          searchAndResults.initialSearch = true;
        }
      }
    };
  }

  /**
   * @ngInject
   */
  function searchParameterSelections($log, $q, HiddenParameter, SearchParameter) {
    const searchParamsCtrl = this;
    searchParamsCtrl.searchAgain = function () {
      $log.debug('Search component - searchParameters searchAgain - executing search');
      $q.when(searchParamsCtrl.searchAction(searchParamsCtrl.params.searchString.displayValue())).catch(error => {
        // Rejected without message, this is nothing exceptional
        if (!_.isEmpty(error)) {
          $log.error('Could not search because:', error);
        }
      });
    };
    searchParamsCtrl.toggleAll = function (parameterName, values) {
      searchParamsCtrl.params[parameterName].removeAllValues();
      if (values instanceof Array) {
        _.forEach(values, function (value) {
          searchParamsCtrl.params[parameterName].toggleValue(value);
        });
      } else {
        searchParamsCtrl.params[parameterName].toggleValue(values);
      }
    };
    searchParamsCtrl.selectAllParametersFromValues = function (parameterName, parameterValue) {
      if (searchParamsCtrl.params[parameterName] instanceof SearchParameter || searchParamsCtrl.params[parameterName] instanceof HiddenParameter) {
        searchParamsCtrl.toggleAll(parameterName, parameterValue);
        $log.debug('Search component - searchParameters selectAllParametersFromValues - executing search');
        searchParamsCtrl.searchAgain();
      }
    };
    searchParamsCtrl.parameterObject = function (parameterName) {
      return searchParamsCtrl.params[parameterName];
    };
  }
})();