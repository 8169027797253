<ng-container *transloco="let t">
  <div *ngIf="courseUnit" class="container-fluid ng-course-unit-info-modal-header">
    <div class="row">
      <div class="col-7 pe-0">
        <div class="mt-4">
          <h2 class="modal-title" id="student-course-unit-modal-heading" tabindex="-1">
            {{ courseUnit.name | localizedString}} ({{ courseUnit.credits | creditRange }})
            <sis-non-active-document-state-badge [documentState]="courseUnit.documentState"></sis-non-active-document-state-badge>
          </h2>
        </div>
        <div class="course-unit-code mb-3">
          <span>{{ courseUnit.code }} | {{ 'ENTITY_NAMES.courseUnit' | translate }}</span>
        </div>
      </div>
      <div class="col-5 ps-0">
        <div class="float-end mt-3 pe-3">
          <sis-button dataCy="course-unit-info-header-close-button" link="true" (clicked)="closeModal.emit()" [ariaLabel]="'ARIA_LABEL.CLOSE_BROCHURE' | translate">
            <sis-icon icon="close-big" color="light"></sis-icon>
          </sis-button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="selectableVersionsAndNames$ | async">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12">
          <app-course-unit-info-modal-header-version-selector
            [courseUnit]="courseUnit"
            [options]="options"
            [courseUnitVersions]="courseUnitVersions"
            (setModalCourseUnitVersion)="setModalCourseUnitVersion.emit($event)">
          </app-course-unit-info-modal-header-version-selector>
        </div>
        <div class="col-lg-6 ms-xl-5 ps-xl-5" data-cy="course-unit-info-modal-info-list">
          <app-course-unit-info-modal-header-info-list
            [courseUnit]="courseUnit"
            [validatablePlan]="validatablePlan">
          </app-course-unit-info-modal-header-info-list>
        </div>
      </div>
      <div aria-atomic="true" aria-live="polite" role="region" class="pe-md-3">
        <div *ngIf="versionChanged && showVersionSwitchNotification && !originalCourseUnitHasSubstitutions" class="mb-4 me-1" data-cy="modal-version-switch-notification">
          <sis-version-switch-notification
            [versionSwitchAllowed]="versionSwitchAllowed"
            (saveVersionChange)="saveVersionChange()"
            [switchVersionTranslationKey]="'COURSE_UNIT_INFO_MODAL.SWITCH_TO_THIS_VERSION'"
            [versionCompatibleTranslationKey]="'COURSE_UNIT_INFO_MODAL.VERSION_IS_COMPATIBLE'"
            [versionNotCompatibleTranslationKey]="'COURSE_UNIT_INFO_MODAL.VERSION_NOT_COMPATIBLE'">
          </sis-version-switch-notification>
        </div>
        <div *ngIf="!versionChanged && hasNewerVersion" class="mb-4 me-1">
          <sis-contextual-notification variant="warning">
            <span>{{ t('COURSE_UNIT_INFO_MODAL.COURSE_UNIT_HAS_NEWER_VERSION') }}</span>
          </sis-contextual-notification>
        </div>
        <div *ngIf="versionChanged && originalCourseUnitHasSubstitutions" class="mb-4 me-1">
          <sis-contextual-notification variant="warning">
            <span>{{ t('COURSE_UNIT_INFO_MODAL.VERSION_CANNOT_BE_SELECTED_DUE_TO_SUBSTITUTION') }}</span>
          </sis-contextual-notification>
        </div>
      </div>
    </ng-container>
    <div class="bottom-section">
      <app-course-unit-info-modal-header-tabs *ngIf="tabs"
                                              [openTab]="'BASIC'"
                                              [tabs]="tabs"
                                              (selectTab)="selectTab()">
      </app-course-unit-info-modal-header-tabs>
    </div>
  </div>
</ng-container>
