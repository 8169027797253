import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { enrolmentValidationServiceProvider } from 'sis-components/ajs-upgraded-modules';
import { EnrolmentModule as CommonEnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { SisComponentsModule } from 'sis-components/sis-components.module';

import { EnrolmentPeriodInfoComponent } from './enrolment-period-info/enrolment-period-info.component';
import { EnrolmentStateIndicatorComponent } from './enrolment-state-indicator/enrolment-state-indicator.component';
import { EnrolmentStateNotificationBoxComponent } from './enrolment-state-notification-box/enrolment-state-notification-box.component';
import { ProcessingStateIndicatorComponent } from './processing-state-indicator/processing-state-indicator.component';

/**
 * This is supposed to be the module for converted courseUnitRealisation.component.js. This means that the converted component should be
 * "enrolment.component.ts". The old name is a bit misleading because all features of the component are enrolment related.
 */
@NgModule({
    declarations: [
        EnrolmentStateIndicatorComponent,
        ProcessingStateIndicatorComponent,
        EnrolmentPeriodInfoComponent,
        EnrolmentStateNotificationBoxComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        SisComponentsModule,
        SisCommonModule,
        CommonEnrolmentModule,
    ],
    exports: [
        EnrolmentStateIndicatorComponent,
        EnrolmentPeriodInfoComponent,
        EnrolmentStateNotificationBoxComponent,
        ProcessingStateIndicatorComponent,
    ],
    providers: [
        enrolmentValidationServiceProvider,
    ],
})
export class EnrolmentModule {
}
