import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { Organisation } from 'common-typescript/types';
import { BehaviorSubject, combineLatest, forkJoin, map, Observable, of } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap, take } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';

import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { Option } from '../../../select/combobox/combobox.component';
import { SelectOrganisationComponent } from '../../../select/select-organisation/select-organisation.component';
import { OrganisationEntityService } from '../../../service/organisation-entity.service';
import { UniversityService } from '../../../service/university.service';
import { SearchService } from '../../search.service';

@Component({
    selector: 'sis-search-filter-organisation-id',
    templateUrl: './search-filter-organisation-id.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterOrganisationIdComponent implements OnInit {

    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly destroyRef = inject(DestroyRef);
    private readonly localeService = inject(LocaleService);
    private readonly organisationService = inject(OrganisationEntityService);
    private readonly modalService = inject(ModalService);
    /**
     * A view specific instance of {@link SearchService}.
     */
    private readonly searchService = inject(SearchService);
    private readonly translocoService = inject(TranslocoService);
    private readonly universityService = inject(UniversityService);

    /**
     * Optional custom key for organisation id search parameter. Default value should satisfy most backend API query
     * parameters.
     */
    @Input() organisationIdKey? = 'orgId';

    /**
     * Optional custom key for root organisation id search parameter. Default value should satisfy most backend API
     * query parameters.
     */
    @Input() rootOrganisationIdKey? = 'orgRootId';

    allOrganisationsForUniversity$: Observable<Organisation[]>;
    options$ = new BehaviorSubject<Option[]>([]);
    selections$: Observable<Option[]>;

    ngOnInit() {
        this.allOrganisationsForUniversity$ = this.searchService.searchParameters$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                map(parameters => parameters?.filters?.universityOrgId || this.universityService.getCurrentUniversityOrgId()),
                distinctUntilChanged(), // fetch all organisations (only) when universityOrgId changes
                switchMap(universityOrgId => this.organisationService.findAll(universityOrgId)),
                shareReplay({ bufferSize: 1, refCount: true }), // share replay for selection$
                this.appErrorHandler.defaultErrorHandler());

        this.selections$ = combineLatest([
            this.searchService.searchParameters$,
            this.allOrganisationsForUniversity$,
        ]).pipe(
            takeUntilDestroyed(this.destroyRef),
            map(([parameters, allOrganisations]) => {
                const organisations = allOrganisations
                    .filter(org => (parameters?.filters?.[this.organisationIdKey] || []).includes(org.id));
                const rootOrganisations = allOrganisations
                    .filter(org => (parameters?.filters?.[this.rootOrganisationIdKey] || []).includes(org.id));

                return [
                    ...this.mapOrganisationsToOptions(organisations),
                    ...this.mapRootOrganisationsToOptions(rootOrganisations),
                ];
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    searchOrganisationsForOptions(fullTextQuery: string) {
        if (fullTextQuery?.length < 3) {
            this.options$.next([]);
            return;
        }
        this.searchService.searchParameters$
            .pipe(
                take(1),
                map(parameters => parameters?.filters?.universityOrgId || this.universityService.getCurrentUniversityOrgId()),
                switchMap(universityOrgId => this.organisationService.search(fullTextQuery, universityOrgId)),
                this.appErrorHandler.defaultErrorHandler(),
            ).subscribe(results => {
                const sortedOptions = this.mapOrganisationsToOptions(results.searchResults)
                    .sort((o1, o2) => (o1.label || '').localeCompare(o2.label));
                this.options$.next(sortedOptions);
            });
    }

    onSelectionChange(selections: Option[]) {
        this.searchService.searchParameters$
            .pipe(take(1))
            .subscribe(parameters => {
                const selectedIds = selections.map(option => option.value);

                // root selections can only be made from dialog, only removals from combobox
                const rootOrganisationIds = (parameters?.filters?.[this.rootOrganisationIdKey] || [])
                    .filter((id: string) => selectedIds.includes(id));

                // all new selections are non-root
                const organisationIds = selectedIds.filter(id => !rootOrganisationIds.includes(id));

                this.searchService.patchFilters({
                    [`${this.organisationIdKey}`]: organisationIds,
                    [`${this.rootOrganisationIdKey}`]: rootOrganisationIds,
                });
            });
    }

    selectOrganisationsFromDialog(organisations: Organisation[]) {
        this.searchService.searchParameters$
            .pipe(
                take(1),
                switchMap(parameters => forkJoin([
                    this.organisationService.getById((parameters?.filters?.universityOrgId || this.universityService.getCurrentUniversityOrgId()))
                        .pipe(take(1)),
                    of((parameters?.filters?.[this.organisationIdKey] || []) as string[]),
                    of((parameters?.filters?.[this.rootOrganisationIdKey] || []) as string[]),
                ])),
                switchMap(([university, selectedIds, selectedParentIds]) =>
                    this.modalService.open(SelectOrganisationComponent, {
                        university,
                        organisations,
                        selectedIds,
                        selectedParentIds,
                    }, { closeWithOutsideClick: true }).closed,
                ))
            .subscribe(({ selectedParents, selectedLeafs }) => {
                this.searchService.patchFilters({
                    [`${this.organisationIdKey}`]: selectedLeafs.map((org: Organisation) => org.id),
                    [`${this.rootOrganisationIdKey}`]: selectedParents.map((org: Organisation) => org.id),
                });
            });
    }

    private mapOrganisationsToOptions(organisations: Organisation[]): Option[] {
        return organisations.map(organisation => ({
            label: this.localeService.localize(organisation.name),
            value: organisation.id,
        }));
    }

    /**
     * Add secondary label to root organisation selections so that user can see the difference in combobox.
     *
     * @param organisations
     * @private
     */
    private mapRootOrganisationsToOptions(organisations: Organisation[]): Option[] {
        return organisations.map(organisation => ({
            label: `${this.localeService.localize(organisation.name)} ${this.translocoService.translate('SELECT_ORGANISATION.SECONDARY_LABELS.ALL_CHILDREN')}`,
            value: organisation.id,
        }));
    }
}
