'use strict';

(function () {
  planJSDataModel.$inject = ["DS", "PLAN_URL", "planModuleJSDataModel", "courseUnitJSDataModel", "jsDataCacheHelper"];
  angular.module('sis-components.model.plan', ['js-data', 'sis-components.model.courseUnit', 'sis-components.model.module', 'sis-components.service.jsDataCacheHelper']).factory('planJSDataModel', planJSDataModel).constant('PLAN_URL', {
    DEFAULT: '/osuva/api/plans',
    MY_PLANS: '/osuva/api/my-plans',
    FOR_USER_AND_EDUCATION: '/osuva/api/plans/for-user-and-education'
  });

  /**
   * @ngInject
   */
  function planJSDataModel(DS, PLAN_URL, planModuleJSDataModel, courseUnitJSDataModel, jsDataCacheHelper) {
    function getId(objectOrId) {
      var id = objectOrId;
      if (_.has(objectOrId, 'id')) {
        id = objectOrId.id;
      }
      return id;
    }
    return DS.defineResource({
      idAttribute: 'id',
      name: 'plan',
      endpoint: PLAN_URL.DEFAULT,
      relations: {
        hasOne: {
          curriculumPeriod: {
            localField: 'curriculumPeriod',
            localKey: 'curriculumPeriodId'
          },
          education: {
            localField: 'education',
            localKey: 'rootId'
          }
        }
      },
      computed: {
        educationId: ['rootId', function (rootId) {
          return rootId;
        }]
      },
      methods: {
        findChildModuleSelections: function (parentModuleId) {
          return _.filter(this.moduleSelections, _.matchesProperty('parentModuleId', parentModuleId));
        },
        findChildCourseUnitSelections: function (parentModuleId) {
          return _.filter(this.courseUnitSelections, _.matchesProperty('parentModuleId', parentModuleId));
        },
        cacheModulesAndCourseUnits: function () {
          var courseUnitIds = _.map(this.courseUnitSelections, function (selection) {
            return selection.courseUnitId;
          });
          var rootId = this.rootId;
          var moduleIds = _.chain(this.moduleSelections).filter(function (selection) {
            return selection.moduleId !== rootId;
          }).map(function (selection) {
            return selection.moduleId;
          }).value();
          jsDataCacheHelper.findByIds(planModuleJSDataModel, moduleIds);
          jsDataCacheHelper.findByIds(courseUnitJSDataModel, courseUnitIds);
        },
        getCourseUnitSelection: function (courseUnit) {
          return _.find(this.courseUnitSelections, {
            'courseUnitId': getId(courseUnit)
          });
        },
        isSubstituted: function (courseUnit) {
          var courseUnitSelection = this.getCourseUnitSelection(getId(courseUnit));
          return !_.isEmpty(_.get(courseUnitSelection, 'substitutedBy'));
        },
        isSelectedCompletionMethod: function (completionMethod, courseUnit) {
          var courseUnitSelection = this.getCourseUnitSelection(courseUnit);
          return courseUnitSelection && completionMethod.localId === _.get(courseUnitSelection, 'completionMethodId');
        },
        isSelectedAssessmentItem: function (courseUnit, assessmentItem) {
          return !!_.find(this.assessmentItemSelections, {
            'courseUnitId': getId(courseUnit),
            'assessmentItemId': getId(assessmentItem)
          });
        },
        getPlannedPeriods: function (courseUnit) {
          var courseUnitSelection = this.getCourseUnitSelection(getId(courseUnit));
          return _.get(courseUnitSelection, 'plannedPeriods');
        }
      }
    });
  }
})();