import { Inject, Injectable } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { CourseUnit, CurriculumPeriod, Module, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { CURRICULUM_PERIOD_SERVICE } from '../ajs-upgraded-modules';
import { UniversityService } from '../service/university.service';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class StudySelectorService {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studySelectorService',
        serviceName: 'studySelectorService',
    };

    constructor(
        private universityService: UniversityService,
        @Inject(CURRICULUM_PERIOD_SERVICE) private curriculumPeriodService: any,
    ) {}

    selectStudyForPlan(studiesOfGroup: (Module | CourseUnit)[], parentCurriculumPeriodIds: OtmId[], planCurriculumPeriodId: OtmId) {
        return this.curriculumPeriodService.loadCurriculumPeriodsStartingFrom(planCurriculumPeriodId)
            .then((curriculumPeriods: CurriculumPeriod[]) => this.selectStudyOfGroup(studiesOfGroup, parentCurriculumPeriodIds, planCurriculumPeriodId, curriculumPeriods));
    }

    selectStudyOfGroup(studiesOfGroup: (Module | CourseUnit)[], parentCurriculumPeriodIds: OtmId[], planCurriculumPeriodId: OtmId, allCurriculumPeriods: CurriculumPeriod[]) {
        const homeUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
        const universityOrgIdsOfStudies = _.chain(studiesOfGroup).flatMap('universityOrgIds').uniq().value();
        if (!_.includes(universityOrgIdsOfStudies, homeUniversityOrgId)) {
            return this.selectStudyBasedOnValidityPeriods(studiesOfGroup);
        }
        return this.selectStudy(
            parentCurriculumPeriodIds,
            this.getPlanCurriculumPeriodIds(planCurriculumPeriodId, allCurriculumPeriods),
            studiesOfGroup,
        );
    }

    getPlanCurriculumPeriodIds(planCurriculumPeriodId: OtmId, allCurriculumPeriods: CurriculumPeriod[]): OtmId[] {
        const sortedCurriculumPeriodIds = _.map(
            _.sortBy(allCurriculumPeriods, cp => cp.activePeriod.startDate),
            'id',
        );

        return _.slice(sortedCurriculumPeriodIds, _.indexOf(sortedCurriculumPeriodIds, planCurriculumPeriodId));
    }

    selectStudy(parentCurriculumPeriodIds: OtmId[], planCurriculumPeriodIds: OtmId[], studiesOfGroup: (Module | CourseUnit)[]): Module | CourseUnit {
        const getCurriculumPeriodIndexById = (id: OtmId) => _.indexOf(planCurriculumPeriodIds, id);

        const allowedStudies = _.filter(studiesOfGroup, study =>
            _.get(study, 'type') === 'GroupingModule' ||
            !_.isEmpty(_.intersection(planCurriculumPeriodIds, study.curriculumPeriodIds)),
        );

        const allowedParentCurriculumPeriodIds = _.intersection(planCurriculumPeriodIds, parentCurriculumPeriodIds);

        const parentMinCurriculumPeriodIndex = _.isEmpty(allowedParentCurriculumPeriodIds) ? 0 :
            _.min(_.map(allowedParentCurriculumPeriodIds, getCurriculumPeriodIndexById));

        const selectableStudies = _.map(allowedStudies, (study) => {
            const curriculumPeriodIndexes = _.map(_.intersection(study.curriculumPeriodIds, planCurriculumPeriodIds), getCurriculumPeriodIndexById);
            const minCurriculumPeriodIndexAfterParent = _.min(_.filter(curriculumPeriodIndexes, _.partial(_.lte, parentMinCurriculumPeriodIndex)));
            const maxCurriculumPeriodIndexBeforeParent = _.max(_.filter(curriculumPeriodIndexes, _.partial(_.gt, parentMinCurriculumPeriodIndex)));
            return {
                study,
                documentStatePreference: study.documentState !== 'DELETED' ? 0 : 1,
                minCurriculumPeriodIndexAfterParent: !_.isNil(minCurriculumPeriodIndexAfterParent) ? minCurriculumPeriodIndexAfterParent : null,
                maxCurriculumPeriodIndexBeforeParent: !_.isNil(maxCurriculumPeriodIndexBeforeParent) ? -maxCurriculumPeriodIndexBeforeParent : null,
            };
        });
        const preferenceOrder = _.sortBy(selectableStudies, ['documentStatePreference', 'minCurriculumPeriodIndexAfterParent', 'maxCurriculumPeriodIndexBeforeParent']);

        return _.get(_.first(preferenceOrder), 'study') || null;
    }

    selectStudyBasedOnValidityPeriods(studiesOfGroup: (Module | CourseUnit)[]): CourseUnit | Module {
        const currentMoment = moment();
        const validVersions = _.filter(studiesOfGroup, (study) =>
            dateUtils.dateRangeContains(currentMoment, _.get(study, 'validityPeriod.startDate'), _.get(study, 'validityPeriod.endDate')));
        if (_.isEmpty(validVersions)) {
            return null;
        }
        return _.chain(validVersions)
            .sortBy(version => _.get(version, 'validityPeriod.startDate'))
            .last()
            .value();

    }

}
