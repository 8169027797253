timelineCourseUnitController.$inject = ["$log", "courseUnitInfoModal", "colorService"];
import angular from 'angular';
import * as _ from 'lodash-es';
import timelineCourseUnitTpl from './timelineCourseUnit.tpl.html';
angular.module('student.timing.timelineCourseUnit', ['sis-common.l10n', 'sis-components.rangeFilter', 'student.common.components.courseUnitInfoModal', 'sis-components.service.colorService']).component('timelineCourseUnit', {
  bindings: {
    courseUnit: '<',
    validatablePlan: '<',
    isActive: '<',
    hasMenu: '<',
    removeFromPeriod: '&',
    removeFromTimeline: '&',
    startTiming: '&',
    startMoving: '&'
  },
  template: timelineCourseUnitTpl,
  controller: timelineCourseUnitController
});

/**
 * @ngInject
 */
function timelineCourseUnitController($log, courseUnitInfoModal, colorService) {
  const $ctrl = this;
  $ctrl.$onInit = function () {
    if ($ctrl.validatablePlan && $ctrl.courseUnit) {
      if ($ctrl.validatablePlan.isCourseUnitAttained($ctrl.courseUnit.id)) {
        $ctrl.courseUnitAttainment = $ctrl.validatablePlan.getCourseUnitAttainment($ctrl.courseUnit.id);
        $ctrl.credits = $ctrl.courseUnitAttainment.credits;
      } else {
        $ctrl.credits = $ctrl.courseUnit.credits;
      }
      $ctrl.colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, $ctrl.courseUnit.id);
      $ctrl.editable = $ctrl.validatablePlan.isCourseUnitTimed($ctrl.courseUnit.id);
    } else {
      $log.error('Failed to init timelineCourseUnit', 'courseUnit:', $ctrl.courseUnit, 'validatablePlan:', $ctrl.validatablePlan);
    }
    $ctrl.menuItems = [{
      action: () => $ctrl.removeFromTimeline(),
      name: 'TIMELINE.UNTIME'
    }, {
      action: () => $ctrl.startTiming(),
      name: 'TIMELINE.EDIT_TIMING'
    }, {
      action: () => $ctrl.startMoving(),
      name: 'TIMELINE.MOVE_TIMING'
    }];
  };
  $ctrl.openCourseUnitInfo = function () {
    courseUnitInfoModal.showCompletionMethodsForCourseUnit($ctrl.courseUnit.id, $ctrl.validatablePlan, `cu-color-${$ctrl.colorCategory}`);
  };
  $ctrl.getGradeAbbreviation = function (courseUnitAttainment) {
    if (courseUnitAttainment && courseUnitAttainment.gradeScale) {
      const {
        gradeId
      } = courseUnitAttainment;
      let gradeText = '';
      if (gradeId !== undefined && gradeId !== null) {
        const {
          grades
        } = courseUnitAttainment.gradeScale;
        gradeText = _.result(_.find(grades, grade => grade.localId === gradeId), 'abbreviation');
      }
      return gradeText;
    }
    $log.warn('gradeScale undefined for courseUnitAttainment:', courseUnitAttainment);
    return courseUnitAttainment.gradeId;
  };
}