import { Transition } from '@uirouter/core';
import { CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT, OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT } from 'common-typescript/constants';
import { UniversitySettings } from 'common-typescript/types';
import { get } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { SisStateDeclaration } from 'sis-components/router/types';
import { UniversityService } from 'sis-components/service/university.service';

import { SearchCooperationNetworkComponent } from './search-cooperation-network/search-cooperation-network.component';
import { SearchMainComponent } from './search-main/search-main.component';
import { SearchOpenUniversityComponent } from './search-open-university/search-open-university.component';
import { SearchTabsComponent } from './search-tabs/search-tabs.component';

export const redirectToSearchMainIfOpenUniversityFeaturesAreNotEnabled = () =>
    ($transition$: Transition) => firstValueFrom(
        $transition$.injector().get(UniversityService)
            .getCurrentUniversitySettings())
        .then((settings: UniversitySettings) => {
            const openUniversityFeaturesEnabledForStudent = get(
                settings,
                'frontendFeatureToggles.openUniversityFeaturesEnabledForStudent',
                OPEN_UNIVERSITY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
            );

            // Do nothing if open university features are enabled, otherwise redirect to search main page
            return openUniversityFeaturesEnabledForStudent ? undefined : { state: 'student.search.main' };
        })
        .catch((err: any) => {
            if (err?.message) {
                console.error(err.message);
            }
            return { state: 'student.search.main' };
        });

export const redirectToSearchMainIfCrossStudyFeaturesAreNotEnabled = () =>
    ($transition$: Transition) => firstValueFrom(
        $transition$.injector().get(UniversityService)
            .getCurrentUniversitySettings())
        .then((settings: UniversitySettings) => {
            const crossStudyFeaturesEnabledForStudent = get(
                settings,
                'frontendFeatureToggles.crossStudyFeaturesEnabledForStudent',
                CROSS_STUDY_FEATURES_ENABLED_FOR_STUDENT_DEFAULT,
            );

            // Do nothing if open university features are enabled, otherwise redirect to search main page
            return crossStudyFeaturesEnabledForStudent ? undefined : { state: 'student.search.main' };
        })
        .catch((err: any) => {
            if (err?.message) {
                console.error(err.message);
            }
            return { state: 'student.search.main' };
        });

export const searchStates: SisStateDeclaration[] = [
    {
        name: 'student.search',
        url: '/search',
        component: SearchTabsComponent,
        data: {
            showHeader: true,
            showHome: true,
            hideLastNode: false,
        },
        headerParams: {
            skipBreadcrumb: false,
            displayNameKey: 'SEARCH_PAGE_BREADCRUMB',
        },
        redirectTo: 'student.search.main',
    },
    {
        name: 'student.search.main',
        url: '/main',
        component: SearchMainComponent,
        headerParams: {
            displayNameKey: 'SEARCH_UNIVERSITY_OFFERING',
            displayPageTitleNameKey: 'SEARCH_PAGE_TITLE',
        },
    },
    {
        name: 'student.search.open-university',
        url: '/open-university',
        component: SearchOpenUniversityComponent,
        redirectTo: redirectToSearchMainIfOpenUniversityFeaturesAreNotEnabled(),
        headerParams: {
            displayNameKey: 'SEARCH_OPEN_UNIVERSITY_OFFERING',
            displayPageTitleNameKey: 'SEARCH_PAGE_TITLE',
        },
    },
    {
        name: 'student.search.cross-study',
        url: '/cross-study',
        component: SearchCooperationNetworkComponent,
        redirectTo: redirectToSearchMainIfCrossStudyFeaturesAreNotEnabled(),
        headerParams: {
            displayNameKey: 'SEARCH_CROSS_STUDY_OFFERING',
            displayPageTitleNameKey: 'SEARCH_PAGE_TITLE',
        },
    },
];
