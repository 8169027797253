import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    OtmId,
    SearchParameters,
} from 'common-typescript/types';
import { omit } from 'lodash-es';
import { ReplaySubject, Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { singleConcurrentSearchWithThrottle } from 'sis-common/search/search-utils';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { silentErrorHandler } from 'sis-components/error-handler/silent-error-handler';
import { SearchService } from 'sis-components/search-ng/search.service';
import { Option } from 'sis-components/select/dropdown-select/dropdown-select.component';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import { OrganisationEntityService } from 'sis-components/service/organisation-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

import { CourseCartEntityService } from '../../common/service/course-cart-entity.service';
import { CooperationNetworkSearchFilters } from '../search.types';

const RESULTS_PER_PAGE = 20;

@Component({
    selector: 'app-search-cooperation-network',
    templateUrl: './search-cooperation-network.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: SearchService,
            useFactory: () => new SearchService<CooperationNetworkSearchFilters>({
                storageKey: 'searchParameters.student.search.cooperation-network',
                universityOrgIdEnabled: false,
                defaultOptions: {
                    start: 0,
                    limit: RESULTS_PER_PAGE,
                },
            }),
        },
    ],
})
export class SearchCooperationNetworkComponent implements OnInit, OnDestroy {

    readonly currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
    readonly currentUniversity$ = this.organisationEntityService.findUniversityRootOrganisation(this.currentUniversityOrgId);

    currentPage = 1;

    courseCartCourseUnitIds: OtmId[];

    destroyed$ = new Subject();

    searching = false;

    searchSortOptions: Option[] = [
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.MATCH'), value: null },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_ASC'), value: 'name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.NAME_DESC'), value: '-name' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_ASC'), value: 'credits' },
        { label: this.translate.instant('SEARCH.SORT_SELECTOR.SORT_METHOD_OPTIONS.CREDITS_DESC'), value: '-credits' },
    ];

    private readonly validParameters$ = new ReplaySubject<SearchParameters<CooperationNetworkSearchFilters>>();
    readonly searchResult$ = this.validParameters$
        .pipe(
            singleConcurrentSearchWithThrottle(parameters => this.executeSearch(parameters)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

    constructor(
        private authService: AuthService,
        private organisationEntityService: OrganisationEntityService,
        private courseUnitService: CourseUnitEntityService,
        private translate: TranslateService,
        private universityService: UniversityService,
        private appErrorHandler: AppErrorHandler,
        private courseCartEntityService: CourseCartEntityService,
        protected searchService: SearchService<CooperationNetworkSearchFilters>,
    ) {
    }

    ngOnInit(): void {
        if (this.isLoggedIn()) {
            this.courseCartEntityService.getCourseCart(true)
                .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
                .subscribe((ids) => this.courseCartCourseUnitIds = ids);
        }
    }

    ngOnDestroy() {
        this.destroyed$.unsubscribe();
    }

    onSearchParameterChange(parameters: SearchParameters<CooperationNetworkSearchFilters>) {
        this.validParameters$.next(parameters);
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

    sort(sortValue: string) {
        this.searchService.sort(sortValue);
    }

    addCourseUnitToCourseCart(courseUnitId: string) {
        this.courseCartEntityService.addCurToCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    removeCourseUnitFromCourseCart(courseUnitId: string) {
        this.courseCartEntityService.deleteCurFromCourseCart(courseUnitId)
            .pipe(takeUntil(this.destroyed$), this.appErrorHandler.defaultErrorHandler())
            .subscribe();
    }

    onPaginationChange(newPage: number) {
        this.currentPage = newPage;
        this.searchService.patchOptions({ start: (newPage - 1) * RESULTS_PER_PAGE });
        document.getElementById('results-show-guide')?.focus();
    }

    get resultsPerPage() {
        return RESULTS_PER_PAGE;
    }

    private executeSearch({ filters, options }: SearchParameters<CooperationNetworkSearchFilters>) {
        this.searching = true;
        return this.courseUnitService.searchActive({
            ...options,
            ...(omit(filters, ['targetUniversityId'])),
            cooperationNetworkIdsForUniversityOrgId: this.currentUniversityOrgId,
            excludeUniversityOrgId: this.currentUniversityOrgId,
            sort: options.sort?.join(','),
            universityOrgId: filters.targetUniversityId,
            validity: 'ONGOING_AND_FUTURE',
        })
            .pipe(
                tap(results => this.currentPage = Math.floor((results?.start ?? 0) / RESULTS_PER_PAGE) + 1),
                finalize(() => this.searching = false),
                silentErrorHandler,
            );
    }
}

