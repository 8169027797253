import * as _ from 'lodash-es';

import { RangeType } from '../../types/baseTypes.js';

export class Range {

    min: number;
    max: number | null;

    static cast(input: any): Range {
        if (input instanceof Range) {
            return input;
        }
        return new Range(input);
    }

    static zero(): Range {
        return new Range(0);
    }

    static divideRange(inputRange: Range, divider: number): Range {
        return Range.cast(
            {
                min: inputRange.min / divider,
                max: inputRange.max === null ? null : inputRange.max / divider,
            });
    }

    private static toNumber(value: any, name: string, defaultValue: any) {
        if (_.isNil(value)) {
            return defaultValue;
        }
        if (_.isFinite(value) || _.isFinite(Number(value))) {
            return Number(value);
        }
        throw Error(`Expected number for ${name}, got ${value}`);
    }

    private static toComparableMax(max: any): number {
        if (_.isNumber(max)) {
            return max;
        }
        return +Infinity;
    }

    constructor(...args: any) {
        if (args.length === 1) {
            if (_.isObject(args[0])) {
                this.min = Range.toNumber((args[0] as { min: any }).min, 'min', 0);
                this.max = Range.toNumber((args[0] as { max: any }).max, 'max', null);
            } else {
                // eslint-disable-next-line no-multi-assign
                this.min = this.max = Range.toNumber(args[0], 'min', 0);
            }
        } else {
            this.min = Range.toNumber(args[0], 'min', 0);
            this.max = Range.toNumber(args[1], 'max', null);
        }
    }

    comparableMax(): number {
        return Range.toComparableMax(this.max);
    }

    hasDistinctMax(): boolean {
        return this.hasMax() && this.max !== this.min;
    }

    hasMax(): boolean {
        return _.isNumber(this.max) && this.max !== +Infinity;
    }

    add(otherRange: Range): Range {
        let other;
        if (otherRange) {
            other = Range.cast(otherRange);
            let min = null;
            let max = null;

            const thisMin = _.isFinite(this.min) ? this.min : null;
            const otherMin = _.isFinite(_.get(other, 'min')) ? other.min : null;
            if (thisMin !== null || otherMin !== null) {
                // Missing lower bound defaults to 0, but only if at least one of the input ranges has a lower bound
                min = _.defaultTo(thisMin, 0) + _.defaultTo(otherMin, 0);
            }

            if (_.isFinite(this.max) && _.isFinite(_.get(other, 'max'))) {
                // The result will only have an upper bound if both input ranges have an upper bound
                max = _.defaultTo(this.max, 0) + _.defaultTo(other.max, 0);
            }

            return new Range(min, max);
        }

        return this;
    }

    getType(): RangeType {
        if (this.min === this.max) {
            if (this.min === 1) {
                return RangeType.ONE;
            }
            return RangeType.EXACTLY;
        }

        if (this.hasMax()) {
            if (this.min === 0) {
                return RangeType.MAX;
            }
            return RangeType.BETWEEN;
        }

        return RangeType.MIN;

    }

    sumRanges(inputRanges: any): Range {
        let ranges = inputRanges;
        // This function can be invoked either with a plain array or using varargs (i.e. rest parameters),
        // or it can be used as an iteratee for _.reduce()
        if (!_.isArray(ranges)) {
            // eslint-disable-next-line prefer-rest-params
            ranges = _.filter(arguments, (arg) => _.isPlainObject(arg) || arg instanceof Range);
        }

        return _.reduce(ranges, (sum, range) => Range.cast(sum).add(range), new Range(0));
    }

}
