import { Component, Inject, Input, OnChanges, Optional, ViewEncapsulation } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    CourseUnit,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    EntityWithRule,
    Module,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import {
    PLAN_VALIDATION_RESULT_HELPER,
} from '../../ajs-upgraded-modules';
import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { PLAN_ACTIONS_SERVICE_INJECTION_TOKEN, PlanActionsService } from '../plan-actions-service/plan-actions.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-invalid-selections',
    templateUrl: './invalid-selections.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class InvalidSelectionsComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.invalidSelections',
        directiveName: 'sisInvalidSelections',
    };

    hasInvalidSelections = false;
    invalidCourseUnitSelections: CourseUnit[];
    invalidModuleSelections: Module[];
    invalidCustomCourseUnitAttainmentSelections: CustomCourseUnitAttainment[];
    invalidCustomModuleAttainmentSelections: CustomModuleAttainment[];
    customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference: OtmId[];
    customModuleAttainmentSelectionsWithInvalidAttainmentReference: OtmId[];

    @Input() parentModule: EntityWithRule;
    @Input() planValidationResult: any;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;

    constructor(
        @Inject(PLAN_VALIDATION_RESULT_HELPER) private planValidationResultHelper: any,
        @Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService,
    ) {
    }

    ngOnChanges() {
        this.update();
    }

    update() {
        if (!this.validatablePlan || !this.planValidationResult) {
            return;
        }
        this.invalidCourseUnitSelections =
            _.filter(this.validatablePlan.getSelectedCourseUnitsUnderModule(this.parentModule), cu =>
                _.get(this.planValidationResultHelper.getCourseUnitValidationResult(cu.id, this.planValidationResult), 'invalidSelection') === true);
        this.invalidModuleSelections =
            _.filter(this.validatablePlan.getSelectedModulesUnderModule(this.parentModule), module =>
                _.get(this.planValidationResultHelper.getModuleValidationResult(module.id, this.planValidationResult), 'invalidSelection') === true);
        this.invalidCustomCourseUnitAttainmentSelections =
            _.filter(this.validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(this.parentModule), ccua =>
                _.get(this.planValidationResultHelper.getCustomCourseUnitAttainmentValidationResult(ccua.id, this.planValidationResult), 'invalidSelection') === true);
        this.invalidCustomModuleAttainmentSelections =
            _.filter(this.validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(this.parentModule), cma =>
                _.get(this.planValidationResultHelper.getCustomModuleAttainmentValidationResult(cma.id, this.planValidationResult), 'invalidSelection') === true);
        this.customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference = _.chain(_.get(this.validatablePlan, 'plan.customCourseUnitAttainmentSelections'))
            .filter(selection => selection.parentModuleId === _.get(this.parentModule, 'id'))
            .filter(selection => this.validatablePlan.getCustomCourseUnitAttainment(selection.customCourseUnitAttainmentId) === null)
            .map('customCourseUnitAttainmentId')
            .value();
        this.customModuleAttainmentSelectionsWithInvalidAttainmentReference = _.chain(_.get(this.validatablePlan, 'plan.customModuleAttainmentSelections'))
            .filter(selection => selection.parentModuleId === _.get(this.parentModule, 'id'))
            .filter(selection => this.validatablePlan.getCustomModuleAttainment(selection.customModuleAttainmentId) === null)
            .map('customModuleAttainmentId')
            .value();
        this.hasInvalidSelections =
            (
                _.size(this.invalidCourseUnitSelections) +
                _.size(this.invalidModuleSelections) +
                _.size(this.invalidCustomCourseUnitAttainmentSelections) +
                _.size(this.invalidCustomModuleAttainmentSelections) +
                _.size(this.customCourseUnitAttainmentSelectionsWithInvalidAttainmentReference) +
                _.size(this.customModuleAttainmentSelectionsWithInvalidAttainmentReference)
            ) > 0;
    }

    removeCourseUnit(courseUnit: CourseUnit) {
        this.planActionsService?.removeCourseUnit?.(courseUnit, this.parentModule);
    }

    removeModule(module: Module) {
        this.planActionsService?.forceRemoveModule?.(module, this.parentModule);
    }

    removeCustomCourseUnitAttainment(customCourseUnitAttainment: CustomCourseUnitAttainment) {
        this.planActionsService?.removeCustomCourseUnitAttainmentById?.(customCourseUnitAttainment.id, this.parentModule);
    }

    removeCustomModuleAttainment(customModuleAttainment: CustomModuleAttainment) {
        this.planActionsService?.removeCustomModuleAttainmentById?.(customModuleAttainment.id, this.parentModule);
    }

    removeCustomCourseUnitAttainmentById(customCourseUnitAttainmentId: OtmId) {
        this.planActionsService?.removeCustomCourseUnitAttainmentById?.(customCourseUnitAttainmentId, this.parentModule);
    }

    removeCustomModuleAttainmentById(customModuleAttainmentId: OtmId) {
        this.planActionsService?.removeCustomModuleAttainmentById?.(customModuleAttainmentId, this.parentModule);
    }

}
