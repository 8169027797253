import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import {
    CourseUnitResultItem,
    OtmId,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-search-cooperation-network-result-row',
    templateUrl: './search-cooperation-network-result-row.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SearchCooperationNetworkResultRowComponent implements OnInit, OnDestroy, OnChanges {

    @Input() result: CourseUnitResultItem;
    @Input() term: string;
    @Input() courseCartCourseIds: OtmId[];
    @Input() loggedIn: boolean;
    @Output() addCourseUnitToCourseCart = new EventEmitter<string>();
    @Output() removeCourseUnitFromCourseCart = new EventEmitter<string>();

    isInCart: boolean;
    matchingOtherLang: boolean;
    destroyed$ = new Subject<void>();
    curLangCodeUrns: string[] = [];

    ngOnInit(): void {
        this.matchingOtherLang = this.matchInOtherLang(this.result.name, this.result.nameMatch);

        // "result.curCodeUrns" can include multiple types of a urns (e.g.: "urn:code:course-unit-realisation-type:teaching-participation-lectures"),
        // but we only need the language urns.
        this.curLangCodeUrns = (this.result.curCodeUrns || []).filter((urn: string) => urn.includes('urn:code:language'));
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.courseCartCourseIds) {
            this.isInCourseCart();
        }
    }

    isInCourseCart() {
        this.isInCart = _.includes(this.courseCartCourseIds, this.result.id);
    }

    addToCart() {
        this.addCourseUnitToCourseCart.emit(this.result.id);
    }

    removeFromCart() {
        this.removeCourseUnitFromCourseCart.emit(this.result.id);
    }

    matchInOtherLang(valueInUILang: string, matchedValue: string): boolean {
        return valueInUILang !== matchedValue && /<b>/.test(matchedValue);
    }
}

