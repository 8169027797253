<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-dialog-content>
      <fudis-form [title]="t('PROFILE.CONTACT_INFO')"
                  [level]="2"
                  [errorSummaryTitle]="t('ERROR-SUMMARY.HELP_TEXT')"
                  [errorSummaryVisible]="errorSummaryVisible">
        <fudis-form-actions>
          <fudis-button fudisDialogClose [label]="t('BUTTON.CANCEL')" [variant]="'secondary'" />
          <fudis-button fudisFormSubmit [formValid]="form.valid" [label]="t('BUTTON.SAVE')" (handleClick)="saveContactInformationForm()" />
        </fudis-form-actions>
        <fudis-form-content>
          <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
            <fudis-text-input
                    [id]="'phoneNumber'"
                    [control]="phoneNumber"
                    [type]="'tel'"
                    [label]="t('FIELD_NAMES.phoneNumber')"/>
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('FIELD_NAMES.primaryEmail')" />
                <fudis-dd [contentText]="copyStudentProfile.primaryEmail" />
              </fudis-dl-item>
            </fudis-dl>
            <fudis-text-input
                    [id]="'secondaryEmail'"
                    [control]="secondaryEmail"
                    [type]="'email'"
                    [label]="t('FIELD_NAMES.secondaryEmail')" />
          </fudis-grid>
          <ng-container *ngIf="!isPrimaryAddressEditable">
            <fudis-grid [rowGap]="'sm'">
              <fudis-heading [level]="3" [variant]="'xs'">
                {{ t('FIELD_NAMES.primaryAddress') }}
              </fudis-heading>
              <fudis-body-text>{{ 'PROFILE.GUIDANCE_ADDRESS_NOT_USER_EDITABLE' | translate }}</fudis-body-text>
              <a fudisLink [title]="t('PROFILE.GUIDANCE_ADDRESS_NOT_USER_EDITABLE_URL')"
                 [href]="t('PROFILE.GUIDANCE_ADDRESS_NOT_USER_EDITABLE_URL')"
                 [external]="true"></a>
              <fudis-dl [columns]="{ sm: 1, md: 2 }">
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('FIELD_NAMES.streetAddress')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.streetAddress" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('FIELD_NAMES.postalCode')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.postalCode" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('FIELD_NAMES.city')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.city" />
                </fudis-dl-item>
                <fudis-dl-item>
                  <fudis-dt [contentText]="t('FIELD_NAMES.country')" />
                  <fudis-dd [contentText]="copyStudentProfile.primaryAddress.countryUrn | codeLocalizedName | async" />
                </fudis-dl-item>
              </fudis-dl>
            </fudis-grid>
          </ng-container>
          <fudis-fieldset
                  *ngIf="isPrimaryAddressEditable"
                  class="fudis-mt-md"
                  [label]="t('FIELD_NAMES.primaryAddress')"
                  [errorSummaryBreadcrumb]="true"
          >
            <fudis-fieldset-content>
              <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
                <ng-container *ngIf="isPrimaryAddressEditable">
                  <fudis-text-input
                          [id]="'primaryAddressStreetAddress'"
                          [control]="primaryAddressStreetAddress"
                          [label]="t('FIELD_NAMES.streetAddress')" />
                  <fudis-text-input
                          [id]="'primaryAddressPostalCode'"
                          [control]="primaryAddressPostalCode"
                          [label]="t('FIELD_NAMES.postalCode')" />
                  <fudis-text-input
                          [id]="'primaryAddressCity'"
                          [control]="primaryAddressCity"
                          [label]="t('FIELD_NAMES.city')" />
                  <fudis-dl>
                    <fudis-dl-item>
                      <fudis-dt [contentText]="t('FIELD_NAMES.country')" />
                      <fudis-dd [contentText]="copyStudentProfile.primaryAddress?.countryUrn | codeLocalizedName | async" />
                    </fudis-dl-item>
                  </fudis-dl>
                </ng-container>
              </fudis-grid>
            </fudis-fieldset-content>
          </fudis-fieldset>
          <fudis-heading *ngIf="!copyStudentProfile.secondaryAddress" [level]="3" [variant]="'xs'">
            {{ t('FIELD_NAMES.secondaryAddress') }}
          </fudis-heading>
          <fudis-fieldset *ngIf="copyStudentProfile.secondaryAddress" class="fudis-mt-md" [label]="t('FIELD_NAMES.secondaryAddress')" [errorSummaryBreadcrumb]="true">
            <fudis-fieldset-actions>
              <fudis-button  *ngIf="copyStudentProfile.secondaryAddress"
                             (handleClick)="removeSecondaryAddress()"
                             [labelHidden]="true"
                             [icon]="'delete'"
                             [size]="'icon-only'"
                             [variant]="'tertiary'"
                             [label]="('ARIA_LABEL.DELETE' | translate ) + ' ' + t('FIELD_NAMES.secondaryAddress')" />
            </fudis-fieldset-actions>
            <fudis-fieldset-content>
              <fudis-grid [columns]="{ sm: 1, md: 'inputMd inputMd' }" [rowGap]="'sm'">
                <ng-container *ngIf="copyStudentProfile.secondaryAddress">
                  <sis-radio-button
                          [name]="'secondaryAddressType'"
                          [id]="'finnishAddressRadioButton'"
                          [control]="secondaryAddressType"
                          [inline]="true"
                          [value]="'FinnishAddress'"
                          [translatedLabel]="t('PROFILE.ADDRESS.SECONDARY_ADDRESS_IN_FINLAND')">
                  </sis-radio-button>
                  <sis-radio-button
                          [name]="'secondaryAddressType'"
                          [id]="'genericAddressRadioButton'"
                          [control]="secondaryAddressType"
                          [inline]="true"
                          [translatedLabel]="t('PROFILE.ADDRESS.SECONDARY_ADDRESS_ABROAD')"
                          [value]="'GenericAddress'">
                  </sis-radio-button>
                  <ng-container *ngIf="!isSecondaryAddressInFinland()">
                    <fudis-text-area
                            fudisGridItem [columns]="{ sm: 1, md: 2 }"
                            [id]="'secondaryAddressAddress'"
                            [control]="genericSecondaryAddressAddress"
                            [label]="t('PROFILE.ADDRESS.SECONDARY_ADDRESS_GENERIC_FORM_TITLE')"
                            [helpText]="'PROFILE.ADDRESS.SECONDARY_ADDRESS_GUIDANCE' | translate">
                    </fudis-text-area>
                    <div class="form-group" fudisGridItem [columns]="1">
                      <label [for]="'secondaryAddressCountryUrn'">{{ t('FIELD_NAMES.country') }}</label>
                      <sis-code-select-editor
                              [codebookUrn]="countryCodebookUrns"
                              (onSelectCode)="onSelectCodeUrn(genericSecondaryAddressCountryUrn, $event.selectedUrn)"
                              [name]="'secondaryAddressCountryUrn'"
                              [id]="'secondaryAddressCountryUrn'"
                              [labelForId]="'secondaryAddressCountryInput'"
                              [required]="!isSecondaryAddressInFinland()"
                              [showErrors]="!isSecondaryAddressInFinland()"
                              [selectedUrn]="genericSecondaryAddressCountryUrn.value"
                              [excludeCodes]="finnishCountryCodes">
                      </sis-code-select-editor>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isSecondaryAddressInFinland()">
                    <fudis-text-input
                            [id]="'secondaryAddressStreetAddress'"
                            [control]="finnishSecondaryAddressStreetAddress"
                            [label]="t('FIELD_NAMES.streetAddress')" />
                    <fudis-text-input
                            [id]="'secondaryAddressPostalCode'"
                            [control]="finnishSecondaryAddressPostalCode"
                            [label]="t('FIELD_NAMES.postalCode')" />
                    <fudis-text-input
                            [id]="'secondaryAddressCity'"
                            [control]="finnishSecondaryAddressCity"
                            [label]="t('FIELD_NAMES.city')" />
                  </ng-container>
                </ng-container>
              </fudis-grid>
            </fudis-fieldset-content>
          </fudis-fieldset>
          <fudis-button *ngIf="!copyStudentProfile.secondaryAddress"
                        [label]="t('PROFILE.ADDRESS.ADD_SECONDARY_ADDRESS')"
                        (handleClick)="addSecondaryAddress()"
                        [variant]="'tertiary'"
                        [icon]="'ring-plus'" />
        </fudis-form-content>
      </fudis-form>
    </fudis-dialog-content>
  </fudis-dialog>
</ng-container>
