<ng-container *transloco="let t">
  @if (searchService.searchParameters$ | async; as searchParameters) {
    @let fullTextQueryLength = searchParameters.filters?.fullTextQuery?.length;
    @if (isSearchValid(searchParameters)) {
      <ng-content/>
    } @else if (fullTextQueryLength && fullTextQueryLength < 3 || fullTextQueryLength > maxQueryLength) {
      <p class="guidance" data-cy="search-results-query-guidance">
        @if (fullTextQueryLength === 1) {
          {{ t('SEARCH.TYPE_TWO_MORE_LETTERS') }}
        } @else if (fullTextQueryLength === 2) {
          {{ t('SEARCH.TYPE_ONE_MORE_LETTER') }}
        } @else if (fullTextQueryLength > maxQueryLength) {
          {{ t('SEARCH.QUERY_TEXT_TOO_LONG') }}
        }
      </p>
    }
  }
</ng-container>
