import {
    Component, EventEmitter, Inject, Input, OnInit, Output,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult, ValidatablePlan } from 'common-typescript';
import { Module, StudyRight } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';

import { MODULE_ATTAINMENT_APPLICATION_SERVICE } from '../../../../../ajs-upgraded-modules';

@Component({
    selector: 'app-student-module-info-modal-create-module-attainment-application-block',
    templateUrl: './student-module-info-modal-create-module-attainment-application-block.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentModuleInfoModalCreateModuleAttainmentApplicationBlockComponent implements OnInit {

    attainmentApplicationSendPending = false;
    mode: 'FLAT' | 'RECURSIVE' | 'STUDY_RIGHT_NOT_FOUND';

    @Input() module: Module;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: PlanValidationResult;
    @Input() studyRight: StudyRight;
    @Output() refreshData = new EventEmitter<void>();

    constructor(
        @Inject(MODULE_ATTAINMENT_APPLICATION_SERVICE) private moduleAttainmentApplicationService: any,
        @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any,
    ) {}

    ngOnInit() {
        this.mode = this.getMode();
    }

    getMode() {
        if (!this.studyRight) {
            return 'STUDY_RIGHT_NOT_FOUND';
        }
        const childModules = this.validatablePlan.getSelectedModulesUnderModule(this.module);
        if (!_.isEmpty(childModules)) {
            return 'RECURSIVE';
        }
        return 'FLAT';
    }

    applyForModuleAttainment() {
        this.attainmentApplicationSendPending = true;
        this.moduleAttainmentApplicationService.sendApplication(this.module, this.validatablePlan, this.studyRight, this.planValidationResult)
            .then(() => {
                this.attainmentApplicationSendPending = false;
                this.refreshData.emit();
            }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

}
