import angular from 'angular';
import { dateUtils } from 'common-typescript/constants';
import {
    LocalDateRange,
    ModuleResponsibilityInfoType,
    OtmId,
    ResponsibilityInfo,
    ResponsibilityInfosEditorValidationErrors,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { UuidService } from 'sis-common/uuid/uuid.service';

// @ts-ignore
import { codeBookJSDataModelModule } from '../model/codebook.model';
import { CodeSelectEditorComponent } from '../select/code-select-editor.component';
import { CommonCodeService } from '../service/common-code.service';

// @ts-ignore
import responsibilityInfosEditorComponentHtml from './responsibilityInfosEditor.component.html';
// @ts-ignore
import { searchEmployeeModule } from './searchEmployee.comp';

export const responsibilityInfosEditorModule = 'sis-components.person.responsibilityInfosEditor';

type PartialResponsibilityInfoWithIdAndRoleUrn = Partial<ResponsibilityInfo & { _id: OtmId; roleUrn: ModuleResponsibilityInfoType }>;

export class ResponsibilityInfosEditor {
    private responsibilityInfos: PartialResponsibilityInfoWithIdAndRoleUrn[];
    private responsibilityInfoTypeUrn: string;
    private formName: string;
    private required: boolean;
    private minCount: number;
    private maxCount: number;
    private textMinLength: number;
    private textMaxLength: number;
    private employeeService: Function;
    private validationErrors: ResponsibilityInfosEditorValidationErrors;
    private hideValidity: boolean;
    private titleEnabled: boolean;
    private showAllLanguages: boolean;

    // If you change the constructor parameters, remember to update the "ResponsibilityInfosEditor.$inject = ..." line below too.
    constructor(private $scope: any, private uuidService: any, private commonCodeService: any) {}

    private universityCodes: any;
    private loaded: any;
    private minCountInt: number;
    private maxCountInt: number;

    $onInit(): void {
        this.commonCodeService.getCodeBookUniversityUsage(this.responsibilityInfoTypeUrn)
            .then(
                (response: any) => {
                    this.universityCodes = response;
                    this.loaded = true;
                    this.handleChange();
                },
                () => {
                    this.loaded = true;
                },
            );

        if (_.isNil(this.hideValidity)) {
            this.hideValidity = false;
        }

        if (_.isNil(this.titleEnabled)) {
            this.titleEnabled = false;
        }

        if (_.isNil(this.showAllLanguages)) {
            this.showAllLanguages = false;
        }

        if (this.required && _.isNil(this.minCount)) {
            this.minCount = 1;
        }

        if (!_.isNil(this.minCount) && !_.isEmpty(this.minCount)) {
            this.minCountInt = Number(this.minCount);
        }

        if (!_.isNil(this.maxCount) && !_.isEmpty(this.maxCount)) {
            this.maxCountInt = Number(this.maxCount);
        }

        if (_.isNil(this.responsibilityInfos)) {
            this.responsibilityInfos = [];
        }
        _.forEach(this.responsibilityInfos, (responsibilityInfo, index) => {
            if (_.isUndefined(responsibilityInfo._id)) {
                responsibilityInfo._id = this.uuidService.randomUUID();
            }
            this.responsibilityInfos[index] = _.cloneDeep(responsibilityInfo);
        });

        this.validationErrors = {
            duplicatePersonsWithSameRole: false,
            itemsWithoutPersonIdOrInfoText: false,
            itemsWithoutRoleUrn: false,
            minCount: false,
            maxCount: false,
        };

        // Validate form through scope since ng-model cannot be used in Angular through upgrading
        this.$scope.$watch(
            () => this.validationErrors,
            (newValue: any, oldValue: any, scope: any) => {
                scope[this.formName].$setValidity(`form ${this.formName} invalid`, !_.some(this.validationErrors));
            },
            true,
        );
    }

    $onChanges(): void {
        _.forEach(this.responsibilityInfos, (responsibilityInfo, index) => {
            if (_.isUndefined(responsibilityInfo._id)) {
                responsibilityInfo._id = this.uuidService.randomUUID();
            }
            this.responsibilityInfos[index] = _.cloneDeep(responsibilityInfo);
        });
        setTimeout(() => this.$scope.$apply());
    }

    remove(responsibilityInfoToBeRemoved: ResponsibilityInfo & { _id: string }) {
        this.responsibilityInfos = _.filter(this.responsibilityInfos, responsibilityInfo => responsibilityInfo._id !== responsibilityInfoToBeRemoved._id);
        this.handleChange();
    }

    addNew() {
        this.responsibilityInfos = _.concat(this.responsibilityInfos, { _id: this.uuidService.randomUUID(), roleUrn: null });
        this.handleChange();
    }

    roleChanged(index: number, roleUrn: ModuleResponsibilityInfoType) {
        this.responsibilityInfos = _.map(this.responsibilityInfos, (responsibilityInfo, i) => i === index ? _.assign({}, responsibilityInfo, { roleUrn }) : responsibilityInfo);
        this.handleChange();
    }

    /* called with null person when value is removed */
    personSelected(responsibilityInfoToBeChanged: any, person: any) {
        this.responsibilityInfos = _.map(this.responsibilityInfos, responsibilityInfo => responsibilityInfo._id === responsibilityInfoToBeChanged._id ? _.assign({}, responsibilityInfo, { person, personId: person ? person.id : null }) : responsibilityInfo);
        this.handleChange();
    }

    handleTitleChange(responsibilityInfo: any, title: any) {
        responsibilityInfo.title = title;
        this.handleChange();
    }

    handleChange() {
        // Set form to dirty so that AngularJS-forms work correctly and update reference of responsibility infos for new Angular components
        this.responsibilityInfos = [...this.responsibilityInfos];
        this.$scope[this.formName]?.$setDirty();
        // trigger to detect changes in responsibilityInfos and form errors
        setTimeout(() => this.$scope.$apply());
    }

    validateHasDuplicatePersonsWithSameRole() {
        return this.validationErrors.duplicatePersonsWithSameRole = this.isAnyDuplicatePersonsWithSameRoleAndValidityPeriod();
    }

    isAnyDuplicatePersonsWithSameRoleAndValidityPeriod(): boolean {
        for (const resInfo of this.responsibilityInfos) {
            const possibleDuplicates = this.responsibleInfosWithDuplicatePersonAndRole(resInfo._id, resInfo.personId, resInfo.roleUrn);
            if (possibleDuplicates.length > 0) {
                if (this.areValidityPeriodsOverlapping(resInfo.validityPeriod, _.map(possibleDuplicates, 'validityPeriod'))) {
                    return true;
                }
            }
        }
        return false;
    }

    responsibleInfosWithDuplicatePersonAndRole(id: OtmId, personId: OtmId, roleUrn: any) {
        return _.filter(this.responsibilityInfos, x => x._id !== id &&
            (x.personId != null && x.personId === personId) &&
            x.roleUrn === roleUrn);
    }

    areValidityPeriodsOverlapping(validityPeriod: LocalDateRange, possibleOverlappingValidityPeriods: LocalDateRange[]): boolean {
        for (const possibleOverlapping of possibleOverlappingValidityPeriods) {
            if (dateUtils.dateTimeRangesOverlapNullsAsInfinity(validityPeriod?.startDate, validityPeriod?.endDate, possibleOverlapping?.startDate, possibleOverlapping?.endDate)) {
                return true;
            }
        }
        return false;
    }

    validateSomeItemsWithoutPersonIdOrInfoText() {
        return this.validationErrors.itemsWithoutPersonIdOrInfoText =
            !_.every(this.responsibilityInfos,
                     responsibilityInfo => !_.isNil(responsibilityInfo.personId) || !_.isNil(responsibilityInfo.text));
    }

    validateSomeItemsWithoutRoleUrn() {
        return this.validationErrors.itemsWithoutRoleUrn = !_.every(this.responsibilityInfos, responsibilityInfo => !_.isNil(responsibilityInfo.roleUrn));
    }

    validateOverMaxCount() {
        return this.validationErrors.maxCount = !_.isNil(this.maxCountInt) && this.responsibilityInfos.length > this.maxCountInt;
    }

    validateUnderMinCount() {
        return this.validationErrors.minCount = !_.isNil(this.minCountInt) && this.responsibilityInfos.length < this.minCountInt;
    }

    isTitleEnabled() {
        return this.titleEnabled;
    }

    displayAllLanguages() {
        return this.showAllLanguages;
    }

}
ResponsibilityInfosEditor.$inject = ['$scope', 'uuidService', 'commonCodeService'];

angular.module(responsibilityInfosEditorModule, [
    'pascalprecht.translate',
    'sis-common.l10n.localizedStringFilter',
    UuidService.downgrade.moduleName,
    'sis-components.date.localDateRangeFilter',
    'sis-components.date.localDateRangeEditor',
    CodeSelectEditorComponent.downgrade.moduleName,
    searchEmployeeModule,
    CommonCodeService.downgrade.moduleName,
    codeBookJSDataModelModule,

]).component('responsibilityInfosEditor', {
    template: responsibilityInfosEditorComponentHtml,
    controller: ResponsibilityInfosEditor,
    bindings: {
        responsibilityInfos: '=',
        responsibilityInfoTypeUrn: '@',
        formName: '@',
        required: '@',
        hideValidity: '<?',
        titleEnabled: '<?',
        showAllLanguages: '<?',
        minCount: '@',
        maxCount: '@',
        textMinLength: '@',
        textMaxLength: '@',
        employeeService: '<',
        validationErrors: '=?',
    },
});
