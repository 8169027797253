<div class="action-box">
  <sis-button *ngIf="action !== 'select-to-plan'" [link]="true" (clicked)="actionChange.emit()">
    <ng-container *ngIf="action === 'place-to-plan'" [ngTemplateOutlet]="title"
                  [ngTemplateOutletContext]="{key:'SIS_COMPONENTS.STUDY.PLACE_TO_PLAN', icon: 'place'}"></ng-container>
    <ng-container *ngIf="action === 'remove-from-plan'" [ngTemplateOutlet]="title"
                  [ngTemplateOutletContext]="{key:'SIS_COMPONENTS.STUDY.REMOVE_FROM_PLAN', icon: 'delete'}"></ng-container>
    <ng-container *ngIf="action === 'remove-selection'" [ngTemplateOutlet]="title"
                  [ngTemplateOutletContext]="{key:'SIS_COMPONENTS.STUDY.REMOVE_SELECTION', icon: 'delete'}"></ng-container>
  </sis-button>
  <ng-container *ngIf="action === 'select-to-plan'">
    @if (inputType === 'radio-button') {
      <sis-simple-radio-button
        [selected]="selected"
        [id]="actionInputId"
        [name]="actionInputName"
        [visuallyHiddenInfo]="studyInfo"
        label="SIS_COMPONENTS.STUDY.SELECT_TO_PLAN"
        (selectedChange)="toggleSelected()"/>
    } @else {
      <sis-checkbox [checked]="selected"
                    [id]="actionInputId"
                    (checkedChange)="toggleSelected()"
                    [name]="actionInputName"
                    label="SIS_COMPONENTS.STUDY.SELECT_TO_PLAN"
                    [visuallyHiddenInfo]="studyInfo">
      </sis-checkbox>
    }
  </ng-container>
</div>
<ng-template #title let-key="key" let-icon="icon">
  <sis-icon [icon]="icon" [iconClass]="action === 'place-to-plan' && 'cw-90'"></sis-icon>{{key | translate}}
  <span class="visually-hidden">{{studyInfo}}</span>
</ng-template>
