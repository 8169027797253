import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength } from 'common-typescript/constants';
import { CustomAttainmentApplication,
    CustomAttainmentWorkflowApplication, CustomStudyDraft, OtmId, Urn } from 'common-typescript/types';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { isNumber, max, maxLength, min, required } from 'sis-components/form/form-validators';
import { getLabelState, getNumericInputValue } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

export interface CustomAttainmentFormInfo {
    applicationRationale: FormControl<string>;
    responsibleTeacher: FormControl<string>;
    plannedCredits: FormControl<number>;
    attainmentLanguage: FormControl<Urn>;
    attainmentDescription: FormControl<string>;
}
@Component({
    selector: 'app-caa-attainment-info',
    templateUrl: './caa-attainment-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CaaAttainmentInfoComponent implements OnInit {

    @Input() customStudyDraft: CustomStudyDraft;
    @Input() workflowId?: OtmId;
    @Input() workflowApplication?: Partial<CustomAttainmentWorkflowApplication>;
    @Input() initialAttachments: FileItem[] = [];
    @Output() attachmentsChange = new EventEmitter<FileItem[]>();
    @Output() exit = new EventEmitter<void>();
    @Output() previous = new EventEmitter<void>();
    @Output() continue = new EventEmitter<Partial<CustomAttainmentApplication>>();

    form: FormGroup<CustomAttainmentFormInfo>;
    getLabelState = getLabelState;
    readonly attainmentLanguageCodeBookUrn = 'urn:code:language';
    readonly acceptedFileTypes = [
        'application/msword',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png',
        'text/plain',
    ];

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group<CustomAttainmentFormInfo>({
            applicationRationale: new FormControl(this.workflowApplication?.applicationRationale, [required(), maxLength(MaxLength.MAX_LONG_STRING_LENGTH)]),
            responsibleTeacher: new FormControl(this.workflowApplication?.responsibleTeacher, [required(), maxLength(MaxLength.MAX_TERSE_STRING_LENGTH)]),
            plannedCredits: new FormControl(this.workflowApplication?.plannedCredits || this.customStudyDraft?.credits, [required(), isNumber(), min(0), max(99)]),
            attainmentLanguage: new FormControl(this.workflowApplication?.attainmentLanguage, required()),
            attainmentDescription: new FormControl(
                this.workflowApplication?.attainmentDescription ||
                this.customStudyDraft?.description,
                [
                    required(),
                    maxLength(MaxLength.MAX_LONG_STRING_LENGTH),
                ],
            ),
        });
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.continue.emit({
                ...this.form.value,
                plannedCredits: getNumericInputValue(this.plannedCredits),
            });
        } else {
            this.form.markAllAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    onAttainmentLanguageChange(language: Urn): void {
        if (language !== this.attainmentLanguage.value) {
            this.attainmentLanguage.setValue(language);
            this.attainmentLanguage.markAsTouched();
            this.attainmentLanguage.markAsDirty();
        }
    }

    get applicationRationale(): FormControl<string> {
        return this.form?.get('applicationRationale') as FormControl<string>;
    }

    get responsibleTeacher(): FormControl<string> {
        return this.form?.get('responsibleTeacher') as FormControl<string>;
    }

    get plannedCredits(): FormControl<number> {
        return this.form?.get('plannedCredits') as FormControl<number>;
    }

    get attainmentLanguage(): FormControl<Urn> {
        return this.form?.get('attainmentLanguage') as FormControl<Urn>;
    }

    get attainmentDescription(): FormControl<string> {
        return this.form?.get('attainmentDescription') as FormControl<string>;
    }
}
