import angular from 'angular';
import angularTranslate from 'angular-translate';
import * as _ from 'lodash-es';
import moment from 'moment';
import 'sis-components/error-handler/legacy/errorService';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { localeServiceModule } from 'sis-common/l10n/localeService';
import { TooltipComponent } from 'sis-components/tooltip/tooltip.component.ts';
import { substitutionModule } from 'sis-components/courseUnit/substitution.component';
import { commonCurriculumPeriodServiceModule } from '../service/curriculumPeriod.service';
import { substitutionInfoModule } from './substitutionInfo.component';
import courseUnitTpl from './courseUnit.tpl.html';
(function () {
  CourseUnitCtrl.$inject = ["$translate", "localeService", "commonCourseUnitService", "planAttainmentsService", "commonStudentAttainmentService", "colorService", "commonCurriculumPeriodService", "validAttainmentFilterService", "defaultPromiseHandler"];
  angular.module('sis-components.courseUnit', ['sis-components.number.creditRangeFilter', 'sis-components.service.courseUnitService', 'sis-common.errorhandler.errorService', 'sis-components.service.planAttainmentsService', 'sis-components.service.studentAttainmentService', 'sis-components.service.colorService', 'sis-components.service.validAttainmentFilterService', 'sis-components.date.localDateFormatFilter', substitutionInfoModule, substitutionModule, angularTranslate, commonCurriculumPeriodServiceModule, localeServiceModule, IconComponent.downgrade.moduleName, TooltipComponent.downgrade.moduleName]).component('commonCourseUnit', {
    template: courseUnitTpl,
    controller: CourseUnitCtrl,
    transclude: {
      additional: '?additional',
      notifications: '?notifications'
    },
    bindings: {
      courseUnitId: '<?',
      courseUnitGroupId: '<?',
      courseUnit: '<?',
      validatablePlan: '<?',
      parentModuleId: '<?',
      parentModuleGroupId: '<?',
      isSubstitution: '<?',
      showExpiryWarning: '<?',
      clickAction: '&?',
      substituteFor: '<?',
      // Set to true for an implicit course unit selection
      showSelectedParent: '<?',
      // supplied attainment
      courseUnitAttainment: '<?'
    }
  });

  /**
   * @ngInject
   */
  function CourseUnitCtrl($translate, localeService, commonCourseUnitService, planAttainmentsService,
  // NOSONAR
  commonStudentAttainmentService, colorService, commonCurriculumPeriodService, validAttainmentFilterService, defaultPromiseHandler) {
    const $ctrl = this;
    $ctrl.attainment = undefined;
    $ctrl.visibleCredits = undefined;
    $ctrl.gradeAbbreviation = undefined;
    $ctrl.categoryCssClass = undefined;
    $ctrl.selected = false;
    $ctrl.clickable = false;
    $ctrl.titleAriaLabel = undefined;
    $ctrl.substitutionExpanded = false;
    $ctrl.$onInit = function () {
      if (!!$ctrl.courseUnitGroupId && !$ctrl.courseUnit && !$ctrl.courseUnitId) {
        commonCourseUnitService.findByGroupId($ctrl.courseUnitGroupId).then(courseUnit => {
          $ctrl.courseUnit = courseUnit;
          updateCourseUnitColor();
          setVisibleCredits(courseUnit);
          loadCurriculumPeriods($ctrl.courseUnit);
          if ($ctrl.courseUnitAttainment) {
            $ctrl.attainment = $ctrl.courseUnitAttainment;
            setLocalizedGradeAbbreviation($ctrl.attainment);
            setVisibleCredits($ctrl.courseUnit, $ctrl.attainment);
          }
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
      if ($ctrl.courseUnit) {
        loadCurriculumPeriods($ctrl.courseUnit);
      }
      updateCourseUnitColor();
      $ctrl.clickable = angular.isDefined($ctrl.clickAction);
    };
    $ctrl.onClick = function () {
      $ctrl.clickAction();
    };
    $ctrl.updateAttainment = function () {
      if ($ctrl.courseUnit && $ctrl.plan) {
        const courseUnitId = $ctrl.courseUnit.id;
        planAttainmentsService.getCourseUnitAttainment($ctrl.plan, courseUnitId).then(courseUnitAttainment => {
          if (courseUnitAttainment) {
            $ctrl.attainment = courseUnitAttainment;
            setLocalizedGradeAbbreviation(courseUnitAttainment);
            setVisibleCredits($ctrl.courseUnit, courseUnitAttainment);
          } else {
            setVisibleCredits($ctrl.courseUnit, undefined);
          }
        }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        const courseUnitInPlan = _.find($ctrl.plan.courseUnitSelections, {
          courseUnitId
        });
        const parentModuleId = _.get(courseUnitInPlan, 'parentModuleId');
        if (parentModuleId) {
          planAttainmentsService.getModuleAttainment($ctrl.plan, parentModuleId).then(moduleAttainment => {
            if (moduleAttainment) {
              const courseUnitAttainment = _.find(moduleAttainment.attainments, {
                courseUnitId
              });
              if (courseUnitAttainment) {
                $ctrl.attainment = courseUnitAttainment;
                setVisibleCredits($ctrl.courseUnit, courseUnitAttainment);
              } else {
                setVisibleCredits($ctrl.courseUnit, undefined);
              }
            }
          }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
        }
        if ($ctrl.showSelectedParent) {
          $ctrl.selectedParent = _.get($ctrl.validatablePlan.modulesById, $ctrl.parentModuleId);
        }
      }
    };
    $ctrl.$onChanges = function (changesObject) {
      if (changesObject.parentModuleGroupId && changesObject.parentModuleGroupId.currentValue) {
        $ctrl.parentModuleGroupId = changesObject.parentModuleGroupId.currentValue;
      }
      if (changesObject.validatablePlan) {
        const validatablePlan = changesObject.validatablePlan.currentValue;
        $ctrl.plan = _.get(validatablePlan, 'plan');
        $ctrl.updateAttainment();
      }
      if (changesObject.courseUnitId && changesObject.courseUnitId.currentValue) {
        commonCourseUnitService.findById(changesObject.courseUnitId.currentValue, {
          loadRelations: true
        }).then(courseUnit => {
          $ctrl.courseUnit = courseUnit;
          setVisibleCredits(courseUnit);
          loadCurriculumPeriods($ctrl.courseUnit);
        }).then($ctrl.updateAttainment).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      }
      if (changesObject.courseUnit && changesObject.courseUnit.currentValue) {
        setVisibleCredits($ctrl.courseUnit);
        setTitleAriaLabel();
      }
    };
    $ctrl.isAttainmentAboutToExpire = function () {
      const allAttainments = $ctrl.validatablePlan ? $ctrl.validatablePlan.getAllAttainments() : null;
      return $ctrl.attainment && allAttainments && validAttainmentFilterService.isAttainmentAboutToExpire($ctrl.attainment) && !validAttainmentFilterService.isAttached($ctrl.attainment, allAttainments);
    };
    $ctrl.getAttainmentExpiryDate = function () {
      return $ctrl.attainment ? moment($ctrl.attainment.expiryDate) : null;
    };
    function setLocalizedGradeAbbreviation(attainment) {
      const gradeScale = commonStudentAttainmentService.get('gradeScales', attainment.gradeScaleId);
      $ctrl.gradeAbbreviation = _.get(_.find(gradeScale.grades, {
        localId: attainment.gradeId
      }), 'abbreviation');
    }
    function setVisibleCredits(courseUnit, attainment) {
      if (attainment) {
        $ctrl.visibleCredits = {
          min: attainment.credits,
          max: attainment.credits
        };
      } else if (courseUnit) {
        $ctrl.visibleCredits = courseUnit.credits;
      }
      if ($ctrl.validatablePlan && $ctrl.validatablePlan.isSubstitute(courseUnit) && $ctrl.substituteFor) {
        const substitutedCourseUnit = $ctrl.validatablePlan.getCourseUnit($ctrl.substituteFor);
        const creditsUsedForSubstitution = $ctrl.validatablePlan.getSubstitutionCredits(substitutedCourseUnit, courseUnit);
        if (creditsUsedForSubstitution && $ctrl.visibleCredits.max !== creditsUsedForSubstitution) {
          $ctrl.visibleCredits = {
            min: creditsUsedForSubstitution,
            max: creditsUsedForSubstitution
          };
          $ctrl.isPartialCreditAmount = true;
        }
      }
    }
    function updateCourseUnitColor() {
      let courseUnitCategory;
      if ($ctrl.courseUnitId) {
        courseUnitCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, $ctrl.courseUnitId);
      } else if ($ctrl.courseUnit) {
        courseUnitCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, $ctrl.courseUnit.id);
      }
      if (courseUnitCategory) {
        $ctrl.categoryCssClass = `cu-color-${courseUnitCategory}`;
      }
    }
    function loadCurriculumPeriods(courseUnit) {
      commonCurriculumPeriodService.findCurriculumPeriodsByIds(courseUnit.curriculumPeriodIds).then(curriculumPeriods => {
        $ctrl.curriculumPeriods = _.sortBy(curriculumPeriods, 'activePeriod.startDate');
        setTitleAriaLabel();
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    $ctrl.isInvalidSelection = function (courseUnit) {
      return _.get(courseUnit, 'validationResults.invalidSelection') === true;
    };
    $ctrl.isInvalidAccordingToModuleContentApproval = function (courseUnit) {
      return _.get(courseUnit, 'validationResults.invalidAccordingToModuleContentApproval') === true;
    };
    $ctrl.hasModuleContentApproval = function (courseUnit) {
      return _.get(courseUnit, 'validationResults.invalidAccordingToModuleContentApproval') !== undefined;
    };
    function setTitleAriaLabel() {
      const courseUnitName = localeService.getLocalizedValue($ctrl.courseUnit.name);
      const curriculumPeriods = $ctrl.curriculumPeriods || [];
      const curriculumPeriodsHeading = $translate.instant('SIS_COMPONENTS.PLAN.STUDY_TOOLTIP.CURRICULUM_PERIODS');
      const curriculumPeriodNames = curriculumPeriods.map(period => localeService.getLocalizedValue(period.name)).join(', ');
      $ctrl.titleAriaLabel = `${courseUnitName}, ${curriculumPeriodsHeading}: ${curriculumPeriodNames}`;
    }
  }
})();