import { Injectable } from '@angular/core';
import { LocalDateString } from 'common-typescript/types';
import * as _ from 'lodash-es';

import {
    AttainmentTreeGroupNode,
    AttainmentTreeNode,
    CourseUnitAttainmentNode,
    ModuleAttainmentNode,
} from './attainment-tree-node.types';

@Injectable()
export class AttainmentSortingService {
    /*
     * Sorts given nodes primarily by attainmentDate, secondarily by registrationDate with id as tie breaker.
     * The node with the newest attainmentDate will be the first element in the returned array.
     */
    sortNodesByAttainmentDate<T extends AttainmentTreeNode>(nodes: T[]): T[] {
        return _.orderBy(
            nodes,
            ['attainmentDate', 'registrationDate', 'id'],
            ['desc', 'desc', 'asc'],
        );
    }

    getLatestRegistrationDate(nodes: (ModuleAttainmentNode | CourseUnitAttainmentNode | AttainmentTreeGroupNode)[] | null): LocalDateString {
        if (nodes && nodes.length > 0) {
            const registrationDates = nodes.map((node) => node.registrationDate as LocalDateString);
            return _.reduce(registrationDates, (d1, d2) => d1 > d2 ? d1 : d2);
        }
        return null;
    }
}
