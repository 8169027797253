import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ruleUtils, ValidatablePlan } from 'common-typescript';
import { EntityWithRule, Rule } from 'common-typescript/types';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-rule-select',
    templateUrl: './rule-select.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class RuleSelectComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.ruleSelect',
        directiveName: 'sisRuleSelect',
    };

    @Input() parentModule: EntityWithRule;
    @Input() rule: Rule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    readonly ruleUtils = ruleUtils;
}
