import * as _ from 'lodash-es';
import percentEditorTpl from './percentEditor.tpl.html';
(function () {
  angular.module("sis-components.number.percentEditor", ['pascalprecht.translate', 'sis-common.l10n.localizedStringFilter']).directive('percentEditor', percentEditorDirective);

  /**
   * @ngInject
   */
  function percentEditorDirective() {
    return {
      restrict: 'E',
      require: ['ngModel'],
      template: percentEditorTpl,
      link: function (scope, elem, attrs, ctrls) {
        scope.ngModel = ctrls[0];
        scope.ngModel.$formatters.push(function (modelValue) {
          if (_.isNil(modelValue)) {
            return null;
          }
          return modelValue * 100;
        });
        scope.ngModel.$parsers.push(function (viewValue) {
          if (_.isNil(viewValue)) {
            return null;
          }
          return _.round(viewValue / 100, 6);
        });
        scope.update = function () {
          scope.ngModel.$setViewValue(scope.ngModel.$viewValue);
        };
      }
    };
  }
})();