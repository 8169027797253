import { Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, CurriculumPeriod, Plan } from 'common-typescript/types';
import _ from 'lodash';

import { CourseUnitInfoVersion } from '../courseUnitInfo/course-unit-info.service';

@Injectable({
    providedIn: 'root',
})
export class CourseUnitVersionService {

    showVersionChangeSuggestionNotification(courseUnit: CourseUnit, courseUnitVersions: CourseUnitInfoVersion[], validatablePlan: ValidatablePlan, currentCurriculumPeriod: CurriculumPeriod): boolean {
        if (validatablePlan?.isCourseUnitAttained(courseUnit.id)) {
            return false;
        }
        const currentCuVersion = courseUnitVersions.find(cuv => _.eq(cuv.courseUnit.id, courseUnit.id));
        const doesCurrentCurriculumPeriodExistInCurrentCuVersion = this.currentCurriculumPeriodExistInCuVersion(currentCuVersion, currentCurriculumPeriod);
        const someCourseUnitVersionHasCurrentCurriculumPeriod = courseUnitVersions
            .some(cuVersion => this.currentCurriculumPeriodExistInCuVersion(cuVersion, currentCurriculumPeriod));

        if (doesCurrentCurriculumPeriodExistInCurrentCuVersion) { // Check if current curriculum period exists in selected course unit version
            return false;
        }
        return someCourseUnitVersionHasCurrentCurriculumPeriod;
    }

    currentCurriculumPeriodExistInCuVersion(currentVersion: CourseUnitInfoVersion, currentCurriculumPeriod: CurriculumPeriod): boolean {
        return currentVersion?.curriculumPeriods.some(cp => _.isEqual(cp.activePeriod, currentCurriculumPeriod?.activePeriod));
    }

    showVersionSwitchNotification(originalCourseUnit: CourseUnit, validatablePlan: ValidatablePlan): boolean {
        if (originalCourseUnit) {
            return validatablePlan?.isCourseUnitInPlan(originalCourseUnit) || validatablePlan?.isCourseUnitAttained(originalCourseUnit.id) || validatablePlan?.isCourseUnitInPlanAsSubstitute(originalCourseUnit);
        }
    }

    wasVersionChangeSuccessful(oldCourseUnitVersion: CourseUnit, newCourseUnitVersion: CourseUnit, rawPlan: Plan): boolean {
        const courseUnitSelections = _.get(rawPlan, 'courseUnitSelections') || [];
        const oldVersionCourseUnitSelection = _.find(courseUnitSelections, { courseUnitId: oldCourseUnitVersion?.id });
        const newVersionCourseUnitSelection = _.find(courseUnitSelections, { courseUnitId: newCourseUnitVersion?.id });
        if (newVersionCourseUnitSelection && !oldVersionCourseUnitSelection) {
            return true;
        }
        return false;
    }
}
