import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Organisation, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { from, Observable, tap } from 'rxjs';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { Option } from '../../select/dropdown-select/dropdown-select.component';
import { OrganisationEntityService } from '../../service/organisation-entity.service';
import { UniversityService } from '../../service/university.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-university-select-editor',
    templateUrl: './university-select-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class UniversitySelectEditorComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.university.universitySelectEditor',
        directiveName: 'sisUniversitySelectEditor',
    };

    universities: Organisation[] = [];
    universityOptions: Option[] = [];

    @Input() ariaLabelledBy: string;
    @Input() compact: boolean;
    @Input() onlySisuOrganisations: boolean;
    @Input() onDarkBackground: boolean;
    @Input() placeOnLeft: boolean;
    @Input() university: Organisation;

    @Output() universityChange = new EventEmitter<Organisation>();

    loadUniversities$: Observable<any>;

    constructor(private localeService: LocaleService,
                private organisationService: OrganisationEntityService,
                private universityService: UniversityService,
                private appErrorHandler: AppErrorHandler) {}

    setDefaultUniversity(universities: Organisation[]) {
        const currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
        if (currentUniversityOrgId !== null) {
            this.selectUniversity(currentUniversityOrgId, true);
        }
        if (!this.university) {
            this.selectUniversity(universities[0]?.id, true);
        }
    }

    selectUniversity(id: OtmId, skipOnChange?: boolean) {
        this.university = _.find(this.universities, { id }) as Organisation;
        if (this.universityChange && !skipOnChange) {
            this.universityChange.emit(this.university);
        }
    }

    ngOnInit() {
        this.loadUniversities$ = this.organisationService.getRootOrganisationsAndStore()
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                tap((result: Organisation[]) => {
                    let universities = result;
                    if (this.onlySisuOrganisations) {
                        universities = result.filter(university => !university.cooperationNetworkIdentifiers || university.cooperationNetworkIdentifiers.direction !== 'INBOUND');
                    }
                    this.universities = universities;
                    this.universityOptions = universities.map(uni => ({ value: uni.id, label: this.localeService.localize(uni.name) }));
                    if (!this.university) {
                        this.setDefaultUniversity(universities);
                    } else if (!this.university.metadata) {
                        this.selectUniversity(this.university.id, true);
                    }
                }));
    }
}
