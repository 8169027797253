<ng-container *transloco="let t">
  <div *ngIf="this.studentWorkflow" data-cy="prior-learning-workflow-student-view">
    <fudis-grid [rowGap]="'md'">
      <fudis-grid-item>
        <div class="d-flex flex-column flex-sm-row justify-content-end gap-4">
          <fudis-button *ngIf="isSubstitutionWorkflow()"
                        [label]="t('STUDENT_APPLICATIONS.SHOW_COURSE_UNIT_INFO')"
                        [variant]="'tertiary'"
                        [icon]="'brochure'"
                        (handleClick)="openCourseUnitInfoModal()"
                        data-cy="plw-course-unit-info-button"
          />
          <fudis-button *ngIf="isSupplementRequested()"
                        [label]="t('PROFILE.APPLICATIONS.SUPPLEMENT.SUPPLEMENT_APPLICATION')"
                        [variant]="'secondary'"
                        (handleClick)="goToSupplementWizard()"
                        data-cy="plw-supplement-button"
          />
          <fudis-button *ngIf="isWorkflowRequested() || isWorkflowInHandling() || isSupplementRequested()"
                        [label]="t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.BUTTON.OPEN_CANCEL_APPLICATION_MODAL')"
                        (handleClick)="openCancelDialog()"
                        data-cy="plw-cancel-button"
          />
        </div>
      </fudis-grid-item>
    </fudis-grid>

    <fudis-grid [classes]="'fudis-mt-sm'" [rowGap]="'sm'">
        <fudis-notification [variant]="'warning'" *ngIf="isWorkflowRequested()">
          <fudis-body-text>
            {{ t('PROFILE.APPLICATIONS.ATTACHMENT_DELIVERY_REMINDER') }}
          </fudis-body-text>
        </fudis-notification>
        <fudis-notification [variant]="'warning'" *ngIf=" isSupplementRequested() && this.supplementRationale$ | async as supplementRationale">
          <fudis-body-text>{{ t('PROFILE.APPLICATIONS.SUPPLEMENT_REQUESTED_RATIONALE') }}</fudis-body-text>
          <fudis-body-text>{{ supplementRationale }}</fudis-body-text>
        </fudis-notification>
        <fudis-notification *ngIf="isInitiatedByStaff()" [variant]="'info'">
          <fudis-body-text>
            {{ t('PROFILE.APPLICATIONS.APPLICATION_CREATED_BY_STAFF') }}
          </fudis-body-text>
        </fudis-notification>

      <fudis-body-text *ngIf="isWorkflowCancelled()" [variant]="'md-light'">
        {{ t('PROFILE.APPLICATIONS.APPLICATION_CANCELLED') }}
      </fudis-body-text>

      <sis-localized-markup-string-view class="fudis-body-text__md-light"
                                        *ngIf="isInclusionWorkflow() && inclusionApplicationInstruction$ | async as inclusionApplicationInstruction"
                                        [content]="inclusionApplicationInstruction"
      />

      <fudis-grid-item *ngIf="!studentWorkflow.decision">
        <fudis-heading [level]="2">{{ t('STUDENT_APPLICATIONS.APPLICATION_INFO') }}</fudis-heading>
        <app-prior-learning-workflow-content *ngIf="studentWorkflow"
                                             [priorLearningWorkflow]="studentWorkflow"
                                             (openCourseUnitInfoModal)="openCourseUnitInfoModal()">
        </app-prior-learning-workflow-content>
      </fudis-grid-item>

      <fudis-grid-item *ngIf="studentWorkflow.decision">
        <fudis-expandable [level]="2" [title]="t('STUDENT_APPLICATIONS.APPLICATION_INFO')">
          <ng-template fudisExpandableContent>
            <app-prior-learning-workflow-content *ngIf="studentWorkflow"
                                                 [priorLearningWorkflow]="studentWorkflow"
                                                 (openCourseUnitInfoModal)="openCourseUnitInfoModal()">
            </app-prior-learning-workflow-content>
          </ng-template>
        </fudis-expandable>
        <fudis-expandable [title]="t('STUDENT_APPLICATIONS.DECISION_INFO')"
                          [level]="2"
                          [closed]="false">
          <ng-template fudisExpandableContent>
            <sis-attainment-workflow-decision-summary [workflowDecision]="studentWorkflow.decision"
                                                      [workflowState]="studentWorkflow.state"
                                                      [workflowType]="studentWorkflow.type"
                                                      [attainment]="activeAttainment"
            />
          </ng-template>
        </fudis-expandable>
      </fudis-grid-item>
    </fudis-grid>
  </div>
</ng-container>
