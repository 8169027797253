import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, concatMap, distinctUntilChanged, Observable, of, startWith } from 'rxjs';

import { LEGACY_TRANSLATE_SERVICE } from '../ajs-upgraded-modules';

@Injectable({ providedIn: 'root' })
export class LanguageChangeService {

    /**
     * Starts by emitting the current language,
     * followed by new languages whenever the language changes.
     */
    readonly currentLanguage$: Observable<string> = of(null).pipe(
        // Don't do anything outside a subscription.
        // E.g. startWith wouldn't work correctly for subscriptions started after a language change,
        // if we set it up synchronously during this class's construction.
        concatMap(() => this.translocoService.langChanges$.pipe(
            startWith(this.translocoService.getActiveLang()),
        )),
        distinctUntilChanged(),
    );

    public languageWasChanged = new BehaviorSubject<boolean>(false);

    constructor(
        private state: StateService,
        private translateService: TranslateService,
        private translocoService: TranslocoService,
        @Inject(DOCUMENT) private document: Document,
        @Optional() @Inject(LEGACY_TRANSLATE_SERVICE) private legacyTranslateService: any,
    ) {
        this.document.documentElement.setAttribute('lang', this.translocoService.getActiveLang());
    }

    use(newLanguage: string): void {
        this.translocoService.setActiveLang(newLanguage);
        this.translateService.use(newLanguage);
        this.languageWasChanged.next(true);
        this.document.documentElement.setAttribute('lang', newLanguage);
        if (this.legacyTranslateService) {
            return this.legacyTranslateService.use(newLanguage).then(() => this.state.reload());
        }
        this.state.reload();
    }
}
