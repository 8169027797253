<ng-container *transloco="let t">
  <div class="messenger-inner"
       id="{{ planMessage.id }}">

    <div class="row message-title-row">
      <div class="col-6 col-sm-8 col-xl-9 message-title">
        <span>{{ t(messageTypeTranslationKey) }}</span>
          @if (isPlanCourseUnitComment(planMessage)) {
            <span> {{ planMessage.courseUnitId | courseUnit | async | entityName | localizedString }}</span>
          }
          @if (isPlanCustomStudyDraftComment(planMessage)) {
            <span> {{ planMessage.customStudyDraft.name }}</span>
          }
          @if (isPlanModuleComment(planMessage)) {
            <span> {{ planMessage.moduleId | module | async | entityName | localizedString }}</span>
          }
      </div>
      <div class="col-6 col-sm-4 col-xl-3 message-info">
        <span class="messenger-timestamp">{{ planMessage.messageTime | localDateTimeFormat }}</span>
        <span>{{ senderFullName }}</span>
      </div>
    </div>

    @if (!isPlanGenericComment(planMessage)) {
      @if (hasStudyBox(planMessage)) {
        <div class="row">
          <div class="study-box-message col-12 col-lg-8">
            @if (isPlanCourseUnitComment(planMessage) && (planMessage.courseUnitId | courseUnit | async); as courseUnit) {
              @let attainment = validatablePlan.getCourseUnitAttainmentByGroupId(courseUnit.groupId);
              <sis-study-box [type]="StudyBoxType.courseUnit"
                             [id]="courseUnit.id"
                             [name]="courseUnit.name"
                             [code]="courseUnit.code"
                             [credits]="courseUnit.credits"
                             [studyState]="courseUnit.documentState !== 'ACTIVE' ? courseUnit.documentState : undefined"
                             [attainedCredits]="attainment?.credits"
                             [gradeId]="attainment?.gradeId"
                             [gradeScaleId]="attainment?.gradeScaleId"
                             [isSubstituted]="attainment?.state === 'SUBSTITUTED'"
                             [level]="3">
                <ng-container study-box="notifications">
                  @if (validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit)) {
                    <sis-study-notification-box type="substitution">
                      {{ t('SIS_COMPONENTS.STUDY.SELECTED_SUBSTITUTION') }}
                    </sis-study-notification-box>
                  }
                </ng-container>
              </sis-study-box>
            }

            @if (isPlanCustomStudyDraftComment(planMessage) && planMessage.customStudyDraft; as studyDraft) {
              <sis-study-box [type]="StudyBoxType.studyDraft"
                             [id]="studyDraft.id"
                             [name]="studyDraft.name"
                             [credits]="{ max: studyDraft.credits, min: studyDraft.credits }"
                             [level]="3">
              </sis-study-box>
            }

            @if (isPlanModuleComment(planMessage) && (planMessage.moduleId | module | async); as module) {
              @let attainment = validatablePlan.getModuleAttainmentByGroupId(module.groupId);
              <sis-study-box [type]="StudyBoxType.studyModule"
                             [id]="module.id"
                             [name]="module.name"
                             [code]="module.code"
                             [credits]="module | moduleTargetCredits"
                             [studyState]="module.documentState !== 'ACTIVE' ? module.documentState : undefined"
                             [attainedCredits]="attainment?.credits"
                             [gradeId]="attainment?.gradeId"
                             [gradeScaleId]="attainment?.gradeScaleId"
                             [isSubstituted]="attainment?.state === 'SUBSTITUTED'"
                             [level]="3">
              </sis-study-box>
            }

          </div>
        </div>

        @if (isSuggestionForAddition(planMessage)) {
          <div class="row">
            <div class="col-12 message-parent-module-info">
              @if (planMessage.parentModuleId | module | async; as parentModule) {
                <transloco
                  key="SIS_COMPONENTS.MESSENGER.SUGGEST_ADD.UNDER_MODULE"
                  [params]="{ code: parentModule.code, name: (parentModule.name | localizedString) }"
                ></transloco>
              }
            </div>
          </div>
        }
      }
      <div class="row messenger-message">
        <div class="col-12 message-comment">{{ planMessage.comment }}</div>
      </div>
    }

    @if (isPlanGenericComment(planMessage)) {
      <div class="row messenger-message">
        <div class="col-12 message-comment">{{ planMessage.comment }}</div>
      </div>
    }
  </div>
</ng-container>
