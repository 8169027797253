import angular from 'angular';
import * as _ from 'lodash-es';
import { Range, RangeValidation } from 'common-typescript';
import 'sis-components/sharedModel/PlanState';
import 'sis-components/sharedModel/Range';
import 'sis-components/sharedModel/RangeValidationResult';
import 'sis-components/service/studentApplication.service';
(function () {
  showViewModuleContentApplicationLinkService.$inject = ["PlanState", "RangeValidationResult", "commonStudentApplicationService"];
  angular.module('student.common.components.showViewModuleContentApplicationLink').factory('showViewModuleContentApplicationLinkService', showViewModuleContentApplicationLinkService);
  function showViewModuleContentApplicationLinkService(PlanState, RangeValidationResult, commonStudentApplicationService) {
    return {
      isModuleContentApplicationRequired,
      getStatusCode,
      shouldShowModuleContentApplicationLink
    };
    function getStatusCode(validatablePlan, module, moduleValidationResult, isFreeEdit) {
      let caseCode = isFreeEdit ? 'FREE_EDIT.' : 'SELECTION_ASSISTANT.';
      caseCode += isModuleContentApplicationRequired(module) ? 'APPROVAL_REQUIRED.' : 'APPROVAL_NOT_REQUIRED.';
      if (isModuleEmpty(validatablePlan, module)) {
        return `${caseCode}EMPTY`;
      }
      if (isPlanStateValid(moduleValidationResult)) {
        return `${caseCode}VALID_CONTENTS`;
      }
      if (hasInvalidOrMissingSelections(moduleValidationResult) ||
      // NOSONAR
      !isFreeEdit || isFreeEdit && (!isPlannedCreditsMatchingTargetCredits(module, moduleValidationResult) || _.isNil(module.targetCredits) || _.get(moduleValidationResult, 'state'))) {
        return `${caseCode}INVALID_CONTENTS`;
      }
      return `${caseCode}VALID_CONTENTS`;
    }
    function isPlanStateValid(moduleValidationResult) {
      const validPlanStates = [PlanState.PLANNED, PlanState.APPROVED, PlanState.APPROVED_CONDITIONALLY, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.PARTS_ATTAINED, PlanState.IMPLICIT];
      return _.includes(validPlanStates, _.get(moduleValidationResult, 'state'));
    }
    function isPlannedCreditsMatchingTargetCredits(module, moduleValidationResult) {
      if (_.isNil(module.targetCredits)) {
        return true; // eg. GroupingModule
      }
      const rangeValidationResult = RangeValidation.validateRange(new Range(module.targetCredits), new Range(_.get(moduleValidationResult, 'plannedCredits')));
      return rangeValidationResult.result === RangeValidationResult.IMPLICIT || rangeValidationResult.result === RangeValidationResult.OK;
    }
    function isModuleContentApplicationRequired(module) {
      if (module) {
        return module.moduleContentApprovalRequired;
      }
      return false;
    }
    function isModuleEmpty(validatablePlan, module) {
      return !_.some(_.concat(validatablePlan.getSelectedModulesUnderModule(module), validatablePlan.getSelectedCourseUnitsUnderModule(module), validatablePlan.getSelectedCustomModuleAttainmentsUnderModule(module), validatablePlan.getSelectedCustomCourseUnitAttainmentsUnderModule(module), validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id)), selection => !!selection);
    }
    function hasInvalidOrMissingSelections(moduleValidationResult) {
      return !!_.get(moduleValidationResult, 'hasInvalidOrMissingSelections');
    }
    function shouldShowModuleContentApplicationLink(validatablePlan, module) {
      const studentViewableModuleContentApplication = getStudentViewableModuleContentApplication(validatablePlan, module);
      return !!studentViewableModuleContentApplication;
    }
    function getStudentViewableModuleContentApplication(validatablePlan, module) {
      let studentViewableModuleContentApplication;
      let moduleContentApplication;
      if (validatablePlan && module) {
        moduleContentApplication = validatablePlan.getEffectiveModuleContentApproval(module.id);
      }
      if (!moduleContentApplication && validatablePlan && module) {
        const parentModuleSelection = _.get(validatablePlan.moduleIdSelectionMap, module.id);
        const parentModuleId = _.get(parentModuleSelection, 'parentModuleId');
        let mcas = _.filter(validatablePlan.moduleContentApplications, mca => mca.educationId === _.get(validatablePlan.rootModule, 'id') && mca.approvedModuleId === module.id && mca.parentModuleId === parentModuleId);

        // rejected or revoked
        // order by last modified date to get the latest first.
        mcas = _.orderBy(mcas, mca => mca.metadata.lastModifiedOn, 'desc');
        moduleContentApplication = _.first(mcas);
      }
      if (moduleContentApplication && commonStudentApplicationService.isStudentViewableModuleContentApplication(moduleContentApplication.state) && commonStudentApplicationService.isMatchingModuleContentApplication(moduleContentApplication, validatablePlan, module.id)) {
        studentViewableModuleContentApplication = moduleContentApplication;
      }
      return studentViewableModuleContentApplication;
    }
  }
})();