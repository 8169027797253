import { Injectable } from '@angular/core';
import { JL } from 'jsnlog';
import * as _ from 'lodash-es';

export const globalErrorHandlerLoggerName: string = 'GlobalException';

@Injectable({
    providedIn: 'root',
})
export class LogService {
    timeBasis: number = 0;
    readonly levels = {
        CRITICAL: 0,
        ERROR: 1,
    };

    init(defaultAjaxUrl: string) {
        JL.setOptions({
            defaultAjaxUrl,
            maxMessages: 5,
        });
        JL().setOptions({
            onceOnly: [
                '"AbortError"',
                'Aborting the connection',
            ],
        });
        JL(globalErrorHandlerLoggerName).setOptions({
            disallow: 'status 419',
        });
    }

    critical(id: string, logObject: any, error: any) {
        this.doLog(id, logObject, this.levels.CRITICAL);
        JL(id).fatalException(error ? logObject : this.stringify(logObject), error);
    }

    error(id: string, logObject: any) {
        this.doLog(id, logObject, this.levels.ERROR);
        const logObj = this.stringify(logObject);
        JL(id).error(logObj);
    }

    private doLog = (id: string, logObject: any, level: number) => {
        if (!this.timeBasis) this.timeBasis = new Date().getTime();

        const levelNames = (_.invert(this.levels));
        const stamp = new Date().getTime();
        const prefix = `${stamp} ${levelNames[level]} (${id})`;

        // eslint-disable-next-line no-console
        console.log.apply(console, [prefix, logObject]);
    };

    private stringify = (object: any) => {
        // Stringify objects with circular references
        let cache: any[] = [];
        const result = JSON.stringify(object, (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Circular reference found, discard key
                    return undefined;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // Enable garbage collection
        return result;
    };
}
