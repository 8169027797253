import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output, ViewEncapsulation } from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { StudyEvent, StudySubGroup } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { STUDY_EVENT_SUMMARY_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { SisComponentsModule } from 'sis-components/sis-components.module';

/**
 * Do note that this component still depends on (global) styles from 'course-unit-realisation.scss'.
 * Be careful when altering the html structure as it might break the styling.
 */
@Component({
    selector: 'app-calendar-cur-study-sub-group-study-event-summary',
    templateUrl: './calendar-cur-study-sub-group-study-event-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        SisComponentsModule,
        SisCommonModule,
        NgxFudisModule,
    ],
})
export class CalendarCurStudySubGroupStudyEventSummaryComponent {
    studyEventSummaryService = inject(STUDY_EVENT_SUMMARY_SERVICE);

    studyEvent = input<StudyEvent>();
    studySubGroup = input<StudySubGroup>();
    onViewInCalendar = output<string>();

    weekDayOrInterval = computed(() => this.studyEventSummaryService.getWeekDayOrInterval(this.studyEvent()));
    timeRange = computed(() => this.studyEventSummaryService.getTimeRange(this.studyEvent()));
    dateRange = computed(() => this.studyEventSummaryService.getDateRange(this.studyEvent()));
    canShowCalendarButton = computed(() => this.studyEvent()?.startTime);

    relevantOverrides = computed(() => {
        const studyEvent = this.studyEvent();
        return _.filter(studyEvent.overrides, override => !_.isEmpty(override.irregularLocationIds));
    });

    allEventsExcludedOrCancelled = computed(() => {
        const studyEvent = this.studyEvent();
        return _.every(studyEvent.events, event => event.cancelled || event.excluded);
    });

    viewInCalendar(): void {
        const date = moment(this.studyEvent().startTime).format('YYYY-MM-DD');
        this.onViewInCalendar.emit(date);
    }
}
