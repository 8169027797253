import * as _ from 'lodash-es';

import {
    AttainmentType,
    ModuleType,
} from '../../../types/baseTypes.js';
import { OtmId, StudyPeriodLocator } from '../../../types/commonTypes.js';
import {
    AssessmentItem,
    AssessmentItemAttainment,
    AssessmentItemSelection,
    Attainment,
    AttainmentGroupNode,
    AttainmentNode,
    AttainmentReferenceNode,
    CompletionMethod,
    CourseUnit,
    CourseUnitAttainment,
    CourseUnitSelection,
    CourseUnitSubstitution,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    CustomStudyDraft,
    DegreeProgrammeAttainment,
    Education,
    EmbeddedModule,
    EntityWithRule,
    Module,
    ModuleAttainment,
    ModuleContentApplication,
    Plan,
    StudyRight,
} from '../../../types/generated/common-backend.js';
import {
    CourseUnitSelectionWithAttainment,
    CustomCourseUnitAttainmentSelectionWithAttainment,
    CustomModuleAttainmentSelectionWithAttainment,
    ModuleSelectionWithAttainment,
    SelectionWithAttainment,
} from '../../customTypes.js';
import { CleanPlanService } from '../../service/cleanPlan.service.js';
import {
    getAllLeafNodes,
    getAllRules,
    getCourseUnitPrecedence,
    getModulePrecedence,
    isApplicationEffective,
    isCustomAttainment,
    isModuleAttainment,
    notNil,
    substitutionMatches,
} from '../planUtils.js';

interface CourseUnitWithPrecedenceIndex extends CourseUnit {
    index?: number;
}

export class ValidatablePlan {

    readonly modulesById: { [id: string]: EntityWithRule } = {};
    readonly courseUnitsById: { [id: string]: CourseUnitWithPrecedenceIndex } = {};
    readonly assessmentItemsById: { [id: string]: AssessmentItem } = {};

    readonly attainmentsById: { [id: string]: Attainment } = {};
    readonly moduleAttainmentsById: { [id: string]: ModuleAttainment | DegreeProgrammeAttainment } = {};
    readonly courseUnitAttainmentsById: { [id: string]: CourseUnitAttainment } = {};
    readonly assessmentItemAttainmentsById: { [id: string]: AssessmentItemAttainment } = {};
    readonly customModuleAttainmentsById: { [id: string]: CustomModuleAttainment } = {};
    readonly customCourseUnitAttainmentsById: { [id: string]: CustomCourseUnitAttainment } = {};
    readonly embeddedModuleAttainmentsByGroupId: { [groupId: string]: EmbeddedModule & { parentModuleId: OtmId } } = {};

    /**
     * Contains a mapping between substituting and substituted course units, along with the amount of credits each individual
     * course unit substitution is worth.
     */
    private courseUnitSubstitutedForById: { [substitutingCourseUnitId: string]: { [substitutedCourseUnitId: string]: number | null } } = {};

    /**
     * Contains a merge of course unit selections and attainments, where each value in the object is one of:
     * - a course unit selection (if the course unit is in the plan, but has not been attained)
     * - a course unit id and an attainment (if a course unit is not in the plan, but has been attained)
     * - an enriched course unit selection object that contains also the attainment as a property (if a course
     *   unit has been attained and is also selected into the plan)
     */
    readonly courseUnitIdSelectionMap: { [courseUnitId: string]: CourseUnitSelectionWithAttainment } = {};

    /**
     * Contains a merge of module selections and attainments, similarly to courseUnitIdSelectionMap.
     */
    readonly moduleIdSelectionMap: { [moduleId: string]: ModuleSelectionWithAttainment } = {};

    /**
     * Contains a merge of custom course unit attainment selections and attainments, where the attainments
     * are added as a property to the corresponding custom course unit selection object.
     */
    readonly customCourseUnitAttainmentIdSelectionMap: { [attainmentId: string]: CustomCourseUnitAttainmentSelectionWithAttainment } = {};

    /**
     * Contains a merge of custom module attainment selections and attainments, similarly to customCourseUnitAttainmentIdSelectionMap.
     */
    readonly customModuleAttainmentIdSelectionMap: { [attainmentId: string]: CustomModuleAttainmentSelectionWithAttainment } = {};

    private assessmentItemIdSelectionMap: { [assessmentItemId: string]: AssessmentItemSelection } = {};

    constructor(readonly plan: Plan,
                attainments: Attainment[] = [],
                readonly rootModule: Education,
                modules: EntityWithRule[] = [],
                courseUnits: CourseUnit[] = [],
                assessmentItems: AssessmentItem[] = [],
                readonly moduleContentApplications: ModuleContentApplication[] = [],
                readonly studyRight?: StudyRight) {

        if (_.isNil(plan)) {
            throw Error('Plan is missing, can\'t build a ValidatablePlan without it');
        }
        if (_.isNil(rootModule)) {
            throw Error('Root module is missing, can\'t build a ValidatablePlan without it');
        }

        CleanPlanService.cleanPlan(plan, attainments, modules, courseUnits);
        this.cleanCustomAttainmentValidationResults(attainments);

        this.modulesById = _.keyBy(modules, 'id');
        this.courseUnitsById = _.keyBy(courseUnits, 'id');
        this.assessmentItemsById = _.keyBy(assessmentItems, 'id');
        this.attainmentsById = _.keyBy(attainments, 'id');

        const { courseUnitSelections = [] } = plan;

        this.categorizeAttainments(attainments);
        this.initSelectionMaps(plan);
        this.initPrecedenceIndexForCourseUnits(this.courseUnitIdSelectionMap, courseUnitSelections, this.courseUnitsById);

        Object.values(this.moduleAttainmentsById)
            .forEach(attainment => this.initParentModuleReferencesForModuleAttainmentContents(attainment));
        Object.values(this.customModuleAttainmentsById)
            .forEach((attainment) => {
                if (!_.isEmpty(attainment.nodes)) {
                    attainment.nodes.forEach(node => this.populateParentIdReferenceForAttainmentNode(attainment.id, node, true));
                }
            });

        courseUnitSelections.forEach(selection => this.processSubstitutions(selection));
    }

    getAssessmentItemAttainment(assessmentItemId: OtmId): AssessmentItemAttainment | null {
        return this.assessmentItemAttainmentsById[assessmentItemId] || null;
    }

    getAllAssessmentItems(): AssessmentItem[] {
        return Object.values(this.assessmentItemsById);
    }

    getAllAttainments(): { [id: string]: Attainment } {
        return { ...this.attainmentsById };
    }

    getCourseUnit(courseUnitId: OtmId): CourseUnit | null {
        return this.courseUnitsById[courseUnitId] || null;
    }

    getCourseUnitInPlanByGroupId(groupId: OtmId): CourseUnit | null {
        if (_.isNil(groupId)) {
            return null;
        }

        return Object.values(this.courseUnitsById)
            .filter(courseUnit => courseUnit.groupId === groupId)
            .find(courseUnit => this.isCourseUnitInPlan(courseUnit) || this.isCourseUnitInPlanAsSubstitute(courseUnit)) || null;
    }

    getCourseUnitOrSubstitutingCourseUnitsForCourseUnitId(courseUnitId: OtmId): CourseUnit[] {
        const courseUnit = this.getCourseUnit(courseUnitId);
        if (_.isNil(courseUnit)) {
            return [];
        }
        if (this.isSubstituted(courseUnit)) {
            return _.uniq(this.getSubstitutedBy(courseUnit))
                .map(substitutingCourseUnitId => this.getCourseUnit(substitutingCourseUnitId))
                .filter(notNil);
        }

        return [courseUnit];
    }

    getCourseUnitsOrSubstitutingCourseUnitsForModule(module: EntityWithRule): CourseUnit[] {
        const courseUnits = this.getSelectedCourseUnitsUnderModule(module)
            .map(courseUnit => this.getCourseUnitOrSubstitutingCourseUnitsForCourseUnitId(courseUnit.id))
            .flat();

        return _.uniq(courseUnits);
    }

    getCourseUnitSelection(courseUnitOrCourseUnitId: CourseUnit | OtmId): CourseUnitSelectionWithAttainment | null {
        let courseUnitId: OtmId;
        if (_.isString(courseUnitOrCourseUnitId)) {
            courseUnitId = courseUnitOrCourseUnitId as string;
        } else if (_.has(courseUnitOrCourseUnitId, 'id')) {
            courseUnitId = (courseUnitOrCourseUnitId as CourseUnit).id;
        } else {
            return null;
        }

        return this.courseUnitIdSelectionMap[courseUnitId] || null;
    }

    /**
     * Returns the ids for all course units in the plan. For course units that have been substituted,
     * only the ids of the substituting course units will be returned.
     */
    getIdForAllCourseUnitsInPlan(): OtmId[] {
        return this.getAllCourseUnitsInPlan().map(courseUnit => courseUnit.id);
    }

    /**
     * Returns all course units in the plan. For course units that have been substituted,
     * only the substituting course units will be returned.
     */
    getAllCourseUnitsInPlan(): CourseUnit[] {
        const courseUnits = Object.values(this.courseUnitIdSelectionMap)
            .map(selection => this.getCourseUnit(selection.courseUnitId))
            .filter(notNil)
            .filter(cu => (this.isCourseUnitInPlan(cu) && !this.isSubstituted(cu)) || this.isCourseUnitInPlanAsSubstitute(cu));

        return _.uniq(courseUnits);
    }

    getSelectedCourseUnitsUnderModule(parentModule: EntityWithRule): CourseUnit[] {
        if (_.isNil(parentModule)) {
            return [];
        }

        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return _.chain(getAllLeafNodes(moduleAttainment))
                .map(node => this.attainmentsById[node.attainmentId])
                .compact()
                .filter({ type: AttainmentType.COURSE_UNIT_ATTAINMENT })
                .map(attainment => this.courseUnitsById[(attainment as CourseUnitAttainment).courseUnitId])
                .compact()
                .value();
        }

        return _.chain(Object.values(this.courseUnitIdSelectionMap))
            .filter({ parentModuleId: parentModule.id })
            .map(courseUnitSelection => this.courseUnitsById[courseUnitSelection.courseUnitId])
            .compact()
            .orderBy([_.partial(getCourseUnitPrecedence, getAllRules(parentModule.rule)), 'id'])
            .value();
    }

    getSelectedCourseUnitsUnderCustomModuleAttainment(parentCustomModuleAttainment: CustomModuleAttainment): CourseUnit[] {
        if (_.isNil(parentCustomModuleAttainment)) {
            return [];
        }

        return _.chain(getAllLeafNodes(parentCustomModuleAttainment))
            .map(node => this.attainmentsById[node.attainmentId])
            .compact()
            .filter({ type: AttainmentType.COURSE_UNIT_ATTAINMENT })
            .map(attainment => this.courseUnitsById[(attainment as CourseUnitAttainment).courseUnitId])
            .compact()
            .value();
    }

    /**
     * @deprecated This method has a misleading name; use {@link getSelectedCourseUnitsUnderModule} instead.
     */
    getSelectedCourseUnitsById(parentModule: EntityWithRule): CourseUnit[] {
        return this.getSelectedCourseUnitsUnderModule(parentModule);
    }

    getAllCourseUnitAndCustomCourseUnitAttainmentsInPlan(): (CourseUnitAttainment | CustomCourseUnitAttainment | undefined | null)[] {
        return this.getAllCourseUnitAndCustomCourseUnitAttainmentsUnderModule();
    }

    /**
     * Returns all course unit attainments and custom course unit attainments that are in the plan under
     * the defined module. Does a recursive search, i.e. returns all descendants instead of just direct
     * children. If moduleId is omitted, returns all attainments (of said types) in the plan.
     *
     * @param [moduleId] The id of the module whose descendants to return.
     */
    getAllCourseUnitAndCustomCourseUnitAttainmentsUnderModule(moduleId?: OtmId): (CourseUnitAttainment | CustomCourseUnitAttainment | undefined)[] {
        return [
            ...Object.values(this.courseUnitAttainmentsById)
                .filter((attainment: CourseUnitAttainment) => {
                    const courseUnit = this.getCourseUnit(attainment.courseUnitId);
                    return !_.isNil(courseUnit) && (this.isCourseUnitInPlanUnderModule(courseUnit, moduleId) ||
                        this.isCourseUnitInPlanAsSubstituteUnderModule(courseUnit, moduleId));
                }),

            ...Object.values(this.customCourseUnitAttainmentIdSelectionMap)
                .map(selection => _.get(selection, 'attainment'))
                .filter(value => !!value)
                .filter(attainment => this.isCustomCourseUnitAttainmentInPlanUnderModule(attainment, moduleId)),
        ];
    }

    getCourseUnitAttainment(courseUnitId: OtmId): CourseUnitAttainment | null {
        return this.courseUnitAttainmentsById[courseUnitId] || null;
    }

    getCourseUnitAttainmentByGroupId(courseUnitGroupId: OtmId): CourseUnitAttainment | null {
        return Object.values(this.courseUnitAttainmentsById)
            .find(attainment => attainment.courseUnitGroupId === courseUnitGroupId) || null;
    }

    getCustomCourseUnitAttainment(customCourseUnitAttainmentId: OtmId): CustomCourseUnitAttainment | null {
        return this.customCourseUnitAttainmentsById[customCourseUnitAttainmentId] || null;
    }

    getAllCustomCourseUnitAttainmentsInPlan() {
        return this.getAllCourseUnitAndCustomCourseUnitAttainmentsInPlan()
            .filter(({ type }) => type === AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT);
    }

    getPlannedPeriods(courseUnitOrCourseUnitId: CourseUnit | OtmId): StudyPeriodLocator[] | null {
        let courseUnitId: OtmId;
        if (_.isString(courseUnitOrCourseUnitId)) {
            courseUnitId = courseUnitOrCourseUnitId as string;
        } else if (_.has(courseUnitOrCourseUnitId, 'id')) {
            courseUnitId = (courseUnitOrCourseUnitId as CourseUnit).id;
        } else {
            return null;
        }

        const courseUnitSelection = _.find(this.plan.courseUnitSelections, { courseUnitId });
        return _.get(courseUnitSelection, 'plannedPeriods') || null;
    }

    getSelectedSubstitution(courseUnit: CourseUnit): { courseUnit: CourseUnit, credits: number }[] {
        const substitutedBy = this.getSubstitutedBy(courseUnit);
        const substitution = this.findFirstMatchingSubstitution(courseUnit.substitutions, substitutedBy);
        return _.chain(substitution)
            .map(courseUnitSubstitution => ({
                courseUnit: this.courseUnitsById[courseUnitSubstitution.courseUnitId],
                credits: courseUnitSubstitution.credits || 0,
            }))
            .filter(courseUnitSubstitution => !_.isNil(courseUnitSubstitution.courseUnit))
            .value();
    }

    findFirstMatchingSubstitution(substitutions: CourseUnitSubstitution[][], substitutedBy: OtmId[]):
    (CourseUnitSubstitution & { courseUnitId: string })[] | null {

        if (_.isEmpty(substitutions) || _.isEmpty(substitutedBy)) {
            return null;
        }

        const substitutesByGroupId: { [groupId: string]: { courseUnitId: string, courseUnitGroupId: string } } =
            substitutedBy.reduce(
                (acc, courseUnitId) => {
                    const courseUnitGroupId = _.has(this.courseUnitsById, courseUnitId) ? this.courseUnitsById[courseUnitId].groupId : null;
                    return _.isNil(courseUnitGroupId) ? acc : { ...acc, [courseUnitGroupId]: { courseUnitId, courseUnitGroupId } };
                },
                {},
            );

        const matchingSubstitution = substitutions
            .find(substitution => substitutionMatches(substitution, Object.keys(substitutesByGroupId)));
        return _.isNil(matchingSubstitution) ? null :
            matchingSubstitution.map(cus => ({ ...cus, courseUnitId: substitutesByGroupId[cus.courseUnitGroupId].courseUnitId }));
    }

    getSubstitutedBy(courseUnit: CourseUnit): OtmId[] {
        return _.get(this.getCourseUnitSelection(courseUnit), 'substitutedBy', []);
    }

    getSubstituteForCredits(courseUnit: CourseUnit): number[] {
        if (_.isNil(courseUnit)) {
            return [];
        }

        return (Object.values(this.courseUnitSubstitutedForById[courseUnit.id] || {})).filter(notNil) as number[];
    }

    getSubstitutionCredits(substitutedCourseUnit: CourseUnit, substituteCourseUnit: CourseUnit): number | null {
        if (_.isNil(substitutedCourseUnit) || _.isNil(substituteCourseUnit)) {
            return null;
        }
        return _.get(_.get(this.courseUnitSubstitutedForById, substituteCourseUnit.id), substitutedCourseUnit.id);
    }

    getSubstituteForIds(courseUnit: CourseUnit): OtmId[] {
        return _.isNil(courseUnit) ? [] : Object.keys(this.courseUnitSubstitutedForById[courseUnit.id] || {});
    }

    getModule(moduleId: OtmId): EntityWithRule | null {
        return this.modulesById[moduleId] || null;
    }

    getModuleInPlanByGroupId(groupId: OtmId): EntityWithRule | null {
        if (_.isNil(groupId)) {
            return null;
        }

        return Object.values(this.modulesById)
            .filter(module => module.groupId === groupId)
            .find(module => this.isModuleInPlan(module.id)) || null;
    }

    getModuleSelection(moduleId: OtmId): ModuleSelectionWithAttainment | null {
        return this.moduleIdSelectionMap[moduleId] || null;
    }

    getModuleAttainment(moduleId: OtmId): ModuleAttainment | DegreeProgrammeAttainment | null {
        return this.moduleAttainmentsById[moduleId] || null;
    }

    getModuleAttainmentByGroupId(moduleGroupId: OtmId): ModuleAttainment | DegreeProgrammeAttainment | null {
        return Object.values(this.moduleAttainmentsById)
            .find(attainment => attainment.moduleGroupId === moduleGroupId) || null;
    }

    getCustomModuleAttainment(customModuleAttainmentId: OtmId): CustomModuleAttainment | null {
        return this.customModuleAttainmentsById[customModuleAttainmentId] || null;
    }

    getParentModuleOrCustomModuleAttainmentForCourseUnit(courseUnit: CourseUnit): EntityWithRule | CustomModuleAttainment | null {
        if (_.isNil(courseUnit)) {
            return null;
        }

        const courseUnitSelection = this.courseUnitIdSelectionMap[courseUnit.id];
        const parentModuleAttainmentId = _.get(courseUnitSelection, 'parentModuleAttainmentId');
        if (!_.isNil(parentModuleAttainmentId) && this.isModuleAttainmentInPlan(parentModuleAttainmentId)) {
            const parentModuleAttainment = this.attainmentsById[parentModuleAttainmentId];
            if (parentModuleAttainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
                return parentModuleAttainment as CustomModuleAttainment;
            }

            return this.modulesById[(parentModuleAttainment as ModuleAttainment).moduleId] || null;
        }

        const parentModuleId = _.get(courseUnitSelection, 'parentModuleId');
        if (!_.isNil(parentModuleId) && this.isModuleInPlan(parentModuleId)) {
            if (parentModuleId === this.rootModule.id) {
                return this.rootModule;
            }
            return this.modulesById[parentModuleId] || null;
        }

        return null;
    }

    getParentModuleOrCustomModuleAttainmentForModule(module: EntityWithRule): EntityWithRule | CustomModuleAttainment | null {
        if (_.isNil(module)) {
            return null;
        }

        const moduleSelection = this.moduleIdSelectionMap[module.id];
        const parentModuleAttainmentId = _.get(moduleSelection, 'parentModuleAttainmentId');
        if (!_.isNil(parentModuleAttainmentId) && this.isModuleAttainmentInPlan(parentModuleAttainmentId)) {
            const parentModuleAttainment = _.get(this.attainmentsById, parentModuleAttainmentId);
            if (parentModuleAttainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
                return parentModuleAttainment as CustomModuleAttainment;
            }

            return this.modulesById[(parentModuleAttainment as ModuleAttainment).moduleId] || null;
        }
        const parentModuleId = _.get(moduleSelection, 'parentModuleId');
        if (!_.isNil(parentModuleId) && this.isModuleInPlan(parentModuleId)) {
            if (parentModuleId === this.rootModule.id) {
                return this.rootModule;
            }
            return this.modulesById[parentModuleId];
        }

        return null;
    }

    getParentModuleOrCustomModuleAttainmentForCustomAttainment(attainment: CustomCourseUnitAttainment | CustomModuleAttainment): EntityWithRule | CustomModuleAttainment | null {
        if (_.isNil(attainment)) {
            return null;
        }

        let selection;
        if (attainment.type === AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT) {
            selection = this.customCourseUnitAttainmentIdSelectionMap[attainment.id] || null;
        } else if (attainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
            selection = this.customModuleAttainmentIdSelectionMap[attainment.id] || null;
        } else {
            return null;
        }

        const parentModuleAttainmentId = _.get(selection, 'parentModuleAttainmentId');
        if (!_.isNil(parentModuleAttainmentId) && this.isModuleAttainmentInPlan(parentModuleAttainmentId)) {
            const parentModuleAttainment = this.attainmentsById[parentModuleAttainmentId];
            if (parentModuleAttainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
                return parentModuleAttainment as CustomModuleAttainment;
            }
        }

        const parentModuleId = _.get(selection, 'parentModuleId');
        if (!_.isNil(parentModuleId) && this.isModuleInPlan(parentModuleId)) {
            if (parentModuleId === this.rootModule.id) {
                return this.rootModule;
            }
            return this.modulesById[parentModuleId];
        }

        return null;
    }

    getSelectedAssessmentItems(courseUnit: CourseUnit): AssessmentItem[] {
        if (_.isNil(courseUnit)) {
            return [];
        }

        return Object.values(this.assessmentItemIdSelectionMap)
            .filter(selection => selection.courseUnitId === courseUnit.id)
            .map(selection => this.assessmentItemsById[selection.assessmentItemId])
            .filter(notNil);
    }

    getSelectedCompletionMethod(courseUnitOrCourseUnitId: CourseUnit | OtmId): CompletionMethod | null {
        let courseUnit: CourseUnit | null;
        if (_.isString(courseUnitOrCourseUnitId)) {
            courseUnit = this.getCourseUnit(courseUnitOrCourseUnitId as OtmId);
        } else {
            courseUnit = courseUnitOrCourseUnitId as CourseUnit;
        }

        const selection = this.getCourseUnitSelection(courseUnitOrCourseUnitId) as CourseUnitSelection;
        if (_.isNil(courseUnit) || _.isEmpty(courseUnit.completionMethods) || _.isNil(selection)) {
            return null;
        }

        return courseUnit.completionMethods.find(({ localId }) => localId === selection.completionMethodId) || null;
    }

    getSelectedCustomCourseUnitAttainmentIdsUnderModule(parentModule: EntityWithRule): OtmId[] {
        if (_.isNil(parentModule)) {
            return [];
        }

        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return getAllLeafNodes(moduleAttainment)
                .map(node => this.getCustomCourseUnitAttainment(node.attainmentId))
                .filter(notNil)
                .map(customCourseUnitAttainment => customCourseUnitAttainment.id);
        }

        return (this.plan.customCourseUnitAttainmentSelections || [])
            .filter(selection => selection.parentModuleId === parentModule.id)
            .map(selection => selection.customCourseUnitAttainmentId);
    }

    getSelectedCustomModuleAttainmentIdsUnderModule(parentModule: EntityWithRule): OtmId[] {
        if (_.isNil(parentModule)) {
            return [];
        }

        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return getAllLeafNodes(moduleAttainment)
                .map(node => this.getCustomModuleAttainment(node.attainmentId))
                .filter(notNil)
                .map(customModuleAttainment => customModuleAttainment.id);
        }

        return (this.plan.customModuleAttainmentSelections || [])
            .filter(selection => selection.parentModuleId === parentModule.id)
            .map(selection => selection.customModuleAttainmentId);
    }

    getSelectedCustomCourseUnitAttainmentsUnderModule(parentModule: EntityWithRule): CustomCourseUnitAttainment[] {
        if (_.isNil(parentModule)) {
            return [];
        }

        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return getAllLeafNodes(moduleAttainment)
                .map(node => this.getCustomCourseUnitAttainment(node.attainmentId))
                .filter(notNil);
        }

        return (this.plan.customCourseUnitAttainmentSelections || [])
            .filter(selection => selection.parentModuleId === parentModule.id)
            .map(selection => this.getCustomCourseUnitAttainment(selection.customCourseUnitAttainmentId))
            .filter(notNil);
    }

    getSelectedCustomCourseUnitAttainmentsUnderCustomModuleAttainment(parentCustomModuleAttainment: CustomModuleAttainment): CustomCourseUnitAttainment[] {
        if (_.isNil(parentCustomModuleAttainment)) {
            return [];
        }

        return getAllLeafNodes(parentCustomModuleAttainment)
            .map(node => this.getCustomCourseUnitAttainment(node.attainmentId))
            .filter(notNil);
    }

    /**
     * @deprecated This method has a misleading name; use {@link getSelectedCustomCourseUnitAttainmentsUnderModule} instead.
     */
    getSelectedCustomCourseUnitAttainmentsById(parentModule: EntityWithRule): CustomCourseUnitAttainment[] {
        return this.getSelectedCustomCourseUnitAttainmentsUnderModule(parentModule);
    }

    getSelectedCustomModuleAttainmentsUnderModule(parentModule: EntityWithRule): CustomModuleAttainment[] {
        if (_.isNil(parentModule)) {
            return [];
        }

        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return getAllLeafNodes(moduleAttainment)
                .map(node => this.getCustomModuleAttainment(node.attainmentId))
                .filter(notNil);
        }

        return (this.plan.customModuleAttainmentSelections || [])
            .filter(selection => selection.parentModuleId === parentModule.id)
            .map(selection => this.getCustomModuleAttainment(selection.customModuleAttainmentId))
            .filter(notNil);
    }

    getSelectedCustomModuleAttainmentsUnderCustomModuleAttainment(parentCustomModuleAttainment: CustomModuleAttainment): CustomModuleAttainment[] {
        if (_.isNil(parentCustomModuleAttainment)) {
            return [];
        }

        return getAllLeafNodes(parentCustomModuleAttainment)
            .map(node => this.getCustomModuleAttainment(node.attainmentId))
            .filter(notNil);
    }

    /**
     * @deprecated This method has a misleading name; use {@link getSelectedCustomModuleAttainmentsUnderModule} instead.
     */
    getSelectedCustomModuleAttainmentsById(parentModule: EntityWithRule): CustomModuleAttainment[] {
        return this.getSelectedCustomModuleAttainmentsUnderModule(parentModule);
    }

    getSelectedModulesUnderModule(parentModule: EntityWithRule): Module[] {
        const moduleAttainment = this.getModuleAttainment(parentModule.id);
        if (!_.isNil(moduleAttainment)) {
            return _.chain(getAllLeafNodes(moduleAttainment))
                .map(node => this.attainmentsById[node.attainmentId])
                .filter(isModuleAttainment)
                // Poor man's cyclic reference detector
                .reject({ moduleId: parentModule.id })
                .map(attainment => this.modulesById[(attainment as ModuleAttainment).moduleId] as Module)
                .compact()
                .value();
        }

        return _.chain(Object.values(this.moduleIdSelectionMap))
            .filter({ parentModuleId: parentModule.id })
            // Poor man's cyclic reference detector
            .reject({ moduleId: parentModule.id })
            .map(moduleSelection => this.modulesById[moduleSelection.moduleId] as Module)
            .compact()
            .orderBy([_.partial(getModulePrecedence, getAllRules(parentModule.rule)), 'id'])
            .value();
    }

    getSelectedModulesUnderCustomModuleAttainment(parentCustomModuleAttainment: CustomModuleAttainment): Module[] {
        if (_.isNil(parentCustomModuleAttainment)) {
            return [];
        }

        return _.chain(getAllLeafNodes(parentCustomModuleAttainment))
            .map(node => this.attainmentsById[node.attainmentId])
            .filter(isModuleAttainment)
            .map(attainment => this.modulesById[(attainment as ModuleAttainment).moduleId] as Module)
            .compact()
            .value();

    }

    /**
     * @deprecated This method has a misleading name; use {@link getSelectedModulesUnderModule} instead.
     */
    getSelectedModulesById(parentModule: EntityWithRule): Module[] {
        return this.getSelectedModulesUnderModule(parentModule);
    }

    getSelectionForAttainment(attainment: Attainment): SelectionWithAttainment | null {
        if (!_.isNil(attainment)) {
            if (attainment.type === AttainmentType.COURSE_UNIT_ATTAINMENT) {
                return this.courseUnitIdSelectionMap[(attainment as CourseUnitAttainment).courseUnitId];
            }
            if (isModuleAttainment(attainment)) {
                return this.moduleIdSelectionMap[attainment.moduleId];
            }
            if (attainment.type === AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT) {
                return this.customCourseUnitAttainmentIdSelectionMap[attainment.id];
            }
            if (attainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
                return this.customModuleAttainmentIdSelectionMap[attainment.id];
            }
        }

        return null;
    }

    getSelectedCustomStudyDraftsByParentModuleId(moduleId: OtmId): CustomStudyDraft[] {
        if (_.isNil(moduleId) || _.isEmpty(this.plan.customStudyDrafts)) {
            return [];
        }

        return this.plan.customStudyDrafts.filter(draft => draft.parentModuleId === moduleId);
    }

    getEffectiveModuleContentApproval(moduleId: OtmId): ModuleContentApplication | null {
        if (_.isEmpty(this.moduleContentApplications)) {
            return null;
        }

        const moduleSelection = this.getModuleSelection(moduleId);
        if (_.isNil(moduleSelection)) {
            return null;
        }

        return this.moduleContentApplications
            .find(moduleContentApplication => isApplicationEffective(moduleContentApplication) &&
                moduleContentApplication.educationId === this.rootModule.id &&
                moduleContentApplication.approvedModuleId === moduleId &&
                moduleContentApplication.parentModuleId === moduleSelection.parentModuleId) || null;
    }

    isAnyCompletionMethodSelected(courseUnitId: OtmId): boolean {
        return !_.isNil(this.getSelectedCompletionMethod(courseUnitId));
    }

    isAssessmentItemAttained(assessmentItemId: OtmId): boolean {
        // TODO HOX! ota suorituksen tila huomioon tässä
        return _.has(this.assessmentItemAttainmentsById, assessmentItemId);
    }

    isCourseUnitAttained(courseUnitId: OtmId): boolean {
        // TODO HOX! ota suorituksen tila huomioon tässä
        return _.has(this.courseUnitAttainmentsById, courseUnitId);
    }

    isCourseUnitInPlan(courseUnit: CourseUnit): boolean {
        return this.isCourseUnitInPlanUnderModule(courseUnit, undefined);
    }

    isCourseUnitInPlanUnderModule(courseUnit: CourseUnit, parentModuleId?: OtmId): boolean {
        if (_.isNil(courseUnit) || _.isEmpty(courseUnit.id)) {
            return false;
        }

        const courseUnitSelection = this.courseUnitIdSelectionMap[courseUnit.id];
        if (!_.isNil(courseUnitSelection)) {
            if (!_.isNil(courseUnitSelection.parentModuleAttainmentId)) {
                return _.isNil(parentModuleId) ?
                    this.isModuleAttainmentInPlan(courseUnitSelection.parentModuleAttainmentId) :
                    this.isModuleAttainmentInPlanUnderModule(courseUnitSelection.parentModuleAttainmentId, parentModuleId);
            }
            if (!_.isNil(courseUnitSelection.parentModuleId)) {
                return _.isNil(parentModuleId) ?
                    this.isModuleInPlan(courseUnitSelection.parentModuleId) :
                    this.isModuleInPlanUnderModule(courseUnitSelection.parentModuleId, parentModuleId);
            }
        }

        return false;
    }

    isCourseUnitInPlanAsSubstitute(courseUnit: CourseUnit): boolean {
        return this.isCourseUnitInPlanAsSubstituteUnderModule(courseUnit, undefined);
    }

    isCourseUnitInPlanAsSubstituteUnderModule(courseUnit: CourseUnit, parentModuleId?: OtmId): boolean {
        if (_.isNil(courseUnit) || _.isEmpty(courseUnit.id)) {
            return false;
        }

        const substitutions = this.courseUnitSubstitutedForById[courseUnit.id] || {};
        return Object.keys(substitutions).some((courseUnitId) => {
            const substitutedCourseUnit = this.courseUnitsById[courseUnitId];
            return !_.isNil(substitutedCourseUnit) && this.isCourseUnitInPlanUnderModule(substitutedCourseUnit, parentModuleId);
        });
    }

    isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment: CustomCourseUnitAttainment): boolean {
        return this.isCustomCourseUnitAttainmentInPlanUnderModule(customCourseUnitAttainment, undefined);
    }

    isCustomCourseUnitAttainmentInPlanUnderModule(customCourseUnitAttainment: CustomCourseUnitAttainment | undefined,
                                                  parentModuleId?: OtmId): boolean {
        if (!!customCourseUnitAttainment) {
            const ccuAttainmentSelection = this.customCourseUnitAttainmentIdSelectionMap[customCourseUnitAttainment.id];

            if (_.has(ccuAttainmentSelection, 'parentModuleAttainmentId')) {
                return _.isNil(parentModuleId) ?
                    this.isModuleAttainmentInPlan(ccuAttainmentSelection.parentModuleAttainmentId) :
                    this.isModuleAttainmentInPlanUnderModule(ccuAttainmentSelection.parentModuleAttainmentId, parentModuleId);
            }
            if (_.has(ccuAttainmentSelection, 'parentModuleId')) {
                return _.isNil(parentModuleId) ?
                    this.isModuleInPlan(ccuAttainmentSelection.parentModuleId) :
                    this.isModuleInPlanUnderModule(ccuAttainmentSelection.parentModuleId, parentModuleId);
            }
        }

        return false;
    }

    isCourseUnitTimed(courseUnitId: OtmId): boolean {
        const courseUnitSelection = _.find(this.plan.courseUnitSelections, { courseUnitId });
        return !_.isEmpty(_.get(courseUnitSelection, 'plannedPeriods'));
    }

    isModuleInPlan(moduleId?: OtmId): boolean {
        if (_.isNil(moduleId)) {
            return false;
        }
        if (moduleId === this.plan.rootId) {
            return true;
        }

        const selection = _.get(this.moduleIdSelectionMap, moduleId);
        if (_.has(selection, 'parentModuleAttainmentId')) {
            return this.isModuleAttainmentInPlan(selection.parentModuleAttainmentId);
        }
        if (_.has(selection, 'parentModuleId')) {
            return this.isModuleInPlan(selection.parentModuleId);
        }

        return false;
    }

    isModuleInPlanUnderModule(childModuleId?: OtmId, parentModuleId?: OtmId): boolean {
        if (_.isNil(childModuleId) || _.isNil(parentModuleId)) {
            return false;
        }
        if (childModuleId === parentModuleId) {
            return true;
        }
        if (childModuleId === this.plan.rootId) {
            return false;
        }

        const selection = this.moduleIdSelectionMap[childModuleId];
        if (_.has(selection, 'parentModuleAttainmentId')) {
            return this.isModuleAttainmentInPlanUnderModule(selection.parentModuleAttainmentId, parentModuleId);
        }
        if (_.has(selection, 'parentModuleId')) {
            return this.isModuleInPlanUnderModule(selection.parentModuleId, parentModuleId);
        }

        return false;
    }

    isModuleAttainmentInPlan(moduleAttainmentId?: OtmId): boolean {
        if (_.isNil(moduleAttainmentId)) {
            return false;
        }

        const moduleAttainment = this.moduleAttainmentsById[moduleAttainmentId] || this.customModuleAttainmentsById[moduleAttainmentId];
        if (_.isNil(moduleAttainment)) {
            return false;
        }
        if ((moduleAttainment as ModuleAttainment).moduleId === this.plan.rootId) {
            return true;
        }

        const selection = this.customModuleAttainmentIdSelectionMap[moduleAttainmentId];
        if (!_.isNil(selection)) {
            if (_.has(selection, 'parentModuleAttainmentId')) {
                return this.isModuleAttainmentInPlan(selection.parentModuleAttainmentId);
            }
            if (_.has(selection, 'parentModuleId')) {
                return this.isModuleInPlan(selection.parentModuleId);
            }
        }

        return false;
    }

    isModuleAttainmentInPlanUnderModule(moduleAttainmentId?: OtmId, parentModuleId?: OtmId): boolean {
        if (_.isNil(moduleAttainmentId) || _.isNil(parentModuleId)) {
            return false;
        }

        const moduleAttainment = this.attainmentsById[moduleAttainmentId];
        if (moduleAttainment.id === parentModuleId) {
            return true;
        }
        if (moduleAttainment.id === this.plan.rootId) {
            return false;
        }

        const selection = this.customModuleAttainmentIdSelectionMap[moduleAttainmentId];
        if (selection) {
            if (_.has(selection, 'parentModuleAttainmentId')) {
                return this.isModuleAttainmentInPlanUnderModule(_.get(selection, 'parentModuleAttainmentId'), parentModuleId);
            }
            if (_.has(selection, 'parentModuleId')) {
                return this.isModuleInPlanUnderModule(_.get(selection, 'parentModuleId'), parentModuleId);
            }
        }
        return false;
    }

    isModuleAttained(moduleId: OtmId): boolean {
        return _.has(this.moduleAttainmentsById, moduleId);
    }

    isModulePlannedOrAttainedUnderModule(childModuleId: OtmId, childModuleGroupId: OtmId, parentModuleId: OtmId): boolean {
        const isSelectedUnderModule = this.isModuleInPlanUnderModule(childModuleId, parentModuleId);
        if (isSelectedUnderModule) {
            return true;
        }

        const embeddedModuleAttainment = this.embeddedModuleAttainmentsByGroupId[childModuleGroupId];
        if (_.has(embeddedModuleAttainment, 'parentModuleId')) {
            return this.isModuleInPlanUnderModule(embeddedModuleAttainment.parentModuleId, parentModuleId);
        }

        return false;
    }

    isSelectedChildModuleEmbedded(phase: number) {
        if (phase !== 1 && phase !== 2) {
            return false;
        }
        const childGroupId = phase === 1 ?
            this.studyRight?.acceptedSelectionPath?.educationPhase1ChildGroupId :
            this.studyRight?.acceptedSelectionPath?.educationPhase2ChildGroupId;
        return !!childGroupId ? _.has(this.embeddedModuleAttainmentsByGroupId, childGroupId) : false;
    }

    isSelectedParentOfCourseUnit(parentModule: EntityWithRule, courseUnit: CourseUnit): boolean {
        if (_.isNil(parentModule) || _.isNil(courseUnit)) {
            return false;
        }

        const selection = this.getCourseUnitSelection(courseUnit.id);
        return !_.isNil(selection) && selection.parentModuleId === parentModule.id;
    }

    isSelectedParentOfModule(parentModule: EntityWithRule, module: EntityWithRule): boolean {
        if (_.isNil(parentModule) || _.isNil(module)) {
            return false;
        }

        const selection = this.getModuleSelection(module.id);
        return !_.isNil(selection) && selection.parentModuleId === parentModule.id;
    }

    isSelectedParentOfCustomCourseUnitAttainment(parentModule: EntityWithRule, customCourseUnitAttainment: CustomCourseUnitAttainment): boolean {
        if (_.isNil(parentModule) || _.isNil(customCourseUnitAttainment) || _.isEmpty(this.plan.customCourseUnitAttainmentSelections)) {
            return false;
        }

        return this.plan.customCourseUnitAttainmentSelections
            .some(selection => selection.customCourseUnitAttainmentId === customCourseUnitAttainment.id &&
                selection.parentModuleId === parentModule.id);
    }

    isSelectedParentOfCustomModuleAttainment(parentModule: EntityWithRule, customModuleAttainment: CustomModuleAttainment): boolean {
        if (_.isNil(parentModule) || _.isNil(customModuleAttainment) || _.isEmpty(this.plan.customModuleAttainmentSelections)) {
            return false;
        }

        return this.plan.customModuleAttainmentSelections
            .some(selection => selection.customModuleAttainmentId === customModuleAttainment.id &&
                selection.parentModuleId === parentModule.id);
    }

    isSubstitute(courseUnit: CourseUnit): boolean {
        return _.has(this.courseUnitSubstitutedForById, courseUnit.id);
    }

    isSubstituted(courseUnit: CourseUnit): boolean {
        return !_.isEmpty(this.getSubstitutedBy(courseUnit));
    }

    getCurriculumPeriodIdsRecursively(moduleOrEducation: EntityWithRule): OtmId[] | null {
        if (_.isNil(moduleOrEducation)) {
            return null;
        }
        if (moduleOrEducation.type === ModuleType.EDUCATION) {
            return moduleOrEducation.id === this.plan.rootId ? [this.plan.curriculumPeriodId] : null;
        }
        const module = moduleOrEducation as Module;
        if (!_.isEmpty(module.curriculumPeriodIds)) {
            return module.curriculumPeriodIds;
        }
        const parentModule = this.getParentModuleOrCustomModuleAttainmentForModule(module);
        if (parentModule && parentModule.type !== AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
            return this.getCurriculumPeriodIdsRecursively(parentModule as Module);
        }
        return null;
    }

    private cleanCustomAttainmentValidationResults(attainments: Attainment[]): void {
        if (!_.isEmpty(attainments)) {
            attainments
                .filter(isCustomAttainment)
                .forEach(attainment => _.unset(attainment, 'validationResults'));
        }
    }

    private categorizeAttainments(attainments: Attainment[] = []): void {
        if (!_.isEmpty(attainments)) {
            attainments.forEach((attainment) => {
                if (attainment.type === AttainmentType.MODULE_ATTAINMENT) {
                    this.moduleAttainmentsById[(attainment as ModuleAttainment).moduleId] = attainment as ModuleAttainment;
                } else if (attainment.type === AttainmentType.DEGREE_PROGRAMME_ATTAINMENT) {
                    this.moduleAttainmentsById[(attainment as DegreeProgrammeAttainment).moduleId] =
                        attainment as DegreeProgrammeAttainment;
                } else if (attainment.type === AttainmentType.COURSE_UNIT_ATTAINMENT) {
                    this.courseUnitAttainmentsById[(attainment as CourseUnitAttainment).courseUnitId] =
                        attainment as CourseUnitAttainment;
                } else if (attainment.type === AttainmentType.ASSESSMENT_ITEM_ATTAINMENT) {
                    this.assessmentItemAttainmentsById[(attainment as AssessmentItemAttainment).assessmentItemId] =
                        attainment as AssessmentItemAttainment;
                } else if (attainment.type === AttainmentType.CUSTOM_MODULE_ATTAINMENT) {
                    this.customModuleAttainmentsById[attainment.id] = attainment as CustomModuleAttainment;
                } else if (attainment.type === AttainmentType.CUSTOM_COURSE_UNIT_ATTAINMENT) {
                    this.customCourseUnitAttainmentsById[attainment.id] = attainment as CustomCourseUnitAttainment;
                }
            });
        }
    }

    /**
     * Merges the user's selections in the plan with the corresponding attainments, and creates "selections maps" that contain
     * either a selection in the plan (e.g. for a course unit), an attainment included in the plan, or both.
     */
    private initSelectionMaps(plan: Plan): void {
        // Include all attainments as plan selections, though parent references may vary
        _.forEach(
            _.merge(
                _.mapValues(this.courseUnitAttainmentsById, attainment => ({ attainment, courseUnitId: attainment.courseUnitId })),
                _.keyBy(plan.courseUnitSelections, 'courseUnitId'),
            ),
            (selection, courseUnitId) => this.courseUnitIdSelectionMap[courseUnitId] = selection,
        );

        _.forEach(
            _.merge(
                _.mapValues(this.customCourseUnitAttainmentsById, attainment => ({ attainment, id: attainment.id })),
                _.keyBy(plan.customCourseUnitAttainmentSelections, 'customCourseUnitAttainmentId'),
            ),
            (selection, attainmentId) => this.customCourseUnitAttainmentIdSelectionMap[attainmentId] = selection,
        );

        _.forEach(
            _.merge(
                _.mapValues(this.moduleAttainmentsById, attainment => ({ attainment, moduleId: attainment.moduleId })),
                _.keyBy(plan.moduleSelections, 'moduleId'),
            ),
            (selection, moduleId) => this.moduleIdSelectionMap[moduleId] = selection,
        );

        _.forEach(
            _.merge(
                _.mapValues(this.customModuleAttainmentsById, attainment => ({ attainment, id: attainment.id })),
                _.keyBy(plan.customModuleAttainmentSelections, 'customModuleAttainmentId'),
            ),
            (selection, attainmentId) => this.customModuleAttainmentIdSelectionMap[attainmentId] = selection,
        );

        this.assessmentItemIdSelectionMap = _.keyBy(plan.assessmentItemSelections, 'assessmentItemId');
    }

    /**
     * Calculate precedence index for course units that preserves original selection order.
     * Start the index form 5000 in order to allow rules to have higher order. Otherwise
     * the indices may overlap if the starting point is zero.
     *
     * Rules have their own independent index calculation, see the *Precedence -functions.
     * Also, greatest index assigned is 9999, so starting from 5000 allows for a pretty
     * large rule hierarchy.
     *
     * If a course unit is not ordered by any rule, selection order index takes effect.
     *
     * TODO: Refactor this so that no extra properties are added to the course units
     */
    private initPrecedenceIndexForCourseUnits(courseUnitIdSelectionMap: { [courseUnitId: string]: CourseUnitSelectionWithAttainment } = {},
                                              courseUnitSelections: CourseUnitSelection[] = [],
                                              courseUnitsById: { [id: string]: CourseUnitWithPrecedenceIndex } = {}): void {
        const indexStart = 5000;
        _.forEach(courseUnitIdSelectionMap, (selection) => {
            const index = _.findIndex(courseUnitSelections, { courseUnitId: selection.courseUnitId });
            if (index !== -1 && courseUnitsById[selection.courseUnitId]) {
                courseUnitsById[selection.courseUnitId].index = indexStart + index;
            }
        });
    }

    private initParentModuleReferencesForModuleAttainmentContents(attainment: ModuleAttainment | DegreeProgrammeAttainment): void {
        if (!_.isNil(attainment)) {
            if (!_.isEmpty(attainment.nodes)) {
                attainment.nodes.forEach(node => this.populateParentIdReferenceForAttainmentNode(attainment.moduleId, node, false));
            }

            if (!_.isEmpty(attainment.embeddedModules)) {
                // Add a parent module reference to the embedded modules
                const embeddedModulesByGroupId = attainment.embeddedModules
                    .map(embeddedModule => ({ ...embeddedModule, parentModuleId: attainment.moduleId }))
                    .reduce((accumulator, embeddedModule) => ({ ...accumulator, [embeddedModule.moduleGroupId]: embeddedModule }), {});
                Object.assign(this.embeddedModuleAttainmentsByGroupId, embeddedModulesByGroupId);
            }
        }
    }

    /**
     * Set parent id reference for selections under a (custom) module attainment node hierarchy.
     */
    private populateParentIdReferenceForAttainmentNode(parentId: OtmId, attainmentNode: AttainmentNode,
                                                       hasCustomParentAttainment: boolean): void {
        if (_.isNil(attainmentNode)) {
            return;
        }

        if (attainmentNode.type === 'AttainmentReferenceNode') {
            const attainment = this.attainmentsById[(attainmentNode as AttainmentReferenceNode).attainmentId];
            const selection = this.getSelectionForAttainment(attainment);
            if (_.isNil(selection)) {
                return;
            }

            if (hasCustomParentAttainment) {
                if (!selection.parentModuleId && !selection.parentModuleAttainmentId) {
                    selection.parentModuleAttainmentId = parentId;
                }
            } else if (!selection.parentModuleId) {
                selection.parentModuleId = parentId;
            }
        } else if (attainmentNode.type === 'AttainmentGroupNode') {
            // Represents a grouping module; execute recursively for sub-nodes
            ((attainmentNode as AttainmentGroupNode).nodes || [])
                .forEach(node => this.populateParentIdReferenceForAttainmentNode(parentId, node, hasCustomParentAttainment));
        }
    }

    private processSubstitutions(courseUnitSelection: CourseUnitSelection): void {
        if (_.isNil(courseUnitSelection) || _.isEmpty(courseUnitSelection.substitutedBy)) {
            return;
        }

        const courseUnit = _.get(this.courseUnitsById, courseUnitSelection.courseUnitId);
        const { substitutions = [] } = courseUnit || {};
        const matchingSubstitution = this.findFirstMatchingSubstitution(substitutions, courseUnitSelection.substitutedBy);
        if (matchingSubstitution) {
            matchingSubstitution.forEach((courseUnitSubstitution) => {
                _.set(
                    this.courseUnitSubstitutedForById,
                    `[${courseUnitSubstitution.courseUnitId}][${courseUnitSelection.courseUnitId}]`,
                    courseUnitSubstitution.credits || 0,
                );
            });
        } else {
            courseUnitSelection.substitutedBy.forEach((substitutingCourseUnitId) => {
                _.set(
                    this.courseUnitSubstitutedForById,
                    `[${substitutingCourseUnitId}][${courseUnitSelection.courseUnitId}]`,
                    null,
                );
            });
        }
    }
}
