import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash-es';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export enum Breakpoint {
    XXS = 0,
    XS = 480,
    SM = 768,
    MD = 992,
    LG = 1200,
    XL = 1600,
}

const breakpointsInReverseOrder = [Breakpoint.XL, Breakpoint.LG, Breakpoint.MD, Breakpoint.SM, Breakpoint.XS, Breakpoint.XXS];

function pixelsToBreakpoint(pixels: number): Breakpoint {
    if (!_.isFinite(pixels)) {
        return null;
    }
    for (const breakpoint of breakpointsInReverseOrder) {
        if (pixels >= breakpoint) {
            return breakpoint;
        }
    }

    return null;
}

/**
 * Calculates the current screen size breakpoint based on the window width.
 *
 * N.B. When writing tests for other components/services that depend on this service, ALWAYS mock this service. This service
 * adds event listeners to the window object, which appears to be a shared global singleton across all test executions, so
 * not mocking this service will likely result in sporadic behavior. You have been warned.
 */
@Injectable({ providedIn: 'root' })
export class BreakpointService implements OnDestroy {

    private readonly breakpointSubject$: BehaviorSubject<Breakpoint>;
    private readonly resizeSubscription: Subscription;

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.breakpointSubject$ = new BehaviorSubject(pixelsToBreakpoint(document.defaultView?.innerWidth));

        this.resizeSubscription = fromEvent(document.defaultView, 'resize')
            .pipe(
                map(event => (event?.target as Window)?.innerWidth),
                map(pixelsToBreakpoint),
                filter(_.isFinite),
                distinctUntilChanged(),
            )
            .subscribe(breakpoint => this.breakpointSubject$.next(breakpoint));
    }

    /**
     * Returns an observable which emits the active (Bootstrap 3) grid breakpoint based on the current window width. Will
     * emit a new value only if the window is resized enough that the new width will result in a different breakpoint value.
     * I.e. will not emit the same breakpoint value several times in a row if the window width changes are small.
     *
     * The observable will emit the current breakpoint value immediately when subscribed to.
     */
    get breakpoint$(): Observable<Breakpoint> {
        return this.breakpointSubject$.asObservable();
    }

    /**
     * Returns the active (Bootstrap 3) grid breakpoint based on the current window width.
     */
    getCurrentBreakpoint(): Breakpoint {
        return this.breakpointSubject$.getValue();
    }

    ngOnDestroy(): void {
        this.resizeSubscription?.unsubscribe();
        this.breakpointSubject$.complete();
    }
}
