import angular from 'angular';
import * as _ from 'lodash-es';
import { PlanValidationTs } from 'common-typescript';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler.ts';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service.ts';
import { tap, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
(function () {
  moduleAttainmentApplicationService.$inject = ["$q", "commonStudentApplicationService", "STUDENT_APPLICATION_STATE", "studentApplicationJSDataModel", "AuthService", "PlanState", "STUDENT_APPLICATION_TYPE", "workflowEntityService", "appErrorHandler"];
  angular.module('student.common.service.moduleAttainmentApplicationService', ['sis-common.auth', 'sis-components.model.studentApplication', 'sis-components.service.studentApplicationService', WorkflowEntityService.downgrade.moduleName, AppErrorHandler.downgrade.moduleName, 'student.shared']).service('moduleAttainmentApplicationService', moduleAttainmentApplicationService);
  function moduleAttainmentApplicationService(
  //NOSONAR
  $q, commonStudentApplicationService, STUDENT_APPLICATION_STATE, studentApplicationJSDataModel, AuthService, PlanState, STUDENT_APPLICATION_TYPE, workflowEntityService, appErrorHandler) {
    var api = {
      /**
       * This method ensures that there exists moduleAttainmentApplications for module (module*) and all child
       * modules. If module* already has moduleAttainmentApplication then the existing one will be
       * returned. An application will be sent for any module that is not yet attained and does not have
       * existing application. You probably made a mistake somewhere if you are calling this method for
       * module* that has an application and especially when it is already attained or it is a grouping module.
       * @param module a.k.a. module*
       * @param validatablePlan
       * @param studyRight
       * @param planValidationResult
       * @param sentAsDPChildApplication
       * @returns new application or existing application for module
       */
      sendApplication: function (module, validatablePlan, studyRight, planValidationResult, sentAsDPChildApplication) {
        if (!sentAsDPChildApplication) {
          if (isLikeGroupingModule(module)) {
            //LOL, major failure! This is a huge mistake. Maybe a shitty check in component.
            return $q.reject('Module ' + module.id + ' is a grouping or non graded module.');
          }
          if (!isPlanStateValidForApplicationSending(module)) {
            //LOL, major failure! This is a huge mistake. Maybe a shitty check in component.
            return $q.reject('Module ' + module.id + ' is not in correct plan state.');
          }
        }
        return commonStudentApplicationService.findAll({
          studentId: AuthService.personId()
        }, {
          bypassCache: true
        }).then(function (applications) {
          var requested_MA_applications = _.chain(applications).filter({
            type: STUDENT_APPLICATION_TYPE.MODULE_ATTAINMENT_APPLICATION
          }).filter(function (application) {
            return application.state === STUDENT_APPLICATION_STATE.REQUESTED || application.state === STUDENT_APPLICATION_STATE.IN_HANDLING;
          }).value();
          return $q.resolve(recursiveApplicationSender(module, validatablePlan, studyRight, requested_MA_applications, planValidationResult));
        });
        function recursiveApplicationSender(module, validatablePlan, studyRight, existingApplications, planValidationResult) {
          var existingApplication = _.find(existingApplications, ['moduleId', module.id]);
          if (existingApplication) {
            return $q.resolve(existingApplication);
          }
          var childModules = validatablePlan.getSelectedModulesById(module);
          //We should 'dive deeper into' modules that are either grouping modules, non graded modules or
          //modules in correct plan state.
          var promises = _.chain(childModules).filter(function (module) {
            return isLikeGroupingModule(module) || isPlanStateValidForApplicationSending(module, sentAsDPChildApplication);
          }).map(function (childModule) {
            return recursiveApplicationSender(childModule, validatablePlan, studyRight, existingApplications, planValidationResult);
          }).value();
          return $q.all(promises).then(function () {
            if (isLikeGroupingModule(module) || !isPlanStateValidForApplicationSending(module, sentAsDPChildApplication)) {
              //We should 'dive deeper into' grouping modules but not send applications for them
              return $q.resolve();
            }
            const workflowApplication = createWorkflowApplication(module, validatablePlan, studyRight);
            return api.createModuleOrDegreeProgrammeAttainmentWorkflow(workflowApplication);
          });
        }
        function createWorkflowApplication(module, validatablePlan, studyRight) {
          const {
            plan
          } = validatablePlan;
          return {
            moduleId: module.id,
            planId: plan.id,
            studyRightId: studyRight.id,
            state: 'REQUESTED',
            type: 'ModuleAttainmentApplication',
            planContent: {
              courseUnitSelections: _.cloneDeep(plan.courseUnitSelections),
              moduleSelections: _.cloneDeep(plan.moduleSelections),
              customCourseUnitAttainmentSelections: _.cloneDeep(plan.customCourseUnitAttainmentSelections),
              customModuleAttainmentSelections: _.cloneDeep(plan.customModuleAttainmentSelections),
              customStudyDrafts: _.cloneDeep(plan.customStudyDrafts)
            }
          };
        }
        function isPlanStateValidForApplicationSending(module, sentAsDPChildApplication) {
          var planState = PlanValidationTs.getPlanStateForModule(module, validatablePlan, planValidationResult);
          return api.planStateIsValidForSendingApplication(planState, sentAsDPChildApplication);
        }
        function isLikeGroupingModule(module) {
          return module.type === 'GroupingModule' || module.graded === false;
        }
      },
      planStateIsValidForSendingApplication: function (planState, sentAsDPChildApplication) {
        var validPlanStates;
        if (sentAsDPChildApplication) {
          validPlanStates = [PlanState.PLANNED, PlanState.APPROVED, PlanState.APPROVED_CONDITIONALLY, PlanState.APPROVAL_REQUESTED, PlanState.APPROVAL_REQUESTED_PARTS_ATTAINED, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.PARTS_ATTAINED, PlanState.IMPLICIT];
        } else {
          validPlanStates = [PlanState.PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED];
        }
        return _.includes(validPlanStates, planState);
      },
      /**
       * Search module attainment applications by module id and users id as student id.
       * @param module
       * @returns list of applications
       */
      searchExistingApplicationsForModule: function (module) {
        var params = {
          moduleId: module.id,
          applicationType: STUDENT_APPLICATION_TYPE.MODULE_ATTAINMENT_APPLICATION,
          studentId: AuthService.personId()
        };
        var searchConfig = {
          build: function () {
            return {
              params: params
            };
          }
        };
        return commonStudentApplicationService.search(searchConfig).then(function (searchResult) {
          return studentApplicationJSDataModel.inject(searchResult.searchResults);
        });
      },
      createModuleOrDegreeProgrammeAttainmentWorkflow: workflowApplication => {
        const deferred = $q.defer();
        workflowEntityService.createAsStudent(workflowApplication).pipe(take(1), tap(result => {
          deferred.resolve(result);
        }), catchError(err => {
          deferred.reject();
          return throwError(() => err);
        }), appErrorHandler.defaultErrorHandler()).subscribe();
        return deferred.promise;
      }
    };
    return api;
  }
})();