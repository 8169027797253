import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

import { ActiveUserRoleService, UserRole } from '../../service/active-user-role.service';
import { navUrls } from '../../service/nav-utils.service';

interface UserRoleSelection {
    active: boolean;
    nameKey: string;
    url: string;
}

@Component({
    selector: 'sis-user-role-menu',
    templateUrl: './user-role-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleMenuComponent implements OnInit {

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurMobileUserRoleMenu = new EventEmitter<any>();

    userRoleSelections: UserRoleSelection[] = [];

    activeRoleNameKey: string;
    currentUniversityOrgId: OtmId;

    constructor(
        private readonly configService: ConfigService,
        private readonly authService: AuthService,
        private readonly activeUserRoleService: ActiveUserRoleService,
    ) {}

    ngOnInit() {
        const activeUserRole: UserRole | null = this.activeUserRoleService.activeUserRole;
        this.userRoleSelections = this.getUserRoleSelections(activeUserRole);
        this.activeRoleNameKey = this.getActiveRoleNameKey(activeUserRole);
    }

    private getUserRoleSelections(activeUserRole: UserRole | null): UserRoleSelection[] {
        const roles: UserRoleSelection[] = [];
        const universityConfig = this.configService.get();
        if (this.authService.hasScope('student') || this.authService.hasScope('staff')) {
            roles.push({
                active: activeUserRole === UserRole.Student,
                nameKey: 'AUTH.APPROLE.STUDENT',
                url: !universityConfig.studentFrontpageRedirectUrl ?
                    navUrls.STUDENT_URL : navUrls.STUDENT_PLAN_URL,
            });
        }
        if (this.authService.hasScope('staff')) {
            roles.push({
                active: activeUserRole === UserRole.Teacher,
                nameKey: 'AUTH.APPROLE.TEACHER',
                url: navUrls.TEACHER_URL,
            });
            roles.push({
                active: activeUserRole === UserRole.Staff,
                nameKey: 'AUTH.APPROLE.STAFF',
                url: navUrls.STAFF_URL,
            });
        }
        if (this.authService.hasScope(['admin', 'admin-ui'])) {
            roles.push({
                active: activeUserRole === UserRole.Admin,
                nameKey: 'AUTH.APPROLE.ADMIN',
                url: navUrls.ADMIN_URL,
            });
        }

        return roles;
    }

    private getActiveRoleNameKey(activeUserRole: UserRole | null): string {
        switch (activeUserRole) {
            case UserRole.Admin:
                return 'AUTH.APPROLE.ADMIN';
            case UserRole.Student:
                return 'AUTH.APPROLE.STUDENT';
            case UserRole.Staff:
                return 'AUTH.APPROLE.STAFF';
            case UserRole.Teacher:
                return 'AUTH.APPROLE.TEACHER';
            default:
                return null;
        }
    }
}
