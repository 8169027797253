import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { GradeScaleEntityService } from '../service/grade-scale-entity.service';

export type GradeViewType = 'ABBREVIATION' | 'NAME' | 'NUMERIC';

@Pipe({
    name: 'grade',
})
export class GradePipe implements PipeTransform {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private localeService: LocaleService,
        private gradeScaleEntityService: GradeScaleEntityService,
    ) {
    }

    transform(gradeId: number, gradeScaleId: string, viewType: GradeViewType = 'ABBREVIATION'): Observable<string> {
        if (!gradeScaleId) {
            return of(undefined);
        }
        return this.gradeScaleEntityService.findGrade(gradeId, gradeScaleId)
            .pipe(
                map((grade) => {
                    if (viewType === 'ABBREVIATION') {
                        return this.localeService.localize(grade?.abbreviation);
                    }
                    if (viewType === 'NAME') {
                        return this.localeService.localize(grade?.name);
                    }
                    return _.isNil(grade?.numericCorrespondence) ? undefined : String(grade.numericCorrespondence);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }
}
