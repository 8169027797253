<ng-container *transloco="let t">
  <ng-container *ngIf="data$ | async as data">
    <div class="container sis-pt-md">
      <sis-breadcrumbs></sis-breadcrumbs>
      <sis-page-title [title]="t('APPLICATION_STRUCTURE_OF_STUDIES')" [showCrumbs]="true"/>
      <div class="row">
        <div class="col-12">
          <app-plan-header [allStudentPlans]="data.allStudentPlans"
                           [currentPlan]="data.plan"
                           (selectPlan)="this.handlePlanChange($event)">
          </app-plan-header>
        </div>
      </div>
        <div class="row">
            <div class="col-12">
                <app-plan-notification [planStructureData]="data">
                </app-plan-notification>
            </div>
        </div>
      <div class="sis-mb-sm">
        <fudis-notification  [variant]="'warning'" *ngIf="data.outDatedCourseUnitsInPlan?.length > 0">
          <fudis-body-text>
            {{t('PLAN.STATE.OUTDATED_COURSE_UNITS_IN_THE_PLAN')}}
            <fudis-button
              [label]="t('PLAN.STATE.OPEN_OUTDATED_COURSE_UNITS_DIALOG')"
              (handleClick)="openOutdatedCourseUnitsModal(data, data.outDatedCourseUnitsInPlan)"
            ></fudis-button>
          </fudis-body-text>
        </fudis-notification>
      </div>
      <div class="sis-mb-sm">
        <fudis-notification  [variant]="'warning'" *ngIf="noStudyRightForStudyPlan(data)">
          <fudis-body-text>
            {{t('PLAN_STUDY_RIGHT_STATE.NO_STUDY_RIGHT_FOR_PLAN')}}
          </fudis-body-text>
        </fudis-notification>
      </div>
      <div class="sis-mb-sm">
        <fudis-notification  [variant]="'warning'" *ngIf="studyRightForStudyPlanButNoneActive(data)">
          <fudis-body-text>
            {{t('PLAN_STUDY_RIGHT_STATE.STUDY_RIGHT_FOR_PLAN_BUT_NONE_ACTIVE')}}
          </fudis-body-text>
        </fudis-notification>
      </div>
      <div class="sis-mb-sm">
        <ng-container *ngIf="termRegistrationRequired$ | async">
          <fudis-notification  [variant]="'warning'" *ngIf="missingTermRegistration$ | async">
            <fudis-body-text>
              {{t('PLAN_STUDY_RIGHT_STATE.TERM_REGISTRATION_MISSING')}}
            </fudis-body-text>
          </fudis-notification>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-11">
          <div class="float-end">
            @if (matchingStudyRight$ | async; as studyRight) {
              <fudis-button [label]="t('SIS_COMPONENTS.PLAN.SHOW_MESSAGES.STUDENT')"
                            [ariaLabel]="t('SIS_COMPONENTS.PLAN.SHOW_MESSAGES.STUDENT')"
                            [size]="'small'"
                            [variant]="'primary'"
                            (handleClick)="this.openTutoring()">
              </fudis-button>
            }
          </div>
        </div>
        <div class="col-1">
          <div class="text-end">
            <sis-menu-button [id]="'planMenuOptionsButton'"
                             [color]="'primary'"
                             [options]="menuOptions$ | async">
              <sis-icon [icon]="'three-dots'"></sis-icon>
            </sis-menu-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-plan-structure [education]="data.validatablePlanEducation"
                              [planValidationResult]="data.planValidationResult"
                              [planStateObject]="data.planStateAndData.planStateObject"
                              [planData]="data.planStateAndData.planData">
          </app-plan-structure>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
