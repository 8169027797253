import { Component, Inject, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    StudyEvent,
    StudySubGroup,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { take, tap } from 'rxjs/operators';

import {
    STUDY_EVENT_SERVICE,
} from '../../ajs-upgraded-modules';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { StudyEventEntityService } from '../../service/study-event-entity.service';

@Component({
    selector: 'sis-study-sub-group-structure',
    templateUrl: './study-sub-group-structure.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class StudySubGroupStructureComponent implements OnChanges {

    sortedStudyEvents: StudyEvent[] = [];
    studySubGroupHasTeacherOverrides = false;

    @Input() studySubGroup: StudySubGroup;
    @Input() language: string;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private studyEventEntityService: StudyEventEntityService,
        @Inject(STUDY_EVENT_SERVICE) private commonStudyEventService: any,
    ) {}

    ngOnChanges() {
        if (this.studySubGroup && !_.isEmpty(this.studySubGroup.studyEventIds)) {
            this.studyEventEntityService.getByIds(this.studySubGroup.studyEventIds, true)
                .pipe(
                    take(1),
                    tap((studyEvents) => {
                        this.sortedStudyEvents = this.commonStudyEventService.orderStudyEventsByTime(studyEvents);
                        this.studySubGroupHasTeacherOverrides = _.some(this.sortedStudyEvents, studyEvent => !_.isEmpty(_.filter(studyEvent.overrides, override =>
                            override.irregularTeacherIds !== null)));
                    }),
                    this.appErrorHandler.defaultErrorHandler(),
                ).subscribe();
        }

    }

}
