import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, effect,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit, signal,
    Signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TransitionService } from '@uirouter/angular';
import { RawParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { asyncScheduler, Observable, Subject, takeUntil } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';
import { getElementResizeEventObservable } from 'sis-common/resize-observer/resize-observer';

import { ActiveUserRoleService, UserRole } from '../../service/active-user-role.service';
import { NavUtilsService } from '../../service/nav-utils.service';
import { NotificationsService } from '../../service/notifications/notifications.service';

export interface MainNavigationItem {
    /** Unique id of the link */
    linkId: string;
    /** Visible label of the link */
    translationKey: string;
    /** If true, the link will only be visible for authenticated users */
    loginRequired?: boolean;
    /** The name of the state where the link points to. Alternative to `toUrl`. */
    toState?: string;
    /** Additional state parameters for `toState` */
    toParams?: any;
    /** A URL the link points to. Alternative to `toState`. */
    toUrl?: string;
}

@Component({
    selector: 'sis-top-navigation',
    templateUrl: './top-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationComponent implements OnDestroy, OnInit, AfterViewInit {
    /** The target router state the frontpage link. Alternative to {@link homeUrl}. */
    @Input() homeState: string;
    /** The target URL the frontpage link. Alternative to {@link homeState}. */
    @Input() homeUrl: string;

    @Input() mainNavigationItems: MainNavigationItem[];

    @ViewChild('navigation') navigation: ElementRef;
    @ViewChild('navigationLinks') navigationLinks: ElementRef;
    @ViewChild('side') side: ElementRef;
    @ViewChild('mobileNavButton') mobileNavButton: ElementRef;
    @ViewChild('mobileMenu') mobileMenu: ElementRef;
    @ViewChild('exitLinkFirst') exitLinkFirst: ElementRef<HTMLAnchorElement>;
    @ViewChild('exitLinkSecond') exitLinkSecond: ElementRef<HTMLAnchorElement>;
    @ViewChild('mobileSide') mobileSide: ElementRef;

    readonly authenticated: Signal<boolean>;
    isMobile = signal<boolean>(true);
    isMobileMenuOpen = signal<boolean>(false);
    activeIndex = signal<number>(undefined);

    focusOnCart: boolean = false;

    frontendName: string;
    activeRoleNameKey: string;
    displayName: string;
    notificationObservable: Observable<number>;
    destroyed$ = new Subject<void>();

    deregisterActiveUrlChangeHook: Function;

    constructor(
        authService: AuthService,
        private readonly environmentService: EnvironmentService,
        private readonly notificationsService: NotificationsService,
        private readonly navUtilsService: NavUtilsService,
        private readonly state: StateService,
        private readonly transitionService: TransitionService,
        private readonly uiRouterGlobals: UIRouterGlobals,
        private readonly activeUserRoleService: ActiveUserRoleService,
    ) {
        this.authenticated = toSignal(authService.userLoggedIn$);
        this.notificationObservable = this.notificationsService.getMessageCountObservable()
            .pipe(takeUntil(this.destroyed$));
        this.displayName = authService.displayname();
        effect(() => {
            if (this.focusMode()) {
                const targetElement = this.exitLinkFirst?.nativeElement ? this.exitLinkFirst.nativeElement : this.exitLinkSecond?.nativeElement;
                targetElement?.focus();
            } else if (this.focusOnCart) {
                this.isMobile() ? this.getIconButtonFromElement(this.mobileSide)?.focus() : this.getIconButtonFromElement(this.side)?.focus();
                this.toggleFocusOnCart();
            }
        });
    }

    ngAfterViewInit() {
        this.initResizeObserver()
            .subscribe(() => this.checkLayoutMode());
    }

    get focusMode() {
        return this.navUtilsService.focusMode;
    }

    toggleFocusOnCart() {
        this.focusOnCart = !this.focusOnCart;
    }

    ngOnInit() {
        this.frontendName = this.environmentService.frontendName;
        this.setActiveIndex(this.uiRouterGlobals.current.name);
        this.deregisterActiveUrlChangeHook = this.registerActiveUrlChangeHook();
        this.activeRoleNameKey = this.getActiveRoleNameKey();
    }

    initResizeObserver() {
        return getElementResizeEventObservable(this.navigation.nativeElement)
            .pipe(throttleTime(200, asyncScheduler, { leading: true, trailing: true }),
                  takeUntil(this.destroyed$));
    }

    checkLayoutMode() {
        const links = this.navigationLinks?.nativeElement?.getBoundingClientRect();
        const side = this.side?.nativeElement?.getBoundingClientRect();
        if (links?.right && side?.left) {
            links.right >= side.left ? this.isMobile.set(true) : this.isMobile.set(false);
            if (!this.isMobile() && this.isMobileMenuOpen()) this.isMobileMenuOpen.set(false);
        }
    }

    ngOnDestroy() {
        this.deregisterActiveUrlChangeHook();
        this.destroyed$.complete();
    }

    toggleMobileMenu() {
        this.isMobileMenuOpen.set(!this.isMobileMenuOpen());
    }

    isValidState(state: string, params: RawParams = null) {
        return !!state && !!this.state.href(state, params);
    }

    registerActiveUrlChangeHook() {
        return this.transitionService.onSuccess({}, (transition) => {
            this.isMobileMenuOpen.set(false);
            this.setActiveIndex(transition.to().name);
        });
    }

    setActiveIndex(activePathName: string) {
        this.activeIndex.set(undefined);
        if (activePathName.includes('logged-in.frontpage') || activePathName.includes('.login')) {
            this.activeIndex.set(-1);
        } else if (activePathName.includes('student.logged-in.structure')) {
            // path changes from plan-select to structure before the transition updates
            this.activeIndex.set(0);
        } else {
            this.mainNavigationItems?.forEach((item: any, index) => {
                if (activePathName.includes(item.toState)) {
                    this.activeIndex.set(index);
                }
            });
        }
    }

    private getActiveRoleNameKey(): string {
        switch (this.activeUserRoleService.activeUserRole) {
            case UserRole.Admin:
                return 'AUTH.APPROLE.ADMIN';
            case UserRole.Student:
                return 'AUTH.APPROLE.STUDENT';
            case UserRole.Staff:
                return 'AUTH.APPROLE.STAFF';
            case UserRole.Teacher:
                return 'AUTH.APPROLE.TEACHER';
            default:
                return null;
        }
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.isMobileMenuOpen() && event.code !== 'Tab') {
            const listItems = this.getAllNavItems() as HTMLInputElement[];
            if (listItems.length > 0) {
                const current = listItems.find(item => item.ariaCurrent === 'page') || listItems[0];
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    const focusElement = this.getFocusedElement();
                    const siblingElementsCount = listItems.length - 1;
                    event.preventDefault();

                    const focusIndex = listItems.indexOf(focusElement);

                    let elementToFocus = current;

                    if (event.key === 'ArrowUp') {
                        elementToFocus = listItems[focusIndex > 0 ? focusIndex - 1 : siblingElementsCount];
                    }
                    if (event.key === 'ArrowDown') {
                        elementToFocus = listItems[focusIndex < siblingElementsCount ? focusIndex + 1 : 0];
                    }
                    elementToFocus.focus();

                    // If elementToFocus is hidden behind display none
                    if (focusElement === this.getFocusedElement()) {
                        elementToFocus = listItems[event.key === 'ArrowUp' ? this.getAllNavItems().length - 1 : 0];
                        elementToFocus.focus();
                    }

                } else if (event.code === 'Space') {
                    const focusElement = this.getFocusedElement();
                    focusElement?.click();
                }
            }
        }
    }

    private getFocusedElement() {
        return this.mobileMenu?.nativeElement.querySelector(':focus');
    }

    private getAllNavItems() {
        const navList = this.mobileMenu?.nativeElement.querySelectorAll('a, button');
        return [...navList];
    }

    /* Ugly af, but did not have the energy to revamp the sis-navigation-icon / icons component.
    * Feel free to fix this focus handling if you want. */
    private getIconButtonFromElement(element: ElementRef) {
        return element?.nativeElement?.querySelector('sis-top-navigation-icon > a, button');
    }

    private elementContains(element: ElementRef, event: any) {
        return element?.nativeElement.contains(event.target);
    }

    /** Close open mobile navigation if targeted element is not inside the mobile nav, expect when the click event opens the nav */
    @HostListener('document:click', ['$event'])
    private offClickHandler(event: any) {
        if (this.isMobileMenuOpen() &&
            !this.elementContains(this.mobileNavButton, event) &&
            !this.elementContains(this.mobileMenu, event)) {
            this.isMobileMenuOpen.set(false);
        }
    }

    @HostListener('document:keyup.Escape', ['$event'])
    private onEscapeMobileMenu(event: any) {
        if (this.isMobileMenuOpen() &&
            (this.elementContains(this.mobileNavButton, event) || this.elementContains(this.mobileMenu, event))) {
            this.isMobileMenuOpen.set(false);
            this.mobileNavButton?.nativeElement?.focus();
        }
    }

    @HostListener('document:keyup.Tab', ['$event'])
    private onLeaveMobileMenu(event: any) {
        if (this.isMobileMenuOpen() &&
            (!this.elementContains(this.mobileNavButton, event) && !this.elementContains(this.mobileMenu, event))) {
            this.isMobileMenuOpen.set(false);
        }
    }
}
