import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { PrivatePerson } from 'common-typescript/types';
import _ from 'lodash';
import {
    ClassifiedPersonInfoToggleModalComponent,
} from 'sis-components/classified-person-info-toggle-modal/classified-person-info-toggle-modal.component';
import {
    editContactInformationDialogOpener,
} from 'sis-components/person/edit-contact-information-dialog/edit-contact-information-dialog.component';
import { ClassifiedVisibility, COUNTRY_URN_FI } from 'sis-components/person/utils/basic-info-utils';

@Component({
    selector: 'app-contact-information',
    templateUrl: './contact-information.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInformationComponent implements OnInit {
    @Input() studentProfile: PrivatePerson;
    @Input() isEditEnabledByUniversity: boolean;
    @Output() updatedStudentChange = new EventEmitter<PrivatePerson>();

    classifiedVisibilityOverrides: ClassifiedVisibility;

    private readonly editContactInformationDialogOpener = editContactInformationDialogOpener();

    constructor(
        private dialogService: FudisDialogService,
    ) {
    }

    ngOnInit(): void {
        this.classifiedVisibilityOverrides = {
            phoneNumberVisible: false,
            secondaryEmailVisible: false,
            primaryAddressVisible: false,
            secondaryAddressVisible: false,
            municipalityUrnVisible: false,
        };
    }

    isSecondaryAddressInFinland(): boolean {
        return this.studentProfile.secondaryAddress.type === 'FinnishAddress';
    }

    toggleFieldVisibility(fieldName: string) {
        const field = `${_.camelCase(fieldName)}Visible`;
        _.set(this.classifiedVisibilityOverrides, field, !_.get(this.classifiedVisibilityOverrides, field));
    }

    isClassifiedInfoVisible(fieldName: string) {
        const fieldOverride = `${_.camelCase(fieldName)}Visible`;
        const classifiedField = `classifiedPersonInfo.is${fieldName}Classified`;
        const override = _.get(this.classifiedVisibilityOverrides, fieldOverride);
        const classified = !_.get(this.studentProfile, classifiedField);
        return override || classified;
    }

    isClassified(fieldName: string) {
        const classifiedField = `classifiedPersonInfo.is${fieldName}Classified`;
        return !!_.get(this.studentProfile, classifiedField);
    }

    openEditDialog() {
        if (!this.isEditEnabledByUniversity) {
            return;
        }

        this.editContactInformationDialogOpener({ studentProfile: this.studentProfile })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.studentProfile = result.studentProfile;
                }
            });

        if (_.isNil(this.studentProfile.primaryAddress) || _.isNil(this.studentProfile.primaryAddress.countryUrn)) {
            _.set(this.studentProfile, 'primaryAddress.countryUrn', COUNTRY_URN_FI);
            _.set(this.studentProfile, 'primaryAddress.type', 'FinnishAddress');
        }
    }

    openClassifiedInfoDialog(): void {
        this.dialogService.open(ClassifiedPersonInfoToggleModalComponent, {
            data: {
                privatePerson: this.studentProfile,
                staffEdit: false,
            },
        })
            .afterClosed().subscribe((updatedValues) => {
                if (updatedValues) {
                    this.studentProfile = updatedValues;
                    this.updatedStudentChange.emit(updatedValues);
                }
            });
    }
}
