import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FudisTranslationService } from '@funidata/ngx-fudis';
import { FudisLanguageAbbr } from '@funidata/ngx-fudis/lib/types/miscellaneous';
import { OiliPayment, PaymentCategory, StudentPayment } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

@Component({
    selector: 'app-oili-payments-table',
    templateUrl: './oili-payments-table.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OiliPaymentsTableComponent implements OnInit {
    @Input() payments: OiliPayment[];
    @Input() caption: string;

    public providedLanguages: FudisLanguageAbbr[] = [];
    public selectedDescriptionLanguage: string;

    constructor(private localeService: LocaleService, private fudisTranslationService: FudisTranslationService) {}

    ngOnInit(): void {
        /**
         * Usually LanguageBadgeGroup should be grouped with only one text field, here it is used as table header where it is not reasonable to detect which description languages are provided.
         */
        this.providedLanguages = this.localeService.getOfficialLanguages() as FudisLanguageAbbr[];
        this.fudisTranslationService.setSelectableLanguages(this.providedLanguages);
        this.selectedDescriptionLanguage = this.localeService.getCurrentLanguage();
    }

    changeLanguageBadgeContent(lang: FudisLanguageAbbr) {
        this.selectedDescriptionLanguage = lang;
    }

    getDescriptionWithSelectedLanguage(category: PaymentCategory): string {
        return category?.description?.[this.selectedDescriptionLanguage] ?? '';
    }

    getStudyTermNameTranslationKey(payment: StudentPayment): string {
        if (!payment.studyTerm) {
            return 'STUDY_TERMS.AUTUMN_AND_SPRING';
        }
        if (payment.studyTerm.termIndex === 0) {
            return 'STUDY_TERMS.AUTUMN';
        }
        if (payment.studyTerm.termIndex === 1) {
            return 'STUDY_TERMS.SPRING';
        }
        return 'STUDY_TERMS.AUTUMN_AND_SPRING';
    }

}
