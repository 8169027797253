import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { dateUtils, MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import { LocalDateRange } from 'common-typescript/types';
import moment from 'moment';

@Pipe({
    name: 'validityPeriodDisplay',
})
export class ValidityPeriodDisplayPipe implements PipeTransform {

    constructor(private translocoService: TranslocoService) {}
    transform(dates: LocalDateRange): string {
        if (!dates) {
            return '';
        }

        let format: string;
        let startDate: moment.Moment;
        let endDate: moment.Moment;

        if (dates.startDate) {
            startDate = moment(dateUtils.convertLocalizedDateToIsoLocalDate(dates.startDate));
        }

        if (dates.endDate) {
            endDate = moment(dateUtils.convertLocalizedDateToIsoLocalDate(dates.endDate));
        }

        if (startDate && endDate && startDate.isSame(endDate)) {
            format = 'ARE_SAME';
        } else if (startDate && endDate) {
            format = 'START_END';
        } else if (startDate && !endDate) {
            format = 'START_';
        } else if (!startDate && endDate) {
            format = '_END';
        } else {
            format = 'FOR_NOW';
        }

        return this.translocoService.translate(`DATE_RANGE.${format}`, {
            start: startDate ? startDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined,
            end: endDate ? endDate.format(MOMENT_DATE_DISPLAY_FORMAT) : undefined,
        });
    }

}
