import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OtmId, PersonalIdentityCode, PersonDataChange } from 'common-typescript/types';
import _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PrivatePersonEntityService } from '../../service/private-person-entity.service';

export interface PersonalInfoHistoryDialogValues {
    history: PersonDataChange[],
    showCorrectedRows: boolean,
    studentId: OtmId,
    arePICsIncludedInHistory: boolean
}

@Component({
    selector: 'sis-personal-info-history-dialog',
    templateUrl: './personal-info-history-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PersonalInfoHistoryDialogComponent implements OnInit {

    sortedHistory: PersonDataChange[];
    studentPICVisibilityMappings: { [id: OtmId]: { value: PersonalIdentityCode | null, isVisible: boolean } };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PersonalInfoHistoryDialogValues,
        private privatePersonEntityService: PrivatePersonEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit() {
        this.sortedHistory = _.orderBy(this.data.history, ['validUntil', 'modified'], ['desc', 'desc']);
        this.studentPICVisibilityMappings = _.reduce(
            this.sortedHistory,
            (result, historyObject) => ({
                ...result, [historyObject.id]: {
                    value: this.data.arePICsIncludedInHistory ? historyObject.oldPersonalIdentityCode : null,
                    isVisible: false,
                },
            }),
            {},
        );
    }

    togglePICVisibility(id: OtmId) {
        // If PICs are not included in this.data.history, fetch them on show event individually by PersonDataChange id
        if (!this.data.arePICsIncludedInHistory && !this.isPICVisible(id)) {
            // Fetch PIC on each click of the show button, so audit log can log each event individually
            this.privatePersonEntityService.getDataChangePIC(this.data.studentId, id)
                .pipe(
                    catchError(err => {
                        this.studentPICVisibilityMappings = {
                            ...this.studentPICVisibilityMappings,
                            [id]: { value: null, isVisible: false },
                        };
                        return throwError(() => err);
                    }),
                    this.appErrorHandler.defaultErrorHandler())
                .subscribe(fetchedPIC => {
                    this.studentPICVisibilityMappings = {
                        ...this.studentPICVisibilityMappings,
                        [id]: { value: fetchedPIC, isVisible: true },
                    };
                });
        // Set PIC value to null on hide event when PICs are not included in this.data.history
        } else if (!this.data.arePICsIncludedInHistory) {
            this.studentPICVisibilityMappings = {
                ...this.studentPICVisibilityMappings,
                [id]: { value: null, isVisible: false },
            };
        // When PICs are included in this.data.history, perform normal toggle
        } else {
            this.studentPICVisibilityMappings = {
                ...this.studentPICVisibilityMappings,
                [id]: { value: this.studentPICVisibilityMappings[id].value, isVisible: !this.studentPICVisibilityMappings[id].isVisible },
            };
        }
    }

    isPICVisible(id: OtmId) {
        return this.studentPICVisibilityMappings[id].isVisible;
    }
}
