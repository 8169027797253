import angular from 'angular';
import * as _ from 'lodash-es';
(function () {
  minorStudyRightService.$inject = ["$q", "$http", "studyRightJSDataModel", "StudyRightMinorSelectionState", "StudyRightAcceptanceType", "StudyRightSelectionType", "MinorSelectionViewState"];
  angular.module('student.common.service.minorStudyRightService', ['sis-components.model.studyRight']).constant('StudyRightAcceptanceType', {
    ACCEPTED_BY_TEACHER: 'urn:code:study-right-acceptance-type:accepted-by-teacher',
    AUTOMATIC: 'urn:code:study-right-acceptance-type:automatic'
  }).constant('StudyRightSelectionType', {
    MINOR_STUDY_RIGHT: 'urn:code:study-right-selection-type:minor-study-right',
    NONE: 'urn:code:study-right-selection-type:none'
  }).constant('StudyRightMinorSelectionState', {
    APPROVED: 'APPROVED',
    REQUESTED: 'REQUESTED'
  }).constant('MinorSelectionViewState', {
    SHOULD_CONFIRM: 'SHOULD_CONFIRM',
    SHOULD_APPLY: 'SHOULD_APPLY',
    APPROVED_WITHOUT_END_DATE: 'APPROVED_WITHOUT_END_DATE',
    APPROVED_ENDED: 'APPROVED_ENDED',
    APPROVED_ENDING: 'APPROVED_ENDING'
  }).factory('minorStudyRightService', minorStudyRightService);
  function minorStudyRightService($q, $http, studyRightJSDataModel, StudyRightMinorSelectionState, StudyRightAcceptanceType, StudyRightSelectionType, MinorSelectionViewState) {
    function getParentPhaseForModule(module, studyRight, validatablePlan) {
      function getEducationPhaseDp(phaseGroupId) {
        if (!phaseGroupId) {
          return undefined;
        }
        const dp = validatablePlan.getModuleInPlanByGroupId(phaseGroupId);
        if (dp && validatablePlan.isModuleInPlanUnderModule(module.id, dp.id)) {
          return dp;
        }
        return undefined;
      }
      if (!_.get(module, 'id')) {
        return undefined;
      }
      let phaseDp = getEducationPhaseDp(_.get(studyRight, 'acceptedSelectionPath.educationPhase1GroupId'));
      if (phaseDp) {
        return {
          phase: '1',
          module: phaseDp
        };
      }
      phaseDp = getEducationPhaseDp(_.get(studyRight, 'acceptedSelectionPath.educationPhase2GroupId'));
      if (phaseDp) {
        return {
          phase: '2',
          module: phaseDp
        };
      }
      return undefined;
    }
    const api = {
      /**
       * Returns true if module has 'MINOR_STUDY_RIGHT' studyRightSelectionType and it has a parent module
       * selection that also has 'MINOR_STUDY_RIGHT' in contentFilter.studyRightSelectionType.
       */
      isMinorSelection: function (module, validatablePlan) {
        var minorStudyRightType = StudyRightSelectionType.MINOR_STUDY_RIGHT;
        if (minorStudyRightType !== _.get(module, 'studyRightSelectionType')) {
          return false;
        }
        var parentModule = validatablePlan.getParentModuleOrCustomModuleAttainmentForModule(module);
        return minorStudyRightType === _.get(parentModule, 'contentFilter.studyRightSelectionType');
      },
      /**
       * Return true if module would be a minor if selected under potentialParentModule. This function is
       * intended to be used in selection assistant when the module in question is not yet in plan
       * but presented as an option that can be selected.
       */

      isPotentialMinorSelection: function (module, potentialParentModule) {
        var minorStudyRightType = StudyRightSelectionType.MINOR_STUDY_RIGHT;
        if (minorStudyRightType !== _.get(module, 'studyRightSelectionType')) {
          return false;
        }
        return minorStudyRightType === _.get(potentialParentModule, 'contentFilter.studyRightSelectionType');
      },
      /**
       * Returns true when getEducationPhaseForModule finds education phase. Returns false when
       * getEducationPhaseForModule return undefined.
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns {boolean}
       */
      isModuleSelectedUnderStudyRightPhase: function (module, studyRight, validatablePlan) {
        return !!getParentPhaseForModule(module, studyRight, validatablePlan);
      },
      /**
       * Checks if either education phase is a parent selection to module. Education phase is determined by
       * educationPhase1GroupId or educationPhase2GroupId in studyRight.acceptedSelectionPath. If parent phase is
       * found then it is returned otherwise returns undefined
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns education phase
       */
      getEducationPhaseForModule: function (module, studyRight, validatablePlan) {
        return _.get(getParentPhaseForModule(module, studyRight, validatablePlan), 'module');
      },
      /**
       * First determines which phase does this module selection in plan belong to. If a phase can be determined
       * then it finds approved StudyRightMinorSelection object in StudyRight by module group id. Returns
       * undefined if phase can not be determined or approved minor selection does not exists for phase.
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns StudyRightMinorSelection object
       */
      getApprovedMinorSelection: function (module, studyRight, validatablePlan) {
        //We assume that phase1MinorSelections list is unique by moduleGroupId field. We assume same thing for
        //phase2MinorSelections.
        var minorSelection = api.getMinorSelection(module, studyRight, validatablePlan);
        if (StudyRightMinorSelectionState.APPROVED === _.get(minorSelection, 'selectionState')) {
          return minorSelection;
        }
      },
      /**
       * First determines which phase does this module selection in plan belong to. If a phase can be determined
       * then it finds StudyRightMinorSelection object in StudyRight by module group id. Returns undefined if
       * phase can not be determined or approved minor selection does not exists for phase.
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns StudyRightMinorSelection object
       */
      getMinorSelection: function (module, studyRight, validatablePlan) {
        var parentPhase = getParentPhaseForModule(module, studyRight, validatablePlan);
        if (!parentPhase) {
          return;
        }
        var studyRightField = parentPhase.phase === '1' ? 'phase1MinorSelections' : 'phase2MinorSelections';
        return _.find(_.get(studyRight, studyRightField, []), {
          moduleGroupId: module.groupId
        });
      },
      /**
       * We assume that module is a minor as the possible return values do not mean anything for module that is
       * not a minor.
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns
       *   SHOULD_CONFIRM if module is not approved selection and has not ACCEPTED_BY_TEACHER acceptance type.
       *   SHOULD_APPLY if module is not approved selection and has ACCEPTED_BY_TEACHER acceptance type.
       *   APPROVED_WITHOUT_END_DATE if module is approved selection and has no end date.
       *   APPROVED_ENDED if module is approved selection and has passed end date.
       *   APPROVED_ENDING if module is approved selection and has future end date.
       */
      getViewStateForModule: function (module, studyRight, validatablePlan) {
        if (!module) {
          return undefined;
        }
        const minorSelection = api.getApprovedMinorSelection(module, studyRight, validatablePlan);
        if (!minorSelection) {
          return api.isTeacherAcceptanceRequired(module) ? MinorSelectionViewState.SHOULD_APPLY : MinorSelectionViewState.SHOULD_CONFIRM;
        }
        var endDate = _.get(minorSelection, 'validityPeriod.endDate');
        if (_.isNil(endDate)) {
          return MinorSelectionViewState.APPROVED_WITHOUT_END_DATE;
        }
        return moment().isBefore(moment(endDate)) ? MinorSelectionViewState.APPROVED_ENDING : MinorSelectionViewState.APPROVED_ENDED;
      },
      /**
       * Returns rejected promise if parent education phase cannot be determined for module. Returns rejected
       * promise if approved minor selection exists for module 'in the same phase'. Makes POST request to
       * /ori/api/study-rights/{studyRightId}/request-minor-selection/phaseX. Expects studyRight object as
       * response. Injects it to js-data model. Does not load js-data relations.
       * @param module
       * @param studyRight
       * @param validatablePlan
       * @returns StudyRight
       */
      confirmSelection: function (module, studyRight, validatablePlan) {
        const parentPhase = getParentPhaseForModule(module, studyRight, validatablePlan);
        if (!parentPhase) {
          return $q.reject('Can not determine correct phase for minor selection confirmation.');
        }
        if (!!api.getMinorSelection(module, studyRight, validatablePlan)) {
          return $q.reject('Target phase already has a minor selection for module.');
        }
        const urlSuffix = parentPhase.phase === '1' ? '/request-minor-selection/phase1' : '/request-minor-selection/phase2';
        const url = `/ori/api/study-rights/${studyRight.id}${urlSuffix}`;
        const minorSelection = {
          moduleGroupId: module.groupId,
          selectionType: 'urn:code:study-right-selection-type:minor-study-right'
        };
        return $http.post(url, minorSelection).then(response => response.data).then(studyRightJSDataModel.inject);
      },
      /**
       * Checks urn value in minorStudyRightAcceptanceType field.
       * @param module
       */
      isTeacherAcceptanceRequired: function (module) {
        return module.minorStudyRightAcceptanceType === StudyRightAcceptanceType.ACCEPTED_BY_TEACHER;
      }
    };
    return api;
  }
})();