import angular from 'angular';
import * as _ from 'lodash-es';
import { IconComponent } from 'sis-components/icon/icon.component.ts';
import { PopoverComponent } from 'sis-components/popover/popover.component.ts';
import { CourseUnitAttainmentNotificationComponent } from '../course-unit-attainment-notification/course-unit-attainment-notification.component.ts';
import courseUnitBoxTpl from './courseUnitBox.tpl.html';
(function () {
  CourseUnitBoxController.$inject = ["$rootScope", "$log", "$scope", "$state", "defaultPromiseHandler", "commonPlanService", "planServiceEventType", "courseUnitInfoModal", "colorService", "validAttainmentFilterService", "uiContext"];
  angular.module('student.common.components.courseUnitBox', ['sis-components.number.creditRangeFilter', 'sis-components.date.localDateFormatFilter', 'sis-components.service.colorService', 'sis-components.service.validAttainmentFilterService', 'student.common.components.courseUnitInfoModal', 'student.common.components.courseUnitBox.courseUnitSubstitutionInfo', IconComponent.downgrade.moduleName, PopoverComponent.downgrade.moduleName, CourseUnitAttainmentNotificationComponent.downgrade.moduleName]).component('courseUnitBox', {
    template: courseUnitBoxTpl,
    transclude: {
      default: '?default',
      'additional-notifications': '?additionalNotifications'
    },
    bindings: {
      courseUnit: '<',
      dimmed: '<?',
      isSubstitution: '<?',
      substitutionFor: '<?',
      selected: '<?',
      showNotifications: '<?',
      selectedParent: '<?',
      validatablePlan: '<?',
      planValidationResult: '<?',
      courseUnitSelection: '<',
      showCompletionMethod: '<',
      showCourseUnitAttainmentNotification: '<',
      showCourseUnitGradeRaise: '<'
    },
    controller: CourseUnitBoxController
  });
  function CourseUnitBoxController($rootScope, $log, $scope, $state, defaultPromiseHandler, commonPlanService, planServiceEventType, courseUnitInfoModal, colorService, validAttainmentFilterService, uiContext) {
    const $ctrl = this;
    $ctrl.scope = $scope;
    $ctrl.$onInit = function () {
      $ctrl.substitutionExpanded = false;
    };
    $ctrl.$onChanges = () => {
      if ($ctrl.validatablePlan) {
        $ctrl.courseUnitId = _.get($ctrl.courseUnit, 'id');
        $ctrl.courseUnitAttainment = $ctrl.validatablePlan.getCourseUnitAttainment($ctrl.courseUnitId);
        $ctrl.courseUnitAttainmentId = _.get($ctrl.courseUnitAttainment, 'id');
        const courseUnitValidationResults = _.get($ctrl.planValidationResult, 'courseUnitValidationResults');
        $ctrl.courseUnitValidationResult = _.get(courseUnitValidationResults, $ctrl.courseUnitId);
        setVisibleCredits($ctrl.courseUnit);
        $ctrl.colorCategory = getCourseUnitColorCategory();
        $ctrl.isAboutToExpire = isAboutToExpire();
        $ctrl.isInvalidSelection = isInvalidSelection();
        $ctrl.isInvalidAccordingToModuleContentApproval = isInvalidAccordingToModuleContentApproval();
        $ctrl.hasModuleContentApproval = hasModuleContentApproval();
      }
    };
    function getCourseUnitColorCategory() {
      try {
        const colorCategory = colorService.getCourseUnitColorCategory($ctrl.validatablePlan, _.get($ctrl, 'courseUnit.id'));
        if (colorCategory) {
          return `cu-color-${colorCategory}`;
        }
        return undefined;
      } catch (e) {
        $log.error('Failed to getCourseUnitColorCategory', e);
        return undefined;
      }
    }
    $ctrl.openCourseUnitInfoModal = $event => {
      $event.stopPropagation();
      return courseUnitInfoModal.showCourseUnit($ctrl.courseUnit.id, $ctrl.validatablePlan, undefined, getCourseUnitColorCategory());
    };
    function setVisibleCredits(courseUnit) {
      if (!$ctrl.validatablePlan || !$ctrl.validatablePlan.isCourseUnitAttained(courseUnit.id)) {
        $ctrl.visibleCredits = $ctrl.courseUnit.credits;
      } else {
        const attainedCredits = _.get($ctrl.validatablePlan.getCourseUnitAttainment(courseUnit.id), 'credits');
        $ctrl.visibleCredits = {
          min: attainedCredits,
          max: attainedCredits
        };
      }
      if ($ctrl.validatablePlan && $ctrl.validatablePlan.isSubstitute(courseUnit) && $ctrl.substitutionFor) {
        const creditsUsedForSubstitution = $ctrl.validatablePlan.getSubstitutionCredits($ctrl.substitutionFor, courseUnit);
        if (creditsUsedForSubstitution && $ctrl.visibleCredits.max !== creditsUsedForSubstitution) {
          $ctrl.visibleCredits = {
            min: creditsUsedForSubstitution,
            max: creditsUsedForSubstitution
          };
          $ctrl.isPartialCreditAmount = true;
        }
      }
    }
    function isAboutToExpire() {
      return $ctrl.courseUnitAttainment && validAttainmentFilterService.isAttainmentAboutToExpire($ctrl.courseUnitAttainment) && !validAttainmentFilterService.isAttached($ctrl.courseUnitAttainment, $ctrl.validatablePlan.getAllAttainments());
    }
    function isInvalidSelection() {
      return _.get($ctrl.courseUnitValidationResult, 'invalidSelection') === true;
    }
    function isInvalidAccordingToModuleContentApproval() {
      return _.get($ctrl.courseUnitValidationResult, 'invalidAccordingToModuleContentApproval') === true;
    }
    function hasModuleContentApproval() {
      return _.get($ctrl.courseUnitValidationResult, 'invalidAccordingToModuleContentApproval') !== undefined;
    }
    $ctrl.selectCompletionMethod = (completionMethod, assessmentItemSelections, gradeRaiseAttempt, cancelGradeRaiseAttempt) => {
      if (cancelGradeRaiseAttempt) {
        removeGradeRaiseAttempt();
      } else {
        handleCompletionMethodSelection(completionMethod, assessmentItemSelections, gradeRaiseAttempt);
      }
    };
    function handleCompletionMethodSelection(completionMethod, assessmentItemIds, gradeRaiseAttempt) {
      uiContext.planContext.selectCompletionMethodWithAssessmentItems($ctrl.courseUnitId, completionMethod, assessmentItemIds, gradeRaiseAttempt);
    }
    function removeGradeRaiseAttempt() {
      uiContext.planContext.removeGradeRaiseAttempt($ctrl.courseUnitId);
    }
  }
})();