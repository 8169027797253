import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
    CourseUnit,
    CourseUnitRealisation, Enrolment,
    EnrolmentCalculationConfigForPerson,
    OtmId, SisValidatorFn, StudyGroupSet, StudySubGroup,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment/moment';
import { Subject, takeUntil } from 'rxjs';
import { required } from 'sis-components/form/form-validators';

import { StudyGroupSetFormGroup, StudyGroupSetsFormGroup, StudySubGroupFormGroup } from './enrolment-wizard.component';

export interface Allocation {
    cancelled: boolean;
    hasSpace: boolean;
    enrolledAmount: number;
}

export enum StudyGroupSetSelectionType {
    MANDATORY = 'MANDATORY',
    RANGE = 'RANGE',
    MIN = 'MIN',
    NO_SELECTION = 'NO_SELECTION',
}

export interface StudyGroupSetInfo {
    studyGroupSet: StudyGroupSet;
    studySubGroupAllocations: Map<OtmId, Allocation>;
    availableStudySubGroupCount: number;
    selectionType: StudyGroupSetSelectionType;
    range?: number[];
}

export const getLanguageOptions = (courseUnit: CourseUnit) => {
    const languages = _.get(courseUnit, 'possibleAttainmentLanguages', []).slice();
    if (languages.indexOf('urn:code:language:fi') === -1) languages.push('urn:code:language:fi');
    if (languages.indexOf('urn:code:language:sv') === -1) languages.push('urn:code:language:sv');
    return languages;
};

export function getStudyGroupSetInfo(
    isConfirmedEnrolmentEdit: boolean, enrolment: Enrolment,
    courseUnitRealisation: CourseUnitRealisation,
    confirmedEnrolledCountsBySsgId: Map<OtmId, number>,
    enrolmentCalculationResult: EnrolmentCalculationConfigForPerson) {
    const studyGroupSetsInfo: Map<OtmId, StudyGroupSetInfo> = new Map();

    courseUnitRealisation.studyGroupSets.forEach(sgs => {
        const studySubGroupAllocations: Map<OtmId, Allocation> = new Map<OtmId, Allocation>();
        const selectableStudySubGroups = getSelectableStudySubGroups(sgs);
        const selectionType = getStudyGroupSetSelectionType(sgs, selectableStudySubGroups.length);
        const range = selectionType === StudyGroupSetSelectionType.RANGE ? getTargetStudySubGroupRange(sgs, selectableStudySubGroups.length) : null;

        sgs.studySubGroups.forEach(ssg => {
            studySubGroupAllocations.set(ssg.id, {
                cancelled: ssg.cancelled,
                hasSpace: !isStudySubGroupFull(ssg, courseUnitRealisation, confirmedEnrolledCountsBySsgId, enrolment, enrolmentCalculationResult, isConfirmedEnrolmentEdit),
                enrolledAmount: getEnrolledAmountCount(ssg.id, isConfirmedEnrolmentEdit, courseUnitRealisation.continuousEnrolment, confirmedEnrolledCountsBySsgId, enrolmentCalculationResult),
            });
        });

        studyGroupSetsInfo.set(sgs.localId, { studyGroupSet: sgs, studySubGroupAllocations, availableStudySubGroupCount: selectableStudySubGroups.length, range, selectionType });
    });
    return studyGroupSetsInfo;
}

export const createStudyGroupSetsFormGroup = (enrolment: Enrolment,
                                              isConfirmedEnrolmentEdit: boolean,
                                              courseUnitRealisation: CourseUnitRealisation,
                                              studyGroupSetsInfo: Map<OtmId, StudyGroupSetInfo>,
                                              destroyed$: Subject<void>, locale: string) => {
    const studyGroupSetsFormGroup: StudyGroupSetsFormGroup = {};
    courseUnitRealisation.studyGroupSets.forEach(sgs => {
        const studyGroupSetInfo = studyGroupSetsInfo.get(sgs.localId);
        checkForNewStudySubGroups(enrolment, sgs, studyGroupSetInfo.selectionType);
        const studySubGroupFormGroup = new FormGroup(initStudySubGroupsFormGroup(isConfirmedEnrolmentEdit, enrolment, sgs.studySubGroups, studyGroupSetInfo, destroyed$));
        const targetSubGroupAmount = initTargetStudySubGroupAmountFormControl(isConfirmedEnrolmentEdit, studyGroupSetInfo, sgs, enrolment, destroyed$, studySubGroupFormGroup);
        studySubGroupFormGroup.setValidators(subGroupRangeValidator(studyGroupSetInfo.selectionType, studyGroupSetInfo.availableStudySubGroupCount, isConfirmedEnrolmentEdit, targetSubGroupAmount, sgs));
        studySubGroupFormGroup.updateValueAndValidity();
        studyGroupSetsFormGroup[sgs.localId] = new FormGroup<StudyGroupSetFormGroup>({
            studySubGroups: studySubGroupFormGroup,
            targetStudySubGroupAmount: targetSubGroupAmount,
        });
        studyGroupSetsFormGroup[sgs.localId].setValidators(studyGroupSetErrorSummaryValidator(sgs, locale));
        studyGroupSetsFormGroup[sgs.localId].updateValueAndValidity();
    });
    return studyGroupSetsFormGroup;
};

const getTargetStudySubGroupRange = (studyGroupSet: StudyGroupSet, selectableStudySubGroupCount: number) => {
    const numbers: number[] = [];
    const min = studyGroupSet.subGroupRange.min;
    const max = studyGroupSet.subGroupRange.max ? studyGroupSet.subGroupRange.max : selectableStudySubGroupCount;

    for (let i: number = min; i <= max; i += 1) { numbers.push(i); }

    return numbers;
};

const checkForNewStudySubGroups = (enrolment: Enrolment, studyGroupSet: StudyGroupSet, studyGroupSetSelectionType: StudyGroupSetSelectionType) => {
    // Check if the realisation has new study subgroups which has not been included in students enrolment
    const newStudySubGroups = _.difference(studyGroupSet.studySubGroups.map(ssg => ssg.id), enrolment.studySubGroups.map(ssg => ssg.studySubGroupId));
    if (newStudySubGroups.length !== 0) {
        newStudySubGroups.forEach(id => {
            const studySubGroup = _.find(studyGroupSet.studySubGroups, { id });
            enrolment.studySubGroups.push({
                enrolmentStudySubGroupPriority: studyGroupSetSelectionType === StudyGroupSetSelectionType.MANDATORY ? 'PRIMARY' : 'NOT_SUITABLE',
                studySubGroupId: studySubGroup.id,
                isInCalendar: false,
            });
        });
    }
};

/* Common helpers */
const getSelectableStudySubGroups = (studyGroupSet: StudyGroupSet): StudySubGroup[] => studyGroupSet.studySubGroups.filter(ssg => !ssg.cancelled);

export const isLateEnrolment = (cur: CourseUnitRealisation) => {
    const now = moment();
    const { enrolmentPeriod, lateEnrolmentEnd } = cur;
    const enrolmentPeriodEnd = moment(enrolmentPeriod.endDateTime);
    return now.isAfter(enrolmentPeriodEnd) && lateEnrolmentEnd && now.isBefore(lateEnrolmentEnd);
};

const getStudyGroupSetSelectionType = (studyGroupSet: StudyGroupSet, selectableStudySubGroupCount: number) => {
    const min = studyGroupSet.subGroupRange.min;
    const max = studyGroupSet.subGroupRange.max;
    if (selectableStudySubGroupCount === min) return StudyGroupSetSelectionType.MANDATORY;
    if (min === 0 && max === 0) return StudyGroupSetSelectionType.NO_SELECTION;
    if (!max || min !== max) return StudyGroupSetSelectionType.RANGE;
    return StudyGroupSetSelectionType.MIN;
};

/* Form helpers */
const initTargetStudySubGroupAmountFormControl = (isConfirmedEnrolmentEdit: boolean,
                                                  studyGroupSetInfo: StudyGroupSetInfo,
                                                  studyGroupSet: StudyGroupSet,
                                                  enrolment: Enrolment, destroyed$: Subject<void>,
                                                  studySubGroupsFormGroup: FormGroup<StudySubGroupFormGroup>) => {
    if (!isConfirmedEnrolmentEdit && studyGroupSetInfo.selectionType === StudyGroupSetSelectionType.RANGE) {
        const enrolmentStudyGroupSet = _.find(enrolment.studyGroupSets, sgs => sgs.studyGroupSetId === studyGroupSet.localId);
        const control = new FormControl<number>(_.some(studyGroupSetInfo.range, val => val === enrolmentStudyGroupSet?.targetStudySubGroupAmount) ? enrolmentStudyGroupSet.targetStudySubGroupAmount : null, required());
        control.valueChanges.pipe(takeUntil(destroyed$)).subscribe(() => studySubGroupsFormGroup.updateValueAndValidity());
        return control;
    }
    return new FormControl<number>(null);
};

const initStudySubGroupsFormGroup = (
    isConfirmedEnrolmentEdit: boolean,
    enrolment: Enrolment,
    studySubGroups: StudySubGroup[],
    studyGroupSetInfo: StudyGroupSetInfo,
    destroyed$: Subject<void>,
) => {
    const studySubGroupsFormGroup: StudySubGroupFormGroup = {};
    if (isConfirmedEnrolmentEdit) {
        const confirmedStudySubGroupIds = enrolment.confirmedStudySubGroupIds || [];
        studySubGroups.forEach(ssg => {
            const allocation = studyGroupSetInfo.studySubGroupAllocations.get(ssg.id);
            const isConfirmed = _.includes(confirmedStudySubGroupIds, ssg.id);
            initStudySubGroupFormGroup(studySubGroupsFormGroup, studyGroupSetInfo.selectionType, allocation, ssg, isConfirmed, isConfirmed, destroyed$);
        });
    } else {
        const selectedAvailableStudySubGroups = enrolment.studySubGroups.filter(ssg => !!_.find(studySubGroups, { id: ssg.studySubGroupId }));
        selectedAvailableStudySubGroups.forEach(enrolmentSsg => initStudySubGroupFormGroup(
            studySubGroupsFormGroup,
            studyGroupSetInfo.selectionType,
            studyGroupSetInfo.studySubGroupAllocations.get(enrolmentSsg.studySubGroupId),
            _.find(studySubGroups, { id: enrolmentSsg.studySubGroupId }),
            enrolmentSsg.enrolmentStudySubGroupPriority !== 'NOT_SUITABLE' || studyGroupSetInfo.selectionType === StudyGroupSetSelectionType.MANDATORY,
            enrolmentSsg.enrolmentStudySubGroupPriority === 'PRIMARY' || studyGroupSetInfo.selectionType === StudyGroupSetSelectionType.MANDATORY,
            destroyed$));
    }

    return studySubGroupsFormGroup;
};

const initStudySubGroupFormGroup = (
    studySubGroupsFormGroup: StudySubGroupFormGroup,
    studyGroupSetSelectionType: StudyGroupSetSelectionType,
    allocation: Allocation, studySubGroup: StudySubGroup,
    isSelected: boolean, isPrimary: boolean,
    destroyed$: Subject<void>,
) => {
    const isNotSelectable = () => !allocation.hasSpace || studySubGroup.cancelled || studyGroupSetSelectionType === StudyGroupSetSelectionType.NO_SELECTION;
    const group = new FormGroup({
        isPrimary: new FormControl(isNotSelectable() ? false : isPrimary, required()),
        isSelected: new FormControl(isNotSelectable() ? false : isSelected, required()),
    });
    group.controls.isPrimary.valueChanges.pipe(takeUntil(destroyed$)).subscribe(() => {
        if (group.controls.isPrimary.value && !group.controls.isSelected.value) group.controls.isSelected.setValue(true);
    });
    group.controls.isSelected.valueChanges.pipe(takeUntil(destroyed$)).subscribe(() => {
        if (!group.controls.isSelected.value && group.controls.isPrimary.value) group.controls.isPrimary.setValue(false);
    });
    if (isNotSelectable() || studyGroupSetSelectionType === StudyGroupSetSelectionType.MANDATORY) group.disable({ onlySelf: true });
    studySubGroupsFormGroup[studySubGroup.id] = group;
};

/*  Validators */
function studyGroupSetErrorSummaryValidator(studyGroupSet: StudyGroupSet, locale: string): SisValidatorFn {
    return (formGroup: FormGroup<StudyGroupSetFormGroup>) => {
        if (formGroup.controls.studySubGroups.invalid || formGroup.controls.targetStudySubGroupAmount.invalid) {
            return createValidationError('errorSummary', 'ENROLMENTS.ENROLMENT_MODAL.ERROR.SUMMARY_STUDY_GROUPS', { content: studyGroupSet.name[locale] });
        }
    };
}

function subGroupRangeValidator(selectionType: StudyGroupSetSelectionType, availableStudySubGroups: number, isConfirmedEnrolmentEdit: boolean, targetStudySubGroupAmount: FormControl<number>, studyGroupSet: StudyGroupSet): SisValidatorFn {
    return (formGroup: FormGroup<StudySubGroupFormGroup>) => {
        if (selectionType === StudyGroupSetSelectionType.MIN || selectionType === StudyGroupSetSelectionType.RANGE) {
            let selected = 0;

            Object.keys(formGroup.controls).forEach(key => {
                if (formGroup.controls[key].controls.isSelected.value) selected += 1;
            });

            const errorMessage: ValidationErrors = selectionType === StudyGroupSetSelectionType.MIN ? isMinValid(selected, isConfirmedEnrolmentEdit, studyGroupSet) : isRangeValid(selected, availableStudySubGroups, isConfirmedEnrolmentEdit, targetStudySubGroupAmount, studyGroupSet);
            if (errorMessage) return errorMessage;
        }
    };
}

const isMinValid = (selected: number, isConfirmedEnrolmentEdit: boolean, studyGroupSet: StudyGroupSet) => {
    if (!isConfirmedEnrolmentEdit) {
        return selected >= studyGroupSet.subGroupRange.min ? undefined : (studyGroupSet.subGroupRange.min === 1 ?
            createValidationError('invalidSubGroupRange', 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.MIN_VALIDATION_ERROR_SINGLE', null) :
            createValidationError('invalidSubGroupRange', 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.MIN_VALIDATION_ERROR_MULTI', { count: studyGroupSet.subGroupRange.min }));
    }
    return studyGroupSet.subGroupRange.min === selected ? undefined : (studyGroupSet.subGroupRange.min === 1 ?
        createValidationError('invalidSubGroupRange', 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.SSG_EDIT_MIN_VALIDATION_SINGLE', null) :
        createValidationError('invalidSubGroupRange', 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.SSG_EDIT_MIN_VALIDATION_MULTIPLE', { count: studyGroupSet.subGroupRange.min }));
};

const isRangeValid = (selected: number, availableStudySubGroups: number, isConfirmedEnrolmentEdit: boolean, targetStudySubGroupAmount: FormControl<number>, studyGroupSet: StudyGroupSet) => {
    if (!isConfirmedEnrolmentEdit) {
        return targetStudySubGroupAmount.value !== null ?
            (targetStudySubGroupAmount.value <= selected ? null : createValidationError('invalidSubGroupRange', 'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.RANGE_VALIDATION_ERROR', null)) :
            null;
    }
    const max = studyGroupSet.subGroupRange.max ? studyGroupSet.subGroupRange.max : availableStudySubGroups;
    return selected >= studyGroupSet.subGroupRange.min && selected <= max ?
        undefined :
        createValidationError(
            'invalidSubGroupRange',
            'ENROLMENTS.ENROLMENT_MODAL.STUDY_GROUP.SSG_EDIT_VALIDATION_RANGE',
            {
                min: studyGroupSet.subGroupRange.min,
                max: studyGroupSet.subGroupRange.max ? studyGroupSet.subGroupRange.max : availableStudySubGroups,
            });
};

const createValidationError = (key: string, translationKey: string, translationParams: { [paramKey: string]: any }): ValidationErrors => ({
    [key]: {
        translationKey,
        translationParams,
    },
});

/* Attendee logic */
const isStudySubGroupFull = (
    studySubGroup: StudySubGroup,
    courseUnitRealisation: CourseUnitRealisation,
    confirmedEnrolledCountsBySsgId: Map<OtmId, number>,
    enrolment: Enrolment,
    enrolmentCalculationResult: EnrolmentCalculationConfigForPerson,
    isConfirmedEnrolmentEdit: boolean) => (courseUnitRealisation.continuousEnrolment || isLateEnrolment(courseUnitRealisation) || isConfirmedEnrolmentEdit) &&
    !enrolment.confirmedStudySubGroupIds.includes(studySubGroup.id) &&
    getEnrolledAmountCount(
        studySubGroup.id,
        isConfirmedEnrolmentEdit,
        courseUnitRealisation.continuousEnrolment,
        confirmedEnrolledCountsBySsgId,
        enrolmentCalculationResult) === studySubGroup.size;

const getEnrolledAmountCount = (
    ssgId: OtmId,
    isConfirmedEnrolmentEdit: boolean,
    isContinuousEnrolment: boolean,
    confirmedEnrolledCountsBySsgId: Map<OtmId, number>,
    enrolmentCalculationResult: EnrolmentCalculationConfigForPerson) => {
    if (isConfirmedEnrolmentEdit) return confirmedEnrolledCountsBySsgId ? confirmedEnrolledCountsBySsgId.get(ssgId) : null;
    if (isContinuousEnrolment) return getEnrolledCountForSsg(ssgId, enrolmentCalculationResult);
    return getTentativeCountForSsg(ssgId, enrolmentCalculationResult) + getEnrolledCountForSsg(ssgId, enrolmentCalculationResult);
};

const getEnrolledCountForSsg = (ssgId: OtmId, enrolmentCalculationResult: EnrolmentCalculationConfigForPerson) => _.get(getAllocationCountsForSsg(ssgId, enrolmentCalculationResult), 'enrolledCount', 0);

const getTentativeCountForSsg = (ssgId: OtmId, enrolmentCalculationResult: EnrolmentCalculationConfigForPerson) => _.get(getAllocationCountsForSsg(ssgId, enrolmentCalculationResult), 'tentativeCount', 0);

const getAllocationCountsForSsg = (ssgId: OtmId, enrolmentCalculationResult: EnrolmentCalculationConfigForPerson) =>
    (enrolmentCalculationResult?.enrolmentAllocationCounts?.studySubGroupAllocationCounts || [])
        .find(allocationCounts => allocationCounts.studySubGroupId === ssgId);
