import * as _ from 'lodash-es';
import moment, { unitOfTime } from 'moment';

import {
    ResponsiblePersonValidityPeriodEndDates,
} from '../types/baseTypes.js';
import {
    LocalDateString,
    LocalDateTimeString,
    LocalTimeString,
} from '../types/commonTypes.js';
import {
    LocalDateRange, LocalDateTimeRange,
    OrganisationRoleShare,
    PersonWithCourseUnitRealisationResponsibilityInfoType,
    PersonWithEducationResponsibilityInfoType,
    PersonWithModuleResponsibilityInfoType,
} from '../types/generated/common-backend.js';
import { PersonWithGroupResponsibilityInfoType } from '../types/generated/ori.js';

import {
    ISO_LOCAL_DATE_FORMAT,
    ISO_LOCAL_DATE_TIME_FORMAT,
    ISO_LOCAL_TIME_FORMAT,
    MOMENT_DATE_DISPLAY_FORMAT,
    MOMENT_TIME_DISPLAY_FORMAT,
} from './dateTimeConstants.js';

function rangesOverlapNullsAsInfinity(
    aStart: moment.Moment | string, aEnd: moment.Moment | string, bStart: moment.Moment | string, bEnd: moment.Moment | string,
    precision: unitOfTime.StartOf, sameOk: boolean = false,
): boolean {
    const aStartMoment = createMoment(aStart, { allowInvalid: true });
    const aEndMoment = createMoment(aEnd, { allowInvalid: true });
    const bStartMoment = createMoment(bStart, { allowInvalid: true });
    const bEndMoment = createMoment(bEnd, { allowInvalid: true });
    // Adapted from https://stackoverflow.com/a/325964/305436 for end date exclusivity
    return (_.isNil(aStartMoment) || _.isNil(bEndMoment) || (sameOk ? aStartMoment.isSameOrBefore(bEndMoment, precision) : aStartMoment.isBefore(bEndMoment, precision))) &&
        (_.isNil(aEndMoment) || _.isNil(bStartMoment) || (sameOk ? aEndMoment.isSameOrAfter(bStartMoment, precision) : aEndMoment.isAfter(bStartMoment, precision)));
}

type RoleWithValidity =
    PersonWithEducationResponsibilityInfoType
    | PersonWithGroupResponsibilityInfoType
    | PersonWithModuleResponsibilityInfoType
    | PersonWithCourseUnitRealisationResponsibilityInfoType
    | OrganisationRoleShare;

export const dateUtils = {
    /**
     * Returns true, if the date range [aStart, aEnd) overlaps with the date range [bStart, bEnd)
     * (start dates are inclusive, end dates are exclusive). All parameters are mandatory; if any
     * parameter is omitted, false is returned. All date strings must be ISO 8601 formatted.
     * Has an optional boolean parameter sameOk, which adds option to accept dates that are the same.
     */
    dateRangesOverlap(aStart: moment.Moment | string, aEnd: moment.Moment | string,
                      bStart: moment.Moment | string, bEnd: moment.Moment | string, sameOk: boolean = false): boolean {
        if (_.isNil(aStart) || _.isNil(aEnd) || _.isNil(bStart) || _.isNil(bEnd)) {
            return false;
        }
        return rangesOverlapNullsAsInfinity(aStart, aEnd, bStart, bEnd, 'day' as unitOfTime.StartOf, sameOk);
    },
    /**
     * Returns true, if the date range [aStart, aEnd) overlaps with the date range [bStart, bEnd)
     * (start dates are inclusive, end dates are exclusive). Omitted or null arguments will be regarded as
     * infinity/-infinity. All non null date strings must be ISO 8601 formatted.
     */
    dateTimeRangesOverlapNullsAsInfinity(
        aStart: moment.Moment | string,
        aEnd: moment.Moment | string,
        bStart: moment.Moment | string,
        bEnd: moment.Moment | string,
        precision: unitOfTime.StartOf = 'second',
    ): boolean {
        return rangesOverlapNullsAsInfinity(aStart, aEnd, bStart, bEnd, precision);
    },

    /**
     * End date is always excluded in our date ranges, show we have to subtract one day from the end date,
     * when we want to show the end date in the UI.
     */
    dateRangeEndDateForUI(dateRange: LocalDateRange): moment.Moment | undefined {
        return dateUtils.createMoment(dateRange?.endDate)?.subtract(1, 'day');
    },
    /**
     * Returns true, if the date range [startDate, endDate) contains compareDate (startDate is
     * compared inclusively and endDate exclusively). Either startDate or endDate can be omitted,
     * in which case the function returns true if compareDate is before endDate or after/equal to
     * startDate (respectively). If both startDate and endDate are omitted this function will return
     * true. All date strings must be ISO 8601 formatted.
     */
    dateRangeContains(compareDate: moment.Moment | string, startDate: moment.Moment | string,
                      endDate: moment.Moment | string): boolean {
        const compareDateMoment = createMoment(compareDate, { allowInvalid: true });
        const startDateMoment = createMoment(startDate, { allowInvalid: true });
        const endDateMoment = createMoment(endDate, { allowInvalid: true });

        if (_.isNil(compareDateMoment)) {
            return false;
        }
        if (!_.isNil(startDateMoment) && !_.isNil(endDateMoment)) {
            return compareDateMoment.isBetween(startDateMoment, endDateMoment, 'day', '[)');
        }
        if (!_.isNil(startDateMoment)) {
            return compareDateMoment.isSameOrAfter(startDateMoment, 'day');
        }
        if (!_.isNil(endDateMoment)) {
            return compareDateMoment.isBefore(endDateMoment, 'day');
        }

        return true;
    },
    /**
     * Returns true, if the date range [startDate, endDate) contains compareDateRange [startDate, endDate)
     * (startDates are compared inclusively and endDates exclusively). Either startDate or endDate can be omitted,
     * in which case the function returns true if compareDateRange is before endDate or after/equal to
     * startDate (respectively). If both startDate and endDate are omitted this function will return
     * true. All date strings must be ISO 8601 formatted.
     */
    dateRangeContainsRange(compareStartDate: moment.Moment | string, compareEndDate: moment.Moment | string,
                           startDate: moment.Moment | string, endDate: moment.Moment | string): boolean {
        const compareStartDateMoment = createMoment(compareStartDate, { allowInvalid: true });
        const compareEndDateMoment = createMoment(compareEndDate, { allowInvalid: true });
        const startDateMoment = createMoment(startDate, { allowInvalid: true });
        const endDateMoment = createMoment(endDate, { allowInvalid: true });

        if (!_.isNil(startDateMoment) && !_.isNil(endDateMoment)) {
            if (_.isNil(compareStartDateMoment) || _.isNil(compareEndDateMoment)) {
                return false;
            }
            // because both endDates are compared exclusively, we use []-inclusivity instead of [)-inclusivity, when checking single dates
            return compareStartDateMoment.isBetween(startDateMoment, endDateMoment, 'day', '[]')
                    &&
                    compareEndDateMoment.isBetween(startDateMoment, endDateMoment, 'day', '[]');
        }
        if (!_.isNil(startDateMoment)) { // [startDate,  null) < [compareStartDate, compareEndDate)
            if (_.isNil(compareStartDateMoment)) {
                return false;
            }
            return compareStartDateMoment.isSameOrAfter(startDateMoment, 'day');
        }
        if (!_.isNil(endDateMoment)) { // [compareStartDate, compareEndDate) < [null, endDate)
            if (_.isNil(compareEndDateMoment)) {
                return false;
            }
            // because both endDates are compared exclusively, we use isSameOrBefore instead of isBefore
            return compareEndDateMoment.isSameOrBefore(endDateMoment, 'day');
        }

        return true;
    },
    /**
     * This method should be identical to dateRangeContains if used with LocalDateRange.
     * Returns true if date is contained in range (half open [start, end))
     * Note that this return false is compareDate or range is falsy.
     */
    rangeContains(compareDate: moment.Moment | string, range: LocalDateRange | LocalDateTimeRange): boolean {
        const compareDateMoment = createMoment(compareDate, { allowInvalid: false });
        if (someIsNull(range, compareDateMoment)) {
            return false;
        }
        return !this.isRangeBefore(compareDate, range) && !this.isRangeAfter(compareDate, range);
    },

    /**
     * Returns true if range starts after compareDate
     * Note that this return false is compareDate or range is falsy.
     */
    isRangeAfter(compareDate: moment.Moment | string, range: LocalDateRange | LocalDateTimeRange): boolean {
        const compareDateMoment = createMoment(compareDate, { allowInvalid: false });
        if (someIsNull(range, compareDateMoment)) {
            return false;
        }
        const start = getStart(range);
        return !!start && (!!compareDateMoment && compareDateMoment.isBefore(start));
    },

    /**
     * Returns true if range ends before compareDate (range end date (time) is exclusive).
     * Note that this return false is compareDate or range is falsy.
     */
    isRangeBefore(compareDate: moment.Moment | string, range: LocalDateRange | LocalDateTimeRange): boolean {
        const compareDateMoment = createMoment(compareDate, { allowInvalid: false });
        if (someIsNull(range, compareDateMoment)) {
            return false;
        }
        const end = getEnd(range);
        return !!end && end.isSameOrBefore(compareDateMoment);
    },

    /**
     * Is range started on compareDate.
     * Unlike !isRangeAfter(compareDate, range) this will return false for null range.
     */
    isRangeStarted(compareDate: moment.Moment | string, range: LocalDateRange | LocalDateTimeRange): boolean {
        return this.rangeContains(compareDate, range) || this.isRangeBefore(compareDate, range);
    },

    /**
     * Returns true if the given role is valid on the given date. If the validity period is null, the role is always valid.
     */
    isRoleValid(role: RoleWithValidity, date: moment.Moment | string): boolean {
        return !!role && !!date && (!role.validityPeriod || this.rangeContains(date, role.validityPeriod));
    },

    /**
     * Returns true, if the first date provided is before the second date provided
     * @param compareDate the date that has to be before the other date
     * @param afterDate the date after the first date
     */
    dateIsSameOrAfter(compareDate: moment.Moment | string, afterDate: moment.Moment | string): boolean {
        const compareDateMoment = createMoment(compareDate);
        const afterDateMoment = createMoment(afterDate);
        if (_.isNil(compareDateMoment) || _.isNil(afterDateMoment)) {
            return false;
        }
        return afterDateMoment.isSameOrAfter(compareDateMoment, 'day');
    },

    /**
     * Format the given value using the given format pattern, but only if the value is a valid input for moment.js.
     *
     * @param value The value to format
     * @param format A moment.js format string (see https://momentjs.com/docs/#/displaying/format/)
     * @param defaultValue The value to return if the input can't be parsed to a valid moment instance
     * @return A formatted string if the input was valid, or an empty string otherwise
     */
    formatDateIfValid(value: string | Date | moment.Moment | null, format: string, defaultValue = ''): string {
        if (!value || !format) {
            return defaultValue;
        }

        return createMoment(value)?.format(format) ?? defaultValue;
    },

    /**
     * Parse separate date and time strings into the local date time string used in the backend ('YYYY-MM-DDTHH:mm').
     *
     * @param date LocalDateString
     * @param startTime LocalTimeString
     * @return A formatted string if the input was valid, or otherwise ''
     */
    getLocalDateTimeString(date: LocalDateString, startTime: LocalTimeString): LocalDateTimeString {
        const momentDateTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH.mm');
        return this.formatDateIfValid(momentDateTime, ISO_LOCAL_DATE_TIME_FORMAT);
    },

    /**
     * Parses localDateTimeStrings in ISO format to Display format.
     * As an example, 2020-02-02T22:00 -> date: 02.02.2020 time: 22.00
     * @param dateTimeString string to parse
     */
    convertIsoLocalDateTimeToDisplayFormat(dateTimeString: LocalDateTimeString) {
        return dateUtils.extractDateAndTime(dateTimeString, MOMENT_DATE_DISPLAY_FORMAT, MOMENT_TIME_DISPLAY_FORMAT);
    },

    /**
     * Parses localDateString in ISO format to Display format.
     * As an exampĺe, 2020-02-02 -> 02.02.2020
     * @param dateString the string to parse
     */
    convertIsoLocalDateToDisplayFormat(dateString: LocalDateString) {
        return dateString ?
            moment(dateString, ISO_LOCAL_DATE_FORMAT, true).format(MOMENT_DATE_DISPLAY_FORMAT) :
            null;
    },

    /**
     * Parses time from string in ISO format ('HH:mm') to Display format (H.mm).
     * As an exampĺe, 01:03.45 -> 1.03
     * @param timeString the string to parse
     */
    convertIsoLocalTimeToDisplayFormat(timeString: LocalTimeString) {
        return moment(timeString, ISO_LOCAL_TIME_FORMAT, true).format(MOMENT_TIME_DISPLAY_FORMAT);
    },

    /**
     * Converts a localized date string (i.e. Finnish date format) to an ISO-8601 date string.
     *
     * @param value The localized date to convert
     * @param defaultValue The value to return if the input can't be parsed
     */
    convertLocalizedDateToIsoLocalDate(value: string, defaultValue = ''): LocalDateString {
        const parsedDate = this.createMoment(value, { format: MOMENT_DATE_DISPLAY_FORMAT });
        return this.formatDateIfValid(parsedDate, ISO_LOCAL_DATE_FORMAT, defaultValue);
    },

    /**
     * Converts a JavaScript Date object to an ISO-8601 date string
     */
    convertDateToIsoLocalDate(date: Date): string {
        return this.formatDateIfValid(moment(date), ISO_LOCAL_DATE_FORMAT);
    },

    /**
     *
     * Parses the given `LocalDateTimeString` and splits it to separate date and time parts.
     *
     * @param dateTimeString Any valid ISO-8601 date or datetime string
     * @param dateFormat The format used for the returned date part (defaults to ISO-8601 date, e.g. '2020-01-01')
     * @param timeFormat The format used for the returned time part (defaults to ISO-8601 time with minute precision, e.g. '12:00')
     */
    extractDateAndTime(dateTimeString: LocalDateTimeString,
                       dateFormat = ISO_LOCAL_DATE_FORMAT,
                       timeFormat = ISO_LOCAL_TIME_FORMAT): { date: string | null, time: string | null } {
        const dateTime = moment(dateTimeString, moment.ISO_8601);
        return dateTime.isValid() ? { date: dateTime.format(dateFormat), time: dateTime.format(timeFormat) } : { date: null, time: null };
    },

    /**
     * Returns true if given `LocalDateString` or `LocalDateTimeString` has a date (compares year, month and day) that is in the future.
     * @param localDate Any valid ISO-8601 date or datetime string
     */
    dateInFuture(localDate: LocalDateString | LocalDateTimeString): boolean {
        return createMoment(localDate)?.isAfter(moment.now(), 'day') ?? false;
    },

    /**
     * Returns true if given `LocalDateString` or `LocalDateTimeString` has a date (compares year, month and day) that is in the past.
     * @param localDate Any valid ISO-8601 date or datetime string
     */
    dateInPast(localDate: LocalDateString | LocalDateTimeString): boolean {
        return createMoment(localDate)?.isBefore(moment.now(), 'day') ?? false;
    },

    /**
     * Compares the date (year, month and day) of two `LocalDateString`'s or `LocalDateTimeString`'s and returns true if they are the same.
     * @param firstDate Any valid ISO-8601 date or datetime string
     * @param secondDate Any valid ISO-8601 date or datetime string
     */
    datesEqual(firstDate: LocalDateString | LocalDateTimeString, secondDate: LocalDateString | LocalDateTimeString): boolean {
        return createMoment(firstDate)?.isSame(secondDate, 'day') ?? false;
    },

    createMoment,

    /**
     * Returns max date/dateTime as dateTime ignoring nulls and dates counted at midnight
     */
    maxDateTime(...values: (LocalDateString | LocalDateTimeString)[]): LocalDateTimeString {
        return moment.max(values.filter(Boolean).map(dt => moment(dt))).format(ISO_LOCAL_DATE_TIME_FORMAT);
    },

    /**
     * Returns min date/dateTime as dateTime ignoring nulls and dates counted at midnight
     */
    minDateTime(...values: (LocalDateString | LocalDateTimeString)[]): LocalDateTimeString {
        return moment.min(values.filter(Boolean).map(dt => moment(dt))).format(ISO_LOCAL_DATE_TIME_FORMAT);
    },

    /**
     * Compares two date ranges, primarily by end, secondarily by start.
     * Null end is considered later than a not-null end.
     * Null start is considered earlier than a not-null start.
     */
    compareDateRanges(range1: LocalDateRange | LocalDateTimeRange,
                      range2: LocalDateRange | LocalDateTimeRange): number {
        const range1Start = range1 ? getStart(range1) : null;
        const range1End = range1 ? getEnd(range1) : null;
        const range2Start = range2 ? getStart(range2) : null;
        const range2End = range2 ? getEnd(range2) : null;

        const r1 = compareDates(range1End, range2End);
        if (r1 !== 0) {
            return r1;
        }
        if (!range1Start) {
            return !range2Start ? 0 : -1;
        }
        if (!range2Start) {
            return 1;
        }
        return compareDates(range1Start, range2Start);
    },

    addOneDayToDate(date?: LocalDateString): LocalDateString | undefined {
        return date ? moment(date).add(1, 'days').format(ISO_LOCAL_DATE_FORMAT) : undefined;
    },

    /**
    * Adds one day to the end date of each responsible person type. Temporarily located here, will be moved to a
    * common wizard class once such exists.
    */
    addOneDayToExistingDates(endDates: ResponsiblePersonValidityPeriodEndDates): ResponsiblePersonValidityPeriodEndDates {
        return {
            responsibleTeacherRoleEndDate: this.addOneDayToDate(endDates.responsibleTeacherRoleEndDate),
            teacherRoleEndDate: this.addOneDayToDate(endDates.teacherRoleEndDate),
            adminRoleEndDate: this.addOneDayToDate(endDates.adminRoleEndDate),
            contactInfoRoleEndDate: this.addOneDayToDate(endDates.contactInfoRoleEndDate),
        };
    },
};

export interface MomentCreationOptions {
    /**
     * If true, the returned moment can be invalid. Useful e.g. when doing date comparisons (e.g. isAfter() or
     * isBefore() with an invalid moment always return false). If false, returns null if the input can't be parsed
     * into a valid moment instance.
     */
    allowInvalid?: boolean;
    /**
     * A moment format string used to parse the date (strictly). Only relevant if the input is a string. If omitted,
     * defaults to strict ISO-8601 parsing.
     */
    format?: string;
}

/**
 * Creates a moment instance from the given input and returns it. If the input is null or undefined, returns null.
 *
 * Default behavior (if no `opts` are defined):
 * - If the input is a string, it must be a valid ISO-8601 date or date time string
 * - If the input can't be parsed to a valid moment instance, returns null
 *
 * @param date The input for the moment creation
 * @param opts Parsing options (optional)
 */
function createMoment(date: moment.Moment | Date | string, opts?: MomentCreationOptions): moment.Moment | null {
    if (_.isNil(date)) {
        return null;
    }

    let parsed: moment.Moment;
    if (typeof date === 'string') {
        parsed = opts?.format ? moment(date, opts.format, true) : moment(date, moment.ISO_8601);
    } else {
        parsed = moment(date);
    }
    return (opts?.allowInvalid || parsed?.isValid()) ? parsed : null;
}

function getStart(range: LocalDateRange | LocalDateTimeRange): moment.Moment | null {
    if ((range as LocalDateRange).startDate) {
        return createMoment((range as LocalDateRange).startDate, { allowInvalid: true });
    }
    if ((range as LocalDateTimeRange).startDateTime) {
        return createMoment((range as LocalDateTimeRange).startDateTime, { allowInvalid: true });
    }
    return null;
}

function getEnd(range: LocalDateRange | LocalDateTimeRange): moment.Moment | null {
    if ((range as LocalDateRange).endDate) {
        return createMoment((range as LocalDateRange).endDate, { allowInvalid: true });
    }
    if ((range as LocalDateTimeRange).endDateTime) {
        return createMoment((range as LocalDateTimeRange).endDateTime, { allowInvalid: true });
    }
    return null;
}

function someIsNull(dateRange: any, compareDate: any): boolean {
    return !dateRange || !compareDate;
}

function compareDates(date1: any, date2: any): number {

    // Null date is considered after nonnull
    if (!date1) {
        return !date2 ? 0 : 1;
    }
    if (!date2) {
        return -1;
    }
    if (date1?.isAfter(date2)) {
        return 1;
    }
    if (date1?.isBefore(date2)) {
        return -1;
    }
    return 0;
}
