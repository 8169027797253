<ng-container *transloco="let t">
  <ng-container *ngIf="selections$ | async as selections">
    <sis-combobox
      [placeholder]="t('SEARCH.PLACEHOLDER_SEARCH_OR_SELECT')"
      [selected]="selections"
      [options]="options"
      [label]="label"
      (optionChange)="onSelectionChange($event)">
    </sis-combobox>
  </ng-container>
</ng-container>
