import { RangeValidationResultState } from '../../../types/baseTypes.js';
import { Range } from '../../model/range.js';

import { RangeValidationResult } from './rangeValidationResult.js';

export class RangeValidation {

    static validateRange(referenceRange: Range, inputRange: Range): RangeValidationResult {
        const refMin = referenceRange.min;
        const refMax = referenceRange.comparableMax();
        const inputMin = inputRange.min;
        const inputMax = inputRange.comparableMax();

        // Nothing selected or only implicit selections
        if (refMin === 0 && inputMin === 0 && inputMin < inputMax) {
            return new RangeValidationResult(
                RangeValidationResultState.IMPLICIT,
                0,
                referenceRange.hasMax() ? refMax : null,
            );
        }

        // More is required
        if (inputMax < refMin) {
            return new RangeValidationResult(
                RangeValidationResultState.MORE_REQUIRED,
                refMin - inputMax,
                referenceRange.hasMax() ? refMax - inputMin : null,
            );
        }

        // Too much!
        if (inputMin > refMax) {
            return new RangeValidationResult(
                RangeValidationResultState.LESS_REQUIRED,
                0,
                refMax - inputMin,
            );
        }

        if (inputMin < refMin) {
            return new RangeValidationResult(
                RangeValidationResultState.IMPLICIT_OK,
                0,
                referenceRange.hasMax() ? refMax - inputMin : null,
            );
        }

        // OK
        return new RangeValidationResult(
            RangeValidationResultState.OK,
            0,
            referenceRange.hasMax() ? refMax - inputMin : null,
        );
    }

}
