import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import angular from 'angular';
import { DATEPICKER_FORMAT, ISO_LOCAL_DATE_FORMAT, MOMENT_DATE_DISPLAY_FORMAT } from 'common-typescript/constants';
import { LocalDateString, LocalDateTimeString } from 'common-typescript/types';
import * as _ from 'lodash-es';
import moment from 'moment';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { DateAdapter } from '../../date-adapter/date-adapter';

/** The validators supported by this component. The events emitted by 'validate' are from this array. */
export const validators = ['required', 'pattern', 'minDate', 'maxDate'];

/**
 * A "hybrid" local date editor using the NG Bootstrap datepicker. Can be used in both AngularJS and Angular forms, although
 * usage in Angular forms is not recommended, as there is no direct support for Angular's form control API. Mainly intended
 * to provide backward compatibility for old AngularJS forms.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-legacy-local-date-editor',
    templateUrl: './legacy-local-date-editor.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LegacyLocalDateEditorComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis.date.legacyLocalDateEditor',
        directiveName: 'sisLegacyLocalDateEditor',
    };

    @Input() disabled?: boolean;
    @Input() label?: string;
    @Input() model: LocalDateString | LocalDateTimeString;
    @Output() modelChange = new EventEmitter<LocalDateString | LocalDateTimeString>();
    /** Emits an event when the date value changes. If the date is invalid, contains the name of the failed validation, or null otherwise */
    @Output() validate = new EventEmitter<string>();
    // min and max dates are validated as closed range [minDate, maxDate],
    // When input is min 2018-08-01 & max 2018-08-31, then all dates in august of 2018 are valid and no
    // other dates. Should probably be half open range [minDate, maxDate) because that is our default format
    // of ranges, but refactoring all usages so close to release is a risk.
    @Input() maxDate?: LocalDateString;
    @Input() minDate?: LocalDateString;
    /** A date string that indicates where to open the calendar view */
    @Input() startDate?: LocalDateString;
    @Input() name?: string;
    @Input() required?: boolean;

    /**
     * The $scope instance of the containing AngularJS component. Only necessary when this component is used in an AngularJS form.
     * Without this the AngularJS change detection will not pick up the changes made in this component.
     */
    @Input() scope: angular.IScope;

    dateFormat = DATEPICKER_FORMAT;
    invalid: boolean;
    ngbModel: LocalDateString = '';
    maxNgbDate: NgbDateStruct;
    minNgbDate: NgbDateStruct;
    startNgbDate: NgbDateStruct;

    /**
     * Holds the time part of the model input in case it is a date time string. The old localDateEditor supported editing only
     * the date part of a date time string, and this provides backwards compatibility for the forms depending on that feature.
     */
    time = '';

    readonly todayString = moment().format('D.M.');

    constructor(private dateAdapter: DateAdapter) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.model) {
            // Only update ngbModel if the new model value is valid, or if the previous ngbModel value was valid.
            // This is necessary to make the two-way binding in AngularJS forms to work in a somewhat coherent way.
            // If the user inputs an invalid value, ngbModel is set to a string (the raw user input), but modelChange
            // emits undefined. Due to the nature of AngularJS two-way bindings, that same undefined comes back to this
            // component via the model input. If we overwrite the old ngbModel with the received value, the input field
            // in the UI is cleared, which is not what we want. If we would ignore all nil model input values, it would
            // be impossible to clear the datepicker programmatically by the form. The current approach tries to achieve
            // a compromise by assuming a nil input while the previous value is invalid is a result of the nil value
            // emitted by modelChange coming back from the parent, and assuming that a nil input while the previous value
            // is valid to be an intentional clearing of the date made by the form.
            const newModelValue = changes.model.currentValue;
            if (!_.isNil(newModelValue) || !this.invalid) {
                const parsed = moment(newModelValue, moment.ISO_8601);
                if (parsed.isValid()) {
                    this.ngbModel = parsed.format(MOMENT_DATE_DISPLAY_FORMAT);
                    if (newModelValue.includes('T')) {
                        this.time = newModelValue.substring(newModelValue.indexOf('T'));
                    }
                } else {
                    this.ngbModel = null;
                }
            }

            if (!changes.model.isFirstChange()) {
                this.updateValidity(this.ngbModel);
            }
        }
        if (changes.minDate) {
            this.minNgbDate = this.dateAdapter.fromModel(changes.minDate.currentValue);
        }
        if (changes.maxDate) {
            this.maxNgbDate = this.dateAdapter.fromModel(changes.maxDate.currentValue);
        }
        if (changes.startDate) {
            this.startNgbDate = this.dateAdapter.fromModel(changes.startDate.currentValue);
        }
    }

    onDateValueChange(date: string): void {
        this.ngbModel = date;
        this.updateValidity(date);
        let modelDate: LocalDateString;
        const parsedDate = moment(date, MOMENT_DATE_DISPLAY_FORMAT, true);
        if (parsedDate.isValid()) {
            modelDate = parsedDate.format(ISO_LOCAL_DATE_FORMAT) + this.time;
        }

        if (_.isNil(this.scope)) {
            this.modelChange.emit(modelDate);
        } else {
            this.scope.$apply(() => this.modelChange.emit(modelDate));
        }
    }

    private updateValidity(date: string): void {
        if (this.disabled) {
            return;
        }

        let error: string = null;
        const parsedDate = moment(date, MOMENT_DATE_DISPLAY_FORMAT, true);
        if (_.isEmpty(date)) {
            error = this.required ? 'required' : null;
        } else if (!parsedDate.isValid()) {
            error = 'pattern';
        } else if (this.minDate && parsedDate.isBefore(this.minDate)) {
            error = 'minDate';
        } else if (this.maxDate && parsedDate.isAfter(this.maxDate)) {
            error = 'maxDate';
        }

        this.invalid = !_.isNil(error);
        if (_.isNil(this.scope)) {
            this.validate.emit(error);
        } else {
            // The validation is run inside a digest loop, so need to use setTimeout to prevent "$digest already in progress" errors
            setTimeout(() => this.scope.$apply(() => this.validate.emit(error)));
        }
    }

    selectToday(): void {
        this.onDateValueChange(moment().format(MOMENT_DATE_DISPLAY_FORMAT));
    }
}
