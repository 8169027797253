import { inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CompositeRule, CourseUnit, EntityWithRule, Module, OtmId, Plan, StudyRight } from 'common-typescript/types';
import * as _ from 'lodash-es';

import { COMMON_PLAN_SELECTION_SERVICE, COMMON_PLAN_SERVICE, RULE_SERVICE } from '../ajs-upgraded-modules';

/**
 * A service that will replace the AngularJS service `commonPlanSelectionService`. This service will contain all selection
 * logic related to plan selections ( RawPlanEditService will handle free edit selections).
 */
@Injectable({
    providedIn: 'root',
})
export class PlanSelectionService {

    commonPlanService = inject(COMMON_PLAN_SERVICE);
    ruleService = inject(RULE_SERVICE);
    commonPlanSelectionService = inject(COMMON_PLAN_SELECTION_SERVICE);

    /**
     * Activates a rule group and makes automatic selections for the activated rule group.
     *
     * @param activatedRule The rule to be activated.
     * @param parentModule The parent module of the activated rule.
     * @param rawPlan Current plan.
     * @param validatablePlan Current validated plan.
     * @param studyRight The study right of the plan.
     */
    activateRuleGroup(activatedRule: CompositeRule, parentModule: EntityWithRule, rawPlan: Plan, validatablePlan: ValidatablePlan, studyRight: StudyRight): Promise<Plan | null> {
        if (!this.doesParentModuleAcceptSelections(parentModule.id, validatablePlan)) {
            return Promise.resolve(null);
        }
        const rulePath = this.ruleService.getRulePathFromRootToSubRule(parentModule.rule, activatedRule);

        // Deactivates rules that are not part of the activated rule path if the activated rule is a select one rule.
        _.forEach(rulePath, (rule) => {
            if (rule.type === 'CompositeRule' && this.ruleService.isSelectOneRule(rule)) {
                const rulesToBeDeactivated = _.filter(rule.rules, childRule => !_.includes(rulePath, childRule));
                _.forEach(rulesToBeDeactivated, (ruleToBeDeactivated) => {
                    this.commonPlanSelectionService.deActivateRule(parentModule, parentModule.rule, ruleToBeDeactivated, rawPlan, validatablePlan);
                });
            }
        });
        let courseUnitSelectionsRequiringAutomaticSelections: CourseUnit[] = [];
        let moduleSelectionsRequiringAutomaticSelections: Module[] = [];
        return this.commonPlanService.getValidatablePlan(rawPlan, false, true).then((newValidatablePlan: ValidatablePlan) => {
            const updatedValidatablePlan = newValidatablePlan;
            let courseUnitGroupIdsToBeSelected: OtmId[] = [];
            let moduleGroupIdsToBeSelected: OtmId[] = [];
            _.forEach(rulePath, (rule) => {
                if (rule.type === 'CompositeRule' && this.ruleService.isSelectAllRule(rule)) {
                    _.forEach(rule.rules, (subRule) => {
                        if (!_.includes(rulePath, subRule)) {
                            const studiesToBeSelected = this.commonPlanSelectionService.getGroupIdsToBeSelected(subRule);
                            courseUnitGroupIdsToBeSelected = _.concat(courseUnitGroupIdsToBeSelected, studiesToBeSelected.courseUnitGroupIds);
                            moduleGroupIdsToBeSelected = _.concat(moduleGroupIdsToBeSelected, studiesToBeSelected.moduleGroupIds);
                        }
                    });
                }
            });
            return this.commonPlanSelectionService.selectStudiesByGroupId(courseUnitGroupIdsToBeSelected, moduleGroupIdsToBeSelected, parentModule, rawPlan, updatedValidatablePlan)
                .then((studiesRequiringAutomaticSelections: { modules: Module[], courseUnits: CourseUnit[] }) => {
                    moduleSelectionsRequiringAutomaticSelections = _.concat(moduleSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.modules);
                    courseUnitSelectionsRequiringAutomaticSelections = _.concat(courseUnitSelectionsRequiringAutomaticSelections, studiesRequiringAutomaticSelections.courseUnits);
                    return this.commonPlanSelectionService.makeAutomaticSelectionsForModules(rawPlan, updatedValidatablePlan, moduleSelectionsRequiringAutomaticSelections, courseUnitSelectionsRequiringAutomaticSelections, studyRight)
                        .then(() => {
                            this.commonPlanSelectionService.makeAutomaticSelectionsForCourseUnits(rawPlan, courseUnitSelectionsRequiringAutomaticSelections);
                            return rawPlan;
                        });
                });
        });
    }

    /**
     * Deactivates a rule group and makes automatic selections for the deactivated rule group.
     * Notice that this method will directly modify the plan.
     *
     * @param deactivatedRule The rule to be deactivated.
     * @param parentModule The parent module of the deactivated rule.
     * @param rawPlan Current plan.
     * @param validatablePlan Current validated plan.
     */
    deactivateRuleGroup(deactivatedRule: CompositeRule, parentModule: EntityWithRule, rawPlan: Plan, validatablePlan: ValidatablePlan): void {
        this.commonPlanSelectionService.deActivateRule(parentModule, parentModule.rule, deactivatedRule, rawPlan, validatablePlan);
    }

    /**
     * Check if parent module is in plan and not attained.
     *
     * @param parentModuleId The id of the parent module.
     * @param validatablePlan Current validated plan.
     * @private
     */
    private doesParentModuleAcceptSelections(parentModuleId: OtmId, validatablePlan: ValidatablePlan): boolean {
        return validatablePlan.isModuleInPlan(parentModuleId) && !validatablePlan.isModuleAttained(parentModuleId);
    }
}
