import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { AddCourseUnitRealisationValues } from './addCourseUnitRealisationDialog.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'add-course-unit-realisation-dialog' })
export class AddCourseUnitRealisationDialogDirective extends UpgradeComponent {

    @Output() close: EventEmitter<any>;
    @Output() dismiss: EventEmitter<any>;
    @Input() resolve: AddCourseUnitRealisationValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('addCourseUnitRealisationDialog', elementRef, injector);
    }
}
