<sis-tooltip [tooltipText]="backButtonDisabledTooltip"
             [tooltipEnabled]="backButtonDisabled"
             [tooltipBlockElement]="true">
  <sis-button *ngIf="backButtonDisplay"
              size="lg"
              variant="primary"
              [hollow]="true"
              class="sis-tooltip-trigger-content"
              [disabled]="backButtonDisabled"
              (clicked)="back.emit()"
              data-cy="wizard-back-button">
    {{ backButtonTitle }}
  </sis-button>
</sis-tooltip>
<sis-tooltip
             [tooltipText]="forwardButtonDisabledTooltip"
             [tooltipEnabled]="forwardButtonDisabled"
             [tooltipBlockElement]="true">
  <sis-button *ngIf="forwardButtonDisplay"
              size="lg"
              variant="primary"
              class="sis-tooltip-trigger-content"
              [disabled]="forwardButtonDisabled"
              (clicked)="forward.emit()"
              data-cy="wizard-forward-button">
    {{ forwardButtonTitle }}
  </sis-button>
</sis-tooltip>

