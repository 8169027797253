import { SisStateDeclaration } from 'sis-components/router/types';

import { FrontpageComponent } from './frontpage-component/frontpage.component';

export const frontpageRootState = 'student.logged-in.frontpage';

export const frontpageRoutes: SisStateDeclaration[] = [
    {
        name: frontpageRootState,
        url: '/frontpage',
        component: FrontpageComponent,
        data: {
            browserTabTitle: 'APPLICATION_FRONTPAGE',
            hasSecondaryBackground: true,
        },
    },
];

