<ng-container *ngFor="let node of nodes">
    <div *ngIf="node.type === 'CourseUnit'" class="tree-list-body">
        <div class="row">
            <div class="col-sm-6 tree-list-body-inner">
                <sis-plan-course-unit [courseUnit]="this.planData.courseUnitsById[node.courseUnitId]"
                                      [planStateObject]="planStateObject"
                                      [planData]="planData">
                </sis-plan-course-unit>
            </div>
        </div>
    </div>


    <sis-plan-module *ngIf="node.type === 'Module'"
                     [module]="this.planData.modulesById[node.moduleId]"
                     [planStateObject]="planStateObject"
                     [planData]="planData"
                     [uiStateObject]="uiStateObject"
                     [level]="level+1">
    </sis-plan-module>
    <div *ngIf="node.type === 'CustomCourseUnitAttainment'" class="tree-list-body">
        @let attainment = planData.attainmentsById[node.customCourseUnitAttainmentId];
        @if (isCustomCourseUnitAttainment(attainment)) {
            <div class="row">
                <div class="col-sm-6 tree-list-body-inner">
                    <sis-plan-custom-course-unit-attainment [customCourseUnitAttainment]="attainment"
                                                            [planStateObject]="planStateObject">
                    </sis-plan-custom-course-unit-attainment>
                </div>
            </div>
        }
    </div>

    @if (node.type === 'CustomModuleAttainment') {
        @let attainment = planData.attainmentsById[node.customModuleAttainmentId];
        @if (isCustomModuleAttainment(attainment)) {
            <sis-plan-custom-module-attainment [customModuleAttainment]="attainment"
                                               [planStateObject]="planStateObject"
                                               [planData]="planData"
                                               [uiStateObject]="uiStateObject"
                                               [level]="level+1">
            </sis-plan-custom-module-attainment>
        }
    }

    <sis-attainment-group-node *ngIf="node.type === 'AttainmentGroupNode'"
                               [attainmentGroupNode]="node"
                               [planStateObject]="planStateObject"
                               [planData]="planData"
                               [uiStateObject]="uiStateObject"
                               [level]="level+1">

    </sis-attainment-group-node>
</ng-container>
