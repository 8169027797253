<div *transloco="let t" class="container">
  <ng-container *ngIf="this.studentWorkflow">
    <fudis-grid [rowGap]="'md'">
      <fudis-grid-item>
        <div class="d-flex flex-column flex-sm-row justify-content-end gap-4">
          <fudis-button
            *ngIf="isWorkflowSupplementRequested()"
            (handleClick)="navigateToWorkflowSupplement()"
            [label]="t('PROFILE.APPLICATIONS.SUPPLEMENT.SUPPLEMENT_APPLICATION')"
            [variant]="'secondary'" data-cy="caw-supplement-button"/>
          <fudis-button
            *ngIf="shouldShowCancelButton()"
            (handleClick)="openCancelDialog()"
            [label]="t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.BUTTON.OPEN_CANCEL_APPLICATION_MODAL')"
            [variant]="'primary'" data-cy="caw-cancel-button"/>
        </div>
      </fudis-grid-item>

      <ng-container *ngIf="isWorkflowCancelled()">
        <fudis-notification
          [variant]="'warning'">{{ t('PROFILE.APPLICATIONS.APPLICATION_CANCELLED') }}
        </fudis-notification>
      </ng-container>

      <ng-container *ngIf="!shouldShowDecision()">
        <fudis-notification [variant]="'info'">
          <transloco *ngIf="studentWorkflow?.moduleId | module | async as degreeProgramme"
                     [params]="{ degreeProgramme: degreeProgramme.name | localizedString, applicationName: studentWorkflow?.name }"
                     key='PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.APPLICATION_INFO'></transloco>
        </fudis-notification>
      </ng-container>

      <ng-container *ngIf="isWorkflowSupplementRequested()">
        <sis-contextual-notification [variant]="'warning'"
                                     *ngIf="activeSupplementRequestDescription$ | async as description">
          <p>{{ t('PROFILE.APPLICATIONS.SUPPLEMENT_REQUESTED_RATIONALE') }}</p>
          <p>{{ description }}</p>
        </sis-contextual-notification>
      </ng-container>

      <ng-container *ngIf="isAttachmentDeliveryPossible()">
        <fudis-notification [variant]="'warning'">{{ t('PROFILE.APPLICATIONS.ATTACHMENT_DELIVERY_REMINDER') }}
        </fudis-notification>
      </ng-container>

      <ng-container *ngIf="!!studentWorkflow?.decision; else onlyApplication">
        <ng-container>
          <fudis-expandable [level]="2" [title]="t('STUDENT_APPLICATIONS.APPLICATION_INFO')">
            <ng-template fudisExpandableContent>
              <ng-container *ngTemplateOutlet="applicationInfo"/>
            </ng-template>
          </fudis-expandable>
        </ng-container>
        <ng-container>
          <fudis-expandable [level]="2" [closed]="false" [title]="t('STUDENT_APPLICATIONS.DECISION_INFO')">
            <ng-template fudisExpandableContent>
              <ng-container *ngTemplateOutlet="decisionInfo"/>
            </ng-template>
          </fudis-expandable>
        </ng-container>
      </ng-container>
    </fudis-grid>

    <ng-template #onlyApplication>
      <fudis-heading [level]="2">{{ t('STUDENT_APPLICATIONS.APPLICATION_INFO') }}</fudis-heading>
      <ng-container *ngTemplateOutlet="applicationInfo"/>
      <ng-container>
        <fudis-heading [level]="3">{{ t('STUDENT_APPLICATIONS.DECISION') }}</fudis-heading>
        <fudis-body-text>
          <transloco
            [key]="'PROFILE.APPLICATIONS.' + (studentWorkflow.mustPrintDecision ? 'DECISION_REQUESTED_BY_MAIL' : 'DECISION_NOT_REQUESTED_BY_MAIL')"/>
        </fudis-body-text>
      </ng-container>
    </ng-template>

    <ng-template #applicationInfo>
      <app-custom-attainment-workflow-summary [studentWorkflow]="studentWorkflow" [studentId]="studentWorkflow.studentId"/>
      <sis-application-files [applicationId]="studentWorkflow.id" [studentId]="studentWorkflow.studentId"
                             [editable]="isAttachmentDeliveryPossible()"/>
    </ng-template>

    <ng-template #decisionInfo>
      <sis-attainment-workflow-decision-summary
        [workflowDecision]="studentWorkflow.decision"
        [workflowState]="studentWorkflow.state"
        [workflowType]="studentWorkflow.application.type"
        [attainment]="attainment$ | async"
      />
    </ng-template>

  </ng-container>
</div>

