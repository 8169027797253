import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { AttainmentMisregistrationRationaleValues } from './attainmentMisregistrationRationaleModal-ng.component';

/**
 * @deprecated
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'attainment-misregistration-rationale-modal' })
export class AttainmentMisregistrationRationaleModalDirective extends UpgradeComponent {

    @Output() close: EventEmitter<any>;
    @Input() resolve: AttainmentMisregistrationRationaleValues;

    constructor(elementRef: ElementRef<any>, injector: Injector) {
        super('attainmentMisregistrationRationaleModal', elementRef, injector);
    }
}
