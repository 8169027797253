import angular from 'angular';
import angularTranslate from 'angular-translate';
import * as _ from 'lodash-es';
import { localizedStringFilterModule } from 'sis-common/l10n/localizedStringFilter';
import { errorServiceModule } from 'sis-components/error-handler/legacy/errorService';
import { commonEnrolmentPeriodDateServiceModule } from 'sis-components/service/enrolmentPeriodDate.service';
import { commonEnrolmentServiceModule } from 'sis-components/service/enrolment.service';
import { localDateFormatFilterModule } from 'sis-components/date/filters/localDateFormat/localDateFormat.filter';
import { enrolmentServiceModule } from '../../../service/enrolment.service';
import cancelOrAbortEnrolmentModalTpl from './cancelOrAbortEnrolmentModal.tpl.html';
export const cancelOrAbortEnrolmentModalModule = 'student.common.components.course-unit-realisation.cancelOrAbortEnrolmentModal';
(function () {
  cancelOrAbortEnrolmentModalController.$inject = ["$q", "enrolmentPeriodDateService", "commonEnrolmentService", "enrolmentService", "defaultPromiseHandler"];
  angular.module(cancelOrAbortEnrolmentModalModule, [angularTranslate, localDateFormatFilterModule, localizedStringFilterModule, commonEnrolmentPeriodDateServiceModule, commonEnrolmentServiceModule, enrolmentServiceModule, errorServiceModule]).component('cancelOrAbortEnrolmentModal', {
    template: cancelOrAbortEnrolmentModalTpl,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: cancelOrAbortEnrolmentModalController
  });

  /**
   * This component will abort or cancel enrolment if confirmButton is pressed. The action is determined by $ctrl.resolve.abort on
   * component initialisation.
   */
  function cancelOrAbortEnrolmentModalController($q, enrolmentPeriodDateService, commonEnrolmentService, enrolmentService, defaultPromiseHandler) {
    const ctrl = this;
    ctrl.$onInit = function () {
      ctrl.courseUnitRealisation = ctrl.resolve.courseUnitRealisation;
      ctrl.courseUnit = ctrl.resolve.courseUnit;
      ctrl.enrolment = ctrl.resolve.enrolment;
      ctrl.abort = ctrl.resolve.abort;
      ctrl.enrolmentPeriodDates = enrolmentPeriodDateService.getEnrolmentDates(ctrl.courseUnitRealisation);
      ctrl.collapsed = false;
      ctrl.credits = enrolmentService.getEnrolmentCredits(ctrl.enrolment);
      ctrl.isCreditRange = ctrl.credits && ctrl.credits.min !== ctrl.credits.max;
    };
    ctrl.confirmButton = function () {
      (ctrl.abort ? ctrl.abortEnrolment() : ctrl.cancelEnrolment()).then(() => {
        const modalResponse = {
          enrolment: ctrl.enrolment
        };
        ctrl.close({
          $value: modalResponse
        });
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    ctrl.cancelEnrolment = function () {
      return commonEnrolmentService.cancelEnrolment(ctrl.enrolment).then(enrolment => {
        ctrl.enrolment = enrolment;
      }, error => {
        ctrl.errorOnCancelEnrolment = true;
        return $q.reject(error);
      });
    };
    ctrl.abortEnrolment = function () {
      return commonEnrolmentService.abortOwnEnrolment(ctrl.enrolment).then(enrolment => {
        ctrl.enrolment = enrolment;
      }, error => {
        ctrl.errorOnAbortEnrolment = true;
        return $q.reject(error);
      });
    };
    ctrl.getEducationNameForStudyRightId = function (id) {
      const studyRight = _.find(ctrl.resolve.myStudyRights, {
        id
      });
      return _.chain(ctrl.resolve.myStudyRightEducations).find({
        id: _.get(studyRight, 'educationId')
      }).get('name').value();
    };
  }
})();