import 'angular-translate';
import angular from 'angular';
import * as _ from 'lodash-es';
(function () {
  angular.module('sis-components.attainment.helper.sort', ['pascalprecht.translate', 'sis-common.l10n.localeService']).factory('attainmentSortHelper', ["$translate", "localeService", ($translate, localeService) => {
    return {
      sortBy: (attainments, sortParams) => {
        const reverse = sortParams.order === 'desc' ? 1 : -1;
        if (sortParams.type === 'date') {
          return attainments.sort((a, b) => {
            const aDate = new Date(_.result(a, sortParams.field));
            const bDate = new Date(_.result(b, sortParams.field));
            if (aDate.toString() === bDate.toString()) {
              return stringCompare(_.get(a, 'id'), _.get(b, 'id'), reverse);
            }
            return aDate < bDate ? reverse : -reverse;
          });
        }
        if (sortParams.type === 'string') {
          return attainments.sort((a, b) => {
            const result = stringCompare(_.result(a, sortParams.field, ''), _.result(b, sortParams.field, ''), reverse);
            if (result === 0) {
              return stringCompare(_.get(a, 'id'), _.get(b, 'id'), reverse);
            }
            return result;
          });
        }
        if (sortParams.type === 'localized') {
          return attainments.sort((a, b) => {
            const aLang = localeService.getLocalizedValue(_.result(a, sortParams.field, ''));
            const bLang = localeService.getLocalizedValue(_.result(b, sortParams.field, ''));
            const result = _.toString(aLang).localeCompare(_.toString(bLang), localeService.getCurrentLocale());
            if (result === 0) {
              return stringCompare(_.get(a, 'id'), _.get(b, 'id'), reverse);
            }
            return result * -reverse;
          });
        }
        if (sortParams.type === 'integer') {
          return attainments.sort((a, b) => {
            const aValue = _.result(a, sortParams.field, 0);
            const bValue = _.result(b, sortParams.field, 0);
            if (aValue === bValue) {
              return stringCompare(_.get(a, 'id'), _.get(b, 'id'), reverse);
            }
            return aValue < bValue ? reverse : -reverse;
          });
        }
      }
    };
    function stringCompare(a, b, reverse) {
      return _.toString(a).localeCompare(_.toString(b), localeService.getCurrentLocale()) * -reverse;
    }
  }]);
})();