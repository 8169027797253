import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedString, Organisation, OtmId } from 'common-typescript/types';
import { each, groupBy, keyBy, omit } from 'lodash-es';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { ModalService } from 'sis-common/modal/modal.service';

import { CheckboxTreeOption } from '../../checkbox-tree/checkbox-tree.component';

export interface SelectOrganisationModalValues {
    university: Organisation;
    organisations: Organisation[];
    selectedIds: OtmId[];
    selectedParentIds: OtmId[];
}

interface OrganisationOption {
    id: OtmId;
    name: LocalizedString;
}

@Component({
    selector: 'sis-select-organisation',
    templateUrl: './select-organisation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOrganisationComponent implements OnInit {
    organisations: Organisation[];
    options: CheckboxTreeOption[];
    university: Organisation;
    optionTree: CheckboxTreeOption;
    selected: CheckboxTreeOption[] = [];
    selectedIds: OtmId[];

    @Output() selectedChange = new EventEmitter<CheckboxTreeOption[]>();

    constructor(
        private modal: NgbActiveModal,
        private localeService: LocaleService,
        @Inject(ModalService.injectionToken) private values: SelectOrganisationModalValues,
    ) {
        this.university = values.university;
        this.organisations = values.organisations;
        this.selectedIds = values.selectedIds.concat(values.selectedParentIds.map(id => this.createParentId(id)));
    }

    ngOnInit(): void {
        this.optionTree = this.buildOptionTree(this.organisations);
        this.options = this.flattenChildren([this.optionTree]);
        this.selected = this.selectedIds.map(this.idToOptionMapper(this.options));
    }

    get defaultExpanded() {
        // Always expand university root.
        const expanded = [this.createParentId(this.university.id)];

        // Expand parents deep.
        for (const opt of this.selected) {
            const parents = this.getAllParents(opt);
            const parentIds = parents.map(o => o.value);
            expanded.push(...parentIds);
        }
        return expanded;
    }

    get topLevelStyleTargets() {
        return this.optionTree.children.map(opt => opt.value);
    }

    /**
     * Handle toggling the options on and off.
     *
     * Basically we just add or remove Options from the selection but with the little additional
     * twist that we unset all children upon selecting a parent. The Children are already
     * implicitly in the selection through their parent and doing this also obviates the need to
     * explicitly deselect children when a parent option is unchecked.
     *
     * @param option The Option that was clicked on.
     */
    selectChange = (option: CheckboxTreeOption): void => {
        // Deselect and return early.
        const isSelected = !!this.selected.find(({ value }) => value === option.value);
        if (isSelected) {
            this.selected = this.selected.filter(({ value }) => value !== option.value);
            return;
        }

        // Select, deselecting children, if found.
        this.selected.push(option);
        const deepChildrenIds = this.flattenChildren(option.children)
            .map(child => child.value);
        this.selected = this.selected.filter(({ value }) => !deepChildrenIds.includes(value));
    };

    /**
     * Build a hierarchical option tree for expandable checkboxes. Parent nodes are duplicated as the first child
     * in their `children` array. We need to do this to offer an explicit control to select only the parent organisation
     * without any of its children. To maintain unique ID's and values for options, parent nodes' ID's are appended
     * with "-parent".
     */
    private buildOptionTree(organisations: Organisation[]): CheckboxTreeOption {
        if (!organisations.length) {
            return {
                value: this.university.id,
                parentId: null,
                children: [],
                label: this.university.name,
            };
        }

        const parentSecondaryLabel = this.localeService.getLocalizedString('SELECT_ORGANISATION.SECONDARY_LABELS.ALL_CHILDREN');
        const topOrgSecondaryLabel = this.localeService.getLocalizedString('SELECT_ORGANISATION.SECONDARY_LABELS.ONLY_TOP_ORG');

        const options = organisations.map(this.organisationToOptionMapper);

        const parentGroups = omit(groupBy(options, 'parentId'), null);
        const optionsByValue = keyBy(options, (opt) => this.createParentId(opt.value));

        each(parentGroups, (children, parentId) => {
            const parent = options.find(opt => opt.value === parentId);
            if (!parent) {
                return;
            }

            const parentNodeId = this.createParentId(parentId);
            const parentOrgOption: CheckboxTreeOption = {
                parentId: parentNodeId,
                label: parent.label,
                secondaryLabel: topOrgSecondaryLabel,
                value: parentId,
                children: [],
            };
            const sortedChildren = this.sortOptions(children);
            const modifiedChildren = sortedChildren.map(child => {
                child.parentId = parentNodeId;
                return child;
            });
            optionsByValue[parentNodeId].children = [parentOrgOption].concat(modifiedChildren);
            optionsByValue[parentNodeId].value = parentNodeId;
            optionsByValue[parentNodeId].secondaryLabel = parentSecondaryLabel;
        });

        return optionsByValue[this.createParentId(this.university.id)];
    }

    private createParentId(id: string): string {
        return `${id}-parent`;
    }

    private removeParentIdSuffix(id: string): string {
        return id.split('-parent')[0];
    }

    private sortOptions(options: CheckboxTreeOption[]): CheckboxTreeOption[] {
        return options.sort((a, b) => {
            const first = this.localeService.localize(a.label);
            const last = this.localeService.localize(b.label);
            return first.localeCompare(
                last,
                this.localeService.getCurrentLanguage(),
                { sensitivity: 'accent' },
            );
        });
    }

    private organisationToOptionMapper = ({ id, name, parentId }: Organisation): CheckboxTreeOption =>
        ({
            value: id,
            label: name,
            parentId,
            children: [],
        });

    private idToOptionMapper = (options: CheckboxTreeOption[]) =>
        (id: string): CheckboxTreeOption => options.find(({ value }) => value === id);

    private optionToOrganisationOptionMapper = ({ value, label }: CheckboxTreeOption): OrganisationOption =>
        ({ id: value, name: label });

    private flattenChildren(nodes: CheckboxTreeOption[]): CheckboxTreeOption[] {
        let children: CheckboxTreeOption[] = [];
        return nodes.map((node: any) => {
            if (node.children && node.children.length) {
                children = [...children, ...node.children];
            }
            return node;
        }).concat(children.length ? this.flattenChildren(children) : children);
    }

    /**
     * Traverse hierarchical tree upwards beginning at given `child`, returning list of all
     * nodes encountered.
     */
    private getAllParents(child: CheckboxTreeOption): CheckboxTreeOption[] {
        const parent = this.options.find(opt => opt.value === child.parentId);
        if (!parent) {
            return [];
        }

        return [parent].concat(this.getAllParents(parent));
    }

    close() {
        this.modal.dismiss();
    }

    confirmSelection() {
        const parents = this.selected
            .filter(opt => opt.children.length)
            .map(opt => ({ ...opt, value: this.removeParentIdSuffix(opt.value) }));
        const leafs = this.selected.filter(opt => !opt.children.length);

        this.modal.close({
            selectedParents: parents.map(this.optionToOrganisationOptionMapper),
            selectedLeafs: leafs.map(this.optionToOrganisationOptionMapper),
        });
    }

    reset() {
        this.selected = [];
    }
}
