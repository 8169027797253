import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { findClosestFocusModeParams, NavUtilsService } from '../../service/nav-utils.service';
import { SkipLinkComponent } from '../../skip-link/skip-link.component';
import { BadgeData, HeaderService } from '../header.service';

/**
 * Sis-page-title component can either fetch route and page information from header service, or the information can be
 * given through the input properties. If given, input attributes overrides the data fetched from the header service.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-page-title',
    templateUrl: './page-title.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PageTitleComponent implements OnInit, OnDestroy, AfterViewInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.downgraded.page-title',
        directiveName: 'sisPageTitle',
    };

    /** Set true if page has header crumbs visible */
    @Input() showCrumbs?: boolean = false;
    @Input() title?: string;
    @Input() subTitle?: string;
    @Input() dataCy?: string = 'page-title';

    badges: BadgeData[] = [];
    breadcrumbCount: number;

    private breadcrumbPageTitle: string;
    private customPageTitle: string;
    private customPageSubTitle: string;
    private subscriptions: Subscription[] = [];

    constructor(private headerService: HeaderService,
                private uiRouterGlobals: UIRouterGlobals,
                private readonly navUtilsService: NavUtilsService) {}

    ngAfterViewInit() {
        this.focusOnMainContent();
    }

    get focusMode() {
        return this.navUtilsService.focusMode;
    }

    focusOnMainContent = () => {
        /* Here we check that the user has been navigating in sisu already - we don't want to
        * focus on main content on eg. page refresh or on first visit. Also we don't focus on
        * main content in focus mode */
        if (this.uiRouterGlobals?.lastStartedTransitionId !== 0 && !this.focusMode()) {
            /* This is to check, that are we navigating back from focus mode. If we are, we are NOT focusing on
            * header (there is logic in top navigation component to focus on cart button instead) */
            const exitingFromFocusMode = !!findClosestFocusModeParams(this.uiRouterGlobals.transitionHistory?.peekHead()?.$from());
            if (!exitingFromFocusMode) {
                const el = document.getElementById(SkipLinkComponent.SKIP_LINK_TARGET_ID);
                el?.focus();
            }
        }
    };

    ngOnInit(): void {
        this.subscriptions = [
            this.headerService.breadcrumbsChanged.subscribe((breadcrumbs) => {
                this.breadcrumbCount = breadcrumbs.length;
                this.breadcrumbPageTitle = _.last(breadcrumbs)?.displayName;
            }),
            this.headerService.customPageTitleChanged.subscribe(title => {
                if (this.customPageTitle !== title) {
                    this.customPageTitle = title;
                    /* If the component still exists (so the afterviewinit does not trigger)
                    * we focus if the title changes. */
                    if (this.customPageTitle) this.focusOnMainContent();
                }
            }),
            this.headerService.customPageSubTitleChanged.subscribe(subTitle => this.customPageSubTitle = subTitle),
            this.headerService.pageTitleBadgesChanged.subscribe(badges => this.badges = badges),
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions?.forEach(sub => sub.unsubscribe());
    }

    get showBreadcrumbs(): boolean {
        return this.showCrumbs ? true : (this.breadcrumbCount > 1 && !this.uiRouterGlobals.$current?.data?.headerWithoutBreadcrumbs);
    }

    get pageTitle(): string {
        return this.title ?? this.customPageTitle ?? this.breadcrumbPageTitle;
    }

    get pageSubTitle(): string {
        return this.subTitle || this.customPageSubTitle;
    }
}
