import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { numberUtils } from 'common-typescript';
import {
    AssessmentItem,
    CourseUnit, CourseUnitEnrolmentRight,
    CourseUnitRealisation,
    CreditRange,
    CurriculumPeriod,
    EnrolmentRight,
    EnrolmentRightChangeLogItem,
    OpenUniversityProduct,
    OtmId,
    UsedEnrolments,
} from 'common-typescript/types';
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    switchMap,
} from 'rxjs/operators';

import { AppErrorHandler } from '../../../error-handler/app-error-handler';
import { CreditRangePipe } from '../../../number/credit-range.pipe';
import { AssessmentItemEntityService } from '../../../service/assessment-item-entity.service';
import { CourseUnitEntityService } from '../../../service/course-unit-entity.service';
import { CourseUnitRealisationEntityService } from '../../../service/course-unit-realisation-entity.service';
import { CurriculumPeriodEntityService } from '../../../service/curriculum-period-entity.service';
import { EnrolmentRightEntityService } from '../../../service/enrolment-right-entity.service';
import { OpenUniversityProductEntityService } from '../../../service/open-university-product-entity.service';
import { EnrolmentRightModalService } from '../enrolment-right-modal.service';
import { isCourseUnitEnrolmentRight } from '../enrolment-right.type-guards';

@Component({
    selector: 'sis-enrolment-right-expandable-body',
    templateUrl: './enrolment-right-expandable-body.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrolmentRightExpandableBodyComponent {

    @Input() componentType?: 'STAFF' | 'STUDENT' = 'STAFF';

    @Input() set enrolmentRight(enrolmentRight: EnrolmentRight) {
        this._enrolmentRight$.next(enrolmentRight);

        this.editInfos = enrolmentRight?.changeLog?.filter(({ changeType }) => changeType === 'EDIT');
        this.cancellationInfo = enrolmentRight?.state !== 'CANCELLED' ?
            null : enrolmentRight.changeLog?.find(({ changeType }) => changeType === 'CANCEL' || changeType === 'AUTOMATED_CANCEL');
    }

    get enrolmentRight(): EnrolmentRight | null {
        return this._enrolmentRight$.value;
    }

    private readonly _enrolmentRight$: BehaviorSubject<EnrolmentRight | null> = new BehaviorSubject<EnrolmentRight | null>(null);

    editInfos: EnrolmentRightChangeLogItem[];
    cancellationInfo: EnrolmentRightChangeLogItem;
    /**
     * Emits the current curriculum period,
     * or null when not found or still loading.
     */
    readonly curriculumPeriod$: Observable<CurriculumPeriod | null>;
    /**
     * Emits assessment items of all course unit realisations related to {@link enrolmentRight}.
     * Emits null when loading or enrolment right not yet set.
     */
    readonly assessmentItems$: Observable<AssessmentItem[] | null>;
    /**
     * Emits all course unit realisations related to {@link enrolmentRight},
     * with some CURs related to the associated open university product added if necessary.
     * Emits null when loading or enrolment right not yet set.
     */
    readonly courseUnitRealisationsByAssessmentItemId$: Observable<{ [assessmentItemId: string]: CourseUnitRealisation[] } | null>;
    /**
     * Emits a map telling for each ID in {@link assessmentItems$} whether CUR info should be shown,
     * or null when loading or enrolment right not set.
     */
    readonly showInfoTextByAssessmentItemId$: Observable<{ [assessmentItemId: string]: boolean } | null>;
    /**
     * Emits the open university product that the user bought to receive {@link enrolmentRight},
     * or null if the enrolment right was received in other means
     * or the product is being loaded.
     */
    readonly openUniversityProduct$: Observable<OpenUniversityProduct | null>;
    /**
     * If {@link enrolmentRight} is a substitution right,
     * emits the (formatted) credit range of the course unit.
     * Otherwise, if it was created as a result of an open university product purchase,
     * emits (formatted) credits of the product.
     * Null if neither one is available or loading data.
     */
    readonly formattedCredits$: Observable<string | null>;
    /**
     * Emits a map of remaining enrolment counts for each ID in {@link assessmentItems$},
     * or null when loading or enrolment right not set.
     */
    readonly remainingEnrolmentCountsByAssessmentItemId$: Observable<{ [assessmentItemId: string]: RemainingEnrolmentCounts } | null>;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private readonly _assessmentItemService: AssessmentItemEntityService,
        private readonly _courseUnitRealisationEntityService: CourseUnitRealisationEntityService,
        private enrolmentRightModalService: EnrolmentRightModalService,
        private readonly _openUniversityProductEntityService: OpenUniversityProductEntityService,
        private readonly _curriculumPeriodService: CurriculumPeriodEntityService,
        private readonly _translocoService: TranslocoService,
        private readonly _courseUnitEntityService: CourseUnitEntityService,
        private readonly _creditRangePipe: CreditRangePipe,
        private readonly _enrolmentRightEntityService: EnrolmentRightEntityService,
    ) {
        this.curriculumPeriod$ = this.createCurriculumPeriodStream();
        this.openUniversityProduct$ = this.createOpenUniversityProductStream();
        this.formattedCredits$ = this.createFormattedCreditsStream();
        this.courseUnitRealisationsByAssessmentItemId$ = this.createCourseUnitRealisationsByAssessmentItemIdStream();
        this.assessmentItems$ = this.createAssessmentItemsStream();
        this.showInfoTextByAssessmentItemId$ = this.createShowInfoTextByAssessmentItemIdStream();
        this.remainingEnrolmentCountsByAssessmentItemId$ = this.createRemainingEnrolmentCountsByAssessmentItemIdStream();
    }

    get isCurEnrolmentRight(): boolean {
        return this._enrolmentRight$.value?.type === 'CUR_ENROLMENT';
    }

    openEditInfoDialog(): void {
        this.enrolmentRightModalService.openEditInfoDialog(this.editInfos);
    }

    openCancellationInfoDialog(): void {
        this.enrolmentRightModalService.openCancellationInfoDialog(this.cancellationInfo);
    }

    private createOpenUniversityProductStream(): Observable<OpenUniversityProduct | null> {
        return this._enrolmentRight$.pipe(
            mapYieldNull((er: EnrolmentRight) => isCourseUnitEnrolmentRight(er) ? er.openUniversityProductId : null),
            distinctUntilChanged(),
            switchMapYieldNull((openUniversityProductId: OtmId) => this._openUniversityProductEntityService.get<OpenUniversityProduct | null>(openUniversityProductId).pipe(
                this.appErrorHandler.defaultErrorHandler(),
                startWith(null), // while loading
            )),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private get courseUnit$(): Observable<CourseUnit | null> {
        return this._enrolmentRight$.pipe(
            mapYieldNull((er: EnrolmentRight) => er.courseUnitId),
            distinctUntilChanged(),
            switchMapYieldNull((courseUnitId: OtmId) => this._courseUnitEntityService.getById(courseUnitId).pipe(
                this.appErrorHandler.defaultErrorHandler(),
                startWith(null), // while loading
            )),
            distinctUntilChanged(),
        );
    }

    private get isSubstitutionRight$(): Observable<boolean | null> {
        return this._enrolmentRight$.pipe(
            mapYieldNull((er: EnrolmentRight) => er.type === 'SUBSTITUTION'),
            distinctUntilChanged(),
        );
    }

    private createFormattedCreditsStream(): Observable<string | null> {
        return this.isSubstitutionRight$.pipe(
            switchMapYieldNull((isSubstitutionRight: boolean) => isSubstitutionRight
                ? this.courseUnit$.pipe(
                    mapYieldNull((cu: CourseUnit) => cu.credits),
                    mapYieldNull((creditRange: CreditRange) => this._creditRangePipe.transform(creditRange, 'SYMBOLS')),
                )
                : this.openUniversityProduct$.pipe(
                    mapYieldNull((oup: OpenUniversityProduct) => oup.credits),
                    switchMapYieldNull((credits: number) => {
                        const formattedCredits: string = numberUtils.numberToString(credits);

                        // this will produce a new value whenever the language changes
                        return this._translocoService.selectTranslate('CREDITS').pipe(
                            map((suffix: string) => `${formattedCredits} ${suffix}`),
                            startWith(null), // while loading
                        );
                    }),
                ),
            ),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createCourseUnitRealisationsByAssessmentItemIdStream(): Observable<{ readonly [assessmentItemId: string]: CourseUnitRealisation[] } | null> {
        return this._enrolmentRight$.pipe(
            mapYieldNull((er: EnrolmentRight) => isCourseUnitEnrolmentRight(er) ? er : null),
            switchMapYieldNull((er: CourseUnitEnrolmentRight) =>
                this._courseUnitRealisationEntityService.findForEnrolmentRight(er, ['ACTIVE'], ['PUBLISHED']).pipe(
                    this.appErrorHandler.defaultErrorHandler(),
                    startWith(null), // while loading
                ),
            ),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createAssessmentItemsStream(): Observable<AssessmentItem[] | null> {
        return combineLatest([
            this._enrolmentRight$.pipe(
                mapYieldNull((enrolmentRight: EnrolmentRight) => enrolmentRight.courseUnitId),
                distinctUntilChanged(),
            ),
            this.courseUnitRealisationsByAssessmentItemId$.pipe(
                mapYieldNull((cursByAssessmentItemId: { [assessmentItemId: string]: CourseUnitRealisation[] }) => Object.keys(cursByAssessmentItemId)),
                distinctUntilChanged(),
            ),
        ]).pipe(
            switchMap(([courseUnitId, assessmentItemIds]: [OtmId | null, OtmId[] | null]) => courseUnitId === null || assessmentItemIds === null
                ? of(null)
                : this._assessmentItemService.getByIdsSorted(assessmentItemIds, courseUnitId).pipe(
                    this.appErrorHandler.defaultErrorHandler(),
                    startWith(null), // while loading
                ),
            ),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createShowInfoTextByAssessmentItemIdStream(): Observable<{ [assessmentItemId: string]: boolean } | null> {
        return combineLatest([
            this._enrolmentRight$,
            this.assessmentItems$.pipe(
                mapYieldNull((assessmentItems: AssessmentItem[]) => assessmentItems.map(ai => ai.id)),
            ),
        ]).pipe(
            map(([enrolmentRight, assessmentItemIds]: [EnrolmentRight | null, OtmId[] | null]) => !enrolmentRight || assessmentItemIds === null
                ? null
                : assessmentItemIds.reduce((acc, assessmentItemId) => ({ ...acc, [assessmentItemId]: !enrolmentRightHasCURConstraintsFor(enrolmentRight, assessmentItemId) }), {}),
            ),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createRemainingEnrolmentCountsByAssessmentItemIdStream(): Observable<{ [assessmentItemId: string]: RemainingEnrolmentCounts } | null> {
        return combineLatest([
            this._enrolmentRight$.pipe(
                switchMap((enrolmentRight: EnrolmentRight | null) =>
                    enrolmentRight
                        ? combineLatest([
                            of(enrolmentRight),
                            this._enrolmentRightEntityService.countUsedEnrolments(enrolmentRight.id).pipe(
                                this.appErrorHandler.defaultErrorHandler(),
                                startWith(null), // while loading
                            ),
                        ])
                        : of([null, null]),
                ),
            ),
            this.assessmentItems$,
        ]).pipe(
            map(([[enrolmentRight, usedEnrolmentsList], assessmentItems]: [[EnrolmentRight | null, UsedEnrolments[] | null], AssessmentItem[] | null]) => {
                if (enrolmentRight === null || usedEnrolmentsList === null || assessmentItems === null) {
                    return null;
                }

                const result: { [assessmentItemId: string]: RemainingEnrolmentCounts } = {};
                for (const assessmentItem of assessmentItems) {
                    const total: number | null = getMaxNumberOfEnrolmentsByAssItemId(enrolmentRight, assessmentItem.id);
                    const used: number | null = getUsedNumberOfEnrolmentsByAssItemId(assessmentItem.id, usedEnrolmentsList);

                    result[assessmentItem.id] = <RemainingEnrolmentCounts>{
                        total,
                        used,
                        remain: () => total - used,
                    };
                }
                return result;
            }),
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private createCurriculumPeriodStream(): Observable<CurriculumPeriod | null> {
        return this._curriculumPeriodService.findCurrentCurriculumPeriod().pipe(
            this.appErrorHandler.defaultErrorHandler(),
            startWith(null), // while loading
            distinctUntilChanged(),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}

export interface RemainingEnrolmentCounts {
    total: number;
    used: number;
    remain: () => number;
}

function switchMapYieldNull<T, R>(project: (value: T) => Observable<R>): OperatorFunction<T | null, R | null> {
    return (source: Observable<T | null>) => source.pipe(
        switchMap((value: T | null) => value === null
            ? of(null)
            : project(value),
        ),
    );
}

function mapYieldNull<T, R>(project: (value: T) => R): OperatorFunction<T | null, R | null> {
    return (source: Observable<T | null>) => source.pipe(
        map((value: T | null) => value === null
            ? null
            : project(value),
        ),
    );
}

function enrolmentRightHasCURConstraintsFor(enrolmentRight: EnrolmentRight, assessmentItemId: OtmId): boolean {
    return isCourseUnitEnrolmentRight(enrolmentRight) && !!enrolmentRight.enrolmentConstraints
        ?.find(constraint => constraint.assessmentItemId === assessmentItemId)
        ?.allowedCourseUnitRealisationIds;
}

function getMaxNumberOfEnrolmentsByAssItemId(enrolmentRight: EnrolmentRight, assessmentItemId: OtmId): number | null {
    return (enrolmentRight.type === 'CUR_ENROLMENT' && (enrolmentRight as CourseUnitEnrolmentRight).enrolmentConstraints?.find(enrolmentConstraint => enrolmentConstraint?.assessmentItemId === assessmentItemId)?.maxNumberOfEnrolments) ?? null;
}

function getUsedNumberOfEnrolmentsByAssItemId(assessmentItemId: OtmId, usedEnrolmentsList: UsedEnrolments[]): number | null {
    const matchingUsedEnrolments = usedEnrolmentsList.find(usedEnrolments => usedEnrolments.assessmentItemId === assessmentItemId);
    if (!matchingUsedEnrolments) {
        // If there is no enrolments for the enrolmentRights assessmentItem, api doesn't return anything. We need to assume that "used" count is zero.
        return 0;
    }
    return matchingUsedEnrolments.enrolmentCount;
}
