import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FudisValidators } from '@funidata/ngx-fudis';
import { FudisSelectOption } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ValidatablePlan } from 'common-typescript';
import { MaxLength } from 'common-typescript/constants';
import {
    OtmId,
    Plan,
    PlanCourseUnitMessage, PlanCustomStudyDraftMessage,
    PlanGenericMessage,
    PlanMessage,
    PlanModuleMessage,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, forkJoin, take } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { MessageTypes } from '../../constant/messageTypes';
import { PlanOperationTypes } from '../../constant/planOperationTypes';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import {
    planSelectorModalOpener,
    SelectedCourseUnit,
    SelectedCustomStudyDraft,
    SelectedModule,
    SelectedStudy,
} from '../../plan/plan-selector-modal/plan-selector-modal.component';
import { MessageEntityService } from '../../service/message-entity.service';
import { PlanEntityService } from '../../service/plan-entity.service';

import {
    isPlanCourseUnitComment,
    isPlanCustomStudyDraftComment,
    isPlanModuleComment,
    isStudyBoxMessage,
} from './plan-message-utils';

interface NewMessageForm {
    messageType: FormControl<FudisSelectOption<any>>;
    comment: FormControl<string>;
    agreement: FormGroup<{ checkbox: FormControl<boolean> }>;
}

export type MessengerMessage = PlanGenericMessage | PlanModuleMessage | PlanCourseUnitMessage | PlanCustomStudyDraftMessage;

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-plan-messages',
    templateUrl: './sis-plan-messages.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SisPlanMessagesComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.planMessages.downgraded',
        directiveName: 'sisPlanMessages',
    };

    private readonly appErrorHandler = inject(AppErrorHandler);
    private readonly authService = inject(AuthService);
    private readonly fb = inject(SisFormBuilder);
    private readonly messageService = inject(MessageEntityService);
    private readonly openPlanSelectorModal = planSelectorModalOpener();
    private readonly planService = inject(PlanEntityService);

    // We cannot use inject-method for translocoService, because of AngularJS/Angular hybrid app.
    // AngularJS tests in student and tutor won't work if we use inject-method for translocoService.
    // So we have to inject it in the constructor
    constructor(private translocoService: TranslocoService) {
    }

    @Input({ required: true }) plan: Plan;
    @Input({ required: true }) validatablePlan: ValidatablePlan;

    isStudent: boolean;
    authPersonId: OtmId;

    allRelevantMessages: PlanMessage[];
    messagesByPlanId: { [planId: string]: PlanMessage[] };
    messages: PlanMessage[] = [];
    newMessage: Partial<MessengerMessage>;

    expandedPlans: { [id: string]: boolean } = {};
    otherPlansWithoutMessages: Plan[] = [];
    otherPlansWithMessages: Plan[] = [];
    private otherRelevantPlans: Plan[] = [];

    form: FormGroup<NewMessageForm>;
    messageTypeOptions: FudisSelectOption<{ value: string; label: string }>[];

    readonly isStudyBoxMessage = isStudyBoxMessage;
    readonly isPlanCourseUnitComment = isPlanCourseUnitComment;
    readonly isPlanCustomStudyDraftComment = isPlanCustomStudyDraftComment;
    readonly isPlanModuleComment = isPlanModuleComment;

    ngOnInit() {
        this.authPersonId = this.authService.personId();
        this.isStudent = this.plan.userId === this.authPersonId;
        this.resetNewMessage();
        this.buildForm();
        this.loadMessages();
    }

    buildForm(): void {
        combineLatest([
            this.translocoService.selectTranslate('SIS_COMPONENTS.MESSENGER.NEW.TYPE_PLAN_GENERIC'),
            this.translocoService.selectTranslate('SIS_COMPONENTS.MESSENGER.NEW.TYPE_PLAN_TARGETED'),
            this.translocoService.selectTranslate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED'),
            this.translocoService.selectTranslate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.MAX_LENGTH', { maxLength: MaxLength.MAX_LONG_STRING_LENGTH }),
        ]).pipe(
            take(1),
        ).subscribe(([labelPlanGeneric, labelPlanTargeted, requiredError, maxLengthMessage]) => {
            this.messageTypeOptions = [
                { value: 'PlanGeneric', label: labelPlanGeneric },
                { value: 'PlanTargeted', label: labelPlanTargeted },
            ];
            this.form = this.fb.group<NewMessageForm>({
                messageType: this.fb.control(this.messageTypeOptions[0], [FudisValidators.required(requiredError)]),
                comment: this.fb.control('', [
                    FudisValidators.required(requiredError),
                    FudisValidators.maxLength(MaxLength.MAX_LONG_STRING_LENGTH, maxLengthMessage),
                ]),
                agreement: this.fb.group({
                    checkbox: this.fb.control(null, [
                        FudisValidators.required(requiredError),
                        FudisValidators.pattern('^true$', requiredError),
                    ]),
                }),
            });
        });
    }

    loadMessages() {
        const observables = [];

        if (!_.get(this.plan, 'learningOpportunityId')) {
            observables.push(
                this.messageService.findByPlanId(this.plan.id)
                    .pipe(tap((messages) => this.allRelevantMessages = messages)),
            );
        } else {
            observables.push(
                this.planService.getByUserIdAndEducationIdAndLearningOpportunityId(this.plan.rootId, this.plan.userId, this.plan.learningOpportunityId)
                    .pipe(tap((result) => this.otherRelevantPlans = _.filter(result, plan => plan.id !== this.plan.id)),
                    ),
            );

            observables.push(
                this.messageService.findForPlansByEducation(this.plan.userId, this.plan.rootId, this.plan.learningOpportunityId)
                    .pipe(tap((messages) => this.allRelevantMessages = messages)),
            );
        }

        return forkJoin(observables)
            .pipe(take(1), this.appErrorHandler.defaultErrorHandler())
            .subscribe(() => {
                this.messagesByPlanId = _.groupBy(this.allRelevantMessages, 'planId');
                const otherPlansWithMessages = _.filter(this.otherRelevantPlans, plan => _.has(this.messagesByPlanId, plan.id));
                this.expandPlans(otherPlansWithMessages);
                this.otherPlansWithoutMessages = _.filter(this.otherRelevantPlans, plan => !_.has(this.messagesByPlanId, plan.id));
                this.otherPlansWithMessages = _.orderBy(otherPlansWithMessages, plan => _.get(_.first(_.get(this.messagesByPlanId, plan.id)), 'messageTime'), ['desc']);
                this.messages = _.get(this.messagesByPlanId, this.plan.id);
            });
    }

    isTeacherMessage(message: PlanMessage): boolean {
        return message.senderId !== this.plan.userId;
    }

    expandPlans(plans: Plan[]) {
        _.forEach(plans, (plan) => {
            this.expandedPlans[plan.id] = true;
        });
    }

    togglePlanExpandable(planId: OtmId) {
        this.expandedPlans[planId] = !this.expandedPlans[planId];
    }

    resetNewMessage(): void {
        this.newMessage = {
            type: MessageTypes.PLAN_GENERIC,
            studentId: this.plan.userId,
            planId: this.plan.id,
        };
    }

    resetMessageType(): void {
        this.resetNewMessage();
        this.form.controls.messageType.setValue(this.messageTypeOptions?.[0]);
    }

    setMessageTarget(): void {
        this.openPlanSelectorModal({
            title: 'SIS_COMPONENTS.MESSENGER.TARGETED.TITLE',
            description: 'SIS_COMPONENTS.MESSENGER.TARGETED.TITLE_HELP',
            validatablePlan: this.validatablePlan,
        })
            .afterClosed()
            .pipe(filter(Boolean))
            .subscribe((selection: SelectedStudy) => {
                let message;
                const courseUnit = (selection as SelectedCourseUnit)?.courseUnit;
                const customStudyDraft = (selection as SelectedCustomStudyDraft)?.customStudyDraft;
                if (courseUnit) {
                    message = _.cloneDeep(this.newMessage) as PlanCourseUnitMessage;
                    message.type = MessageTypes.PLAN_COURSE_UNIT;
                    message.courseUnitId = courseUnit.id;
                } else if (customStudyDraft) {
                    message = _.cloneDeep(this.newMessage) as PlanCustomStudyDraftMessage;
                    message.type = MessageTypes.PLAN_CUSTOM_STUDY_DRAFT;
                    message.customStudyDraft = customStudyDraft;
                } else {
                    message = _.cloneDeep(this.newMessage) as PlanModuleMessage;
                    const module = (selection as SelectedModule)?.module;
                    message.type = MessageTypes.PLAN_MODULE;
                    message.moduleId = module?.id;
                }
                message.operationType = PlanOperationTypes.COMMENT;
                message.parentModuleId = selection.parentId;
                this.newMessage = message;
            });
    }

    setMessageType(messageType: FudisSelectOption<{ value: string; label: string }>): void {
        this.resetNewMessage();
        if (messageType.value === 'PlanTargeted') {
            this.setMessageTarget();
        }
    }

    saveNewMessage(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
        } else {
            this.newMessage.comment = this.form.value.comment;
            this.messageService.add(this.newMessage as MessengerMessage)
                .pipe(
                    take(1),
                    this.appErrorHandler.defaultErrorHandler(),
                ).subscribe(() => {
                    this.form.reset();
                    this.resetMessageType();
                    this.loadMessages();
                });
        }
    }

    scroll() {
        document.querySelector('#other-plans').scrollIntoView({ behavior: 'smooth' });
    }
}
