import { Inject, Injectable } from '@angular/core';
import {
    AssessmentItemSelection,
    CourseUnit,
    CourseUnitSelection,
    CourseUnitSubstitution,
    OtmId,
    Plan,
} from 'common-typescript/types';
import * as _ from 'lodash-es';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { COMMON_PLAN_SELECTION_SERVICE, COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { LogService } from 'sis-components/error-handler/log.service';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
/**
 * Created directly from the old AngularJS service.
 * There are two differences compared to the old service:
 *  - removeSubstitution and saveSubstitution don't directly edit the plan object that is passed (instead a copy is created)
 *  - updateMyPlan is used instead of saveMyPlan
 * The old service still exists as there are usages that could break due to the small changes here.
 */
export class NewPlanSubstitutionService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.common.service.newPlanSubstitutionService',
        serviceName: 'newPlanSubstitutionService',
    };

    constructor(private logService: LogService,
                @Inject(COMMON_PLAN_SERVICE) private commonPlanService: any,
                @Inject(COMMON_PLAN_SELECTION_SERVICE) private commonPlanSelectionService: any,
    ) {}

    removeSubstitution(courseUnit: CourseUnit, originalPlan: Plan): Promise<any> {
        const plan = _.cloneDeep(originalPlan);
        const courseUnitId = courseUnit?.id;
        const courseUnitSelection = _.find(plan.courseUnitSelections, { courseUnitId });
        this.removeOldSubstitutionLinksAndAssessmentItemSelections(courseUnit, courseUnitSelection, plan);
        courseUnitSelection.substitutedBy = [];

        return this.commonPlanService.updateMyPlan(plan);
    }

    private removeOldSubstitutionLinksAndAssessmentItemSelections(courseUnit: CourseUnit, courseUnitSelection: CourseUnitSelection, plan: Plan): void {
        _.forEach(courseUnitSelection.substitutedBy, (substitutedBy) => {
            _.remove(plan.assessmentItemSelections, (assessmentItemSelection: AssessmentItemSelection) => substitutedBy === assessmentItemSelection.courseUnitId);
            this.removeSubstitutionSubstituteFor(courseUnit.id, substitutedBy, plan);
        });
    }

    private removeSubstitutionSubstituteFor(substitutedCourseUnitId: OtmId, substitutingCourseUnitId: OtmId, plan: Plan): void {
        const courseUnitSelectionForSubstitutingCourseUnit = _.find(plan.courseUnitSelections, { courseUnitId: substitutingCourseUnitId });
        if (courseUnitSelectionForSubstitutingCourseUnit) {
            _.remove(courseUnitSelectionForSubstitutingCourseUnit.substituteFor, { substitutedCourseUnitId });
            if (_.isEmpty(courseUnitSelectionForSubstitutingCourseUnit.substituteFor) &&
                (!courseUnitSelectionForSubstitutingCourseUnit.parentModuleId)) {
                _.remove(plan.courseUnitSelections, { courseUnitId: substitutingCourseUnitId });
            }
        } else {
            this.logService.error('Angular',
                                  {
                                      item: { substitutedCourseUnitId, substitutingCourseUnitId },
                                      message: 'removeSubstitutionSubstituteFor > courseUnitSelectionForSubstitutingCourseUnit not found for substitutingCourseUnitId',
                                  });
        }
    }

    saveSubstitution(courseUnit: CourseUnit, substitutions: CourseUnitSubstitution[], originalPlan: Plan, substituteCourseUnitsByGroupId: { [key: OtmId]: CourseUnit }): Promise<any> {
        const plan = _.cloneDeep(originalPlan);
        const substitutingIds = [];
        const courseUnitSelection = _.find(plan.courseUnitSelections, { courseUnitId: courseUnit.id });

        const courseUnitsRequiringAutomaticSelections: CourseUnit[] = [];

        this.removeOldSubstitutionLinksAndAssessmentItemSelections(courseUnit, courseUnitSelection, plan);
        for (const substitution of substitutions) {
            const courseUnitSubstitution = substitution;

            const substitutingCourseUnitGroupId = _.get(courseUnitSubstitution, 'courseUnitGroupId');
            const substitutingCourseUnit = _.get(substituteCourseUnitsByGroupId, substitutingCourseUnitGroupId);
            const substitutingCourseUnitId = _.get(substitutingCourseUnit, 'id');
            substitutingIds.push(substitutingCourseUnitId);

            const courseUnitSelectionForSubstitutingCourseUnit = _.find(plan.courseUnitSelections, { courseUnitId: substitutingCourseUnitId });
            if (courseUnitSelectionForSubstitutingCourseUnit &&
                 !_.isEmpty(courseUnitSelectionForSubstitutingCourseUnit.substitutedBy)) {
                this.logService.error('Angular',
                                      {
                                          item: { substitutingCourseUnitId, plan, substitutingIds },
                                          message: 'Substitution in course unit which is a substitute already',
                                      });

                return Promise.reject();
            }

            const substituteFor = {
                substitutedCourseUnitId: courseUnit.id,
                substitutedCredits: _.get(courseUnitSubstitution, 'credits'),
            };

            if (courseUnitSelectionForSubstitutingCourseUnit) {
                if (!courseUnitSelectionForSubstitutingCourseUnit.substituteFor) {
                    courseUnitSelectionForSubstitutingCourseUnit.substituteFor = [];
                }
                courseUnitSelectionForSubstitutingCourseUnit.substituteFor.push(substituteFor);

            } else {
                plan.courseUnitSelections.push(
                    {
                        courseUnitId: substitutingCourseUnitId,
                        substitutedBy: [],
                        substituteFor: [substituteFor],
                    } as CourseUnitSelection,
                );
                courseUnitsRequiringAutomaticSelections.push(substitutingCourseUnit);
            }
        }

        // set substituted courseUnitSelection
        courseUnitSelection.completionMethodId = undefined;
        courseUnitSelection.substitutedBy = substitutingIds;
        courseUnitSelection.plannedPeriods = [];

        this.commonPlanSelectionService.makeAutomaticSelectionsForCourseUnits(plan, courseUnitsRequiringAutomaticSelections);

        return this.commonPlanService.updateMyPlan(plan);
    }
}
