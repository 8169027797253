import { Inject, Injectable } from '@angular/core';
import {
    CourseUnitRealisation,
    Enrolment,
    EnrolmentRight,
    OtmId,
} from 'common-typescript/types';
import { Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { isCourseUnitEnrolmentRight } from 'sis-components/open-university/enrolment-right/enrolment-right.type-guards';
import { EnrolmentRightEntityService } from 'sis-components/service/enrolment-right-entity.service';

import { COURSE_UNIT_INFO_MODAL } from '../../ajs-upgraded-modules';
import { OpenUniversityOfferingPreselection } from '../../open-university/study-offering/open-university-offering/open-university-offering.component';

@Injectable({ providedIn: 'root' })
export class StudentCourseUnitInfoModalService {
    constructor(
        private readonly _enrolmentRightEntityService: EnrolmentRightEntityService,
        @Inject(COURSE_UNIT_INFO_MODAL) private readonly _courseUnitInfoModal: any,
        private readonly _appErrorHandler: AppErrorHandler,
    ) { }

    /**
     * If the enrolment is an open university enrolment,
     * open the course unit info modal on the open university offering tab with the purchased product pre-selected
     * and the enrolled assessment item and course unit realisation focused.
     * Otherwise, opens the completion methods tab.
     */
    openCourseUnitModalForEnrolment(
        courseUnitId: OtmId,
        courseUnitRealisation: CourseUnitRealisation,
        enrolment: Enrolment,
    ): void {
        this.getOpenUniversityProductId(enrolment).pipe(
            take(1),
            map((openUniversityProductId: OtmId | null) => {
                if (openUniversityProductId) {
                    this._courseUnitInfoModal.showOpenUniversityPurchase(
                        courseUnitId,
                        courseUnitRealisation,
                        <OpenUniversityOfferingPreselection>{
                            selectedOpenUniversityProductId: openUniversityProductId,
                            // Enrolment.assessmentItemId can be null, but it's OK
                            focusedAssessmentItemId: enrolment.assessmentItemId,
                            focusedCourseUnitRealisationId: courseUnitRealisation.id,
                        },
                    );
                } else {
                    this._courseUnitInfoModal.showCourseUnitRealisationForCourseUnit(
                        courseUnitId,
                        courseUnitRealisation,
                    );
                }
            }),
            this._appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }

    private getOpenUniversityProductId(enrolment: Enrolment): Observable<OtmId | null> {
        if (!enrolment.enrolmentRightId) {
            return of(null);
        }

        return this._enrolmentRightEntityService.getById(enrolment.enrolmentRightId).pipe(
            map((enrolmentRight: EnrolmentRight) => (isCourseUnitEnrolmentRight(enrolmentRight) && enrolmentRight.openUniversityProductId) ?? null),
        );
    }
}
