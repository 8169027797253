import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PrivatePerson } from 'common-typescript/types';
import { basicInfoUtils, ClassifiedVisibility } from 'sis-components/person/utils/basic-info-utils';

@Component({
    selector: 'app-basic-info-personal-info-details',
    templateUrl: './basic-info-personal-info-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoPersonalInfoDetailsComponent {

    @Input() person: PrivatePerson;
    @Input() classifiedVisibility: ClassifiedVisibility;
    @Output() classifiedVisibilityChange = new EventEmitter<ClassifiedVisibility>();

    toggleFieldVisibility(fieldName: string): void {
        this.classifiedVisibilityChange.emit(basicInfoUtils.toggleFieldVisibility(fieldName, this.classifiedVisibility));
    }

    isClassifiedInfoVisible(fieldName: string): boolean {
        return basicInfoUtils.isClassifiedInfoVisible(fieldName, this.classifiedVisibility, this.person);
    }

    isClassified(fieldName: string): boolean {
        return basicInfoUtils.isClassified(fieldName, this.person);
    }
}

