import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { dateUtils } from 'common-typescript/constants';
import { CourseUnit, CourseUnitRealisation, Enrolment, LocalDateTimeRange } from 'common-typescript/types';
import moment from 'moment';
import { catchError, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Breakpoint, BreakpointService } from 'sis-components/service/breakpoint.service';

import { EnrolmentStudentService } from '../../../common/service/enrolment-student.service';

@Component({
    selector: 'app-course-unit-realisation-expandable',
    templateUrl: './course-unit-realisation-expandable.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitRealisationExpandableComponent implements OnInit {

    @Input() courseUnit: CourseUnit;

    @Input() set courseUnitRealisation(courseUnitRealisation: CourseUnitRealisation) {
        if (this._courseUnitRealisation?.id !== courseUnitRealisation?.id) {
            this.enrolment$ = (courseUnitRealisation ? this.enrolmentStudentService.findEnrolment(courseUnitRealisation.id) : of(null))
                .pipe(
                    filter(enrolment => !!enrolment?.state && enrolment.state !== 'NOT_ENROLLED'),
                    catchError(() => of(null)),
                );
        }
        this._courseUnitRealisation = courseUnitRealisation;

        this.effectiveEnrolmentPeriod = !courseUnitRealisation?.enrolmentPeriod ? null : {
            startDateTime: courseUnitRealisation.enrolmentPeriod.startDateTime,
            endDateTime: courseUnitRealisation.lateEnrolmentEnd ?? courseUnitRealisation.enrolmentPeriod.endDateTime,
        };
    }

    get courseUnitRealisation(): CourseUnitRealisation {
        return this._courseUnitRealisation;
    }

    @Input() enrolmentLimitReached = false;

    @Output() enrolClicked = new EventEmitter<void>();

    effectiveEnrolmentPeriod: LocalDateTimeRange;
    enrolment$: Observable<Enrolment>;
    isMobileView$: Observable<boolean>;

    private _courseUnitRealisation: CourseUnitRealisation;

    constructor(
        private breakpointService: BreakpointService,
        private enrolmentStudentService: EnrolmentStudentService,
    ) {}

    ngOnInit(): void {
        this.isMobileView$ = this.breakpointService.breakpoint$
            .pipe(map(breakpoint => breakpoint < Breakpoint.MD));
    }

    isEnrolmentAllowed(): boolean {
        return !this.enrolmentLimitReached && dateUtils.rangeContains(moment(), this.effectiveEnrolmentPeriod);
    }
}
