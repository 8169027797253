import angular from 'angular';
import * as _ from 'lodash-es';
import moment from 'moment';
import { PlanValidationTs } from 'common-typescript';
import { StudentCustomStudyDraftModalComponent } from '../custom-study-draft-modal/custom-study-draft-modal.component.ts';
import planModuleTpl from './planModule.tpl.html';
(function () {
  PlanModuleController.$inject = ["$scope", "$q", "$state", "PlanState", "uiContext", "moduleType", "validAttainmentFilterService", "colorService", "planStudyRightService", "moduleAttainmentApplicationService", "defaultPromiseHandler", "$rootScope", "minorStudyRightService", "planValidationResultHelper", "commonModuleService", "commonAttainmentService", "attainmentService", "errorService", "modalService", "moduleInfoModalServiceNg"];
  angular.module('student.plan').component('planModule', {
    template: planModuleTpl,
    bindings: {
      module: '<',
      validatablePlan: '<',
      planValidationResult: '<',
      educationOptions: '<',
      level: '<',
      studyRight: '<',
      applyForGraduation: '&?',
      isGraduationAllowed: '<?',
      isGraduationRequestedForModule: '<?',
      canApplyForCustomAttainments: '<'
    },
    controller: PlanModuleController
  });
  function PlanModuleController(
  // NOSONAR
  $scope, $q, $state, PlanState, uiContext, moduleType, validAttainmentFilterService, colorService, planStudyRightService, moduleAttainmentApplicationService, defaultPromiseHandler, $rootScope, minorStudyRightService, planValidationResultHelper, commonModuleService, commonAttainmentService, attainmentService, errorService, modalService, moduleInfoModalServiceNg) {
    const $ctrl = this;
    $ctrl.$onInit = () => {
      $ctrl.moduleAttainment = $ctrl.validatablePlan.getModuleAttainment(_.get($ctrl.module, 'id'));
      $ctrl.isAboutToExpire = isAboutToExpire($ctrl.module);
      $ctrl.showModuleInfoIcon = showModuleInfoIcon();
      $ctrl.showCredits = $ctrl.module.type !== moduleType.education;
      $ctrl.targetCredits = _.result($ctrl.module, 'targetCredits');
    };
    $ctrl.$onChanges = () => {
      $ctrl.moduleAttainment = $ctrl.validatablePlan.getModuleAttainment(_.get($ctrl.module, 'id'));
      updateChildStudies();
      $ctrl.classes = $ctrl.getClasses();
      $ctrl.studyRightState = $ctrl.getStudyRightState();
      $ctrl.isEducationOption = !!$ctrl.studyRightState;
      $ctrl.isInvalidSelection = isInvalidSelection();
      $ctrl.hasModuleContentApproval = hasModuleContentApproval();
      $ctrl.isInvalidAccordingToModuleContentApproval = isInvalidAccordingToModuleContentApproval();
      $ctrl.hasPersonalizedPhase = hasPersonalizedPhase();
      $ctrl.stateImpliesError = $ctrl.studyRightState !== 'CONFIRMED';
      $ctrl.canGraduate = canGraduate();
      $ctrl.isModuleAttained = isModuleAttained();
      $ctrl.planState = getPlanState();
      $ctrl.contextualState = getContextualState();
      $ctrl.showState = $ctrl.planState !== PlanState.ATTAINED || $ctrl.isModuleAttained;
      $ctrl.plannedCredits = getPlannedCredits();
      $ctrl.attainedCredits = getAttainedCredits();
      $ctrl.isMinorSelection = isMinorSelection();
      if ($ctrl.isMinorSelection) {
        $ctrl.minorSelectionViewState = getMinorSelectionViewState();
        $ctrl.approvedMinorSelection = getApprovedMinorSelection();
      }
    };
    $scope.$on('studyRightChanged', $ctrl.$onChanges);
    $ctrl.getPlanValidationResult = () => _.get(uiContext, 'planContext.planValidationResult');
    $ctrl.getModuleValidationResult = () => planValidationResultHelper.getModuleValidationResult(_.get($ctrl.module, 'id'), $ctrl.getPlanValidationResult());
    $ctrl.moduleAttainmentApplicationWrapper = {};
    $ctrl.toggleModuleSelections = toggleModuleSelections;
    $ctrl.openModuleSelections = openModuleSelections;
    $ctrl.isSelected = isSelected;
    $ctrl.getPlannedCredits = getPlannedCredits;
    $ctrl.getPlanState = getPlanState;
    $ctrl.getAttainedCredits = getAttainedCredits;
    $ctrl.showTargetCredits = showTargetCredits;
    $ctrl.toggleCollapsed = toggleCollapsed;
    $ctrl.isCollapsed = isCollapsed;
    $ctrl.handleKeyPress = handleKeyPress;
    $ctrl.handleStatusCellClick = handleStatusCellClick;
    $ctrl.getStudyRightState = () => {
      const matchingEducationOption = planStudyRightService.getMatchingEducationOption($ctrl.module.groupId, $ctrl.educationOptions, $ctrl.validatablePlan);
      // eslint-disable-next-line max-len
      if (!!matchingEducationOption && matchingEducationOption.isInPlan === true && matchingEducationOption.isInPlanAsMinor === false) {
        return matchingEducationOption.studyRightState;
      }
      return undefined;
    };

    // Cache the custom attainment expiry dates, as calculating them in each digest loop is expensive
    $ctrl.customCourseUnitAttainmentExpiryDates = {};
    $ctrl.getCustomCourseUnitAttainment = id => $ctrl.validatablePlan.getCustomCourseUnitAttainment(id);

    // eslint-disable-next-line max-len
    $ctrl.getCustomModuleAttainment = customModuleAttainmentId => $ctrl.validatablePlan.getCustomModuleAttainment(customModuleAttainmentId);
    $ctrl.moduleTargetStates = moduleTargetStates;
    $ctrl.hasPlanningOfCourseUnit = uiContext.hasPlanningOfCourseUnit;
    $ctrl.planContext = uiContext.planContext;
    $ctrl.openModuleInfo = openModuleInfo;
    $ctrl.showActionRequiredIcon = () => {
      if (!isCollapsed()) {
        return false;
      }
      const validationResult = $ctrl.getModuleValidationResult();
      const invalidStates = [PlanState.INVALID, PlanState.INCOMPLETE];
      return !_.includes(invalidStates, validationResult?.contextualState) && _.includes(invalidStates, validationResult?.state);
    };
    $ctrl.openCustomStudyDraftInfo = customStudyDraft => {
      const options = {
        customAttainmentApplicationsDisabled: !$ctrl.canApplyForCustomAttainments,
        priorLearningInclusionApplicationHidden: !uiContext.priorLearningInclusionApplicationAllowed,
        customAttainmentApplicationHidden: !uiContext.customAttainmentApplicationAllowed
      };
      const studentId = _.get($ctrl.validatablePlan, 'plan.userId');
      modalService.open(StudentCustomStudyDraftModalComponent, {
        studentId,
        customStudyDraft,
        options
      }, {
        size: 'sm'
      }).result.then(result => {
        const operation = _.get(result, 'operation');
        const customStudyDraftId = customStudyDraft.id;
        const planId = _.get($ctrl.validatablePlan, 'plan.id');
        const stateParams = {
          customStudyDraftId,
          planId
        };
        if (operation === 'INCLUSION_APPLICATION') {
          $state.go('student.logged-in.profile.applications.create-prior-learning-inclusion-application', stateParams);
        }
        if (operation === 'CUSTOM_ATTAINMENT_APPLICATION') {
          $state.go('student.logged-in.profile.applications.create-custom-attainment-application', stateParams);
        }
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    };
    $ctrl.getCourseUnitSelection = courseUnit => _.get($ctrl.validatablePlan.courseUnitIdSelectionMap, courseUnit.id);
    $ctrl.isCourseUnitSubstituted = courseUnit => $ctrl.validatablePlan.isSubstituted(courseUnit);
    $ctrl.getSubstitutingCourseUnits = courseUnit => _.map($ctrl.validatablePlan.getSubstitutedBy(courseUnit), courseUnitId => $ctrl.validatablePlan.getCourseUnit(courseUnitId));
    $ctrl.getCustomCourseUnitAttainmentExpiryDate = attainmentId => {
      if (_.has($ctrl.customCourseUnitAttainmentExpiryDates, attainmentId)) {
        return $ctrl.customCourseUnitAttainmentExpiryDates[attainmentId];
      }
      const attainment = $ctrl.getCustomCourseUnitAttainment(attainmentId);
      const allAttainments = $ctrl.validatablePlan.getAllAttainments();
      if (attainment && attainment.expiryDate && allAttainments && validAttainmentFilterService.isAttainmentAboutToExpire(attainment) && !validAttainmentFilterService.isAttached(attainment, allAttainments)) {
        const expiryDate = moment(attainment.expiryDate);
        $ctrl.customCourseUnitAttainmentExpiryDates[attainment.id] = expiryDate;
        return expiryDate;
      }
      return null;
    };
    function canGraduate() {
      return $ctrl.module.type === moduleType.degreeProgramme && _.includes([PlanState.PARTS_ATTAINED, PlanState.APPROVED_PARTS_ATTAINED, PlanState.APPROVED_CONDITIONALLY_PARTS_ATTAINED], getPlanState());
    }

    // To trigger opening of right bar with Enter and Spacebar
    function handleKeyPress(event) {
      if (event.keyCode === 32 || event.keyCode === 13) {
        event.preventDefault();
        event.target.click();
      }
    }
    function handleStatusCellClick(event) {
      toggleModuleSelections();
      setFocus(event);
    }

    /*
    When opening the right bar, we set the focus to it's close button.
    To return focus to same position when closing with keyboard, we save the clicked cell ID to session storage.
    This is used in plan.component.js.
    */
    function setFocus(event) {
      const clickedStatusCell = event.currentTarget.getAttribute('id');
      sessionStorage.setItem('clickedStatusCell', clickedStatusCell);
      const rightBarCloseButton = document.getElementById('rightBarCloseButton');
      rightBarCloseButton.focus();

      // If right bar is open and focusing first time didn't work, let's try that after small delay.
      if (uiContext.hasActiveSelection() && document.activeElement.id !== 'rightBarCloseButton') {
        setTimeout(() => {
          rightBarCloseButton.focus();
        }, 100);
      }
    }
    function toggleModuleSelections() {
      uiContext.closeFreeEdit();
      if ($ctrl.isCollapsed($ctrl.module)) {
        $ctrl.toggleCollapsed($ctrl.module);
      }
      if (!$ctrl.validatablePlan.isModuleAttained($ctrl.module.id)) {
        uiContext.toggleModuleSelections($ctrl.module);
        $rootScope.$emit('closeLeftBar');
        if ($ctrl.hasPlanningOfCourseUnit()) {
          $ctrl.openModuleSelections();
        }
      }
    }
    function openModuleSelections() {
      if ($ctrl.isCollapsed($ctrl.module)) {
        $ctrl.toggleCollapsed($ctrl.module);
      }
      if (!$ctrl.validatablePlan.isModuleAttained($ctrl.module.id)) {
        uiContext.openModuleSelections($ctrl.module);
        $rootScope.$emit('closeLeftBar');
      }
    }
    $ctrl.cancelPlacingReturnToLeftBar = () => {
      $rootScope.$emit('openLeftBarCloseRightBar');
    };
    $ctrl.getClasses = () => {
      const classes = [`tree-list-item-level-${$ctrl.level}`];
      if ($ctrl.level > 1) {
        classes.push('tree-list-item', 'clearfix');
      }
      return classes.join(' ');
    };
    $ctrl.onApplyForGraduation = () => {
      if (!isSelected($ctrl.module)) {
        toggleModuleSelections();
      }
      if ($ctrl.applyForGraduation && !($ctrl.isGraduationRequestedForModule && $ctrl.isGraduationRequestedForModule($ctrl.module))) {
        $ctrl.applyForGraduation();
      }
    };
    function isSelected(module) {
      return uiContext.isSelected(module);
    }
    function getAttainedCredits() {
      if ($ctrl.module.type === moduleType.education) {
        return false;
      }
      const moduleAttainment = $ctrl.validatablePlan.getModuleAttainment($ctrl.module.id);
      if (moduleAttainment) {
        return moduleAttainment.credits;
      }
      return _.get($ctrl.getModuleValidationResult(), 'attainedCredits');
    }
    function getPlannedCredits() {
      return _.get($ctrl.getModuleValidationResult(), 'plannedCredits');
    }
    function getPlanState() {
      const planValidationResult = _.get(uiContext, 'planContext.planValidationResult');
      return PlanValidationTs.getPlanStateForModule($ctrl.module, $ctrl.validatablePlan, planValidationResult);
    }
    function getContextualState() {
      const planValidationResult = _.get(uiContext, 'planContext.planValidationResult');
      return PlanValidationTs.getContextualStateForModule($ctrl.module, $ctrl.validatablePlan, planValidationResult);
    }
    function showTargetCredits() {
      if ($ctrl.module.type === moduleType.education) {
        return false;
      }
      const state = $ctrl.getPlanState();
      return state && state !== PlanState.ATTAINED;
    }
    function toggleCollapsed() {
      uiContext.toggleCollapsed($ctrl.module);
    }
    function isCollapsed() {
      return uiContext.isCollapsed($ctrl.module);
    }
    function moduleTargetStates() {
      return uiContext.getModuleTargetStates($ctrl.module.id);
    }
    function openModuleInfo($event) {
      const isPrimaryPlan = _.get($ctrl.validatablePlan, 'plan.primary', false);
      const planId = _.get($ctrl.validatablePlan, 'plan.id');
      const organisationId = _.get($ctrl.studyRight, 'organisationId');
      $ctrl.resolveDataForModuleAttainmentApplication().then(moduleAttainmentApplicationWrapper => {
        moduleInfoModalServiceNg.open({
          moduleId: $ctrl.module.id,
          isPrimaryPlan,
          organisationId,
          planId,
          moduleAttainmentApplicationWrapper,
          sectionExpandableOpenSettings: {
            basics: true
          }
        }).subscribe({
          next: () => {/* Modal was closed */},
          error: () => {/* Modal was closed */}
        });
      }, error => {
        // Pass error from GET applications to error handler but open modal regardless.
        moduleInfoModalServiceNg.open({
          moduleId: $ctrl.module.id,
          isPrimaryPlan,
          organisationId,
          planId,
          sectionExpandableOpenSettings: {
            basics: true
          }
        }).subscribe({
          next: () => {/* Modal was closed */},
          error: () => {/* Modal was closed */}
        });
        return $q.reject(error);
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
      $event.stopPropagation();
    }

    /**
     * Returns undefined or object containing 'mode' and possibly containing 'applications' and
     * 'applyForModuleAttainment'. This function also binds this 'applicationWrapper' to controller so that it can
     * be updated after sending application.
     *
     * mode is APPLICATION_IS_SENT if requested applications exist regardless of anything else.
     * mode is FLAT if plan state is valid for sending application, existing requested application do not exist,
     *   study right exists and there are no child modules.
     * mode is RECURSIVE if plan state is valid for sending application, existing requested application do not exist,
     *   study right exists and there are child modules.
     * mode is STUDY_RIGHT_NOT_FOUND if study right and applications do not exist
     * return undefined if module is attained.
     * return undefined in other cases.
     * @returns promise
     */
    $ctrl.resolveDataForModuleAttainmentApplication = () => {
      if ($ctrl.module.type !== moduleType.studyModule) {
        return $q.resolve();
      }
      const planState = $ctrl.getPlanState();
      if (planState === PlanState.ATTAINED || $ctrl.module.graded === false) {
        return $q.resolve();
      }
      return moduleAttainmentApplicationService.searchExistingApplicationsForModule($ctrl.module).then(allApplications => {
        const applications = _.filter(allApplications, application => _.includes(['IN_HANDLING', 'REQUESTED'], application.state));
        const validForSending = moduleAttainmentApplicationService.planStateIsValidForSendingApplication(planState);
        if (!validForSending && _.isEmpty(applications)) {
          return $q.resolve();
        }
        const childModules = $ctrl.validatablePlan.getSelectedModulesById($ctrl.module);
        let mode = _.isEmpty(childModules) ? 'FLAT' : 'RECURSIVE';
        const studyRightExists = !!_.get($ctrl, 'studyRight');
        if (!studyRightExists) {
          mode = 'STUDY_RIGHT_NOT_FOUND';
        }
        if (!_.isEmpty(applications)) {
          // This case is on top priority. Student should be able to see own requested applications.
          mode = 'APPLICATION_IS_SENT';
        }
        const moduleAttainmentApplicationWrapper = {
          mode,
          applications
        };
        if (_.isEmpty(applications) && studyRightExists && validForSending) {
          // Don't expose apply callback if requested application exists
          moduleAttainmentApplicationWrapper.applyForModuleAttainment = applyForModuleAttainment;
        }
        $ctrl.moduleAttainmentApplicationWrapper = moduleAttainmentApplicationWrapper;
        return moduleAttainmentApplicationWrapper;
      });
    };

    /**
     * makes sure that the module is not attained before sending a new application.
     * in case there is an attainment, show an error message and force a refresh of the page
     * the attainment should then correctly be found in the plan
     */
    function applyForModuleAttainment() {
      return checkForExistingAttainments().then(hasAttainmentForModule => {
        if (hasAttainmentForModule) {
          showError();
          return null;
        }
        return sendApplication();
      });
    }
    function showModuleInfoIcon() {
      return _.includes([moduleType.degreeProgramme, moduleType.studyModule], $ctrl.module.type);
    }
    function isInvalidSelection() {
      return _.get($ctrl.getModuleValidationResult(), 'invalidSelection') === true;
    }
    function isInvalidAccordingToModuleContentApproval() {
      return _.get($ctrl.getModuleValidationResult(), 'invalidAccordingToModuleContentApproval') === true;
    }
    function hasModuleContentApproval() {
      return _.get($ctrl.getModuleValidationResult(), 'invalidAccordingToModuleContentApproval') !== undefined;
    }
    function isAboutToExpire(module) {
      const moduleAttainment = $ctrl.validatablePlan.getModuleAttainment(module.id);
      // eslint-disable-next-line max-len
      return moduleAttainment && validAttainmentFilterService.isAttainmentAboutToExpire(moduleAttainment) && !isParentAttained(module);
    }
    function isParentAttained(module) {
      const moduleAttainment = $ctrl.validatablePlan.getModuleAttainment(module.id);
      return validAttainmentFilterService.isAttached(moduleAttainment, $ctrl.validatablePlan.getAllAttainments());
    }
    function isModuleAttained() {
      return $ctrl.validatablePlan.isModuleAttained($ctrl.module.id);
    }
    function isMinorSelection() {
      return minorStudyRightService.isMinorSelection($ctrl.module, $ctrl.validatablePlan);
    }
    function getApprovedMinorSelection() {
      return minorStudyRightService.getApprovedMinorSelection($ctrl.module, $ctrl.studyRight, $ctrl.validatablePlan);
    }
    function getMinorSelectionViewState() {
      return minorStudyRightService.getViewStateForModule($ctrl.module, $ctrl.studyRight, $ctrl.validatablePlan);
    }
    function updateChildStudies() {
      if (!$ctrl.moduleAttainment) {
        $ctrl.selectedCourseUnits = $ctrl.validatablePlan.getSelectedCourseUnitsUnderModule($ctrl.module);
        $ctrl.selectedModules = $ctrl.validatablePlan.getSelectedModulesUnderModule($ctrl.module);
        if ($ctrl.module.id === $ctrl.validatablePlan.rootModule.id && _.every($ctrl.selectedModules, {
          type: 'DegreeProgramme'
        })) {
          $ctrl.selectedModules = commonModuleService.getDegreeProgrammesOrderedByTypeUrn($ctrl.selectedModules);
        }
        $ctrl.selectedCustomCourseUnitAttainmentIds = _.uniq($ctrl.validatablePlan.getSelectedCustomCourseUnitAttainmentIdsUnderModule($ctrl.module));
        $ctrl.selectedCustomModuleAttainmentIds = _.uniq($ctrl.validatablePlan.getSelectedCustomModuleAttainmentIdsUnderModule($ctrl.module));
        $ctrl.selectedCustomStudyDrafts = $ctrl.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId($ctrl.module.id);
      } else {
        // eslint-disable-next-line max-len
        const childStudies = commonAttainmentService.getChildStudiesFromAttainmentNodes($ctrl.moduleAttainment.nodes, $ctrl.validatablePlan);
        $ctrl.selectedCourseUnits = childStudies.courseUnits;
        $ctrl.selectedModules = childStudies.modulesAndGroupNodes;
        $ctrl.selectedCustomCourseUnitAttainmentIds = childStudies.customCourseUnitAttainmentIds;
        $ctrl.selectedCustomModuleAttainmentIds = childStudies.customModuleAttainmentIds;
      }
    }
    function hasPersonalizedPhase() {
      return !!_.get($ctrl.studyRight, 'personalizedSelectionPath.phase1');
    }
    function checkForExistingAttainments() {
      return attainmentService.getMyValidAttainments(true).then(validAttainments => {
        const attainmentsForCurrentModule = validAttainments.filter(attainment => attainment.moduleId === $ctrl.module.id);
        return attainmentsForCurrentModule.length > 0;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
    function showError() {
      errorService.showTranslatedError({
        titleKey: 'MODULE_INFO.MODULE_ATTAINMENT_HANDLED_ERROR.TITLE',
        messageKey: 'MODULE_INFO.MODULE_ATTAINMENT_HANDLED_ERROR.TEXT'
      });
    }
    function sendApplication() {
      // eslint-disable-next-line max-len
      return moduleAttainmentApplicationService.sendApplication($ctrl.module, $ctrl.validatablePlan, $ctrl.studyRight, $ctrl.planValidationResult).then(sentApplication => {
        // Update the wrapper object so that modal will receive updated information and sending application.
        $ctrl.moduleAttainmentApplicationWrapper.applications = [sentApplication];
        $ctrl.moduleAttainmentApplicationWrapper.mode = 'APPLICATION_IS_SENT';
        delete $ctrl.moduleAttainmentApplicationWrapper.applyForModuleAttainment;
        return sentApplication;
      }).catch(defaultPromiseHandler.loggingRejectedPromiseHandler);
    }
  }
})();