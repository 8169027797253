import {
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomStudyDraft, OtmId, UniversitySettings, Workflow } from 'common-typescript/types';
import * as _ from 'lodash-es';
import { combineLatest, map, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { UniversityService } from 'sis-components/service/university.service';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

export interface StudentCustomStudyDraftOptions {
    priorLearningInclusionApplicationHidden?: boolean;
    customAttainmentApplicationHidden?: boolean;
    internshipCustomAttainmentApplicationHidden?: boolean;
    customAttainmentApplicationsDisabled?: boolean;
}

export interface StudentCustomStudyDraftModalValues {
    options: StudentCustomStudyDraftOptions;
    studentId: OtmId;
    customStudyDraft: CustomStudyDraft;
}
interface TemplateData {
    studyDraft: CustomStudyDraft
    viewOptions: StudentCustomStudyDraftOptions;
    homeUniversityName: string;
    isNewApplicationAllowed: boolean;
    isSuggestButtonVisible: boolean;
}

@Component({
    selector: 'app-student-custom-study-draft-modal',
    templateUrl: './custom-study-draft-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentCustomStudyDraftModalComponent implements OnInit {

    suggestAttainment: boolean;
    data$: Observable<TemplateData>;

    constructor(public modal: NgbActiveModal,
                private universityService: UniversityService,
                private appErrorHandler: AppErrorHandler,
                private workflowService: WorkflowEntityService,
                @Inject(ModalService.injectionToken) public values: StudentCustomStudyDraftModalValues) {
    }

    ngOnInit(): void {
        this.suggestAttainment = false;

        if (this.values.customStudyDraft && this.values.studentId) {
            const universitySettings$ = this.createUniversitySettingsObservable();
            const workflows$ = this.createStudentWorkflowObservable(this.values.studentId, this.values.customStudyDraft.id);
            this.data$ = this.createDataObservable(universitySettings$, workflows$);
        }
    }

    createDataObservable(universitySettings$: Observable<UniversitySettings>, workflows$: Observable<Workflow[]>): Observable<TemplateData> {
        return combineLatest([universitySettings$, workflows$]).pipe(
            map(([universitySettings, workflows]) => {
                const studyDraft = this.values.customStudyDraft;
                const viewOptions = this.resolveCustomStudyDraftOptions(this.values.options, universitySettings);
                const homeUniversityName = this.universityService.getCurrentUniversity().name;
                const isNewApplicationAllowed = this.isNewApplicationAllowed(workflows, viewOptions.customAttainmentApplicationsDisabled);
                const isSuggestButtonVisible = this.isSuggestButtonVisible(viewOptions, isNewApplicationAllowed);
                return { studyDraft, viewOptions, homeUniversityName, isNewApplicationAllowed, isSuggestButtonVisible };
            }));
    }

    createUniversitySettingsObservable(): Observable<UniversitySettings> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    createStudentWorkflowObservable(studentId: string, customStudyDraftId: string): Observable<Workflow[]> {
        return this.workflowService.getStudentWorkflowsMatchingCustomStudyDraft(studentId, customStudyDraftId)
            .pipe(
                take(1),
                map(workflows => workflows.filter(workflow => _.includes(['REQUESTED', 'ACCEPTED', 'IN_HANDLING', 'CONDITIONAL'], workflow.state))),
                this.appErrorHandler.defaultErrorHandler(),
            );
    }

    cancel(): void {
        this.modal.dismiss();
    }

    startAttainmentApplicationProcess(workflowOperationType: string): void {
        this.modal.close({
            operation: workflowOperationType,
        });

    }

    isNewApplicationAllowed(workflows: Workflow[], customAttainmentApplicationsDisabled: boolean): boolean {
        return workflows.length < 1 && !(customAttainmentApplicationsDisabled);
    }

    isSuggestButtonVisible(options: StudentCustomStudyDraftOptions, isNewApplicationAllowed: boolean): boolean {
        if (options.priorLearningInclusionApplicationHidden && options.customAttainmentApplicationHidden && options.internshipCustomAttainmentApplicationHidden) {
            return false;
        }
        return isNewApplicationAllowed;
    }

    resolveCustomStudyDraftOptions(options: StudentCustomStudyDraftOptions, universitySettings: UniversitySettings): StudentCustomStudyDraftOptions {
        return {
            priorLearningInclusionApplicationHidden: _.defaultTo(options.priorLearningInclusionApplicationHidden,
                                                                 !(_.get(universitySettings, 'frontendFeatureToggles.priorLearningInclusionApplicationEnabled', true))),
            customAttainmentApplicationHidden: _.defaultTo(options.customAttainmentApplicationHidden,
                                                           !(_.get(universitySettings, 'frontendFeatureToggles.customCourseCreditApplicationEnabled', true))),
            internshipCustomAttainmentApplicationHidden: _.defaultTo(options.internshipCustomAttainmentApplicationHidden,
                                                                     !(_.get(universitySettings, 'frontendFeatureToggles.internshipCustomAttainmentApplicationEnabled', false))),
            customAttainmentApplicationsDisabled: _.defaultTo(options.customAttainmentApplicationsDisabled, true),
        };
    }
}
