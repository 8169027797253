@if (ruleUtils.isCourseUnitCountRule(rule)) {
  <sis-course-unit-count-rule [rule]="rule"
                              [parentModule]="parentModule"
                              [validatablePlan]="validatablePlan"
                              [planValidationResult]="planValidationResult"
                              [blockLevel]="blockLevel"
                              [planStateObject]="planStateObject"
                              [planData]="planData"
                              [uiStateObject]="uiStateObject">

  </sis-course-unit-count-rule>
} @else if (ruleUtils.isCreditsRule(rule)) {
  <sis-credits-rule [creditsRule]="rule"
                    [parentModule]="parentModule"
                    [validatablePlan]="validatablePlan"
                    [planValidationResult]="planValidationResult"
                    [blockLevel]="blockLevel"
                    [planStateObject]="planStateObject"
                    [planData]="planData"
                    [uiStateObject]="uiStateObject">

  </sis-credits-rule>
} @else if (ruleUtils.isCompositeRule(rule)) {
  <sis-composite-rule [compositeRule]="rule"
                      [parentModule]="parentModule"
                      [validatablePlan]="validatablePlan"
                      [planValidationResult]="planValidationResult"
                      [blockLevel]="blockLevel"
                      [planStateObject]="planStateObject"
                      [planData]="planData"
                      [uiStateObject]="uiStateObject">

  </sis-composite-rule>
} @else if (ruleUtils.isCourseUnitRule(rule)) {
  <sis-course-unit-rule [rule]="rule"
                        [parentModule]="parentModule"
                        [validatablePlan]="validatablePlan"
                        [planValidationResult]="planValidationResult"
                        [blockLevel]="blockLevel"
                        [planStateObject]="planStateObject"
                        [planData]="planData"
                        [uiStateObject]="uiStateObject">

  </sis-course-unit-rule>
} @else if (ruleUtils.isModuleRule(rule)) {
  <sis-module-rule [rule]="rule"
                   [parentModule]="parentModule"
                   [validatablePlan]="validatablePlan"
                   [planValidationResult]="planValidationResult"
                   [blockLevel]="blockLevel" [planStateObject]="planStateObject"
                   [planData]="planData"
                   [uiStateObject]="uiStateObject">

  </sis-module-rule>
} @else if (ruleUtils.isAnyCourseUnitRule(rule)) {
  <sis-any-course-unit-rule [rule]="rule"
                            [parentModule]="parentModule"
                            [validatablePlan]="validatablePlan"
                            [planValidationResult]="planValidationResult"
                            [blockLevel]="blockLevel"
                            [planStateObject]="planStateObject"
                            [planData]="planData"
                            [uiStateObject]="uiStateObject">

  </sis-any-course-unit-rule>
} @else if (ruleUtils.isAnyModuleRule(rule)) {
  <sis-any-module-rule [rule]="rule"
                       [parentModule]="parentModule"
                       [validatablePlan]="validatablePlan"
                       [planValidationResult]="planValidationResult"
                       [blockLevel]="blockLevel"
                       [planStateObject]="planStateObject"
                       [planData]="planData"
                       [uiStateObject]="uiStateObject"
  ></sis-any-module-rule>
}
