<ng-container *transloco="let t">
  <fudis-expandable [level]="2" [title]="t('FREE_EDIT.CUSTOM_STUDY_DRAFT_EXPANDABLE.TITLE')" [closed]="false">
    <ng-template fudisExpandableContent>
      <fudis-body-text>{{ t('FREE_EDIT.CUSTOM_STUDY_DRAFT_EXPANDABLE.CUSTOM_STUDY_DRAFTS_INFORMATION') }}</fudis-body-text>
      <sis-button
        [label]="t('FREE_EDIT.CUSTOM_STUDY_DRAFT_EXPANDABLE.CUSTOM_STUDY_DRAFTS_READ_MORE')"
        (clicked)="openReadMoreAboutStudyDraftModal()"
        link="true">
      </sis-button>
      <div class="sis-pt-md sis-pb-xs">
        <fudis-button
          [label]="t('FREE_EDIT.CUSTOM_STUDY_DRAFT_EXPANDABLE.ADD_NEW_CUSTOM_STUDY_DRAFT')"
          [variant]="'secondary'"
          [size]="'small'"
          [disabled]="false"
          (handleClick)="openAddStudyDraftModal()"
        ></fudis-button>
      </div>
      <div class="sis-pt-sm" *ngFor="let customStudyDraft of allCustomStudyDrafts">
        <sis-study-box
          [type]="StudyBoxType.studyDraft"
          [selected]="true"
          [action]="'select-to-plan'"
          (actionChange)="handleToggle(customStudyDraft)"
          [id]="customStudyDraft.id"
          [name]="{ fi: customStudyDraft.name, sv: customStudyDraft.name, en: customStudyDraft.name }"
          [credits]="{ max: customStudyDraft.credits, min: customStudyDraft.credits }">
        </sis-study-box>
      </div>
    </ng-template>
  </fudis-expandable>
</ng-container>
