import {
    Component,
    Input,
    OnChanges,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { CreditsRule, EntityWithRule } from 'common-typescript/types';
import * as _ from 'lodash-es';
import {
    ComponentDowngradeMappings,
    DowngradedComponent,
    StaticMembers,
} from 'sis-common/types/angular-hybrid';

import { PlanData, PlanStateObject } from '../../../service/plan-state.service';
import { UiStateObject } from '../../plan-edit/plan-edit.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-credits-rule',
    templateUrl: './credits-rule.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class CreditsRuleComponent implements OnChanges {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.plan.rules.creditsRule',
        directiveName: 'sisCreditsRule',
    };

    ruleValidationResult: any;

    @Input() parentModule: EntityWithRule;
    @Input() creditsRule: CreditsRule;
    @Input() validatablePlan: ValidatablePlan;
    @Input() planValidationResult: any;
    @Input() planStateObject: PlanStateObject;
    @Input() planData: PlanData;
    @Input() uiStateObject: UiStateObject;
    @Input() level: number;
    @Input() blockLevel: number;

    ngOnChanges() {
        this.ruleValidationResult = this.getRuleValidationResult(this.planValidationResult, this.parentModule);
    }

    getRuleValidationResult(planValidationResult: any, module: EntityWithRule): any {
        return _.get(planValidationResult.ruleValidationResults, [module.id, this.creditsRule.localId]);
    }

}
