import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges, Optional,
    ViewEncapsulation,
} from '@angular/core';
import { PlanValidationResult } from 'common-typescript';
import { CourseUnit, OtmId } from 'common-typescript/types';
import * as _ from 'lodash-es';

import {
    PLAN_ACTIONS_SERVICE_INJECTION_TOKEN,
    PlanActionsService,
} from '../../plan/plan-actions-service/plan-actions.service';
import { PlanData, PlanStateObject } from '../../service/plan-state.service';
import { SubstituteCourseUnit } from '../../study-boxes/study-substitution-box/study-substitution-box.component';

@Component({
    selector: 'sis-plan-structure-course-unit-substitutions',
    templateUrl: './plan-structure-course-unit-substitutions.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanStructureCourseUnitSubstitutionsComponent implements OnChanges {
    @Input({ required: true }) substitutedCourseUnit: CourseUnit;
    @Input({ required: true }) planValidationResult: PlanValidationResult;
    @Input({ required: true }) planStateObject: PlanStateObject;
    @Input({ required: true }) planData: PlanData;
    @Input({ required: true }) headingLevel: number;

    substituteCourseUnits: SubstituteCourseUnit[];

    constructor(@Optional() @Inject(PLAN_ACTIONS_SERVICE_INJECTION_TOKEN) private planActionsService: PlanActionsService) {}

    ngOnChanges() {
        this.substituteCourseUnits = this.planStateObject.courseUnits[this.substitutedCourseUnit.id].substitutedBy
            .map(substituteId => {
                const courseUnitStateObject = _.get(this.planStateObject.courseUnits, substituteId);
                const substituteCourseUnit = this.planData.courseUnitsById[substituteId];
                const attainment = _.get(this.planData.attainmentsById, courseUnitStateObject.attainmentId);
                const substituteCredits = courseUnitStateObject.substituteFor
                    .find(sf => sf.substitutedCourseUnitId === this.substitutedCourseUnit.id)
                    ?.substitutedCredits;
                // In order: marked substitute credits ->
                // attainment credits -> substitute course unit credits
                const usedCredits = substituteCredits
                    ?? attainment?.credits
                    ?? substituteCourseUnit.credits;
                // If the substitute course unit has no credits all credits are assumed to be used
                const isPartialSubstitute = !!substituteCredits;
                return <SubstituteCourseUnit>{
                    id: substituteCourseUnit.id,
                    name: substituteCourseUnit.name,
                    code: substituteCourseUnit.code,
                    isPartialSubstitute,
                    substituteCredits: usedCredits,
                    credits: substituteCourseUnit.credits,
                    gradeId: attainment?.gradeId,
                    gradeScaleId: attainment?.gradeScaleId,
                    attainedCredits: attainment?.credits,
                    isAttainmentAboutToExpire: courseUnitStateObject.isAttainmentAboutToExpire,
                    attainmentExpiryDate: courseUnitStateObject.attainmentExpiryDate,
                };
            });
    }

    openCourseUnitInfoModal(courseUnitId: OtmId): void {
        this.planActionsService?.openCourseUnitInfoModal(this.planData.courseUnitsById[courseUnitId]);
    }

}
