<ng-container *transloco="let t">
  <ng-container *ngIf="allOrganisationsForUniversity$ | async as organisations">
    <ng-container *ngIf="options$ | async as options">
      <ng-container *ngIf="selections$ | async as selections">
        <sis-combobox
          [placeholder]="t('SEARCH.PLACEHOLDER_SEARCH')"
          [selected]="selections"
          [options]="options"
          [label]="t('SEARCH.FILTER_TAGS.ORGANIZERS')"
          [predictive]=true
          (optionChange)="onSelectionChange($event)"
          (predictiveSearch)="searchOrganisationsForOptions($event)">
        </sis-combobox>
        <div class="sis-mt-xxs">
          <sis-button [link]="true" (clicked)="selectOrganisationsFromDialog(organisations)">
            <sis-icon [icon]="'picker'"/>
            {{ t('SEARCH.OPEN_ORGANISATION_MODAL') }}
          </sis-button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
