import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ExternalAttainedStudy, OtmId, PriorLearning, PriorStudies } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    EmrexAttainmentDialogComponent,
} from '../../applications/emrex-attainment-dialog/emrex-attainment-dialog.component';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { PRIOR_LEARNING_TYPE } from '../../model/student-application-constants';
import { ExternalAttainedStudyService } from '../../service/external-attained-study.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-learning-list',
    templateUrl: './prior-learning-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorLearningListComponent implements OnInit {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.applications.priorLearningList',
        directiveName: 'sisPriorLearningList',
    };

    readonly PRIOR_LEARNING_TYPE = PRIOR_LEARNING_TYPE;

    /** Determines the naming of the prior learning entries (depending on the type of the containing application). */
    @Input() applicationType: string;
    @Input() priorLearnings: any[];
    @Input() showAsExpandable: boolean;
    /**
     * Determines heading's semantic aria-level for screen readers, default is equivalent for h3
     */
    @Input() level?: number = 3;

    eAQueries: Map<OtmId, Observable<ExternalAttainedStudy>> = new Map();

    private dialogService = inject(FudisDialogService);
    private externalAttainedStudyService = inject(ExternalAttainedStudyService);
    private appErrorHandler = inject(AppErrorHandler);

    ngOnInit() {
        this.priorLearnings?.forEach((pl: PriorLearning) => this.handlePriorStudiesWithExternalStudyAttainments(pl));
    }

    getExternalAttainedObservable(id: OtmId) {
        if (!id || !this.eAQueries.has(id)) return of(null);
        return this.eAQueries.get(id);
    }

    handlePriorStudiesWithExternalStudyAttainments = (priorLearning: PriorLearning) => {
        const priorStudies = priorLearning as PriorStudies;
        if (priorLearning?.type === 'STUDIES' && priorStudies?.externalAttainedStudyId) {
            this.eAQueries.set(priorStudies.externalAttainedStudyId, this.getExternalAttainedStudyObservable(priorStudies.externalAttainedStudyId));
        }
    };

    getExternalAttainedStudyObservable(id: OtmId) {
        return this.externalAttainedStudyService.getExternalAttainedStudyById(id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    openEmrexAttainmentDialog(externalAttainedStudy: ExternalAttainedStudy) {
        this.dialogService.open(EmrexAttainmentDialogComponent, {
            data: {
                externalAttainedStudy,
            },
        });
    }
}
